export enum BillAlarmType {
	/**
	 * +Megas service
	 */
	worryFree = '6',
	/**
	 * You have been charged for the concept indicated.
	 * This type of charge is made for services contracted either punctually or on a monthly basis.
	 */
	singleCharge = '5',
	/**
	 * You have been reimbursed on this bill. It will be subtracted from the total value of the invoice.
	 */
	refund = '9',
	/**
	 * You purchased a product when the billing cycle had already started,
	 * this month you only have to pay the proportional part of the monthly fee.
	 */
	newProduct = '1',
	/**
	 * Having purchased a product when the billing cycle had already begun,
	 * in this invoice you must pay the proportional part of the monthly fee,
	 * plus the full fee of the following month when you have paid in advance.
	 */
	newProductPaymentInAdvance = '2',
	/**
	 * As the tariff has changed once your billing cycle has started, you do not have to pay the full fees,
	 * so this month you only have to pay the proportional part of each monthly fee.
	 */
	changeTariff = '3',
	/**
	 * When you change your tariff, you have been given a partial refund of what you were charged in advance.
	 * You have also been charged the proportional part of the new tariff and the advance payment of this.
	 */
	changeTariffWithPaymentInAdvance = '4',
	/**
	 * Because of your disconnection your monthly fee has been prorated. We have charged you the reconnection fee.
	 */
	reconnectAfterSuspension = '7',
	/**
	 * Unalarmed consumption according to the per-minute tariff
	 */
	minuteTariff = '12',
	/**
	 * Discount for early departure
	 */
	earlyBirdDiscount = '13',
	/**
	 * You enjoy new benefits in your tariff.
	 */
	newBenefints = '14',
	/**
	 * Your tariff includes the new Voucher for calls and SMS abroad.
	 */
	abroadCallsSMS = '15',
	/**
	 * First invoice with payment in advance
	 */
	firstInvoicePaymentInAdvance = '16',
	/**
	 * First invoice with monthly payment due
	 */
	firstInvoice = '17',
	/**
	 * Video/Music/Chat/Map pass promotions
	 */
	passPromotion = '18',
	/**
	 * Last month of discount with monthly payment due
	 */
	lastMonthDiscount = '19',
	/**
	 * Last month of discount with payment in advance
	 */
	lastMonthDiscountPaymentInAdvance = '20',
	/**
	 * Two months before the end of the discount with monthly payment due
	 */
	twoMonthRemainingDiscount = '21',
	/**
	 * Two months before the end of the discount with payment in advance
	 */
	twoMonthsRemainingDiscountPaymentInAdvance = '22',
	/**
	 * Last month of promotion with monthly payment due
	 */
	lastMonthPromotion = '23',
	/**
	 * Last month of promotion with payment in advance
	 */
	lastMonthPromotionPaymentInAdvance = '24',
	/**
	 * Two months before the end of the promotion with monthly payment due
	 */
	twoMonthRemainingPromotion = '25',
	/**
	 * Two months before the end of the promotion with payment in advance
	 */
	twoMonthsRemainingPromotionPaymentInAdvance = '26',
	/**
	 * Payments related to third parties
	 */
	thirdPartiesPayments = '28',
	/**
	 * Special premium payments
	 */
	premiumPayments = '29',
	/**
	 * M4M
	 */
	m4m = '30',
	/**
	 * automatic migration
	 */
	migration = '31',
}
