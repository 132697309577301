<mva10-c-backdrop
	[show]="showBackdrop"
	(mva10Close)="closeBackdrop()"
	(back)="closeBackdrop()"
	id="productAndServicesBackdropChooser"
	[ngClass]="{ hideX: typeBackdrop != '' }"
>
	<p bd-subtitle *ngIf="dobleButton" class="headerPerm" id="productAndServicesBackdropChooserMiniTittle">
		{{ backdropDobleButton.headerTitle }}
	</p>

	<div
		class="backdropChooser"
		bd-content
		id="productAndServicesBackdropChooserContentDoublebutton"
		*ngIf="!dobleButton; then thenBlock; else elseBlock"
	></div>

	<ng-template class="backdropChooser" #thenBlock>
		<img
			class="imageBackdropChooser"
			alt="icon back"
			id="productAndServicesBackdropChooserContentSingleButtonImg"
			src="{{ backdropOneButton.image_rsp | imgFullPath }}"
		/>
		<div class="titleBackdropUnauth" id="productAndServicesBackdropChooserContentSingleButtonTitle">
			{{ backdropOneButton.title }}
		</div>
		<div class="descBackdropUnauth" id="productAndServicesBackdropChooserContentSingleButtonDescText">
			{{ backdropOneButton.descriptionText }}
		</div>
		<button class="redBtn" id="productAndServicesBackdropChooserContentSingleButtonButton" (click)="closeBackdrop()">
			{{ backdropOneButton.backButtonText }}
		</button>
	</ng-template>

	<ng-template #elseBlock>
		<div class="titlePerm permissionTitle" id="productAndServicesBackdropChooserContentDoubleButton">
			{{ backdropDobleButton.title }}
		</div>
		<div class="descriptionPerm permissionDesc" id="productAndServicesBackdropChooserDoubleButtonContentDesc">
			{{ backdropDobleButton.descriptionText }}
		</div>
		<div class="chooserButtons">
			<button class="redBtn" id="productAndServicesBackdropChooserContentDoubleButtonButton" (click)="actionBackdrop()">
				{{ backdropDobleButton.createButtonText }}
			</button>
			<button
				class="whiteBtn"
				id="productAndServicesBackdropChooserContentDoublebuttonCancelButton"
				(click)="closeBackdrop()"
			>
				{{ backdropDobleButton.cancelButtonText }}
			</button>
		</div>
	</ng-template>
</mva10-c-backdrop>

<vfac-overlay
	[show]="showSpinner"
	[loaded]="true"
	(closed)="closeBackdrop()"
	[trayMode]="true"
	id="productAndServicesBackdropSpinner"
>
	<div body id="productAndServicesBackdropChooserContentSpinner" class="spinnerChooser">
		<mva10-c-speechmark-spinner
			[color]="spinnerColor"
			[type]="spinnerType"
			id="productAndServicesBackdropChooserSpinner"
		>
		</mva10-c-speechmark-spinner>
	</div>
</vfac-overlay>
