import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StatePedido, orderTypes, orderTypesKeys } from '../../../../enums/order-type.enum';
import { OrdenObtenerPedidos } from '../../../../models/obtenerPedidos.model';
import { Router } from '@angular/router';
import { config } from '../../../../../../config/pages-config';
import { buttonBreakdown } from '../../../../constants/defines';
import { MyOrdersTagService } from '../../services/myOrderTag.service';
import { OrdersService } from '../../services/orders.service';
import { DetallePedidoExtended } from '../../../../models/detalleSeguimientoPedido.model';
import { stateMyOrders, tabsMyOrders } from '../../../../constants/defines';

@Component({
	selector: 'sp-collapse',
	templateUrl: './sp-collapse.component.html',
	styleUrls: ['./sp-collapse.component.scss'],
})
export class SpCollapseComponent {
	@Input() orderData: OrdenObtenerPedidos;
	@Input() title: string;
	@Input() color: string;
	@Input() icon: string;
	@Input() nextIcon: string;
	@Input() orderType: string;
	@Input() btnDetalle: boolean;
	@Input() size: string;
	@Output() clickButton: EventEmitter<OrdenObtenerPedidos> = new EventEmitter<OrdenObtenerPedidos>();
	isCollapsed: boolean;
	verDetalle: string = tabsMyOrders.showDetail;
	public returnTabs: string = orderTypesKeys.returns;
	public forWardSlash: string = tabsMyOrders.forWardSlash;
	public ofString: string = tabsMyOrders.ofString;
	public formatDate: string = tabsMyOrders.formatDate;
	public formatDay: string = tabsMyOrders.formatDay;
	public formatMonth: string = tabsMyOrders.formatMonth;
	public formatX: string = tabsMyOrders.formatX;
	public stateInProgress: string = StatePedido.OPEN;
	public stateCompleted: string = StatePedido.COMPLETED;
	public stateReturn: string = StatePedido.RETURNS;
	public stateCancelled: string = StatePedido.CANCELLED;
	public inProgress: string = stateMyOrders.inProgress;
	public completed: string = stateMyOrders.completed;
	public return: string = stateMyOrders.return;
	public cancelled: string = stateMyOrders.cancelled;

	public flow24: string = buttonBreakdown.flow24;
	public flow25: string = buttonBreakdown.flow25;

	constructor(
		public translate: TranslateService,
		public router: Router,
		public ordersService: OrdersService,
		public myOrdersTagService: MyOrdersTagService
	) {}

	getOrderState(criteria: string): boolean {
		const states: { [key: string]: boolean } = {
			myOrders: this.orderType === orderTypes.vigentes || this.orderType === orderTypes.completados,
			returns: this.orderType === orderTypes.returns,
			canceled: this.orderType === orderTypes.cancelados,
		};
		return states[criteria];
	}

	getIconClasses(): string {
		let colector = this.icon;
		if (this.size) {
			colector += ' mvf-collapse__product-icon--' + this.size;
		} else {
			colector += ' mvf-collapse__product-icon--big';
		}
		if (this.color) {
			colector += ' mvf-collapse__product-icon--' + this.color;
		} else {
			colector += ' mvf-collapse__product-icon--primary';
		}
		return colector;
	}

	getText(): { date: string; number: string } {
		const inprogress = this.translate.instant('v10.myOrder.collapse.orderInprogress');
		const norder = this.translate.instant('v10.myOrder.collapse.numberOrder');
		const ordercompleted = this.translate.instant('v10.myOrder.collapse.orderCompleted');
		const ordercancel = this.translate.instant('v10.myOrder.collapse.orderCancel');
		if (this.getOrderState(orderTypesKeys.myOrders)) {
			return { date: inprogress, number: norder };
		} else if (this.getOrderState(orderTypesKeys.returns)) {
			return { date: ordercompleted, number: norder };
		} else {
			return { date: ordercancel, number: norder };
		}
	}

	getWarnTitle(): string {
		const orderdelay = this.translate.instant('v10.myOrder.collapse.orderDelay');
		const cancelreason = this.translate.instant('v10.myOrder.collapse.cancellationReason');
		return this.getOrderState(orderTypesKeys.completed) ? orderdelay : cancelreason;
	}

	next(): void {
		this.clickButton.emit(this.orderData);
	}

	public goBreakdowns(): void {
		this.ordersService.selectedOrderData = this.orderData;
		this.ordersService.detailOrderData = new DetallePedidoExtended();
		this.ordersService.detailOrderData.numeroPedido = this.orderData.numeroPedido;
		this.ordersService.detailOrderData.descripcion = this.orderData.descripcion;
		this.router.navigate([config.FaultManagement.LandingPage.route]);
		this.myOrdersTagService.tagClickBreakdowns();
	}
}
