import { Component } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { DynamicTrayService } from '../../services/dynamic-tray.service';

@Component({
	selector: 'sp-success-error-tray',
	templateUrl: './success-error-tray.component.html',
	styleUrls: ['./success-error-tray.component.scss'],
})
export class SuccessErrorTrayComponent {
	/**
	 * this class is a helper component for let us easily deal with confirmation and success/failure trays
	 * @param dynamicTrayService that has all data and functionality of tray
	 */
	constructor(public dynamicTrayService: DynamicTrayService, public appService: AppService) {}
}
