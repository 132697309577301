import { LeftMenuWs10Service } from './../../../mva10/shared/services/left-menu-ws10.service';
import { CustomerAccountService } from './../../services/customer-account.service';
import { Component } from '@angular/core';
import { AppService } from '../../../app.service';
import { Router } from '@angular/router';
import { config } from '../../../../config/pages-config';
import { TaggingViewModel, TaggingActionModel } from '../../../../app/models/tagging.model';
import { tagging } from '../../../../config/tagging-config';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { ProductsAndServicesService } from '../../../products-and-services/products-and-services.service';
import { TranslateService } from '@ngx-translate/core';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';
import { NewTaggingHelperService } from './../../../core/services/new-tagging.helper.service';
import { FOLDER_ADARA_MY_PRODUCTS, ADARA_TRACKING } from '../../constants/archetype/adaraTracking';
@Component({
	selector: 'sp-submenu-products',
	templateUrl: './submenu-products.component.html',
	styleUrls: ['./submenu-products.component.scss'],
})
export class SubmenuProductsComponent {
	title: string;
	subtitle: string;

	public telcoFilter: boolean = true;

	constructor(
		public customerAccountService: CustomerAccountService,
		public leftMenuService: LeftMenuWs10Service,
		public appService: AppService,
		public router: Router,
		public taggingHelper: TaggingHelperService,
		public productAndService: ProductsAndServicesService,
		public translateService: TranslateService,
		public newTaggingHelperService: NewTaggingHelperService
	) {}

	selectClick(event) {
		if (event) {
			this.leftMenuService.setSiteSelectorClicked();
		}
	}

	goToMyOrders(): void {
		// Click from tablet products submenu link
		const page: TaggingViewModel = Object.assign({}, tagging.selectorCardView);
		const trackAction: TaggingActionModel = Object.assign({}, tagging.dashboard.pendingInstallMyOrdersTrayClick);
		this.taggingHelper.track(trackAction.eventName, Object.assign(page, trackAction.data));

		this.leftMenuService.closeSubmenu();
		this.router.navigate([config.myorders.route]);
	}

	public clickOnAdaraCTATags(): void {
		this.router.navigate([config.adara.route]);
		this.newTaggingHelperService.getJsonTagging(FOLDER_ADARA_MY_PRODUCTS).subscribe((data: NewTaggingJsonModel) => {
			this.newTaggingHelperService.interaction(data.eventList[ADARA_TRACKING.INTERACTIONS.FILTER_CLICK], data.page);
		});
	}
}
