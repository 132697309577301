export class FundedDevice {
	id: string;
	brand: string;
	model: string;
	imagePath: string;
	msisdn: string;
	paymentStatus?: string;
	state?: number;
	/* Regular Payments Detail */
	initialPayment: number;
	totalPendingQuotes: number;
	totalPendingAmount: number;
	totalPendingAm?: number;
	totalAmount: number;
	regularPayment: PaymentDetail;
	isCompletedRegularFunded: boolean;
	/* Deferred Payments Detail */
	hasFinalPayment: boolean;
	deferredPayment: PaymentDetail;
	isDeferrable: boolean;
	pendingFinalPaymentAproval: boolean;
	deferToBeConfirmed: boolean;
	deferConfirmed: boolean;
	deferAccepted: boolean;
	isCompletedFunded: boolean;
	isLastPayment: boolean;
	showFinalPaymentData: boolean;
	/* Dates Detail */
	nextQuoteDate: Date;
	finalQuoteDate: Date;
	finalPaymentDueDate: Date;
	purchaseDate: Date;
	aceptanceIniDate: Date;
	aceptanceDueDate: Date;
	isAceptancePeriod: boolean;
	/* Amortization Detail */
	amortizationQuotes: number;
	amortizationAmount: number;
}

export class PaymentDetail {
	totalAmount: number;
	pendingAmount: number;
	payedAmount?: number;
	totalQuotes: number;
	pendingQuotes: number;
	payedQuotes?: number;
	monthlyQuote: string;
	monthlyQuoteNotFormated?: number;
	pendingCancelledQuotes?: number;
}
