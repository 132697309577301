<div class="overlaycontainer" [ngStyle]="{ height: pageHeight }" id="fullOverlayPageContainerID">
	<div #pageContent>
		<div class="overlaycontainer__img-container" id="fullOverlayPageImageContainer">
			<img [src]="overLayContent?.imgUrl" id="fullOverlayPageImageSrc" />
			<div class="title" id="fullOverlayPageImageTitle">
				<p class="overlaycontainer__img-container__title" [innerHtml]="overLayContent?.pageTitle"></p>
				<span class="icon icon-close" (click)="close()"> </span>
			</div>
		</div>
		<div class="overlaycontainer__content-container" id="fullOverlayPageContentContainer">
			<p
				class="overlaycontainer__content-container__title"
				[innerHtml]="overLayContent?.pageSubtitle"
				id="fullOverlayPageContentSubTitle"
			></p>
			<div class="overlaycontainer__mainContent" id="fullOverlayPageContent">
				<div *ngFor="let item of overLayContent?.subtitleContent" class="overlaycontainer__mainContent__bullets">
					<div class="overlaycontainer__mainContent__imgeContainer">
						<svg-icon class="overlaycontainer__mainContent__imgeContainer__image" [src]="item.imageUrl"> </svg-icon>
					</div>
					<div class="overlaycontainer__mainContent__description">
						<p [innerHtml]="item.title"></p>
					</div>
				</div>
				<div class="overlaycontainer__mainContent__btnContainer">
					<button type="button" class="btn" (click)="btnClick()">{{ overLayContent?.btnConfigs?.btnName }}</button>
				</div>
			</div>
		</div>
	</div>
	<sp-fixed-bottom-button
		id="fullOverlayPageCTA"
		#footer
		[btnName]="overLayContent?.btnConfigs?.btnName"
		[isMobileScrollable]="!isMobileScrollable && isMobileView"
		[grayBackgroundBelowFooter]="false"
		[shadowFooter]="true"
		(btnAction)="btnClick()"
	>
	</sp-fixed-bottom-button>
</div>
