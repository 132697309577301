export enum ProductSubtype {
	Data,
	EnjoyMore,
	FixedToMobile,
	FixedToInternational,
	Minutes,
	Sms,
	FixedToFixed,
	Extra,
	EnjoyMoreExtra,
}
