export const marketplaceEntryPoints: string[] = ['MKTP', 'MKTPP'];

export const mktpUnsuitablePostalCodes: string[] = ['35', '38', '51', '52'];

export const marketplaceGrifoEntryPoint: string = 'MKTPO';

export const marketplaceMyOrdersScreenCode: string = 'MYORDERS';

export const kitDigital: string = 'lnk_kitdigital';
export const serviciosProfesionales: string = 'lnk_sp_mobile';
export const topUp: string = 'lnk_top_up';
export const forYou: string = 'lnk_for_you';

export const marketplaceParams: { [key: string]: string } = {
	authorizeClientIdValue: 'marketplace',
	redirectPrefix: '/authorize/marketplace?response_type=code&client_id=',
	redirectScope: '&scope=OPENID&id_token_hint=',
	redirectUri: '&redirect_uri=',
	REDUCEDTOKENSCOPE: '',
	wcsActivatedFlag: 'v10.productsServices.marketplace.myOrdersActivated',
	wcsUriHome: 'v10.productsServices.marketplace.uriHome',
	wcsUriMyOrders: 'v10.productsServices.marketplace.uriMyOrders',
	wcsUriBillingAccount: 'marketplace_wallet',
	wcsUrlBase: 'v10.productsServices.marketplace.urlBase',
};
