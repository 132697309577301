import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResizeService } from '../../../mva10/shared/services/resize.service';
import { AppService } from '../../../app.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { CaptureResponseService } from '../../services/capture-response.service';
import { EntryPointsService } from '../../services/entry-points.service';
import { LeftMenuWs10Service } from '../../../mva10/shared/services/left-menu-ws10.service';
import { config } from '../../../../config/pages-config';
import { Router } from '@angular/router';
import { OverLayContent } from '../../../models/overlayContent.model';
import { Breakpoints } from '@mva10/mva10-angular';
import { AppThemes, changeTariffNoPromoBtnWidths, pegaPlatinumOfferCodes } from '../../constants/defines';
import { EntryPointResponseModel, EntryPointModel } from '../../../models/entry-points.model';
import { tagging } from '../../../../config/tagging-config';

@Component({
	selector: 'sp-nba-pega-platinum-offer',
	templateUrl: './nba-pega-platinum-offer.component.html',
	styleUrls: ['./nba-pega-platinum-offer.component.scss'],
})
export class NbaPegaPlatinumOfferComponent implements OnInit, OnDestroy {
	overlayContent: OverLayContent;
	isMobile: boolean;
	previousTheme: string;
	pegaCode: string;
	constructor(
		public translate: TranslateService,
		private router: Router,
		private resizeService: ResizeService,
		public appService: AppService,
		private tagging: TaggingHelperService,
		private captureResponseService: CaptureResponseService,
		private entryPointService: EntryPointsService,
		private leftMenuService: LeftMenuWs10Service
	) {}

	ngOnInit(): void {
		this.appService.hiddenHeader = true;
		this.leftMenuService.showLeftMenu = true;
		this.previousTheme = this.appService.theme;
		this.isMobile = this.resizeService.breakpoint < Breakpoints.TABLET;
		this.appService.theme = this.isMobile ? `${AppThemes.ThemeBkgWhite} ${AppThemes.Theme2}` : AppThemes.ThemeGrayBG;
		this.sendAcceptCaptureResponse();
		this.getOvelayContentWCS();
	}

	closeTray(): void {
		this.sendInteractionTaggingCerrarBtn();
		this.router.navigate([config.dashboard.route]);
	}

	btnAction(): void {
		this.sendInteractionTaggingRecargarBtn();
		this.router.navigate([config.TopUp.topUpPrepaidservice.route]);
	}

	sendAcceptCaptureResponse(): void {
		this.captureResponseService.entryPoint = this.entryPointService.getEntryPointByCode(
			this.entryPointService.entryCode
		);
		const pegaEntryPoints: EntryPointResponseModel = {
			entryPoints: [this.captureResponseService.entryPoint],
		};
		this.captureResponseService.callCaptureResponsePega(pegaEntryPoints);
	}

	getOvelayContentWCS(): void {
		const entryPoints: EntryPointResponseModel = this.entryPointService?.cacheEntrypointsService?.getEntryPoints();
		const entryPoint: EntryPointModel = entryPoints?.entryPoints?.find((ele) =>
			pegaPlatinumOfferCodes.includes(ele?.code)
		);
		this.pegaCode = entryPoint?.code;
		this.translate.get(`v10.prepaidPEGAPlatinumOffer.${entryPoint?.code}.landingPage`).subscribe((data) => {
			this.overlayContent = {
				pageTitle: data.title,
				pageSubtitle: data.subTtile,
				subtitleContent: data.paragraph.replace(`{${entryPoint?.nameVar}}`, entryPoint?.valueVar),
				btnConfigs: {
					btnName: data.button,
					btnWidth: this.isMobile ? changeTariffNoPromoBtnWidths.mobile : changeTariffNoPromoBtnWidths.desktopPromo,
				},
				imgUrl: this.isMobile ? data.coverPhoto : data.coverPhotoResp,
			};
		});
		this.sendPageTaggingAlmostInOrAlmostOut();
	}

	sendPageTaggingAlmostInOrAlmostOut(): void {
		this.pegaCode === pegaPlatinumOfferCodes[0]
			? this.tagging.view(tagging.pegaPlatinumJourney.almostIN.state)
			: this.tagging.view(tagging.pegaPlatinumJourney.almostOut.state);
	}
	sendInteractionTaggingRecargarBtn(): void {
		if (this.pegaCode === pegaPlatinumOfferCodes[0]) {
			this.tagging.track(
				tagging.pegaPlatinumJourney.almostIN.interaction.ClickOnRecargar.eventName,
				tagging.pegaPlatinumJourney.almostIN.interaction.ClickOnRecargar.data,
				tagging.pegaPlatinumJourney.almostIN.state
			);
		} else {
			this.tagging.track(
				tagging.pegaPlatinumJourney.almostOut.interaction.ClickOnRecargar.eventName,
				tagging.pegaPlatinumJourney.almostOut.interaction.ClickOnRecargar.data,
				tagging.pegaPlatinumJourney.almostOut.state
			);
		}
	}
	sendInteractionTaggingCerrarBtn(): void {
		if (this.pegaCode === pegaPlatinumOfferCodes[0]) {
			this.tagging.track(
				tagging.pegaPlatinumJourney.almostIN.interaction.ClickOnCerrar.eventName,
				tagging.pegaPlatinumJourney.almostIN.interaction.ClickOnCerrar.data,
				tagging.pegaPlatinumJourney.almostIN.state
			);
		} else {
			this.tagging.track(
				tagging.pegaPlatinumJourney.almostOut.interaction.ClickOnCerrar.eventName,
				tagging.pegaPlatinumJourney.almostOut.interaction.ClickOnCerrar.data,
				tagging.pegaPlatinumJourney.almostOut.state
			);
		}
	}

	ngOnDestroy(): void {
		this.appService.hiddenHeader = false;
		this.appService.theme = this.previousTheme;
	}
}
