import { Component, OnInit } from '@angular/core';
import { UpgradeProfileService } from './upgrade-profile.service';

@Component({
	selector: 'sp-upgrade-profile',
	templateUrl: './upgrade-profile.component.html',
})
export class UpgradeProfileComponent implements OnInit {
	constructor(public upgrade: UpgradeProfileService) {}
	ngOnInit() {}
}
