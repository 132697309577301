import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilitiesService } from '../../shared/utils/utilities.service';
import { AppService } from '../../app.service';

@Component({
	selector: 'sp-shop-policy',
	templateUrl: './shop-policy.component.html',
	styleUrls: ['./shop-policy.component.scss'],
})
export class ShopPolicyComponent implements OnInit {
	shopPloicies: { title: string; desc: string }[] = new Array<{ title: string; desc: string }>();

	constructor(
		private translate: TranslateService,
		private utilitiesService: UtilitiesService,
		private appService: AppService
	) {}

	ngOnInit(): void {
		this.translate.get('v10.legal.shopPolicy.itemList').subscribe((list) => {
			const shopPolicyList: { title: string; desc: string }[] = Object.values(list);
			shopPolicyList.forEach((item) => {
				this.shopPloicies.push(item);
			});
		});
	}

	downloadDocument(documentPath: string): void {
		const url: string = this.appService.getImgFullPath(documentPath);
		this.utilitiesService.downloadPDF(url);
	}
}
