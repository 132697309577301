import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentErrorCategory } from '../../../shared/enums/payment-error-category.enum';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentJourny } from '../../../shared/enums/payment-journy.enum';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentErrorInterface } from './payment-error-interface';
import { PaymentErrorPostPaid } from './payment-error-post-paid';

export class PaymentErrorPostPaidAnonymous extends PaymentErrorPostPaid implements PaymentErrorInterface {
	defaultErrorCategory: PaymentErrorCategory = PaymentErrorCategory.SystemNotAvailable;
	constructor(
		public paymentNavigationService: PaymentNavigationService,
		public translate: TranslateService,
		public router: Router
	) {
		super(paymentNavigationService, translate, router);
	}
	getFirstBtnAction(category: string): Function {
		let btnAction: Function;
		switch (category) {
			case PaymentErrorCategory.general:
			case PaymentErrorCategory.PaymentApiFails:
			case PaymentErrorCategory.TimeOut:
			case PaymentErrorCategory.invalidCreditCard:
			case PaymentErrorCategory.CreditCardTypeNotSupported:
			case PaymentErrorCategory.invalidCreditCardInfo:
			case PaymentErrorCategory.ReplicaMode:
			case PaymentErrorCategory.SystemNotAvailable:
				btnAction = this.restartJourney();
				break;
			default:
				btnAction = this.close();
				break;
		}
		return btnAction;
	}
	/**
	 * action for x btn click on overlay
	 */
	closeBtnAction(): void {
		if (
			this.displayedErrorCategory === PaymentErrorCategory.anonymousIdentifierFailure &&
			!this.paymentNavigationService.accountNumberValue
		) {
			this.paymentNavigationService.back();
		} else if (
			this.displayedErrorCategory === PaymentErrorCategory.noDebtFailure &&
			this.paymentNavigationService.accountNumberValue
		) {
			this.paymentNavigationService.back(2);
		} else {
			this.paymentNavigationService.closePayment();
		}
	}
	restartJourney(): () => void {
		let firstEnteryPointName: PaymentPages;
		switch (this.paymentNavigationService.WCSJourneyKey) {
			case PaymentJourny.anonymousDebtPartial:
			case PaymentJourny.anonymousDebtTotal:
				firstEnteryPointName = PaymentPages.dept;
				break;
			case PaymentJourny.anonymousPayInAdvance:
				firstEnteryPointName = PaymentPages.inadvance;
				break;
		}
		return this.paymentNavigationService.restartPaymentJourney.bind(
			this.paymentNavigationService,
			firstEnteryPointName
		);
	}
}
