import {
	AuthorizationList,
	logOut,
	CONFIG,
	chatBotToken,
	DocumentumHeader,
	ChangePinParentalHeader,
	CommercialJourneys,
	HttpRequestMethods,
	ERRORCODES,
	SEIBEL_USER,
	menuItemsUrl,
} from '../../shared/constants/defines';
import { throwError, Observable, BehaviorSubject, empty } from 'rxjs';
import { catchError, tap, switchMap, finalize, filter, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpErrorResponse,
	HttpResponse,
} from '@angular/common/http';

import { StorageService } from '../services/storage.service';
import { environment } from '../../../environments/environment';
import { LOCAL_STORAGE_KEYS } from '../../shared/constants/defines';
import { AuthenticateService } from '../services/authenticate.service';
import { API_URLS } from '../../shared/constants/routes-config';
import * as _ from 'lodash';
import { CSRFToken } from '../../shared/constants/defines';
import { appUrlsConfiguration } from '../../shared/constants/defines';
import { Router } from '@angular/router';
import { config } from '../../../config/pages-config';
import { AppService } from '../../app.service';
import { ConfigurationService } from '../../core/services/configuration.service';
import { Guid } from 'guid-typescript';

@Injectable({
	providedIn: 'root',
})
export class CustomHttpInterceptor implements HttpInterceptor {
	transactionId: string;
	// using pure functions to create pipeline request
	requestInterceptors: ((req: HttpRequest<any>) => HttpRequest<any>)[] = [];
	isSettingsApiCsrfToken: boolean = false;
	csrfSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	apiUrlsFailed403: string[] = [];
	isRefreshing = false;
	refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(
		private storage: StorageService,
		private appService: AppService,
		private authenticateService: AuthenticateService,
		private configService: ConfigurationService,
		private router: Router
	) {}
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// intercept request
		let newReq: HttpRequest<any>;
		/** check either access token not expired or the url doesn't need access token header */
		if (this.checkAccessToken(req)) {
			newReq = this.addRequestHeader(req);
		}
		return this.handleRequest(newReq || req, next);
	}

	checkAccessToken(req: HttpRequest<any>): boolean {
		return (
			!this.authenticateService.isAccessTokenExpired() ||
			(!this.storage.accessToken && !req.url.toString().includes(menuItemsUrl)) ||
			this.authorizationLinks(req.url) ||
			req.url.toString().includes(appUrlsConfiguration) ||
			req.url.toString().includes(logOut) ||
			req.url.toString().includes(chatBotToken)
		);
	}

	handleRequest(newReq: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(newReq).pipe(
			tap((event) => {
				if (event instanceof HttpResponse) {
					this.CheckResponseHeader(event);
				}
			}),
			catchError((error, event) => {
				const customErrorCode: string | number = error.error?.ecode || error.ecode;
				if (customErrorCode?.toString() === SEIBEL_USER.seibelUserCode) {
					return throwError(error);
				} else if (
					error.status === 403 &&
					newReq.method !== HttpRequestMethods.get &&
					newReq.url === API_URLS.Login.SESSION_START
				) {
					return this.handle403ErrorCsrfToken(newReq, next, error);
				} else if (
					error.status === 401 &&
					error?.error?.code !== ERRORCODES.WRONG_OTP_ERROR &&
					error?.error?.error === ERRORCODES.INVALID_TOKEN_ERROR
				) {
					return this.handle401Error(newReq, next);
				}
				return throwError(error);
			})
		);
	}

	handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);
			return this.authenticateService.refreshTokens().pipe(
				switchMap(() => {
					this.isRefreshing = false;
					this.refreshTokenSubject.next(this.storage.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN));
					return next.handle(this.addAutherizationHeaderBearer(request));
				}),
				catchError((error) => {
					this.isRefreshing = false;
					this.rerouteToLogin();
					return throwError(error);
				})
			);
		} else {
			return this.refreshTokenSubject.pipe(
				filter((token) => token != null),
				take(1),
				switchMap(() => {
					return next.handle(this.addAutherizationHeaderBearer(request));
				})
			);
		}
	}

	addRequestHeader(req: any): any {
		let newReq: any = req;
		if (newReq.url.indexOf(environment.middlewareBaseUrl) > -1) {
			newReq = this.addAutherizationHeaderBearer(newReq);
			newReq = this.addTargetEnvironmentHeader(newReq);

			const transactionID: Guid = Guid.create()['value'];
			newReq = newReq.clone({
				headers: newReq.headers.set('vf-trace-transaction-id', transactionID),
				withCredentials: true,
			});

			newReq = newReq.clone({
				headers: newReq.headers.set('vf-country-code', CONFIG.COUNTRY_CODE),
				withCredentials: true,
			});
			newReq = this.addCSRFTokenHeader(newReq);
			if (newReq.url.includes(API_URLS.Documentum.post)) {
				newReq = newReq.clone({
					headers: newReq.headers.set(DocumentumHeader.name, DocumentumHeader.value),
					withCredentials: true,
				});
			}
			if (newReq.url.includes(API_URLS.ActivationAndConfiguration.patchChangePinTest)) {
				newReq = newReq.clone({
					headers: newReq.headers.set(ChangePinParentalHeader.name, ChangePinParentalHeader.value),
					withCredentials: true,
				});
			}
		}
		newReq = this.addAuthTicketHeaderForCommercialJourneys(newReq);
		return newReq;
	}
	addTokenHeader(req: HttpRequest<any>, token: string): HttpRequest<any> {
		return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
	}

	addAuthTicketHeaderForCommercialJourneys(newReq: any): any {
		if (
			newReq.url.includes(CommercialJourneys.securePath) ||
			(newReq.url.includes(CommercialJourneys.pathOfM2POferts) && newReq.url.includes(CommercialJourneys.shopV10.page))
		) {
			return newReq.clone({
				headers: newReq.headers.set('auth_ticket', this.storage.getLocalStorage(LOCAL_STORAGE_KEYS.JWT)),
			});
		}
		return newReq;
	}

	addAutherizationHeaderBearer(newReq: any): any {
		if (
			!this.authorizationLinks(newReq.url) &&
			!newReq.url.toString().includes(appUrlsConfiguration) &&
			!newReq.url.toString().includes(logOut) &&
			!newReq.url.toString().includes(chatBotToken)
		) {
			return newReq.clone({
				headers: newReq.headers.set(
					'Authorization',
					'Bearer ' + this.storage.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
				),
				withCredentials: true,
			});
		}
		return newReq;
	}

	addCSRFTokenHeader(newReq: any): any {
		const CSRFTokenValue: any = this.storage.getLocalStorage(LOCAL_STORAGE_KEYS.USER_CSRF_TOEKN);
		if (CSRFTokenValue) {
			return newReq.clone({
				headers: newReq.headers.set(CSRFToken, CSRFTokenValue.toString()),
				withCredentials: true,
			});
		}
		return newReq;
	}

	addTargetEnvironmentHeader(newReq: any): any {
		if (environment.environmentName) {
			return newReq.clone({
				headers: newReq.headers.set('vf-target-environment', 'aws-' + environment.environmentName + '-es'),
				withCredentials: true,
			});
		}
		return newReq;
	}

	handle403ErrorCsrfToken(
		newreq: HttpRequest<any>,
		next: HttpHandler,
		error: HttpErrorResponse
	): Observable<HttpEvent<any>> {
		this.apiUrlsFailed403.push(newreq.url);
		if (this.apiUrlsFailed403.filter((url) => url === newreq.url).length > 1) {
			this.apiUrlsFailed403 = this.apiUrlsFailed403.filter((url) => url !== newreq.url);
			return throwError(error);
		}
		if (!this.isSettingsApiCsrfToken) {
			this.isSettingsApiCsrfToken = true;

			// Reset here so that the following requests wait until the token
			// comes back from the refreshToken call
			this.csrfSubject.next(null);

			return this.configService.load().pipe(
				switchMap(() => {
					if (!this.configService.isConfigurationApiFailed) {
						this.csrfSubject.next('Done');
						return next.handle(this.addCSRFTokenHeader(newreq));
					}
				}),
				finalize(() => {
					this.isSettingsApiCsrfToken = false;
				})
			);
		} else {
			return this.csrfSubject.pipe(
				filter((csrftoken) => csrftoken != null),
				take(1),
				switchMap(() => {
					return next.handle(this.addCSRFTokenHeader(newreq));
				})
			);
		}
	}

	authorizationLinks(targetURL: string): boolean {
		const whitelist: string[] = AuthorizationList;
		const result: string[] = _.filter(whitelist, (o: RegExp) => {
			return targetURL.match(o);
		});
		return result.length > 0;
	}

	CheckResponseHeader(response: HttpResponse<any>): void {
		if (response.headers.has('msisdn') && response.headers.get('msisdn')) {
			this.storage.msisdn = response.headers.get('msisdn');
		}
		if (response.headers.has('Authorization') && response.headers.get('Authorization')) {
			const parts: string[] = response.headers.get('Authorization').split('.');
			// ensure the jwt is correct
			if (parts.length === 3) {
				this.storage.setLocalStorage(LOCAL_STORAGE_KEYS.JWT, [response.headers.get('Authorization')]);
				this.storage.userProfile = this.authenticateService.getUserProfile(response.headers.get('Authorization'));
				this.storage.gdprUpdatedLocaly = false;
			}
		}
	}
	HandleRefreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.authenticateService.refreshTokens().pipe(
			switchMap((response) => {
				const refreshToken: any = this.storage.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
				if (refreshToken) {
					return next.handle(this.addRequestHeader(req));
				} else {
					this.rerouteToLogin();
				}
			}),
			catchError((err) => {
				if (err.url === API_URLS.Login.SESSION_START) {
					this.rerouteToLogin();
				}
				return throwError(err);
			})
		);
	}
	rerouteToLogin(): void {
		this.appService.emitApplogoutSubject();
		this.authenticateService.forceClearUserStorageData();
		this.router.navigate([config.login.name]);
	}
}
