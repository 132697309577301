<article
	class="vfes-card vfes-card-border-radius vfes-colours-bg-vodafone-white vfes-card-no-border mvf-detail vfes-mt"
>
	<!-- HEADER 1.0 -->

	<section class="mvf-detail__header-group">
		<div class="mvf-collapse__bottom-border">
			<div class="boxStatusOrders">
				<strong
					id="myOrdersSpDetailTitle"
					class="titleBoxStatusOrders mvf-collapse__title-text mva10-u-heading--5-bd"
					>{{ 'v10.myOrder.steps.status' | translate }}</strong
				>
				<strong
					id="myOrdersSpDetailDescTitle"
					class="descrpBoxStatusOrders mvf-collapse__title-text mva10-u-heading--5-bd"
					>{{ stateActivation }}</strong
				>
			</div>
			<header class="mvf-collapse__header">
				<div>
					<svg
						id="myOrdersSpDetailIconAlert"
						class="vfes-alert-icon icon"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						width="24px"
						height="24px"
					>
						<title>{{ 'v10.myOrder.detail.error' | translate }}</title>
						<use class="icon-v3" xlink:href="" [attr.xlink:href]="'#' + icon"></use>
					</svg>
				</div>
				<div class="mvf-collapse__title-container">
					<h3 class="mvf-collapse__title" id="myOrdersSpDetailTitleOrder">
						<span id="myOrdersSpDetailDescTitleOrder" class="mvf-collapse__title-text mva10-u-heading--5-bd">
							{{ orderData.nombreComercial || orderData.descripcion }}
						</span>
					</h3>
					<div class="mvf-collapse__info-container">
						<div class="mvf-collapse__sim-info mva10-u--footnote" *ngIf="orderData.infoSim">
							<div class="mvf-collapse__sim-info--sim">
								<strong id="myOrdersSpDetailInfoSim"> {{ 'v10.myOrder.detail.sim' | translate }} </strong>
								{{ orderData.infoSim.sim || guion }}
							</div>
							<div class="mvf-collapse__sim-info--pinpuk">
								<span id="myOrdersSpDetailInfoPinSpan" class="mvf-collapse__sim-info--pin">
									<strong id="myOrdersSpDetailInfoPin"> {{ 'v10.myOrder.detail.pin' | translate }} </strong>
									{{ orderData.infoSim.pin || guion }}
								</span>
								<span id="myOrdersSpDetailInfoPukSeparator" class="mvf-collapse__sim-info--separator">
									{{ separator }}
								</span>
								<span id="myOrdersSpDetailInfoPukSpan" class="mvf-collapse__sim-info--puk">
									<strong id="myOrdersSpDetailInfoPuk"> {{ 'v10.myOrder.detail.puk' | translate }} </strong>
									{{ orderData.infoSim.puk || guion }}
								</span>
							</div>
						</div>
						<span id="myOrdersSpDetailNombreTienda" class="mvf-collapse__subtitle mva10-u--body">
							{{ orderData.nombreTienda }}
						</span>
					</div>
				</div>
			</header>
		</div>
		<!-- WARNING SUPERIOR -->
		<div *ngIf="ordersService.detailOrderData?.mensajeSuperior">
			<sp-warn-box [messages]="[ordersService.detailOrderData?.mensajeSuperior]"> </sp-warn-box>
		</div>
		<div *ngIf="ordersService.showMessage">
			<sp-warn-box [messages]="[ordersService.superiorMessage]"> </sp-warn-box>
		</div>
		<!-- NUM PEDIDO Y FECHA DE SOLICITUD -->
		<section class="mvf-detail__header-content">
			<div>
				<span class="mvf-detail__item-title mva10-u--body">
					{{ titleFecha }}
				</span>

				<span id="myOrdersSpDetailDateSpan" class="mvf-detail__item-desc mva10-u--body-bd">
					<strong id="myOrdersSpDetailDate">
						{{
							getDateByOrderState(orderData.statePedido)?.includes(forWardSlash)
								? (getDateByOrderState(orderData.statePedido) | formatDate : formatDay : formatDate) +
								  ofString +
								  (getDateByOrderState(orderData.statePedido) | formatDate : formatMonth : formatDate)
								: (getDateByOrderState(orderData.statePedido) | formatDate : formatDay : formatX) +
								  ofString +
								  (getDateByOrderState(orderData.statePedido) | formatDate : formatMonth : formatX)
						}}
					</strong>
				</span>
			</div>
		</section>

		<div
			id="myOrdersSpDetailBreakdownsContainer"
			*ngIf="
				orderData?.nombreFlujo?.toLowerCase() === flowDetail24.toLowerCase() ||
				orderData?.nombreFlujo?.toLowerCase() === flowDetail25.toLowerCase()
			"
		>
			<div class="breakDowns-section-detail" id="myOrdersSpDetailBreakdownsContainerBox">
				<img
					id="myOrdersSpDetailBreakdownsImage"
					class="imgControlDetail"
					src="{{ 'v10.myOrder.collapse.Breakdowns.img' | imgFullPath }}"
					alt="controlDetail gen1"
				/>
				<span class="mvf-collapse__item-title mva10-u--body desc-breakdowns" id="myOrdersSpDetailBreakdownsDesc">
					{{ 'v10.myOrder.collapse.Breakdowns.desc' | translate }}
				</span>
				<div class="button-content-detail" id="myOrdersSpDetailBreakdownsButtonContainer">
					<mva10-c-button
						(mva10OnClick)="goBreakdownsDetail()"
						[styleClass]="'mva10-c-button--secondary'"
						[small]="true"
					>
						<p class="extra-padding" id="myOrdersSpDetailBreakdownsTitleButton">
							{{ 'v10.myOrder.collapse.Breakdowns.button' | translate }}
						</p>
					</mva10-c-button>
				</div>
			</div>
			<hr />
		</div>
	</section>

	<!-- NEW LANDLINE PORTABILITY GUIDE-->
	<ng-container *ngIf="ordersService.detailOrderData?.fijoTemporal">
		<div class="landline-portability_cta" (click)="clickNewLandlinePortabilityGuide()">
			<span class="landline-portability_cta-image">
				<img src="{{ appService.getImgFullPath('v10.myOrder.detail.img.tutorials' | translate) }}" alt="" />
			</span>
			<span
				class="landline-portability_cta-text mva10-u--body"
				[innerHTML]="'v10.myOrder.detail.guidePortaFixButtonAccess' | translate"
			>
			</span>
			<span class="landline-portability_cta-chevron">
				<svg class="landline-portability_cta-chevron__icon" width="30px" height="30px">
					<title>{{ 'v10.myOrder.detail.chevron' | translate }}</title>
					<use class="icon-v3" xlink:href="#icon-chevron-right"></use>
				</svg>
			</span>
		</div>
		<sp-new-landline-portability-guide
			*ngIf="showLandlinePortabilityGuide"
			(closed)="showLandlinePortabilityGuide = false"
			[fijoTemporal]="ordersService.detailOrderData?.fijoTemporal"
		>
		</sp-new-landline-portability-guide>
	</ng-container>

	<!-- DETAIL CONTENT -->
	<section class="mvf-detail__content" *ngIf="ordersService.detailOrderData?.pasos?.length && !showSpinner">
		<sp-steps
			[orderData]="ordersService.detailOrderData"
			[orderLastOLStatus]="olService.orderLastOLStatus"
			(openOverlayCheckInstallation)="openOverlayCheckInstallation($event)"
			(openAutoiOverlay)="openAutoiHelp()"
			(openAutoiTicketDetail)="goToTicketDetail(TicketTypeEnum.TIA)"
			(openRouterActivationGuide)="openActivationRouterModal()"
			(openRouterInstallationGuide)="openRouterInstallationModal()"
			(openDecoInstallationGuide)="openDecoInstallationModal()"
			(openDecoGen3InstallationGuide)="openDecoInstallationModal()"
			(openHistoryOLModal)="openOrderHistoryModal()"
			(openNebaInstallationGuide)="openNebaInstallationModal()"
			(openNebaInstallationTvGuide)="openNebaInstallationTvModal()"
			(openNebaInstallationTvMulticastGuide)="openNebaInstallationTvModal()"
			(openNebaInstallationTvUnicastGuide)="openNebaInstallationTvModal()"
		>
		</sp-steps>
		<div
			class="mvf-detail__messagesDestkop"
			*ngIf="!(ordersService.detailOrderData.tipo == 6 && ordersService.detailOrderData.paso == '2')"
		>
			<div
				class="mvf-detail__messages"
				*ngIf="
					!ordersService.detailOrderData.mensajeEE &&
					ordersService.detailOrderData.mostrarMensajes &&
					!ordersService.detailOrderData.portabilidadRechazada
				"
			>
				<table>
					<caption></caption>
					<tr class="mvf-detail__messages__normal" *ngIf="ordersService.detailOrderData.mensaje">
						<th
							id=""
							*ngIf="
								ordersService.detailOrderData.activarRouter === RouterGuideType.NO_GUIDE ||
								ordersService.detailOrderData.activarRouter === RouterGuideType.NO_GUIDE_2
							"
						>
							<svg
								*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.NO_GUIDE"
								class="vfes-alert-icon icon mvf-detail-tick-icon"
								xmlns="http://www.w3.org/2000/svg "
								xmlns:xlink="http://www.w3.org/1999/xlink "
								width="24px "
								height="24px "
							>
								<title>{{ 'v10.myOrder.detail.error' | translate }}</title>
								<use class="icon-v3" xlink:href="#icon-tick-or-solved "></use>
							</svg>
							<svg
								*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.NO_GUIDE_2"
								class="vfes-alert-icon icon mvf-detail-clock-icon"
								xmlns="http://www.w3.org/2000/svg "
								xmlns:xlink="http://www.w3.org/1999/xlink "
								width="24px "
								height="24px "
							>
								<title>{{ 'v10.myOrder.detail.error' | translate }}</title>
								<use class="icon-v3" xlink:href="#icon-clock-or-timed"></use>
							</svg>
						</th>
						<td class="vfes-pb-">
							<span [innerHtml]="ordersService.detailOrderData.mensaje | trustHtml"> </span>
						</td>
					</tr>
					<tr class="mvf-detail__messages__normal transparent-background" *ngIf="ordersService.detailOrderData.urlVPT">
						<td>
							<!-- VPT signing -->
							<div class="vpt-sign">
								<span class="vpt-sign-description mva10-u--body">
									{{ 'v10.myOrder.detail.signDigitalIVPTDescription' | translate }}
								</span>
								<div class="vpt-sign-cta" (click)="startVptSignProcess(ordersService.detailOrderData)">
									<span class="vpt-sign-cta-text mva10-u--body">
										{{ 'v10.myOrder.detail.signDigitalIVPTLink' | translate }}
									</span>
									<span class="vpt-sign-cta-chevron">
										<svg class="vpt-sign-cta-chevron__icon" width="30px" height="30px">
											<title>{{ 'v10.myOrder.detail.chevron' | translate }}</title>
											<use class="icon-v3" xlink:href="#icon-chevron-right"></use>
										</svg>
									</span>
								</div>
							</div>
							<!-- ---------------------- -->
						</td>
					</tr>
				</table>
			</div>

			<!-- Mensajes de Estado de Excepción (EE) -->
			<div
				class="mvf-detail__messages__warn"
				*ngIf="ordersService.detailOrderData.mensajeEE && !ordersService.detailOrderData.warningMessage"
			>
				<sp-warn-box
					*ngIf="ordersService.detailOrderData.mensajeEE"
					[messages]="[ordersService.detailOrderData.mensajeEE]"
				>
				</sp-warn-box>
			</div>
			<div
				class="mvf-detail__messages__warn"
				*ngIf="ordersService.detailOrderData.warningMessage && !ordersService.detailOrderData.mensajeEE"
			>
				<sp-warn-box
					*ngIf="ordersService.detailOrderData.warningMessage"
					[messages]="[ordersService.detailOrderData.warningMessage]"
				>
				</sp-warn-box>
			</div>

			<!-- Pedido historico OL -->
			<div
				class="mvf-detail__messages__normal transparent-background"
				*ngIf="
					(ordersService.detailOrderData.estadool || ordersService.detailOrderData.mensajeOL) &&
					('v10.myOrder.detail.showButtonHistoricoOL' | translate) == 'true' &&
					(!ordersService.detailOrderData.mensajeEE || ordersService.detailOrderData.hasAutoInstallationCard)
				"
			>
				<div class="vfes-pb-" *ngIf="ordersService.detailOrderData.mensajeOL">
					<span class="mva10-u--body" [innerHtml]="ordersService.detailOrderData.mensajeOL | trustHtml"> </span>
				</div>
				<div>
					<div
						class="ol-history-container"
						*ngIf="
							!olService.checkingOL || !orderOLHistory || (orderOLHistory && orderOLHistory.historicoOL.length !== 0)
						"
					>
						<sp-order-status-cta
							[loading]="olService.checkingOL"
							[orderStatus]="olService.orderLastOLStatus"
							(click)="openOrderHistoryModal()"
						>
						</sp-order-status-cta>
					</div>
				</div>
			</div>
			<!-- ---------------------- -->

			<!-- Mensaje Porta Fija y Movil -->
			<div class="mvf-detail__messages__normal transparent-background" *ngIf="ordersService.detailOrderData.mensajePF">
				<span class="mva10-u--body vfes-pb-" [innerHtml]="ordersService.detailOrderData.mensajePF | trustHtml"> </span>
			</div>
			<div
				class="mvf-detail__messages__normal transparent-background vfes-pb-"
				*ngIf="ordersService.detailOrderData.mensajePM"
			>
				<span class="mva10-u--body vfes-pb-" [innerHtml]="ordersService.detailOrderData.mensajePM | trustHtml"> </span>
			</div>
			<!-- ---------------------- -->

			<div
				class="mvf-detail__messages__warn"
				*ngIf="ordersService.detailOrderData.citaPlanificada && !ordersService.detailOrderData.mensajeEE"
			>
				<sp-warn-box
					*ngIf="ordersService.detailOrderData.mensaje"
					[info]="true"
					[messages]="[ordersService.detailOrderData.mensaje]"
				>
				</sp-warn-box>
			</div>
			<div
				*ngIf="
					ordersService.detailOrderData.cambioCita > 0 &&
					ordersService.detailOrderData.activarRouter === RouterGuideType.NO_GUIDE
				"
				class="vfes-p vfes-mb vfes-align--left mvf-detail__appointment__button"
			>
				<mva10-c-button (click)="openAppointmentModal()" name="cambio cita" title="cambio cita" [appearance]="'alt1'">
					{{ botonCita }}
				</mva10-c-button>
			</div>
			<div
				*ngIf="
					(ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET ||
						ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV) &&
					ordersService.detailOrderData.cambioCita === 0 &&
					detallePedido.orderFlow !== orderFlowDetail
				"
				class="vfes-p vfes-align--left mvf-detail__appointment__button"
			>
				<mva10-c-button
					(click)="openRouterInstallationModal()"
					name="instala tu nuevo router"
					title="instala tu nuevo router"
					[appearance]="'alt1'"
				>
					{{ 'v10.myOrder.detail.installYourNewRouter' | translate }}
				</mva10-c-button>
			</div>
			<div
				*ngIf="
					ordersService.detailOrderData.activarRouter === RouterGuideType.ACTIVATE_ROUTER &&
					ordersService.detailOrderData.cambioCita === 0
				"
				class="vfes-p vfes-mb vfes-align--left mvf-detail__appointment__button"
			>
				<mva10-c-button
					(click)="openActivationRouterModal()"
					name="activar router"
					title="activar router"
					[appearance]="'alt1'"
				>
					{{
						ordersService.detailOrderData.statePedido === StatePedido.COMPLETED
							? ('v10.myOrder.detail.guideInstallRouter' | translate)
							: ('v10.myOrder.detail.activateRouter' | translate)
					}}
				</mva10-c-button>
			</div>

			<div
				*ngIf="ordersService.detailOrderData.cambiarEquipos === 1 && ordersService.detailOrderData.cambioCita === 0"
				class="vfes-p vfes-mb vfes-align--left mvf-detail__appointment__button"
			>
				<mva10-c-button
					(click)="openDecoChangeModal()"
					name="Comenzar instalación"
					title="Comenzar instalación"
					[appearance]="'alt1'"
				>
					{{ 'v10.myOrder.detail.changeDeviceStart' | translate }}
				</mva10-c-button>
			</div>

			<div
				*ngIf="
					(ordersService.detailOrderData.instalarEquipos === DecoGuideType.UPGRADE_TV ||
						ordersService.detailOrderData.instalarEquipos === DecoGuideType.UPGRADE_DECO_GEN3 ||
						ordersService.detailOrderData.activarRouter === RouterGuideType.UPGRADE_INTERNET_AND_DECO_GEN3) &&
					detallePedido.orderFlow !== orderFlowDetail
				"
				class="vfes-p vfes-mb vfes-align--left mvf-detail__appointment__button"
			>
				<mva10-c-button
					(click)="openDecoInstallationModal()"
					name="instala tu nuevo equipo de televisión"
					title="instala tu nuevo equipo de televisión"
					[appearance]="'alt1'"
				>
					{{ 'v10.myOrder.detail.installDevices' | translate }}
				</mva10-c-button>
			</div>

			<div
				id="MyOrdersSpStepsWarnAutoiContainerStep41"
				*ngIf="detallePedido.orderFlow === orderFlowDetail && hasStep41OrStep42Detail(four_one_detail)"
				class="vfes-mt vfes-colours-bg-light-white"
			>
				<div class="vfes-layout__item" id="MyOrdersSpStepsWarnAutoiCard">
					<mva10-c-alert [layout]="'horizontal'" [state]="'info'">
						<div id="MyOrdersSpStepsWarnAutoiMessageStep41" class="mva10-u--mb4">
							{{ 'v10.myOrder.orders.autoi.infoMessage' | translate }}
						</div>
						<div id="MyOrdersSpStepsWarnAutoiButtonStep41" class="vfes-align--center">
							<mva10-c-button
								id="MyOrdersSpStepsWarnAutoiButton"
								(mva10OnClick)="continueTaskAutoiDetail()"
								[appearance]="'alt1'"
							>
								{{ 'v10.myOrder.orders.autoi.infoBtn' | translate }}
							</mva10-c-button>
						</div>
					</mva10-c-alert>
				</div>
			</div>

			<div
				id="MyOrdersSpStepsCheckAutoiContainerStep42"
				*ngIf="detallePedido.orderFlow === orderFlowDetail && hasStep41OrStep42Detail(four_two_detail)"
				class="vfes-mt vfes-colours-bg-light-white vfes-align--center"
			>
				<div class="vfes-layout__item" id="MyOrdersSpStepsWarnAutoiContainer">
					<mva10-c-card [modifier]="'shadow'">
						<div id="MyOrdersSpStepsWarnAutoiMessageStep42" class="messageCardCheck">
							<img
								class="mva10-u--mb2"
								id="MyOrdersSpStepCardIcon"
								src="{{ 'v10.myOrder.orders.autoi.img.boosting' | imgFullPath }}"
								alt="icon"
							/>
							{{ 'v10.myOrder.orders.autoi.checkCard' | translate }}
						</div>
						<mva10-c-button
							id="MyOrdersSpStepsWarnAutoiButton"
							(mva10OnClick)="backDropAutoiDetail()"
							[appearance]="'primary'"
						>
							{{ 'v10.myOrder.orders.autoi.checkCardBtn' | translate }}
						</mva10-c-button>
					</mva10-c-card>
				</div>
			</div>

			<div
				*ngIf="
					ordersService.detailOrderData.statePedido === StatePedido.OPEN &&
					ordersService.detailOrderData.guiaAutoiNeba === NebaGuideType.NEBA_INTERNET
				"
				class="vfes-p vfes-mb vfes-align--left mvf-detail__appointment__button"
			>
				<mva10-c-button [appearance]="'alt1'" (mva10OnClick)="openNebaInstallationModal()">
					{{ 'v10.myOrder.detail.guideNebaInternetButtonOpen' | translate }}
				</mva10-c-button>
			</div>

			<div
				*ngIf="
					('v10.myOrder.checkInstallationOverlay.checkNebaAutoInstallationActive' | translate) === stringOfTrue &&
					slidService.showCheckInstallation &&
					orderData.statePedido === StatePedido.OPEN &&
					ordersService.detailOrderData.guiaAutoiNeba === NebaGuideType.NEBA_INTERNET
				"
			>
				<mva10-c-card class="card-recupSlid">
					<p class="card-recupSlid__text">{{ 'v10.myOrder.detail.recuperarSmartSlidText' | translate }}</p>
					<mva10-c-button (mva10OnClick)="openOverlayCheckInstallation()">
						{{ 'v10.myOrder.detail.recuperarSmartSlidButton' | translate }}
					</mva10-c-button>
				</mva10-c-card>
			</div>

			<div
				*ngIf="
					ordersService.detailOrderData.statePedido === StatePedido.OPEN &&
					(ordersService.detailOrderData.guiaAutoiNebaTv === NebaTvGuideType.NEBA_DECO_GEN3 ||
						ordersService.detailOrderData.guiaAutoiNebaTv === NebaTvGuideType.NEBA_DECO_MULTICAST ||
						ordersService.detailOrderData.guiaAutoiNebaTv === NebaTvGuideType.NEBA_DECO_UNICAST)
				"
				class="vfes-p vfes-mb vfes-align--left mvf-detail__appointment__button"
			>
				<mva10-c-button [appearance]="'alt1'" (mva10OnClick)="openNebaInstallationTvModal()">
					{{ 'v10.myOrder.detail.guideNebaTvButtonOpen' | translate }}
				</mva10-c-button>
			</div>

			<div
				*ngIf="ordersService.detailOrderData.guiaOneConecta === 1"
				class="vfes-p vfes-mb vfes-align--left mvf-detail__appointment__button"
			>
				<mva10-c-button
					(click)="openOneConectaConDecoModal()"
					name="instalar equipo one conecta con deco"
					title="instalar equipo one conecta con deco"
					[appearance]="'alt1'"
				>
					{{ 'v10.myOrder.detail.installDevices' | translate }}
				</mva10-c-button>
			</div>

			<div
				*ngIf="ordersService.detailOrderData.guiaOneConecta === 2"
				class="vfes-p vfes-mb vfes-align--left mvf-detail__appointment__button"
			>
				<mva10-c-button
					(click)="openOneConectaSinDecoModal()"
					name="instalar equipo one conecta sin deco"
					title="instalar equipo one conecta sin deco"
					[appearance]="'alt1'"
				>
					{{ 'v10.myOrder.detail.installDevices' | translate }}
				</mva10-c-button>
			</div>

			<!-- Autoinstalación -->
			<div class="vfes-installation__help vfes-mt" *ngIf="showAutoiCard">
				<sp-card-help
					[loading]="ticketsService.checkingAutoiTickets || olService.checkingOL"
					withBorder="true"
					image="{{ appService.getImgFullPath('v10.myOrder.detail.img.icroutermid' | translate) }}"
					[title]="'v10.myOrder.detail.autoiHelpCardTitle' | translate"
					[subtitle]="'v10.myOrder.detail.autoiHelpCardDescription' | translate"
					[buttonText]="'v10.myOrder.detail.autoiHelpCardButton' | translate"
					(clickHelp)="openAutoiHelp()"
					(clickDetail)="goToTicketDetail(TicketTypeEnum.TIA)"
				>
				</sp-card-help>
			</div>

			<!-- Documentacion scoring -->
			<div class="vfes-layout vfes-pt" *ngIf="ordersService.detailOrderData.scoring?.necesitaDocumentosScoring">
				<div *ngFor="let document of ordersService.detailOrderData.scoring.documentos">
					<div class="vfes-layout__item vfes-mb-">
						<svg
							class="vfes-alert-icon icon mvf-detail-clock-icon"
							xmlns="http://www.w3.org/2000/svg "
							xmlns:xlink="http://www.w3.org/1999/xlink "
							width="24px "
							height="24px "
						>
							<title>{{ 'v10.myOrder.detail.document' | translate }}</title>
							<use class="icon-v3" xlink:href="#icon-document"></use>
						</svg>
						<span> {{ 'v10.myOrder.detail.scoringNameDocument.' + document | translate }} </span>
						<svg
							(click)="showDocumentInfo(document)"
							class="vfes-alert-icon icon mvf-document-info-icon"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							width="24px"
							height="24px"
						>
							<title>{{ 'v10.myOrder.detail.info' | translate }}</title>
							<use class="icon-v3" xlink:href="#icon-info-circle"></use>
						</svg>
					</div>
				</div>
				<div
					class="vfes-layout__item vfes-mt"
					*ngIf="
						(!ordersService.isOB &&
							('v10.myOrder.detail.scoringFlagBtnShowBocs' | translate) === stringOfTrue &&
							utilsService.isIkki) ||
						ordersService.detailOrderData.scoring.necesitaDocumentosScoring !== 2
					"
				>
					<mva10-c-button
						(click)="
							ordersService.detailOrderData.scoring.necesitaDocumentosScoring === 2
								? watchScoringDocs()
								: openScoringModal()
						"
						name="documentation scoring"
						title="documentation scoring"
						[appearance]="'alt1'"
					>
						{{
							ordersService.detailOrderData.scoring.necesitaDocumentosScoring === 2
								? ('v10.myOrder.detail.ScoringBtnShow' | translate)
								: ('v10.myOrder.detail.scoringBtnAttach' | translate)
						}}
					</mva10-c-button>
				</div>
			</div>
			<!-- ---------------------- -->

			<!-- OpenBanking -->
			<div id="MyOrdersSpDetailOBButton" class="vfes-layout__item vfes-mt buttonOB" *ngIf="ordersService.isOB">
				<mva10-c-button
					(click)="clickOBDetail()"
					name="documentation scoring"
					title="documentation scoring"
					[appearance]="'alt1'"
				>
					{{ 'v10.myOrder.detail.ScoringBtnOB' | translate }}
				</mva10-c-button>
			</div>

			<!-- Rechazo portabilidad -->
			<div *ngIf="ordersService.detailOrderData.portabilidadRechazada?.tipoRechazoPortabilidad === 0">
				<sp-warn-box
					*ngIf="ordersService.detailOrderData.mensajePF"
					[isError]="true"
					[messages]="[ordersService.detailOrderData.mensajePF]"
				>
				</sp-warn-box>
				<sp-warn-box
					*ngIf="ordersService.detailOrderData.mensajePM"
					[isError]="true"
					[messages]="[ordersService.detailOrderData.mensajePM]"
				>
				</sp-warn-box>
				<br />

				<div class="mvf-steps__input">
					<div class="vfes-form__box">
						<mva10-c-text-field
							data-vfes-js="_autocomplete"
							class="vfes-form__input vfes-form__input--dropdown js-form-autocomplete vfes-form__input--selector"
							readonly
							aria-labelledby="label"
							type="text"
							id="inputDropdown"
							aria-autocomplete="both"
							aria-expanded="false"
							aria-owns="vfes-autocomplete"
							name="texto2"
							placeholder="Tipo de documento"
							autocomplete="off"
							(focus)="openDropdown()"
							(blur)="closeDropdown()"
							(change)="change()"
							[(ngModelInput)]="docType"
						>
						</mva10-c-text-field>
						<span class="vfes-form__label" id="label">
							{{ 'v10.myOrder.detail.relaunchPortaTypeDoc' | translate }}
						</span>
						<svg
							class="icon vfes-form__icon vfes-form__icon-down"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							width="32px"
							height="32px"
						>
							<title>{{ 'v10.myOrder.detail.locate' | translate }}</title>
							<use class="icon-v3" xlink:href="#icon-chevron-down"></use>
						</svg>
						<svg
							class="icon vfes-form__icon vfes-form__icon-up"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							width="32px"
							height="32px"
						>
							<title>{{ 'v10.myOrder.detail.locate' | translate }}</title>
							<use class="icon-v3" xlink:href="#icon-chevron-up"></use>
						</svg>
						<ul
							id="vfes-autocomplete"
							name="vfes-autocomplete"
							role="listbox"
							class="vfes-form__autocomplete-list js-form-autocomplete-list mvf-steps__ul"
						>
							<li
								*ngFor="let item of docOptions"
								role="option"
								class="js-form-autocomplete-list-option vfes-form__autocomplete-list-option"
								(mousedown)="selectOption(item)"
							>
								{{ item }}
							</li>
						</ul>
					</div>
				</div>

				<div class="mvf-steps__input">
					<div class="vfes-form__box">
						<mva10-c-text-field
							class="vfes-form__input vfes-form__input"
							[ngClass]="{ 'vfes-form__input--error': dniValid !== undefined && !dniValid }"
							type="text"
							aria-labelledby="label"
							id="texto2"
							name="texto2"
							placeholder="DNI no válido"
							[(ngModelInput)]="numDoc"
							(ngModelChange)="updateNumDoc($event)"
						>
						</mva10-c-text-field>
						<span class="vfes-form__label" id="label"> {{ 'v10.myOrder.detail.invalidDNI' | translate }} </span>
						<p class="vfes-form__error-text mvf-steps__input-validation">
							{{ 'v10.myOrder.detail.invalidDocument' | translate }}
						</p>
						<svg
							class="icon vfes-form__icon vfes-form__icon-warning mvf-steps__input-warning"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							width="32px"
							height="32px"
						>
							<title>{{ 'v10.myOrder.detail.warning' | translate }}</title>
							<use class="icon-v3" xlink:href="#icon-warning-esp"></use>
						</svg>
						<svg
							*ngIf="dniValid === undefined || dniValid"
							class="icon mvf-steps__input-icon"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							width="32px"
							height="32px"
						>
							<title>{{ 'v10.myOrder.detail.profile' | translate }}</title>
							<use class="icon-v3" xlink:href="#icon-profile"></use>
						</svg>
					</div>
				</div>

				<div class="mvf-steps__input">
					<div class="vfes-form__box">
						<mva10-c-text-field
							class="vfes-form__input vfes-form__input"
							[ngClass]="{ 'vfes-form__input--error': numValid !== undefined && !numValid }"
							type="text"
							aria-labelledby="label"
							id="texto2"
							name="texto2"
							placeholder="Teléfono móvil"
							(ngModelChange)="updateNumPortar($event)"
							[(ngModelInput)]="numPortar"
						>
						</mva10-c-text-field>
						<span *ngIf="ordersService.detailOrderData.mensajePM" class="vfes-form__label" id="label">
							{{ 'v10.myOrder.detail.relaunchPortaMobilePhone' | translate }}
						</span>
						<span *ngIf="ordersService.detailOrderData.mensajePF" class="vfes-form__label" id="label">
							{{ 'v10.myOrder.detail.relaunchPortaLandline' | translate }}
						</span>
						<p class="vfes-form__error-text mvf-steps__input-validation">
							{{ 'v10.myOrder.detail.relaunchPortaPhoneValidation' | translate }}
						</p>
						<svg
							class="icon vfes-form__icon vfes-form__icon-warning mvf-steps__input-warning"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							width="32px"
							height="32px"
						>
							<title>{{ 'v10.myOrder.detail.warning' | translate }}</title>
							<use class="icon-v3" xlink:href="#icon-warning-esp"></use>
						</svg>
						<svg
							*ngIf="
								(numValid === undefined || numValid) &&
								ordersService.detailOrderData.portabilidadRechazada.tipoLinea.toLowerCase() === 'movil'
							"
							class="icon mvf-steps__input-icon"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							width="32px"
							height="32px"
						>
							<title>{{ 'v10.myOrder.detail.mobile' | translate }}</title>
							<use class="icon-v3" xlink:href="#icon-mobile"></use>
						</svg>
						<svg
							*ngIf="
								(numValid === undefined || numValid) &&
								ordersService.detailOrderData.portabilidadRechazada.tipoLinea.toLowerCase() !== 'movil'
							"
							class="icon mvf-steps__input-icon"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							width="32px"
							height="32px"
						>
							<title>{{ 'v10.myOrder.detail.landline' | translate }}</title>
							<use class="icon-v3" xlink:href="#icon-business-phone"></use>
						</svg>
					</div>
				</div>
				<mva10-c-button
					[disabled]="disableButton"
					data-analytics-element=""
					data-analytics-category="cta"
					(mva10OnClick)="sendInfo()"
				>
					{{ 'v10.myOrder.detail.relaunchPortaSend' | translate }}
				</mva10-c-button>
			</div>

			<!-- Rechazo portabilidad (lapiz) -->
			<div
				*ngIf="
					ordersService.detailOrderData.portabilidadRechazada &&
					ordersService.detailOrderData.portabilidadRechazada.tipoRechazoPortabilidad > 0
				"
			>
				<sp-warn-box
					*ngIf="ordersService.detailOrderData.mensajePF"
					[isError]="true"
					[messages]="[ordersService.detailOrderData.mensajePF]"
				>
				</sp-warn-box>

				<sp-warn-box
					*ngIf="ordersService.detailOrderData.mensajePM"
					[isError]="true"
					[messages]="[ordersService.detailOrderData.mensajePM]"
				>
				</sp-warn-box>

				<div *ngIf="ordersService.detailOrderData.mensajePF">
					<p class="mvf-steps__text">{{ 'v10.myOrder.detail.relaunchPortaInfoText' | translate }}</p>
					<div class="mvf-steps__separator"></div>
					<div class="mvf-steps__title-container">
						<p class="mvf-steps__txt-title">{{ 'v10.myOrder.detail.relaunchPortaPersonalInfo' | translate }}</p>
						<svg
							class="icon mvf-steps__icon-edit"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							width="24px"
							height="24px"
							(click)="editData()"
						>
							<title>{{ 'v10.myOrder.detail.edit' | translate }}</title>
							<use class="icon-v3" xlink:href="#icon-edit"></use>
						</svg>
					</div>
					<p>{{ 'v10.myOrder.detail.relaunchPortaTypeDoc' | translate }}: {{ docType }}</p>
					<p>{{ 'v10.myOrder.detail.relaunchPortaDNI' | translate }}: {{ numDoc }}</p>
					<p>{{ 'v10.myOrder.detail.relaunchPortaPhone' | translate }}: {{ numPortar }}</p>
					<div class="mvf-steps__separator"></div>
				</div>

				<div *ngIf="ordersService.detailOrderData.mensajePM">
					<div class="mvf-steps__separator margin-top-24"></div>
					<div class="mvf-steps__title-container">
						<p
							*ngIf="ordersService.detailOrderData.portabilidadRechazada.tipoRechazoPortabilidad !== 3"
							class="mvf-steps__txt-title"
						>
							{{ 'v10.myOrder.detail.relaunchPortaPersonalInfo' | translate }}
						</p>
						<p
							class="mvf-steps__txt-title"
							*ngIf="ordersService.detailOrderData.portabilidadRechazada.codigoRechazo === 'RECH_ICCID'"
						>
							{{ 'v10.myOrder.detail.relaunchPortaInfoICCID' | translate }}
						</p>
						<svg
							*ngIf="ordersService.detailOrderData.portabilidadRechazada.tipoRechazoPortabilidad !== 3"
							class="icon mvf-steps__icon-edit"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							width="24px"
							height="24px"
							(click)="editData()"
						>
							<title>{{ 'v10.myOrder.detail.edit' | translate }}</title>
							<use class="icon-v3" xlink:href="#icon-edit"></use>
						</svg>
					</div>
					<div *ngIf="ordersService.detailOrderData.portabilidadRechazada.tipoRechazoPortabilidad !== 3">
						<p *ngIf="docType === ('v10.myOrder.detail.relaunchPortCIF' | translate)">
							{{ 'v10.myOrder.detail.relaunchPortaCompanyName' | translate }}: {{ nombre }}
						</p>
						<p *ngIf="docType !== ('v10.myOrder.detail.relaunchPortCIF' | translate)">
							{{ 'v10.myOrder.detail.relaunchPortaName' | translate }}: {{ nombre }}
						</p>
						<p *ngIf="docType !== ('v10.myOrder.detail.relaunchPortCIF' | translate)">
							{{ 'v10.myOrder.detail.relaunchPortaSurname' | translate }}: {{ apellido }}
						</p>
						<p *ngIf="docType !== ('v10.myOrder.detail.relaunchPortCIF' | translate)">
							{{ 'v10.myOrder.detail.relaunchPortaSurname2' | translate }}: {{ apellido2 }}
						</p>
						<p>{{ docType }}: {{ numDoc }}</p>
						<div class="mvf-steps__separator"></div>
					</div>
					<!-- RECH_ICCID   -->
					<div *ngIf="ordersService.detailOrderData.portabilidadRechazada.codigoRechazo === 'RECH_ICCID'">
						<p>{{ 'v10.myOrder.detail.relaunchPortaICCID' | translate }}: {{ iccid }}</p>
						<div class="mvf-steps__separator"></div>
						<p class="mvf-steps__iccid-text">{{ 'v10.myOrder.detail.relaunchPortaICCIDInfo' | translate }}</p>
						<svg
							(click)="showICCIDInfo()"
							class="vfes-alert-icon icon"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							width="24px"
							height="24px"
						>
							<title>{{ 'v10.myOrder.detail.info' | translate }}</title>
							<use class="icon-v3" xlink:href="#icon-info-circle"></use>
						</svg>
					</div>
				</div>
			</div>
			<!-- ---------------------- -->
		</div>

		<div
			class="mvf-detail__detailDesktop"
			*ngIf="ordersService.detailOrderData.devolucionesEquipos?.length > 0 && ordersService.detailOrderData.paso == '2'"
		>
			<p class="mvf-detail__titleDev">{{ 'v10.myOrder.detail.deviceWeHaveRecived' | translate }}</p>
			<div class="mvf-detail__bgEquipo" *ngFor="let equipo of ordersService.detailOrderData.devolucionesEquipos">
				<div class="mvf-detail__detailP">
					<div class="mvf-detail__detail">
						<p class="mvf-detail__subtitleDetailN">{{ equipo.numEquipo }}</p>
						<p class="mvf-detail__subtitleDetail">{{ 'v10.myOrder.detail.deviceNumber' | translate }}</p>
					</div>
					<div class="mvf-detail__detail">
						<p class="mvf-detail__subtitleDetailN">{{ equipo.codDevolucion }}</p>
						<p class="mvf-detail__subtitleDetail">{{ 'v10.myOrder.detail.returnCode' | translate }}</p>
					</div>
					<div class="mvf-detail__detail">
						<p class="mvf-detail__subtitleDetailN">
							{{ equipo.fechaLimite | date : 'dd/MM/yyyy' }}
						</p>
						<p class="mvf-detail__subtitleDetail">{{ 'v10.myOrder.detail.deadline' | translate }}</p>
					</div>
					<div class="mvf-detail__detail">
						<p class="{{ getClassByEstado(equipo.estado) }}">
							{{ equipo.estado }}
						</p>
						<p class="mvf-detail__subtitleDetail">{{ 'v10.myOrder.detail.status' | translate }}</p>
					</div>
				</div>
				<div *ngIf="equipo.estado === 'Pendiente'">
					<hr />
					<p class="mvf-detail__subtitleDetail">
						{{ 'v10.myOrder.detail.daysReturnDevice' | translate }}
						<span class="mvf-detail__subtitleDetailN" [innerHtml]="calcularDiasRestantes(equipo.fechaLimite)"> </span>
					</p>
					<p class="mvf-detail__subtitleWarn" (click)="goToInfo()">
						{{ 'v10.myOrder.detail.whatHaveReturnDevice' | translate }}
						<a>
							<svg
								class="vfes-alert-icon icon mvf-detail__info"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
							>
								<title>{{ 'v10.myOrder.detail.information' | translate }}</title>
								<use class="icon-v3" xlink:href="#icon-info-circle"></use>
							</svg>
						</a>
					</p>
				</div>
				<div *ngIf="equipo.textoDescripcion">
					<hr />
					<p class="mvf-detail__subtitleDetailRev">
						{{ equipo.textoDescripcion }}
					</p>
				</div>
			</div>
		</div>

		<div class="mvf-detail__messages__warn" *ngIf="ordersService.detailOrderData.mensajeFijo">
			<sp-warn-box [messages]="[ordersService.detailOrderData.mensajeFijo]" [fijo]="true"> </sp-warn-box>
		</div>

		<sp-summary [orderData]="ordersService.detailOrderData"> </sp-summary>
		<div *ngIf="mostrarBtnCancelarCanje" class="mvf-btn-cancelar-canje">
			<mva10-c-button [small]="true" (mva10OnClick)="openCancelTerminalModal()">
				{{ 'v10.myOrder.detail.cancel' | translate }}
			</mva10-c-button>
		</div>
		<sp-request-cancellation
			*ngIf="showCancelTerminalModal"
			(closed)="showCancelTerminalModal = false"
			(closed)="onCloseCanjeOverlay($event)"
		>
		</sp-request-cancellation>
	</section>

	<!-- Loader spinner and error -->
	<div class="mvf-detail-error" *ngIf="showError || showSpinner">
		<sp-loader [showError]="showError" [showSpinner]="showSpinner"> </sp-loader>
	</div>

	<!-- Close detail button -->
	<sp-button (click)="closeDetail.emit()" [parentClass]="'mvf-button--closeable mvf-button--closeable--mobile'">
		<span class="mvf-button__text mva10-u--body"> {{ 'v10.myOrder.detail.close' | translate }} </span>
	</sp-button>
</article>

<!-- Overlay/Modals for detail (tablet/desktop) and steps (mobile) pages -->

<sp-help-autoi-modal
	*ngIf="showOverlayAutoiHelp"
	(onClose)="closeAutoiHelpModal($event)"
	[orderStatus]="olService.orderLastOLStatus"
>
</sp-help-autoi-modal>

<sp-check-installation-overlay
	class="check-installation-overlay"
	*ngIf="showOverlayCheckInstallation"
	[serviceType]="overlaySchamanServiceType"
	[checkType]="overlaySchamanCheckType"
	(close)="closeCheckInstallationOverlay($event)"
	(closeOverlayAndOpenTvGuide)="closeOverlayCheckInstallationAndOpenTvGuide()"
	(closeTvGuide)="closeAutoiGuide($event)"
>
</sp-check-installation-overlay>

<sp-order-history-modal *ngIf="showHistoryOLOverlay" (onClose)="closeHistoryOLOverlay()"> </sp-order-history-modal>

<!-- Autoi guides -->
<sp-activate-router *ngIf="showRouterActivationGuide" (onClose)="closeAutoiGuide($event)"> </sp-activate-router>

<sp-router-installation
	*ngIf="showRouterInstallationGuide"
	[withActivation]="checkInstallationInGuide"
	(onClose)="closeAutoiGuide($event)"
>
</sp-router-installation>

<sp-deco-installation
	*ngIf="showDecoInstallationGuide"
	[withActivation]="checkInstallationInGuide"
	(onClose)="closeAutoiGuide($event)"
>
</sp-deco-installation>

<sp-gen3-installation
	*ngIf="showDecoGen3InstallationGuide"
	[withActivation]="checkInstallationInGuide"
	(onClose)="closeAutoiGuide($event)"
>
</sp-gen3-installation>

<sp-neba-installation *ngIf="showNebaInstallationGuide" (onClose)="closeAutoiGuide($event)"> </sp-neba-installation>

<sp-neba-installation-tv
	*ngIf="showNebaInstallationTvGuide"
	(onClose)="closeAutoiGuide($event)"
	(openOverlayCheckInstallation)="openOverlayCheckInstallation($event)"
>
</sp-neba-installation-tv>

<sp-neba-installation-tv-multicast
	*ngIf="showNebaInstallationTvMulticastGuide"
	(onClose)="closeAutoiGuide($event)"
	(openOverlayCheckInstallation)="openOverlayCheckInstallation($event)"
>
</sp-neba-installation-tv-multicast>

<sp-neba-installation-tv-unicast
	*ngIf="showNebaInstallationTvUnicastGuide"
	(onClose)="closeAutoiGuide($event)"
	(openOverlayCheckInstallation)="openOverlayCheckInstallation($event)"
>
</sp-neba-installation-tv-unicast>

<sp-view-desktop *ngIf="showNebaDesktopOverlay" (onClose)="closeAutoiGuide($event)"> </sp-view-desktop>
<vfac-overlay-full-screen
	*ngIf="showDetailOverlayFullScreenModal"
	[show]="myOrdersService.showResponseBox"
	[overlayFullScreenData]="overlayFullScreenData"
	(closeBtnClicked)="myOrdersService.dataResponseBox.buttonClick(); closeModal()"
>
	<sp-response-box
		[loading]="myOrdersService.loadingResponseBox"
		[isPort]="myOrdersService.isPortResponseBox"
		[type]="myOrdersService.typeResponseBox"
		[genericError]="myOrdersService.genericErrorResponseBox"
		[isChange]="myOrdersService.isChangeResponseBox"
		[codigoRechazo]="myOrdersService.codigoRechazoResponseBox"
		[overlayData]="myOrdersService.dataResponseBox"
		[isModal]="true"
	>
	</sp-response-box>
</vfac-overlay-full-screen>

<vfac-overlay
	[show]="showBackDropOBDetail"
	[overlayData]="xrContentsOverlayDataDetail"
	[loaded]="true"
	[zIndex]="24"
	id="MyOrdersSpDetailBackdropOB"
	(closed)="closeOBDetail()"
	(primaryBtnClicked)="clickButtonBackdropDetail()"
	[showButtons]="true"
>
	<div body>
		<div class="imgContent">
			<div class="headerImage">
				<img
					[src]="'v10.myOrder.detail.backdropOB.imgTitle' | imgFullPath"
					width="80"
					height="80"
					id="MyOrdersSpDetailBackdropOBImgHeader"
					alt="imgTitle"
				/>
			</div>
		</div>
		<div class="titleContent">
			<div class="headerTitle">
				<h3 class="mva10-u--mb4x bold-font" id="MyOrdersSpDetailBackdropOBTitle">
					{{ 'v10.myOrder.detail.backdropOB.title' | translate }}
				</h3>
			</div>
		</div>
		<div class="bodyOBContent">
			<p id="MyOrdersSpDetailBackdropOBTitleContent">
				{{ 'v10.myOrder.detail.backdropOB.subtitle' | translate }}
			</p>
			<p id="MyOrdersSpDetailBackdropOBSubtitleContent">
				{{ 'v10.myOrder.detail.backdropOB.titleContent' | translate }}
			</p>
			<span
				id="MyOrdersSpDetailBackdropOBContent"
				class="OBContentList list-disc"
				[innerHtml]="'v10.myOrder.detail.backdropOB.content' | translate"
			>
			</span>
			<div class="containerCardOB">
				<mva10-c-card [colorBg]="'light-grey'" [selected]="false" [paddingMobile]="'mini'">
					<div class="cardOB">
						<div class="cardOBImg">
							<img
								[src]="'v10.myOrder.detail.backdropOB.imgAlert' | imgFullPath"
								width="40"
								height="40"
								id="MyOrdersSpDetailBackdropOBCardOBImg"
								alt="imgAlert"
							/>
						</div>
						<div class="cardOBText">
							<span
								[innerHtml]="'v10.myOrder.detail.backdropOB.alert' | translate"
								id="MyOrdersSpDetailBackdropOBDescriptionBox"
							>
							</span>
						</div>
					</div>
				</mva10-c-card>
			</div>
		</div>
	</div>
</vfac-overlay>
<sp-modal-full #modalKoDetail [hideX]="false">
	<div id="MyOrdersSpDetailmodalKoIconContainer" class="modalKoIconContainer mva10-u--mb5">
		<img
			id="MyOrdersSpDetailmodalKoIcon"
			class="modalKoIcon"
			src="{{ 'v10.myOrder.orders.returns.sumary.modalKo.image' | imgFullPath }}"
			alt="control gen1"
		/>
	</div>
	<div id="MyOrdersSpDetailmodalKoBody" class="modalKoBodyContainer vfes-align--center">
		<p id="myOrdersmodalKoHeader" class="modalKoTitle">
			{{ 'v10.myOrder.orders.returns.sumary.modalKo.title' | translate }}
		</p>
		<p id="MyOrdersSpDetailmodalKoBodyTitle" class="modalKoBodyTitle">
			{{ 'v10.myOrder.orders.autoi.modalKoMessage' | translate }}
		</p>
	</div>
</sp-modal-full>

<vfac-overlay
	id="MyOrdersSpDetailBackDropAutoi"
	[overlayData]="'overlayDataDetail'"
	[show]="openBackDropDetail"
	[loaded]="true"
	(closed)="closeOverlayDetail()"
>
	<div body class="backDropContainer">
		<div id="MyOrdersSpDetailBackDropIconContainer" class="backDropIconContainer">
			<img
				id="MyOrdersSpDetailBackDropIcon"
				class="backDropIcon"
				src="{{ 'v10.myOrder.orders.autoi.img.backDropIcon' | imgFullPath }}"
				alt="icon backdrop"
			/>
		</div>
		<div id="MyOrdersSpDetailBackDropDescContainer" class="descContainer">
			<p id="MyOrdersSpDetailBackDropTitle" class="backDropBodyTitle">
				{{ 'v10.myOrder.orders.autoi.backDropTitle' | translate }}
			</p>
			<p id="MyOrdersSpDetailBackDropSubTitle" class="backDropBodySubTitle">
				{{ 'v10.myOrder.orders.autoi.backDropSubTitle' | translate }}
			</p>
		</div>
		<div id="MyOrdersSpDetailBackDropButtonContainer" class="buttonContainer">
			<div id="MyOrdersSpDetailBackDropButtonContainerOk" class="mva10-u--mb4">
				<mva10-c-button (mva10OnClick)="clickOkDetail()" [appearance]="'primary'">
					<p id="MyOrdersSpDetailBackDropOkBtn">
						{{ 'v10.myOrder.orders.autoi.backDropBtnOk' | translate }}
					</p>
				</mva10-c-button>
			</div>
			<div id="MyOrdersSpDetailBackDropButtonContainerNoOk">
				<mva10-c-button (mva10OnClick)="clickNoOkDetail()" [appearance]="'alt1'">
					<p id="MyOrdersSpDetailBackDropNoOkBtn">
						{{ 'v10.myOrder.orders.autoi.backDropBtnGuide' | translate }}
					</p>
				</mva10-c-button>
			</div>
		</div>
	</div>
</vfac-overlay>

<sp-check-installation-overlay
	*ngIf="showOverlayCheckInstallationDetail"
	(close)="closeCheckInstallationOverlayDetail($event)"
>
</sp-check-installation-overlay>
<!-- END of Overlay/Modals -->
