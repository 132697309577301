<div class="vfes-alert vfes-alert--info mvf-warn-box" role="alert" *ngFor="let message of messages">
	<div
		class="vfes-alert-icon-box"
		[ngStyle]="{ 'background-color': info ? '#009900' : isError ? '#E40015' : fijo ? '#fff' : null }"
	>
		<div class="icon-container" *ngIf="!info && !isError">
			<svg class="vfes-info-icon icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
				<title>{{ 'v10.myOrder.warnBox.titleInfo' | translate }}</title>
				<use class="icon-v3" xlink:href="#icon-info-circle-esp"></use>
			</svg>
		</div>
		<div class="icon-container" *ngIf="info">
			<svg class="vfes-ok-icon icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
				<title>{{ 'v10.myOrder.warnBox.titleTick' | translate }}</title>
				<use class="icon-v3" xlink:href="#icon-tick-or-solved_esp"></use>
			</svg>
		</div>
		<div class="icon-container" *ngIf="!info && isError">
			<svg class="vfes-warn-icon icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
				<title>{{ 'v10.myOrder.warnBox.error' | translate }}</title>
				<use class="icon-v3" xlink:href="#icon-warning-esp"></use>
			</svg>
		</div>
	</div>
	<div
		class="vfes-alert-body mvf-warn-box-body"
		[ngStyle]="{
			border: info ? '1px solid #009900' : isError ? '1px solid #E40015' : fijo ? 'none' : null,
			padding: fijo ? '5px 10px' : '9px 13px 9px 8px'
		}"
	>
		<p class="vfes-paragraph vfes-paragraph--small">
			<!-- CONTENIDO ALARMA -->
			<span [innerHtml]="message | trustHtml" [ngStyle]="fijo && { 'font-size': '14px', 'color': '#666666' }"> </span>
			<!-- FIN CONTENIDO ALARMA -->
		</p>
	</div>
</div>

<div class="vfes-alert vfes-alert--info mvf-warn-box" role="alert" *ngIf="text">
	<div class="vfes-alert-icon-box">
		<svg class="vfes-info-icon icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
			<title>{{ 'v10.myOrder.warnBox.titleInfo' | translate }}</title>
			<use class="icon-v3" xlink:href="#icon-info-circle-esp"></use>
		</svg>
	</div>
	<div class="vfes-alert-body mvf-warn-box-body">
		<p class="vfes-paragraph vfes-paragraph--small">
			<span *ngIf="text" [innerHtml]="text | trustHtml"> </span>
			<!-- FIN CONTENIDO ALARMA -->
		</p>
	</div>
</div>
