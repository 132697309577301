import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { API_URLS } from '../constants/routes-config';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { SubscriptionService } from '../../core/services/subscription.service';
import { throwError, Observable } from 'rxjs';
import { CustomerAccountService } from './customer-account.service';
import { SmartPayGetPaymentListModel } from './../../models/smart-pay-get-payment.model';
import { SmartPayCreatePaymentModel } from './../../models/smart-pay-create-payment.model';
import { SmartPayTopUpHistoryListModel } from 'src/app/models/smart-pay-top-up-history.model';

@Injectable()
export class SmartPayPaymentService {
	public userName: string;
	public msisdn: string;
	constructor(
		private http: HttpClient,
		public subscriptionService: SubscriptionService,
		public customerAccountService: CustomerAccountService
	) {}
	getSmartPayPayment(msisdn: string, userName: string): Observable<SmartPayGetPaymentListModel> {
		const url: string = API_URLS.SmartPayPayment.getPayment.replace('{msisdn}', msisdn);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('X-VF-API-Process', 'ConsultaSmartPay');
		headers = headers.append('Content-Type', 'application/json');
		const options: Object = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res: SmartPayGetPaymentListModel) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	activateSmartPayPayment(data: SmartPayCreatePaymentModel, isSmartPay: boolean): Observable<any> {
		const url: string = API_URLS.SmartPayPayment.createPayment;
		let headers: HttpHeaders = new HttpHeaders();
		if (isSmartPay) {
			headers = headers.append('X-VF-API-Process', 'CambioTarjetaSmartPay');
		} else {
			headers = headers.append('X-VF-API-Process', 'AltaSmartPay');
		}
		headers = headers.append('Content-Type', 'application/json');
		const options: Object = {
			headers: headers,
		};
		const dataToSend: SmartPayCreatePaymentModel = data;
		return this.http.post(url, dataToSend, options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	deleteSmartPayPayment(msisdn: string): Observable<any> {
		const url: string = API_URLS.SmartPayPayment.deletePayment.replace('{msisdn}', msisdn);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('X-VF-API-Process', 'BajaSmartPay');
		headers = headers.append('Content-Type', 'application/json');
		const options: Object = {
			headers: headers,
		};
		return this.http.delete(url, options).pipe(
			map((res: any) => {
				return res;
			})
		);
	}

	getSmartPayTopUpHistory(
		initialDate: string,
		finalDate: string,
		currentSiteId: string,
		currentServiceId: string
	): Observable<SmartPayTopUpHistoryListModel> {
		const url: string = API_URLS.SmartPayPayment.getTopUpHistory
			.replace('{initialDate}', initialDate)
			.replace('{finalDate}', finalDate)
			.replace('{currentSiteId}', currentSiteId)
			.replace('{currentServiceId}', currentServiceId);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('X-VF-API-Process', 'ConsultaMovimientosPUC');
		headers = headers.append('Content-Type', 'application/json');
		const options: Object = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res: SmartPayTopUpHistoryListModel) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}
}
