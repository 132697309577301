import { TrueFalse, BackButtons } from './../enums/backButtons.enum';
import { map } from 'rxjs/operators';
import { AditionalLines } from './../../models/aditional-lines.model';
import { ServiceKeepWrap } from './../../models/service-keep-wrap.model';
import { OfferModel } from './../../models/offer.model';
import { ProductGroup } from './../enums/product-group.enum';
import { UserPermissions } from './../enums/user-permissions.enum';
import { TryAndBuyOverlayPackage } from './../enums/try-and-buy.enum';
import { DiscountModel } from './../../models/discount.model';
import { ServiceProductModel } from '../../models/service-product.model';
import { JSON_PATHS, LOCAL_STORAGE_KEYS, isUnlimited, codesOutComparator } from './../constants/defines';
import { CombinationModel } from './../../models/combination.model';
import { ServiceKeepModel } from './../../models/service-keep.model';
import { Injectable } from '@angular/core';
import { API_URLS } from '../constants/routes-config';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { HttpHeaders } from '@angular/common/http';
import * as JsonQuery from 'jsonpath/jsonpath';
import { PackageServiceModel } from '../../models/package-service.model';
import { ProductModel } from '../../models/product-composition.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServiceType } from '../enums/serviceType.enum';
import { AttrModel } from '../../models/attr.model';
import { TranslateService } from '@ngx-translate/core';
import { CommitmentModel } from '../../models/commitments.model';
import { CilckReturnModel } from '../../models/clickReturn.model';
import { UtilitiesService } from '../utils/utilities.service';
import { CustomerType } from '../enums/customerType.enum';
import { MyAccountService } from '../../my-account/my-account.service';
import { tagging } from './../../../config/tagging-config';
import { TryAndBuyBody } from './../../models/tryAndBuyBody.model';
import { TryAndBuyResponse } from 'src/app/models/tryAndBuyResponse.model';
import { TryAndBuySetTaggingService } from './../../try-and-buy/try-and-buy-set-tagging.service';
import { OneProfesionalService } from '../../op-products/one-profesional.service';
@Injectable()
export class TryAndBuyService {
	public keepsViewModel: ServiceKeepWrap[];

	public compareModel = {};

	public offers: OfferModel[];
	public currentProduct: ProductModel;

	// misisdn selector
	public identifierOptions: any[] = [];

	// Offers observable
	private offerSubject = new BehaviorSubject(null);
	public offers$ = this.offerSubject.asObservable();

	public isCTC: boolean = false;

	// CurrentProduct observable
	private currentProductSubject = new BehaviorSubject(null);
	public currentProduct$ = this.currentProductSubject.asObservable();

	// comparator model observalbe
	private comparationSubject = new BehaviorSubject(null);
	public comparationModel$ = this.comparationSubject.asObservable();

	// keeps model observable
	private keeps: any[];
	private keepsSubject = new BehaviorSubject(null);
	public keepsModel$ = this.keepsSubject.asObservable();
	public keepTotalAmount = { fee: 0, netFee: 0 };

	public unlimitedLit;
	public selectedLines: AditionalLines[] = [];
	public allContracts;
	public tbStep: number = 0;
	public tbBreadcrumb: number = 0;
	public step: number = 0;
	public breadcrumbStep: number = -1;
	public isNewForm: boolean = false;
	public isResume: boolean = false;
	public isProcessOut: boolean = false;
	public overlayShow: boolean = false;
	public modeBuy: boolean = false;
	public tobBarTitle: string;
	public tobBarTitleTAB: string;
	public directBuy: boolean = false;
	public modeBuyFromTry: boolean = false;
	public hasPermissionToBuy: boolean = true;
	public showPermissionToBuyLabel: boolean = false;
	public hasBuyRequest: boolean = false;
	public actTabTitleDesc: string;
	public typeReco: string;

	constructor(
		private myAccountService: MyAccountService,
		public http: HttpClient,
		private storageService: StorageService,
		private subscriptionService: SubscriptionService,
		private translate: TranslateService,
		private utilitiesService: UtilitiesService,
		private tryAndBuySetTaggingService: TryAndBuySetTaggingService,
		private oneProfesionalService: OneProfesionalService
	) {}

	/**
	 * Fetch All products of the current site and current service
	 */
	GetProductAndRecommendations() {
		// TODO Reemplazar por el end point definitivo
		const url = API_URLS.TryAndBuy.getTryAndBuy.replace(
			'{customerAccountId}',
			this.subscriptionService.customerData.customerAccountsId
		);

		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		const options = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res: any) => {
				return res;
			})
		);
	}

	getTagging() {
		let status_benefits = this.storageService.productAndServiceVariables.status_benefits;

		if (!status_benefits) {
			status_benefits = tagging.staticVariables.benefitsStatus.unknown;
		}

		this.myAccountService.setPlanCommitmentforMyaccount(this.allContracts);
	}

	SendTicket(body: any) {
		const url = API_URLS.Ticket.post;

		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options = {
			headers: headers,
		};

		return this.http.post(url, body, options).pipe(
			map((res) => {
				return res;
			})
		);
	}

	SendTicketTryAndBuy(bodyTryAndBuy: TryAndBuyBody): Observable<TryAndBuyResponse> {
		const url: string = API_URLS.TicketTryAndBuy.patch;

		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options: { headers: HttpHeaders } = {
			headers: headers,
		};
		return this.http.patch<TryAndBuyResponse>(url, bodyTryAndBuy, options).pipe(
			map((res) => {
				return res;
			})
		);
	}

	public setServiceId(currentValue, newValue) {
		Object.keys(this.compareModel).forEach((key) => {
			if (this.isMobile(this.compareModel[key].serviceType)) {
				this.compareModel[key].offered.forEach((service) => {
					if (service.id === currentValue) {
						service.id = newValue;
					} else if (service.id === newValue) {
						service.id = currentValue;
					}
				});
			}
		});

		this.offers.forEach((offer) => {
			if (offer.product.packages.services && offer.product.packages.services.length > 0) {
				offer.product.packages.services.forEach((service) => {
					this.updateServiceId(service, currentValue, newValue);
				});
			}

			if (offer.product.services && offer.product.services.length > 0) {
				offer.product.services.forEach((service) => {
					this.updateServiceId(service, currentValue, newValue);
				});
			}
		});

		// call keeps login to know if extras or others has been excluded
		this.keepsLogic(this.keeps);

		this.offerSubject.next(this.offers);
		this.keepsSubject.next(this.keepsViewModel);
	}

	private updateServiceId(service: ServiceProductModel, currentValue: string, newValue: string) {
		if (this.isMobile(service.type)) {
			if (service.id === currentValue) {
				service.id = newValue;
			} else if (service.id === newValue) {
				service.id = currentValue;
			}
		}
	}

	private comparatorModelLogic() {
		// Comparator model bussines logic
		this.compareModel = {};
		this.comparatorLogic('current', this.currentProduct);
		if (this.offers && this.offers.length > 0) {
			this.comparatorLogic('offered', this.offers[0].product);
		}
		this.comparationSubject.next(this.compareModel);
	}

	/**
	 * Method for model current product and offered product for comparisson
	 * @param entity {string} current products or offered product
	 * @param model {ProductModel} to extract services
	 */
	private comparatorLogic(entity, model) {
		if (model.packages && model.packages.services) {
			model.packages.services.map((service) => {
				if (!codesOutComparator.includes(service.code)) {
					this.serviceComparatorLogic(service, entity, ProductGroup.PACKAGE);
				}
			});
		}

		if (model.services) {
			model.services.map((service) => {
				if (!codesOutComparator.includes(service.code)) {
					this.serviceComparatorLogic(service, entity, ProductGroup.SERVICE);
				}
			});
		}
	}

	/**
	 * Method for evaluate te service type and set as current or offered product
	 * @param service {string} service to evaluate attrs
	 * @param entity {ServiceProductModel} to evaluate type a set in hashset
	 * @param type {string} current or offered product string, indicate the object to set
	 */
	private serviceComparatorLogic(service: ServiceProductModel, entity, type) {
		// Template variables
		const serviceType = service.type.toUpperCase();
		const id = service.id;

		// Key value composition
		const keyValue = `${serviceType}_${id}_${type}`;

		// Check if pair key-object<current, offered> exists
		if (!this.compareModel[keyValue]) {
			this.compareModel[keyValue] = {};
		}

		// Check if attrs array exists and create if not
		if (!this.compareModel[keyValue][entity]) {
			this.compareModel[keyValue].serviceType = serviceType;
			this.compareModel[keyValue].group = type;
			this.compareModel[keyValue][entity] = [];
			if (serviceType && serviceType.toLowerCase() === ServiceType.Tv.toLowerCase()) {
				this.translate.get('dashboard.contentList.serviceSelector.servicesList.tv.tv_title').subscribe((tvMessage) => {
					this.compareModel[keyValue]['name' + entity] = tvMessage;
				});
			} else {
				this.compareModel[keyValue]['name' + entity] = service.name;
			}
		}

		// Set attrs on current or offered pendind entity strind by keyValue
		service.attrs.forEach((attr) => {
			if (this.isMobile(service.type) && attr.type !== 'data') {
				// TODO
			} else {
				const attrView: any = {};
				attrView.type = attr.type;
				attrView.id = this.isMobile(service.type) || this.isMBB(service.type) ? service.id : null;
				attrView.value = attr.value;
				attrView.units = attr.units;
				attrView.label = this.calculateLabel(service, attr);
				attrView.code = service.code;
				attrView.subtitle = this.calculateSubtitle(service, attr);

				this.compareModel[keyValue][entity].push(attrView);

				if (this.isMobile(service.type)) {
					const opt = { text: service.id, value: service.id };

					let matchItem = false;
					this.identifierOptions.forEach((item) => {
						if (item.value === service.id) {
							matchItem = true;
						}
					});

					if (!matchItem) {
						this.identifierOptions.push(opt);
					}
				}
			}
		});
		// TODO: Improve using diccionary
		this.compareModel[keyValue][entity].sort();
	}

	private calculateSubtitle(service: ServiceProductModel, attr: AttrModel) {
		let label = '';
		if (service.type.toLowerCase() === ServiceType.Tv.toLowerCase()) {
			label += attr.value;
			label += attr.units ? ' ' + attr.units : '';
		}
		return label;
	}

	private currentProductLogic(product) {
		this.currentProduct = new ProductModel();

		if (product) {
			this.currentProduct.subscriptionsAmount =
				JsonQuery.value(product, JSON_PATHS.Product.subscriptionsAmount) || null;
			this.currentProduct.subscriptionsNetAmount =
				JsonQuery.value(product, JSON_PATHS.Product.subscriptionsNetAmount) || null;

			if (product.services) {
				const services = new Array<ServiceProductModel>();

				product.services.map((item) => {
					services.push(this.jsonToServiceModel(item));
				});

				this.currentProduct.services = services;
			}

			if (product.package) {
				this.currentProduct.packages = this.jsonToPackageModel(product.package);
			}
		}

		// Emit observable valu
		this.currentProductSubject.next(this.currentProduct);
	}

	private recommendedOffersLogic(offers) {
		this.offers = new Array<OfferModel>();

		if (offers) {
			offers.map((item) => {
				const offer = this.jsonToOfferModel(item);
				this.offers.push(offer);
			});
			this.offerSubject.next(this.offers);
		}
	}

	/**
	 * Method to parse keeps json to view model
	 */
	private keepsLogic(keeps) {
		this.keepsViewModel = new Array<ServiceKeepWrap>();

		// Group keeps by serviceId
		const keepsDiccionary = [];
		if (keeps) {
			keeps.map((item) => {
				const idetify = JsonQuery.value(item, JSON_PATHS.Service.id) || null;
				if (!keepsDiccionary[idetify]) {
					keepsDiccionary[idetify] = [];
				}
				keepsDiccionary[idetify].push(this.jsonToServiceKeepModel(item));
			});

			// Model as ServiceKeepWrap[]
			Object.keys(keepsDiccionary).forEach((key) => {
				const keepServiceWrap = new ServiceKeepWrap();

				keepServiceWrap.id = key;
				const pckOrder = this.getPriorityAsPackage(key);

				keepServiceWrap.serviceType = this.getServiceType(key);
				keepServiceWrap.serviceName = this.getServiceName(keepServiceWrap.serviceType);
				keepServiceWrap.img = this.utilitiesService.setServiceIcon(keepServiceWrap.serviceType);
				keepServiceWrap.order = `${pckOrder}_${this.getOrderByType(keepServiceWrap.serviceType, key)}`;

				this.keepsViewModel.push(keepServiceWrap);
			});

			this.keepTotalAmount.fee = this.keepTotalAmount.netFee = 0;
			this.keepsViewModel.forEach((service) => {
				service.extras.forEach((extra) => {
					this.keepTotalAmount.fee += extra.fee ? parseFloat(extra.fee) : 0;
					this.keepTotalAmount.netFee += extra.netFee ? parseFloat(extra.netFee) : 0;
				});
				service.others.forEach((other) => {
					this.keepTotalAmount.fee += other.fee ? parseFloat(other.fee) : 0;
					this.keepTotalAmount.netFee += other.netFee ? parseFloat(other.netFee) : 0;
				});
			});

			// Order keeps
			this.keepsViewModel.sort(this.utilitiesService.sortByAttr.bind({ sortField: 'order' }));

			this.keepsSubject.next(this.keepsViewModel);
		}
	}

	/**
	 * Method that return order index for service type
	 * @return {string} order key
	 */
	private getOrderByType(serviceType: string, id: string) {
		let order;
		switch (serviceType.toLowerCase()) {
			case ServiceType.Mobile.toLowerCase():
			case ServiceType.Postpaid.toLowerCase():
			case ServiceType.Prepaid.toLowerCase():
				order = TryAndBuyOverlayPackage.first;
				break;
			case ServiceType.Internet.toLowerCase():
			case ServiceType.Fibre.toLowerCase():
				order = TryAndBuyOverlayPackage.second;
				break;
			case ServiceType.Landline.toLowerCase():
				order = TryAndBuyOverlayPackage.third;
				break;
			case ServiceType.MbbPostpaid.toLowerCase():
			case ServiceType.MbbPrepaid.toLowerCase():
				order = TryAndBuyOverlayPackage.forth;
				break;
			case ServiceType.Tv.toLowerCase():
				order = TryAndBuyOverlayPackage.fifth;
				break;
		}
		return `${order}_${id}`;
	}

	/**
	 * Method to get the serviceType from an id
	 * @param id
	 */
	public getServiceType(id: string): string {
		let idPack;
		let idServ;
		if (
			this.currentProduct.packages &&
			this.currentProduct.packages.services &&
			this.currentProduct.packages.services.length > 0
		) {
			idPack = this.currentProduct.packages.services.filter((num) => num.id === id)[0];
		}
		if (this.currentProduct.services && this.currentProduct.services.length > 0) {
			idServ = this.currentProduct.services.filter((num) => num.id === id)[0];
		}
		return idPack ? idPack.type.toLowerCase() : idServ ? idServ.type.toLowerCase() : '';
	}

	/**
	 * Method to get the serviceName from serviceType
	 * @param serviceType
	 */
	public getServiceName(serviceType: string): string {
		let serviceName: string;
		switch (serviceType) {
			case ServiceType.Tv.toLowerCase():
				this.translate
					.get('migration.tarifa.tarifa_mantiene.itemList.lit_mantiene_tele_titulo.body')
					.subscribe((data) => {
						serviceName = data;
					});
				break;
			case ServiceType.Mobile.toLowerCase():
			case ServiceType.Postpaid.toLowerCase():
			case ServiceType.Prepaid.toLowerCase():
				this.translate
					.get('migration.tarifa.tarifa_mantiene.itemList.lit_mantiene_movil_titulo.body')
					.subscribe((data) => {
						serviceName = data;
					});
				break;
			case ServiceType.Landline.toLowerCase():
				this.translate
					.get('migration.tarifa.tarifa_mantiene.itemList.lit_mantiene_fijo_titulo.body')
					.subscribe((data) => {
						serviceName = data;
					});
				break;
			case ServiceType.Internet.toLowerCase():
			case ServiceType.Fibre.toLowerCase():
				this.translate
					.get('migration.tarifa.tarifa_mantiene.itemList.lit_mantiene_fibra_titulo.body')
					.subscribe((data) => {
						serviceName = data;
					});
				break;
			case ServiceType.MbbPostpaid.toLowerCase():
			case ServiceType.MbbPrepaid.toLowerCase():
				this.translate
					.get('migration.tarifa.tarifa_mantiene.itemList.lit_mantiene_mbb_titulo.body')
					.subscribe((data) => {
						serviceName = data;
					});
				break;
			default:
				serviceName = '';
				break;
		}
		return serviceName;
	}

	private jsonToOfferModel(item) {
		const offer = new OfferModel();

		offer.id = JsonQuery.value(item, JSON_PATHS.Offer.id) || null;
		offer.typeReco = JsonQuery.value(item, JSON_PATHS.Offer.typeReco) || null;
		offer.codReco = JsonQuery.value(item, JSON_PATHS.Offer.codReco) || null;
		offer.codTax = JsonQuery.value(item, JSON_PATHS.Offer.codTax) || null;
		offer.offerTotalAmount = JsonQuery.value(item, JSON_PATHS.Offer.offerTotalAmount) || null;
		offer.offerNetAmount = JsonQuery.value(item, JSON_PATHS.Offer.offerNetAmount) || null;
		offer.hasAgent = JsonQuery.value(item, JSON_PATHS.Offer.hasAgent) || null;

		if (!item.evaluatedOffers.package) {
			item.evaluatedOffers.package = {};
		}

		// Package bussines logic
		if (item.evaluatedOffers.package) {
			offer.product = new ProductModel();
			offer.product.packages = this.jsonToPackageModel(item.evaluatedOffers.package);
		}

		if (item.discounts) {
			offer.discounts = new Array<DiscountModel>();
			item.discounts.map((discount) => {
				offer.discounts.push(this.jsonToDiscount(discount));
			});
		}

		if (item.commitments) {
			offer.commitments = new Array<CommitmentModel>();
			item.commitments.map((commitment) => {
				offer.commitments.push(this.jsonToCommitmentModel(commitment));
			});
		}

		// Services bussines logic
		if (item.evaluatedOffers.services) {
			offer.product.services = new Array<ServiceProductModel>();
			item.evaluatedOffers.services.map((serv) => {
				offer.product.services.push(this.jsonToServiceModel(serv));
			});

			offer.product.services.sort(this.utilitiesService.sortByAttr.bind({ sortField: 'order' }));
		}

		return offer;
	}

	private jsonToCommitmentModel(commitment: any) {
		const model = new CommitmentModel();
		model.type = JsonQuery.value(commitment, JSON_PATHS.Commitment.type) || null;
		model.commIniDate = JsonQuery.value(commitment, JSON_PATHS.Commitment.commIniDate) || null;
		model.commEndDate = JsonQuery.value(commitment, JSON_PATHS.Commitment.commEndDate) || null;
		model.commDuration = JsonQuery.value(commitment, JSON_PATHS.Commitment.commDuration) || null;
		model.penaltyAmount = JsonQuery.value(commitment, JSON_PATHS.Commitment.penaltyAmount) || null;
		model.inService = JsonQuery.value(commitment, JSON_PATHS.Commitment.inService) || null;
		return model;
	}

	private jsonToPackageModel(packageObj: any) {
		const model = new PackageServiceModel();
		model.code = JsonQuery.value(packageObj, JSON_PATHS.Service.code) || null;
		model.fee = JsonQuery.value(packageObj, JSON_PATHS.Service.fee) || null;
		model.name = JsonQuery.value(packageObj, JSON_PATHS.Service.name) || null;
		model.longDescription = JsonQuery.value(packageObj, JSON_PATHS.Service.longDescription) || null;
		model.netFee = JsonQuery.value(packageObj, JSON_PATHS.Service.netFee) || null;
		model.type = JsonQuery.value(packageObj, JSON_PATHS.Service.type) || null;

		if (packageObj.services) {
			model.services = new Array<ServiceProductModel>();
			packageObj.services.map((serv) => {
				model.services.push(this.jsonToServiceModel(serv));
			});

			model.services.sort(this.utilitiesService.sortByAttr.bind({ sortField: 'order' }));
		}

		return model;
	}

	/**
	 * Method to transform service to ServiceRecommendationModel
	 * @param service any service from json response
	 */
	private jsonToServiceModel(service: any) {
		const model = new ServiceProductModel();
		model.code = JsonQuery.value(service, JSON_PATHS.Service.code) || null;
		model.fee = JsonQuery.value(service, JSON_PATHS.Service.fee) || null;
		model.id = JsonQuery.value(service, JSON_PATHS.Service.id) || null;
		model.name = JsonQuery.value(service, JSON_PATHS.Service.name) || null;
		model.longDescription = JsonQuery.value(service, JSON_PATHS.Service.longDescription) || null;
		model.netFee = JsonQuery.value(service, JSON_PATHS.Service.netFee) || null;
		model.type = JsonQuery.value(service, JSON_PATHS.Service.type) || null;
		model.order = this.getOrderByType(model.type, model.id);

		if (service.attrs) {
			model.attrs = new Array<AttrModel>();
			service.attrs.map((attr) => {
				model.attrs.push(this.jsonToAttrModel(attr));
			});
		}

		return model;
	}

	private jsonToAttrModel(attr: any) {
		const model = new AttrModel();
		model.value = JsonQuery.value(attr, JSON_PATHS.Attr.value) || null;
		model.type = JsonQuery.value(attr, JSON_PATHS.Attr.type) || null;
		model.units = JsonQuery.value(attr, JSON_PATHS.Attr.units) || null;
		return model;
	}

	private jsonToDiscount(discount: any) {
		const model = new DiscountModel();
		model.type = JsonQuery.value(discount, JSON_PATHS.Discount.type) || null;
		model.dtoExpire = JsonQuery.value(discount, JSON_PATHS.Discount.dtoExpire) || null;
		model.dtoDuration = JsonQuery.value(discount, JSON_PATHS.Discount.dtoDuration) || null;
		model.amount = JsonQuery.value(discount, JSON_PATHS.Discount.amount) || null;
		model.netAmount = JsonQuery.value(discount, JSON_PATHS.Discount.netAmount) || null;
		return model;
	}

	/**
	 * Bussines logic that transforms response keeps attribute to objects model
	 */
	private jsonToServiceKeepModel(item) {
		const keep = new ServiceKeepModel();
		keep.code = JsonQuery.value(item, JSON_PATHS.Service.code) || null;
		keep.dateExpire = JsonQuery.value(item, JSON_PATHS.Service.dateExpire) || null;
		keep.id = JsonQuery.value(item, JSON_PATHS.Service.id) || null;
		keep.name = JsonQuery.value(item, JSON_PATHS.Service.name) || null;
		keep.type = JsonQuery.value(item, JSON_PATHS.Service.type) || null;

		if (item.combinations) {
			const combinations = new Array<CombinationModel>();
			item.combinations.map((comb) => {
				const combination = new CombinationModel();
				combination.excludeAddon = JsonQuery.value(comb, JSON_PATHS.Combination.excludeAddon);
				combination.tariffId = JsonQuery.value(comb, JSON_PATHS.Combination.tariffId) || null;
				combinations.push(combination);
			});

			keep.combinations = combinations;
		}

		keep.fee = JsonQuery.value(item, JSON_PATHS.Service.fee) || null;
		keep.netFee = JsonQuery.value(item, JSON_PATHS.Service.netFee) || null;

		return keep;
	}

	private calculateLabel(service: ServiceProductModel, attr: AttrModel) {
		if (service.type.toLowerCase() === ServiceType.Tv.toLowerCase()) {
			return service.name;
		} else {
			this.translate.get('migration.tarifa.tarifa_info.itemList.lit_ilimitados.body').subscribe((msg) => {
				this.unlimitedLit = msg;
			});
			let auxValue = attr.value;
			const unitText = attr.units ? attr.units : '';
			if (attr.value.toLowerCase() === isUnlimited.toLowerCase()) {
				auxValue = `${unitText} ${this.unlimitedLit}`;
			} else {
				auxValue = `${auxValue} ${unitText}`;
			}
			return auxValue;
		}
	}

	private isMobile(serviceType: string) {
		return (
			serviceType.toLowerCase() === ServiceType.Mobile.toLowerCase() ||
			serviceType.toLowerCase() === ServiceType.Postpaid.toLowerCase() ||
			serviceType.toLowerCase() === ServiceType.Prepaid.toLowerCase()
		);
	}

	private isMBB(serviceType: string) {
		return (
			serviceType.toLowerCase() === ServiceType.MbbPostpaid.toLowerCase() ||
			serviceType.toLowerCase() === ServiceType.MbbPrepaid.toLowerCase()
		);
	}

	public evaluateAdditionalLines(): boolean {
		const packageSrv = this.currentProduct.packages ? this.currentProduct.packages.services : null;
		const lineServices = this.currentProduct.services
			? this.currentProduct.services.filter(
					(srv) =>
						srv.type.toLowerCase() === ServiceType.Postpaid.toLowerCase() ||
						srv.type.toLowerCase() === ServiceType.Prepaid.toLowerCase()
			  )
			: null;
		let maxPartis;
		let maxMicro;
		this.translate.get('migration.tarifasCommon.itemsList.reco').subscribe((data) => {
			maxPartis = data.maxLineasPartis.body;
			maxMicro = data.maxLineasMicro.body;
		});

		if (
			packageSrv &&
			lineServices &&
			packageSrv.find((srv) => srv.type === ServiceType.Postpaid) &&
			((packageSrv.length > 1 &&
				lineServices.length < parseInt(maxPartis, 10) &&
				this.storageService.userProfile.customerType.toLowerCase() === CustomerType.Consumer.toLowerCase()) ||
				(packageSrv.length > 1 &&
					lineServices.length < parseInt(maxMicro, 10) &&
					this.storageService.userProfile.customerType.toLowerCase() === CustomerType.Authorized.toLowerCase()))
		) {
			return true;
		} else {
			return false;
		}
	}

	public getPriorityAsPackage(id: string): number {
		let idPack;
		let idServ;
		if (
			this.currentProduct.packages &&
			this.currentProduct.packages.services &&
			this.currentProduct.packages.services.length > 0
		) {
			idPack = this.currentProduct.packages.services.filter((num) => num.id === id)[0];
		}
		if (this.currentProduct.services && this.currentProduct.services.length > 0) {
			idServ = this.currentProduct.services.filter((num) => num.id === id)[0];
		}
		return idPack ? TryAndBuyOverlayPackage.first : idServ ? TryAndBuyOverlayPackage.second : 0;
	}

	clean() {
		this.currentProduct = new ProductModel();
		this.keepsViewModel = [];
		this.offers = new Array<OfferModel>();
		this.compareModel = {};
		this.identifierOptions = [];
		this.isCTC = false;
		this.modeBuyFromTry = false;
		this.modeBuy = false;
	}

	advanceToResume(): void {
		this.directBuy = true;
		this.step = 2;
		this.breadcrumbStep = 1;
		this.isNewForm = false;
		this.isResume = true;
		this.tryAndBuySetTaggingService.step = this.step;
		this.tbStep = 2;
		this.tbBreadcrumb = 2;
		this.tryAndBuySetTaggingService.isResume = this.isResume;
		if (!this.modeBuy) {
			this.tryAndBuySetTaggingService.setTagging();
		}
	}

	advanceToFrom(): void | boolean {
		const logicActive: boolean =
			this.translate.instant(
				`v10.commercial.permissionRequest.enablePermissionValidation.${UserPermissions.BUY.toLowerCase()}`
			) === TrueFalse.true;
		this.hasPermissionToBuy =
			this.storageService.userProfile.customerType.toLowerCase() === CustomerType.Authorized.toLowerCase() ||
			this.hasPermissionBuy();

		if ((this.hasPermissionToBuy && logicActive) || !this.modeBuy || !logicActive) {
			this.step++;
			this.breadcrumbStep++;
			this.tryAndBuySetTaggingService.step = this.step;
			if (this.step === 3) {
				this.isNewForm = false;
				this.isResume = true;
				this.tryAndBuySetTaggingService.isResume = this.isResume;
				this.tryAndBuySetTaggingService.isNewForm = this.isNewForm;
			} else {
				this.isNewForm = true;
				this.isResume = false;
				this.tryAndBuySetTaggingService.isResume = this.isResume;
				this.tryAndBuySetTaggingService.isNewForm = this.isNewForm;
			}
			this.tbStep++;
			this.tbBreadcrumb++;
			if (!this.modeBuy) {
				this.tryAndBuySetTaggingService.setTagging();
			}
		} else {
			this.tryAndBuySetTaggingService.setBackdropTagging(this.typeReco, this.actTabTitleDesc);
			this.showPermissionToBuyLabel = true;
		}
	}

	hasPermissionBuy(): boolean {
		return (
			this.storageService.userProfile?.userPermissions?.find(
				(permission) => permission.id.toLowerCase() === UserPermissions.BUY.toLowerCase()
			)?.status || this.storageService.userProfile?.userPermissions?.length === 0
		);
	}

	steepBack(step: CilckReturnModel): void {
		if (step.leftIcon.iconTitle === BackButtons.labReturn && this.step > 0) {
			this.step--;
			this.tbStep--;
			if (this.breadcrumbStep >= 0) {
				this.breadcrumbStep--;
				this.tbBreadcrumb--;
			}
		} else {
			if (this.oneProfesionalService.newDataPega.isPega && step.leftIcon.iconTitle !== BackButtons.labReturn) {
				this.oneProfesionalService.showBackdropPega = true;
			} else {
				this.isProcessOut = true;
				this.overlayShow = true;
				this.tryAndBuySetTaggingService.leaveProccessTrack();
			}
		}
		if (this.step === 0) {
			this.setStepFrom();
		}
		if (this.step === 1) {
			// this.newproductDescription.step = 1;
			this.isResume = false;
			this.isNewForm = false;
			this.tryAndBuySetTaggingService.isResume = this.isResume;
			this.tryAndBuySetTaggingService.isNewForm = this.isNewForm;
		} else {
			// this.newproductDescription.step = this.step;
			this.isNewForm = true;
			this.isResume = false;
			this.tryAndBuySetTaggingService.isResume = this.isResume;
			this.tryAndBuySetTaggingService.isNewForm = this.isNewForm;
		}
		if (this.directBuy) {
			this.directBuy = false;
			this.breadcrumbStep = -1;
			this.tbStep = 0;
			this.tbBreadcrumb = 0;
		}
	}

	setStepFrom() {
		this.isNewForm = false;
		this.isResume = false;
		if (this.modeBuyFromTry) {
			this.modeBuy = false;
			this.modeBuyFromTry = false;
			this.step = 1;
		} else {
			this.tobBarTitle = this.tobBarTitleTAB;
		}
		this.tryAndBuySetTaggingService.isNewForm = this.isNewForm;
		this.tryAndBuySetTaggingService.isResume = this.isResume;
	}
}
