import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as JsonQuery from 'jsonpath/jsonpath';
import { CardImageSelectorModel, SelectorModel } from '@mva10/mva10-angular';

import { tap, map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AppService } from '../app.service';
import { CustomerAccount } from '../models/customer-account.model';
import { OpenNewTicket } from '../models/openTicket.model';
import { ServiceAvailability, ServiceAvailablityType } from '../models/service-avalability.model';
import { ServiceModel } from '../models/service.model';
import { Template } from '../models/template.model';
import { TicketModel, TicketTemplateModel } from '../models/ticket.model';
import { TicketPerCategory } from '../models/ticketPerCategory.model';
import * as constants from '../shared/constants/defines';
import { JSON_PATHS, home5G, secondResidences } from '../shared/constants/defines';
import { FaultManagmentTicketType } from '../shared/enums/fault-managmenet-ticket-type.enum';
import { ServiceType } from '../shared/enums/serviceType.enum';
import { TicketStatus } from '../shared/enums/ticket-status.enum';
import { FaultManagementData } from './fault-management.data';
@Injectable()
export class FaultManagementService {
	showToast: boolean = false;
	serviceAvailability: ServiceAvailability;
	services: ServiceModel[];
	template: Template;
	selectedService: ServiceModel;
	selectedServiceName: string;
	ticketId: object;
	templateName: string = '';
	previousTemplateAnalytics: string = '';
	public faultManagementOpenNewTicketLoading: boolean = false;
	userSites: any[] = [];
	selectedSiteId: string;
	selectedSite: CustomerAccount;
	tickets: TicketModel[] = [];
	openTicketPerCategory: TicketPerCategory = {
		movil: '',
		fixed: '',
		default: '',
	};
	closedTicketPerCategory: TicketPerCategory = {
		movil: '',
		fixed: '',
		default: '',
	};
	mappedServices: SelectorModel = new SelectorModel();
	_showFullOverlay: boolean = false;
	faultManagementGetTicketsLoading: boolean = false;
	specificAveriaVariables = {
		issue_type: '',
		issue_information: '',
	};
	passBonitaFlow = false;
	startExitBonitaFlowSubject: Subject<any> = new Subject<any>();
	private _showOverlay: boolean = false;
	private _showSecondOverlay: boolean = false;
	private _showThirdOverlay: boolean = false;
	private _showTimeLineFaultOverlay: boolean = false;

	public set showFullOverlay(v: boolean) {
		if (v) {
			this.appService.openBubbleStopScroll = true;
		} else {
			this.appService.openBubbleStopScroll = false;
		}
		this._showFullOverlay = v;
	}

	public get showFullOverlay(): boolean {
		return this._showFullOverlay;
	}

	public get showOverlay(): boolean {
		return this._showOverlay;
	}
	public set showOverlay(v: boolean) {
		this._showOverlay = v;
		if (v) {
			this.startExitBonitaFlowSubject.next();
		}
	}
	public getShowSecondOverlay(): boolean {
		return this._showSecondOverlay;
	}
	public getShowThirdOverlay(): boolean {
		return this._showThirdOverlay;
	}
	public getShowTimeLineFaultOverlay(): boolean {
		return this._showTimeLineFaultOverlay;
	}
	public setShowSecondOverlay(value: boolean): void {
		this._showSecondOverlay = value;
		if (value) {
			this.startExitBonitaFlowSubject.next();
		}
	}
	public setShowThirdOverlay(value: boolean): void {
		this._showThirdOverlay = value;
		if (value) {
			this.startExitBonitaFlowSubject.next();
		}
	}
	public setShowTimeLineFaultOverlay(value: boolean): void {
		this._showTimeLineFaultOverlay = value;
		if (value) {
			this.startExitBonitaFlowSubject.next();
		}
	}
	constructor(
		public settingData: FaultManagementData,
		private appService: AppService,
		private http: HttpClient,
		private translate: TranslateService
	) {}
	getTickets(siteId: string): Observable<any> {
		this.tickets = [];
		return this.settingData.getTicketsData(siteId).pipe(
			map((res: any) => {
				return res.items.map(this.ticketDetail);
			}),
			tap((items) => {
				this.tickets = items;
			})
		);
	}
	getTicketById(ticketId: string, ticketIndex?: number): Observable<TicketModel> {
		return this.settingData.getCurrentTicket(this.selectedSiteId, ticketId).pipe(
			map((res: TicketModel) => {
				this.tickets[ticketIndex].template = this.ticketDetail(res).template;
				this.tickets[ticketIndex].attachment = this.ticketDetail(res).attachment;
				this.tickets[ticketIndex].caseBE = this.ticketDetail(res).caseBE;
				this.tickets[ticketIndex].journey = this.ticketDetail(res).journey;
				this.tickets[ticketIndex].status = this.ticketDetail(res).status;
				this.tickets[ticketIndex].chatRecorded = this.ticketDetail(res).chatRecorded;
				return this.tickets[ticketIndex];
			})
		);
	}

	getServiceAvailability(siteId: string) {
		return this.settingData.getServiceAvailability(siteId).pipe(
			map((data) => {
				if (data) {
					this.serviceAvailability = this.mapServiceAvailability(data);
					return this.serviceAvailability;
				}
			})
		);
	}
	postServiceAvailability(siteId: string, msisdn, serviceType, massiveTicketId) {
		return this.settingData.postServiceAvailability(siteId, msisdn, serviceType, massiveTicketId).pipe(
			map((data: any) => {
				if (data) {
					this.ticketId = data.ticketId;
					return this.serviceAvailability;
				}
			})
		);
	}

	mapServiceAvailability(data): ServiceAvailability {
		const serviceAvailability = new ServiceAvailability();
		serviceAvailability.hasMassiveTicket = JsonQuery.value(
			data,
			JSON_PATHS.FaultManagment.ServiceAvailability.hasMassiveTicket
		);
		serviceAvailability.hasOrderTicket = JsonQuery.value(
			data,
			JSON_PATHS.FaultManagment.ServiceAvailability.hasOrderTicket
		);
		serviceAvailability.isMobile = JsonQuery.value(data, JSON_PATHS.FaultManagment.ServiceAvailability.isMobile);
		serviceAvailability.isInternet = JsonQuery.value(data, JSON_PATHS.FaultManagment.ServiceAvailability.isInternet);
		serviceAvailability.isTV = JsonQuery.value(data, JSON_PATHS.FaultManagment.ServiceAvailability.isTV);
		serviceAvailability.isFixed = JsonQuery.value(data, JSON_PATHS.FaultManagment.ServiceAvailability.isFixed);
		serviceAvailability.massiveTicketId = JsonQuery.value(
			data,
			JSON_PATHS.FaultManagment.ServiceAvailability.massiveTicketId
		);
		serviceAvailability.massiveTicketType = JsonQuery.value(
			data,
			JSON_PATHS.FaultManagment.ServiceAvailability.massiveTicketType
		);

		return serviceAvailability;
	}
	getTicketTemplate(customerAccountId: string, newTicketObject: OpenNewTicket): Observable<any> {
		return this.settingData.getTicketTemplate(customerAccountId, newTicketObject).pipe(
			map((res) => {
				this.template = new Template();
				this.template.status = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.OpenNewTicket.status) || null;
				this.template.taskId = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.OpenNewTicket.taskId) || null;
				this.template.form = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.OpenNewTicket.form) || null;
				this.template.backend = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.OpenNewTicket.backend) || null;
				this.template.symptom =
					JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.OpenNewTicket.symptom) || undefined;
				this.template.technology =
					JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.OpenNewTicket.technology) || undefined;
				this.template.deco = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.OpenNewTicket.deco) || undefined;
				this.template.ticketId =
					JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.OpenNewTicket.ticketId) || undefined;
				return this.template;
			})
		);
	}
	ticketDetail(res: TicketModel): TicketModel {
		const ticketDetail = new TicketModel();
		ticketDetail.ticketId = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.TicketDetails.ticketId);
		ticketDetail.title = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.TicketDetails.title);
		ticketDetail.averia = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.TicketDetails.averia);
		ticketDetail.ticketName = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.TicketDetails.ticketName);
		ticketDetail.ticketType = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.TicketDetails.ticketType);
		ticketDetail.status = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.TicketDetails.status);
		ticketDetail.creationDate = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.TicketDetails.creationDate);
		ticketDetail.icon = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.TicketDetails.icon);
		ticketDetail.attachment = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.TicketDetails.attachment);
		ticketDetail.isGeneralBreakDown = JsonQuery.value(
			res,
			constants.JSON_PATHS.FaultManagment.TicketDetails.isGeneralBreakDown
		);
		ticketDetail.showTicketDetail = false;
		ticketDetail.showCloseOpen = false;
		ticketDetail.closingDate = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.TicketDetails.closingDate);
		ticketDetail.journey = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.TicketDetails.journey);
		ticketDetail.chatRecorded = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.TicketDetails.chatRecorded);
		ticketDetail.template = {};
		ticketDetail.template.templateCode = JsonQuery.value(
			res,
			constants.JSON_PATHS.FaultManagment.TicketDetails.template.templateCode
		);
		ticketDetail.template.templateId = JsonQuery.value(
			res,
			constants.JSON_PATHS.FaultManagment.TicketDetails.template.templateId
		);
		ticketDetail.template.templateDate = JsonQuery.value(
			res,
			constants.JSON_PATHS.FaultManagment.TicketDetails.template.templateDate
		);
		ticketDetail.template.visitFrom = JsonQuery.value(
			res,
			constants.JSON_PATHS.FaultManagment.TicketDetails.template.visitFrom
		);
		ticketDetail.template.visitTo = JsonQuery.value(
			res,
			constants.JSON_PATHS.FaultManagment.TicketDetails.template.visitTo
		);
		ticketDetail.template.outSLA = JsonQuery.value(
			res,
			constants.JSON_PATHS.FaultManagment.TicketDetails.template.outSLA
		);
		ticketDetail.caseBE = {};
		ticketDetail.caseBE.caseId = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.TicketDetails.caseBE.caseId);
		ticketDetail.caseBE.status = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.TicketDetails.caseBE.status);
		ticketDetail.caseBE.channelId = JsonQuery.value(
			res,
			constants.JSON_PATHS.FaultManagment.TicketDetails.caseBE.channelId
		);
		ticketDetail.caseBE.agentName = JsonQuery.value(
			res,
			constants.JSON_PATHS.FaultManagment.TicketDetails.caseBE.agentName
		);
		ticketDetail.caseBE.postId = JsonQuery.value(res, constants.JSON_PATHS.FaultManagment.TicketDetails.caseBE.postId);
		ticketDetail.caseBE.createdAt = JsonQuery.value(
			res,
			constants.JSON_PATHS.FaultManagment.TicketDetails.caseBE.createdAt
		);
		ticketDetail.caseBE.statusUpdatedAt = JsonQuery.value(
			res,
			constants.JSON_PATHS.FaultManagment.TicketDetails.caseBE.statusUpdatedAt
		);
		return ticketDetail;
	}
	updateStatus(ticketId, status) {
		return this.settingData.updateTicketStatus(this.selectedSiteId, ticketId, status).pipe(
			map((res: any) => {
				return this.ticketDetail(res);
			})
		);
	}
	setInteractionForMonitoring(
		customerAccountId: string,
		interactionsId: string,
		serviceType: string,
		backend: string
	): any {
		return this.settingData.sendInteractions(customerAccountId, interactionsId, serviceType, backend);
	}
	setInteractionForMonitoringSLA(
		customerAccountId: string,
		interactionsId: string,
		ticketId: string,
		ticketName: string,
		creationDate: string,
		sendCustomerAccountId: boolean
	): Observable<string> {
		return this.settingData.sendInteractionsSLA(
			customerAccountId,
			interactionsId,
			ticketId,
			ticketName,
			creationDate,
			sendCustomerAccountId
		);
	}
	ticketStatus(ticketstatus) {
		let title;
		this.translate.get('faultManagement.messagesList').subscribe((data) => {
			if (ticketstatus === TicketStatus.Open) {
				title = data.faultInProgressStatus.faultInProgressStatus_description;
			} else {
				title = data.faultSolvedStatus.faultSolvedStatus_description;
			}
		});
		return title;
	}
	setServiceCategory(_services?: ServiceModel[]): ServiceModel[] {
		_services.forEach((service: ServiceModel) => {
			switch (service.type.toLowerCase()) {
				case ServiceType.Prepaid.toLowerCase():
				case ServiceType.Postpaid.toLowerCase():
				case ServiceType.MbbPrepaid.toLowerCase():
					this.checkServiceOngoingOrder(service, { ...new ServiceAvailablityType(), isMobile: true });
					service.serviceCategory = FaultManagmentTicketType.MOVIL;
					break;
				case ServiceType.MbbPostpaid.toLowerCase(): {
					if (service.tarrifCode === home5G.tariffCode) {
						this.checkServiceOngoingOrder(service, { ...new ServiceAvailablityType(), isFixed: true });
						service.serviceCategory = FaultManagmentTicketType.FIXED;
					} else {
						this.checkServiceOngoingOrder(service, { ...new ServiceAvailablityType(), isMobile: true });
						service.serviceCategory = FaultManagmentTicketType.MOVIL;
					}
					break;
				}
				case ServiceType.Fixed.toLowerCase():
				case ServiceType.VodafoneEnTuCasa.toLowerCase():
				case ServiceType.Landline.toLowerCase():
					this.checkServiceOngoingOrder(service, { ...new ServiceAvailablityType(), isFixed: true });
					service.serviceCategory = FaultManagmentTicketType.FIXED;
					break;
				case ServiceType.ADSL.toLowerCase():
				case ServiceType.Fibre.toLowerCase():
				case ServiceType.Internet.toLowerCase():
					this.checkServiceOngoingOrder(service, { ...new ServiceAvailablityType(), isInternet: true });
					service.serviceCategory = FaultManagmentTicketType.FIXED;
					break;
				case ServiceType.Tv.toLowerCase():
					this.checkServiceOngoingOrder(service, { ...new ServiceAvailablityType(), isTv: true });
					service.serviceCategory = FaultManagmentTicketType.FIXED;
					break;
			}
		});
		this.services = _services;
		return _services;
	}
	getOpenTicketForCategries() {
		this.openTicketPerCategory.movil = '';
		this.openTicketPerCategory.fixed = '';
		this.openTicketPerCategory.default = '';
		this.tickets.forEach((ticket) => {
			if (ticket.status === TicketStatus.Open) {
				if (ticket.ticketType === FaultManagmentTicketType.MOVIL && !this.openTicketPerCategory.movil) {
					this.openTicketPerCategory.movil = ticket.ticketId;
				} else if (ticket.ticketType === FaultManagmentTicketType.FIXED && !this.openTicketPerCategory.fixed) {
					this.openTicketPerCategory.fixed = ticket.ticketId;
				} else if (ticket.ticketType === FaultManagmentTicketType.DEFAULT && !this.openTicketPerCategory.default) {
					this.openTicketPerCategory.default = ticket.ticketId;
				}
			}
		});
	}
	getClosedTicketForCategriesAndOpenChat(): void {
		this.closedTicketPerCategory.movil = '';
		this.closedTicketPerCategory.fixed = '';
		this.closedTicketPerCategory.default = '';
		this.tickets.forEach((ticket) => {
			if (ticket.status === TicketStatus.Closed && ticket.averia) {
				if (ticket.ticketType === FaultManagmentTicketType.MOVIL && !this.closedTicketPerCategory.movil) {
					this.closedTicketPerCategory.movil = ticket.ticketId;
				} else if (ticket.ticketType === FaultManagmentTicketType.FIXED && !this.closedTicketPerCategory.fixed) {
					this.closedTicketPerCategory.fixed = ticket.ticketId;
				} else if (ticket.ticketType === FaultManagmentTicketType.DEFAULT && !this.closedTicketPerCategory.default) {
					this.closedTicketPerCategory.default = ticket.ticketId;
				}
			}
		});
	}
	dimedServices() {
		this.services?.forEach((service) => {
			if (this.openTicketPerCategory.default) {
				service.hasOpenTicket = true;
				service.openTicketId = this.openTicketPerCategory.default;
			} else if (this.openTicketPerCategory.fixed && service.serviceCategory === FaultManagmentTicketType.FIXED) {
				service.hasOpenTicket = true;
				service.openTicketId = this.openTicketPerCategory.fixed;
			} else if (this.openTicketPerCategory.movil && service.serviceCategory === FaultManagmentTicketType.MOVIL) {
				service.hasOpenTicket = true;
				service.openTicketId = this.openTicketPerCategory.movil;
			}
		});
	}
	closedServices(): void {
		this.services.forEach((service) => {
			if (
				this.closedTicketPerCategory.default ||
				(this.closedTicketPerCategory.fixed && service.serviceCategory === FaultManagmentTicketType.FIXED) ||
				(this.closedTicketPerCategory.movil && service.serviceCategory === FaultManagmentTicketType.MOVIL)
			) {
				service.hasClosedTicket = true;
			}
		});
	}
	checkServiceOngoingOrder(service: ServiceModel, serviceAvailablityType: ServiceAvailablityType) {
		if (this.serviceAvailability?.hasOrderTicket) {
			if (this.serviceAvailability?.isMobile && serviceAvailablityType.isMobile) {
				service.hasOnGoingOrder = true;
			} else if (this.serviceAvailability?.isFixed && serviceAvailablityType.isFixed) {
				service.hasOnGoingOrder = true;
			} else if (this.serviceAvailability?.isInternet && serviceAvailablityType.isInternet) {
				service.hasOnGoingOrder = true;
			} else if (this.serviceAvailability?.isTV && serviceAvailablityType.isTv) {
				service.hasOnGoingOrder = true;
			}
		}
	}
	mapServicesToSelectorModel() {
		this.mappedServices.cardImageSelectorList = [];
		this.services.forEach((service: ServiceModel, index) => {
			let itemImageCard = new CardImageSelectorModel();
			itemImageCard = this.mapServiceSelector(service, service.type);
			this.translate.get('faultManagement.itemsList').subscribe((text) => {
				if (service.hasOpenTicket) {
					itemImageCard.blueLabel = text.openTicketAlert.body;
				} else if (service.hasOnGoingOrder) {
					itemImageCard.blueLabel = text.openOrderAlert.body;
				}
			});
			this.mappedServices.cardImageSelectorList.push(itemImageCard);
		});
	}

	resetSelectedSite_service() {
		this.selectedSite = new CustomerAccount();
		this.selectedSiteId = '';
		this.serviceAvailability = new ServiceAvailability();
		this.selectedService = new ServiceModel();
		this.tickets = [];
	}
	mapServiceSelector(item: ServiceModel, type: ServiceType): CardImageSelectorModel {
		const itemImageCard: CardImageSelectorModel = new CardImageSelectorModel();
		this.translate.get('v10').subscribe((text) => {
			switch (type.toLowerCase()) {
				case ServiceType.Postpaid.toLowerCase():
					itemImageCard.title = text.dashboard.overlay_outrate.mobile.postpaid;
					itemImageCard.description = item.id;
					itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.mobile);
					itemImageCard.id = item.id;
					break;
				case ServiceType.MbbPostpaid.toLowerCase(): {
					itemImageCard.id = item.id;
					if (item.tarrifCode === home5G.tariffCode) {
						itemImageCard.title = text.faultManagement.itemsList.home5g.body;
						itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.hogar5g);
					} else if (item.tarrifCode === secondResidences.tariffCode) {
						itemImageCard.title = text.commercial.secondResidences.bonuses.description;
						itemImageCard.description = item.id;
						itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.mbb);
					} else {
						itemImageCard.title = text.dashboard.overlay_outrate.mbb.postpaid;
						itemImageCard.description = item.id;
						itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.mbb);
					}
					break;
				}
				case ServiceType.MBB_Holiday.toLowerCase():
					itemImageCard.title = text.dashboard.overlay_outrate.mbb.postpaid;
					itemImageCard.description = item.id;
					itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.mbb);
					itemImageCard.id = item.id;
					break;
				case ServiceType.Prepaid.toLowerCase():
					itemImageCard.title = text.dashboard.overlay_outrate.mobile.prepaid;
					itemImageCard.description = item.id;
					itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.mobile);
					itemImageCard.id = item.id;
					break;
				case ServiceType.MbbPrepaid.toLowerCase():
					itemImageCard.title = text.dashboard.overlay_outrate.mbb.prepaid;
					itemImageCard.description = item.id;
					itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.mbb);
					itemImageCard.id = item.id;
					break;
				case ServiceType.Landline.toLowerCase():
				case ServiceType.VodafoneEnTuCasa.toLowerCase():
					itemImageCard.title = text.common.literals.phone_C;
					itemImageCard.description = item.id;
					itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.landline);
					itemImageCard.id = item.id;
					break;
				case ServiceType.Tv.toLowerCase():
				case ServiceType.TV_online.toLowerCase():
					itemImageCard.title = text.common.literals.tv_C;
					itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.tv);
					itemImageCard.id = item.id;
					break;
				case ServiceType.ADSL.toLowerCase():
					itemImageCard.title = text.common.literals.adsl;
					itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.fibre_adsl);
					itemImageCard.id = item.id;
					break;
				case ServiceType.Fibre.toLowerCase():
					itemImageCard.title = text.common.literals.fiber_C;
					itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.fibre_adsl);
					itemImageCard.id = item.id;
					break;
				case ServiceType.Others.toLowerCase():
					itemImageCard.title = text.faultManagement.contentList.dynamicLanding.FE.body;
					itemImageCard.image = this.appService.getImgFullPath(text.faultManagement.images.FE.png.url);
					itemImageCard.id = item.id;
					break;
			}
			itemImageCard.checked = false;
		});
		return itemImageCard;
	}
	addCloseRopenBtnsToTickets() {
		this.getOpenTicketForCategries();
		this.tickets.forEach((ticket) => {
			if (ticket.status === TicketStatus.Open && !ticket.isGeneralBreakDown) {
				ticket.showCloseOpen = true;
			} else if (
				ticket.status === TicketStatus.Closed &&
				!ticket.isGeneralBreakDown &&
				!this.checkOpenDefaultORSameType(ticket.ticketType) &&
				this.isClosedLessThan24Hours(ticket.closingDate)
			) {
				ticket.showCloseOpen = true;
			} else {
				ticket.showCloseOpen = false;
			}
		});
	}
	checkOpenDefaultORSameType(ticketType: string) {
		return (
			this.openTicketPerCategory.default ||
			(ticketType.toLowerCase() === FaultManagmentTicketType.MOVIL.toLowerCase() && this.openTicketPerCategory.movil) ||
			(ticketType.toLowerCase() === FaultManagmentTicketType.FIXED.toLowerCase() && this.openTicketPerCategory.fixed) ||
			(ticketType.toLowerCase() === FaultManagmentTicketType.DEFAULT.toLowerCase() &&
				(this.openTicketPerCategory.fixed || this.openTicketPerCategory.movil))
		);
	}
	isClosedLessThan24Hours(closeDate: any) {
		const date1 = new Date(closeDate);
		const timeStamp = Math.round(new Date().getTime() / 1000);
		const timpStampYesterday = timeStamp - 24 * 3600;
		const is24 = date1.getTime() >= new Date(timpStampYesterday * 1000).getTime();
		return is24;
	}
}
