import { Injectable } from '@angular/core';
import { Person, Validation } from '../../../models/changePMIdent.model';
import { DetallePedidoResponse, PortabilidadRechazada } from '../../../models/detalleSeguimientoPedido.model';
import { TranslateService } from '@ngx-translate/core';
import { charsValid } from '../../../constants/defines';

@Injectable({
	providedIn: 'root',
})
export class ResponseBoxUtilsService {
	constructor(private translate: TranslateService) {}

	validateCIF(numDoc: string): boolean {
		const CIF_REGEX: RegExp = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;

		const str = numDoc?.toString().toUpperCase();

		if (!CIF_REGEX.test(str)) {
			return false;
		}

		const match = str.match(CIF_REGEX);
		const letter = match[1],
			number = match[2],
			control = match[3];

		let even_sum = 0;
		let odd_sum = 0;
		let n;

		for (let i: number = 0; i < number.length; i++) {
			n = parseInt(number[i], 10);
			if (i % 2 === 0) {
				n *= 2;
				odd_sum += n < 10 ? n : n - 9;
			} else {
				even_sum += n;
			}
		}

		const control_digit: number = 10 - parseInt((even_sum + odd_sum).toString().slice(-1), 10);
		const control_letter: string = 'JABCDEFGHI'.slice(control_digit, control_digit - 9);

		if (letter.match(/[ABEH]/)) {
			return parseInt(control, 10) === control_digit;
		} else if (letter.match(/[KPQS]/)) {
			return control === control_letter;
		} else {
			return parseInt(control, 10) === control_digit || control === control_letter;
		}
	}

	validateDNI(numDoc: string, docType: string, dniValid: boolean): boolean {
		if (docType === this.translate.instant('v10.myOrder.steps.relaunchPortabilityCIF')) {
			return this.validateCIF(numDoc);
		} else if (docType !== this.translate.instant('v10.myOrder.steps.relaunchPortabilityPassport')) {
			const validChars: string = charsValid;
			const nifRexp: RegExp = /^\d{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
			const nieRexp: RegExp = /^[XYZ]\d{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;

			const str: string = numDoc?.toString().toUpperCase();

			if (!nifRexp.test(str) && !nieRexp.test(str)) {
				dniValid = false;
				return dniValid;
			}
			const nie: string = str.replace(/^X/, '0').replace(/^Y/, '1').replace(/^Z/, '2');
			const letter: string = str.slice(-1);
			const charIndex: number = parseInt(nie.slice(0, 8), 10) % 23;
			if (validChars.charAt(charIndex) === letter) {
				dniValid = true;
				return dniValid;
			}
			dniValid = false;
			return dniValid;
		} else {
			dniValid = /^[a-z]{3}\d{6}[a-z]?$/i.test(numDoc);
			return dniValid;
		}
	}

	change(
		docType: string,
		numDoc: string,
		numPortar: string,
		enableButton: boolean,
		portabilidadRechazadaObject: PortabilidadRechazada,
		numValid: boolean,
		dniValid: boolean
	): boolean {
		if (
			(docType === portabilidadRechazadaObject.tipoDoc &&
				numDoc === portabilidadRechazadaObject.numDoc &&
				numPortar === portabilidadRechazadaObject.numPortar) ||
			(docType !== this.translate.instant('v10.myOrder.steps.relaunchPortabilityNIF') &&
				docType !== this.translate.instant('v10.myOrder.steps.relaunchPortabilityNIE') &&
				docType !== this.translate.instant('v10.myOrder.steps.relaunchPortabilityPassport') &&
				docType !== this.translate.instant('v10.myOrder.steps.relaunchPortabilityCIF'))
		) {
			enableButton = false;
		} else {
			enableButton = !!(numValid && dniValid);
		}
		return enableButton;
	}

	changePMIdent(
		person: Person,
		enableButton: boolean,
		portabilidadRechazadaObject: PortabilidadRechazada,
		validation: Validation
	): boolean {
		if (
			(person.docType === portabilidadRechazadaObject.tipoDoc &&
				person.numDoc === portabilidadRechazadaObject.numDoc &&
				person.name === portabilidadRechazadaObject.nombre &&
				person.surname === portabilidadRechazadaObject.apellido1) ||
			(person.docType !== this.translate.instant('v10.myOrder.steps.relaunchPortabilityNIF') &&
				person.docType !== this.translate.instant('v10.myOrder.steps.relaunchPortabilityNIE') &&
				person.docType !== this.translate.instant('v10.myOrder.steps.relaunchPortabilityPassport') &&
				person.docType !== this.translate.instant('v10.myOrder.steps.relaunchPortabilityCIF'))
		) {
			enableButton = false;
		} else {
			enableButton = !!(
				validation.dniValid &&
				validation.nameValid &&
				validation.surnameValid &&
				validation.secondSurnameValid
			);
		}
		return enableButton;
	}

	validatePhone(numPortar: string, numValid: boolean, mensajePM: string): boolean {
		if (mensajePM) {
			// Mobile phone
			numValid = /^([6-7])\d{8}$/.test(numPortar);
		} else {
			// Landline
			numValid = /^([8-9])(\d{8})$/.test(numPortar);
		}
		return numValid;
	}

	validateICCID(iccid: string, serviceData: DetallePedidoResponse): boolean {
		const iccidValid: boolean =
			/^(\d{19}|\d{19}[a-zA-Z])$/.test(iccid) && iccid !== serviceData.portabilidadRechazada.iccid;
		return iccidValid;
	}
}
