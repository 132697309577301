import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { config } from '../../../../config/pages-config';
import { AppService } from '../../../app.service';

@Component({
	selector: 'sp-tradein-deeplink',
	template: '',
})
export class TradeinDeeplinkComponent implements OnInit {
	constructor(private appService: AppService, private router: Router, private translate: TranslateService) {}

	ngOnInit(): void {
		this.appService.showVodaFullLoader = true;
		window.open(this.translate.instant('v10.productsServices.tradein.url'));
		this.router.navigate([config.dashboard.route]);
	}

	ngOnDestroy(): void {
		this.appService.showVodaFullLoader = false;
	}
}
