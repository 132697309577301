import { Component } from '@angular/core';

@Component({
	selector: 'sp-payment-full-loader',
	templateUrl: './payment-full-loader.component.html',
	styleUrls: ['./payment-full-loader.component.scss'],
})
export class PaymentFullLoaderComponent {
	constructor() {}
}
