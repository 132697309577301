import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthTicket } from '../../../models/authTicket.model';
import { map } from 'rxjs/operators';
import { MyOrdersService } from './my-orders.service';
import { environmentMock } from '../../../models/environmentMock';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	ticket: string = '';
	constructor(private http: HttpClient, private myOrdersService: MyOrdersService) {}

	requestTicket(): Observable<AuthTicket> {
		// Retrieve ticket from CAS data
		this.ticket = this.ticket || this.myOrdersService.MYORDERS_DATA?.auth_ticket || '';
		if (this.ticket) {
			return of({ ticket: this.ticket });
		} else {
			return this.http.get<AuthTicket>(environmentMock.baseUrl + environmentMock.endpoints.getTicket.url).pipe(
				map((res) => {
					this.ticket = res.ticket;
					return res;
				})
			);
		}
	}
}
