<div
	class="mva10-full-overlay"
	[ngClass]="{ 'scroll-full-screen': OverlayService.fullScreenScroll }"
	[@openClosefromRight]="OverlayService._isOpenedFromRight ? 'open' : 'closed'"
	[@openClose]="OverlayService._isOpened ? 'open' : 'closed'"
	[ngStyle]="{ height: docHeight }"
>
	<div class="{{ slideClassName1 }} {{ slideClassName1Animation }} mainSection">
		<div #dynamicCompRef_1></div>
	</div>
	<div class="{{ slideClassName2 }} {{ slideClassName2Animation }} mainSection">
		<div #dynamicCompRef_2></div>
	</div>
</div>
