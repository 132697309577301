import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { config } from 'src/config/pages-config';
import { PaymentNavigationService } from '../../services/payment-navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { TariffService } from 'src/app/shared/services/tariff.service';
import { defines, SuccessScreenTaggingLiterals } from 'src/app/shared/constants/defines';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { tagging } from 'src/config/tagging-config';
import { TaggingHelperService } from 'src/app/core/services/tagging.helper.service';
import { TopUpService } from 'src/app/top-up/top-up.service';
import { TaggingViewModel } from 'src/app/models/tagging.model';
@Component({
	selector: 'sp-spend-new-balance',
	templateUrl: './spend-new-balance.component.html',
	styleUrls: ['./spend-new-balance.component.scss'],
})
export class SpendNewBalanceComponent implements OnInit {
	@Input() newBalance: string = '';
	@Input() isBalanceChanged: boolean;
	descriptionNewBalance: string = '';
	constructor(
		private router: Router,
		private paymentNavigationService: PaymentNavigationService,
		private translateService: TranslateService,
		private tariffService: TariffService,
		private dashboardService: DashboardService,
		private topUpService: TopUpService,
		private taggingHelperService: TaggingHelperService
	) {}

	ngOnInit(): void {
		this.translateService.get('v10.payment.counter.counterResponse.changed').subscribe((data) => {
			this.descriptionNewBalance = data
				.replace('{0}', this.newBalance + defines.euro)
				.replace('{1}', this.tariffService.Tariff.Name.split('Vodafone ')[1])
				.replace('{2}', this.tariffService.Tariff.Fee + defines.euro);
		});
		this.isBalanceChanged ? this.sendingTopUpNewBalanceTagging() : this.sendingTopUpNewBalanceFailureTagging();
	}

	sendingTopUpNewBalanceTagging(): void {
		const page: TaggingViewModel = { ...tagging.afterTopUp.pageWithOptions.state };
		this.topUpService.promoCode
			? (page['&&products'] = page['&&products'].replace(
					SuccessScreenTaggingLiterals.promoCodeKey,
					`;eVar207=${this.topUpService.promoCode}`
			  ))
			: (page['&&products'] = SuccessScreenTaggingLiterals.productValue);
		this.taggingHelperService.view(null, page);
	}

	sendingTopUpNewBalanceFailureTagging(): void {
		const page: TaggingViewModel = { ...tagging.afterTopUp.FailureScreenAfterTopUp.state };
		this.topUpService.promoCode
			? (page['&&products'] = page['&&products'].replace(
					SuccessScreenTaggingLiterals.promoCodeKey,
					`;eVar207=${this.topUpService.promoCode}`
			  ))
			: (page['&&products'] = SuccessScreenTaggingLiterals.productValue);
		this.taggingHelperService.view(null, page);
	}

	navigateToBenefitRenewal(): void {
		this.taggingHelperService.track(
			tagging.afterTopUp.pageWithOptions.interactions.updateMyTarrifa.eventName,
			tagging.afterTopUp.pageWithOptions.interactions.updateMyTarrifa.data,
			tagging.afterTopUp.pageWithOptions.state
		);
		this.paymentNavigationService.closePayment();
		setTimeout(() => {
			this.router.navigate([config.ProductsAndServices.BenefitRenewal.route]);
		}, 100);
	}

	navigateToExtrasList(): void {
		this.taggingHelperService.track(
			tagging.afterTopUp.pageWithOptions.interactions.buyBonus.eventName,
			tagging.afterTopUp.pageWithOptions.interactions.buyBonus.data,
			tagging.afterTopUp.pageWithOptions.state
		);
		this.paymentNavigationService.closePayment();
		setTimeout(() => {
			this.dashboardService.showExtraTileTrayFlag$.next(true);
		}, 2000);
	}

	closeScreen(): void {
		this.paymentNavigationService.closePayment();
	}
}
