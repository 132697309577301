import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppService } from '../../../../../app.service';
import { HistoryStatusDetail } from '../../../../models/historyStatusMap.model';
import { HistoricoOL } from '../../../../models/pedidoHistoricoOL.model';
import { HistoryOlService } from '../../services/history-ol.service';

@Component({
	selector: 'sp-order-status-cta',
	templateUrl: './sp-order-status-cta.component.html',
	styleUrls: ['./sp-order-status-cta.component.scss'],
})
export class SpOrderStatusCtaComponent implements OnChanges {
	@Input() orderStatus: HistoricoOL;
	@Input() onClick: Function;
	@Input() loading: boolean;
	statusMap: HistoryStatusDetail;

	constructor(private olService: HistoryOlService, public appService: AppService) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.orderStatus?.currentValue) {
			this.statusMap = this.olService.getShipmentHistoryStatusDetail(changes.orderStatus.currentValue as HistoricoOL);
		}
	}

	onCTAClick(): void {
		if (typeof this.onClick === 'function') {
			this.onClick();
		}
	}
}
