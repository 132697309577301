import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ITerminalViewModel, PromoViewModel } from '../../../models/terminalView.model';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../app.service';
import { regexEuropeanFundsYoutube, sanitizerURL, sanitizeURLYoutube } from '../../constants/defines';
import { OverlayFullScreenModel } from '@mva10/mva10-angular';
import { commercialMobile } from '../../constants/defines';
import { TaggingHelperService } from 'src/app/core/services/tagging.helper.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { ClientTypology } from '../../../shared/enums/clientTopology.enum';
import { MicroFlowsService } from '../../services/micro-flows.service';
import { TaggingVarsValues, journeyTags } from '../../../shared/constants/defines';
import { UtilitiesService } from '../../utils/utilities.service';
import { tagging } from 'src/config/tagging-config';
import { TaggingViewModel } from 'src/app/models/tagging.model';
import { CommercialMobileDetailService } from '../../services/commercial-mobile-detail.service';

@Component({
	selector: 'sp-commercial-promotions',
	templateUrl: './commercial-promotions.component.html',
	styleUrls: ['./commercial-promotions.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CommercialPromotionsComponent implements OnInit {
	@Input() public terminalViewModel: ITerminalViewModel;
	@Input() public switchFinancingChecked: boolean;

	public viewMore: string;
	public viewLess: string;
	public numberPromotions: number = 2;
	public text: string;
	public showBackdrop: boolean = false;
	public promotion: PromoViewModel;
	public showModalIframe: boolean = false;
	public overlayDataIframe: OverlayFullScreenModel;

	constructor(
		public appService: AppService,
		public microFlowService: MicroFlowsService,
		public tagging: TaggingHelperService,
		public storageService: StorageService,
		public utilitiesService: UtilitiesService,
		public detailService: CommercialMobileDetailService,
		public translate: TranslateService
	) {}

	ngOnInit() {
		this.translate.get('v10.commercial.checkout.terminalSetup.promos').subscribe((data) => {
			this.viewMore = data.viewMore;
			this.viewLess = data.viewLess;
		});
		this.text = this.viewMore;
	}

	public changeView(): void {
		if (this.numberPromotions === 2) {
			this.numberPromotions = 4;
			this.text = this.viewLess;
		} else {
			this.numberPromotions = 2;
			this.text = this.viewMore;
		}
	}

	public setTaggingLogic(): void {
		const page: TaggingViewModel = { ...tagging.CommercialJourneys.canjeTerminales.page };
		const data: TaggingViewModel = { ...tagging.CommercialJourneys.canjeTerminales.data };
		const event: TaggingViewModel = { ...tagging.CommercialJourneys.canjeTerminales.event };
		const finalPaymentValue: string = this.terminalViewModel.finalPayment
			? this.terminalViewModel.finalRefinancing
				? commercialMobile.Tagg.finalPayType.parcial.replace(
						'{0}',
						this.terminalViewModel.finalRefinancing.numberMonths.toString()
				  )
				: commercialMobile.Tagg.finalPayType.total
			: commercialMobile.Tagg.finalPayType.no;
		data.journey_options = data.journey_options.replace('{0}', finalPaymentValue);
		const trackdata: TaggingViewModel = { ...page, ...data, ...event };
		trackdata.is_main_flow = this.microFlowService.isMainFlow ? TaggingVarsValues.yes : TaggingVarsValues.no;
		trackdata.journey_category = this.tagging?.getUserType(
			this.storageService.userProfile.customerType,
			ClientTypology
		);
		trackdata.journey_subcategory = this.utilitiesService?.isPurePrepaid() ? journeyTags.prepaid : journeyTags.pospaid;
		trackdata.event_name = this.promotion?.text?.toLowerCase() ?? '';
		trackdata.event_category = tagging.CommercialJourneys.canjeTerminales.promotions.event_category;
		trackdata.event_context = tagging.CommercialJourneys.canjeTerminales.promotions.event_context;
		trackdata.event_label = this.promotion?.text?.toLowerCase();
		trackdata.journey_payment_type = this.switchFinancingChecked
			? commercialMobile.Tagg.evar.financedPayment.replace('{0}', this.terminalViewModel.numMeses.toString())
			: commercialMobile.Tagg.evar.paymentCash;
		trackdata['&&products'] = this.detailService.productTagg;
		this.tagging.trackReplace(trackdata.event_name, trackdata, false);
	}

	public showDetailPromo(promo: PromoViewModel): void {
		this.promotion = promo;
		this.setTaggingLogic();
		this.showBackdrop = true;
		this.promotion.urlEmbeddedVideo = this.promotion.urlEmbeddedVideo ? this.getVideoIframe(promo) : null;
	}

	public closeBackdrop(): void {
		this.showBackdrop = false;
	}

	public getVideoIframe(promo: PromoViewModel): string {
		const url: string = promo.urlEmbeddedVideo;
		const results: RegExpMatchArray = url.match(regexEuropeanFundsYoutube);
		promo.urlEmbeddedVideo =
			results === null ? url.replace(sanitizeURLYoutube, sanitizerURL) : `${sanitizerURL}${results[1]}`;
		return promo.urlEmbeddedVideo;
	}

	public openModalIframe(): void {
		this.overlayDataIframe = new OverlayFullScreenModel();
		this.overlayDataIframe.closeBtn = true;
		this.showModalIframe = true;
		this.appService.showVodaFullLoader = false;
		this.showBackdrop = false;
	}

	public closeModalIframe(): void {
		this.showBackdrop = true;
		this.showModalIframe = false;
	}
}
