import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'sp-zone-one-tray',
	templateUrl: './zone-one-tray.component.html',
	styleUrls: ['./zone-one-tray.component.scss'],
})
export class ZoneOneTrayComponent implements OnInit {
	zoneOneTrayDesc: any;

	constructor(public translate: TranslateService) {}

	ngOnInit() {
		this.translate.get('v10.roaming.tray.countriesZone1').subscribe((data) => {
			this.zoneOneTrayDesc = data.description;
		});
	}
}
