export class RoamingBundleDeatils {
	type: romaingBundleDeailType;
	icon?: string;
	title?: string;
	description?: string;
	establishmentPrice?: string;
	establishmentUnit?: string;
	price?: string;
	priceUnit?: string;
}

export enum romaingBundleDeailType {
	callssent = 'callssent',
	callssentestablishment = 'callssentestablishment',
	callsreceived = 'callsreceived',
	callsreceivedestablishment = 'callsreceivedestablishment',
	smssent = 'smssent',
	data = 'data',
}
