import { Component, OnInit } from '@angular/core';
import { TopupPrepaidErrorOVerlayDataService } from '../top-up-prepaid-error-overlay-data.service';
import { SpMva10OverlayService } from '../../shared/services/sp-mva10-overlay.service';
import { PaymentCardsService } from '../../shared/services/payment-cards.service';
import { PaymentCardModel } from '../../models/payment-card.model';
import { AppService } from '../../app.service';
import { TopUpService } from '../top-up.service';

import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { tagging } from '../../../config/tagging-config';
import { DynamicTrayService } from '../../shared/services/dynamic-tray.service';
import { Breakpoints } from '@mva10/mva10-angular';

@Component({
	selector: 'sp-top-up-general-error-overlay',
	templateUrl: './top-up-general-error-overlay.component.html',
	styleUrls: ['./top-up-general-error-overlay.component.scss'],
})
export class TopUpGeneralErrorOverlayComponent implements OnInit {
	errorDescLine1 = '';
	errorDescLine2 = '';
	constructor(
		public trayService: SpMva10OverlayService,
		public topupErrorService: TopupPrepaidErrorOVerlayDataService,
		private paymentCardsService: PaymentCardsService,
		public appService: AppService,
		public topupService: TopUpService,
		private tagging: TaggingHelperService,
		private dynamicTrayService: DynamicTrayService
	) {}

	ngOnInit(): void {
		this.topupService.dynamicTrayData.closeButtonAction = this.closeTray.bind(this);
		if (this.dynamicTrayService.trayData) {
			/** Set close function to the original dynamic tray */
			this.dynamicTrayService.trayData.closeButtonAction = this.closeTray.bind(this);
			/** Hide back button */
			if (this.dynamicTrayService.trayData.basicTrayData) {
				this.dynamicTrayService.trayData.basicTrayData.showBackButton = false;
			}
			/** check if the device is mobile */
			const isMobileView: boolean = window.innerWidth < Breakpoints.TABLET;
			/** Make title is bold in responsive view */
			this.dynamicTrayService.trayData.isBoldTitle = !isMobileView;
			/** Make gradient backgroud in mobile view */
			this.dynamicTrayService.trayData.isGradientBackground = true;
			/** Make full height in mobile view */
			this.dynamicTrayService.trayData.isContentBodyFullHeight = true;
			/** Remove padding of tray */
			this.dynamicTrayService.trayData.removeTrayPadding = true;
		}
	}

	ngDestroy() {
		this.paymentCardsService.cardsList = new Array<PaymentCardModel>();
		this.topupService.dynamicTrayData.closeButtonAction = null;
		if (this.dynamicTrayService.trayData) {
			this.dynamicTrayService.trayData.isGradientBackground = false;
		}
	}

	closeTray() {
		this.sendTaggingClickOnXInteraction();
		if (this.topupErrorService.activateXbuttonSubject) {
			this.topupErrorService.xButtonSubject.next();
			return;
		} else {
			this.sendTaggingClickOnXInteraction();
			this.topupErrorService.closeTray();
			this.dynamicTrayService.closeTraySubject.next();
		}
	}

	sendTaggingClickOnXInteraction() {
		if (this.topupErrorService.sendTaggingClickOnXInteractionFlag) {
			const eventName = tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.eventName.replace(
				'<page_name>',
				this.topupErrorService.pageTaggingName
			);
			this.tagging.track(
				eventName,
				tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.data,
				tagging.pciPrepaidPages.koPage
			);
		}
	}
}
