<sp-modal-full #fullModal hideX="true">
	<div class="full modal-unavilability row">
		<div class="modal-body">
			<div class="head">
				<div class="image-modal-unavilability head col-xs-12">
					<p class="title">{{ 'common.errorList.400.6666.title' | translate }}</p>
				</div>
				<div class="image-modal-unavilability col-xs-12 modal-img-position">
					<img class="img-responsive-modal" [src]="imgSrc" />
				</div>
				<div class="body-content">
					<span class="body-content-position" [innerHtml]="'common.errorList.400.6666.description' | translate"> </span>
				</div>
				<div class="buttons-modal-unavilability">
					<button id="UnavilabilityComp_btn_entendido" class="button red" (click)="close()">
						{{ 'common.errorList.400.6666.dismissButton.text' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>
</sp-modal-full>
