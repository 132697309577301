<div class="card-help-loading" *ngIf="loading">
	<sp-skeleton [imageSize]="60" [numberOfBars]="2"> </sp-skeleton>
</div>
<ng-container *ngIf="!loading">
	<div class="card-help-ticket-status vfes-mb" *ngIf="ticketsService.ticketList[TicketTypeEnum.TIA]?.length > 0">
		<mva10-c-button (mva10OnClick)="onShowTicketDetailClick()" [appearance]="'alt1'">
			{{ 'v10.myOrder.cardHelp.autoiTicketButton' | translate }}
		</mva10-c-button>
	</div>

	<div
		class="card-help"
		*ngIf="
			ticketsService.autoiHelp?.showWrongCablesOption ||
			ticketsService.autoiHelp?.showPackageNotReceivedOption ||
			ticketsService.autoiHelp?.showNotWorkingOption ||
			ticketsService.autoiHelp?.showChatOption
		"
		[ngClass]="{ 'with-border': withBorder }"
		[ngStyle]="{ 'grid-template-columns': imageSize + 'px 1fr' }"
	>
		<div
			class="card-help-image"
			[ngClass]="{ 'icon-dsl': imageDsl }"
			[ngStyle]="{ 'background-image': image && !imageDsl ? 'url(' + image + ')' : '' }"
		>
			<svg *ngIf="imageDsl" [ngStyle]="{ 'max-height': imageSize + 'px' }">
				<title>{{ imageDsl }}</title>
				<use [attr.xlink:href]="'#' + imageDsl" class="icon-v3"></use>
			</svg>
		</div>
		<div class="card-help-text">
			<p class="title" *ngIf="title">{{ title }}</p>
			<p class="subtitle" *ngIf="subtitle">{{ subtitle }}</p>
		</div>
		<div class="card-help-button" *ngIf="buttonText">
			<mva10-c-button
				(mva10OnClick)="onButtonClick()"
				type="mva10-c-button--tertiary mva10-c-button--small mva10-c-button--block"
				[spinner]="false"
				[disabled]="false"
			>
				{{ buttonText }}
			</mva10-c-button>
		</div>
	</div>
</ng-container>
