import { Component, Input } from '@angular/core';

@Component({
	selector: 'sp-tooltip-modal',
	templateUrl: './tooltip-modal.component.html',
})
export class TooltipModalComponent {
	@Input() showAsOverlay: boolean;
	isTooltipOverlayOpen: boolean;
}
