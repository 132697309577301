<div class="form-group col-xs-12 dropdown-form">
	<div class="dropdown">
		<label for="{{ id }}">
			{{ label }}
			<span [ngClass]="{ required: required }" *ngIf="required" style="color: red">*</span>
		</label>
		<select
			[ngClass]="{ 'default-option': isDefaultOption, 'other-option': !isDefaultOption }"
			(change)="onChange()"
			[disabled]="isDisabled"
			class="selectpicker form-control"
			name="{{ name }}"
			id="{{ id }}"
			[(ngModel)]="selected"
		>
			<option style="display: none" value="" *ngIf="defaultOptionText">{{ defaultOptionText }}</option>
			<option class="other-option" value="{{ item.value }}" *ngFor="let item of items">
				{{ item.text }}
			</option>
		</select>
	</div>
</div>
