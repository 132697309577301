export enum orderTypes {
	vigentes = 'vigentes',
	completados = 'completados',
	cancelados = 'cancelados',
	returns = 'devoluciones',
}

export enum orderTypesKeys {
	myOrders = 'myOrders',
	inProgress = 'inProgress',
	completed = 'completed',
	canceled = 'canceled',
	returns = 'returns',
}

export enum StatePedido {
	OPEN = '3',
	COMPLETED = '2',
	CANCELLED = '1',
	RETURNS = '4',
}
