<vfac-overlay
	[show]="showOverlay"
	[loaded]="!showLoaded"
	loaderType="vodafone"
	[page]="page"
	[overlayData]="
		page === 1 ? overlayData : { title: '', hideBackButtonOnAllPages: true, hideXButton: true, hideHeader: true }
	"
	(closed)="closeOverlayInfo()"
>
	<div body id="page1">
		<span class="installation__title">
			{{ 'v10.myOrder.requestCancellation.confirmation' | translate }} {{ this.idOrden }} ?</span
		>
		<span class="guide-portability__text"> {{ 'v10.myOrder.requestCancellation.cancelInfo' | translate }} </span>

		<div class="mvf-btn-cancelar-canje">
			<div class="mvf-btn-cancelar-canje--width">
				<mva10-c-button (mva10OnClick)="clickRequestCancelTerminal()">
					{{ 'v10.myOrder.requestCancellation.cancelarCanje' | translate }}
				</mva10-c-button>
			</div>
		</div>
	</div>
	<div body id="page2">
		<div class="confirmPage">
			<img [attr.src]="'v10.myOrder.requestCancellation.img.checkok' | imgFullPath" alt="Check OK" id="imgCheckOK" />
			<div class="title vfes-title--bold mva10-u-heading--3">
				{{ 'v10.myOrder.requestCancellation.sentTitle' | translate }}
			</div>
			<div id="divGPsendBodyText" class="guide-portability__text mva10-u--body">
				{{ 'v10.myOrder.requestCancellation.sentBody' | translate }}
			</div>
		</div>
		<div class="mvf-btn-cancelar-canje">
			<div class="mvf-btn-cancelar-canje--width">
				<mva10-c-button (mva10OnClick)="closeOverlayInfo()">
					{{ 'v10.myOrder.requestCancellation.accept' | translate }}
				</mva10-c-button>
			</div>
		</div>
	</div>
	<div body id="page3">
		<div class="confirmPage">
			<div class="imageContainer">
				<img
					class="image"
					id="MyOrdersSpRequestCancellationErrorImgOK"
					*ngIf="errorEcode !== ecode"
					[attr.src]="'v10.myOrder.requestCancellation.img.alertOk' | imgFullPath"
					alt="alertOk"
				/>
				<img
					class="image"
					id="MyOrdersSpRequestCancellationErrorImg"
					*ngIf="errorEcode === ecode"
					[attr.src]="'v10.myOrder.requestCancellation.img.alert' | imgFullPath"
					alt="alert"
				/>
			</div>
			<div class="col-xs-12">
				<div
					*ngIf="errorEcode === ecode"
					id="MyOrdersSpRequestCancellationErrorOkTitle"
					class="title vfes-title--bold mva10-u-heading--3"
				>
					{{ 'v10.myOrder.requestCancellation.koTitleOk' | translate }}
				</div>
				<div
					*ngIf="errorEcode !== ecode"
					id="MyOrdersSpRequestCancellationErrorTitle"
					class="title vfes-title--bold mva10-u-heading--3"
				>
					{{ 'v10.myOrder.requestCancellation.kotitle' | translate }}
				</div>
				<div id="MyOrdersSpRequestCancellationErrorBody" class="guide-portability__text">{{ errorTitle }}</div>
				<div class="mvf-btn-cancelar-canje">
					<div class="mvf-btn-cancelar-canje--width">
						<mva10-c-button (mva10OnClick)="closeOverlayInfo()">
							{{ 'v10.myOrder.requestCancellation.koaccept' | translate }}
						</mva10-c-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</vfac-overlay>
