import { Component, DoCheck, Input } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { orderTypes } from '../../../../enums/order-type.enum';

@Component({
	selector: 'sp-orders-empty',
	templateUrl: './sp-orders-empty.component.html',
	styleUrls: ['./sp-orders-empty.component.scss'],
})
export class SpOrdersEmptyComponent implements DoCheck {
	@Input() orderType: orderTypes;
	isReturns: boolean;
	isMyOrders: boolean;
	isCancelled: boolean;
	constructor(public appService: AppService) {}

	ngDoCheck(): void {
		this.isReturns = this.orderType === orderTypes.returns;
		this.isMyOrders = this.orderType === orderTypes.vigentes || this.orderType === orderTypes.completados;
		this.isCancelled = this.orderType === orderTypes.cancelados;
	}
}
