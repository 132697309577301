<vfac-overlay
	#routerInstallationGuideOverlay
	[overlayData]="routerInstallationGuideOverlayData"
	[show]="showRouterInstallationGuideOverlay"
	[page]="selectedStep + 1"
	[loaded]="overlayLoaded"
	[loaderType]="'vodafone'"
	(closed)="closeGuideOverlay()"
	(backPage)="goBackwards()"
>
	<div header>
		<div class="mvf-router-installation__dialog-content">
			<div class="mvf-router-installation__header vfes-align--left">
				<mva10-c-steps [steps]="stepsData" class="mva10-u--mt4x" [selected]="selectedStep" [vertical]="false">
				</mva10-c-steps>
			</div>
		</div>
	</div>

	<!-- Step 1 -->
	<div body id="page1">
		<div class="mvf-router-installation__p16">
			<span class="vfes-title--regular"> {{ 'v10.myOrder.routerInstallation.step1desc' | translate }} </span>
			<p
				class="mvf-router-installation__bold-font vfes-title vfes-title--4 mvf-router-installation__pt16"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV"
			>
				{{ 'v10.myOrder.routerInstallation.fiberTitle' | translate }}
			</p>
			<div
				class="mvf-router-installation__display mvf-router-installation__pt16 mvf-router-installation__justify-center"
			>
				<img
					class="mvf-router-installation__img1"
					src="{{ appService.getImgFullPath('v10.myOrder.routerInstallation.img.mobilestep' | translate) }}"
					alt="elementos necesarios"
				/>
			</div>
			<div *ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV">
				<p class="mvf-router-installation__bold-font vfes-title vfes-title--4 mvf-router-installation__pt16">
					{{ 'v10.myOrder.routerInstallation.tvTitle' | translate }}
				</p>
				<img
					class="mvf-router-installation__img vfes-mt vfes-mb"
					src="{{ appService.getImgFullPath('v10.myOrder.routerInstallation.img.contents' | translate) }}"
					alt="contenidos Vodafone TV"
				/>
			</div>
			<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
		</div>
	</div>

	<!-- Step2 -->
	<div body id="page2">
		<div class="mvf-router-installation__p16">
			<div
				class="vfes-title--bold vfes-pb- mvf-router-installation__fs20"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET"
			>
				{{ 'v10.myOrder.routerInstallation.step2title' | translate }}
			</div>
			<div
				class="vfes-title--bold vfes-pb- mvf-router-installation__fs20"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV"
			>
				{{ 'v10.myOrder.routerInstallation.step2titleB' | translate }}
			</div>
			<span
				class="vfes-title--regular"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET"
			>
				{{ 'v10.myOrder.routerInstallation.step2desc' | translate }}
			</span>
			<span
				class="vfes-title--regular"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV"
			>
				{{ 'v10.myOrder.routerInstallation.step2descB' | translate }}
			</span>
			<img
				class="mvf-router-installation__img vfes-mt vfes-mb"
				src="{{ appService.getImgFullPath('v10.myOrder.routerInstallation.img.routercon' | translate) }}"
				alt="cable de fibra"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET"
			/>
			<img
				class="mvf-router-installation__img vfes-mt vfes-mb"
				src="{{ appService.getImgFullPath('v10.myOrder.routerInstallation.img.litter' | translate) }}"
				alt="cable de fibra"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV"
			/>
			<div
				id="spRouterInstallationStep2_2Title"
				class="vfes-title--bold vfes-pb- mvf-router-installation__fs20"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV"
			>
				{{ 'v10.myOrder.routerInstallation.step2_2title' | translate }}
			</div>
			<span
				id="spRouterInstallationStep2_2Desc"
				class="vfes-title--regular"
				[innerHTML]="'v10.myOrder.routerInstallation.step2_2Desc' | translate"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV"
			>
			</span>
			<img
				id="spRouterInstallationStep2_2Control1"
				class="imgControl"
				src="{{ 'v10.myOrder.routerInstallation.img.step2_02_controlGEN1' | imgFullPath }}"
				alt="Gen1"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV"
			/>
			<span
				class="vfes-title--regular"
				id="spRouterInstallationStep2_2ImgDesc"
				[innerHTML]="'v10.myOrder.routerInstallation.step2_2ImgDesc' | translate"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV"
			></span>
			<img
				id="spRouterInstallationStep2_Control3"
				class="imgControl"
				src="{{ 'v10.myOrder.routerInstallation.img.step2_02_controlGEN3' | imgFullPath }}"
				alt="Gen3"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV"
			/>
			<span
				class="vfes-title--regular"
				id="spRouterInstallationStep2_2ImgDescB"
				[innerHTML]="'v10.myOrder.routerInstallation.step2_2ImgDescB' | translate"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV"
			></span>
			<div
				class="vfes-title--bold vfes-pb- mvf-router-installation__fs20"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET"
			>
				{{ 'v10.myOrder.routerInstallation.step2_2title' | translate }}
			</div>
			<div
				id="spRouterInstallationStep2_3titleB"
				class="vfes-title--bold vfes-pb- mvf-router-installation__fs20 mva10-u--mt5"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV"
			>
				{{ 'v10.myOrder.routerInstallation.step2_3titleB' | translate }}
			</div>
			<span
				id="spRouterInstallationStep2_3descB"
				class="vfes-title--regular"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV"
			>
				{{ 'v10.myOrder.routerInstallation.step2_3descB' | translate }}
			</span>
			<img
				class="mvf-router-installation__img vfes-mt vfes-mb"
				src="{{ appService.getImgFullPath('v10.myOrder.routerInstallation.img.phonecon' | translate) }}"
				alt="conexión ethernet"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET"
			/>
			<img
				id="spRouterInstallationStep2_03Img"
				class="mvf-router-installation__img vfes-mt vfes-mb"
				src="{{ 'v10.myOrder.routerInstallation.img.step2_03' | imgFullPath }}"
				alt="conexión ethernet"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV"
			/>
			<div
				class="vfes-title--bold vfes-pb- mvf-router-installation__fs20"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET"
			>
				{{ 'v10.myOrder.routerInstallation.step2_3title' | translate }}
			</div>
			<div
				id="spRouterInstallationStep2_4TitleB"
				class="vfes-title--bold vfes-pb- mvf-router-installation__fs20"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV"
			>
				{{ 'v10.myOrder.routerInstallation.step2_4titleB' | translate }}
			</div>
			<span
				class="vfes-title--regular"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET"
			>
				{{ 'v10.myOrder.routerInstallation.step2_3desc' | translate }}
			</span>
			<img
				class="mvf-router-installation__img vfes-mt vfes-mb"
				src="{{ appService.getImgFullPath('v10.myOrder.routerInstallation.img.wificon3' | translate) }}"
				alt="cable de alimentación"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET"
			/>
			<img
				id="spRouterInstallationStep2_04Img"
				class="mvf-router-installation__img vfes-mt vfes-mb"
				src="{{ 'v10.myOrder.routerInstallation.img.step2_04' | imgFullPath }}"
				alt="conexión ethernet"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV"
			/>
			<!-- Instrucciones extra HFC 3P -->
			<div *ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV">
				<div id="spRouterInstallationStep2_5TitleB" class="vfes-title--bold vfes-pb- mvf-router-installation__fs20">
					{{ 'v10.myOrder.routerInstallation.step2_5titleB' | translate }}
				</div>
				<span class="vfes-title--regular" id="spRouterInstallationStep2_5descB">
					{{ 'v10.myOrder.routerInstallation.step2_5descB' | translate }}
				</span>
				<img
					id="spRouterInstallationStep2_05Img"
					class="mvf-router-installation__img vfes-mt vfes-mb"
					src="{{ 'v10.myOrder.routerInstallation.img.step2_05' | imgFullPath }}"
					alt="cable ethernet a los dispositivos"
				/>

				<div id="spRouterInstallationStep2_6titleB" class="vfes-title--bold vfes-pb- mvf-router-installation__fs20">
					{{ 'v10.myOrder.routerInstallation.step2_6titleB' | translate }}
				</div>
				<span class="vfes-title--regular" id="spRouterInstallationStep2_6descB">
					{{ 'v10.myOrder.routerInstallation.step2_6descB' | translate }}
				</span>
				<img
					id="spRouterInstallationStep2_06Img"
					class="mvf-router-installation__img vfes-mt vfes-mb"
					src="{{ 'v10.myOrder.routerInstallation.img.step2_06' | imgFullPath }}"
					alt="cable hdmi a deco y tv"
				/>
				<div id="spRouterInstallationStep2_7TitleB" class="vfes-title--bold vfes-pb- mvf-router-installation__fs20">
					{{ 'v10.myOrder.routerInstallation.step2_7titleB' | translate }}
				</div>
				<span class="vfes-title--regular" id="spRouterInstallationStep2_7descB">
					{{ 'v10.myOrder.routerInstallation.step2_7descB' | translate }}
				</span>
				<img
					id="spRouterInstallationStep2_2Title"
					class="mvf-router-installation__img vfes-mt vfes-mb"
					src="{{ 'v10.myOrder.routerInstallation.img.step2_07' | imgFullPath }}"
					alt="cable de alimentación"
				/>
				<div id="spRouterInstallationStep2_8titleB" class="vfes-title--bold vfes-pb- mvf-router-installation__fs20">
					{{ 'v10.myOrder.routerInstallation.step2_8titleB' | translate }}
				</div>
				<span class="vfes-title--regular" id="spRouterInstallationStep2_8descB">
					{{ 'v10.myOrder.routerInstallation.step2_8descB' | translate }}
				</span>
				<img
					id="spRouterInstallationStep2_08Img"
					class="mvf-router-installation__img vfes-mt vfes-mb"
					src="{{ 'v10.myOrder.routerInstallation.img.step2_08' | imgFullPath }}"
					alt="television"
				/>
				<div
					id="spRouterInstallationStep2_8Btn"
					class="mvf-router-installation__button-top mvf-router-installation__p16 vfes-align--center"
				>
					<mva10-c-button (mva10OnClick)="onNextButtonClick()">
						{{ 'v10.myOrder.routerInstallation.step2_8Btn' | translate }}
					</mva10-c-button>
				</div>
				<!-- STEP 12 al 17 GEN3 -->
				<div id="spRouterInstallationContentsStep12to17Gen3">
					<div id="spRouterInstallationStep2_12Title" class="vfes-title--bold vfes-pb- mvf-router-installation__fs20">
						{{ 'v10.myOrder.routerInstallation.step2_12Title' | translate }}
					</div>
					<span class="vfes-title--regular" id="spRouterInstallationStep2_12Desc">
						{{ 'v10.myOrder.routerInstallation.step2_12Desc' | translate }}
					</span>
					<div id="spRouterInstallationStep2_12ImgGen3">
						<img
							class="mvf-router-installation__img vfes-mt vfes-mb"
							src="{{ 'v10.myOrder.routerInstallation.img.step2_12GEN3' | imgFullPath }}"
							alt="television"
						/>
					</div>
					<div id="spRouterInstallationStep2_13Title" class="vfes-title--bold vfes-pb- mvf-router-installation__fs20">
						{{ 'v10.myOrder.routerInstallation.step2_13Title' | translate }}
					</div>
					<div id="spRouterInstallationStep2_13Img">
						<img
							id="spRouterInstallationStep2_13ImgGen3"
							class="mvf-router-installation__img vfes-mt vfes-mb"
							src="{{ 'v10.myOrder.routerInstallation.img.step2_04' | imgFullPath }}"
							alt="television"
						/>
					</div>
					<div id="spRouterInstallationStep2_14Title" class="vfes-title--bold vfes-pb- mvf-router-installation__fs20">
						{{ 'v10.myOrder.routerInstallation.step2_14Title' | translate }}
					</div>
					<span class="vfes-title--regular" id="spRouterInstallationStep2_14Desc">
						{{ 'v10.myOrder.routerInstallation.step2_14Desc' | translate }}
					</span>
					<div>
						<img
							id="spRouterInstallationStep2_14Img"
							class="mvf-router-installation__img vfes-mt vfes-mb"
							src="{{ 'v10.myOrder.routerInstallation.img.step2_14GEN3' | imgFullPath }}"
							alt="television"
						/>
					</div>
					<div id="spRouterInstallationStep2_15Title" class="vfes-title--bold vfes-pb- mvf-router-installation__fs20">
						{{ 'v10.myOrder.routerInstallation.step2_15Title' | translate }}
					</div>
					<span id="spRouterInstallationStep2_15Desc" class="vfes-title--regular">
						{{ 'v10.myOrder.routerInstallation.step2_6descB' | translate }}
					</span>
					<div id="spRouterInstallationStep2_15ImgDiv">
						<img
							id="spRouterInstallationStep2_15ImgGEN3"
							class="mvf-router-installation__img vfes-mt vfes-mb"
							src="{{ 'v10.myOrder.routerInstallation.img.step2_15GEN3' | imgFullPath }}"
							alt="television"
						/>
					</div>
					<div id="spRouterInstallationStep2_16Title" class="vfes-title--bold vfes-pb- mvf-router-installation__fs20">
						{{ 'v10.myOrder.routerInstallation.step2_16Title' | translate }}
					</div>
					<span id="spRouterInstallationStep2_16descB" class="vfes-title--regular">
						{{ 'v10.myOrder.routerInstallation.step2_7descB' | translate }}
					</span>
					<div id="spRouterInstallationStep2_16Btn">
						<img
							id="spRouterInstallationStep2_16BtnGen3"
							class="mvf-router-installation__img vfes-mt vfes-mb"
							src="{{ 'v10.myOrder.routerInstallation.img.step2_07' | imgFullPath }}"
							alt="television"
						/>
					</div>
					<div id="spRouterInstallationStep2_17Title" class="vfes-title--bold vfes-pb- mvf-router-installation__fs20">
						{{ 'v10.myOrder.routerInstallation.step2_17Title' | translate }}
					</div>
					<span class="vfes-title--regular" id="spRouterInstallationStep2_17DescB">
						{{ 'v10.myOrder.routerInstallation.step2_8descB' | translate }}
					</span>
					<div id="spRouterInstallationStep2_17Img">
						<img
							id="spRouterInstallationStep2_17ImgGen3"
							class="mvf-router-installation__img vfes-mt vfes-mb"
							src="{{ 'v10.myOrder.routerInstallation.img.step2_17GEN3' | imgFullPath }}"
							alt="television"
						/>
					</div>
				</div>
			</div>
		</div>
		<div
			*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET"
			id="spRouterInstallationStep2_BtnInternet"
		>
			<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
		</div>
		<div
			*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV"
			id="spRouterInstallationStep2_BtnInternetAndTv"
		>
			<div
				id="spRouterInstallationStep2_17Btn2"
				class="mvf-router-installation__button-top mvf-router-installation__p16 vfes-align--center"
			>
				<mva10-c-button (mva10OnClick)="onNextButtonClick()">
					{{ 'v10.myOrder.routerInstallation.step2_17Btn' | translate }}
				</mva10-c-button>
			</div>
		</div>
	</div>

	<!-- Step 3 -->
	<div body id="page3">
		<div class="mvf-router-installation__p16">
			<div *ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET">
				<div class="vfes-title--bold vfes-pb- mvf-router-installation__fs20">
					{{ 'v10.myOrder.routerInstallation.step3_title1' | translate }}
				</div>
				<span class="vfes-title--regular"> {{ 'v10.myOrder.routerInstallation.step3_desc1' | translate }} </span>
				<div class="mvf-router-installation__display mvf-router-installation__pt16 mvf-router-installation__pb16">
					<img
						class="mvf-router-installation__img1 mvf-router-installation__desktop-p"
						src="{{ appService.getImgFullPath('v10.myOrder.routerInstallation.img.init1' | translate) }}"
						alt="luces router wi-fi"
					/>
				</div>
				<span class="vfes-title--regular"> {{ 'v10.myOrder.routerInstallation.step3_desc2' | translate }} </span>
			</div>
			<div *ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV">
				<div id="spRouterInstallationStep3_9TitleB" class="vfes-title--bold vfes-pb- mvf-router-installation__fs20">
					{{ 'v10.myOrder.routerInstallation.step3_9titleB' | translate }}
				</div>
				<span id="spRouterInstallationStep3_9DescB" class="vfes-title--regular">
					{{ 'v10.myOrder.routerInstallation.step3_9descB' | translate }}
				</span>
				<div class="vfes-align--center" id="spRouterInstallationStep3_9ImgCenter">
					<img
						id="spRouterInstallationStep3_9Img"
						class="vfes-mt vfes-mb"
						src="{{ 'v10.myOrder.routerInstallation.img.loading' | imgFullPath }}"
						alt="television"
					/>
				</div>
				<div id="spRouterInstallationStep3_10Title" class="vfes-title--bold vfes-pb- mvf-router-installation__fs20">
					{{ 'v10.myOrder.routerInstallation.step3_10Title' | translate }}
				</div>
				<span
					class="vfes-title--regular"
					id="spRouterInstallationStep3_10Desc"
					[innerHTML]="'v10.myOrder.routerInstallation.step3_10Desc' | translate"
				>
				</span>
				<div id="spRouterInstallationStep3_10Img">
					<img
						id="spRouterInstallationStep3_10ImgRemote"
						class="mvf-router-installation__img vfes-mt vfes-mb"
						src="{{ 'v10.myOrder.routerInstallation.img.step3_remoteControl' | imgFullPath }}"
						alt="television"
					/>
				</div>
				<div id="spRouterInstallationStep3_11Title" class="vfes-title--bold vfes-pb- mvf-router-installation__fs20">
					{{ 'v10.myOrder.routerInstallation.step3_11Title' | translate }}
				</div>
				<span
					class="vfes-title--regular"
					id="spRouterInstallationStep3_11Desc"
					[innerHTML]="'v10.myOrder.routerInstallation.step3_11Desc' | translate"
				>
				</span>
				<div id="spRouterInstallationStep3_11Img">
					<img
						id="spRouterInstallationStep3_11Img2"
						class="mvf-router-installation__img vfes-mt vfes-mb"
						src="{{ 'v10.myOrder.routerInstallation.img.step3_11' | imgFullPath }}"
						alt="television"
					/>
				</div>

				<ng-container id="spRouterInstallationStep3TemplateBtn" *ngTemplateOutlet="navigationButtonsTemplate">
				</ng-container>

				<div id="spRouterInstallationStep3_18Title" class="vfes-title--bold vfes-pb- mvf-router-installation__fs20">
					{{ 'v10.myOrder.routerInstallation.step3_18Title' | translate }}
				</div>
				<div class="displayDescription" id="spRouterInstallationStep3_18Content">
					<span class="vfes-title--regular" id="spRouterInstallationStep3_18Des1">
						{{ 'v10.myOrder.routerInstallation.step3_9descB' | translate }}
					</span>
					<span class="vfes-title--regular mva10-u--mt2" id="spRouterInstallationStep3_18Des">
						{{ 'v10.myOrder.routerInstallation.step3_18Desc' | translate }}
					</span>
					<span class="vfes-title--regular mva10-u--mt2" id="spRouterInstallationStep3_18DesB">
						{{ 'v10.myOrder.routerInstallation.step3_18DescB' | translate }}
					</span>
				</div>
				<div id="spRouterInstallationStep3_18Img">
					<img
						id="spRouterInstallationStep3_18ImgGen3"
						class="mvf-router-installation__img vfes-mt vfes-mb"
						src="{{ 'v10.myOrder.routerInstallation.img.loading' | imgFullPath }}"
						alt="television"
					/>
				</div>
				<div id="spRouterInstallationStep3_19Title" class="vfes-title--bold vfes-pb- mvf-router-installation__fs20">
					{{ 'v10.myOrder.routerInstallation.step3_19Title' | translate }}
				</div>
				<span
					class="vfes-title--regular"
					id="spRouterInstallationStep3_19Desc"
					[innerHTML]="'v10.myOrder.routerInstallation.step3_10Desc' | translate"
				>
				</span>
				<div id="spRouterInstallationStep3_19Img">
					<img
						id="spRouterInstallationStep3_19ImgGen3"
						class="mvf-router-installation__img vfes-mt vfes-mb"
						src="{{ 'v10.myOrder.routerInstallation.img.step3_remoteControl' | imgFullPath }}"
						alt="television"
					/>
				</div>
				<div id="spRouterInstallationStep3_20Title" class="vfes-title--bold vfes-pb- mvf-router-installation__fs20">
					{{ 'v10.myOrder.routerInstallation.step3_20Title' | translate }}
				</div>
				<div class="displayDescription" id="spRouterInstallationStep3_20Content">
					<span
						class="vfes-title--regular"
						id="spRouterInstallationStep3_20Desc"
						[innerHTML]="'v10.myOrder.routerInstallation.step3_20Desc' | translate"
					>
					</span>
					<span
						class="vfes-title--regular mva10-u--mt2"
						id="spRouterInstallationStep3_20DescB"
						[innerHTML]="'v10.myOrder.routerInstallation.step3_20DescB' | translate"
					>
					</span>
					<span class="vfes-title--regular mva10-u--mt2" id="spRouterInstallationStep3_20DescC">
						{{ 'v10.myOrder.routerInstallation.step3_20DescC' | translate }}>
					</span>
				</div>
				<div id="spRouterInstallationStep3_20Img">
					<img
						id="spRouterInstallationStep3_20ImgGen3"
						class="mvf-router-installation__img vfes-mt vfes-mb"
						src="{{ 'v10.myOrder.routerInstallation.img.step3_20GEN3' | imgFullPath }}"
						alt="television"
					/>
				</div>
			</div>
			<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
		</div>
	</div>
</vfac-overlay>

<ng-template #navigationButtonsTemplate>
	<!-- Navigation buttons -->
	<div class="mvf-router-installation__button-top mvf-router-installation__p16 vfes-align--center">
		<mva10-c-button (mva10OnClick)="onNextButtonClick()">
			{{
				(disableSchamanCheck
					? selectedStep === pagesNames.length - 1
						? 'entendido'
						: 'v10.myOrder.routerInstallation.installerRouter'
					: selectedStep === pagesNames.length - 2
					? 'v10.myOrder.routerInstallation.autoiCheckInstallationButton'
					: 'v10.myOrder.routerInstallation.installerRouter'
				) | translate
			}}
		</mva10-c-button>
	</div>
</ng-template>

<sp-check-installation-overlay
	[openFrom]="pageName"
	*ngIf="showOverlayCheckInstallation"
	(close)="closeCheckInstallationOverlay($event)"
>
</sp-check-installation-overlay>
