import { CookieService } from 'ngx-cookie';
import { Injectable } from '@angular/core';

@Injectable()
export class CookieHelper {
	constructor(private _cookieService: CookieService) {}

	resetUtagValue() {
		/* the below code used for get domin url as tealium use*/
		const a = '' + location.hostname;
		const b = a.split('.');
		const c = /\.co\.|\.com\.|\.org\.|\.edu\.|\.net\.|\.asn\.|\...\.jp$/.test(a) ? 3 : 2;
		const domain = b.splice(b.length - c, c).join('.');
		this._cookieService.put('utag_main', '', { expires: 'Thu, 01 Jan 1970 00:00:01 GMT', path: '/', domain: domain });
	}
}
