import { Component, Input, OnInit } from '@angular/core';
import { DetallePedidoExtended } from '../../../../models/detalleSeguimientoPedido.model';
import { UtilsService } from '../../services/utils.service';
import { MyOrdersTaggingService } from '../../../../services/my-orders-tagging.service';

@Component({
	selector: 'sp-summary',
	templateUrl: './sp-summary.component.html',
	styleUrls: ['./sp-summary.component.scss'],
})
export class SpSummaryComponent implements OnInit {
	@Input() orderData: DetallePedidoExtended;
	public summaryData: { incluidoPedido: string[]; permanencias: string[] };

	constructor(private utilsService: UtilsService, private tagging: MyOrdersTaggingService) {}

	ngOnInit(): void {
		this.summaryData = Object.assign(
			{},
			{
				incluidoPedido: this.orderData.incluidoPedido.filter((elem) => !!elem),
				permanencias: this.orderData.permanencias,
			}
		);
	}

	summaryTypes(): string[] {
		return Object.keys(this.orderData).filter((key) => ['incluidoPedido', 'permanencias', 'pagoUnico'].includes(key));
	}

	getSummaryTitle(type: string): string {
		const typeTitle: { [key: string]: string } = {
			incluidoPedido: 'Qué incluye este pedido',
			pagoUnico: 'Además, estas cantidades solo te las cobraremos una vez:',
			permanencias: 'Servicios sobre los que se aplica un compromiso con Vodafone:',
		};
		return typeTitle[type];
	}

	redirectToContracts(): void {
		this.tagging.sendInteraction({
			link_type: 'click en acceder resumen de compra',
		});
		this.utilsService.postMessageToParent(
			JSON.stringify({
				action: 'forward',
				message: 'contratos-documentacion',
			})
		);
	}
}
