import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OverlayModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { ChangeEEValue } from '../../../enums/ee-status.enum';
import { MyOrdersService } from '../services/my-orders.service';
import { OrdersService } from '../services/orders.service';
import { MyOrdersTaggingService } from '../../../services/my-orders-tagging.service';
import { ErrorCancel } from '../../../models/cancelarCanjeBody.model';
import { CancellationsTerminals, koCancellationsTerminals } from '../../../constants/defines';
import { MyOrdersTagService } from '../services/myOrderTag.service';

@Component({
	selector: 'sp-request-cancellation',
	templateUrl: './sp-request-cancellation.component.html',
	styleUrls: ['./sp-request-cancellation.component.scss'],
})
export class SpRequestCancellationComponent implements OnInit {
	@Input() page: number = 1;
	/**
	 * Returns true if page reload is needed
	 */
	@Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
	overlayData: OverlayModel = {
		title: 'Solicitar cancelación',
		hideBackButtonOnAllPages: true,
		isCenterTitle: true,
	};
	showOverlay: boolean;
	showLoaded: boolean = false;
	eeStatusValueEnum: typeof ChangeEEValue = ChangeEEValue;
	idCliente: string;
	idOrden: string;
	public errorTitle: string;
	public errorEcode: string;
	public ecode: string = CancellationsTerminals;
	constructor(
		public ordersService: OrdersService,
		private myOrdersService: MyOrdersService,
		public translate: TranslateService,
		public appService: AppService,
		private tagging: MyOrdersTaggingService,
		public myOrdersTagService: MyOrdersTagService
	) {}

	ngOnInit(): void {
		this.showOverlay = true;
		this.idCliente = this.myOrdersService.MYORDERS_DATA.idCliente;
		this.idOrden = this.ordersService.detailOrderData.numeroPedido;
		this.doSendView();
	}

	doSendView(): void {
		this.resetTagging();
		this.tagging.sendPage(['solicitud cancelacion']);
	}

	ngOnDestroy(): void {
		this.resetTagging();
		this.tagging.extendUtagPageName(['solicitud cancelacion'], null, true);
		this.tagging.extendUtagPageName(['detalle pedido']);
	}

	clickRequestCancelTerminal(): void {
		this.tagging.sendInteraction({
			link_type: 'click en confirmar solicitar cancelacion',
		});
		this.ordersService
			.cancelarCanje(this.idCliente, this.idOrden, this.eeStatusValueEnum.CODE_EE_NEWCANCELAR)
			.subscribe({
				next: () => {
					this.page = 2;
					this.resetTagging();
					this.tagging.sendPage(['ok']);
				},
				error: (error: ErrorCancel) => {
					this.errorTitle = this.translate.instant(`v10.myOrder.requestCancellation.koBodies.${error.error.ecode}`);
					this.errorEcode = error.error.ecode;
					this.page = 3;
					this.resetTagging();
					if (this.errorEcode !== this.ecode) {
						this.tagging.sendPage([koCancellationsTerminals]);
					} else {
						this.myOrdersTagService.tagModalStateCancellations();
					}
				},
			});
	}

	closeOverlayInfo(): void {
		this.showOverlay = false;
		setTimeout(() => {
			// Reload page after OK response (page 2)
			this.closed.emit(this.page === 2);
			this.page = 1;
		}, 500);
		this.tagging.sendInteraction({
			link_type: 'click en volver a mis pedidos',
		});
	}

	resetTagging(): void {
		this.tagging.extendUtagPageName(['ok', 'ko'], null, true);
	}
}
