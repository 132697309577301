<div class="content">
	<div class="card">
		<img
			class="sfy_icon card_icon"
			alt="Img"
			src="{{ icon | imgFullPath }}"
			id="SharedComponentDistributedMiniCardIcon"
		/>
		<div [ngClass]="sizeTextBig ? 'card_text' : 'card_text_mini'" id="SharedComponentDistributedMiniCardText">
			{{ text }}
		</div>
	</div>
</div>
