import { Component, OnInit, OnDestroy } from '@angular/core';
import { SpMva10OverlayService } from '../../shared/services/sp-mva10-overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { tagging } from '../../../config/tagging-config';
import { TopUpService } from '../top-up.service';
import { config } from '../../../config/pages-config';
import { Router } from '@angular/router';
import {
	TopupPrepaidStages,
	STORE_URL,
	PaymentCardJournies,
	defines,
	vodafoneEsRecarga,
} from '../../shared/constants/defines';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OperatingSystem } from '../../shared/enums/operating-systems.enum';
import { AppService } from '../../app.service';
import { PaymentCardsService } from '../../shared/services/payment-cards.service';
import { StorageService } from '../../core/services/storage.service';
import { PaymentCardPatchModel, PaymentCardModel } from '../../models/payment-card.model';
import { LanguageEnum } from '../../shared/enums/language.enum';
import { DynamicTrayService } from '../../shared/services/dynamic-tray.service';
import { DashboardService } from '../../shared/services/dashboard.service';
import { BillingService } from '../../billing/billing.service';
import { CrossVariables } from '../../models/tagging.model';
import { Breakpoints } from '@mva10/mva10-angular';
import { UtilitiesService } from '../../shared/utils/utilities.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
@Component({
	selector: 'sp-top-up-confirmation-overlay',
	templateUrl: './top-up-confirmation-overlay.component.html',
	styleUrls: ['./top-up-confirmation-overlay.component.scss'],
})
export class TopUpConfirmationOverlayComponent implements OnInit, OnDestroy {
	confirmationDescLine1: any;
	confirmationDescLine2: any;
	confirmationButtonText = '';
	secondButtonText = '';
	uiModel = {
		refills: '',
		sucessTopupMsg_title: '',
		successForWeb_title: '',
		successForWeb_description: '',
		successForWebText: '',
		controlBenefits_description: '',
		rechargeEasier_description: '',
		confirmButton: '',
	};
	hideContent = false;
	showSaveCardToggle = false;
	/** make toggle OFF in case of postpaid payment (debt, cash desk & in advance) */
	saveCreditCard: boolean = !this.billService.startBillingPayment;
	spanishAnonymousDesc: string = '';
	englishAnonymousDesc: string = '';
	constructor(
		public trayService: SpMva10OverlayService,
		private translate: TranslateService,
		public topupService: TopUpService,
		private tagging: TaggingHelperService,
		private router: Router,
		public deviceService: DeviceDetectorService,
		public appService: AppService,
		private paymentCardsService: PaymentCardsService,
		private storageService: StorageService,
		private dynamicTrayService: DynamicTrayService,
		public billService: BillingService,
		private dashboardService: DashboardService,
		private sanitizer: DomSanitizer
	) {}

	ngOnInit() {
		this.sendPageTagging();
		this.setPageUi();
		this.showSaveCreditCardLogic();
		this.topupService.dynamicTrayData.closeButtonAction = this.closeTrayWithTagging.bind(this);
		if (this.dynamicTrayService.trayData) {
			/** isMobileView should be true in case of mobile view */
			const isMobileView: boolean = window.innerWidth < Breakpoints.TABLET;
			/** open the tray with gradient background */
			this.dynamicTrayService.trayData.isGradientBackground = true;
			this.dynamicTrayService.trayData.isContentBodyFullHeight = true;
			this.dynamicTrayService.trayData.isBoldTitle = !isMobileView;
			this.dynamicTrayService.trayData.removeTrayPadding = true;
		}
	}

	setPageUi() {
		this.trayService.fullScreenTray = true;
		this.setUiContent(this.topupService.topupLanguage);
		if (!this.topupService.isAnonymousProcess) {
			this.translate.get('payment.buttonList').subscribe((data) => {
				this.confirmationButtonText = data.viewPaymentTicketButton.text;
			});
		}
	}
	setUiContent(lan: LanguageEnum = LanguageEnum.spanish): void {
		this.handleConfirmationScreenForPrepaid(lan);
	}

	handleConfirmationScreenForPostPaid(): void {
		if (this.billService.isDeptPayment) {
			if (this.billService.showPayPartial) {
				this.translate.get('payment.messagesList.successfulPartialPayment').subscribe((data) => {
					this.confirmationButtonText = data.confirmButton.text;
					this.secondButtonText = data.confirmButton.text;
					const amount: string = this.billService.roundAmount(
						this.billService.deptAmount - this.topupService.curruentSelectedAmountSelectorValue
					);
					this.uiModel.sucessTopupMsg_title = data.description.replace('{0}', `${amount}`);
					this.confirmationDescLine1 = data.subtitle;
				});
			} else {
				this.translate.get('payment.messagesList.successfulDebtPayment').subscribe((data) => {
					this.confirmationButtonText = data.confirmButton.text;
					this.secondButtonText = data.confirmButton.text;
					this.uiModel.sucessTopupMsg_title = data.description;
				});
			}
		} else if (this.billService.isCashDeskPayment) {
			if (this.billService.showPayPartial) {
				this.translate.get('payment.messagesList.successfulPartialCashDeskPayment').subscribe((data) => {
					this.confirmationButtonText = data.confirmButton.text;
					const amount = this.billService.roundAmount(this.topupService.curruentSelectedAmountSelectorValue);
					this.uiModel.sucessTopupMsg_title = data.description.replace('{0}', `${amount}`);
					this.confirmationDescLine1 = data.subtitle;
				});
			} else {
				this.translate.get('payment.messagesList.successfulTotalCashDeskPayment').subscribe((data) => {
					this.confirmationButtonText = data.confirmButton.text;
					this.uiModel.sucessTopupMsg_title = data.description;
				});
			}
		} else if (this.billService.isInAdvancePayment) {
			this.translate.get('payment.messagesList.successfulNoDebtPayment').subscribe((data) => {
				this.confirmationButtonText = data.confirmButton.text;
				const amount: string = this.billService.roundAmount(this.topupService.curruentSelectedAmountSelectorValue);
				this.uiModel.sucessTopupMsg_title = data.description.replace('{0}', `${amount + defines.euro}`);
			});
		}
	}

	handleConfirmationScreenForPrepaid(lan: LanguageEnum): void {
		if (this.topupService.isOpenedFromLoginTabs || this.topupService.isAnonymousProcess) {
			this.topupService.showSecondCTA = true;
		}
		switch (lan) {
			case LanguageEnum.spanish:
				this.translate
					.get('v10.payment.messagesList.anonymousPrepaidTopup.es.paymentSuccessful.description')
					.subscribe((data) => {
						this.spanishAnonymousDesc = data;
					});
				this.translate.get('payment').subscribe((data) => {
					if (data.messagesList.sucessTopupMsg.description.indexOf('<br') !== -1) {
						const description: string = this.topupService.isAnonymousProcess
							? this.spanishAnonymousDesc
							: data.messagesList.sucessTopupMsg_en.description;
						const descSplittedLine2: string[] = description.split('>');
						this.confirmationDescLine1 = this.sanitizer.bypassSecurityTrustHtml(description.split('<')[0]);
						this.confirmationDescLine2 = this.sanitizer.bypassSecurityTrustHtml(
							descSplittedLine2[descSplittedLine2.length - 1]
						);
					} else {
						this.confirmationDescLine1 = this.topupService.isAnonymousProcess
							? this.sanitizer.bypassSecurityTrustHtml(this.spanishAnonymousDesc)
							: this.sanitizer.bypassSecurityTrustHtml(data.messagesList.sucessTopupMsg.description);
					}
					this.confirmationButtonText = data.messagesList.sucessTopupMsg.confirmButton.text;
					if (this.topupService.showSecondCTA) {
						this.secondButtonText = data.buttonList.cancelViewTicketDebt.text;
					}
					this.uiModel.sucessTopupMsg_title = data.messagesList.sucessTopupMsg.title;
				});
				this.translate.get('common').subscribe((data) => {
					this.uiModel.refills = data.itemsList.refills.body;
				});
				if (!this.topupService.isMobileView) {
					this.translate.get('common.buttonList.annoymousAcceptBtn.text').subscribe((data) => {
						this.confirmationButtonText = data;
					});
				}
				break;
			case LanguageEnum.english:
				this.translate
					.get('v10.payment.messagesList.anonymousPrepaidTopup.en.paymentSuccessful.description')
					.subscribe((data) => {
						this.englishAnonymousDesc = data;
					});
				this.translate.get('payment').subscribe((data) => {
					if (data.messagesList.sucessTopupMsg_en.description.indexOf('<br') !== -1) {
						const description: string = this.topupService.isAnonymousProcess
							? this.englishAnonymousDesc
							: data.messagesList.sucessTopupMsg_en.description;
						const descSplittedLine2: string[] = description.split('>');
						this.confirmationDescLine1 = this.sanitizer.bypassSecurityTrustHtml(description.split('<')[0]);
						this.confirmationDescLine2 = this.sanitizer.bypassSecurityTrustHtml(
							descSplittedLine2[descSplittedLine2.length - 1]
						);
					} else {
						this.confirmationDescLine1 = this.topupService.isAnonymousProcess
							? this.sanitizer.bypassSecurityTrustHtml(this.englishAnonymousDesc)
							: this.sanitizer.bypassSecurityTrustHtml(data.messagesList.sucessTopupMsg_en.description);
					}
					this.confirmationButtonText = data.messagesList.sucessTopupMsg_en.confirmButton.text;
					if (this.topupService.showSecondCTA) {
						this.secondButtonText = data.buttonList.closeButton_en.text;
					}
					this.uiModel.sucessTopupMsg_title = data.messagesList.sucessTopupMsg_en.title;
				});
				this.translate.get('common').subscribe((data) => {
					this.uiModel.refills = data.itemsList.refills_en.body;
				});
				if (!this.topupService.isMobileView) {
					this.translate.get('common.buttonList.annoymousAcceptBtn_en.text').subscribe((data) => {
						this.confirmationButtonText = data;
					});
				}
				break;
		}
	}

	sendPageTagging() {
		if (this.billService.startBillingPayment) {
			const paymentPageName = Object.assign({}, tagging.paymentDonePageName);
			const paymentJourney = Object.assign({}, tagging.paymentDoneJourney);
			if (this.billService.isDeptPayment) {
				paymentPageName.page_name = paymentPageName.page_name.replace(
					'<payment_page_name>',
					tagging.staticVariables.paymentDeptPageName
				);
				paymentPageName.page_subcategory_level_2 = paymentPageName.page_subcategory_level_2.replace(
					'<payment_page_name>',
					tagging.staticVariables.paymentDeptPageName
				);
				paymentJourney['journey_payment_type'] = this.billService.showPayPartial
					? tagging.staticVariables.payPartial
					: tagging.staticVariables.payTotal;
				paymentJourney.journey_process = tagging.staticVariables.paymentDeptJourneyProcess;
			} else if (this.billService.isCashDeskPayment) {
				paymentPageName.page_name = paymentPageName.page_name.replace(
					'<payment_page_name>',
					tagging.staticVariables.paymentCashDeskPageName
				);
				paymentPageName.page_subcategory_level_2 = paymentPageName.page_subcategory_level_2.replace(
					'<payment_page_name>',
					tagging.staticVariables.paymentCashDeskPageName
				);
				paymentJourney['journey_payment_type'] = this.billService.showPayPartial
					? tagging.staticVariables.payPartial
					: tagging.staticVariables.payTotal;
				paymentJourney.journey_process = tagging.staticVariables.paymentCashDeskJourneyProcess;
			} else if (this.billService.isInAdvancePayment) {
				paymentPageName.page_name = paymentPageName.page_name.replace(
					'<payment_page_name>',
					tagging.staticVariables.paymentInAdvancePageName
				);
				paymentPageName.page_subcategory_level_2 = paymentPageName.page_subcategory_level_2.replace(
					'<payment_page_name>',
					tagging.staticVariables.paymentInAdvancePageName
				);
				paymentJourney.journey_process = tagging.staticVariables.paymentInAdvanceJourneyProcess;
			}
			paymentJourney.journey_category = CrossVariables.client_typology;
			paymentJourney.journey_card_selected = this.topupService.selectedCreditCard
				? tagging.staticVariables.savedCreditCard
				: tagging.staticVariables.newCreditCard;
			paymentJourney.payment_id = this.topupService.transactionId;
			this.tagging.view(paymentPageName, paymentJourney);
		} else {
			const service = this.topupService.msisdn
				? this.topupService.msisdn
				: this.topupService.newPciSelectedService
				? this.topupService.newPciSelectedService.id
				: '';
			if (service) {
				this.tagging.encryptApi(service).subscribe((data) => {
					tagging.pciPrepaid.journys.success.data.mobile_recharge = data;
					tagging.pciPrepaid.journys.success.data.journey_options =
						this.topupService.curruentSelectedAmountSelectorValue.toString();
					tagging.pciPrepaid.journys.success.data.journey_subcategory =
						this.topupService.setTaggingJournySubCategoryValue();
					tagging.pciPrepaid.journys.success.data.journey_type = this.topupService.setPciTaggingJourneyType();
					this.tagging.view(
						tagging.pciPrepaidPages.okPage,
						_.merge(this.storageService.topupPrepaid, tagging.pciPrepaid.journys.success.data)
					);
				});
			}
		}
	}

	closeTray() {
		this.trayService.close();
		if (this.dynamicTrayService.trayData) {
			this.dynamicTrayService.trayData.isGradientBackground = false;
		}
		this.dynamicTrayService.closeTraySubject.next();
		if (this.topupService.isAnonymousProcess && this.topupService.isRedirected) {
			window.open(vodafoneEsRecarga, '_self');
		}
		if (this.topupService.isAnonymousProcess && !this.topupService.isRedirected) {
			this.router.navigate([config.login.route]);
		}
		this.paymentCardsService.cardsList = new Array<PaymentCardModel>();
		if (this.billService.startBillingPayment) {
			this.billService.successPaymentSubject.next();
		}
	}

	sendTaggingClickOnXInteraction() {
		if (!this.billService.startBillingPayment) {
			const pageTaggingName = tagging.pciPrepaidPages.okPage.page_name;
			const eventName = tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.eventName.replace(
				'<page_name>',
				pageTaggingName
			);
			this.tagging.track(
				eventName,
				tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.data,
				tagging.pciPrepaidPages.okPage
			);
		}
	}

	closeTrayWithTagging(): void {
		this.sendTaggingClickOnXInteraction();
		this.closeTray();
		if (!this.topupService.isAnonymousProcess) {
			this.saveCreditCardLogic();
			this.reloadDashboard();
		}
	}

	downLoadApp() {
		this.tagging.track(
			tagging.pciPrepaid.interactions.clickDownLoadApp.eventName,
			tagging.pciPrepaid.interactions.clickDownLoadApp.data,
			tagging.pciPrepaidPages.okPage,
			this.storageService.topupPrepaid
		);

		switch (this.deviceService.os) {
			case OperatingSystem.android.toLowerCase():
				window.open(STORE_URL.ANDROID, '_blank');
				break;
			case OperatingSystem.mac.toLowerCase():
			case OperatingSystem.ios.toLowerCase():
				window.open(STORE_URL.IOS, '_blank');
				break;
		}
		this.hideContent = true;
		setTimeout(() => {
			this.topupService.topupNavigationSubject.next({ page: TopupPrepaidStages.paymentTicket });
			this.trayService.showForwardTransition = true;
			this.trayService.showBackwardTransition = false;
			this.closeTray();
		});
	}
	confirmationButtonClick() {
		if (!this.topupService.isAnonymousProcess && !this.topupService.isOpenedFromLoginTabs) {
			this.saveCreditCardLogic();
		}
		this.hideContent = true;
		setTimeout(() => {
			this.topupService.topupNavigationSubject.next({ page: TopupPrepaidStages.paymentTicket });
			this.trayService.showForwardTransition = true;
			this.trayService.showBackwardTransition = false;
			if (this.dynamicTrayService.trayData) {
				this.dynamicTrayService.trayData.isGradientBackground = false;
			}
		}, 1500);
	}

	secondButtonClick(): void {
		if (!this.topupService.isAnonymousProcess) {
			this.saveCreditCardLogic();
		}
		if (this.dynamicTrayService.trayData) {
			this.dynamicTrayService.trayData.isContentBodyFullHeight = false;
		}
		this.closeTray();
		this.hideContent = true;
	}

	saveCreditCardLogic(): void {
		if (this.showSaveCardToggle) {
			if (this.saveCreditCard) {
				const cardObj: PaymentCardPatchModel = new PaymentCardPatchModel();
				cardObj.cardMask = this.topupService.cardMask;
				cardObj.documentID = this.storageService.userProfile.document.id;
				cardObj.documentType = this.storageService.userProfile.document.type;
				cardObj.expiryDate = this.topupService.cardExpiry;
				cardObj.journey = this.billService.startBillingPayment ? PaymentCardJournies.bill : PaymentCardJournies.topup;
				cardObj.token = this.topupService.cardToken;
				this.paymentCardsService.savePaymentCard(this.storageService.userProfile.document.id, cardObj).subscribe();
			}
			/** Send tagging when leaving this confirmation screen and toggle is appeared */
			if (this.showSaveCardToggle) {
				this.sendOnLeavingTaggingAction();
			}
		} else {
			/** Check if selected card is not recurrent then make it recurrent */
			if (!this.topupService.selectedCreditCard?.pagoRecurrente) {
				/** Save credit card again to make it recurrent */
				const savedCard: PaymentCardPatchModel = this.getCardModelForPatch(this.topupService.selectedCreditCard);
				this.paymentCardsService
					.savePaymentCard(this.storageService.userProfile.document.id, savedCard)
					.subscribe((res: any) => {
						/** Set recurrent value for existing saved credit card */
						this.topupService.selectedCreditCard.pagoRecurrente = res.pagoRecurrente;
					});
			}
		}
	}

	/**
	 * Method for getting mapped card model to patch model
	 * @param card selected card
	 * @returns new instance of PaymentCardPatchModel model
	 */
	private getCardModelForPatch(card: PaymentCardModel): PaymentCardPatchModel {
		const cardObj: PaymentCardPatchModel = new PaymentCardPatchModel();
		cardObj.uuid = card.uuid;
		cardObj.cardMask = card.cardMaskData;
		cardObj.documentID = card.documentID;
		cardObj.documentType = card.documentType;
		cardObj.expiryDate = card.expiryDate;
		cardObj.journey = card.journey;
		cardObj.token = card.token;
		cardObj.principle = card.principle;
		return cardObj;
	}

	showSaveCreditCardLogic() {
		if (this.paymentCardsService.cardsList) {
			const isCardSavedBefore = this.paymentCardsService.cardsList.find(
				(card) => card.token === this.topupService.cardToken
			);
			this.showSaveCardToggle =
				!isCardSavedBefore && !this.topupService.isAnonymousProcess && !this.topupService.isOpenedFromLoginTabs;
		}
	}

	onSaveCreditCardToggleChanges(event) {
		this.saveCreditCard = event;
		if (!this.billService.startBillingPayment) {
			const taggingObject: any = event
				? tagging.pciPrepaid.interactions.clickSaveCreditCard
				: tagging.pciPrepaid.interactions.clickDontSaveCreditCard;
			this.tagging.track(taggingObject.eventName, taggingObject.data, tagging.pciPrepaidPages.okPage);
		}
	}

	reloadDashboard() {
		if (this.router.url?.toLowerCase() === config.dashboard.route) {
			this.dashboardService.relaodDashboardSubject.next();
		}
	}

	ngOnDestroy() {
		this.paymentCardsService.cardsList = new Array<PaymentCardModel>();
		this.topupService.dynamicTrayData.closeButtonAction = null;
		this.reloadDashboard();
		if (this.dynamicTrayService.trayData) {
			this.dynamicTrayService.trayData.isGradientBackground = false;
		}
	}

	/**
	 * Tagging state for postpaid payment
	 */
	sendOnLeavingTaggingAction(): void {
		if (this.billService.startBillingPayment) {
			const saveCreditCardJourney: any = UtilitiesService.deepCopy(tagging.saveCreditCardJourney);
			const saveCreditCardPageJourney: any = UtilitiesService.deepCopy(tagging.saveCreditCardJourney.pageJourney);
			let pageName: string = '';

			if (this.billService.isDeptPayment) {
				pageName = tagging.staticVariables.paymentDeptPageName;
				saveCreditCardJourney['journey_payment_type'] = this.billService.showPayPartial
					? tagging.staticVariables.payPartial
					: tagging.staticVariables.payTotal;
				saveCreditCardJourney.data.journey_process = tagging.staticVariables.paymentDeptJourneyProcess;
			} else if (this.billService.isCashDeskPayment) {
				pageName = tagging.staticVariables.paymentCashDeskPageName;
				saveCreditCardJourney['journey_payment_type'] = this.billService.showPayPartial
					? tagging.staticVariables.payPartial
					: tagging.staticVariables.payTotal;
				saveCreditCardJourney.data.journey_process = tagging.staticVariables.paymentCashDeskJourneyProcess;
			} else if (this.billService.isInAdvancePayment) {
				pageName = tagging.staticVariables.paymentInAdvancePageName;
				saveCreditCardJourney.data.journey_process = tagging.staticVariables.paymentInAdvanceJourneyProcess;
			}

			saveCreditCardJourney.event_name = saveCreditCardJourney.event_name.replace('<page_name>', pageName);
			saveCreditCardPageJourney.page_name = saveCreditCardPageJourney.page_name.replace('<page_name>', pageName);
			saveCreditCardPageJourney.page_subcategory_level_2 = pageName;

			const paymentId: string = this.topupService.transactionId;
			saveCreditCardJourney.data.payment_id = paymentId;
			const eventNumber: any = this.saveCreditCard
				? saveCreditCardJourney.toggle_active
				: saveCreditCardJourney.toggle_not_active;
			saveCreditCardJourney.data['&&events'] = saveCreditCardJourney.data['&&events']
				.replace('<event_number>', eventNumber.toString())
				.replace('<payment_id>', paymentId);
			saveCreditCardJourney.data.journey_category = CrossVariables.client_typology;
			saveCreditCardJourney.data.journey_card_selected = this.topupService.selectedCreditCard
				? saveCreditCardJourney.savedCreditCard
				: saveCreditCardJourney.newCreditCard;

			this.tagging.track(saveCreditCardJourney.event_name, saveCreditCardJourney.data, saveCreditCardPageJourney);
		}
	}
}
