<div *ngIf="showModal" class="modal-swiper" [ngClass]="{ 'modal-noPadding': noPadding }">
	<div *ngIf="!hideHeader" class="modal-swiper-header">
		<span class="span-icon icon icon-close" (click)="hideSwiperModal()"> </span>
		<span *ngIf="headerText" class="header-title"> {{ headerText }} </span>
	</div>
	<div class="modal-swiper-body">
		<div class="content-container" fxLayout="column" fxLayoutAlign="stretch">
			<swiper #swiper overlay-controls [config]="config" (indexChange)="onIndexChange($event)">
				<ng-content> </ng-content>
			</swiper>
			<div class="swiper-scrollbar"></div>
			<div class="prevent-swiper-bullets-action"></div>
			<div class="swiper-pagination"></div>
		</div>
	</div>
</div>
