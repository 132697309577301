<section id="topUpCountDownContainer" class="countdown-container">
	<div>
		<img
			id="topUpCountDownImg"
			class="countdown-container__img"
			[src]="'v10.payment.counter.counterPage.icon' | imgFullPath"
		/>
	</div>
	<p id="topUpCountDownTitle" class="countdown-container__title">
		{{ 'v10.payment.counter.counterPage.title' | translate }}
	</p>
	<p
		id="topUpCountDownDesc"
		class="countdown-container__desc"
		[innerHTML]="
			counter
				? ('v10.payment.counter.counterPage.description' | translate)
				: ('v10.payment.counter.counterPage.descriptionLoading' | translate)
		"
	></p>
	<div *ngIf="counter !== 0">
		<img
			id="topUpCountDownImgCounter"
			class="countdown-container__secImg"
			[src]="'https://assets-es-dev.dxldev.local.vodafone.es/assets/images/Seg.svg' | imgFullPath"
		/>
		<p id="topUpCountDownCounter" class="countdown-container__timer">{{ counterToDisplay }}</p>
		<p id="topUpCountDownSecondsTxt" class="countdown-container__seconds">
			{{ 'v10.payment.counter.counterPage.seconds' | translate }}
		</p>
	</div>
	<mva10-c-speechmark-spinner *ngIf="counter === 0 && showLoader" [color]="'red'" [type]="'intro'">
	</mva10-c-speechmark-spinner>
</section>
