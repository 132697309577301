<div class="marketplace__tabs">
	<div
		*ngIf="isResponsive"
		class="marketplace__tab--space marketplace__tab--left-border"
		(click)="onTabClick(tabs.Shop)"
	></div>
	<div class="marketplace__row" [class.mva10-responsive]="isResponsive">
		<div
			class="marketplace__tab"
			[ngClass]="marketplaceService.tabShop ? 'marketplace__tab--active' : ''"
			(click)="onTabClick(tabs.Shop)"
		>
			<p id="marketplaceTabsShopTab" class="marketplace__tab__text">
				{{ tabShopLabel }}
			</p>
		</div>
		<div
			class="marketplace__tab"
			[ngClass]="marketplaceService.tabMarketplace ? 'marketplace__tab--active' : ''"
			(click)="onTabClick(tabs.Mktplace)"
		>
			<p id="marketplaceTabsMarketplaceTab" class="marketplace__tab__text">
				{{ tabMarketplaceLabel }}
			</p>
		</div>
	</div>
	<div
		*ngIf="isResponsive"
		class="marketplace__tab--space marketplace__tab--right-border"
		(click)="onTabClick(tabs.Mktplace)"
	></div>
</div>
