import { leftMenuProductsContainer } from './../../constants/defines';
import { Router } from '@angular/router';
import { config } from './../../../../config/pages-config';
import { CustomerAccountService } from './../../services/customer-account.service';
import { NotificationInboxService } from './../../services/notification-inbox.service';
import { SideMenuItemType } from './../../enums/side-menu-item-type.enum';
import { TypeUser } from './../../enums/typeUser';
import { AppService } from './../../../app.service';
import { TranslateService } from '@ngx-translate/core';
import { Breakpoints, OverlayModel } from '@mva10/mva10-angular';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { ResizeService } from '../../../mva10/shared/services/resize.service';
import { LeftMenuWs10Service } from '../../../mva10/shared/services/left-menu-ws10.service';
import { TaggingViewModel, TaggingActionModel } from '../../../../app/models/tagging.model';
import { tagging } from '../../../../config/tagging-config';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { DashboardService } from '../../services/dashboard.service';
import { ProductsAndServicesService } from '../../../products-and-services/products-and-services.service';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';
import { NewTaggingHelperService } from './../../../core/services/new-tagging.helper.service';
import { FOLDER_ADARA_MY_PRODUCTS, ADARA_TRACKING } from '../../constants/archetype/adaraTracking';

@Component({
	selector: 'sp-left-menu',
	templateUrl: './left-menu.component.html',
	styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent implements OnInit, AfterViewChecked {
	expanded: boolean;
	itemSelected: number;
	viewMoreClick = false;
	showViewMore = true;
	bigSize: boolean;
	SideMenuItemType = SideMenuItemType;
	yuBitIcon: any;
	vfIcon: string;
	isYuBit = false;
	showChangeCifOverlay = false;
	overlayData: OverlayModel;
	showGradientTop = false;
	showGradientBottom = false;
	BREAKPOINTS = Breakpoints;
	breakpoint: Breakpoints;

	private yuBitIconSmall: string;
	private yuBitIconBig: any;
	yuBitFlagAnimation: boolean = false;
	vfFlagAnimation: boolean = false;
	iconError: boolean = false;

	public telcoFilter: boolean = true;

	@ViewChild('menuProducts') menuProductsElement: ElementRef;
	@ViewChild('productsContainer') productsContainer: ElementRef;

	@Input()
	set expand(expand) {
		if (!this.bigSize) {
			this.expanded = expand;
			this.viewMoreClick = false;
		}
	}
	@Input() set clearSelected(clear: boolean) {
		this.itemSelected = clear ? -1 : this.itemSelected;
	}

	@Input()
	set typeUser(value: number) {
		this.isYuBit = value === TypeUser.One || value === TypeUser.Three;
		this.updateMenu();
	}

	constructor(
		public notificationInboxService: NotificationInboxService,
		private resizeService: ResizeService,
		public leftMenuService: LeftMenuWs10Service,
		private translate: TranslateService,
		public appService: AppService,
		public customerAccountService: CustomerAccountService,
		public taggingHelper: TaggingHelperService,
		public dashboardService: DashboardService,
		private router: Router,
		public productAndService: ProductsAndServicesService,
		public newTaggingHelperService: NewTaggingHelperService
	) {}

	ngOnInit(): void {
		this.overlayData = new OverlayModel();
		this.translate.get('v10.common').subscribe((text) => {
			this.overlayData.title = text.tray.overlay_myproducts.selectcifaddress;
			this.overlayData.primaryButton = text.literals.agree_C;
		});

		this.initIcon();
		this.resizeService.onResize$.subscribe((size: Breakpoints) => {
			this.updateMenu();
		});
		this.updateMenu();
	}

	ngAfterViewChecked(): void {
		this.checkProductSelectorScroll();
	}

	onMenuClick(item, itemIdx) {
		this.itemSelected = itemIdx;
		this.leftMenuService.leftMenuItemClick(item);
	}

	selectedProduct(product) {
		this.leftMenuService.productSelected(product.id);
	}

	selectedProductModal(id) {
		this.selectedProduct(
			this.customerAccountService.selectorData.cardImageSelectorList.find((element) => element.id === id)
		);
	}

	onViewMoreClick() {
		this.viewMoreClick = !this.viewMoreClick;
		this.menuProductsElement.nativeElement.scrollTop = 0;
	}

	productsButtonClick() {
		this.leftMenuService.productsButtonClicked();
	}

	logoClick() {
		this.router.navigate([config.dashboard.route]);
	}

	openCifOverlay(): void {
		this.leftMenuService.closeSubmenu();
		this.showChangeCifOverlay = true;
	}

	changeCifOverlayClick() {
		this.showChangeCifOverlay = false;
		this.leftMenuService.setSiteSelectorClicked();
	}

	scrollProducts() {
		this.checkProductSelectorScroll();
	}

	goToMyOrders(): void {
		// Click from desktop CIF overlay link
		const page: TaggingViewModel = Object.assign({}, tagging.selectorCardView);
		const trackAction: TaggingActionModel = Object.assign({}, tagging.dashboard.pendingInstallMyOrdersTrayClick);
		this.taggingHelper.track(trackAction.eventName, Object.assign(page, trackAction.data));

		this.leftMenuService.closeSubmenu();
		this.showChangeCifOverlay = false;
		this.router.navigate([config.myorders.route]);
	}

	private checkProductSelectorScroll() {
		if (this.menuProductsElement) {
			if (this.productsContainer.nativeElement.offsetHeight < leftMenuProductsContainer.maxHeight) {
				this.viewMoreClick = true;
				this.showViewMore = false;
			} else {
				this.showViewMore = true;
			}
			this.showGradientTop = this.menuProductsElement.nativeElement.scrollTop > 0;
			this.showGradientBottom =
				this.menuProductsElement.nativeElement.offsetHeight + this.menuProductsElement.nativeElement.scrollTop <
				this.menuProductsElement.nativeElement.scrollHeight;
		}
	}

	private updateMenu() {
		if (this.resizeService.breakpoint === Breakpoints.DESKTOP) {
			this.expanded = true;
			this.bigSize = true;
		} else {
			this.expanded = false;
			this.bigSize = false;
		}
		this.yuBitIcon = this.resizeService.breakpoint === Breakpoints.TABLET ? this.yuBitIconSmall : this.yuBitIconBig;
		this.breakpoint = this.resizeService.breakpoint;
	}

	private initIcon(): void {
		this.translate.get('v10.dashboard.images.yu_bit').subscribe((text) => {
			this.yuBitFlagAnimation = JSON.parse(text.yu_bit_flagAnimation);
			this.yuBitIconBig = this.appService.getImgFullPath(text.yu_bit_img);
			this.yuBitIconSmall = this.appService.getImgFullPath(text.yu_bit_img_rsp);
			if (this.yuBitFlagAnimation) {
				this.iconError =
					this.yuBitIconBig.includes('.json') && this.yuBitIconSmall.includes('.json') ? this.iconError : true;
			} else {
				this.iconError =
					this.yuBitIconBig.includes('.png') && this.yuBitIconSmall.includes('.png') ? this.iconError : true;
			}
		});
		this.translate.get('v10.dashboard.images.logos').subscribe((text) => {
			this.vfFlagAnimation = JSON.parse(text.flagAnimation);
			this.vfIcon = this.appService.getImgFullPath(text.logo_vf_img);
			if (this.vfFlagAnimation) {
				this.iconError = this.vfIcon.includes('.json') ? this.iconError : true;
			} else {
				this.iconError = this.vfIcon.includes('.png') ? this.iconError : true;
			}
		});
	}

	public clickOnAdaraCTATags(): void {
		this.router.navigate([config.adara.route]);
		this.newTaggingHelperService.getJsonTagging(FOLDER_ADARA_MY_PRODUCTS).subscribe((data: NewTaggingJsonModel) => {
			this.newTaggingHelperService.interaction(data.eventList[ADARA_TRACKING.INTERACTIONS.FILTER_CLICK], data.page);
		});
	}
}
