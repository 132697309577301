<div class="mva10-row">
	<sp-background-header-and-icon
		[headerBackground]="'v10.productsServices.xrContents.header.backgroundImage' | translate | imgFullPath"
		[headerTitle]="'v10.productsServices.xrContents.header.title' | translate"
		[headerIcon]="'v10.productsServices.xrContents.header.icon' | translate | imgFullPath"
		(goBackEvent)="goBack()"
	>
	</sp-background-header-and-icon>

	<div class="mva10-responsive">
		<div class="mva10-u-heading--3 mva10-u--mt12x bold-font" id="sharedXrContentsTitle">
			{{ 'v10.productsServices.xrContents.title' | translate }}
		</div>
		<div
			class="mva10-u--body mva10-u--mt4x"
			id="sharedXrContentsDescription"
			[innerHTML]="'v10.productsServices.xrContents.desc' | translate"
		></div>
		<div class="card-offer mva10-u--mt8x" *ngIf="cardOfferFlag">
			<div class="mva10-u-heading--4 banner-section-name bold-font" id="sharedXrContentsBannerSectionName">
				{{ 'v10.productsServices.xrContents.banner.sectionName' | translate }}
			</div>
			<mva10-c-card-offer
				class="banner"
				id="sharedXrContentsBanner"
				[buttonBody]="'v10.productsServices.xrContents.banner.button' | translate"
				[buttonName]="'v10.productsServices.xrContents.banner.button' | translate"
				[buttonTitle]="'v10.productsServices.xrContents.banner.button' | translate"
				[image]="'v10.productsServices.xrContents.banner.image' | translate | imgFullPath"
				[imageLarge]="'v10.productsServices.xrContents.banner.image' | translate | imgFullPath"
				[modifier]="'line'"
				(buttonClick)="navigateToCheckout()"
			>
				<span
					co-title
					class="mva10-u-color--primary"
					id="sharedXrContentsBannerTitle"
					[innerHTML]="'v10.productsServices.xrContents.banner.title' | translate"
				>
				</span>
				<span
					co-body
					id="sharedXrContentsBannerSubTitle"
					[innerHTML]="'v10.productsServices.xrContents.banner.subtitle' | translate"
				>
				</span>
			</mva10-c-card-offer>
		</div>
		<div class="characteristics-section">
			<div
				class="mva10-u-heading--4 section-characteristics-header bold-font"
				id="sharedXrContentsSectionCharacteristicsHeader"
			>
				{{ 'v10.productsServices.xrContents.subtitle' | translate }}
			</div>
			<div class="characteristics-list mva10-u--mt4x" *ngFor="let characteristic of characteristicsList; let i = index">
				<img
					[src]="characteristic.icon | imgFullPath"
					id="sharedXrContentsCharacteristicImage{{ i }}"
					alt="Characteristic icon"
				/>
				<p [innerHTML]="characteristic.text" id="sharedXrContentsCharacteristicText"></p>
			</div>
		</div>
		<div class="mobile-section mva10-u--mt8x">
			<div class="mva10-u-heading--4 bold-font" id="sharedXrContentsSectionMobileFootNote">
				{{ 'v10.productsServices.xrContents.downloadApp' | translate }}
			</div>
			<div class="mobile-links mva10-u--mt4x">
				<a (click)="navigateToMobileStore(MarketNames.appStore)">
					<img
						[src]="'v10.productsServices.xrContents.appStoreIcon' | translate | imgFullPath"
						id="sharedXrContentsAppStoreIcon"
						alt="App store icon"
					/>
				</a>
				<a (click)="navigateToMobileStore(MarketNames.googlePlay)">
					<img
						[src]="'v10.productsServices.xrContents.playStoreIcon' | translate | imgFullPath"
						id="sharedXrContentsPlayStoreIcon"
						alt="Play store icon"
					/>
				</a>
			</div>
		</div>
	</div>
</div>
