import { Injectable } from '@angular/core';

import { sharedServicesPegaReplacerReplaceFromSource } from '../util';

import { SharedServicesPegaReplacerReplaceOptions } from '../entity/shared-services-pega-replacer.entity';

@Injectable({ providedIn: 'root' })
export class SharedServicesPegaReplacerService {
	public replace(options: SharedServicesPegaReplacerReplaceOptions): string {
		return sharedServicesPegaReplacerReplaceFromSource(options);
	}
}
