import { Component, Input } from '@angular/core';
import { OverlayModel } from '@mva10/mva10-angular';

@Component({
	selector: 'sp-general-error-tray',
	templateUrl: './general-error-tray.component.html',
	styleUrls: ['./general-error-tray.component.scss'],
})
export class GeneralErrorTrayComponent {
	@Input() trayKOTitle: string = '';
	@Input() trayKOText: string = '';
	@Input() trayKOBtn: string = '';
	@Input() trayKOIcon: string = '';
	@Input() id: string;
	@Input() smallTray: boolean = false;
	overlayData: OverlayModel = {
		title: ' ',
	};
	showError: boolean = false;

	public show(): void {
		this.showError = true;
	}
}
