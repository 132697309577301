<div class="header-v-product" [ngStyle]="{ 'background-image': 'url(' + headerBackground + ')' }">
	<mva10-top-bar
		id="SharedComponentsBackgroundHeaderAndIcon"
		[leftIcon]="headerIconClose ? '' : { iconRef: 'icon-arrow-left', iconTitle: 'back' }"
		[rightIcon]="headerIconClose ? [{ iconRef: 'icon-close', iconTitle: 'quit' }] : []"
		(mva10OnClick)="goBack()"
		[isLogo]="false"
		[styleClass]="'header-v-product-top-bar'"
	>
		<p class="header-v-product-title">{{ headerTitle }}</p>
	</mva10-top-bar>
	<div class="header-v-product-bottom">
		<div class="header-v-product-bottom-flex">
			<div class="header-v-product-bottom-desc" [ngStyle]="{ 'background-color': inputColor ? inputColor : '#fff' }">
				<span [innerHtml]="headerDesc"> </span>
			</div>
		</div>
		<img [src]="headerIcon" />
	</div>
</div>
