import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Observable } from 'rxjs';
import { config } from '../../../../config/pages-config';
import { tagging } from '../../../../config/tagging-config';
import { AppService } from '../../../app.service';
import { ServiceModel } from '../../../models/service.model';
import { ProductsAndServicesService } from '../../../products-and-services/products-and-services.service';
import {
	Validation,
	contacts,
	LOCAL_STORAGE_KEYS,
	entryPointsMVA10,
	pegaOffersIDs,
	pegaOfferFields,
	BundleType,
	pegaPlatinumOfferCodes,
	pegaExtraBalanceOfferIcon,
} from '../../../shared/constants/defines';
import { IframeStatus } from '../../../shared/enums/iframe-status.enum';
import { LanguageEnum } from '../../../shared/enums/language.enum';
import { PaymentCompoentInputData_Keys } from '../../../shared/enums/payment-compoent-input-data-keys.enum';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentTrayAction } from '../../../shared/enums/payment-tray-action.enum';
import { ServiceType } from '../../../shared/enums/serviceType.enum';
import { PaymentJourny } from '../../../shared/enums/payment-journy.enum';
import { PaymentLanguages } from '../../../shared/enums/payment-languages.enum';
import { DashboardService } from '../../../shared/services/dashboard.service';
import { SpMva10OverlayService } from '../../../shared/services/sp-mva10-overlay.service';
import { StorageService } from '../../../core/services/storage.service';
import { SubscriptionService } from '../../../core/services/subscription.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { SpMva10ServiceSelectorComponent } from '../../../shared/sp-mva10-service-selector/sp-mva10-service-selector.component';
import { TopUpService } from '../../../top-up/top-up.service';
import { PaymentNavigationService } from '../../services/payment-navigation.service';
import { PaymentCardModel } from '../../../models/payment-card.model';
import { PaymentWalletService } from '../../../shared/services/payment-wallet.service';
import { Breakpoints } from '@mva10/mva10-angular';
import { ResizeService } from '../../../mva10/shared/services/resize.service';
import { CustomerBillService } from 'src/app/shared/services/customer-bill.service';
import { EntryPointsService } from 'src/app/shared/services/entry-points.service';
import { EntryPointResponseModel, EntryPointModel } from 'src/app/models/entry-points.model';
import { concatMap, filter, map } from 'rxjs/operators';
import { SharedServicesPegaParserService } from 'src/app/shared/services/pega/feature/parser';
import { UtilityService } from 'src/app/core/services/utility.service';
import { ServiceBalanceWs10Service } from 'src/app/shared/services/service-balance-ws10.service';
import { TariffService } from 'src/app/shared/services/tariff.service';
import { TariffListResponse } from 'src/app/models/tariff-list-response.model';
import { Dashboard } from 'src/app/models/dashboard.model';
@Component({
	selector: 'sp-payment-topup-landing',
	templateUrl: './payment-topup-landing.component.html',
	styleUrls: ['./payment-topup-landing.component.scss'],
})
export class PaymentTopupLandingComponent implements OnInit {
	@Input() isAnonymousProcess: boolean;
	@Input() isOpenedFromIkki: boolean = true;
	selectedAmountSelectorValue: number = 2;
	currentSelectedAmountSelectorValue: number;
	amountSelectorValues: Array<any> = new Array<any>();
	minAmountSelector: number = 5;
	maxAmountSelectorValue: number = 95;
	visaImage: string;
	masterCardImage: string;
	selections: ServiceModel[];
	lottieConfig: object;
	hideContent: boolean = false;
	anim: any;
	showFullLoader: boolean = false;
	form: UntypedFormGroup;
	MsisdnValidation: any;
	showeMsisdnErrorMessage: boolean;
	isFocus: boolean;
	selectedService: ServiceModel;
	errorMessage: string;
	showSafariErrorScreen: string;
	promoCode: string;
	/**flag to check if it's loading for first time or return from wallet */
	loadScreenForFirstTime: boolean = false;
	uiModel: any = {
		refills: '',
		rechargeNumber: '',
		introduceNewMobile: '',
		rechargeAmount: '',
		continueBtn: '',
		sslSecured: '',
	};
	@ViewChild('serviceSelector') serviceSelector: SpMva10ServiceSelectorComponent;
	@ViewChild('landingContainer') landingDiv: ElementRef;
	msisdnLessThanNineDigits: string;
	msisdnNotcontains6Or7: string;
	textInputPreviousValue: string = '';
	scrolledView: boolean = false;
	pageHasScroller: boolean = false;
	reachScrollerEnd: boolean = false;
	changingLanguage: boolean = false;
	// subscription array to collect ally subscrible to clean tem on destroy
	subscriptions: Subscription[] = [];
	subscriptionToSuccessFulPayment: Subscription;
	/**for padlock image */
	BREAKPOINTS: typeof Breakpoints = Breakpoints;
	breakpoint: Breakpoints = Breakpoints.MOBILE;
	/**show success icon when take a number from contact list */
	showMsisdnFieldSuccessIcon: boolean = false;
	ableToOpenContactList: string;
	numberWrittenByUser: boolean;
	/**pega offer values */
	pegaOfferKeyValue: Record<string, string> | Map<string, string> = new Map([
		[pegaOfferFields.extraBalancePCT, ''],
		[pegaOfferFields.pricePlanRate, ''],
		[pegaOfferFields.platinumMinRecharge, ''],
	]);
	pegaOffers: string[] = [
		pegaOffersIDs.Offer30PCT,
		pegaOffersIDs.Offer40PCT,
		pegaOffersIDs.Offer50PCT,
		pegaOffersIDs.Offer15PCT,
		pegaOffersIDs.ALMOUSTIN,
		pegaOffersIDs.ALMOUSTOUT,
	];
	pegaOfferText: string = '';
	showPegaOffer: boolean = false;
	showPegaPlatinumOffer: boolean = false;
	validateOfferDate: boolean = true;
	isValidRechargeAmount: boolean = true;
	dailyDateCheckForPegaOffer: Date = new Date();
	showAmountSelector: boolean = true;
	showUImodel: boolean = false;
	pegaPricPlanRate: string = '';
	pegaOfferPCTCode: string = '';
	pegaPlatinumOfferText: string = '';
	pegaExtraBalanceIcon: string = pegaExtraBalanceOfferIcon;
	constructor(
		private appService: AppService,
		private translateService: TranslateService,
		public trayService: SpMva10OverlayService,
		private productsAndServicesService: ProductsAndServicesService,
		public topupService: TopUpService,
		private subscriptionsService: SubscriptionService,
		private router: Router,
		private tagging: TaggingHelperService,
		private formBuilder: UntypedFormBuilder,
		private storageService: StorageService,
		private paymentNavigationService: PaymentNavigationService,
		public paymentWalletService: PaymentWalletService,
		private resizeService: ResizeService,
		private storage: StorageService,
		private dashboardService: DashboardService,
		private customerBillService: CustomerBillService,
		private entryPoints: EntryPointsService,
		private pegaParserService: SharedServicesPegaParserService,
		private utilityService: UtilityService,
		private serviceBalanceService: ServiceBalanceWs10Service,
		private tariffService: TariffService
	) {}

	public get isConsultant(): boolean {
		return this.storageService?.userProfile?.isConsultant || false;
	}

	ngOnInit() {
		this.breakpoint = this.resizeService.breakpoint;
		this.topupService.isAnonymousProcess = this.isAnonymousProcess;
		/**check if browser supported contact list or not */
		this.topupService.contactListSupported = contacts in navigator;
		/** resets topup language at topup flow start to prevent language caching */
		if (!this.topupService.isReturnFromPermissionScreen) {
			this.topupService.topupLanguage =
				this.paymentNavigationService.paymentJourneyParams.language === PaymentLanguages.english
					? LanguageEnum.english
					: LanguageEnum.spanish;
		}
		this.setPageUi();
		this.setTopupForOthersFormInput();
		this.setUiContent(this.topupService.topupLanguage);
		this.setAmountSelectorInitalValue();
		this.serviceSelectorLogic();
		this.resetAmountSelectorInitialConfigurations();
		this.loadScreenForFirstTime = true;
		this.topupService.returnDataFromContactList.subscribe(() => this.checkOnPhoneNumber());
		if (!this.isAnonymousProcess) {
			this.showFullLoader = true;
		} else {
			this.sendPageTagging();
			this.sendNewTopupPageTagging();
		}
		if (this.subscriptionToSuccessFulPayment) {
			this.subscriptionToSuccessFulPayment.unsubscribe();
		}
		this.subscriptionToSuccessFulPayment = this.paymentNavigationService.changePaymentCompSubject.subscribe(
			({ actionNeeded, finalStatus }) => {
				if (actionNeeded === PaymentTrayAction.forceClose) {
					this.closeTray();
					if (finalStatus === IframeStatus.complete) {
						this.reloadDashboard();
						this.subscriptionToSuccessFulPayment.unsubscribe();
					}
				}
			}
		);
		this.dailyDateCheckForPegaOffer.setHours(0, 0, 0, 0);
		this.topupService.selectedServiceId = this.subscriptionsService.customerData.currentService.id;
		this.checkExistedBundles();
	}

	sendNewTopupPageTagging(): void {
		const selectedCard: PaymentCardModel = this.paymentWalletService.selectedCard;
		if (this.topupService.isAnonymousProcess || !selectedCard) {
			tagging.pciPrepaid.newTopupJourney.journey_card_selected = tagging.staticVariables.newCreditCard;
		} else {
			tagging.pciPrepaid.newTopupJourney.journey_card_selected = tagging.staticVariables.savedCreditCard;
		}
		this.tagging.viewReplace(false, {}, tagging.pciPrepaid.newTopupJourney);
	}

	serviceSelectorLogic(): void {
		const prePaidServices: Array<ServiceModel> = this.subscriptionsService.customerData.services
			? this.subscriptionsService.customerData.services.filter((service) => {
					return (
						service.type &&
						(service.type.toLowerCase() === ServiceType.Prepaid.toLowerCase() ||
							service.type.toLowerCase() === ServiceType.MbbPrepaid.toLowerCase())
					);
			  })
			: [];
		if (prePaidServices.length === 0 || this.isAnonymousProcess) {
			this.showFullLoader = false;
			this.topupService.showPlus = this.isAnonymousProcess ? false : true;
		} else {
			this.setServiceSelectorServices();
		}
	}
	returnDataAfterBackFromWallet(): void {
		this.form = this.formBuilder.group({
			msisdn: [this.topupService.msisdn],
		});
		this.geterrorMessage();
		if (this.form.controls['msisdn'].value[0] !== '6' && this.form.controls['msisdn'].value[0] !== '7') {
			this.showeMsisdnErrorMessage = true;
			this.isFocus = true;
			this.errorMessage = this.msisdnNotcontains6Or7;
		} else if (this.form.controls['msisdn'].value.length !== 9) {
			this.showeMsisdnErrorMessage = true;
			this.isFocus = true;
			this.errorMessage = this.msisdnLessThanNineDigits;
		}
	}
	setTopupForOthersFormInput(): void {
		this.MsisdnValidation = Validation.msisdn;
		if (this.topupService.returnFromWalletScreen && this.topupService.msisdn) {
			this.returnDataAfterBackFromWallet();
		} else if (this.topupService.isReturnFromPermissionScreen) {
			this.checkOnPhoneNumber();
		} else {
			this.form = this.formBuilder.group({
				msisdn: ['', Validators.required],
			});
		}
	}

	/**check if selected number from contact list to check the validation or not */
	checkOnPhoneNumber(): void {
		this.numberWrittenByUser = this.topupService.selectedPhoneNumber ? false : true;
		if (this.topupService.selectedPhoneNumber) {
			this.form = this.formBuilder.group({
				msisdn: [this.topupService.selectedPhoneNumber],
			});
			this.form.controls['msisdn'].markAsTouched();
			this.onBlur();
			this.form.controls['msisdn'].markAsDirty();
			this.isFocus = true;
		}
	}

	setAmountSelectorInitalValue(): void {
		let amountSelectorValue: number = this.minAmountSelector;
		for (let i: number = 0; amountSelectorValue <= this.maxAmountSelectorValue; amountSelectorValue += 5, i++) {
			this.amountSelectorValues.push({
				value: i,
				name: amountSelectorValue.toString(),
				selected: i === this.selectedAmountSelectorValue ? true : false,
			});
		}
		if (this.productsAndServicesService.initialAmount) {
			const initialAmountIndex: any = this.amountSelectorValues.findIndex((amount) => {
				return +amount.name >= this.productsAndServicesService.initialAmount;
			});
			this.selectedAmountSelectorValue =
				initialAmountIndex !== -1 ? initialAmountIndex : this.selectedAmountSelectorValue;
			this.productsAndServicesService.initialAmount = 0;
			this.amountSelectorValues.forEach((item, index) => {
				item.selected = item.value === initialAmountIndex ? true : false;
			});
		}
		this.topupService.curruentSelectedAmountSelectorValue = this.topupService.curruentSelectedAmountSelectorValue
			? this.topupService.curruentSelectedAmountSelectorValue
			: this.amountSelectorValues[this.selectedAmountSelectorValue].name;
	}

	setPageUi(): void {
		this.translateService.get('payment').subscribe((data) => {
			this.promoCode = data.enterCode?.fieldsList?.promoInput?.placeHolder;
			if (this.breakpoint === Breakpoints.MOBILE) {
				this.visaImage = this.appService.getImgFullPath(data.paymentMethods?.images?.pciVisa?.url);
				this.masterCardImage = this.appService.getImgFullPath(data.paymentMethods?.images?.pciMasterCard?.url);
			} else {
				this.visaImage = this.appService.getImgFullPath(data.paymentMethods?.images?.pciFooterVisa?.url);
				this.masterCardImage = this.appService.getImgFullPath(data.paymentMethods?.images?.pciFooterMasterCard?.url);
			}
		});
	}

	setUiContent(lan: LanguageEnum = LanguageEnum.spanish): void {
		setTimeout(() => {
			switch (lan) {
				case LanguageEnum.spanish:
					this.translateService.get('common.itemsList').subscribe((data) => {
						this.uiModel.refills = data.refills?.body;
						this.uiModel.rechargeNumber = data.rechargeNumber?.body;
						this.uiModel.rechargeAmount = data.rechargeAmount?.body;
						this.showUImodel = true;
						this.pegaOfferText = data.PEGAOffer?.body?.replace(
							'{0}',
							this.pegaOfferKeyValue[pegaOfferFields.extraBalancePCT] ?? ' '
						);
					});
					this.translateService.get('payment').subscribe((data) => {
						this.uiModel.continueBtn = data.paymentMethods?.buttonsList?.continueBtn?.text;
						this.uiModel.sslSecured = data.mobileRecharge?.fieldsList?.sslSecured?.body;
					});
					this.translateService.get('v10.payment.messagesList.prepaidTopup').subscribe((data) => {
						if (this.isAnonymousProcess) {
							this.uiModel.introduceNewMobile = data.es?.addNewMobile;
						} else {
							this.uiModel.introduceNewMobile = data.es?.addNewMobileInLoggedInJourney;
						}
					});
					break;
				case LanguageEnum.english:
					this.translateService.get('common.itemsList').subscribe((data) => {
						this.uiModel.refills = data.refills_en?.body;
						this.uiModel.rechargeNumber = data.rechargeNumber_en?.body;
						this.uiModel.rechargeAmount = data.rechargeAmount_en?.body;
					});
					this.translateService.get('payment').subscribe((data) => {
						this.uiModel.continueBtn = data.paymentMethods?.buttonsList?.continueBtn_en?.text;
						this.uiModel.sslSecured = data.mobileRecharge?.fieldsList?.sslSecured_en?.body;
					});
					this.translateService.get('v10.payment.messagesList.prepaidTopup').subscribe((data) => {
						this.uiModel.introduceNewMobile = data.en?.addNewMobile;
					});
					break;
			}
		}, 300);
	}

	setServiceSelectorServices(): void {
		this.paymentNavigationService.showVodafoneLoader();
		if (this.topupService.backToFirstStep) {
			this.topupService.transactionCanceledSubject.subscribe(() => {
				this.getServicesLogic();
			});
		} else {
			this.getServicesLogic();
		}
	}

	getServicesLogic(): void {
		this.selections = this.filterPrepaidServices();
		this.topupService.traySelectedService = null;
		this.topupService.newPciSelectedService = this.selections[0];
		this.showFullLoader = false;
		if (this.selections.length === 0) {
			this.showFullLoader = false;
			this.topupService.showPlus = true;
		}
		if (this.topupService.msisdn) {
			this.topupService.newPciSelectedService = null;
			this.topupService.showPlus = true;
		}
	}

	filterPrepaidServices(): Array<ServiceModel> {
		const result: Array<ServiceModel> = this.topupService.getPrepaidServiceUnderSite(
			this.subscriptionsService.customerData.customerAccountsId,
			this.topupService.backToFirstStep
				? this.topupService.newPciSelectedService
				: this.topupService.traySelectedService
		);
		this.topupService.backToFirstStep = false;
		return result;
	}

	/** Send tagging when open screen */
	sendPageTagging(): void {
		this.tagging.view(tagging.pciPrepaidPages.serviceSelectorPage, this.storageService.topupPrepaid);
		tagging.pciPrepaid.journys.firstStep.data.journey_subcategory =
			this.topupService.setTaggingJournySubCategoryValue();
		tagging.pciPrepaid.journys.firstStep.data.journey_options =
			this.amountSelectorValues[this.selectedAmountSelectorValue].toString();
		tagging.pciPrepaid.journys.firstStep.data.journey_type = this.topupService.setPciTaggingJourneyType();
		this.tagging.track(
			tagging.pciPrepaid.journys.firstStep.eventName,
			tagging.pciPrepaid.journys.firstStep.data,
			tagging.pciPrepaidPages.serviceSelectorPage,
			this.storageService.topupPrepaid
		);
	}

	selectedAmountSelectorValues(index: number): void {
		this.currentSelectedAmountSelectorValue =
			index !== -1 ? +this.amountSelectorValues[index].name : this.currentSelectedAmountSelectorValue;
		this.topupService.currentSelectedAmount = this.currentSelectedAmountSelectorValue;
		this.topupService.curruentSelectedAmountSelectorValue = this.currentSelectedAmountSelectorValue;
		if (this.showPegaOffer) {
			this.isValidRechargeAmount = !(
				this.currentSelectedAmountSelectorValue < +(this.pegaOfferKeyValue[this.pegaPricPlanRate] | 0)
			);
		} else if (this.showPegaPlatinumOffer) {
			this.isValidRechargeAmount =
				this.currentSelectedAmountSelectorValue >= +(this.pegaOfferKeyValue[pegaOfferFields.platinumMinRecharge] | 0);
		}
		this.showPegaOffer && this.isValidRechargeAmount
			? (this.topupService.isPega = true)
			: (this.topupService.isPega = false);
		this.selectedAmountSelectorValue = index;
	}

	closeTray(): void {
		this.topupService.selectedItemIndex = 0;
		this.topupService.msisdn = '';
		this.topupService.promoCode = '';
		this.topupService.showPlus = false;
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.topupService.isReturnFromPermissionScreen = false;
	}

	clickPlus(): void {
		this.showAmountSelector = false;
		this.topupService.showPlus = true;
		this.topupService.newPciSelectedService = null;
		this.topupService.msisdn = '';
		this.form.controls['msisdn'].setValue('');
		this.resetToDefaultAmountSelector();
		setTimeout(() => {
			this.showAmountSelector = true;
		}, 0);
		this.sendClickPlusTagging();
	}
	onServiceChange(service: ServiceModel): void {
		this.topupService.showPlus = false;
		this.selectedService = service;
		this.topupService.selectedServiceId = service.id;
		this.showAmountSelector = false;
		const isEligableToNewTopup: boolean = this.topupService.checkNewPciEligability(service);
		if (!isEligableToNewTopup) {
			this.closeTray();
			if (this.router.url === config.TopUp.topUpPrepaidservice.route) {
				this.topupService.trayServiceChangeSubject.next(service);
			} else {
				this.topupService.traySelectedService = service;
				this.router.navigate([config.TopUp.topUpPrepaidservice.route]);
			}
		} else {
			this.topupService.newPciSelectedService = service;
			/**check if entry point is already cached per service */
			if (!this.entryPoints.checkIfEntryPointIsCachedPerService(this.topupService.newPciSelectedService.id)) {
				this.getEntryPointForPegaOffers(this.topupService.newPciSelectedService.id);
			} else {
				const offerEntryPoint: EntryPointModel = this.entryPoints
					.getEntryPointByServiceID(this.topupService.newPciSelectedService.id)
					.entryPoints.find((entryPoint) => this.pegaOffers.includes(entryPoint.code));
				setTimeout(() => {
					this.showAmountSelector = true;
				}, 0);
				if (offerEntryPoint) {
					this.checkIfPegaOrPlatinum(offerEntryPoint);
				} else {
					this.showPegaOffer = false;
					this.resetToDefaultAmountSelector();
				}
			}
			this.topupService.selectedItemIndex = this.selections.findIndex((item) => {
				return item.id === service.id;
			});
		}
		this.callServiceBalanceAndTariffApis(service);
		this.topupService.showPlus = false;
		this.topupService.msisdn = '';
		this.tagging.track(
			tagging.pciPrepaid.interactions.clickLineNumber.eventName,
			tagging.pciPrepaid.interactions.clickLineNumber.data,
			tagging.pciPrepaidPages.serviceSelectorPage,
			this.storageService.topupPrepaid
		);
	}

	callServiceBalanceAndTariffApis(service: ServiceModel): void {
		const existedBundles: { serviceId: string; bundles: Dashboard[] } =
			this.serviceBalanceService.bundlesInPlanAndGroup?.find((bundle) => bundle?.serviceId === service.id);
		if (!existedBundles) {
			this.serviceBalanceService.getServiceBalance(BundleType.inPlan, false, service.id).subscribe((res) => {
				this.topupService.currentBalance = this.customerBillService.getAmountPrepaid(res.items);
				this.topupService.serviceBundle = this.serviceBalanceService.bundlesInPlanAndGroup?.find(
					(bundle) => bundle.serviceId === service.id
				)?.bundles;
			});
		} else {
			this.topupService.serviceBundle = existedBundles.bundles;
			this.topupService.currentBalance = this.customerBillService.getAmountPrepaid(existedBundles.bundles);
		}
		this.tariffService.GetAllTariffs(service.id).subscribe((res: TariffListResponse) => {
			this.topupService.serviceTariff = res;
		});
	}

	handleAnimation(anim: any): void {
		this.anim = anim;
	}

	navigateToIframeEnablement(): void {
		this.topupService.selectedItemIndex = 0;
		if (this.topupService.showPlus) {
			this.topupService.isForOthersJourney = true;
		}
		if (this.topupService.showPlus || this.isAnonymousProcess) {
			this.topupService.msisdn = this.form.controls['msisdn'].value;
		} else {
			this.topupService.msisdn = '';
		}
		if (this.isAnonymousProcess) {
			this.topupService.openPaymentModuleForAnonoumus(
				this.topupService.curruentSelectedAmountSelectorValue.toString(),
				this.isOpenedFromIkki ? PaymentJourny.prePaidAnonymus : PaymentJourny.prePaidAnonymusThirdParty
			);
		} else {
			if (
				(this.topupService.newPciSelectedService &&
					!this.topupService.newPciSelectedService.hasPendingPciTransactions) ||
				this.topupService.showPlus ||
				(this.isAnonymousProcess && this.form.controls['msisdn'].valid)
			) {
				this.topupService.openPaymentModule(
					this.topupService.curruentSelectedAmountSelectorValue.toString(),
					PaymentJourny.prepaidTopup,
					this.customerBillService.valueAmountPrepaid,
					this.topupService.promoCode
				);
			}
		}
	}
	resetAmountSelectorInitialConfigurations(): void {
		this.hideContent = false;
		this.currentSelectedAmountSelectorValue =
			this.topupService.curruentSelectedAmountSelectorValue && this.topupService.backToFirstStep
				? this.topupService.curruentSelectedAmountSelectorValue
				: +this.amountSelectorValues[this.selectedAmountSelectorValue].name;
		if (
			(this.topupService.curruentSelectedAmountSelectorValue && this.topupService.backToFirstStep) ||
			this.topupService.returnFromWalletScreen ||
			this.topupService.isReturnFromPermissionScreen
		) {
			this.selectedAmountSelectorValue = this.amountSelectorValues.findIndex(
				(amount) => amount === this.topupService.curruentSelectedAmountSelectorValue
			);
		} else {
			this.selectedAmountSelectorValue = 2;
			this.currentSelectedAmountSelectorValue = +this.amountSelectorValues[this.selectedAmountSelectorValue].name;
			this.topupService.curruentSelectedAmountSelectorValue = this.currentSelectedAmountSelectorValue;
		}
	}
	onFocus(): void {
		this.isFocus = true;
		if (!this.form.controls['msisdn'].value) {
			this.showeMsisdnErrorMessage = false;
		}
	}
	onInputFn(event: any): void {
		this.showMsisdnFieldSuccessIcon = false;
		this.numberWrittenByUser = true;
		if (isNaN(event.data) && event.data) {
			event.preventDefault();
			this.form.controls['msisdn'].setValue(this.textInputPreviousValue);
			event.target.value = this.textInputPreviousValue;
		} else {
			this.textInputPreviousValue = event.target.value;
			this.geterrorMessage();
			this.showeMsisdnErrorMessage = false;
			if (
				(this.form.controls['msisdn'].value &&
					!(this.form.controls['msisdn'].value[0] === '7' || this.form.controls['msisdn'].value[0] === '6')) ||
				(!this.form.controls['msisdn'].value && !(event.target.value === '7' || event.target.value === '6'))
			) {
				this.showeMsisdnErrorMessage = true;
				this.errorMessage = this.msisdnNotcontains6Or7;
			} else if (this.form.controls['msisdn'].value.length === 8 && event.data) {
				event.target.blur();
			}
		}
	}

	onBlur(): void {
		this.geterrorMessage();
		this.isFocus = false;
		this.showeMsisdnErrorMessage = true;
		if (this.form.controls['msisdn'].value[0] !== '6' && this.form.controls['msisdn'].value[0] !== '7') {
			this.errorMessage = this.msisdnNotcontains6Or7;
		} else if (this.form.controls['msisdn'].value.length !== 9) {
			this.errorMessage = this.msisdnLessThanNineDigits;
		} else {
			if (this.topupService.isReturnFromPermissionScreen && !this.numberWrittenByUser) {
				this.showMsisdnFieldSuccessIcon = true;
			}
		}
	}

	geterrorMessage(): void {
		switch (this.topupService.topupLanguage) {
			case LanguageEnum.spanish:
				this.translateService.get('common.itemsList').subscribe((data) => {
					this.msisdnNotcontains6Or7 = data.numberNotStartingWithSixOrSeven?.body;
					this.msisdnLessThanNineDigits = data.numberLessThanNightDigits?.body;
				});
				break;
			case LanguageEnum.english:
				this.translateService.get('common.itemsList').subscribe((data) => {
					this.msisdnNotcontains6Or7 = data.numberNotStartingWithSixOrSeven_en?.body;
					this.msisdnLessThanNineDigits = data.numberLessThanNightDigits_en?.body;
				});
				break;
		}
	}
	// code is related to anonymous journey
	changeLanguage(language: string): void {
		if (this.topupService.topupLanguage === language) {
			return;
		}
		this.topupService.topupLanguage =
			language === LanguageEnum.spanish.toString() ? LanguageEnum.spanish : LanguageEnum.english;
		this.changingLanguage = true;
		this.setUiContent(this.topupService.topupLanguage);
		setTimeout(() => {
			this.changingLanguage = false;
			this.paymentNavigationService.changeLanguage(
				language === LanguageEnum.spanish.toString() ? PaymentLanguages.spanish : PaymentLanguages.english
			);
		}, 300);
		this.changeLanguageTagging(language);
	}
	changeLanguageTagging(language: string): void {
		switch (language) {
			case 'es':
				this.storageService.topupPrepaid.page_language = tagging.staticVariables.pciPrepaidLanguage.spanish;
				this.tagging.track(
					tagging.pciPrepaid.interactions.clickSpanishLanguage.eventName,
					tagging.pciPrepaid.interactions.clickSpanishLanguage.data,
					tagging.pciPrepaidPages.serviceSelectorPage,
					this.storageService.topupPrepaid
				);
				break;
			case 'en':
				this.storageService.topupPrepaid.page_language = tagging.staticVariables.pciPrepaidLanguage.english;
				this.tagging.track(
					tagging.pciPrepaid.interactions.clickEnglishLanguage.eventName,
					tagging.pciPrepaid.interactions.clickEnglishLanguage.data,
					tagging.pciPrepaidPages.serviceSelectorPage,
					this.storageService.topupPrepaid
				);
				break;
		}
	}

	sendClickPlusTagging(): void {
		this.tagging.track(
			tagging.pciPrepaid.interactions.clickAddNewLineNumber.eventName,
			tagging.pciPrepaid.interactions.clickAddNewLineNumber.data,
			tagging.pciPrepaidPages.serviceSelectorPage,
			this.storageService.topupPrepaid
		);
	}

	/** Method for setting msisdn if plus is apeared and send tagging */
	onChevronClicked(): void {
		this.topupService.isReturnFromPermissionScreen = false;
		if (this.topupService.showPlus) {
			this.topupService.msisdn = this.form.controls['msisdn'].value;
		}
		this.sendClickOnManageCreditCardTagging();
	}

	onScroll(event: any): void {
		this.scrolledView = event.target.scrollTop !== 0;
		this.reachScrollerEnd = Math.ceil(event.target.scrollTop + event.target.clientHeight) >= event.target.scrollHeight;
		// this.topupService.dynamicTrayData.trayInnerContentScrolling = this.scrolledView;
	}

	sendClickOnManageCreditCardTagging(): void {
		this.tagging.track(
			tagging.pciPrepaid.interactions.clickViewSavedCreditCard.eventName,
			tagging.pciPrepaid.interactions.clickViewSavedCreditCard.data,
			tagging.pciPrepaidPages.serviceSelectorPage
		);
	}

	/** This method should be invoked when wallet chevron data loaded */
	stopLoading(): void {
		this.getEntryPointForPegaOffers();
		this.sendPageTagging();
		if (this.loadScreenForFirstTime) {
			this.sendNewTopupPageTagging();
			this.loadScreenForFirstTime = false;
		}
	}
	reloadDashboard(): void {
		if (this.router.url.toLowerCase().includes(config.dashboard.route)) {
			this.dashboardService.relaodDashboardSubject.next();
		}
	}

	/**
	 * Method for showing an overlay to open contact List
	 */
	navigateToContactListPermission(): void {
		this.showMsisdnFieldSuccessIcon = false;
		this.ableToOpenContactList = this.storage.getLocalStorage(LOCAL_STORAGE_KEYS.ableToOpenContactList);
		let wcsKeyForTopupJourney: PaymentJourny;
		if (this.isAnonymousProcess) {
			wcsKeyForTopupJourney = this.isOpenedFromIkki
				? PaymentJourny.prePaidAnonymus
				: PaymentJourny.prePaidAnonymusThirdParty;
			this.paymentNavigationService.paymentJourneyParams.language =
				this.topupService.topupLanguage === 'en' ? PaymentLanguages.en : PaymentLanguages.es;
		} else {
			wcsKeyForTopupJourney = PaymentJourny.prepaidTopup;
		}
		if (!this.ableToOpenContactList) {
			this.paymentNavigationService.openPaymentComponent(PaymentPages.contactListPermission, wcsKeyForTopupJourney, [
				{
					key: PaymentCompoentInputData_Keys.paymentDeleteCardConfiramtionComponent_firstBtnAction,
					value: this.openContactList.bind(this),
				},
				{
					key: PaymentCompoentInputData_Keys.paymentDeleteCardConfiramtionComponent_secondBtnAction,
					value: this.returnToFirstScreens.bind(this),
				},
				{
					key: PaymentCompoentInputData_Keys.paymentDeleteCardConfiramtionComponent_isContactListPermissionPage,
					value: true,
				},
			]);
		} else {
			this.showMsisdnFieldSuccessIcon = false;
			this.topupService.openContactList().then(() => {
				this.checkOnPhoneNumber();
			});
		}
	}

	/** Method called when take permession to open contact list */
	openContactList(): void {
		this.topupService.isReturnFromPermissionScreen = true;
		this.showMsisdnFieldSuccessIcon = false;
		this.paymentNavigationService.back();
		this.storage.setLocalStorage(LOCAL_STORAGE_KEYS.ableToOpenContactList, true);
		this.topupService.openContactList().then(() => {
			this.topupService.returnDataFromContactList.next();
		});
	}
	/** Method called when discard permession to open contact list */
	returnToFirstScreens(): void {
		this.topupService.isReturnFromPermissionScreen = true;
		this.paymentNavigationService.back();
	}

	getEntryPointForPegaOffers(selectedService?: string): void {
		this.showFullLoader = true;
		this.showAmountSelector = false;
		const entrypointScreenCode: string = entryPointsMVA10.dashboard;
		const entrypointCustomerAccountId: string = this.subscriptionsService.customerData.customerAccountsId;
		const entrypointMaxNumber: (value: Record<string, any>) => number = (value: Record<string, any>): number =>
			value.dashboard.config.maxNumberEP;
		const entrypointInsideJourney: boolean = false;
		const entrypointServiceId: string = selectedService
			? selectedService
			: this.subscriptionsService.customerData.currentService.id;
		const entrypointVersion: (value: Record<string, any>) => string = (value: Record<string, any>) =>
			value.commercial.versionPortalEP;

		const entrypointResponseModel$: (value: Record<string, any>) => Observable<EntryPointResponseModel> = (
			value: Record<string, any>
		) =>
			this.entryPoints.getEntryPoints(
				entrypointScreenCode,
				entrypointCustomerAccountId,
				entrypointMaxNumber(value),
				entrypointInsideJourney,
				entrypointServiceId,
				entrypointVersion(value)
			);
		this.translateService
			.get('v10')
			.pipe(
				concatMap((value: Record<string, any>) => entrypointResponseModel$(value)),
				filter((response) => {
					if (response.entryPoints.length === 0) {
						this.showAmountSelector = true;
						this.showFullLoader = false;
						this.resetToDefaultAmountSelector();
					}
					return !!response.entryPoints.length;
				}),
				map((res) => res.entryPoints.find((entryPoint) => this.pegaOffers.includes(entryPoint.code)))
			)
			.subscribe(
				(res) => {
					this.showPegaOffer = !!res;
					this.showFullLoader = false;
					if (this.showPegaOffer) {
						this.pegaOfferPCTCode = res.code;
						if (pegaPlatinumOfferCodes.includes(res.code)) {
							this.showPegaPlatinumOffer = true;
							this.showPegaOffer = false;
							this.pegaOfferPCTCode = res.code;
							this.mapPegaPlatinumOffer(res);
						} else {
							this.mapPegaEntryPointOfferValues(res);
						}
					} else {
						this.resetToDefaultAmountSelector();
					}
					this.showAmountSelector = true;
				},
				(err) => {
					this.showFullLoader = false;
					this.showPegaOffer = false;
					this.showAmountSelector = true;
				}
			);
	}

	mapPegaEntryPointOfferValues(res: EntryPointModel): void {
		this.entryPoints.entryCode = res.code;
		const pegaValues: Record<'nameVar' | 'valueVar', string> = {
			nameVar: res?.nameVar,
			valueVar: res?.valueVar,
		};
		this.pegaOfferKeyValue = this.pegaParserService.parse(pegaValues?.nameVar, pegaValues?.valueVar, {
			transformToObject: true,
		});
		this.topupService.pegaOfferKeyValue = this.pegaOfferKeyValue;
		if (this.showPegaOffer) {
			this.validateOfferDate =
				this.dailyDateCheckForPegaOffer <=
				this.utilityService.getDateFromString(this.pegaOfferKeyValue[pegaOfferFields.acceptanceDate]);
		}
		this.setPegaOfferTextAndAmount();
	}

	setPegaOfferTextAndAmount(): void {
		this.pegaPricPlanRate =
			this.pegaOfferPCTCode === pegaOffersIDs.Offer15PCT
				? pegaOfferFields.pricePlanRateFiftenPCT
				: pegaOfferFields.pricePlanRate;
		this.translateService.get('common.itemsList.PEGAOffer.body').subscribe((data: string) => {
			this.pegaOfferText = data.replace('{0}', this.pegaOfferKeyValue[pegaOfferFields.extraBalancePCT]);
		});
		const selectedIndex: number = this.amountSelectorValues.findIndex((amount) => amount?.selected);
		const selectPegaAmountIndex: number = this.amountSelectorValues.findIndex(
			(amount) => amount?.name === (this.pegaOfferKeyValue[this.pegaPricPlanRate] | 0).toString()
		);
		this.amountSelectorValues[selectedIndex].selected = false;
		if (selectPegaAmountIndex !== -1) {
			this.amountSelectorValues[selectPegaAmountIndex].selected = true;
		}
		this.topupService.curruentSelectedAmountSelectorValue = +(this.pegaOfferKeyValue[this.pegaPricPlanRate] | 0);
		this.selectedAmountSelectorValues(selectPegaAmountIndex);
	}

	resetToDefaultAmountSelector(): void {
		const selectedIndex: number = this.amountSelectorValues.findIndex((amount) => amount?.selected);
		this.amountSelectorValues[selectedIndex].selected = false;
		this.selectedAmountSelectorValue = 2;
		this.amountSelectorValues[this.selectedAmountSelectorValue].selected = true;
		this.selectedAmountSelectorValues(this.selectedAmountSelectorValue);
	}

	checkExistedBundles(): void {
		const existedBundles: Dashboard[] = this.serviceBalanceService.bundlesInPlanAndGroup?.find(
			(bundle) => bundle?.serviceId === this.subscriptionsService.customerData.currentService.id
		)?.bundles;
		this.customerBillService.getAmountPrepaid(existedBundles);
		this.topupService.currentBalance = this.customerBillService.valueAmountPrepaid;
	}

	mapPegaPlatinumOffer(res: EntryPointModel): void {
		this.entryPoints.entryCode = res.code;
		this.topupService.pegaPlatinumOfferCode = res.code;
		this.topupService.isPegaPlatinum = true;
		const pegaValues: Record<'nameVar' | 'valueVar', string> = {
			nameVar: res?.nameVar,
			valueVar: res?.valueVar,
		};
		this.pegaOfferKeyValue = this.pegaParserService.parse(pegaValues?.nameVar, pegaValues?.valueVar, {
			transformToObject: true,
			isPegaPlatinum: true,
		});
		this.topupService.pegaOfferKeyValue = this.pegaOfferKeyValue;
		this.setPegPlatinumOfferTextAndAmount();
	}

	setPegPlatinumOfferTextAndAmount(): void {
		const selectedIndex: number = this.amountSelectorValues.findIndex((amount) => amount?.selected);
		const selectPegaAmountIndex: number = this.amountSelectorValues.findIndex(
			(amount) => amount?.name === (this.pegaOfferKeyValue[pegaOfferFields.platinumMinRecharge] | 0).toString()
		);
		this.amountSelectorValues[selectedIndex].selected = false;
		const result: number = this.roundPegaPlatinumAmountSelector(
			this.pegaOfferKeyValue[pegaOfferFields.platinumMinRecharge]
		);
		if (selectPegaAmountIndex !== -1) {
			this.amountSelectorValues[selectPegaAmountIndex].selected = true;
		} else {
			const selectPegaPlatinumAmountIndex: number = this.amountSelectorValues.findIndex(
				(amount) => amount?.name === result.toString()
			);
			if (selectPegaPlatinumAmountIndex !== -1) {
				this.amountSelectorValues[selectPegaPlatinumAmountIndex].selected = true;
			}
		}
		this.translateService
			.get(`v10.prepaidPEGAPlatinumOffer.${this.entryPoints.entryCode}.topup.body`)
			.subscribe((data: string) => {
				this.pegaPlatinumOfferText = data.replace(
					'{minimum_amount}',
					this.pegaOfferKeyValue[pegaOfferFields.platinumMinRecharge]
				);
			});
		this.topupService.curruentSelectedAmountSelectorValue = +(result | 0);
		this.selectedAmountSelectorValues(selectPegaAmountIndex);
		this.sendPlatinumOfferTagging();
	}

	roundPegaPlatinumAmountSelector(value: string): number {
		if (+value % 5 === 0) {
			return Math.floor(+value / 5) * 5;
		} else {
			return Math.floor(+value / 5) * 5 + 5;
		}
	}

	sendPlatinumOfferTagging(): void {
		if (this.entryPoints.entryCode === pegaPlatinumOfferCodes[0]) {
			this.tagging.track(
				tagging.pegaPlatinumJourney.almostIN.interaction.infoMessageTopUp.eventName,
				tagging.pegaPlatinumJourney.almostIN.interaction.infoMessageTopUp.data,
				tagging.pciPrepaidPages.serviceSelectorPage
			);
		} else {
			this.tagging.track(
				tagging.pegaPlatinumJourney.almostOut.interaction.infoPlatinumOfferTopUp.eventName,
				tagging.pegaPlatinumJourney.almostOut.interaction.infoPlatinumOfferTopUp.data,
				tagging.pciPrepaidPages.serviceSelectorPage
			);
		}
	}

	sendToolTipPlatinumOfferTagging(): void {
		if (this.entryPoints.entryCode === pegaPlatinumOfferCodes[0]) {
			this.tagging.track(
				tagging.pegaPlatinumJourney.almostIN.interaction.clickOnToolTip.eventName,
				tagging.pegaPlatinumJourney.almostIN.interaction.clickOnToolTip.data,
				tagging.pciPrepaidPages.serviceSelectorPage
			);
		} else {
			this.tagging.track(
				tagging.pegaPlatinumJourney.almostOut.interaction.clickOnToolMoreInfoIcon.eventName,
				tagging.pegaPlatinumJourney.almostOut.interaction.clickOnToolMoreInfoIcon.data,
				tagging.pciPrepaidPages.serviceSelectorPage
			);
		}
	}

	checkIfPegaOrPlatinum(offerEntryPoint: EntryPointModel): void {
		if (pegaPlatinumOfferCodes.includes(offerEntryPoint.code)) {
			this.showPegaPlatinumOffer = true;
			this.showPegaOffer = false;
			this.pegaOfferPCTCode = offerEntryPoint.code;
			this.mapPegaPlatinumOffer(offerEntryPoint);
		} else {
			this.pegaOfferPCTCode = offerEntryPoint.code;
			this.showPegaOffer = true;
			this.mapPegaEntryPointOfferValues(offerEntryPoint);
		}
	}
}
