export interface SharedServicesPegaParserParseOptions {
	separator?: RegExp;
	transformToObject?: boolean;
	isPegaPlatinum?: boolean;
}

export interface SharedServicesPegaParserStringToHashMapOptions {
	separator?: RegExp;
	isPegaPlatinum?: boolean;
}

export type SharedServicesPegaParserHashMap = Map<number | string, string | RegExp | undefined>;
export type SharedServicesPegaParserZippedHashMap = Map<string, string>;

export enum SharedServicesPegaParserError {
	InvalidZipSizes = 'Invalid HashMaps. The HashMaps should have the same sizes.',
	InvalidZipSeparators = 'Invalid HashMaps. The HashMaps should have the same separators.',
	InvalidStringToParse = 'Invalid string to parse. Must have minimum two fragments.',
	InvalidStringArgument = 'Invalid argument to parse. Must be of type string.',
}
