import { TranslateService } from '@ngx-translate/core';
import { PaymentCancel, PaymentDeleteConfirmation } from '../../../models/payment';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentCancelInterface } from './payment-cancel-interface';

export class PaymentCancelDefault implements PaymentCancelInterface {
	constructor(private payNavSer: PaymentNavigationService, private translate: TranslateService) {}

	/**
	 * function to fill cancel overlay with it's proper
	 * wcs values and btn actions
	 */
	fillCancelOverlay(): PaymentCancel {
		const cancelOverlay: PaymentCancel = new PaymentCancel();
		this.translate.get(this.payNavSer.getWcsPath(PaymentPages.cancel)).subscribe((data) => {
			cancelOverlay.desc = data.description;
			cancelOverlay.firstBtnText = data.button1;
			cancelOverlay.secondBtnText = data.button2;
		});
		cancelOverlay.firstBtnAction = this.firstBtnAction.bind(this);
		cancelOverlay.secondBtnAction = this.secondBtnAction.bind(this);
		return cancelOverlay;
	}
	/**
	 * the default implmentaion of the first btn in cancel payment screen
	 */
	firstBtnAction(): void {
		this.payNavSer.back();
	}
	/**
	 * the default implmentaion of the second btn in cancel payment screen
	 */
	secondBtnAction(): void {
		this.payNavSer.isRestartingPayment = false;
		if (this.payNavSer.customCancelOverlaySecondBtnAction) {
			this.payNavSer.customCancelOverlaySecondBtnAction();
		} else {
			this.defaultClose();
		}
	}
	/**
	 * the default implmentaion to close the tray
	 */
	defaultClose(): void {
		this.payNavSer.defaultKoClose();
	}
	/**
	 * function to fill Delete Crediet Card Confirmation Overlay with it's proper
	 * wcs values and btn actions
	 */
	fillDeleteCredietCardConfirmationOverlay(): PaymentDeleteConfirmation {
		const deleteConfirmation: PaymentDeleteConfirmation = new PaymentDeleteConfirmation();
		this.translate.get(this.payNavSer.getWcsPath(PaymentPages.deleteConfiramtion)).subscribe((data) => {
			deleteConfirmation.desc = data.subtitle;
			deleteConfirmation.secondDesc = data.description;
			deleteConfirmation.icon = data.icon;
			deleteConfirmation.firstBtnText = data.button1;
			deleteConfirmation.secondBtnText = data.button2;
		});
		return deleteConfirmation;
	}

	/**
	 * function to fill open permission contact list Overlay with it's proper
	 * wcs values and btn actions
	 */
	fillContactListPermissionOverlay(): PaymentDeleteConfirmation {
		const deleteConfirmation: PaymentDeleteConfirmation = new PaymentDeleteConfirmation();
		this.translate.get(this.payNavSer.getWcsPath(PaymentPages.contactListPermission)).subscribe((data) => {
			deleteConfirmation.desc = data.subtitle;
			deleteConfirmation.secondDesc = data.description;
			deleteConfirmation.icon = data.icon;
			deleteConfirmation.firstBtnText = data.button1;
			deleteConfirmation.secondBtnText = data.button2;
		});
		return deleteConfirmation;
	}
}
