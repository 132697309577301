import { Component, OnInit, Input, forwardRef, AfterContentChecked } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl, NG_VALIDATORS } from '@angular/forms';
import { siebleLinks } from '../../constants/defines';
/**Just empty definition for a function */
const noop = () => {};
/**custom validation function call in every change */
export function createRadioButtonValidator(required: boolean) {
	return (c: UntypedFormControl) => {
		if (required) {
			return c.value ? null : {};
		} else {
			return null;
		}
	};
}
@Component({
	moduleId: module.id,
	selector: 'sp-radio-button',
	templateUrl: './sp-radio-button.component.html',
	styleUrls: ['./sp-radio-button.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SpRadioButtonComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => SpRadioButtonComponent),
			multi: true,
		},
	],
})
export class SpRadioButtonComponent implements OnInit, ControlValueAccessor, AfterContentChecked {
	/**Placeholders for the callbacks which are later providesd by the Control Value Accessor */
	onTouchedCallback: () => void = noop;
	onChangeCallback: (_: any) => void = noop;
	/**array of choices that will bind in radio buttons */
	@Input() choices: any[];
	/**group name of radio button group */
	@Input() groupName: string;
	/**flag to identify whether we should choose radio button or not */
	@Input() required: boolean;
	/**flag to identify whether there is a default value checked or not */
	@Input() defaultChecked: boolean;
	/**error message */
	@Input() message: string;
	/**flag to indicate if it's the company chooser's selection drop down */
	@Input() isCompanyChooser: boolean;
	/**Flag to check if has External Companies */
	@Input() hasExternalCompanies: boolean;
	/**empty function that will hold the validation function */
	validateFn: any;
	/**the inner value of radio button */
	innerValue: any;
	/**function that will call only when initialize to updat
	 * refresh the inner value in case of it is not like the old inner value.
	 * @param  {any} The text value.
	 */
	LinkExternal: any = siebleLinks;
	writeValue(value: any): void {
		if (value) {
			this.innerValue = value;
		} else if (this.defaultChecked) {
			if (this.choices.length > 0) {
				this.innerValue = this.choices[0];
			}
		}
	}
	/**function that will be called with every change in form*/
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}
	/**function that will be called with every touch of form control*/
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}
	/**function that will call onChangeCallback to validat the form after any change*/
	ngAfterContentChecked() {
		this.onChangeCallback(this.innerValue);
	}

	/**
	 * function that will update the inner value with the current choice
	 * @param choice
	 */
	updateForm(choice: any) {
		this.innerValue = choice;
	}
	checked(choice: any) {
		return this.innerValue === choice;
	}
	/**this function will be called after any change to validate the form */
	validate(c: UntypedFormControl) {
		return this.validateFn(c);
	}
	/**function that will run when initialize the component */
	ngOnInit() {
		this.validateFn = createRadioButtonValidator(this.required);
	}
}
