import { LoggerLevel } from '../app/shared/enums/loggerLevel.enum';

export const environment = {
	production: true,
	middlewareBaseUrl: 'https://api.dxldev.local.vodafone.es/',
	contentBaseUrl: 'https://assets-es-dev.dxldev.local.vodafone.es/mves/',
	imageBaseUrl: 'https://assets-es-dev.dxldev.local.vodafone.es',
	stubsFlag: false,
	environmentName: 'test',
	taggingEnv: 'dev',
	otp: '',
	seibelBaseUrl: 'http://10.225.239.228:3080/Smartphones/logadoNuevoPortal',
	smapi: 'GCP_PRE', // GCP_PRE	 Used for pre-production (testing) - - Google Cloud
	plumeUrl: 'https://piranha-beta.prod.us-west-2.aws.plumenet.io/',
	level: LoggerLevel.LOG,
	CLIENT_ID: 'jwGZysjQIXK2HRJUFbZjAQABgUdEyXxB',
	MSISDNScope: 'MSISDN_AUTHENTICATION_MVA_FREETEXT_ANY_USER',
	prodContent: 'prod_web.json',
	actorToken: '7lBdGJEvfjsjtDa6AP9oR0q4G9ynHppm',
	actorTokenForGroup: 'NH1g1JyBU7h0F5l3yItRvzpljuEswF23',
	googleToken: '6LdysDEaAAAAANHfLTJ1Ds854CAY5gcrGHK8sEFc',
	chatUrl: 'https://livechat-loader-vf-es-prepro.brandembassy.com/1.0/livechat-window',
	anonymousPaymentClientId: 'Hmt5c33snQrv6zRUzeiOKnGCIa4a7Ool',
	eCareUrl: 'http://vodlta25:25283/c/mivodafone/es/acceso-ikki/?destination={0}',
	casBaseUrl: 'http://vodlta25:25283/c/cas/login',
	ADARA_CLIENT_ID: 'gDfTo1LIP9T6myLyigKGgZl24uGJdQMq',
	EDC_CLIENT_ID: 'pVhYCuG3QMAWthCKajHmaKhNgAlApwEk',
};
