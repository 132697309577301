<vfac-overlay
	#historyOLOverlay
	[overlayData]="historyOLOverlayData"
	[show]="showHistoryOLOverlay"
	[loaded]="!showSpinner"
	[loaderType]="'vodafone'"
	(closed)="closeOverlay(true)"
>
	<div body class="mvf-order-history-modal">
		<div class="mvf-order-history-modal__scroll" *ngIf="!showSpinner && !showError">
			<div class="mvf-order-history-modal-container">
				<div class="mvf-order-history-modal-content">
					<div *ngIf="orderHistory">
						<ng-container *ngIf="lastStatusMap?.status !== olStatusEnum.CANCELLED">
							<div class="order-history-pretitle mva10-u--body">
								{{ 'v10.myOrder.orderHistoryModal.eta' | translate }}
							</div>
							<div class="order-history-title mva10-u-heading--3-bd">{{ estimatedDate }}</div>
						</ng-container>

						<div class="order-history-table vfes-mt">
							<div class="order-history-steps-dots">
								<ng-container *ngTemplateOutlet="showMobileSteps ? stepsMobile : stepsResponsive"> </ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</vfac-overlay>

<ng-template #stepsMobile>
	<!-- Vertical steps (mobile view) -->
	<div
		class="order-history-steps-dots__step-vertical"
		*ngFor="let record of shipmentSteps"
		[ngClass]="{
			'open-step': record.currentStep,
			'active-step': record.currentStep,
			'done-step': record.completedStep && !record.currentStep
		}"
	>
		<div class="step-header">
			<span class="step-bubble">
				<span class="step-inner-bubble"> </span>
			</span>
			<div class="step-title">
				<div class="step-title__status mva10-u--body" [ngClass]="{ 'mva10-u--body-bd': record.currentStep }">
					{{ (record.statusDescription | translate) || record.statusDescription }}
				</div>
				<div class="step-title__date mva10-u--body" *ngIf="record.date">
					{{ record.date | date : "dd/MM/yyyy '-' HH:mm" }}
				</div>
			</div>
		</div>
		<div class="step-body">
			<span class="step-bar"> </span>
			<div class="step-content" *ngIf="record.currentStep">
				<div class="order-history-content">
					<!-- No subSteps case -->
					<div class="order-history-content__step" *ngIf="!record.subSteps || record.subSteps.length === 0">
						<div class="order-history-content__image" *ngIf="record.status !== olStatusEnum.INIT">
							<img
								src="{{ appService.getImgFullPath('v10.myOrder.orderHistoryModal.img.' + record.status | translate) }}"
								alt="{{ record.status }}"
							/>
						</div>
						<div class="order-history-content__description">
							<p class="mva10-u--body">{{ record.detailedDescription }}</p>
						</div>
						<div class="order-history-content__call-button" *ngIf="record.callButton">
							<span class="order-history-content__call-button__wrapper">
								<a class="mva10-u--body-bd" href="{{ 'tel:' + record.callButton }}" (click)="onSolveButtonClick()">
									{{ 'v10.myOrder.orderHistoryModal.historicoOLBotonSolucionar' | translate }}
								</a>
							</span>
						</div>
						<ng-container
							[ngTemplateOutlet]="askForReviewTemplate"
							[ngTemplateOutletContext]="{ outdated: record.outdatedSLA }"
						>
						</ng-container>
					</div>
					<!-- Record with subSteps -->
					<div class="order-history-content__subStep" *ngFor="let subStep of record.subSteps; let subStepIndex = index">
						<div
							class="order-history-content__subStep__status mva10-u--body"
							[ngClass]="{ 'mva10-u--body-bd': subStepIndex + 1 === record.subSteps.length }"
						>
							{{ (subStep.statusDescription | translate) || subStep.statusDescription }}
						</div>
						<div class="order-history-content__subStep__date mva10-u--body" *ngIf="subStep.date">
							{{ subStep.date | date : "dd/MM/yyyy '-' HH:mm" }}
						</div>
						<ng-container *ngIf="subStepIndex + 1 === record.subSteps.length">
							<div class="order-history-content__image" *ngIf="subStep.status !== olStatusEnum.INIT">
								<img
									src="{{
										appService.getImgFullPath('v10.myOrder.orderHistoryModal.img.' + subStep.status | translate)
									}}"
									alt="{{ subStep.status }}"
								/>
							</div>
							<div class="order-history-content__description">
								<p class="mva10-u--body" [innerHtml]="subStep.detailedDescription | trustHtml"></p>
							</div>
							<div class="order-history-content__call-button" *ngIf="subStep.callButton">
								<span class="order-history-content__call-button__wrapper">
									<a class="mva10-u--body-bd" href="{{ 'tel:' + subStep.callButton }}" (click)="onSolveButtonClick()">
										{{ 'v10.myOrder.orderHistoryModal.historicoOLBotonSolucionar' | translate }}
									</a>
								</span>
							</div>
							<ng-container
								[ngTemplateOutlet]="askForReviewTemplate"
								[ngTemplateOutletContext]="{ outdated: subStep.outdatedSLA }"
							>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #stepsResponsive>
	<!-- Horizontal steps (tablet view) -->
	<div
		class="order-history-steps-dots__step-horizontal__header"
		[ngClass]="{ 'align-right': shipmentSteps?.length > 6 }"
	>
		<div
			class="order-history-steps-dots__step-horizontal"
			*ngFor="let record of shipmentSteps"
			[ngClass]="{
				'open-step': record.currentStep,
				'active-step': record.currentStep,
				'done-step': record.completedStep && !record.currentStep
			}"
		>
			<div class="step-header">
				<span class="step-bubble">
					<span class="step-inner-bubble"> </span>
				</span>
				<span class="step-bar"> </span>
				<div class="step-title">
					<div class="step-title__status mva10-u--body" [ngClass]="{ 'mva10-u--body-bd': record.currentStep }">
						{{ (record.statusDescription | translate) || record.statusDescription }}
					</div>
					<div class="step-title__date mva10-u--body" *ngIf="record.date">
						{{ record.date | date : "dd/MM/yyyy '-' HH:mm" }}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="order-history-steps-dots__step-horizontal__body">
		<div
			class="order-history-steps-dots__step-horizontal"
			*ngFor="let record of shipmentSteps"
			[ngClass]="{
				'open-step': record.currentStep,
				'active-step': record.currentStep,
				'done-step': record.completedStep && !record.currentStep
			}"
		>
			<div class="step-body">
				<div class="step-content" *ngIf="record.currentStep">
					<div class="order-history-content">
						<!-- No subSteps case (tablet view) -->
						<div
							class="order-history-steps-dots__step-horizontal__image-wrapper"
							*ngIf="lastStatusMap.status !== olStatusEnum.INIT"
						>
							<div class="order-history-content__image">
								<img
									src="{{
										appService.getImgFullPath('v10.myOrder.orderHistoryModal.img.' + lastStatusMap.status | translate)
									}}"
									alt="{{ lastStatusMap.status }}"
								/>
							</div>
						</div>
						<div class="order-history-steps-dots__step-horizontal__descriptions-wrapper">
							<div class="order-history-content__step" *ngIf="!record.subSteps || record.subSteps.length === 0">
								<div class="order-history-content__descriptions-wrapper">
									<div class="order-history-content__description">
										<p class="mva10-u--body">{{ record.detailedDescription }}</p>
									</div>
									<div class="order-history-content__call-button" *ngIf="record.callButton">
										<span class="order-history-content__call-button__wrapper">
											<a
												class="mva10-u--body-bd"
												href="{{ 'tel:' + record.callButton }}"
												(click)="onSolveButtonClick()"
											>
												{{ 'v10.myOrder.orderHistoryModal.historicoOLBotonSolucionar' | translate }}
											</a>
										</span>
									</div>
									<ng-container
										[ngTemplateOutlet]="askForReviewTemplate"
										[ngTemplateOutletContext]="{ outdated: record.outdatedSLA }"
									>
									</ng-container>
								</div>
							</div>
							<!-- Record with subSteps (tablet view) -->
							<div
								class="order-history-content__subStep"
								*ngFor="let subStep of record.subSteps; let subStepIndex = index"
							>
								<div
									class="order-history-content__subStep__status mva10-u--body"
									[ngClass]="{ 'mva10-u--body-bd': subStepIndex + 1 === record.subSteps.length }"
								>
									{{ (subStep.statusDescription | translate) || subStep.statusDescription }}
								</div>
								<div class="order-history-content__subStep__date mva10-u--body" *ngIf="subStep.date">
									{{ subStep.date | date : "dd/MM/yyyy '-' HH:mm" }}
								</div>
								<ng-container *ngIf="subStepIndex + 1 === record.subSteps.length">
									<div class="order-history-content__description">
										<p class="mva10-u--body" [innerHtml]="subStep.detailedDescription | trustHtml"></p>
									</div>
									<div class="order-history-content__call-button" *ngIf="subStep.callButton">
										<span class="order-history-content__call-button__wrapper">
											<a
												class="mva10-u--body-bd"
												href="{{ 'tel:' + subStep.callButton }}"
												(click)="onSolveButtonClick()"
											>
												{{ 'v10.myOrder.orderHistoryModal.historicoOLBotonSolucionar' | translate }}
											</a>
										</span>
									</div>
									<ng-container
										[ngTemplateOutlet]="askForReviewTemplate"
										[ngTemplateOutletContext]="{ outdated: subStep.outdatedSLA }"
									>
									</ng-container>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #askForReviewTemplate let-outdatedSLA="outdated">
	<ng-container *ngIf="outdatedSLA">
		<div class="order-history-content__loading vfes-mt" *ngIf="isLoadingSLA">
			<sp-skeleton [imageSize]="24" [numberOfBars]="1"> </sp-skeleton>
		</div>
		<ng-container *ngIf="!isLoadingSLA">
			<ng-container *ngIf="!slaRestError; else slaErrorTemplate">
				<div class="order-history-content__call-button" *ngIf="clientHasOpenTicket">
					<mva10-c-alert state="info" layout="horizontal">
						<span
							class="mva10-u--body"
							[innerHTML]="
								(ticketStatus === ticketStatusEnum.Closed || ticketStatus === ticketStatusEnum.Solved
									? 'v10.myOrder.orderHistoryModal.autoiOlSlaAlertSolvedTicket'
									: 'v10.myOrder.orderHistoryModal.autoiOlSlaAlertOpenTicket'
								) | translate
							"
						>
						</span>
					</mva10-c-alert>
				</div>
				<div class="order-history-content__call-button" *ngIf="!clientHasOpenTicket">
					<span class="order-history-content__call-button__wrapper">
						<a class="mva10-u--body-bd" (click)="onAskForReviewButtonClick()">
							{{ 'v10.myOrder.orderHistoryModal.reviewButton' | translate }}
						</a>
					</span>
				</div>
			</ng-container>
		</ng-container>
	</ng-container>
	<ng-template #slaErrorTemplate>
		<div class="order-history-content__call-button">
			<mva10-c-alert state="neutral" layout="horizontal">
				<span class="mva10-u--body" [innerHTML]="'v10.myOrder.orderHistoryModal.alerterror' | translate"> </span>
			</mva10-c-alert>
		</div>
	</ng-template>
</ng-template>

<!-- Other overlays -->

<vfac-overlay
	#askForReviewOverlay
	[overlayData]="askForReviewOverlayData"
	[show]="showAskForReviewOverlay"
	[loaded]="!isLoadingAskForReview"
	loaderType="vodafone"
	(closed)="isLoadingAskForReview ? null : (showAskForReviewOverlay = false)"
>
	<div body>
		<div class="ask-for-review-overlay">
			<ng-container *ngIf="askForReviewResult === 'ok'">
				<div class="content result-ok">
					<div class="image">
						<img
							[attr.src]="appService.getImgFullPath('v10.myOrder.orderHistoryModal.img.checkok' | translate)"
							alt="Check OK"
						/>
					</div>
					<div class="title vfes-title--bold mva10-u-heading--3">
						{{ 'v10.myOrder.orderHistoryModal.oktitle' | translate }}
					</div>
					<div class="review-code vfes-mt">
						{{ 'v10.myOrder.orderHistoryModal.ticketnumber' | translate }} <strong> {{ askForReviewTicketId }} </strong>
					</div>
					<div class="description mva10-u--body vfes-mt">
						{{ 'v10.myOrder.orderHistoryModal.desc' | translate }}
					</div>
				</div>
				<div class="button-container vfes-mt">
					<mva10-c-button (mva10OnClick)="showAskForReviewOverlay = false">
						{{ 'v10.myOrder.orderHistoryModal.autoiOlSlaModalOkButton' | translate }}
					</mva10-c-button>
				</div>
			</ng-container>
			<ng-container *ngIf="askForReviewResult === 'error'">
				<div class="content result-error">
					<div class="image">
						<img
							[attr.src]="appService.getImgFullPath('v10.myOrder.orderHistoryModal.img.alert' | translate)"
							alt="Alert"
						/>
					</div>
					<div class="title vfes-title--bold mva10-u-heading--3">
						{{ 'v10.myOrder.orderHistoryModal.errortitle' | translate }}
					</div>
					<div class="description mva10-u--body vfes-mt-">
						{{ 'v10.myOrder.orderHistoryModal.errorDesc' | translate }}
					</div>
				</div>
				<div class="button-container vfes-mt">
					<mva10-c-button (mva10OnClick)="showAskForReviewOverlay = false">
						{{ 'v10.myOrder.orderHistoryModal.autoiOlSlaModalErrorButton' | translate }}
					</mva10-c-button>
				</div>
			</ng-container>
		</div>
	</div>
</vfac-overlay>
