import { Component, Input } from '@angular/core';
import { ProductsAndServicesService } from '../../../products-and-services/products-and-services.service';

@Component({
	selector: 'sp-landing-percentage',
	templateUrl: './landing-percentage.component.html',
	styleUrls: ['./landing-percentage.component.scss'],
})
export class LandingPercentageComponent {
	@Input() public title;
	@Input() public percentage;
	@Input() public remaining;
	@Input() public unit;

	constructor(public productsAndServicesService: ProductsAndServicesService) {}
}
