<div class="payment-cash-desk">
	<form class="payment-cash-desk__form-container" [formGroup]="partialPaymentForm" (ngSubmit)="onSubmit()">
		<div class="payment-cash-desk__total-pay" *ngIf="!showPayPartial">
			<div>
				<div class="payment-cash-desk__desc-container">
					<p class="payment-cash-desk__desc">
						{{ totalPaymentCashDeskWcsData.description }}
					</p>
					<img class="payment-cash-desk__wink-image" [src]="winkImage" alt="wink" />
				</div>
				<p class="payment-cash-desk__amount">
					{{ billingService.roundAmount(cashDeskAmount) }} <span class="euro-sign">€</span>
				</p>
			</div>
			<div class="cashdesk-partial">
				<div>
					<p class="cashdesk-partial__desc">{{ totalPaymentCashDeskWcsData.partialOption }}</p>
					<a class="cashdesk-partial__link" (click)="payPartial()">
						{{ totalPaymentCashDeskWcsData.payPartialButtonText }}
					</a>
				</div>
				<img class="cashdesk-partial__icon" src="https://assets-es-dev.dxldev.local.vodafone.es/assets/images/ic-info-circle-mid.png" />
			</div>
			<sp-payment-wallet-chevron [marginTop]="32"> </sp-payment-wallet-chevron>
		</div>
		<div class="payment-cash-desk__partial-pay" *ngIf="showPayPartial">
			<p class="payment-cash-desk__desc-partial">
				{{ partialPaymentCashDeskWcsData.subtitle }}
			</p>
			<p class="payment-cash-desk__amount sm-margin">
				{{ billingService.roundAmount(cashDeskAmount) }} <span class="euro-sign">€</span>
			</p>
			<p class="payment-cash-desk__desc-partial desc-margin">{{ partialPaymentCashDeskWcsData.description }}</p>
			<div class="text-box">
				<sp-text
					ngDefaultControl
					formControlName="payPartialField"
					(onBlurFn)="addEurSignToValidInputOnBlur()"
					[mva10Textbox]="true"
					[placeholder]="partialPaymentCashDeskWcsData.textboxPlaceholder"
					(onFocusFn)="onFocus()"
					[showRightIconSuccess]="false"
					[showErrorMessage]="showErrorMsg"
					[patternErrorMessage]="partialPaymentCashDeskWcsData.paidAmountErrorMsg"
					[redHelperText]="true"
					[resetButton]="partialPaymentForm.controls['payPartialField'].value && !showErrorMsg && showXIcon"
					(xButtonClicked)="clickXButtonInTextField()"
				>
				</sp-text>
			</div>
			<sp-payment-wallet-chevron [marginTop]="48"> </sp-payment-wallet-chevron>
		</div>
		<div
			class="btn-container"
			[ngClass]="{
				'payment-cash-desk__increase-top-margin': isFieldFocused && !paymentWalletService.selectedCard,
				'payment-in-advance__decrease-top-margin': isFieldFocused && paymentWalletService.selectedCard
			}"
		>
			<button
				type="submit"
				[disabled]="!partialPaymentForm.valid && showPayPartial"
				[ngClass]="{
					'invalid-form': !partialPaymentForm.valid && showPayPartial,
					'cashdesk-partial__button': !showPayPartial
				}"
			>
				{{
					!showPayPartial
						? totalPaymentCashDeskWcsData.confirmButtonText + ' ' + billingService.roundAmount(cashDeskAmount) + '€'
						: partialPaymentCashDeskWcsData.confirmButtonText
				}}
			</button>
		</div>
	</form>
	<div
		class="payment-cash-desk__footer"
		[ngClass]="{ 'margin-footer-total': !showPayPartial, 'margin-footer-partial': showPayPartial }"
	>
		<div class="payment-cash-desk__footer-images">
			<div class="visa-image">
				<img [src]="visaImage" alt="visa" />
			</div>
			<div class="mastercard-image">
				<img [src]="masterCardImage" alt="mastercard" />
			</div>
		</div>
		<div class="payment-cash-desk__footer-securedssl">
			<p>{{ securedSslText }}</p>
		</div>
	</div>
</div>
