import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';

@Component({
	selector: 'sp-tray-loading-mode',
	templateUrl: './tray-loading-mode.component.html',
	styleUrls: ['./tray-loading-mode.component.scss'],
})
export class TrayLoadingModeComponent implements OnInit {
	constructor(public appservice: AppService) {}

	ngOnInit() {}
}
