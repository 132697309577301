<ng-container *ngFor="let item of shopPloicies">
	<mva10-c-card (click)="downloadDocument(item.desc)" [modifier]="'no-shadow'" class="shop-policy__card">
		<span id="LegalShopPolicyTitle" class="shop-policy__title"> {{ item.title }} </span>
		<mva10-c-icon
			id="LegalShopPolicyIcon"
			class="shop-policy__icon"
			[iconId]="'icon-download'"
			[title]="'download file'"
			[size]="25"
		>
		</mva10-c-icon>
	</mva10-c-card>
	<hr class="shop-policy__bottom-line" />
</ng-container>
