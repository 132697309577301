<div
	bsModal
	#modal="bs-modal"
	class="modal fade"
	tabindex="-1"
	role="dialog"
	aria-labelledby="mySmallModalLabel"
	aria-hidden="true"
	[config]="{ keyboard: false, backdrop: 'static' }"
>
	<div class="full modal-dialog" [ngClass]="{ 'show': show, 'notification-style': notificationStyle }">
		<div class="modal-header">
			<button
				id="SpModalFullComp_btn_hide"
				*ngIf="!hideX"
				type="button"
				class="close-container pull-right"
				aria-label="Close"
				(click)="hide(); close()"
			>
				<span class="icon icon-close modal-full-close"> </span>
			</button>
		</div>
		<div class="modal-body">
			<div
				*ngIf="headerTitle && !spinner && !newSpinner"
				[ngClass]="{ 'center-title': centerTheTitle }"
				class="image-modal-overlay head"
			>
				<p class="title" id="SpModalFullComp_title">{{ headerTitle }}</p>
			</div>
			<div class="modal-img-position" *ngIf="imgSrc && !spinner && !newSpinner">
				<img
					id="SpModalFullComp_icon"
					class="img-responsive-modal"
					[ngClass]="{ 'different-width': differentIconWidth }"
					[src]="appService.getImgFullPath(imgSrc)"
				/>
			</div>
			<div
				*ngIf="bodyTitle && !spinner && !newSpinner"
				id="SpModalFullComp_bodyTitle"
				[ngClass]="{ 'center-title': centerTheTitle }"
				class="head"
			>
				<h3 class="bold-font" [ngClass]="{ 'different-size': differentFontSize }">{{ bodyTitle }}</h3>
			</div>
			<h2 *ngIf="bodyRouter" class="title">{{ bodyRouter }}</h2>
			<div *ngIf="bodyRouter">
				<p class="desc">{{ textRouter }}</p>
				<div *ngIf="textAddress">
					<span class="icon icon-location col-xs-1"> </span>
					<p class="desc">{{ textAddress }}</p>
				</div>
				<p class="desc">{{ textAuto }}</p>
			</div>
			<div *ngIf="!spinner && !newSpinner">
				<div *ngIf="items" class="items-selection container" [ngClass]="alarmClass">
					<div
						*ngFor="let item of items"
						class="section-list row {{ item.class }}"
						[ngClass]="{ 'remove-under-padding': !item.lineOne && !item.lineTwo }"
					>
						<div
							class="list"
							[ngClass]="{
								'col-xs-8': !adjustStyleForSingleCharge,
								'col-xs-9': adjustStyleForSingleCharge,
								'adjust-list-alignment': adjustStyleForSingleCharge
							}"
						>
							<p *ngIf="item.title" class="title">{{ item.title }}</p>
							<p *ngIf="item.lineOne" class="description">{{ item.lineOne }}</p>
							<p *ngIf="item.lineTwo" class="description">{{ item.lineTwo }}</p>
						</div>
						<div
							class="price"
							[ngClass]="{
								'col-xs-4': !adjustStyleForSingleCharge,
								'adjust-price-alignment': adjustStyleForSingleCharge,
								'col-xs-3': adjustStyleForSingleCharge
							}"
						>
							<p *ngIf="item.rightText" class="right-text">
								<span class="dash" *ngIf="item.rightText.addDash">-</span>
								{{ item.rightText.amount | currency : 'EUR' : 'symbol' | sliceCurrency }}€
							</p>
						</div>
					</div>
					<div class="total-section row" *ngIf="totalTitle || totalPrice">
						<div class="total">
							<div class="list col-xs-6">
								<p *ngIf="totalTitle" class="title">{{ totalTitle }}</p>
							</div>
							<div *ngIf="totalPrice" class="price col-xs-6">
								<p class="right-text">
									<span class="dash" *ngIf="totalPrice.addDash">-</span>
									{{ totalPrice.amount | currency : 'EUR' : 'symbol' | sliceCurrency }}€
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div
						id="SpModalFullComp_desc"
						*ngIf="bodyContent"
						class="desc-extra-modal col-xs-12"
						[innerHtml]="bodyContent"
					></div>
					<div
						id="SpModalFullComp_desc"
						*ngIf="bodyContentSafeHtml"
						class="desc-extra-modal col-xs-12"
						[innerHtml]="bodyContentSafeHtml"
					></div>
					<div
						id="SpModalFullComp_desc"
						*ngIf="htmlContent"
						class="desc-extra-modal col-xs-12"
						[innerHtml]="htmlContent"
					></div>
				</div>
			</div>
			<!-- <div *ngIf="spinner" class="swiper-slide-content" fxLayout="column" fxLayoutAlign="center center">
                <div class="billing-spinner-container">
                    <div class="loader"> </div>
                </div>
            </div> -->
			<ng-content> </ng-content>
			<!------------->
			<div class="error-buttons-container">
				<div
					class="mva10-o-equal-cols custom-col"
					[ngClass]="{ 'mva10-o-equal-cols reverseee': appService.isResponsiveView }"
				>
					<mva10-c-button
						*ngIf="(primaryButton || primaryButtonText !== '') && !spinner && !newSpinner"
						(click)="primaryButtonClick()"
						[body]="primaryButtonText"
						[styleClass]="'mva10-c-button--primary ' + primaryButtonStyle"
						id="SpModalFullComp_btn_primaryClick"
					>
					</mva10-c-button>

					<mva10-c-button
						*ngIf="(secondaryButton || secondaryButtonText !== '') && !spinner && !newSpinner"
						class="form-group"
						id="SpModalFullComp_btn_secondaryClick"
						[styleClass]="'mva10-c-button--secondary mva10-c-button--block'"
						class="{{ secondaryButtonStyle }}"
						[body]="secondaryButtonText"
						(mva10OnClick)="secondaryButtonClick()"
					>
					</mva10-c-button>

					<mva10-c-button
						*ngIf="(tertiaryButton || tertiaryButtonText !== '') && !spinner && !newSpinner"
						(click)="tertiaryButtonClick()"
						[body]="tertiaryButtonText"
						[styleClass]="'mva10-c-button--tertiary'"
						id="SpModalFullComp_btn_tertiaryClick"
					>
					</mva10-c-button>
				</div>
			</div>
			<!--------->
			<div class="spinnerContainer col-xs-12" *ngIf="newSpinner">
				<div class="loaderCF"></div>
			</div>
		</div>
	</div>
</div>
