<div *ngIf="isCarousel && !isUniqueCard">
	<mva10-c-carousel
		[config]="carouselConfig"
		[secondary]="true"
		[hasMask]="true"
		[alignBottom]="true"
		(mva10OnChange)="carrouselCardChange($event, dmpArray)"
	>
		<mva10-c-carousel-item *ngFor="let dmp of dmpArray; index as i">
			<mva10-c-card-discover
				[textLink]="dmp.textCTA"
				[title]="dmp.title"
				[text]="dmp.text"
				[bgImg]="dmp.image"
				[bgImgTablet]="dmp.image_rsp"
				[bgImgDesktop]="dmp.image_rsp"
				[darkText]="!darkMode[i]"
				(click)="buildDeeplinkNavigate(dmp, i)"
			>
			</mva10-c-card-discover>
		</mva10-c-carousel-item>
	</mva10-c-carousel>
</div>
<div *ngIf="!isCarousel && !isUniqueCard">
	<div class="mva10-u--mt6x" *ngFor="let item of dmpArray; index as i">
		<mva10-c-card-discover
			[textLink]="item.textCTA"
			[title]="item.title"
			[text]="item.text"
			[bgImg]="item.image"
			[bgImgTablet]="item.image_rsp"
			[bgImgDesktop]="item.image_rsp"
			[darkText]="!darkMode[i]"
			(click)="buildDeeplinkNavigate(item, i)"
		>
		</mva10-c-card-discover>
	</div>
</div>
<div *ngIf="isUniqueCard && showUniqueCard">
	<mva10-c-card-discover
		[textLink]="dmpObject.textCTA"
		[title]="dmpObject.title"
		[text]="dmpObject.text"
		[bgImg]="dmpObject.image"
		[bgImgTablet]="dmpObject.image_rsp"
		[bgImgDesktop]="dmpObject.image_rsp"
		[darkText]="!darkModeUnique"
		(click)="buildDeeplinkNavigate(dmpObject)"
	>
	</mva10-c-card-discover>
</div>
