import { StorageService } from '../../core/services/storage.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SubscriptionService } from '../../core/services/subscription.service';
import { CustomerType } from '../../shared/enums/customerType.enum';
import { SiteType } from '../../shared/enums/siteType.enum';
import { DeepLinkingService } from '../../shared/services/deep-linking.service';
import { config } from '../../../config/pages-config';
import { SiteHandlingService } from '../../shared/services/site-handling.service';
import { ImpersonationService } from '../../shared/services/impersonation.service';
import { ImpersonationGroup } from '../../shared/enums/impersonationGroup.enum';

@Injectable({
	providedIn: 'root',
})
export class PostPaidGuard implements CanActivate, CanActivateChild {
	constructor(
		private subscriptionData: SubscriptionService,
		private StorageService: StorageService,
		private deepLinkingService: DeepLinkingService,
		private router: Router,
		private impersonationService: ImpersonationService,
		private siteHandlingService: SiteHandlingService
	) {}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (state.url === config.billing.currentConsumption.route) {
			return true;
		}
		if (
			this.siteHandlingService.isSiteP2() ||
			(this.impersonationService.impersonatedUser &&
				this.impersonationService.impersonatedUser.Group &&
				this.impersonationService.impersonatedUser.Group.toLocaleLowerCase() ===
					ImpersonationGroup.PayADebt.toLocaleLowerCase())
		) {
			return true;
		} else {
			if (
				this.StorageService.userProfile.customerType.toString().toLowerCase() === CustomerType.Authorized.toLowerCase()
			) {
				return true;
			} else if (
				this.StorageService.userProfile.customerType.toString().toLowerCase() === CustomerType.Consumer.toLowerCase()
			) {
				if (this.subscriptionData.customerData.SiteType.toLowerCase() === SiteType.Postpaid.toLowerCase()) {
					return true;
				} else {
					this.navigateTODashboard();
					return false;
				}
			} else {
				this.navigateTODashboard();
				return false;
			}
		}
	}
	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		return this.canActivate(childRoute, state);
	}
	navigateTODashboard() {
		this.deepLinkingService.isDeepLink = false;
		this.deepLinkingService.isError = true;
		this.router.navigate([config.dashboard.route]);
	}
}
