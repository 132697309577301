export enum AutoiOption {
	/**
	 * Option to allow TIA ticket creation
	 */
	WRONG_CABLES = 'wrong_cables',
	/**
	 * Option to check OL and open GP ticket after SLA expiration
	 */
	NOT_RECEIVED = 'not_received',
	/**
	 * Option to start Schaman process
	 */
	NOT_WORKING = 'not_working',
	/**
	 * Option to show chat option (debug option)
	 */
	CHAT = 'chat',
}
