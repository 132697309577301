import { OutOfBundleModel } from './out-of-bundle-model.model';

export class OutOfBundle {
	bundles: Array<OutOfBundleModel>;
	totalAmount = 0;
	id: String;
	display: Boolean = false;
	showBillButton: Boolean = true;
	showLockIcon: boolean;
}
