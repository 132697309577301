export type GetOrders = GetOrder[];

export interface ExternalIdentifier {
	id: string;
	owner: string;
}

export interface RelatedParty {
	name?: string;
	contactMedium?: ContactMedium;
}

export interface ContactMedium {
	validFor: ValidFor;
}

export interface ValidFor {
	endDateTime: string;
}
export interface GetOrder {
	category?: string;
	agreement?: Agreement[];
	description?: string;
	expectedCompletionDate?: string;
	document?: Document[];
	id: string;
	name?: string;
	note?: Note[];
	orderCategory?: OrderCategory[];
	orderTotalPrice?: OrderTotalPrice[];
	processFlow?: ProcessFlow;
	productOrderItem?: ProductOrderItem[];
	requestedStartDate?: string;
	state?: string;
	subState?: string;
	externalIdentifier?: ExternalIdentifier[];
	orderDate?: string;
	cancellationDate?: string;
	completionDate?: string;
	relatedParty?: RelatedParty[];
	deliveryInstruction?: DeliveryInstruction;
}

export interface Agreement {
	href: string;
	id: string;
	name: string;
}

export interface Note {
	id: string;
	text: string;
}

export interface ProductOrderItem {
	product: Product;
	characteristic?: Characteristic[];
	productOffering: ProductOffering;
	state: string;
	date?: number;
	itemPrice?: ItemPrice[];
	idFather?: string;
	logisticState?: string;
}

export interface ItemPrice {
	priceType?: string;
	price?: Price;
}

export interface Price {
	taxIncludedAmount?: TaxIncludedAmount;
	dutyFreeAmount?: DutyFreeAmount;
}

export interface TaxIncludedAmount {
	value?: number;
}

export interface DutyFreeAmount {
	value?: number;
}

export interface Product {
	id: string;
	name?: string;
	productCharacteristic?: ProductCharacteristic[];
}

export interface ProductCharacteristic {
	name: string;
	value: string;
}

export interface ProductOffering {
	id: string;
	name: string;
}

export interface DeliveryInstruction {
	geographicSite: GeographicSite;
}

export interface GeographicSite {
	'@baseType': unknown;
	'@schemaLocation': unknown;
	'@type': unknown;
	'calendar': unknown;
	'code': unknown;
	'description': unknown;
	'href': unknown;
	'id': unknown;
	'name': string;
	'place': unknown;
	'relatedParty': unknown;
	'siteRelationship': unknown;
	'status': unknown;
}

export interface OrderCategory {
	description: string;
	name: string;
}

export interface ProcessFlow {
	processFlowSpecificationRef: ProcessFlowSpecificationRef;
	taskFlow: TaskFlow[];
	characteristic?: Characteristic[];
}

export interface ProcessFlowSpecificationRef {
	name: string;
}

export interface TaskFlow {
	characteristic: Characteristic[];
	id: string;
	state: string;
	taskFlowSpecificationRef: TaskFlowSpecificationRef;
}

export interface Characteristic {
	name: string;
	value?: string;
}

export interface TaskFlowSpecificationRef {
	name: string;
}

export interface Document {
	name: string;
}

export interface OrderTotalPrice {
	price: {
		taxRate: number;
	};
	priceType: string;
}

export class MonitoringPurchase {
	monitoring_purchase_id?: string;
	monitoring_purchase_status?: string;
	monitoring_purchase_type?: string;
	monitoring_purchase_step?: string;
	monitoring_purchase_flux?: string;
	monitoring_purchase_substatus?: string;
}

export class ResultImage {
	default?: string;
	sapImage: string;
}

export class TypesReturn {
	value?: string;
	label?: string;
	desc?: string;
	checked?: boolean;
	id?: string;
}

export class ReturnResponse {
	orderId?: string;
}

export class BodyResponse {
	'deliveryInstruction'?: DeliveryInstructionPost;
	'productOrderItem'?: ProductOrderItemPost[];
	'relatedParty'?: RelatedPartyPost[];
	'@type'?: string;
}

export class DeliveryInstructionPost {
	geographicSite?: GeographicSitePost;
	type: string;
}

export class GeographicSitePost {
	place: Place;
}

export class Place {
	'streetType': string;
	'streetName': string;
	'streetNr': string;
	'portalDoor': string;
	'locality': string;
	'stateOrProvince': string;
	'postCode': string;
	'geographicSubAddress': GeographicSubAddress;
	'role': string;
	'@referredType': string;
	'@type': string;
}

export class GeographicSubAddress {
	'subUnitNumber': string;
	'levelNumber': string;
	'block': string;
	'@type': string;
}

export class ProductOrderItemPost {
	'id': string;
	'action': string;
	'product': ProductSerialNumber;
	'@type': string;
}

export class ProductSerialNumber {
	productSerialNumber: string;
}

export class RelatedPartyPost {
	'id': string;
	'role': string;
	'contactMedium'?: ContactMediumPost;
	'@referredType': string;
	'@type'?: string;
}

export class ContactMediumPost {
	characteristic: CharacteristicPost;
}

export class CharacteristicPost {
	phoneNumber: string;
	contactType: string;
}
