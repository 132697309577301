import { RestrictionsStatus } from '../shared/enums/restrictions-status.enum';

export class CallRestrictions {
	all: RestrictionsStatus;
	incoming: RestrictionsStatus;
	international: RestrictionsStatus;
	roaming: RestrictionsStatus;
	incomingRoaming: RestrictionsStatus;
	highLevel: RestrictionsStatus;
	data?: RestrictionsStatus;
	lowLevel: RestrictionsStatus;
	specialTariffMobile?: RestrictionsStatus;
	specialTariffLandline?: RestrictionsStatus;
}
