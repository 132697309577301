<div *ngIf="showFailureComponent">
	<div class="failure-container" [ngClass]="{ 'failure-handling': responsiveFailure }">
		<img [src]="failureObject?.img" class="failure-img" [attr.id]="id ? id + 'Img' : null" />
		<span class="failure-title" [attr.id]="id ? id + 'Title' : null"> {{ failureObject?.title }} </span>
		<span class="failure-description" [attr.id]="id ? id + 'Desc' : null"> {{ failureObject?.description }} </span>
		<span class="failure-action-link" [attr.id]="id ? id + 'ActionLink' : null" (click)="actionLink()">
			{{ failureObject?.actionlinkText }}
		</span>
	</div>
</div>
