import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OverlayModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { OrdersService } from '../services/orders.service';
import { AppService } from 'src/app/app.service';
import { SchamanServiceType } from '../../../enums/schaman-service-type.enum';
import { stringOfTrue } from '../../../constants/defines';

@Component({
	selector: 'sp-neba-installation-tv-unicast',
	templateUrl: './sp-neba-installation-tv-unicast.component.html',
	styleUrls: ['./sp-neba-installation-tv-unicast.component.scss'],
})
export class SpNebaInstallationTvUnicastComponent implements OnInit {
	@Output() openOverlayCheckInstallation: EventEmitter<SchamanServiceType> = new EventEmitter();
	@Output() onClose: EventEmitter<string> = new EventEmitter();
	progressBarTitles: string[] = [];
	progressBarCurrentStep: number;
	overlayPage: number;
	pageName: string = 'guia instalacion desconocida';
	pagesNames: string[] = [];
	overlayLoaded: boolean;
	showNebaInstallationGuideTvUnicastOverlay: boolean;
	nebaInstallationGuideTvOverlayData: OverlayModel;
	stringOfTrue: string = stringOfTrue;

	constructor(public ordersService: OrdersService, public translate: TranslateService, public appService: AppService) {
		this.nebaInstallationGuideTvOverlayData = {
			isFullScreen: true,
			isCenterTitle: true,
			title: translate.instant('v10.myOrder.nebaInstallationTvUnicast.guideNebaTvTitle'),
		};
	}

	ngOnInit(): void {
		this.overlayLoaded = false;
		this.showNebaInstallationGuideTvUnicastOverlay = true;
		this.overlayPage = 1;
		this.updateTitleAndProgressBar();
		this.pageName = 'guia instalacion deco unicast';
		this.progressBarTitles = [
			this.translate.instant('v10.myOrder.nebaInstallationTvUnicast.guideNebaTvStep1Title'),
			this.translate.instant('v10.myOrder.nebaInstallationTvUnicast.guideNebaTvStep2Title'),
			this.translate.instant('v10.myOrder.nebaInstallationTvUnicast.guideNebaTvStep3Title'),
		];
		this.pagesNames = ['overlay inicio guia', 'material', 'instalacion cableado', 'sintonizacion'];

		this.sendTaggingFirstView();

		setTimeout(() => {
			this.overlayLoaded = true;
		}, 600);
	}

	updateTitleAndProgressBar(): void {
		if (this.overlayPage < 2) {
			this.progressBarCurrentStep = 1;
			this.nebaInstallationGuideTvOverlayData.title = this.translate.instant(
				'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvTitle'
			);
		} else {
			this.progressBarCurrentStep = this.overlayPage - 1;
			this.nebaInstallationGuideTvOverlayData.title = ' ';
		}
	}

	sendInteractionAnalytics(link_type: string): void {
		// TODO-TAG
	}

	sendPageAnalytics(page: string[]): void {
		// TODO-TAG
	}

	sendTaggingFirstView(): void {
		this.resetTagging(true);
		this.sendPageAnalytics([this.pageName, this.pagesNames[0]]);
	}

	sendPageOverlayTagging(): void {
		this.resetTagging();
		this.sendPageAnalytics([this.pagesNames[this.overlayPage - 1]]);
	}

	overlayGoForwards(): void {
		if (this.overlayPage !== this.pagesNames.length) {
			// Not last page, go to next one
			this.overlayPage++;
			this.updateTitleAndProgressBar();
			this.sendPageOverlayTagging();
		} else {
			// Last guide's page, close
			this.closeGuideOverlay(true);
		}
	}

	overlayGoBackwards(): void {
		if (this.overlayPage === 1) {
			// First guide's page, close
			this.closeGuideOverlay();
		} else {
			// Not in first page, go to previous one
			this.overlayPage--;
			this.updateTitleAndProgressBar();
			this.sendPageOverlayTagging();
		}
	}

	closeGuideOverlayAndOpenInstallationCheck(): void {
		this.openOverlayCheckInstallation.emit(SchamanServiceType.NEBA_TV);
	}

	closeGuideOverlay(forceClose?: boolean, buttonType?: string): void {
		if (forceClose) {
			if (buttonType === 'btnCerrarGuia') {
				this.showNebaInstallationGuideTvUnicastOverlay = false;
				this.sendInteractionAnalytics('click en cerrar');
			} else if (buttonType === 'btnComprobarInstalacion') {
				this.showNebaInstallationGuideTvUnicastOverlay = false;
				this.sendInteractionAnalytics('click en comprobar instalacion');
			}
			setTimeout(() => {
				this.onClose.emit();
			}, 500);
		} else {
			this.sendPageAnalytics(['overlay abandonar guia']);
		}
	}

	resetTagging(removePageName?: boolean): void {
		// TODO-TAG
	}

	ngOnDestroy(): void {
		this.resetTagging(true);
	}
}
