import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../../core/services/configuration.service';
import { config } from '../../../config/pages-config';
import { TranslateService } from '@ngx-translate/core';
import { Mva10FullModslServiceService } from '../../shared/services/mva10-full-modsl-service.service';
import { AppService } from '../../app.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { tagging } from '../../../config/tagging-config';

@Injectable({
	providedIn: 'root',
})
export class BizTalkReplicaModeGuard implements CanActivate {
	constructor(
		private configurationService: ConfigurationService,
		private translateService: TranslateService,
		private fullModslServiceService: Mva10FullModslServiceService,
		private appService: AppService,
		private tagging: TaggingHelperService,
		private router: Router
	) {}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (!this.configurationService.configuration.biztalkAvailabilityFlag) {
			if (state.url.includes(config.billing.billPayment.route)) {
				this.showPaymentModal();
			} else {
				this.showBiztalkModal();
				this.sendTaggingView(state);
			}
			this.router.navigate([config.dashboard.route]);
			return false;
		} else {
			return true;
		}
	}

	showBiztalkModal(): void {
		this.translateService.get('common.messagesList.systemUnavailableMsg').subscribe((data) => {
			this.showFullModal(
				data.systemUnavailableMsg_title,
				data.systemUnavailableMsg_description,
				data.systemUnavailableMsg_button1.text,
				data.systemUnavailableMsg_icon.url
			);
		});
	}
	sendTaggingView(state: RouterStateSnapshot): void {
		if (state.url.includes(config.FaultManagement.route)) {
			this.tagging.view(tagging.averiaNotAvailable);
		} else if (state.url.includes(config.billing.route)) {
			this.tagging.view(tagging.billingNotAvailable);
		}
	}

	/**
	 * Set full modal overlay data
	 */
	showPaymentModal(): void {
		this.translateService.get('payment.messagesList.errorLoadingPayment').subscribe((data) => {
			this.showFullModal(data.title, data.description, data.confirmButton.text, data.icon.url);
		});
	}

	/**
	 * Set full modal overlay data and open it
	 */
	showFullModal(title: string, subTitle: string, buttonText: string, imgUrl: string): void {
		this.fullModslServiceService.errorTitle = title;
		this.fullModslServiceService.errorSubtitle = subTitle;
		this.fullModslServiceService.errorButton1 = buttonText;
		this.fullModslServiceService.img = imgUrl;

		this.appService.showErrorFullModal = true;
	}
}
