<div
	class="history-dropdown-btn"
	[ngClass]="{ 'selected': isSelected, 'ppe-selected': isExpanded }"
	(click)="toggleHistory()"
>
	<div class="full-height histoy-dropdown-btn-internal-wrapper">
		<span class="set-margin" *ngIf="!selectDatePicker && addPrefix"> {{ prefix }} </span>
		<span *ngIf="!selectDatePicker" [innerHTML]="historyValue"> </span>
		<span class="set-margin" *ngIf="selectDatePicker" [innerHTML]="historyValue"> </span>
		<i class="icon icon-chevron-down history-dropdown-arrow"> </i>
		<i class="icon icon-chevron-up history-dropdown-arrow none-display"> </i>
	</div>
</div>
<div class="history-dropdown none-display">
	<p class="history-dropdown-title">{{ title }}</p>
	<div (click)="toggleHistory()" *ngFor="let item of dataSet" class="history-dropdown-row">
		<span
			(click)="setHistoryValue(item)"
			[ngClass]="{ selected: item.duration == selectedDuration }"
			class="history-dropdown-Selection full-height"
		>
		</span>
		<span class="check-box-label prefix" *ngIf="addPrefix"> {{ prefix }} </span>
		<span (click)="setHistoryValue(item)" class="check-box-label" [innerHTML]="item.label"> </span>
	</div>
	<div (click)="toggleHistory()" *ngIf="showDatePickerFlage" class="history-dropdown-row">
		<span
			(click)="openDatepicker()"
			[ngClass]="{ selected: selectDatePicker }"
			class="history-dropdown-Selection full-height"
		>
		</span>
		<span (click)="openDatepicker()" class="check-box-label"> {{ datePickerText }} </span>
	</div>
	<div class="history-dropdown-sparator-height"></div>
</div>
