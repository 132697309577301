export enum PaymentPages {
	thirdPartyContainer = 'thirdPartyContainer',
	ok = 'okScreen',
	ko = 'koScreen',
	cancel = 'cancelScreen',
	loading = 'loading',
	wallet = 'walletScreen',
	ticket = 'ticket',
	dept = 'dept',
	inadvance = 'inadvance',
	cashDesk = 'cashDesk',
	partialCashDesk = 'partialCashDesk',
	partialDept = 'partialDept',
	otherPaymentMethods = 'otherPaymentMethods',
	deleteConfiramtion = 'deleteConfiramtion',
	contactListPermission = 'contactListPermission',
	topupScreen = 'topupScreen',
	paymentAuthntication = 'paymentAuthntication',
	paymentAnonymousBilling = 'paymentAnonymousBilling',
	paymentOtp = 'paymentOtp',
	paymentDisclaimer = 'paymentDisclaimer',
	saveCreditCard = 'saveCreditCard',
	cardPaymentCommercial = 'eShopMiVF',
	financedDevicesTotal = 'financedDevicesTotal',
	PaymentClarifyUser = 'PaymentClarifyUser',
	TopupCountDownScreen = 'topupCountDown',
	HowToSpendNewBalance = 'spendNewBlance',
	SmartPayPromoAfterTopUp = 'smartPayAfterTopUp',
}
