import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { responseType } from '../../../../enums/response-type.enum';
import { ResponseBoxData } from '../../../../models/responseBoxData.model';
import { UtilsService } from '../../services/utils.service';
import { AppService } from '../../../../../app.service';
import { MyOrdersTaggingService } from '../../../../services/my-orders-tagging.service';

@Component({
	selector: 'sp-response-box',
	templateUrl: './sp-response-box.component.html',
	styleUrls: ['./sp-response-box.component.scss'],
})
export class SpResponseBoxComponent implements OnInit {
	@Input() type: string;
	@Input() data: string;
	@Input() isCambioCita: boolean;
	@Input() isGuiaActivacion: boolean;
	@Input() isPort: boolean;
	@Input() isChange: boolean;
	@Input() codigoRechazo: string;
	@Input() genericError: boolean;
	@Input() overlayData: ResponseBoxData;
	@Input() loading: boolean;
	@Input() isModal: boolean;
	@Output() loadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() callback: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() dismiss: EventEmitter<void> = new EventEmitter<void>();
	@Output() retry: EventEmitter<string> = new EventEmitter<string>();
	showSuccess: boolean;
	showError: boolean;
	titulo: string;
	constructor(
		private utilsService: UtilsService,
		public appService: AppService,
		private tagging: MyOrdersTaggingService
	) {}

	ngOnInit(): void {
		this.showSuccess = this.type === responseType.success;
		this.showError = this.type === responseType.error;
		this.loading = false;
		this.loadingChange.emit(false);
		this.titulo = this.overlayData?.titulo
			? this.overlayData.titulo
			: this.utilsService.handleTituloResponseBox(this.isCambioCita, this.isPort, this.isChange, this.showError);
		this.initTagging();
	}

	initTagging(): void {
		if (this.genericError) {
			this.tagging.sendPage(
				this.codigoRechazo
					? ['detalle pedido', 'modificar datos ' + this.codigoRechazo.replace('RECH_', '').toLowerCase(), 'ko total']
					: ['detalle pedido', 'portabilidad iniciada', 'error total']
			);
		} else {
			if (this.isPort) {
				this.resetTagging();
				this.tagIsPort();
			} else if (this.isChange) {
				this.resetTagging();
				this.tagIsChange();
			}
		}
	}

	tagIsPort(): void {
		if (this.showError) {
			this.tagging.sendPage(['detalle pedido', 'portabilidad iniciada', 'error']);
		} else {
			this.tagging.sendPage(
				this.codigoRechazo
					? ['detalle pedido', 'modificar datos ' + this.codigoRechazo.replace('RECH_', '').toLowerCase(), 'ok']
					: ['detalle pedido', 'portabilidad iniciada', 'success']
			);
		}
	}

	tagIsChange(): void {
		if (this.showError) {
			this.tagging.sendPage(
				this.codigoRechazo
					? ['detalle pedido', 'modificar datos ' + this.codigoRechazo.replace('RECH_', '').toLowerCase(), 'ko parcial']
					: ['detalle pedido', 'modificar tus datos', 'error']
			);
		} else {
			this.tagging.sendPage(['detalle pedido', 'modificar tus datos', 'success']);
		}
	}

	resetTagging(): void {
		this.tagging.extendUtagPageName(
			[
				'detalle pedido',
				'portabilidad iniciada',
				'modificar tus datos',
				'error',
				'success',
				'error total',
				'modificar datos iccid',
				'modificar datos ident',
				'ko total',
				'ko parcial',
				'ok',
			],
			null,
			true
		);
	}

	ngOnDestroy(): void {
		this.resetTagging();
	}

	clickButton(): void {
		if (this.showError && (this.isPort || this.isChange)) {
			this.resetTagging();
			this.tagging.extendUtagPageName(
				this.codigoRechazo
					? ['detalle pedido', 'modificar datos ' + this.codigoRechazo.replace('RECH_', '').toLowerCase(), 'ko parcial']
					: ['detalle pedido', 'portabilidad iniciada', 'error']
			);
			this.loading = true;
			this.loadingChange.emit(true);
			this.retry.emit('sendRetryAnalytics');
		} else {
			this.callback.emit(this.showSuccess);
		}
	}

	cancelChange(): void {
		this.dismiss.emit();
	}
}
