import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
	name: 'categorizeBy',
})
export class CategorizeByPipe implements PipeTransform {
	/** This pipe should return an array of object {key , list}
	 * key: the key you ctagorized by
	 * list: list of your array type
	 * @param filedPath you can send a normal key like "name"
	 * or send a path of the key like "timePeriod.startDate"
	 * @param callbackFn used to change value of the grouping field (fieldPath) before grouping
	 */
	transform(value: any[], filedPath: string, callbackFn: any) {
		if (value) {
			const result: { key: string; list: any[] }[] = [];
			let groupedArray: any[];
			if (!callbackFn) {
				groupedArray = _.groupBy(value, filedPath);
			} else {
				value.forEach((el) => (el._.groupByKey = callbackFn(this.getValueByPath(el, filedPath))));
				groupedArray = _.groupBy(value, 'groupByKey');
			}
			const arrKeys = Object.keys(groupedArray);
			arrKeys.forEach((key) => {
				result.push({ key, list: groupedArray[key] });
			});
			/** the result should be an array
			 * each element consists of (key & list)
			 */
			return result;
		}
	}

	private getValueByPath(originalObject: any, filedPath: string) {
		const parts = filedPath.split('.');
		let obj = originalObject;
		parts.forEach((part, index) => {
			if (!obj[parts[index]]) {
				obj[parts[index]] = {};
			}
			obj = obj[parts[index]];
		});

		return obj;
	}
}
