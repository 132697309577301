import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'sp-marketplace-iframe',
	templateUrl: './marketplace-iframe.component.html',
	styleUrls: ['./marketplace-iframe.component.scss'],
})
export class MarketplaceIframeComponent implements OnInit {
	@Input() url: string;
	urlIframe: SafeResourceUrl;

	constructor(public sanitizer: DomSanitizer) {}

	ngOnInit(): void {
		this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
	}
}
