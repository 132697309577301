export class PersonalModel {
	name: string;
	firstsurname: string;
	secondsurname: string;
	identificationType: number;
	identificationNumber: string;
	birthdateday: string;
	birthdatemonth: string;
	birthdateyear: string;
	email: string;
	nationality: number;
	phonenumber: string;
	flagTitularFijo: boolean;
	flagTitularMovil: boolean;
}

export interface SavePersonalDataReponse {
	result: {
		status: number;
	};
	data: {
		orderToken: string;
		shopTypePrincipal: string;
		orderNum: string;
		completed: boolean;
		order_id: string;
		orderDateCreation: string;
	};
}
