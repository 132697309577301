export enum SiteStatus {
	None = '',
	Active = 'Active',
	Activo = 'Activo',
	Suspending = 'Suspending',
	Suspendido = 'Suspendido',
	Cancelled = 'Cancelled',
	Cancelado = 'Cancelado',
	Disconnected = 'Disconnected',
	Desconectado = 'Desconectado',
	Disconnect_Pend = 'Disconnect Pend',
	Pend_de_Desconectar = 'Pend de Desconectar',
	Reconnect_Pend = 'Reconnect Pend',
	Pend_de_Reconectar = 'Pend de Reconectar',
	Pending_Change = 'Pending Change',
	Pend_de_Cambio = 'Pend de Cambio',
	Install_Pend = 'Install Pend',
	Pending_installation = 'Pending installation',
	Pend_de_Instalar = 'Pend de Instalar',
	Disconnected_no_payment_for_more_than_6_months = 'Disconnected no payment for more than 6 months',
	Desconectado_No_Pago_Mas_De_Seis_Meses = 'Desconectado No Pago Mas De Seis Meses',
	Disconnected_for_more_than_6_months = 'Disconnected for more than 6 months',
	Desconectada_Mas_De_Seis_Meses = 'Desconectada Mas De Seis Meses',
	Pend_de_Instalar_Pend = 'Pend de Instalar Pend',
	Unpaid_Disconnected = 'Unpaid Disconnected',
	Desconectado_No_Pago = 'Desconectado No Pago',
	Suspended = 'Suspended',
	Active_Pending = 'ACTIVE_PENDING',
	disconnectedNoPaymentLess6 = 'desconectado no pago',
	disconnectedLess6 = 'desconectado',
	suspended = 'suspendido',
}
export enum siteTypes {
	maestra = 'maestra',
	subNoResponsable = 'subcuenta no responsable',
	nonResponsible = 'NON_RESPONSIBLE',
}
