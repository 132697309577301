<div class="service-result">
	<div class="content result-ok">
		<div class="image">
			<img [src]="imageRoute" alt="Network" id="schamanClientConfirmationImg" />
		</div>
		<div class="title vfes-title--bold mva10-u-heading--3" id="schamanClientConfirmationTitle">
			{{ title }}
		</div>
		<div class="description mva10-u--body" id="schamanClientConfirmationDescription">
			{{ description }}
		</div>

		<div class="button-container vfes-mt">
			<mva10-c-button (mva10OnClick)="okButtonClick.emit()" id="schamanClientConfirmationOkButton">
				{{ okButtonText }}
			</mva10-c-button>
		</div>
		<div>
			<mva10-c-button
				[appearance]="'alt1'"
				(mva10OnClick)="koButtonClick.emit()"
				id="schamanClientConfirmationKoButton"
			>
				{{ 'v10.myOrder.checkInstallationOverlay.internetConnectionConfirmationButtonKo' | translate }}
			</mva10-c-button>
		</div>
	</div>
</div>
