import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentSuccess, PaymentTicket } from '../../../models/payment';
import { greenTickIcon, stringOfTrue, Validation } from '../../../shared/constants/defines';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentSuccessInterface } from './payment-success-interface';

export class PaymentSuccessDefault implements PaymentSuccessInterface {
	constructor(public payNavSer: PaymentNavigationService, public translate: TranslateService, public router: Router) {}
	/**
	 * function to fill success overlay with it's proper
	 * wcs values and btn actions
	 */
	fillSuccessOverlay(): PaymentSuccess {
		const paymentSuccessObj: PaymentSuccess = new PaymentSuccess();
		this.translate.get(this.payNavSer.getWcsPath(PaymentPages.ok)).subscribe((data) => {
			// search for place holder
			paymentSuccessObj.title = data.subtitle;
			this.payNavSer.paymentJourneyParams.placeHolders.okScreeen.subtitle.forEach((value, index) => {
				paymentSuccessObj.title = paymentSuccessObj.title.replace(`{${index}}`, value);
			});
			paymentSuccessObj.desc = this.isEmpty(data.description) ? '' : data.description;
			this.payNavSer.paymentJourneyParams.placeHolders.okScreeen.desc.forEach((value, index) => {
				paymentSuccessObj.desc = paymentSuccessObj.desc.replace(`{${index}}`, value);
			});
			paymentSuccessObj.firstBtnText = this.payNavSer.paymentJourneyParams.firstButton
				? this.payNavSer.paymentJourneyParams.firstButton
				: data.button1;
			paymentSuccessObj.secondBtnText = this.payNavSer.paymentJourneyParams.secondButton
				? this.payNavSer.paymentJourneyParams.secondButton
				: data.button2;
			paymentSuccessObj.isWalletEligible = data.isWalletEligible.toString().toLowerCase() === stringOfTrue;
			paymentSuccessObj.isSuccessToggleON = data.isSuccessToggleON.toString().toLowerCase() === stringOfTrue;
			paymentSuccessObj.walletToggleTitle = data.walletToggleTitle;
		});
		paymentSuccessObj.iconUrl = this.payNavSer.paymentJourneyParams.icon
			? this.payNavSer.paymentJourneyParams.icon
			: greenTickIcon;
		paymentSuccessObj.firstBtnAction = this.firstBtnAction.bind(this);
		paymentSuccessObj.secondBtnAction = this.secondBtnAction.bind(this);
		return paymentSuccessObj;
	}
	/**
	 * the default implmentaion of the first btn in success payment screen
	 */
	firstBtnAction(): void {
		this.payNavSer.defaultOkClose();
	}
	/**
	 * the default implmentaion of the second btn in success payment screen
	 */
	secondBtnAction(): void {
		this.payNavSer.closePayment();
	}
	/**
	 * default omplmentaion here is for post Paid
	 */
	fillTicketOverlay(): PaymentTicket {
		const paymentTicket: PaymentTicket = new PaymentTicket();
		this.translate.get(this.payNavSer.getWcsPath(PaymentPages.ticket)).subscribe((data) => {
			paymentTicket.subtitle = data.subtitle;
			paymentTicket.firstBtnText = data.button1;
			paymentTicket.secondBtnText = data.button2;
			paymentTicket.toast = data.toast;
			paymentTicket.ticketNote = data.note ?? '';
			paymentTicket.ticketDetails.push({
				key: data.ecommerceCompanyText,
				value: data.ecommerceCompanyDes,
				spaceFromLeft: 90,
			});
			paymentTicket.ticketDetails.push({
				key: data.PaymentConcept,
				value: data.PaymentConceptDes,
				spaceFromLeft: 87,
			});
			paymentTicket.ticketDetails.push({
				key: data.paymentIdTitle,
				value: this.payNavSer.thirdPartyURLParams.transactionId,
				spaceFromLeft: 92,
			});
			paymentTicket.ticketDetails.push({
				key: data.dateAndTimeTitle,
				value: this.payNavSer.thirdPartyURLParams.transactionDate,
				spaceFromLeft: 107,
			});
			paymentTicket.ticketDetails.push({
				key: data.cardNumberTitle,
				value: this.payNavSer.thirdPartyURLParams?.cardMask?.slice(6),
				spaceFromLeft: 145,
			});
			paymentTicket.ticketDetails.push({
				key: data.rechargeAmountTitle,
				value: this.payNavSer.roundAmountToString(this.payNavSer.paymentJourneyParams?.amount) + '€',
				spaceFromLeft: 75,
			});
		});
		paymentTicket.secondBtnAction = this.secondBtnAction.bind(this);
		return paymentTicket;
	}

	/**
	 * action for x btn click on overlay
	 */
	closeBtnAction(): void {
		this.payNavSer.defaultOkClose();
	}
	isEmpty(data: string): boolean {
		const ValidationPattern: RegExp = new RegExp(Validation.emptyString);
		return ValidationPattern.test(data);
	}
}
