<div class="lottie-animation">
	<ng-lottie
		[options]="lottieConfig"
		(animationCreated)="handleAnimation($event)"
		[width]="width || 200"
		[height]="height || 150"
	>
	</ng-lottie>
	<div *ngIf="loadingText" class="loading-text-container">
		<p [innerHTML]="loadingText" [ngStyle]="{ width: textWidth ? textWidth + 'px' : 'auto' }"></p>
	</div>
</div>
