import { Observable } from 'rxjs';
import { DeepLinkingService } from '../../shared/services/deep-linking.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { config } from '../../../config/pages-config';

@Injectable()
export class DisableDeepLinksGuard implements CanActivate {
	constructor(private router: Router, private deepLinkingService: DeepLinkingService) {}
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (this.deepLinkingService.navigationId === 1) {
			if (this.deepLinkingService.routeDeepLink(state.url)) {
				return true;
			} else {
				this.router.navigate([config.dashboard.route]);
				return false;
			}
		}
		return true;
	}
	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		return this.canActivate(childRoute, state);
	}
}
