import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe that replaces text in a given string, using a regular expression or search string.
 * Usage in HTML: 'inputString' | replace: 'searchValue':'replaceValue'
 *
 * @param searchValue — A string or RegExp to search for.
 * @param replaceValue — A string containing the text to replace for every successful match of searchValue in this string.
 */
@Pipe({
	name: 'replace',
})
export class ReplacePipe implements PipeTransform {
	transform(inputString: string, searchValue: string | RegExp, replaceValue: string): string {
		if (!inputString) {
			return '';
		}
		if (searchValue === undefined || replaceValue === undefined) {
			return inputString;
		}
		return inputString.split(searchValue).join(replaceValue);
	}
}
