export enum PaymentJourneyDetail {
	thirdPartyIframe = 'pago con tarjeta',
	ok = 'ok',
	ko = 'ko',
	koInCorrectCC = 'datos tarjeta incorrectos',
	custom = '',
	ticket = 'ticket de pago',
	cancel = 'solicitar cancelacion',
	deptPartial = 'cantidad a pagar',
	dept = 'start',
	cancelDeleteCCButton = 'cancelar eliminar tarjeta',
	deleteCC = 'eliminar tarjeta',
	confirmeDeleteButton = 'confirmar eliminar tarjeta',
	pasarela = 'pasarela',
}
