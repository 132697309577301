import { config } from './pages-config';

export const modulesStartPoints: { [key: string]: string } = {
	myAccount: config.myAccount.OverviewScreen.route,
	topUp: config.TopUp.topUpHistory.route,
	inbox: config.Inbox.InboxListing.route,
	serviceSettings: config.serviceSettings.navigators.route,
	purchaseProducts: config.PurchasedProducts.route,
	myorders: config.myorders.route,
	/** here we will set the start point route in billing resolver
	 * because the billing module has 3 start points (billOverview, billDetails and noBills)
	 * and this will serve us to handle back button behaviour to navigate to dashboard */
	billing: '',
};
