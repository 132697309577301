export enum StepFlow {
	KO_SLID = 'KO SLID',
	KO_CLIENT_ROUTER = 'KO CLIENTE ROUTER',
	KO_CLIENT_TV = 'KO CLIENTE TV',
	KO_ROUTER = 'KO ROUTER',
	KO_TV = 'KO TV',
}

export enum MotiveSupport {
	KO_SLID_SMART = 'SLID no se recupera de SMART',
	FAILURE_SLID = 'Falla la recuperación del SLID',
	KO_CLIENT_ROUTER_TV = 'Cliente no confirma',
	KO_SCHAMAN_ROUTER_TV = 'KO Schaman ',
}

export enum TypeFlow {
	AUTOI_NEBA_PORTA = 'AUTOI NEBA porta',
	AUTOI_NEBA_PORTA_TV = 'AUTOI NEBA porta con TV',
}
