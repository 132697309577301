import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';

import { CustomerType } from '../../shared/enums/customerType.enum';
import { DeepLinkingService } from '../../shared/services/deep-linking.service';
import { config } from '../../../config/pages-config';
import { AuthenticateService } from '../services/authenticate.service';

@Injectable()
export class PreLoginGuard implements CanActivate {
	url: any;
	loginTabName: any;
	constructor(
		private authenticateService: AuthenticateService,
		private router: Router,
		private deepLinkService: DeepLinkingService,
		private route: ActivatedRoute
	) {}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		this.route.queryParams.subscribe((params) => {
			this.url = params['targetUrl'];
			this.loginTabName = params['tab'];
		});
		const response = this.authenticateService.validateAuthentication();

		if (response instanceof Observable) {
			response.subscribe(
				(res) => {
					if (res) {
						if (this.authenticateService.hasRole(CustomerType.Authorized.toLowerCase())) {
							this.router.navigate([config.login.companyChooser.route], {
								queryParams: {
									targetUrl: this.url,
								},
							});
						} else if (this.url && this.deepLinkService.isDeepLink) {
							this.router.navigateByUrl(this.url);
						} else {
							this.router.navigate([config.dashboard.name]);
						}
						return of(false);
					} else {
						return of(true);
					}
				},
				(error) => {
					// should go to login and show error
					return of(true);
				}
			);
		} else if (response && response.defaultSiteId) {
			//  othraized  user with user profile object returned
			if (this.authenticateService.hasRole(CustomerType.Authorized.toLowerCase())) {
				this.router.navigate([config.login.companyChooser.route], {
					queryParams: {
						targetUrl: this.url,
					},
				});
			} else if (this.url && this.deepLinkService.isDeepLink) {
				this.router.navigateByUrl(this.url);
			} else {
				this.router.navigate([config.dashboard.name]);
			}
			return of(false);
		} else if (!response) {
			// not othraized
			return of(true);
		}
	}
}
