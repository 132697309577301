import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SlidConfig, TipoInteraccionModel, PartyInteractionBody } from '../../../models/slidResponse.model';
import { MyOrdersService } from './my-orders.service';
import { EventMessage } from '../../../models/eventMessage.model';
import { UtilsService } from './utils.service';
import { InsertInteractionSLID } from '../../../models/InteractionSLID.model';
import { InteractionType } from '../../../enums/interaction-type.enum';
import { API_URLS } from '../../../constants/routes-config';
import { DatePipeFormat, oNTL3Model, slidConfigResponse } from '../../../../shared/constants/defines';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { HeadersDataOptions } from '../../../models/dxl-header-data-options.model';
import { BodySLIDs } from '../../../models/bodyslid.model';

@Injectable({
	providedIn: 'root',
})
export class SlidService {
	slidConfig: SlidConfig | null = null;
	$routerSlidSubject: Subject<EventMessage>;
	showCheckInstallation: boolean;

	constructor(
		private http: HttpClient,
		private myOrdersService: MyOrdersService,
		private utils: UtilsService,
		public translate: TranslateService
	) {}

	public headers(): HeadersDataOptions {
		const headers: HttpHeaders = new HttpHeaders()
			.set('X-VF-API-Process', 'GetPasswordUserConfig')
			.set('content-type', 'application/json')
			.set('Accept', 'application/json');
		const options: HeadersDataOptions = {
			headers: headers,
			withCredentials: true,
		};
		return options;
	}
	public getRouterSLID(idCliente: string = this.myOrdersService.MYORDERS_DATA.idCliente): Observable<SlidConfig> {
		const url: string = API_URLS.Slid.getPasswordUserConfig
			.replace('{idClient}', idCliente)
			.replace('{type}', oNTL3Model);
		const options: HeadersDataOptions = this.headers();
		return this.http.get<SlidConfig>(url, options).pipe(
			map((res) => {
				this.slidConfig = {
					userConfig: '',
					passUserConfig: '',
					passGpon: '',
				};
				res?.[0]?.characteristic?.forEach((item) => {
					if (item.name === slidConfigResponse.userConfig) {
						this.slidConfig.userConfig = item.value;
					} else if (item.name === slidConfigResponse.passUserConfig) {
						this.slidConfig.passUserConfig = item.value;
					} else if (item.name === slidConfigResponse.passGPON) {
						this.slidConfig.passGpon = item.value;
					}
				});
				return this.slidConfig;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	sendSlidToRouter(): Observable<EventMessage> {
		const data: string = window.btoa(JSON.stringify(this.slidConfig)).split('').reverse().join('');
		this.utils.postMessageToParent(
			JSON.stringify({
				action: 'router',
				message: 'router-slid',
				data: data,
			})
		);
		this.$routerSlidSubject = new Subject<EventMessage>();
		return this.$routerSlidSubject.asObservable();
	}

	postInsertInteractionSLID(
		idCliente: string,
		tipoInteraccion: InteractionType,
		idOrden?: string
	): Observable<InsertInteractionSLID> {
		const interaccion: TipoInteraccionModel = this.translate.instant(
			`v10.myOrder.slid.tiposInteracciones.${tipoInteraccion.toLowerCase()}`
		);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options: { [key: string]: HttpHeaders } = {
			headers: headers,
		};
		const url: string = API_URLS.Slid.getInteractionSLID;
		const body: PartyInteractionBody = {
			description: interaccion.comentario,
			status: interaccion.accion,
			channel: [
				{
					id: interaccion.tipo,
				},
			],
			relatedParty: [
				{
					id: interaccion.grupoTrabajo,
					role: 'workgroup',
				},
			],
			interactionItem: [
				{
					id: interaccion.codigoApertura,
					resolution: interaccion.resultado,
					item: {
						id: idCliente,
						role: 'customer',
					},
					note: [
						{
							author: 'Razon1',
							text: interaccion.razon1,
						},
						{
							author: 'Razon2',
							text: interaccion.razon2,
						},
						{
							author: 'Razon3',
							text: idOrden || interaccion.razon3,
						},
					],
				},
			],
		};
		return this.http.post(url, body, options).pipe(
			map((res: InsertInteractionSLID) => {
				return res;
			})
		);
	}

	getInteraction(idCliente: string, tipoInteraccion: InteractionType): Observable<BodySLIDs[]> {
		const interaccion: TipoInteraccionModel = this.translate.instant(
			`v10.myOrder.slid.tiposInteracciones.${tipoInteraccion.toLowerCase()}`
		);
		const gteDate: Date = new Date();
		gteDate.setDate(gteDate.getDate() - 2);
		const gteDateString: string = moment(gteDate).format(DatePipeFormat.dateHyphens);
		const urlBase: string = `${API_URLS.Slid.getInteractionSLID}?interactionItem.item[?(@.role%3D%3Dcustomer)].id=${idCliente}`;
		const urlGteDateString: string = `&interactionItem.itemDate.gte=${gteDateString}`;
		const urlRazon1: string = `&interactionItem.note[0].text=${interaccion.razon1}`;
		const urlRazon2: string = `&interactionItem.note[1].text=${interaccion.razon2}`;
		const urlRazon3: string = `&interactionItem.note[2].text=${interaccion.razon3}`;
		const url: string = `${urlBase}${urlGteDateString}${urlRazon1}${urlRazon2}${urlRazon3}`;

		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options: { [key: string]: HttpHeaders } = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(map((res: BodySLIDs[]) => res));
	}

	getInteractionSLID(idCliente: string = this.myOrdersService.MYORDERS_DATA.idCliente): Observable<BodySLIDs[]> {
		return this.getInteraction(idCliente, InteractionType.NEBA);
	}
}
