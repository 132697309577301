import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TicketType } from '../../../../enums/ticket-type.enum';
import { TicketsService } from '../../services/tickets.service';

@Component({
	selector: 'sp-card-help',
	templateUrl: './sp-card-help.component.html',
	styleUrls: ['./sp-card-help.component.scss'],
})
export class SpCardHelpComponent {
	@Input() withBorder: boolean;
	@Input() image: string;
	@Input() imageDsl: string;
	@Input() imageSize: number = 60;
	@Input() title: string;
	@Input() subtitle: string;
	@Input() buttonText: string;
	@Input() loading: boolean;
	@Output() clickHelp: EventEmitter<void> = new EventEmitter<void>();
	@Output() clickDetail: EventEmitter<void> = new EventEmitter<void>();

	TicketTypeEnum: typeof TicketType = TicketType;

	constructor(readonly ticketsService: TicketsService) {}

	onButtonClick(): void {
		this.clickHelp.emit();
	}

	onShowTicketDetailClick(): void {
		this.clickDetail.emit();
	}
}
