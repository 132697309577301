<sp-modal-full #fullModal> </sp-modal-full>

<div id="overlay" #overlay>
	<div id="stepOne">
		<div class="close-icon">
			<span class="icon icon-close modal-full-close close" type="button" aria-label="Close" (click)="dismiss()"> </span
			>`
		</div>
		<div class="clearfix"></div>
		<div id="stepOneTitle">
			<p class="head-title" [innerHTML]="'login.title' | translate"></p>
		</div>

		<div style="overflow: auto">
			<form
				style="overflow: auto"
				class="login-form-upgrade"
				[formGroup]="form"
				novalidate="true"
				(ngSubmit)="submit()"
			>
				<sp-text
					id="StepOneComp_txt_username"
					[infoText]="loginNameInfo"
					[upgrade]="isupgrade"
					formControlName="username"
					max="40"
					showInfoIcon="true"
					label="{{ 'login.loginForm.fieldsList.username.label' | translate }}"
					isRequired="true"
					placeholder="{{ 'login.loginForm.fieldsList.username.placeHolder' | translate }}"
				>
				</sp-text>

				<sp-text
					id="StepOneComp_txt_password"
					formControlName="password"
					[upgrade]="isupgrade"
					max="18"
					isPassword="true"
					showPasswordIcon="true"
					label="{{ 'login.loginForm.fieldsList.password.label' | translate }}"
					isRequired="true"
					placeholder="{{ 'login.loginForm.fieldsList.password.placeHolder' | translate }}"
				>
				</sp-text>

				<div class="form-group col-xs-12">
					<button
						id="StepOneComp_btn_submit"
						type="submit"
						class="login-btn login-btn-enabled"
						[disabled]="!form.valid"
					>
						{{ 'login.loginForm.buttonsList.loginButton.text' | translate }}
					</button>
				</div>
				<div class="form-group col-xs-12">
					<button id="StepOneComp_btn_forgetPassword" class="cancel-btn" (click)="forgetPassword()">
						{{ 'login.loginForm.buttonsList.recoverPasswordButton.text' | translate }}
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
