export enum TryAndBuy {
	unitEmail = 'mail',
	unitNumber = 'number',
	unitText = 'text',
	unitDocument = 'document',
	validEmail = 'email',
	validNumber = 'MobileNumber',
	validText = 'AlphaValues',
	validDocument = 'Dni',
	contratable = 'contratable',
	activo = 'activo',
	noDisponible = 'noDisponible',
	try = 'try',
	buy = 'buy',
	confirmable = 'confirmable',
	validationType = 'validationType',
	id = 'id',
	maxLenght = 'maxLenght',
	colorTurquoise = 'turquoise',
	colorOrange = 'fresh-orange',
	pruebaTerminada = 'prueba_terminada',
	pruebaActiva = 'prueba_activa',
	formulario = 'formulario',
	estadoInicial = 'estado_inicial',
	inicial = 'inicial',
	activa = 'prueba activa',
}

export enum TryAndBuyOverlayPackage {
	siteId = 'siteId',
	codigoServicio = '\n Código Servicio: ',
	modalidad = '\n  Modalidad: ',
	campos = ' \n Campos petición: \n ',
	fields = 'fields',
	first = 1,
	second = 2,
	third = 3,
	forth = 4,
	fifth = 5,
	maxLenghtPhone = 9,
}

export enum UsersAccount {
	usersAccount = 'usersAccount',
}
