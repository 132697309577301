import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'formatNumberPrice',
})
export class FormatNumberPricePipe implements PipeTransform {
	transform(value: string): string {
		let isNegative = false;
		if (value) {
			if (value.indexOf('-') !== -1) {
				value = value.replace('-', '');
				isNegative = true;
			}

			const output: string = value.replace('.', ',').replace(',00', '');

			return (isNegative ? '-' : '') + output;
		} else {
			return '';
		}
	}
}
