import { map } from 'rxjs/operators';
import { SubscriptionService } from './../core/services/subscription.service';
import { TopUpModel } from './../models/top-up.model';
import { Injectable } from '@angular/core';
import { TopUpDataService } from './top-up-data.service';
import * as constants from '../shared/constants/defines';
import * as JsonQuery from 'jsonpath/jsonpath';
import { IconClassNames } from '../shared/enums/iconClassNames.enum';
import { TopUPType } from '../shared/enums/top-up-type.enum';
import { UtilitiesService } from '../shared/utils/utilities.service';
import { ServiceType } from '../shared/enums/serviceType.enum';
import { Slide } from '../shared/components/sp-swiper/slide.model';
import {
	giftIcon,
	greenSmallTickIcon,
	pegaOfferFields,
	redSmallTickIcon,
	redTickIcon,
	SSNDB,
	TopupPrepaidStages,
	SuccessScreenTaggingLiterals,
	pegaOffersIDs,
	redGift,
} from '../shared/constants/defines';
import { TimePeriodModel } from '../models/time-period.model';
import { TopUpCurrentSpendingDTOModel } from '../models/top-up-current-spending.dto.model';
import { ConfigurationService } from '../core/services/configuration.service';
import { ServiceModel } from '../models/service.model';
import { Subject } from 'rxjs';
import { tagging } from '../../config/tagging-config';
import { TaggingHelperService } from '../core/services/tagging.helper.service';
import { PaymentCardModel } from '../models/payment-card.model';
import { LanguageEnum } from '../shared/enums/language.enum';
import { IDynamicTray } from '../models/dynamic-tray.model';
import { PaymentJourneyIds } from '../shared/enums/payment-journey-ids.enum';
import { PaymentJourneyName } from '../shared/enums/payment-journey-name.enum';
import { PaymentJourneySubCategory } from '../shared/enums/payment-journey-sub-category.enum';
import { PaymentJourneyType } from '../shared/enums/payment-journey-type.enum';
import { PaymentJourny } from '../shared/enums/payment-journy.enum';
import { PaymentJourneyEvents } from '../shared/enums/payment-journey-events.enum';
import { PaymentJourneyProducts } from '../shared/enums/payment-journey-products.enum';
import { PaymentLanguages } from '../shared/enums/payment-languages.enum';
import {
	PaymentJourneyParams,
	PaymentTaggingJourney,
	PaymentTaggingObject,
	PaymentTaggingPageName,
} from '../models/payment';
import { StorageService } from '../core/services/storage.service';
import { PaymentWalletService } from '../shared/services/payment-wallet.service';
import { PaymentNavigationService } from '../payment/services/payment-navigation.service';
import { CrossVariables } from '../models/tagging.model';
import { TariffService } from '../shared/services/tariff.service';
import { TranslateService } from '@ngx-translate/core';
import { TariffStatus } from '../shared/enums/tariffStatus.enum';
import { TariffListResponse } from '../models/tariff-list-response.model';
import { Dashboard } from '../models/dashboard.model';
import { PaymentJourneyEnvironment } from '../shared/enums/payment-journey-environment.enum';
import { PlatinumPegaTypeModel, SuccessScreenDataModel } from '../models/success-screen-data.model';
import { PaymentJourneyProcess } from '../shared/enums/payment-journey-process.enum';

@Injectable()
export class TopUpService {
	public topUps: Array<TopUpModel>;
	public topUpCurrentSpendings: Array<TopUpCurrentSpendingDTOModel>;
	public topUpOffset: number;
	public topUpCurrentSpendingsOffset: number;
	public selectedServiceId: string;
	public traySelectedService: ServiceModel;
	public trayServiceChangeSubject = new Subject<ServiceModel>();
	public newPciSelectedService: ServiceModel;
	public manual: string;
	public automatic: string;
	/**to check if it will back from permission screen */
	isReturnFromPermissionScreen: boolean = false;
	showCardSelectionSection = false;
	slides: Array<any> = [];
	index = 0;
	showIframeError: boolean = false;
	backToFirstStep = false;
	public topupNavigationSubject = new Subject<{ page: TopupPrepaidStages; isFirstTimeOpen?: boolean }>();
	public returnDataFromContactList = new Subject();
	curruentSelectedAmountSelectorValue: number = 0;
	msisdn: string;
	showPlus: boolean;
	isMobileView = true;
	isAnonymousProcess: boolean;
	topupLanguage = LanguageEnum.spanish;
	public transactionCanceledSubject = new Subject<any>();
	transactionId: string;
	cardMask = '';
	cardToken = '';
	transactionDate = '';
	cardExpiry = '';
	selectedCreditCard: PaymentCardModel;
	/** become true once selected card deleted */
	selectedCardDeleted: boolean = false;
	useDynamicTray = false;
	dynamicTrayData: IDynamicTray = {
		basicTrayData: {
			isCenterTitle: true,
			isFullScreen: true,
			isScrollableTray: false,
		},
		preventTrayInnerScrolling: true,
		isBoldTitle: true,
	};
	returnFromWalletScreen: boolean = false;
	isOpenedFromLoginTabs = false;
	/**selected item in service selector component*/
	selectedItemIndex: number;
	/** to check if redirect to public topup from portal mobile*/
	isRedirected = false;
	/**show second button flag */
	showSecondCTA = false;

	/**check if contact list api supported or not supported */
	contactListSupported: boolean = false;
	selectedPhoneNumber: string;
	returnFromSuccessSaveCreditCard: boolean = false;
	promoCode: string;
	isPega: boolean;
	isPegaPlatinum: boolean;
	isSuccessPegaOverlayOpened: boolean;
	pegaOfferKeyValue: Record<string, string> | Map<string, string> = new Map([
		[pegaOfferFields.extraBalancePCT, ''],
		[pegaOfferFields.pricePlanRate, ''],
	]);
	currentSelectedAmount: number;
	serviceTariff: TariffListResponse;
	serviceBundle: Dashboard[];
	currentBalance: string = '';
	paymetTicketIcon: string;
	isForOthersJourney: boolean;
	paymentJourneyParams: PaymentJourneyParams;
	currentAmount: string;
	activeBenefit: boolean;
	pegaPlatinumOfferCode: string = '';
	constructor(
		private topUpDataService: TopUpDataService,
		private subscriptionService: SubscriptionService,
		private utilitiesService: UtilitiesService,
		private subscriptionData: SubscriptionService,
		private configurationsService: ConfigurationService,
		private storage: StorageService,
		private paymentWalletService: PaymentWalletService,
		public paymentNavigationService: PaymentNavigationService,
		private tariffService: TariffService,
		private translateService: TranslateService,
		private taggingHelperService: TaggingHelperService
	) {}
	mapTopUpData(item: any) {
		const topUP = new TopUpModel();
		if (item) {
			topUP.topupType = JsonQuery.value(item, constants.JSON_PATHS.TopUpHistory.topupType) || null;
			topUP.topupDate = JsonQuery.value(item, constants.JSON_PATHS.TopUpHistory.topupDate) || null;
			topUP.topupAmount = JsonQuery.value(item, constants.JSON_PATHS.TopUpHistory.topupAmount) || 0;
		}
		return topUP;
	}
	mapTopUpCurrentSpendings(item: any) {
		const topUP = new TopUpCurrentSpendingDTOModel();
		if (item) {
			topUP.type = JsonQuery.value(item, constants.JSON_PATHS.TopUpCurrentSpendings.type) || null;
			topUP.description = JsonQuery.value(item, constants.JSON_PATHS.TopUpCurrentSpendings.description) || null;
			topUP.amount = JsonQuery.value(item, constants.JSON_PATHS.TopUpCurrentSpendings.amount) || 0;
			topUP.quantity = JsonQuery.value(item, constants.JSON_PATHS.TopUpCurrentSpendings.quantity) || 0;
			topUP.destination = JsonQuery.value(item, constants.JSON_PATHS.TopUpCurrentSpendings.destination) || null;
			topUP.unit = JsonQuery.value(item, constants.JSON_PATHS.TopUpCurrentSpendings.unit) || null;
			topUP.timePeriod = new TimePeriodModel({
				startDateTime:
					JsonQuery.value(item, constants.JSON_PATHS.TopUpCurrentSpendings.timePeriod.startDateTime) || null,
				endDateTime: JsonQuery.value(item, constants.JSON_PATHS.TopUpCurrentSpendings.timePeriod.endDateTime) || null,
				duration: JsonQuery.value(item, constants.JSON_PATHS.TopUpCurrentSpendings.timePeriod.duration) || 0,
			});
		}
		return topUP;
	}
	getTopUpHistory(subscriptionId, startDate, endDate, loadMore?) {
		return this.topUpDataService
			.getTopUpHistory(this.subscriptionService.customerData.currentService.siteId, startDate, endDate, subscriptionId)
			.pipe(
				map((res) => {
					const items: any = JsonQuery.value(res, constants.JSON_PATHS.TopUpHistory.items);
					if (!loadMore) {
						this.topUps = new Array<any>();
					}
					items.map((item) => {
						this.topUps.push(this.mapTopUpData(item));
					});
					this.topUpOffset = JsonQuery.value(res, constants.JSON_PATHS.TopUpHistory.offset);
					return res;
				})
			);
	}

	getPciPendingServices(siteId) {
		const result = [];
		return this.topUpDataService.getTopUpServicesPendingTransactions(siteId).pipe(
			map((res) => {
				const items = JsonQuery.value(res, constants.JSON_PATHS.TopUpHistory.items);
				if (!items || items.length === 0) {
					return result;
				}
				items.forEach((transaction) => {
					const hasPendingOrders = JsonQuery.value(transaction, constants.JSON_PATHS.TopUpHistory.hasPendingOrders);
					if (hasPendingOrders) {
						result.push(JsonQuery.value(transaction, constants.JSON_PATHS.TopUpHistory.serviceId));
					}
				});
				return result;
			})
		);
	}
	getTopUpCurrentSpendings(subscriptionId, startDate, endDate, type) {
		this.topUpCurrentSpendings = new Array<TopUpCurrentSpendingDTOModel>();
		return this.topUpDataService
			.getTopUpCurrentSpendings(
				this.subscriptionService.customerData.currentService.siteId,
				subscriptionId,
				startDate,
				endDate,
				type
			)
			.pipe(
				map((res) => {
					const items: any = JsonQuery.value(res, constants.JSON_PATHS.TopUpCurrentSpendings.items);
					items.map((item) => {
						this.topUpCurrentSpendings.push(this.mapTopUpCurrentSpendings(item));
					});
					this.topUpCurrentSpendingsOffset = JsonQuery.value(res, constants.JSON_PATHS.TopUpCurrentSpendings.offset);
					return res;
				})
			);
	}
	getTopupIcon(type: string) {
		let icon;
		switch (type.toLowerCase()) {
			case TopUPType.automatic.toLowerCase():
				icon = IconClassNames.icon_top_up_download;
				break;
			case TopUPType.manual.toLowerCase():
				icon = IconClassNames.icon_top_up_1;
				break;
			case TopUPType.abono.toLowerCase():
				icon = IconClassNames.icon_top_up_2;
				break;
			default:
				break;
		}
		return icon;
	}
	getDateDiffrence(numberOfMonth, notISO?: boolean) {
		let startDate;
		startDate = new Date().setMonth(new Date().getMonth() - numberOfMonth);
		startDate = new Date(startDate);
		if (!notISO) {
			startDate = this.utilitiesService.convertDateTOISO(startDate);
		}
		return startDate;
	}
	drawSwiper(id, selectedServiceId?) {
		this.index = 0;
		this.subscriptionData.customerData.services.map((el) => {
			if (el.siteId === id) {
				if (
					el.type.toLowerCase() === ServiceType.MbbPrepaid.toLowerCase() ||
					el.type.toLowerCase() === ServiceType.Prepaid.toLowerCase()
				) {
					this.logic(el, selectedServiceId);
					const slide = this.slides.filter((el) => {
						return selectedServiceId
							? el.id === selectedServiceId
							: el.id === this.subscriptionData.customerData.currentService.id;
					});
					if (!slide) {
						this.slides[0].checked = true;
						this.selectedServiceId = this.slides[0].id;
					}
				}
			}
		});
	}
	logic(el, selectedServiceId?) {
		const slide = new Slide();
		slide.icon = this.utilitiesService.setServiceIcon(el.type);
		slide.index = this.index;
		this.index++;
		slide.id = el.id;
		slide.active = true;
		slide.type = el.type;
		if (
			(!selectedServiceId && slide.id === this.subscriptionData.customerData.currentService.id) ||
			(selectedServiceId && slide.id === selectedServiceId)
		) {
			slide.checked = true;
			slide.selected = SSNDB.selected;
			this.selectedServiceId = slide.id;
		}
		slide.text = el.name.toString();
		this.slides.push(slide);
	}
	checkNewPciEligability(
		service = this.subscriptionService.customerData.currentService,
		pciFlag = this.configurationsService.configuration.topupNewPCI,
		pciPattern = this.configurationsService.configuration.topupNewPCIMsisdnPattern
	) {
		if (!pciFlag) {
			return false;
		}

		if (
			service.type.toLowerCase() === ServiceType.Prepaid.toLowerCase() ||
			service.type.toLowerCase() === ServiceType.MbbPrepaid.toLowerCase()
		) {
			if (!pciPattern) {
				return true;
			}
			return this.checkPattern(service.id, pciPattern);
		} else {
			return this.checkNewPciEligabilityForNonPrepaid(pciPattern);
		}
	}
	checkNewPciEligabilityForNonPrepaid(pattern, siteServiceList = this.subscriptionService.customerData.services) {
		const prePaidServices = siteServiceList.filter((service) => {
			return (
				service.type &&
				(service.type.toLowerCase() === ServiceType.Prepaid.toLowerCase() ||
					service.type.toLowerCase() === ServiceType.MbbPrepaid.toLowerCase())
			);
		});
		if (prePaidServices.length === 0) {
			return true;
		}
		if (prePaidServices.length > 0 && !pattern) {
			return true;
		}
		let patternMatched = false;
		prePaidServices.forEach((service) => {
			if (this.checkPattern(service.id, pattern)) {
				patternMatched = true;
				return;
			}
		});
		return patternMatched;
	}
	checkPattern(msisdn, pattern) {
		const patternList = pattern.split(',');
		let patternMatched = false;
		patternList.forEach((pattern) => {
			const trimmedPattern = pattern.trim();
			const validationPartOfMsisdn = msisdn.substring(msisdn.length - trimmedPattern.length);
			if (validationPartOfMsisdn === trimmedPattern) {
				patternMatched = true;
				return;
			}
		});
		return patternMatched;
	}

	getPrepaidServiceUnderSite(siteId, selectedService?) {
		const prepaidServices = this.subscriptionData.customerData.services.filter((service) => {
			return (
				service.type &&
				service.siteId &&
				service.siteId === siteId &&
				(service.type.toLowerCase() === ServiceType.MbbPrepaid.toLowerCase() ||
					service.type.toLowerCase() === ServiceType.Prepaid.toLowerCase())
			);
		});
		return this.orderPrepaidService(prepaidServices, selectedService);
	}

	orderPrepaidService(services: ServiceModel[], selectedService?: ServiceModel) {
		if (selectedService) {
			return this.setSelectedServiceAtFirstIndex(services, selectedService);
		}
		if (
			this.subscriptionService.customerData.currentService.type.toLowerCase() === ServiceType.Prepaid.toLowerCase() ||
			this.subscriptionService.customerData.currentService.type.toLowerCase() === ServiceType.MbbPrepaid.toLowerCase()
		) {
			const isNewPciEligible = this.checkNewPciEligability();
			if (isNewPciEligible) {
				return this.setSelectedServiceAtFirstIndex(services, this.subscriptionService.customerData.currentService);
			}
		}
		const firstEligibleService = services.find((service) => this.checkNewPciEligability(service));
		return this.setSelectedServiceAtFirstIndex(services, firstEligibleService);
	}

	private setSelectedServiceAtFirstIndex(services: ServiceModel[], selectedService: ServiceModel) {
		const servicesArray = services.filter((service) => service.id !== selectedService.id);
		servicesArray.splice(0, 0, selectedService);
		servicesArray.forEach((service) => {
			service.hasPendingPciTransactions = false;
		});
		return servicesArray;
	}

	setTaggingJournySubCategoryValue() {
		if (this.showPlus) {
			return tagging.staticVariables.pciPrepaidJourniesSubCategory.other;
		}
		if (this.isAnonymousProcess) {
			return tagging.staticVariables.pciPrepaidJourniesSubCategory.public;
		}
		return tagging.staticVariables.pciPrepaidJourniesSubCategory.prepaid;
	}

	setPciTaggingJourneyType() {
		return this.selectedCreditCard
			? tagging.staticVariables.pciPrepaidJournyTypes.withSavedCreditCard
			: tagging.staticVariables.pciPrepaidJournyTypes.withoutSavedCreditCard;
	}
	/**
	 * Open iframe and set payment joureny params object needed to open it
	 * @param amount of the money user need to pay
	 * @param WCSJournyKey of the current journey
	 */
	openPaymentModule(
		amount: string,
		WCSJournyKey: PaymentJourny,
		currentAmount?: string,
		promoCode?: string,
		saveFirstCreditCardWithoutTopup?: boolean
	): void {
		const paymentJourneyParams: PaymentJourneyParams = new PaymentJourneyParams();
		// payer data
		paymentJourneyParams.payer.documentId = this.storage.userProfile.document.id;
		paymentJourneyParams.payer.siteId = this.subscriptionService.customerData.customerAccountsId;
		paymentJourneyParams.payer.serviceId = this.msisdn
			? this.subscriptionService.customerData.currentService.id
			: this.newPciSelectedService
			? this.newPciSelectedService.id
			: this.subscriptionService.customerData.currentService.id;
		paymentJourneyParams.payer.documentType = this.storage.userProfile.document.type;
		// buyer data
		paymentJourneyParams.buyer.documentId = this.msisdn ? '' : this.storage.userProfile.document.id;
		paymentJourneyParams.buyer.siteId = this.msisdn ? '' : this.subscriptionService.customerData.customerAccountsId;
		paymentJourneyParams.buyer.serviceId = this.msisdn
			? this.msisdn
			: this.newPciSelectedService
			? this.newPciSelectedService.id
			: this.subscriptionService.customerData.currentService.id;

		paymentJourneyParams.amount = amount;
		paymentJourneyParams.WCSJournyKey = WCSJournyKey;
		paymentJourneyParams.isAnonymous = false;
		// params related to iframe screen
		paymentJourneyParams.concepto = this.newPciSelectedService
			? this.newPciSelectedService.id
			: this.msisdn
			? this.msisdn
			: this.subscriptionService.customerData.currentService.id;
		paymentJourneyParams.journeyId = saveFirstCreditCardWithoutTopup
			? PaymentJourneyIds.prepaidNewCreditCard
			: PaymentJourneyIds.prePaidWithOutCvv;
		// crediet card data
		const selectedCard: PaymentCardModel = this.paymentWalletService.selectedCard;
		if (selectedCard) {
			paymentJourneyParams.token = selectedCard.token;
			paymentJourneyParams.expiryDate = selectedCard.expiryDate;
			paymentJourneyParams.cardMask = selectedCard.cardMask;
			paymentJourneyParams.journeyId = PaymentJourneyIds.prePaidWithCvv;
		}
		// for handling journey

		paymentJourneyParams.isHandleWaiting = true;
		paymentJourneyParams.language = PaymentLanguages.es;
		paymentJourneyParams.promoCode = promoCode;
		this.paymentJourneyParams = paymentJourneyParams;
		this.currentAmount = currentAmount;
		if (this.isForOthersJourney) {
			this.updateDescInPREPAIDSuccessScreenForOthers(paymentJourneyParams, currentAmount);
		} else {
			this.updateDescInPREPAIDSuccessScreen(paymentJourneyParams);
		}
		paymentJourneyParams.paymentTaggingConfig = this.getPaymentModuleTagging();
		this.paymentNavigationService.openPaymentThirdParty(paymentJourneyParams);
	}

	updateSuccessScreenIfLowFeeAndNotPega(
		paymentJourneyParams: PaymentJourneyParams,
		data: SuccessScreenDataModel
	): void {
		paymentJourneyParams.firstButton = data.button1.replace('{0}', data.own.button1Title);
		paymentJourneyParams.secondButton = data.button2.replace('{0}', data.own.button2Title);
		paymentJourneyParams.placeHolders.okScreeen.desc = [data.own.description];
		if (paymentJourneyParams.promoCode) {
			paymentJourneyParams.icon = giftIcon;
			paymentJourneyParams.placeHolders.okScreeen.subtitle = [
				data.own.promotionalSubtitle.replace('{0}', data.promotionValue),
			];
		} else {
			paymentJourneyParams.icon = redTickIcon;
			paymentJourneyParams.placeHolders.okScreeen.subtitle = [data.own.normalSubtitle];
		}
	}

	updateSuccessScreenIfLowFeeAndPega(paymentJourneyParams: PaymentJourneyParams, data: SuccessScreenDataModel): void {
		paymentJourneyParams.icon = giftIcon;
		paymentJourneyParams.firstButton = data.button1.replace('{0}', data.own.button1Title);
		paymentJourneyParams.secondButton = data.button2.replace('{0}', data.own.button2Title);
		paymentJourneyParams.placeHolders.okScreeen.desc = [data.own.description];
		this.isSuccessPegaOverlayOpened = true;
		if (paymentJourneyParams.promoCode) {
			paymentJourneyParams.placeHolders.okScreeen.subtitle = [
				data.own.promotionWithPEGASubtitle
					.replace(
						'{1}',
						((this.currentSelectedAmount * this.pegaOfferKeyValue[pegaOfferFields.extraBalancePCT]) / 100).toString()
					)
					.replace('{0}', data.promotionValue),
			];
		} else {
			paymentJourneyParams.placeHolders.okScreeen.subtitle = [
				data.own.PEGASubtitle.replace(
					'{0}',
					((this.currentSelectedAmount * this.pegaOfferKeyValue[pegaOfferFields.extraBalancePCT]) / 100).toString()
				),
			];
		}
	}

	updateSuccessScreenIfHighFeeAndNotPega(
		paymentJourneyParams: PaymentJourneyParams,
		data: SuccessScreenDataModel
	): void {
		paymentJourneyParams.firstButton = data.button1.replace('{0}', data.defaultButton1Title);
		paymentJourneyParams.secondButton = data.button2.replace('{0}', data.defaultButton2Title);
		paymentJourneyParams.placeHolders.okScreeen.desc = [data.own.description];
		if (paymentJourneyParams.promoCode) {
			paymentJourneyParams.icon = giftIcon;
			paymentJourneyParams.placeHolders.okScreeen.subtitle = [
				data.own.promotionalSubtitle.replace('{0}', data.promotionValue),
			];
		} else {
			paymentJourneyParams.icon = redTickIcon;
			paymentJourneyParams.placeHolders.okScreeen.subtitle = [data.own.normalSubtitle];
		}
	}

	updateSuccessScreenIfHighFeePega(paymentJourneyParams: PaymentJourneyParams, data: SuccessScreenDataModel): void {
		paymentJourneyParams.icon = giftIcon;
		paymentJourneyParams.firstButton = data.button1.replace('{0}', data.defaultButton1Title);
		paymentJourneyParams.secondButton = data.button2.replace('{0}', data.defaultButton2Title);
		paymentJourneyParams.placeHolders.okScreeen.desc = [data.own.description];
		this.isSuccessPegaOverlayOpened = true;
		if (paymentJourneyParams.promoCode) {
			paymentJourneyParams.placeHolders.okScreeen.subtitle = [
				data.own.promotionWithPEGASubtitle
					.replace(
						'{1}',
						((this.currentSelectedAmount * this.pegaOfferKeyValue[pegaOfferFields.extraBalancePCT]) / 100).toString()
					)
					.replace('{0}', data.promotionValue),
			];
		} else {
			paymentJourneyParams.placeHolders.okScreeen.subtitle = [
				data.own.PEGASubtitle.replace(
					'{0}',
					((this.currentSelectedAmount * this.pegaOfferKeyValue[pegaOfferFields.extraBalancePCT]) / 100).toString()
				),
			];
		}
	}

	updateSuccessScreenWithPlatinumPegaAlmost(
		paymentJourneyParams: PaymentJourneyParams,
		data: SuccessScreenDataModel,
		withPega: PlatinumPegaTypeModel
	): void {
		paymentJourneyParams.firstButton = data.button1.replace('{0}', data.defaultButton1Title);
		paymentJourneyParams.secondButton = data.button2.replace('{0}', data.defaultButton2Title);
		paymentJourneyParams.isSuccessTarrifFeeOverlayOpened = false;
		this.isSuccessPegaOverlayOpened = true;
		if (paymentJourneyParams.promoCode) {
			paymentJourneyParams.icon = redGift;
			paymentJourneyParams.placeHolders.okScreeen.desc = [withPega.pegaWithPromotion.description];
			paymentJourneyParams.placeHolders.okScreeen.subtitle = [
				withPega.pegaWithPromotion.subtitle.replace('{PROMO_VALUE}', data.promotionValue),
			];
		} else {
			paymentJourneyParams.icon = redTickIcon;
			paymentJourneyParams.placeHolders.okScreeen.desc = [withPega.pega.description];
			paymentJourneyParams.placeHolders.okScreeen.subtitle = [withPega.pega.subtitle];
		}
	}

	updateSuccessScreenWithPlatinumPega(paymentJourneyParams: PaymentJourneyParams, data: SuccessScreenDataModel): void {
		if (
			this.isPegaPlatinum &&
			this.currentSelectedAmount >= +this.pegaOfferKeyValue[pegaOfferFields.platinumMinRecharge]
		) {
			if (this.pegaPlatinumOfferCode === pegaOffersIDs.ALMOUSTIN) {
				this.updateSuccessScreenWithPlatinumPegaAlmost(
					paymentJourneyParams,
					data,
					data.own.withPega.LOY_HVC_INF_ALMOST_IN_APP
				);
			} else if (this.pegaPlatinumOfferCode === pegaOffersIDs.ALMOUSTOUT) {
				this.updateSuccessScreenWithPlatinumPegaAlmost(
					paymentJourneyParams,
					data,
					data.own.withPega.LOY_HVC_INF_ALMOST_OUT_APP
				);
			}
		}
	}

	updateDescInPREPAIDSuccessScreen(paymentJourneyParams: PaymentJourneyParams): void {
		this.translateService.get('v10.payment.messagesList.prepaidTopup_v3.es.paymentSuccessful').subscribe((data) => {
			const isActiveBenfit: boolean =
				this.tariffService.Tariff &&
				this.tariffService.Tariff.benefitStatus?.toLowerCase() === TariffStatus.Active.toLowerCase();
			this.paymetTicketIcon = redSmallTickIcon;
			this.promoCode = paymentJourneyParams.promoCode;
			// replace , with . to avoid NAN run time error
			if (isActiveBenfit && this.currentSelectedAmount >= +this.tariffService.Tariff.Fee) {
				paymentJourneyParams.isSuccessTarrifFeeOverlayOpened = true;
				!this.isPega
					? this.updateSuccessScreenIfLowFeeAndNotPega(paymentJourneyParams, data)
					: this.updateSuccessScreenIfLowFeeAndPega(paymentJourneyParams, data);
			} else if (
				!isActiveBenfit &&
				this.currentSelectedAmount >= +this.tariffService.Tariff.Fee &&
				!this.isPega &&
				!paymentJourneyParams.promoCode
			) {
				paymentJourneyParams.icon = redTickIcon;
				paymentJourneyParams.firstButton = data.button1?.replace('{0}', data.defaultButton1Title);
				paymentJourneyParams.secondButton = data.button2?.replace('{0}', data.defaultButton2Title);
				paymentJourneyParams.placeHolders.okScreeen.desc = [data.reviewingTariffDescription];
				paymentJourneyParams.placeHolders.okScreeen.subtitle = [data.own.normalSubtitle];
			} else {
				!this.isPega
					? this.updateSuccessScreenIfHighFeeAndNotPega(paymentJourneyParams, data)
					: this.updateSuccessScreenIfHighFeePega(paymentJourneyParams, data);
			}
			this.updateSuccessScreenWithPlatinumPega(paymentJourneyParams, data);
		});
	}

	updateDescInPREPAIDSuccessScreenForOthers(paymentJourneyParams: PaymentJourneyParams, currentAmount): void {
		this.translateService.get('v10.payment.messagesList.prepaidTopup_v3.es.paymentSuccessful').subscribe((data) => {
			const isActiveBenfit: boolean =
				this.tariffService.Tariff &&
				this.tariffService.Tariff.benefitStatus?.toLowerCase() === TariffStatus.Active.toLowerCase();
			this.activeBenefit = isActiveBenfit;
			this.paymetTicketIcon = greenSmallTickIcon;
			// replace , with . to avoid NAN run time error
			if (paymentJourneyParams.promoCode && !this.isPega) {
				paymentJourneyParams.firstButton = data.button1?.replace('{0}', data.defaultButton1Title);
				paymentJourneyParams.secondButton = data.button2?.replace('{0}', data.defaultButton2Title);
				paymentJourneyParams.placeHolders.okScreeen.subtitle = [
					data.defaultPromotionalSubtitle?.replace('{0}', data.promotionValue),
				];
				paymentJourneyParams.placeHolders.okScreeen.desc = [
					data.defaultPromotionalDescription?.replace('{0}', data.promotionValue),
				];
			} else if (!paymentJourneyParams.promoCode && !this.isPega) {
				paymentJourneyParams.placeHolders.okScreeen.subtitle = [data.defaultSubtitle];
				paymentJourneyParams.firstButton = data.button1?.replace('{0}', data.defaultButton1Title);
				paymentJourneyParams.secondButton = data.button2?.replace('{0}', data.defaultButton2Title);
				if (
					!isActiveBenfit &&
					+currentAmount?.replace(',', '.') + +paymentJourneyParams?.amount >= +this.tariffService.Tariff.Fee
				) {
					paymentJourneyParams.placeHolders.okScreeen.desc = [data.reviewingTariffDescription];
				} else {
					paymentJourneyParams.placeHolders.okScreeen.desc = [data.defaultDescription];
				}
			}
			if (this.isPega && !paymentJourneyParams.promoCode) {
				paymentJourneyParams.firstButton = data.button1?.replace('{0}', data.defaultButton1Title);
				paymentJourneyParams.secondButton = data.button2?.replace('{0}', data.defaultButton2Title);
				paymentJourneyParams.placeHolders.okScreeen.subtitle = [
					data.defaultPegaSubtitle?.replace(
						'{0}',
						(this.currentSelectedAmount * this.pegaOfferKeyValue[pegaOfferFields.extraBalancePCT]) / 100
					),
				];
				paymentJourneyParams.placeHolders.okScreeen.desc = [data.defaultPegaDescription];
				this.isSuccessPegaOverlayOpened = true;
			}
			if (this.isPega && paymentJourneyParams.promoCode) {
				paymentJourneyParams.firstButton = data.button1?.replace('{0}', data.defaultButton1Title);
				paymentJourneyParams.secondButton = data.button2?.replace('{0}', data.defaultButton2Title);
				paymentJourneyParams.placeHolders.okScreeen.subtitle = [
					data.PromotionalWithPegaSubtitle.replace(
						'{0}',
						(this.currentSelectedAmount * this.pegaOfferKeyValue[pegaOfferFields.extraBalancePCT]) / 100
					).replace('{1}', data.promotionValue),
				];
				paymentJourneyParams.placeHolders.okScreeen.desc = [data.PromotionalWithPegaDescription];
				this.isSuccessPegaOverlayOpened = true;
			}
			this.isForOthersJourney = false;
		});
	}

	/**
	 * Open iframe and set payment joureny params object needed to open it
	 * @param amount of the money user need to pay
	 * @param WCSJournyKey of the current journey
	 */
	openPaymentModuleForAnonoumus(amount: string, WCSJournyKey: PaymentJourny): void {
		const paymentJourneyParams: PaymentJourneyParams = new PaymentJourneyParams();
		// payer data
		paymentJourneyParams.payer.documentId = '';
		paymentJourneyParams.payer.siteId = 'ANONYMOUS';
		paymentJourneyParams.payer.serviceId = this.msisdn;
		paymentJourneyParams.payer.documentType = '';
		// buyer data
		paymentJourneyParams.buyer.documentId = '';
		paymentJourneyParams.buyer.siteId = '';
		paymentJourneyParams.buyer.serviceId = this.msisdn;

		paymentJourneyParams.amount = amount;
		paymentJourneyParams.WCSJournyKey = WCSJournyKey;
		paymentJourneyParams.isAnonymous = true;
		// params related to iframe screen
		paymentJourneyParams.concepto = this.msisdn;
		paymentJourneyParams.journeyId = PaymentJourneyIds.prePaidWithOutCvv;
		// for handling journey
		paymentJourneyParams.isHandleWaiting = true;
		paymentJourneyParams.language = this.topupLanguage === 'en' ? PaymentLanguages.en : PaymentLanguages.es;
		paymentJourneyParams.paymentTaggingConfig = this.getPaymentModuleTagging();
		this.paymentNavigationService.openPaymentThirdParty(paymentJourneyParams);
	}
	/**
	 * Return tagging object (pagename, journey) for prepaid tagging
	 */
	getPaymentModuleTagging(): PaymentTaggingObject {
		const paymentTaggingObject: PaymentTaggingObject = new PaymentTaggingObject();
		let pageName: string = '';
		let pageName_ok: string = '';
		let pageName_ko: string = '';
		this.activeBenefit =
			this.tariffService.Tariff &&
			this.tariffService.Tariff.benefitStatus?.toLowerCase() === TariffStatus.Active.toLowerCase();
		if (this.activeBenefit && this.currentSelectedAmount >= +this.tariffService.Tariff.Fee) {
			if (this.paymentJourneyParams.promoCode) {
				pageName = tagging.prePaidGenericPaymentPageName.page_name.replace(
					'<options>',
					tagging.staticVariables.afterTopUp.withPromoCode
				);
				pageName_ok = tagging.prePaidGenericPaymentDonePageName.page_name.replace(
					'<options>',
					tagging.staticVariables.afterTopUp.withPromoCode
				);
				pageName_ko = tagging.prePaidGenericPaymentKOPageName.page_name.replace(
					'<options>',
					tagging.staticVariables.afterTopUp.withPromoCode
				);
			} else {
				pageName = tagging.prePaidGenericPaymentPageName.page_name.replace(
					'<options>',
					tagging.staticVariables.afterTopUp.withOptions
				);
				pageName_ok = tagging.prePaidGenericPaymentDonePageName.page_name.replace(
					'<options>',
					tagging.staticVariables.afterTopUp.withOptions
				);
				pageName_ko = tagging.prePaidGenericPaymentKOPageName.page_name.replace(
					'<options>',
					tagging.staticVariables.afterTopUp.withOptions
				);
			}
		} else {
			pageName = tagging.prePaidGenericPaymentPageName.page_name.replace('<options>', '');
			pageName_ok = tagging.prePaidGenericPaymentDonePageName.page_name.replace('<options>', '');
			pageName_ko = tagging.prePaidGenericPaymentKOPageName.page_name.replace('<options>', '');
		}
		paymentTaggingObject.pageName = this.getPageNameForPrePaidTagging({
			...tagging.prePaidGenericPaymentPageName,
			page_name: pageName,
		});
		paymentTaggingObject.pageName_ok = this.getPageNameForPrePaidTagging({
			...tagging.prePaidGenericPaymentDonePageName,
			page_name: pageName_ok,
		});
		paymentTaggingObject.pageName_ko = this.getPageNameForPrePaidTagging({
			...tagging.prePaidGenericPaymentKOPageName,
			page_name: pageName_ko,
		});
		paymentTaggingObject.journey = this.getJourneyForPrePaidTagging();
		return paymentTaggingObject;
	}
	/**
	 * Return pagename object for prepaid tagging
	 * @param pageNameObject the pagename object
	 */
	getPageNameForPrePaidTagging(pageNameObject: PaymentTaggingPageName): PaymentTaggingPageName {
		const pageName: PaymentTaggingPageName = Object.assign({}, pageNameObject);
		return pageName;
	}
	/**
	 * Return journey object for prepaid tagging
	 */
	getJourneyForPrePaidTagging(): PaymentTaggingJourney {
		let journey: PaymentTaggingJourney = new PaymentTaggingJourney();
		journey.journey_name = PaymentJourneyName.prepaid;
		journey.journey_category = CrossVariables.client_typology;
		journey.journey_subcategory = PaymentJourneySubCategory.prepaid;
		journey.journey_type = PaymentJourneyType.servicio;
		journey.journey_environment = PaymentJourneyEnvironment.private;
		journey.event_success = PaymentJourneyEvents.okPrepaid;
		journey.event_error = PaymentJourneyEvents.koEvent3;
		if (this.activeBenefit && this.currentSelectedAmount >= +this.tariffService.Tariff.Fee) {
			this.promoCode
				? (journey['&&products'] = tagging.afterTopUp.successPageWithPromoCode.state['&&products'].replace(
						SuccessScreenTaggingLiterals.promoCodeKey,
						`;eVar207=${this.promoCode}`
				  ))
				: (journey['&&products'] = SuccessScreenTaggingLiterals.productValue);
		} else {
			journey['&&products'] = PaymentJourneyProducts.prepaid;
		}
		journey = this.sendPlatinumPegaTagging(journey);
		return journey;
	}

	sendPlatinumPegaTagging(journey: PaymentTaggingJourney): PaymentTaggingJourney {
		if (this.isPegaPlatinum && this.currentSelectedAmount >= +this.tariffService.Tariff.Fee) {
			if (this.promoCode) {
				if (this.pegaPlatinumOfferCode === pegaOffersIDs.ALMOUSTIN) {
					journey.journey_process = PaymentJourneyProcess.ofertaPlatino;
				}
			} else {
				if (this.pegaPlatinumOfferCode === pegaOffersIDs.ALMOUSTIN) {
					journey.journey_process = PaymentJourneyProcess.platinoOfferAlmostIn;
				} else if (this.pegaPlatinumOfferCode === pegaOffersIDs.ALMOUSTOUT) {
					journey.journey_process = PaymentJourneyProcess.platinoOfferAlmostOut;
				}
			}
		}
		return journey;
	}

	/**check if the browser in the device able lto open contact list or not */
	checkContactList(): void {
		this.contactListSupported = constants.contacts in navigator;
		if (this.contactListSupported) {
			const supportedProperties = (navigator as any).contacts.getProperties();
			if (supportedProperties.includes(constants.tel)) {
				this.openContactList();
			}
		}
	}

	/**to open contact list and get the selected contact */
	async openContactList(): Promise<string> {
		/**to not be able to select multiple contacts */
		const opts: {} = { multiple: false };
		/**select contact from contact list */
		const contact = await (navigator as any).contacts.select([constants.tel], opts);
		this.selectedPhoneNumber =
			contact && contact[0].tel[0]
				? contact[0].tel[0].replace(/\s/g, '').replace(/^0034/g, '').replace(/^\+34/g, '').replace(/\+/g, '')
				: null;
		return Promise.resolve(this.selectedPhoneNumber);
	}
}
