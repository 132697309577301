<div (click)="linkAction()" class="link">
	<span>
		<img
			*ngIf="imgUrl"
			[attr.id]="id ? id + 'img' : null"
			[src]="imgUrl | imgFullPath"
			class="left-icon"
			alt="lfticon"
		/>
		<a class="text" [attr.id]="id ? id + 'Text' : null"> {{ linkText }} </a>
	</span>
	<i class="icon icon-chevron-right" [attr.id]="id ? id + 'Icon' : null"> </i>
</div>
<!-- divider-->
<div class="divider" *ngIf="showDivider"></div>
