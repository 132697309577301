import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { API_URLS } from '../constants/routes-config';
import { LOCAL_STORAGE_KEYS, CaptureResponseActions } from '../constants/defines';
import { CaptureResponseModel } from '../../models/capture-response.model';
import { StorageService } from '../../core/services/storage.service';
import { EntryPointModel, EntryPointResponseModel } from '../../models/entry-points.model';
import { SubscriptionService } from '../../core/services/subscription.service';
@Injectable({
	providedIn: 'root',
})
export class CaptureResponseService {
	isPega: boolean;
	entryPoint: EntryPointModel;
	public captureResponseError: Subject<boolean> = new Subject<boolean>();
	public isCaptureResponseError: boolean;
	public setCaptureResponseError: HttpErrorResponse;

	constructor(
		private http: HttpClient,
		private storageService: StorageService,
		private subscriptionService: SubscriptionService
	) {}

	public callCaptureResponsePega(entrypoint: EntryPointResponseModel): void {
		this.setCaptureResponsePega(entrypoint.entryPoints[0], CaptureResponseActions.click);
	}
	public acceptedCaptureResponsePega(entrypoint: EntryPointModel): void {
		this.setCaptureResponsePega(entrypoint, CaptureResponseActions.accepted);
	}

	public offerNoAvailable(offerDescriptorDL: string, offerDescriptorEP: string): boolean {
		return offerDescriptorDL ? !offerDescriptorEP || offerDescriptorDL !== offerDescriptorEP : false;
	}

	public setCaptureResponsePega(entrypoint: EntryPointModel, outcomeData: string): void {
		let ifError: boolean = false;
		this.isCaptureResponseError = false;
		const laEntryPoint: EntryPointModel = entrypoint;
		if (laEntryPoint.offerVFDescriptor) {
			const id: string = laEntryPoint.id;
			const data: CaptureResponseModel = this.captureResponseModelData(entrypoint, outcomeData);
			this.captureResponse(id, data, laEntryPoint.pegaToken).subscribe(
				(res) => {
					this.captureResponseError.next(ifError);
				},
				(error) => {
					this.isCaptureResponseError = true;
					this.setCaptureResponseError = error;
					if (error.ecode || error.error.ecode || error.code || error.error.code) {
						ifError =
							error.ecode?.toString() === '1300' ||
							error.error.ecode?.toString() === '1300' ||
							error.error.code?.toString() === '1300' ||
							error.code?.toString() === '1300';
					}
					this.captureResponseError.next(ifError);
				}
			);
		} else {
			this.captureResponseError.next(ifError);
		}
	}

	private captureResponseModelData(laEntryPoint: EntryPointModel, outcomeData: string): CaptureResponseModel {
		return {
			containerName: laEntryPoint.containerName,
			originNetFee: laEntryPoint.originNetFee,
			name: laEntryPoint.name,
			label: laEntryPoint.label,
			issue: laEntryPoint.issue,
			groupNBA: laEntryPoint.groupNBA,
			interactionID: laEntryPoint.interactionID,
			rank: laEntryPoint.rank,
			bundleParent: laEntryPoint.bundleParent,
			paqueteDescAdHoc: laEntryPoint.paqueteDescAdHoc,
			precioDescAdHoc: laEntryPoint.precioDescAdHoc,
			duracionDescAdHoc: laEntryPoint.duracionDescAdHoc,
			codigoDescAdHoc: laEntryPoint.codigoDescAdHoc,
			cuotaDescAdHoc: laEntryPoint.cuotaDescAdHoc,
			offerVFDescriptor: laEntryPoint.offerVFDescriptor,
			netFeeIncrease: laEntryPoint.netFeeIncrease,
			outcome: outcomeData,
			code: laEntryPoint.code,
			codigoNBA: laEntryPoint.codigoNBA,
			colorNBA: laEntryPoint.colorNBA,
			flow: laEntryPoint.flow,
			group: laEntryPoint.group,
			id: laEntryPoint.id,
			order: laEntryPoint.order,
			space: laEntryPoint.space,
			umbralesNBA: laEntryPoint.umbralesNBA,
			siteID: this.subscriptionService.customerData.currentService.siteId,
			channel: laEntryPoint.channel,
		};
	}

	public captureResponse(id: string, data: CaptureResponseModel, optionalToken?: string): Observable<any> {
		const url: string = API_URLS.CaptureResponse.replace(/\{0\}/g, id);
		const token: string | undefined = data.pegaToken;
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);

		const authTokenHeader: string = 'Auth-Pega' as const;

		if (optionalToken) {
			headers = headers.append(authTokenHeader, optionalToken);
		} else if (token) {
			headers = headers.append(authTokenHeader, token);
		}

		const options: Record<string, boolean | HttpHeaders> = {
			headers,
			withCredentials: true,
		};

		return this.http.patch(url, data, options);
	}
}
