import { Component, Input, EventEmitter, Output } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
@Component({
	selector: 'sp-full-width-slider',
	templateUrl: './full-width-slider.component.html',
	styleUrls: ['./full-width-slider.component.scss'],
})
export class FullWidthSliderComponent {
	@Output() indexChangeFn = new EventEmitter<any>();
	@Input() headerText: string;
	@Input() hideHeader: boolean;
	showModal: boolean;
	noPadding: boolean;
	config: SwiperConfigInterface = {
		scrollbar: false,
		direction: 'horizontal',
		slidesPerView: 1,
		pagination: true,
		mousewheel: true,
		navigation: true,
	};

	constructor(private tagging: TaggingHelperService) {}

	onIndexChange(index: number) {
		this.indexChangeFn.emit(index);
	}
	showSwiperModal() {
		this.showModal = true;
	}

	swiperNoPadding() {
		this.noPadding = true;
	}

	hideSwiperModal() {
		this.showModal = false;
		this.tagging.hideBillingOverlay.next();
		this.tagging.sendViewState();
	}
}
