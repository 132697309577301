<div id="sharedModuleFullOverlayIframeContainer" class="full-screen-modal" *ngIf="showModal">
	<div id="sharedModuleFullOverlayIframeHeader" class="modal-header">
		<button
			id="sharedModuleFullOverlayIframeCloseButton"
			type="button"
			class="pull-right"
			aria-label="Close"
			(click)="onCloseModal()"
		>
			<span class="icon icon-close overlay-full-close"> </span>
		</button>
	</div>
	<div id="sharedModuleFullOverlayIframeContent" class="modal-content">
		<iframe id="sharedModuleFullOverlayIframeIframe" [src]="iframeSrcSanitized"> </iframe>
	</div>
</div>
