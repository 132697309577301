export class PromoCommercial {
	public id?: string;
	public applyTo?: string;
	public descriptionCRM?: string;
	public discountAmount?: string;
	public discountAmountTaxes?: string;
	public discountClassType?: string;
	public discountDuration?: string;
	public discountType?: string;
	public detailTextList?: string;
	public evaluationOrder?: number;
	public flagIgnoreCRM?: string;
	public flagRegional?: string;
	public headbandOpenText?: string;
	public internalName?: string;
	public linkedToCatalog?: string;
	public longText?: string;
	public publicName?: string;
	public urlMobilePromotionImage?: string;
	public urlMobilePromotionImageFicha?: string;
	public urlTabletPromotionImageFicha?: string;
	public shortText?: string;
	public keep?: boolean;
	public endDate?: any;
	public llbbText?: string;
	public llbbUrl?: string;
	public detailTextFicha?: string;
	public stayPromotionText?: string;
	public starText?: string;
	public longTextFicha?: string;
	public listIcon?: string;
	public detailIcon?: string;
	public discountDurationUndefined?: boolean;
	public cardColor?: string;
	public cardText?: string;
	public detailPromotionText?: string;
	public addLineBannerText?: string;
}
export class ListPromoCommercial {
	txtPromo: string;
	valuePromo: string;
}
