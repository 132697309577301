<div id="entry-points-management">
	<ng-container *ngIf="entryPoints">
		<div (click)="navigateToMobiles()" class="entry-card col-xs-12 position-relative">
			<div class="content col-xs-8">
				<p class="title">{{ title }}</p>
				<button>
					<div class="buttonTxt subtitle">
						{{ button }}
					</div>
					<div class="icon icon-chevron-right align-right-icon"></div>
				</button>
			</div>
			<div class="image col-xs-4" class="position-absolute">
				<img class="position-absolute" src="{{ appService.getImgFullPath(imageUrl) }}" />
			</div>
		</div>
	</ng-container>
</div>
