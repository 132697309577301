<mva10-c-card class="navigation-card">
	<mva10-c-icon
		[id]="'sharedINCIcon' + cardTitle"
		class="navigation-card__icon"
		[iconId]="iconId"
		[title]="iconTitle"
		[size]="iconSize"
	>
	</mva10-c-icon>

	<p [id]="'sharedINCText' + cardTitle" class="mva10-u-heading--5-lt navigation-card__text">
		{{ cardText }}
	</p>
	<mva10-c-link-chevron
		id="sharedINCNavigation"
		class="navigation-card__icon"
		[size]="'normal'"
		[appearance]="chevronColorId"
		[hrefLink]="routerLink"
	>
	</mva10-c-link-chevron>
</mva10-c-card>
