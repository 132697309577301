<vfac-overlay [show]="showNebaDesktopOverlay" (closed)="closedOverlay()" [loaded]="true" [greyMode]="false">
	<div body class="container">
		<div class="container__image">
			<img [src]="'v10.myOrder.viewDesktop.img.mobile' | imgFullPath" alt="Alert" />
		</div>
		<div class="container__title vfes-title--bold mva10-u-heading--4">
			{{ 'v10.myOrder.viewDesktop.nebatitle' | translate }}
		</div>
		<div class="container__description mva10-u--body">
			{{ 'v10.myOrder.viewDesktop.nebadesc' | translate }}
		</div>
		<div class="container__button">
			<mva10-c-button (mva10OnClick)="closedOverlay()">
				{{ 'v10.myOrder.viewDesktop.agreebutton' | translate }}
			</mva10-c-button>
		</div>
	</div>
</vfac-overlay>
