import { UserProfile } from '../../models/user-profile.model';
import { adaraValues, JSON_PATHS } from '../../shared/constants/defines';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { JSONPath } from 'jsonpath-plus';
import { KJUR } from 'jsrsasign';
import { Otp } from '../../models/otp.model';
import { DecodedToken } from '../../models/decoded-token.model';
import { UtilityService } from './utility.service';
import { CustomerAccount } from '../../models/customer-account.model';
import { ImpersonationGroup } from '../../shared/enums/impersonationGroup.enum';

@Injectable()
export class JWTHelper {
	constructor(private storageService: StorageService, private utils: UtilityService) {}

	/**
	 * Get Otp data from the jwt recieved from the server
	 * @param data
	 */
	getOtpDataFromJWT(data: any): Otp {
		// convert jwt into string
		const decoded: any = this.convertFromBase64ToString(data.jws);
		const response: any = JSON.parse(decoded);
		const otp: Otp = {} as Otp;
		otp.isOtp = !(!response.hasOwnProperty('mailList') && !response.hasOwnProperty('msisdnList'));
		otp.mailList = response.mailList ? response.mailList : null;
		otp.msisdnList = response.msisdnList ? response.msisdnList : null;
		otp.access_token = data.access_token;
		return otp;
	}

	/**
	 * Get UserProfile from the jwt recieved from the server
	 * @param token the base64 token jwt recieved
	 */
	getUserProfileFromJWT(token: string): UserProfile {
		// convert jwt into string
		const decoded: string = this.convertFromBase64ToString(token);
		const userprofileResponse: DecodedToken = JSON.parse(decoded);
		const userProfile: UserProfile = {} as UserProfile;

		const json: DecodedToken = userprofileResponse;
		const wrap: boolean = false;

		userProfile.username = JSONPath({ path: JSON_PATHS.USERPROFILE.USERNAME, json, wrap });
		// TODO: Remove Default MSISDN
		userProfile.msisdn = JSONPath({ path: JSON_PATHS.USERPROFILE.MSISDN, json, wrap });
		userProfile.firstName = JSONPath({ path: JSON_PATHS.USERPROFILE.FIRSTNAME, json, wrap });
		userProfile.id = JSONPath({ path: JSON_PATHS.USERPROFILE.ID, json, wrap });
		userProfile.lastPasswordChange = JSONPath({ path: JSON_PATHS.USERPROFILE.LAST_PASSWORD_CHANGE, json, wrap });
		userProfile.lastName = JSONPath({ path: JSON_PATHS.USERPROFILE.LASTNAME, json, wrap });
		userProfile.email = JSONPath({ path: JSON_PATHS.USERPROFILE.EMAIL, json, wrap });
		userProfile.defaultSiteId = JSONPath({ path: JSON_PATHS.USERPROFILE.DEFAULT_SITE_ID, json, wrap });
		userProfile.profileType = JSONPath({ path: JSON_PATHS.USERPROFILE.PROFILE_TYPE, json, wrap });
		userProfile.companies = JSONPath({ path: JSON_PATHS.USERPROFILE.COMPANIES, json, wrap });
		userProfile.customerType = JSONPath({ path: JSON_PATHS.USERPROFILE.CUSTOMER_TYPE, json, wrap });
		userProfile.document = {};
		userProfile.document.id = JSONPath({ path: JSON_PATHS.USERPROFILE.DOUCMENT_ID, json, wrap });
		userProfile.document.type = JSONPath({ path: JSON_PATHS.USERPROFILE.DOUCMENT_TYPE, json, wrap });
		userProfile.sites = [];
		userProfile.sites = this.getOrderedSites(userprofileResponse, JSON_PATHS.USERPROFILE.SITES);
		userProfile.hasEnergySites = this.hasEnergySites(userProfile.sites);
		const permission: string = JSONPath({ path: JSON_PATHS.USERPROFILE.PERMISSIONS, json, wrap });
		userProfile.permissions = [];
		userProfile.permissions.push(permission.toLowerCase());
		userProfile.userPermissions = JSONPath({ path: JSON_PATHS.USERPROFILE.USSERPERMISSIONS, json, wrap });
		userProfile.services = JSONPath({ path: JSON_PATHS.USERPROFILE.SERVICES, json, wrap }) || [];
		userProfile.hasExternalCompanies =
			JSONPath({ path: JSON_PATHS.USERPROFILE.hasExternalCompanies, json, wrap }) || false;
		userProfile.hasExternalServices =
			JSONPath({ path: JSON_PATHS.USERPROFILE.hasExternalServices, json, wrap }) || false;
		userProfile.showGDPROverlay = JSONPath({ path: JSON_PATHS.USERPROFILE.showGDPROverlay, json, wrap }) || false;
		userProfile.gdprDiagnostics = JSONPath({ path: JSON_PATHS.USERPROFILE.gdprDiagnostics, json, wrap });
		userProfile.gdprDiagnostics =
			userProfile.gdprDiagnostics === undefined || userProfile.gdprDiagnostics === null
				? true
				: userProfile.gdprDiagnostics;
		userProfile.firmaDigital = JSONPath({ path: JSON_PATHS.LOGIN.SUCCESS.firmaDigital, json, wrap }) || false;
		userProfile.category = JSONPath({ path: JSON_PATHS.USERPROFILE.category, json, wrap });
		userProfile.isConsultant = userprofileResponse?.impersonatedBy?.group === ImpersonationGroup.Consultant;
		userProfile.selectedCif = JSONPath({ path: JSON_PATHS.USERPROFILE.selectedCif, json, wrap });
		userProfile.roles = JSONPath({ path: JSON_PATHS.USERPROFILE.ROLES, json, wrap });
		// Refactor due to cyclomatic complexity when using || null
		return this.utils.parseNullValues(userProfile, null);
	}

	/**
	 * Converts the encoded string into decoded string
	 * @param base64 string encoded in base64
	 */
	public convertFromBase64ToString(base64: string) {
		try {
			const jws = new KJUR.jws.JWS();
			jws.parseJWS(base64);
			return jws.parsedJWS.payloadS;
		} catch (e) {
			throw new Error('The string to be decoded is not correctly encoded.');
		}
	}

	/**
	 * Compare refresh token against current date
	 * @return boolean indicates the referesh token is finished or not
	 */
	public isRefereshTokenExpired(): boolean {
		return new Date().getTime() - this.getRefreshTokenExpirationDate().getTime() > 1;
	}

	public isAccessTokenExpired(): boolean {
		return new Date().getTime() - this.getAccessTokenExpirationDate().getTime() > 1;
	}
	/**
	 * Get expiring time of refresh token
	 */
	private getRefreshTokenExpirationDate(): Date {
		const refDate = this.storageService.getStorage('refreshTokenExpirationTime');
		return this.storageService.refreshExpirationTime || new Date(refDate);
	}
	private getAccessTokenExpirationDate(): Date {
		const accessDate = this.storageService.getStorage('accessExpirationTime');
		return this.storageService.accessExpirationTime || new Date(accessDate);
	}

	private getOrderedSites(response: DecodedToken, path: string): CustomerAccount[] {
		const originalSites: CustomerAccount[] = JSONPath({ path, json: response, wrap: false });
		const dividedSites: { telco: CustomerAccount[]; energy: CustomerAccount[] } = originalSites.reduce(
			(acc, site) => {
				site.marketType === adaraValues.CLIENT_ADARA ? acc.energy.push(site) : acc.telco.push(site);
				return acc;
			},
			{ energy: [], telco: [] }
		);

		return [...dividedSites.telco, ...dividedSites.energy];
	}

	private hasEnergySites(sites: CustomerAccount[]): boolean {
		return Boolean(sites?.some((site) => site.marketType === adaraValues.CLIENT_ADARA));
	}
}
