export enum MyAccountDetailsType {
	VF = 'VF',
	Email = 'Email',
	Mobile = 'Mobile',
	Landline = 'Landline',
	Additional = 'Additional',
	PostalAddress = 'PostalAddress',
}

export enum ChangeTypeMyData {
	mobile = 'CHANGE_MOBILE',
	optional_mobile = 'CHANGE_OPTIONAL_MOBILE',
	email = 'CHANGE_EMAIL',
}
