import {
	SharedServicesPegaReplacerTokens,
	SharedServicesPegaReplacerError,
	SharedServicesPegaReplacerTokensMap,
} from '../../entity/shared-services-pega-replacer.entity';

/**
 *
 * @param tokens Tokens separated by `+` character. Example: `{+}`, `%+%`, `[+]`, etc.
 * @returns {SharedServicesPegaReplacerTokensMap} Validated token as a custom Map.
 * @example
 * ```ts
 * const tokens = sharedServicesPegaReplacerCreateToken('{+}');
 * const pattern: RegExp = sharedServicesPegaReplacerCreatePatternFromToken(tokens);
 * ```
 */
export function sharedServicesPegaReplacerCreateToken(
	tokens: SharedServicesPegaReplacerTokens
): SharedServicesPegaReplacerTokensMap {
	if (typeof tokens !== 'string') {
		throw new Error(SharedServicesPegaReplacerError.InvalidTypeForCreateToken);
	}

	if (tokens.length !== 3) {
		throw new Error(SharedServicesPegaReplacerError.InvalidValueForCreateToken);
	}

	const tokensSeparator = /\+/g;
	const [a, b]: string[] = tokens.split(tokensSeparator);

	const tokensMap: SharedServicesPegaReplacerTokensMap = new Map();

	tokensMap.set('prefix', a);
	tokensMap.set('suffix', b);
	tokensMap.set('separator', tokensSeparator.source);

	return tokensMap;
}
