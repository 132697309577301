import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'sp-card-link',
	templateUrl: './sp-card-link.component.html',
	styleUrls: ['./sp-card-link.component.scss'],
})
export class SpCardLinkComponent {
	@Input() title: string;
	@Input() text: string;
	@Output() clickCard: EventEmitter<void> = new EventEmitter<void>();

	openLink(): void {
		this.clickCard.emit();
	}
}
