<div class="overlay">
	<div class="confirmation">
		<button id="confirmClose" class="icon-reset">
			<span class="icon icon-close delete-alise white-top-border" (click)="crossAction()"> </span>
		</button>
		<div class="mva10-u--m8x" *ngIf="header || highlightedText">
			<p *ngIf="highlightedText" class="bold-font mva10-u-color--dark-grey">{{ highlightedText }}</p>
			<h3 *ngIf="header" class="mva10-u-color--dark-grey">{{ header }}</h3>
		</div>
		<div class="content">
			<img *ngIf="image" [src]="appService.getImgFullPath(image)" />
			<p *ngIf="title" class="title">{{ title }}</p>
			<p *ngIf="subtitle" class="subtitle" [innerHtml]="subtitle"></p>
			<ng-content> </ng-content>
		</div>
	</div>
</div>
