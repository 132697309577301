<div class="wallet-container-responsive">
	<sp-marketplace-tabs
		*ngIf="marketplaceService.hasMarketPlaceMyOrdersEP"
		class="marketplace__tabs"
		[pageTagData]="pageTagData"
	>
	</sp-marketplace-tabs>
	<sp-marketplace-iframe
		*ngIf="
			marketplaceService.hasMarketPlaceMyOrdersEP &&
			marketplaceService.marketplaceBillingAccountUrl &&
			marketplaceService.tabMarketplace
		"
		class="marketplace__body"
		[url]="marketplaceService.marketplaceBillingAccountUrl"
	>
	</sp-marketplace-iframe>
	<div id="mainPaymentWalletTabShop" class="top-up-cards-container" *ngIf="marketplaceService.tabShop">
		<sp-wallet-card
			[menuItems]="actions"
			[isDotsShow]="true"
			[isPrinciple]="card.principle"
			[label]="principalTitle"
			[cardImg]="card.cardImage"
			[cardNumber]="card.cardMask"
			*ngFor="let card of cardsList; trackBy: cardIdentify"
			(onMenuActionClick)="updateOrDelete($event, card)"
			(onCardClick)="selectAndBack(card)"
		>
		</sp-wallet-card>
		<sp-wallet-card
			*ngIf="!standAloneWallet"
			[isDotsShow]="false"
			[isPrinciple]="false"
			[cardImg]="anotherCreditCardWalletImage"
			[cardNumber]="anotherCreditCardWalletText"
			(click)="selectAndBack(null)"
			class="last-card"
		>
		</sp-wallet-card>
		<div class="alert" *ngIf="alertObject?.showModel">
			<mva10-c-alert [layout]="alertObject?.layout?.toString()" [state]="alertObject?.modelType?.toString()">
				<p [innerHtml]="alertObject?.message"></p>
			</mva10-c-alert>
		</div>
	</div>
</div>
