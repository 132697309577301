import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { capmaignsStoredKey, PAGES } from '../../constants/defines';
import { API_URLS } from '../../constants/routes-config';
import { StorageService } from '../../../core/services/storage.service';
import { AuthenticateService } from '../../../core/services/authenticate.service';
import { UtilitiesService } from '../../utils/utilities.service';
import { SubscriptionService } from '../../../core/services/subscription.service';
import { Subject } from '../../../../../node_modules/rxjs';
import { AppService } from '../../../app.service';
import { config } from '../../../../config/pages-config';
import { Router } from '@angular/router';

@Injectable()
export class CampaignService {
	moduleName: any;
	// i stored the request in variable to centralize the object.. nearly like sigletone pattern
	show: boolean;
	campaigns: any;
	currentCampaign: any;
	customerAccountId: string;
	public status: Subject<boolean> = new Subject();

	constructor(
		private http: HttpClient,
		private storageService: StorageService,
		private auth: AuthenticateService,
		private utils: UtilitiesService,
		private subscription: SubscriptionService,
		private appTheme: AppService,
		private router: Router
	) {}

	fetchCampaign(customerAccountId) {
		// get all client campaign
		const url = API_URLS.campaign.fetchCampaign.replace('{customerAccountId}', customerAccountId);
		return this.http.get(url).pipe(
			map((res) => {
				return res ? res['items'] : res;
			})
		);
	}

	sendCampaignPermissions(permissions) {
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');
		const options = {
			headers: headers,
		};
		// send permissions data
		const url = API_URLS.CustomerAccount.permissionsAndPreferences.replace('{siteId}', this.customerAccountId);
		return this.http.patch(url, permissions, options).pipe(map((res) => {}));
	}

	sessionStore(campaignId) {
		// here we store the campaign in case of failer in request
		// @INPUT: campaignId
		let storedCampaign = this.storageService.getStorage(capmaignsStoredKey);
		if (!storedCampaign) {
			storedCampaign = [];
		}
		storedCampaign.push(campaignId + this.moduleName);
		this.storageService.setStorage('campaigns', storedCampaign);
	}

	sessionExist(campaign) {
		const capmaignsStoredValue: any = this.storageService.getStorage(capmaignsStoredKey);
		// 'if campaign in the session then i have to hide it from showing '
		if (capmaignsStoredValue) {
			return capmaignsStoredValue.some((element) => {
				return element === campaign.id + this.moduleName;
			});
		} else {
			return false;
		}
	}

	localStorageSet(campaignId): void {
		// this function set campaign in localStorage with login-id and date of last view
		// @INPUT: campaign
		let storedCampaign = this.storageService.getLocalStorage(capmaignsStoredKey);
		if (!Array.isArray(storedCampaign)) {
			storedCampaign = [];
		}
		storedCampaign.push({
			id: campaignId + this.moduleName,
			customerAccountId: this.customerAccountId,
			time: new Date(),
		});
		this.storageService.setLocalStorage('campaigns', storedCampaign);
	}

	localStorageGet(campaign) {
		// this function check campaign from localStorage if exist and in the hidden duration
		// @INPUT: campaign
		// @OUTPUT: true if the campaign inside the localstorage and within hidden duration
		const capmaignsStoredValue: any = this.storageService.getLocalStorage(capmaignsStoredKey);
		// 'if campaign in the session then i have to hide it from showing '
		if (Array.isArray(capmaignsStoredValue)) {
			return capmaignsStoredValue.some((element) => {
				if (element.id === campaign.id + this.moduleName && element.customerAccountId === this.customerAccountId) {
					const now = new Date();
					const diffDays = Math.abs(this.utils.hourDiff(now, new Date(element.time)));
					return diffDays < campaign.recurrence * 24; // to convert recurrence from day to hours
				}
			});
		}
	}

	getCurrentCampaign(moduleName) {
		this.moduleName = moduleName;
		// add that the user must be light or full profile
		this.customerAccountId = this.subscription.customerData.currentService.siteId;
		// this helps us to select the right campaign for this user and in the current module
		if (!(this.campaigns && this.campaigns.lenght)) {
			// if campaigs are not exist go and get it
			// we can conseder it as entry point for the fetchCampaign
			this.fetchCampaign(this.customerAccountId).subscribe(
				(data) => {
					this.campaignPreprocess(data);
				},
				(error) => {
					// to fix if the API not available
					this.status.next(true);
				}
			);
		} else {
			this.campaignPreprocess();
		}
	}

	campaignPreprocess(data?: any) {
		// this will filter campaigns
		this.campaigns = this.filterCampaigns(data || this.campaigns);
		this.currentCampaign = this.findCampaignByModule(this.moduleName);
		if (this.currentCampaign) {
			this.show = true;
			this.appTheme.showFullAppLoader = false;
		} else {
			this.status.next(true);
		}
	}

	findCampaignByModule(moduleName) {
		// get campaign which has this module
		return JSON.parse(JSON.stringify(this.campaigns)).find((campaign: any) => {
			campaign.modules = campaign.modules.find((module) => {
				return module.name.toLowerCase() === moduleName.toLowerCase();
			});
			return campaign.modules ? campaign : false;
		});
	}

	filterCampaigns(campaigns) {
		// this will filter campaign array as per session, localstorage logic
		return campaigns.filter((campaign) => {
			// this loop over campaigns and hundel its new values then update the campain object
			if (this.sessionExist(campaign) || (!campaign.blocker && this.localStorageGet(campaign))) {
				return false;
			}
			return true;
		});
	}
	passToTarget(): void {
		this.appTheme.showFullAppLoader = true;
		this.show = false;
		this.status.next(true);
	}

	back(): void {
		this.status.next(false);
		this.show = false;
		if (this.moduleName.toLowerCase() === PAGES.DASHBORD.toLowerCase()) {
			this.auth.logout();
			this.router.navigate([config.login.route]);
		}
	}
}
