import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OverlayModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { LandlinePortablityGuide } from '../../../models/landlinePortabilityGuide';
import { MyOrdersTaggingService } from '../../../services/my-orders-tagging.service';

@Component({
	selector: 'sp-new-landline-portability-guide',
	templateUrl: './sp-new-landline-portability-guide.component.html',
	styleUrls: ['./sp-new-landline-portability-guide.component.scss'],
})
export class SpNewLandlinePortabilityGuideComponent implements OnInit {
	@Input() page: number = 1;
	@Input() fijoTemporal: string;
	showOverlay: boolean;
	showLoaded: boolean = false;

	titleProgressBarTechnician: string[] = [];
	titleProgressBarSelfInstallation: string[] = [];

	@Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() reloadTileData: EventEmitter<LandlinePortablityGuide> = new EventEmitter();
	@Output() backPage: EventEmitter<LandlinePortablityGuide> = new EventEmitter();
	currentTechHouseSlideChange: number;
	public configCarousel: string = JSON.stringify({
		responsive: {
			'0': { breakpoint: 0, elements: 1 },
			'1': { breakpoint: 769, elements: 1 },
			'2': { breakpoint: 1025, elements: 1, arrows: true },
		},
		build: { arrows: false, bullets: true, clickable_bullets: true },
	});

	showTechnicianHouse: boolean;

	guideArrayTechnician: Array<LandlinePortablityGuide>;
	guideArrayInstallation: Array<LandlinePortablityGuide>;
	positionIndex: number = 0;
	overlayData: OverlayModel = {
		title: ' ',
	};
	showMoreInf: boolean = false;
	overlayDataInf: OverlayModel;
	constructor(
		public appService: AppService,
		private translate: TranslateService,
		private tagging: MyOrdersTaggingService
	) {}

	ngOnInit(): void {
		this.showOverlay = true;
		this.titleProgressBarTechnician = [
			this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.Title1'),
			this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.Title2'),
			this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.Title3'),
			this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.Title4'),
		];
		this.titleProgressBarSelfInstallation = [
			this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.autoInstallTitle1'),
			this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.autoInstallTitle2'),
			this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.autoInstallTitle3'),
			this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.autoInstallTitle4'),
		];
		this.resetTagging();
		this.tagging.sendPage(['selector guia portabilidad fija']);
	}

	ngOnDestroy(): void {
		this.resetTagging();
		this.tagging.extendUtagPageName(['detalle pedido']);
	}

	technicianWillComeHouse(): void {
		this.showLoaded = false;
		this.page = 2;
		this.guideTechHouseCarousel();

		this.tagging.sendInteraction({
			link_type: 'click en acceder guia tecnico portabilidad fija',
		});

		setTimeout(() => {
			this.resetTagging();
			this.tagging.sendPage(['guia tecnico portabilidad fija']);
		}, 0);
	}

	guideTechHouseCarousel(): void {
		this.guideArrayTechnician = [];
		const sbdescription = this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.subdescription2b');
		this.guideArrayTechnician.push(
			{
				img: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.img.teleop1'),
				title: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.title1b'),
				description: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.description1b'),
			},
			{
				img: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.img.landline1'),
				title: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.title2b'),
				description: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.description2b'),
				subdescription: sbdescription.replace('{phone}', this.fijoTemporal),
			},
			{
				img: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.img.email1'),
				title: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.title3b'),
				description: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.subdescription3b'),
			},
			{
				img: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.img.portday1'),
				title: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.title4b'),
				description: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.description4b'),
				subdescription: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.subdescription4b'),
			}
		);
	}

	selfInstallation(): void {
		this.showLoaded = false;
		this.page = 3;
		this.guideSelfInstallation();

		this.tagging.sendInteraction({
			link_type: 'click en acceder guia autoinstalacion portabilidad fija',
		});

		setTimeout(() => {
			this.resetTagging();
			this.tagging.sendPage(['guia autoinstalacion portabilidad fija']);
		}, 0);
	}

	guideSelfInstallation(): void {
		this.guideArrayInstallation = [];
		const decription = this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.description1a');
		const subdecription = this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.subdescription2a');
		this.guideArrayInstallation.push(
			{
				img: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.img.teleop1'),
				title: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.title1a'),
				description: decription.replace('{phone}', this.fijoTemporal),
			},
			{
				img: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.img.landline1'),
				title: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.title2a'),
				description: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.description2a'),
				subdescription: subdecription.replace('{phone}', this.fijoTemporal),
			},
			{
				img: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.img.email1'),
				title: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.title3a'),
				description: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.description3a'),
			},
			{
				img: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.img.portday1'),
				title: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.title4a'),
				description: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.description4a'),
				subdescription: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.subdescription4a'),
				moreInformation: this.translate.instant('v10.myOrder.newLandlinePortabilityGuide.carousel.moreinfoa'),
			}
		);
	}

	closeOverlay(): void {
		this.showOverlay = false;
		setTimeout(() => {
			this.closed.emit();
			this.page = 1;
			this.positionIndex = 0;
		}, 500);

		this.tagging.sendInteraction({
			link_type: 'click en cerrar',
		});
	}

	onOverlayBackPage(): void {
		this.page = 1;
		this.positionIndex = 0;
		this.backPage.emit();

		this.tagging.sendInteraction({
			link_type: 'click en volver a mis pedidos',
		});
	}

	showValTechnician(position: number): void {
		if (position !== this.positionIndex) {
			this.reloadTileData.emit(this.guideArrayTechnician[position]);
			this.positionIndex = position;
		}
	}

	showVal(position: number): void {
		if (position !== this.positionIndex) {
			this.reloadTileData.emit(this.guideArrayInstallation[position]);
			this.positionIndex = position;
		}
	}

	clickMoreInformation(): void {
		this.showLoaded = false;
		this.showMoreInf = true;
		this.overlayDataInf = { title: 'Más información', isCenterTitle: true };

		this.tagging.sendInteraction({
			link_type: 'click en mas informacion',
		});

		setTimeout(() => {
			this.resetTagging();
			this.tagging.sendPage(['guia autoinstalacion portabilidad fija', 'overlay mas info']);
		}, 0);
	}

	closeOverlayInfo(): void {
		this.showMoreInf = false;
		this.tagging.sendInteraction({
			link_type: 'click en volver a la guia',
		});
	}

	resetTagging(): void {
		this.tagging.extendUtagPageName(
			[
				'detalle pedido',
				'selector guia portabilidad fija',
				'guia tecnico portabilidad fija',
				'guia autoinstalacion portabilidad fija',
				'overlay mas info',
			],
			null,
			true
		);
	}
}
