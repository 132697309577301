<vfac-overlay
	#routerActivationGuideOverlay
	[overlayData]="routerActivationGuideOverlayData"
	[show]="showRouterActivationGuideOverlay"
	[page]="selectedStep + 1"
	[loaded]="overlayLoaded"
	[loaderType]="'vodafone'"
	(closed)="closeGuideOverlay()"
	(backPage)="goBackwards()"
>
	<div header *ngIf="!showErrorView && selectedStep < 3">
		<mva10-c-steps [steps]="stepsData" [selected]="selectedStep" [vertical]="false" class="mva10-u--mt4x">
		</mva10-c-steps>
	</div>

	<div body id="page1">
		<div class="vfes-layout vfes-pt">
			<div class="vfes-layout__wrapper">
				<div class="vfes-layout__item">
					<div class="mvf-activate-router__display vfes-pb+">
						<img
							class="mvf-activate-router__img1 vfes-pr"
							src="{{ appService.getImgFullPath('v10.myOrder.activateRouter.img.group_5' | translate) }}"
							alt="desconecta el cable de alimentación"
						/>
						<div class="vfes-align--left">
							<ol class="mvf-activate-router__ordered-list vfes-pl mvf-activate-router__font-small" start="1">
								<li class="vfes-pl- vfes-pr-">
									<span class="vfes-title--bold mva10-u--body">
										<strong>
											{{ 'v10.myOrder.activateRouter.activateRouterStep1Instruction1Title' | translate }}
										</strong>
									</span>

									<span class="vfes-title--regular mva10-u--body">
										{{ 'v10.myOrder.activateRouter.activateRouterStep1Instruction1Description' | translate }}
									</span>
								</li>
							</ol>
						</div>
					</div>
					<div class="mvf-activate-router__display vfes-pb-">
						<img
							class="mvf-activate-router__img2 vfes-pb+ vfes-pr-"
							src="{{ appService.getImgFullPath('v10.myOrder.activateRouter.img.group' | translate) }}"
							alt="desconecta el cable coaxial"
						/>
						<div class="vfes-align--left">
							<ol class="mvf-activate-router__ordered-list vfes-pl mvf-activate-router__font-small" start="2">
								<li class="vfes-pl- vfes-pr-">
									<span class="vfes-title--bold mva10-u--body">
										<strong>
											{{ 'v10.myOrder.activateRouter.activateRouterStep1Instruction2Title' | translate }}
										</strong>
									</span>

									<span class="vfes-title--regular mva10-u--body">
										{{ 'v10.myOrder.activateRouter.activateRouterStep1Instruction2Description' | translate }}
									</span>
								</li>
							</ol>
						</div>
					</div>

					<div class="mvf-activate-router__display vfes-pb">
						<img
							class="mvf-activate-router__img3 vfes-pb+ vfes-pr"
							src="{{ appService.getImgFullPath('v10.myOrder.activateRouter.img.group_2' | translate) }}"
							alt="desconecta el cable Ethernet"
						/>
						<div class="vfes-align--left">
							<ol class="mvf-activate-router__ordered-list vfes-pl mvf-activate-router__font-small" start="3">
								<li class="vfes-pl- vfes-pr-">
									<span class="vfes-title--bold mva10-u--body">
										<strong>
											{{ 'v10.myOrder.activateRouter.activateRouterStep1Instruction3Title' | translate }}
										</strong>
									</span>

									<span class="vfes-title--regular mva10-u--body">
										{{ 'v10.myOrder.activateRouter.activateRouterStep1Instruction3Description' | translate }}
									</span>
								</li>
							</ol>
						</div>
					</div>

					<div class="mvf-activate-router__display">
						<img
							class="mvf-activate-router__img4 vfes-pb+ vfes-pr"
							src="{{ appService.getImgFullPath('v10.myOrder.activateRouter.img.group_4' | translate) }}"
							alt="desconecta el cable USB"
						/>
						<div class="vfes-align--left">
							<ol class="mvf-activate-router__ordered-list vfes-pl mvf-activate-router__font-small" start="4">
								<li class="vfes-pl- vfes-pr-">
									<span class="vfes-title--bold mva10-u--body">
										<strong>
											{{ 'v10.myOrder.activateRouter.activateRouterStep1Instruction4Title' | translate }}
										</strong>
									</span>

									<span class="vfes-title--regular mva10-u--body">
										{{ 'v10.myOrder.activateRouter.activateRouterStep1Instruction4Description' | translate }}
									</span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
	</div>

	<div body id="page2">
		<div class="vfes-display--inline-block mvf-activate-router__second-step vfes-pt vfes-pl-">
			<ol class="mvf-activate-router__ordered-list vfes-title--bold vfes-pb-">
				<li>
					<div class="vfes-pb- mva10-u--body vfes-title--bold">
						<strong> {{ 'v10.myOrder.activateRouter.activateRouterStep2Instruction1Title' | translate }} </strong>
					</div>

					<span class="vfes-title--regular mva10-u--body">
						{{ 'v10.myOrder.activateRouter.activateRouterStep2Instruction1Description' | translate }}
					</span>

					<img
						class="mvf-activate-router__img vfes-pb+ vfes-pt+"
						src="{{ appService.getImgFullPath('v10.myOrder.activateRouter.img.conectarouter1' | translate) }}"
						alt="cable de fibra"
					/>
				</li>

				<li>
					<div class="vfes-pb- mva10-u--body vfes-title--bold">
						<strong> {{ 'v10.myOrder.activateRouter.activateRouterStep2Instruction2Title' | translate }} </strong>
					</div>

					<span class="vfes-title--regular mva10-u--body">
						{{ 'v10.myOrder.activateRouter.activateRouterStep2Instruction2Description' | translate }}
					</span>

					<img
						class="mvf-activate-router__img vfes-pb+ vfes-pt+"
						src="{{ appService.getImgFullPath('v10.myOrder.activateRouter.img.conectarouter2' | translate) }}"
						alt="conexión ethernet"
					/>
				</li>

				<li>
					<div class="vfes-pb- mva10-u--body vfes-title--bold">
						<strong> {{ 'v10.myOrder.activateRouter.activateRouterStep2Instruction3Title' | translate }} </strong>
					</div>

					<span class="vfes-title--regular mva10-u--body">
						{{ 'v10.myOrder.activateRouter.activateRouterStep2Instruction3Description' | translate }}
					</span>

					<img
						class="mvf-activate-router__img vfes-pb+ vfes-pt+"
						src="{{ appService.getImgFullPath('v10.myOrder.activateRouter.img.conectarouter3' | translate) }}"
						alt="cable de alimentación"
					/>
				</li>
			</ol>
		</div>
		<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
	</div>

	<div body id="page3">
		<div class="vfes-align--center mva10-u--mb16x">
			<img
				class="mvf-activate-router__img vfes-pb+ vfes-pt+"
				src="{{ appService.getImgFullPath('v10.myOrder.activateRouter.img.activarrouter' | translate) }}"
				alt="router"
			/>
			<h2 class="vfes-title mva10-u-heading--2 mvf-activate-router__h2-mb">
				{{ 'v10.myOrder.activateRouter.activateRouterStep3Title' | translate }}
			</h2>
			<span class="vfes-title--regular mva10-u--body">
				{{ 'v10.myOrder.activateRouter.activateRouterStep3Description' | translate }}
			</span>
		</div>
		<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
	</div>

	<div body id="page4">
		<div class="vfes-align--center mvf-activate-router__confirm-feedback" *ngIf="!showSpinner">
			<img
				class="mvf-activate-router__img5 vfes-pb+ vfes-pt+"
				[src]="
					(showErrorView ? 'v10.myOrder.responseBox.img.alert' : 'v10.myOrder.activateRouter.img.conectarouter1')
						| imgFullPath
				"
				alt="check"
				id="page4_img"
			/>
			<h2 class="vfes-title mva10-u-heading--2 mvf-activate-router__h2-mb">
				{{
					(showErrorView
						? 'v10.myOrder.activateRouter.errorDateTitle'
						: 'v10.myOrder.activateRouter.newRouterActivated'
					) | translate
				}}
			</h2>
			<p class="vfes-title--regular mva10-u--body">
				{{
					(showErrorView
						? 'v10.myOrder.activateRouter.errorSubtitle'
						: 'v10.myOrder.activateRouter.activateRouterProcessTitle'
					) | translate
				}}
			</p>
			<p class="vfes-title--regular mva10-u--body" *ngIf="showErrorView">
				{{ 'v10.myOrder.activateRouter.errorSubtitle2' | translate }}
			</p>
			<div class="mvf-activate-router__confirm-feedback-buttons vfes-mt">
				<mva10-c-button (mva10OnClick)="closeGuideOverlay()">
					{{ 'v10.myOrder.activateRouter.understood' | translate }}
				</mva10-c-button>
			</div>
		</div>
		<div class="vfes-align--center mvf-activate-router__loading" *ngIf="showSpinner">
			<sp-loader [showSpinner]="showSpinner"> </sp-loader>
		</div>
	</div>
</vfac-overlay>

<ng-template #navigationButtonsTemplate>
	<!-- Navigation buttons -->
	<div class="mvf-activate-router__button-top mvf-activate-router__p16 vfes-align--center mva10-u--pb4x">
		<mva10-c-button
			(mva10OnClick)="stepsData.length > 2 && selectedStep === stepsData.length - 1 ? activateRouter() : goForwards()"
		>
			{{
				(selectedStep === stepsData.length - 1
					? stepsData.length > 2
						? 'v10.myOrder.activateRouter.activateRouterStep3ActivateDevice'
						: 'v10.myOrder.activateRouter.close'
					: 'v10.myOrder.activateRouter.next'
				) | translate
			}}
		</mva10-c-button>
	</div>
</ng-template>
