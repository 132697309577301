<article class="vfes-card vfes-card-border-radius vfes-colours-bg-vodafone-white vfes-card-no-border vfes-mt">
	<div class="mvf-collapse {{ color ? 'mvf-collapse--' + (color || 'neutro') : '' }}" [ngClass]="{ open: isCollapsed }">
		<header>
			<div class="mvf-collapse__bottom-border">
				<div class="boxStatusOrders" id="{{ 'myOrdersSpCollapseDescrpBoxStatusContainer' + orderData.numeroPedido }}">
					<strong
						class="titleBoxStatusOrders mvf-collapse__title-text mva10-u-heading--5-bd"
						id="{{ 'myOrdersSpCollapseDescrpBoxStatusTitle' + orderData.numeroPedido }}"
						>{{ 'v10.myOrder.steps.status' | translate }}</strong
					>
					<strong
						id="{{ 'myOrdersSpCollapseDescrpBoxStatusInProgress' + orderData.numeroPedido }}"
						*ngIf="orderData.statePedido === stateInProgress"
						class="descrpBoxStatusInProgress mvf-collapse__title-text mva10-u-heading--5-bd"
						>{{ inProgress }}</strong
					>
					<strong
						id="{{ 'myOrdersSpCollapseDescrpBoxStatusCompleted' + orderData.numeroPedido }}"
						*ngIf="orderData.statePedido === stateCompleted"
						class="descrpBoxStatusCompleted mvf-collapse__title-text mva10-u-heading--5-bd"
						>{{ completed }}</strong
					>
					<strong
						id="{{ 'myOrdersSpCollapseDescrpBoxStatusReturn' + orderData.numeroPedido }}"
						*ngIf="orderData.statePedido === stateReturn"
						class="descrpBoxStatusReturn mvf-collapse__title-text mva10-u-heading--5-bd"
						>{{ return }}</strong
					>
					<strong
						id="{{ 'myOrdersSpCollapseDescrpBoxStatusCancelled' + orderData.numeroPedido }}"
						*ngIf="orderData.statePedido === stateCancelled"
						class="descrpBoxStatusCancelled mvf-collapse__title-text mva10-u-heading--5-bd"
						>{{ cancelled }}</strong
					>
				</div>
				<div class="mvf-collapse__header">
					<div class="mvf-collapse__header--image">
						<svg
							class="vfes-alert-icon icon"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							width="24px"
							height="24px"
						>
							<title>{{ 'v10.myOrder.collapse.error' | translate }}</title>
							<use class="icon-v3" [attr.xlink:href]="'#' + icon"></use>
						</svg>
					</div>
					<!-- TITULO Y DIRECCION -->
					<div class="mvf-collapse__title-container">
						<h3 class="mvf-collapse__title">
							<span class="mvf-collapse__title-text mva10-u-heading--5-bd" title="{{ title }}">
								{{ orderData.nombreComercial || orderData.descripcion || title }}
							</span>
						</h3>
						<div class="mvf-collapse__info-container">
							<div class="mvf-collapse__sim-info mva10-u--footnote" *ngIf="orderData.infoSim">
								<div class="mvf-collapse__sim-info--sim"><strong>SIM:</strong> {{ orderData.infoSim.sim || '-' }}</div>
								<div class="mvf-collapse__sim-info--pinpuk">
									<span class="mvf-collapse__sim-info--pin">
										<strong>PIN:</strong> {{ orderData.infoSim.pin || '-' }}
									</span>
									<span class="mvf-collapse__sim-info--separator"> | </span>
									<span class="mvf-collapse__sim-info--puk">
										<strong>PUK:</strong> {{ orderData.infoSim.puk || '-' }}
									</span>
								</div>
							</div>
							<span class="mvf-collapse__subtitle mva10-u--body">
								{{ orderData.nombreTienda }}
							</span>
						</div>
					</div>
				</div>
				<!-- WARN BOX -->
				<section class="mvf-collapse__excepcion-mobile" *ngIf="orderData.mnsjExcepcion">
					<sp-warn-box [text]="orderData.mnsjExcepcion"> </sp-warn-box>
				</section>
				<!-- FIN WARN BOX -->
				<!-- MENSAJE FIJO -->
				<div
					class="mvf-collapse__excepcion-mobile"
					*ngIf="orderData.mnsjFijo && orderData.nombreFlujo == 'FLUJO_GENERICO'"
				>
					<sp-warn-box [messages]="[orderData.mnsjFijo]" [fijo]="true"> </sp-warn-box>
				</div>
				<!-- FIN MENSAJE FIJO -->
			</div>
		</header>

		<section class="mvf-collapse__header-content">
			<div *ngIf="getOrderState('myOrders') && (orderData.fechaSolicitud || orderData.fechaFin)">
				<span
					class="mvf-collapse__item-title mva10-u--body"
					*ngIf="orderData.statePedido === stateInProgress"
					id="myOrdersSpCollapseDateTitleInProgress"
				>
					{{ 'v10.myOrder.collapse.requestDate' | translate }}
				</span>
				<span
					class="mvf-collapse__item-title mva10-u--body"
					*ngIf="orderData.statePedido === stateCompleted"
					id="myOrdersSpCollapseDateTitleCompleted"
				>
					{{ 'v10.myOrder.collapse.finishDate' | translate }}
				</span>
				<span id="myOrdersSpCollapseDateSpanMyOrders" class="mvf-collapse__item-desc mva10-u--body-bd">
					<strong *ngIf="orderData.statePedido === stateInProgress" id="myOrdersSpCollapseDateDescInProgress">
						{{
							orderData.fechaSolicitud?.includes(forWardSlash)
								? (orderData.fechaSolicitud | formatDate : formatDay : formatDate) +
								  ofString +
								  (orderData.fechaSolicitud | formatDate : formatMonth : formatDate)
								: (orderData.fechaSolicitud | formatDate : formatDay : formatX) +
								  ofString +
								  (orderData.fechaSolicitud | formatDate : formatMonth : formatX)
						}}
					</strong>
					<strong *ngIf="orderData.statePedido === stateCompleted" id="myOrdersSpCollapseDateDescCompleted">
						{{
							orderData.fechaFin?.includes(forWardSlash)
								? (orderData.fechaFin | formatDate : formatDay : formatDate) +
								  ofString +
								  (orderData.fechaFin | formatDate : formatMonth : formatDate)
								: (orderData.fechaFin | formatDate : formatDay : formatX) +
								  ofString +
								  (orderData.fechaFin | formatDate : formatMonth : formatX)
						}}
					</strong>
				</span>
			</div>

			<div *ngIf="getOrderState(returnTabs) && orderData.fechaFin">
				<span class="mvf-collapse__item-title mva10-u--body" id="myOrdersSpCollapseDateTitleReturns">
					{{ 'v10.myOrder.collapse.finishDate' | translate }}
				</span>
				<span id="myOrdersSpCollapseDateSpanReturn" class="mvf-collapse__item-desc mva10-u--body-bd">
					<strong *ngIf="orderData.fechaFin" id="myOrdersSpCollapseDateDescReturns">
						{{
							orderData.fechaFin?.includes(forWardSlash)
								? (orderData.fechaFin | formatDate : formatDay : formatDate) +
								  ofString +
								  (orderData.fechaFin | formatDate : formatMonth : formatDate)
								: (orderData.fechaFin | formatDate : formatDay : formatX) +
								  ofString +
								  (orderData.fechaFin | formatDate : formatMonth : formatX)
						}}
					</strong>
				</span>
			</div>

			<div *ngIf="getOrderState('canceled') && orderData.fechaCancelacion" id="myOrdersSpCollapseDateTitleCancelled">
				<span class="mvf-collapse__item-title mva10-u--body">
					{{ 'v10.myOrder.collapse.closeDate' | translate }}
				</span>
				<span id="myOrdersSpCollapseDateSpan" class="mvf-collapse__item-desc mva10-u--body-bd">
					<strong id="myOrdersSpCollapseDateDetail">
						{{
							orderData.fechaCancelacion?.includes(forWardSlash)
								? (orderData.fechaCancelacion | formatDate : formatDay : formatDate) +
								  ofString +
								  (orderData.fechaCancelacion | formatDate : formatMonth : formatDate)
								: (orderData.fechaCancelacion | formatDate : formatDay : formatX) +
								  ofString +
								  (orderData.fechaCancelacion | formatDate : formatMonth : formatX)
						}}
					</strong>
				</span>
			</div>
		</section>

		<div
			id="myOrdersSpCollapseBreakdownsContainer"
			*ngIf="
				orderData?.nombreFlujo?.toLowerCase() === flow24.toLowerCase() ||
				orderData?.nombreFlujo?.toLowerCase() === flow25.toLowerCase()
			"
		>
			<hr />
			<div class="breakDowns-section" id="myOrdersSpCollapseBreakdownsContainerBox">
				<img
					id="myOrdersSpCollapseBreakdownsImage"
					class="imgControlCollapse"
					src="{{ 'v10.myOrder.collapse.Breakdowns.img' | imgFullPath }}"
					alt="control gen1"
				/>
				<span class="mvf-collapse__item-title mva10-u--body desc-breakdowns" id="myOrdersSpCollapseBreakdownsDesc">
					{{ 'v10.myOrder.collapse.Breakdowns.desc' | translate }}
				</span>
				<div class="button-content" id="myOrdersSpCollapseBreakdownsButtonContainer">
					<mva10-c-button (mva10OnClick)="goBreakdowns()" [styleClass]="'mva10-c-button--secondary'" [small]="true">
						<p class="extra-padding" id="myOrdersSpCollapseBreakdownsTitleButton">
							{{ 'v10.myOrder.collapse.Breakdowns.button' | translate }}
						</p>
					</mva10-c-button>
				</div>
			</div>
		</div>

		<!-- TIPO DE SERVICIO -->
		<!-- ******  SIN VISUALIZAR ESTE DATO OJO ******* -->
		<!-- ******  SIN VISUALIZAR ESTE DATO OJO ******* -->
		<!-- ******  SIN VISUALIZAR ESTE DATO OJO ******* -->
		<!-- ******  SIN VISUALIZAR ESTE DATO OJO ******* -->
		<span class="mvf-collapse__order-service" *ngIf="orderData.tipoServicio">
			{{ orderData.tipoServicio }}
		</span>
		<!-- ******  SIN VISUALIZAR ESTE DATO OJO ******* -->
		<!-- ******  SIN VISUALIZAR ESTE DATO OJO ******* -->
		<!-- ******  SIN VISUALIZAR ESTE DATO OJO ******* -->
		<!-- ******  SIN VISUALIZAR ESTE DATO OJO ******* -->

		<!-- FIN TIPO DE SERVICIO -->

		<!-- DETAIL BUTTON -->
		<span class="mvf-collapse__nav-icon" *ngIf="btnDetalle">
			<sp-button
				(click)="next()"
				[parentClass]="'mvf-button--closeable'"
				*ngIf="
					(!orderData.pedidoTol ||
						orderData.pedidoTol == 2 ||
						(orderData.pedidoTol && orderData.statePedido === '3')) &&
					orderData.nombreFlujo != 'FLUJO_GENERICO'
				"
			>
				<span class="mvf-button__text mva10-u--body"> {{ verDetalle }} </span>
				<span class="mvf-button__icon arrow-down"> </span>
			</sp-button>
		</span>
		<!-- FIN DETAIL BUTTON -->
	</div>
</article>
