<div class="parent-div">
	<div class="delivery-types">
		<div class="delivery-types_head" *ngIf="deliveryTypes?.length > 1">
			<p>
				<b> {{ 'v10.dashboard.onePlus.shippingProcess.choosemode' | translate }} </b>
			</p>
			<div class="flex">
				<div
					*ngFor="let type of deliveryTypes"
					class="selector"
					[ngClass]="{ selected: type.selected }"
					(click)="onChangeMode(type)"
				>
					<img [src]="type.icon" />
					<p class="rate-container-title size-16" [ngClass]="{ 'text-bold': type.selected }">
						{{ type.title }}
					</p>
					<div class="rate-container-title size-14" *ngIf="type.price > 0 && (daysPassed > 30 || daysPassed === -1)">
						{{ type.priceTax | priceToShow }}
					</div>
					<div class="rate-container-title size-14" *ngIf="type.price === 0 || (daysPassed > 0 && daysPassed <= 30)">
						{{ free }}
					</div>
				</div>
			</div>
		</div>
		<div class="delivery-types_head" *ngIf="deliveryTypes?.length === 1">
			<p class="strong">
				{{ selected.title }}
				<span class="price" *ngIf="selected.price > 0">
					{{ selected.priceTax | priceToShow }}
				</span>
				<span class="price" *ngIf="selected.price === 0"> {{ free }} </span>
			</p>
		</div>
		<div *ngIf="isShop" class="delivery-types_postal">
			<form>
				<div>
					<mva10-c-text-field
						[withIcon]="showTextIcon"
						[nameInput]="'nameInput'"
						(ngModelInputChange)="textChanged($event)"
						[error]="inputError || notFoundError || impositiveError || submitError"
						[invalid]="true"
						[limit]="5"
						[textLabel]="postalCodeText"
						[helperText]="inputHelper"
						[icon]="'location'"
						[titleIcon]="postalCodeText"
						[idSpan]="'idSpan'"
					>
					</mva10-c-text-field>
					<div class="lds-ring" *ngIf="loadingShops">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
				<div *ngIf="availableStores" class="stores">
					<div *ngFor="let store of availableStores" [ngClass]="store.checked ? 'store-card' : 'store-card-disabled'">
						<div class="store-card-radio">
							<mat-radio-button [checked]="store.checked" (change)="changeStore(store)"> </mat-radio-button>
						</div>
						<div class="stores-info">
							<p>
								<b> {{ store.nombreTienda }} </b>
							</p>
							<p>{{ store.direccion }}</p>
							<p>
								{{ store.localidad }}, {{ store.provincia }},
								{{ store.codigoPostal }}
							</p>
						</div>
						<div class="store-card-distance" [ngClass]="{ 'store-card-distance-bold': store.checked }">
							A {{ store.distanciaPuntoRef | number : '1.0-2' }} Km
						</div>
					</div>
				</div>
			</form>
		</div>
		<div class="delivery-types_middle" *ngIf="isHome">
			<div [innerHtml]="selected.description"></div>
			<p class="delivery-types_address">{{ formatedAddress }}</p>
			<div class="deliver-div">
				<div class="deliver-div-margin">
					<img class="deliver-div-image" [src]="boxImage" />
				</div>
				<div>
					<p>{{ 'v10.dashboard.onePlus.shippingProcess.home.date' | translate }}</p>
					<p>{{ minDate | date : 'd/M/yyyy' }} - {{ maxDate | date : 'd/M/yyyy' }}</p>
				</div>
			</div>
		</div>
		<div class="calendar-div" *ngIf="availableStores">
			<mva10-c-text-field
				[withIcon]="true"
				[(ngModelInput)]="dateToShow"
				[invalid]="true"
				[disabled]="true"
				[textLabel]="'Fecha'"
				(click)="calendar = true"
				[styleClass]="'calendar-div-text'"
				[helperText]="pickUpText"
				[icon]="'calendar'"
			>
			</mva10-c-text-field>
			<div class="mva-calendar" *ngIf="calendar">
				<mva10-c-calendar
					[highlighted]="startDate"
					[weekOffset]="1"
					[locale]="'es-ES'"
					(selectDay)="onDateChange($event)"
					[eligibleDates]="fechas"
				>
				</mva10-c-calendar>
			</div>
		</div>
		<ng-content> </ng-content>
	</div>
</div>
