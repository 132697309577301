import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartPayComponent } from './smart-pay.component';
import { SmartPayRoutingModule } from './smart-pay-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CoreModule, GenericModule, OverlayFullScreenModule } from '@mva10/mva10-angular';
import { SmartPayManagementComponent } from './smart-pay-management/smart-pay-management.component';
import { SmartPayInfoCardComponent } from './smart-pay-info-card/smart-pay-info-card.component';
import { SmartPayPaymentCardComponent } from './smart-pay-payment-card/smart-pay-payment-card.component';
import { SmartPayFooterComponent } from './smart-pay-footer/smart-pay-footer.component';

@NgModule({
	declarations: [
		SmartPayComponent,
		SmartPayManagementComponent,
		SmartPayInfoCardComponent,
		SmartPayPaymentCardComponent,
		SmartPayFooterComponent,
	],

	imports: [CommonModule, CoreModule, GenericModule, SharedModule, OverlayFullScreenModule, SmartPayRoutingModule],
})
export class SmartPayModule {}
