import { PaymentFullTrayModel, CustomStyle } from '../../../models/payment';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentTrayConfigPostPaid } from './payment-tray-config-postPaid';
import { PaymentTrayConfigInterface } from './payment-tray-config-interface';
import { PaymentNavigationService } from '../payment-navigation.service';
import { DelegateCreationService } from '../delegate-creation.service';
import { TranslateService } from '@ngx-translate/core';
import { PaymentWalletService } from 'src/app/shared/services/payment-wallet.service';
import { PaymentJourny } from 'src/app/shared/enums/payment-journy.enum';
import { overlayTitleSelector, overlayTitlecustomStyle } from 'src/app/shared/constants/defines';

export class PaymentTrayConfigPaymentCommitment
	extends PaymentTrayConfigPostPaid
	implements PaymentTrayConfigInterface
{
	// this component is treated like an ovrelay we remove tem from history after navigating away from them
	// not to show them to the user accidentaly when he navigate back
	compnentToBeRemovedFromHistoryAfternavigation: PaymentPages[] = [
		PaymentPages.cancel,
		PaymentPages.loading,
		PaymentPages.deleteConfiramtion,
	];
	/** variable to be used for getting a tray config method based on component name */
	trayConfigurations: Record<string, Function> = {
		[PaymentPages.thirdPartyContainer]: this.getIframeCompTrayConfig.bind(this),
		[PaymentPages.ok]: this.getOkCompTrayConfig.bind(this),
		[PaymentPages.ko]: this.getKoCompTrayConfig.bind(this),
		[PaymentPages.cancel]: this.getCancelCompTrayConfig.bind(this),
		[PaymentPages.wallet]: this.getWalletCompTrayConfig.bind(this),
		[PaymentPages.loading]: this.getLoadingCompTrayConfig.bind(this),
		[PaymentPages.ticket]: this.getTicketCompTrayConfig.bind(this),
		[PaymentPages.deleteConfiramtion]: this.getDeleteCardCompTrayConfig.bind(this),
		[PaymentPages.dept]: this.getDeptCompTrayConfig.bind(this),
		[PaymentPages.partialDept]: this.getDeptPartialCompTrayConfig.bind(this),
		[PaymentPages.partialCashDesk]: this.getCashDeskPartialCompTrayConfig.bind(this),
		[PaymentPages.inadvance]: this.getInadvanceCompTrayConfig.bind(this),
		[PaymentPages.cashDesk]: this.getCashDeskCompTrayConfig.bind(this),
		[PaymentPages.otherPaymentMethods]: this.getOtherPaymentMethodsCompTrayConfig.bind(this),
	};
	constructor(
		public paymentNavService: PaymentNavigationService,
		public delegateService: DelegateCreationService,
		public translate: TranslateService,
		public paymentWalletService: PaymentWalletService
	) {
		super(paymentNavService, delegateService, translate, paymentWalletService);
	}
	/**
	 * @param compName the page we need it's tray configration
	 * function should return PaymentFullTrayModel containing
	 * the proper tray configration
	 */
	getCurrentTrayConfig(compName: PaymentPages): PaymentFullTrayModel {
		/** if compName is exist in trayConfigurations then invoke its method to get tray configuration */
		return this.trayConfigurations[compName] ? this.trayConfigurations[compName]() : this.getIframeCompTrayConfig();
	}

	getDeptCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate.get('v10.payment.itemsList.paymentCommitment.es.paymentPromise.title').subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data;
		});
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.paymentNavService.showCancelPayment();
		};
		/**
		 * Using the WCS Dept Journey keys because no changes needed in all WCS in the journey
		 * Incase new changes is added in the journey a new WCS has to be requested with all parameters
		 */
		this.paymentNavService.WCSJourneyKey = PaymentJourny.postDeptTotal;
		paymentFullTrayModel.MVA10Config.showBackButton = false;
		paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = false;
		paymentFullTrayModel.paymentCustomConfig.customStyles.push(
			new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle)
		);
		return paymentFullTrayModel;
	}
}
