export class CurrentConsumptionData {
	type: string;
	description: string;
	amount: number;
	quantity: number;
	destination: string;
	unit: string;
	timePeriod: TimePeriod;
	icon: string;
	code: string;
}

export class TimePeriod {
	startDate: string;
	endDate: string;
	duration: number;
}

export class TotalizedCurrentConsumption {
	description: string;
	formattedTotal?: string;
	totalAmount?: number;
	amountUnits?: string;
	totalPrice?: number;
}
