export class Promotion {
	public id?: string;
	public name?: string;
	public code?: string;
	public fee?: string;
	public feeUnit?: string;
	public feeNumber?: number;
	public status?: string;
	public serviceIds?: string[];
	public activationDate?: string;
	public channelCode?: string;
}
