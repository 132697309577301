<section class="smartPayTopUpSection">
	<div class="smartPayTopUpSection__img">
		<img
			id="smartPayPromoTopUpImg"
			[src]="'v10.productsServices.smartPay.smartPayAfterTopUp.img' | imgFullPath"
			alt="smartPayImage"
		/>
	</div>
	<p id="smartPayPromoTopUpTitle" class="smartPayTopUpSection__title">
		{{ 'v10.productsServices.smartPay.smartPayAfterTopUp.title' | translate }}
	</p>
	<p
		id="smartPayPromoTopUpDesc"
		class="smartPayTopUpSection__desc"
		[innerHTML]="'v10.productsServices.smartPay.smartPayAfterTopUp.description' | translate"
	></p>
	<mva10-c-button
		id="smartPayPromoTopUpBtn"
		(click)="navigateToSmartPay()"
		[styleClass]="'mva10-c-button--primary'"
		[body]="'v10.productsServices.smartPay.smartPayAfterTopUp.btnTitle' | translate"
		[title]="'v10.productsServices.smartPay.smartPayAfterTopUp.btnTitle' | translate"
		class="smartPayTopUpSection__btn"
	>
	</mva10-c-button>
</section>
