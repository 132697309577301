<div class="overlaycontainer" [ngStyle]="{ height: pageHeight }" id="fullOverlayContainerID">
	<div class="overlaycontainer__img-container" id="fullOverlayImageContainer">
		<div #imageContainer>
			<img
				[src]="overLayContent?.imgUrl | imgFullPath"
				id="fullOverlayImageSrc"
				alt="img"
				[ngClass]="{ 'fixed-height': fixedImageHeight }"
			/>
			<div class="title" id="fullOverlayImageTitle">
				<p [innerHtml]="overLayContent?.pageTitle" class="title-container" id="fullOverlayImageTitle"></p>
				<span class="icon icon-close" (click)="close()" id="fullOverlayCloseButton"> </span>
			</div>
		</div>
		<div class="label" *ngIf="overLayContent?.hintLabel?.showLabel" id="fullOverlayHindLabel">
			<div class="label-container">
				<div class="text-container">
					<label class=" {{ overLayContent?.hintLabel?.labelClass }} label-text">
						{{ overLayContent?.hintLabel?.labelText }}
					</label>
				</div>
			</div>
		</div>
		<div #pageContent class="page-content-container" id="fullOverlayContentContainer">
			<div class="overlaycontainer__content-container">
				<div class="overlaycontainer__content-container_content">
					<div
						class="overlaycontainer__content-container__title"
						[innerHtml]="overLayContent?.pageSubtitle"
						id="fullOverlayContentSubTitle"
					></div>
					<div
						class="overlaycontainer__mainContent_Description"
						[innerHtml]="overLayContent?.subtitleContent ? overLayContent?.subtitleContent : overlaySubtitleContent"
						id="fullOverlayContentDescription"
					></div>
				</div>
			</div>
			<div
				class="overlaycontainer__mainContent__btnContainer"
				[ngClass]="{ 'shadow-footer': isMobileScrollable && breakpoint === BREAKPOINTS.MOBILE }"
				#footer
			>
				<button
					type="button"
					class="btn"
					(click)="btnClick()"
					id="fullOverlayFooterButton"
					[ngStyle]="{ width: breakpoint === BREAKPOINTS.MOBILE ? '100%' : overLayContent?.btnConfigs?.btnWidth }"
				>
					{{ overLayContent?.btnConfigs?.btnName }}
				</button>
			</div>
		</div>
	</div>
</div>
