import { Pipe, PipeTransform } from '@angular/core';
import { DurationTimeFormat } from '../enums/durationTimeFormat.enum';
/**
 * convert second to a time duration string HH:mm:ss
 * (Please note that this will fail if the seconds exceed 24 hours)
 *
 * @export
 * @class SecondsToTimePipe
 * @implements {PipeTransform}
 */
@Pipe({
	name: 'secondsToTime',
})
export class SecondsToTimePipe implements PipeTransform {
	transform(value: any, format?: DurationTimeFormat): any {
		const strArr = new Date(value * 1000).toISOString().substr(11, 8).split(':');
		const str =
			// duration hours
			(strArr[0] !== '00' ? strArr[0] + ' h ' : format === DurationTimeFormat.ShowZeroHr ? '00 h ' : '') +
			// duration minutes
			(strArr[1] !== '00'
				? strArr[1] + ' min '
				: format === DurationTimeFormat.ShowZeroHr || // Shows duration as 00 h 00 min 00s
				  format === DurationTimeFormat.ShowZeroMin // Shows duration as xx h 00 min 00s
				? '00 min '
				: '') +
			// duration seconds
			(strArr[2] !== '00'
				? strArr[2] + ' s'
				: format === DurationTimeFormat.ShowZeroHr || // Shows duration as 00 h 00 min 00s
				  format === DurationTimeFormat.ShowZeroMin || // Shows duration as xx h 00 min 00s
				  format === DurationTimeFormat.ShowZeroSec // Shows duration as xx h xx min 00s
				? '00 s'
				: '');
		return str;
	}
}
