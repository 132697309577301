import { Component, Input, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { PaymentCancelService } from '../services/payment-cancel.service';
import { PaymentNavigationService } from '../services/payment-navigation.service';

@Component({
	selector: 'sp-payment-delete-card-confiramtion',
	templateUrl: './payment-delete-card-confiramtion.component.html',
	styleUrls: ['./payment-delete-card-confiramtion.component.scss'],
})
export class PaymentDeleteCardConfiramtionComponent implements OnInit {
	@Input() firstBtnAction: Function;
	@Input() secondBtnAction: Function;
	@Input() lastFourDigits: string;
	@Input() isContactListPermissionPage: boolean = false;
	constructor(
		private paymentNavigationService: PaymentNavigationService,
		public paymentCancelService: PaymentCancelService,
		public appService: AppService
	) {}

	ngOnInit() {
		if (!this.isContactListPermissionPage) {
			this.paymentCancelService.fillDeleteCredietCardConfirmationOverlay();
		} else {
			this.paymentCancelService.fillContactListPermissionOverlay();
		}
	}
	/**
	 * the default implmentaion of the first btn in delete card confiramtion screen
	 */
	onFirstBtnAction(): void {
		this.firstBtnAction();
	}
	/**
	 * the default implmentaion of the second btn in delete card confiramtion screen
	 * go back to previous screen (cclist screen)
	 */
	onSecondBtnAction(): void {
		if (this.secondBtnAction) {
			this.secondBtnAction();
		} else {
			this.paymentNavigationService.back();
		}
	}
}
