export enum ContractType {
	valor_bundle = 'VALOR_BUNDLE',
	valor_nc = 'VALOR_NC',
	bundle = 'BUNDLE',
	descuentos_cnc = 'DESCUENTOS_CNC',
	promocion_tv = 'PROMOCION_TV',
	promocion = 'PROMOCION',
	descuento_bundle = 'DESCUENTO_BUNDLE',
	descuentos_nc = 'DESCUENTOS_NC',
	descuento_mixto = 'DESCUENTO_MIXTO',
	cargo_inst = 'CARGO_INST',
	default = 'default',
}
