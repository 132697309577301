import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
	selector: 'sp-toggle-button-mva10',
	templateUrl: './sp-toggle-button-mva10.component.html',
	styleUrls: ['./sp-toggle-button-mva10.component.scss'],
})
export class SpToggleButtonMva10Component implements OnChanges {
	/**event fired when click on check box */
	@Output()
	option: EventEmitter<boolean> = new EventEmitter();
	/**label */
	@Input()
	label = '';
	/**id for checkbox */
	@Input()
	id = '';
	/**name for checkbox */
	@Input()
	name;
	/**flag show if check box checked or not */
	@Input()
	check: boolean;
	@Input()
	disable: boolean;
	@Input()
	stopToggleAnimation: boolean;

	/**
	 * toggles the check that represents the toggle button current value
	 * then emits the value
	 */
	toggle() {
		this.check = !this.check;
		this.option.emit(this.check);
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['check']) {
			this.check = changes['check'].currentValue;
		}
	}
}
