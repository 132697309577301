import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { OverlayModel } from '@mva10/mva10-angular';
import { TicketStatus } from '../../../enums/ticket-status.enum';
import { HistoryStatusDetail } from '../../../models/historyStatusMap.model';
import { HistoricoOL, PedidoHistoricoOLResponse } from '../../../models/pedidoHistoricoOL.model';
import { HistoryOlService } from '../services/history-ol.service';
import { OrdersService } from '../services/orders.service';
import { TicketsService } from '../services/tickets.service';
import { MyOrdersService } from '../services/my-orders.service';
import { Ticket } from '../../../models/autoiTicketResponse.model';
import { TicketType } from '../../../enums/ticket-type.enum';
import { OLStatus } from '../../../enums/ol-status.enum';
import { AppService } from '../../../../app.service';
import { MyOrdersTaggingService } from '../../../services/my-orders-tagging.service';
import { TranslateService } from '@ngx-translate/core';
import { estimatedDateFormat } from '../../../constants/defines';

@Component({
	selector: 'sp-order-history-modal',
	templateUrl: './sp-order-history-modal.component.html',
	styleUrls: ['./sp-order-history-modal.component.scss'],
})
export class SpOrderHistoryModalComponent implements OnInit {
	pageName: string = 'detalle envio';
	showMobileSteps: boolean;
	allowScroll: boolean;
	orderHistory: PedidoHistoricoOLResponse;
	showSpinner: boolean;
	showError: boolean;
	estimatedDate: string;
	shipmentSteps: HistoryStatusDetail[];
	headerTitle: string;
	lastStatusMap: HistoryStatusDetail;
	isLoadingSLA: boolean;
	slaRestError: boolean;
	clientHasOpenTicket: boolean;
	ticketStatus: TicketStatus;
	ticketStatusEnum: typeof TicketStatus = TicketStatus;
	askForReviewOverlayData: OverlayModel;
	showAskForReviewOverlay: boolean;
	isLoadingAskForReview: boolean;
	askForReviewResult: string;
	askForReviewTicketId: string;
	showHistoryOLOverlay: boolean;
	historyOLOverlayData: OverlayModel;
	olStatusEnum: typeof OLStatus = OLStatus;
	@Output() onClose: EventEmitter<string> = new EventEmitter();

	constructor(
		private ordersService: OrdersService,
		private myOrdersService: MyOrdersService,
		readonly ticketsService: TicketsService,
		private olService: HistoryOlService,
		public appService: AppService,
		private tagging: MyOrdersTaggingService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.showHistoryOLOverlay = true;
		this.shipmentSteps = [];
		this.onResize();
		this.loadShipmentHistory();
		this.askForReviewOverlayData = {
			title: this.translate.instant('v10.myOrder.orderHistoryModal.autoiOlSlaModalHeader'),
			isCenterTitle: true,
		};
		this.historyOLOverlayData = {
			title: this.headerTitle,
			isCenterTitle: true,
			hideBackButtonOnAllPages: true,
			isScrollableTray: true,
		};
	}

	doSendView(): void {
		this.resetTagging();
		this.tagging.sendPage(['detalle pedido', this.pageName]);
	}

	resetTagging(): void {
		this.tagging.extendUtagPageName(
			['detalle pedido', this.pageName, 'ticket gestiones pack ok', 'ticket gestiones pack ko'],
			null,
			true
		);
	}

	initStepsTemplate(): void {
		this.showMobileSteps = window.innerWidth < 769;
	}

	@HostListener('window:resize', ['$event'])
	private onResize(): void {
		this.initStepsTemplate();
	}

	loadShipmentHistory(): void {
		this.showSpinner = true;
		this.olService.getOrderHistoryOL(this.ordersService.detailOrderData.numeroPedido).subscribe({
			next: (res) => {
				if (res.historicoOL) {
					this.orderHistory = res;
					this.initShipmentData();
				} else {
					this.showError = true;
				}
				this.showSpinner = false;
				this.doSendView();
			},
			error: () => {
				this.showError = true;
				this.showSpinner = false;
				this.doSendView();
			},
		});
	}

	initShipmentData(): void {
		this.getEstimatedDate();
		this.orderHistory.historicoOL.forEach((historyEvent: HistoricoOL, index: number) => {
			const shipmentData: HistoryStatusDetail = this.olService.getShipmentHistoryStatusDetail(historyEvent);
			shipmentData.date = historyEvent.fecha;
			shipmentData.detailedDescription = historyEvent.descripcion;
			shipmentData.completedStep = true;
			const previousStep: HistoryStatusDetail =
				this.shipmentSteps.length > 0 ? this.shipmentSteps[this.shipmentSteps.length - 1] : null;
			this.initShipmentDataRefact(previousStep, shipmentData);
			if (index + 1 === this.orderHistory.historicoOL.length) {
				this.lastStatusMap = shipmentData;
				this.headerTitle = this.lastStatusMap.statusDescription;
				this.shipmentSteps[this.shipmentSteps.length - 1].currentStep = true;
				this.tagging._extend({ monitoring_purchase_substatus: this.lastStatusMap.statusCode });
			}
		});

		if (this.lastStatusMap.outdatedSLA) {
			this.isLoadingSLA = true;
			this.resetTagging();
			this.ticketsService.getAutoiOpenTickets(TicketType.GESTIONES_PACK).subscribe({
				next: (response: Ticket[]) => {
					this.isLoadingSLA = false;
					this.clientHasOpenTicket = response?.length > 0;
					this.ticketStatus = response?.[0]?.status;
					setTimeout(() => {
						this.tagging.sendInteraction({
							link_type: this.clientHasOpenTicket ? 'con ticket gestiones pack' : 'sin ticket gestiones pack',
						});
					}, 1000);
				},
				error: () => {
					this.isLoadingSLA = false;
					this.slaRestError = true;
					setTimeout(() => {
						this.tagging.sendInteraction({ link_type: 'ko consulta servicio gestiones pack' });
					}, 1000);
				},
			});
		}

		if (
			!this.shipmentSteps.some((step) =>
				[OLStatus.DELIVERED, OLStatus.CANCELLED, OLStatus.EXPIRED].includes(step?.status)
			)
		) {
			// If shipment is not delivered or cancelled, add future steps
			const basicSteps: string[] = ['A1', 'A4', 'G1', 'A7'];
			basicSteps.forEach((basicStep) => {
				const basicStepData: HistoryStatusDetail = this.olService.getShipmentHistoryStatusDetail({ estado: basicStep });
				if (
					!this.shipmentSteps.some(
						(step) =>
							step.status === basicStepData.status ||
							(step.status === OLStatus.PICK_UP && basicStepData.status === OLStatus.SENT)
					)
				) {
					this.shipmentSteps.push(JSON.parse(JSON.stringify(basicStepData)));
				}
			});
		}
	}

	getEstimatedDate(): void {
		const fechaPrimeraHorquilla: string = this.ordersService.detailOrderData.fechaPrimeraHorquilla;
		const fechaSegundaHorquilla: string = this.ordersService.detailOrderData.fechaSegundaHorquilla;

		this.estimatedDate = `${fechaPrimeraHorquilla} ${estimatedDateFormat.mensajeOLNotInfo} ${fechaSegundaHorquilla}`;
	}

	public initShipmentDataRefact(previousStep: HistoryStatusDetail, shipmentData: HistoryStatusDetail): void {
		if ((previousStep && previousStep.step === shipmentData.step) || !shipmentData.step) {
			if (!previousStep.subSteps) {
				previousStep.subSteps = [];
			}
			previousStep.subSteps.push(JSON.parse(JSON.stringify(shipmentData)));
			previousStep.status = shipmentData.status;
		} else {
			this.shipmentSteps.push(JSON.parse(JSON.stringify(shipmentData)));
		}
	}

	onSolveButtonClick(): void {
		this.resetTagging();
		this.tagging.sendInteraction({ link_type: 'click en solucionar' });
	}

	onContactButtonClick(): void {
		this.resetTagging();
		this.tagging.sendInteraction({ link_type: 'click en contactar' });
	}

	onAskForReviewButtonClick(): void {
		this.resetTagging();
		this.tagging.sendInteraction({ link_type: 'click en solicitar revision' });
		if (!this.clientHasOpenTicket) {
			this.isLoadingSLA = true;
			this.isLoadingAskForReview = true;
			this.showAskForReviewOverlay = true;

			this.ticketsService
				.createNewAutoiTicket(
					TicketType.GESTIONES_PACK,
					this.ordersService.detailOrderData.numeroPedido,
					this.myOrdersService.MYORDERS_DATA.idCliente
				)
				.subscribe({
					next: (response: string) => {
						if (response) {
							this.askForReviewResult = 'ok';
							this.askForReviewTicketId = response;
							this.clientHasOpenTicket = true;
							this.ticketStatus = TicketStatus.Open;
							this.tagging.sendPage(['ticket gestiones pack ok']);
						} else {
							this.askForReviewResult = 'error';
							this.clientHasOpenTicket = false;
							this.ticketStatus = null;
							this.tagging.sendPage(['ticket gestiones pack ko']);
						}
						this.isLoadingAskForReview = false;
						this.isLoadingSLA = false;
					},
					error: () => {
						this.askForReviewResult = 'error';
						this.clientHasOpenTicket = false;
						this.ticketStatus = null;
						this.isLoadingAskForReview = false;
						this.isLoadingSLA = false;
						this.tagging.sendPage(['ticket gestiones pack ko']);
					},
				});
		}
	}

	closeOverlay(sendAnalytics: boolean): void {
		this.resetTagging();
		if (sendAnalytics) {
			this.tagging.sendInteraction({ link_type: 'click en contactar' });
		}
		this.showHistoryOLOverlay = false;
		setTimeout(() => {
			this.onClose.emit();
		}, 500);
	}

	ngOnDestroy(): void {
		this.resetTagging();
		this.tagging._extend({ monitoring_purchase_substatus: '' });
		this.tagging.extendUtagPageName([this.pageName], null, true);
	}
}
