import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'sp-background-header-and-icon',
	templateUrl: './background-header-and-icon.component.html',
	styleUrls: ['./background-header-and-icon.component.scss'],
})
export class BackgroundHeaderAndIconComponent {
	@Input() headerBackground: string;
	@Input() headerTitle: string;
	@Input() headerDesc: string;
	@Input() headerIcon: string;
	@Input() inputColor: string;
	@Input() headerIconClose: boolean = false;
	@Output() goBackEvent = new EventEmitter<any>();

	goBack() {
		this.goBackEvent.emit();
	}
}
