<div #parentDiv class="carousel slide">
	<div class="carousel-inner" id="parentSwiper" #parentSwiper>
		<div class="add-width"></div>
		<div
			#image
			*ngFor="let slidez of allSlides; let index = index"
			(click)="select(slidez)"
			[ngClass]="{ add: slidez.addPlusClass }"
			[class.active]="true"
			class="item clicked move {{ slidez.selected }}"
		>
			<div class="image-cropper" [ngClass]="{ slected: slidez.checked }">
				<i *ngIf="!slidez.svgPath" class="icon" [ngClass]="slidez.icon"> </i>
				<svg-icon class="svg-icon" [src]="slidez?.svgPath || ''" [svgStyle]="{ 'width.px': 90 }"> </svg-icon>
			</div>
			<div class="item-content">
				<input
					id="SpSwiperComp_rdo_slidez_checked"
					type="radio"
					name="service"
					class="radio"
					[disabled]="slidez.radioDisabled"
					[checked]="slidez.checked"
				/>
				<div class="check" [ngClass]="{ 'checked-slide': slidez.checked }"></div>
			</div>
			<div [ngClass]="{ 'font-bigger': slidez.checked }" class="desc small-font">
				<label [style.visibility]="slidez.checked ? 'visible' : 'hidden'"> {{ slidez.text }} </label>
				<label
					*ngIf="checkType(slidez.type)"
					[style.visibility]="slidez.checked ? 'visible' : 'hidden'"
					class="service-id"
				>
					{{ slidez.id }}
				</label>
			</div>
		</div>

		<div
			*ngIf="showAdd && forceShowAddBtn"
			#image
			(click)="add()"
			[class.active]="true"
			[ngClass]="defaultSelected"
			class="item clicked move"
		>
			<div class="image-cropper">
				<svg-icon class="svg-icon" [src]="icon_addOne" [svgStyle]="{ 'width.px': 90 }"> </svg-icon>
			</div>
			<div *ngIf="addService">
				<label class="plus-span">Ver otras</label>
			</div>
		</div>
		<div class="add-width"></div>
	</div>
</div>

<sp-service-selector-modal
	[userProfile]="storage.userProfile"
	[customerAccounts]="customerAccountService.customerAccounts"
	#fullModal
>
</sp-service-selector-modal>
