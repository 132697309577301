import { Component, OnInit, ViewChild } from '@angular/core';
import { SpModalComponent } from '../sp-modal/sp-modal.component';
import { CampaignService } from './campaign-overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { tagging } from '../../../../config/tagging-config';
import { ErrorHandlingService } from '../../../core/services/errorHandling.service';
import { PAGES, no } from '../../constants/defines';
import { CrossFunctionalityService } from '../../services/cross-functionality.service';
import { AppService } from '../../../app.service';

@Component({
	selector: 'sp-campaign-overlay',
	templateUrl: './campaign-overlay.component.html',
	styleUrls: ['./campaign-overlay.component.scss'],
})
export class CampaignOverlayComponent implements OnInit {
	@ViewChild('campaignModal', { static: true }) campaignModal: SpModalComponent;
	close: boolean;
	campaign;
	WCS: any;

	constructor(
		private CampaignService: CampaignService,
		public cross: CrossFunctionalityService,
		public translate: TranslateService,
		private taggingService: TaggingHelperService,
		private errorHandling: ErrorHandlingService,
		public appService: AppService
	) {}

	ngOnInit() {
		this.loadWcs();
		this.campaign = this.CampaignService.currentCampaign;
		if (this.campaign) {
			this.campaign.modules.permissions.filter((permission) => {
				// If a permission status is already enabled, filter this permission out from the overlay
				return !permission.status;
			});
			if (
				this.campaign.modules.permissions.every((permission) => {
					// If all permissions in a given campaign are already enabled, do not show the campaign overlay
					return permission.status;
				})
			) {
				this.CampaignService.passToTarget();
			}

			const view = this.getCampaignView();
			this.taggingService.sendViewState(view);
			this.taggingService.view(view);
		} else {
			this.CampaignService.passToTarget();
		}
	}

	loadWcs() {
		this.translate.get('common.messagesList.cvmCampaign').subscribe((data) => {
			if (data) {
				this.WCS = data;
				this.translate.get('common.buttonList').subscribe((data) => {
					this.WCS.continue = data ? data.continue : null;
				});
			}
		});
		this.translate.get('common.itemsList').subscribe((data) => {
			if (data) {
				this.WCS.accept = data.accept ? data.accept.body : null;
				this.WCS.noAccept = data.noAccept ? data.noAccept.body : null;
			}
		});
	}

	changeCTAStyle() {
		// collect all permissions in array
		if (this.campaign && this.campaign.blocker) {
			// blocker
			// -- all permission must be true
			return this.campaign.modules.permissions.every((el) => {
				return el.status;
			});
		} else {
			// optional
			// -- any permission true
			if (this.campaign && this.campaign.modules) {
				return this.campaign.modules.permissions.some((el) => {
					return el.status;
				});
			}
		}
	}

	toggle(event, permission) {
		permission.status = event;
	}

	onCancel(callback?: Function) {
		if (this.campaign) {
			const eventName = this.getWCSKey(tagging.campaign.close.eventName.replace('<campaign id>', this.campaign.id));
			this.taggingService.track(eventName, tagging.campaign.close.data, this.getCampaignView());
		}
		if (this.campaign && this.campaign.blocker) {
			this.CampaignService.back();
		} else {
			this.saveAndContinue();
		}
	}

	onGrantPermissions() {
		const eventName = this.getWCSKey(tagging.campaign.save.eventName.replace('<campaign id>', this.campaign.id));
		this.taggingService.track(eventName, tagging.campaign.save.data, this.getCampaignView());
		// this function to send the campaign permission
		const obj = { permissions: [] };
		this.campaign.modules.permissions
			.filter((el) => el.status)
			.map((el: any) => {
				obj.permissions.push(el.name);
			});
		this.CampaignService.sendCampaignPermissions(obj).subscribe(
			(res) => {
				this.CampaignService.passToTarget();
			},
			(error) => {
				const errorMsg = this.errorHandling.lookUpError(PAGES.COMMON, error);
				this.cross.nudgeDescription = errorMsg.description;
				this.cross.nudgeTitle = errorMsg.title;
				this.cross.buttonText = errorMsg.confirmButtonText;
				this.cross.showNudge = true;
				this.CampaignService.sessionStore(this.campaign ? this.campaign.id : null);
				this.CampaignService.passToTarget();
			}
		);
	}

	continue() {
		const eventName = this.getWCSKey(tagging.campaign.continue.eventName.replace('<campaign id>', this.campaign.id));
		this.taggingService.track(eventName, tagging.campaign.continue.data, this.getCampaignView());
		this.saveAndContinue();
	}

	saveAndContinue() {
		this.CampaignService.localStorageSet(this.campaign.id);
		this.CampaignService.passToTarget();
	}

	getWCSKey(value: string) {
		let replacement = '';
		if (this.campaign && !this.campaign.blocker) {
			replacement = no;
		}
		return value.replace('{0}', replacement);
	}

	getCampaignView() {
		const view = Object.assign({}, tagging.campaign.view);
		view.page_name = this.getWCSKey(view.page_name.replace('<campaign id>', this.campaign.id));
		view.page_screen = view.page_screen.replace('<campaign id>', this.campaign.id);
		view.page_screen = view.page_subcategory_level_2.replace('<campaign id>', this.campaign.id);
		view.page_subcategory_level_1 = this.getWCSKey(view.page_subcategory_level_1);
		return view;
	}
}
