import {
	ChangeDetectorRef,
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	OnInit,
	Renderer2,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
} from '@angular/core';
import { removePaddingSelector } from 'src/app/shared/constants/defines';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { PaymentFullTrayModel, PaymentTrayCustomConfig } from '../../models/payment';
import { PaymentModalLeftIcon } from '../../shared/enums/payment-modal-left-icon.enum';
import { PaymentTrayAction } from '../../shared/enums/payment-tray-action.enum';
import { DynamicCompRef } from '../../shared/enums/dynamic-comp-ref.enum';
import { PaymentNavigationService } from '../services/payment-navigation.service';
import { PaymentTrayConfigService } from '../services/payment-tray-config.service';
import { AppService } from '../../app.service';
import { TopUpService } from 'src/app/top-up/top-up.service';
import { CaptureResponseService } from 'src/app/shared/services/capture-response.service';
import { EntryPointsService } from 'src/app/shared/services/entry-points.service';
@Component({
	selector: 'sp-payment-full-dynamic-tray',
	templateUrl: './payment-full-dynamic-tray.component.html',
	styleUrls: ['./payment-full-dynamic-tray.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class PaymentFullDynamicTrayComponent implements OnInit {
	/* dynamic ref is used to push dynamic components inside */
	@ViewChild('dynamicCompRef_1', { read: ViewContainerRef }) dynamicCompRef_1: ViewContainerRef;
	/* dynamic ref is used to push dynamic components inside */
	@ViewChild('dynamicCompRef_2', { read: ViewContainerRef }) dynamicCompRef_2: ViewContainerRef;
	/* vfac overlay ref used to set animation  */
	@ViewChild('overlay', { read: ViewContainerRef }) overlay: ViewContainerRef;
	/* height of the tray */
	pageHeight: string;
	/* the current used ref iside the tray needed to push the component in the other ref */
	currentUsedRef: DynamicCompRef = DynamicCompRef.zero;
	/* boolean to start the forward animation */
	showForwardTransition = false;
	/* boolean to start the backward animation */
	showBackwardTransition = false;
	/* boolean to start the fade out animation */
	fadeOut = false;
	/* subscription is used to re calculate the height of the tray when resize occured  */
	resizeSubscription$: Subscription;
	/** leftIcon: is used for setting back arrow icon in mobile view or vodafone icon in responsive view */
	leftIcon: PaymentModalLeftIcon;
	/** leftIconSize: is used for setting left icon size */
	leftIconSize: number;
	constructor(
		public TrayControl: PaymentTrayConfigService,
		private paymentNavService: PaymentNavigationService,
		private renderer: Renderer2,
		private resolver: ComponentFactoryResolver,
		public appService: AppService,
		private cd: ChangeDetectorRef,
		private topUpService: TopUpService,
		private captureResponse: CaptureResponseService,
		private entryPointService: EntryPointsService
	) {}
	ngOnInit() {
		/* here we are waiting for any compoent to be pushed inside the tray to handle it
    / and check the page height and if resizeSubscription is not defined we should subscirpe
    / again
    */
		this.TrayControl.paymentNavigationSubject.subscribe(
			({ comp, animationOnTranstion, goBack, initComponantOnBack, compIndex }) => {
				this.setLeftIconAndSize();
				this.onDynamicCompChange(comp, compIndex, goBack, animationOnTranstion, initComponantOnBack);
				this.setTrayHeight();
				if (!this.resizeSubscription$) {
					const resizeObservable$: Observable<any> = fromEvent(window, 'resize');
					this.resizeSubscription$ = resizeObservable$.subscribe((evt) => {
						this.setTrayHeight();
					});
				}
			}
		);
		/* when tray is closed we need to reset the component */
		this.paymentNavService.changePaymentCompSubject.subscribe(({ actionNeeded }) => {
			if (actionNeeded === PaymentTrayAction.forceClose) {
				this.onClose();
			}
		});
	}

	/**
	 * @param comp component needed to be push
	 * @param goBack if true this means that we need back enimation for this comp
	 * @param initComponantOnBack  boolean to or not to run ngOnInit of the component
	 * the handler to any request to change the dynamic component the function here decide
	 *  which ref the new component will push to and if we need any animation or not
	 */
	onDynamicCompChange(
		comp: any,
		compIndex: number,
		goBack: boolean,
		animationOnTranstion: boolean = true,
		initComponantOnBack = true
	): void {
		if (!this.currentUsedRef) {
			this.applyChanges(
				this.dynamicCompRef_1,
				comp,
				DynamicCompRef.one,
				compIndex,
				false,
				goBack,
				animationOnTranstion,
				initComponantOnBack
			);
		} else if (this.currentUsedRef === DynamicCompRef.one) {
			this.applyChanges(
				this.dynamicCompRef_2,
				comp,
				DynamicCompRef.two,
				compIndex,
				true,
				goBack,
				animationOnTranstion,
				initComponantOnBack
			);
		} else if (this.currentUsedRef === DynamicCompRef.two) {
			this.applyChanges(
				this.dynamicCompRef_1,
				comp,
				DynamicCompRef.one,
				compIndex,
				true,
				goBack,
				animationOnTranstion,
				initComponantOnBack
			);
		}
	}

	/**
	 * @param viewContainerRef  the viewContainerRef to push the new component inside
	 * @param comp component needed to be push
	 * @param refToUse the ref number to push the new component inside
	 * @param animate boolean to or not to used forward or backward animation
	 * @param goBack boolean to or not to used backward animation
	 * @param initComponantOnBack boolean to or not to run ngOnInit of the component
	 */
	applyChanges(
		viewContainerRef: ViewContainerRef,
		comp: any,
		refToUse: DynamicCompRef,
		compIndex: number,
		animate: boolean = true,
		goBack: boolean,
		animationOnTranstion: boolean,
		initComponantOnBack: boolean
	): void {
		if (animate) {
			this.animation(goBack, animationOnTranstion, refToUse);
		} else {
			this.selectRefApplyTrayConfig(refToUse);
		}
		if (initComponantOnBack) {
			this.createComponent(viewContainerRef, comp, compIndex);
		}
	}

	/**
	 * @param compRef the viewContainerRef to push the new component inside
	 * @param DynamicComp component needed to be push
	 */
	createComponent(compRef: ViewContainerRef, DynamicComp: any, compIndex: number): void {
		const compFactory: ComponentFactory<any> = this.resolver.resolveComponentFactory(DynamicComp);
		compRef.clear();
		const cmpRef: ComponentRef<any> = compRef.createComponent(compFactory);
		if (this.TrayControl.paymentCompHistory[compIndex] && this.TrayControl.paymentCompHistory[compIndex].compData) {
			this.TrayControl.paymentCompHistory[compIndex].compData.forEach((input) => {
				cmpRef.instance[input.key] = input.value;
			});
		}
		this.cd.detectChanges();
	}

	/**
	 * @param goBack boolean to or not to used backward animation
	 * @param currentUsedRef the ref number to push the new component inside
	 * function to seset animation and start either backward or farward animation
	 * for the component
	 */
	animation(goBack = false, animationOnTranstion: boolean, currentUsedRef: DynamicCompRef): void {
		this.resetAnimation();
		const showBckBtn: boolean =
			this.TrayControl.nextTrayConfig.paymentCustomConfig.forceShowBackBtn ||
			(this.TrayControl.nextTrayConfig.MVA10Config.showBackButton && this.TrayControl.paymentCompHistory.length > 1);
		if (!showBckBtn) {
			this.TrayControl.showBackBtn = showBckBtn;
		}
		if (animationOnTranstion) {
			this.fadeOut = true;
			setTimeout(() => {
				this.startTranstion(currentUsedRef, showBckBtn, goBack);
			}, 1500);
		} else {
			this.selectRefApplyTrayConfig(currentUsedRef);
			this.TrayControl.showBackBtn = showBckBtn;
		}
	}

	/**
	 * @param currentUsedRef the ref number to push the new component inside
	 * check the back btn status to show or not
	 * and control the tray animation
	 */
	startTranstion(currentUsedRef: DynamicCompRef, showBckBtn: boolean, goBack: boolean): void {
		this.selectRefApplyTrayConfig(currentUsedRef);
		this.showForwardTransition = !goBack;
		this.showBackwardTransition = goBack;
		this.fadeOut = false;
		this.TrayControl.showBackBtn = showBckBtn;
	}
	/**
	 * @param currentUsedRef the ref number to push the new component inside
	 * function to select the ref
	 * change the cuurent tary configration to used the confogration of the new component
	 */
	selectRefApplyTrayConfig(currentUsedRef: DynamicCompRef): void {
		this.currentUsedRef = currentUsedRef;
		this.TrayControl.currentNotDestroyedComp[currentUsedRef] = this.TrayControl.paymentCompHistory[
			this.TrayControl.paymentCompHistory.length - 1
		]
			? this.TrayControl.paymentCompHistory[this.TrayControl.paymentCompHistory.length - 1].compName
			: DynamicCompRef.zero;
		this.clearPreviousCompCustomStyles(this.TrayControl.currentTrayConfig.paymentCustomConfig);
		setTimeout(() => {
			this.applyCustomStyls(this.TrayControl.nextTrayConfig.paymentCustomConfig);
			this.paymentNavService.hideVodafoneLoader();
		}, 100);
		this.TrayControl.currentTrayConfig = this.TrayControl.nextTrayConfig;
	}

	/**
	 * @param customConfig the new tray configration
	 * used to set the new style classes
	 */
	applyCustomStyls(customConfig: PaymentTrayCustomConfig): void {
		if (customConfig && customConfig.customStyles) {
			customConfig.customStyles.forEach((customStyle) => {
				const selector: any = this.overlay.element.nativeElement.querySelector(customStyle.selector);
				if (selector) {
					this.renderer.addClass(selector, customStyle.className);
				}
			});
		}
	}

	/**
	 * @param customConfig the old tray configration
	 * used to remove the new style classes
	 */
	clearPreviousCompCustomStyles(customConfig: PaymentTrayCustomConfig): void {
		if (customConfig && customConfig.customStyles) {
			customConfig.customStyles.forEach((customStyle) => {
				const selector: any = this.overlay.element.nativeElement.querySelector(customStyle.selector);
				if (selector) {
					this.renderer.removeClass(selector, customStyle.className);
				}
			});
		}
	}

	/**
	 * function used to set the tray height
	 */
	setTrayHeight(): void {
		if (this.overlay.element.nativeElement.querySelector('.vfac-c-overlay__scroll-content')) {
			const height: number = parseInt(
				this.overlay.element.nativeElement.querySelector('.vfac-c-overlay__scroll-content').style.height,
				10
			);
			this.pageHeight = this.pageHeight === height - 32 + 'px' ? this.pageHeight : height - 32 + 'px';
			const selector: any = this.overlay.element.nativeElement.querySelector(removePaddingSelector);
			if (selector) {
				this.renderer.removeStyle(selector, 'min-height');
				if (!this.appService.isResponsiveView) {
					this.renderer.setStyle(selector, 'min-height', height + 'px');
				}
			}
		}
	}

	/**
	 * function to reset component after tray closing
	 */
	onClose(): void {
		setTimeout(() => {
			if (!this.TrayControl.showTray) {
				this.TrayControl.showBackBtn = false;
				this.dynamicCompRef_1.clear();
				this.dynamicCompRef_2.clear();
				this.currentUsedRef = DynamicCompRef.zero;
				this.resetAnimation();
				this.TrayControl.currentTrayConfig = new PaymentFullTrayModel();
				this.TrayControl.nextTrayConfig = new PaymentFullTrayModel();
				if (this.resizeSubscription$) {
					this.resizeSubscription$.unsubscribe();
				}
			}
		}, 500);
	}

	/**
	 * function called when user click on the tray x icon
	 */
	closeOverlay(): void {
		this.TrayControl.close();
		this.sendCaptureResponse();
	}
	sendCaptureResponse(): void {
		if (this.topUpService.isPega && this.topUpService.isSuccessPegaOverlayOpened) {
			this.captureResponse.acceptedCaptureResponsePega(
				this.entryPointService.getEntryPointByCode(this.entryPointService.entryCode)
			);
		}
		this.topUpService.isSuccessPegaOverlayOpened = false;
		this.topUpService.isPega = false;
	}

	/**
	 * function called when user click on the tray back icon
	 */
	back(): void {
		/** Prevent the back action in case of another icon than back arrow is appeared */
		if (this.leftIcon === PaymentModalLeftIcon.backArrow) {
			this.TrayControl.pop();
		}
	}
	/**
	 * function called before any animation to reset every animation booleans
	 */
	resetAnimation(): void {
		this.showForwardTransition = false;
		this.showBackwardTransition = false;
		this.fadeOut = false;
	}
	/**
	 * function for setting back arrow icon in loggedIn journey or vodafone icon in anonymous journey and its size
	 */
	setLeftIconAndSize(): void {
		/** isMobileView should be true in case of mobile view */
		if (this.TrayControl.nextTrayConfig?.MVA10Config?.showVodafoneLogo) {
			this.leftIcon = PaymentModalLeftIcon.vodafne;
		} else if (this.TrayControl.nextTrayConfig?.MVA10Config?.showBackButton) {
			this.leftIcon = PaymentModalLeftIcon.backArrow;
		}
		this.leftIconSize = 24;
	}
}
