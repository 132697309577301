import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { API_URLS } from '../constants/routes-config';
import { throwError } from 'rxjs/internal/observable/throwError';
import { CommercialKeywordsAll, CommercialTerminalKeywordsItems } from '../../models/commercial-terminal.model';

@Injectable()
export class CommercialTerminalKeywordsService {
	constructor(public http: HttpClient) {}

	public getTerminalsKeywords(body: CommercialTerminalKeywordsItems) {
		const url: string = API_URLS.CommercialMobiles.getTerminalsKeywords;

		const headers: HttpHeaders = new HttpHeaders();
		const options: { [key: string]: HttpHeaders } = {
			headers: headers,
		};
		return this.http.post(url, body, options).pipe(
			map((res: CommercialKeywordsAll) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}
}
