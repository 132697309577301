import { PriceToShowPipe } from 'src/app/shared/pipes/price-to-show/price-to-show.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [PriceToShowPipe],
	exports: [PriceToShowPipe],
	imports: [CommonModule],
})
export class PriceToShowModule {}
