export class CanjeModel {
	banner_title: string;
	banner_description: string;
	banner_img: string;
	entryPoint_img: string;
	link_description: string;
	experienceName: string;
	link: string;
	reco_title: string;
	reco_promo: string;
	reco_mobile_1: string;
	reco_mobile_2: string;
	reco_description: string;
	reco_CTA: string;
	reco_CTA_2: string;
	ticket_title: string;
	ticket_description: string;
	reco_mobile_3: string;
}
