<vfac-overlay
	#autoiHelpOverlay
	[overlayData]="autoiHelpData"
	[show]="showOverlayAutoiHelp"
	[loaded]="!isLoading"
	[loaderType]="'vodafone'"
	[greyMode]="true"
	(closed)="onCloseClicked('header')"
	[page]="pageAutoiHelp"
	(backPage)="goBack()"
>
	<div header *ngIf="pageAutoiHelp === 1">
		<div class="title vfes-mt- vfes-title--bold mva10-u--body">
			{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalTitle' | translate }}
		</div>
		<div class="subtitle mva10-u-heading--3-lt">
			{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalSubtitle' | translate }}
		</div>
	</div>
	<div body id="page1">
		<div class="mvf-help-autoi-modal">
			<div class="mvf-help-autoi-modal__content">
				<p class="mvf-help-autoi-modal__intro mva10-u--body">
					{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptions' | translate }}
				</p>
				<div class="mvf-help-autoi-modal__options">
					<div class="mvf-help-autoi-modal__cta-option" *ngIf="ticketsService.autoiHelp?.showWrongCablesOption">
						<div class="mvf-help-autoi-modal__cta-option__desc mva10-u--body" (click)="onWrongCableClick()">
							{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptionCables' | translate }}
						</div>
						<svg class="mvf-help-autoi-modal__cta-option__icon" width="32px" height="32px">
							<title>{{ 'v10.myOrder.helpAutoiModal.next' | translate }}</title>
							<use class="icon-v3" xlink:href="#icon-chevron-right"></use>
						</svg>
					</div>
					<div class="mvf-help-autoi-modal__cta-option" *ngIf="ticketsService.autoiHelp?.showPackageNotReceivedOption">
						<div class="mvf-help-autoi-modal__cta-option__desc mva10-u--body" (click)="onNoOrderReceivedClick()">
							{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptionDelivery' | translate }}
						</div>
						<svg class="mvf-help-autoi-modal__cta-option__icon" width="32px" height="32px">
							<title>{{ 'v10.myOrder.helpAutoiModal.next' | translate }}</title>
							<use class="icon-v3" xlink:href="#icon-chevron-right"></use>
						</svg>
					</div>
					<div class="mvf-help-autoi-modal__cta-option" *ngIf="ticketsService.autoiHelp?.showNotWorkingOption">
						<div class="mvf-help-autoi-modal__cta-option__desc mva10-u--body" (click)="onServiceNotWorkingClick()">
							{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptionService' | translate }}
						</div>
						<svg class="mvf-help-autoi-modal__cta-option__icon" width="32px" height="32px">
							<title>{{ 'v10.myOrder.helpAutoiModal.next' | translate }}</title>
							<use class="icon-v3" xlink:href="#icon-chevron-right"></use>
						</svg>
					</div>
					<div class="mvf-help-autoi-modal__cta-option" *ngIf="ticketsService.autoiHelp?.showChatOption">
						<div class="mvf-help-autoi-modal__cta-option__desc mva10-u--body" (click)="onOpenChatClick()">
							{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptionChat' | translate }}
						</div>
						<svg class="mvf-help-autoi-modal__cta-option__icon" width="32px" height="32px">
							<title>{{ 'v10.myOrder.helpAutoiModal.next' | translate }}</title>
							<use class="icon-v3" xlink:href="#icon-chevron-right"></use>
						</svg>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div body id="page2">
		<div class="technician-appointment">
			<div class="content">
				<div class="image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.helpAutoiModal.img.women' | translate) }}"
						alt="Assistant"
					/>
				</div>
				<div class="title vfes-title--bold mva10-u-heading--4">
					{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptionCablesTitle' | translate }}
				</div>
				<div class="description mva10-u--body">
					{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptionCablesDescription' | translate }}
				</div>
			</div>
			<div class="button-container vfes-mt">
				<mva10-c-button (mva10OnClick)="createNewTechnicianTicket()">
					{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptionCablesButton' | translate }}
				</mva10-c-button>
			</div>
		</div>
	</div>
	<div body id="page3">
		<div class="guide-or-check">
			<div class="content">
				<div class="image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.helpAutoiModal.img.network' | translate) }}"
						alt="Router"
					/>
				</div>
				<div class="title vfes-title--bold mva10-u-heading--4">
					{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptionServiceTitle' | translate }}
				</div>
				<div class="description mva10-u--body">
					{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptionServiceDescription' | translate }}
				</div>
			</div>
			<div class="button-container vfes-mt">
				<mva10-c-button (mva10OnClick)="onCloseClicked('openCheckActivation')">
					{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptionServiceButtonActivate' | translate }}
				</mva10-c-button>
				<mva10-c-button [styleClass]="'mva10-c-button--tertiary vfes-mt-'" (mva10OnClick)="onCloseClicked('openGuide')">
					{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptionServiceButtonGuide' | translate }}
				</mva10-c-button>
			</div>
		</div>
	</div>
	<div body id="page4">
		<div class="ok-page" *ngIf="ticketCreationResult === 'ok'">
			<div class="content">
				<div class="image">
					<img src="{{ appService.getImgFullPath('v10.myOrder.helpAutoiModal.img.checkok' | translate) }}" alt="OK" />
				</div>
				<div class="title vfes-title--bold mva10-u-heading--4">
					{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptionCablesOkTitle' | translate }}
				</div>
				<div class="description mva10-u--body">
					{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptionCablesOkDescription' | translate }}
				</div>
			</div>
			<div class="button-container vfes-mt">
				<mva10-c-button (mva10OnClick)="onCloseClicked('reload')">
					{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptionCablesOkButton' | translate }}
				</mva10-c-button>
			</div>
		</div>
		<div class="ko-page" *ngIf="ticketCreationResult === 'error'">
			<div class="content">
				<div class="image">
					<img [src]="'v10.myOrder.helpAutoiModal.img.alert' | imgFullPath" alt="Alert" />
				</div>
				<div class="title vfes-title--bold mva10-u-heading--4">
					{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptionCablesErrorTitle' | translate }}
				</div>
				<div class="description mva10-u--body">
					{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptionCablesErrorDescription' | translate }}
				</div>
			</div>
			<div class="button-container vfes-mt">
				<mva10-c-button (mva10OnClick)="onCloseClicked('header')">
					{{ 'v10.myOrder.helpAutoiModal.autoiHelpModalOptionCablesErrorButton' | translate }}
				</mva10-c-button>
			</div>
		</div>
	</div>
</vfac-overlay>
