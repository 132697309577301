import { Component, Input } from '@angular/core';

@Component({
	selector: 'sp-spinner-loader',
	templateUrl: './spinner-loader.component.html',
	styleUrls: ['./spinner-loader.component.scss'],
})
export class SpinnerLoaderComponent {
	@Input() spinnerText: string;
}
