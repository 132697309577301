import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, ValidatorFn, AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ANONYMOUS, Validation, debtPaymentType } from '../../../shared/constants/defines';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../app.service';
import { tagging } from '../../../../config/tagging-config';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { CrossVariables } from '../../../models/tagging.model';
import { PaymentCompoentInputData_Keys } from '../../../shared/enums/payment-compoent-input-data-keys.enum';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentJourneyEnvironment } from '../../../shared/enums/payment-journey-environment.enum';
import { PaymentJourny } from '../../../shared/enums/payment-journy.enum';
import { PaymentNavigationService } from '../../../payment/services/payment-navigation.service';
import { BillingService } from '../../../billing/billing.service';
import { PaymentWalletService } from '../../../shared/services/payment-wallet.service';
import { AnonymousPostPaidPaymentService } from '../../services/anonymous-post-paid-payment.service';
import { PaymentCustomer } from '../../../models/payment';
import { SiteHandlingService } from '../../../shared/services/site-handling.service';
@Component({
	selector: 'sp-payment-dept',
	templateUrl: './payment-dept.component.html',
	styleUrls: ['./payment-dept.component.scss'],
})
export class PaymentDeptComponent implements OnInit {
	/** amount in bill payment page for dept user */
	deptAmount: number;
	/** declare the form */
	partialPaymentForm: UntypedFormGroup = this.fb.group({
		payPartialField: ['', [this.amountValidator()]],
	});
	/** images variables */
	visaImage: string = '';
	masterCardImage: string = '';
	/** bottom right text in the tray */
	securedSslText: string = '';
	/** WCS variables */
	totalPaymentWcsData: {
		description: string;
		icon1: string;
		icon2: string;
		confirmButtonText: string;
		partialOption: string;
		payPartialButtonText: string;
	} = { description: '', icon1: '', icon2: '', confirmButtonText: '', partialOption: '', payPartialButtonText: '' };

	partialPaymentWcsData: {
		title: string;
		subtitle: string;
		description: string;
		confirmButtonText: string;
		textboxPlaceholder: string;
		paidAmountErrorMsg: string;
		icon: string;
	} = {
		title: '',
		subtitle: '',
		description: '',
		confirmButtonText: '',
		textboxPlaceholder: '',
		paidAmountErrorMsg: '',
		icon: '',
	};
	/** should include the error message that should be appeared once user enters a value more than maximum */
	amountMoreThanMaxMsg: string = '';
	/** should include the error message that should be appeared once user enters an invalid value */
	invalidValueMsg: string = '';
	/** Show and hide validation error of the entered value in partial payment */
	showErrorMsg: boolean = false;
	/** Show and hide X button in the text field */
	showXIcon: boolean = true;
	/** to handle the height of tray when keyboard is opening */
	isFieldFocused: boolean = false;
	/** to show the textbox to add amount of partial payment */
	@Input() showPayPartial: boolean = false;
	@Input() isAnonymous: boolean = false;
	/** to show the entrypoint for partial payment or not */
	@Input() hasPayPartialSection: boolean = true;
	constructor(
		public billingService: BillingService,
		private fb: UntypedFormBuilder,
		private translate: TranslateService,
		private appService: AppService,
		private taggingHelperService: TaggingHelperService,
		public paymentWalletService: PaymentWalletService,
		public anonPayService: AnonymousPostPaidPaymentService,
		private paymentNavigationService: PaymentNavigationService,
		private siteHandlingService: SiteHandlingService
	) {}

	ngOnInit() {
		this.paymentNavigationService.isAnonymousPayment = this.isAnonymous;
		this.deptAmount = this.billingService.deptAmount;
		this.paymentNavigationService.partialEnergyDebtPayment = false;
		this.translate.get('payment').subscribe((data) => {
			// get Data from wcs for total payment
			this.totalPaymentWcsData.description = data.messagesList.pendingPayment.description;
			this.totalPaymentWcsData.confirmButtonText = data.messagesList.pendingPayment.confirmButton.text;
			this.totalPaymentWcsData.partialOption = data.itemsList.partialPaymentOption.body.replace(
				'{0}',
				this.billingService.roundAmount(this.deptAmount)
			);
			this.totalPaymentWcsData.payPartialButtonText = data.buttonList.partialPaymentButton.text;
			// get Data from wcs for partial payment
			this.partialPaymentWcsData.title = data.messagesList.pendingPartialPayment.title;
			this.partialPaymentWcsData.subtitle = data.messagesList.pendingPartialPayment.subtitle;
			this.partialPaymentWcsData.description = data.messagesList.pendingPartialPayment.description;
			this.partialPaymentWcsData.confirmButtonText = data.messagesList.pendingPartialPayment.button1.text;
			this.partialPaymentWcsData.textboxPlaceholder = data.itemsList.partialAmountToPay.body;
			this.partialPaymentWcsData.paidAmountErrorMsg = data.messagesList.paidAmountErrMsg.description;
			this.invalidValueMsg = data.messagesList.paidAmountErrMsg.description;
			this.amountMoreThanMaxMsg = data.itemsList.debtNewErrorMsg.body;
			this.partialPaymentWcsData.icon = this.appService.getImgFullPath(data.messagesList.paidAmountErrMsg.icon.url);
			// get Data from wcs for total, partial footer images and text
			this.securedSslText = data.itemsList.securedSsl.body;
			this.visaImage = this.appService.getImgFullPath(data.paymentMethods.images.pciVisa.url);
			this.masterCardImage = this.appService.getImgFullPath(data.paymentMethods.images.pciMasterCard.url);
		});
		if (this.showPayPartial) {
			tagging.deptPaymentStartPartialJourney.journey_category = CrossVariables.client_typology;
			tagging.deptPaymentStartPartialJourney.journey_environment = this.isAnonymous
				? PaymentJourneyEnvironment.anonymous
				: PaymentJourneyEnvironment.private;
			this.taggingHelperService.view(tagging.deptPaymentStartPartialPageName, tagging.deptPaymentStartPartialJourney);
		} else {
			tagging.deptPaymentStartJourney.journey_category = CrossVariables.client_typology;
			tagging.deptPaymentStartJourney.journey_environment = this.isAnonymous
				? PaymentJourneyEnvironment.anonymous
				: PaymentJourneyEnvironment.private;
			this.taggingHelperService.view(tagging.deptPaymentStartPageName, tagging.deptPaymentStartJourney);
		}
	}
	/**
	 * Executed when click on pay partial button in total screen to show pay partial screen
	 */
	payPartial(): void {
		this.paymentNavigationService.openPaymentComponent(PaymentPages.partialDept, PaymentJourny.postDeptPartial, [
			{ key: PaymentCompoentInputData_Keys.payment_debt_cashdesk_showPayPartial, value: true },
			{ key: PaymentCompoentInputData_Keys.isAnonymous, value: this.isAnonymous },
		]);
	}
	/**
	 * Excuted when click on continuar Button to make total or partial payment.
	 * Remove € sign from text field when submit value.
	 * Check if value contains comma replace it with dot.
	 */
	onSubmit(): void {
		let deptAmount: number;
		if (this.showPayPartial) {
			this.billingService.paymentType = debtPaymentType.partialPayment;
			if (this.partialPaymentForm.controls['payPartialField'].value.includes(',')) {
				const removeCommaFromAmount: any = this.partialPaymentForm.controls['payPartialField'].value.replace(',', '.');
				deptAmount = +removeCommaFromAmount.replace('€', '');
			} else {
				deptAmount = +this.partialPaymentForm.controls['payPartialField'].value.replace('€', '');
			}
		} else {
			this.billingService.paymentType = debtPaymentType.totalPayment;
			deptAmount = this.deptAmount;
		}
		if (this.isAnonymous) {
			const payer: PaymentCustomer = new PaymentCustomer();
			payer.documentId = this.anonPayService.anonymousPaymentCustomer?.documentId; // user input
			payer.siteId = this.anonPayService.anonymousPaymentCustomer?.selectedsiteId; // site id
			payer.serviceId = ANONYMOUS;
			this.translate.get('payment.itemsList.debtPaymentType.body').subscribe((concepto) => {
				this.billingService.openPaymentModulePostPaidAnonymous(
					deptAmount.toString(),
					this.showPayPartial ? PaymentJourny.anonymousDebtPartial : PaymentJourny.anonymousDebtTotal,
					concepto,
					payer,
					payer
				);
			});
		} else {
			this.translate.get('payment.itemsList.debtPaymentType.body').subscribe((concepto) => {
				const paymentJourney: PaymentJourny = this.showPayPartial
					? PaymentJourny.postDeptPartial
					: this.siteHandlingService.isSiteSuspended()
					? PaymentJourny.suspendedDeptTotal
					: PaymentJourny.postDeptTotal;
				this.paymentNavigationService.partialEnergyDebtPayment = this.billingService.deptAmount > deptAmount;
				this.billingService.openPaymentModule(deptAmount.toString(), paymentJourney, concepto);
			});
		}
	}
	/**
	 * @return a regex that accept only numbers and single comma in text field.
	 */
	numbersAndSingleCommaOnly(): RegExp {
		const regexForNumbersAndSingleComaOnly: RegExp = new RegExp(Validation.numbersAndCommaOnly);
		return regexForNumbersAndSingleComaOnly;
	}
	/**
	 * Validation Fn to validate form against it.
	 * Make text field must have a value , accept only numbers , single comma , and value smaller than or equal dept amount.
	 */
	amountValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			if (!control.value) {
				this.showErrorMsg = false;
				return { invalid: true };
			} else if (
				control.value &&
				this.numbersAndSingleCommaOnly().test(control.value) &&
				+control.value.replace(',', '.') > 0 &&
				+control.value.replace(',', '.') <= this.deptAmount
			) {
				if (control.value.includes('.')) {
					setTimeout(() => {
						control.setValue(control.value.replace('.', ','));
					}, 300);
				}
				this.showErrorMsg = false;
				return null;
			} else {
				/** Check if amount more than maximum value or it's invalid value */
				if (+control.value.replace(',', '.') > this.deptAmount) {
					this.partialPaymentWcsData.paidAmountErrorMsg = this.amountMoreThanMaxMsg;
				} else {
					this.partialPaymentWcsData.paidAmountErrorMsg = this.invalidValueMsg;
				}
				this.showErrorMsg = true;
				return { invalid: true };
			}
		};
	}
	/**
	 * Add € sign to valid text field value when blur or focus out from it.
	 * Reset validators to null to make submit button not disabled when value is valid and € added to text field.
	 * Hide x button inside text field when blur.
	 * Revert isFieldFocused to make tray height as before
	 */
	addEurSignToValidInputOnBlur(): void {
		setTimeout(() => {
			this.isFieldFocused = false;
			this.showXIcon = false;
			if (this.partialPaymentForm.valid && this.partialPaymentForm.controls['payPartialField'].value) {
				this.partialPaymentForm.controls['payPartialField'].setValidators(null);
				this.partialPaymentForm.controls['payPartialField'].setValue(
					this.partialPaymentForm.controls['payPartialField'].value + '€'
				);
			}
		}, 1);
	}
	/**
	 * Check if text field value contains € sign which added on blur and then reomove it on focus in text field.
	 * Reset Validators again to validator fn.
	 * Reset showXIcon boolean to true again after blur.
	 * Set isFieldFocused boolean to true on focus.
	 */
	onFocus(): void {
		this.showXIcon = true;
		this.isFieldFocused = true;
		if (
			this.partialPaymentForm.controls['payPartialField'].value &&
			this.partialPaymentForm.controls['payPartialField'].value.includes('€')
		) {
			this.partialPaymentForm.controls['payPartialField'].setValue(
				this.partialPaymentForm.controls['payPartialField'].value.replace('€', '')
			);
			this.partialPaymentForm.controls['payPartialField'].setValidators([this.amountValidator()]);
		}
	}
	/**
	 * Executed when click on x button inside text field.
	 * Reset showXIcon boolean to true again after blur.
	 * Reset Validators again to validator fn.
	 */
	clickXButtonInTextField(): void {
		this.showXIcon = true;
		this.isFieldFocused = true;
		this.partialPaymentForm.controls['payPartialField'].setValidators([this.amountValidator()]);
	}
}
