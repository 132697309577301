import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ERRORCODES, VERIFYEMAIL } from '../../../shared/constants/defines';
import { PaymentErrorCategory } from '../../../shared/enums/payment-error-category.enum';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentErrorInterface } from './payment-error-interface';
import { PaymentErrorPrePaidLogin } from './payment-error-pre-paid-login';

export class PaymentErrorPrePaidAnonymous extends PaymentErrorPrePaidLogin implements PaymentErrorInterface {
	constructor(
		public paymentNavigationService: PaymentNavigationService,
		public translate: TranslateService,
		public router: Router
	) {
		super(paymentNavigationService, translate, router);
	}

	/**
	 * @param ecode error code string
	 * functon to map error code to error category
	 */
	mapErrorCode(ecode: any): PaymentErrorCategory {
		let errorCategory: PaymentErrorCategory;
		switch (ecode) {
			case ERRORCODES.NOT_A_PREPAID_SERVICE:
			case ERRORCODES.PENDING_MSISDN:
			case ERRORCODES.PAYMENT_INACTIVE_MSISDN:
				errorCategory = PaymentErrorCategory.Notactive;
				break;
			case ERRORCODES.PREPAID_SERVICE_HAS_PENDING_ORDERS:
				errorCategory = PaymentErrorCategory.TopupInProgress;
				break;
			case ERRORCODES.REPLICA_MODE_ERROR:
			case VERIFYEMAIL.errorAppUnavilability:
				errorCategory = PaymentErrorCategory.ReplicaMode;
				break;
			default:
				errorCategory = this.defaultErrorCategory;
				break;
		}
		return errorCategory;
	}
}
