import { Component, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';

@Component({
	selector: 'sp-fixed-bottom-button',
	templateUrl: './sp-fixed-bottom-button.component.html',
	styleUrls: ['./sp-fixed-bottom-button.component.scss'],
})
export class SpFixedBottomButtonComponent {
	/** btnText property is an input property for setting text above the btn*/
	@Input() btnText: string;
	/** btnName property is an input property for setting btn name */
	@Input() btnName: string;
	/** disabled is a boolean property to disable buttoon  */
	@Input() disabled: boolean;
	/** this flag remove white color if the bottom background */
	@Input() isMobileScrollable: boolean;
	/** btnAction is an output property for btnAction  */
	@Output() btnAction: EventEmitter<any> = new EventEmitter<any>();
	/** to set the bottom of the button gray*/
	@Input() grayBackgroundBelowFooter: boolean = false;
	/**this flag for shadow footer */
	@Input() shadowFooter: boolean = false;
	/**this property to know the height of the footer */
	@ViewChild('firstFooter') firstFooter: ElementRef;
	@ViewChild('remaninigFooter') remaninigFooter: ElementRef;

	btnClick() {
		this.btnAction.emit();
	}
}
