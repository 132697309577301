<article
	class="vfes-card vfes-card-border-radius vfes-colours-bg-vodafone-white vfes-card-no-border mvf-orders__empty-card vfes-mt"
>
	<section class="mvf-orders__empty">
		<p class="mvf-orders__empty-text">
			<img
				src="{{ appService.getImgFullPath('v10.myOrder.ordersEmpty.img.bundlesHiDark' | translate) }}"
				alt=""
				class="mvf-orders__empty-logo"
			/>
			<span *ngIf="isReturns" id="myOrdersSpOrdersEmptyReturns">
				<strong> {{ 'v10.myOrder.ordersEmpty.currentlyText' | translate }} </strong>
				{{ 'v10.myOrder.ordersEmpty.noCompletedOrders' | translate }}
			</span>
			<span *ngIf="isMyOrders" id="myOrdersSpOrdersEmptyMyOrders">
				{{ 'v10.myOrder.ordersEmpty.noCurrentOrders' | translate }}
			</span>
			<span *ngIf="isCancelled" id="myOrdersSpOrdersEmptyCancelled">
				<strong> {{ 'v10.myOrder.ordersEmpty.currentlyText' | translate }} </strong>
				{{ 'v10.myOrder.ordersEmpty.noCancelOrders' | translate }}
			</span>
		</p>
	</section>
</article>
