<div class="mvf-response-box__loader" *ngIf="loading">
	<sp-loader [showError]="false" [showSpinner]="true"> </sp-loader>
</div>
<div
	class="mvf-response-box vfes-pt+"
	*ngIf="!loading"
	[ngClass]="{ 'mvf-response-box__background': overlayData, 'isModal': isModal }"
>
	<div class="mvf-response-box__grow">
		<div class="mvf-response-box__center">
			<svg
				*ngIf="isPort || isChange"
				class="icon mvf-response-box__close"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				width="32px"
				height="32px"
				(click)="cancelChange()"
			>
				<title>Profile</title>
				<use class="icon-v3" xlink:href="#icon-close"></use>
			</svg>
			<figure class="mvf-response-box__image vfes-mb">
				<img
					id="imgAlertOrSuccess"
					src="{{
						overlayData?.imagen
							? overlayData.imagen
							: showError
							? ('v10.myOrder.responseBox.img.alert' | imgFullPath)
							: ('v10.myOrder.responseBox.img.success' | imgFullPath)
					}}"
					alt="{{ type }}"
				/>
			</figure>
			<div *ngIf="!isPort && !isChange" class="mvf-response-box__content vfes-align--center vfes-ml vfes-mr vfes-mb+">
				<p class="mvf-response-box__tittle vfes-mb" id="titleOverlayData">{{ overlayData?.titulo || titulo }}</p>
				<p
					class="mvf-response-box__text vfes-mb"
					[innerHtml]="
						overlayData?.subtitulo
							? overlayData.subtitulo
							: showError
							? ('v10.myOrder.responseBox.errorSubtitle' | translate)
							: ('v10.myOrder.responseBox.successSubtitle' | translate)
					"
				></p>
				<p class="mvf-response-box__text vfes-mb" *ngIf="isGuiaActivacion">
					{{ showError ? ('v10.myOrder.responseBox.errorSubtitle2' | translate) : '' }}
				</p>
				<p class="mvf-response-box__appointment vfes-mb" *ngIf="!showError">
					{{ data }}
				</p>
				<p class="mvf-response-box__footnote" *ngIf="overlayData?.notaPequena">
					{{ overlayData.notaPequena }}
				</p>
			</div>

			<div
				*ngIf="isPort && !genericError"
				class="mvf-response-box__content vfes-align--center vfes-ml vfes-mr vfes-mb+"
			>
				<p class="mvf-response-box__tittle vfes-mb" id="titleOverlayDataTwo">{{ overlayData?.titulo || titulo }}</p>
				<p class="mvf-response-box__text mvf-response-box__port-subtitle">
					{{
						showError
							? ('v10.myOrder.responseBox.relaunchPortaErrorSubtitle1' | translate)
							: ('v10.myOrder.responseBox.relaunchPortaOkSubtitle' | translate)
					}}
				</p>
				<p class="mvf-response-box__text vfes-mb">
					{{ showError ? ('v10.myOrder.responseBox.relaunchPortaErrorSubtitle2' | translate) : '' }}
				</p>
			</div>

			<div
				*ngIf="isChange && !genericError"
				class="mvf-response-box__content vfes-align--center vfes-ml vfes-mr vfes-mb+"
			>
				<p class="mvf-response-box__tittle vfes-mb">{{ overlayData?.titulo || titulo }}</p>
				<p class="mvf-response-box__text mvf-response-box__port-subtitle">
					{{
						showError
							? ('v10.myOrder.responseBox.relaunchPortaChangeErrorSub1' | translate)
							: ('v10.myOrder.responseBox.relaunchPortaOkSubtitle' | translate)
					}}
				</p>
				<p class="mvf-response-box__text vfes-mb">
					{{ showError ? ('v10.myOrder.responseBox.relaunchPortaChangeErrorSub2' | translate) : '' }}
				</p>
			</div>

			<div
				*ngIf="(isChange || isPort) && genericError"
				class="mvf-response-box__content vfes-align--center vfes-ml vfes-mr vfes-mb+"
			>
				<p class="mvf-response-box__tittle vfes-mb">{{ overlayData?.titulo || titulo }}</p>
				<p class="mvf-response-box__text mvf-response-box__port-subtitle">
					{{ 'v10.myOrder.responseBox.relaunchPortaErrorGenericSubtitle' | translate }}
				</p>
			</div>
		</div>
	</div>

	<div class="mvf-response-box__button">
		<div class="vfes-mb vfes-align--center">
			<button
				*ngIf="!isPort && !isChange"
				(click)="overlayData?.buttonClick ? overlayData?.buttonClick() : clickButton()"
				type="button"
				name="entendido"
				title="entendido"
				class="mvf-response-box__button vfes-button vfes-button--primary"
			>
				{{
					overlayData?.textoBoton
						? overlayData.textoBoton
						: showError
						? ('v10.myOrder.responseBox.close' | translate)
						: ('v10.myOrder.responseBox.undestood' | translate)
				}}
			</button>
			<button
				*ngIf="(isPort || isChange) && !genericError"
				(click)="clickButton()"
				type="button"
				name="entendido"
				title="entendido"
				class="mvf-response-box__button vfes-button vfes-button--primary"
			>
				{{
					showError
						? ('v10.myOrder.responseBox.relaunchPortaErrorRetry' | translate)
						: ('v10.myOrder.responseBox.relaunchPortaAccept' | translate)
				}}
			</button>
			<button
				*ngIf="(isPort || isChange) && genericError"
				(click)="cancelChange()"
				type="button"
				name="entendido"
				title="entendido"
				class="mvf-response-box__button vfes-button vfes-button--primary"
			>
				{{ 'v10.myOrder.responseBox.undestood' | translate }}
			</button>
		</div>
	</div>
</div>
