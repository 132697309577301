export enum FinancialPlanType {
	TotalFinanciado = 'TotalFinanciado',
	Cuota = 'Cuota',
	FinalpaymentAm = 'FinalpaymentAm',
	Estadorefin = 'Estadorefin',
	Duedate = 'Duedate',
	Inidate = 'Inidate',
	FpDateIni = 'FpDateIni',
	FpDateEnd = 'FpDateEnd',
	Fee = 'Fee',
	FinalFee = 'FinalFee',
}
