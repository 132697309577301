export class LegalTermsItemModel {
	id: number;
	type: string;
	name: string;
	internalDescription: string;
	order?: number;
	orden?: number;
	shortText?: string;
	htmlText?: string;
	code: string;
	checked?: boolean;
	notSavedChecked?: boolean;
}

export class LegalTermsListResponse {
	result: {
		status: number;
	};
	data: {
		listAceptos: LegalTermsItemModel[];
		initialText: string;
		finalText: string;
	};
}
