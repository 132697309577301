import { Injectable } from '@angular/core';
import { BuySimPersonalInfoHistoryData } from '../../models/buy-sim-personal-info-history-data';
import { BuySimAddressHistory } from '../../models/buy-sim-address-history';
import { BuySimPortabilityHistory } from '../../models/buy-sim-portability-history.model';
import { BuySimService } from './buy-sim.service';
import { PortabilityOwnerModel } from '../../models/buy-sim-portability-owner-model';
import { PersonalModel } from '../../models/buy-sim-personal-data.model';

@Injectable({
	providedIn: 'root',
})
export class BuySimDataHistoryService {
	personalInfoHistoryObj: BuySimPersonalInfoHistoryData = new BuySimPersonalInfoHistoryData();
	portabilityHistoryObj: BuySimPortabilityHistory = new BuySimPortabilityHistory();
	addressHistroyObj: BuySimAddressHistory = new BuySimAddressHistory();

	constructor(private buySimService: BuySimService) {}

	/** resets history service entries */
	resetHistory(): void {
		this.personalInfoHistoryObj = new BuySimPersonalInfoHistoryData();
		this.portabilityHistoryObj = new BuySimPortabilityHistory();
		this.addressHistroyObj = new BuySimAddressHistory();
		this.buySimService.paymentTermsAndConditionFlag = false;
		this.buySimService.portabilityChecked = false;
		this.buySimService.portabilityFullName = '';
		this.buySimService.changePortabilityOwner = false;
		this.buySimService.portabilityOwner = new PortabilityOwnerModel();
		this.buySimService.personalInfo = new PersonalModel();
	}
}
