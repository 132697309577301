import { AddressVerticalInfoModel } from './buy-sim-address-vertical-info.model';

export class BuySimAddressHistory {
	suggestedAddressList: any[];
	selectedAddress: string;
	selectedAddressIndex: number;
	addressNumber: number;
	addressNumberDisabled: boolean = false;
	manualAddressFormData: any;
	verticalAddress: AddressVerticalInfoModel;
}
