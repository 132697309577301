import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
	name: 'countdown',
})
export class CountdownPipe implements PipeTransform {
	public transform(duration: number): Observable<string> {
		if (!duration) {
			return null;
		}
		return timer(0, 1000).pipe(
			map(() => {
				duration = duration - 1;
				const countdown: moment.Duration = moment.duration(duration, 'seconds');
				const hours: number = Math.trunc(countdown.asHours());
				const minutes: number = countdown.minutes();
				const seconds: number = countdown.seconds();
				return this.parseTime(hours, minutes, seconds);
			})
		);
	}

	private parseTime(hours: number, minutes: number, seconds: number): string {
		if (hours) {
			return `${this.parseString(hours)}:${this.parseString(minutes)}:${this.parseString(seconds)}`;
		} else {
			return `${this.parseString(minutes)}:${this.parseString(seconds)}`;
		}
	}

	private parseString(num: number): string {
		return String(num).padStart(2, '0');
	}
}
