import { NavigationExtras, Router } from '@angular/router';
import { SubscriptionService } from '../../../core/services/subscription.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { UserSettingToken } from '../../enums/user-setting-token.enum';
import { AppService } from '../../../app.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { config } from '../../../../config/pages-config';
import { MyOrderEventChatId, MyOrdersEventAction, MyOrdersEvents } from '../../enums/myOrdersEvents.enum';
import { StorageService } from '../../../core/services/storage.service';
import { CommonService } from '../../../core/services/common.service';
import { CustomerAccountService } from '../../services/customer-account.service';
import { UtilitiesService } from '../../utils/utilities.service';
import { SpModalFullComponent } from '../sp-modal-full/sp-modal-full.component';
import { OrdersEventData } from '../../../models/orders-event-data.model';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { tagging } from '../../../../config/tagging-config';
import { TaggingActionModel, TaggingClickDataModel, TaggingViewModel } from '../../../models/tagging.model';
import { ConfigurationService } from '../../../core/services/configuration.service';
import { NeedBubbleHelpTabs } from '../../enums/need-bubble-help-tabs.enum';
import { SidemenuConfigService } from '../../services/sidemenu-config.service';
import { MarketplaceService } from 'src/app/marketplace/services/marketplace.service';
import { marketplaceTagging } from 'src/app/marketplace/tagging/marketplaceTagging';
import { CustomerAccountNewModel } from '../../../models/customer-account.model';
import { MyOrdersService } from './services/my-orders.service';
import { UtilsService } from './services/utils.service';
import { PlatformType } from '../../enums/platform-type.enum';
import { switchEnviroment } from '../../constants/defines';
import { LoggerService } from '../../utils/LoggerService';

@Component({
	selector: 'sp-my-orders',
	templateUrl: './my-orders.component.html',
	styleUrls: ['./my-orders.component.scss'],
})
export class MyOrdersComponent implements OnInit, OnDestroy, AfterViewInit {
	showMyOrders2: boolean = false;
	siteId: string;
	redirectUrl: string;
	redirectReplaceUrl: string;
	webToken: string = '';
	urlIframe: SafeResourceUrl;
	showIframe: boolean = false;
	ifrm: HTMLIFrameElement;
	handleReceivedMessageFromIframe: (event: MessageEvent) => void;

	@ViewChild('modalCancelSignProcess') modalCancelSignProcess: SpModalFullComponent;
	@ViewChild('modalMaxOtp') modalMaxOtp: SpModalFullComponent;
	@ViewChild('modalGenericError') modalGenericError: SpModalFullComponent;

	pageTagData: TaggingClickDataModel = marketplaceTagging.marketplaceTabsTab.myOrdersPage;

	constructor(
		public translate: TranslateService,
		private userSettingsService: UserSettingsService,
		private subscriptionService: SubscriptionService,
		private customerAccountService: CustomerAccountService,
		readonly appService: AppService,
		private myOrdersService: MyOrdersService,
		private router: Router,
		readonly storageService: StorageService,
		private commonService: CommonService,
		readonly utilities: UtilitiesService,
		private taggingHelper: TaggingHelperService,
		private sidemenuConfigService: SidemenuConfigService,
		private configurationService: ConfigurationService,
		private sanitizer: DomSanitizer,
		public marketplaceService: MarketplaceService,
		private utilsService: UtilsService,
		private loggerService: LoggerService
	) {}

	ngOnInit(): void {
		const myOrdersCBO: string = this.storageService.getLocalStorage(switchEnviroment.myOrdersCBO);

		if (this.translate.instant('v10.myOrder.switch') === switchEnviroment.two) {
			if (myOrdersCBO === switchEnviroment.CBO) {
				this.showMyOrders2 = false;
			} else {
				this.showMyOrders2 = true;
			}
		} else if (this.translate.instant('v10.myOrder.switch') === switchEnviroment.one) {
			this.showMyOrders2 = false;
		} else {
			this.showMyOrders2 = true;
		}

		this.translate
			.get(
				this.storageService.showFirmaEntryPoint
					? 'v10.orders.firma_digital.title'
					: 'v10.dashboard.gestion.list.mis_pedidos.desc'
			)
			.subscribe((data) => {
				this.appService.appHeaderTitle = data;
			});
		this.siteId =
			this.subscriptionService.customerData.customerAccountsId ||
			(this.storageService.userProfile.sites && this.storageService.userProfile.sites[0]
				? this.storageService.userProfile.sites[0].id
				: null);

		this.apiGetUserSettingsToken();

		window.datos =
			'{ "idCliente": "' +
			this.siteId +
			'", "sistemaOrigen": "smph", "auth_ticket": "auth-ticket-1", "tipoCliente": "particular"}';
		this.myOrdersService.MYORDERS_DATA = JSON.parse(window.datos);
	}

	apiGetUserSettingsToken(): void {
		const serviceId: string = !this.customerAccountService.isPendingInstallationSiteStatus(null, this.siteId)
			? this.subscriptionService.customerData.currentService?.id
			: null;
		if (this.utilsService.isWeb()) {
			this.userSettingsService
				.getUserSettingsToken(
					this.showMyOrders2 ? UserSettingToken.DxlAuth : UserSettingToken.WebToken,
					this.siteId,
					serviceId,
					false
				)
				.subscribe(
					() => {
						this.oldMethodsMyorders();
					},
					(error) => {
						this.router.navigate([config.dashboard.route]);
					}
				);
		}
	}

	oldMethodsMyorders(): void {
		this.redirectReplaceUrl = this.translate.instant('login.messagesList.seeOrderMsg.button1.extLinkURL');
		this.redirectUrl = this.utilities.getECareUrl(this.redirectReplaceUrl);
		this.openIframe(this.redirectUrl, this.userSettingsService.webToken);
		this.initOverlaysData();
	}

	ngAfterViewInit(): void {
		if (this.utilsService.isWeb()) {
			this.marketplaceService.init(true).then(() => {
				return;
			});
		}
	}

	openIframe(url: string, webToken: string): void {
		this.appService.showFullAppLoader = false;
		this.webToken = webToken;
		this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
		this.showIframe = true;
		// We save the handler into a variable to allow removeEventListener and preserve the scope of this
		this.handleReceivedMessageFromIframe = (event: MessageEvent) => {
			if (event?.data && this.ifrm?.contentWindow === event?.source) {
				this.handleReceivedMessageFromIframeorno(event, false);
			} else if (event?.data) {
				this.handleReceivedMessageFromIframeorno(event, true);
			}
		};
		window.addEventListener('message', this.handleReceivedMessageFromIframe);
	}
	handleReceivedMessageFromIframeorno(event: MessageEvent, myorders2: boolean): void {
		try {
			const receivedData: OrdersEventData = JSON.parse(event.data);
			if (receivedData?.message === MyOrdersEvents.loadOrders) {
				if (myorders2) {
					this.utilsService.isIkkiWeb = true;
				} else {
					if (this.ifrm?.contentWindow) {
						this.ifrm.contentWindow.postMessage(MyOrdersEvents.webIkkiType, '*');
					}
				}
			}
			if (receivedData.message === MyOrdersEvents.redirectTienda) {
				this.redirectTo(config.TiendaV10.route);
			}
			if (receivedData.message === MyOrdersEvents.redirectContratos) {
				this.redirectTo(config.myAccount.contracts.route, { queryParams: { tab: 'documents' } });
			}
			if (receivedData.message === MyOrdersEvents.openChat) {
				this.customerAccountService.getMyData(this.siteId).subscribe({
					next: (resp: CustomerAccountNewModel) => {
						this.userSettingsService.contactEmail = resp?.contact?.email;
						this.userSettingsService.contactPhone = resp?.contact?.mobile;
						this.openAutoiChat(receivedData);
					},
					error: () => {
						this.userSettingsService.contactEmail = '';
						this.userSettingsService.contactPhone = '';
						this.openAutoiChat(receivedData);
					},
				});
			}
			// Digitel Firma Digital events
			this.handleDigitalSignatureEvents(receivedData);
		} catch (error) {
			// Skip message due to JSON error
			console.error('Error:', error, 'while processing received message event:', event);
		}
	}

	openAutoiChat(receivedData: OrdersEventData): void {
		this.configurationService.configuration.chatEnabled = true;
		this.userSettingsService.orderId = receivedData.order_id;
		this.userSettingsService.tipo_flujo = receivedData.tipo_flujo;
		this.userSettingsService.paso_flujo = receivedData.paso_flujo;
		this.userSettingsService.motivo_soporte = receivedData.motivo_soporte;
		this.sidemenuConfigService.openBubble(
			receivedData.chat_id === MyOrderEventChatId.autoi ? NeedBubbleHelpTabs.autoi : NeedBubbleHelpTabs.chat
		);
		this.appService.closeNeedHelpBubble.subscribe(() => {
			this.configurationService.configuration.chatEnabled = false;
			this.userSettingsService.orderId = '';
			this.userSettingsService.tipo_flujo = '';
			this.userSettingsService.paso_flujo = '';
			this.userSettingsService.motivo_soporte = '';
			this.userSettingsService.contactEmail = '';
			this.userSettingsService.contactPhone = '';
		});
	}

	handleDigitalSignatureEvents(receivedData: OrdersEventData): void {
		const hasNoPendingSigns: boolean = [false, 'false'].includes(receivedData.pending_signs);
		if (receivedData.action === MyOrdersEventAction.error) {
			this.showGenericErrorModal();
		}
		if (receivedData.message === MyOrdersEvents.closeSign) {
			this.showCancelSignProcessModal();
		}
		if (receivedData.message === MyOrdersEvents.retriesFirmaDigital) {
			this.storageService.isFirmaFinished = hasNoPendingSigns;
			this.showMaxOtpModal();
		}
		if (receivedData.message === MyOrdersEvents.cancelFirmaDigital) {
			this.storageService.isFirmaFinished = hasNoPendingSigns;
			this.redirectTo(config.dashboard.route);
		}
		if (receivedData.message === MyOrdersEvents.finishFirmaVPT) {
			// Finish VPT process and hide dashboard entry point - Orders page should be reloaded
			this.storageService.userProfile.firmaDigital = false;
			this.storageService.isFirmaFinished = true;
		}
		if (hasNoPendingSigns && receivedData.message === MyOrdersEvents.acceptFinishFirma) {
			// Finish sign process and hide dashboard entry point
			this.storageService.userProfile.firmaDigital = false;
			this.storageService.isFirmaFinished = true;
			// Redirect to dashboard
			this.redirectTo(config.dashboard.route);
		}
		if (receivedData.message === MyOrdersEvents.downloadPDF) {
			this.utilities.downloadBase64(receivedData.base64, receivedData.doc_name || 'file.pdf');
		}
		const isPendingSignatureEvent: boolean =
			receivedData.action === MyOrdersEventAction.error ||
			[
				MyOrdersEvents.acceptFinishFirma,
				MyOrdersEvents.signedFirmaDigital,
				MyOrdersEvents.finishFirmaDigital,
				MyOrdersEvents.finishFirmaVPT,
				MyOrdersEvents.cancelFirmaDigital,
				MyOrdersEvents.retriesFirmaDigital,
				MyOrdersEvents.downloadPDF,
			].includes(receivedData.message);
		if (isPendingSignatureEvent) {
			// Send tagging
			this.sendDigitelEventTagging(receivedData);
		}
	}

	sendDigitelEventTagging(event: OrdersEventData): void {
		const clickTaggingData: TaggingActionModel = tagging.myOrders.signIframeEvent;
		const trackData: TaggingViewModel = Object.assign({}, clickTaggingData.data);
		const replaced: TaggingViewModel = Object.assign(trackData, {
			event_name: trackData.event_name.replace('{event_id}', event.message),
			event_label: trackData.event_label.replace('{contract_id}', event.message),
			event_category: trackData.event_category.replace(
				'{contract_type}',
				`${event.contract_type} ${event.contract_id?.replace(':', '-')}`
			),
		});
		this.taggingHelper.track(clickTaggingData.eventName.replace('{event_id}', event.message), replaced);
	}

	public onIframeLoad(): void {
		this.ifrm = document.getElementById('myOrdersIframe') as HTMLIFrameElement;
		if (this.ifrm?.contentWindow) {
			this.ifrm.contentWindow.postMessage(this.webToken, '*');
		}
	}

	initOverlaysData(): void {
		// Cancel signature process
		this.modalCancelSignProcess.primaryButton = true;
		this.modalCancelSignProcess.primaryButtonText = this.translate.instant(
			'v10.digitalSignature.abandonProcessOverlay.cancel'
		);
		this.modalCancelSignProcess.primaryButtonClick = () => {
			this.modalCancelSignProcess.hide();

			const page: TaggingViewModel = Object.assign({}, tagging.myOrders.overlayCloseSignProcess.view);
			const trackData: TaggingClickDataModel = Object.assign(
				page,
				tagging.myOrders.overlayCloseSignProcess.clickStay.data
			);
			trackData.event_label = '' + this.modalCancelSignProcess.primaryButtonText;
			this.taggingHelper.track(tagging.myOrders.overlayCloseSignProcess.clickStay.eventName, trackData);
		};
		this.modalCancelSignProcess.close = () => {
			this.modalCancelSignProcess.hide();

			const page: TaggingViewModel = Object.assign({}, tagging.myOrders.overlayCloseSignProcess.view);
			const trackData: TaggingClickDataModel = Object.assign(
				page,
				tagging.myOrders.overlayCloseSignProcess.clickClose.data
			);
			this.taggingHelper.track(tagging.myOrders.overlayCloseSignProcess.clickClose.eventName, trackData);
		};
		this.modalCancelSignProcess.tertiaryButton = true;
		this.modalCancelSignProcess.tertiaryButtonText = this.translate.instant(
			'v10.digitalSignature.abandonProcessOverlay.confirm'
		);
		this.modalCancelSignProcess.tertiaryButtonClick = () => {
			// Finish sign process, but keep dashboard entry point
			this.storageService.isFirmaFinished = false;
			this.redirectTo(config.dashboard.route);

			const page: TaggingViewModel = Object.assign({}, tagging.myOrders.overlayCloseSignProcess.view);
			const trackData: TaggingClickDataModel = Object.assign(
				page,
				tagging.myOrders.overlayCloseSignProcess.clickLeave.data
			);
			trackData.event_label = '' + this.modalCancelSignProcess.tertiaryButtonText;
			this.taggingHelper.track(tagging.myOrders.overlayCloseSignProcess.clickLeave.eventName, trackData);
		};

		// Max OTP retries overlay
		this.modalMaxOtp.primaryButton = true;
		this.modalMaxOtp.primaryButtonText = this.translate.instant('v10.digitalSignature.maxOtpOverlay.confirm');
		this.modalMaxOtp.primaryButtonClick = () => {
			this.redirectTo(config.dashboard.route);

			const page: TaggingViewModel = Object.assign({}, tagging.myOrders.overlayMaxOTP.view);
			const trackData: TaggingClickDataModel = Object.assign(page, tagging.myOrders.overlayMaxOTP.clickUnderstood.data);
			this.taggingHelper.track(tagging.myOrders.overlayMaxOTP.clickUnderstood.eventName, trackData);
		};
		this.modalMaxOtp.close = () => {
			this.redirectTo(config.dashboard.route);

			const page: TaggingViewModel = Object.assign({}, tagging.myOrders.overlayMaxOTP.view);
			const trackData: TaggingClickDataModel = Object.assign(page, tagging.myOrders.overlayMaxOTP.clickClose.data);
			this.taggingHelper.track(tagging.myOrders.overlayMaxOTP.clickClose.eventName, trackData);
		};

		// Generic error
		this.modalGenericError.primaryButton = true;
		this.modalGenericError.primaryButtonText = this.translate.instant('v10.common.literals.understood_C');
		this.modalGenericError.primaryButtonClick = () => {
			// Finish sign process, but keep dashboard entry point
			this.storageService.isFirmaFinished = false;
			this.redirectTo(config.dashboard.route);
		};
		this.modalGenericError.close = this.modalGenericError.primaryButtonClick;
	}

	showCancelSignProcessModal(): void {
		this.modalCancelSignProcess.show();
		this.taggingHelper.view(null, tagging.myOrders.overlayCloseSignProcess.view);
	}

	showMaxOtpModal(): void {
		this.modalMaxOtp.show();
		this.taggingHelper.view(null, tagging.myOrders.overlayMaxOTP.view);
	}

	showGenericErrorModal(): void {
		this.modalGenericError.show();
	}

	redirectTo(route: string, extras?: NavigationExtras): void {
		this.appService.showFullAppLoader = true;
		this.commonService.overrideBackHandling = false;
		this.router.navigate([route], extras);
	}

	ngOnDestroy(): void {
		this.showIframe = false;
		this.showMyOrders2 = false;
		this.modalCancelSignProcess?.hide();
		this.modalMaxOtp?.hide();
		this.modalGenericError?.hide();
		window.removeEventListener('message', this.handleReceivedMessageFromIframe);
		this.marketplaceService.destroy();
		this.appService.closeNeedHelpBubble.next();
	}
}
