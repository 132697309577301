import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaymentNavigationService } from '../services/payment-navigation.service';

@Component({
	selector: 'sp-pay-with-other-payment-methods',
	templateUrl: './pay-with-other-payment-methods.component.html',
	styleUrls: ['./pay-with-other-payment-methods.component.scss'],
})
export class PayWithOtherPaymentMethodsComponent implements OnInit {
	firstBtnDescription: string = '';
	payThroughBankBody: string = '';
	constructor(private translate: TranslateService, private paymentNavigationService: PaymentNavigationService) {}
	ngOnInit(): void {
		this.translate.get('payment.itemsList.otherPaymentMethods.body').subscribe((data) => {
			this.firstBtnDescription = data;
		});
		this.translate.get('billing.readyPayBill.fieldsList.payThroughBank.body').subscribe((data) => {
			this.payThroughBankBody = data;
		});
	}
	/**
	 * the default implmentaion of the first btn in other payment methods screen
	 */
	closeTray(): void {
		this.paymentNavigationService.isRestartingPayment = false;
		this.paymentNavigationService.partialEnergyDebtPayment = false;
		this.paymentNavigationService.closePayment();
	}
}
