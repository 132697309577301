import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { OLStatus } from '../../../enums/ol-status.enum';
import { OrderFlow } from '../../../enums/order-flow.enum';
import { constants } from '../../../models/constants';
import { HistoryStatusDetail, HistoryStatusMap } from '../../../models/historyStatusMap.model';
import { HistoricoOL, PedidoHistoricoOLResponse } from '../../../models/pedidoHistoricoOL.model';
import { TaskFlowSpecification } from '../../../models/getTaskFlowTypeOL.model';
import { MyOrdersService } from './my-orders.service';
import { OrdersService } from './orders.service';
import { API_URLS } from '../../../constants/routes-config';
import * as defines from '../../../constants/defines';
import { StorageService } from '../../../../core/services/storage.service';
import { PedidoHistoricoOLResponseNew, StatusChange } from '../../../models/pedidoHistoricoOLnew.model';

@Injectable({
	providedIn: 'root',
})
export class HistoryOlService {
	public statusMap: HistoryStatusMap;
	public disableSLACheck: boolean = false;
	public checkingOL: boolean;
	readonly historyOL: { [orderId: string]: PedidoHistoricoOLResponse } = {};
	public orderLastOLStatus: HistoricoOL;
	public auxOutdatedSLA: boolean;
	public auxCurrentFlow: OrderFlow;

	private taskFlowSpecification: TaskFlowSpecification[];

	constructor(
		private http: HttpClient,
		public myOrdersService: MyOrdersService,
		public ordersService: OrdersService,
		public appService: AppService,
		public translate: TranslateService,
		private storageService: StorageService
	) {}

	getOrderHistoryOL(idOrden: string): Observable<PedidoHistoricoOLResponse> {
		if (this.historyOL?.[idOrden]) {
			this.checkingOL = false;
			return of(this.historyOL[idOrden]);
		}
		this.checkingOL = true;
		this.orderLastOLStatus = null;
		const url: string = API_URLS.HistoryOl.getOrderHistoryOL.replace('{id}', idOrden);
		const httpOptions: { [key: string]: HttpHeaders } = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			}),
		};
		httpOptions.headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(defines.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		return this.http.get(url, httpOptions).pipe(
			switchMap((resp: PedidoHistoricoOLResponseNew[]) => this.mapPedidoHistoricoOLResponse(resp, idOrden)),
			finalize(() => {
				this.checkingOL = false;
			})
		);
	}

	public mapPedidoHistoricoOLResponse(
		resp: PedidoHistoricoOLResponseNew[],
		idOrden: string
	): Observable<PedidoHistoricoOLResponse> {
		let fullHistoryOL: PedidoHistoricoOLResponse = {
			historicoOL: [],
		};
		const historicoStatus: string[] = [];

		resp?.[0].statusChange?.forEach((item) => {
			if (historicoStatus[historicoStatus.length - 1] !== item.status) {
				historicoStatus.push(item.status);
			}
			if (item.status === defines.status00) {
				const historiconewn: HistoricoOL = {
					estado: item.status,
					fecha: new Date(
						item.changeDate.monthValue +
							'/' +
							item.changeDate.dayOfMonth +
							'/' +
							item.changeDate.year +
							' ' +
							item.changeDate.hour +
							':' +
							item.changeDate.minute
					).getTime(),
					descripcion: '',
				};
				historiconewn.descripcion = this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStartRequest');
				fullHistoryOL = this.addHistoricoOL(fullHistoryOL, historiconewn, idOrden);
			}
		});
		historicoStatus.push(resp?.[0].status);

		if (resp?.[0].status === defines.status00) {
			const historiconew: HistoricoOL = {
				estado: resp?.[0].status,
				fecha: new Date(
					resp?.[0].lastUpdateDate.monthValue +
						'/' +
						resp?.[0].lastUpdateDate.dayOfMonth +
						'/' +
						resp?.[0].lastUpdateDate.year +
						' ' +
						resp?.[0].lastUpdateDate.hour +
						':' +
						resp?.[0].lastUpdateDate.minute
				).getTime(),
				descripcion: '',
			};
			historiconew.descripcion = this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStartRequest');
			fullHistoryOL = this.addHistoricoOL(fullHistoryOL, historiconew, idOrden);
		}
		return this.getTaskFlowTypeOL(historicoStatus).pipe(
			map((taskFlowResponse: TaskFlowSpecification[]) => {
				this.taskFlowSpecification = taskFlowResponse;
				fullHistoryOL = this.resolveHistoricoOL(resp, idOrden, fullHistoryOL, taskFlowResponse);

				fullHistoryOL = this.addCurrentHistoricoOL(fullHistoryOL, resp[0], idOrden, taskFlowResponse);
				return fullHistoryOL;
			})
		);
	}

	addHistoricoOL(
		fullHistoryOL: PedidoHistoricoOLResponse,
		historico: HistoricoOL,
		idOrden: string
	): PedidoHistoricoOLResponse {
		if (historico?.descripcion) {
			fullHistoryOL.historicoOL.push(historico);
			fullHistoryOL.historicoOL = this.clearDuplicates(fullHistoryOL?.historicoOL);
			fullHistoryOL.historicoOL.sort((x, y) => x.fecha - y.fecha);
		}
		this.mapPedidoHistoricoOL(idOrden, fullHistoryOL);

		return fullHistoryOL;
	}

	mapPedidoHistoricoOL(idOrden: string, historicoOLNew: PedidoHistoricoOLResponse): void {
		this.historyOL[idOrden] = historicoOLNew;
		this.orderLastOLStatus = historicoOLNew.historicoOL[historicoOLNew.historicoOL.length - 1];
	}

	clearDuplicates(array: HistoricoOL[]): HistoricoOL[] {
		const uniqueValues: HistoricoOL[] = [];
		const newFields: Array<HistoricoOL> = new Array();
		for (const object of array) {
			const value: HistoricoOL = { fecha: object.fecha, estado: object.estado };
			if (!newFields.some((field) => field.fecha === value.fecha && field.estado === value.estado)) {
				newFields.push(value);
				uniqueValues.push(object);
			}
		}
		return uniqueValues;
	}

	getTaskFlowTypeOL(status: string[]): Observable<TaskFlowSpecification[]> {
		const url: string = API_URLS.HistoryOl.getTaskFlowTypeOL.replace('{statusOL}', status.join(';'));
		const httpOptions: { [key: string]: HttpHeaders } = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			}),
		};
		httpOptions.headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(defines.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		return this.http.get(url, httpOptions).pipe(
			map((res: TaskFlowSpecification[]) => {
				return res;
			})
		);
	}
	/**
	 * Maps current shipment status code to data object
	 * @param {string} currentStatusCode
	 * @returns Status data object
	 */
	getShipmentHistoryStatusDetail(currentStatusData: HistoricoOL): HistoryStatusDetail {
		this.auxOutdatedSLA = constants.trueLiteral.includes(currentStatusData?.slaSuperado);
		this.auxCurrentFlow = this.ordersService.detailOrderData?.orderFlow;
		const taskFlow: TaskFlowSpecification = this.taskFlowSpecification?.find(
			(spec) => spec.name?.toLocaleLowerCase() === currentStatusData?.estado?.toLocaleLowerCase()
		);
		if (taskFlow) {
			return {
				statusCode: currentStatusData.estado,
				step: taskFlow.taskFlowSpecificationCharacteristic?.[1]?.characteristicValueSpecification[0].value,
				status: this.resolveOLStatusFromStatusCode(currentStatusData.estado),
				statusDescription: taskFlow.taskFlowSpecificationCharacteristic?.[2]?.characteristicValueSpecification[0].value,
			};
		}
		if (!this.statusMap) {
			// First time: create Proxy config
			this.statusMap = this.generateStatusMapOldLogic();
		}
		return this.statusMap[currentStatusData?.estado];
	}

	resolveOLStatusFromStatusCode(statusCode: string): OLStatus {
		if (this.translate.instant('v10.myOrder.OLStatuses.init').includes(statusCode)) {
			return OLStatus.INIT;
		} else if (this.translate.instant('v10.myOrder.OLStatuses.preparing').includes(statusCode)) {
			return OLStatus.PREPARING;
		} else if (this.translate.instant('v10.myOrder.OLStatuses.ready').includes(statusCode)) {
			return OLStatus.READY;
		} else if (this.translate.instant('v10.myOrder.OLStatuses.sent').includes(statusCode)) {
			return OLStatus.SENT;
		} else if (this.translate.instant('v10.myOrder.OLStatuses.not_delivered').includes(statusCode)) {
			return OLStatus.NOT_DELIVERED;
		} else if (this.translate.instant('v10.myOrder.OLStatuses.last_try').includes(statusCode)) {
			return OLStatus.LAST_TRY;
		} else if (this.translate.instant('v10.myOrder.OLStatuses.delivered').includes(statusCode)) {
			return OLStatus.DELIVERED;
		} else if (this.translate.instant('v10.myOrder.OLStatuses.cancelled').includes(statusCode)) {
			return OLStatus.CANCELLED;
		} else if (this.translate.instant('v10.myOrder.OLStatuses.incidence').includes(statusCode)) {
			return OLStatus.INCIDENCE;
		} else if (this.translate.instant('v10.myOrder.OLStatuses.expired').includes(statusCode)) {
			return OLStatus.EXPIRED;
		} else if (this.translate.instant('v10.myOrder.OLStatuses.pick_up').includes(statusCode)) {
			return OLStatus.PICK_UP;
		}
	}

	public getShipmentHistoryStatusDetailRefact(
		statusesData: HistoryStatusMap,
		currentStatus: string
	): HistoryStatusDetail {
		let mappedStatus: HistoryStatusDetail = new HistoryStatusDetail();
		for (const status in statusesData) {
			if (
				currentStatus &&
				status &&
				RegExp('^' + status.replace('|', '$|^') + '$').test((currentStatus || '').trim())
			) {
				mappedStatus = this.getShipmentHistoryStatusDetailif(status, statusesData, currentStatus);
				if (
					!this.disableSLACheck &&
					![OLStatus.DELIVERED, OLStatus.CANCELLED].includes(mappedStatus.status) &&
					this.ordersService.autoiFlows.includes(this.auxCurrentFlow)
				) {
					this.getShipmentHistoryStatusDetailif_if(mappedStatus);
				}
				break;
			}
		}
		return mappedStatus;
	}

	public getShipmentHistoryStatusDetailif(
		status: string,
		statusesData: HistoryStatusMap,
		currentStatus: string
	): HistoryStatusDetail {
		const mappedStatus: HistoryStatusDetail = { ...statusesData[status] };
		mappedStatus.statusCode = currentStatus;
		mappedStatus.completedStep = null;
		mappedStatus.date = null;
		mappedStatus.detailedDescription = null;
		mappedStatus.callButton = constants.trueLiteral.includes(
			this.translate.instant('v10.myOrder.orderHistoryModal.hideClickToCallButtonsOL')
		)
			? null
			: mappedStatus.callButton;
		return mappedStatus;
	}

	public getShipmentHistoryStatusDetailif_if(mappedStatus: HistoryStatusDetail): void {
		mappedStatus.outdatedSLA = constants.trueLiteral.includes(this.auxOutdatedSLA);
		mappedStatus.showAlert = mappedStatus.showAlert || mappedStatus.outdatedSLA;
		mappedStatus.alertMsg = mappedStatus.outdatedSLA
			? this.translate.instant('v10.myOrder.orderHistoryModal.autoiOlSlaBadge')
			: mappedStatus.alertMsg;
	}

	private resolveHistoricoOL(
		shippingOrderResponse: PedidoHistoricoOLResponseNew[],
		idOrden: string,
		updatedHistoricoOL: PedidoHistoricoOLResponse,
		taskFlowResponse: TaskFlowSpecification[]
	): PedidoHistoricoOLResponse {
		// LLamada a taskflow
		shippingOrderResponse[0].statusChange.forEach((element) => {
			const shippingOrder: TaskFlowSpecification = taskFlowResponse.find((item) => item.name === element.status);
			const historiconew: HistoricoOL = {
				estado: element.status,
				fecha: this.generateDate(shippingOrderResponse, element).getTime(),
				descripcion: shippingOrder?.description,
			};
			updatedHistoricoOL = this.addHistoricoOL(updatedHistoricoOL, historiconew, idOrden);
		});

		return updatedHistoricoOL;
	}

	private generateDate(shippingOrderResponse: PedidoHistoricoOLResponseNew[], statusChange?: StatusChange): Date {
		if (statusChange) {
			return new Date(
				statusChange.changeDate.monthValue +
					'/' +
					statusChange.changeDate.dayOfMonth +
					'/' +
					statusChange.changeDate.year +
					' ' +
					statusChange.changeDate.hour +
					':' +
					statusChange.changeDate.minute
			);
		}

		return new Date(
			shippingOrderResponse?.[0].lastUpdateDate.monthValue +
				'/' +
				shippingOrderResponse?.[0].lastUpdateDate.dayOfMonth +
				'/' +
				shippingOrderResponse?.[0].lastUpdateDate.year +
				' ' +
				shippingOrderResponse?.[0].lastUpdateDate.hour +
				':' +
				shippingOrderResponse?.[0].lastUpdateDate.minute
		);
	}

	addCurrentHistoricoOL(
		historicoOLNew: PedidoHistoricoOLResponse,
		historico: PedidoHistoricoOLResponseNew,
		idOrden: string,
		taskFlowResponse: TaskFlowSpecification[]
	): PedidoHistoricoOLResponse {
		const shippingOrder: TaskFlowSpecification = taskFlowResponse.find((item) => item.name === historico.status);
		const historiconew: HistoricoOL = {
			estado: historico.status,
			fecha: new Date(
				historico.lastUpdateDate.monthValue +
					'/' +
					historico.lastUpdateDate.dayOfMonth +
					'/' +
					historico.lastUpdateDate.year +
					' ' +
					historico.lastUpdateDate.hour +
					':' +
					historico.lastUpdateDate.minute
			).getTime(),
			descripcion: shippingOrder?.description,
		};

		return this.addHistoricoOL(historicoOLNew, historiconew, idOrden);
	}

	/**
	 * @returns HistoryStatusMap
	 */
	private generateStatusMapOldLogic(): HistoryStatusMap {
		return new Proxy(
			{
				'00': {
					step: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusInit'),
					status: OLStatus.INIT,
					statusDescription: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusInit'),
				},
				'5|A1|30-03': {
					step: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusPreparing'),
					status: OLStatus.PREPARING,
					statusDescription: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusPreparing'),
				},
				'10|20|A2|A4': {
					step: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusReady'),
					status: OLStatus.READY,
					statusDescription: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusReady'),
				},
				'AX': {
					step: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusPickUp'),
					status: OLStatus.PICK_UP,
					statusDescription: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusPickUp'),
				},
				'G1|G2|A5|AA': {
					step: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusSent'),
					status: OLStatus.SENT,
					statusDescription: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusSent'),
				},
				'A8': {
					step: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusNotDelivered'),
					status: OLStatus.NOT_DELIVERED,
					statusDescription: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusNotDelivered'),
					showAlert: true,
					alertMsg: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLAlertNotDelivered'),
				},
				'G2|AY|30-04|30-08|30-02|30-4|30-8|30-2|D1-04|D1-05|D1-06|D1-07': {
					step: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusNotDeliveredCall'),
					status: OLStatus.NOT_DELIVERED,
					statusDescription: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusNotDeliveredCall'),
					showAlert: true,
					alertMsg: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLAlertNotDeliveredCall'),
					callButton: 22123,
				},
				'G3': {
					step: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusLastTry'),
					status: OLStatus.LAST_TRY,
					statusDescription: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusLastTry'),
					showAlert: true,
					alertMsg: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLAlertLastTry'),
				},
				'A7|40': {
					step: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusDelivered'),
					status: OLStatus.DELIVERED,
					statusDescription: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusDelivered'),
				},
				'G4|30-06|D1-01|D1-02': {
					step: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusCancelled'),
					status: OLStatus.CANCELLED,
					statusDescription: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusCancelled'),
				},
				'D1-7|30-6|D1-07': {
					step: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusCancelledCall'),
					status: OLStatus.CANCELLED,
					statusDescription: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusCancelledCall'),
					callButton: 22123,
				},
				'D1-10|H0': {
					step: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusExpired'),
					status: OLStatus.EXPIRED,
					statusDescription: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusExpired'),
				},
				'D1-21|D1-22|D1-23|D1-24|D1-25|D1-26|D1-27|D1-28|D1-29|A0|D1': {
					step: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusIncidence'),
					status: OLStatus.INCIDENCE,
					statusDescription: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusIncidence'),
				},
			},
			{
				get: (statusesData: HistoryStatusMap, currentStatus: string): HistoryStatusDetail => {
					const mappedStatus: HistoryStatusDetail = this.getShipmentHistoryStatusDetailRefact(
						statusesData,
						currentStatus
					);
					return mappedStatus?.statusCode
						? mappedStatus
						: {
								statusCode: currentStatus,
								step: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusUnknown'),
								status: OLStatus.UNKNOWN,
								statusDescription: this.translate.instant('v10.myOrder.orderHistoryModal.historicoOLStatusUnknown'),
						  };
				},
			}
		);
	}
}
