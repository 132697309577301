export enum BuySimSteps {
	stepZeroPersonalInfo = 0,
	stepOnePortability = 1,
	stepTwoAddress = 2,
	stepThreePayment = 3,
}

export enum BuySimFailedApis {
	ResetShoppingCart = 'ResetShoppingCart',
	Portability = 'portability',
	OrderConfirmation = 'OrderConfirmation',
	deliveryAddress = 'deliveryAddress',
}
