export enum SideMenuItemType {
	MENU,
	FORYOU,
	SHOP,
	PRODUCTS,
	ACCOUNT,
	TOPUP,
	ONEPROFESSIONAL,
	KITDIGITAL,
	PLACEHOLDER,
	HISTORY,
}
