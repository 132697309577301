<vfac-overlay
	#decoInstallationGuideOverlay
	[overlayData]="decoInstallationGuideOverlayData"
	[show]="showDecoInstallationGuideOverlay"
	[page]="selectedStep + 1"
	[loaded]="overlayLoaded"
	[loaderType]="'vodafone'"
	(closed)="closeGuideOverlay()"
	(backPage)="goBackwards()"
>
	<div header>
		<div class="mvf-deco-installation__header vfes-align--left">
			<mva10-c-steps [steps]="stepsData" class="mva10-u--mt4x" [selected]="selectedStep" [vertical]="false">
			</mva10-c-steps>
		</div>
	</div>

	<!-- Step 1 -->
	<div body id="page1">
		<div class="mvf-deco-installation__p16">
			<div class="vfes-title--bold vfes-pb- mvf-deco-installation__fs20">
				{{ 'v10.myOrder.decoInstallation.guideInstalacionDecoPaso1Nombre' | translate }}
			</div>
			<div class="vfes-title--regular">
				{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep1Description' | translate }}
			</div>
			<div class="mvf-deco-installation__display mvf-deco-installation__pt16 mvf-deco-installation__justify-center">
				<img
					class="mvf-deco-installation__img1 mvf-deco-installation__img-mobile-display-none"
					src="{{ appService.getImgFullPath('v10.myOrder.decoInstallation.img.componentsDeco' | translate) }}"
					alt="componentes necesarios para la instalacion del decodificador"
				/>
				<div class="mvf-deco-installation__img-display-none">
					<div class="mvf-deco-installation__display-flex vfes-mb+">
						<ul class="mvf-deco-installation__image-grid">
							<li class="vfes-mt- vfes-mr">
								<img
									class="mvf-deco-installation__img3"
									src="{{ appService.getImgFullPath('v10.myOrder.decoInstallation.img.deco' | translate) }}"
									alt="Decodificador"
								/>
								<span class="caption vfes-mt- vfes-title--bold">
									{{ 'v10.myOrder.decoInstallation.guideInstallationDecoDecoder' | translate }}
								</span>
							</li>
							<li class="vfes-ml vfes-mt- vfes-mr">
								<img
									class="mvf-deco-installation__img3"
									src="{{ appService.getImgFullPath('v10.myOrder.decoInstallation.img.notextfilter' | translate) }}"
									alt="Filtro"
								/>
								<span class="caption vfes-mt- vfes-title--bold">
									{{ 'v10.myOrder.decoInstallation.guideInstallationDecoFilter' | translate }}
								</span>
							</li>
							<li class="vfes-ml vfes-mt-">
								<img
									class="mvf-deco-installation__img4"
									src="{{ appService.getImgFullPath('v10.myOrder.decoInstallation.img.Tvremote' | translate) }}"
									alt="Mando television"
								/>
								<span class="caption vfes-mt- vfes-title--bold">
									{{ 'v10.myOrder.decoInstallation.guideInstallationDecoControllerTV' | translate }}
								</span>
							</li>
						</ul>
					</div>
					<div class="mvf-deco-installation__display-flex vfes-mb+">
						<ul class="mvf-deco-installation__image-grid">
							<li class="vfes-mt- vfes-mr">
								<img
									class="mvf-deco-installation__img3"
									src="{{ appService.getImgFullPath('v10.myOrder.decoInstallation.img.psu' | translate) }}"
									alt="Fuente de alimentación"
								/>
								<span class="caption vfes-mt- vfes-title--bold">
									{{ 'v10.myOrder.decoInstallation.guideInstallationDecoPowerSupply' | translate }}
								</span>
							</li>
							<li class="vfes-ml vfes-mt- vfes-mr">
								<img
									class="mvf-deco-installation__img3"
									src="{{ appService.getImgFullPath('v10.myOrder.decoInstallation.img.cableethernet' | translate) }}"
									alt="Cable Ethernet"
								/>
								<span class="caption vfes-mt- vfes-title--bold">
									{{ 'v10.myOrder.decoInstallation.guideInstallationDecoWireEthernet' | translate }}
								</span>
							</li>
							<li class="vfes-ml vfes-mt-">
								<img
									class="mvf-deco-installation__img3"
									src="{{ appService.getImgFullPath('v10.myOrder.decoInstallation.img.CableCoaxial' | translate) }}"
									alt="Llave cable coaxial"
								/>
								<span class="caption vfes-mt- vfes-title--bold">
									{{ 'v10.myOrder.decoInstallation.guideInstallationDecoKeyWireCoaxial' | translate }}
								</span>
							</li>
						</ul>
					</div>
					<div class="mvf-deco-installation__display-flex vfes-mb+">
						<ul class="mvf-deco-installation__image-grid">
							<li class="vfes-mt- vfes-mr">
								<img
									class="mvf-deco-installation__img3"
									src="{{ appService.getImgFullPath('v10.myOrder.decoInstallation.img.cableHDMI' | translate) }}"
									alt="cable HDMI"
								/>
								<span class="caption vfes-mt- vfes-title--bold">
									{{ 'v10.myOrder.decoInstallation.guideInstallationDecoWireHDMI' | translate }}
								</span>
							</li>
							<li class="vfes-ml vfes-mt- vfes-mr">
								<img
									class="mvf-deco-installation__img3"
									src="{{ appService.getImgFullPath('v10.myOrder.decoInstallation.img.hose' | translate) }}"
									alt="latiguillo"
								/>
								<span class="caption vfes-mt- vfes-title--bold">
									{{ 'v10.myOrder.decoInstallation.guideInstallationDecoHose' | translate }}
								</span>
							</li>
							<li class="vfes-ml vfes-mt-">
								<img
									class="mvf-deco-installation__img3"
									src="{{ appService.getImgFullPath('v10.myOrder.decoInstallation.img.splitter' | translate) }}"
									alt="splitter"
								/>
								<span class="caption vfes-mt- vfes-title--bold">
									{{ 'v10.myOrder.decoInstallation.guideInstallationDecoSplitter' | translate }}
								</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
		</div>
	</div>

	<!-- Step2 -->
	<div body id="page2">
		<div
			class="vfes-display--inline-block mvf-deco-installation__p16 mvf-deco-installation__second-step"
			id="MyOrdersSpDecoInstallationStep2Content"
		>
			<div
				class="mvf-deco-installation__cards mvf-deco-installation__display vfes-pb"
				id="MyOrdersSpDecoInstallationStep2SubStep1Card"
			>
				<div
					class="vfes-align--left"
					id="MyOrdersSpDecoInstallationStep2SubStep1TitleContent1"
					id="MyOrdersSpDecoInstallationStep2SubStep1Content"
				>
					<div id="MyOrdersSpDecoInstallationStep2ContentTivoInfo" class="mva10-u--mb4 contentTivoInfo">
						<mva10-c-alert [layout]="'vertical'" [state]="'info'">
							<div id="MyOrdersSpDecoInstallationStep2TivoInfo">
								{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2TivoInfo' | translate }}
							</div>
						</mva10-c-alert>
					</div>
					<div id="MyOrdersSpDecoInstallationStep2TivoInstruccion" class="tivoInstruccionContainer">
						<div id="MyOrdersSpDecoInstallationStep2TivoInstruccion1" class="tivoInstruccion">
							<div id="MyOrdersSpDecoInstallationStep2TivoInstruccion1ImgContent" class="tivoInstruccion1Img">
								<img
									id="MyOrdersSpDecoInstallationStep2TivoInstruccion1Img"
									class="imgControl"
									src="{{ 'v10.myOrder.decoInstallation.img.decoOff' | imgFullPath }}"
									alt="control gen1"
								/>
							</div>
							<ul id="MyOrdersSpDecoInstallationStep2TivoInstruccion1Ul" class="tivoInstruccionUl">
								<li id="MyOrdersSpDecoInstallationStep2TivoInstruccion1Li" class="tivoInstruccionLi">
									<div id="MyOrdersSpDecoInstallationStep2TivoInstruccion1Desc" class="tivoInstruccion1Desc">
										<p id="MyOrdersSpDecoInstallationStep2TivoInstruccion1Text" class="tivoInstruccionText">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2TivoStep1' | translate }}
										</p>
									</div>
								</li>
							</ul>
						</div>
						<div id="MyOrdersSpDecoInstallationStep2TivoInstruccion2" class="tivoInstruccion2">
							<ul id="MyOrdersSpDecoInstallationStep2TivoInstruccion2Ul" class="tivoInstruccionUl2">
								<li id="MyOrdersSpDecoInstallationStep2TivoInstruccion2Li" class="tivoInstruccionLi">
									<div id="MyOrdersSpDecoInstallationStep2TivoInstruccion2Desc" class="tivoInstruccion2Desc">
										<p id="MyOrdersSpDecoInstallationStep2TivoInstruccion2Text" class="tivoInstruccionText">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2TivoStep2' | translate }}
										</p>
									</div>
								</li>
							</ul>
							<div id="MyOrdersSpDecoInstallationStep2TivoInstruccion2ImgContent" class="tivoInstruccion2Img">
								<img
									id="MyOrdersSpDecoInstallationStep2TivoInstruccion2Img"
									class="imgControl"
									src="{{ 'v10.myOrder.decoInstallation.img.decoPowerHdmi' | imgFullPath }}"
									alt="control gen1"
								/>
							</div>
						</div>
						<div id="MyOrdersSpDecoInstallationStep2TivoInstruccion3" class="tivoInstruccion">
							<div id="MyOrdersSpDecoInstallationStep2TivoInstruccion3ImgContent" class="tivoInstruccion3Img">
								<img
									id="MyOrdersSpDecoInstallationStep2TivoInstruccion3Img"
									class="imgControl"
									src="{{ 'v10.myOrder.decoInstallation.img.conectCableFiberDeco8_5' | imgFullPath }}"
									alt="control gen1"
								/>
							</div>
							<ul id="MyOrdersSpDecoInstallationStep2TivoInstruccion3Ul" class="tivoInstruccionUl">
								<li id="MyOrdersSpDecoInstallationStep2TivoInstruccion3l1" class="tivoInstruccionLi">
									<div id="MyOrdersSpDecoInstallationStep2TivoInstruccion3Desc" class="tivoInstruccion3Desc">
										<p id="MyOrdersSpDecoInstallationStep2TivoInstruccion3Text" class="tivoInstruccionText">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2TivoStep3' | translate }}
										</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<ol class="mvf-deco-installation__ordered-list vfes-pl mvf-deco-installation__font-small" start="1">
						<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
							<span class="vfes-title--bold" id="MyOrdersSpDecoInstallationStep2SubStep1Title1">
								{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2_1Title' | translate }}
							</span>
							<div
								class="vfes-title--regular vfes-pb+"
								id="MyOrdersSpDecoInstallationStep2SubStep1Desc1"
								[innerHTML]="'v10.myOrder.routerInstallation.step2_2Desc' | translate"
							></div>
							<img
								id="MyOrdersSpDecoInstallationStep2SubStep1Img1"
								class="imgControl"
								src="{{ 'v10.myOrder.routerInstallation.img.step2_02_controlGEN1' | imgFullPath }}"
								alt="control gen1"
							/>
							<p
								class="vfes-title--regular"
								id="MyOrdersSpDecoInstallationStep2SubStep1ImgDesc1"
								[innerHTML]="'v10.myOrder.decoInstallation.guideInstallationDecoStep2_1ImgDesc' | translate"
							></p>
							<div class="tivoText" id="MyOrdersSpDecoInstallationStep2SubStep1IconContainer">
								<div class="mva10-u--mr2" id="MyOrdersSpDecoInstallationStep2SubStep1ImageContainer">
									<mva10-c-icon
										id="MyOrdersSpDecoInstallationStep2SubStep1Icon"
										[iconId]="'icon-notification-info'"
										[title]="'info'"
										[size]="4"
										[state]="'info'"
									></mva10-c-icon>
								</div>
								<p
									[innerHTML]="'v10.myOrder.decoInstallation.guideInstallationDecoStep2_1ImgDescTivo' | translate"
									id="MyOrdersSpDecoInstallationStep2SubStep1IconText"
								></p>
							</div>
							<img
								id="MyOrdersSpDecoInstallationStep2SubStep1Img2"
								class="imgControl mva10-u--mt5"
								src="{{ 'v10.myOrder.routerInstallation.img.step2_02_controlGEN3' | imgFullPath }}"
								alt="control gen3"
							/>
							<p
								class="vfes-title--regular"
								id="MyOrdersSpDecoInstallationStep2SubStep1ImgDesc2"
								[innerHTML]="'v10.myOrder.decoInstallation.guideInstallationDecoStep2_1ImgDescB' | translate"
							></p>
							<div class="tivoText" id="MyOrdersSpDecoInstallationStep2SubStep2IconContainer">
								<div class="mva10-u--mr2" id="MyOrdersSpDecoInstallationStep2SubStep2ImageContainer">
									<mva10-c-icon
										id="MyOrdersSpDecoInstallationStep2SubStep1Icon"
										[iconId]="'icon-notification-info'"
										[title]="'info'"
										[size]="4"
										[state]="'info'"
									></mva10-c-icon>
								</div>
								<p
									[innerHTML]="'v10.myOrder.decoInstallation.guideInstallationDecoStep2_1ImgDescTivoB' | translate"
									id="MyOrdersSpDecoInstallationStep2SubStep2IconText"
								></p>
							</div>
						</li>
					</ol>
				</div>
			</div>
			<div
				class="mvf-deco-installation__cards mvf-deco-installation__display vfes-pb"
				id="MyOrdersSpDecoInstallationStep2SubStep2Card"
			>
				<ol class="mvf-deco-installation__ordered-list vfes-pl mvf-deco-installation__font-small" start="2">
					<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
						<span class="vfes-title--bold" id="MyOrdersSpDecoInstallationStep2SubStep3Title">
							{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Name' | translate }}
						</span>
						<p class="vfes-title--regular" id="MyOrdersSpDecoInstallationStep2SubStep3Desc">
							{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Instruction1' | translate }}
						</p>
					</li>
				</ol>
			</div>
			<div class="vfes-title--bold vfes-pb- mvf-deco-installation__fs20">
				{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2WithWireFibra' | translate }}
			</div>
			<div class="mvf-deco-installation__display mvf-deco-installation__pt16 vfes-pb+">
				<div class="vfes-layout__wrapper">
					<div class="vfes-layout__item">
						<!-- 1.1 -->
						<div class="mvf-deco-installation__display vfes-pb+">
							<div
								class="mvf-deco-installation__placeholder vfes-pr- vfes-pl mvf-deco-installation__img-mobile mvf-deco-installation__remove_pl_mobile"
							></div>
							<div class="vfes-align--left mvf-deco-installation__subpaso">
								<ol
									class="mvf-deco-installation__ordered-list mvf-deco-installation__font-small mvf-deco-installation__paso11"
								>
									<li class="vfes-pl-">
										<span class="vfes-title--regular">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Instruction1_1' | translate }}
										</span>
									</li>
								</ol>
							</div>
						</div>

						<!-- 1.2 -->
						<div class="mvf-deco-installation__display vfes-pb-">
							<img
								class="vfes-pb+ vfes-pr- vfes-pl mvf-deco-installation__img-mobile mvf-deco-installation__remove_pl_mobile"
								src="{{ appService.getImgFullPath('v10.myOrder.decoInstallation.img.routercablefiber' | translate) }}"
								alt="desenrosca cable fibra"
							/>
							<div class="vfes-align--left mvf-deco-installation__subpaso">
								<ol
									class="mvf-deco-installation__ordered-list mvf-deco-installation__font-small mvf-deco-installation__paso12"
									start="2"
								>
									<li class="vfes-pl-">
										<span class="vfes-title--regular">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Instruction1_2' | translate }}
										</span>
									</li>
								</ol>
							</div>
						</div>

						<!-- 1.3 -->
						<div class="mvf-deco-installation__display vfes-pb">
							<img
								class="vfes-pb+ vfes-pr- vfes-pl mvf-deco-installation__img-mobile mvf-deco-installation__remove_pl_mobile"
								src="{{
									appService.getImgFullPath('v10.myOrder.decoInstallation.img.conectCoaxialSplitter' | translate)
								}}"
								alt="enrosca cable coaxial en splitter"
							/>
							<div class="vfes-align--left mvf-deco-installation__subpaso">
								<ol
									class="mvf-deco-installation__ordered-list mvf-deco-installation__font-small mvf-deco-installation__paso13"
									start="3"
								>
									<li class="vfes-pl-">
										<span class="vfes-title--regular">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Instruction1_3' | translate }}
										</span>
									</li>
								</ol>
							</div>
						</div>

						<!-- 1.4 -->
						<div class="mvf-deco-installation__display">
							<img
								class="vfes-pb+ vfes-pr- vfes-pl mvf-deco-installation__img-mobile mvf-deco-installation__remove_pl_mobile"
								src="{{ appService.getImgFullPath('v10.myOrder.decoInstallation.img.hose' | translate) }}"
								alt="conecta latiguillo entre splitter y router"
							/>
							<div class="vfes-align--left mvf-deco-installation__subpaso">
								<ol
									class="mvf-deco-installation__ordered-list mvf-deco-installation__font-small mvf-deco-installation__paso14"
									start="4"
								>
									<li class="vfes-pl-">
										<span class="vfes-title--regular">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Instruction1_4' | translate }}
										</span>
									</li>
								</ol>
							</div>
						</div>

						<!-- 1.5 -->
						<div class="mvf-deco-installation__display">
							<img
								class="vfes-pb+ vfes-pr- vfes-pl mvf-deco-installation__img-mobile mvf-deco-installation__remove_pl_mobile"
								src="{{ appService.getImgFullPath('v10.myOrder.decoInstallation.img.cableFiberFilter' | translate) }}"
								alt="conecta filtro a la toma de Vodafone fibra"
							/>
							<div class="vfes-align--left mvf-deco-installation__subpaso">
								<ol
									class="mvf-deco-installation__ordered-list mvf-deco-installation__font-small mvf-deco-installation__paso15"
									start="5"
								>
									<li class="vfes-pl-">
										<span class="vfes-title--regular">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Instruction1_5' | translate }}
										</span>
									</li>
								</ol>
							</div>
						</div>

						<!-- 1.6 -->
						<div class="mvf-deco-installation__display">
							<img
								class="vfes-pb+ vfes-pr- mvf-deco-installation__img-mobile"
								src="{{
									appService.getImgFullPath('v10.myOrder.decoInstallation.img.conectCableFiberDeco' | translate)
								}}"
								alt="conectar cable fibra"
							/>
							<div class="vfes-align--left mvf-deco-installation__subpaso">
								<ol
									class="mvf-deco-installation__ordered-list mvf-deco-installation__font-small mvf-deco-installation__paso16"
									start="6"
								>
									<li class="vfes-pl-">
										<span class="vfes-title--regular">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Instruction1_6' | translate }}
										</span>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="vfes-title--bold vfes-pl- vfes-pb- mvf-deco-installation__fs20">
				{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2WithoutWireFibra' | translate }}
			</div>
			<!-- 2.1 -->
			<div class="mvf-deco-installation__display vfes-pb" id="MyOrdersSpDecoInstallationStep2SubStep21Card">
				<img
					class="vfes-pb vfes-pr- vfes-pl mvf-deco-installation__img-mobile mvf-deco-installation__remove_pl_mobile"
					src="{{ 'v10.myOrder.decoInstallation.img.step2_2Img6' | imgFullPath }}"
					alt="conectar cable ethernet"
				/>
				<div
					class="vfes-align--left mvf-deco-installation__subpaso"
					id="MyOrdersSpDecoInstallationStep2SubStep21Subpaso"
				>
					<ol
						class="mvf-deco-installation__ordered-list mvf-deco-installation__font-small mvf-deco-installation__paso2"
						start="2"
					>
						<li class="vfes-pl-">
							<span
								class="vfes-title--regular"
								[innerHtml]="'v10.myOrder.decoInstallation.guideInstallationDecoStep2Instruction2' | translate"
							>
							</span>
						</li>
					</ol>
				</div>
			</div>

			<!-- 3 -->
			<div
				class="mvf-deco-installation__cards mvf-deco-installation__display vfes-pb"
				id="MyOrdersSpDecoInstallationStep2SubStep3Card"
			>
				<img
					id="MyOrdersSpDecoInstallationStep2SubStep3Img"
					class="mvf-deco-installation__img-mobile"
					src="{{ 'v10.myOrder.decoInstallation.img.conectCableHDMI' | imgFullPath }}"
					alt="conectar cable HDMI"
				/>
				<div class="vfes-align--left" id="MyOrdersSpDecoInstallationStep2SubStep3TitleContent">
					<ol class="mvf-deco-installation__ordered-list vfes-pl mvf-deco-installation__font-small" start="3">
						<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
							<span class="vfes-title--bold" id="MyOrdersSpDecoInstallationStep2SubStep3Title">
								{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Instruction3Title' | translate }}
							</span>
							<p class="vfes-title--regular" id="MyOrdersSpDecoInstallationStep2SubStep3Desc">
								{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Intruction3Description' | translate }}
							</p>
						</li>
					</ol>
				</div>
			</div>

			<!-- 4 -->
			<div
				class="mvf-deco-installation__cards mvf-deco-installation__display vfes-pb"
				id="MyOrdersSpDecoInstallationStep2SubStep4Card"
			>
				<img
					id="MyOrdersSpDecoInstallationStep2SubStep4Img"
					class="mvf-deco-installation__img-mobile"
					src="{{ 'v10.myOrder.decoInstallation.img.conectpsu' | imgFullPath }}"
					alt="conectar fuente alimentacion"
				/>
				<div class="vfes-align--left" id="MyOrdersSpDecoInstallationStep2SubStep4Title">
					<ol class="mvf-deco-installation__ordered-list vfes-pl mvf-deco-installation__font-small" start="4">
						<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
							<span
								class="vfes-title--bold"
								[innerHtml]="paso2Instruccion4Titulo"
								id="MyOrdersSpDecoInstallationStep2SubStep4Title"
							>
							</span>
							<p class="vfes-title--regular" id="MyOrdersSpDecoInstallationStep2SubStep4Desc">
								{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Instruction4Description' | translate }}
							</p>
						</li>
					</ol>
				</div>
			</div>

			<div id="MyOrdersSpDecoInstallationStep2SubStep4Btn2" class="vfes-pb+ navigation-buttons">
				<mva10-c-button (mva10OnClick)="onNextButtonClick()">
					{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2_4Btn' | translate }}
				</mva10-c-button>
			</div>

			<div
				class="vfes-title--bold vfes-pb- mvf-deco-installation__fs20"
				id="MyOrdersSpDecoInstallationStep2SubStep8HTitle"
			>
				{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2_8Title' | translate }}
			</div>

			<div class="vfes-title--regular vfes-pb+" id="MyOrdersSpDecoInstallationStep2SubStep8HDesc">
				{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Instruction1' | translate }}
			</div>
			<div class="vfes-align--left" id="MyOrdersSpDecoInstallationStep2SubStep8TitleContent">
				<ol class="mvf-deco-installation__ordered-list" start="8">
					<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
						<span class="vfes-title--bold" id="MyOrdersSpDecoInstallationStep2SubStep8Title">
							{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Name' | translate }}
						</span>
					</li>
				</ol>
			</div>

			<div
				class="mvf-deco-installation__display mvf-deco-installation__pt16"
				id="MyOrdersSpDecoInstallationStep3SubStep8Card"
			>
				<div class="vfes-layout__wrapper">
					<div class="vfes-layout__item">
						<!-- 8.1 -->
						<div class="mvf-deco-installation__display vfes-pb+" id="MyOrdersSpDecoInstallationStep2SubStep81Card">
							<div
								class="mvf-deco-installation__placeholder vfes-pr- vfes-pl imgDeco mvf-deco-installation__remove_pl_mobile"
							></div>
							<div
								class="vfes-align--left mvf-deco-installation__subpaso"
								id="MyOrdersSpDecoInstallationStep2SubStep81Subpaso"
							>
								<ol
									class="mvf-deco-installation__ordered-list mvf-deco-installation__font-small mvf-deco-installation__paso81"
								>
									<li class="vfes-pl-">
										<span class="vfes-title--regular" id="MyOrdersSpDecoInstallationStep2SubStep8Desc81">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Instruction1_1' | translate }}
										</span>
									</li>
								</ol>
							</div>
						</div>

						<!-- 8.2 -->
						<div class="mvf-deco-installation__display vfes-pb-" id="MyOrdersSpDecoInstallationStep2SubStep82Card">
							<img
								id="MyOrdersSpDecoInstallationStep2SubStep8Img82"
								class="vfes-pb+ vfes-pr- vfes-pl imgDeco mvf-deco-installation__remove_pl_mobile"
								src="{{ 'v10.myOrder.decoInstallation.img.routercablefiber' | imgFullPath }}"
								alt="desenrosca cable fibra"
							/>
							<div
								class="vfes-align--left mvf-deco-installation__subpaso"
								id="MyOrdersSpDecoInstallationStep2SubStep82Subpaso"
							>
								<ol
									class="mvf-deco-installation__ordered-list mvf-deco-installation__font-small mvf-deco-installation__paso82"
									start="2"
								>
									<li class="vfes-pl-">
										<span class="vfes-title--regular" id="MyOrdersSpDecoInstallationStep2SubStep8Desc82">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Instruction1_2' | translate }}
										</span>
									</li>
								</ol>
							</div>
						</div>

						<!-- 8.3 -->
						<div class="mvf-deco-installation__display vfes-pb" id="MyOrdersSpDecoInstallationStep2SubStep83Card">
							<img
								id="MyOrdersSpDecoInstallationStep2SubStep8Img83"
								class="vfes-pb+ vfes-pr- vfes-pl imgDeco mvf-deco-installation__remove_pl_mobile"
								src="{{ 'v10.myOrder.decoInstallation.img.conectCoaxialSplitter' | imgFullPath }}"
								alt="enrosca cable coaxial en splitter"
							/>
							<div
								class="vfes-align--left mvf-deco-installation__subpaso"
								id="MyOrdersSpDecoInstallationStep2SubStep83Subpaso"
							>
								<ol
									class="mvf-deco-installation__ordered-list mvf-deco-installation__font-small mvf-deco-installation__paso83"
									start="3"
								>
									<li class="vfes-pl-">
										<span class="vfes-title--regular" id="MyOrdersSpDecoInstallationStep2SubStep8Desc83">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Instruction1_3' | translate }}
										</span>
									</li>
								</ol>
							</div>
						</div>

						<!-- 8.4 -->
						<div class="mvf-deco-installation__display vfes-pb" id="MyOrdersSpDecoInstallationSte23SubStep84Card">
							<img
								id="MyOrdersSpDecoInstallationStep2SubStep8Img84"
								class="vfes-pb+ vfes-pr- vfes-pl imgDeco mvf-deco-installation__remove_pl_mobile"
								src="{{ 'v10.myOrder.decoInstallation.img.hose' | imgFullPath }}"
								alt="conecta latiguillo entre splitter y router"
							/>
							<div
								class="vfes-align--left mvf-deco-installation__subpaso"
								id="MyOrdersSpDecoInstallationStep2SubStep84Subpaso"
							>
								<ol
									class="mvf-deco-installation__ordered-list mvf-deco-installation__font-small mvf-deco-installation__paso84"
									start="4"
								>
									<li class="vfes-pl-">
										<span class="vfes-title--regular" id="MyOrdersSpDecoInstallationStep2SubStep8Desc84">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Instruction1_4' | translate }}
										</span>
									</li>
								</ol>
							</div>
						</div>
						<!-- 8.5 -->
						<div class="mvf-deco-installation__display vfes-pb" id="MyOrdersSpDecoInstallationStep2SubStep85Card">
							<img
								id="MyOrdersSpDecoInstallationStep2SubStep8Img85"
								class="vfes-pb+ vfes-pr- vfes-pl imgDeco2 mvf-deco-installation__remove_pl_mobile"
								src="{{ 'v10.myOrder.decoInstallation.img.conectCableFiberDeco8_5' | imgFullPath }}"
								alt="conecta latiguillo entre splitter y router"
							/>
							<div
								class="vfes-align--left mvf-deco-installation__subpaso"
								id="MyOrdersSpDecoInstallationStep2SubStep85Subpaso"
							>
								<ol
									class="mvf-deco-installation__ordered-list mvf-deco-installation__font-small mvf-deco-installation__paso85"
									start="5"
								>
									<li class="vfes-pl-">
										<span class="vfes-title--regular" id="MyOrdersSpDecoInstallationStep2SubStep8Desc85">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Instruction8_5' | translate }}
										</span>
									</li>
								</ol>
							</div>
						</div>

						<!-- 8.6 -->
						<div class="mvf-deco-installation__display vfes-pb+" id="MyOrdersSpDecoInstallationStep2SubStep86Card">
							<img
								id="MyOrdersSpDecoInstallationStep2SubStep8Img86"
								class="vfes-pb+ vfes-pr- vfes-pl imgDeco2 mvf-deco-installation__remove_pl_mobile"
								src="{{ 'v10.myOrder.decoInstallation.img.cableFiberFilter8_6' | imgFullPath }}"
								alt="conecta latiguillo entre splitter y router"
							/>
							<div
								class="vfes-align--left mvf-deco-installation__subpaso"
								id="MyOrdersSpDecoInstallationStep2SubStep86Subpaso"
							>
								<ol
									class="mvf-deco-installation__ordered-list mvf-deco-installation__font-small mvf-deco-installation__paso86"
									start="6"
								>
									<li class="vfes-pl-">
										<span class="vfes-title--regular" id="MyOrdersSpDecoInstallationStep2SubStep8Desc86">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2Instruction8_6' | translate }}
										</span>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="mvf-deco-installation__cards mvf-deco-installation__display vfes-pb"
				id="MyOrdersSpDecoInstallationStep3SubStep9Card"
			>
				<img
					id="MyOrdersSpDecoInstallationStep2SubStep9Img"
					class="mvf-deco-installation__img-mobile"
					src="{{ 'v10.myOrder.routerInstallation.img.step2_14GEN3' | imgFullPath }}"
					alt="conectar fuente alimentacion"
				/>
				<div class="vfes-align--left" id="MyOrdersSpDecoInstallationStep2SubStep9Title">
					<ol class="mvf-deco-installation__ordered-list vfes-pl mvf-deco-installation__font-small" start="9">
						<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
							<span class="vfes-title--bold" id="MyOrdersSpDecoInstallationStep2SubStep9Title">
								{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2_9Title' | translate }}
							</span>
							<p class="vfes-title--regular" id="MyOrdersSpDecoInstallationStep2SubStep9Desc">
								{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2_9Desc' | translate }}
							</p>
						</li>
					</ol>
				</div>
			</div>
			<div
				class="mvf-deco-installation__cards mvf-deco-installation__display vfes-pb"
				id="MyOrdersSpDecoInstallationStep3SubStep10Card"
			>
				<img
					id="MyOrdersSpDecoInstallationStep2SubStep10Img"
					class="mvf-deco-installation__img-mobile"
					src="{{ 'v10.myOrder.routerInstallation.img.step2_15GEN3' | imgFullPath }}"
					alt="conectar fuente alimentacion"
				/>
				<div class="vfes-align--left" id="MyOrdersSpDecoInstallationStep2SubStep10Title">
					<ol class="mvf-deco-installation__ordered-list vfes-pl mvf-deco-installation__font-small" start="10">
						<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
							<span class="vfes-title--bold" id="MyOrdersSpDecoInstallationStep2SubStep10Title">
								{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2_10Title' | translate }}
							</span>
							<p class="vfes-title--regular" id="MyOrdersSpDecoInstallationStep2SubStep10Desc">
								{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2_10Desc' | translate }}
							</p>
						</li>
					</ol>
				</div>
			</div>
			<div
				class="mvf-deco-installation__cards mvf-deco-installation__display vfes-pb+"
				id="MyOrdersSpDecoInstallationStep3SubStep11Card"
			>
				<img
					id="MyOrdersSpDecoInstallationStep2SubStep11Img"
					class="mvf-deco-installation__img-mobile"
					src="{{ 'v10.myOrder.routerInstallation.img.step2_17GEN3' | imgFullPath }}"
					alt="conectar fuente alimentacion"
				/>
				<div class="vfes-align--left" id="MyOrdersSpDecoInstallationStep2SubStep11Title">
					<ol class="mvf-deco-installation__ordered-list vfes-pl mvf-deco-installation__font-small" start="11">
						<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
							<span
								class="vfes-title--bold"
								id="MyOrdersSpDecoInstallationStep2SubStep10Title"
								[innerHTML]="paso2Instruccion4Titulo"
							>
							</span>
							<p class="vfes-title--regular" id="MyOrdersSpDecoInstallationStep2SubStep11Desc">
								{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep2_10Desc' | translate }}
							</p>
						</li>
					</ol>
				</div>
			</div>
			<div id="MyOrdersSpDecoInstallationStep2Btn" class="vfes-pb navigation-buttons">
				<mva10-c-button (mva10OnClick)="onNextButtonClick()">
					{{ 'v10.myOrder.decoInstallation.installRouterStep2btn12' | translate }}
				</mva10-c-button>
			</div>
		</div>
	</div>

	<!-- Step 3 -->
	<div body id="page3">
		<div class="mvf-deco-installation__p16" id="MyOrdersSpDecoInstallationStep3SubStep1Content">
			<div class="vfes-align--left" id="MyOrdersSpDecoInstallationStep3SubStep1TitleContent">
				<ol class="mvf-deco-installation__ordered-list" start="5">
					<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
						<span class="vfes-title--bold" id="MyOrdersSpDecoInstallationStep3SubStep1Title">
							{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep3Title' | translate }}
						</span>
					</li>
				</ol>
			</div>

			<div class="vfes-title--regular vfes-pb">
				{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep3Description' | translate }}
			</div>

			<div class="mvf-deco-installation__display mvf-deco-installation__pt16 vfes-pb">
				<div class="vfes-layout__wrapper">
					<div class="vfes-layout__item">
						<!-- 1 -->
						<div class="mvf-deco-installation__cards mvf-deco-installation__display vfes-pb-">
							<img
								class="mvf-deco-installation__img4 vfes-pb"
								src="{{ appService.getImgFullPath('v10.myOrder.decoInstallation.img.softdataconection' | translate) }}"
								alt="conexion de datos"
							/>
							<div
								class="vfes-align--left mvf-deco-installation__subpaso"
								id="MyOrdersSpDecoInstallationStep3SubStep1Subpaso"
							>
								<ol
									class="mvf-deco-installation__ordered-list mvf-deco-installation__font-small mvf-deco-installation__paso51"
									start="1"
								>
									<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
										<span class="vfes-title--bold">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep3Instruction1Title' | translate }}
										</span>
										<p class="vfes-title--regular">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep3Instruction2Description' | translate }}
										</p>
									</li>
								</ol>
							</div>
						</div>

						<!-- 2 -->
						<div class="mvf-deco-installation__cards mvf-deco-installation__display vfes-pb">
							<img
								class="mvf-deco-installation__img4 vfes-pb"
								src="{{ appService.getImgFullPath('v10.myOrder.decoInstallation.img.softaccountverify' | translate) }}"
								alt="verificacion de la cuenta"
							/>
							<div
								class="vfes-align--left mvf-deco-installation__subpaso"
								id="MyOrdersSpDecoInstallationStep3SubStep2Subpaso"
							>
								<ol
									class="mvf-deco-installation__ordered-list mvf-deco-installation__font-small mvf-deco-installation__paso52"
									start="2"
								>
									<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
										<span class="vfes-title--bold">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep3Instruction2Title' | translate }}
										</span>
										<p class="vfes-title--regular">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep3Instruction2Description' | translate }}
										</p>
									</li>
								</ol>
							</div>
						</div>
						<div
							class="mvf-deco-installation__cards mvf-deco-installation__display vfes-pb"
							id="MyOrdersSpDecoInstallationStep3SubStep3Card"
						>
							<img
								id="MyOrdersSpDecoInstallationStep3SubStep3Img"
								class="mvf-deco-installation__img4 vfes-pb"
								src="{{ 'v10.myOrder.decoInstallation.img.softSyncChannels' | imgFullPath }}"
								alt="verificacion de la cuenta"
							/>
							<div class="vfes-align--left mvf-deco-installation__subpaso">
								<ol
									class="mvf-deco-installation__ordered-list mvf-deco-installation__font-small mvf-deco-installation__paso53"
									start="3"
								>
									<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
										<span class="vfes-title--bold">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep3Instruction3Title' | translate }}
										</span>
										<p class="vfes-title--regular">
											{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep3Instruction4Description' | translate }}
										</p>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="mvf-deco-installation__cards mvf-deco-installation__display vfes-pb"
				id="MyOrdersSpDecoInstallationStep3SubStep6Card"
			>
				<img
					id="MyOrdersSpDecoInstallationStep3SubStep6Img"
					class="widthImageControl"
					src="{{ 'v10.myOrder.routerInstallation.img.step3_remoteControl' | imgFullPath }}"
					alt="conectar fuente alimentacion"
				/>
				<div class="vfes-align--left mva10-u--pb2" id="MyOrdersSpDecoInstallationStep3SubStep6TitleContent">
					<ol class="mvf-deco-installation__ordered-list vfes-pl mvf-deco-installation__font-small" start="6">
						<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
							<span class="vfes-title--bold" id="MyOrdersSpDecoInstallationStep3SubStep6Title">
								{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep3_6Title' | translate }}
							</span>
							<p
								class="vfes-title--regular"
								id="MyOrdersSpDecoInstallationStep3SubStep6Desc"
								[innerHTML]="'v10.myOrder.routerInstallation.step3_10Desc' | translate"
							></p>
						</li>
					</ol>
				</div>
			</div>
			<div
				class="mvf-deco-installation__cards mvf-deco-installation__display vfes-pb+"
				id="MyOrdersSpDecoInstallationStep3SubStep7Card"
			>
				<img
					id="MyOrdersSpDecoInstallationStep3SubStep7Img"
					class="mvf-deco-installation__img-mobile vfes-pb height-max"
					src="{{ 'v10.myOrder.routerInstallation.img.step3_11' | imgFullPath }}"
					alt="conectar fuente alimentacion"
				/>
				<div class="vfes-align--left" id="MyOrdersSpDecoInstallationStep3SubStep7TitleContent">
					<ol class="mvf-deco-installation__ordered-list vfes-pl mvf-deco-installation__font-small" start="7">
						<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
							<span class="vfes-title--bold" id="MyOrdersSpDecoInstallationStep3SubStep7Title">
								{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep3_7Title' | translate }}
							</span>
							<p
								class="vfes-title--regular"
								id="MyOrdersSpDecoInstallationStep3SubStep7Desc"
								[innerHTML]="'v10.myOrder.routerInstallation.step3_11Desc' | translate"
							></p>
						</li>
					</ol>
				</div>
			</div>
			<div id="MyOrdersSpDecoInstallationStep3SubStep7Btn" class="vfes-pb+ navigation-buttons">
				<mva10-c-button (mva10OnClick)="onNextButtonClick()" id="MyOrdersSpDecoInstallationStep3SubStep7BtnMva">
					{{ 'v10.myOrder.decoInstallation.understood' | translate }}
				</mva10-c-button>
			</div>
			<div class="vfes-pb" id="MyOrdersSpDecoInstallationStep3SubStep12CardContent">
				<div
					class="mvf-deco-installation__cards mvf-deco-installation__display"
					id="MyOrdersSpDecoInstallationStep3SubStep12Card"
				>
					<img
						id="MyOrdersSpDecoInstallationStep3SubStep12Img"
						class="mvf-deco-installation__img-mobile vfes-pb"
						src="{{ 'v10.myOrder.decoInstallation.img.step3_12ImgA' | imgFullPath }}"
						alt="conectar fuente alimentacion"
					/>
					<div class="vfes-align--left" id="MyOrdersSpDecoInstallationStep3SubStep12TitleContent">
						<ol class="mvf-deco-installation__ordered-list vfes-pl mvf-deco-installation__font-small" start="12">
							<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
								<span class="vfes-title--bold" id="MyOrdersSpDecoInstallationStep3SubStep12Title">
									{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep3_12Title' | translate }}
								</span>
								<p class="vfes-title--regular" id="MyOrdersSpDecoInstallationStep3SubStep12Desc">
									{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep3_12DescA' | translate }}
								</p>
							</li>
						</ol>
					</div>
				</div>
				<div
					class="mvf-deco-installation__cards mvf-deco-installation__display"
					id="MyOrdersSpDecoInstallationStep3SubStep12BCard"
				>
					<img
						id="MyOrdersSpDecoInstallationStep3SubStep12BImgB"
						class="mvf-deco-installation__img-mobile"
						src="{{ 'v10.myOrder.decoInstallation.img.step3_12ImgB' | imgFullPath }}"
						alt="conectar fuente alimentacion"
					/>
					<div class="vfes-align--left" id="MyOrdersSpDecoInstallationStep3SubStep12BTitleContent">
						<ol class="mvf-deco-installation__ordered-list vfes-pl mvf-deco-installation__font-small nonelists">
							<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
								<p class="vfes-title--regular" id="MyOrdersSpDecoInstallationStep3SubStep12Desc">
									{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep3_12DescB' | translate }}
								</p>
							</li>
						</ol>
					</div>
				</div>
			</div>
			<div
				class="mvf-deco-installation__cards mvf-deco-installation__display vfes-pb"
				id="MyOrdersSpDecoInstallationStep3SubStep13Card"
			>
				<img
					id="MyOrdersSpDecoInstallationStep3SubStep13Img"
					class="widthImageControl"
					src="{{ 'v10.myOrder.routerInstallation.img.step3_remoteControl' | imgFullPath }}"
					alt="connection control"
				/>
				<div class="vfes-align--left mva10-u--pb2" id="MyOrdersSpDecoInstallationStep3SubStep13TitleContent">
					<ol class="mvf-deco-installation__ordered-list vfes-pl mvf-deco-installation__font-small" start="13">
						<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
							<span class="vfes-title--bold" id="MyOrdersSpDecoInstallationStep3SubStep13Title">
								{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep3_6Title' | translate }}
							</span>
							<p
								class="vfes-title--regular"
								id="MyOrdersSpDecoInstallationStep3SubStep13Desc"
								[innerHTML]="'v10.myOrder.routerInstallation.step3_10Desc' | translate"
							></p>
						</li>
					</ol>
				</div>
			</div>
			<div
				class="mvf-deco-installation__cards mvf-deco-installation__display vfes-pb+"
				id="MyOrdersSpDecoInstallationStep3SubStep14Card"
			>
				<img
					id="MyOrdersSpDecoInstallationStep3SubStep14Img"
					class="mvf-deco-installation__img-mobile vfes-pb height-max"
					src="{{ 'v10.myOrder.routerInstallation.img.step3_11GEN3' | imgFullPath }}"
					alt="control gen3"
				/>
				<div class="vfes-align--left" id="MyOrdersSpDecoInstallationStep3SubStep14TitleContent">
					<ol class="mvf-deco-installation__ordered-list vfes-pl mvf-deco-installation__font-small" start="14">
						<li class="vfes-pl- mvf-deco-installation__remove_pl_mobile">
							<span class="vfes-title--bold" id="MyOrdersSpDecoInstallationStep3SubStep14Title">
								{{ 'v10.myOrder.decoInstallation.guideInstallationDecoStep3_7Title' | translate }}
							</span>
							<p
								class="vfes-title--regular"
								id="MyOrdersSpDecoInstallationStep3SubStep14Desc"
								[innerHTML]="'v10.myOrder.routerInstallation.step3_20Desc' | translate"
							></p>
							<p
								class="vfes-title--regular"
								id="MyOrdersSpDecoInstallationStep3SubStep14DescB"
								[innerHTML]="'v10.myOrder.routerInstallation.step3_20DescB' | translate"
							></p>
							<p class="vfes-title--regular" id="MyOrdersSpDecoInstallationStep3SubStep14DescC">
								{{ 'v10.myOrder.routerInstallation.step3_20DescC' | translate }}
							</p>
						</li>
					</ol>
				</div>
			</div>
		</div>
		<div id="MyOrdersSpDecoInstallationStep3SubStep14Btn" class="vfes-pb navigation-buttons">
			<mva10-c-button (mva10OnClick)="onNextButtonClick()" id="MyOrdersSpDecoInstallationStep3SubStep7BtnMva">
				{{ 'v10.myOrder.decoInstallation.understood' | translate }}
			</mva10-c-button>
		</div>
	</div>

	<!-- Step 4 -->
	<div body id="page4">
		<div class="mvf-deco-installation__summary">
			<img
				id="MyOrdersSpDecoInstallationStepImg"
				class="mvf-deco-installation__img-mobile vfes-pb"
				src="{{ 'v10.myOrder.nebaInstallationTvUnicast.img.guideNebaTvUnicastStep4Img' | imgFullPath }}"
				alt="summary"
			/>
			<div class="mva10-u--mb5" id="MyOrdersSpDecoInstallationStep4ContentTitle">
				<span
					id="MyOrdersSpDecoInstallationStep4Title"
					class="vfes-title--bold mvf-deco-installation__fs26"
					[innerHtml]="'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep4SubTitleSummary' | translate"
				>
				</span>
			</div>
			<div class="vfes-title--regular vfes-pb+" id="MyOrdersSpDecoInstallationStep4ContentDescription">
				<span
					id="MyOrdersSpDecoInstallationStep4Description"
					[innerHtml]="'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep4DescriptionSummary' | translate"
				>
				</span>
			</div>
		</div>
		<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
	</div>
</vfac-overlay>

<ng-template #navigationButtonsTemplate>
	<!-- Navigation buttons -->
	<div class="mvf-deco-installation__button-top vfes-align--center navigation-buttons vfes-pb">
		<mva10-c-button (mva10OnClick)="onNextButtonClick()">
			{{
				(disableSchamanCheck
					? selectedStep === pagesNames.length - 1
						? 'v10.myOrder.decoInstallation.understood'
						: 'v10.myOrder.decoInstallation.installRouterContinue'
					: selectedStep === pagesNames.length - 2
					? 'v10.myOrder.decoInstallation.autoiCheckInstallationButton'
					: 'v10.myOrder.decoInstallation.installRouterContinue'
				) | translate
			}}
		</mva10-c-button>
	</div>
</ng-template>

<sp-check-installation-overlay
	[openFrom]="pageName"
	*ngIf="showOverlayCheckInstallation"
	(close)="closeCheckInstallationOverlay($event)"
>
</sp-check-installation-overlay>
