import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'convertToAsterisks',
})
export class ConvertToAsterisksPipe implements PipeTransform {
	transform(input: any, nOfLastDigit: number, convertTo: any, args?: any): any {
		let i;
		let output = '';
		const charToConvert = input.substring(0, input.length - nOfLastDigit);
		const char = input.substring(input.length - nOfLastDigit);
		if (input.length > 0) {
			for (i = 0; i < charToConvert.length; i++) {
				output += charToConvert.replace(charToConvert, convertTo);
			}
			output += char;
			return output;
		} else {
			return '';
		}
	}
}
