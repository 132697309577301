import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { constants } from '../../../models/constants';
import { Category, CreateAutoiTicketResponse, Ticket } from '../../../models/autoiTicketResponse.model';
import { AutoiHelp } from '../../../models/autoiHelp.model';
import { TicketType } from '../../../enums/ticket-type.enum';
import { AutoiOption } from '../../../enums/autoi-option.enum';
import {
	AutoiSchamanCheckResponse,
	AutoiSchamanStatusResponse,
	AutoiSchamanResolution,
	AutoiCreateSchamanCheckModel,
} from '../../../models/autoiSchaman.model';
import { SchamanResolutions, SchamanState } from '../../../enums/schaman-resolutions.enum';
import { finalize, map } from 'rxjs/operators';
import { MyOrdersService } from './my-orders.service';
import { API_URLS } from '../../../constants/routes-config';
import { StorageService } from '../../../../core/services/storage.service';
import * as defines from '../../../constants/defines';
import { AutoiTicket } from '../../../models/autoiTickets.model';
import { TicketStatus } from '../../../enums/ticket-status.enum';
import { SchamanTags } from '../../../enums/schaman-tags.enum';

@Injectable({
	providedIn: 'root',
})
export class TicketsService {
	checkingAutoiTickets: boolean;
	ticketList: Record<TicketType, Ticket[]>;
	ticketDetailType: TicketType;
	ticketDetailId: string;

	autoiHelp: AutoiHelp = {
		optionsToShow: [],
		showWrongCablesOption: false,
		showPackageNotReceivedOption: false,
		showNotWorkingOption: false,
		showChatOption: false,
	};

	constructor(
		private myOrdersService: MyOrdersService,
		public translate: TranslateService,
		private http: HttpClient,
		private storageService: StorageService
	) {
		this.ticketList = {
			gp: null,
			tia: null,
			Averia: null,
		};
		this.initAutoiOptions();
	}

	initAutoiOptions(): void {
		this.autoiHelp.optionsToShow = [];
		if (
			constants.trueLiteral.includes(
				this.translate.instant('v10.myOrder.autoiHelpModal.ticketsService.OptionCablesEnable')
			)
		) {
			this.autoiHelp.optionsToShow.push(AutoiOption.WRONG_CABLES);
		}
		if (
			constants.trueLiteral.includes(
				this.translate.instant('v10.myOrder.autoiHelpModal.ticketsService.OptionDeliveryEnable')
			)
		) {
			this.autoiHelp.optionsToShow.push(AutoiOption.NOT_RECEIVED);
		}
		if (
			constants.trueLiteral.includes(
				this.translate.instant('v10.myOrder.autoiHelpModal.ticketsService.OptionServiceEnable')
			)
		) {
			this.autoiHelp.optionsToShow.push(AutoiOption.NOT_WORKING);
		}
		if (
			constants.trueLiteral.includes(
				this.translate.instant('v10.myOrder.autoiHelpModal.ticketsService.OptionChatEnable')
			)
		) {
			this.autoiHelp.optionsToShow.push(AutoiOption.CHAT);
		}
	}

	getAutoiOpenTickets(
		ticketType: TicketType,
		clientId: string = this.myOrdersService.MYORDERS_DATA.idCliente
	): Observable<Ticket[]> {
		this.checkingAutoiTickets = true;
		return this.getAutoiOpenTicketsResponse(ticketType, clientId).pipe(
			map((res) => {
				const tickets: Ticket[] = [];
				const categorys: Category[] = [];
				res.forEach((item) => {
					const ticket: Ticket = {
						idTicket: '',
					};
					ticket.idTicket = item.id;
					ticket.name = item.name;
					ticket.created = new Date(item.creationDate).getTime();
					if (item.status === 'Abierto' || item.status === 'abierto') {
						ticket.status = TicketStatus.Open;
					} else if (item.status === 'Cerrado' || item.status === 'cerrado') {
						ticket.status = TicketStatus.Closed;
					} else {
						ticket.status = TicketStatus.Solved;
					}
					item.note.forEach((item) => {
						const category: Category = {
							name: item.id || '',
							value: item.text || '',
						};
						categorys.push(category);
					});
					ticket.categories = categorys;
					tickets.push(ticket);
				});
				this.ticketList[ticketType.toLowerCase()] = tickets || [];
				return this.ticketList[ticketType.toLowerCase()];
			}),
			finalize(() => {
				this.checkingAutoiTickets = false;
			})
		);
	}

	getAutoiOpenTicketsResponse(
		ticketType: TicketType,
		clientId: string = this.myOrdersService.MYORDERS_DATA.idCliente
	): Observable<AutoiTicket[]> {
		const apiUrl: string = API_URLS.Ticket.getAutoiTickets
			.replace('{siteId}', clientId)
			.replace('{ticketType}', ticketType);
		const httpOptions: { [key: string]: HttpHeaders } = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			}),
		};
		httpOptions.headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(defines.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		return this.http.get<AutoiTicket[]>(apiUrl, httpOptions);
	}

	createNewAutoiTicket(ticketType: TicketType, orderId: string, clientId: string): Observable<string> {
		const url: string = API_URLS.Ticket.post;
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('X-VF-API-Process', 'CreaTicketTIAGestPack');
		const options: { [key: string]: HttpHeaders } = {
			headers: headers,
		};
		const body: Object = {
			ticketType: ticketType.toUpperCase(),
			description: 'Creación ticket ' + ticketType.toUpperCase(),
			severity: 1,
			relatedEntity: [
				{
					role: 'ProductOrder',
					id: orderId,
				},
			],
			relatedParty: [
				{
					id: clientId,
					role: 'Customer',
				},
			],
		};
		return this.http.post<CreateAutoiTicketResponse>(url, body, options).pipe(
			map((res: CreateAutoiTicketResponse) => {
				return res?.id;
			})
		);
	}

	getAutoiTicketDetailById(
		ticketType: TicketType = this.ticketDetailType,
		ticketId: string = this.ticketDetailId
	): Observable<Ticket> {
		const foundTicket: Ticket = this.ticketList[ticketType.toLowerCase()]?.find(
			(ticket: Ticket) => ticket.idTicket === ticketId
		);
		if (foundTicket) {
			return of(foundTicket);
		} else {
			return this.getAutoiOpenTickets(ticketType).pipe(
				map((ticketsFromService: Ticket[]) => this.findTicket(ticketsFromService, ticketId, ticketType))
			);
		}
	}

	public findTicket(ticketList: Ticket[], ticketId: string, ticketType: TicketType): Ticket {
		const foundFromService: Ticket = ticketList.find((ticket: Ticket) => ticket.idTicket === ticketId);
		if (foundFromService) {
			return foundFromService;
		} else {
			throw new Error(`${ticketType.toUpperCase()} ticket ${ticketId} not found`);
		}
	}

	createAutoiServiceCheck(
		testData?: AutoiCreateSchamanCheckModel,
		clientId: string = this.myOrdersService.MYORDERS_DATA.idCliente
	): Observable<string> {
		const url = API_URLS.faultManagement.getTicketTemplate.replace('{CustomerAccountId}', clientId);
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options = {
			headers: headers,
		};
		const body: Object = {
			taskId: 'createServiceTest',
			serviceType: 'sevice_test',
			inputs: [
				{
					name: 'type',
					value: testData?.type || 'serviceDiagnosis',
				},
				{
					name: 'serviceIdentifier',
					value: testData?.serviceIdentifier || '',
				},
				{
					name: 'serviceIdType',
					value: testData?.serviceIdType || 'siteid',
				},
				{
					name: 'sourceDetail',
					value: testData?.sourceDetail || 'ikki',
				},
				{
					name: 'sourceInteractionId',
					value: testData?.sourceInteractionId || `${clientId}_${Date.now()}`,
				},
				{
					name: 'tags',
					value: testData?.tags || SchamanTags.NONE,
				},
				{
					name: 'id',
					value: testData?.id || 'ikki',
				},
				{
					name: 'tv_problem',
					value: testData?.tv_problem || 'false',
				},
				{
					name: 'complain_noise',
					value: testData?.complain_noise || 'false',
				},
				{
					name: 'calling_from_diagnosedservice',
					value: testData?.calling_from_diagnosedservice || 'false',
				},
				{
					name: 'incoming_phone_number',
					value: testData?.incoming_phone_number || '',
				},
			],
		};
		return this.http.post(url, body, options).pipe(
			map((res: AutoiSchamanCheckResponse) => {
				return this.mapAutoiSchamanCheckResponse(res);
			})
		);
	}

	public mapAutoiSchamanCheckResponse(response: AutoiSchamanCheckResponse): string {
		if (response?.ticketId) {
			return response.ticketId;
		}
		throw new Error('No idExecution returned');
	}

	getAutoiServiceCheckStatus(
		executionId: string,
		clientId: string = this.myOrdersService.MYORDERS_DATA.idCliente
	): Observable<AutoiSchamanResolution> {
		const url = API_URLS.faultManagement.getTicketTemplate.replace('{CustomerAccountId}', clientId);
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options = {
			headers: headers,
		};
		const body = {
			taskId: 'retrieveServiceTest',
			ticketId: executionId,
			serviceType: 'sevice_test',
		};
		return this.http.post(url, body, options).pipe(map(this.mapAutoiSchamanResponse));
	}

	public mapAutoiSchamanResponse(response: AutoiSchamanStatusResponse): AutoiSchamanResolution {
		if (response) {
			const body: AutoiSchamanResolution = {
				troubleshootId: response.message?.toLowerCase() || '',
				state: (response.status?.toLowerCase() || '') as SchamanState,
				resolution: (response.symptom?.toLowerCase() || '') as SchamanResolutions,
			};
			return body;
		}
		throw new Error('No Schaman resolution returned');
	}

	advanceTicketStatus(
		orderId: string,
		clientId: string = this.myOrdersService.MYORDERS_DATA.idCliente
	): Observable<unknown> {
		const url: string = API_URLS.ProductOrderingManagement.ProductOrderUpdate.replace('{id}', orderId);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options = {
			headers: headers,
		};
		const body = {
			productOrderItem: [
				{
					id: defines.productOrderItemDefines.id,
					name: defines.productOrderItemDefines.name,
					state: defines.productOrderItemDefines.state,
					action: defines.productOrderItemDefines.action,
				},
			],
			relatedParty: [
				{
					'role': defines.digitalKits.roleCustomer,
					'id': clientId,
					'@referredType': defines.digitalKits.roleCustomer,
				},
			],
		};
		return this.http.patch(url, body, options).pipe(
			map((res) => {
				return res;
			})
		);
	}
}
