import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environmentMock } from '../../../../models/environmentMock';

@Component({
	selector: 'sp-loader',
	templateUrl: './sp-loader.component.html',
	styleUrls: ['./sp-loader.component.scss'],
})
export class SpLoaderComponent implements OnInit {
	@Input() fullHeight: boolean;
	@Input() showError: boolean;
	@Input() showSpinner: boolean;
	content: { [key: string]: string };
	src: string;
	constructor(public translate: TranslateService) {}

	ngOnInit(): void {
		this.content = {
			errorTitle: this.translate.instant('v10.myOrder.loader.apologies'),
			errorContent: this.translate.instant('v10.myOrder.loader.tryagain'),
		};
		this.src = environmentMock.production
			? '/c/mivodafone/es/childpagosdeuda/static/img/loading_03.gif'
			: '/src/assets/images/loading_03.gif';
	}
}
