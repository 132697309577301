export class DeliveryOffice {
	acceso: string;
	ccaa: string;
	codHorario: string;
	codProvincia: string;
	codigoPostal: string;
	codired: string;
	coordXETRS89: string;
	coordXWGS84: string;
	coordYETRS89: string;
	coordYWGS84: string;
	direccion: string;
	distanciaPuntoRef: number;
	horario: string;
	id: number;
	latitud: number;
	latitudETRS89: string;
	latitudWGS84: string;
	localidad: string;
	longitud: number;
	longitudETRS89: string;
	longitudWGS84: string;
	nombreUnidad: string;
	provincia: string;
	provinciaEs: string;
	telefono: string;
	checked?: boolean;
}
