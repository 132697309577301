<div class="marketplace">
	<div class="marketplace__header" *ngIf="!appService.loadedFromWebview">
		<sp-marketplace-tabs
			*ngIf="marketplaceService.hasMarketPlaceMyOrdersEP && marketplaceService.showTabs"
			[pageTagData]="pageTagData"
		>
		</sp-marketplace-tabs>
	</div>

	<sp-marketplace-iframe
		*ngIf="
			!appService.loadedFromWebview &&
			marketplaceService.hasMarketPlaceMyOrdersEP &&
			marketplaceService.marketplaceMyOrdersUrl &&
			marketplaceService.tabMarketplace
		"
		class="marketplace__body"
		[url]="marketplaceService.marketplaceMyOrdersUrl"
	>
	</sp-marketplace-iframe>

	<!-- Cuando no este en la ruta myorders2-->
	<iframe
		title=""
		class="marketplace__body"
		id="myOrdersIframe"
		*ngIf="!showMyOrders2 && showIframe && (marketplaceService.tabShop || !marketplaceService.hasMarketPlaceMyOrdersEP)"
		[src]="urlIframe"
		(load)="onIframeLoad()"
	>
	</iframe>

	<!-- Si esta en la routa myorders2-->
	<sp-main *ngIf="showMyOrders2 && (marketplaceService.tabShop || !marketplaceService.hasMarketPlaceMyOrdersEP)">
	</sp-main>
</div>

<!-- Cancel firma digital modal -->
<sp-modal-full #modalCancelSignProcess>
	<div class="my-orders-cancel-sign-modal">
		<div class="my-orders-cancel-sign-modal-img">
			<img
				id="myOrdersCancelSignModalIcon"
				class="my-orders-cancel-sign-modal-img-file"
				src="https://assets-es-dev.dxldev.local.vodafone.es/assets/images/ic-warning-hi-light-theme@2x.png"
				alt="ic-warning-hi-light-theme@2x.png"
			/>
		</div>
		<p id="myOrdersCancelSignModalTitle" class="my-orders-cancel-sign-modal-title">
			{{ 'v10.digitalSignature.abandonProcessOverlay.title' | translate }}
		</p>
		<p id="myOrdersCancelSignModalDescription" class="my-orders-cancel-sign-modal-subtible mva10-u-body">
			{{ 'v10.digitalSignature.abandonProcessOverlay.description' | translate }}
		</p>
	</div>
</sp-modal-full>

<!-- Max OTP retries modal -->
<sp-modal-full #modalMaxOtp>
	<div class="my-orders-max-otp-modal">
		<div class="my-orders-max-otp-modal-img">
			<img
				id="myOrdersMaxOtpModalIcon"
				class="my-orders-max-otp-modal-img-file"
				src="https://assets-es-dev.dxldev.local.vodafone.es/assets/images/ic-warning-hi-light-theme@2x.png"
				alt="ic-warning-hi-light-theme@2x.png"
			/>
		</div>
		<p id="myOrdersMaxOtpModalTitle" class="my-orders-max-otp-modal-title">
			{{ 'v10.digitalSignature.maxOtpOverlay.title' | translate }}
		</p>
		<p id="myOrdersMaxOtpModalDescription" class="my-orders-max-otp-modal-subtible mva10-u-body">
			{{ 'v10.digitalSignature.maxOtpOverlay.description' | translate }}
		</p>
	</div>
</sp-modal-full>

<!-- Generic error modal -->
<sp-modal-full #modalGenericError>
	<div class="my-orders-generic-error-modal">
		<div class="my-orders-generic-error-modal-img">
			<img
				id="myOrdersGenericErrorModalIcon"
				class="my-orders-generic-error-modal-img-file"
				src="https://assets-es-dev.dxldev.local.vodafone.es/assets/images/ic-warning-hi-light-theme@2x.png"
				alt="ic-warning-hi-light-theme@2x.png"
			/>
		</div>
		<p id="myOrdersGenericErrorModalTitle" class="my-orders-generic-error-modal-title">
			{{ 'v10.common.literals.msg.error.ups_seems' | translate }}
		</p>
		<p id="myOrdersGenericErrorModalDescription" class="my-orders-generic-error-modal-subtible mva10-u-body">
			{{ 'v10.common.literals.msg.error.technical_problem' | translate }}
		</p>
	</div>
</sp-modal-full>
