<div class="jumpbotronContainer-navigator container">
	<a id="SpJumpbotronContainerNavigatorComp_a_navigate" (click)="navigate()">
		<div class="jumpbotronContent-navigator row">
			<div class="col-xs-10">
				<div class="jumpbotronTitle-navigator" *ngIf="title">
					<p>{{ title }}</p>
				</div>
				<div class="jumpbotronDescription-navigator" *ngIf="description">
					<span> {{ description }} </span>
				</div>
			</div>
			<div class="col-xs-2">
				<div class="jumpbotronIcons-navigator">
					<span class="icon icon-chevron-right red" [ngClass]="{ black: isBlack }"> </span>
				</div>
			</div>
		</div>
	</a>
</div>
