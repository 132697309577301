import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { AutoiOption } from '../../../enums/autoi-option.enum';
import { CancelarCanjeStatus } from '../../../enums/canje-terminal.enum';
import { DecoGuideType, NebaTvGuideType, NebaGuideType, RouterGuideType } from '../../../enums/guide-types.enum';
import { OLStatus } from '../../../enums/ol-status.enum';
import { orderTypes, StatePedido } from '../../../enums/order-type.enum';
import { TicketType } from '../../../enums/ticket-type.enum';
import { Ticket } from '../../../models/autoiTicketResponse.model';
import { constants } from '../../../models/constants';
import { DataPortabilidadModel } from '../../../models/dataPortabilidad.model';
import { DetallePedidoExtended, Paso, PortabilidadRechazada } from '../../../models/detalleSeguimientoPedido.model';
import { HistoryStatusDetail } from '../../../models/historyStatusMap.model';
import { OrdenObtenerPedidos } from '../../../models/obtenerPedidos.model';
import { HistoricoOL, PedidoHistoricoOLResponse } from '../../../models/pedidoHistoricoOL.model';
import { HistoryOlService } from '../services/history-ol.service';
import { MyOrdersService } from '../services/my-orders.service';
import { OrdersService } from '../services/orders.service';
import { PortabilityService } from '../services/portability.service';
import { ResponseBoxUtilsService } from '../services/response-box-utils.service';
import { TicketsService } from '../services/tickets.service';
import { UtilsService } from '../services/utils.service';
import { SchamanTags } from '../../../enums/schaman-tags.enum';
import { SchamanServiceType } from '../../../enums/schaman-service-type.enum';
import { SlidService } from '../services/slid.service';
import { config } from '../../../../../config/pages-config';
import { MyOrdersTaggingService } from '../../../services/my-orders-tagging.service';
import {
	buttonBreakdown,
	setOB,
	stringOfTrue,
	VodafoneTV,
	tabsMyOrders,
	autoiMigration,
} from '../../../constants/defines';
import { BodySLIDs } from '../../../models/bodyslid.model';
import { OverlayFullScreenModel, OverlayModel } from '@mva10/mva10-angular';
import { OrderFlow } from '../../../enums/order-flow.enum';
import { TypeFlow } from '../../../enums/step-flow.enum';
import { GetOrder, TaskFlow } from '../../../models/getOrder.model';
import { MyOrdersTagService } from '../services/myOrderTag.service';
import { SpModalFullComponent } from '../../sp-modal-full/sp-modal-full.component';

@Component({
	selector: 'sp-detail',
	templateUrl: './sp-detail.component.html',
	styleUrls: ['./sp-detail.component.scss'],
})
export class SpDetailComponent implements OnInit, OnDestroy {
	@Input() orderData: OrdenObtenerPedidos;
	@Input() icon: string;
	@Input() titleFecha: string;
	@Output() closeDetail: EventEmitter<void> = new EventEmitter<void>();
	botonCita: string;
	docOptions: string[];
	docType: string;
	numDoc: string;
	numPortar: string;
	nombre: string;
	apellido: string;
	apellido2: string;
	iccid: string;
	orderOLHistory: PedidoHistoricoOLResponse;
	trueLiteral: (string | boolean)[] = constants.trueLiteral;
	numValid: boolean;
	dniValid: boolean;
	disableButton: boolean;
	dataPort: DataPortabilidadModel;
	showSpinner: boolean;
	showError: boolean;
	showLandlinePortabilityGuide: boolean = false;
	showCancelTerminalModal: boolean = false;
	showOverlayCheckInstallation: boolean = false;
	overlaySchamanServiceType: SchamanServiceType;
	overlaySchamanCheckType: SchamanTags;
	showOverlayAutoiHelp: boolean;
	TicketTypeEnum: typeof TicketType = TicketType;
	DecoGuideType: typeof DecoGuideType = DecoGuideType;
	RouterGuideType: typeof RouterGuideType = RouterGuideType;
	NebaGuideType: typeof NebaGuideType = NebaGuideType;
	NebaTvGuideType: typeof NebaTvGuideType = NebaTvGuideType;
	StatePedido: typeof StatePedido = StatePedido;
	showAutoiCard: boolean;
	mostrarBtnCancelarCanje: boolean;
	olDetail: HistoryStatusDetail;
	checkInstallationInGuide: boolean;
	showRouterActivationGuide: boolean;
	showRouterInstallationGuide: boolean;
	showDecoInstallationGuide: boolean;
	showDecoGen3InstallationGuide: boolean;
	showHistoryOLOverlay: boolean;
	showNebaInstallationGuide: boolean;
	showNebaDesktopOverlay: boolean;
	showNebaInstallationTvGuide: boolean;
	showNebaInstallationTvMulticastGuide: boolean;
	showNebaInstallationTvUnicastGuide: boolean;
	stringOfTrue: string = stringOfTrue;
	showDetailOverlayFullScreenModal: boolean = false;
	overlayFullScreenData: OverlayFullScreenModel = {
		closeBtn: true,
		background: '#f4f4f4',
	};
	public showBackDropOBDetail: boolean;
	public urlOB: string;
	public xrContentsOverlayDataDetail: OverlayModel;
	public currentOrdersDetail: GetOrder;

	public flowDetail24: string = buttonBreakdown.flow24;
	public flowDetail25: string = buttonBreakdown.flow25;
	public stateActivation: string = tabsMyOrders.stateActivation;
	public separator: string = tabsMyOrders.separator;
	public guion: string = tabsMyOrders.guion;
	public forWardSlash: string = tabsMyOrders.forWardSlash;
	public ofString: string = tabsMyOrders.ofString;
	public formatDate: string = tabsMyOrders.formatDate;
	public formatDay: string = tabsMyOrders.formatDay;
	public formatMonth: string = tabsMyOrders.formatMonth;
	public formatX: string = tabsMyOrders.formatX;
	public orderFlowDetail: string = OrderFlow.FLUJO_7;
	public overlayDataDetail: OverlayModel = new OverlayModel();
	public openBackDropDetail: boolean = false;
	public showOverlayCheckInstallationDetail: boolean;
	@ViewChild(autoiMigration.modalKoDetail) modalKoDetail: SpModalFullComponent;
	public detallePedido: DetallePedidoExtended;
	public four_one_detail: string = autoiMigration.four_one;
	public four_two_detail: string = autoiMigration.four_two;

	constructor(
		public ordersService: OrdersService,
		public olService: HistoryOlService,
		public utilsService: UtilsService,
		public myOrdersService: MyOrdersService,
		private router: Router,
		private responseBoxUtils: ResponseBoxUtilsService,
		readonly ticketsService: TicketsService,
		private portabilityService: PortabilityService,
		public translate: TranslateService,
		public appService: AppService,
		public slidService: SlidService,
		private tagging: MyOrdersTaggingService,
		public route: ActivatedRoute,
		public myOrdersTagService: MyOrdersTagService
	) {}

	ngOnInit(): void {
		this.disableButton = true;
		this.docOptions = [
			this.translate.instant('v10.myOrder.detail.relaunchPortCIF'),
			this.translate.instant('v10.myOrder.detail.relaunchPortNIF'),
			this.translate.instant('v10.myOrder.detail.relaunchPortNIE'),
			this.translate.instant('v10.myOrder.detail.relaunchPortPassport'),
		];
		this.setTokenOBDetail();
		this.showSpinner = true;
		this.olService.orderLastOLStatus = null;
		const detalle: DetallePedidoExtended = this.ordersService.getDetalle(this.orderData.numeroPedido);
		this.detallePedido = detalle;
		this.ordersService.flow = this.detallePedido.orderFlow;
		this.showSpinner = false;
		this.setButton(detalle);
		this.mostrarBtnCancelarCanje =
			detalle.cancelarCanje === CancelarCanjeStatus.CANCELLABLE &&
			constants.trueLiteral.includes(this.translate.instant('v10.myOrder.detail.showCancelButton'));

		this.utilsService.flowNebaTV =
			detalle.orderFlow.includes(OrderFlow.FLUJO_500) && detalle.incluidoPedido.includes(VodafoneTV)
				? TypeFlow.AUTOI_NEBA_PORTA_TV
				: TypeFlow.AUTOI_NEBA_PORTA;

		setTimeout(() => {
			const portabilidadRechazada: PortabilidadRechazada = this.ordersService.detailOrderData.portabilidadRechazada;
			if (portabilidadRechazada) {
				this.docType = portabilidadRechazada.tipoDoc;
				this.numDoc = portabilidadRechazada.numDoc;
				this.numPortar = portabilidadRechazada.numPortar;
				this.nombre = portabilidadRechazada.nombre;
				this.apellido = portabilidadRechazada.apellido1;
				this.apellido2 = portabilidadRechazada.apellido2;
				this.iccid = portabilidadRechazada.iccid;
			}
		}, 1500);
		this.doSendView();
		this.showAutoiCard = this.setShowAutoiCard();
		if (this.showAutoiCard) {
			this.loadAutoiData();
		}
		this.checkshowButtonHistoricoOL(detalle);

		this.ordersService.setScoring();

		this.slidService.getInteractionSLID().subscribe({
			next: (resp: BodySLIDs[]) => {
				this.slidService.showCheckInstallation = resp.length > 0;
			},
			error: () => {
				this.slidService.showCheckInstallation = false;
			},
		});
	}

	public setButton(detalle: DetallePedidoExtended): void {
		if (isNaN(detalle.cambioCita) || detalle.cambioCita === 0) {
			this.botonCita = null;
		} else if (detalle.cambioCita < 4) {
			this.botonCita = tabsMyOrders.changeAppointment;
		} else {
			this.botonCita = tabsMyOrders.planAppointment;
		}
	}

	public checkshowButtonHistoricoOL(detalle: DetallePedidoExtended): void {
		if (constants.trueLiteral.includes(this.translate.instant('v10.myOrder.detail.showButtonHistoricoOL'))) {
			if (detalle.estadool) {
				this.checkOrderShippingStatus();
			} else if (detalle.mensajeOL) {
				this.getOLHistoryDetail();
			}
		}
	}

	public setShowAutoiCard(): boolean {
		return (
			this.ordersService.detailOrderData.hasAutoInstallationCard &&
			(this.ordersService.selectedOrderType === orderTypes.vigentes ||
				this.ordersService.selectedOrderType === orderTypes.completados) &&
			+this.ordersService.detailOrderData.paso > 2
		);
	}

	public setTokenOBDetail(): void {
		this.findOrdersDetail();
		this.currentOrdersDetail?.processFlow?.taskFlow?.forEach((task) => {
			this.setCharacteristicDetail(task);
		});
	}

	public findOrdersDetail(): void {
		this.currentOrdersDetail = this.ordersService?.orders?.find(
			(el) => el.id === this.ordersService?.detailOrderData?.numeroPedido
		);
	}

	public setCharacteristicDetail(task: TaskFlow): void {
		task.characteristic?.forEach((item) => {
			if (
				item.name.toLowerCase() === setOB.ordenName.toLowerCase() &&
				item.value.toLowerCase() === setOB.ordenValue.toLowerCase() &&
				task.state.toLowerCase() === setOB.activeOB.toLowerCase()
			) {
				this.ordersService.isOB = true;
				this.ordersService.setTokenOB(this.currentOrdersDetail.processFlow.characteristic);
			}
		});
	}

	public clickOBDetail(): void {
		this.myOrdersTagService.tagClickContinue(this.botonCita);
		this.showBackDropOBDetail = true;
		this.xrContentsOverlayDataDetail = new OverlayModel();
		this.xrContentsOverlayDataDetail.title = setOB.spacer;
		this.xrContentsOverlayDataDetail.hideXButton = false;
		this.xrContentsOverlayDataDetail.primaryButton = this.translate.instant('v10.myOrder.detail.backdropOB.button');
		this.myOrdersTagService.tagModalState(this.botonCita);
	}

	public closeOBDetail(): void {
		this.myOrdersTagService.tagCloseBackDrop(this.botonCita);
		this.showBackDropOBDetail = false;
	}

	public clickButtonBackdropDetail(): void {
		this.myOrdersTagService.tagClickStart(this.botonCita);
		this.ordersService.setUrlOB();
		window.open(this.ordersService.urlOB, '_blank');
		this.showBackDropOBDetail = false;
		this.router.navigate([config.dashboard.route]);
	}

	loadAutoiData(): void {
		this.ticketsService.getAutoiOpenTickets(TicketType.TIA).subscribe({
			next: (response: Ticket[]) => {
				this.ticketsService.ticketDetailId = response?.[0]?.idTicket;
				this.ticketsService.initAutoiOptions();
				this.checkAutoiOptions();
			},
		});
	}

	openOverlayCheckInstallation(schamanServiceType?: SchamanServiceType, skipClickTagging?: boolean): void {
		if (schamanServiceType) {
			this.overlaySchamanServiceType = schamanServiceType;

			switch (this.overlaySchamanServiceType) {
				case SchamanServiceType.NEBA_INTERNET:
					this.overlaySchamanCheckType = SchamanTags.NET;

					break;
				case SchamanServiceType.NEBA_INTERNET_TV:
					this.overlaySchamanCheckType = SchamanTags.NET;

					break;
				case SchamanServiceType.NEBA_TV:
					this.overlaySchamanCheckType = SchamanTags.TV;

					break;
				default:
					this.overlaySchamanCheckType = SchamanTags.NONE;

					break;
			}
		} else {
			if (this.ordersService.detailOrderData.esOrdenNeba) {
				this.overlaySchamanServiceType = this.resolveSchamanServiceTypeFromOrderDetails();
				this.overlaySchamanCheckType = SchamanTags.NET;
			} else {
				this.overlaySchamanCheckType = SchamanTags.ALL;
			}
		}

		this.showOverlayCheckInstallation = true;
	}

	closeOverlayCheckInstallationAndOpenTvGuide(): void {
		this.showOverlayCheckInstallation = false;
		this.openNebaInstallationTvModal(true);
	}

	checkAutoiOptions(olHistory: HistoricoOL = this.olService.orderLastOLStatus): void {
		if (!this.showAutoiCard) {
			return;
		}

		// Open TIA ticket flow
		this.ticketsService.autoiHelp.showWrongCablesOption =
			this.ticketsService.autoiHelp.optionsToShow.includes(AutoiOption.WRONG_CABLES) &&
			this.translate
				.instant('v10.myOrder.detail.autoiHelpModalOptionCablesAllowedStatus')
				?.split(';')
				?.includes(olHistory?.estado) &&
			this.ticketsService.ticketList?.[TicketType.TIA]?.length === 0;

		// SLA + GP Ticket flow
		const wcsShowSLAOption: boolean = this.ticketsService.autoiHelp.optionsToShow.includes(AutoiOption.NOT_RECEIVED);
		if (wcsShowSLAOption) {
			if (!this.orderOLHistory || this.orderOLHistory?.historicoOL.length === 0) {
				// We need it to recover "slaSuperado" parameter
				this.getOLHistoryDetail();
			} else {
				this.olService.disableSLACheck = false;
			}
		} else {
			this.olService.disableSLACheck = true;
		}
		if (!this.olDetail) {
			this.olDetail = this.olService.getShipmentHistoryStatusDetail(olHistory);
		}
		this.ticketsService.autoiHelp.showPackageNotReceivedOption =
			wcsShowSLAOption &&
			this.olDetail?.outdatedSLA &&
			![OLStatus.DELIVERED, OLStatus.CANCELLED, OLStatus.UNKNOWN].includes(this.olDetail.status);

		// Schaman flow
		this.ticketsService.autoiHelp.showNotWorkingOption =
			this.ticketsService.autoiHelp.optionsToShow.includes(AutoiOption.NOT_WORKING) &&
			this.hasInstallationGuide() &&
			[OLStatus.DELIVERED].includes(this.olDetail?.status);

		// Chat option for testing
		this.ticketsService.autoiHelp.showChatOption = this.ticketsService.autoiHelp.optionsToShow.includes(
			AutoiOption.CHAT
		);
	}

	doSendView(): void {
		let mensajeEEScoring: string = '';
		const mensajeEEScoringDocumentacion: string = 'documentacion';
		const mensajeEEScoringFianza: string = '-fianza';
		if (this.ordersService.detailOrderData.scoring) {
			if (
				this.ordersService.detailOrderData.scoring.documentos.includes('P') ||
				this.ordersService.detailOrderData.scoring.documentos.includes('A') ||
				this.ordersService.detailOrderData.scoring.documentos.includes('B') ||
				this.ordersService.detailOrderData.scoring.documentos.includes('C')
			) {
				mensajeEEScoring = '-' + mensajeEEScoringDocumentacion;
			}
			if (this.ordersService.detailOrderData.scoring.documentos.includes('fianza')) {
				mensajeEEScoring = mensajeEEScoringFianza;
				if (mensajeEEScoring) {
					mensajeEEScoring += ` y ${mensajeEEScoring.replace('-', '')}`;
				} else {
					mensajeEEScoring = mensajeEEScoringFianza;
				}
			}
		}
		this.ordersService.mensajeEEScoring = mensajeEEScoring;
		this.tagging._extend({
			monitoring_purchase_id: this.orderData.numeroPedido,
			monitoring_purchase_status: this.setMonitoringPurchaseStatus(mensajeEEScoring),
			monitoring_purchase_type: this.isTienda(this.orderData.pedidoTol),
			monitoring_purchase_step: this.botonCita
				? `${this.utilsService.normalize(
						this.ordersService.detailOrderData.pasos[
							this.ordersService.detailOrderData.pasos.findIndex(this.isStepIndex, this)
						]?.pasoNombre.toLowerCase()
				  )}-${this.botonCita.toLowerCase()} disponible`
				: `${this.utilsService.normalize(
						this.ordersService.detailOrderData.pasos[
							this.ordersService.detailOrderData.pasos.findIndex(this.isStepIndex, this)
						]?.pasoNombre.toLowerCase()
				  )}`,
			monitoring_purchase_flux: this.orderData.nombreComercial
				? this.utilsService.normalize(this.utilsService.getStringOrdersFlux([this.orderData])).toLowerCase()
				: this.utilsService
						.normalize(this.utilsService.getStringOrdersFlux(this.ordersService.detailOrderData.descripcion))
						.toLowerCase(),
		});
		this.tagging.sendPage(['detalle pedido']);
	}

	checkOrderShippingStatus(): void {
		this.olService.orderLastOLStatus = this.olService.orderLastOLStatus || {
			estado: this.ordersService.detailOrderData.estadool,
		};
		this.checkAutoiOptions();
	}

	getOLHistoryDetail(): void {
		this.orderOLHistory = null;
		this.olService.getOrderHistoryOL(this.orderData.numeroPedido).subscribe({
			next: (res: PedidoHistoricoOLResponse) => {
				if (res?.historicoOL?.length) {
					this.orderOLHistory = res;
					this.checkAutoiOptions();
				} else {
					this.orderOLHistory = { historicoOL: [] };
				}
			},
			error: () => {
				this.orderOLHistory = null;
			},
		});
	}

	setMonitoringPurchaseStatus(mensajeEEScoring: string): string {
		let msg: string;
		if (this.ordersService.detailOrderData.portabilidadRechazada?.tipoRechazoPortabilidad) {
			msg = constants.statePedidoAnalytics[parseInt(this.orderData.statePedido, 10) - 1] + '-portabilidad denegada';
		} else {
			msg = this.ordersService.detailOrderData.mensajeEE
				? `${
						constants.statePedidoAnalytics[parseInt(this.orderData.statePedido, 10) - 1]
				  }-contiene ee${mensajeEEScoring}`
				: constants.statePedidoAnalytics[parseInt(this.orderData.statePedido, 10) - 1];
		}
		return msg;
	}

	isStepIndex(elem: Paso): boolean {
		return parseInt(elem.pasoOrden, 10) === parseInt(this.ordersService.detailOrderData.paso, 10);
	}

	isTienda(pedidoTol: number): string {
		switch (pedidoTol) {
			case 0:
				return constants.shopTypes.tiendaFisica;
			case 1:
			case 2:
				return constants.shopTypes.tiendaOnline;
			default:
				return constants.shopTypes.telefonica;
		}
	}

	startVptSignProcess(currentOrderDetail: DetallePedidoExtended): void {
		if (currentOrderDetail?.urlVPT) {
			this.ordersService.firmaDigitalRes = {
				errorCode: 100,
				error: '',
				pendienteFirma: true,
				url: [currentOrderDetail.urlVPT],
			};
		}
		this.tagging.sendInteraction({
			link_type: 'click en autoriza la portabilidad',
		});
		this.myOrdersService.vptSignProcess = true;
		this.router.navigate([config.myorders2.firmaDigital.route], { skipLocationChange: true });
	}

	goToInfo(): void {
		this.router.navigate([config.myorders2.mvfInfoDevolucion.route], { skipLocationChange: true });
	}

	openAppointmentModal(): void {
		this.tagging.sendInteraction({
			link_type: 'acceder ' + (this.botonCita ? this.botonCita.toLowerCase() : 'cambiar cita'),
		});
		this.router.navigate([config.myorders2.appointment.route], { skipLocationChange: true });
	}

	openActivationRouterModal(): void {
		this.tagging.sendInteraction({
			link_type: 'activar router',
		});
		this.showRouterActivationGuide = true;
	}

	openRouterInstallationModal(skipTagging?: boolean, withActivation?: boolean): void {
		if (!skipTagging) {
			let linkType: string;
			if (this.ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET) {
				linkType = 'click en mostrar guia instalacion router';
			} else if (this.ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV) {
				linkType = 'click en mostrar guia instalacion router y deco';
			} else {
				linkType = 'click en mostrar guia desconocida';
			}

			this.tagging.sendInteraction({ link_type: linkType });
		}
		this.checkInstallationInGuide = !!withActivation;
		this.showRouterInstallationGuide = true;
	}

	openDecoChangeModal(): void {
		this.tagging.sendInteraction({
			link_type: 'cambia tu equipo de televisión',
		});
		this.router.navigate([config.myorders2.decoChange.route], { skipLocationChange: true });
	}

	openOneConectaConDecoModal(): void {
		this.tagging.sendInteraction({
			link_type: 'instalar equipo one conecta con deco',
		});
		this.router.navigate([config.myorders2.oneConectaConDecoGuide.route], { skipLocationChange: true });
	}

	openOneConectaSinDecoModal(): void {
		this.tagging.sendInteraction({
			link_type: 'instalar equipo one conecta sin deco',
		});
		this.router.navigate([config.myorders2.oneConectaSinDecoGuide.route], { skipLocationChange: true });
	}

	openDecoInstallationModal(skipTagging?: boolean, withActivation?: boolean): void {
		if (!skipTagging) {
			let linkType: string;
			if (this.ordersService.detailOrderData.instalarEquipos === DecoGuideType.UPGRADE_TV) {
				linkType = 'click en mostrar guia instalacion deco';
			} else if (this.ordersService.detailOrderData.instalarEquipos === DecoGuideType.UPGRADE_DECO_GEN3) {
				linkType = 'click en mostrar guia instalacion deco gen 3';
			} else if (this.ordersService.detailOrderData.activarRouter === RouterGuideType.UPGRADE_INTERNET_AND_DECO_GEN3) {
				linkType = 'click en mostrar guia instalacion router y deco gen 3';
			} else {
				linkType = 'click en mostrar guia desconocida';
			}

			this.tagging.sendInteraction({ link_type: linkType });
		}
		this.checkInstallationInGuide = !!withActivation;
		if (this.ordersService.detailOrderData.instalarEquipos === DecoGuideType.UPGRADE_TV) {
			this.showDecoInstallationGuide = true;
		} else if (
			this.ordersService.detailOrderData.instalarEquipos === DecoGuideType.UPGRADE_DECO_GEN3 ||
			this.ordersService.detailOrderData.activarRouter === RouterGuideType.UPGRADE_INTERNET_AND_DECO_GEN3
		) {
			this.showDecoGen3InstallationGuide = true;
		}
	}

	openNebaInstallationModal(skipTagging?: boolean): void {
		if (!skipTagging) {
			this.tagging.sendInteraction({
				link_type: 'click en mostrar guia neba internet',
			});
		}
		if (this.ordersService.detailOrderData.guiaAutoiNeba === NebaGuideType.NEBA_INTERNET) {
			if (
				this.utilsService.isMobileSize() &&
				constants.trueLiteral.includes(this.translate.instant('v10.myOrder.detail.flagToShowGuideNeba'))
			) {
				this.showNebaInstallationGuide = true;
			} else {
				this.showNebaDesktopOverlay = true;
			}
		}
	}

	openNebaInstallationTvModal(skipTagging?: boolean): void {
		if (this.ordersService.detailOrderData.guiaAutoiNebaTv === NebaTvGuideType.NEBA_DECO_GEN3) {
			this.showNebaInstallationTvGuide = true;
		} else if (this.ordersService.detailOrderData.guiaAutoiNebaTv === NebaTvGuideType.NEBA_DECO_MULTICAST) {
			this.showNebaInstallationTvMulticastGuide = true;
		} else if (this.ordersService.detailOrderData.guiaAutoiNebaTv === NebaTvGuideType.NEBA_DECO_UNICAST) {
			this.showNebaInstallationTvUnicastGuide = true;
		}
	}

	openScoringModal(): void {
		this.tagging.sendInteraction({
			link_type: 'adjuntar documentacion',
		});
		this.router.navigate([config.myorders2.documentationScoring.route], { skipLocationChange: true });
	}

	openOrderHistoryModal(skipClickTagging?: boolean): void {
		if (!skipClickTagging) {
			this.tagging.sendInteraction({
				link_type: 'click en ver detalle del envio',
			});
		}
		this.showHistoryOLOverlay = true;
	}

	editData(): void {
		this.tagging.sendInteraction({
			link_type: this.ordersService.detailOrderData.portabilidadRechazada.codigoRechazo
				? 'click en modificar ' +
				  this.ordersService.detailOrderData.portabilidadRechazada.codigoRechazo.replace('RECH_', '').toLowerCase()
				: 'editar datos personales',
		});
		this.tagging.extendUtagPageName(['error iccid', 'error ident', 'informacion iccid'], null, true);
		this.router.navigate([config.myorders2.formBox.route], { skipLocationChange: true });
	}

	showICCIDInfo(): void {
		this.tagging.sendInteraction({
			link_type: 'click en info iccid',
		});
		this.tagging.extendUtagPageName(['error iccid', 'error ident', 'informacion iccid'], null, true);
		this.router.navigate([config.myorders2.iccidInfo.route], { skipLocationChange: true });
	}

	clickNewLandlinePortabilityGuide(): void {
		this.showLandlinePortabilityGuide = true;
		this.tagging.sendInteraction({
			link_type: 'click en entiende la portabilidad',
		});
	}
	openCancelTerminalModal(): void {
		this.showCancelTerminalModal = true;
		this.tagging.sendInteraction({
			link_type: 'click en solicitar cancelacion',
		});
	}

	showDocumentInfo(document: string): void {
		if (!document) {
			return;
		}
		const docInfoType: { texto: string; codigo: string } = constants.infoDocAnalytics.find(
			(elem) => elem.codigo === document
		);
		this.tagging.sendInteraction({
			link_type: 'ver informacion ' + docInfoType.texto,
		});
		const currentInfoPage: string = constants.infoPageName + docInfoType.texto;
		this.doSendView();
		this.tagging.extendUtagPageName([currentInfoPage], false, true);
		this.myOrdersService.openDocumentInfo(document, this.ordersService.detailOrderData);
		this.showDetailOverlayFullScreenModal = this.myOrdersService.showResponseBox;
	}

	watchScoringDocs(): void {
		this.tagging.sendInteraction({
			link_type: 'ver documentacion',
		});
		// Avisamos a la ventana padre de que se ha hecho click en ver documentacion
		this.utilsService.postMessageToParent(
			JSON.stringify({
				action: 'forward',
				message: 'contratos-documentacion',
			})
		);
	}

	openDropdown(): void {
		document.getElementById('inputDropdown').setAttribute('aria-expanded', 'true');
	}

	closeDropdown(): void {
		document.getElementById('inputDropdown').setAttribute('aria-expanded', 'false');
	}

	sendInfo(): void {
		this.tagging.sendInteraction({
			link_type: 'enviar',
		});
		this.myOrdersService.showSpinner = true;
		this.dataPort = {
			idCliente: this.myOrdersService.MYORDERS_DATA.idCliente,
			numeroPedido: this.ordersService.detailOrderData.numeroPedido,
			docType: this.docType,
			numDoc: this.numDoc,
			numPortar: this.numPortar,
			codigoRechazo: this.ordersService.detailOrderData.portabilidadRechazada
				? this.ordersService.detailOrderData.portabilidadRechazada.codigoRechazo
				: '',
			tipoLinea:
				this.ordersService.detailOrderData.portabilidadRechazada.tipoLinea.toLowerCase() === 'movil'
					? this.ordersService.detailOrderData.portabilidadRechazada.tipoLinea.toLowerCase()
					: 'fija',
		};
		this.portabilityService.sendInfo(this.dataPort);
	}

	change(): void {
		this.numValid = this.responseBoxUtils.validatePhone(
			this.numPortar,
			this.numValid,
			this.ordersService.detailOrderData.mensajePM
		);
		this.dniValid = this.responseBoxUtils.validateDNI(this.numDoc, this.docType, this.dniValid);
		this.disableButton = !this.responseBoxUtils.change(
			this.docType,
			this.numDoc,
			this.numPortar,
			this.disableButton,
			this.ordersService.detailOrderData.portabilidadRechazada,
			this.numValid,
			this.dniValid
		);
	}

	selectOption(option: string): void {
		this.docType = option;
		this.change();
	}

	getDateByOrderState(stateNum: string): string {
		switch (Number(stateNum)) {
			case 1:
				return this.orderData.fechaCancelacion;
			case 2:
				return this.orderData.fechaFin;
			case 3:
				return this.orderData.fechaSolicitud;
		}
	}

	// INI - Commercial Strategy F2 - EVERIS - djuareza
	getClassByEstado(estado: string): string {
		switch (estado) {
			case 'Completado':
				return 'mvf-detail__subtitleCompletado';
			case 'Pendiente':
				return 'mvf-detail__subtitlePendiente';
			case 'Verificando':
				return 'mvf-detail__subtitleVerificando';
		}
	}

	calcularDiasRestantes(fechaLimite: number): string {
		const today: number = Date.now();
		const dif: number = fechaLimite - today;
		const one_day: number = 1000 * 60 * 60 * 24;
		const dias: number = Math.round(dif / one_day);
		return dias + ' días';
	}
	// FIN - Commercial Strategy F2 - EVERIS - djuareza
	updateNumDoc(numDoc: string): void {
		this.numDoc = numDoc;
		this.change();
	}

	updateNumPortar(value: string): void {
		this.numPortar = value;
		this.change();
	}

	openAutoiHelp(): void {
		if (this.showAutoiCard) {
			this.showOverlayAutoiHelp = true;
		}
		this.tagging.sendInteraction({ link_type: 'click en ayuda autoinstalacion' });
	}

	hasInstallationGuide(): boolean {
		return (
			(this.ordersService.detailOrderData.activarRouter < 0 ||
				this.ordersService.detailOrderData.instalarEquipos > 0) &&
			this.ordersService.detailOrderData.cambioCita === 0
		);
	}

	closeAutoiHelpModal(closeType: string): void {
		this.showOverlayAutoiHelp = false;
		let analytics: string;
		if (closeType === 'openGuide') {
			analytics = 'click en no he revisado la guia';
			this.closeAutoiHelpOverlayAndOpenGuide(true);
		}
		if (closeType === 'openOL') {
			this.closeAutoiHelpOverlayAndOpenOL();
		}
		if (closeType === 'openCheckActivation') {
			analytics = 'click en activar equipos';
			this.openOverlayCheckInstallation(SchamanServiceType.NONE);
		}
		if (closeType === 'openCheckActivationNeba') {
			this.openOverlayCheckInstallation(this.resolveSchamanServiceTypeFromOrderDetails());
		}
		if (closeType === 'openCheckActivationNebaTv') {
			this.openOverlayCheckInstallation(SchamanServiceType.NEBA_TV);
		}
		if (closeType === 'chat') {
			this.openChat();
		}
		if (closeType === 'reload') {
			this.router.navigateByUrl('/');
		}

		// Send analytics
		if (analytics) {
			this.tagging.sendInteraction({
				link_type: analytics,
			});
		}
	}

	onCloseCanjeOverlay(forceReload?: boolean): void {
		if (forceReload) {
			this.router.navigateByUrl('/');
		}
	}

	openChat(): void {
		this.utilsService.openAutoiChat(this.ordersService.detailOrderData.numeroPedido);
	}

	closeAutoiHelpOverlayAndOpenGuide(skipTagging?: boolean): void {
		this.showOverlayAutoiHelp = false;
		if (
			this.ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET ||
			this.ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV
		) {
			this.openRouterInstallationModal(skipTagging, true);
		} else if (
			this.ordersService.detailOrderData.instalarEquipos === DecoGuideType.UPGRADE_TV ||
			this.ordersService.detailOrderData.instalarEquipos === DecoGuideType.UPGRADE_DECO_GEN3 ||
			this.ordersService.detailOrderData.activarRouter === RouterGuideType.UPGRADE_INTERNET_AND_DECO_GEN3
		) {
			this.openDecoInstallationModal(skipTagging, true);
		}
	}

	goToTicketDetail(ticketType: TicketType, ticketID: string = this.ticketsService.ticketDetailId): void {
		this.tagging.sendInteraction({ link_type: 'click en ver estado solicitud' });
		this.ticketsService.ticketDetailType = ticketType;
		this.ticketsService.ticketDetailId = ticketID;
		this.router.navigate([config.myorders2.ticketDetail.route], { skipLocationChange: true });
	}

	closeAutoiHelpOverlayAndOpenOL(): void {
		this.showOverlayAutoiHelp = false;
		this.openOrderHistoryModal(true);
	}

	closeCheckInstallationOverlay(closeType: string): void {
		this.showOverlayCheckInstallation = false;
		this.tagging.extendUtagPageName(['detalle pedido']);
		if (['chat'].includes(closeType)) {
			this.openChat();
		}
	}

	closeAutoiGuide(closeType: string): void {
		this.showRouterActivationGuide = false;
		this.showRouterInstallationGuide = false;
		this.showDecoInstallationGuide = false;
		this.showDecoGen3InstallationGuide = false;
		this.showNebaInstallationGuide = false;
		this.showNebaDesktopOverlay = false;
		this.showNebaInstallationTvGuide = false;
		this.showNebaInstallationTvMulticastGuide = false;
		this.showNebaInstallationTvUnicastGuide = false;
		this.tagging.extendUtagPageName(['detalle pedido']);
		if (['chat'].includes(closeType)) {
			this.openChat();
		}
	}

	closeHistoryOLOverlay(): void {
		this.showHistoryOLOverlay = false;
	}

	ngOnDestroy(): void {
		this.tagging.extendUtagPageName(['detalle pedido'], null, true);
	}

	private resolveSchamanServiceTypeFromOrderDetails(): SchamanServiceType {
		return this.ordersService.detailOrderData.guiaAutoiNebaTv > 0
			? SchamanServiceType.NEBA_INTERNET_TV
			: SchamanServiceType.NEBA_INTERNET;
	}

	closeModal(): void {
		this.showDetailOverlayFullScreenModal = false;
	}

	public goBreakdownsDetail(): void {
		this.router.navigate([config.FaultManagement.LandingPage.route]);
		this.myOrdersTagService.tagClickBreakdowns(this.botonCita);
	}

	public hasStep41OrStep42Detail(order: string): boolean {
		return this.findActiveTaskDetail() === order;
	}

	public findActiveTaskDetail(): string {
		return this.ordersService.orders
			?.find((el) => el.id === this.orderData?.numeroPedido)
			?.processFlow?.taskFlow?.find((el) => el.state === autoiMigration.active)
			.characteristic.find((el) => el.name === autoiMigration.orden)?.value;
	}

	public continueTaskAutoiDetail(): void {
		this.ticketsService
			.advanceTicketStatus(
				this.ordersService.detailOrderData.numeroPedido,
				this.myOrdersService.MYORDERS_DATA.idCliente
			)
			.subscribe(
				() => {
					this.goToGuideDetail();
				},
				(error) => {
					this.modalErrorDetail();
				}
			);
	}

	public goToGuideDetail(): void {
		if (this.detallePedido.instalarEquipos === DecoGuideType.UPGRADE_TV) {
			this.showDecoInstallationGuide = true;
		} else if (this.detallePedido.activarRouter === RouterGuideType.INTERNET_AND_TV) {
			this.showRouterInstallationGuide = true;
		}
	}

	public modalErrorDetail(): void {
		this.modalKoDetail.primaryButton = true;
		this.modalKoDetail.primaryButtonText = this.translate.instant('v10.myOrder.orders.returns.sumary.modalKo.btn');
		this.modalKoDetail.primaryButtonClick = () => {
			this.router.navigate([config.myorders.route]);
			this.modalKoDetail?.hide();
		};
		this.modalKoDetail.close = () => {
			this.router.navigate([config.myorders.route]);
			this.modalKoDetail?.hide();
		};
		this.modalKoDetail.show();
	}

	public backDropAutoiDetail(): void {
		this.openBackDropDetail = true;
	}

	public closeOverlayDetail(): void {
		this.openBackDropDetail = false;
	}

	public clickOkDetail(): void {
		this.openBackDropDetail = false;
		this.showOverlayCheckInstallation = true;
	}

	public clickNoOkDetail(): void {
		this.goToGuideDetail();
		this.openBackDropDetail = false;
	}

	public closeCheckInstallationOverlayDetail(closeType: string): void {
		this.showOverlayCheckInstallationDetail = false;
	}
}
