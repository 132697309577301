<div class="tab-bubble-header" *ngIf="headerText">
	<p class="header-title">
		{{ headerText }}
		<span (click)="close()" class="icon icon-close"> </span>
	</p>
</div>
<div class="child-container" [ngClass]="{ allowscrolling: headerText && !showLoading && !showError }">
	<div [hidden]="showLoading || showError" class="iframe-height-100">
		<div id="childBrowser" class="child-browser">
			<iframe
				id="Iframe"
				#iframe
				[ngStyle]="{ display: showLoading || showError ? 'none' : 'block' }"
				*ngIf="iframeURL"
				[src]="iframeURL"
				width="100%"
				allow="geolocation"
			>
			</iframe>
		</div>
	</div>

	<div *ngIf="showLoading && !showError" class="spinner spinner-out-container">
		<div class="content-wrapper row spinner-container">
			<div class="spinnerContainer col-xs-12">
				<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</div>
			<span *ngIf="showLoadingMessage" class="loading-text col-xs-8 col-xs-push-2">
				{{ 'common.messagesList.browserAndPdfLoading.browserAndPdfLoading_description' | translate }}
			</span>
		</div>
	</div>

	<div *ngIf="showError" class="error-container">
		<sp-error-message
			[ShowErrorImg]="true"
			[showButton]="showErrorButton"
			message="{{ errorTitle }}"
			retryBtn="{{ 'common.messagesList.bubbleError.confirmButton.text' | translate }}"
			(navigate)="retry()"
		>
		</sp-error-message>
	</div>
</div>
