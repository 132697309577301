import { map } from 'rxjs/operators';
import { API_URLS } from './../shared/constants/routes-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PremiumContent } from '../models/premium-content.model';
import { BackendCancelSubscriptionType } from './../shared/enums/backend-cancel-subscription-type.enum';
import { CancelSubscription } from '../models/product-cancel-subscription.model';

@Injectable()
export class ServiceSettingsData {
	constructor(private http: HttpClient) {}

	getSIMProfileData(siteId: string, serviceId: string, serviceType?: string): Observable<any> {
		let url = API_URLS.serviceSettings.getSIMProfileData.replace('{id}', serviceId);
		url += '?customerAccountId=' + siteId;
		if (serviceType) {
			url += '&serviceType=' + serviceType;
		}
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res) => {
				return res;
			})
		);
	}

	postUnlockSIM(siteId: string, msisdn: string, imei: string, email: string) {
		const url = API_URLS.serviceSettings.postUnlockSIM.replace('{id}', siteId);
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const body = {
			msisdn,
			imei,
			email,
		};
		const options = {
			headers: headers,
		};
		return this.http.post(url, body, options).pipe(
			map((res) => {
				return res;
			})
		);
	}
	getPurchasesAndSubscriptions(serviceId: string) {
		const url = API_URLS.serviceSettings.getPurchasesAndSubscriptions.replace('{serviceId}', serviceId);
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options = {
			headers: headers,
		};

		return this.http.get(url, options).pipe(
			map((res) => {
				return res;
			})
		);
	}

	getThirdPartyData(serviceId: string) {
		const url = API_URLS.serviceSettings.getThirdPartyData.replace('{serviceId}', serviceId);
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res) => {
				return res;
			})
		);
	}
	updatePaymentRestrictions(serviceId: string, code, changeToBill: boolean) {
		const url = API_URLS.serviceSettings.updatePaymentRestrictions.replace('{serviceId}', serviceId);
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');
		const options = {
			headers: headers,
		};
		const body = {
			name: code,
			isChargeToBill: changeToBill,
		};

		return this.http.patch(url, body, options).pipe(
			map((res) => {
				return res;
			})
		);
	}

	cancelSubscriptionService(
		serviceId: string,
		code: string,
		originBackend: string,
		productOfferingId: string,
		name: string
	): Observable<CancelSubscription> {
		const url: string = API_URLS.serviceSettings.cancelSubscriptionService;
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('X-VF-API-Process', 'SubscriptionCancellation');
		const options: Record<string, HttpHeaders> = {
			headers: headers,
		};
		const body: CancelSubscription = {
			productOrderItem: [
				{
					id: '',
					action: 'delete',
					product: {
						id: code,
					},
					productOffering: {
						id: '',
					},
				},
			],
			relatedParty: [
				{
					'id': serviceId,
					'role': 'Subscriber',
					'@referredType': 'individual',
				},
			],
			channel: [
				{
					id: 'Web',
				},
			],
			category: originBackend,
			name: name,
		};

		if (originBackend) {
			if (originBackend === BackendCancelSubscriptionType.ocs) {
				body.productOrderItem.forEach((poi) => {
					poi.id = `${serviceId}-${productOfferingId}-${code}`;
					poi.productOffering.id = productOfferingId;
				});
			} else {
				body.productOrderItem.forEach((poi) => {
					poi.id = `${serviceId}-${code}`;
				});
			}
		}

		return this.http.post(url, body, options).pipe(
			map((res: CancelSubscription) => {
				return res;
			})
		);
	}

	deleteThirdParty(serviceId: string, subscriptionId: string) {
		const url = API_URLS.serviceSettings.deleteThirdParty
			.replace('{serviceId}', serviceId)
			.replace('{subscriptionId}', subscriptionId);
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options = {
			headers: headers,
		};
		return this.http.delete(url, options).pipe(
			map((res) => {
				return res;
			})
		);
	}

	getPremiumContentSubscription(serviceId: string): Observable<PremiumContent> {
		const url: string = API_URLS.serviceSettings.getPremiumContentSubscription.replace('{serviceId}', serviceId);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		return this.http.get(url, { headers }).pipe(
			map((res: PremiumContent) => {
				return res;
			})
		);
	}
}
