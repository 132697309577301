export class LineRestrictionsVisibilityStatusModel {
	answerMachine: AnswerMachineCardModelVisibility;
	recieveSMS: RecieveSMSCardModelVisibility;
	callWaiting: CallWaitingCardModelVisibility;
	hideMyNumber: HideMyNumberCardModelVisibility;
	divertCalls: DivertCallsCardModelVisibility;
	autoredial: AutoredialCardModelVisibility;
	dictaSMS: DictaSMSCardModelVisibility;
	lineRestrictions: LineRestrictionsVisibility;
}
export class AnswerMachineCardModelVisibility {
	show: boolean;
	always: boolean;
	busy: boolean;
	unreachable: boolean;
	noAnswerIn: boolean;
	smsNotification: boolean;
	language: boolean;
}
export class RecieveSMSCardModelVisibility {
	show: boolean;
	busy: boolean;
	outOfCoverage: boolean;
}
export class CallWaitingCardModelVisibility {
	show: boolean;
}
export class HideMyNumberCardModelVisibility {
	show: boolean;
}
export class DivertCallsCardModelVisibility {
	show: boolean;
}
export class AutoredialCardModelVisibility {
	show: boolean;
	status: boolean;
}
export class DictaSMSCardModelVisibility {
	show: boolean;
	status: boolean;
}

export class LineRestrictionsVisibility {
	lineStatus: boolean;
	callSettings: CallSettingsVisibility;
}

export class CallSettingsVisibility {
	show: boolean;
	all: boolean;
	incoming: boolean;
	international: boolean;
	roaming: boolean;
	incomingRoaming: boolean;
	highLevel: boolean;
	lowLevel: boolean;
}
