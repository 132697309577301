import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { callIdStatus } from '../../enums/callIdStatus.enum';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../app.service';

@Component({
	selector: 'sp-activation-toggle-card-mva10',
	templateUrl: './sp-activation-toggle-card-mva10.component.html',
	styleUrls: ['./sp-activation-toggle-card-mva10.component.scss'],
})
export class SpActivationToggleCardMva10Component implements OnInit, OnChanges {
	@Input() public firstLine: string;
	@Input() public secondLine: string;
	@Input() public stauts: string;
	@Input() public disable: boolean;
	@Output() toggleChanged = new EventEmitter<boolean>();
	stautsText: string;
	color: string;
	checkToggle: boolean;
	isPending: boolean;
	pendingIconSource: string;

	constructor(private translateService: TranslateService, private appService: AppService) {}

	ngOnChanges() {
		this.drawComponent();
	}

	ngOnInit() {
		/** to bring text from wcs of status */
		this.stautsText = '';
		this.drawComponent();
	}
	/**
	 * method for emitting the output toggleChanged
	 * @param statuschecked is a flag to let us know if we enable/disable the toggle
	 */
	changeStatus(statuschecked: boolean) {
		this.toggleChanged.emit(statuschecked);
	}

	/**
	 * method for set color and status text based on the value of the status
	 */
	drawComponent() {
		this.translateService.get('v10.lineServices.callOptions.itemsList').subscribe((data) => {
			switch (this.stauts.toLowerCase()) {
				case callIdStatus.active.toLowerCase():
					this.color = '#009900';
					this.isPending = false;
					this.checkToggle = true;
					this.stautsText = data.activeStatus.body;
					break;
				case callIdStatus.inactive.toLowerCase():
					this.color = '#e60000';
					this.isPending = false;
					this.checkToggle = false;
					this.stautsText = data.deActiveStatus.body;
					break;
				case callIdStatus.pendingActivation.toLowerCase():
					this.color = '#333333';
					this.isPending = true;
					this.checkToggle = false;
					this.stautsText = data.pendingActivationStatus.body;
					this.setIconSource(data);
					break;
				case callIdStatus.pendingDeactivation.toLowerCase():
					this.color = '#333333';
					this.isPending = true;
					this.checkToggle = true;
					this.stautsText = data.pendingDeactivationStatus.body;
					this.setIconSource(data);
					break;
			}
		});
	}
	/**
	 * method for setting the pending icon source
	 * @param wcs data that received from web center site
	 */
	setIconSource(wcs: any) {
		this.pendingIconSource = this.appService.getImgFullPath(wcs.pendingStatusIcon.url);
	}
}
