import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HelpOptions, AdaraHelpButtonOptions } from '../../../models/HelpOptions.model';
import { HelpConfigOptions } from '../../../models/HelpConfigOptions.model';
import { BubbleHelpConfiguration } from 'src/app/models/bubbleHelpConfiguration';

@Injectable({
	providedIn: 'root',
})
export class BubbleHelpService {
	private defaultOptions: HelpOptions = {
		url: null,
		showIframe: false,
		openAnimation: false,
		openSound: false,
		closeAnimation: false,
		closeSound: false,
	};
	private adaraHelpButtonOptions: AdaraHelpButtonOptions = {
		isEnergySection: false,
		isEnergyPurchase: false,
	};
	private isShowingHelp: BehaviorSubject<HelpOptions> = new BehaviorSubject(this.defaultOptions);

	private isAdaraSection: BehaviorSubject<AdaraHelpButtonOptions> = new BehaviorSubject<AdaraHelpButtonOptions>(
		this.adaraHelpButtonOptions
	);

	public isAdaraSectionObservable: Observable<AdaraHelpButtonOptions> = this.isAdaraSection.asObservable();

	isBubbleOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

	/** Flag for prevent repeating bubble click sound */
	soundIsPlaying: boolean;
	currentPageBubbleHelpConfig: BubbleHelpConfiguration;

	constructor() {}

	/**
	 * Funtion that returns an Observable to subscribe to iframe status Subject
	 */
	public iframeHelpStatus(): Observable<HelpOptions> {
		return this.isShowingHelp.asObservable();
	}

	/**
	 * Function to open the help iframe overlay. If no options are specified openSound and openAnimation will default to false
	 * @param openOptions Configuration to open the iframe overlay
	 */
	public showHelpIframe(openOptions?: HelpConfigOptions): void {
		this.isShowingHelp.next({
			...this.defaultOptions,
			...openOptions,
			showIframe: true,
		});
	}

	/**
	 * Function to close the help iframe overlay. If no options are specified closeSound and closeAnimation will default to false
	 * @param closeOptions Configuration to close the iframe overlay
	 */
	public hideHelpIframe(closeOptions?: HelpConfigOptions): void {
		this.isShowingHelp.next({
			...this.defaultOptions,
			...closeOptions,
			showIframe: false,
		});
	}

	public isAdaraSectionValue(isLeaving: boolean, isPurchase?: boolean): void {
		if (isLeaving) {
			this.isAdaraSection.next({
				isEnergySection: false,
				isEnergyPurchase: false,
			});
			return;
		}
		isPurchase
			? this.isAdaraSection.next({
					isEnergySection: true,
					isEnergyPurchase: true,
			  })
			: this.isAdaraSection.next({
					isEnergySection: true,
					isEnergyPurchase: false,
			  });
	}
}
