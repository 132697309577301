<div class="banner-card-container" id="bannerCardContainerId">
	<mva10-c-card [modifier]="'line'" id="bannerCardCardId">
		<p class="title" id="bannerCardTitleId">{{ bannerCard?.title }}</p>
		<p class="description" id="bannerCardDescriptionId">{{ bannerCard?.description }}</p>
		<span class="image-container" id="bannerCardIconId">
			<img [src]="bannerCard?.icon | imgFullPath" />
		</span>
		<div class="button-container" id="bannerCardButtonId">
			<button (click)="clickOnBannerCTA()">{{ bannerCard?.button }}</button>
		</div>
	</mva10-c-card>
</div>
