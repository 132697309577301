import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OverlayModel } from '@mva10/mva10-angular';
import { AppService } from 'src/app/app.service';
import { TicketType } from '../../../enums/ticket-type.enum';
import { HistoricoOL } from '../../../models/pedidoHistoricoOL.model';
import { OrdersService } from '../services/orders.service';
import { TicketsService } from '../services/tickets.service';
import { MyOrdersTaggingService } from '../../../services/my-orders-tagging.service';
import { TranslateService } from '@ngx-translate/core';
import { MyOrdersService } from '../services/my-orders.service';

@Component({
	selector: 'sp-help-autoi-modal',
	templateUrl: './sp-help-autoi-modal.component.html',
	styleUrls: ['./sp-help-autoi-modal.component.scss'],
})
export class SpHelpAutoiModalComponent implements OnInit {
	@Input() orderStatus: HistoricoOL;
	@Output() onClose: EventEmitter<string> = new EventEmitter<string>();

	pageName: string = 'ayuda autoinstalacion';
	overlayPageName: { [pageNum: number]: string } = {
		1: 'selector incidencias',
		2: 'cita tecnico',
		3: 'confirmacion revision guia',
	};
	pageAutoiHelp: number;
	autoiHelpData: OverlayModel;
	showOverlayAutoiHelp: boolean;
	isLoading: boolean;
	ticketCreationResult: string;
	TicketTypeEnum: typeof TicketType = TicketType;

	constructor(
		private ordersService: OrdersService,
		readonly ticketsService: TicketsService,
		public translate: TranslateService,
		public appService: AppService,
		private tagging: MyOrdersTaggingService,
		public myOrdersService: MyOrdersService
	) {}

	ngOnInit(): void {
		this.goToOverlayPage(1);
		this.showOverlayAutoiHelp = true;
		this.isLoading = false;
		this.autoiHelpData = {
			isCenterTitle: true,
			isFullScreen: false,
			title: this.translate.instant('v10.myOrder.helpAutoiModal.autoiHelpModalHeader'),
		};
	}

	goToOverlayPage(page: number, sendAnalytics: boolean = true): void {
		this.pageAutoiHelp = page;
		this.resetTagging();
		if (sendAnalytics) {
			if (page < 4) {
				// Pages 1-3
				setTimeout(() => {
					this.tagging.sendPage([this.overlayPageName[page]]);
				}, 100);
			} else {
				// Technician appointment ok/error
				setTimeout(() => {
					this.tagging.sendPage([this.overlayPageName[2], this.ticketCreationResult === 'ok' ? 'ok' : 'ko']);
				}, 100);
			}
		}
	}

	ngOnDestroy(): void {
		this.resetTagging();
		this.tagging.extendUtagPageName([this.pageName], null, true);
		this.tagging.extendUtagPageName(['detalle pedido']);
	}

	resetTagging(): void {
		this.tagging.extendUtagPageName(
			[
				'detalle pedido',
				this.pageName,
				this.overlayPageName[1],
				this.overlayPageName[2],
				this.overlayPageName[3],
				'ok',
				'ko',
			],
			null,
			true
		);
		this.tagging.extendUtagPageName([this.pageName]);
	}

	goBack(): void {
		if (this.pageAutoiHelp === 4 && this.ticketCreationResult === 'ok') {
			this.onCloseClicked();
		} else {
			this.goToOverlayPage(1);
		}
	}

	onCloseClicked(closeType?: string): void {
		this.showOverlayAutoiHelp = false;
		if (this.pageAutoiHelp === 4 && this.ticketCreationResult === 'ok') {
			closeType = 'reload';
		}
		setTimeout(() => {
			this.onClose.emit(closeType);
		}, 500);
	}

	onWrongCableClick(): void {
		this.tagging.sendInteraction({ link_type: 'click en no tengo cableado o esta deteriorado' });
		// Change mvf-detail page overlay to page 2 (technician appointment)
		this.goToOverlayPage(2);
	}

	onNoOrderReceivedClick(): void {
		this.tagging.sendInteraction({ link_type: 'click en no me han llegado los equipos' });
		this.onCloseClicked('openOL');
	}

	onOpenChatClick(): void {
		this.onCloseClicked('chat');
	}

	onServiceNotWorkingClick(): void {
		this.tagging.sendInteraction({ link_type: 'click en no me funciona el servicio' });
		// Change mvf-detail page overlay to page 3 (read guide or check device)
		this.goToOverlayPage(3);
	}

	createNewTechnicianTicket(): void {
		this.tagging.sendInteraction({ link_type: 'click en solicitar visita tecnico' });
		this.isLoading = true;
		this.ticketsService
			.createNewAutoiTicket(
				TicketType.TIA,
				this.ordersService.detailOrderData.numeroPedido,
				this.myOrdersService.MYORDERS_DATA.idCliente
			)
			.subscribe({
				next: (response: string) => {
					if (response) {
						this.ticketCreationResult = 'ok';
					} else {
						this.ticketCreationResult = 'error';
					}
					// OK/KO Page
					this.goToOverlayPage(4);
					this.isLoading = false;
				},
				error: () => {
					this.ticketCreationResult = 'error';
					// OK/KO Page
					this.goToOverlayPage(4);
					this.isLoading = false;
				},
			});
	}
}
