export enum PaymentCompoentInputData_Keys {
	paymentDeleteCardConfiramtionComponent_firstBtnAction = 'firstBtnAction',
	paymentDeleteCardConfiramtionComponent_secondBtnAction = 'secondBtnAction',
	paymentDeleteCardConfiramtionComponent_lastFourDigits = 'lastFourDigits',
	payment_debt_cashdesk_showPayPartial = 'showPayPartial',
	topUp_isAnonymous = 'isAnonymousProcess',
	is_openedFrom_ikki = 'isOpenedFromIkki',
	paymentDeleteCardConfiramtionComponent_isContactListPermissionPage = 'isContactListPermissionPage',
	showDebt = 'showDebt',
	paymentErrorComponent_firstBtnDimmed = 'isDimmed',
	standAloneWallet = 'standAloneWallet',
	isAnonymous = 'isAnonymous',
	paymentFinancedDevicesTotal_amountToPay = 'amountToPay',
	alertObject = 'alertObject',
	showPartialSection = 'hasPayPartialSection',
	TopUpnewBalance = 'newBalance',
	TopUpBalanceChanged = 'isBalanceChanged',
}
