<div class="mvf-order-detail-summary" *ngFor="let type of summaryTypes()">
	<div *ngIf="summaryData[type].length">
		<h3 class="mvf-order-detail-summary__title">
			{{ getSummaryTitle(type) }}
		</h3>
		<ul class="mvf-order-detail-summary__list">
			<li class="mvf-order-detail-summary__item" *ngFor="let item of summaryData[type]">
				{{ item }}
			</li>
		</ul>
		<p class="mvf-order-detail-summary__item" *ngIf="type === 'permanencias'">
			{{ 'v10.myOrder.summary.permanencies' | translate }}
		</p>
		<span class="mvf-order-detail-summary__link" *ngIf="type === 'incluidoPedido'" (click)="redirectToContracts()">
			{{ 'v10.myOrder.summary.linkResumenCompra' | translate }}
		</span>
	</div>
</div>
