import { Component, OnInit, Input } from '@angular/core';
import { defines, cardOfferBgColors } from '../../constants/defines';
import { TranslateService } from '@ngx-translate/core';
import { SwiperOptions } from 'swiper';
import { CommercialSwiperList } from '../../../models/commercial-mfdevices-swiper-list.model';

@Component({
	selector: 'sp-loading-view-carousel',
	templateUrl: './loading-view-carousel.component.html',
	styleUrls: ['./loading-view-carousel.component.scss'],
})
export class LoadingViewCarouselComponent implements OnInit {
	@Input() codeWCS: string;

	config: SwiperOptions = {
		scrollbar: false,
		direction: 'horizontal',
		observer: true,
		slidesPerView: 1,
		keyboard: false,
		mousewheel: false,
		simulateTouch: false,
		pagination: true,
		threshold: 10000,
		autoplay: {
			delay: 2000,
			disableOnInteraction: false,
		},
	};
	public arraySwiper: CommercialSwiperList[];
	public index: number = 1;
	public claimList: string[] = [];
	public showSwiper: boolean = false;
	public appearance: string;
	public spinner: string;
	public background: boolean;

	constructor(private translate: TranslateService) {}

	ngOnInit(): void {
		this.translate.get(`v10.common.loadingScreen.${this.codeWCS}`).subscribe((data) => {
			this.claimList = data?.textList;
			this.appearance = data?.appearance;
			this.config.autoplay = {
				delay: Number(data?.textDurationMiliseconds) ? Number(data?.textDurationMiliseconds) : 2000,
				disableOnInteraction: false,
			};
		});
		this.setAppearance();
		this.createArraySwiper();
	}

	public setAppearance(): void {
		if (this.appearance?.toLowerCase() === defines.secondary?.toLowerCase()) {
			this.spinner = cardOfferBgColors.white;
			this.background = false;
		} else {
			this.spinner = cardOfferBgColors.red;
			this.background = true;
		}
	}

	public createArraySwiper(): void {
		this.arraySwiper = new Array<CommercialSwiperList>();
		this.claimList?.forEach((claim) => {
			const list: CommercialSwiperList = new CommercialSwiperList();
			list.image = '';
			list.text = claim;
			this.arraySwiper.push(list);
		});
		if (this.arraySwiper.length > 0) {
			this.showSwiper = true;
		}
	}

	public controlChangePips(event: number): void {
		this.index = event + 1;
	}
}
