import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CommercialTerminalList, IdPromos, ParamsBuyNow, Semaphore } from '../../models/commercial-terminal-list.model';
import { CommercialFinancingOption, CommercialPayment } from '../../models/commercial-terminal-financing.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SubscriptionService } from '../../core/services/subscription.service';
import {
	JSON_PATHS,
	PAGES,
	commercialMobile,
	CommercialJourneys,
	LOCAL_STORAGE_KEYS,
	stringOfTrue,
	deliveryParams,
} from '../constants/defines';
import { API_URLS } from '../constants/routes-config';
import * as JsonQuery from 'jsonpath/jsonpath';
import { CommercialTerminal, CommercialKeywordsAll } from '../../models/commercial-terminal.model';
import { PromoCommercial } from '../../models/promo-commercial.model';
import { CommercialCapacity } from '../../models/commercial-capacity.model';
import { CommercialData } from '../../models/commercial-data.model';
import { DeliveryType } from '../../models/delivery-type.model';
import { DeliveryHours } from '../../models/delivery-hours.model';
import { DeliveryDate } from '../../models/delivery-date.model';
import { DeliveryModality } from '../../models/delivery-modality.model';
import { PostalAddress, PostalAddressBuyNow } from '../../models/postal-address.model';
import { DeliverySelectedStore } from '../../models/delivery-selected-store.model';
import {
	CommercialShoppingCart,
	CommercialCheckoutCriteriaDto,
	CommercialShopingCartBuyNowRes,
} from '../../models/commercial-shopping-cart.model';
import { CommercialCartItem } from '../../models/commercial-cart-item.model';
import { CommercialPrice } from '../../models/commercial-price.model';
import { CommercialDetail, PackageImage, PackageText } from '../../models/commercial-detail.model';
import { CommercialService } from '../../models/commercial-service.model';
import { StoreCoordinates } from '../../models/store-coordinates.model';
import { DeliveryStore } from '../../models/delivery-store.model';
import { CommercialShippingCosts } from '../../models/commercial-shipping-costs.model';
import { LegalTermDetail } from '../../models/legalTermDetail.model';
import { LegalTerms } from '../../models/legalTerms.model';
import { CommercialPlaylist, PlayListCommercial } from '../../models/commercial-playlist.model';
import { PlaylistListTerminalsCommercial } from '../../models/commercial-playlist-list-terminals.model';
import {
	CommercialBrandFilter,
	CommercialCapacityFilter,
	CommercialColorFilter,
} from '../../models/commercial-filters.model';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { ProductConfigurationService } from './product-configuration.service';
import { ProductConfigurationID } from '../enums/product-configuration-id.enum';
import { DeliveryOffice } from '../../models/delivery-office.model';
import { StorageService } from '../../core/services/storage.service';
import { AllowedServicesService } from './allowed-services.service';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs/internal/Observable';
import { MicroFlowsService } from './micro-flows.service';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { UtilitiesService } from '../utils/utilities.service';
import { ChangeAddress } from 'src/app/models/change-address.model';
import { CommercialListTerminals, CommercialOptions } from 'src/app/models/commercial-list-terminals.model';
import { ServiceAddress } from '../../models/service-address.model';
import { OptionCommercialMobile } from '../../models/options-commercial-mobile.model';
import { ParamsCommercialService } from '../../models/params-commercial-service.model';
import { InsuranceModel, InsurancePrices } from '../../models/insurance.model';
import { PrivateOfferListCartModel } from '../../models/private-offer-list-cart.model';

@Injectable()
export class CommercialMobileService {
	public playlists: CommercialPlaylist;

	private terminals: CommercialTerminal[];
	public allTerminals: CommercialTerminal[];

	public promos: PromoCommercial[];

	public loaded: boolean = null;
	public errorPreScoring: boolean = false;
	public attempsNumber: number = 0;
	public retry: boolean = true;
	backFromStep: boolean[] = new Array<boolean>();
	origin: string = PAGES.DASHBORD;
	public stepTitles: string[];
	public stepCurrent: number;
	public productTagg: string;
	public sap: string = '';
	public group: string = '';
	public offerId: string = '';
	public paymentTerminalType: number;
	public terminalID: number;
	public comeFromMyAccount: boolean = false;
	public playlistSelected: PlayListCommercial;
	public PlaylistListTerminals: PlaylistListTerminalsCommercial[];
	public brandsOptions: CommercialBrandFilter[];
	public brandsSelected: CommercialBrandFilter[];
	public capacitiesOptions: CommercialCapacityFilter[];
	public capacitiesSelected: CommercialCapacityFilter[];
	public colorsOptions: CommercialColorFilter[];
	public colorsSelected: CommercialColorFilter[];
	public listFiltered: CommercialTerminal[];
	public PlaylistName: string;
	public filterTagg: string;
	public playlistId: Number;
	public privatePlaylist: boolean = false;
	public mboxPlayList: string;
	public selectOffer: string;
	public installationDate: string;
	public installationHour: string;
	public listTerminals: Array<PlaylistListTerminalsCommercial> = new Array<PlaylistListTerminalsCommercial>();
	public phoneNumber: string;
	public defaultNumber: string;
	public originNumber: string;
	public terminalListLoaded: boolean = false;
	public prescoringErrorLoad: boolean = false;
	public listKeywords: CommercialKeywordsAll;
	public searchAll: boolean = false;

	personalData: CommercialData;
	ecodePersonalData: string;
	postalAddress: PostalAddress;
	addressHome: PostalAddress;
	changeAddress: ChangeAddress[];
	zipCodeStore: string;
	deliveryTypes: DeliveryType[];
	startDate: Date;
	deliveryModality: DeliveryModality;
	datesHome: DeliveryModality;
	datesStore: DeliveryModality;
	availableStores: DeliveryStore[];
	stores: DeliveryStore[];
	commercialshoppingCartItems: CommercialShoppingCart;
	cartItemId: string;
	paymentType: boolean | number;
	legalTermsArray: LegalTerms[];
	shippingType: string;
	isHome: boolean;
	public isOffice: boolean;

	shopDelivery: string;
	addressDelivery: string;
	dateDelivery: string;
	timeDelivery: any;
	public priceToPay: number;
	public priceInitialPay: number;
	public numMeses: number;

	public shippingScheduleText: string;
	public ecodeTerminalList: string;
	public taxType: string;
	public isME: boolean;
	public isYU: boolean;
	public switchTaxes: boolean;

	public canon: number;
	public promotedCanon: boolean;

	public sapDeeplink = false;

	private showFinalPayment: boolean = false;

	public cartItemIdTerminals: string;
	public msisdn: string;
	public idPlaylist: number;

	public hasInsuranceOnCart: boolean = false;
	public hasOneProfOnCart: boolean = false;
	public isMicroResegmented: boolean;

	public notFindSearchMessage: string;
	public serachTextOK: string;
	public showFindSearch: boolean;
	public showNotFindSearch: boolean;
	public textInFinder: string = '';
	public defaultPlaylistSearch: PlayListCommercial;
	public insuranceModality: string = null;
	public finderArray: Array<string> = [];
	public promotions: PromoCommercial[];

	public orderByCachePosition: number;

	constructor(
		public http: HttpClient,
		private subscriptionData: SubscriptionService,
		private taggingHelperService: TaggingHelperService,
		private productConfiguration: ProductConfigurationService,
		private storageService: StorageService,
		private allowedServices: AllowedServicesService,
		private microFlowService: MicroFlowsService,
		private translate: TranslateService,
		private readonly utilities: UtilitiesService
	) {
		this.isMicroResegmented = this.utilities.isConsumerAndHasMicro();
	}

	public getPlaylist() {
		let url = API_URLS.CommercialMobiles.getPlaylist
			.replace(
				'{clientType}',
				this.isME || this.isMicroResegmented
					? commercialMobile.clientTypeMicro.toString()
					: commercialMobile.clientType.toString()
			)
			.replace(
				'{shopType}',
				this.isME || this.isMicroResegmented
					? commercialMobile.shopTypeMicro.toString()
					: this.isYU
					? commercialMobile.shopTypeYu.toString()
					: commercialMobile.shopType.toString()
			)
			.replace('{lineType}', commercialMobile.lineType.toString())
			.replace('{registerType}', commercialMobile.registerType.toString())
			.replace('{sceneType}', commercialMobile.sceneType.toString())
			.replace('{contractType}', commercialMobile.contractType.toString());
		if (this.playlistId) {
			url = url.concat('&playlistId=' + this.playlistId);
		}

		const headers = new HttpHeaders();
		const options = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res: any) => {
				return this.playlistLogic(res);
			}),
			catchError((err) => {
				this.setErrorEcodes(err.error.ecode);
				return throwError(err);
			})
		);
	}

	public getOrderedList(): Observable<any> {
		const url: string = API_URLS.CommercialMobiles.getOrderedList
			.replace('{contractType}', commercialMobile.contractType.toString())
			.replace(
				'{clientType}',
				this.isME || this.isMicroResegmented
					? commercialMobile.clientTypeMicro.toString()
					: commercialMobile.clientType.toString()
			)
			.replace('{sceneType}', commercialMobile.sceneType.toString())
			.replace(
				'{shopType}',
				this.isME || this.isMicroResegmented
					? commercialMobile.shopTypeMicro.toString()
					: this.isYU
					? commercialMobile.shopTypeYu.toString()
					: commercialMobile.shopType.toString()
			)
			.replace('{registerType}', CommercialJourneys.registerType)
			.replace('{name}', '2NDHOME');

		const headers: HttpHeaders = new HttpHeaders();

		const options: object = {
			headers: headers,
		};

		return this.http.get(url, options).pipe(
			map((res: any) => {
				this.setErrorEcodes(res);
				if (!this.errorPreScoring) {
					return res;
				}
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	private playlistLogic(playlist) {
		this.playlists = new CommercialPlaylist();
		this.playlists.ecode = JsonQuery.value(playlist, JSON_PATHS.CommercialPlayList.ecode) || null;
		const list = JsonQuery.value(playlist, JSON_PATHS.CommercialPlayList.playlists) || null;
		if (list) {
			this.playlists.playlist = new Array<PlayListCommercial>();
			list.map((plists) => {
				const plist = new PlayListCommercial();
				plist.code = JsonQuery.value(plists, JSON_PATHS.CommercialPlayList.code) || 0;
				plist.name = JsonQuery.value(plists, JSON_PATHS.CommercialPlayList.name) || null;
				plist.order = JsonQuery.value(plists, JSON_PATHS.CommercialPlayList.order) || 0;
				plist.type = JsonQuery.value(plists, JSON_PATHS.CommercialPlayList.type) || null;
				plist.flgAutoComplete = JsonQuery.value(plists, JSON_PATHS.CommercialPlayList.flgAutoComplete) || false;
				plist.items = JsonQuery.value(plists, JSON_PATHS.CommercialPlayList.items) || null;
				this.playlists.playlist.push(plist);
			});
		}
		this.playlists.playlist.sort((playlistFirst, playlistSecond) => {
			return Number(playlistFirst.order) - Number(playlistSecond.order);
		});
	}

	public setPlaylistName(): void {
		this.PlaylistName = this.taggingHelperService.replaceSpecialCharReplace(this.playlistSelected.name, true);
		this.privatePlaylist = this.playlistSelected.type === commercialMobile.privatePlaylist;
	}

	public getTerminals(playlistId?: number, allowed?: boolean): Observable<CommercialListTerminals> {
		this.terminals = new Array<CommercialTerminal>();
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options: CommercialOptions = {
			headers: headers,
			auth_ticket: this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT),
			NTOL_TXID: this.microFlowService.cartId,
			withCredentials: true,
		};
		const url: string = this.setUrlGetTerminals(playlistId, allowed);

		return this.http.get(url, options).pipe(
			map((res: CommercialListTerminals) => {
				this.attempsNumber = 0;
				this.terminalLogic(res);
				this.promosLogic(res);
				this.ecodeTerminalList = res.ecode;
				if (res.playlist && !playlistId) {
					this.playlistSelected.code = res.playlist;
				}
				this.taxType = res.taxType;
				this.setErrorEcodes(res.ecode);
				return res;
			}),
			catchError((err) => {
				this.ecodeTerminalList = err.error.ecode;
				this.setErrorEcodes(err);
				return throwError(err);
			})
		);
	}

	public getClientType(): string {
		return this.isME || this.isMicroResegmented
			? commercialMobile.clientTypeMicro.toString()
			: commercialMobile.clientType.toString();
	}

	public getShopType(): string {
		return this.isME || this.isMicroResegmented
			? commercialMobile.shopTypeMicro.toString()
			: this.isYU
			? commercialMobile.shopTypeYu.toString()
			: commercialMobile.shopType.toString();
	}

	public setUrlGetTerminals(playlistId?: number, allowed?: boolean): string {
		let code: string = null;
		let url: string = API_URLS.CommercialMobiles.getTerminalList
			.replace('{clientType}', this.getClientType())
			.replace('{shopType}', this.getShopType())
			.replace('{lineType}', commercialMobile.lineType.toString())
			.replace('{registerType}', commercialMobile.registerType.toString())
			.replace('{sceneType}', commercialMobile.sceneType.toString())
			.replace('{contractType}', commercialMobile.contractType.toString())
			.replace('{clientId}', this.subscriptionData.customerData.currentService.siteId)
			.replace('{profile}', commercialMobile.profile)
			.replace('{version}', this.translate.instant('v10.commercial.fullVersionPortalEP'));
		if (playlistId && !this.searchAll) {
			url = url
				.replace('{terminalType}', CommercialJourneys.registerTypeMTP)
				.replace('{msisdn}', '')
				.replace('{cartItemId}', CommercialJourneys.registerType)
				.concat('&playlist=' + playlistId);
		} else if (this.playlistSelected && !this.searchAll) {
			code = this.playlistSelected.code.toString();
			url = url
				.replace('{terminalType}', commercialMobile.terminalType.toString())
				.replace('{msisdn}', this.msisdn)
				.replace('{cartItemId}', this.cartItemIdTerminals)
				.concat('&playlist=' + code);
		} else {
			url = API_URLS.CommercialMobiles.getTerminalList
				.replace('{clientType}', this.getClientType())
				.replace('{shopType}', this.getShopType())
				.replace('{lineType}', commercialMobile.lineType.toString())
				.replace('{terminalType}', commercialMobile.terminalType.toString())
				.replace('{registerType}', commercialMobile.registerType.toString())
				.replace('{sceneType}', commercialMobile.sceneType.toString())
				.replace('{contractType}', commercialMobile.contractType.toString())
				.replace('{clientId}', this.subscriptionData.customerData.currentService.siteId)
				.replace('{msisdn}', this.msisdn)
				.replace('{profile}', commercialMobile.profile)
				.replace('{cartItemId}', this.cartItemIdTerminals)
				.replace('{version}', this.translate.instant('v10.commercial.fullVersionPortalEP'));
		}
		if (allowed) {
			url = `${url}&listAllowedMsisdn=${this.allowedServices.listAllowedMsisdn}&listAllowedCartItemId=${this.allowedServices.listAllowedCartItemId}`;
		}
		return url;
	}

	private terminalLogic(terminal) {
		this.showFinalPayment = this.productConfiguration.shouldShowFeature(ProductConfigurationID.showFinalPayment);
		this.terminals = new Array<CommercialTerminal>();
		this.allTerminals = new Array<CommercialTerminal>();
		const terms = JsonQuery.value(terminal, JSON_PATHS.CommercialTerminal.terminal) || null;
		terms.map((term) => {
			const terminalCommercial = new CommercialTerminal();
			terminalCommercial.id = JsonQuery.value(term, JSON_PATHS.CommercialTerminal.id) || 0;
			terminalCommercial.type = JsonQuery.value(term, JSON_PATHS.CommercialTerminal.type) || null;
			terminalCommercial.nombre = JsonQuery.value(term, JSON_PATHS.CommercialTerminal.nombre) || null;
			terminalCommercial.orden = JsonQuery.value(term, JSON_PATHS.CommercialTerminal.orden) || 0;
			terminalCommercial.ordenCuotaMensual =
				JsonQuery.value(term, JSON_PATHS.CommercialTerminal.ordenCuotaMensual) || 0;
			terminalCommercial.ordenPagoInicial = JsonQuery.value(term, JSON_PATHS.CommercialTerminal.ordenPagoInicial) || 0;
			terminalCommercial.commercialTerminalList =
				JsonQuery.value(term, JSON_PATHS.CommercialTerminal.commercialTerminalList) || null;
			terminalCommercial.isPrioritary = JsonQuery.value(term, JSON_PATHS.CommercialTerminal.isPrioritary) || false;
			terminalCommercial.orderCriteria = JsonQuery.value(term, JSON_PATHS.CommercialTerminal.orderCriteria) || false;
			if (terminalCommercial.commercialTerminalList) {
				terminalCommercial.commercialTerminalList = this.terminalListLogic(
					terminalCommercial.commercialTerminalList,
					term
				);
			}
			this.terminals.push(terminalCommercial);
			this.allTerminals.push(terminalCommercial);
			this.loaded = this.terminalsLoaded();
			return terminalCommercial;
		});
	}

	private terminalListLogic(terminalToStore, terminalToGet): CommercialTerminalList[] {
		terminalToStore = new Array<CommercialTerminalList>();
		const listTerms: CommercialTerminalList[] =
			JsonQuery.value(terminalToGet, JSON_PATHS.CommercialTerminal.commercialTerminalList) || null;
		listTerms.forEach((listT: CommercialTerminalList) => {
			let terminalList: CommercialTerminalList = new CommercialTerminalList();
			terminalList = this.terminalListDetail(listT, terminalList);
			terminalList = this.terminalListDetailCaracteristics(listT, terminalList);
			const promosArray = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.listaPromos) || null;
			if (promosArray) {
				promosArray.forEach((id) => {
					terminalList.listaPromos =
						(JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.listaPromos) as IdPromos[]) || null;
				});
			}
			this.terminalListfinancingOptions(listT, terminalList);
			if (terminalList.financingOptions.length) {
				terminalToStore.push(terminalList);
			}
		});
		return terminalToStore;
	}

	public terminalListfinancingOptions(listT: CommercialTerminalList, terminalList: CommercialTerminalList): void {
		const financingOptions: CommercialFinancingOption[] =
			JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.financingOptions) || [];
		if (financingOptions.length) {
			terminalList.financingOptions = [];
			financingOptions.forEach((option) => {
				const financingOption: CommercialFinancingOption = new CommercialFinancingOption();
				financingOption.numMeses = JsonQuery.value(option, JSON_PATHS.CommercialFinancingOption.numMeses) || 0;
				const payments: CommercialPayment[] =
					JsonQuery.value(option, JSON_PATHS.CommercialFinancingOption.payments) || [];
				if (payments.length) {
					financingOption.payments = [];
					payments.forEach((item) => {
						let payment: CommercialPayment = new CommercialPayment();
						payment = this.terminalListPaymets(item, payment);
						if (!payment.flagFinalPayment || (this.showFinalPayment && payment.flagFinalPayment)) {
							financingOption.payments.push(payment);
						}
					});
				}
				this.terminalListfinancingOptionsPush(financingOption, terminalList);
			});
		}
	}

	public terminalListfinancingOptionsPush(
		financingOption: CommercialFinancingOption,
		terminalList: CommercialTerminalList
	): void {
		if (financingOption.payments.length) {
			terminalList.financingOptions.push(financingOption);
		}
	}

	public terminalListPaymets(item: CommercialPayment, payment: CommercialPayment): CommercialPayment {
		payment.availableBuyNow = JsonQuery.value(item, JSON_PATHS.CommercialPayment.availableBuyNow) || false;
		payment.idPrecio = JsonQuery.value(item, JSON_PATHS.CommercialPayment.idPrecio) || 0;
		payment.pagoAlContadoConImpuesto =
			JsonQuery.value(item, JSON_PATHS.CommercialPayment.pagoAlContadoConImpuesto) || 0;
		payment.pagoAlContadoSinImpuesto =
			JsonQuery.value(item, JSON_PATHS.CommercialPayment.pagoAlContadoSinImpuesto) || 0;
		payment.cuotaMensualConImpuesto = JsonQuery.value(item, JSON_PATHS.CommercialPayment.cuotaMensualConImpuesto) || 0;
		payment.cuotaMensualSinImpuesto = JsonQuery.value(item, JSON_PATHS.CommercialPayment.cuotaMensualSinImpuesto) || 0;
		payment.pagoInicialConImpuesto = JsonQuery.value(item, JSON_PATHS.CommercialPayment.pagoInicialConImpuesto) || 0;
		payment.pagoInicialSinImpuesto = JsonQuery.value(item, JSON_PATHS.CommercialPayment.pagoInicialSinImpuesto) || 0;
		payment.crossedOfferOriginal = JsonQuery.value(item, JSON_PATHS.CommercialPayment.crossedOfferOriginal) || '';
		payment.offerType = JsonQuery.value(item, JSON_PATHS.CommercialPayment.offerType) || '';
		payment.canonConImpuesto = JsonQuery.value(item, JSON_PATHS.CommercialPayment.canonConImpuesto) || 0;
		payment.canonSinImpuesto = JsonQuery.value(item, JSON_PATHS.CommercialPayment.canonSinImpuesto) || 0;
		payment.paymentType = JsonQuery.value(item, JSON_PATHS.CommercialPayment.paymentType) || null;
		payment.flagFinalPayment = JsonQuery.value(item, JSON_PATHS.CommercialPayment.flagFinalPayment) || false;
		return this.terminalListPaymetsTaxes(item, payment);
	}
	public terminalListPaymetsTaxes(item: CommercialPayment, payment: CommercialPayment): CommercialPayment {
		payment.flagOptionsRefinancingFp =
			JsonQuery.value(item, JSON_PATHS.CommercialPayment.flagOptionsRefinancingFp) || false;
		payment.deviceFinalPaymentTaxes = JsonQuery.value(item, JSON_PATHS.CommercialPayment.deviceFinalPaymentTaxes) || 0;
		payment.deviceFinalPayment = JsonQuery.value(item, JSON_PATHS.CommercialPayment.deviceFinalPayment) || 0;
		payment.pvpTotal = JsonQuery.value(item, JSON_PATHS.CommercialPayment.pvpTotal) || null;
		payment.pvpTotalTaxes = JsonQuery.value(item, JSON_PATHS.CommercialPayment.pvpTotalTaxes) || null;
		return payment;
	}

	public terminalListDetail(
		listT: CommercialTerminalList,
		terminalList: CommercialTerminalList
	): CommercialTerminalList {
		terminalList.idGrupo = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.idGrupo) || 0;
		terminalList.ordenGrupo = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.ordenGrupo) || 0;
		terminalList.sap = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.sap) || null;
		terminalList.color = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.color) || null;
		terminalList.codigoColor = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.codigoColor) || null;
		terminalList.capacidadValorUnidad = new CommercialCapacity();
		terminalList.capacidadValorUnidad.capacidad =
			JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.capacidad) || null;
		terminalList.capacidadValorUnidad.valor = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.valor) || null;
		terminalList.capacidadValorUnidad.unidad = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.unidad) || null;
		terminalList.imagenListado = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.imagenListado) || null;
		terminalList.textoImagenListado =
			JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.textoImagenListado) || null;
		terminalList.modelo = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.modelo) || null;
		terminalList.so = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.so) || null;
		terminalList.altImagenSo = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.altImagenSo) || null;
		terminalList.analyticsCategoryType =
			JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.analyticsCategoryType) || null;
		return terminalList;
	}

	public terminalListDetailCaracteristics(listT, terminalList: CommercialTerminalList): CommercialTerminalList {
		terminalList.marca = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.marca) || null;
		terminalList.idMarca = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.idMarca) || 0;
		terminalList.idTerminal = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.idTerminal) || 0;
		terminalList.idTipoTerminal = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.idTipoTerminal) || 0;
		terminalList.limitado = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.limitado) || false;
		terminalList.catalogOffer = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.catalogOffer) || 0;
		terminalList.nombreTarifa = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.nombreTarifa) || null;
		terminalList.exclusivoVodafone = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.exclusivoVodafone) || 0;
		terminalList.textoNoCobroCanon =
			JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.textoNoCobroCanon) || null;
		terminalList.semaphore = new Semaphore();
		terminalList.semaphore.color = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.colorSemaphore) || null;
		terminalList.semaphore.text = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.textSemaphore) || null;
		terminalList.mainCharacteristics =
			JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.mainCharacteristics) || null;
		terminalList.isPrioritary = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.isPrioritary);
		terminalList.techDeviceType = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.techDeviceType);
		terminalList.presale = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.presale);
		terminalList.accelerator = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.accelerator);
		terminalList.unavailable = JsonQuery.value(listT, JSON_PATHS.CommercialTerminalList.unavailable);
		return terminalList;
	}

	private promosLogic(promo) {
		this.promos = new Array<PromoCommercial>();
		const proms = JsonQuery.value(promo, JSON_PATHS.PromoCommercial.proms) || null;
		if (proms) {
			proms.map((prom) => {
				const commercialPromo = new PromoCommercial();
				commercialPromo.id = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.id) || null;
				commercialPromo.applyTo = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.applyTo) || null;
				commercialPromo.descriptionCRM = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.descriptionCRM) || null;
				commercialPromo.discountAmount = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountAmount) || null;
				commercialPromo.discountClassType = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountClassType) || null;
				commercialPromo.discountDuration = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountDuration) || null;
				commercialPromo.discountType = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountType) || null;
				commercialPromo.evaluationOrder = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.evaluationOrder) || null;
				commercialPromo.flagIgnoreCRM = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.flagIgnoreCRM) || false;
				commercialPromo.flagRegional = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.flagRegional) || false;
				commercialPromo.headbandOpenText = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.headbandOpenText) || null;
				commercialPromo.internalName = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.internalName) || null;
				commercialPromo.linkedToCatalog = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.linkedToCatalog) || null;
				commercialPromo.longText = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.longText) || null;
				commercialPromo.publicName = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.publicName) || null;
				commercialPromo.detailTextList = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.detailTextList) || null;
				commercialPromo.urlMobilePromotionImage =
					JsonQuery.value(prom, JSON_PATHS.PromoCommercial.urlMobilePromotionImage) || null;
				commercialPromo.urlMobilePromotionImageFicha =
					JsonQuery.value(prom, JSON_PATHS.PromoCommercial.urlMobilePromotionImageFicha) || null;
				commercialPromo.shortText = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.shortText) || null;
				commercialPromo.listIcon = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.listIcon) || null;
				commercialPromo.detailIcon = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.detailIcon) || null;
				commercialPromo.cardColor = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.cardColor) || null;
				commercialPromo.cardText = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.cardText) || null;
				commercialPromo.detailPromotionText =
					JsonQuery.value(prom, JSON_PATHS.PromoCommercial.detailPromotionText) || null;
				this.promos.push(commercialPromo);
				return commercialPromo;
			});
		}
	}

	private setErrorEcodes(error: string): void {
		if (error === commercialMobile.errorCodes.preScoring3 || error === commercialMobile.errorCodes.preScoring4) {
			this.errorPreScoring = true;
			this.PlaylistListTerminals = new Array<PlaylistListTerminalsCommercial>();
		}
	}

	public terminalsLoaded(): boolean {
		this.loaded = this.terminals ? true : false;
		return this.loaded;
	}

	public setOrigen(origin: string): string {
		this.origin = origin;
		return this.origin;
	}

	public saveStepper(stepTitles: string[]): void {
		this.stepTitles = stepTitles;
	}

	public getStepper(): string[] {
		return this.stepTitles;
	}

	private headersData() {
		const headers = new HttpHeaders();

		const options: OptionCommercialMobile = {
			headers: headers,
			NTOL_TXID: this.microFlowService.cartId,
			withCredentials: true,
		};
		return options;
	}

	public deleteReset(idClient: string, _clientTypeID: string): Observable<void> {
		const url: string = API_URLS.Checkout.deteleReset
			.replace('{idClient}', idClient)
			.replace(
				'{clientTypeID}',
				this.isME || this.isMicroResegmented
					? commercialMobile.clientTypeMicro.toString()
					: commercialMobile.clientType.toString()
			);

		const options: OptionCommercialMobile = this.headersData();

		const params: {} = {};
		return this.http.post(url, params, options).pipe(
			map((res: any) => {}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public postShoppingCartItem(
		idPrecio: number,
		paymentType: boolean | number,
		flagPaymentOptionFp: boolean,
		finalPaymentMonths?: number,
		itemId?: number
	): Observable<any> {
		this.paymentType = paymentType;
		const url: string = API_URLS.Checkout.postShoppingCartItem;
		const params: object = this.setShoppingCartParams(
			idPrecio,
			paymentType,
			flagPaymentOptionFp,
			finalPaymentMonths,
			itemId
		);
		const options = this.headersData();

		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				if (
					Number(res.ecode) === Number(commercialMobile.errorCodes.preScoring) ||
					Number(res.ecode) === Number(commercialMobile.errorCodes.preScoring5)
				) {
					return res;
				} else {
					this.shoppingCartLogic(res);
					return res;
				}
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public postShoppingCartItemBuyNow(
		idPrecio: number,
		paymentType: boolean | number,
		flagPaymentOptionFp: boolean,
		itemId?: number
	): Observable<CommercialShopingCartBuyNowRes> {
		this.paymentType = paymentType;
		const url: string = API_URLS.Checkout.postShoppingCartItemBuyNow;
		const params: ParamsBuyNow = this.setShoppingCartParamsBuyNow(idPrecio, paymentType, flagPaymentOptionFp, itemId);
		const options: OptionCommercialMobile = this.headersData();
		return this.http.post(url, params, options).pipe(
			map((res: CommercialShopingCartBuyNowRes) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getShoppingCartItem() {
		const url: string = API_URLS.Checkout.getShoppingCartItem;

		const options: OptionCommercialMobile = this.headersData();
		return this.http.get(url, options).pipe(
			map((res: any) => {
				this.shoppingCartLogic(res);
				res.pega = res.pega || {};
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public postPersonalData(param) {
		const url = API_URLS.Checkout.postPersonalData;

		const params = Object.assign(param, {
			clientType: this.isME || this.isMicroResegmented ? commercialMobile.clientTypeMicro : commercialMobile.clientType,
			shopType:
				this.isME || this.isMicroResegmented
					? commercialMobile.shopTypeMicro
					: this.isYU
					? commercialMobile.shopTypeYu
					: commercialMobile.shopType,
			sceneType: 1,
		});

		const options = this.headersData();

		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				this.personalData = new CommercialData();
				this.personalData.completed = JsonQuery.value(res, JSON_PATHS.PersonalData.completed) || false;
				this.personalData.order_id = JsonQuery.value(res, JSON_PATHS.PersonalData.order_id) || null;
				this.personalData.ecode = JsonQuery.value(res, JSON_PATHS.PersonalData.ecode) || null;
				if (param.email) {
					this.personalData.email = param.email;
				}
				this.ecodePersonalData = this.personalData.ecode;
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getDeliveryTypes(): Observable<DeliveryType[]> {
		const url: string = API_URLS.Checkout.getDeliveryTypes;

		const options: OptionCommercialMobile = this.headersData();

		return this.http.get(url, options).pipe(
			map((res: any) => {
				return this.deliveryTypesLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	private deliveryTypesLogic(response): DeliveryType[] {
		const resTypes = JsonQuery.value(response, JSON_PATHS.DeliveryTypes.deliveryTipes) || null;
		this.deliveryTypes = new Array<DeliveryType>();
		this.deliveryTypes = resTypes.map((type) => {
			const deliveryType = new DeliveryType();
			deliveryType.idType = JsonQuery.value(type, JSON_PATHS.DeliveryTypes.idType) || null;
			deliveryType.cdType = JsonQuery.value(type, JSON_PATHS.DeliveryTypes.cdType) || null;
			deliveryType.price = JsonQuery.value(type, JSON_PATHS.DeliveryTypes.price) || 0;
			deliveryType.priceTax = JsonQuery.value(type, JSON_PATHS.DeliveryTypes.priceTax) || 0;
			deliveryType.selected = JsonQuery.value(type, JSON_PATHS.DeliveryTypes.itMarcadoDefault) || false;
			deliveryType.nmOrden = JsonQuery.value(type, JSON_PATHS.DeliveryTypes.nmOrden) || 0;
			deliveryType.title = JsonQuery.value(type, JSON_PATHS.DeliveryTypes.title) || null;
			deliveryType.description = JsonQuery.value(type, JSON_PATHS.DeliveryTypes.description || null);
			deliveryType.itMarcadoDefault = JsonQuery.value(type, JSON_PATHS.DeliveryTypes.itMarcadoDefault || null);
			deliveryType.xOpcEntrega = JsonQuery.value(type, JSON_PATHS.DeliveryTypes.xOpcEntrega || null);
			return deliveryType;
		});
		return this.deliveryTypes;
	}

	public getDeliveryDates(address: ServiceAddress): Observable<DeliveryModality> {
		const url: string = API_URLS.Checkout.getDeliveryDates;

		// Body params
		const params: ParamsCommercialService = {
			cpDireccionEntrega: address.cp,
			idTipoEntrega: address.idType,
			cdTipoDeliveryType: address.cdType,
		};

		const options: OptionCommercialMobile = this.headersData();

		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				return this.deliveryDatesLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	private deliveryDatesLogic(response): DeliveryModality {
		this.deliveryModality = new DeliveryModality();
		this.deliveryModality.cdTipoProceso = JsonQuery.value(response, JSON_PATHS.DeliveryDates.cdTipoProceso) || null;
		this.deliveryModality.dsTipoProceso = JsonQuery.value(response, JSON_PATHS.DeliveryDates.dsTipoProceso) || null;

		this.deliveryModality.xCanal = JsonQuery.value(response, JSON_PATHS.DeliveryDates.xCanal) || null;
		this.deliveryModality.xPerfil = JsonQuery.value(response, JSON_PATHS.DeliveryDates.xPerfil) || null;
		this.deliveryModality.xTipoCliente = JsonQuery.value(response, JSON_PATHS.DeliveryDates.xTipoCliente) || null;

		const responseDates = JsonQuery.value(response, JSON_PATHS.DeliveryDates.deliveryDates) || null;
		this.deliveryModality.fechasEntrega = responseDates.map((date) => {
			const deliveryDate = new DeliveryDate();
			deliveryDate.fechaEntregaPedido = JsonQuery.value(date, JSON_PATHS.DeliveryDates.deliveryDate) || null;
			const portabilityDates = JsonQuery.value(date, JSON_PATHS.DeliveryDates.portabilityDate) || null;
			deliveryDate.fechaEntregaPedido = deliveryDate.fechaEntregaPedido
				? moment(deliveryDate.fechaEntregaPedido).toDate()
				: null;

			if (portabilityDates) {
				deliveryDate.fechaPortabilidad = moment(portabilityDates[0].fechaPortabilidad).toDate() || null;
				deliveryDate.xDiaEntrega = JsonQuery.value(portabilityDates[0], JSON_PATHS.DeliveryDates.xDiaEntrega) || null;
				deliveryDate.xHoraLiberacion =
					JsonQuery.value(portabilityDates[0], JSON_PATHS.DeliveryDates.xHoraLiberacion) || null;
				deliveryDate.xSlaFraude = JsonQuery.value(portabilityDates[0], JSON_PATHS.DeliveryDates.xSlaFraude) || null;
			}
			return deliveryDate;
		});

		const responseHours = JsonQuery.value(response, JSON_PATHS.DeliveryDates.horasEntrega) || null;
		this.deliveryModality.horasEntrega = responseHours.map((data) => {
			const deliveryHour = new DeliveryHours();
			deliveryHour.cdTipoDeliveryType = JsonQuery.value(data, JSON_PATHS.DeliveryDates.cdTipoDeliveryType) || null;
			deliveryHour.dsHoraEntrega = JsonQuery.value(data, JSON_PATHS.DeliveryDates.dsHoraEntrega) || null;
			deliveryHour.dsOpcEntrega = JsonQuery.value(data, JSON_PATHS.DeliveryDates.dsOpcEntrega) || null;
			deliveryHour.idModalidadEntrega = JsonQuery.value(data, JSON_PATHS.DeliveryDates.idModalidadEntrega) || null;
			deliveryHour.idTipoEntrega = JsonQuery.value(data, JSON_PATHS.DeliveryDates.idTipoEntrega) || null;
			deliveryHour.nombreModalidadEntrega =
				JsonQuery.value(data, JSON_PATHS.DeliveryDates.nombreModalidadEntrega) || null;
			deliveryHour.cdCatalogOfferCharge = JsonQuery.value(data, JSON_PATHS.DeliveryDates.cdCatalogOfferCharge) || null;
			deliveryHour.dsCatalogOfferChargeName =
				JsonQuery.value(data, JSON_PATHS.DeliveryDates.dsCatalogOfferChargeName) || null;
			deliveryHour.euCatalogOfferCharge = JsonQuery.value(data, JSON_PATHS.DeliveryDates.euCatalogOfferCharge) || 0;
			deliveryHour.eurPrecio = JsonQuery.value(data, JSON_PATHS.DeliveryDates.eurPrecio) || 0;
			deliveryHour.eurPrecioPromocion = JsonQuery.value(data, JSON_PATHS.DeliveryDates.eurPrecioPromocion) || 0;
			deliveryHour.eurPrecioPromocionTaxes =
				JsonQuery.value(data, JSON_PATHS.DeliveryDates.eurPrecioPromocionTaxes) || 0;
			deliveryHour.eurPrecioTaxes = JsonQuery.value(data, JSON_PATHS.DeliveryDates.eurPrecioTaxes) || 0;
			deliveryHour.idModalidadEntrega = JsonQuery.value(data, JSON_PATHS.DeliveryDates.idModalidadEntrega) || null;
			deliveryHour.idTipoEntrega = JsonQuery.value(data, JSON_PATHS.DeliveryDates.idTipoEntrega) || null;
			deliveryHour.xIdentificacion = JsonQuery.value(data, JSON_PATHS.DeliveryDates.xIdentificacion) || null;
			deliveryHour.xOpcEntrega = JsonQuery.value(data, JSON_PATHS.DeliveryDates.xOpcEntrega) || null;
			deliveryHour.xPrioridadEntrega = JsonQuery.value(data, JSON_PATHS.DeliveryDates.xPrioridadEntrega) || null;
			return deliveryHour;
		});
		return this.deliveryModality;
	}

	public postDeliveryData(
		address: PostalAddress,
		deliveryInfo: DeliveryModality,
		horasEntrega: DeliveryHours,
		deliveryDate: DeliveryDate,
		selectedStore: DeliverySelectedStore,
		office?: DeliveryOffice,
		deliveryPhone?: string
	) {
		const url: string = API_URLS.Checkout.postDeliveryDataSecure;
		let params = {
			deliveryPhone: deliveryPhone,
			flagTieneError: false,
			itPortabilidad: 0,
			clientType:
				this.isME || this.isMicroResegmented
					? commercialMobile.clientTypeMicro.toString()
					: commercialMobile.clientType.toString(),
			shopType:
				this.isME || this.isMicroResegmented
					? commercialMobile.shopTypeMicro.toString()
					: this.isYU
					? commercialMobile.shopTypeYu.toString()
					: commercialMobile.shopType.toString(),
			sceneType: 1,
			address: {
				door: address.door,
				floor: address.floor,
				name: address.name,
				number: address.addressNumber,
				postcode: address.zipCode,
				province: Number(address.province),
				town: address.town,
				type: address.type,
				verticalId: address.verticalId,
				longitude: address.longitud,
				latitude: address.latitud,
			},
			cdTipoProceso: deliveryInfo.cdTipoProceso,
			xTipoProceso: deliveryInfo.dsTipoProceso,
			xCanal: deliveryInfo.xCanal,
			xPerfil: deliveryInfo.xPerfil,
			xTipoCliente: deliveryInfo.xTipoCliente,
			idModalidadEntrega: horasEntrega.idModalidadEntrega,
			idTipoEntrega: horasEntrega.idTipoEntrega,
			cdTipoDeliveryType: horasEntrega.cdTipoDeliveryType,
			xOpcEntrega: horasEntrega.xOpcEntrega,
			nombreModalidadEntrega: horasEntrega.nombreModalidadEntrega,
			dsHoraEntrega: horasEntrega.dsHoraEntrega,
			dsOpcEntrega: horasEntrega.dsOpcEntrega,
			xIdentificacion: horasEntrega.xIdentificacion,
			xPrioridadEntrega: horasEntrega.xPrioridadEntrega,
			xCosteOpcEntrega: {
				eurPrecio: horasEntrega.eurPrecio,
				eurPrecioPromocion: horasEntrega.eurPrecioPromocion,
				eurPrecioTaxes: horasEntrega.eurPrecioTaxes,
				eurPrecioPromocionTaxes: horasEntrega.eurPrecioPromocionTaxes,
				cdCatalogOfferCharge: horasEntrega.cdCatalogOfferCharge,
				dsCatalogOfferChargeName: horasEntrega.dsCatalogOfferChargeName,
				euCatalogOfferCharge: horasEntrega.euCatalogOfferCharge,
			},
			xDestEntrega: horasEntrega.cdTipoDeliveryType,
			xCosteEur: horasEntrega.eurPrecioTaxes,
			xCostePromoEur: horasEntrega.eurPrecioPromocionTaxes,
			xDsOpcEntrega: horasEntrega.dsOpcEntrega,
			eurPrecio: horasEntrega.eurPrecio,
			eurPrecioPromocion: horasEntrega.eurPrecioPromocion,
			eurPrecioTaxes: horasEntrega.eurPrecioTaxes,
			eurPrecioPromocionTaxes: horasEntrega.eurPrecioPromocionTaxes,
			cdCatalogOfferCharge: horasEntrega.cdCatalogOfferCharge,
			dsCatalogOfferChargeName: horasEntrega.dsCatalogOfferChargeName,
			euCatalogOfferCharge: horasEntrega.euCatalogOfferCharge,
			xDiaEntrega: deliveryDate.xDiaEntrega ? deliveryDate.xDiaEntrega : null,
			xHoraLiberacion: deliveryDate.xHoraLiberacion ? deliveryDate.xHoraLiberacion : null,
			xSlaFraude: deliveryDate.xSlaFraude ? deliveryDate.xSlaFraude : null,
			fcEntregaPedido: deliveryDate.fechaEntregaPedido ? deliveryDate.fechaEntregaPedido : deliveryDate,
			fechaPortabilidad: deliveryDate.fechaPortabilidad ? deliveryDate.fechaPortabilidad : null,
			fcPortabilidad: deliveryDate.fechaPortabilidad ? deliveryDate.fechaPortabilidad : null,
		};
		if (selectedStore) {
			const tiendaParams = {
				tiendaNombre: selectedStore.tiendaNombre,
				tiendaSfid: selectedStore.tiendaSfid,
				tiendaTelefono: selectedStore.tiendaTelefono,
				tiendaProvincia: selectedStore.tiendaProvincia,
				tiendaCdAlmacen: selectedStore.tiendaCdAlmacen,
			};
			params = Object.assign({}, params, tiendaParams);
		}
		if (office) {
			const officeParams = {
				correosId: office.id,
				correosCodired: office.codired,
			};
			params = Object.assign({}, params, officeParams);
		}
		const options = this.headersData();

		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getPostalAddress() {
		const url: string = API_URLS.Checkout.getPostalAddress;

		const options: OptionCommercialMobile = this.headersData();

		return this.http.get(url, options).pipe(
			map((res: any) => {
				return this.postalAddressLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getPostalAddressBuyNow(): Observable<PostalAddressBuyNow> {
		const url: string = API_URLS.Checkout.getPostalAddressBuyNnow.replace(
			'{siteId}',
			this.subscriptionData.customerData.currentService.siteId
		);
		const options: OptionCommercialMobile = this.headersData();
		return this.http.get(url, options).pipe(
			map((res: any) => {
				return this.postalAddressLogicBuyNow(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getChangeAddress(): Observable<ChangeAddress[]> {
		const url: string = API_URLS.Checkout.getChangeAddress;
		const options: OptionCommercialMobile = this.headersData();
		return this.http.get(url, options).pipe(
			map((res: ChangeAddress[]) => {
				return this.changeAddressLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	private postalAddressLogic(response): PostalAddress {
		const postalAddress = new PostalAddress();
		postalAddress.addressNumber = JsonQuery.value(response, JSON_PATHS.PostalAddress.addressNumber) || null;
		postalAddress.door = JsonQuery.value(response, JSON_PATHS.PostalAddress.door) || null;
		postalAddress.floor = JsonQuery.value(response, JSON_PATHS.PostalAddress.floor) || null;
		postalAddress.name = JsonQuery.value(response, JSON_PATHS.PostalAddress.name) || null;
		postalAddress.province = JsonQuery.value(response, JSON_PATHS.PostalAddress.province) || null;
		postalAddress.town = JsonQuery.value(response, JSON_PATHS.PostalAddress.town) || null;
		postalAddress.type = JsonQuery.value(response, JSON_PATHS.PostalAddress.type) || null;
		postalAddress.verticalId = JsonQuery.value(response, JSON_PATHS.PostalAddress.verticalId) || null;
		postalAddress.zipCode = JsonQuery.value(response, JSON_PATHS.PostalAddress.zipCode) || null;
		postalAddress.notNormalizedAllowed =
			JsonQuery.value(response, JSON_PATHS.PostalAddress.notNormalizedAllowed) || false;
		this.postalAddress = postalAddress;
		return this.postalAddress;
	}

	public postalAddressLogicBuyNow(response: any): PostalAddressBuyNow {
		let postalAddress: PostalAddressBuyNow = new PostalAddressBuyNow();
		postalAddress.id = JsonQuery.value(response, JSON_PATHS.PostalAddress.id) || 0;
		postalAddress.type = JsonQuery.value(response, JSON_PATHS.PostalAddress.type) || null;
		postalAddress.name = JsonQuery.value(response, JSON_PATHS.PostalAddress.name) || null;
		postalAddress.addressNumber = JsonQuery.value(response, JSON_PATHS.PostalAddress.addressNumber) || null;
		postalAddress.stairway = JsonQuery.value(response, JSON_PATHS.PostalAddress.stairway) || null;
		postalAddress.floor = JsonQuery.value(response, JSON_PATHS.PostalAddress.floor) || null;
		postalAddress.door = JsonQuery.value(response, JSON_PATHS.PostalAddress.door) || null;
		postalAddress.otherdoor = JsonQuery.value(response, JSON_PATHS.PostalAddress.otherdoor) || null;
		postalAddress.zipCode = JsonQuery.value(response, JSON_PATHS.PostalAddress.zipCode) || null;
		postalAddress.province = JsonQuery.value(response, JSON_PATHS.PostalAddress.province) || null;
		postalAddress.town = JsonQuery.value(response, JSON_PATHS.PostalAddress.town) || null;
		postalAddress.additionalInfo = JsonQuery.value(response, JSON_PATHS.PostalAddress.additionalInfo) || null;
		postalAddress.verticalId = JsonQuery.value(response, JSON_PATHS.PostalAddress.verticalId) || null;
		postalAddress.normalized = JsonQuery.value(response, JSON_PATHS.PostalAddress.normalized) || false;
		postalAddress = this.postalAddressLogicBuyNowLocation(response, postalAddress);
		return postalAddress;
	}

	public postalAddressLogicBuyNowLocation(response: any, address: PostalAddressBuyNow): PostalAddressBuyNow {
		address.num = JsonQuery.value(response, JSON_PATHS.PostalAddress.block) || null;
		address.block = JsonQuery.value(response, JSON_PATHS.PostalAddress.town) || null;
		address.identificador = JsonQuery.value(response, JSON_PATHS.PostalAddress.identificador) || null;
		address.letter = JsonQuery.value(response, JSON_PATHS.PostalAddress.letter) || null;
		address.hand1 = JsonQuery.value(response, JSON_PATHS.PostalAddress.hand1) || null;
		address.hand2 = JsonQuery.value(response, JSON_PATHS.PostalAddress.hand2) || null;
		address.descriptionNumber = JsonQuery.value(response, JSON_PATHS.PostalAddress.descriptionNumber) || null;
		address.descriptionStair = JsonQuery.value(response, JSON_PATHS.PostalAddress.descriptionStair) || null;
		address.descriptionFloor = JsonQuery.value(response, JSON_PATHS.PostalAddress.descriptionFloor) || null;
		address.descriptionDoor = JsonQuery.value(response, JSON_PATHS.PostalAddress.descriptionDoor) || null;
		address.observations = JsonQuery.value(response, JSON_PATHS.PostalAddress.observations) || null;
		address.deliveryDate = JsonQuery.value(response, JSON_PATHS.PostalAddress.deliveryDate) || null;
		address.deliveryTime = JsonQuery.value(response, JSON_PATHS.PostalAddress.deliveryTime) || null;
		address.deliveryType = JsonQuery.value(response, JSON_PATHS.PostalAddress.deliveryType) || null;
		return address;
	}

	private changeAddressLogic(respuestaProvinciaDD: ChangeAddress[]): ChangeAddress[] {
		let provinciaList: ChangeAddress[] = new Array<ChangeAddress>();
		provinciaList = JsonQuery.value(respuestaProvinciaDD, JSON_PATHS.ChangeAddress.provinceDD) || null;

		if (provinciaList) {
			provinciaList.forEach((province) => {
				const changeAddress: ChangeAddress = new ChangeAddress();
				changeAddress.id = JsonQuery.value(province, JSON_PATHS.ChangeAddress.id) || null;
				changeAddress.name = JsonQuery.value(province, JSON_PATHS.ChangeAddress.name) || null;
				changeAddress.code = JsonQuery.value(province, JSON_PATHS.ChangeAddress.code) || null;
				changeAddress.prefixes = JsonQuery.value(province, JSON_PATHS.ChangeAddress.prefixes) || null;
				provinciaList.push(changeAddress);
			});
		}
		this.changeAddress = provinciaList;
		return this.changeAddress;
	}

	public shoppingCartLogic(items: CommercialShoppingCart) {
		let shoppingCart = new CommercialShoppingCart();
		shoppingCart = this.getShopppingCartPart1(shoppingCart, items);
		const price = JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.price) || null;
		if (price) {
			shoppingCart.price = this.getPrices(shoppingCart.price, items);
		}
		const itemsCart = new Array<CommercialCartItem>();
		const item = JsonQuery.value(items, JSON_PATHS.CartItemsCommercial.cartItems) || null;
		if (item) {
			item.map((it) => {
				const itemCart: CommercialCartItem = new CommercialCartItem();
				itemCart.cartItemId = JsonQuery.value(it, JSON_PATHS.CartItemsCommercial.cartItemId) || null;
				this.cartItemId = itemCart.cartItemId;
				itemCart.price = JsonQuery.value(it, JSON_PATHS.CartItemsCommercial.price) || null;
				if (itemCart.price) {
					itemCart.price = this.getPrices(itemCart.price, it);
				}
				let services: Array<CommercialService> = new Array<CommercialService>();
				const service: Array<CommercialService> =
					JsonQuery.value(it, JSON_PATHS.ServicesItemsCommercial.services) || null;

				services = this.getServices(service, services);

				itemCart.services = services;
				itemsCart.push(itemCart);
			});
		}
		shoppingCart.cartItems = itemsCart;
		const shippingCosts = JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.shippingCosts) || null;

		if (shippingCosts) {
			shoppingCart.shippingCosts = this.getShippingCosts(shoppingCart.shippingCosts, items);
		}
		const checkoutCriteriaDto = JsonQuery.value(items, JSON_PATHS.CriteriaDto.checkoutCriteriaDto) || null;
		shoppingCart = this.getShopppingCartPart3(shoppingCart, items);

		if (checkoutCriteriaDto) {
			shoppingCart.checkoutCriteriaDto = this.getCheckoutCriteriaDto(shoppingCart.checkoutCriteriaDto, items);
		}
		this.commercialshoppingCartItems = shoppingCart;
		return this.commercialshoppingCartItems;
	}

	public getShopppingCartPart1(
		shoppingCart: CommercialShoppingCart,
		items: CommercialShoppingCart
	): CommercialShoppingCart {
		shoppingCart.deleteItems = JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.deleteItems) || false;
		shoppingCart.overMaxInternetLines =
			JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.overMaxInternetLines) || false;
		shoppingCart.overMaxLines = JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.overMaxLines) || false;
		shoppingCart.remainingInternetLines =
			JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.remainingInternetLines) || null;
		shoppingCart.remainingMobileLines =
			JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.remainingMobileLines) || null;
		shoppingCart.taxType = JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.taxType) || null;
		shoppingCart.taxValue = JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.taxValue) || null;
		shoppingCart.siteId = JsonQuery.value(items, JSON_PATHS.ServicesItemsCommercial.siteId) || null;
		shoppingCart = this.getShopppingCartPart2(shoppingCart, items);
		return shoppingCart;
	}

	public getShopppingCartPart2(
		shoppingCart: CommercialShoppingCart,
		items: CommercialShoppingCart
	): CommercialShoppingCart {
		shoppingCart.privateOfferList = this.getPrivateOffer(items);
		shoppingCart.pega = items.pega || {};
		return shoppingCart;
	}

	public getShopppingCartPart3(
		shoppingCart: CommercialShoppingCart,
		items: CommercialShoppingCart
	): CommercialShoppingCart {
		shoppingCart.ecode = JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.ecode) || 0;
		return shoppingCart;
	}

	public getServices(service: Array<CommercialService>, services: Array<CommercialService>): Array<CommercialService> {
		const insuranceOnCartArray: boolean[] = [];
		service.forEach((sv) => {
			let serv: CommercialService = new CommercialService();
			serv = this.getServPart1(serv, sv);

			if (serv.detail) {
				serv.detail = new CommercialDetail();
				serv = this.getServDetailPart1(serv, sv);

				if (serv.detail.promotions) {
					const promotion: Array<PromoCommercial> =
						JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.promotions) || null;
					serv.detail.promotions = this.getPromotion(promotion);
				}

				const packageText: PackageText = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.packageText) || null;
				if (packageText) {
					serv.detail.packageText = this.getpackageText(packageText);
				}
				const packageImage: PackageImage = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.packageImage) || null;
				if (packageImage) {
					serv.detail.packageImage = this.getPackageImage(packageImage);
				}

				if (sv.insurance) {
					serv.insurance = sv.insurance;
					insuranceOnCartArray.push(true);
				} else {
					insuranceOnCartArray.push(false);
				}
				this.hasOneProfOnCart = sv.services?.some(
					(service: CommercialService) => service.detail.serviceItemType === CommercialJourneys.OneProfessionalService
				);
			}
			this.hasInsuranceOnCart = insuranceOnCartArray.find((item: boolean) => item === true);
			services.push(serv);
		});
		return services;
	}

	public getServPart1(serv: CommercialService, sv: CommercialService): CommercialService {
		serv.catalogElementType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.catalogElementType) || null;
		serv.code = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.code) || null;
		serv.itemId = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.itemId) || null;
		serv.tax = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.tax) || 0;
		serv.productHierarchyType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.productHierarchyType) || null;
		serv.price = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.price) || null;
		serv.nodeItemId = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.nodeItemIdId);
		if (serv.price) {
			serv.price = this.getPrices(serv.price, sv);
		}
		serv.detail = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.detail) || null;
		return serv;
	}

	public getServDetailPart1(serv: CommercialService, sv: CommercialService): CommercialService {
		serv.detail.additionalLine = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.additionalLine) || false;
		serv.detail.agentOffer = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.agentOffer) || false;
		serv.detail.catalogOffer = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.catalogOffer) || null;
		serv.detail.clientType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.clientType) || 0;
		serv.detail.contractType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.contractType) || 0;
		serv.detail.coverageTechnology = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.coverageTechnology) || null;
		serv.detail.crossedOfferId = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.crossedOfferId) || null;
		serv.detail.description = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.description) || null;
		serv = this.getServDetailPart2(serv, sv);
		return serv;
	}
	public getServDetailPart2(serv: CommercialService, sv: CommercialService): CommercialService {
		serv.detail.idesc = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.idesc) || null;
		serv.detail.manualProvision = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.manualProvision) || false;
		serv.detail.name = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.name) || null;
		serv.detail.nameList = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.nameList) || null;
		serv.detail.numberLine = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.numberLine) || 0;
		serv.detail.offerId = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.offerId) || 0;
		serv.detail.os = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.os) || null;
		serv.detail.permanenceDuration = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.permanenceDuration) || null;
		serv = this.getServDetailPart3(serv, sv);
		return serv;
	}
	public getServDetailPart3(serv: CommercialService, sv: CommercialService): CommercialService {
		serv.detail.plusLine = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.plusLine) || false;
		serv.detail.rateType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.rateType) || 0;
		serv.detail.rateTypeMapeo = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.rateTypeMapeo) || 0;
		serv.detail.registerType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.registerType) || 0;
		serv.detail.registerTypeName = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.registerTypeName) || null;
		serv.detail.sap = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.sap) || null;
		serv.detail.sceneType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.sceneType) || 0;
		serv.detail.serviceItemType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.serviceItemType) || 0;
		serv = this.getServDetailPart4(serv, sv);
		return serv;
	}
	public getServDetailPart4(serv: CommercialService, sv: CommercialService): CommercialService {
		serv.detail.serviceItemTypeName =
			JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.serviceItemTypeName) || null;
		serv.detail.shopType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.shopType) || 0;
		serv.detail.symmetricSpeed = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.symmetricSpeed) || false;
		serv.detail.finalPaymentNumberMonths =
			JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.finalPaymentNumberMonths) || null;
		serv.detail.promotions = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.promotions) || null;
		serv.detail.associatedLine = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.associatedLine) || 0;
		serv.detail.brand = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.brand) || null;
		serv.detail.promotedCanon = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.promotedCanon) || false;
		this.promotedCanon = serv.detail.promotedCanon;
		serv = this.getServDetailPart5(serv, sv);
		return serv;
	}
	public getServDetailPart5(serv: CommercialService, sv: CommercialService): CommercialService {
		this.canon = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.canonTaxes) || 0;
		serv.detail.canon = !serv.detail.promotedCanon
			? JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.canon)
			: 0 || 0;
		serv.detail.canonTaxes = !serv.detail.promotedCanon
			? JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.canonTaxes)
			: 0 || 0;
		serv.detail.capacity = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.capacity) || null;
		serv.detail.cash = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.cash) || 0;
		serv.detail.cashTaxes = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.cashTaxes) || 0;
		serv = this.getServDetailPart6(serv, sv);
		return serv;
	}

	public getServDetailPart6(serv: CommercialService, sv: CommercialService): CommercialService {
		serv.detail.color = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.color) || null;
		serv.detail.manufacturedType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.manufacturedType) || null;
		serv.detail.months = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.months) || 0;
		serv.detail.paymentType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.paymentType) || null;
		serv.detail.permanencePenalty = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.permanencePenalty) || 0;
		serv.detail.permanencePenaltyTaxes =
			JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.permanencePenaltyTaxes) || 0;
		serv.detail.terminalType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.terminalType) || 0;
		return serv;
	}

	public getpackageText(packageText: PackageText): PackageText {
		packageText = this.getpackageTextPart1(packageText);
		packageText.htmlTextInfo = JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.htmlTextInfo) || null;
		packageText.htmlTextRateTable =
			JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.htmlTextRateTable) || null;
		packageText.htmlTextTicketMobile =
			JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.htmlTextTicketMobile) || null;
		packageText.otherDetailList =
			JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.otherDetailList) || null;
		packageText.otherList = JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.otherList) || null;
		packageText.roamingList = JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.roamingList) || null;
		return packageText;
	}

	public getpackageTextPart1(packageText: PackageText): PackageText {
		packageText.callsIncludedDetailList =
			JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.callsIncludedDetailList) || null;
		packageText.callsIncludedFicha =
			JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.callsIncludedFicha) || null;
		packageText.callsIncludedList =
			JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.callsIncludedList) || null;
		packageText.callsIncludedSecondFicha =
			JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.callsIncludedSecondFicha) || null;
		packageText.callsIncludedSecondList =
			JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.callsIncludedSecondList) || null;
		packageText.callsList = JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.callsList) || null;
		packageText.dataDetailFicha =
			JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.dataDetailFicha) || null;
		packageText.dataDetailList = JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.dataDetailList) || null;
		packageText = this.getpackageTextPart2(packageText);
		return packageText;
	}
	public getpackageTextPart2(packageText: PackageText): PackageText {
		packageText.dataIncludedDetailList =
			JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.dataIncludedDetailList) || null;
		packageText.dataIncludedFicha =
			JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.dataIncludedFicha) || null;
		packageText.dataIncludedList =
			JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.dataIncludedList) || null;
		packageText.dataIncludedSecondFicha =
			JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.dataIncludedSecondFicha) || null;
		packageText.dataIncludedSecondList =
			JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.dataIncludedSecondList) || null;
		packageText.dataList = JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.dataList) || null;
		packageText.dataSpeedFicha = JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.dataSpeedFicha) || null;
		packageText.dataSpeedList = JsonQuery.value(packageText, JSON_PATHS.CommercialPackageText.dataSpeedList) || null;
		return packageText;
	}

	public getPackageImage(packageImage: PackageImage): PackageImage {
		packageImage = this.getPackageImagePart1(packageImage);
		packageImage.tabletDataIncludedIcon =
			JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.tabletDataIncludedIcon) || null;
		packageImage.tabletOtherIcon =
			JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.tabletOtherIcon) || null;
		packageImage.tabletRoamingIcon =
			JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.tabletRoamingIcon) || null;

		return packageImage;
	}

	public getPackageImagePart1(packageImage: PackageImage): PackageImage {
		packageImage.altCallsIncludedIcon =
			JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.altCallsIncludedIcon) || null;
		packageImage.altDataIncludedIcon =
			JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.altDataIncludedIcon) || null;
		packageImage.altImageIcon = JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.altImageIcon) || null;
		packageImage.altOtherIcon = JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.altOtherIcon) || null;
		packageImage.altRoamingIcon =
			JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.altRoamingIcon) || null;
		packageImage.desktopCallsIncludedIcon =
			JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.desktopCallsIncludedIcon) || null;
		packageImage.desktopDataIncludedIcon =
			JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.desktopDataIncludedIcon) || null;
		packageImage.desktopOtherIcon =
			JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.desktopOtherIcon) || null;
		packageImage = this.getPackageImagePart2(packageImage);
		return packageImage;
	}

	public getPackageImagePart2(packageImage: PackageImage): PackageImage {
		packageImage.desktopRoamingIcon =
			JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.desktopRoamingIcon) || null;
		packageImage.mobileCallsIncludedIcon =
			JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.mobileCallsIncludedIcon) || null;
		packageImage.mobileDataIncludedIcon =
			JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.mobileDataIncludedIcon) || null;
		packageImage.mobileOtherIcon =
			JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.mobileOtherIcon) || null;
		packageImage.mobileRoamingIcon =
			JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.mobileRoamingIcon) || null;
		packageImage.rateImageIcon = JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.rateImageIcon) || null;
		packageImage.rateImageIconFicha =
			JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.rateImageIconFicha) || null;
		packageImage.tabletCallsIncludedIcon =
			JsonQuery.value(packageImage, JSON_PATHS.CommercialPackageImage.tabletCallsIncludedIcon) || null;
		return packageImage;
	}

	public getPromotion(promotion: PromoCommercial[]): PromoCommercial[] {
		const promotions: Array<PromoCommercial> = new Array<PromoCommercial>();
		promotion.forEach((prom) => {
			let promo: PromoCommercial = new PromoCommercial();
			promo.id = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.id) || null;
			promo.applyTo = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.applyTo) || null;
			promo.discountAmount = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountAmount) || null;
			promo.discountAmountTaxes = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountAmountTaxes) || null;
			promo.discountClassType = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountClassType) || null;
			promo.discountDuration = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountDuration) || null;
			promo.discountType = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountType) || null;
			promo = this.getPromotionPart1(promo, prom);
			promo = this.getPromotionPart2(promo, prom);
			promotions.push(promo);
		});
		return promotions;
	}

	public getPromotionPart1(promo: PromoCommercial, prom: PromoCommercial): PromoCommercial {
		promo.evaluationOrder = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.evaluationOrder) || null;
		promo.flagIgnoreCRM = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.flagIgnoreCRM) || false;
		promo.flagRegional = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.flagRegional) || false;
		promo.headbandOpenText = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.headbandOpenText) || null;
		promo.internalName = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.internalName) || null;
		promo.linkedToCatalog = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.linkedToCatalog) || null;
		promo.longText = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.longText) || null;
		promo.publicName = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.publicName) || null;
		return promo;
	}

	public getPromotionPart2(promo: PromoCommercial, prom: PromoCommercial): PromoCommercial {
		promo.detailTextList = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.detailTextList) || null;
		promo.urlMobilePromotionImage = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.urlMobilePromotionImage) || null;
		promo.urlMobilePromotionImageFicha =
			JsonQuery.value(prom, JSON_PATHS.PromoCommercial.urlMobilePromotionImageFicha) || null;
		promo.llbbText = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.llbbText) || null;
		promo.llbbUrl = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.llbbUrl) || null;
		promo.cardColor = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.cardColor) || null;
		promo.cardText = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.cardText) || null;
		promo.detailPromotionText = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.detailPromotionText) || null;
		return promo;
	}

	public getPrivateOffer(items: CommercialShoppingCart): Array<PrivateOfferListCartModel> {
		const privateOfferList: Array<PrivateOfferListCartModel> = new Array<PrivateOfferListCartModel>();
		const privateOffer: Array<PrivateOfferListCartModel> = JsonQuery.value(
			items,
			JSON_PATHS.ServicesItemsCommercial.privateOffer
		);
		if (privateOffer) {
			privateOffer.forEach((privateOffer) => {
				const offer: PrivateOfferListCartModel = new PrivateOfferListCartModel();
				offer.techDeviceType = JsonQuery.value(privateOffer, JSON_PATHS.ServicesItemsCommercial.techDeviceType);
				offer.nodeItemId = JsonQuery.value(privateOffer, JSON_PATHS.ServicesItemsCommercial.nodeItemId);
				privateOfferList.push(offer);
			});
		}
		return privateOfferList;
	}
	private getPrices(itemToStore, itemToGet) {
		itemToStore = new CommercialPrice();
		itemToStore.devicesUpfront = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.devicesUpfront) || 0;
		itemToStore.devicesUpfront = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.devicesUpfront) || 0;
		itemToStore.devicesUpfrontPromo =
			JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.devicesUpfrontPromo) || 0;
		itemToStore.devicesUpfrontTaxes =
			JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.devicesUpfrontTaxes) || 0;
		itemToStore.devicesUpfrontTaxesPromo =
			JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.devicesUpfrontTaxesPromo) || 0;
		itemToStore.installment = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.installment) || 0;
		itemToStore.installmentPromo = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.installmentPromo) || 0;
		itemToStore.installmentPromoTaxes =
			JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.installmentPromoTaxes) || 0;
		itemToStore.installmentTaxes = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.installmentTaxes) || 0;
		itemToStore.singlePayment = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.singlePayment) || 0;
		itemToStore.singlePaymentPromo =
			JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.singlePaymentPromo) || 0;
		itemToStore.singlePaymentTaxes =
			JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.singlePaymentTaxes) || 0;
		itemToStore.singlePaymentTaxesPromo =
			JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.singlePaymentTaxesPromo) || 0;
		itemToStore.upfront = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.upfront) || 0;
		itemToStore.upfrontPromo = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.upfrontPromo) || 0;
		itemToStore.upfrontTaxes = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.upfrontTaxes) || 0;
		itemToStore.upfrontTaxesPromo =
			JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.upfrontTaxesPromo) || 0;
		itemToStore.deviceFinalPayment =
			JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.deviceFinalPayment) || null;
		itemToStore.deviceFinalPaymentTaxes =
			JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.deviceFinalPaymentTaxes) || null;
		itemToStore.finalPaymentMonthlyFee =
			JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.finalPaymentMonthlyFee) || null;
		itemToStore.finalPaymentMonthlyFeeTaxes =
			JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.finalPaymentMonthlyFeeTaxes) || null;
		return itemToStore;
	}

	public getCoordinates(zipCode) {
		const url: string = API_URLS.Checkout.getCoordinates.replace('{zipCode}', zipCode);
		const options: OptionCommercialMobile = this.headersData();

		return this.http.get(url, options).pipe(
			map((res: any) => {
				return this.getCoordinatesLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	getCoordinatesLogic(response): StoreCoordinates {
		const coords: StoreCoordinates = new StoreCoordinates();
		coords.city = JsonQuery.value(response, JSON_PATHS.StoreCoordinates.city) || null;
		coords.country = JsonQuery.value(response, JSON_PATHS.StoreCoordinates.country) || null;
		coords.latitude = JsonQuery.value(response, JSON_PATHS.StoreCoordinates.latitude) || 0;
		coords.longitude = JsonQuery.value(response, JSON_PATHS.StoreCoordinates.longitude) || 0;
		coords.province = JsonQuery.value(response, JSON_PATHS.StoreCoordinates.province) || null;
		coords.region = JsonQuery.value(response, JSON_PATHS.StoreCoordinates.region) || null;
		return coords;
	}

	getTiendasEntregaLogic(response): DeliveryStore[] {
		const tiendas = JsonQuery.value(response, JSON_PATHS.DeliveryStore.tiendas) || null;
		this.stores = new Array<DeliveryStore>();
		this.stores = tiendas.map((store) => {
			const deliveryStore = new DeliveryStore();
			deliveryStore.diaFin = JsonQuery.value(store, JSON_PATHS.DeliveryStore.diaFin) || null;
			deliveryStore.diaInicio = JsonQuery.value(store, JSON_PATHS.DeliveryStore.diaInicio) || null;
			deliveryStore.distanciaPuntoRef = JsonQuery.value(store, JSON_PATHS.DeliveryStore.distanciaPuntoRef) || null;
			deliveryStore.direccion = JsonQuery.value(store, JSON_PATHS.DeliveryStore.direccion) || null;
			deliveryStore.horaFin = JsonQuery.value(store, JSON_PATHS.DeliveryStore.horaFin) || null;
			deliveryStore.horaFinJornadaPartida =
				JsonQuery.value(store, JSON_PATHS.DeliveryStore.horaFinJornadaPartida) || null;
			deliveryStore.horaInicio = JsonQuery.value(store, JSON_PATHS.DeliveryStore.horaInicio) || null;
			deliveryStore.horaInicioJornadaPartida =
				JsonQuery.value(store, JSON_PATHS.DeliveryStore.horaInicioJornadaPartida) || null;
			deliveryStore.nombreTienda = JsonQuery.value(store, JSON_PATHS.DeliveryStore.nombreTienda) || null;
			deliveryStore.sfid = JsonQuery.value(store, JSON_PATHS.DeliveryStore.sfid) || null;
			deliveryStore.codigoPostal = JsonQuery.value(store, JSON_PATHS.DeliveryStore.codigoPostal) || null;
			deliveryStore.latitud = JsonQuery.value(store, JSON_PATHS.DeliveryStore.latitud) || 0;
			deliveryStore.longitud = JsonQuery.value(store, JSON_PATHS.DeliveryStore.longitud) || 0;
			deliveryStore.localidad = JsonQuery.value(store, JSON_PATHS.DeliveryStore.localidad) || 0;
			deliveryStore.cdAlmacen = JsonQuery.value(store, JSON_PATHS.DeliveryStore.cdAlmacen) || null;
			deliveryStore.provincia = JsonQuery.value(store, JSON_PATHS.DeliveryStore.provincia) || null;
			deliveryStore.telefono = JsonQuery.value(store, JSON_PATHS.DeliveryStore.telefono) || null;
			deliveryStore.checked = false;
			return deliveryStore;
		});
		return this.stores;
	}

	private getOficinasCorreosEntregaLogic(response): DeliveryOffice[] {
		const offices = JsonQuery.value(response, JSON_PATHS.DeliveryOffice.oficinasCorreos) || null;
		let arrayOffices = new Array<DeliveryOffice>();
		arrayOffices = offices.map((office) => {
			const deliveryOffice = new DeliveryOffice();
			deliveryOffice.acceso = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.acceso) || null;
			deliveryOffice.ccaa = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.ccaa) || null;
			deliveryOffice.codHorario = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.codHorario) || null;
			deliveryOffice.codProvincia = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.codProvincia) || null;
			deliveryOffice.codigoPostal = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.codigoPostal) || null;
			deliveryOffice.codired = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.codired) || null;
			deliveryOffice.coordXETRS89 = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.coordXETRS89) || null;
			deliveryOffice.coordXWGS84 = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.coordXWGS84) || null;
			deliveryOffice.coordYETRS89 = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.coordYETRS89) || null;
			deliveryOffice.coordYWGS84 = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.coordYWGS84) || null;
			deliveryOffice.direccion = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.direccion) || null;
			deliveryOffice.distanciaPuntoRef = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.distanciaPuntoRef) || null;
			deliveryOffice.horario = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.horario) || null;
			deliveryOffice.id = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.id) || null;
			deliveryOffice.latitud = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.latitud) || null;
			deliveryOffice.latitudETRS89 = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.latitudETRS89) || null;
			deliveryOffice.latitudWGS84 = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.latitudWGS84) || null;
			deliveryOffice.localidad = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.localidad) || null;
			deliveryOffice.longitud = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.longitud) || null;
			deliveryOffice.longitudETRS89 = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.longitudETRS89) || null;
			deliveryOffice.longitudWGS84 = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.longitudWGS84) || null;
			deliveryOffice.nombreUnidad = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.nombreUnidad) || null;
			deliveryOffice.provincia = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.provincia) || null;
			deliveryOffice.provinciaEs = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.provinciaEs) || null;
			deliveryOffice.telefono = JsonQuery.value(office, JSON_PATHS.DeliveryOffice.telefono) || null;
			deliveryOffice.checked = false;
			return deliveryOffice;
		});
		return arrayOffices;
	}

	public getTiendasEntrega(province, latitude, longitude) {
		const url = API_URLS.Checkout.getTiendasEntrega
			.replace('{province}', province)
			.replace('{latitude}', latitude)
			.replace('{longitude}', longitude)
			.replace(
				'{shopType}',
				this.isME || this.isMicroResegmented
					? commercialMobile.shopTypeMicro.toString()
					: this.isYU
					? commercialMobile.shopTypeYu.toString()
					: commercialMobile.shopType.toString()
			)
			.replace('{checkStock}', 'false');
		const options = this.headersData();
		return this.http.get(url, options).pipe(
			map((res: any) => {
				return this.getTiendasEntregaLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getOficinasCorreosEntrega(postalCode, latitude, longitude) {
		let url = API_URLS.Checkout.getOficinasCorreosEntrega;
		url = url.replace('{postalCode}', postalCode);
		url = url.replace('{latitude}', latitude);
		url = url.replace('{longitude}', longitude);
		url = url.replace(
			'{shopType}',
			this.isME || this.isMicroResegmented
				? commercialMobile.shopTypeMicro.toString()
				: this.isYU
				? commercialMobile.shopTypeYu.toString()
				: commercialMobile.shopType.toString()
		);
		const options = this.headersData();
		return this.http.get(url, options).pipe(
			map((res: any) => {
				return this.getOficinasCorreosEntregaLogic(res);
			}),
			catchError((err) => {
				return throwError([]);
			})
		);
	}

	private getShippingCosts(
		itemToStore: CommercialShippingCosts,
		itemToGet: CommercialShoppingCart
	): CommercialShippingCosts {
		itemToStore = new CommercialShippingCosts();
		itemToStore.fromActivated = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.fromActivated) || false;
		itemToStore.postalCode = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.postalCode) || null;
		itemToStore.upfront = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.upfrontShippingCosts) || 0;
		itemToStore.upfrontPromo =
			JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.upfrontPromoShippingCosts) || 0;
		itemToStore.upfrontTaxes =
			JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.upfrontTaxesShippingCosts) || 0;
		itemToStore.upfrontTaxesPromo =
			JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.upfrontTaxesPromoShippingCosts) || 0;
		return itemToStore;
	}

	private getCheckoutCriteriaDto(
		itemToStore: CommercialCheckoutCriteriaDto,
		itemToGet: CommercialShoppingCart
	): CommercialCheckoutCriteriaDto {
		itemToStore = new CommercialCheckoutCriteriaDto();
		itemToStore.deliveryType = JsonQuery.value(itemToGet, JSON_PATHS.CriteriaDto.deliveryType) || null;
		itemToStore.postalCode = JsonQuery.value(itemToGet, JSON_PATHS.CriteriaDto.postalCode) || null;
		itemToStore.bankAccount = JsonQuery.value(itemToGet, JSON_PATHS.CriteriaDto.bankAccount) || null;
		itemToStore.paymenttypeid = JsonQuery.value(itemToGet, JSON_PATHS.CriteriaDto.paymentTypeId) || 0;
		return itemToStore;
	}

	getLegalterms() {
		const url = API_URLS.Checkout.getLegalTerms
			.replace(
				'{clientType}',
				this.isME || this.isMicroResegmented
					? commercialMobile.clientTypeMicro.toString()
					: commercialMobile.clientType.toString()
			)
			.replace(
				'{shopType}',
				this.isME || this.isMicroResegmented
					? commercialMobile.shopTypeMicro.toString()
					: this.isYU
					? commercialMobile.shopTypeYu.toString()
					: commercialMobile.shopType.toString()
			)
			.replace('{page}', commercialMobile.page);
		const options: OptionCommercialMobile = this.headersData();

		return this.http.get(url, options).pipe(
			map((res: any) => {
				this.legalTermsLogic(res);
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	private legalTermsLogic(legal: LegalTermDetail) {
		this.legalTermsArray = new Array<LegalTerms>();
		const listAceptos = JsonQuery.value(legal, JSON_PATHS.LegalTermJson.listAceptos) || null;
		listAceptos.map((op) => {
			const legalBasics = new LegalTerms();

			legalBasics.checked = JsonQuery.value(op, JSON_PATHS.LegalTermJson.checked) || false;
			legalBasics.id = JsonQuery.value(op, JSON_PATHS.LegalTermJson.id) || null;
			legalBasics.internalDescription = JsonQuery.value(op, JSON_PATHS.LegalTermJson.internalDescription) || null;
			legalBasics.orden = JsonQuery.value(op, JSON_PATHS.LegalTermJson.orden) || null;

			legalBasics.legalTermDetail = new Array<LegalTermDetail>();
			const legalDetail = JsonQuery.value(op, JSON_PATHS.LegalTermJson.legalTermDetails) || null;
			legalDetail.map((ap) => {
				const legalDetailes = new LegalTermDetail();
				legalDetailes.htmlText = JsonQuery.value(ap, JSON_PATHS.LegalTermJson.htmlText) || null;
				legalDetailes.linkShortText = JsonQuery.value(ap, JSON_PATHS.LegalTermJson.linkShortText) || null;
				legalDetailes.shortText = JsonQuery.value(ap, JSON_PATHS.LegalTermJson.shortText) || null;

				legalBasics.legalTermDetail.push(legalDetailes);
				return legalDetailes;
			});
			this.legalTermsArray.push(legalBasics);
			return legalBasics;
		});
		this.sortLegalTerms(this.legalTermsArray);
	}

	private sortLegalTerms(legalTerms: LegalTerms[]) {
		legalTerms.sort((termFirst, termSecond) => {
			return termFirst.orden < termSecond.orden ? -1 : 1;
		});
		return legalTerms;
	}

	postLegalTerms(id: number[]): Observable<void> {
		const url: string = API_URLS.Checkout.postLegalTerms;
		const headers: HttpHeaders = new HttpHeaders();
		const options: { headers: HttpHeaders; responseType: 'json'; withCredentials: boolean } = {
			headers: headers,
			responseType: 'text' as 'json',
			withCredentials: true,
		};
		const listAceptos: Array<{ id: number; checked: boolean }> = [];
		id.forEach((res) => {
			const plantilla: { id: number; checked: boolean } = { id: res, checked: true };
			listAceptos.push(plantilla);
		});
		const params: { listAceptos: Array<{ id: number; checked: boolean }> } = {
			listAceptos,
		};
		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	postLegalTermsSave() {
		const url = API_URLS.Checkout.postLegalTermsSave;
		const headers = new HttpHeaders();
		const options = {
			headers: headers,
			withCredentials: true,
		};
		const params = {};
		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				this.personalData.termsId = res.idIkkiRequest;
				this.personalData.email = res.email;
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getTerminalsElement(): CommercialTerminal[] {
		return this.terminals.slice();
	}

	public setTerminalsElement(terminals: CommercialTerminal[]) {
		this.terminals = terminals.slice();
	}

	public deleteProducts(itemId: string): Observable<any> {
		const url: string = API_URLS.DeleteProduct;
		const headers: HttpHeaders = new HttpHeaders();
		const options: any = {
			headers: headers,
			auth_ticket: this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT),
			NTOL_TXID: this.microFlowService.cartId,
			withCredentials: true,
		};
		const params: any = { itemid: itemId };

		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				this.shoppingCartLogic(res);
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	private setShoppingCartParams(
		idPrecio: number,
		paymentType: boolean | number,
		flagPaymentOptionFp: boolean,
		finalPaymentMonths?: number,
		itemId?: number
	): object {
		const params: object = { idPrecio: idPrecio, paymentType: paymentType, flagPaymentOptionFp: flagPaymentOptionFp };
		if (flagPaymentOptionFp) {
			params['finalPaymentMonths'] = finalPaymentMonths;
		}
		params['shopTypeId'] =
			this.isME || this.isMicroResegmented
				? Number(commercialMobile.shopTypeMicro)
				: this.isYU
				? Number(commercialMobile.shopTypeYu)
				: Number(commercialMobile.shopType);

		if (itemId || itemId === 0) {
			params['itemid'] = itemId;
		} else if (this.allowedServices?.selectedLine?.itemId) {
			params['itemid'] = Number(this.allowedServices.selectedLine.itemId) ?? Number(this.cartItemIdTerminals);
		} else {
			params['relatedService'] = Number(this.allowedServices?.selectedLine?.serviceId) || Number(this.msisdn);
		}
		if (this.insuranceModality) {
			params['modalidadSeguro'] = this.insuranceModality;
		}
		return params;
	}

	public setShoppingCartParamsBuyNow(
		idPrecio: number,
		paymentType: boolean | number,
		flagPaymentOptionFp: boolean,
		itemId?: number
	): ParamsBuyNow {
		const params: ParamsBuyNow = {
			idPrecio: idPrecio,
			paymentType: paymentType,
			flagPaymentOptionFp: flagPaymentOptionFp,
		};
		if (this.isME || this.isMicroResegmented) {
			params['shopType'] = Number(commercialMobile.shopTypeMicro);
		} else {
			params['shopType'] = this.isYU ? Number(commercialMobile.shopTypeYu) : Number(commercialMobile.shopType);
		}
		if (itemId || itemId === 0) {
			params['itemid'] = itemId;
		} else if (this.allowedServices?.selectedLine?.itemId) {
			params['itemid'] = Number(this.allowedServices.selectedLine.itemId) ?? Number(this.cartItemIdTerminals);
		} else {
			params['relatedService'] = Number(this.allowedServices?.selectedLine?.serviceId) || Number(this.msisdn);
		}
		if (this.insuranceModality) {
			params['modalidadSeguro'] = this.insuranceModality;
		}
		params['app'] = 0;
		params['version'] = this.translate.instant('v10.commercial.fullVersionPortalEP');
		params['siteId'] = this.subscriptionData.customerData.currentService.siteId;
		params['clientType'] =
			this.isME || this.isMicroResegmented
				? Number(commercialMobile.clientTypeMicro)
				: Number(commercialMobile.clientType);
		params['sceneType'] = Number(commercialMobile.sceneType);
		return params;
	}

	postRequestNotifyMeByEmail(email: string, legalConditionsAccepted: boolean, sap: Number): Observable<any> {
		const url: string = API_URLS.CommercialMobiles.presaleTerminal.clientNotification;
		const headers: HttpHeaders = new HttpHeaders();
		const options: any = {
			headers: headers,
			withCredentials: true,
		};
		const params: any = {
			emailContact: email,
			acceptConditions: legalConditionsAccepted,
			sap: sap,
			clientType:
				this.isME || this.isMicroResegmented
					? Number(commercialMobile.clientTypeMicro)
					: Number(commercialMobile.clientType),
			shopType:
				this.isME || this.isMicroResegmented
					? Number(commercialMobile.shopTypeMicro)
					: this.isYU
					? Number(commercialMobile.shopTypeYu)
					: Number(commercialMobile.shopType),
			sceneType: Number(commercialMobile.sceneType),
			clientId: this.subscriptionData.customerData.currentService.siteId,
		};
		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	hasXrContentDeviceOnCart(): boolean {
		const saps: string[] = this.translate
			.instant('v10.commercial.checkout.confirmation.xrContents.IDSAPList')
			.split(',');
		return (
			this.commercialshoppingCartItems.cartItems.some((item) =>
				item.services.find((srv) => saps.includes(srv.detail.sap))
			) &&
			this.translate.instant('v10.commercial.checkout.confirmation.xrContents.active').toLowerCase() === stringOfTrue
		);
	}
	selectNumber(
		temp: DeliveryType,
		flagStore: boolean,
		flagOffice: boolean,
		flagHomeAdd: boolean,
		flagHomeChange: boolean,
		wasInvalid: boolean
	): string {
		switch (temp?.cdType) {
			case deliveryParams.storeCd:
				if (!flagStore) {
					return this.defaultNumber;
				} else {
					return this.phoneNumber;
				}
			case deliveryParams.homeCd:
				return this.homeNumberSelect(flagHomeAdd, flagHomeChange, wasInvalid);
			case deliveryParams.officeCd:
				if (!flagOffice) {
					return this.defaultNumber;
				} else {
					return this.phoneNumber;
				}
		}
	}

	homeNumberSelect(flagHomeAdd: boolean, flagHomeChange: boolean, wasInvalid: boolean): string {
		if ((!flagHomeAdd && !flagHomeChange) || (wasInvalid && flagHomeChange && !flagHomeAdd)) {
			return this.originNumber;
		} else if (!flagHomeChange) {
			return this.defaultNumber;
		} else {
			return this.phoneNumber;
		}
	}

	public resetPLTerminals(): void {
		this.PlaylistListTerminals = undefined;
		this.playlistSelected = null;
	}

	public getInsurancesList(shopType: string, sap: string): Observable<InsuranceModel[]> {
		const url: string = API_URLS.Insurance.getInsurance
			.replace('{msisdn}', this.msisdn)
			.replace('{sap}', sap)
			.replace('{shopType}', shopType);
		const headers: HttpHeaders = new HttpHeaders();
		const options: { headers: HttpHeaders; withCredentials: boolean } = {
			headers: headers,
			withCredentials: true,
		};

		return this.http.get(url, options).pipe(
			map((res: InsurancePrices[]) => {
				return res.map((item: InsurancePrices) => this.mapInsuranceList(item));
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	private mapInsuranceList(res: InsurancePrices): InsuranceModel {
		const wcsInsurance: InsuranceModel = this.translate
			.instant('v10.commercial.care.insuranceSelector.overlay.insurances')
			.find((insurance: InsuranceModel) => insurance.name.toLowerCase() === res.name.toLowerCase());
		return {
			name: res.name,
			description: wcsInsurance.description,
			longDescription: wcsInsurance.longDescription,
			excess: res.excessFee,
			discountedFee: res.promotion,
			fee: res.finalPrice,
			discountDuration: res.duration,
			productId: res.productId,
			coverages: wcsInsurance.coverages,
		};
	}
}
