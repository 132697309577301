import { GroupList } from './oneGroupList.model';

export class OneProfesionalData {
	nombreAdm: string;
	apellidosAdm: string;
	emailAdm: string;
	nifAdm: string;
	telefonoAdm: string;
	dominioNuevo: string;
	dominios: Array<string>;
	numDominios: string;
	licencias: Array<string>;
	numLicencias: string;
	url: string;
	actividad: string;
	telefonoPublicacion: string;
	emailPublicacion: string;
	lineInstanceOrigin: string;
	direccionVisible: boolean;
	redSocial: string;
}

export class Ticket {
	faultyCode: string;
	offerData: string;
	adminData: string;
	remember: string;
	rememberDesc: string;
}

export class StateService {
	available: boolean;
	codProvision: string;
	hireable: boolean;
	instance: string;
	msisdn: string;
	rgu: string;
}

export class OnepGroupList {
	onepGroupList: GroupList[];
}
export class ProductList {
	btn_buy: string;
	btn_try: string;
	description: string;
	products: ProductListProducts;
	title: string;
}

export class ProductPromotion {
	perct: string;
	priceWithPromotion: string;
	priceWithPromotionOrigin: string;
	priceWithPromotionTaxes: string;
	shortTextPromotion: string;
	longTextPromotion: string;
	originalPrice: number;
	imgPromotion: string;
	ticketText: string;
	textEnd: string;
	promotionsResumeTxt?: string;
	ResumeOriginalPrice?: string;
}

interface ProductListProducts {
	TABBI: ProductListProductsIco;
	TABCL: ProductListProductsIco;
	TABSD: ProductListProductsIco;
}

interface ProductListProductsIco {
	ico: string;
}

export class OpProduct {
	clHtmlDescrLargaIkki: string;
	clHtmlDescrNoDispIkki: string;
	dsDescripcionInterna: string;
	flagProvision: boolean;
	id: number;
	idCCategoria: number;
	idCPuntoProcesoCompra: number;
	idTipoAgrupacion: number;
	idTipoAsociado: number;
	idTipoEstado: number;
	idTipoTienda: number;
	imgUrlIkki: string;
	itCatalogofferFamilia: boolean;
	itTryAndBuy: boolean;
	name: string;
	onepProducts: [];
	position: number;
}

export class Promotion {
	added: boolean;
	type: string;
	id: number;
	internalName: string;
	publicName: string;
	startDate: string;
	endDate: string;
	stateType: string;
	flagRegional: boolean;
	manualProvision: boolean;
	flagPrivate: boolean;
	discountClassType: string;
	discountDuration: string;
	discountType: string;
	discountAmount: string;
	applyTo: string;
	catalogOffer: string;
	descriptionCrm: string;
	linkedToCatalog: boolean;
	showEvenWithoutLink: boolean;
	amdocsType: number;
	rgu: string;
	flagIgnoreCRM: boolean;
	emailLongText: string;
	ticketTextDetail: string;
	summarycontractText: string;
	tvBannerText: string;
	addLineBannerText: string;
	showStrikethrough: boolean;
	keep: boolean;
	evaluationOrder: number;
	shortText: string;
	longText: string;
	ticketText: string;
	confirmationText: string;
	emailText: string;
	fileBannerText: string;
	basketBannerText: string;
	headbandCloseText: string;
	headbandOpenText: string;
	stayPromotionText: string;
	starText: string;
	stayPromotionTextFicha: string;
	longTextFicha: string;
	urlMobilePromotionImageFicha: string;
	detailTextList: string;
	detailTextFicha: string;
	shopType: number;
	productCriteriaNameList: [string];
}

export class RecomendedOffers {
	evaluatedOffers: {
		services: [id: any];
	};
	id: string;
	typeReco: string;
}

export class EvaluatedOff {
	services: string[];
}

export class RunProduct {
	clHtmlDescrLargaIkki?: string;
	clHtmlDescrNoDispIkki?: string;
	dsDescripcionInterna?: string;
	flagProvision?: boolean;
	id?: number;
	idCCategoria?: number;
	idCPuntoProcesoCompra?: number;
	idTipoAgrupacion?: number;
	idTipoAsociado?: number;
	idTipoEstado?: number;
	idTipoTienda?: number;
	imgUrlIkki?: string;
	itCatalogofferFamilia?: boolean;
	itTryAndBuy?: boolean;
	name?: string;
	onepProducts?: OneProducts[];
	position?: number;
}

export interface OneProducts {
	available: true;
	clHtmlCondicionesLegales: string;
	clHtmlDescrLargaIkki: string;
	clHtmlTicketLateral: string;
	codProvision: string;
	dsDescripcionInterna: string;
	groupAssociation: number;
	groupId: number;
	groupName: string;
	hireable: boolean;
	id: string;
	idCCategoria: number;
	idTipoEstado: number;
	idTipoTienda: number;
	idTipoUsuario: number;
	imgUrlIkki: string;
	itCatalogofferFamilia: boolean;
	itCatalogofferNoCuota: boolean;
	itDominioExistente: boolean;
	itIncompatibleSelected: boolean;
	itLicenciaObligatoria: boolean;
	itPrerrequisiteSelected: boolean;
	itProductoSeleccionado: boolean;
	itProvisionManual: boolean;
	itServiceabilityOk: boolean;
	legalTermsPage: string;
	name: string;
	pageLegalTermsKey: string;
	paymentMethodsList: PaymentMethodsList[];
	position: number;
	listStateServiceInstance?: Instance[];
}

export interface PaymentMethodsList {
	cdCatalogoffer: string;
	cdNameCatalogOffer: string;
	fee: number;
	feeDescription: string;
	feeTaxes: number;
	itCatalogoOffer: boolean;
	paymentType: number;
	selectedByDefault: boolean;
	taxType: string;
	tipoProducto: string;
}

export interface Instance {
	available: boolean;
	codProvision: string;
	hireable: boolean;
	instance: string;
	msisdn: string;
	rgu: string;
}

export class OneProfNexus {
	id: number;
	idTipoTienda: number;
	idTipoEstado: number;
	idTipoUsuario: number;
	idCCategoria: number;
	name: string;
	dsDescripcionInterna: string;
	position: number;
	paymentMethodsList: PaymentMethodsListNexus[];
	itDominioExistente: boolean;
	itProvisionManual: boolean;
	itLicenciaObligatoria: boolean;
	imgUrlIkki: string;
	imgTooltipIkki: string;
	imgUrlResumenIkki: string;
	imgTooltipResumenIkki: string;
	clHtmlCondicionesLegales: string;
	clHtmlDescrLargaIkki: string;
	itCatalogofferNoCuota: boolean;
	itServiceabilityOk: boolean;
	itProductoSeleccionado: boolean;
	itPrerrequisiteSelected: boolean;
	itIncompatibleSelected: boolean;
	pageLegalTermsKey: string;
	legalTermsPage: string;
	elementTypePackage: boolean;
}

interface PaymentMethodsListNexus {
	tipoProducto: string;
	paymentType: number;
	fee: number;
	feeTaxes: number;
	taxType: string;
	cdCatalogoffer: string;
	feeDescription: string;
	itCatalogoOffer: boolean;
	selectedByDefault: boolean;
	cdNameCatalogOffer: string;
}

export interface OverlayOpNexus {
	include: IncludeData;
	desc: string;
	price: string;
	banner: string;
	banner_rsp: string;
	what: string;
	button: string;
}

interface IncludeData {
	elements: ElementInclude[];
	title: string;
}

interface ElementInclude {
	description: string;
	image: string;
}

export class DataPega {
	isPega: boolean;
	productId: number;
	productname: string;
	istab: boolean;
	modalityid: number;
	pegaDiscount?: string;
}
