export class SimDataModel {
	sim: string;
	pins: {
		pin1: string;
		pin2: string;
	};
	puks: {
		puk1: string;
		puk2: string;
	};
	expiryDate?: string;
}
