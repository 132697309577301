<div
	class="form-group col-xs-12 sp-text"
	[ngClass]="{ 'mva10-style': mva10Textbox, 'mva10-style--no-padding': mva10Textbox && mva10TextboxRemovePadding }"
>
	<label *ngIf="label" for="{{ id }}"> {{ label }} </label>
	<div class="pop-up-container" *ngIf="isShow">
		<div class="pop-up-background"></div>
		<div id="arrow"></div>
		<div class="pop-up-text">
			<div class="row">
				<div class="col-xs-1">
					<span class="icon icon_info-circle-circle-64" id="icon-info-white"> </span>
				</div>
				<div class="col-xs-9">
					<p class="txt-rg popuptext" id="myPopup" [innerHTML]="infoText"></p>
				</div>

				<div class="col-xs-1 close-icon">
					<span class="icon icon_close" (click)="closePopup()"> </span>
				</div>
			</div>
		</div>
	</div>
	<div class="input-txt-container">
		<div
			class="error-message"
			*ngIf="
				patternErrorMessage && ((!variable.valid && variable.value && enforceValidation) || showError) && !mva10Textbox
			"
		>
			<i class="icon icon-warning"> </i> <span> {{ patternErrorMessage }} </span>
		</div>
		<div
			class="border"
			*ngIf="mva10Textbox && (focused || (variable.touched && dirtyValidationCheck))"
			[ngClass]="{ 'error-class': !variable.valid && (variable.value || dirtyValidationCheck) && enforceValidation }"
		>
			<div class="placeholder" [ngClass]="{ grayPlaceHolder: mva10Textbox && !focused && variable.valid }">
				<p [innerHTML]="placeholder"></p>
			</div>
		</div>
		<span
			*ngIf="mva10Textbox && !variable.valid && (variable.value || dirtyValidationCheck) && enforceValidation"
			class="icon icon-warning-notification error-icon"
		>
		</span>

		<textarea
			[rows]="areaRowsNo"
			class="form-control error"
			name=""
			id="{{ id }}"
			[pattern]="pattern"
			[required]="isRequired"
			[ngClass]="{
				'error': enforceValidation && !variable.valid,
				'upperCase': isUpperCase,
				'mva10-not-focused-textbox': mva10Textbox && !focused,
				'mva10-focused-textbox': mva10Textbox && focused
			}"
			[placeholder]="placeholder"
			[spMaxLength]="max"
			(keyup)="Change()"
			(blur)="onBlur()"
			[(ngModel)]="innerValue"
			#variable="ngModel"
			(focus)="onFocusEvent()"
		>
		</textarea>
		<span
			*ngIf="mva10Textbox && !variable.valid && (variable.value || dirtyValidationCheck) && enforceValidation"
			class="error-text"
		>
			{{ patternErrorMessage }}
		</span>
		<a class="icon">
			<span
				class="icon icon_info-circle"
				id="icon-info-white"
				(click)="openPopup()"
				[ngClass]="{ resizeIcon: !isShow }"
				*ngIf="showInfoIcon"
			>
			</span>
			<span class="icon icon_viewed" *ngIf="hashed" [ngClass]="{ resizeIcon: !isShow }" (click)="toggleIcon()"> </span>
			<span class="icon icon_hide-password" *ngIf="!hashed" [ngClass]="{ resizeIcon: !isShow }" (click)="toggleIcon()">
			</span>
		</a>
	</div>
</div>
