import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'sp-button',
	templateUrl: './sp-button.component.html',
	styleUrls: ['./sp-button.component.scss'],
})
export class SpButtonComponent implements OnInit {
	@Input() text: string;
	@Input() color: string;
	@Input() parentClass: string;
	@Input() icon: string;
	@Input() shape: string;
	@Input() type: string;
	@Input() size: string;
	ngClass: string;

	ngOnInit(): void {
		this.ngClass = this.parentClass.split("'").join('');
		this.ngClass += this.color ? ' mvf-button--purple' : '';
	}
}
