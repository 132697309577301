export class PromotionsBox {
	public icon: string;
	public title: string;
	public description?: string;
	public button?: string;
}

export class HandsetsBox {
	public principal: boolean;
	public title: string;
	public type: string;
	public icon: string;
	public description: string;
	public monthlyPayment?: number;
	public monthlyPaymentTaxes?: number;
	public months?: number;
	public initialPayment?: number;
	public initialPaymentTaxes?: number;
	public finalPayment?: number;
	public finalPaymentTaxes?: number;
	public promotions: Array<PromotionsBox>;
	public orderType: string;
	public addedProduct?: boolean;
	public itemId: string;
	public finalRefinancedPay?: string;
	public finalRefinancedPayTaxes?: string;
	public insurance?: InsuranceBox;
	public opServices?: Array<OpServices>;
}

export class InsuranceBox {
	name: string;
	price: string;
	promotionalPrice: string;
}

export class OpServices {
	image: string;
	name: string;
	price: string;
}
