<div *ngIf="topTileLoaded; else topTileSkeletonContent">
	<div class="mva10-top-tile" *ngIf="!topTileError; else topTileErrorContent">
		<mva10-c-carousel
			class="mva10-c-carousel-card"
			[config]="carouselConfig"
			[hasMask]="true"
			[appMobile]="true"
			[positionIndex]="positionIndex"
			(mva10OnChange)="showVal($event.position)"
		>
			<mva10-c-carousel-item *ngFor="let service of arrayProductsServices; index as i">
				<div class="header-card">
					<div class="mva10-top-tile__title">
						<svg
							id="dashboardTopTileIcon"
							[ngClass]="{ 'tariff-name-container__icon': isEligibleForTariffNameDisplay }"
						>
							<use [attr.xlink:href]="iconTitle" />
						</svg>
						<div [ngClass]="{ 'tariff-name-container': isEligibleForTariffNameDisplay }">
							<p id="dashboardTopTileTariffName" class="tariff-name" *ngIf="isEligibleForTariffNameDisplay">
								{{ tariffService?.Tariff?.Name }}
							</p>
							<p [ngClass]="{ 'service-num': isEligibleForTariffNameDisplay }" id="{{ 'textTitle' + i }}">
								{{ textTitle }}
							</p>
						</div>
					</div>
				</div>
				<span (click)="topTileClick()" id="topTileBtnCard">
					<div *ngIf="isPostpaid">
						<div id="topTileUnlimited" *ngIf="service.ilimitada">
							<div id="topTileUnlimitedDiv1" class="mva10-top-tile-postpaid__content">
								<div
									id="topTileUnlimitedDiv2"
									class="mva10-top-tile__data-usage"
									*ngFor="let itemDataUsageData of arrayDataUsageData; let i = index"
								>
									<div id="topTileUnlimitedDataUsage{{ i }}" class="mva10-top-tile__data-usage--border">
										<vfac-data-usage
											id="itemDataUsageDataID"
											[dataUsageData]="itemDataUsageData"
											[yuAppearance]="this.appTheme.checkYuUser()"
										>
										</vfac-data-usage>
									</div>
								</div>
							</div>
							<div>
								<label
									class="mva10-top-tile-postpaid__bottom-left"
									id="topTilePostpaidUnlimitedText"
									*ngIf="isTarifUnlimited"
									>{{ 'v10.dashboard.tariffCard.bottomText' | translate }}</label
								>
							</div>
						</div>
						<div id="topTileNotUnlimited" *ngIf="!service.ilimitada">
							<div id="topTileNotUnlimitedDiv1" class="mva10-top-tile-postpaid__content">
								<div
									id="topTileNotUnlimitedDiv2"
									class="mva10-top-tile__data-usage"
									*ngFor="let itemDataUsageData of arrayDataUsageData; let i = index"
								>
									<div id="topTileNotUnlimitedDataUsage{{ i }}" class="mva10-top-tile__data-usage--border">
										<vfac-data-usage [dataUsageData]="itemDataUsageData" [yuAppearance]="this.appTheme.checkYuUser()">
										</vfac-data-usage>
									</div>
								</div>
							</div>
							<div id="topTileNotUnlimitedDivValue" class="mva10-top-tile-postpaid__bottom">
								<label class="mva10-top-tile-postpaid__bottom-left" id="topTilePostpaidTextBottomLeft">
									{{ valueOutOfBundle }}
								</label>
								<div id="topTileNotUnlimitedDivNotHasRoamers" class="mva10-top-tile-postpaid__bottom-right">
									<label
										class="mva10-top-tile-postpaid__bottom-label"
										id="topTilePostpaidTextBottomRight"
										*ngIf="!hasRoamers"
									>
										{{ topTileBottomRight }}
									</label>
									<span id="topTileNotUnlimitedDivHasRoamers" *ngIf="hasRoamers">
										<label
											class="mva10-top-tile-postpaid__bottom-label mva10-top-tile-postpaid__bottom-label--red"
											id="topTilePostpaidTextBottomRightRoamers"
										>
											{{ 'v10.roaming.mainTile.exceedDesc' | translate }}
										</label>
										<svg id="topTileNotUnlimitedDivNotHasRoamersWarning" class="mva10-u--ml1x">
											<use xlink:href="#icon-warning-esp" />
										</svg>
									</span>
								</div>
							</div>
						</div>
					</div>

					<div *ngIf="isFibre" class="mva10-top-tile-fibre-content">
						<div *ngIf="fibreDownloadSpeed" class="mva10-top-tile-fibre-content__data">
							<svg class="mva10-top-tile-fibre-content__svg">
								<use xlink:href="#icon-download" />
							</svg>
							<label
								class="mva10-u-heading--5 mva10-u--mt2x mva10-top-tile-fibre-content__label-speed"
								id="topTileFibreDownloadSpeed"
							>
								{{ fibreDownloadSpeed }}
							</label>
							<label class="mva10-top-tile-fibre-content__label-info" id="topTileFibreDownloadSpeedText">
								{{ fibreDownloadSpeedText }}
							</label>
						</div>

						<div *ngIf="fibreUploadSpeed" class="mva10-top-tile-fibre-content__data">
							<svg class="mva10-top-tile-fibre-content__svg">
								<use xlink:href="#icon-upload" />
							</svg>
							<label
								class="mva10-u-heading--5 mva10-u--mt2x mva10-top-tile-fibre-content__label-speed"
								id="topTileFibreUploadSpeed"
							>
								{{ fibreUploadSpeed }}
							</label>
							<label class="mva10-top-tile-fibre-content__label-info" id="topTileFibreUploadSpeedText">
								{{ fibreUploadSpeedText }}
							</label>
						</div>
					</div>

					<div *ngIf="isLandline">
						<div class="mva10-top-tile-landline-content">
							<svg *ngIf="breakpoint !== BREAKPOINTS.MOBILE" class="mva10-top-tile-landline-content__svg">
								<use xlink:href="#incoming-call" />
							</svg>
							<label id="topTileLandlineLimitedText" class="mva10-top-tile-landline-content__label-unlimited">
								{{ label_unlimited }}
							</label>
							<label
								id="topTileLandlineTypeText"
								*ngIf="breakpoint !== BREAKPOINTS.MOBILE"
								class="mva10-top-tile-landline-content__label-type"
							>
								{{ label_type }}
							</label>
							<meter
								class="mva10-top-tile-landline-content__meter"
								[ngClass]="{ 'mva10-c-data-chart--yu': this.appTheme.checkYuUser() }"
								max="100"
								min="0"
								value="{{ value_landline }}"
							></meter>
						</div>
						<label id="topTileLandlineOob" class="mva10-top-tile__oob"> {{ valueOutOfBundle }} </label>
					</div>

					<div *ngIf="isMbb">
						<div class="mva10-top-tile-mbb-content">
							<svg *ngIf="breakpoint !== BREAKPOINTS.MOBILE" class="mva10-top-tile-mbb-content__svg">
								<use xlink:href="#icon-upload" />
							</svg>
							<span class="mva10-top-tile-mbb-content__block-usage">
								<span>
									<label id="topTileLandlineLimitedText" class="mva10-top-tile-mbb-content__label-used">
										{{ labelUsed }}
									</label>
									<label id="topTileLandlineLimitedText" class="mva10-top-tile-mbb-content__label-available-unit">
										{{ labelUnit }}
									</label>
								</span>

								<label id="topTileLandlineLimitedText" class="mva10-top-tile-mbb-content__label-available">
									{{ labelAvailable }}
								</label>
							</span>
							<meter class="mva10-top-tile-mbb-content__meter" max="100" min="0" value="{{ valueMbb }}"></meter>
						</div>
						<label id="topTileMbbOob" class="mva10-top-tile__oob"> {{ valueOutOfBundle }} </label>
					</div>

					<div *ngIf="isTV">
						<div class="mva10-top-tile-tv-content">
							<span class="mva10-top-tile-tv-content__block">
								<label id="topTileTvInfoPacks">
									{{ infoPacks }}
								</label>
								<label id="topTileTvDescPacks" class="mva10-u-ellipsis--single-line">
									{{ descPacks }}
								</label>
							</span>

							<svg class="mva10-top-tile-tv-content__svg" *ngIf="breakpoint === BREAKPOINTS.MOBILE">
								<use xlink:href="#icon-tv-mid" />
							</svg>

							<img
								id="topTileTvImgTv"
								class="mva10-top-tile-tv-content__img"
								*ngIf="breakpoint !== BREAKPOINTS.MOBILE"
								src="{{ appTheme.getImgFullPath('v10.dashboard.images.product_selector.tv' | translate) }}"
								alt="tv"
							/>
						</div>
						<label id="topTileTvOob" class="mva10-top-tile__oob"> {{ valueOutOfBundle }} </label>
					</div>

					<div *ngIf="isPrepaid">
						<div *ngIf="purePrepaid" class="mva10-top-tile-pure-prepaid-content">
							<span class="mva10-top-tile-pure-prepaid-content__amount" id="topTilePrepaidAmount">
								<label> {{ amountPrepaid }} </label> <label>€</label>
							</span>
							<div class="mva10-top-tile-pure-prepaid-content__bottom">
								<label id="topTilePrepaidLastRecharge" class="mva10-top-tile-pure-prepaid-content__label-bottom">
									{{ lastRecharge }}
								</label>
								<label
									id="topTilePrepaidRecharge"
									class="mva10-top-tile-pure-prepaid-content__label-bottom mva10-top-tile-pure-prepaid-content__label-bottom--red"
								>
									{{ 'v10.dashboard.common.recharge' | translate }}
								</label>
							</div>
						</div>
						<div *ngIf="prepaidWithBenefitsInactive" class="mva10-top-tile-benefits-inactive-prepaid-content">
							<div class="mva10-top-tile-benefits-inactive-prepaid-content__desc">
								<label id="topTilePrepaidBenefitsInactiveInfo">
									{{ 'v10.dashboard.common.tile_recharge_deactive_new' | translate }}
								</label>
								<label id="topTilePrepaidBenefitsInactiveDesc">
									{{ 'v10.dashboard.common.tile_recharge_deactive_desc_new' | translate }}
								</label>
							</div>
							<svg
								class="mva10-top-tile-benefits-inactive-prepaid-content__svg"
								*ngIf="breakpoint === BREAKPOINTS.MOBILE"
							>
								<use xlink:href="#icon-bundles-mid" />
							</svg>
							<img
								id="topTilePrepaidBenefitsInactiveImg"
								class="mva10-top-tile-benefits-inactive-prepaid-content__img"
								*ngIf="breakpoint !== BREAKPOINTS.MOBILE"
								src="{{ appTheme.getImgFullPath('v10.dashboard.images.empty_box_prepaid' | translate) }}"
								alt="box"
							/>
						</div>
						<div *ngIf="prepaidWithBenefitsActive" class="mva10-top-tile-benefits-active-prepaid-content">
							<div
								class="mva10-top-tile__data-usage"
								*ngFor="let itemDataUsageData of arrayDataUsageData; let i = index"
							>
								<div class="mva10-top-tile__data-usage--border">
									<vfac-data-usage [dataUsageData]="itemDataUsageData" [yuAppearance]="this.appTheme.checkYuUser()">
									</vfac-data-usage>
								</div>
							</div>
						</div>
						<div *ngIf="roamersPrepaidWarningTitle" class="mva10-top-tile-roamers-prepaid-content">
							<mva10-c-icon [size]="2" iconId="icon-notification-warn" state="error"> </mva10-c-icon>
							<span id="topTilePrepaidTextBottomRightPrepaidRoamersWarningText">
								{{ roamersPrepaidWarningTitle }}
							</span>
						</div>
					</div>
				</span>
			</mva10-c-carousel-item>
		</mva10-c-carousel>
	</div>
</div>

<ng-template #topTileSkeletonContent>
	<div class="skeleton-top-tile-mobile" *ngIf="breakpoint === BREAKPOINTS.MOBILE">
		<div class="skeleton-top-tile-mobile__title">
			<div class="skeleton"></div>
			<div class="skeleton"></div>
		</div>
		<div class="skeleton-top-tile-mobile__content">
			<div class="skeleton"></div>
			<div class="skeleton"></div>
		</div>
	</div>
	<div class="skeleton-top-tile-resp" *ngIf="breakpoint !== BREAKPOINTS.MOBILE">
		<div class="skeleton-top-tile-resp__content" *ngFor="let number of [0, 1, 2]">
			<div class="skeleton"></div>
			<div class="skeleton"></div>
		</div>
	</div>
</ng-template>

<ng-template #topTileErrorContent>
	<div class="error-top-tile">
		<svg class="error-top-tile__icon">
			<use xlink:href="#icon-warning-mid" />
		</svg>
		<label class="error-top-tile__description" id="topTileErrorTextDesc">
			{{ 'v10.common.literals.msg.error.ups' | translate }}
		</label>
		<label class="error-top-tile__button" (click)="retryTopTile()" id="topTileErrorBtn">
			{{ 'v10.common.literals.retry_C' | translate }}
		</label>
	</div>
</ng-template>
