import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'sp-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
	@Input() text: string;

	constructor() {}

	ngOnInit() {}
}
