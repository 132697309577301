import { catchError } from 'rxjs/operators';
/**
 * Common Factory is a file contains all factories used in the app
 */
import { ConfigurationService } from '../../core/services/configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { SmapiService } from '../../core/services/smapi.service';
/**
 * config Loader is a Factory that holds
 * @param config  Configuration Service which contains load function that load configuration from server
 */
export function configLoaderFactory(
	config: ConfigurationService,
	translateService: TranslateService,
	smapi: SmapiService
): Function {
	return () => {
		config.load().subscribe();
		// this language will be used as a fallback when a translation isn't found in the current language
		translateService.setDefaultLang('defaultWording');
		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translateService.use('sp').pipe(
			catchError(() => {
				return translateService.use('defaultWording');
			})
		);
		smapi.initSmapi();
	};
}
