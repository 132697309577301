import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from '../../../app.service';
import { IframeWCS, ThirdPartyIframePayload } from '../../../models/payment';
import { paymentPhpUrlOk, paymentPhpUrlKo } from '../../../shared/constants/defines';
import { IframeStatus } from '../../../shared/enums/iframe-status.enum';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { PaymentWalletService } from '../../../../app/shared/services/payment-wallet.service';
@Component({
	selector: 'sp-net-plus-iframe',
	templateUrl: './net-plus-iframe.component.html',
	styleUrls: ['./net-plus-iframe.component.scss'],
})
export class NetPlusIframeComponent implements OnChanges, OnDestroy, OnInit {
	/* the url of the iframe */
	@Input() url: string = '';
	/* timer between every interaction user do between any iframe page */
	@Input() interactionsTimer: number = 50;
	/* globel timer for the whole payment journey */
	@Input() globalTimer: string = '';
	/* object that have the wcs keys and values  */
	@Input() wcsObj: IframeWCS;
	/* event emiiter to emit the net plus status*/
	@Output() public iframeStatus: EventEmitter<ThirdPartyIframePayload> = new EventEmitter<ThirdPartyIframePayload>();
	/* the sanitized url of the iframe  */
	netPlusURL: any;
	/* counter for the iframe redirection */
	counter: number = 0;
	/* TimeOut object between every interaction user do between any iframe page */
	interactionsTimeOut: any;
	/* TimeOut object for the whole payment journey */
	globalTimeOut: any;
	/* the payload object to be emitted after iframe reach final status */
	thirdPartyIframePayload: ThirdPartyIframePayload = new ThirdPartyIframePayload();
	/* boolean to show or not the partial loader  */
	showLottieLoader: boolean = true;
	/* height of page needed to be increased in case of the otp page */
	pageHeight: string = '';
	needBackStep: boolean;
	constructor(
		private sanitizer: DomSanitizer,
		private utilService: UtilitiesService,
		private paymentWalletService: PaymentWalletService,
		public appService: AppService
	) {}

	ngOnInit(): void {
		this.counter = navigator.userAgent.toLowerCase().indexOf('firefox') !== -1 ? 1 : 0;
	}
	/**
	 * @param changes
	 * life cycle hock to the iframe url and process it
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['url'] && changes['url'].currentValue) {
			this.netPlusURL = this.sanitizer.bypassSecurityTrustResourceUrl(changes['url'].currentValue);
		}
	}
	/**
	 * @param iframe iframe from dom
	 * function to check the iframe reach which screen
	 * and decide what to do read parameter
	 * or check for ok ko url or set time out
	 */
	loadURL(iframe: HTMLIFrameElement): void {
		this.showLottieLoader = false;
		this.counter++;
		if (this.counter === 2) {
			this.startGlobalTimeOut(iframe);
			this.increaseIframeOtpPageHeight();
		}
		if (this.counter === 3) {
			this.onIframeRedirection(iframe);
		}
		if (this.counter > 3) {
			this.onIframeRedirection(iframe);
			this.startInteractionsTimeOut(iframe);
		}
	}
	/* height of page needed to be increased in case of the otp page */
	increaseIframeOtpPageHeight(): void {
		this.pageHeight = '440px';
	}

	/**
	 * @param iframe iframe from dom
	 * function to start global time out
	 */
	startGlobalTimeOut(iframe: HTMLIFrameElement): void {
		if (this.netPlusURL && this.globalTimer) {
			this.globalTimeOut = setTimeout(() => {
				this.onTimeOut(iframe);
			}, +this.globalTimer * 1000);
		}
	}

	/**
	 *
	 * @param iframe iframe from dom
	 * function to start interaction time out
	 */
	startInteractionsTimeOut(iframe: HTMLIFrameElement): void {
		this.clearTimers(false);
		if (this.interactionsTimer && this.netPlusURL) {
			this.interactionsTimeOut = setTimeout(() => {
				this.onTimeOut(iframe);
			}, +this.interactionsTimer * 1000);
		}
	}

	/**
	 * @param iframe iframe from dom
	 * check for the url of ok or ko
	 * and act based on that
	 */
	onIframeRedirection(iframe: HTMLIFrameElement): void {
		try {
			if (iframe.contentWindow.location.href.indexOf(paymentPhpUrlOk) !== -1) {
				this.readNetPlusUrlParams(iframe);
				this.thirdPartyIframePayload.status = IframeStatus.complete;
				this.OnIframeFinish();
				this.needBackStep = true;
			} else if (iframe.contentWindow.location.href.indexOf(paymentPhpUrlKo) !== -1) {
				this.readNetPlusUrlParams(iframe);
				this.readErrorObj(iframe);
				this.thirdPartyIframePayload.status = IframeStatus.failed;
				this.OnIframeFinish();
				this.needBackStep = true;
			}
		} catch (error) {
			// Catch onIframeRedirection error
		}
	}
	/**
	 * @param iframe iframe from dom
	 * @param readAllParams to read all parameter or parameter related to netplus only
	 * read paramter from url
	 * some generic parameter and some is related to error or netplus
	 */
	readNetPlusUrlParams(iframe: HTMLIFrameElement, readAllParams: boolean = true): void {
		try {
			this.readExtraParametersForSavingCard(iframe);

			this.thirdPartyIframePayload.netPlusParams.cardMask = this.readIframeParameter(iframe, 'mascara');
			this.thirdPartyIframePayload.netPlusParams.cardToken = this.readIframeParameter(iframe, 'numeroCifrado');
			const datosMatchingArray: any = iframe.contentWindow.location.href.split('datosMatching=');
			const datosMatching: any =
				datosMatchingArray.length > 1
					? iframe.contentWindow.location.href.split('datosMatching=')[1].split('&')[0]
					: '';
			this.thirdPartyIframePayload.netPlusParams.transactionDate = this.utilService.formatDateWithTimeOffset(
				Number.parseInt(datosMatching, 10)
			);
			this.thirdPartyIframePayload.netPlusParams.cardExpiry = this.readIframeParameter(iframe, 'fechaCaducidad');
			if (readAllParams) {
				this.thirdPartyIframePayload.iframUrlParam = this.utilService.getRouteParams(
					iframe.contentWindow.location.href
				);
			}
		} catch (error) {
			// readNetPlusUrlParams catch error
		}
	}

	/**
	 * @param iframe iframe from dom
	 * if we have ko we need to read parmerter about the error
	 */
	readErrorObj(iframe: HTMLIFrameElement): void {
		try {
			this.thirdPartyIframePayload.error.errorCode = this.readIframeParameter(iframe, 'codigo');
			this.thirdPartyIframePayload.error.errorDescription = this.readIframeParameter(iframe, 'mensaje');
		} catch (error) {
			// readErrorObj catch error
		}
	}

	/**
	 * @param clearAll if true clear all timout object
	 * clear global and inertactions time out
	 */
	clearTimers(clearAll: boolean = true): void {
		if (this.interactionsTimeOut) {
			clearTimeout(this.interactionsTimeOut);
		}
		if (this.globalTimeOut && clearAll) {
			clearTimeout(this.globalTimeOut);
		}
	}

	/**
	 * @param iframe iframe from dom
	 * handle when time ut happened
	 * read the needed paramter
	 * emit event with the proper payload
	 */
	onTimeOut(iframe?: any): void {
		this.thirdPartyIframePayload.status = IframeStatus.timeout;
		this.readNetPlusUrlParams(iframe, false);
		this.OnIframeFinish();
	}

	/**
	 * funcction to emit the final status from the iframe
	 * to the parent container
	 */
	OnIframeFinish(): void {
		this.clearTimers(true);
		this.iframeStatus.emit(this.thirdPartyIframePayload);
	}

	/**
	 * Method for reading extra parameters from net plus redirection URL and store them in extraBodyParamsForSavingCard object
	 * @param iframe iframe from dom
	 */
	readExtraParametersForSavingCard(iframe: HTMLIFrameElement): void {
		const txId_COF: string = this.readIframeParameter(iframe, 'txId_COF');
		const pagoRecurrente: string = this.readIframeParameter(iframe, 'pagoRecurrente');

		const hasTxtId: boolean = txId_COF && txId_COF !== 'undefined';
		const hasPagoRecurrente: boolean = pagoRecurrente && pagoRecurrente !== 'undefined';

		if (hasTxtId || hasPagoRecurrente) {
			this.paymentWalletService.txId_COF = null;
			this.paymentWalletService.pagoRecurrente = null;
			if (hasTxtId) {
				this.paymentWalletService.txId_COF = txId_COF;
			}
			if (hasPagoRecurrente) {
				this.paymentWalletService.pagoRecurrente = pagoRecurrente;
			}
		} else {
			this.paymentWalletService.txId_COF = null;
			this.paymentWalletService.pagoRecurrente = null;
		}
	}

	/**
	 * Method for helping reading parameter from iframe URL
	 * @param iframe iframe from dom
	 * @param parameterName from iframe's redirection URL
	 */
	readIframeParameter(iframe: HTMLIFrameElement, parameterName: string): string {
		return decodeURIComponent(
			iframe?.contentWindow.location.href.split(`${parameterName}=`)[1]?.split('&')[0]
		)?.replace(/\+/g, ' ');
	}

	ngOnDestroy(): void {
		this.clearTimers(true);
		if (this.needBackStep) {
			setTimeout(() => {
				history.back();
			}, 0);
		}
	}
}
