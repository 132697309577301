import { Directive, HostListener, Output, Input, EventEmitter } from '@angular/core';

@Directive({
	selector: '[spMaxLength]',
})
export class MaxLengthDirective {
	@Input('spMaxLength') spMaxLength: any;
	@Output() ngModelChange: EventEmitter<any> = new EventEmitter();

	constructor() {}
	@HostListener('focus', ['$event']) onFocus(event) {
		const element: HTMLInputElement = event.target as HTMLInputElement;
		if (this.spMaxLength) {
			element.setAttribute('maxlength', this.spMaxLength);
		} else {
			element.removeAttribute('maxlength');
		}
	}
}
