<div class="library-container">
	<p class="library-container-description">{{ 'v10.legal.libraries.description' | translate }}</p>
	<div class="nav-container" *ngFor="let library of libraries; let i = index">
		<div (click)="openLink(library.url)" class="MV10-item-container">
			<span> {{ library.name }} </span>
			<span class="icon icon-chevron-right"> </span>
		</div>
		<hr *ngIf="i !== libraries.length - 1" class="bottom-line" />
	</div>
</div>
