import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'sp-exclusive-offer-stamp',
	templateUrl: './exclusive-offer-stamp.component.html',
	styleUrls: ['./exclusive-offer-stamp.component.scss'],
})
export class ExclusiveOfferStampComponent implements OnInit {
	@Input() text: string;

	constructor(private translate: TranslateService) {}

	ngOnInit(): void {
		this.text = this.translate.instant('v10.commercial.bundleupsell.offer.stamp');
	}
}
