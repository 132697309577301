<vfac-overlay
	class="tray-ko"
	[overlayData]="overlayData"
	*ngIf="showError"
	[show]="showError"
	[loaded]="true"
	(closed)="showError = false"
>
	<div body>
		<div>
			<div>
				<img alt="id + 'KOTrayIcon'" [id]="id + 'KOTrayIcon'" [src]="trayKOIcon" class="tray-ko__icon" />
			</div>
			<div>
				<p [id]="id + 'KOTrayTitle'" class="tray-ko__title">{{ trayKOTitle }}</p>
				<p [id]="id + 'KOTrayText'" class="tray-ko__text margin-bottom">
					{{ trayKOText }}
				</p>
			</div>
		</div>
		<div class="tray-ko__btn">
			<mva10-c-button [body]="trayKOBtn" (mva10OnClick)="showError = false" id="trayKOBtn"> </mva10-c-button>
		</div>
	</div>
</vfac-overlay>
