import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
	name: 'trustHtml',
})
export class TrustHtmlPipe implements PipeTransform {
	constructor(private domSanitizer: DomSanitizer) {}

	transform(value: string): SafeHtml {
		const url: string = this.domSanitizer.sanitize(SecurityContext.HTML, value);
		return url;
	}
}
