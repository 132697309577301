import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import * as constants from '../../constants/defines';
import { StorageService } from '../../../core/services/storage.service';
import { UtilitiesService } from '../../utils/utilities.service';
import { tagging } from '../../../../config/tagging-config';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { OperatingSystem } from '../../enums/operating-systems.enum';
import { AppService } from '../../../app.service';
import { NewTaggingJsonModel } from '../../../models/new-tagging-json-model';
import { NewTaggingHelperService } from '../../../core/services/new-tagging.helper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'sp-banner-app-from-web',
	templateUrl: './banner-app-from-web.component.html',
	styleUrls: ['./banner-app-from-web.component.scss'],
})
export class BannerAppFromWebComponent implements OnInit, OnDestroy {
	displayBanner: boolean;
	imagePathAuth: string;
	imagePathNoAuth: string;
	iosImagePathNoAuth: string;
	androidImagePathNoAuth: string;
	iosImagePathAuth: string;
	androidImagePathAuth: string;
	isLoged: boolean = true;
	titleAuth: string;
	titleNoAuth: string;
	descAuth: string;
	descNoAuth: string;
	@Output() bannerClosed = new EventEmitter();
	curentPageChangedSubscriber: any;
	@Input() frequency: number;
	@Input() authenticate: boolean;

	constructor(
		private storageService: StorageService,
		private utility: UtilitiesService,
		private tagging: TaggingHelperService,
		public translate: TranslateService,
		public appService: AppService,
		private newTagging: NewTaggingHelperService
	) {}

	ngOnInit(): void {
		this.setNudgeContent();
		this.checkDownloadAppNudgeLogic();
	}

	setNudgeContent(): void {
		this.translate.get('common.messagesList.nativeBannerLogged').subscribe((data) => {
			this.titleAuth = data.title;
			this.descAuth = data.description;
			this.iosImagePathAuth = data?.confirmButton?.imagesList?.iOSIcon.url;
			this.androidImagePathAuth = data?.confirmButton?.imagesList?.androidIcon.url;
		});

		this.translate.get('common.messagesList.nativeBanner').subscribe((data) => {
			this.titleNoAuth = data.title;
			this.descNoAuth = data.description;
			this.iosImagePathNoAuth = data?.confirmButton?.imagesList?.iOSIcon.url;
			this.androidImagePathNoAuth = data?.confirmButton?.imagesList?.androidIcon.url;
		});
	}

	checkDownloadAppNudgeLogic(): void {
		if (this.checkFrequecy()) {
			/** this line will be executed at first time only */
			this.sendTrackAction();
			/** Send track action tagging for each page */
			this.curentPageChangedSubscriber = this.appService.curentPageChangedEmitter.subscribe(() => {
				this.sendTrackAction();
			});
			this.storageService.setLocalStorage(constants.downloadAppNudgeKey, new Date());
			this.setImagePath();
			this.displayBanner = true;
			this.appService.isAppFromWebBannerShown = true;
		} else {
			this.bannerClosed.emit();
		}
	}
	sendTrackAction(): void {
		this.newTagging.getJsonTagging('login').subscribe((data: NewTaggingJsonModel) => {
			this.newTagging.interaction(data.eventList['nudge_descarga_app_impresion'], this.appService.pageObject);
		});
	}
	checkFrequecy(): boolean {
		let result: boolean;
		const downloadAppNudgeShownLastTime: Date = this.storageService.getLocalStorage(constants.downloadAppNudgeKey);
		if (!downloadAppNudgeShownLastTime) {
			/** then this is the first time to open the web portal */
			this.storageService.setLocalStorage(constants.downloadAppNudgeKey, new Date());
			result = true;
		} else {
			const diffDays: number = this.utility.getDayDiffBetween2Dates(downloadAppNudgeShownLastTime, new Date());
			result = diffDays >= (+this.frequency || 0);
		}

		return result;
	}
	xButtonClicked(): void {
		this.displayBanner = false;
		this.appService.isAppFromWebBannerShown = false;
		this.bannerClosed.emit();
	}
	openAppOrStore(): void {
		this.newTagging.getJsonTagging('login').subscribe((data: NewTaggingJsonModel) => {
			this.newTagging.interaction(data.eventList['nudge_descarga_app_abrir'], this.appService.pageObject);
		});

		if ((window as any).visitor) {
			const navigationlink: string = `${constants.nativeAppVariables.domainProduction}/?link=https://Dashboard`;
			const link: any = (window as any).visitor.appendVisitorIDsTo(navigationlink);
			const adobelink: string = link.replace('Dashboard&', 'Dashboard?');
			const completeDeepLink: string = constants.adobeNativeAppURL
				.replace('{adobelink}', adobelink)
				.replace('{afl}', constants.nativeAppVariables.androidAppStore)
				.replace('{ifl}', constants.nativeAppVariables.iosAppleStore)
				.replace('{ofl}', constants.nativeAppVariables.webAppLink)
				.replace('{apn}', constants.nativeAppVariables.nativeAppProductionBundleId)
				.replace('{ibi}', constants.nativeAppVariables.nativeAppProductionBundleId);
			window.location.href = completeDeepLink;
		} else {
			const completeDeepLink: string = constants.nativeAppURL
				.replace('{afl}', constants.nativeAppVariables.androidAppStore)
				.replace('{ifl}', constants.nativeAppVariables.iosAppleStore)
				.replace('{ofl}', constants.nativeAppVariables.webAppLink)
				.replace('{domain}', constants.nativeAppVariables.domainProduction)
				.replace('{apn}', constants.nativeAppVariables.nativeAppProductionBundleId)
				.replace('{ibi}', constants.nativeAppVariables.nativeAppProductionBundleId);
			window.location.href = completeDeepLink;
		}
	}
	register(): void {
		this.newTagging.getJsonTagging('login').subscribe((data: NewTaggingJsonModel) => {
			this.newTagging.interaction(data.eventList['nudge_descarga_crear_usuario'], this.appService.pageObject);
		});
		let registerUrl: string = '';
		this.translate.get('login.loginForm.buttonsList.registerMVA10.extLinkURL').subscribe((data) => {
			registerUrl = data;
			window.open(registerUrl);
		});
		this.tagging.view(tagging.registerPage);
	}
	setImagePath(): void {
		this.imagePathAuth = this.utility.checkDeviceOperatingSystem(OperatingSystem.mac)
			? this.iosImagePathAuth
			: this.androidImagePathAuth;
		this.imagePathNoAuth = this.utility.checkDeviceOperatingSystem(OperatingSystem.mac)
			? this.iosImagePathNoAuth
			: this.androidImagePathNoAuth;
	}

	ngOnDestroy(): void {
		if (this.curentPageChangedSubscriber) {
			this.curentPageChangedSubscriber.unsubscribe();
		}
	}
}
