import { Injectable } from '@angular/core';
import { SMAPIheaders } from '../../shared/constants/defines';
import { environment } from '../../../environments/environment';
import { API_URLS } from '../../shared/constants/routes-config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EncryptRequestModel } from '../../models/encrypt-request.model';
import { Observable } from 'rxjs';
@Injectable()
export class SmapiService {
	sec: any = window.sec; // sec instance is now attached with window object
	constructor(private http: HttpClient) {}

	initSmapi(): void {
		this.sec.init({
			enabled: true,
			environment: environment.smapi,
			verbose: false,
			cookiesEnabled: false,
			networkMonitoringEnabled: true,
			spaTrackingOptions: {
				enabled: true,
				replaceState: true,
			},
			headers: SMAPIheaders,
		});
	}

	setSmapiId(id: string): void {
		this.encrypteSmapiId(id).subscribe((encryptedId: string) => {
			this.sec.setUserID(encryptedId);
		});
	}

	removeSmapiId(): void {
		this.sec.setUserID(null);
	}

	encrypteSmapiId(id: string): Observable<string> {
		const url: string = API_URLS.cypher.aes;
		const headers: HttpHeaders = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		const options: { [key: string]: HttpHeaders } = {
			headers: headers,
		};
		const body: EncryptRequestModel = {
			msisdn: [id],
			action: 'encrypt',
			country_code: '34',
			third_party: 'analytics',
		};
		return this.http.post<Array<string>>(url, body, options).pipe(
			map((res: string[]) => {
				if (res.length > 0) {
					return res[0];
				} else {
					return '';
				}
			})
		);
	}
}
