import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OverlayModel } from '@mva10/mva10-angular';
import { AppService } from 'src/app/app.service';
import { constants } from '../../../models/constants';
import { TicketsService } from '../services/tickets.service';
import { MyOrdersTaggingService } from '../../../services/my-orders-tagging.service';
import { apagarIcono } from '../../../constants/defines';
import { OrdersService } from '../services/orders.service';
import { OrderFlow } from '../../../enums/order-flow.enum';

@Component({
	selector: 'sp-deco-installation',
	templateUrl: './sp-deco-installation.component.html',
	styleUrls: ['./sp-deco-installation.component.scss'],
})
export class SpDecoInstallationComponent implements OnInit {
	stepsData: string[];
	selectedStep: number;
	pageName: string = 'guia instalacion deco';
	pagesNames: string[] = ['lo tienes todo', 'instala el decodificador', 'puesta en marcha', 'listo'];
	paso2Instruccion4Titulo: string;
	disableSchamanCheck: boolean;
	showOverlayCheckInstallation: boolean;

	@Input() withActivation: boolean;
	@Output() onClose: EventEmitter<string> = new EventEmitter();
	decoInstallationGuideOverlayData: OverlayModel;
	showDecoInstallationGuideOverlay: boolean;
	overlayLoaded: boolean;

	constructor(
		public translate: TranslateService,
		public appService: AppService,
		private ticketsService: TicketsService,
		private tagging: MyOrdersTaggingService,
		public ordersService: OrdersService
	) {}

	ngOnInit(): void {
		this.overlayLoaded = false;
		this.showDecoInstallationGuideOverlay = true;
		this.selectedStep = 0;
		this.stepsData = [
			this.translate.instant('v10.myOrder.decoInstallation.guideInstalacionDecoPaso1Nombre'),
			this.translate.instant('v10.myOrder.decoInstallation.guideInstalacionDecoPaso2Nombre'),
			this.translate.instant('v10.myOrder.decoInstallation.guideInstalacionDecoPaso3Nombre'),
			this.translate.instant('v10.myOrder.decoInstallation.guideInstalacionDecoPaso4Nombre'),
		];
		this.decoInstallationGuideOverlayData = {
			isFullScreen: true,
			isCenterTitle: true,
			title: this.translate.instant('v10.myOrder.decoInstallation.instalaTuNuevoEquipo'),
		};

		let iconoApagar: string = apagarIcono;
		let url: string = this.translate.instant('v10.myOrder.oneConectaConDeco.img.iconpower');
		url = this.appService.getImgFullPath(url);
		iconoApagar = iconoApagar.replace('{url}', url);
		this.paso2Instruccion4Titulo = this.translate
			.instant('v10.myOrder.decoInstallation.guideInstalacionDecoPaso2Instruccion4Titulo')
			.replace('{iconoApagar}', iconoApagar);

		this.disableSchamanCheck =
			!constants.trueLiteral.includes(this.withActivation) || !this.ticketsService.autoiHelp.showNotWorkingOption;

		if (!this.disableSchamanCheck) {
			this.stepsData[this.stepsData.length - 1] = this.translate.instant(
				'v10.myOrder.decoInstallation.autoiCheckInstallationStep'
			);
			this.pagesNames[this.pagesNames.length - 1] = 'comprobar instalacion';
		}
		this.doSendView();
		setTimeout(() => {
			this.overlayLoaded = true;
		}, 500);
	}

	doSendView(): void {
		this.resetTagging(true);
		this.tagging.extendUtagPageName([this.pageName]);
		this.tagging.sendPage([this.pagesNames[0]]);
	}

	closeGuideOverlay(closeType?: string): void {
		this.showDecoInstallationGuideOverlay = false;
		this.resetTagging(true);
		setTimeout(() => {
			this.selectedStep = 0;
			this.onClose.emit(closeType);
		}, 500);
	}

	goForwards(): void {
		// Si no es la ultima pagina de la guía
		if (this.selectedStep !== this.pagesNames.length - 1) {
			this.avanzaPaso();
		} else {
			// Es la ultima pagina de la guia, botón Entendido
			this.tagging.sendInteraction({ link_type: 'click en entendido' });
			if (this.ordersService.flow === OrderFlow.FLUJO_7) {
				this.showOverlayCheckInstallation = true;
			} else {
				this.closeGuideOverlay();
			}
		}
	}

	avanzaPaso(): void {
		this.selectedStep++;
		this.resetTagging();
		this.tagging.sendPage([this.pagesNames[this.selectedStep]]);
	}

	goBackwards(): void {
		this.selectedStep--;
		this.resetTagging();
		this.tagging.extendUtagPageName([this.pagesNames[this.selectedStep]]);
		// The timeout waits for the div on the ngif to load
		if (this.selectedStep < 0) {
			this.closeGuideOverlay();
		} else {
			this.resetTagging();
			this.tagging.sendPage([this.pagesNames[this.selectedStep]]);
		}
	}

	onNextButtonClick(): void {
		if (!this.disableSchamanCheck && this.selectedStep === this.pagesNames.length - 2) {
			this.tagging.sendInteraction({ link_type: 'click en comprobar instalacion' });
			this.openCheckInstallationOverlay();
		} else {
			this.goForwards();
		}
	}

	openCheckInstallationOverlay(): void {
		this.resetTagging(true);
		this.showOverlayCheckInstallation = true;
	}

	closeCheckInstallationOverlay(closeType?: string): void {
		this.showOverlayCheckInstallation = false;
		this.resetTagging(true);
		this.tagging.extendUtagPageName([this.pageName]);
		this.tagging.extendUtagPageName([this.pagesNames[this.selectedStep]]);
		if (['chat', 'check-ok'].includes(closeType)) {
			this.closeGuideOverlay(closeType);
		}
	}

	resetTagging(removePageName?: boolean): void {
		this.tagging.extendUtagPageName([...this.pagesNames, 'detalle pedido'], null, true);
		if (removePageName) {
			this.tagging.extendUtagPageName([this.pageName], null, true);
		}
	}
}
