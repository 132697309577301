<div *ngIf="services" id="NexusOp">
	<div *ngFor="let service of services; let i = index" [attr.data-index]="i">
		<hr class="mva10-u--mt4x" />
		<div class="img_flex">
			<img [src]="service.image | imgFullPath" id="NexusOpImage{{ i }}" />
			<div>
				<p class="bold-font mva10-u--mb1x" id="NexusOpName{{ i }}">{{ service.name }}</p>
				<p>
					<span class="bold-font" id="NexusOpPrice{{ i }}"> {{ service.price }} </span> {{ coinMonth }}
				</p>
			</div>
		</div>
	</div>
</div>
