<vfac-overlay
	id="logout-overlay"
	[overlayData]="overlayData"
	[show]="appService.showLogoutTray"
	[loaded]="true"
	[showButtons]="true"
	(primaryBtnClicked)="logoutClicked()"
	(secondaryBtnClicked)="logoutCancel()"
	(closed)="logoutCancel()"
>
	<div body>
		<p id="logoutOverlayBodyTitle" class="mva10-u-heading--3 mva10-u--mb4x" *ngIf="name">
			{{ 'v10.common.logoutTray.subtitle' | translate | replace : '{0}' : name }}
		</p>
		<p id="logoutOverlayBodyDescription" class="mva10-u-heading--5">
			{{ 'v10.common.logoutTray.description' | translate }}
		</p>
	</div>
</vfac-overlay>
