export enum PaymentJourneyProcess {
	inadvance = 'pagar factura por adelantado',
	dept = 'pagar deuda',
	cashdesk = 'pagar factura actual',
	custom = '',
	bankAccountJourneyProcess = 'modificar',
	cashDeskJourneyProcess = 'domiciliar',
	pasarela = 'pasarela',
	ofertaPlatino = 'oferta platino',
	platinoOfferAlmostIn = 'nueva oferta platino',
	platinoOfferAlmostOut = 'renovacion oferta platino',
}
