<div class="current-comsuption">
	<div class="current-comsuption__selector-type" *ngIf="showSelector">
		<vfac-selector
			[yuAppearance]="this.typeUser === 1 || this.typeUser === 3"
			overlay
			*ngIf="selectorData && selectorStartAtIndex >= 0"
			(cardClickEvent)="selectorCardServiceClicked($event, selectorData)"
			[selectorData]="selectorData"
			[listLoaded]="true"
			[startAtIndex]="selectorStartAtIndex"
		>
		</vfac-selector>
	</div>

	<div *ngIf="loadComsuption; else skeletonComsuption">
		<div *ngIf="!errorComsuption; else errorComsuptionTemplate">
			<div *ngIf="showCycle">
				<label class="current-comsuption__label-cycle mva10-u-heading--3">
					{{ labelMonth }}&nbsp;{{ labelCycle }}
				</label>
			</div>
			<label class="current-comsuption__subtitle"> {{ 'v10.billing.without_comsuption.labelInfo' | translate }} </label>
			<mva10-c-card *ngIf="roamersBanner" [gradient]="'red'">
				<h5 class="bold-font mva10-u--mb3x">{{ roamersBanner.title }}</h5>
				<p class="mva10-u--footnote mva10-u--mb3x" [innerHTML]="roamersBanner.text"></p>
				<mva10-c-button
					[type]="'mva10-c-button--tertiary-2 mva10-c-button--block'"
					(click)="goToRoaming()"
					id="sharedCurrentConsumptionRoamersButton"
				>
					{{ 'v10.billing.roamers.button' | translate }}
				</mva10-c-button>
			</mva10-c-card>
			<div #cardVoice class="current-comsuption__card" *ngIf="checkCardsConsumption(bundleTypeEnum.Voice)">
				<mva10-c-card [styleClass]="'current-comsuption__expandable'" [responsiveSize]="20">
					<div class="current-comsuption__desc_item">
						<span>
							<svg class="icon">
								<title>Calls</title>
								<use xlink:href="#icon-call-log" />
							</svg>
							<label class="titleCaseCapitalize mva10-u--ml4x">
								{{ labelCalls }}
							</label>
						</span>
						<label
							class="current-comsuption--font-family-rg-bold"
							[ngClass]="{ 'current-comsuption--red': totalComsuptionTypeCalls > 0 }"
						>
							{{ totalComsuptionTypeCalls | currency : 'EUR' : 'symbol' | sliceCurrencyRoundZero }}€</label
						>
					</div>

					<div class="current-comsuption--hidden" id="cardExpandCallsId">
						<hr />
						<ng-container
							[ngTemplateOutlet]="sectionTypeComsuptionTemplate"
							[ngTemplateOutletContext]="{
								type: bundleTypeEnum.Voice,
								select: typeComsuptionSelectCalls,
								listTotalComsuptionType: listTotalComsuptionTypeCalls
							}"
						>
						</ng-container>
					</div>

					<hr />
					<div
						class="current-comsuption__expandable__label"
						(click)="clickExpandCardConsumption(!cardExpandableValueCalls, bundleTypeEnum.Voice)"
					>
						<label> {{ labelcardExpandableValueCalls }} </label>
					</div>
				</mva10-c-card>
			</div>

			<div #cardData class="current-comsuption__card" *ngIf="checkCardsConsumption(bundleTypeEnum.Data)">
				<mva10-c-card [styleClass]="'current-comsuption__expandable'" [responsiveSize]="20">
					<div class="current-comsuption__desc_item">
						<span>
							<svg class="icon">
								<title>Wifi</title>
								<use xlink:href="#icon-broadband-or-wifi" />
							</svg>
							<label class="titleCaseCapitalize mva10-u--ml4x">
								{{ labelData }}
							</label>
						</span>
						<label
							class="current-comsuption--font-family-rg-bold"
							[ngClass]="{ 'current-comsuption--red': totalComsuptionTypeInternet > 0 }"
						>
							{{ totalComsuptionTypeInternet | currency : 'EUR' : 'symbol' | sliceCurrencyRoundZero }}€</label
						>
					</div>
					<div class="current-comsuption--hidden" id="cardExpandInternetId">
						<ng-container
							[ngTemplateOutlet]="totalComsuptionTemplate"
							[ngTemplateOutletContext]="{ totalizedConsumption: totalizedInternetSumByType }"
						>
						</ng-container>
						<hr />
						<ng-container
							[ngTemplateOutlet]="sectionTypeComsuptionTemplate"
							[ngTemplateOutletContext]="{
								type: bundleTypeEnum.Data,
								select: typeComsuptionSelectInternet,
								listTotalComsuptionType: listTotalComsuptionTypeInternet
							}"
						>
						</ng-container>
					</div>

					<hr />
					<div
						class="current-comsuption__expandable__label"
						(click)="clickExpandCardConsumption(!cardExpandableValueInternet, bundleTypeEnum.Data)"
					>
						<label> {{ labelcardExpandableValueInternet }} </label>
					</div>
				</mva10-c-card>
			</div>

			<div #cardSMS class="current-comsuption__card" *ngIf="checkCardsConsumption(bundleTypeEnum.SMS)">
				<mva10-c-card [styleClass]="'current-comsuption__expandable'" [responsiveSize]="20">
					<div class="current-comsuption__desc_item">
						<span>
							<svg class="icon">
								<title>SMS</title>
								<use xlink:href="#icon-sms" />
							</svg>
							<label class="titleCaseCapitalize mva10-u--ml4x">
								{{ labelMessages }}
							</label>
						</span>
						<label
							class="current-comsuption--font-family-rg-bold"
							[ngClass]="{ 'current-comsuption--red': totalComsuptionTypeMessages > 0 }"
						>
							{{ totalComsuptionTypeMessages | currency : 'EUR' : 'symbol' | sliceCurrencyRoundZero }}€</label
						>
					</div>
					<div class="current-comsuption--hidden" id="cardExpandMessagesId">
						<hr />
						<ng-container
							[ngTemplateOutlet]="sectionTypeComsuptionTemplate"
							[ngTemplateOutletContext]="{
								type: bundleTypeEnum.SMS,
								select: typeComsuptionSelectMessages,
								listTotalComsuptionType: listTotalComsuptionTypeMessages
							}"
						>
						</ng-container>
					</div>

					<hr />
					<div
						class="current-comsuption__expandable__label"
						(click)="clickExpandCardConsumption(!cardExpandableValueMessages, bundleTypeEnum.SMS)"
					>
						<label> {{ labelcardExpandableValueMessages }} </label>
					</div>
				</mva10-c-card>
			</div>

			<div #cardTV class="current-comsuption__card" *ngIf="checkCardsConsumption(bundleTypeEnum.TV)">
				<mva10-c-card [styleClass]="'current-comsuption__expandable'" [responsiveSize]="20">
					<div class="current-comsuption__desc_item">
						<span>
							<svg class="icon">
								<title>Tv</title>
								<use xlink:href="#icon-tv" />
							</svg>
							<label class="titleCaseCapitalize mva10-u--ml4x">
								{{ labelTv }}
							</label>
						</span>
						<label
							class="current-comsuption--font-family-rg-bold"
							[ngClass]="{ 'current-comsuption--red': totalComsuptionTypeTv > 0 }"
						>
							{{ totalComsuptionTypeTv | currency : 'EUR' : 'symbol' | sliceCurrencyRoundZero }}€</label
						>
					</div>
					<div class="current-comsuption--hidden" id="cardExpandTvId">
						<hr />
						<div *ngIf="listTotalComsuptionTypeTv.length > 0; else emptyComsuptionType">
							<ng-container
								[ngTemplateOutlet]="sectionListComsuptionTemplate"
								[ngTemplateOutletContext]="{ type: bundleTypeEnum.TV }"
							>
							</ng-container>
						</div>
						<ng-template #emptyComsuptionType>
							<ng-container
								[ngTemplateOutlet]="emptyComsuptionTypeTemplate"
								[ngTemplateOutletContext]="{ type: bundleTypeEnum.TV }"
							>
							</ng-container>
						</ng-template>
					</div>

					<hr />
					<div
						class="current-comsuption__expandable__label"
						(click)="clickExpandCardConsumption(!cardExpandableValueTv, bundleTypeEnum.TV)"
					>
						<label> {{ labelcardExpandableValueTv }} </label>
					</div>
				</mva10-c-card>
			</div>
		</div>
		<ng-template #errorComsuptionTemplate>
			<mva10-c-card [responsiveSize]="20">
				<div class="current-comsuption__empty">
					<ng-lottie [options]="lottieConfig" [width]="100" [height]="100" (animationCreated)="handleAnimation($event)">
					</ng-lottie>
					<span> {{ 'v10.billing.comsuption.titleError' | translate }} </span>
					<span> {{ 'v10.billing.comsuption.descError' | translate }} </span>
				</div>
			</mva10-c-card>
		</ng-template>
	</div>
	<ng-template #skeletonComsuption>
		<ng-container [ngTemplateOutlet]="billSkeleton"> </ng-container>
	</ng-template>
</div>

<ng-template #totalComsuptionTemplate let-totalizedConsumption="totalizedConsumption">
	<div *ngIf="totalizedConsumption && totalizedConsumption.length > 0">
		<hr />
		<div
			class="current-comsuption--space-between current-comsuption--margin-bottom"
			*ngFor="let listItem of totalizedConsumption"
		>
			<span> {{ listItem.description }} </span>
			<span> {{ listItem.formattedTotal }} </span>
		</div>
	</div>
</ng-template>

<ng-template
	#sectionTypeComsuptionTemplate
	let-type="type"
	let-select="select"
	let-listTotalComsuptionType="listTotalComsuptionType"
>
	<div *ngIf="listTotalComsuptionType.length > 0; else emptyComsuptionType">
		<div class="current-comsuption--display-flex">
			<span
				class="current-comsuption__badge"
				[ngClass]="
					select === constants.typeTabsComsuption.todo
						? 'current-comsuption__badge--blue'
						: 'current-comsuption__badge--grey'
				"
				(click)="changeSelectComsuption(constants.typeTabsComsuption.todo, type)"
			>
				<label> {{ 'v10.billing.comsuption.labelAll' | translate }} </label>
			</span>
			<span
				class="current-comsuption__badge"
				[ngClass]="
					select === constants.typeTabsComsuption.incluido
						? 'current-comsuption__badge--blue'
						: 'current-comsuption__badge--grey'
				"
				(click)="changeSelectComsuption(constants.typeTabsComsuption.incluido, type)"
			>
				<label> {{ 'v10.billing.comsuption.labelInclude' | translate }} </label>
			</span>
			<span
				class="current-comsuption__badge"
				[ngClass]="
					select === constants.typeTabsComsuption.fuera
						? 'current-comsuption__badge--blue'
						: 'current-comsuption__badge--grey'
				"
				(click)="changeSelectComsuption(constants.typeTabsComsuption.fuera, type)"
			>
				<label> {{ 'v10.billing.comsuption.labelExtra' | translate }} </label>
			</span>
		</div>

		<div
			*ngIf="
				selectedListConsumption && selectedListConsumption[type] && selectedListConsumption[type].length > 0;
				else emptyComsuptionSelect
			"
		>
			<ng-container [ngTemplateOutlet]="sectionListComsuptionTemplate" [ngTemplateOutletContext]="{ type: type }">
			</ng-container>
		</div>
		<ng-template #emptyComsuptionSelect>
			<ng-container [ngTemplateOutlet]="emptyComsuptionSelectTemplate" [ngTemplateOutletContext]="{ select: select }">
			</ng-container>
		</ng-template>
	</div>
	<ng-template #emptyComsuptionType>
		<ng-container [ngTemplateOutlet]="emptyComsuptionTypeTemplate" [ngTemplateOutletContext]="{ type: type }">
		</ng-container>
	</ng-template>
</ng-template>

<ng-template #sectionListComsuptionTemplate let-type="type">
	<div class="current-comsuption__section" *ngFor="let comsuption of selectedListConsumption[type]; let i = index">
		<div
			class="current-comsuption__item current-comsuption__item--separator-day"
			*ngIf="separatorDay(comsuption, selectedListConsumption[type][i - 1])"
		>
			<label> {{ separatorDayText(comsuption.timePeriod.startDate) }} </label>
		</div>
		<div class="current-comsuption__item">
			<label> {{ getDestination(comsuption, type) }} </label>
			<label [ngClass]="{ 'current-comsuption--red': comsuption.amount > 0 }">
				{{ comsuption.amount | currency : 'EUR' : 'symbol' | sliceCurrencyRoundZero }}€</label
			>
		</div>
		<div class="current-comsuption__item-time">
			<label> {{ getDateComsuption(comsuption.timePeriod.startDate) }} </label>
			<ng-container *ngIf="type === bundleTypeEnum.Data; else nonDataTemplate">
				<label *ngIf="!isNaN(comsuption.quantity)"> {{ getDataConsumption(comsuption) }} </label>
			</ng-container>
			<ng-template #nonDataTemplate>
				<label *ngIf="comsuption.timePeriod.duration"> {{ getDuration(comsuption.timePeriod.duration) }} </label>
			</ng-template>
		</div>
	</div>
</ng-template>

<ng-template #emptyComsuptionSelectTemplate let-select="select">
	<div class="current-comsuption__empty">
		<img src="{{ appTheme.getImgFullPath('v10.billing.common.cards.empty.image' | translate) }}" alt="card" />
		<span> {{ getLabelsComsuptionEmpty(select)[0] }} </span>
		<span> {{ getLabelsComsuptionEmpty(select)[1] }} </span>
	</div>
</ng-template>

<ng-template #emptyComsuptionTypeTemplate let-type="type">
	<div class="current-comsuption__empty">
		<img src="{{ appTheme.getImgFullPath('v10.billing.common.cards.empty.image' | translate) }}" alt="card" />
		<span> {{ 'v10.billing.comsuption.titleEmpty' | translate }} </span>
		<span> {{ getLabelEmptyComsuptionType(type) }} </span>
	</div>
</ng-template>

<ng-template #billSkeleton>
	<div class="skeleton-current-comsuption">
		<div class="vfes-box-shadow skeleton-wrapper-section skeleton-current-comsuption__card">
			<div class="skeleton"></div>
			<div class="skeleton"></div>
			<div class="skeleton"></div>
		</div>
	</div>
</ng-template>
