export class PostalAddress {
	type: string;
	name: string;
	addressNumber: string;
	floor: string;
	door: string;
	zipCode: string;
	province: string;
	town: string;
	verticalId: string;
	longitud?: number;
	latitud?: number;
	notNormalizedAllowed?: boolean;
	block?: string;
	stairway?: string;
}

export class PostalAddressBuyNow {
	id?: number;
	type?: string;
	name?: string;
	addressNumber?: string;
	stairway?: string;
	floor?: string;
	door?: string;
	otherdoor?: string;
	zipCode?: string;
	province?: string;
	town?: string;
	additionalInfo?: string;
	verticalId?: string;
	normalized?: boolean;
	num?: string;
	block?: string;
	identificador?: string;
	letter?: string;
	hand1?: string;
	hand2?: string;
	descriptionNumber?: string;
	descriptionStair?: string;
	descriptionFloor?: string;
	descriptionDoor?: string;
	observations?: string;
	deliveryDate?: string;
	deliveryTime?: string;
	deliveryType?: string;
}
