export class CallOptionsRequest {
	missedCallsNotification: MissedCallsNotification;
	autoRedial: AutoRedial;
	dictaSms: DictaSms;
}
export class MissedCallsNotification {
	busy: string;
	outOfCoverage: string;
}

export class AutoRedial {
	status: string;
}

export class DictaSms {
	status: string;
}
