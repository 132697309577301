<vfac-overlay
	#decoGen3InstallationGuideOverlay
	[overlayData]="decoGen3InstallationGuideOverlayData"
	[show]="showDecoGen3InstallationGuideOverlay"
	[page]="selectedStep + 1"
	[loaded]="overlayLoaded"
	[loaderType]="'vodafone'"
	(closed)="closeGuideOverlay()"
	(backPage)="goBackwards()"
>
	<div header>
		<div class="mvf-gen3-installation__header vfes-align--left">
			<mva10-c-steps class="mva10-u--mt4x" [steps]="stepsData" [selected]="selectedStep" [vertical]="false">
			</mva10-c-steps>
		</div>
	</div>

	<!-- Step 1 -->
	<div body id="page1">
		<div class="mvf-gen3-installation__p16">
			<div class="vfes-title--regular mvf-gen3-installation__overflow-wrap">
				{{ 'v10.myOrder.gen3Installation.guideInstallationDecoStep1Description' | translate }}
			</div>
			<!-- HFC 3P GEN3-->
			<div *ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.UPGRADE_INTERNET_AND_DECO_GEN3">
				<p
					class="vfes-title vfes-title--4 mvf-gen3-installation__pt16 mvf-gen3-installation__bold-font mvf-gen3-installation__overflow-wrap"
				>
					{{ 'v10.myOrder.gen3Installation.fibreTitle' | translate }}
				</p>
				<img
					class="mvf-gen3-installation__img"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.instalarRouterPasoMobile' | translate) }}"
					alt="elementos necesarios fibra"
				/>
				<p
					class="vfes-title vfes-title--4 mvf-gen3-installation__pt16 mvf-gen3-installation__bold-font mvf-gen3-installation__overflow-wrap"
				>
					{{ 'v10.myOrder.gen3Installation.vodafoneTvTitle' | translate }}
				</p>
				<img
					class="vfes-mt mvf-gen3-installation__img"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.group11' | translate) }}"
					alt="elementos necesarios TV"
				/>
			</div>
			<!-- OCF GEN3 -->
			<div *ngIf="ordersService.detailOrderData.instalarEquipos === DecoGuideType.UPGRADE_DECO_GEN3">
				<p
					class="vfes-title vfes-title--4 mvf-gen3-installation__pt16 mvf-gen3-installation__bold-font mvf-gen3-installation__overflow-wrap"
				>
					{{ 'v10.myOrder.gen3Installation.contractGen3Step1Title1' | translate }}
				</p>
				<img
					class="vfes-mt mvf-gen3-installation__img"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.group2' | translate) }}"
					alt=""
				/>
				<p
					class="vfes-title vfes-title--4 mvf-gen3-installation__pt16 mvf-gen3-installation__bold-font mvf-gen3-installation__overflow-wrap"
				>
					{{ 'v10.myOrder.gen3Installation.contractGen3Step1Title2' | translate }}
				</p>
				<img
					class="vfes-mt mvf-gen3-installation__img"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.group3' | translate) }}"
					alt=""
				/>
			</div>
		</div>
		<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
	</div>

	<!-- Step2 -->
	<div body id="page2">
		<div class="vfes-display--inline-block mvf-gen3-installation__p16 mvf-gen3-installation__second-step">
			<!-- HFC 3P GEN3-->
			<div *ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.UPGRADE_INTERNET_AND_DECO_GEN3">
				<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.installRouterStep2Instruction1TitleB' | translate }}
				</div>
				<span class="vfes-title--regular mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.installRouterStep2Instruction1DescriptionB' | translate }}
				</span>
				<img
					class="mvf-gen3-installation__img vfes-mt vfes-mb"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.splitterinstall' | translate) }}"
					alt=""
				/>
				<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.instalallGen3Step2Instruction2Title' | translate }}
				</div>
				<img
					class="mvf-gen3-installation__img vfes-mt vfes-mb"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.group6' | translate) }}"
					alt=""
				/>
				<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.instalallGen3Step2Instruction3Title' | translate }}
				</div>
				<img
					class="mvf-gen3-installation__img vfes-mt vfes-mb"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.group7' | translate) }}"
					alt=""
				/>
				<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.instalallGen3Step2Instruction4Title' | translate }}
				</div>
				<img
					class="mvf-gen3-installation__img vfes-mt vfes-mb"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.conectatelf2' | translate) }}"
					alt=""
				/>
				<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.instalallGen3Step2Instruction5Title' | translate }}
				</div>
				<span class="vfes-title--regular mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.instalallGen3Step2Instruction5Description' | translate }}
				</span>
				<img
					class="mvf-gen3-installation__img vfes-mt vfes-mb"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.group10' | translate) }}"
					alt=""
				/>
				<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.instalallGen3Step2Instruction6Title' | translate }}
				</div>
				<span class="vfes-title--regular mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.instalallGen3Step2Instruction6Description' | translate }}
				</span>
				<img
					class="mvf-gen3-installation__img vfes-mt vfes-mb"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.group1' | translate) }}"
					alt=""
				/>
				<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.instalallGen3Step2Instruction7Title' | translate }}
				</div>
				<span class="vfes-title--regular mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.installrRouterStep2Instrucction3Descrioption' | translate }}
				</span>
				<img
					class="mvf-gen3-installation__img vfes-mt vfes-mb"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.conectawifi3' | translate) }}"
					alt=""
				/>
				<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.installGen3Step2Instrucction8Title' | translate }}
				</div>
				<span class="vfes-title--regular mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.installGen3Step2Instrucction8Description' | translate }}
				</span>
				<img
					class="mvf-gen3-installation__img vfes-mt vfes-mb"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.group13' | translate) }}"
					alt=""
				/>
			</div>
			<!-- OCF GEN3 -->
			<div *ngIf="ordersService.detailOrderData.instalarEquipos === DecoGuideType.UPGRADE_DECO_GEN3">
				<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.guideInstallationDecoStep2Name' | translate }}
				</div>
				<p class="vfes-title--regular mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.contractGen3Step2Description' | translate }}
				</p>
				<p class="vfes-title--regular mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.contractGen3Step2Description2' | translate }}
				</p>
				<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.contractGen3Step2Instrucction1Title' | translate }}
				</div>
				<img
					class="vfes-mt mvf-gen3-installation__img"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.group12' | translate) }}"
					alt=""
				/>
				<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 vfes-mt mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.contractGen3Step2Instrucction2Title' | translate }}
				</div>
				<img
					class="vfes-mt mvf-gen3-installation__img"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.splitterinstall' | translate) }}"
					alt=""
				/>
				<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 vfes-mt mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.contractGen3Step2Instrucction3Title' | translate }}
				</div>
				<img
					class="vfes-mt mvf-gen3-installation__img"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.group6' | translate) }}"
					alt=""
				/>
				<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 vfes-mt mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.contractGen3Step2Instrucction4Title' | translate }}
				</div>
				<img
					class="vfes-mt mvf-gen3-installation__img"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.group7' | translate) }}"
					alt=""
				/>
				<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 vfes-mt mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.contractGen3Step2Title2' | translate }}
				</div>
				<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.contractGen3Step2Instrucction5Title' | translate }}
				</div>
				<p class="vfes-title--regular mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.contractGen3Step2Instrucction5Description' | translate }}
				</p>
				<img
					class="vfes-mt mvf-gen3-installation__img"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.group10' | translate) }}"
					alt=""
				/>
				<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 vfes-mt mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.contractGen3Step2Instrucction6Title' | translate }}
				</div>
				<p class="vfes-title--regular mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.contractGen3Step2Instrucction6Description' | translate }}
				</p>
				<img
					class="vfes-mt mvf-gen3-installation__img"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.group1' | translate) }}"
					alt=""
				/>
				<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 vfes-mt mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.contractGen3Step2Instrucction7Title' | translate }}
				</div>
				<p class="vfes-title--regular mvf-gen3-installation__overflow-wrap">
					{{ 'v10.myOrder.gen3Installation.contractGen3Step2Instrucction7Description' | translate }}
				</p>
				<img
					class="vfes-mt mvf-gen3-installation__img"
					src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.group13' | translate) }}"
					alt=""
				/>
			</div>
		</div>
		<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
	</div>

	<!-- Step 3 -->
	<div body id="page3">
		<div class="mvf-gen3-installation__p16">
			<div
				class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 mvf-gen3-installation__overflow-wrap"
				*ngIf="ordersService.detailOrderData.activarRouter === RouterGuideType.UPGRADE_INTERNET_AND_DECO_GEN3"
			>
				{{ 'v10.myOrder.gen3Installation.installGen3Step3Instrucction9Title' | translate }}
			</div>
			<div
				class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 mvf-gen3-installation__overflow-wrap"
				*ngIf="ordersService.detailOrderData.instalarEquipos === DecoGuideType.UPGRADE_DECO_GEN3"
			>
				{{ 'v10.myOrder.gen3Installation.guideInstallDecoStep3Title' | translate }}
			</div>
			<div
				class="vfes-title--bold vfes-pb- vfes-mt mvf-gen3-installation__fs20 mvf-gen3-installation__overflow-wrap"
				*ngIf="ordersService.detailOrderData.instalarEquipos === DecoGuideType.UPGRADE_DECO_GEN3"
			>
				{{ 'v10.myOrder.gen3Installation.contractGen3Step3Title2' | translate }}
			</div>
			<span class="vfes-title--regular mvf-gen3-installation__overflow-wrap">
				{{ 'v10.myOrder.gen3Installation.installGen3Step3Instrucction9Description' | translate }}
			</span>
			<img
				class="mvf-gen3-installation__img vfes-mt vfes-mb"
				src="{{ appService.getImgFullPath('v10.myOrder.gen3Installation.img.pgen-3-12-a-red-1' | translate) }}"
				alt=""
			/>
		</div>
		<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
	</div>

	<!-- Step 4 -->
	<div body id="page4">
		<div class="mvf-gen3-installation__p16">
			<div class="vfes-title--bold vfes-pb- mvf-gen3-installation__fs20 mvf-gen3-installation__overflow-wrap">
				{{ 'v10.myOrder.gen3Installation.installGen3Step4Title' | translate }}
			</div>
			<span
				class="vfes-title--regular mvf-gen3-installation__overflow-wrap"
				[innerHtml]="'v10.myOrder.gen3Installation.installGen3Step4Description' | translate"
			>
			</span>
		</div>
		<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
	</div>
</vfac-overlay>

<ng-template #navigationButtonsTemplate>
	<!-- Navigation buttons -->
	<div class="mvf-gen3-installation__button-top mvf-gen3-installation__p16 vfes-align--center">
		<mva10-c-button (mva10OnClick)="goForwards()">
			{{
				(disableSchamanCheck
					? selectedStep === pagesNames.length - 1
						? 'v10.myOrder.gen3Installation.understood'
						: 'v10.myOrder.gen3Installation.installRouterContinue'
					: selectedStep === pagesNames.length - 2
					? 'v10.myOrder.gen3Installation.autoiCheckInstallationButton'
					: 'v10.myOrder.gen3Installation.installRouterContinue'
				) | translate
			}}
		</mva10-c-button>
	</div>
</ng-template>

<sp-check-installation-overlay
	[openFrom]="pageName"
	*ngIf="showOverlayCheckInstallation"
	(close)="closeCheckInstallationOverlay($event)"
>
</sp-check-installation-overlay>
