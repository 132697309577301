import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OverlayModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs/internal/Subscription';
import { AutoiSchamanResolution } from 'src/app/shared/models/autoiSchaman.model';
import { NebaTvGuideType, RouterGuideType } from '../../../enums/guide-types.enum';
import { SchamanErrors } from '../../../enums/schaman-errors.enum';
import {
	SchamanResolutions,
	SchamanResolutionsInteractionMapping,
	SchamanState,
} from '../../../enums/schaman-resolutions.enum';
import { SchamanTags } from '../../../enums/schaman-tags.enum';
import { SchamanServiceType } from '../../../enums/schaman-service-type.enum';
import { constants } from '../../../models/constants';
import { OrdersService } from '../services/orders.service';
import { TicketsService } from '../services/tickets.service';
import { InteractionType } from '../../../../shared/enums/interaction-type.enum';
import { SlidService } from '../services/slid.service';
import { AppService } from 'src/app/app.service';
import { UtilsService } from '../services/utils.service';
import { MyOrdersService } from '../services/my-orders.service';
import { MyOrdersTaggingService } from '../../../services/my-orders-tagging.service';
import { MotiveSupport, StepFlow } from '../../../enums/step-flow.enum';
import { Commons, checkInstallationAnalytic as analytic } from '../../../constants/defines';

interface DeviceCheckStatus {
	title: string;
	description: string;
}
@Component({
	selector: 'sp-check-installation-overlay',
	templateUrl: './sp-check-installation-overlay.component.html',
	styleUrls: ['./sp-check-installation-overlay.component.scss'],
})
export class SpCheckInstallationOverlayComponent implements OnInit {
	@Input() serviceType: SchamanServiceType;
	@Input() openFrom: string = analytic.ayudaAutoi;
	@Input() checkType: SchamanTags;
	@Output() closeTvGuide: EventEmitter<void> = new EventEmitter();
	@Output() closeOverlayAndOpenTvGuide: EventEmitter<void> = new EventEmitter();
	@Output() close: EventEmitter<string> = new EventEmitter<string>();
	page: number;
	schamanServiceType: typeof SchamanServiceType = SchamanServiceType;
	isLoaded: boolean = true;
	checkInstallationData: OverlayModel;
	checkStatus: DeviceCheckStatus[];
	currentCheckStatusStep: number;
	resultType: string;
	showOverlay: boolean;
	_schamanInterval$: Subject<void> = new Subject();
	showChat: boolean;
	schamanStartTime: number;
	schamanTimeoutTime: number;
	schamanIntervalDelay: number;
	schamanId: string;
	errorText: string;
	_schamanTimeout: ReturnType<typeof setTimeout>;
	schamanOks: SchamanResolutions[] = [
		SchamanResolutions.SOLVED,
		SchamanResolutions.TRANSFER_OK,
		SchamanResolutions.SOLVED_REBOOT_CPE,
		SchamanResolutions.SOLVED_REBOOT_STB,
		SchamanResolutions.ERROR,
	];

	idCliente: string;
	subscriptions: Subscription[] = [];

	constructor(
		public ticketsService: TicketsService,
		public ordersService: OrdersService,
		public translate: TranslateService,
		readonly utilsService: UtilsService,
		private readonly slidService: SlidService,
		private readonly appService: AppService,
		public myOrdersService: MyOrdersService,
		public tagging: MyOrdersTaggingService
	) {}

	ngOnInit(): void {
		this.resetData();
		this.showOverlay = true;
		this.errorText = Commons.ERROR.toLocaleLowerCase();
		this.showChat = constants.trueLiteral.includes(
			this.translate.instant('v10.myOrder.checkInstallationOverlay.autoiCheckInstallationShowChat')
		);
		this.checkInstallationData = {
			isCenterTitle: true,
			isFullScreen: false,
			hideBackButtonOnAllPages: true,
			title: this.translate.instant('v10.myOrder.checkInstallationOverlay.autoiCheckInstallationTitle'),
		};
		this.checkStatus = [
			{
				title: this.translate.instant('v10.myOrder.checkInstallationOverlay.autoiCheckInstallationStep1Title'),
				description: this.translate.instant(
					'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationStep1Description'
				),
			},
			{
				title: this.translate.instant('v10.myOrder.checkInstallationOverlay.autoiCheckInstallationStep2Title'),
				description: this.translate.instant(
					'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationStep2Description'
				),
			},
			{
				title: this.translate.instant('v10.myOrder.checkInstallationOverlay.autoiCheckInstallationStep3Title'),
				description: this.translate.instant(
					'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationStep3Description'
				),
			},
		];

		this.idCliente = this.myOrdersService.MYORDERS_DATA.idCliente;

		this.resetTagging();
		this.ordersService.detailOrderData.esOrdenNeba
			? this.tagging.sendPage([analytic.inicioComprobacionNeba])
			: this.tagging.sendPage([analytic.inicioComprobacionHfc]);
	}

	closeTvGuideAndGoToFinalStep(): void {
		this.slidService.postInsertInteractionSLID(this.idCliente, InteractionType.CLIENTE_OK_TV).subscribe();
		this.subscriptions.push(
			this.ticketsService.advanceTicketStatus(this.ordersService.detailOrderData.numeroPedido).subscribe()
		);

		this.page = 4;
		this.closeTvGuide.emit();

		setTimeout(() => {
			this.clientConfirmAnalytic('click en confirmar instalacion internet con tv');
		}, 500);
	}

	closeTvGuideAndGoToChat(): void {
		this.slidService.postInsertInteractionSLID(this.idCliente, InteractionType.CLIENTE_KO_TV).subscribe();
		this.utilsService.stepFlow = StepFlow.KO_CLIENT_TV;
		this.utilsService.motiveSupport = MotiveSupport.KO_CLIENT_ROUTER_TV;
		this.goToChat();
		this.closeTvGuide.emit();
	}

	resetTagging(): void {
		this.tagging.extendUtagPageName(
			[
				analytic.detallePedido,
				analytic.ayudaAutoi,
				analytic.comprobacionHfc,
				analytic.comprobacionNeba,
				analytic.confirmacionHfc,
				analytic.confirmacionNeba,
				analytic.inicioComprobacionHfc,
				analytic.inicioComprobacionNeba,
				analytic.ok,
				analytic.ko,
				analytic.clickContinuar,
				analytic.clickAbrirChat,
				analytic.clickInstalacionCompletada,
				analytic.clickConfirmarInstalacionSinTv,
				analytic.guiaRouter,
				analytic.guiaRouterDeco,
				analytic.guiaDesconocida,
				analytic.guiaDecoGen3,
				analytic.guiaRouterDecoGen3,
				analytic.guiaDeco,
			],
			null,
			true
		);
		this.tagging.extendUtagPageName([analytic.ayudaAutoi]);
	}

	callCheckService(orderId: string = this.ordersService.detailOrderData.numeroPedido): void {
		this.isLoaded = false;
		this.currentCheckStatusStep = 1;

		this.resetTagging();
		this.ordersService.detailOrderData.esOrdenNeba
			? this.tagging.sendPage([analytic.inicioComprobacionNeba])
			: this.tagging.sendPage([analytic.inicioComprobacionHfc]);
		this.tagging.sendInteraction({ link_type: analytic.clickContinuar });
		setTimeout(() => {
			this.resetTagging();

			this.ordersService.detailOrderData.esOrdenNeba
				? this.tagging.sendPage([analytic.comprobacionNeba])
				: this.tagging.sendPage([analytic.comprobacionHfc]);
		}, 500);

		const checkType: SchamanTags = this.resolveCheckType();

		this.subscriptions.push(
			this.ticketsService.createAutoiServiceCheck({ tags: checkType }).subscribe({
				next: (schamanId) => {
					this.isLoaded = true;
					this.page = 2;
					this.schamanId = schamanId;
					this.schamanStartTime = Date.now();

					this.schamanTimeoutTime =
						this.translate.instant('v10.myOrder.checkInstallationOverlay.autoiSchamanCheckTimeout')?.trim().length &&
						!isNaN(+this.translate.instant('v10.myOrder.checkInstallationOverlay.autoiSchamanCheckTimeout'))
							? +this.translate.instant('v10.myOrder.checkInstallationOverlay.autoiSchamanCheckTimeout')
							: constants.schaman.defaultTimeout;

					if (this.schamanTimeoutTime <= constants.schaman.minTimeout) {
						this.schamanTimeoutTime = constants.schaman.minTimeout;
					}

					this.schamanIntervalDelay =
						this.translate.instant('v10.myOrder.checkInstallationOverlay.autoiSchamanCheckDelay')?.trim().length &&
						!isNaN(+this.translate.instant('v10.myOrder.checkInstallationOverlay.autoiSchamanCheckDelay'))
							? +this.translate.instant('v10.myOrder.checkInstallationOverlay.autoiSchamanCheckDelay')
							: constants.schaman.defaultDelay;

					if (this.schamanIntervalDelay >= this.schamanTimeoutTime) {
						this.schamanIntervalDelay = this.schamanTimeoutTime / 4;
					}
					if (this.schamanIntervalDelay <= constants.schaman.minDelay) {
						this.schamanIntervalDelay = constants.schaman.minDelay;
					}

					// Initial call
					this.checkSchamanStatus(orderId, schamanId);
				},
				error: () => {
					this.createAutoiServiceCheckError();
				},
			})
		);
	}

	private resolveCheckType(): SchamanTags {
		if (this.ordersService.detailOrderData.esOrdenNeba) {
			return this.checkType || this.ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET
				? SchamanTags.NET
				: SchamanTags.ALL;
		}
		return SchamanTags.ALL;
	}

	createAutoiServiceCheckError(): void {
		this.slidService.postInsertInteractionSLID(this.idCliente, InteractionType.KO_WS_SCHAMAN).subscribe();
		this.utilsService.stepFlow = this.checkType === SchamanTags.NET ? StepFlow.KO_ROUTER : StepFlow.KO_TV;
		this.utilsService.motiveSupport = MotiveSupport.KO_SCHAMAN_ROUTER_TV;
		this.handleSchamanError(SchamanErrors.CREATION_ERROR);
	}

	checkSchamanStatus(orderId: string, schamanId: string = this.schamanId): void {
		this._schamanTimeout = setTimeout(() => {
			this.subscriptions.push(
				this.ticketsService
					.getAutoiServiceCheckStatus(schamanId)
					.pipe(takeUntil(this._schamanInterval$))
					.subscribe({
						next: (testStatus) => {
							const isFinished: boolean = [SchamanState.FINISHED].includes(testStatus?.state);
							if (isFinished && this.schamanOks.includes(testStatus?.resolution)) {
								this.slidService
									.postInsertInteractionSLID(
										this.idCliente,
										SchamanResolutionsInteractionMapping[testStatus?.resolution]
									)
									.subscribe();

								this.currentCheckStatusStep = 3;
								setTimeout(() => {
									this.resultType = analytic.ok;
									this.page = 3;
									this.resetTagging();
									this.ordersService.detailOrderData.esOrdenNeba
										? this.tagging.sendPage([analytic.comprobacionNeba, analytic.ok])
										: this.tagging.sendPage([analytic.comprobacionHfc, analytic.ok]);
									this.clientConfirmAnalytic();
								}, this.schamanIntervalDelay);
							} else if (Date.now() - this.schamanStartTime >= this.schamanTimeoutTime) {
								this.slidService
									.postInsertInteractionSLID(this.idCliente, InteractionType.TIMEOUT_DIAGNOSTICO)
									.subscribe();
								this.noConcluyenteSchaman();
							} else if (isFinished) {
								this.slidService
									.postInsertInteractionSLID(
										this.idCliente,
										SchamanResolutionsInteractionMapping[testStatus?.resolution]
									)
									.subscribe();
								this.utilsService.stepFlow = this.checkType === SchamanTags.NET ? StepFlow.KO_ROUTER : StepFlow.KO_TV;
								this.utilsService.motiveSupport = MotiveSupport.KO_SCHAMAN_ROUTER_TV + testStatus?.resolution;
								this.handleSchamanError(SchamanErrors.FINISHED_ERROR, testStatus?.resolution);
							} else {
								this.checkSchamanStatusRefact(testStatus, orderId);
							}
						},
						error: () => {
							this.slidService.postInsertInteractionSLID(this.idCliente, InteractionType.KO_WS_SCHAMAN).subscribe();
							this.noConcluyenteSchaman();
						},
					})
			);
		}, this.schamanIntervalDelay);
	}

	noConcluyenteSchaman(): void {
		this.currentCheckStatusStep = 3;
		setTimeout(() => {
			this.resultType = analytic.ok;
			this.page = 3;

			this.resetTagging();

			this.clientConfirmAnalytic();
		}, this.schamanIntervalDelay);
	}

	public checkSchamanStatusRefact(testStatus: AutoiSchamanResolution, orderId: string): void {
		if ([SchamanState.EXECUTING_RESOLUTION].includes(testStatus?.state)) {
			this.currentCheckStatusStep = 2;
		} else if ([SchamanState.EXECUTING_DIAGNOSE].includes(testStatus?.state)) {
			this.currentCheckStatusStep = 1;
		}
		this.checkSchamanStatus(orderId);
	}

	handleSchamanError(errorType: string, resolution?: string): void {
		this.isLoaded = true;
		this.resultType = this.errorText;
		this.page = 3;
		this.resetTagging();
		this.tagging.sendInteraction({ link_type: `schaman ko - ${errorType}${resolution ? ' ' + resolution : ''}` });
		setTimeout(() => {
			this.tagging.utagData.link_type = '';
			this.ordersService.detailOrderData.esOrdenNeba
				? this.tagging.sendPage([analytic.comprobacionNeba, analytic.ko])
				: this.tagging.sendPage([analytic.comprobacionHfc, analytic.ko]);
		}, 200);
	}

	goToChatNet(): void {
		if (!this.ordersService.detailOrderData.esOrdenNeba) {
			this.resetTagging();
			this.tagging.extendUtagPageName([this.openFrom, analytic.confirmacionHfc]);
			this.tagging.sendInteraction({ link_type: analytic.clickAbrirChat });
			this.slidService.postInsertInteractionSLID(this.idCliente, InteractionType.CLIENTE_KO_ALL).subscribe();
		} else {
			this.slidService.postInsertInteractionSLID(this.idCliente, InteractionType.CLIENTE_KO_NET).subscribe();
			this.utilsService.stepFlow = StepFlow.KO_CLIENT_ROUTER;
			this.utilsService.motiveSupport = MotiveSupport.KO_CLIENT_ROUTER_TV;
		}

		this.goToChat();
	}

	goToChat(schamanKo?: boolean): void {
		if (schamanKo) {
			this.resetTagging();
			this.ordersService.detailOrderData.esOrdenNeba
				? this.tagging.sendPage([analytic.comprobacionNeba, analytic.ko])
				: this.tagging.sendPage([analytic.comprobacionHfc, analytic.ko]);
		} else {
			this.clientConfirmAnalytic(analytic.clickAbrirChat);
		}
		this.tagging.sendInteraction({ link_type: analytic.clickAbrirChat });
		this.onClose(this.showChat ? 'chat' : 'header');
		this.utilsService.openAutoiChat(
			this.ordersService.detailOrderData.numeroPedido,
			this.utilsService.flowNebaTV,
			this.utilsService.stepFlow,
			this.utilsService.motiveSupport
		);
	}

	onClose(closeType: string): void {
		this.showOverlay = false;
		setTimeout(() => {
			this.close.emit(closeType);
		}, 500);
	}

	resetData(): void {
		this.page = 1;
		this.currentCheckStatusStep = 1;
	}

	goNext(): void {
		if (this.serviceType === SchamanServiceType.NEBA_INTERNET) {
			this.goToFinalStep();
		} else if (this.serviceType === SchamanServiceType.NEBA_INTERNET_TV) {
			this.openTvGuide();
		}
	}

	goToFinalStep(): void {
		this.subscriptions.push(
			this.ticketsService.advanceTicketStatus(this.ordersService.detailOrderData.numeroPedido).subscribe()
		);

		this.page = 4;
		if (!this.ordersService.detailOrderData.esOrdenNeba) {
			this.slidService.postInsertInteractionSLID(this.idCliente, InteractionType.CLIENTE_OK_ALL).subscribe();

			this.resetTagging();
			this.tagging.extendUtagPageName([this.openFrom, analytic.confirmacionHfc]);
			this.tagging.sendInteraction({ link_type: analytic.clickInstalacionCompletada });
		} else {
			this.slidService.postInsertInteractionSLID(this.idCliente, InteractionType.CLIENTE_OK_NET).subscribe();
			this.clientConfirmAnalytic(analytic.clickConfirmarInstalacionSinTv);
		}
	}
	openTvGuide(): void {
		this.slidService.postInsertInteractionSLID(this.idCliente, InteractionType.CLIENTE_OK_NET).subscribe();
		this.closeOverlayAndOpenTvGuide.emit();
		this.clientConfirmAnalytic('click en continuar instalacion tv');
	}

	clientConfirmAnalytic(link_type?: string): void {
		this.resetTagging();

		let pageName: string[];
		if (this.serviceType === SchamanServiceType.NEBA_INTERNET) {
			pageName = ['guia neba internet', 'overlay confirmacion cliente internet sin tv', 'internet'];
		} else if (this.serviceType === SchamanServiceType.NEBA_INTERNET_TV) {
			pageName = ['guia neba internet', 'overlay confirmacion cliente internet con tv', 'internet'];
		} else {
			let guide: string = '';
			if (this.ordersService.detailOrderData.guiaAutoiNebaTv === NebaTvGuideType.NEBA_DECO_GEN3) {
				guide = 'guia instalacion deco gen 3';
			} else if (this.ordersService.detailOrderData.guiaAutoiNebaTv === NebaTvGuideType.NEBA_DECO_MULTICAST) {
				guide = 'guia instalacion deco multicast';
			} else if (this.ordersService.detailOrderData.guiaAutoiNebaTv === NebaTvGuideType.NEBA_DECO_UNICAST) {
				guide = 'guia instalacion deco unicast';
			}
			pageName = [guide, 'overlay confirmacion cliente internet con tv', 'tv'];
		}
		this.sendAnalytics(pageName, link_type);
	}

	private sendAnalytics(pageName: string[], link_type?: string): void {
		this.tagging.extendUtagPageName(pageName);
		link_type ? this.tagging.sendInteraction({ link_type }) : this.tagging.sendPage();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => {
			subscription.unsubscribe();
		});
		this.resetData();
		this.resetTagging();
		this.tagging.extendUtagPageName([analytic.ayudaAutoi], null, true);
		this._schamanInterval$.complete();
		clearTimeout(this._schamanTimeout);
	}
}
