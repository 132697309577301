<div
	class="history-dropdown-btn"
	[ngClass]="{ 'selected': lDropDownOptions.isSelected, 'col-xs-6': isTwoPerRow }"
	(click)="toggleItemsList(dropDownType.left)"
>
	<div class="full-height histoy-dropdown-btn-internal-wrapper">
		<span
			class="set-margin"
			*ngIf="!lDropDownOptions.selectDatePicker && lDropDownOptions.addPrefix"
			[ngClass]="{ 'bold-text': lDropDownOptions.makePrefixBold }"
		>
			{{ lDropDownOptions.prefix }}
			<span
				*ngIf="!lDropDownOptions.selectDatePicker"
				[ngClass]="{ 'bold-text': lDropDownOptions.makeLabelBold }"
				[innerHTML]="lDropDownSelectedItem.label"
			>
			</span>
		</span>
		<span
			class="set-margin"
			*ngIf="(lDropDownOptions.selectDatePicker && lDropDownOptions.addPrefix) || !lDropDownOptions.addPrefix"
			[innerHTML]="lDropDownSelectedItem.label"
		>
		</span>
		<i class="icon icon-chevron-up history-dropdown-arrow" [ngClass]="{ 'none-display': !lDropDownOptions.opened }">
		</i>
		<i class="icon icon-chevron-down history-dropdown-arrow" [ngClass]="{ 'none-display': lDropDownOptions.opened }">
		</i>
	</div>
</div>
<div
	class="history-dropdown-btn"
	[ngClass]="{ 'selected': rDropDownOptions.isSelected, 'col-xs-4': isTwoPerRow }"
	(click)="toggleItemsList(dropDownType.right)"
>
	<div class="full-height histoy-dropdown-btn-internal-wrapper">
		<span
			*ngIf="!rDropDownOptions.selectDatePicker && rDropDownOptions.addPrefix"
			class="set-margin"
			[ngClass]="{ 'bold-text': rDropDownOptions.makePrefixBold }"
		>
			{{ rDropDownOptions.prefix }}
		</span>
		<span *ngIf="!rDropDownOptions.selectDatePicker" [ngClass]="{ 'bold-text': rDropDownOptions.makeLabelBold }">
			{{ rDropDownSelectedItem.label }}
		</span>
		<span *ngIf="rDropDownOptions.selectDatePicker" class="set-margin"> {{ rDropDownSelectedItem.label }} </span>
		<i class="icon icon-chevron-up history-dropdown-arrow" [ngClass]="{ 'none-display': !rDropDownOptions.opened }">
		</i>
		<i class="icon icon-chevron-down history-dropdown-arrow" [ngClass]="{ 'none-display': rDropDownOptions.opened }">
		</i>
	</div>
</div>
<div [ngClass]="lDropDownOptions.dropDownClass" class="history-dropdown none-display">
	<p class="history-dropdown-title">{{ lDropDownOptions.title }}</p>
	<div
		(click)="toggleItemsList(dropDownType.left)"
		*ngFor="let item of lDropDownOptions.items"
		class="history-dropdown-row"
	>
		<span
			(click)="setLDropDownSelectedValue(item)"
			[ngClass]="{ selected: item.value === lDropDownSelectedItem.value }"
			class="history-dropdown-Selection full-height"
		>
		</span>
		<span class="prefix" *ngIf="lDropDownOptions.addPrefix" [innerHTML]="lDropDownOptions.prefix"> </span>
		<span (click)="setLDropDownSelectedValue(item)" class="check-box-label" [innerHTML]="item.label"> </span>
	</div>
	<div
		(click)="toggleItemsList(dropDownType.left)"
		*ngIf="lDropDownOptions.showDatePicker"
		class="history-dropdown-row"
	>
		<span
			(click)="openDatePicker(dropDownType.left)"
			[ngClass]="{ selected: lDropDownOptions.selectDatePicker }"
			class="history-dropdown-Selection full-height"
		>
		</span>
		<span
			(click)="openDatePicker(dropDownType.left)"
			class="check-box-label"
			[innerHTML]="lDropDownOptions.datePickerText"
		>
		</span>
	</div>
	<div class="history-dropdown-sparator-height"></div>
</div>
<div [ngClass]="rDropDownOptions.dropDownClass" class="history-dropdown none-display">
	<p class="history-dropdown-title">{{ rDropDownOptions.title }}</p>
	<div
		(click)="toggleItemsList(dropDownType.right)"
		*ngFor="let item of rDropDownOptions.items"
		class="history-dropdown-row"
	>
		<span
			(click)="setRDropDownSelectedValue(item)"
			[ngClass]="{ selected: item.value === rDropDownSelectedItem.value }"
			class="history-dropdown-Selection full-height"
		>
		</span>
		<span class="prefix" *ngIf="rDropDownOptions.addPrefix" [innerHTML]="rDropDownOptions.prefix"> </span>
		<span (click)="setRDropDownSelectedValue(item)" class="check-box-label" [innerHTML]="item.label"> </span>
	</div>
	<div
		(click)="toggleItemsList(dropDownType.right)"
		*ngIf="rDropDownOptions.showDatePicker"
		class="history-dropdown-row"
	>
		<span
			(click)="openDatePicker(dropDownType.right)"
			[ngClass]="{ selected: rDropDownOptions.selectDatePicker }"
			class="history-dropdown-Selection full-height"
		>
		</span>
		<span (click)="openDatePicker(dropDownType.right)" class="check-box-label">
			{{ rDropDownOptions.datePickerText }}
		</span>
	</div>
	<div class="history-dropdown-sparator-height"></div>
</div>
