import { Component, Input, ViewChild } from '@angular/core';
import { Characteristic, Note, ProductOrderItem, TypesReturn } from '../../../../models/getOrder.model';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../../../app.service';
import { subOrders, returnDefines, tabsMyOrders } from '../../../../constants/defines';
import { OrdersService } from '../../services/orders.service';
import { SpModalFullComponent } from '../../../sp-modal-full/sp-modal-full.component';
import { config } from '../../../../../../config/pages-config';
import { Router } from '@angular/router';
import { ReturnsDeviceService } from '../../services/returns-device.service';
import { DetallePedidoExtended } from '../../../../models/detalleSeguimientoPedido.model';
import { MyOrdersTagService } from '../../services/myOrderTag.service';

@Component({
	selector: 'sp-sub-orders',
	templateUrl: './sp-sub-orders.component.html',
	styleUrls: ['./sp-sub-orders.component.scss'],
})
export class SpSubOrdersComponent {
	@Input() orderData: ProductOrderItem;

	public sap: string;
	public terminalDetails: string;
	public imageTerminalDetail: string;
	public delivered: string;
	public dateDelivery: string;
	public sapDefines: string;
	public subOrder: Characteristic[] = [];
	public ofString: string = tabsMyOrders.ofString;
	public formatDay: string = tabsMyOrders.formatDay;
	public formatMonth: string = tabsMyOrders.formatMonth;
	public formatX: string = tabsMyOrders.formatX;
	public showDetails: boolean = false;
	public selectedType: string;
	public disableBtn: boolean = false;
	@ViewChild(returnDefines.modalReturnDevice) modalReturnDevice: SpModalFullComponent;
	public isReturn: boolean = false;
	public isReturnFix: boolean = false;
	public informationDate: string;
	public checkedDoa: boolean = false;
	public checkedNoDoa: boolean = false;

	constructor(
		public translate: TranslateService,
		public appService: AppService,
		public router: Router,
		public returnsDevice: ReturnsDeviceService,
		public ordersService: OrdersService,
		public returnsTagService: MyOrdersTagService
	) {}

	ngOnInit(): void {
		this.terminalDetails = subOrders.terminalDetails;
		this.sapDefines = subOrders.sap;
		this.delivered = subOrders.delivered;
		this.dateDelivery = subOrders.dateDelivery;
		this.setPriceDevice();
		this.checkDateReturn();
	}

	public setImageTerminal(): string {
		this.sap = this.orderData.characteristic?.find(
			(terminal) => terminal.name.toUpperCase() === this.sapDefines.toUpperCase()
		).value;
		this.returnsDevice.sapCode = this.sap;
		this.translate.get('v10.myOrder.orders.images').subscribe((res) => {
			this.imageTerminalDetail = res[this.sap] ?? res.default;
		});
		this.returnsDevice.sapImage = this.appService.getImgFullPath(this.imageTerminalDetail);
		return this.returnsDevice.sapImage;
	}

	public setNameTerminal(): string {
		this.returnsDevice.sapSubOrder = this.orderData.characteristic?.find(
			(terminal) => terminal.name.toUpperCase() === this.terminalDetails.toUpperCase()
		).value;
		return this.returnsDevice.sapSubOrder;
	}

	public clickReturnDevice(): void {
		this.setOrder();
		this.returnsTagService.tagClickNoDoa(returnDefines.whiteSring);
		this.returnsTagService.tagModalStateCases(returnDefines.whiteSring);
		this.modalReturnDevice.primaryButton = false;
		this.modalReturnDevice.show();
	}

	public clickDoa(): void {
		this.setOrder();
		this.returnsTagService.tagClickDoa(returnDefines.whiteSring);
		this.router.navigate([config.myorders2.repairDevice.route]);
	}

	public setOrder(): void {
		this.ordersService.selectedOrderData = this.ordersService?.orderData?.find(
			(el) => el.numeroPedido === this.orderData.idFather
		);
		this.ordersService.detailOrderData = new DetallePedidoExtended();
		this.ordersService.detailOrderData.numeroPedido = this.ordersService?.selectedOrderData?.numeroPedido;
		this.ordersService.detailOrderData.descripcion = this.ordersService?.selectedOrderData?.descripcion;
	}

	public clickCancel(): void {
		this.checkedDoa = false;
		this.checkedNoDoa = false;
		this.disableBtn = true;
		this.returnsTagService.tagClickCancelCases(returnDefines.whiteSring);
		this.modalReturnDevice?.hide();
	}

	public clickContinue(): void {
		this.returnsTagService.tagClickContinueCases(returnDefines.whiteSring);
		if (this.selectedType?.toLowerCase() === returnDefines.doa.toLowerCase()) {
			this.checkedDoa = false;
			this.checkedNoDoa = false;
			this.disableBtn = true;
			window.open(this.translate.instant('v10.myOrder.orders.returns.urlDOA'), returnDefines.blank);
		} else {
			this.routerImei();
		}
		this.modalReturnDevice?.hide();
	}

	public checkUniqSap(): boolean {
		const countSap: string[] = [];
		Object.values(this.ordersService?.subOrder).forEach((el) => {
			el.forEach((subEl) => {
				if (
					subEl.characteristic.find((sap) => sap.name.toUpperCase() === this.sapDefines.toUpperCase()).value ===
					this.sap
				) {
					countSap.push(subEl.idFather);
				}
			});
		});
		return countSap.length < 2;
	}

	public routerImei(): void {
		if (this.checkUniqSap()) {
			this.router.navigate([config.myorders2.returnDevice.route]);
		} else {
			this.router.navigate([config.myorders2.requestImei.route]);
		}
	}

	public setOption(type: string): void {
		this.disableBtn = false;
		this.selectedType = type;
		this.returnsDevice.selectedType = this.selectedType;
		if (type.toLowerCase() === returnDefines.doa.toLowerCase()) {
			this.checkedDoa = true;
			this.checkedNoDoa = false;
		} else {
			this.checkedDoa = false;
			this.checkedNoDoa = true;
		}
	}

	public setPriceDevice(): void {
		this.returnsDevice.priceDevice = this.orderData.itemPrice?.find(
			(elem) => elem.priceType === returnDefines.oneOffPrice
		).price?.taxIncludedAmount?.value;
	}

	public showDetail(): void {
		if (this.showDetails) this.showDetails = false;
		else this.showDetails = true;
	}

	public onClickLink(): void {
		this.returnsTagService.tagClickHelpCases(returnDefines.whiteSring);
		window.open(this.translate.instant('v10.myOrder.orders.returns.optionsReturn.helpUrl'), returnDefines.blank);
	}

	public checkDateReturn(): void {
		const days: number = (this.ordersService?.dateRequest - this.orderData?.date) / (1000 * 60 * 60 * 24);
		if (days <= 14 && this.setDeliveryID()) {
			this.isReturn = true;
			this.informationDate = this.setDayToDate(14);
		} else if (days < 31) {
			this.isReturnFix = true;
			this.informationDate = this.setDayToDate(30);
		}
	}

	public setDeliveryID(): Note {
		return this.ordersService?.productOrder[this.orderData.idFather]?.note?.find(
			(el) => el.id === tabsMyOrders.deliveryID
		);
	}

	public setDayToDate(days: number): string {
		if (this.orderData?.date) {
			const date: Date = new Date(this.orderData.date);
			date.setDate(date.getDate() + days);
			const getTime: number = new Date(date).getTime();

			return getTime.toString();
		}
	}
}
