<div
	class="top-up-rows"
	[ngClass]="{
		'forward-transition-tray': trayService?.showForwardTransition,
		'backward-transition-tray': trayService?.showBackwardTransition,
		'fade-out-tray': topupErrorService?.hideCancelOverlay
	}"
>
	<div class="top-up-header">
		<div class="exit-logo" *ngIf="topupService.isMobileView">
			<span (click)="returnToFirstScreens()" class="icon icon-close"> </span>
		</div>
	</div>
	<div class="permission-container">
		<div class="permission-icon">
			<img [src]="permissionData.icon" />
		</div>
		<div class="permission-title">
			<label> {{ permissionData.subtitle }} </label>
		</div>
		<div class="permission-desc">
			<p>{{ permissionData.description }}</p>
		</div>
	</div>
	<div class="permission-buttons-container">
		<button type="button" class="continue button red two" (click)="openContactList()">
			{{ permissionData.button1 }}
		</button>
		<button type="button" class="cancel button white two" (click)="returnToFirstScreens()">
			{{ permissionData.button2 }}
		</button>
	</div>
</div>
