import { tagging } from './../../../../config/tagging-config';
import { AppService } from '../../../app.service';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionService } from '../../../core/services/subscription.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';

import { StorageService } from '../../../core/services/storage.service';
import { DataUsageModel, Breakpoints } from '@mva10/mva10-angular';
import { TYPEUSER, defines, BundleType, home5G } from './../../../shared/constants/defines';
import { ServiceType } from '../../../shared/enums/serviceType.enum';
import { ArrayItemsServiceBalanceModel, ServiceBalanceModel } from './../../../models/service-balance.model';
import { BundlesGroups } from '../../enums/bundlesGroups';
import { IconClassNames } from '../../enums/iconClassNames.enum';
import { config } from '../../../../config/pages-config';
import { TariffService } from '../../services/tariff.service';
import { ServiceBalanceWs10Service } from '../../services/service-balance-ws10.service';
import { BundleType as ConsumptionBundleType } from '../../../shared/enums/bundleType.enum';
import { ProductsAndServicesService } from '../../../products-and-services/products-and-services.service';
import { Router } from '@angular/router';
import { ResizeService } from '../../../mva10/shared/services/resize.service';
import { MsisdnToShowPipe } from '../../pipes/msisdn-to-show.pipe';
import { DecimalPipe } from '@angular/common';
import { CustomerType } from '../../enums/customerType.enum';
import { ProductSubcriptionItem } from '../../../models/product-subcription.model';
import { CustomerBillService } from '../../services/customer-bill.service';
import { CustomerAccountService } from '../../services/customer-account.service';
import moment from 'moment';
import { ServiceModel } from 'src/app/models/service.model';
import { TopUpService } from 'src/app/top-up/top-up.service';

@Component({
	selector: 'sp-top-tile',
	templateUrl: './top-tile.component.html',
	styleUrls: ['./top-tile.component.scss'],
})
export class TopTileComponent implements OnInit {
	BREAKPOINTS: typeof Breakpoints = Breakpoints;
	breakpoint: Breakpoints;

	arrayDataUsageData: Array<DataUsageModel> = [];

	isTariffYu: boolean = false;
	topTileBottomRight: string;
	topTileBottomLeft: string;
	isPostpaid: boolean = false;
	isFibre: boolean = false;
	isLandline: boolean = false;
	isMbb: boolean = false;
	isTV: boolean = false;
	isPrepaid: boolean = false;

	textTitle: string;
	iconTitle: string;
	fibreDownloadSpeed: string;
	fibreDownloadSpeedText: string;
	fibreUploadSpeed: string;
	fibreUploadSpeedText: string;

	label_unlimited: string;
	label_type: string;
	value_landline: number;
	valueMbb: number;
	labelUsed: string;
	labelUnit: string;
	labelAvailable: string;

	infoPacks: string;
	descPacks: string;

	valueOutOfBundle: string;

	hasRoamers: boolean;
	roamersPrepaidWarningTitle: string;
	public carouselConfig: string =
		'{"responsive":{"0":{"breakpoint":0,"elements":1, ' +
		'"arrows":false}, "1":{"breakpoint":769,"elements":1,' +
		'"arrows":true}, "2":{"breakpoint":1025,"elements":1,' +
		'"arrows":true}}, "build":{"arrows":false, "bullets":true, "clickable_bullets":true}}';

	purePrepaid: boolean;
	amountPrepaid: string;
	prepaidWithBenefitsInactive: boolean;
	prepaidWithBenefitsActive: boolean;
	lastRecharge: string;
	arrayProductsServices: ServiceModel[] = [];
	positionIndex: number = 0;
	isTarifUnlimited: boolean = false;
	isEligibleForTariffNameDisplay: boolean = false;
	@Input() topTileLoaded: boolean;
	@Input() topTileError: boolean;
	@Input() topTileData: ArrayItemsServiceBalanceModel;
	@Output() retryTopTileClick: EventEmitter<void> = new EventEmitter();
	@Output() reloadTileData: EventEmitter<ServiceModel> = new EventEmitter();
	get serviceType(): typeof ServiceType {
		return ServiceType;
	}

	constructor(
		public appTheme: AppService,
		public subscriptionService: SubscriptionService,
		private translateService: TranslateService,
		public taggingHelper: TaggingHelperService,
		public utilitiesService: UtilitiesService,
		public storage: StorageService,
		public tariffService: TariffService,
		public serviceBalanceService: ServiceBalanceWs10Service,
		public productsAndServicesService: ProductsAndServicesService,
		public router: Router,
		private resizeService: ResizeService,
		private msisdnToShowPipe: MsisdnToShowPipe,
		private decimalPipe: DecimalPipe,
		private customerBillService: CustomerBillService,
		public customerAccountService: CustomerAccountService,
		public topupService: TopUpService
	) {}

	ngOnInit(): void {
		this.breakpoint = this.resizeService.breakpoint;
		this.resizeService.onResize$.subscribe((size: Breakpoints) => {
			this.breakpoint = this.resizeService.breakpoint;
		});

		this.arrayProductsServices = this.subscriptionService.customerData?.services;
		this.topupService.selectedServiceId = this.subscriptionService.customerData.currentService.id;
		this.positionIndex = this.arrayProductsServices?.findIndex(
			(service) => service.id === this.subscriptionService.customerData.currentService.id
		);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['topTileData'] && changes['topTileData'].currentValue?.items) {
			this.isTarifUnlimited = this.subscriptionService.customerData?.currentService?.ilimitada;
			this.checkService();

			this.mapHeaderTitle();
			this.checkRoamers();
			if (!this.isTarifUnlimited || this.isFibre) {
				this.switchServiceTypeTopTile(changes['topTileData'].currentValue.items);
			} else {
				this.mapUnlimited();
			}
			if (!this.isPrepaid && !this.isFibre && !this.isTarifUnlimited) {
				this.checkOutOfBundle();
			}
		}
	}

	showVal(position: number): void {
		const typeService: string = this.selectServiceTag();

		if (position !== this.positionIndex) {
			this.reloadTileData.emit(this.arrayProductsServices[position]);
			this.topTileLoaded = false;
			this.positionIndex = position;
		}

		const topTileSliderData: { [key: string]: string } = Object.assign(
			{},
			tagging.dashboard.topTile.topTileSlider.data
		);
		const eventName: string = topTileSliderData.event_name;
		topTileSliderData.event_context = topTileSliderData.event_context.replace('{0}', typeService);
		this.taggingHelper.track(eventName, topTileSliderData);
	}

	private checkOutOfBundle(): void {
		this.valueOutOfBundle = '';
		this.serviceBalanceService
			.getServiceBalance(BundleType.outOfPlan, false)
			.subscribe((resultOutOfBundle: ArrayItemsServiceBalanceModel) => {
				const serviceOutOfBundle: ServiceBalanceModel[] = resultOutOfBundle.items.filter(
					(item) => item.subscriptions[0].id === this.subscriptionService.customerData.currentService?.id
				);
				const textOutOfBundle: string = `${this.translateService.instant('v10.dashboard.common.outrate')}: `;

				if (serviceOutOfBundle.length >= 1) {
					const priceOutOFBundle: string = parseFloat(serviceOutOfBundle[0]?.amount?.amount)
						.toFixed(2)
						.replace('.', ',')
						.replace(',00', '');
					this.valueOutOfBundle = `${textOutOfBundle}${priceOutOFBundle}${serviceOutOfBundle[0]?.amount?.currency}`;
				} else {
					this.valueOutOfBundle = `${textOutOfBundle}${defines.amountZero}${defines.euro}`;
				}
			});
	}

	private checkRoamers(): void {
		const roamers: ConsumptionBundleType[] =
			this.subscriptionService.cachedRoamers?.[this.subscriptionService.customerData.customerAccountsId]?.[
				this.subscriptionService.customerData.currentService?.id
			] || [];

		this.hasRoamers = roamers.length > 1;
		this.checkRoamersPrepaid(roamers);
	}

	checkRoamersPrepaid(roamers: ConsumptionBundleType[]): void {
		if (roamers?.length > 0 && this.isPrepaid) {
			const roamersList: string[] = roamers.map((item) =>
				item === ConsumptionBundleType.PrepaidData ? ConsumptionBundleType.Data : item
			);
			const sortingBundles: string[] = [
				ConsumptionBundleType.Data,
				ConsumptionBundleType.Voice,
				ConsumptionBundleType.SMS,
			];
			roamersList.sort((a, b) => sortingBundles.indexOf(a) - sortingBundles.indexOf(b));
			const wcsKey: string = roamersList.join('');
			this.roamersPrepaidWarningTitle = this.translateService.instant(`v10.billing.roamers.prepaid.${wcsKey}.title`);
		} else {
			this.roamersPrepaidWarningTitle = undefined;
		}
	}

	checkService(): void {
		this.isPostpaid =
			this.subscriptionService.customerData.currentService.type.toLocaleLowerCase() ===
			ServiceType.Postpaid.toLocaleLowerCase();
		this.isFibre =
			this.subscriptionService.customerData.currentService.type.toLocaleLowerCase() ===
				ServiceType.ADSL.toLocaleLowerCase() ||
			this.subscriptionService.customerData.currentService.type.toLocaleLowerCase() ===
				ServiceType.Fibre.toLocaleLowerCase();
		this.isLandline =
			this.subscriptionService.customerData.currentService.type.toLocaleLowerCase() ===
				ServiceType.Landline.toLocaleLowerCase() ||
			this.subscriptionService.customerData.currentService.type.toLocaleLowerCase() ===
				ServiceType.VodafoneEnTuCasa.toLocaleLowerCase();
		this.isMbb =
			this.subscriptionService.customerData.currentService.type.toLocaleLowerCase() ===
			ServiceType.MbbPostpaid.toLocaleLowerCase();
		this.isTV =
			this.subscriptionService.customerData.currentService.type.toLocaleLowerCase() ===
			ServiceType.Tv.toLocaleLowerCase();
		this.isPrepaid =
			this.subscriptionService.customerData.currentService.type.toLocaleLowerCase() ===
			ServiceType.Prepaid.toLocaleLowerCase();
	}

	mapHeaderTitle(): void {
		switch (this.subscriptionService.customerData.currentService.type.toLocaleLowerCase()) {
			case ServiceType.Postpaid.toLocaleLowerCase():
				this.iconTitle = `#${IconClassNames.icon_mobile}`;
				this.textTitle = this.msisdnToShowPipe.transform(this.subscriptionService.customerData.currentService.id);
				break;
			case ServiceType.Fibre.toLocaleLowerCase():
				this.iconTitle = `#${IconClassNames.icon_adsl}`;
				this.textTitle = this.translateService.instant('v10.common.literals.fiber_C');
				break;
			case ServiceType.ADSL.toLocaleLowerCase():
				this.iconTitle = `#${IconClassNames.icon_adsl}`;
				this.textTitle = this.translateService.instant('v10.common.literals.adsl');
				break;
			case ServiceType.Landline.toLocaleLowerCase():
			case ServiceType.VodafoneEnTuCasa.toLocaleLowerCase():
				this.iconTitle = `#${IconClassNames.icon_bussines_phone}`;
				this.textTitle = this.msisdnToShowPipe.transform(this.subscriptionService.customerData.currentService.id);
				break;
			case ServiceType.MbbPostpaid.toLocaleLowerCase():
				this.setMBBpostpaid();
				break;
			case ServiceType.Tv.toLocaleLowerCase():
				this.textTitle = this.translateService.instant('v10.dashboard.tv.tv_title');
				break;
			case ServiceType.Prepaid.toLocaleLowerCase():
				this.iconTitle = `#${IconClassNames.icon_mobile}`;
				this.textTitle = this.msisdnToShowPipe.transform(this.topupService.selectedServiceId);
				break;
		}
	}

	public setMBBpostpaid(): void {
		const isHome5G: boolean = this.subscriptionService.customerData.currentService.tarrifCode === home5G.tariffCode;
		this.iconTitle = isHome5G ? `#${IconClassNames.icon_adsl}` : `#${IconClassNames.icon_usb_modem}`;
		this.textTitle = isHome5G
			? this.subscriptionService.customerData.currentService.name
			: this.msisdnToShowPipe.transform(this.subscriptionService.customerData.currentService.id);
	}

	switchServiceTypeTopTile(arrayItemsTopTile: ServiceBalanceModel[]): void {
		switch (this.subscriptionService.customerData.currentService.type.toLocaleLowerCase()) {
			case ServiceType.Postpaid.toLocaleLowerCase():
				this.mapTopTilePostpaid(arrayItemsTopTile);
				break;
			case ServiceType.Fibre.toLocaleLowerCase():
			case ServiceType.ADSL.toLocaleLowerCase():
				this.mapTopTileFibre(arrayItemsTopTile);
				break;
			case ServiceType.Landline.toLocaleLowerCase():
			case ServiceType.VodafoneEnTuCasa.toLocaleLowerCase():
				this.mapTopTileLandline(arrayItemsTopTile);
				break;
			case ServiceType.MbbPostpaid.toLocaleLowerCase():
				this.mapTopTileMbb(arrayItemsTopTile);
				break;
			case ServiceType.Tv.toLocaleLowerCase():
				this.topTileLoaded = false;
				this.topTileError = false;
				this.loadInfoTv();
				break;
			case ServiceType.Prepaid.toLocaleLowerCase():
				this.mapTopTilePrepaid(arrayItemsTopTile);
				break;
		}
	}

	loadInfoTv(): void {
		const filter_visual_on19: Array<string> = this.translateService.instant('v10.dashboard.config.filter_visual_on19');
		const filter_visual_on19_micro: Array<string> = this.translateService.instant(
			'v10.dashboard.config.filter_visual_on19_micro'
		);
		const filter_visual_on15: Array<string> = this.translateService.instant('v10.dashboard.config.filter_visual_on15');

		if (this.tariffService.tariffWs10?.superofer && this.tariffService.tariffWs10.superofer === defines.on19) {
			// ON19
			if (filter_visual_on19) {
				filter_visual_on19.forEach((item) => {
					this.getProductsON19(item);
				});
			}
			// ON19_MICRO
			if (filter_visual_on19_micro) {
				filter_visual_on19_micro.forEach((item) => {
					this.getProductsON19(item);
				});
			}
		} else {
			// ON15
			if (filter_visual_on15) {
				filter_visual_on15.forEach((item) => {
					if (this.tariffService.tariffWs10?.code === item) {
						this.iconTitle = `#${IconClassNames.icon_tv_tv}`;
						this.getProductsON15();
						this.topTileLoaded = true;
						this.topTileError = false;
					}
				});
			}
		}
	}

	private getProductsON15(): void {
		this.translateService.get('v10.dashboard').subscribe((text) => {
			this.infoPacks = text.tv[this.tariffService.tariffWs10.code].title;
			this.descPacks = text.tv[this.tariffService.tariffWs10.code].descr;
		});
	}

	private getProductsON19(item: string): void {
		if (this.tariffService.tariffWs10.code === item) {
			this.tariffService.getProductsON19(this.tariffService.tariffWs10.code).subscribe(
				(responseTvON19) => {
					this.mapTopTileTvON19(responseTvON19.items);

					this.topTileLoaded = true;
					this.topTileError = false;
				},
				(error) => {
					this.topTileLoaded = true;
					this.topTileError = true;
				}
			);
		}
	}

	mapTopTileTvON19(arrayItemsTvON19: ProductSubcriptionItem[]): void {
		this.translateService.get('v10.dashboard').subscribe((text) => {
			const textPack: string = this.translateService.instant('v10.dashboard.tv.on19.pack');
			const textPacks: string = this.translateService.instant('v10.dashboard.tv.on19.packs');
			const textON19Channels: Array<string> = this.translateService.instant(
				'v10.dashboard.config.filter_visual_on19_channel'
			);

			const resultActive: ProductSubcriptionItem[] = arrayItemsTvON19
				.filter((item) => item.status.current === defines.active)
				.map(function (id: ProductSubcriptionItem): ProductSubcriptionItem {
					id.id = id.id.substr(0, 5);
					return id;
				});

			this.iconTitle = `#${IconClassNames.icon_channel_variety}`;

			if (
				this.storage.userProfile.customerType.toUpperCase() === CustomerType.Consumer.toUpperCase() ||
				this.storage.userProfile.customerType.toUpperCase() === CustomerType.Authorized.toUpperCase()
			) {
				if (resultActive.length === 0) {
					this.infoPacks = `${defines.one} ${textPack}`;
					this.descPacks = this.tariffService.tariffWs10.name;
				} else {
					this.infoPacks = `${resultActive.length} ${resultActive.length > 1 ? textPacks : textPack}`;
					this.descPacks = resultActive
						.map(function (item: ProductSubcriptionItem): string {
							return item.name;
						})
						.join(', ');
				}
			} else {
				const finishON19Channel: ProductSubcriptionItem[] = resultActive.filter((item) =>
					textON19Channels.includes(item.id)
				);

				this.infoPacks = `${finishON19Channel.length} ${finishON19Channel.length > 1 ? textPacks : textPack}`;

				this.descPacks = finishON19Channel
					.map(function (item: ProductSubcriptionItem): string {
						return item.name;
					})
					.join(', ');
			}
		});
	}

	mapTopTileMbb(arrayItemsTopTile: ServiceBalanceModel[]): void {
		if (arrayItemsTopTile[0]?.allowance?.unlimited === true) {
			this.valueMbb = 100;
			this.labelUsed = this.translateService.instant('v10.common.literals.unlimited_P');
		} else {
			if (arrayItemsTopTile[0]?.allowance?.availableAllowance && arrayItemsTopTile[0]?.allowance?.usedAllowance) {
				this.valueMbb =
					(Number.parseFloat(arrayItemsTopTile[0].allowance.remainingAllowance) * 100) /
					Number.parseFloat(arrayItemsTopTile[0].allowance.availableAllowance);
			} else {
				this.valueMbb = 0;
			}

			this.labelUsed = this.decimalPipe.transform(arrayItemsTopTile[0]?.allowance?.remainingAllowance, '1.1-1');
			this.labelUnit = arrayItemsTopTile[0]?.allowance?.remainingAllowanceUnitCode;
			const textAvailable: string = this.translateService.instant('v10.dashboard.common.availableFrom');
			this.labelAvailable = `${textAvailable} ${arrayItemsTopTile[0]?.allowance?.availableAllowance
				.replace('.0', '')
				.replace('.00', '')}
      ${arrayItemsTopTile[0]?.allowance?.availableAllowanceUnitCode}`;
		}
	}

	mapTopTileLandline(arrayItemsTopTile: ServiceBalanceModel[]): void {
		if (arrayItemsTopTile[0]?.allowance?.unlimited === true) {
			this.value_landline = 100;
			this.label_unlimited = this.translateService.instant('v10.common.literals.unlimited_FP');
			this.label_type = this.translateService.instant('v10.common.literals.label_voice').toLowerCase();
		} else {
			if (arrayItemsTopTile[0]?.allowance?.availableAllowance && arrayItemsTopTile[0]?.allowance?.usedAllowance) {
				this.value_landline =
					(Number.parseFloat(arrayItemsTopTile[0].allowance.remainingAllowance) * 100) /
					Number.parseFloat(arrayItemsTopTile[0].allowance.availableAllowance);
			} else {
				this.value_landline = 0;
			}

			this.label_unlimited = `${arrayItemsTopTile[0].allowance.availableAllowance} ${arrayItemsTopTile[0].allowance.availableAllowanceUnitCode}`;
			this.label_type = this.translateService.instant('v10.common.literals.label_voice').toLowerCase();
		}
	}

	mapTopTileFibre(arrayItemsTopTile: ServiceBalanceModel[]): void {
		if (this.isTarifUnlimited) {
			if (this.tariffService.Tariff?.DownloadSpeed) {
				this.fibreDownloadSpeed =
					`${this.deleteTopTileZeros(this.tariffService.Tariff?.DownloadSpeed)} ` +
					`${this.tariffService.Tariff?.DownloadSpeedUnit}`;
				this.fibreDownloadSpeedText = this.translateService.instant('v10.dashboard.common.description_down');
			}

			if (this.tariffService.Tariff?.UploadSpeed) {
				this.fibreUploadSpeed =
					`${this.deleteTopTileZeros(this.tariffService.Tariff?.UploadSpeed)} ` +
					`${this.tariffService.Tariff?.UploadSpeedUnit}`;
				this.fibreUploadSpeedText = this.translateService.instant('v10.dashboard.common.description_up');
			}
		} else {
			if (arrayItemsTopTile[0]?.allowance?.downloadSpeed) {
				this.fibreDownloadSpeed =
					`${this.deleteTopTileZeros(arrayItemsTopTile[0]?.allowance?.downloadSpeed)} ` +
					`${arrayItemsTopTile[0]?.allowance?.downloadSpeedUnit}`;
				this.fibreDownloadSpeedText = this.translateService.instant('v10.dashboard.common.description_down');
			}

			if (arrayItemsTopTile[0]?.allowance?.uploadSpeed) {
				this.fibreUploadSpeed =
					`${this.deleteTopTileZeros(arrayItemsTopTile[0]?.allowance?.uploadSpeed)} ` +
					`${arrayItemsTopTile[0]?.allowance?.uploadSpeedUnit}`;
				this.fibreUploadSpeedText = this.translateService.instant('v10.dashboard.common.description_up');
			}
		}
	}

	public deleteTopTileZeros(speed: string): string {
		return parseFloat(speed).toFixed(2).replace('.00', '');
	}

	mapTopTilePrepaid(arrayItemsTopTile: ServiceBalanceModel[]): void {
		this.isEligibleForTariffNameDisplay = this.checkTariffNameDisplayEligiblity();
		arrayItemsTopTile.forEach((element) => {
			element.allowance.remainingAllowance = Number(Number(element.allowance.remainingAllowance).toFixed(1)).toString();
			element.allowance.availableAllowance = Number(Number(element.allowance.availableAllowance).toFixed(1)).toString();
		});
		this.purePrepaid = false;
		this.prepaidWithBenefitsActive = false;
		this.prepaidWithBenefitsInactive = false;
		if (this.tariffService.isPrepaidNoBenefit) {
			this.purePrepaid = true;
			this.amountPrepaid = this.customerBillService.valueAmountPrepaid;

			this.customerAccountService.getHistory().subscribe(
				() => {
					const text_recharge: string = this.translateService.instant('v10.dashboard.common.time_recharge');
					this.lastRecharge = `${text_recharge} ${moment(new Date(this.customerAccountService.responseHistory)).format(
						'DD/MM/YYYY'
					)}`;
				},
				(error) => {
					this.lastRecharge = this.translateService.instant('v10.dashboard.common.time_norecharge');
				}
			);
		} else {
			if (this.tariffService.tariffWs10.extension.es.benefits.status === defines.active) {
				this.prepaidWithBenefitsActive = true;
				this.mapTopTilePrepaidItems(arrayItemsTopTile);
			} else {
				this.prepaidWithBenefitsInactive = true;
			}
		}
	}

	mapTopTilePrepaidItems(arrayItemsTopTile: ServiceBalanceModel[]): void {
		this.arrayDataUsageData = [];

		const itemData: ServiceBalanceModel = arrayItemsTopTile.find(
			(item) =>
				item.type.toLocaleLowerCase() === ConsumptionBundleType.Data &&
				item.group === BundlesGroups.dataPrepaid.toLocaleLowerCase()
		);

		if (itemData) {
			this.arrayDataUsageData.push(this.mapData(itemData));
		}

		const itemVoice: ServiceBalanceModel = arrayItemsTopTile.find(
			(item) =>
				item.type.toLocaleLowerCase() === ConsumptionBundleType.Voice &&
				item.group === BundlesGroups.voiceNationalPrepaid.toLocaleLowerCase()
		);

		if (itemVoice) {
			this.arrayDataUsageData.push(this.mapVoice(itemVoice));
		}

		const tarriff_filter_yu_prepaid: Array<string> = this.translateService.instant(
			'v10.dashboard.config.filter_yu_prepaid'
		);

		if (tarriff_filter_yu_prepaid.includes(this.tariffService.tariffWs10.code)) {
			const textUnlimited: string = this.translateService.instant('v10.common.literals.unlimited_P');
			const SubtextUnlimited: string = this.translateService.instant('v10.common.literals.sms');
			const icon: string = `#${IconClassNames.icon_sms}`;

			const itemDataUsageModel: DataUsageModel = new DataUsageModel();

			itemDataUsageModel.value = 100;
			itemDataUsageModel.text = textUnlimited;
			itemDataUsageModel.icon = icon;
			itemDataUsageModel.subText = SubtextUnlimited;

			this.arrayDataUsageData.push(itemDataUsageModel);
		} else {
			const itemSms: ServiceBalanceModel = arrayItemsTopTile.find(
				(item) =>
					item.type.toLocaleLowerCase() === ConsumptionBundleType.SMS &&
					item.group === BundlesGroups.smsPrepaid.toLocaleLowerCase()
			);

			if (itemSms) {
				this.arrayDataUsageData.push(this.mapSms(itemSms));
			}
		}
	}

	mapTopTilePostpaid(arrayItemsTopTile: ServiceBalanceModel[]): void {
		this.arrayDataUsageData = [];

		const itemData: ServiceBalanceModel = arrayItemsTopTile.find(
			(item) =>
				item.type.toLocaleLowerCase() === ConsumptionBundleType.Data &&
				item.group === BundlesGroups.data.toLocaleLowerCase()
		);

		if (itemData) {
			this.arrayDataUsageData.push(this.mapData(itemData));
			if (this.appTheme.typeUser === TYPEUSER.one || this.appTheme.typeUser === TYPEUSER.three) {
				this.topTileBottomRight = this.translateService.instant('v10.dashboard.common.yu_bit.accumulated_data');
			}
		}

		const itemVoice: ServiceBalanceModel = arrayItemsTopTile.find(
			(item) =>
				item.type.toLocaleLowerCase() === ConsumptionBundleType.Voice &&
				item.group === BundlesGroups.voiceNational.toLocaleLowerCase()
		);

		if (itemVoice) {
			this.arrayDataUsageData.push(this.mapVoice(itemVoice));
		}

		const itemSms: ServiceBalanceModel = arrayItemsTopTile.find(
			(item) =>
				item.type.toLocaleLowerCase() === ConsumptionBundleType.SMS &&
				item.group === BundlesGroups.sms.toLocaleLowerCase()
		);

		if (itemSms) {
			this.arrayDataUsageData.push(this.mapSms(itemSms));
		}
	}

	mapUnlimited(): void {
		const unlimitedValue: number = 100;
		this.arrayDataUsageData = [
			this.printDataUnlimited(unlimitedValue),
			this.printVoiceUnlimited(unlimitedValue),
			this.printSmsUnlimited(unlimitedValue),
		];
	}

	printDataUnlimited(value: number): DataUsageModel {
		const dataUsage: DataUsageModel = {
			text: this.translateService.instant('v10.common.literals.unlimited_P'),
			subText: '',
			icon: `#${IconClassNames.icon_data}`,
			value,
		};
		return dataUsage;
	}

	printVoiceUnlimited(value: number): DataUsageModel {
		const dataUsage: DataUsageModel = {
			text: this.translateService.instant('v10.common.literals.unlimited_FP'),
			subText: '',
			icon: `#${IconClassNames.icon_landline}`,
			value,
		};
		return dataUsage;
	}

	printSmsUnlimited(value: number): DataUsageModel {
		const dataUsage: DataUsageModel = {
			text: this.translateService.instant('v10.common.literals.unlimited_P'),
			subText: '',
			icon: `#${IconClassNames.icon_sms}`,
			value,
		};
		return dataUsage;
	}

	mapData(itemData: ServiceBalanceModel): DataUsageModel {
		const textUnlimited: string = this.translateService.instant('v10.common.literals.unlimited_P');
		const SubtextUnlimited: string = this.translateService.instant('v10.common.literals.label_data').toLowerCase();
		const icon: string = `#${IconClassNames.icon_data}`;

		return this.mapCommonType(itemData, textUnlimited, SubtextUnlimited, icon);
	}

	mapVoice(itemVoice: ServiceBalanceModel): DataUsageModel {
		const textUnlimited: string = this.translateService.instant('v10.common.literals.unlimited_FP');
		const SubtextUnlimited: string = this.translateService.instant('v10.common.literals.label_voice').toLowerCase();
		const icon: string = `#${IconClassNames.icon_landline}`;

		return this.mapCommonType(itemVoice, textUnlimited, SubtextUnlimited, icon);
	}

	mapSms(itemSms: ServiceBalanceModel): DataUsageModel {
		const textUnlimited: string = this.translateService.instant('v10.common.literals.unlimited_P');
		const SubtextUnlimited: string = this.translateService.instant('v10.common.literals.sms');
		const icon: string = `#${IconClassNames.icon_sms}`;

		return this.mapCommonType(itemSms, textUnlimited, SubtextUnlimited, icon);
	}

	mapCommonType(item: ServiceBalanceModel, text: string, subText: string, icon: string): DataUsageModel {
		const itemDataUsageModel: DataUsageModel = new DataUsageModel();

		if (item.allowance && item.allowance.unlimited && item.allowance.unlimited === true) {
			itemDataUsageModel.value = 100;
			itemDataUsageModel.text = text;
			itemDataUsageModel.icon = icon;
			itemDataUsageModel.subText = subText;
		} else {
			if (item.allowance.availableAllowance && item.allowance.usedAllowance) {
				itemDataUsageModel.value =
					(Number.parseFloat(item.allowance.remainingAllowance) * 100) /
					Number.parseFloat(item.allowance.availableAllowance);
			} else {
				itemDataUsageModel.value = 0;
			}

			itemDataUsageModel.text = `${item.allowance.remainingAllowance} ${item.allowance.remainingAllowanceUnitCode}`;
			itemDataUsageModel.icon = icon;
			const textAvailable: string = this.translateService.instant('v10.dashboard.common.availableFrom');
			itemDataUsageModel.subText = `${textAvailable} ${item.allowance.availableAllowance} ${item.allowance.availableAllowanceUnitCode}`;
		}

		return itemDataUsageModel;
	}

	retryTopTile(): void {
		let typeServiceTag: string = '';

		typeServiceTag = this.selectServiceTag(true);

		const topTileRetryData: { [key: string]: string } = Object.assign({}, tagging.dashboard.topTile.topTileRetry.data);
		const eventName: string = topTileRetryData.event_name.replace('{0}', typeServiceTag);
		topTileRetryData.event_name = topTileRetryData.event_name.replace('{0}', typeServiceTag);
		topTileRetryData.page_name = topTileRetryData.page_name.replace('{0}', typeServiceTag);
		topTileRetryData.event_context = topTileRetryData.event_context.replace('{0}', typeServiceTag);

		this.taggingHelper.track(eventName, topTileRetryData);

		this.retryTopTileClick.emit();
		this.topTileLoaded = false;
		this.topTileError = false;
	}

	topTileClick(): void {
		const typeService: string = this.selectServiceTag();
		const topTileClikedData: { [key: string]: string } = Object.assign(
			{},
			tagging.dashboard.topTile.topTileClicked.data
		);
		const eventName: string = topTileClikedData.event_name;
		topTileClikedData.event_context = topTileClikedData.event_context.replace('{0}', typeService);

		this.taggingHelper.track(eventName, topTileClikedData);

		switch (this.subscriptionService.customerData.currentService.type.toLocaleLowerCase()) {
			case ServiceType.Postpaid.toLocaleLowerCase():
			case ServiceType.Landline.toLocaleLowerCase():
			case ServiceType.VodafoneEnTuCasa.toLocaleLowerCase():
			case ServiceType.MbbPostpaid.toLocaleLowerCase():
			case ServiceType.Tv.toLocaleLowerCase():
				this.productsAndServicesService.redirectionTabComsuption = true;
				break;
			case ServiceType.Prepaid.toLocaleLowerCase():
			case ServiceType.Fibre.toLocaleLowerCase():
			case ServiceType.ADSL.toLocaleLowerCase():
				this.productsAndServicesService.redirectionTabComsuption = false;
				break;
		}

		if (
			this.subscriptionService.customerData.currentService.type.toLocaleLowerCase() ===
				ServiceType.Prepaid.toLocaleLowerCase() &&
			this.purePrepaid
		) {
			this.clickRecharge();
		} else {
			this.router.navigate([config.ProductsAndServices.MainScreen.route]);
		}
	}

	selectServiceTag(noPurePrepaidTagging?: boolean): string {
		let typeServiceTag: string = '';

		switch (this.subscriptionService.customerData.currentService.type.toLocaleLowerCase()) {
			case ServiceType.Landline.toLocaleLowerCase():
			case ServiceType.VodafoneEnTuCasa.toLocaleLowerCase():
				typeServiceTag = tagging.dashboard.topTile.types.landline;
				break;
			case ServiceType.Postpaid.toLocaleLowerCase():
				typeServiceTag = tagging.dashboard.topTile.types.pospaid;
				break;
			case ServiceType.Fibre.toLocaleLowerCase():
			case ServiceType.ADSL.toLocaleLowerCase():
				typeServiceTag = tagging.dashboard.topTile.types.fibre;
				break;
			case ServiceType.MbbPostpaid.toLocaleLowerCase():
				typeServiceTag = tagging.dashboard.topTile.types.mbb;
				break;
			case ServiceType.Tv.toLocaleLowerCase():
				typeServiceTag = tagging.dashboard.topTile.types.tv;
				break;
			case ServiceType.Prepaid.toLocaleLowerCase():
				if (this.purePrepaid && !noPurePrepaidTagging) {
					typeServiceTag = tagging.dashboard.topTile.types.prepago_puro;
				} else {
					typeServiceTag = tagging.dashboard.topTile.types.prepago;
				}
				break;
		}
		return typeServiceTag;
	}

	clickRecharge(): void {
		this.router.navigate([config.TopUp.topUpPrepaidservice.route]);
	}

	checkTariffNameDisplayEligiblity(): boolean {
		const eligibleTariffs: string[] = this.translateService.instant('v10.dashboard.topTile.eligibleTariffs');
		return eligibleTariffs?.includes(this.tariffService?.Tariff?.Code);
	}
}
