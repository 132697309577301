import {
	Component,
	OnInit,
	ViewContainerRef,
	ViewChild,
	ComponentFactoryResolver,
	ChangeDetectorRef,
	Input,
	ElementRef,
} from '@angular/core';
import { state, trigger, style, transition, animate, group } from '@angular/animations';
import { SpMva10OverlayService } from '../../services/sp-mva10-overlay.service';
import { AppService } from '../../../app.service';
import { IframeModalComponent } from '../iframe-modal/iframe-modal.component';
@Component({
	selector: 'sp-mva10-overlay',
	templateUrl: './sp-mva10-overlay.component.html',
	styleUrls: ['./sp-mva10-overlay.component.scss'],
	animations: [
		trigger('openClose', [
			state(
				'open',
				style({
					transform: 'translate(0px,0px)',
					display: 'flex',
				})
			),
			state(
				'closed',
				style({
					transform: 'translate(0 , 100%)',
				})
			),
			transition('* => closed', [
				group([
					animate('500ms 100ms ease-in-out', style({ transform: 'translate(0 , 100%)' })),
					animate('100ms 600ms ease-in-out', style({ display: 'none' })),
				]),
			]),
			transition('closed => open', [group([animate('500ms ease-in-out', style({ transform: 'translate(0 , 0)' }))])]),
		]),
		trigger('backgroundAnimation', [
			state(
				'open',
				style({
					display: 'block',
				})
			),
			state(
				'closed',
				style({
					display: 'none',
				})
			),
			transition('* => closed', [group([animate('500ms ease-in-out', style({ display: 'none' }))])]),
			transition('* => open', [group([animate('500ms ease-in-out', style({ display: 'block' }))])]),
		]),
	],
})
export class SpMva10OverlayComponent implements OnInit {
	@ViewChild('dynamicComponentRef', { read: ViewContainerRef, static: true }) dynamicComponentRef: ViewContainerRef;
	@ViewChild('scrolDIV', { read: ElementRef }) scrolDIV: ElementRef;

	showErrorOverLay: boolean = true;
	component;
	isLoadContent = false;
	lastScrollTop: number = 10;
	@Input() trayHeight: string;
	constructor(
		public _Mva10OverlayService: SpMva10OverlayService,
		public resolver: ComponentFactoryResolver,
		private cd: ChangeDetectorRef,
		public appService: AppService
	) {}

	ngOnInit() {
		this.configIframe();
		this._Mva10OverlayService.closeOverLaySubject.subscribe((keepState) => {
			if (!keepState) {
				this.component = null;
				setTimeout(() => {
					this.dynamicComponentRef.clear();
				}, 1000);
			}
		});
	}
	/**
	 * Closes sp mva10 overlay component
	 */
	close() {
		this._Mva10OverlayService.close();
		if (this.dynamicComponentRef) {
			setTimeout(() => {
				this.dynamicComponentRef.clear();
				this._Mva10OverlayService.showErrorOverLay = false;
			}, 550);
		}
	}
	/**
	 * Onfirsts btn click
	 */
	onfirstBtnClick() {
		this._Mva10OverlayService.onFirstBtnClick();
	}
	/**
	 * on second btn click
	 */
	onSecondBtnClick() {
		this._Mva10OverlayService.onSecondBtnClick();
	}
	showiframe(data) {
		this._Mva10OverlayService.showErrorOverLay = false;
		const compFactory = this.resolver.resolveComponentFactory(IframeModalComponent);
		this.dynamicComponentRef.clear();
		const iframe = this.dynamicComponentRef.createComponent(compFactory);
		iframe.instance.url = data.url;
		iframe.instance.headerText = data.headerText;
		iframe.instance.close = this.close.bind(this);
		iframe.instance.setIframeUrl();
	}
	configIframe() {
		this._Mva10OverlayService.openOverLaySubject.subscribe((data) => {
			this._Mva10OverlayService.fullScreenTray = false;
			this.showiframe(data);
		});
	}
	showModal(component) {
		if (this._Mva10OverlayService.enableRepeatComponent) {
			this.component = null;
		}
		this.showErrorOverLay = false;
		if (this.component !== component) {
			this.component = component;
			this.isLoadContent = true;
		}
	}

	ngAfterViewChecked(): void {
		if (this.isLoadContent) {
			this.isLoadContent = false;
			const compFactory = this.resolver.resolveComponentFactory(this.component);
			this.dynamicComponentRef.clear();
			this.dynamicComponentRef.createComponent(compFactory);
			this.cd.detectChanges();
		}
	}
	onScroll(scrollTop: number) {
		if (this._Mva10OverlayService.isTrayScrolable && this.lastScrollTop && this.lastScrollTop < scrollTop) {
			if (this._Mva10OverlayService.trayMaxHeight !== '100vh') {
				this._Mva10OverlayService.trayMaxHeight = '100vh';
				this._Mva10OverlayService.isTrayScrolable = false;
				this.lastScrollTop = scrollTop;
				this._Mva10OverlayService.maximizeOverLaySubject.next();
			}
		} else {
			this.lastScrollTop = scrollTop;
		}
		if (this._Mva10OverlayService.trayMaxHeight === '50vh') {
			this.scrolDIV.nativeElement.scrollTop = 0;
		}
	}
}
