export enum PaymentJourny {
	postDeptTotal = 'debtTotal',
	postDeptPartial = 'debtPartial',
	postInadvance = 'payInAdvance',
	postcashDeskTotal = 'cashDeskTotal',
	postcashDeskPartial = 'cashDeskPartial',
	prepaidTopup = 'prepaidTopup_v3',
	prePaidAnonymus = 'anonymousPrepaidTopup',
	paymentAnonymus = 'anonymousPayment',
	prePaidAnonymusThirdParty = 'defaultAnonymous',
	default = 'default',
	manageSavedCredietCard = 'manageSavedCredietCard',
	anonymousDebtTotal = 'anonymousDebtTotal',
	anonymousDebtPartial = 'anonymousDebtPartial',
	anonymousPayInAdvance = 'anonymousPayInAdvance',
	saveCreditCard = 'saveCreditCard',
	postCardPaymentCommercial = 'eShopMiVF',
	financedDevicesCancellation = 'financedDevices',
	smartPaySavedCard = 'smartPaySavedCard',
	suspendedDeptTotal = 'suspendedTotal',
	postPaidGeneric = 'postPaidGeneric',
	paymentCommitments = 'paymentCommitment',
	debtJourneysGeneric = 'debtJourneysGeneric',
}
