import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MyOrdersTaggingService } from '../../../services/my-orders-tagging.service';

@Component({
	selector: 'sp-view-desktop',
	templateUrl: './sp-view-desktop.component.html',
	styleUrls: ['./sp-view-desktop.component.scss'],
})
export class SpViewDesktopComponent implements OnInit {
	showNebaDesktopOverlay: boolean;
	@Output() onClose: EventEmitter<string> = new EventEmitter();

	constructor(private tagging: MyOrdersTaggingService) {}

	ngOnInit(): void {
		this.showNebaDesktopOverlay = true;
		this.tagging.extendUtagPageName(['detalle pedido'], null, true);
		this.tagging.extendUtagPageName(['guia neba internet', 'overlay aviso desktop']);
		this.tagging.sendPage();
	}

	closedOverlay(): void {
		this.showNebaDesktopOverlay = false;
		this.tagging.extendUtagPageName(['guia neba internet', 'overlay aviso desktop'], null, true);
		this.tagging.extendUtagPageName(['detalle pedido']);
		setTimeout(() => {
			this.onClose.emit();
		}, 500);
	}
}
