import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URLS } from '../constants/routes-config';
import * as JsonQuery from 'jsonpath/jsonpath';

import { TileDisplayModel, ErrorDisplayModel, ItemListModel, DetailListModel } from '@mva10/mva10-angular';

import { defines, serviceBalanceType, CommercialJourneys, JSON_PATHS } from './../constants/defines';
import { Dashboard } from '../../models/dashboard.model';

// IKKI SERVICES
import { SubscriptionService } from '../../core/services/subscription.service';
import { TariffService } from './../services/tariff.service';
import { CustomerBillService } from './../services/customer-bill.service';

import * as constants from '../../shared/constants/defines';
import { ProductsAndServicesService } from '../../products-and-services/products-and-services.service';
@Injectable()
export class ServiceBalanceWs10Service {
	valueAmountPrepaid: string;
	itemsOutOfPlans: Array<ItemListModel>;
	detailListData: DetailListModel;
	tariffCodeValue: string;

	filteredCategories: any = [];
	valueResGroupTrue: any;

	errorBenefits: ErrorDisplayModel;
	mainTileSimpleError: boolean = false;

	voiceYuPrepaid: boolean = false;
	smsYuPrepaid: boolean = false;
	dataYuPrepaid: boolean = false;

	codeSharing: string;
	dataSharingOwner: any;
	listCompareData: any = [];

	currencyItem: string;

	public bundlesInPlan = {};
	/**this flag to check if the user is inactive or not */
	isInActiveUser: boolean = false;
	refreshTarifAndServiceBalnceApi: boolean = false;
	refreshSubscriptionAndTarifAndServiceBalanceAPIs: boolean = false;
	tariffAndSeriveBalanceisLoaded: boolean = true;

	consumptionDeligthData: TileDisplayModel;
	public bundlesInPlanAndGroup: Array<{ serviceId: string; bundles: Dashboard[] }> = [{ serviceId: '', bundles: [] }];

	constructor(
		private http: HttpClient,
		private subscriptionService: SubscriptionService,
		private tariffService: TariffService,
		private customerBillService: CustomerBillService,
		private productsAndServicesService: ProductsAndServicesService
	) {}

	getServiceBalance(balanceType: string, group: boolean, serviceId?: string): Observable<any> {
		const url = API_URLS['Dashboard-WS10'].ServiceBalance.replace(
			'{siteId}',
			this.subscriptionService.customerData.customerAccountsId
		)
			.replace('{serviceId}', serviceId ?? this.subscriptionService.customerData.currentService.id)
			.replace('{bundleType}', balanceType)
			.replace('{group}', group.toString());

		return this.http.get(url).pipe(
			map((response: any) => {
				this.cachedBundlesLogic(
					response,
					serviceId ?? this.subscriptionService.customerData.currentService.id,
					balanceType,
					group
				);
				return response;
			}),
			catchError((error) => {
				return throwError(error);
			})
		);
	}

	getEntryPoints(maxnumber?) {
		let url = API_URLS['Dashboard-WS10'].EntryPoints;
		const options = this.headersData();
		url = url
			.replace('{screenCode}', 'DSH')
			.replace('{customerAccountId}', this.subscriptionService.customerData.customerAccountsId)
			.replace('{serviceId}', this.subscriptionService.customerData.currentService.id)
			.replace('{channel.id}', CommercialJourneys.entryPointsRequest.channel)
			.replace('{maxnumber}', maxnumber);
		return this.http.get(url, options).pipe(
			map((res: any) => {
				return res;
			})
		);
	}

	private headersData() {
		const headers = new HttpHeaders();
		const options = {
			headers: headers,
			withCredentials: true,
		};
		return options;
	}

	patchDataSharing(_id: any, _data): Observable<any> {
		const url = API_URLS['Dashboard-WS10'].Sharing.replace('{id}', _id);
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');
		const _options = {
			headers: headers,
		};

		return this.http.patch(url, _data, _options).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((error) => {
				return throwError(error);
			})
		);
	}

	getAmountPrepaid(value?) {
		let amountValue = 0;

		if (value && value.length > 0) {
			value.forEach((element) => {
				amountValue += parseFloat(element.amount.amount);
			});

			this.valueAmountPrepaid = amountValue.toFixed(2).toString().replace('.', ',');
		} else {
			this.valueAmountPrepaid = defines.amountZero;
		}
	}

	thousandNumber(item) {
		if (parseFloat(item)) {
			const number = item
				.toString()
				.split('')
				.reverse()
				.join('')
				.replace(/(?=\d*\.?)(\d{3})/g, '$1.');
			const num = number.split('').reverse().join('').replace(/^[\.]/, '');
			return num;
		} else {
			return item;
		}
	}

	private cachedBundlesLogic(res: any, serviceId: string, balanceType: string, group: boolean) {
		if (res && res.items && res.items.length) {
			if (balanceType === serviceBalanceType.inPlan && !group) {
				this.bundlesInPlan[serviceId] = this.bundlesLogic(res.items);
				this.bundlesInPlanAndGroup.push({ serviceId, bundles: this.bundlesLogic(res.items) });
			}
		}
	}

	private bundlesLogic(items: any[]): Dashboard[] {
		const bundles: Dashboard[] = [];
		items.forEach((item) => {
			const bundle = new Dashboard();
			bundle.code = JsonQuery.value(item, JSON_PATHS.Dashboard.ServiceBalance.code) || null;
			bundle.type = JsonQuery.value(item, JSON_PATHS.Dashboard.ServiceBalance.type) || null;
			bundle.category = JsonQuery.value(item, JSON_PATHS.Dashboard.ServiceBalance.category) || null;
			bundle.group = JsonQuery.value(item, JSON_PATHS.Dashboard.ServiceBalance.group) || null;
			bundle.name = JsonQuery.value(item, JSON_PATHS.Dashboard.ServiceBalance.name) || null;
			bundle.shortDescription = JsonQuery.value(item, JSON_PATHS.Dashboard.ServiceBalance.shortDescription) || null;
			const dates = item[JSON_PATHS.Dashboard.ServiceBalance.validity_period];
			bundle.expirationDate = dates ? dates[JSON_PATHS.Dashboard.ServiceBalance.expirationDate] : null;
			bundle.activationDate = JsonQuery.value(item, JSON_PATHS.Dashboard.ServiceBalance.activationDate) || null;
			const amount = JsonQuery.value(item, JSON_PATHS.Dashboard.ServiceBalance.amount);
			bundle.amount = parseFloat(amount ? amount : 0);
			bundle.currency = JsonQuery.value(item, JSON_PATHS.Dashboard.ServiceBalance.currency) || null;
			bundle.basicEnjoyMore = JsonQuery.value(item, JSON_PATHS.Dashboard.ServiceBalance.basicEnjoyMore) || null;
			bundle.productType = JsonQuery.value(item, JSON_PATHS.Dashboard.ServiceBalance.productType) || null;
			const allowances = JsonQuery.value(item, JSON_PATHS.Dashboard.ServiceBalance.allowance) || null;
			if (allowances) {
				bundle.available_allowance =
					parseFloat(allowances[JSON_PATHS.Dashboard.ServiceBalance.available_allowance]) || 0;
				bundle.available_allowance_unit_code =
					allowances[JSON_PATHS.Dashboard.ServiceBalance.available_allowance_unit_code] || null;
				bundle.used_allowance = parseFloat(allowances[JSON_PATHS.Dashboard.ServiceBalance.used_allowance]) || 0;
				bundle.used_allowance_unit_code =
					allowances[JSON_PATHS.Dashboard.ServiceBalance.used_allowance_unit_code] || null;
				bundle.remaining_allawonce =
					parseFloat(allowances[JSON_PATHS.Dashboard.ServiceBalance.remaining_allawonce]) || 0;
				bundle.remaining_allowance_unit_code =
					allowances[JSON_PATHS.Dashboard.ServiceBalance.used_allowance_unit_code] || null;
				bundle.isLimited = !(allowances[JSON_PATHS.Dashboard.ServiceBalance.isLimited] || null);
			}
			bundles.push(bundle);
		});
		return bundles;
	}

	/** method to recall tariff & service balance apis */
	recallTariffAndServiceBalnce(): void {
		this.productsAndServicesService.spinner = true;
		this.tariffService.getTariff().subscribe(
			() => {
				this.getServiceBalance(constants.BundleType.inPlan, false).subscribe(
					(result) => {
						this.customerBillService.getAmountPrepaid(result.items);
						this.tariffAndSeriveBalanceisLoaded = true;
						this.productsAndServicesService.spinner = false;
						this.refreshTarifAndServiceBalnceApi = false;
					},
					() => {
						this.tariffAndSeriveBalanceisLoaded = false;
						this.productsAndServicesService.spinner = false;
						this.refreshTarifAndServiceBalnceApi = false;
					}
				);
			},
			() => {
				this.tariffAndSeriveBalanceisLoaded = false;
				this.productsAndServicesService.spinner = false;
				this.refreshTarifAndServiceBalnceApi = false;
			}
		);
	}

	recallSubscriptionAndTariffAndServiceBalnce(): void {
		this.productsAndServicesService.spinner = true;
		this.subscriptionService
			.GetDefaultServicesWithoutCache(this.subscriptionService.customerData.currentService.id)
			.subscribe(
				() => {
					this.recallTariffAndServiceBalnce();
					this.refreshSubscriptionAndTarifAndServiceBalanceAPIs = false;
				},
				() => {
					this.refreshSubscriptionAndTarifAndServiceBalanceAPIs = false;
				}
			);
	}
}
