import { AppService } from 'src/app/app.service';
import { AppThemes, smartPayTagging } from '../../constants/defines';
import { Component, OnInit } from '@angular/core';
import { Breakpoints } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import * as routes from '../../../../config/pages-config';

import { ConfigurationService } from './../../../core/services/configuration.service';
import { TariffService } from './../../services/tariff.service';
import { SubscriptionService } from '../../../core/services/subscription.service';
import { TaggingViewModel } from './../../../models/tagging.model';
import { NewTaggingHelperService } from 'src/app/core/services/new-tagging.helper.service';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';

@Component({
	selector: 'sp-smartpay-landing',
	templateUrl: './smartpay-landing.component.html',
	styleUrls: ['./smartpay-landing.component.scss'],
})
export class SmartpayLandingComponent implements OnInit {
	public smartpayLandingLoaded: boolean;
	public loaded: boolean;

	public isMobileView: boolean;
	public isOverflowing: boolean;
	public dummyHeight: number;
	public buttonCardMinWidth: number;

	public isEligible: boolean;
	public benefitStatusActive: boolean;
	public smartPayFlag: boolean;
	public smartPayMSIDNPatternMatch: boolean;
	public openFaucet: boolean;
	public previousUrl: string;
	public origin: string;

	public smartPayLandingImage: string;
	public smartPayLandingImageRsp: string;
	public smartPayLandingHeaderText: string;
	public smartPayLandingTitle: string;
	public smartPayLandingPaymentText: string;
	public smartPayLandingSavingText: string;
	public smartPayLandingAllRewardsText: string;
	public smartPayLandingExtrasText: string;

	public incrementer: number;
	public isPattern: boolean;
	public msisdnNumber: number;

	public pattern: string;
	public msisdn: string;

	public smartPayLandingButton: string;
	public smartPayLandingDescription: string;

	public smartPayReplicaMode: boolean;

	public smartPayNoBenefits: boolean;
	public smartPayLandingButtonNoBenefits: string;
	public smartPayLandingDescriptionNoBenefits: string;

	public smartPayComingSoon: boolean;
	public smartPayLandingButtonComingSoon: string;
	public smartPayLandingDescriptionComingSoon: string;

	public smartPayBenefitsInactive: boolean;
	public smartPayLandingButtonBenefitsInactive: string;
	public smartPayLandingDescriptionBenefitsInactive: string;
	public showPaymentFullDinamicTray: boolean;

	public smartPayMoreInfo: boolean = true;
	public smartPayLandingButtonMoreInfo: string;

	public page: TaggingViewModel;
	public data: TaggingViewModel;

	constructor(
		public appService: AppService,
		private newTagging: NewTaggingHelperService,
		private translate: TranslateService,
		private location: Location,
		public configurationService: ConfigurationService,
		public subscription: SubscriptionService,
		private subscriptionData: SubscriptionService,
		public tariffService: TariffService,
		private activeRoute: ActivatedRoute,
		public router: Router
	) {
		this.appService.theme = AppThemes.ThemeCommercial;
		this.appService.showFullAppLoader = false;
		this.appService.showTopBar = false;
	}
	ngOnInit(): void {
		this.appService.hiddenHeader = true;
		this.isMobileView = window.innerWidth < Breakpoints.TABLET;
		this.activeRoute.queryParams.subscribe((params) => {
			this.origin = params.origen;
			this.origin === 'PyS' ? (this.smartPayMoreInfo = false) : (this.smartPayMoreInfo = true);
		});
		this.checkFullStatus();
		this.getWCSData();
		this.assignContent();
		this.moreInfoScreenTagging();
	}

	moreInfoScreenTagging(): void {
		const stateKey: string = this.smartPayFlag
			? 'pago_facil_active_more_info_screen'
			: 'pago_facil_inactive_more_info_screen';
		this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
			this.newTagging.state(data.page['stateList'][stateKey]);
		});
	}

	ngDoCheck(): void {
		const smartpayLandingPage: HTMLElement = document.getElementsByClassName('smartpay-landing')[0] as HTMLElement;
		if (smartpayLandingPage) {
			const smartpayLandingCoordinates: any = smartpayLandingPage.getBoundingClientRect();
			const buttonCard: HTMLElement = document.getElementsByClassName(
				'smartpay-landing__button-card'
			)[0] as HTMLElement;
			const mainCard: HTMLElement = document.getElementsByClassName('smartpay-landing__card')[0] as HTMLElement;
			const buttonCardCoordinates: any = buttonCard.getBoundingClientRect();
			const mainCardCoordinates: any = mainCard.getBoundingClientRect();
			this.dummyHeight = buttonCardCoordinates.height;
			this.buttonCardMinWidth = mainCardCoordinates.width + 64;
			if (smartpayLandingCoordinates.height > window.innerHeight) {
				this.isOverflowing = true;
			} else {
				this.isOverflowing = false;
			}
		}
	}

	ngOnDestroy(): void {
		this.appService.showTopBar = true;
		this.appService.hiddenHeader = false;
	}

	private checkFullStatus(): void {
		this.msisdn = this.subscriptionData.customerData?.currentService?.id;
		this.pattern = this.configurationService.configuration?.smartPayMSIDNPattern;
		this.subscription.smartPay === true ? (this.smartPayFlag = true) : (this.smartPayFlag = false);
		this.tariffService?.Tariff?.hasBenifits === true ? (this.isEligible = true) : (this.isEligible = false);
		this.isEligible === false ? (this.smartPayNoBenefits = true) : (this.smartPayNoBenefits = false);
		this.isEligible === true && this.smartPayFlag === false && !this.meetsPattern(this.msisdn, this.pattern)
			? (this.smartPayComingSoon = true)
			: (this.smartPayComingSoon = false);
		this.isEligible === true && this.meetsPattern(this.msisdn, this.pattern) === true
			? (this.smartPayBenefitsInactive = true)
			: (this.smartPayBenefitsInactive = false);
	}

	public meetsPattern(msisdn: string, pattern: string): boolean {
		const subPatternList: Array<string> = pattern.split(',');
		subPatternList.map((res) => {
			if (msisdn.substr(msisdn.length - res.length) === res) {
				this.isPattern = true;
			}
		});
		return this.isPattern;
	}

	private getWCSData(): void {
		this.getBulletsDataFromWCS();
		this.translate.get('v10.productsServices.smartPay.PyS.noBenefits').subscribe((data) => {
			this.smartPayLandingImage = this.isMobileView
				? this.appService.getImgFullPath(data.image)
				: this.appService.getImgFullPath(data.image_rsp);
			this.smartPayLandingButtonNoBenefits = data.button;
			this.smartPayLandingDescriptionNoBenefits = data.title;
		});
		this.translate.get('v10.productsServices.smartPay.PyS').subscribe((data) => {
			this.smartPayLandingTitle = data.benefits_spInact.title;
			this.smartPayLandingButtonComingSoon = data.benefits_spInact.button;
			this.smartPayLandingDescriptionComingSoon = data.benefits.comingSoon.hintText;
			this.smartPayLandingButtonMoreInfo = data.benefits_spInact.button;
		});
		this.translate.get('v10.productsServices.smartPay.PyS.benefits_inactive').subscribe((data) => {
			this.smartPayLandingButtonBenefitsInactive = data.button;
			this.smartPayLandingDescriptionBenefitsInactive = data.title;
		});
		this.translate.get('v10.productsServices.smartPay.common.easypay').subscribe((data) => {
			this.smartPayLandingHeaderText = data;
		});
	}

	getBulletsDataFromWCS(): void {
		this.translate.get('v10.productsServices.smartPay.PyS.benefits').subscribe((data) => {
			if (this.smartPayMoreInfo) {
				this.smartPayLandingPaymentText = data.text1;
				this.smartPayLandingSavingText = data.text2;
				this.smartPayLandingAllRewardsText = data.text3;
				this.smartPayLandingExtrasText = data.text4;
			} else {
				this.smartPayLandingPaymentText = data.comingSoon.text1;
				this.smartPayLandingSavingText = data.comingSoon.text2;
				this.smartPayLandingAllRewardsText = data.comingSoon.text3;
			}
		});
	}

	private assignContent(): void {
		if (this.smartPayNoBenefits === true) {
			this.smartPayLandingButton = this.smartPayLandingButtonNoBenefits;
			this.smartPayLandingDescription = this.smartPayLandingDescriptionNoBenefits;
		}
		if (this.smartPayComingSoon === true) {
			this.smartPayLandingButton = this.smartPayLandingButtonComingSoon;
			this.smartPayLandingDescription = this.smartPayLandingDescriptionComingSoon;
		}
		if (this.smartPayBenefitsInactive === true) {
			this.smartPayLandingButton = this.smartPayLandingButtonBenefitsInactive;
			this.smartPayLandingDescription = this.smartPayLandingDescriptionBenefitsInactive;
		}
		if (
			(!this.smartPayNoBenefits && !this.smartPayComingSoon && !this.smartPayBenefitsInactive) ||
			this.smartPayFlag === true ||
			this.smartPayMoreInfo === true
		) {
			this.smartPayLandingButton = this.smartPayLandingButtonMoreInfo;
			this.smartPayLandingDescription = '';
		}
		this.loaded = true;
	}
	smartPayButtonTagging(): void {
		const interactionKey: string = this.smartPayFlag
			? smartPayTagging.pago_facil_active_mas_Info_screen_entendido_click
			: smartPayTagging.pago_facil_inactive_mas_info_screen_entendido_click;
		this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
			this.newTagging.interaction(data.eventList[interactionKey], data.page['stateList']['products_service']);
		});
	}

	smartPayButtonFacilUserScreenTagging(interactionKey: string): void {
		this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
			this.newTagging.interaction(data.eventList[interactionKey], data.page['stateList']['facil_user_screen']);
		});
	}
	smartPayButtonOnClick(): void {
		this.smartPayButtonTagging();
		if (this.smartPayBenefitsInactive === true && this.smartPayMoreInfo === false) {
			this.router.navigate([routes.config.ProductsAndServices.landing.route]);
			this.smartPayButtonFacilUserScreenTagging(smartPayTagging.pago_facil_tariff_inActive_renovar_tarifa_click);
		} else if (this.smartPayNoBenefits === true && this.smartPayComingSoon === false) {
			this.router.navigate([routes.config.TariffChange.route]);
			this.smartPayButtonFacilUserScreenTagging(smartPayTagging.pago_facil_facil_user_cambiar_tarifa_click);
		} else {
			this.location.back();
		}
	}

	goBackTagging(): void {
		const interactionKey: string = this.smartPayFlag
			? smartPayTagging.pago_facil_active_mas_Info_screen_X_click
			: smartPayTagging.pago_facil_inactive_mas_info_screen_X_click;
		const stateKey: string = this.smartPayFlag
			? smartPayTagging.pago_facil_active_more_info_screen
			: smartPayTagging.pago_facil_inactive_more_info_screen;
		this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
			this.newTagging.interaction(data.eventList[interactionKey], data.page['stateList'][stateKey]);
		});
	}
	public goBack(): void {
		this.goBackTagging();
		this.location.back();
	}
}
