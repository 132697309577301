import { Injectable } from '@angular/core';
import { API_URLS } from '../constants/routes-config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { PaymentCardPatchModel } from '../../models/payment-card.model';
import { Observable } from 'rxjs';
import { LOCAL_STORAGE_KEYS } from 'src/app/shared/constants/defines';
import { StorageService } from '../../core/services/storage.service';

@Injectable({ providedIn: 'root' })
export class PaymentCardsDataService {
	/** Extra parameters from net plus response, to be send with saving creadit request */
	constructor(private http: HttpClient, private storageService: StorageService) {}

	getPaymentCardsByDocumentId(documentId: string): Observable<Object> {
		const url: string = API_URLS.PaymentCards.Get.replace('{documentId}', documentId);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		const options: any = {
			headers: headers,
		};
		return this.http.get(url, options);
	}

	patchPaymentCard(
		documentId: string,
		body: PaymentCardPatchModel,
		txId_COF?: string,
		pagoRecurrente?: string
	): Observable<Object> {
		const url: string = API_URLS.PaymentCards.Get.replace('{documentId}', documentId);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');
		const options: any = {
			headers: headers,
		};
		/** Append recieved extra parameters (if exists) to body request */
		const newBody: any = { ...body, txId_COF, pagoRecurrente };
		return this.http.patch(url, newBody, options);
	}

	deletePaymentCard(documentId: string, uuid: string): Observable<any> {
		const url: string = API_URLS.PaymentCards.Delete.replace('{documentId}', documentId).replace(
			'{payMethodUUId}',
			uuid
		);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');
		const options: any = {
			headers: headers,
			responseType: 'text' as 'json',
		};
		return this.http.delete(url, options);
	}
}
