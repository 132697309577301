import { Component } from '@angular/core';
import { PaymentJourny } from 'src/app/shared/enums/payment-journy.enum';
import { TopUpService } from 'src/app/top-up/top-up.service';
import { centInEuro } from 'src/app/shared/constants/defines';
import { SmartPayService } from 'src/app/smart-pay/services/smart-pay.service';

@Component({
	selector: 'sp-payment-save-credit-card',
	templateUrl: './payment-save-credit-card.component.html',
	styleUrls: ['./payment-save-credit-card.component.scss'],
})
export class PaymentSaveCreditCardComponent {
	constructor(private topupService: TopUpService, private smartPayService: SmartPayService) {}

	navigateToIframe(): void {
		this.smartPayService.updateCardWithDefault = true;
		this.topupService.openPaymentModule(centInEuro, PaymentJourny.saveCreditCard, '', '', true);
	}
}
