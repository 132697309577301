import { eventCategory } from '../app/models/new-tagging-Interaction.model';
import { ERRORCODES } from '../app/shared/constants/defines';

export let tagging: any = {
	stringReplacement: [
		{
			regex: /\+/g,
			replacement: 'mas',
		},
		{
			regex: /[^(0-9a-zA-Z- :\\-_,.=|)]/gi,
			replacement: '',
		},
		{
			regex: /^\s+/g,
			replacement: '',
		},
		{ regex: /\s\s+/g, replacement: ' ' },
	],
	replacementExceptionKeys: [
		'payment_id',
		'error_list',
		'page_original_referring',
		'page_url',
		'&&events',
		'&&products',
		'plan_commitment',
		'entrypoint_pega',
		'entrypoint_name',
		'entrypoint_label',
		'entrypoint_issue',
		'entrypoint_groupnba',
	],
	webview: {
		page_name: 'webview',
		page_section: 'webview',
		page_subcategory_level_1: '',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'webview',
		page_typology: 'overlay',
	},
	webviewAyuda: {
		page_name: 'webview:ayuda',
		page_section: 'webview',
		page_subcategory_level_1: 'ayuda',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'ayuda',
		page_typology: 'overlay',
	},
	transferOfAddress: {
		page_name: 'mi cuenta:tus datos:editar direccion',
		page_section: 'mi cuenta',
		page_subcategory_level_1: 'tus datos',
		page_subcategory_level_2: 'editar direccion',
		page_subcategory_level_3: '',
		page_screen: 'editar direccion',
		page_typology: 'overlay',
	},
	outOfBundleConsumption: {
		page_name: 'consumo:mi consumo:detalle de consumo fuera de factura',
		page_section: 'consumo',
		page_subcategory_level_1: 'mi consumo',
		page_subcategory_level_2: 'detalle de consumo fuera de factura',
		page_subcategory_level_3: '',
		page_screen: 'detalle de consumo fuera de factura',
		page_typology: 'overlay',
	},
	noBillsTutorial: {
		page_name: '',
		page_section: 'facturas',
		page_subcategory_level_1: 'mis facturas',
		page_subcategory_level_2: 'tutorial de factura',
		page_subcategory_level_3: '',
		page_screen: '',
		page_typology: 'overlay',
	},
	userBlocked: {
		page_name: 'login:cuenta bloqueada',
		page_section: 'login',
		page_subcategory_level_1: 'cuenta bloqueada',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'cuenta bloqueada',
		page_typology: 'overlay',
	},
	vfPassLandingActiveBenefits: {
		page_name: 'recargas:vodafone pass:beneficios activos',
		page_section: 'recargas',
		page_subcategory_level_1: 'vodafone pass',
		page_subcategory_level_2: 'beneficios activos',
		page_subcategory_level_3: '',
		page_screen: 'beneficios activos',
		page_typology: 'principal',
	},
	vfPassLandingInActiveBenefits: {
		page_name: 'recargas:vodafone pass:beneficios no activos',
		page_section: 'recargas',
		page_subcategory_level_1: 'vodafone pass',
		page_subcategory_level_2: 'beneficios no activos',
		page_subcategory_level_3: '',
		page_screen: 'beneficios no activos',
		page_typology: 'principal',
	},
	launcherTrayVodafoneExtras: {
		page_name: 'dashboard:home:extras',
		page_section: 'dashboard',
		page_subcategory_level_1: 'home',
		page_subcategory_level_2: 'extras',
		page_subcategory_level_3: '',
		page_screen: 'extras',
		page_typology: 'overlay',
	},
	userIncorrect: {
		page_name: 'login:usuario incorrecto',
		page_section: 'login',
		page_subcategory_level_1: 'usuario incorrecto',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'usuario incorrecto',
		page_typology: 'overlay',
	},
	passwordIncorrect: {
		page_name: 'login:clave incorrecta',
		page_section: 'login',
		page_subcategory_level_1: 'clave incorrecta',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'clave incorrecta',
		page_typology: 'overlay',
	},
	upgradeStepTwo: {
		page_name: 'upgrade:verifica tu cuenta',
		page_section: 'upgrade',
		page_subcategory_level_1: 'verifica tu cuenta',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'verifica tu cuenta',
		page_typology: 'overlay',
	},
	upgradeStepOne: {
		page_name: 'upgrade:verifica tu cuenta',
		page_section: 'upgrade',
		page_subcategory_level_1: 'verifica tu cuenta',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'verifica tu cuenta',
		page_typology: 'overlay',
	},
	registerPage: {
		page_name: 'registro:bienvenido',
		page_section: 'registro',
		page_subcategory_level_1: 'bienvenido',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'bienvenido',
		page_typology: 'overlay',
	},
	bankInfoSmsVerfication: {
		page_name: 'mi cuenta:metodos de pago:editar datos bancarios:verificación de sms',
		page_section: 'mi cuenta',
		page_subcategory_level_1: 'metodos de pago',
		page_subcategory_level_2: 'editar datos bancarios',
		page_subcategory_level_3: 'verificación de sms',
		page_screen: 'verificación de sms',
		page_typology: 'overlay',
	},
	editPaymentAddressSmsVerfication: {
		page_name: 'mi cuenta:metodos de pago:editar dirección de facturación:verificación de sms',
		page_section: 'mi cuenta',
		page_subcategory_level_1: 'metodos de pago',
		page_subcategory_level_2: 'editar dirección de facturación',
		page_subcategory_level_3: 'verificación de sms',
		page_screen: 'verificación de sms',
		page_typology: 'overlay',
	},
	faq: {
		page_name: 'FAQ',
		page_section: '',
		page_subcategory_level_1: '',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'FAQ',
		page_typology: 'overlay',
	},
	serviceSelectorPage: {
		page_name: 'dashboard:selector de servicios',
		page_section: 'dashboard',
		page_subcategory_level_1: 'selector de servicios',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'selector de servicios',
		page_typology: 'overlay',
	},
	gdprOverlayPage: {
		page_name: 'permisos:gdpr',
		page_section: 'permisos',
		page_subcategory_level_1: 'gdpr',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'gdpr',
		page_typology: 'overlay',
	},
	gdprMoreInfoPage: {
		page_name: 'permisos:gdpr:mas informacion',
		page_section: 'permisos',
		page_subcategory_level_1: 'gdpr',
		page_subcategory_level_2: 'mas informacion',
		page_subcategory_level_3: '',
		page_screen: 'mas informacion',
		page_typology: 'principal',
	},
	permissions: {
		page_name: 'mi cuenta:permisos y preferencias:permisos',
		page_section: 'mi cuenta',
		page_subcategory_level_1: 'permisos y preferencias',
		page_subcategory_level_2: 'permisos',
		page_subcategory_level_3: '',
		page_screen: 'permisos',
		page_typology: 'principal',
	},
	permissionsFromGDPR: {
		page_name: 'permisos:gdpr:mas informacion:permisos',
		page_section: 'permisos',
		page_subcategory_level_1: 'gdpr',
		page_subcategory_level_2: 'mas informacion',
		page_subcategory_level_3: 'permisos',
		page_screen: 'permisos',
		page_typology: 'principal',
	},
	serviceSelectorPageLightUser: {
		page_name: 'dashboard:selector de servicios:verifica tu cuenta',
		page_section: 'dashboard',
		page_subcategory_level_1: 'selector de servicios',
		page_subcategory_level_2: 'verifica tu cuenta',
		page_subcategory_level_3: '',
		page_screen: 'verifica tu cuenta',
		page_typology: 'overlay',
	},
	consumptionPage: {
		page_name: 'consumo:mi consumo:detalle de consumo',
		page_section: 'consumo',
		page_subcategory_level_1: 'mi consumo',
		page_subcategory_level_2: 'detalle de consumo',
		page_subcategory_level_3: '',
		page_screen: 'detalle de consumo',
		page_typology: 'overlay',
	},
	editingBillingAlias: {
		page_name: 'facturas:mis facturas:resumen de cuentas:editar alias factura',
		page_section: 'facturas',
		page_subcategory_level_1: 'mis facturas',
		page_subcategory_level_2: 'resumen de cuentas',
		page_subcategory_level_3: 'editar alias factura',
		page_screen: 'editar alias factura',
		page_typology: 'overlay',
	},
	editingBillingAliasFromBillingDetails: {
		page_name: 'facturas:mis facturas:detalle de factura:editar alias factura',
		page_section: 'facturas',
		page_subcategory_level_1: 'mis facturas',
		page_subcategory_level_2: 'detalle de factura',
		page_subcategory_level_3: 'editar alias factura',
		page_screen: 'editar alias factura',
		page_typology: 'overlay',
	},
	consumptionDetailChangingMonthOverlay: {
		page_name: 'facturas:mis facturas:detalle de factura:detalle de consumo:cambiar mes',
		page_section: 'facturas',
		page_subcategory_level_1: 'mis facturas',
		page_subcategory_level_2: 'detalle de factura',
		page_subcategory_level_3: 'detalle de consumo',
		page_subcategory_level_4: 'cambiar mes',
		page_screen: 'cambiar mes',
		page_typology: 'overlay',
	},
	billDetailsAlarms: {
		page_name: 'facturas:mis facturas:detalle de factura:alarma:{0}',
		page_section: 'facturas',
		page_subcategory_level_1: 'mis facturas',
		page_subcategory_level_2: 'detalle de factura',
		page_subcategory_level_3: 'alarma',
		page_subcategory_level_4: '{0}',
		page_screen: '{0}',
		page_typology: 'overlay',
	},
	creditNoteAlarm: {
		page_name: 'facturas:mis facturas:detalle de factura:alarm:notas de abono',
		page_section: 'facturas',
		page_subcategory_level_1: 'mis facturas',
		page_subcategory_level_2: 'detalle de factura',
		page_subcategory_level_3: 'alarm',
		page_subcategory_level_4: 'notas de abono',
		page_screen: 'notas de abono',
		page_typology: 'principal',
	},
	creditNote: {
		page_name: 'facturas:mis facturas:detalle factura:notas abono',
		site_section: 'facturas',
		page_subcategory_level_1: 'mis facturas',
		page_subcategory_level_2: 'detalle factura',
		page_subcategory_level_3: 'notas abono',
		page_screen: 'notas abono',
		page_typology: 'principal',
		page_detail: 'notas abono',
	},
	deptPaymentStartPageName: {
		page_name: 'pagos:pago factura:deuda',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: 'deuda',
		page_subcategory_level_3: '',
		page_screen: 'deuda',
		page_typology: 'principal',
	},
	deptPaymentStartPartialPageName: {
		page_name: 'pagos:pago factura:deuda:cantidad pago',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: 'deuda',
		page_subcategory_level_3: 'cantidad pago',
		page_screen: 'cantidad pago',
		page_typology: 'principal',
	},
	deptPaymentStartJourney: {
		'journey_name': 'pago de factura',
		'journey_category': '',
		'journey_subcategory': 'postpago',
		'journey_type': 'servicio',
		'journey_process': 'pagar deuda',
		'journey_detail': 'start',
		'journey_step': 'pago de factura:start',
		'&&events': 'event27',
		'journey_environment': '',
	},
	deptPaymentStartPartialJourney: {
		journey_name: 'pago de factura',
		journey_category: '',
		journey_subcategory: 'postpago',
		journey_type: 'servicio',
		journey_process: 'pagar deuda',
		journey_payment_type: 'pago parcial',
		journey_detail: 'cantidad a pagar',
		journey_step: 'pago de factura:cantidad a pagar',
		journey_environment: '',
	},
	cashDeskPaymentStartPageName: {
		page_name: 'pagos:pago factura:actual',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: 'actual',
		page_subcategory_level_3: '',
		page_screen: 'actual',
		page_typology: 'principal',
	},
	anonymousPaymentDisclaimer: {
		page_name: 'pagos:identificacion:consulta deuda',
		page_section: 'pagos',
		page_subcategory_level_1: 'identificacion',
		page_subcategory_level_2: 'consulta deuda',
		page_subcategory_level_3: '',
		page_screen: 'consulta deuda',
		page_typology: 'principal',
	},
	anonymousIdentificationErrors: {
		error_list: 'et=funcional|ec=<netplus error code/dxl error code>|em=pago anonimo|ed=<error "mensaje">',
	},
	anonymousMisPagos: {
		page_name: 'pagos:mis pagos anonimo',
		page_section: 'pagos',
		page_subcategory_level_1: 'mis pagos anonimo',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'mis pagos anonimo',
		page_typology: 'principal',
		journey_name: '',
		journey_category: '',
		journey_subcategory: '',
		journey_type: '',
		journey_detail: '',
		journey_environment: 'publica',
		journey_step: '',
	},
	anonymousclarifyuser: {
		page_name: 'pagos:identificacion:sin acceso para clarify',
		page_section: 'pagos',
		page_subcategory_level_1: 'identificacion',
		page_subcategory_level_2: 'sin acceso para clarify',
		page_subcategory_level_3: '',
		page_screen: 'sin acceso para clarify',
		page_typology: 'principal',
	},
	anonymousKOVar: {
		'&&events': 'event103',
		'error_list': 'et=tecnico:otros|ec=<error_code>|em=<error_message>|ed=<error_desc>',
	},
	anonymousNoExisteDeudaVar: {
		'&&events': 'event103',
		'error_list': 'et=funcional|ec=<error_code>|em=<error_message>|ed=<error_desc>',
	},
	anonymousOtpErrors: {
		'&&events': 'event103',
		'error_list': 'et=funcional|ec=<error_code>|em=pago anonimo|ed=<error_desc>',
	},
	clarifyuser: {
		'&&events': 'event103',
		'error_list': 'et=funcional|ec=<error_code>|em=pago anonimo|ed=sin acceso para usuarios clarify',
	},
	cashDeskPaymentStartPartialPageName: {
		page_name: 'pagos:pago factura:actual:cantidad pago',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: 'actual',
		page_subcategory_level_3: 'cantidad pago',
		page_screen: 'cantidad pago',
		page_typology: 'principal',
	},
	cashDeskPaymentStartJourney: {
		'journey_name': 'pago de factura',
		'journey_category': '',
		'journey_subcategory': 'postpago',
		'journey_type': 'servicio',
		'journey_process': 'pagar factura actual',
		'journey_detail': 'start',
		'journey_step': 'pago de factura:start',
		'&&events': 'event27',
		'journey_environment': '',
	},
	cashDeskPaymentStartPartialJourney: {
		journey_name: 'pago de factura',
		journey_category: '',
		journey_subcategory: 'postpago',
		journey_type: 'servicio',
		journey_process: 'pagar factura actual',
		journey_payment_type: 'pago parcial',
		journey_detail: 'cantidad a pagar',
		journey_step: 'pago de factura:cantidad a pagar',
		journey_environment: '',
	},
	payInAdvancePaymentStartPageName: {
		page_name: 'pagos:pago factura:adelantado',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: 'adelantado',
		page_subcategory_level_3: '',
		page_screen: 'adelantado',
		page_typology: 'principal',
	},
	payInAdvancePaymentStartJourney: {
		'journey_name': 'pago de factura',
		'journey_category': '',
		'journey_subcategory': 'postpago',
		'journey_type': 'servicio',
		'journey_process': 'pagar factura por adelantado',
		'journey_detail': 'start',
		'journey_step': 'pago de factura:start',
		'&&events': 'event27',
		'journey_environment': '',
	},
	walletScreenPostpaidPageName: {
		page_name: 'pagos:pago factura:<payment_page_name>:seleccion tarjeta',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'seleccion tarjeta',
		page_screen: 'seleccion tarjeta',
		page_typology: 'overlay',
	},
	walletScreenCreditCardPageName: {
		page_name: 'transaccional:microflujos:<payment_page_name>:pasarela:pago tarjeta:wallet',
		page_section: 'transaccional',
		page_subcategory_level_1: 'microflujos',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'pasarela',
		page_subcategory_level_4: 'pago tarjeta',
		navigation_level_1: 'transaccional',
		navigation_level_2: 'microflujos',
		navigation_level_3: 'checkout',
		navigation_level_4: 'pasarela',
		navigation_level_5: 'wallet',
		page_screen: 'wallet',
	},
	confirmDeleteCardPostpaidPageName: {
		page_name: 'pagos:pago factura:<payment_page_name>:seleccion tarjeta:confirmar eliminar tarjeta',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'seleccion tarjeta',
		page_subcategory_level_4: 'confirmar eliminar tarjeta',
		page_screen: 'confirmar eliminar tarjeta',
		page_typology: 'overlay',
	},
	confirmDeleteCardCreditCardPageName: {
		page_name: 'transaccional:microflujos:<payment_page_name>:pasarela:pago tarjeta:wallet:confirmar eliminar tarjeta',
		page_section: 'transaccional',
		page_subcategory_level_1: 'microflujos',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'wallet',
		page_subcategory_level_4: 'confirmar eliminar tarjeta',
		page_screen: 'confirmar eliminar tarjeta',
		page_typology: 'overlay',
	},
	clickOnCancelDeleteCardPostPaidJourney: {
		journey_name: 'pago de factura',
		journey_category: '',
		journey_subcategory: 'postpago',
		journey_type: 'servicio',
		journey_process: '',
		journey_detail: 'cancelar eliminar tarjeta',
		journey_step: 'pago de factura:cancelar eliminar tarjeta',
	},
	clickOnCancelDeleteCardPostPaidButton: {
		eventName: 'pagos:pago factura:<payment_page_name>:seleccion tarjeta:confirmar eliminar tarjeta:cancelar',
		data: {
			event_name: 'cancelar',
			event_context: 'confirmar eliminar tarjeta',
			event_category: 'boton',
			event_label: 'cancelar',
		},
	},
	clickOnCancelDeleteCardCreditCardButton: {
		eventName: 'transaccional:microflujos:<payment_page_name>:seleccion tarjeta:confirmar eliminar tarjeta:cancelar',
		data: {
			event_name: 'cancelar',
			event_context: 'confirmar eliminar tarjeta',
			event_category: 'boton',
			event_label: 'cancelar',
		},
	},
	clickOnConfirmDeleteCardPostPaidButton: {
		eventName: 'pagos:pago factura:<payment_page_name>:seleccion tarjeta:confirmar eliminar tarjeta:eliminar',
		data: {
			event_name: 'eliminar',
			event_context: 'confirmar eliminar tarjeta',
			event_category: 'boton',
			event_label: 'eliminar tarjeta',
		},
	},
	clickOnConfirmDeleteCardCreditCardButton: {
		eventName: 'transaccional:microflujos:<payment_page_name>:seleccion tarjeta:confirmar eliminar tarjeta:eliminar',
		data: {
			event_name: 'eliminar',
			event_context: 'confirmar eliminar tarjeta',
			event_category: 'boton',
			event_label: 'eliminar tarjeta',
		},
	},
	clickOnDeleteCCPostPaid: {
		eventName: 'pagos:pago factura:<payment_page_name>:seleccion tarjeta:eliminar tarjeta',
		data: {
			event_name: 'eliminar tarjeta',
			event_context: 'seleccion tarjeta',
			event_category: 'link',
			event_label: 'eliminar',
		},
	},
	clickOnDeleteCCCreditCard: {
		eventName: 'pagos:pago factura:<payment_page_name>:seleccion tarjeta:eliminar tarjeta',
		data: {
			event_name: 'eliminar tarjeta',
			event_context: 'seleccion tarjeta',
			event_category: 'link',
			event_label: 'eliminar',
		},
	},
	downloadTicket: {
		eventName: 'pagos:pago factura:<page_name>:pago tarjeta:ticket de pago:guardar ticket',
		data: {
			event_name: 'guardar ticket',
			event_context: 'ticket de pago',
			event_category: 'boton',
			event_label: 'guardar en tus fotos',
		},
	},
	downloadTicketFinancedDevices: {
		eventName: 'pagos:dispositivos financiados:financiacion activa:pago tarjeta:ticket de pago:guardar ticket',
		data: {
			event_name: 'guardar ticket',
			event_context: 'ticket de pago',
			event_category: 'boton',
			event_label: 'guardar en tus fotos',
		},
	},
	showMoreBillsHistory: {
		eventName: 'pagos:mis pagos:mostrar mas',
		data: {
			event_name: 'mostrar mas',
			event_context: 'historico de pagos',
			event_category: 'link',
			event_label: 'mostrar mas',
		},
	},
	showBillAccountsDetailsScreen: {
		eventName: 'pagos:mis pagos:metodos de pago',
		data: {
			event_name: 'metodos de pago',
			event_context: 'mis pagos',
			event_category: 'link',
			event_label: 'metodos de pago',
		},
	},
	FianciacionActiva: {
		page_name: 'pagos:dispositivos financiados:financiacion activa:metodo pago',
		page_section: 'pagos',
		page_subcategory_level_1: 'dispositivos financiados',
		page_subcategory_level_2: 'financiacion activa',
		page_subcategory_level_3: 'metodo pago',
		page_screen: 'metodo de pago',
		page_typology: 'principal',
		journey_name: 'financiacion activa',
		journey_category: '',
		journey_subcategory: 'pospago',
		journey_type: 'servicio',
		journey_detail: 'metodo de pago',
		journey_step: 'financiacion activa:metodo de pago',
		journey_payment_type: 'pago completo',
		journey_payment_method: 'tarjeta de credito',
	},
	paymentCommitmentPending: {
		page_name: 'pagos:pago factura:promesa pago deuda pendiente',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: 'promesa pago deuda pendiente',
		page_screen: 'promesa pago deuda pendiente',
	},
	paymentCommitmentPaid: {
		page_name: 'pagos:pago factura:promesa pago deuda pendiente:pagada',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: 'promesa pago deuda pendiente',
		page_subcategory_level_3: 'pagada',
		page_screen: 'pagada',
	},
	paymentTotalCommitmentPendingPayEvent: {
		eventName: 'pagos:pago factura:promesa pago deuda pendiente:pagar todo',
		data: {
			event_name: 'pagar todo',
			page_name: 'pagos:pago factura:promesa pago deuda pendiente',
			event_context: 'pagos:promesa pago',
			event_category: 'boton',
			event_label: 'pagar todo',
		},
	},
	paymentCommitmentPayEvent: {
		eventName: 'pagos:pago factura:promesa pago deuda pendiente:pagar',
		data: {
			event_name: 'pagar',
			page_name: 'pagos:pago factura:promesa pago deuda pendiente',
			event_context: 'pagos:promesa pago',
			event_category: 'boton',
			event_label: 'pagar',
		},
	},
	paymentCommitmentPendientesFilter: {
		eventName: 'pagos:pago factura:promesa pago deuda pendiente:pendientes',
		data: {
			event_name: 'pendientes',
			page_name: 'pagos:pago factura:promesa pago deuda pendiente',
			event_context: 'pagos:promesa pago',
			event_category: 'filter',
			event_label: 'pendientes',
		},
	},
	paymentCommitmentCompletadosFilter: {
		eventName: 'pagos:pago factura:promesa pago deuda pendiente:completados',
		data: {
			event_name: 'completados',
			page_name: 'pagos:pago factura:promesa pago deuda pendiente',
			event_context: 'pagos:promesa pago',
			event_category: 'filter',
			event_label: 'completados',
		},
	},
	myAccountFromPagosPageName: {
		page_name: 'pagos:mis pagos:metodos de pago',
		page_section: 'pagos',
		page_subcategory_level_1: 'mis pagos',
		page_subcategory_level_2: 'metodos de pago',
		page_subcategory_level_3: '',
		page_screen: 'metodos de pago',
		page_typology: 'principal',
	},
	myAccountFromPagosPageEditCreditCard: {
		page_name: 'pagos:mis pagos:metodos de pago:tarjetas guardadas',
		page_section: 'pagos',
		page_subcategory_level_1: 'mis pagos',
		page_subcategory_level_2: 'metodos de pago',
		page_subcategory_level_3: 'tarjetas guardadas',
		page_screen: 'tarjetas guardadas',
		page_typology: 'principal',
	},
	myAccountFromPagosPageNameFromMyAccount: {
		page_name: 'pagos:mis pagos:mis metodos de pago',
		page_section: 'pagos',
		page_subcategory_level_1: 'mis pagos',
		page_subcategory_level_2: 'mis metodos de pago',
		page_subcategory_level_3: '',
		page_screen: 'mis metodos de pago',
		page_typology: 'principal',
	},
	editBankAccountFromPagosPageName: {
		page_name: 'pagos:mis pagos:metodos de pago:editar datos bancarios',
		page_section: 'pagos',
		page_subcategory_level_1: 'mis pagos',
		page_subcategory_level_2: 'metodos de pago',
		page_subcategory_level_3: 'editar datos bancarios',
		page_screen: 'editar datos bancarios',
		page_typology: 'principal',
	},
	editBankAccountPageName: {
		page_name: 'mi cuenta:metodos de pago:editar datos bancarios',
		page_section: 'mi cuenta',
		page_subcategory_level_1: 'metodos de pago',
		page_subcategory_level_2: 'editar datos bancarios',
		page_subcategory_level_3: '',
		page_screen: 'editar datos bancarios',
		page_typology: 'principal',
	},
	editBankAccountJourney: {
		'journey_name': 'editar datos de pago',
		'journey_category': '',
		'journey_subcategory': 'pospago',
		'journey_type': 'servicio',
		'journey_process': '',
		'journey_detail': 'start',
		'journey_step': 'editar datos de pago:start',
		'&&events': 'event27',
	},
	confirmSMSEditBankAccountJourney: {
		journey_name: 'editar datos de pago',
		journey_category: '',
		journey_subcategory: 'pospago',
		journey_type: 'servicio',
		journey_process: '',
		journey_detail: 'confirmacion sms',
		journey_step: 'editar datos de pago:confirmacion sms',
	},
	successEditBankAccountJourney: {
		'journey_name': 'editar datos de pago',
		'journey_category': '',
		'journey_subcategory': 'pospago',
		'journey_type': 'servicio',
		'journey_process': '',
		'journey_detail': 'ok',
		'journey_step': 'editar datos de pago:ok',
		'&&events': 'event28',
	},
	payWithCreditCardPageName: {
		page_name: 'pagos:pago factura:<payment_page_name>:pago tarjeta',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'pago tarjeta',
		page_screen: 'pago tarjeta',
		page_typology: 'principal',
	},
	payCreditCardCommercialPageName: {
		page_name: 'transaccional:microflujos:<payment_page_name>:pasarela:pago tarjeta',
		page_section: 'transaccional',
		page_subcategory_level_1: 'microflujos',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'pasarela',
		navigation_level_1: 'transaccional',
		navigation_level_2: 'microflujos',
		navigation_level_3: 'checkout',
		navigation_level_4: 'pasarela',
		navigation_level_5: 'pago tarjeta',
		page_screen: 'pago tarjeta',
	},
	payWithCreditCardFinancedDevicesPageName: {
		page_name: 'pagos:dispositivos financiados:financiacion activa:pago tarjeta',
		page_section: 'pagos',
		page_subcategory_level_1: 'dispositivos financiados',
		page_subcategory_level_2: 'financiacion activa',
		page_subcategory_level_3: 'pago tarjeta',
		page_screen: 'pago tarjeta',
		page_typology: 'principal',
	},
	prePaidGenericPaymentPageName: {
		page_name: 'prepago:recargas:<options>pago tarjeta',
		page_section: 'prepago',
		page_subcategory_level_1: 'recargas',
		page_subcategory_level_2: 'pago tarjeta',
		page_subcategory_level_3: '',
		page_screen: 'pago tarjeta',
		page_typology: 'principal',
	},
	prePaidGenericPaymentKOPageName: {
		page_name: 'prepago:recargas:<options>pago tarjeta:ko',
		page_section: 'prepago',
		page_subcategory_level_1: 'recargas',
		page_subcategory_level_2: 'pago tarjeta',
		page_subcategory_level_3: 'ko',
		page_screen: 'ko',
		page_typology: 'principal',
	},
	prePaidGenericPaymentDonePageName: {
		page_name: 'prepago:recargas:<options>pago tarjeta:ok',
		page_section: 'prepago',
		page_subcategory_level_1: 'recargas',
		page_subcategory_level_2: 'pago tarjeta',
		page_subcategory_level_3: 'ok',
		page_screen: 'ok',
		page_typology: 'principal',
	},
	cancelPaymentInCCPageName: {
		page_name: 'pagos:pago factura:<payment_page_name>:pago tarjeta:cancelar',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'pago tarjeta',
		page_subcategory_level_4: 'cancelar',
		page_screen: 'cancelar',
		page_typology: 'principal',
	},
	cancelPaymentInStartScreenPageName: {
		page_name: 'pagos:pago factura:<payment_page_name>:cancelar',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'cancelar',
		page_subcategory_level_4: '',
		page_screen: 'cancelar',
		page_typology: 'principal',
	},
	cancelPaymentInCClistPageName: {
		page_name: 'pagos:pago factura:<payment_page_name>:seleccion tarjeta:cancelar',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'seleccion tarjeta',
		page_subcategory_level_4: 'cancelar',
		page_screen: 'cancelar',
		page_typology: 'principal',
	},
	cancelPaymentBtnClickedInCCPageName: {
		page_name: 'pagos:pago factura:<payment_page_name>:pago tarjeta:cancelar:cancelado',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'pago tarjeta',
		page_subcategory_level_4: 'cancelar',
		page_screen: 'cancelado',
		page_typology: 'principal',
	},
	cancelPaymentBtnClickedInStartScreenPageName: {
		page_name: 'pagos:pago factura:<payment_page_name>:cancelar:cancelado',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'cancelar',
		page_subcategory_level_4: 'cancelado',
		page_screen: 'cancelado',
		page_typology: 'principal',
	},
	cancelPaymentBtnClickedInCClistPageName: {
		page_name: 'pagos:pago factura:<payment_page_name>:seleccion tarjeta:cancelar:cancelado',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'seleccion tarjeta',
		page_subcategory_level_4: 'cancelar',
		page_screen: 'cancelado',
		page_typology: 'principal',
	},
	paymentDonePageName: {
		page_name: 'pagos:pago factura:<payment_page_name>:pago tarjeta:ok',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'pago tarjeta',
		page_subcategory_level_4: 'ok',
		page_screen: 'ok',
		page_typology: 'principal',
	},
	paymentDoneCommercialPageName: {
		page_name: 'transaccional:microflujos:<payment_page_name>:pasarela:pago tarjeta:ok',
		page_section: 'transaccional',
		page_subcategory_level_1: 'microflujos',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'pasarela',
		page_subcategory_level_4: 'pago tarjeta',
		navigation_level_1: 'transaccional',
		navigation_level_2: 'microflujos',
		navigation_level_3: 'checkout',
		navigation_level_4: 'pasarela',
		navigation_level_5: 'pago tarjeta:ok',
		page_screen: 'pago tarjeta:ok',
	},
	paymentDoneFinancedDevicesPageName: {
		page_name: 'pagos:dispositivos financiados:financiacion activa:pago tarjeta:ok',
		page_section: 'pagos',
		page_subcategory_level_1: 'dispositivos financiados',
		page_subcategory_level_2: 'financiacion activa',
		page_subcategory_level_3: 'pago tarjeta',
		page_subcategory_level_4: 'ok',
		page_screen: 'ok',
		page_typology: 'principal',
	},
	genericPaymentSuccess: {
		page_name: '<payment_page_name>:pago tarjeta:ok',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'pago tarjeta',
		page_subcategory_level_4: 'ok',
		page_screen: 'ok',
		page_typology: 'principal',
	},
	paymentDetailsPageName: {
		page_name: 'pagos:pago factura:<payment_page_name>:pago tarjeta:ticket de pago',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'pago tarjeta',
		page_subcategory_level_4: 'ticket de pago',
		page_screen: 'ticket de pago',
		page_typology: 'principal',
	},
	paymentCreditCardDetailsPageName: {
		page_name: 'transaccional:microflujos:<payment_page_name>:pasarela:pago tarjeta:ticket',
		page_section: 'transaccional',
		page_subcategory_level_1: 'microflujos',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'pasarela',
		page_subcategory_level_4: 'pago tarjeta',
		navigation_level_1: 'transaccional',
		navigation_level_2: 'microflujos',
		navigation_level_3: 'checkout',
		navigation_level_4: 'pasarela',
		navigation_level_5: 'ticket',
		page_screen: 'ticket',
	},
	paymentDetailsFinancedDevicesPageName: {
		page_name: 'pagos:dispositivos financiados:financiacion activa:pago tarjeta:ticket de pago',
		page_section: 'pagos',
		page_subcategory_level_1: 'dispositivos financiados',
		page_subcategory_level_2: 'financiacion activa',
		page_subcategory_level_3: 'pago tarjeta',
		page_subcategory_level_4: 'ticket de pago',
		page_screen: 'ticket de pago',
		page_typology: 'principal',
	},
	inCorrectCreditPageName: {
		page_name: 'pagos:pago factura:<payment_page_name>:pago tarjeta:datos incorrectos',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'pago tarjeta',
		page_subcategory_level_4: 'datos incorrectos',
		page_screen: 'datos incorrectos',
		page_typology: 'principal',
	},
	paymentKOPageName: {
		page_name: 'pagos:pago factura:<payment_page_name>:pago tarjeta:ko',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'pago tarjeta',
		page_subcategory_level_4: 'ko',
		page_screen: 'ko',
		page_typology: 'principal',
	},
	paymentKOCommercialPageName: {
		page_name: 'transaccional:microflujos:<payment_page_name>:pasarela:pago tarjeta:ko',
		page_section: 'transaccional',
		page_subcategory_level_1: 'microflujos',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'pasarela',
		page_subcategory_level_4: 'pago tarjeta',
		navigation_level_1: 'transaccional',
		navigation_level_2: 'microflujos',
		navigation_level_3: 'checkout',
		navigation_level_4: 'pasarela',
		navigation_level_5: 'pago tarjeta:ko',
		page_screen: 'pago tarjeta:ko',
	},
	paymentFinancedDevicesKOPageName: {
		page_name: 'pagos:dispositivos financiados:financiacion activa:pago tarjeta:ko',
		page_section: 'pagos',
		page_subcategory_level_1: 'dispositivos financiados',
		page_subcategory_level_2: 'financiacion activa',
		page_subcategory_level_3: 'pago tarjeta',
		page_subcategory_level_4: 'ko',
		page_screen: 'ko',
		page_typology: 'principal',
	},
	systemUnavailablePageName: {
		page_name: 'pagos:pago factura:<payment_page_name>:pago tarjeta:ko',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'pago tarjeta',
		page_subcategory_level_4: 'ko',
		page_screen: 'ko',
		page_typology: 'principal',
	},
	invalidCreditPageName: {
		page_name: 'pagos:pago factura:<payment_page_name>:pago tarjeta:ko',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'pago tarjeta',
		page_subcategory_level_4: 'ko',
		page_screen: 'ko',
		page_typology: 'principal',
	},
	unsupportedCreditPageName: {
		page_name: 'pagos:pago factura:<payment_page_name>:pago tarjeta:ko',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'pago tarjeta',
		page_subcategory_level_4: 'ko',
		page_screen: 'ko',
		page_typology: 'principal',
	},
	maintenanceErrorPageName: {
		page_name: 'pagos:pago factura:<payment_page_name>:pago tarjeta:ko',
		page_section: 'pagos',
		page_subcategory_level_1: 'pago factura',
		page_subcategory_level_2: '<payment_page_name>',
		page_subcategory_level_3: 'pago tarjeta',
		page_subcategory_level_4: 'ko',
		page_screen: 'ko',
		page_typology: 'principal',
	},
	payWithCreditCardJourney: {
		journey_name: 'pago de factura',
		journey_category: '',
		journey_subcategory: 'postpago',
		journey_type: 'servicio',
		journey_process: '',
		journey_detail: 'pago con tarjeta',
		journey_step: 'pago de factura:pago con tarjeta',
		journey_card_selected: '',
	},
	cancelPaymentJourney: {
		journey_name: 'pago de factura',
		journey_category: '',
		journey_subcategory: 'postpago',
		journey_type: 'servicio',
		journey_process: '',
		journey_detail: 'solicitar cancelacion',
		journey_step: 'pago de factura:solicitar cancelacion',
		journey_card_selected: '',
	},
	cancelPaymentBtnClickedJourney: {
		journey_name: 'pago de factura',
		journey_category: '',
		journey_subcategory: 'postpago',
		journey_type: 'servicio',
		journey_process: '',
		journey_detail: 'cancelado',
		journey_step: 'pago de factura:cancelado',
		journey_card_selected: '',
	},
	paymentDoneJourney: {
		'journey_name': 'pago de factura',
		'journey_category': '',
		'journey_subcategory': 'postpago',
		'journey_type': 'servicio',
		'journey_process': '',
		'journey_detail': 'ok',
		'journey_step': 'pago de factura:ok',
		'&&events': 'event28',
		'journey_card_selected': '',
		'payment_id': '',
	},
	saveCreditCardJourney: {
		event_name: 'pagos:pago factura:<page_name>:pago tarjeta:ok:guardar tarjeta',
		data: {
			'event_name': 'guardar tarjeta',
			'event_context': 'pago tarjeta ok',
			'event_category': 'toggle',
			'event_label': 'guardar mis datos de tarjeta',
			'journey_name': 'pago de factura',
			'journey_category': '', // from cross variables
			'journey_subcategory': 'postpago',
			'journey_type': 'servicio',
			'journey_process': 'pagar factura actual',
			'journey_detail': 'ok',
			'journey_step': 'pago de factura:ok',
			'journey_card_selected': '', // <nueva|guardada>
			'payment_id': '', // transaction id
			'&&events': 'event<event_number>:<payment_id>', // 64 | 65
			'journey_environment': '',
		},
		pageJourney: {
			page_name: 'pagos:pago factura:<page_name>:pago tarjeta:ok',
			page_section: 'pagos',
			page_subcategory_level_1: 'pago factura',
			page_subcategory_level_2: '<page_name>',
			page_subcategory_level_3: 'pago tarjeta',
			page_subcategory_level_4: 'ok',
			page_screen: 'ok',
			page_typology: 'overlay',
		},
		toggle_active: '64',
		toggle_not_active: '65',
		newCreditCard: 'nueva',
		savedCreditCard: 'guardada',
	},
	saveCreditCardCommercialJourney: {
		event_name: 'transaccional:<page_name>:pago tarjeta:ok:guardar tarjeta',
		data: {
			'event_name': 'guardar tarjeta',
			'event_context': 'pago tarjeta ok',
			'event_category': 'toggle',
			'event_label': 'guardar mis datos de tarjeta',
			'journey_name': 'pago con tarjeta',
			'journey_category': '', // from cross variables
			'journey_subcategory': '',
			'journey_type': 'transaccional',
			'journey_process': 'pagar inicial',
			'journey_detail': 'ok',
			'journey_step': 'pago inicial:ok',
			'journey_card_selected': '', // <nueva|guardada>
			'payment_id': '', // transaction id
			'&&events': 'event<event_number>:<payment_id>', // 64 | 65
		},
		pageJourney: {
			page_name: 'transaccional:<page_name>:pago tarjeta:ok',
			page_section: 'transaccional',
			page_subcategory_level_1: 'pago inicial',
			page_subcategory_level_2: '<page_name>',
			page_subcategory_level_3: 'pago tarjeta',
			page_subcategory_level_4: 'ok',
			page_screen: 'ok',
			page_typology: 'overlay',
		},
		toggle_active: '64',
		toggle_not_active: '65',
		newCreditCard: 'nueva',
		savedCreditCard: 'guardada',
	},
	cerrarBtnClickSuccessPaymentOverlay: {
		event_name: 'pagos:pago factura:adelantado:pago tarjeta:ok:cerrar',
		data: {
			event_name: 'cerrar',
			event_context: 'pago tarjeta ok',
			event_category: 'boton',
			event_label: 'cerrar',
		},
		pageJourney: {
			page_name: 'pagos:pago factura:<page_name>:pago tarjeta:ok',
			page_section: 'pagos',
			page_subcategory_level_1: 'pago factura',
			page_subcategory_level_2: '<page_name>',
			page_subcategory_level_3: 'pago tarjeta',
			page_subcategory_level_4: 'ok',
			page_screen: 'ok',
			page_typology: 'overlay',
		},
	},
	cerrarBtnClickSuccessPaymentOverlayCommercial: {
		event_name: 'transaccional:checkout:pago tarjeta:ok:cerrar',
		data: {
			event_name: 'cerrar',
			event_context: 'pago tarjeta ok',
			event_category: 'boton',
			event_label: 'cerrar',
		},
		pageJourney: {
			page_name: 'transaccional:<page_name>:pago tarjeta:ok',
			page_section: 'transaccional',
			page_subcategory_level_1: 'checkout',
			page_subcategory_level_2: '<page_name>',
			page_subcategory_level_3: 'pago tarjeta',
			page_subcategory_level_4: 'ok',
			page_screen: 'ok',
			page_typology: 'overlay',
		},
	},
	closeBtnClickPaymentOverlayCommercial: {
		event_name: 'click en cerrar',
		data: {
			event_name: 'click en cerrar',
			event_context: 'pasarela',
			event_category: 'boton',
			event_label: 'cerrar',
		},
		pageJourney: {
			page_name: 'transaccional:microflujos:<page_name>:pasarela:pago tarjeta:click en cerrar',
			page_section: 'transaccional',
			page_subcategory_level_1: 'microflujos',
			page_subcategory_level_2: '<page_name>',
			page_subcategory_level_3: 'pasarela',
			navigation_level_1: 'transaccional',
			navigation_level_2: 'microflujos',
			navigation_level_3: 'checkout',
			navigation_level_4: 'pasarela',
			page_screen: 'pasarela',
		},
	},
	entendidoBtnClickSuccessPaymentOverlay: {
		event_name: 'pagos:pago factura:adelantado:pago tarjeta:ok:entendido',
		data: {
			event_name: 'entendido',
			event_context: 'pago tarjeta ok',
			event_category: 'boton',
			event_label: 'entendido',
		},
		pageJourney: {
			page_name: 'pagos:pago factura:<page_name>:pago tarjeta:ok',
			page_section: 'pagos',
			page_subcategory_level_1: 'pago factura',
			page_subcategory_level_2: '<page_name>',
			page_subcategory_level_3: 'pago tarjeta',
			page_subcategory_level_4: 'ok',
			page_screen: 'ok',
			page_typology: 'overlay',
		},
	},
	entendidoBtnClickSuccessCreditCardPaymentOverlay: {
		event_name: 'transaccional:checkout:pago tarjeta:ok:entendido',
		data: {
			event_name: 'entendido',
			event_context: 'pago tarjeta ok',
			event_category: 'boton',
			event_label: 'entendido',
		},
		pageJourney: {
			page_name: 'transaccional:<page_name>:pago tarjeta:ok',
			page_section: 'transaccional',
			page_subcategory_level_1: 'checkout',
			page_subcategory_level_2: '<page_name>',
			page_subcategory_level_3: 'pago tarjeta',
			page_subcategory_level_4: 'ok',
			page_screen: 'ok',
			page_typology: 'overlay',
		},
	},
	paymentDetailsJourney: {
		journey_name: 'pago de factura',
		journey_category: '',
		journey_subcategory: 'postpago',
		journey_type: 'servicio',
		journey_process: '',
		journey_detail: 'ticket de pago',
		journey_step: 'pago de factura:ticket de pago',
		journey_card_selected: '',
		payment_id: '',
	},
	paymentDetailsJourneyCreditCard: {
		journey_name: 'checkout',
		journey_category: '',
		journey_subcategory: '',
		journey_type: 'transaccional',
		journey_detail: 'pasarela',
		journey_step: 'checkout:pasarela',
		state_flow: 'checkout',
	},
	incorrectCreditJourney: {
		'journey_name': 'pago de factura',
		'journey_category': '',
		'journey_subcategory': 'postpago',
		'journey_type': 'servicio',
		'journey_process': '',
		'journey_detail': 'datos tarjeta incorrectos',
		'journey_step': 'pago de factura:datos tarjeta incorrectos',
		'error_list': '',
		'&&events': 'event102',
		'journey_card_selected': '',
	},
	PaymentKOJourney: {
		'journey_name': 'pago de factura',
		'journey_category': '',
		'journey_subcategory': 'postpago',
		'journey_type': 'servicio',
		'journey_process': '',
		'journey_detail': 'ko',
		'journey_step': 'pago de factura:ko',
		'error_list': '',
		'&&events': 'event103',
		'journey_card_selected': '',
	},
	maintenanceErrorJourney: {
		'journey_name': 'pago de factura',
		'journey_category': '',
		'journey_subcategory': 'postpago',
		'journey_type': 'servicio',
		'journey_process': '',
		'journey_detail': 'ko',
		'journey_step': 'pago de factura:ko',
		'error_list': '',
		'&&events': 'event101',
		'journey_card_selected': '',
	},
	cashDeskOpenOtherPaymentMethodsOverlay: {
		page_name: 'pagos:otros metodos de pago',
		page_section: 'pagos',
		page_subcategory_level_1: 'otros metodos de pago',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'otros metodos de pago',
		page_typology: 'principal',
	},
	newSiteSelectorTray: {
		openScreen: {
			eventName: 'pagos:mis pagos:otras direcciones',
			data: {
				event_name: 'otras direcciones',
				event_context: 'direccion facturacion',
				event_category: 'boton',
				event_label: 'otras direcciones',
			},
		},
		stateScreen: {
			page_name: 'pagos:mis pagos:selector direccion',
			page_section: 'pagos',
			page_subcategory_level_1: 'mis pagos',
			page_subcategory_level_2: 'selector direccion',
			page_subcategory_level_3: '',
			page_screen: 'selector direccion',
			page_typology: 'overlay',
		},
		track: {
			eventName: 'pagos:mis pagos:selector direccion:seleccionar direccion',
			data: {
				event_name: 'seleccionar dirección',
				event_context: 'selector direccion',
				event_category: 'link',
				event_label: 'direccion',
			},
		},
	},
	billDetailsHelpOverlay: {
		page_name: 'facturas:mis facturas:detalle de factura:concepto:{1}',
		page_section: 'facturas',
		page_subcategory_level_1: 'mis facturas',
		page_subcategory_level_2: 'detalle de factura',
		page_subcategory_level_3: 'concepto',
		page_subcategory_level_4: '{1}',
		page_screen: '{1}',
		page_typology: 'overlay',
	},
	billPaymentReceipt: {
		page_name: 'facturas:pago de facturas:confirmacion de pago',
		page_section: 'facturas',
		page_subcategory_level_1: 'pago de facturas',
		page_subcategory_level_2: 'justificante de pago',
		page_subcategory_level_3: '',
		page_screen: 'justificante de pago',
		page_typology: 'overlay',
	},
	averiaNotAvailable: {
		page_name: 'no disponibilidad:averias',
		page_section: 'no disponibilidad',
		page_subcategory_level_1: 'averias',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'averias',
		page_typology: 'overlay',
	},
	billingNotAvailable: {
		page_name: 'no disponibilidad:pago de factura',
		page_section: 'no disponibilidad',
		page_subcategory_level_1: 'pago de factura',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'pago de factura',
		page_typology: 'overlay',
	},
	billPayment3ds: {
		page_name: 'facturas:pago de facturas:confirmacion de pago:ok',
		page_section: 'facturas',
		page_subcategory_level_1: 'pago de facturas',
		page_subcategory_level_2: 'confirmacion de pago',
		page_subcategory_level_3: 'ok',
		page_screen: 'confirmacion de pago',
		page_typology: 'overlay',
	},
	billPayment3ds_GeneralError: {
		page_name: 'facturas:pago de facturas:confirmacion de pago:ko general',
		page_section: 'facturas',
		page_subcategory_level_1: 'pago de facturas',
		page_subcategory_level_2: 'confirmacion de pago',
		page_subcategory_level_3: 'ko general',
		page_screen: 'confirmacion de pago',
		page_typology: 'overlay',
	},
	billPayment3ds_InvalidCC: {
		page_name: 'facturas:pago de facturas:confirmacion de pago:ko tarjeta no valida',
		page_section: 'facturas',
		page_subcategory_level_1: 'pago de facturas',
		page_subcategory_level_2: 'confirmacion de pago',
		page_subcategory_level_3: 'ko tarjeta no valida',
		page_screen: 'confirmacion de pago',
		page_typology: 'overlay',
	},
	billPayment3ds_InvalidCCData: {
		page_name: 'facturas:pago de facturas:confirmacion de pago:ko datos de tarjeta incorrectos',
		page_section: 'facturas',
		page_subcategory_level_1: 'pago de facturas',
		page_subcategory_level_2: 'confirmacion de pago',
		page_subcategory_level_3: 'ko datos de tarjeta incorrectos',
		page_screen: 'confirmacion de pago',
		page_typology: 'overlay',
	},
	enjoyMorePreconfirmDeactivateBundle: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'vodafone pass',
		page_subcategory_level_2: '',
		page_subcategory_level_3: 'desactivar bono',
		page_screen: 'desactivar bono',
		page_typology: 'overlay',
	},
	enjoyMorePreconfirmBuyBundle: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'vodafone pass',
		page_subcategory_level_2: '',
		page_subcategory_level_3: 'activar bono',
		page_screen: 'activar bono',
		page_typology: 'overlay',
	},
	extraPreconfirmDeactivateBundle: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'extras',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_subcategory_level_4: 'desactivar bono',
		page_screen: 'desactivar bono',
		page_typology: 'overlay',
	},
	extraPreconfirmBuyBundle: {
		'page_name': '',
		'page_section': 'productos y servicios',
		'page_subcategory_level_1': 'extras',
		'page_subcategory_level_2': '',
		'page_subcategory_level_3': '',
		'page_subcategory_level_4': 'activar bono',
		'page_screen': 'activar bono',
		'page_typology': 'overlay',
		'journey_name': 'gestion de bonos:preconfirmacion',
		'journey_options': 'bono ilimitado',
		'journey_type': 'compra',
		'&&events': 'event16',
	},
	extraConfirmBuyBundle: {
		data: {
			page_name: 'productos y servicios:extras:extras de datos:{bond}:activar bono',
			event_name: 'click en {button}',
			page_section: 'productos y servicios',
			page_subcategory_level_1: 'extras',
			page_subcategory_level_2: 'extras de datos',
			page_subcategory_level_3: '',
			page_subcategory_level_4: 'activar bono',
			page_screen: 'activar bono',
			page_typology: 'overlay',
			journey_name: 'gestion de bonos:{final}',
			journey_options: 'bono ilimitado',
			journey_type: 'compra',
		},
		ko: 'ko',
		ok: 'ok',
	},
	onlineTvDetails: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'extras',
		page_subcategory_level_2: 'entretenimiento',
		page_subcategory_level_3: '',
		page_screen: '',
		page_typology: 'principal',
	},
	enjoyMoreDetails: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'vodafone pass',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: '',
		page_typology: 'principal',
	},
	enjoyMorePreconfirmBuy: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'vodafone pass',
		page_subcategory_level_2: '',
		page_subcategory_level_3: 'activar bono',
		page_screen: 'activar bono',
		page_typology: 'overlay',
	},
	extrasCategoryList: {
		'page_name': '',
		'page_section': 'productos y servicios',
		'page_subcategory_level_1': 'extras',
		'page_subcategory_level_2': '',
		'page_subcategory_level_3': '',
		'page_screen': '',
		'page_typology': 'principal',
		'journey_name': '',
		'journey_options': '',
		'journey_type': '',
		'&&events': '',
	},
	extrasCategoryListData: {
		'journey_name': 'gestion de bonos:inicio',
		'journey_options': 'bono ilimitado',
		'journey_type': 'compra',
		'&&events': 'event10',
	},
	extrasListDetails: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'extras',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: '',
		page_typology: 'principal',
		journey_name: '',
		journey_options: '',
		journey_type: '',
	},
	extrasListDetailsData: {
		journey_name: 'gestion de bonos:ficha',
		journey_options: 'bono ilimitado',
		journey_type: 'compra',
	},
	entertainmentDetails: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'extras',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: '',
		page_typology: 'principal',
	},
	standaloneOnlineTvPreconfirmation: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'extras',
		page_subcategory_level_2: 'entretenimiento',
		page_subcategory_level_3: '',
		page_subcategory_level_4: 'preconfirmacion',
		page_screen: 'preconfirmacion',
		page_typology: 'overlay',
	},
	standaloneOnlineTvDeactivation: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'extras',
		page_subcategory_level_2: 'entretenimiento',
		page_subcategory_level_3: '',
		page_subcategory_level_4: 'desactivar',
		page_screen: 'desactivar',
		page_typology: 'overlay',
	},
	manageWorryFreeBuyBundle: {
		page_name: 'productos y servicios:mas megas:activar mas megas',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'mas megas',
		page_subcategory_level_2: 'activar mas megas',
		page_subcategory_level_3: '',
		page_screen: 'activar mas megas',
		page_typology: 'overlay',
	},
	manageWorryFreeRemoveBundle: {
		page_name: 'productos y servicios:mas megas:desactivar mas megas',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'mas megas',
		page_subcategory_level_2: 'desactivar mas megas',
		page_subcategory_level_3: '',
		page_screen: 'desactivar mas megas',
		page_typology: 'overlay',
	},
	manageWorryFreeModifyBundle: {
		page_name: 'productos y servicios:mas megas:modificar mas megas',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'mas megas',
		page_subcategory_level_2: 'modificar mas megas',
		page_subcategory_level_3: '',
		page_screen: 'modificar mas megas',
		page_typology: 'overlay',
	},
	standaloneOnlineTvChangeUsername: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'extras',
		page_subcategory_level_2: 'entretenimiento',
		page_subcategory_level_3: '',
		page_subcategory_level_4: 'cambio de usuario',
		page_screen: 'cambio de usuario',
		page_typology: 'principal',
	},
	specialCall: {
		page_name: 'productos y servicios:detalle de tarifa:llamadas de tarificacion especial',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'detalle de tarifa',
		page_subcategory_level_2: 'llamadas de tarificacion especial',
		page_subcategory_level_3: '',
		page_screen: 'llamadas de tarificacion especial',
		page_typology: 'principal',
	},
	specialSms: {
		page_name: 'productos y servicios:detalle de tarifa:sms de tarificacion especial',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'detalle de tarifa',
		page_subcategory_level_2: 'sms de tarificacion especial',
		page_subcategory_level_3: '',
		page_screen: 'sms de tarificacion especial',
		page_typology: 'principal',
	},
	extraChannelDetails: {
		page_name: 'productos y servicios:detalle de tarifa:sms de tarificacion especial',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'detalle de tarifa',
		page_subcategory_level_2: 'canales extra',
		page_subcategory_level_3: '',
		page_screen: 'sms de tarificacion especial',
		page_typology: 'principal',
	},
	newExtraChannelDetails: {
		page_name: 'productos y servicios:extras:packs vodafone tv:<pack>',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'extras',
		page_subcategory_level_2: 'packs vodafone tv',
		page_subcategory_level_3: '<pack>',
		page_subcategory_level_4: '',
		page_screen: '<pack>',
		page_typology: 'screen',
	},
	extraChannelPrconfirmBuy: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'detalle de tarifa',
		page_subcategory_level_2: 'canales extra',
		page_subcategory_level_3: '',
		page_subcategory_level_4: 'activar canal',
		page_screen: 'activar canal',
		page_typology: 'overlay',
	},
	listCompareClickView: {
		page_name: '',
		page_section: 'dashboard',
		page_subcategory_level_1: 'home',
		page_subcategory_level_2: 'datos moviles',
		page_subcategory_level_3: 'consumo datos moviles',
		page_subcategory_level_4: 'gestiona tus datos compartidos',
		page_screen: 'gestiona tus datos compartidos',
		page_typology: 'overlay',
	},
	changeUsernameTvService: {
		page_name: 'productos y servicios:detalle de tarifa:vodafone tv online:cambio de usuario',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'detalle de tarifa',
		page_subcategory_level_2: 'vodafone tv online',
		page_subcategory_level_3: 'cambio de usuario',
		page_screen: 'cambio de usuario',
		page_typology: 'principal',
	},
	clickAccordionDevice: {
		event_name: 'click en {0}',
	},
	tvStandaloneActivationForm: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'extras',
		page_subcategory_level_2: 'entretenimiento',
		page_subcategory_level_3: '',
		page_subcategory_level_4: 'activacion',
		page_screen: 'activacion',
		page_typology: 'principal',
	},
	outOfBundleOverlay: {
		page_name: 'consumo fuera de tarifa',
		page_section: 'consumo: mi consumo',
		page_subcategory_level_1: '',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'consumo fuera de tarifa',
		page_typology: 'overlay',
	},
	loginUpgrade: {
		page_name: 'login:contenido autorizado',
		page_section: 'login',
		page_subcategory_level_1: 'contenido autorizado',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'contenido autorizado',
		page_typology: 'overlay',
	},
	tvServiceDetails: {
		page_name: 'productos y servicios:detalle de tarifa:detalle de canales tv incluidos',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'detalle de tarifa',
		page_subcategory_level_2: 'detalle de canales tv incluidos',
		page_subcategory_level_3: '',
		page_screen: 'detalle de canales tv incluidos',
		page_typology: 'principal',
	},
	landingBackEndError: {
		page_name: 'averias:error carga',
		page_section: 'averias',
		page_subcategory_level_1: 'error carga',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'error carga',
		page_typology: 'overlay',
	},
	bonitaSiteSelector: {
		page_name: 'averias:seleccionar direccion',
		page_section: 'averias',
		page_subcategory_level_1: 'seleccionar direccion',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'seleccionar direccion',
		page_typology: 'principal',
	},
	generalBreakDown: {
		page_name: 'averias:averia masiva',
		page_section: 'averias',
		page_subcategory_level_1: 'averia masiva',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'averia masiva',
		page_typology: 'principal',
	},
	exitBonitaFlow: {
		page_name: 'averias:cancelar creacion nueva averia',
		page_section: 'averias',
		page_subcategory_level_1: 'cancelar creacion nueva averia',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'cancelar creacion nueva averia',
		page_typology: 'overlay',
	},
	serviceWithOpenTicket: {
		page_name: 'averias:selector de averias:existe averia abierta',
		page_section: 'averias',
		page_subcategory_level_1: 'selector de averias',
		page_subcategory_level_2: 'existe averia abierta',
		page_subcategory_level_3: '',
		page_screen: 'existe averia abierta',
		page_typology: 'overlay',
	},
	serviceWithOpenOrder: {
		page_name: 'averias:selector de averias:existe pedido en curso',
		page_section: 'averias',
		page_subcategory_level_1: 'selector de averias',
		page_subcategory_level_2: 'existe pedido en curso',
		page_subcategory_level_3: '',
		page_screen: 'existe pedido en curso',
		page_typology: 'overlay',
	},
	serviceWithClosedOrder: {
		page_name: 'averias:selector de averias:existe averia cerrada',
		page_section: 'averias',
		page_subcategory_level_1: 'selector de averias',
		page_subcategory_level_2: 'existe averia cerrada',
		page_subcategory_level_3: '',
		page_screen: 'existe averia cerrada',
		page_typology: 'overlay',
	},
	bonitaSelectService: {
		page_name: 'averias:selector de averias',
		page_section: 'averias',
		page_subcategory_level_1: 'selector de averias',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'selector de averias',
		page_typology: 'overlay',
	},
	openNewTicket: {
		page_name: 'averias:<temp-name>',
		page_section: 'averias',
		page_subcategory_level_1: '',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: '',
		page_typology: 'principal',
	},
	openNewTicketTemplate: {
		page_name: '',
		page_section: 'averias',
		page_subcategory_level_1: '',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: '',
		page_typology: 'principal',
	},
	faultTagging: {
		page_code: 'undefined',
		journey_name: 'solucionar averia',
		journey_category: 'undefined',
		journey_subcategory: 'undefined',
		journey_type: 'servicio',
		journey_process: 'undefined',
		journey_detail: 'undefined',
		journey_step: 'undefined',
		journey_fiber_technology: 'undefined',
		journey_fault_backend: 'undefined',
		journey_decoder_model: 'undefined',
	},
	faultVariables: {
		prepaid: 'prepago',
		pospaid: 'pospago',
		authorized: 'AUTHORIZED',
		business: 'microempresa',
		individuals: 'particulares',
		start: 'start',
		event27: 'event27',
		steps: 'pasos intermedios',
		solved: 'solucionada',
		event28: 'event28',
		created: 'ticket creado',
		event: 'evento',
		cancel: 'cancelar',
		ko: 'ko',
		event103: 'event103',
		cancelOverlay: 'overlay cancelacion',
		yesSolved: 'si se ha resuelto',
		button: 'boton',
		event113: 'event113',
		notSolved: 'no se ha resulto',
		nSolved: 'no solucionada',
		event114: 'event114',
		chat: 'chat',
		openChat: 'abrir chat',
		call: 'esperar llamada',
		solvedFault: 'averias:solucionar averia:',
		goBack: 'volver atras',
		clickBackButton: 'click en boton volver',
	},
	orderManagment: {
		page_name: 'login:servicios no activados',
		page_section: 'login',
		page_subcategory_level_1: 'servicios no activados',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'servicios no activados',
		page_typology: 'overlay',
	},
	noPermissions: {
		page_name: 'mivoweb:microempresas:servicios one profesional:{s}:aviso no permiso',
		page_section: 'microempresas',
		page_detail: 'aviso no permiso',
		page_typology: 'overlay',
		journey_type_plan: '{s}',
	},
	noPermissionsTerminal: {
		page_name: 'mivoweb:transaccional:microflujos:canje terminales:ficha:aviso no permiso',
		page_section: 'transaccional',
		page_detail: 'aviso no permiso',
		page_typology: 'overlay',
	},
	tryAndBuy: {
		view: {
			page_name: 'promociones one profesional',
			page_section: 'promociones one profesional',
			page_subcategory_level_1: '',
			page_subcategory_level_2: '',
			page_subcategory_level_3: '',
			page_screen: 'promociones one profesional',
			page_typology: 'detalle',
			eventName: '',
			data: {
				event_name: '',
			},
		},
		buttonDetail: {
			eventName: 'click en ver detalles de {0}', // {0} --> parent item name
			data: {
				event_name: 'click en ver detalles de {0}',
			},
		},
		buttonFree: {
			eventName: 'click en ver probar gratis {0}', // {0} --> parent item name
			data: {
				event_name: 'click en probar gratis {0}',
			},
		},
		buttonContract: {
			eventName: 'click en contratar {0}', // {0} --> parent item name
			data: {
				event_name: 'click en contartar {0}',
			},
		},
		buttonFreeService: {
			eventName: 'click en probar servicio gratis',
			data: {
				event_name: 'click en probar servicio gratis',
			},
		},
		buttonContractService: {
			eventName: 'click en contratar servicio',
			data: {
				event_name: 'click en contratar servicio',
			},
		},
		formPrueba: {
			page_sub_name: ':{0}:formulario prueba gratis', // {0} --> parent item name
			level2: 'formulario prueba gratis',
			ok0: ':ok',
			ok: 'ok',
		},
		formContratacion: {
			page_sub_name: ':{0}:formulario contratacion', // {0} --> parent item name
			level2: 'formulario contratacion',
			ok0: ':ok',
			ok: 'ok',
		},
		statusBenefits: {
			status_benefits: 'Sin determinar',
		},
		newView: {
			page_section: 'microempresas',
			page_subcategory_level_1: '',
			page_subcategory_level_2: '',
			page_subcategory_level_3: '',
			page_subcategory_level_4: '',
			page_name: 'mivoweb:microempresas:servicio one profesional:{product}',
			page_detail: '{product}',
			page_typology: 'principal',
			eventName: '',
			asset_name: 'mivoweb',
			navigation_level_1: 'microempresas',
			navigation_level_2: '',
			page_screen: 'promociones one profesional',
			journey_name: '',
			journey_type: 'transaccional',
			journey_status: '',
			journey_step: '',
			journey_category: '',
			journey_type_plan: '',
		},
		newExtraData: {
			'formPruebaGratis': 'formulario prueba gratis',
			'formPrueba': 'formulario prueba gratis',
			'inicial': 'inicial',
			'formCompraPrueba': 'formulario compra',
			'formAcceso': 'acceso',
			'formfin': 'finalizar pedido',
			'prueba': 'prueba',
			'probar': 'probar',
			'comprado': 'comprado',
			'comprar': 'comprar',
			'detail': 'detalle servicio',
			'formResumen': 'resumen',
			'clickEn': 'click en',
			'boton': 'boton',
			'formulario': 'formulario',
			'ko': 'ko',
			'ok': 'ok',
			'pruebaCompra': '',
			'finalizarForm': 'finalizar formulario',
			'verDetalles': 'ver mas detalles de',
			'exit': 'cierre proceso',
			'abandonar': 'abandonar',
			'continuar': 'continuar',
			'event106': 'event106',
			'event105': 'event105',
			'start': 'start',
			'listadoServicios': 'listado servicios',
			'pruebaActiva': 'prueba activa',
			'pruebaFinalizada': 'prueba finalizada',
			'isDetail': 'isDetail',
			'exitAbandonar': 'exitAbandonar',
			'exitContinuar': 'exitContinuar',
			'end': 'end',
			'confirmable': 'confirmable',
			'error': 'error',
			'postpago': 'postpago',
			'page_name_list': 'microempresas:',
			'sticky': ':sticky',
			'&&products': 'sva;{product};1',
			'&&events': 'scAdd',
			'eventsTrack': 'scCheckout',
			'serviciosOp': 'servicios one profesional',
			'incialPruebaActiva': 'inicial:prueba activa',
			'pruebaActivaComprada': 'prueba activa:prueba comprada',
			'procesoContratacion': 'proceso contratacion',
			'eventFormularioServicio': 'formulario servicio',
			'continuarFlujo': 'continuar flujo',
			'abandonarFlujo': 'abandonar flujo',
			'page_nameSteep': 'microempresas:servicio one profesional:{product}:cierre proceso',
		},
		requestReceived: {
			data: {
				'page_name': 'mivoweb:microempresas:servicios one profesional:{product}:ok solicitud',
				'&&events': 'purchase',
				'page_subcategory_level_3': 'solicitud recibida',
				'navigation_level_4': 'solicitud recibida',
				'page_detail': 'ok solicitud',
				'journey_detail': 'ok solicitud',
				'journey_status': 'end',
				'journey_name': 'servicios one profesional',
				'journey_step': '',
			},
		},
		requestReceivedError: {
			data: {
				'page_name': 'mivoweb:microempresas:servicios one profesional:{product}:ko solicitud',
				'&&events': '',
				'page_subcategory_level_3': 'solicitud recibida',
				'navigation_level_4': 'solicitud recibida',
				'page_detail': 'ko solicitud',
				'journey_detail': 'ko solicitud',
				'journey_status': '',
				'journey_name': 'servicios one profesional',
				'journey_step': '',
			},
		},
		clickEntryPoint: {
			eventName: 'mivoweb:microempresas:tu espacio profesional:click en entrypoint',
			data: {
				'event_name': 'click en ',
				'event_category': 'link',
				'event_context': '',
				'event_label': 'click en ',
				'&&events': 'event70',
				'entrypoint_section': 'microempresas',
				'entrypoint_location': 'promociones one profesional',
				'entrypoint_position': '{undefined}',
				'entrypoint_title': '{et}',
				'journey_status': 'start',
				'experience_name': 'undefined',
				'entrypoint_type': 'reco',
			},
		},
		viewError: {
			page_section: 'microempresas',
			page_subcategory_level_1: 'prueba y compra',
			page_subcategory_level_2: '',
			page_subcategory_level_3: '',
			page_name: 'microempresas:pruebaycompra:{producto}:error',
			asset_name: 'mivoweb',
			navigation_level_1: 'microempresas',
			navigation_level_2: 'prueba y compra',
			navigation_level_3: '',
			navigation_level_4: '',
			page_screen: 'promociones one profesional',
			error_category: '',
			error_description: '',
			error_type: 'funcional',
			error_page: 'microempresas tu espacio profesional error',
			journey_name: 'prueba y compra',
			journey_type: 'sevicios',
			journey_detail: '{journeyDetail}',
			journey_process: '',
		},
		showError: {
			data: {
				'&&events': 'event100',
				'error_list': 'et=tecnico:otros|ec={error_code}|em=pagina|ed={error_description}',
				'error_category': '',
				'error_description': '',
				'error_type': 'funcional',
				'error_page': '',
			},
		},
		leaveProcess: {
			data: {
				page_subcategory_level_3: 'exit',
				page_name: 'microempresas:servicio one profesional:{product}:cierre proceso',
				navigation_level_3: 'exit',
				pager_screen: 'exit',
				journey_name: 'prueba y compra',
				journey_status: 'start',
				journey_detail: 'cierre proceso',
				journey_step: '',
				page_typology: 'overlay',
			},
		},
	},
	oneProfesionalNew: {
		requestReceived: {
			data: {
				page_subcategory_level_3: 'solicitud recibida',
				navigation_level_4: 'solicitud recibida',
				journey_detail: '{product}:formulario:ok',
				journey_status: 'end',
				journey_step: 'prueba y compra:{product}:formulario:ok',
			},
		},
		clickEntryPoint: {
			eventName: 'mivoweb:microempresas:tu espacio profesional:click en entrypoint',
			data: {
				'event_name': 'click en ',
				'event_category': 'link',
				'event_context': 'prueba y compra',
				'event_label': 'click en ',
				'&&events': 'event70',
				'entrypoint_section': 'microempresas',
				'entrypoint_location': 'promociones one profesional',
				'entrypoint_position': '{undefined}',
				'entrypoint_title': '{et}',
				'journey_status': 'start',
				'experience_name': 'undefined',
				'entrypoint_type': 'reco',
			},
		},
		viewError: {
			page_section: 'microempresas',
			page_subcategory_level_1: 'prueba y compra',
			page_subcategory_level_2: '',
			page_subcategory_level_3: '',
			page_name: 'microempresas:pruebaycompra:{producto}:error',
			asset_name: 'mivoweb',
			navigation_level_1: 'microempresas',
			navigation_level_2: 'prueba y compra',
			navigation_level_3: '',
			navigation_level_4: '',
			page_screen: 'promociones one profesional',
			error_category: '',
			error_description: '',
			error_type: 'funcional',
			error_page: 'microempresas tu espacio profesional error',
			journey_name: 'prueba y compra',
			journey_type: 'sevicios',
			journey_detail: '{journeyDetail}',
			journey_process: '',
			journey_step: 'prueba y compra:{journeyDetail}',
		},
		showError: {
			data: {
				'&&events': 'event100',
				'error_list': 'et=tecnico:otros|ec={error_code}|em=pagina|ed={error_description}',
				'error_category': '',
				'error_description': '',
				'error_type': 'funcional',
				'error_page': '',
			},
		},
		leaveProcess: {
			data: {
				page_subcategory_level_3: 'exit',
				page_name: 'microempresas:prueba y compra:{product}:exit',
				navigation_level_3: 'exit',
				pager_screen: 'exit',
				journey_name: 'prueba y compra',
				journey_status: 'start',
				journey_detail: '',
				journey_step: 'prueba y compra:{journeyDetail}',
			},
		},
	},
	oneProfesional: {
		view: {
			page_name: 'one profesional:servicios one profesional:{0}:{1}',
			page_section: 'one profesional',
			page_subcategory_level_1: 'servicios one profesional',
			page_subcategory_level_2: '',
			page_subcategory_level_3: '',
			page_subcategory_level_4: '',
			page_screen: '',
			page_typology: 'detalle',

			eventName: '',
			data: {
				event_name: '',
			},
		},
		otherData: {
			twoPoints: ':',
			active_service: 'servicio activo',
			no_disponible: 'servicio no disponible',
			formulario: 'formulario contratacion',
			form_ok: 'ok',
			form_ko: 'ko',
			fieldError: 'error en campo ',
			generalConditions: ':condiciones generales',
			acceso: 'acceso',
			licencias: 'licencias',
			resumen: 'resumen',
			dominio: 'dominio',
			cancelarCompra: 'cancelar compra',
		},
		seguridadDigital: {
			page_name: 'seguridad digital',
		},
		billing: {
			page_name: 'billing',
		},
		buttonGeneralConditions: {
			eventName: 'click en ver condiciones generales',
			data: {
				event_name: 'click en ver condiciones generales',
			},
		},
		buttonCloseForm: {
			eventName: 'click en cierre',
			data: {
				event_name: 'click en cierre',
			},
		},
		buttonContinue: {
			eventName: 'click en continuar',
			data: {
				event_name: 'click en continuar',
			},
		},
		buttonAbandone: {
			eventName: 'click en abandonar',
			data: {
				event_name: 'click en abandonar',
			},
		},
		buttonContract: {
			eventName: 'click en contratar servicio',
			data: {
				event_name: 'click en contratar servicio',
			},
		},
		buttonOpenForm: {
			eventName: 'click en lo quiero',
			data: {
				event_name: 'click en lo quiero',
			},
		},
		newView: {
			page_section: 'microempresas',
			page_subcategory_level_1: 'servicio one profesional',
			page_subcategory_level_2: 'listado servicios',
			page_subcategory_level_3: '',
			page_subcategory_level_4: '',
			page_detail: '',
			page_name: 'mivoweb:microempresas:servicio one profesional:{product}',
			page_typology: 'principal',
			eventName: '',
			navigation_level_1: 'microempresas',
			navigation_level_2: 'servicio one profesional',
			navigation_level_3: '',
			page_screen: '',
			journey_name: 'servicios one profesional',
			journey_type: 'transaccional',
			journey_status: '',
			journey_service_name: '',
			page_platform: 'web',
			journey_detail: 'listado',
			journey_subcategory: 'postpago',
			asset_name: 'mivoweb',
		},
		newExtraData: {
			listadoServicios: 'listado servicios',
			listadoServiciosOP: 'listado servicios one profesional',
			detalleServivicioOP: 'detalle servicio one profesional',
			eventContextServicio: 'servicio',
			eventContextCambioTabServicio: 'cambio tab servicio',
			eventCompraServicio: 'compra servicio',
			eventFormularioServicio: 'formulario servicio',
			enventsProdView: 'prodView',
			eventsAdd: 'scAdd',
			events106: 'event106',
			events105: 'event105',
			link: 'link',
			boton: 'boton',
			sticky: ':sticky',
			start: 'start',
			detalleProducto: 'detalle producto',
			cambioTab: 'cambio tab',
			compra: 'compra',
			compraSticky: 'compra sticky',
			masInfo: 'mas info',
			continuar: 'Continuar',
			licencias: 'licencias',
			ok: 'ok',
			exit: 'exit',
			cierreProceso: 'cierre proceso',
			abandonar: 'abandonar',
			abandonarFlujo: 'abandonar flujo',
			principal: 'principal',
			solicitudRecibida: 'ok solicitud',
			solicitudKo: 'ko solicitud',
			formKo: 'ko formulario',
			comprar: 'Comprar',
			end: 'end',
			overlay: 'overlay',
			flujo: 'flujo',
			products_tab: 'sva;<productType> <tab>;1',
			products: 'sva;<productType>;1',
			product: '{product}',
			page_name: 'mivoweb:microempresas:servicio one profesional:{product}',
			tabs: ':{tab}',
			ko_pdp: 'ko servicio pdp',
			pega: ' pega',
			verProd: 'ver productos',
			notEvent: 'no events',
			tryBuy: 'inicial:comprado',
		},
		listService: {
			page_detail: 'listado servicios',
			page_typology: 'principal',
			page_name: 'mivoweb:microempresas:servicio one profesional:listado servicios',
			navigation_level_3: 'listado servicios',
			page_screen: 'listado servicios one profesional',
		},
		listServiceKo: {
			page_name: 'mivoweb:microempresas:servicio one profesional:listado servicios:ko servicio listado',
			page_detail: 'ko servicio listado',
			journey_detail: 'ko servicio listado',
		},
		listServiceClick: {
			'event_name': '',
			'event_category': 'link',
			'event_context': 'servicio',
			'event_label': 'acceder {service}',
			'journey_name': 'servicios one profesional',
			'journey_category': '',
			'journey_status': '',
			'journey_detail': 'listado',
			'journey_process': '',
			'&&events': '',
			'&&products': 'sva;<productType>;1',
		},
		formSteep: {
			'page_screen': 'formulario one profesional',
			'page_name': 'mivoweb:microempresas:servicio one profesional:',
			'journey_process': 'contratable',
			'journey_detail': 'proceso contratacion',
			'&&events': 'scCheckout',
			'journey_type_plan': 'contratable',
			'&&products': 'sva;<productType>;1',
		},
		formClick: {
			'event_name': '',
			'event_category': 'boton',
			'event_context': 'formulario servicio',
			'journey_detail': 'proceso contratacion',
			'&&events': '',
			'&&products': 'sva;<productType> <tab>;1',
		},
		formClickDomain: {
			'event_name': '',
			'event_category': 'boton',
			'event_context': 'formulario servicio',
			'journey_detail': 'proceso contratacion',
			'&&events': '',
			'&&products': 'sva;<productType> <tab>;1',
			'event_label': 'no tengo dominio propio',
		},
		formConfirmation: {
			'page_screen': 'ok solicitud',
			'journey_status': 'end',
			'journey_detail': 'ok solicitud',
			'&&events': 'purchase',
			'&&products': 'sva;<productType> <tab>;1',
			'journey_type_plan': 'contratable:activo',
		},
		formConfirmationKo: {
			'page_screen': 'ko solicitud',
			'journey_detail': 'ko solicitud',
			'&&events': '',
			'&&products': 'sva;<productType> <tab>;1',
			'journey_type_plan': 'contratable:activo',
		},
		tabDetail: {
			'page_subcategory_level_2': '{product}',
			'page_subcategory_level_3': '',
			'page_name': '',
			'page_detail': '{product}',
			'navigation_level_3': '{product}',
			'page_screen': 'detalle servicio one profesional',
			'journey_status': '',
			'journey_detail': 'detalle servicio',
			'journey_process': '',
			'&&events': 'prodView',
			'&&products': '',
			'journey_type_plan': '{status}',
		},
		tabDetailClickChange: {
			journey_status: '',
			event_name: 'click en {literalButton}',
			event_category: 'tab',
			event_context: 'cambio tab servicio',
			event_label: 'cambiar modalidad a {literal}',
			journey_detail: 'detalle servicio',
			journey_type_plan: '{status}',
		},
		tabDetailClickBuy: {
			'journey_status': '',
			'event_name': 'click en {literalButton}',
			'event_category': 'boton',
			'event_context': 'detalle servicio',
			'event_label': '{literal}',
			'&&events': 'scAdd',
			'journey_detail': 'compra {literalTxt}',
			'journey_type_plan': '{status}',
		},
		requestReceived: {
			data: {
				page_subcategory_level_3: 'solicitud recibida',
				navigation_level_4: 'solicitud recibida',
				journey_detail: '{product}:formulario:ok',
				journey_status: 'end',
			},
		},
		viewError: {
			page_section: 'microempresas',
			page_subcategory_level_1: 'prueba y compra',
			page_subcategory_level_2: '',
			page_subcategory_level_3: '',
			page_name: 'microempresas:pruebaycompra:{producto}:error',
			asset_name: 'mivoweb',
			navigation_level_1: 'microempresas',
			navigation_level_2: 'prueba y compra',
			navigation_level_3: '',
			navigation_level_4: '',
			page_screen: 'promociones one profesional',
			error_category: '',
			error_description: '',
			error_type: 'funcional',
			error_page: 'microempresas tu espacio profesional error',
			journey_name: 'prueba y compra',
			journey_type: 'sevicios',
			journey_detail: '{journeyDetail}',
			journey_process: '',
		},
		showError: {
			data: {
				'&&events': 'event100',
				'error_list': 'et=tecnico:otros|ec={error_code}|em=pagina|ed={error_description}',
				'error_category': '',
				'error_description': '',
				'error_type': 'funcional',
				'error_page': '',
			},
		},
		leaveProcess: {
			data: {
				page_subcategory_level_3: 'exit',
				page_name: 'microempresas:prueba y compra:{product}:exit',
				navigation_level_3: 'exit',
				pager_screen: 'exit',
				journey_name: 'prueba y compra',
				journey_status: 'start',
				journey_detail: '',
				journey_step: 'prueba y compra:{journeyDetail}',
			},
		},
		listAddons: {
			pay: {
				postpaid: 'Postpaid',
				pospago: 'pospago',
				prepago: 'prepago',
			},
			click: {
				'page_name': 'productos y servicios:servicio one profesional:addons:listado de addons',
				'event_name': 'click en <button>',
				'event_label': '',
				'event_context': '<addonType> | <titleAddon>',
				'event_category': 'boton',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'servicio one profesional',
				'page_subcategory_level_2': 'addons',
				'page_subcategory_level_3': 'listado de addons ',
				'page_screen': 'listado de addons',
				'&&products': 'digital toolkit;<addonType>;1;;;eVar186=<serviceType>',
				'journey_name': 'digital toolkit',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_detail': 'listado de addons',
				'journey_process': '<addonType> | <titleAddon>',
				'journey_step': 'digital toolkit:listado de addons',
				'journey_type': 'transaccional',
			},
			view: {
				'page_name': 'productos y servicios:servicio one profesional:addons:listado de addons',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'servicio one profesional',
				'page_subcategory_level_2': 'addons',
				'page_subcategory_level_3': 'listado de addons',
				'page_screen': 'listado de addons',
				'&&events': 'event10,event27',
				'journey_name': 'digital toolkit',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_detail': 'listado de addons',
				'journey_process': '',
				'journey_step': 'digital toolkit:listado de addons',
				'journey_type': 'transaccional',
			},
		},
		detailsAddon: {
			pay: {
				postpaid: 'Postpaid',
				pospago: 'pospago',
				prepago: 'prepago',
			},
			click: {
				'page_name': 'productos y servicios:servicio one profesional:addons:<addonType>',
				'event_name': 'click en <button>',
				'event_label': '',
				'event_context': '',
				'event_category': 'boton',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'servicio one profesional',
				'page_subcategory_level_2': 'addons',
				'page_subcategory_level_3': '',
				'page_screen': '',
				'&&products': 'digital toolkit;<addonType>;1;;;eVar186=<serviceType>',
				'&&events': 'scAdd',
				'journey_name': 'digital toolkit',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_detail': '',
				'journey_process': '<addonType> | <titleAddon>',
				'journey_step': 'digital toolkit:<addonType>',
				'journey_type': 'transaccional',
			},
			view: {
				'page_name': 'productos y servicios:servicio one profesional:addons:<addonType>',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'servicio one profesional',
				'page_subcategory_level_2': 'addons',
				'page_subcategory_level_3': '',
				'page_screen': '',
				'&&products': 'digital toolkit;<addonType>;1;;;eVar186=<serviceType>',
				'&&events': 'prodView',
				'journey_name': 'digital toolkit',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_detail': '',
				'journey_process': '<addonType> | <titleAddon>',
				'journey_step': 'digital toolkit:<addonType>',
				'journey_type': 'transaccional',
			},
		},
		preconfirmationAddon: {
			pay: {
				postpaid: 'Postpaid',
				pospago: 'pospago',
				prepago: 'prepago',
			},
			click: {
				'page_name': 'productos y servicios:servicio one profesional:addons:<addonType>:preconfirmacion',
				'event_name': 'click en <button>',
				'event_label': '',
				'event_context': '<addonType> | <titleAddon>',
				'event_category': 'boton',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'servicio one profesional',
				'page_subcategory_level_2': 'addons',
				'page_subcategory_level_3': '',
				'page_subcategory_level_4': 'preconfirmacion',
				'page_screen': 'preconfirmacion',
				'&&products': 'digital toolkit;<addonType>;1;;;eVar186=<serviceType>',
				'&&events': 'scCheckout',
				'journey_name': 'digital toolkit',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_detail': 'preconfirmacion',
				'journey_process': '<addonType> | <titleAddon>',
				'journey_step': 'digital toolkit:preconfirmacion',
				'journey_type': 'transaccional',
			},
			view: {
				'page_name': 'productos y servicios:servicio one profesional:addons:<addonType>:preconfirmacion',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'servicio one profesional',
				'page_subcategory_level_2': 'addons',
				'page_subcategory_level_3': '',
				'page_subcategory_level_4': 'preconfirmacion',
				'page_screen': 'preconfirmacion',
				'&&products': 'digital toolkit;<addonType>;1;;;eVar186=<serviceType>',
				'&&events': 'event16',
				'journey_name': 'digital toolkit',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_detail': 'preconfirmacion',
				'journey_process': '<addonType> | <titleAddon>',
				'journey_step': 'digital toolkit:preconfirmacion',
				'journey_type': 'transaccional',
			},
		},
		confirmationAddon: {
			pay: {
				postpaid: 'Postpaid',
				pospago: 'pospago',
				prepago: 'prepago',
			},
			click: {
				'page_name': 'productos y servicios:servicio one profesional:addons:<addonType>:confirmacion',
				'event_name': 'click en <button>',
				'event_label': '',
				'event_context': '<addonType> | <titleAddon>',
				'event_category': 'boton',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'servicio one profesional',
				'page_subcategory_level_2': 'addons',
				'page_subcategory_level_3': '',
				'page_subcategory_level_4': 'confirmacion',
				'page_screen': 'confirmacion',
				'&&products': 'digital toolkit;<addonType>;1;;;eVar186=<serviceType>',
				'journey_name': 'digital toolkit',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_detail': 'confirmacion',
				'journey_process': '<addonType> | <titleAddon>',
				'journey_step': 'digital toolkit:confirmacion',
				'journey_type': 'transaccional',
			},
			view: {
				'page_name': 'productos y servicios:servicio one profesional:addons:<addonType>:confirmacion',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'servicio one profesional',
				'page_subcategory_level_2': 'addons',
				'page_subcategory_level_3': '',
				'page_subcategory_level_4': 'confirmacion',
				'page_screen': 'confirmacion',
				'&&products': 'digital toolkit;<addonType>;1;;;eVar186=<serviceType>',
				'&&events': 'event28,purchase',
				'purchaseID': '',
				'journey_name': 'digital toolkit',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_detail': 'confirmacion',
				'journey_process': '<addonType> | <titleAddon>',
				'journey_step': 'digital toolkit:confirmacion',
				'journey_type': 'transaccional',
			},
		},
		errorAddon: {
			pay: {
				postpaid: 'Postpaid',
				pospago: 'pospago',
				prepago: 'prepago',
			},
			view: {
				'page_name': 'productos y servicios:servicio one profesional:addons:error',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'servicio one profesional',
				'page_subcategory_level_2': 'addons',
				'page_subcategory_level_3': 'error',
				'page_screen': 'error',
				'&&products': 'digital toolkit;<addonType>;1;;;eVar186=<serviceType>',
				'&&events': 'event28,purchase',
				'error_code': '',
				'error_category': '',
				'error_description': '',
				'error_type': '',
				'journey_name': 'digital toolkit',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_detail': 'error',
				'journey_process': '<addonType> | <titleAddon>',
				'journey_step': 'digital toolkit:error',
				'journey_type': 'transaccional',
			},
		},
		entryPointAddons: {
			pay: {
				postpaid: 'Postpaid',
				pospago: 'pospago',
				prepago: 'prepago',
			},
			click: {
				'page_name': 'microempresas:servicio one profesional:<name>:<detail>',
				'event_name': 'click en entrypoint',
				'page_section': 'microempresas',
				'page_subcategory_level_1': 'servicio one profesional',
				'page_subcategory_level_2': '',
				'page_subcategory_level_3': '',
				'page_screen': '',
				'&&events': 'event70',
				'entrypoint_section': '',
				'entrypoint_location': '',
				'entrypoint_title': '',
				'journey_name': 'digital toolkit',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_type': 'transaccional',
			},
			view: {
				'page_name': 'microempresas:servicio one profesional:<name>:<detail>',
				'event_name': 'impresiones entrypoint',
				'page_section': 'microempresas',
				'page_subcategory_level_1': 'servicio one profesional',
				'page_subcategory_level_2': '',
				'page_subcategory_level_3': '',
				'page_screen': '',
				'&&events': 'event75',
				'entrypoint_list': 'es=<es>|el=<el>|jn=digital toolkit|et=<et>',
			},
		},
		bannerAddonsPYS: {
			pay: {
				postpaid: 'Postpaid',
				pospago: 'pospago',
				prepago: 'prepago',
			},
			click: {
				'page_name': 'productos y servicios:resumen de productos y servicios',
				'event_name': 'click en entrypoint',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'resumen de productos y servicios',
				'page_screen': 'resumen de productos y servicios',
				'&&events': 'event70',
				'entrypoint_section': 'pys',
				'entrypoint_location': 'servicios one profesional',
				'entrypoint_title': '',
				'journey_name': 'digital toolkit',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_type': 'transaccional',
			},
			view: {
				'page_name': 'productos y servicios:resumen de productos y servicios',
				'event_name': 'impresiones entrypoint',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'resumen de productos y servicios',
				'page_screen': 'resumen de productos y servicios',
				'&&events': 'event75',
				'entrypoint_list': 'es=pys|el=servicios one profesional|jn=digital toolkit|et=<et>',
			},
		},
		clickOneProfesional: {
			pay: {
				postpaid: 'Postpaid',
				pospago: 'pospago',
				prepago: 'prepago',
			},
			click: {
				page_name: 'productos y servicios:resumen productos y servicios',
				event_name: 'click en <button>',
				event_label: '',
				event_context: '',
				event_category: 'card',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'resumen productos y servicios',
				page_screen: 'resumen productos y servicios',
				journey_name: 'digital toolkit',
				journey_category: '',
				journey_subcategory: '',
				journey_type: 'transaccional',
			},
		},
	},
	tvmicro: {
		newExtraData: {
			events106: 'event106',
			events105: 'event105',
			start: 'start',
			continuar: 'Continuar',
			ok: 'ok',
			exit: ':exit',
			abandonar: 'abandonar',
			principal: 'principal',
			solicitudRecibida: ':solicitud recibida',
			comprar: 'Comprar',
			contratable: 'contratable',
			end: 'end',
			event_context_bar: 'llamar gratis',
		},
		View: {
			page_section: 'microempresas',
			page_subcategory_level_1: 'servicio one profesional',
			page_subcategory_level_2: '{serviceName}',
			page_subcategory_level_3: '',
			page_subcategory_level_4: '',
			page_detail: '',
			page_name: 'microempresas:servicio one profesional:{serviceName}',
			page_typology: '',
			eventName: '',
			asset_name: 'mivoweb',
			navigation_level_1: 'microempresas',
			navigation_level_2: 'servicio one profesional',
			navigation_level_3: '{serviceName}',
			page_screen: '',
			journey_name: 'servicios one profesional',
			journey_type: 'transaccional',
			journey_status: '',
			journey_step: '',
			journey_category: '',
		},
		ViewBar: {
			'journey_status': 'end',
			'journey_process': '',
			'&&events': '',
		},
		ViewNeg: {
			'journey_status': '{status}',
			'journey_process': 'contratable',
			'&&events': 'event27',
		},
		tabDetail: {
			page_screen: 'detalle servicio one profesional',
			journey_subcategory: '{serviceName}',
			journey_status: 'start',
			journey_detail: 'detalle producto',
			journey_process: '',
			journey_step: 'servicios one profesional:detalle producto',
		},
		tabDetailClickBuy: {
			'page_screen': 'detalle servicio one profesional',
			'event_name': 'click en {literalButton}',
			'event_category': 'boton',
			'journey_subcategory': '{serviceName}',
			'event_context': 'compra servicio',
			'event_label': '{literal}',
			'&&events': 'scAdd',
			'journey_status': '',
			'journey_detail': 'compra{literalTxt}',
			'journey_step': 'servicios one profesional:compra {action}',
			'&&products': 'sva;{serviceName};1',
		},
		formSteep: {
			'page_screen': 'formulario one profesional',
			'journey_subcategory': '{serviceName}',
			'journey_process': 'contratable',
			'journey_step': 'servicios one profesional:{literalForm}',
			'&&products': 'sva;{serviceName};1',
		},
		tabFormResumeClick: {
			'page_screen': 'formulario one profesional',
			'event_name': 'click en {literalButton}',
			'event_category': 'boton',
			'event_context': 'formulario servicio',
			'event_label': '{literal}',
			'journey_subcategory': '{serviceName}',
			'journey_process': 'contratable',
			'journey_step': 'servicios one profesional:{literalForm}',
			'&&products': 'sva;{serviceName};1',
		},
		formConfirmation: {
			'page_subcategory_level_3': 'solicitud recibida',
			'page_screen': 'solicitud recibida',
			'journey_subcategory': '{serviceName}',
			'journey_status': 'end',
			'journey_detail': 'ok',
			'journey_process': 'contratable',
			'journey_step': 'servicios one profesional:ok',
			'&&events': 'purchase, event28',
			'&&products': 'sva;{serviceName};1',
		},
		exitProccessState: {
			page_subcategory_level_3: 'exit',
			page_screen: 'exit',
			journey_step: 'servicios one profesional:{journeyDetail}:exit',
		},
		formClick: {
			event_name: 'click en {service}',
			event_category: 'boton',
			event_context: 'formulario servicio',
			journey_step: 'servicios one profesional:',
		},
		tvBar: {
			'event_context': 'llamar gratis',
			'journey_name': '',
			'journey_type': '',
			'journey_detail': '',
			'&&products': '',
			'&&events': '',
			'journey_step': '',
			'journey_category': '',
			'journey_subcategory': '',
		},
	},
	engagement: {
		View: {
			page_name: 'mivoweb:childbrowser:sorteos',
			page_section: 'childbrowser',
			page_detail: 'sorteos',
			page_typology: 'childbrowser',
		},
	},
	beeDigital: {
		view: {
			page_name: 'one profesional:analiza tu presencia en internet',
			page_section: 'one profesional',
			page_subcategory_level_1: 'analiza tu presencia en internet',
			page_subcategory_level_2: 'bee digital',
			page_subcategory_level_3: '',
			page_screen: 'analiza tu presencia en internet',
			page_typology: 'overlay',
			eventName: '',
			data: {
				event_name: '',
			},
		},
		beDigital: {
			page_name: ':bee digital',
		},
		buttonMoreDetails: {
			eventName: 'click en ver mas detalles',
			data: {
				event_name: 'click en ver mas detalles',
			},
		},
		buttonAnalyze: {
			eventName: 'click en analizar mi negocio',
			data: {
				event_name: 'click en analizar mi negocio',
			},
		},
	},
	europeanFundsCRM: {
		View: {
			page_name: 'mivoweb:dashboard:landing fondos europeos agente {paso}',
			page_section: 'dashboard',
			page_detail: 'landing fondos europeos agente {paso}',
			page_typology: 'overlay',
			asset_name: 'mivoweb',
			site_section: 'dashboard',
			page_platform: 'web',
		},
	},
	europeanFundsPega: {
		View: {
			page_name: 'mivoweb:dashboard:landing fondos europeos {paso}',
			page_section: 'dashboard',
			page_detail: 'landing fondos europeos {paso}',
			page_typology: 'overlay',
			asset_name: 'mivoweb',
			site_section: 'dashboard',
			page_platform: 'web',
		},
		chevronClick: {
			event_label: 'ver detalle {paso}',
			event_category: 'boton',
			event_context: 'landing fondos europeos',
		},
		chevronClickTobby: {
			event_label: 'acceder whatsapp tobi',
			event_category: 'boton',
			event_context: 'landing fondos europeos {paso}',
		},
	},
	removeHBORestriction: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'extras',
		page_subcategory_level_2: 'entretenimiento',
		page_subcategory_level_3: '',
		page_screen: 'overlay restriccion pago terceros',
		page_typology: 'overlay',
	},
	removeHBORestrictionConfirmationOverlay: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'detalle de tarifa',
		page_subcategory_level_2: 'canales extra',
		page_subcategory_level_3: '',
		page_screen: 'overlay restriccion',
		page_typology: 'overlay',
	},
	promotionDetails: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'detalle de tarifa',
		page_subcategory_level_2: 'promocion',
		page_subcategory_level_3: '',
		page_screen: '',
		page_typology: 'detalle',
	},
	preconfirmacionPromotion: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'detalle de tarifa',
		page_subcategory_level_2: 'promocion',
		page_subcategory_level_3: '',
		page_subcategory_level_4: 'preconfirmacion',
		page_screen: 'preconfirmacion',
		page_typology: 'overlay',
	},
	promotionMoreDetails: {
		page_name: '',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'detalle de tarifa',
		page_subcategory_level_2: 'promocion',
		page_subcategory_level_3: '',
		page_subcategory_level_4: 'mas detalles',
		page_screen: 'mas detalles',
		page_typology: 'detalle',
	},
	downloadAppNudge: {
		page_name: 'dashboard:overlay descarga app',
		page_section: 'dashboard',
		page_subcategory_level_1: 'overlay descarga app',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: '',
		page_typology: 'overlay',
	},
	pendingCallIdOverlay: {
		page_name: '',
		page_section: 'gestionar identificador llamada',
		page_subcategory_level_1: '',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: '',
		page_typology: 'overlay',
	},
	contactUsNeedHelp: {
		page_name: 'webview:ayuda y chat:necesitas mas ayuda',
		page_section: 'webview',
		page_subcategory_level_1: 'ayuda y chat',
		page_subcategory_level_2: 'necesitas mas ayuda',
		page_subcategory_level_3: '',
		page_screen: 'necesitas mas ayuda',
		page_typology: 'principal',
	},
	helpBubble: {
		mainScreen: {
			page_name: 'ayuda:temas de ayuda',
			page_section: 'ayuda',
			page_subcategory_level_1: 'temas de ayuda',
			page_subcategory_level_2: '',
			page_subcategory_level_3: '',
			page_screen: 'temas de ayuda',
			page_typology: 'overlay',
		},
		faqs: {
			page_name: 'webview:ayuda:faqs',
			page_section: 'webview',
			page_subcategory_level_1: 'ayuda',
			page_subcategory_level_2: 'faqs',
			page_subcategory_level_3: '',
			page_screen: 'faqs',
			page_typology: 'overlay',
		},
		asistente: {
			page_name: 'webview:ayuda:asistente',
			page_section: 'webview',
			page_subcategory_level_1: 'ayuda',
			page_subcategory_level_2: 'asistente',
			page_subcategory_level_3: '',
			page_screen: 'asistente',
			page_typology: 'overlay',
		},
	},
	pciPrepaidPages: {
		serviceSelectorPage: {
			page_name: 'recargas:realizar recarga:inicio de recarga',
			page_section: 'recargas',
			page_subcategory_level_1: 'realizar recarga',
			page_subcategory_level_2: 'inicio de recarga',
			page_subcategory_level_3: '',
			page_screen: 'inicio de recarga',
			page_typology: 'overlay',
		},
		iframePage: {
			page_name: 'recargas:realizar recarga:datos tarjeta',
			page_section: 'recargas',
			page_subcategory_level_1: 'realizar recarga',
			page_subcategory_level_2: 'datos tarjeta',
			page_subcategory_level_3: '',
			page_screen: 'datos tarjeta',
			page_typology: 'overlay',
		},
		okPage: {
			page_name: 'recargas:realizar recarga:ok',
			page_section: 'recargas',
			page_subcategory_level_1: 'realizar recarga',
			page_subcategory_level_2: 'ok',
			page_subcategory_level_3: '',
			page_screen: 'ok',
			page_typology: 'overlay',
		},
		koPage: {
			page_name: 'recargas:realizar recarga:ko',
			page_section: 'recargas',
			page_subcategory_level_1: 'realizar recarga',
			page_subcategory_level_2: 'ko',
			page_subcategory_level_3: '',
			page_screen: 'ko',
			page_typology: 'overlay',
		},
		cancelPage: {
			page_name: 'recargas:realizar recarga:cancelar recarga',
			page_section: 'recargas',
			page_subcategory_level_1: 'realizar recarga',
			page_subcategory_level_2: 'cancelar recarga',
			page_subcategory_level_3: '',
			page_screen: 'cancelar recarga',
			page_typology: 'overlay',
		},
		paymentTicketPage: {
			page_name: 'recargas:realizar recarga:ticket de pago',
			page_section: 'recargas',
			page_subcategory_level_1: 'realizar recarga',
			page_subcategory_level_2: 'ticket de pago',
			page_subcategory_level_3: '',
			page_screen: 'ticket de pago',
			page_typology: 'overlay',
		},
		systemUnderMaintenance: {
			page_name: 'recargas:sistema en mantenimiento',
			page_section: 'recargas',
			page_subcategory_level_1: 'sistema en mantenimiento',
			page_subcategory_level_2: '',
			page_subcategory_level_3: '',
			page_screen: 'sistema en mantenimiento',
			page_typology: 'overlay',
		},
		safariNotSupportedPage: {
			page_name: 'recargas:safari no compatible',
			page_section: 'recargas',
			page_subcategory_level_1: 'safari no compatible',
			page_subcategory_level_2: '',
			page_subcategory_level_3: '',
			page_screen: 'safari no compatible',
			page_typology: 'overlay',
		},
	},
	paymentCardsPages: {
		manageCreditCards: {
			page_name: 'recargas:realizar recarga:tarjetas guardadas',
			page_section: 'recargas',
			page_subcategory_level_1: 'realizar recarga',
			page_subcategory_level_2: 'tarjetas guardadas',
			page_subcategory_level_3: '',
			page_screen: 'tarjetas guardadas',
			page_typology: 'overlay',
		},
		StandAlonemanageCreditCards: {
			page_name: 'pagos:mis pagos:metodos de pago:tarjetas guardadas',
			page_section: 'pagos',
			page_subcategory_level_1: 'mis pagos',
			page_subcategory_level_2: 'metodos de pago',
			page_subcategory_level_3: 'tarjetas guardadas',
			page_screen: 'tarjetas guardadas',
			page_typology: 'overlay',
		},
		managePostpaidCreditCardsPageName: {
			page_name: 'pagos:pago factura:<payment_page_name>:seleccion tarjeta',
			page_section: 'pagos',
			page_subcategory_level_1: 'pago factura',
			page_subcategory_level_2: '<payment_page_name>',
			page_subcategory_level_3: 'seleccion tarjeta',
			page_screen: 'seleccion tarjeta',
			page_typology: 'overlay',
		},
		cerrarBtnClickSuccessPaymentOverlay: {
			page_name: 'pagos:pago factura:<payment_page_name>:seleccion tarjeta',
			page_section: 'pagos',
			page_subcategory_level_1: 'pago factura',
			page_subcategory_level_2: '<payment_page_name>',
			page_subcategory_level_3: 'seleccion tarjeta',
			page_screen: 'seleccion tarjeta',
			page_typology: 'overlay',
		},
		managePostpaidCreditCards: {
			journey_name: 'pago de factura',
			journey_category: '',
			journey_subcategory: 'postpago',
			journey_type: 'servicio',
			journey_process: 'pagar factura por adelantado',
			journey_detail: 'seleccion de tarjeta',
			journey_step: 'pago de factura:seleccion de tarjeta',
		},
		confirmDeleteCard: {
			page_name: 'recargas:realizar recarga:tarjetas guardadas:eliminar tarjeta',
			page_section: 'recargas',
			page_subcategory_level_1: 'realizar recarga',
			page_subcategory_level_2: 'tarjetas guardadas',
			page_subcategory_level_3: 'eliminar tarjeta',
			page_screen: 'eliminar tarjeta',
			page_typology: 'overlay',
		},
		manageCommercialCreditCardsPageName: {
			page_name: 'transaccional:microflujos:<payment_page_name>:pasarela:pago tarjeta:wallet',
			page_section: 'transaccional',
			page_subcategory_level_1: 'microflujos',
			page_subcategory_level_2: '<payment_page_name>',
			page_subcategory_level_3: 'pasarela',
			page_screen: 'tarjetas debito y credito',
			page_typology: 'overlay',
		},
		manageCommercialCreditCards: {
			journey_name: 'checkout',
			journey_type: 'transaccional',
			journey_detail: 'pasarela',
			journey_step: 'checkout:pasarela',
			state_flow: 'checkout',
		},
	},
	bonitaFlowError: {
		page_name: 'averias:error bonita',
		page_section: 'averias',
		page_subcategory_level_1: 'error bonita',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'error bonita',
		page_typology: 'overlay',
	},
	serviceSettingsCompras: {
		page_name: 'ajustes de linea:pagos a vodafone y terceros:compras y suscripciones:compras',
		page_section: 'ajustes de linea',
		page_subcategory_level_1: 'pagos a vodafone y terceros',
		page_subcategory_level_2: 'compras y suscripciones',
		page_subcategory_level_3: 'compras',
		page_screen: 'compras',
		page_typology: 'principal',
	},
	serviceSettingsSubscription: {
		page_name: 'ajustes de linea:pagos a vodafone y terceros:compras y suscripciones:suscripciones',
		page_section: 'ajustes de linea',
		page_subcategory_level_1: 'pagos a vodafone y terceros',
		page_subcategory_level_2: 'compras y suscripciones',
		page_subcategory_level_3: 'suscripciones',
		page_screen: 'suscripciones',
		page_typology: 'principal',
	},
	serviceSettingsSubscriptionNew: {
		page_name: 'productos y servicios:resumen de productos y servicios:mis suscripciones',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'resumen de productos y servicios',
		page_subcategory_level_2: 'mis suscripciones',
		page_screen: 'mis suscripciones',
		asset_name: 'mivoweb',
		navigation_level_1: 'productos y servicios',
		navigation_level_2: 'resumen de productos y servicios',
		navigation_level_3: 'mis suscripciones',
	},
	serviceSettingsSubscriptionPill: {
		page_name: 'productos y servicios:resumen de productos y servicios:mis suscripciones:{0}',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'resumen de productos y servicios',
		page_subcategory_level_2: 'mis suscripciones',
		page_subcategory_level_3: '{0}',
		page_screen: '{0}',
		asset_name: 'mivoweb',
		navigation_level_1: 'productos y servicios',
		navigation_level_2: 'resumen de productos y servicios',
		navigation_level_3: 'mis suscripciones',
		navigation_level_4: '{0}',
	},
	serviceSettingsSubscriptionCancel: {
		page_name: 'productos y servicios:resumen de productos y servicios:mis suscripciones:suscripciones',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'resumen de productos y servicios',
		page_subcategory_level_2: 'mis suscripciones',
		page_subcategory_level_3: 'suscripciones',
		page_screen: 'suscripciones',
		journey_name: 'finalizar suscripcion',
		journey_category: '{0}',
		journey_subcategory: '{0}',
		journey_process: '{0}',
		journey_options: '',
		journey_type: 'servicio',
		journey_detail: 'start',
		journey_step: 'finalizar suscripcion:start',
		journey_enviroment: 'privada',
		asset_name: 'mivoweb',
		navigation_level_1: 'productos y servicios',
		navigation_level_2: 'resumen de productos y servicios',
		navigation_level_3: 'mis suscripciones',
		navigation_level_4: 'suscripciones',
	},
	SubscriptionOriginBackendOK: {
		'page_name':
			'mivoweb:productos y servicios:resumen de productos y servicios:mis suscripciones:overlay finalizar suscripcion {0}:ok',
		'asset_name': 'mivoweb',
		'navigation_level_1 ': 'productos y servicios',
		'navigation_level_2': 'resumen de productos y servicios',
		'navigation_level_3': 'mis suscripciones',
		'navigation_level_4': 'suscripciones',
		'navigation_level_5': 'overlay finalizar suscripcion {0}:ok',
		'page_screen': 'overlay finalizar suscripcion {0}:ok',
	},
	SubscriptionOriginBackendOKJourney: {
		journey_name: 'finalizar suscripcion',
		journey_category: 'particulares',
		journey_subcategory: 'postpago',
		journey_process: '{0}',
		journey_type: 'servicio',
		journey_detail: '{0} ok',
		journey_step: 'finalizar suscripcion {0} ok',
		journey_enviroment: 'privada',
	},
	SubscriptionOriginBackendKOJourney: {
		journey_name: 'finalizar suscripcion',
		journey_category: 'particulares',
		journey_subcategory: 'postpago',
		journey_process: '{0}',
		journey_type: 'servicio',
		journey_detail: '{0} ko parcial',
		journey_step: 'finalizar suscripcion {0} ko parcial',
		journey_enviroment: 'privada',
	},
	SubscriptionOriginBackendKOParcial: {
		'page_name':
			'productos y servicios:resumen de productos y servicios:mis suscripciones:overlay finalizar suscripcion {0}:ko parcial',
		'page_screen': 'overlay finalizar suscripcion {0}:ko parcial',
		'asset_name': 'mivoweb',
		'navigation_level_1 ': 'productos y servicios',
		'navigation_level_2': 'resumen de productos y servicios',
		'navigation_level_3': 'mis suscripciones',
		'navigation_level_4': 'suscripciones',
		'navigation_level_5': 'overlay finalizar suscripcion {0}:ko parcial',
		'error_list': 'et=tecnico|el=backend|ec=<error_ecode>|em=<error_message>|ed=<error_description>|eh=<error_HTTP>',
		'error_description': '<error_description>',
	},
	SubscriptionOriginBackendKOParcialJourney: {
		journey_name: 'finalizar suscripcion',
		journey_category: 'particulares',
		journey_subcategory: 'postpago',
		journey_process: '{0}',
		journey_type: 'servicio',
		journey_detail: '{0} ko parcial',
		journey_step: 'finalizar suscripcion {0} ko parcial',
		journey_enviroment: 'privada',
	},
	SubscriptionOriginBackendKOTotal: {
		'page_name':
			'mivoweb:productos y servicios:resumen de productos y servicios:mis suscripciones:overlay finalizar suscripcion {0}:ko total',
		'asset_name': 'mivoweb',
		'navigation_level_1 ': 'productos y servicios',
		'navigation_level_2': 'resumen de productos y servicios',
		'navigation_level_3': 'mis suscripciones',
		'navigation_level_4': 'suscripciones',
		'navigation_level_5': 'overlay finalizar suscripcion {0}:ko total',
		'error_list': 'et=tecnico|el=backend|ec=<error_ecode>|em=<error_message>|ed=<error_description>|eh=<error_HTTP>',
		'error_description': '<error_description>',
		'journey_name': 'finalizar suscripcion',
		'journey_category': 'particulares',
		'journey_subcategory': 'postpago',
		'journey_process': '{0}',
		'journey_type': 'servicio',
		'journey_detail': '{0} ko total',
		'journey_step': 'finalizar suscripcion {0} ko total',
		'journey_enviroment': 'privada',
	},
	confirmOriginBackendData: {
		page_name:
			'mivoweb:productos y servicios:resumen de productos y servicios:mis suscripciones:overlay finalizar suscripcion {0}',
		asset_name: 'mivoweb',
		navigation_level_1: 'productos y servicios',
		navigation_level_2: 'resumen de productos y servicios ',
		navigation_level_3: 'mis suscripciones',
		navigation_level_4: 'suscripciones',
		navigation_level_5: 'overlay finalizar suscripcion {0}',
		journey_name: 'finalizar suscripcion',
		journey_category: 'particulares',
		journey_subcategory: 'postpago',
		journey_process: '{0}',
		journey_type: 'servicio',
		journey_detail: '{0}',
		journey_step: 'finalizar suscripcion:{0}',
		journey_enviroment: 'privada',
	},
	confirmOriginBackend: {
		view: {
			page_name:
				'productos y servicios:resumen de productos y servicios:mis suscripciones:overlay finalizar suscripcion {0}',
			asset_name: 'mivoweb',
			navigation_level_1: 'productos y servicios',
			navigation_level_2: 'resumen de productos y servicios ',
			navigation_level_3: 'mis suscripciones',
			navigation_level_4: 'suscripciones',
			navigation_level_5: 'overlay finalizar suscripcion {0}',
		},
		clickEvent: {
			eventName:
				'productos y servicios: resumen de productos y servicios: mis suscripciones: overlay finalizar suscripcion {0}',
			data: {
				event_name: 'confirmar {0}',
				event_action: 'click',
				event_context: 'productos y servicios',
				event_category: 'boton',
				event_label: 'confirmar {0}',
			},
		},
		journey: {
			journey_name: 'finalizar suscripcion',
			journey_category: 'particulares',
			journey_subcategory: 'postpago',
			journey_process: '{0}',
			journey_type: 'servicio',
			journey_detail: '{0}',
			journey_step: 'finalizar suscripcion:{0}',
			journey_enviroment: 'privada',
		},
	},
	clickConfirmOriginBackend: {
		eventName:
			'productos y servicios: resumen de productos y servicios: mis suscripciones: overlay finalizar suscripcion {0}',
		data: {
			event_name: 'confirmar {0}',
			event_action: 'click',
			event_context: 'productos y servicios',
			event_category: 'boton',
			event_label: 'confirmar {0}',
		},
	},
	serviceSettingsSubscriptionEntertainment: {
		page_name: 'productos y servicios:resumen de productos y servicios:mis suscripciones',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'resumen de productos y servicios',
		page_subcategory_level_2: 'mis suscripciones',
		page_screen: 'mis suscripciones',
		asset_name: 'mivoweb',
		navigation_level_1: 'productos y servicios',
		navigation_level_2: 'resumen de productos y servicios',
		navigation_level_3: 'mis suscripciones',
	},
	serviceSettingsSubscriptionDetail: {
		page_name: 'productos y servicios:resumen de productos y servicios:mis suscripciones:suscripciones:detalle {0}',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'resumen de productos y servicios',
		page_subcategory_level_2: 'mis suscripciones',
		page_subcategory_level_3: 'suscripciones',
		page_subcategory_level_4: 'detalle {0}',
		page_screen: 'detalle {0}',
		asset_name: 'mivoweb',
		navigation_level_1: 'productos y servicios',
		navigation_level_2: 'resumen de productos y servicios',
		navigation_level_3: 'mis suscripciones',
		navigation_level_4: 'suscripciones',
		navigation_level_5: 'detalle {0}',
	},
	serviceSettingsSubscriptionKoParcial: {
		page_name: 'productos y servicios:resumen de productos y servicios:mis suscripciones:suscripciones:ko parcial',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'resumen de productos y servicios',
		page_subcategory_level_2: 'mis suscripciones',
		page_subcategory_level_3: 'suscripciones',
		page_subcategory_level_4: 'ko parcial',
		page_screen: 'ko parcial',
		error_list: 'et=tecnico|el=backend|ec=<error_ecode>|em=<error_message>|ed=<error_description>|eh=<error_HTTP>',
		error_description: '<error_description>',
		asset_name: 'mivoweb',
		navigation_level_1: 'productos y servicios',
		navigation_level_2: 'resumen de productos y servicios',
		navigation_level_3: 'mis suscripciones',
		navigation_level_4: 'suscripciones',
		navigation_level_5: 'ko parcial',
	},
	serviceSettingsSubscriptionOverlay: {
		page_name:
			'productos y servicios:resumen de productos y servicios:mis suscripciones:suscripciones:overlay finalizar suscripcion restricciones',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'resumen de productos y servicios',
		page_subcategory_level_2: 'mis suscripciones',
		page_subcategory_level_3: 'suscripciones',
		page_subcategory_level_4: 'overlay finalizar suscripcion restricciones',
		page_screen: 'overlay finalizar suscripcion restricciones',
		asset_name: 'mivoweb',
		navigation_level_1: 'productos y servicios',
		navigation_level_2: 'resumen de productos y servicios',
		navigation_level_3: 'mis suscripciones',
		navigation_level_4: 'suscripciones',
		navigation_level_5: 'overlay finalizar suscripcion restricciones',
	},
	serviceSettingsSubscriptionOverlayJourney: {
		journey_name: 'finalizar suscripcion',
		journey_category: '{0}',
		journey_subcategory: '{0}',
		journey_process: '{0}',
		journey_options: '',
		journey_type: 'servicio',
		journey_detail: 'restricciones',
		journey_step: 'finalizar suscripcion:restricciones',
		journey_enviroment: 'privada',
	},
	serviceSettingsSubscriptionOverlayOK: {
		page_name:
			'productos y servicios:resumen de productos y servicios:mis suscripciones:suscripciones:overlay finalizar suscripcion restricciones:ok',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'resumen de productos y servicios',
		page_subcategory_level_2: 'mis suscripciones',
		page_subcategory_level_3: 'suscripciones',
		page_subcategory_level_4: 'overlay finalizar suscripcion restricciones:ok',
		page_screen: 'overlay finalizar suscripcion restricciones:ok',
		asset_name: 'mivoweb',
		navigation_level_1: 'productos y servicios',
		navigation_level_2: 'resumen de productos y servicios',
		navigation_level_3: 'mis suscripciones',
		navigation_level_4: 'suscripciones',
		navigation_level_5: 'overlay finalizar suscripcion restricciones:ok',
	},
	serviceSettingsSubscriptionOverlayOKJourney: {
		journey_name: 'finalizar suscripcion',
		journey_category: '{0}',
		journey_subcategory: '{0}',
		journey_process: '{0}',
		journey_options: '',
		journey_type: 'servicio',
		journey_detail: 'restricciones ok',
		journey_step: 'finalizar suscripcion:restricciones ok',
		journey_enviroment: 'privada',
	},
	serviceSettingsSubscriptionOverlayKOParcial: {
		page_name:
			'productos y servicios:resumen de productos y servicios:mis suscripciones:suscripciones:overlay finalizar suscripcion restricciones:ko parcial',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'resumen de productos y servicios',
		page_subcategory_level_2: 'mis suscripciones',
		page_subcategory_level_3: 'suscripciones',
		page_subcategory_level_4: 'overlay finalizar suscripcion restricciones:ko parcial',
		page_screen: 'overlay finalizar suscripcion restricciones:ko parcial',
		error_list: 'et=tecnico|el=backend|ec=<error_ecode>|em=<error_message>|ed=<error_description>|eh=<error_HTTP>',
		error_description: '<error_description>',
		asset_name: 'mivoweb',
		navigation_level_1: 'productos y servicios',
		navigation_level_2: 'resumen de productos y servicios',
		navigation_level_3: 'mis suscripciones',
		navigation_level_4: 'suscripciones',
		navigation_level_5: 'overlay finalizar suscripcion restricciones:ko parcial',
	},
	serviceSettingsSubscriptionOverlayKOParcialJourney: {
		journey_name: 'finalizar suscripcion',
		journey_category: '{0}',
		journey_subcategory: '{0}',
		journey_process: '{0}',
		journey_options: '',
		journey_type: 'servicio',
		journey_detail: 'restricciones ko parcial',
		journey_step: 'finalizar suscripcion:restricciones ko parcial',
		journey_enviroment: 'privada',
	},
	serviceSettingsSubscriptionOverlayER: {
		page_name:
			'productos y servicios:resumen de productos y servicios:mis suscripciones:overlay finalizar suscripcion er',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'resumen de productos y servicios',
		page_subcategory_level_2: 'mis suscripciones',
		page_subcategory_level_3: 'overlay finalizar suscripcion er',
		page_screen: 'overlay finalizar suscripcion er',
		journey_name: 'finalizar suscripcion',
		journey_category: '{0}',
		journey_subcategory: '{0}',
		journey_process: '{0}',
		journey_options: '{0}',
		journey_type: 'servicio',
		journey_detail: 'er',
		journey_step: 'finalizar suscripcion:er',
		journey_enviroment: 'privada',
		asset_name: 'mivoweb',
		navigation_level_1: 'productos y servicios',
		navigation_level_2: 'resumen de productos y servicios',
		navigation_level_3: 'mis suscripciones',
		navigation_level_4: 'overlay finalizar suscripcion er',
	},
	serviceSettingsSubscriptionOverlayErOK: {
		page_name:
			'productos y servicios:resumen de productos y servicios:mis suscripciones:overlay finalizar suscripcion er:ok',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'resumen de productos y servicios',
		page_subcategory_level_2: 'mis suscripciones',
		page_subcategory_level_3: 'overlay finalizar suscripcion er',
		page_subcategory_level_4: 'ok',
		page_screen: 'ok',
		journey_name: 'finalizar suscripcion',
		journey_category: '{0}',
		journey_subcategory: '{0}',
		journey_process: '{0}',
		journey_options: '{0}',
		journey_type: 'servicio',
		journey_detail: 'er ok',
		journey_step: 'finalizar suscripcion:er ok',
		journey_enviroment: 'privada',
		asset_name: 'mivoweb',
		navigation_level_1: 'productos y servicios',
		navigation_level_2: 'resumen de productos y servicios',
		navigation_level_3: 'mis suscripciones',
		navigation_level_4: 'overlay finalizar suscripcion er',
		navigation_level_5: 'ok',
	},
	serviceSettingsSubscriptionOverlayErKOParcial: {
		page_name:
			'productos y servicios:resumen de productos y servicios:mis suscripciones:overlay finalizar suscripcion er:ko parcial',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'resumen de productos y servicios',
		page_subcategory_level_2: 'mis suscripciones',
		page_subcategory_level_3: 'overlay finalizar suscripcion er',
		page_subcategory_level_4: 'ko parcial',
		page_screen: 'ko parcial',
		journey_name: 'finalizar suscripcion',
		journey_category: '{0}',
		journey_subcategory: '{0}',
		journey_process: '{0}',
		journey_options: '{0}',
		journey_type: 'servicio',
		journey_detail: 'er ko parcial',
		journey_step: 'finalizar suscripcion:er ko parcial',
		journey_enviroment: 'privada',
		asset_name: 'mivoweb',
		navigation_level_1: 'productos y servicios',
		navigation_level_2: 'resumen de productos y servicios',
		navigation_level_3: 'mis suscripciones',
		navigation_level_4: 'overlay finalizar suscripcion er',
		navigation_level_5: 'ko parcial',
	},
	serviceSettingsPurchaseKoParcial: {
		page_name: 'productos y servicios:resumen de productos y servicios:mis suscripciones:compras:ko parcial',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'resumen de productos y servicios',
		page_subcategory_level_2: 'mis suscripciones',
		page_subcategory_level_3: 'compras',
		page_subcategory_level_4: 'ko parcial',
		page_screen: 'ko parcial',
		error_list: 'et=tecnico|el=backend|ec=<error_ecode>|em=<error_message>|ed=<error_description>|eh=<error_HTTP>',
		error_description: '<error_description>',
		asset_name: 'mivoweb',
		navigation_level_1: 'productos y servicios',
		navigation_level_2: 'resumen de productos y servicios',
		navigation_level_3: 'mis suscripciones',
		navigation_level_4: 'compras',
		navigation_level_5: 'ko parcial',
	},
	moreLessPurchases: {
		page_name: 'productos y servicios:resumen de productos y servicios:mis suscripciones:compras',
		page_section: 'productos y servicios',
		page_subcategory_level_1: 'resumen de productos y servicios',
		page_subcategory_level_2: 'mis suscripciones',
		page_subcategory_level_3: 'compras',
		page_screen: 'compras',
		asset_name: 'mivoweb',
	},
	clickMoreLessPurchases: {
		eventName: 'productos y servicios:resumen de productos y servicios:mis compras: click {0}',
		data: {
			event_name: 'click {0}',
			event_action: 'click',
			event_context: 'productos y servicios',
			event_category: 'boton',
			event_label: 'click en {0}',
		},
	},
	svaDetailsScreen: {
		page_name: 'que tengo contratado:servicios extra:<bundle name>',
		page_section: 'que tengo contratado',
		page_subcategory_level_1: 'servicios extra',
		page_subcategory_level_2: '<bundle name>',
		page_subcategory_level_3: '',
		page_screen: '<bundle name>',
		page_typology: 'overlay',
	},
	asessorDigitalScreen: {
		view: {
			page_name: 'mivoweb:ayuda:tus asesores',
			asset_name: 'mivoweb',
			navigation_level_1: 'ayuda',
			navigation_level_2: 'tus asesores',
			navigation_level_3: 'buzon de documentos',
			navigation_level_4: '',
			page_screen: 'tus asesores',
		},
		btnCall: {
			data: {
				event_name: 'clic en llamar',
				event_category: 'boton',
				event_label: 'llamar al 22122',
			},
		},
		btnChat: {
			data: {
				event_name: 'clic en chat',
				event_category: 'boton',
				event_label: 'chatea con tu asesor',
			},
		},
		btnWhatsapp: {
			data: {
				event_name: 'clic en whatsapp',
				event_category: 'boton',
				event_label: 'escribe un whatsapp',
			},
		},
		btnEmail: {
			data: {
				event_name: 'clic en email',
				event_category: 'boton',
				event_label: 'envia un email',
			},
		},
		btnContact: {
			data: {
				event_name: 'clic en formulario de contacto',
				event_category: 'boton',
				event_label: 'formulario de contacto',
			},
		},
		btnRateus: {
			data: {
				event_name: 'clic en valorar atencion',
				event_category: 'boton',
				event_label: 'valora nuestra atencion',
			},
		},
		btnBuzon: {
			data: {
				event_name: 'click en buzon de documentos',
				event_category: 'boton',
				event_label: 'buzon de documentos',
			},
		},
		btnBuzonDonwload: {
			data: {
				event_name: 'click en descargar documento',
				event_category: 'boton',
				event_label: 'descargar documento',
			},
		},
	},
	contactUsScreen: {
		view: {
			page_name: 'ayuda:tus asesores:necesitas mas ayuda',
			page_section: 'ayuda',
			page_subcategory_level_1: 'tus asesores',
			page_subcategory_level_2: 'necesitas mas ayuda',
			page_subcategory_level_3: '',
			page_screen: 'necesitas mas ayuda',
			page_typology: 'overlay',
		},
		btnSend: {
			data: {
				event_name: 'clic en enviar',
			},
		},
	},
	svaContactScreen: {
		page_name: 'que tengo contratado:servicios extra:<bundle name>:contacta con tu tecnico especializado',
		page_section: 'que tengo contratado',
		page_subcategory_level_1: 'servicios extra',
		page_subcategory_level_2: '<bundle name>',
		page_subcategory_level_3: 'contacta con tu tecnico especializado',
		page_screen: 'contacta con tu tecnico especializado',
		page_typology: 'overlay',
	},
	svaMoreDetailsScreen: {
		page_name: 'que tengo contratado:servicios extra:<bundle name>:saber mas',
		page_section: 'que tengo contratado',
		page_subcategory_level_1: 'servicios extra',
		page_subcategory_level_2: '<bundle name>',
		page_subcategory_level_3: 'saber mas',
		page_screen: 'saber mas',
		page_typology: 'overlay',
	},
	svaPurchaseProducts: {
		page_name: 'que tengo contratado:resumen que tengo contratado',
		page_section: 'que tengo contratado',
		page_subcategory_level_1: 'resumen que tengo contratado',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'resumen que tengo contratado',
		page_typology: 'screen',
	},
	navigateToInvoices: {
		page_name: 'que tengo contratado:resumen que tengo contratado',
		page_section: 'que tengo contratado',
		page_subcategory_level_1: 'resumen que tengo contratado',
		page_screen: 'resumen que tengo contratado',
		asset_name: 'mivoweb',
		navigation_level_1: 'que tengo contratado',
		navigation_level_2: 'resumen que tengo contratado',
	},
	superWiFiDetails: {
		page_name: 'productos y servicios:superwifi:ficha',
		page_section: 'ProductsAndServices',
		page_subcategory_level_1: 'superwifi',
		page_subcategory_level_2: 'ficha',
		page_subcategory_level_3: '',
		page_screen: 'ficha',
		page_typology: 'screen',
	},
	superWiFiManageDetails: {
		'page_name': 'productos y servicios:superwifi {type}:gestionar',
		'page_section': 'productos y servicios',
		'page_subcategory_level_1': 'superwifi {type}',
		'page_subcategory_level_2': 'gestionar',
		'page_subcategory_level_3': '',
		'page_screen': 'gestionar',
		'&&products': 'superwifi;superwifi {type};1;{product_price}',
		'journey_name': 'superwifi',
	},
	dashboardWS10: {
		page_name: 'dashboard:home',
		page_platform: 'web',
		page_section: 'dashboard',
		page_subcategory_level_1: 'home',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'home',
		page_typology: 'principal',
	},
	dashboardWS10Discover: {
		'page_name': 'dashboard:home',
		'page_platform': 'web',
		'page_section': 'dashboard',
		'page_subcategory_level_1': 'home',
		'page_screen': 'home',
		'page_typology': 'principal',
		'event_name': 'impresiones entrypoint',
		'&&events': 'event75',
		'entrypoint_list': '{list}',
	},
	overlayOutOfBunndle: {
		page_name: 'consumo:mi consumo:detalle de consumo item',
		page_section: 'consumo',
		page_subcategory_level_1: 'mi consumo',
		page_subcategory_level_2: 'detalle de consumo',
		page_subcategory_level_3: 'item',
		page_subcategory_level_4: '',
		page_screen: 'detalle de consumo',
		page_typology: 'overlay',
	},
	ClickCloseOverlayOutOfBunndle: {
		eventName: 'consumo:mi consumo:detalle de consumo:click en cerrar consumo de datos',
		data: {
			event_name: 'click en cerrar consumo de datos',
		},
	},
	billingTileDashboard: {
		page_name: 'consumo:mi consumo:detalle en facturación',
		page_section: 'consumo',
		page_subcategory_level_1: 'mi consumo',
		page_subcategory_level_2: 'detalle en facturación',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: 'detalle en facturación',
		page_typology: '',
	},
	consuptionDetailsOOB: {
		page_name: 'consumo:mi consumo:detalle de consumo fuera de tarifa:item ',
		page_section: 'consumo',
		page_subcategory_level_1: 'mi consumo',
		page_subcategory_level_2: 'detalle de consumo fuera de tarifa',
		page_subcategory_level_3: 'item',
		page_subcategory_level_4: '',
		page_screen: 'detalle de consumo fuera de tarifa',
		page_typology: 'overlay',
	},
	overlayClickWithoutBill: {
		page_name: 'consumo:mi consumo:detalle de consumo:factura oculta ',
		page_section: 'consumo',
		page_subcategory_level_1: 'mi consumo',
		page_subcategory_level_2: 'factura oculta',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: 'detalle de consumo de datos',
		page_typology: 'overlay',
	},
	averiaLandingPage: {
		page_name: 'averias',
		page_section: 'averias',
		page_subcategory_level_1: '',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: 'averias',
		page_typology: 'principal',
	},
	billPaymentDetailsLandingPage: {
		page_name: 'pagos:mis pagos',
		page_section: 'pagos',
		page_subcategory_level_1: 'mis pagos',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: 'mis pagos',
		page_typology: 'principal',
	},
	billPaymentDetailsErrorList: {
		error_list: 'et=tecnico:otros|ec=<error_code>|em=<error_message>|ed=<error_desc>',
	},
	overlayClickBtn: {
		page_name: 'consumo:mi consumo: detalle de consumo : item',
		page_section: 'consumo',
		page_subcategory_level_1: 'mi consumo',
		page_subcategory_level_2: 'detalle de consumo',
		page_subcategory_level_3: 'item',
		page_subcategory_level_4: '',
		page_screen: 'detalle de consumo',
		page_typology: 'overlay',
	},
	mainTileDetails: {
		page_name: '',
		page_section: 'consumo:',
		page_subcategory_level_1: 'mi consumo:',
		page_subcategory_level_2: 'detalle de consumo',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: 'detalle consumo',
		page_typology: 'overlay',
	},
	mainTileDetailsPrepYu: {
		page_name: 'consumo:mi consumo:detalle de consumo:de {0}:entre yuser',
		page_section: 'consumo:',
		page_subcategory_level_1: 'mi consumo',
		page_subcategory_level_2: 'detalle de consumo',
		page_subcategory_level_3: 'de {0}',
		page_subcategory_level_4: 'entre yuser',
		page_screen: 'entre yuser',
		page_typology: 'overlay',
	},
	mainTileDetailsADSL: {
		page_name: '',
		page_section: 'dashboard:',
		page_subcategory_level_1: 'home:',
		page_subcategory_level_2: 'adsl',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: 'detalle consumo',
		page_typology: 'overlay',
	},
	showDataSharingView: {
		page_name: '',
		page_section: 'dashboard:',
		page_subcategory_level_1: 'home:',
		page_subcategory_level_2: 'datos moviles:',
		page_subcategory_level_3: 'consumo datos moviles',
		page_subcategory_level_4: '',
		page_screen: 'consumo datos moviles',
		page_typology: 'overlay',
	},
	verifyYourAccountFrom: {
		page_name: 'dashboard:home',
		page_section: 'dashboard',
		page_subcategory_level_1: 'home',
		page_screen: 'home',
		event_name: '',
	},
	verifyYourAccount: {
		page_name: '',
		page_section: 'dashboard:',
		page_subcategory_level_1: 'home:',
		page_subcategory_level_2: '',
		page_subcategory_level_3: 'verifica tu cuenta',
		page_subcategory_level_4: '',
		asset_name: 'mivoweb',
		page_typology: 'overlay',
		navigation_level_1: 'dashboard',
		navigation_level_2: '',
		navigation_level_3: 'verifica tu cuenta',
		page_screen: 'verifica tu cuenta',
		event_name: '',
		journey_name: '',
		journey_category: '',
		journey_type: '',
	},
	lightToCompleteProfile: {
		journey_name: 'cambio perfil ligero a completo:start',
		journey_category: 'cambio perfil ligero a completo',
		journey_type: 'eservice',
	},
	selectorCardView: {
		page_name: 'dashboard:home:mis productos:selecciona un producto',
		page_section: 'dashboard:',
		page_subcategory_level_1: 'home:',
		page_subcategory_level_2: 'mis productos:',
		page_subcategory_level_3: 'selecciona un producto',
		page_subcategory_level_4: '',
		page_screen: '',
		page_typology: 'overlay',
	},
	storeCardView: {
		page_name: '',
		page_section: 'dashboard:',
		page_subcategory_level_1: 'home:',
		page_subcategory_level_2: 'tienda',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: '',
		page_typology: '',
	},
	renoveBenefitsView: {
		page_name: '',
		page_section: 'dashboard:',
		page_subcategory_level_1: 'home:',
		page_subcategory_level_2: 'beneficios',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: '',
		page_typology: '',
	},
	trayOverlay: {
		page_name: 'mis productos más de una sede',
		page_typology: 'overlay',
	},
	termsconditions: {
		page_name: 'aviso legal:terminos y condiciones',
		page_section: 'aviso legal',
		page_subcategory_level_1: 'terminos y condiciones',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'terminos y condiciones',
		page_typology: 'screen',
	},
	privacypolicy: {
		page_name: 'aviso legal:politica de privacidad',
		page_section: 'aviso legal',
		page_subcategory_level_1: 'politica de privacidad',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'politica de privacidad',
		page_typology: 'screen',
	},
	libraries: {
		page_name: 'aviso legal:biblioteca codigo abierto',
		page_section: 'aviso legal',
		page_subcategory_level_1: 'biblioteca codigo abierto',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'biblioteca codigo abierto',
		page_typology: 'screen',
	},
	lineaAdicionalError: {
		page_name: 'purchase:lineas adicionales:error',
		page_section: 'purchase',
		page_subcategory_level_1: 'lineas adicionales',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'error',
		page_typology: 'overlay',
	},
	buySimPages: {
		personalData: {
			page_name: 'prepago:tienda:datos personales',
			page_section: 'prepago',
			page_subcategory_level_1: 'tienda',
			page_subcategory_level_2: 'datos personales',
			page_screen: 'datos personales',
			page_typology: 'screen',
		},
		deliveryAddressStep1: {
			page_name: 'prepago:tienda:envio paso1',
			page_section: 'prepago',
			page_subcategory_level_1: 'tienda',
			page_subcategory_level_2: 'envio paso1',
			page_screen: 'envio paso1',
			page_typology: 'screen',
		},
		deliveryAddressStep2: {
			page_name: 'prepago:tienda:envio paso2',
			page_section: 'prepago',
			page_subcategory_level_1: 'tienda',
			page_subcategory_level_2: 'envio paso2',
			page_screen: 'envio paso2',
			page_typology: 'screen',
		},
		manualAddress: {
			page_name: 'prepago:tienda:introducir direccion',
			page_section: 'prepago',
			page_subcategory_level_1: 'tienda',
			page_subcategory_level_2: 'introducir direccion',
			page_screen: 'introducir direccion',
			page_typology: 'screen',
		},
		payment: {
			page_name: 'prepago:tienda:pago',
			page_section: 'prepago',
			page_subcategory_level_1: 'tienda',
			page_subcategory_level_2: 'pago',
			page_screen: 'pago',
			page_typology: 'screen',
		},
		generalConditions: {
			page_name: 'prepago:tienda:pago:condiciones generales',
			page_section: 'prepago',
			page_subcategory_level_1: 'tienda',
			page_subcategory_level_2: 'pago',
			page_subcategory_level_3: 'condiciones generales',
			page_screen: 'condiciones generales',
			page_typology: 'screen',
		},
		orderConfirmation: {
			page_name: 'prepago:tienda:confirmacion pedido',
			page_section: 'prepago',
			page_subcategory_level_1: 'tienda',
			page_subcategory_level_2: 'confirmacion pedido',
			page_screen: 'confirmacion pedido',
			page_typology: 'screen',
		},
		portability: {
			page_name: 'prepago:tienda:tu numero',
			page_section: 'prepago',
			page_subcategory_level_1: 'tienda',
			page_subcategory_level_2: 'tu numero',
			page_screen: 'tu numero',
			page_typology: 'screen',
		},
		portabilityEditOwner: {
			page_name: 'prepago:tienda:tu numero:datos del titular',
			page_section: 'prepago',
			page_subcategory_level_1: 'tienda',
			page_subcategory_level_2: 'tu numero',
			page_subcategory_level_3: 'datos del titular',
			page_screen: 'datos del titular',
			page_typology: 'overlay',
		},
	},
	navigateToNbaTrade: {
		page_name: 'dashboard:home:click en saber mas',
	},
	navigateFromNbaTrade: {
		page_name: 'dashboard:home:amplia tus limites:click en mas info',
	},
	goToSpecialForYouPage: {
		page_name: 'dashboard:home',
		page_section: 'dashboard',
		page_platform: 'web',
		page_subcategory_level_1: 'home',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'home',
		page_typology: 'principal',
		event_name: 'click en para ti',
		event_category: 'boton',
		event_context: 'tile para ti',
		event_label: 'para ti',
	},
	specialForYou: {
		specialForYouPage: {
			page_name: 'especial para ti:home',
			page_section: 'especial para ti',
			page_platform: 'web',
			page_subcategory_level_1: 'home',
			page_subcategory_level_2: '',
			page_subcategory_level_3: '',
			page_screen: 'home',
			page_typology: 'principal',
		},
		swipeDistributiveMenu: {
			event_category: 'carrusel',
			event_context: 'banner especial para ti wcs',
			event_label: 'swipe',
		},
		deeplinkS4UPage: {
			'event_name': 'deeplink entrypoint',
			'event_category': 'deeplink',
			'event_context': 'undefined',
			'event_label': 'click entrypoint',
			'&&events': 'event70',
			'entrypoint_code': 'undefined',
			'entrypoint_section': 'deeplink',
			'entrypoint_location': 'undefined',
			'entrypoint_position': 'undefined',
			'entrypoint_title': 'undefined',
			'entrypoint_type': 'undefined',
			'journey_name': 'especial para ti',
		},
		clickCloseS4UButton: {
			event_name: 'click en cerrar',
			event_category: 'boton',
			event_context: 'especial para ti',
			event_label: 'cerrar',
		},
		impressionsEPsS4U: {
			'event_name': 'impresiones entrypoint',
			'event_category': 'visualizacion',
			'event_context': 'entrypoint especial para ti wcs',
			'event_label': 'impresiones entrypoint',
			'&&events': 'event75',
			'entrypoint_list':
				'es=<entrypoint_section>|el=<entrypoint_location>|ep=<entrypoint_position>|jn=<journey_name>|en=<experience_name>|et=<entrypoint_title>|ety=<entrypoint_type>',
		},
		clickEPsS4U: {
			'event_name': 'click en entrypoint',
			'event_category': '<banner|card>',
			'event_context': 'entrypoint especial para ti wcs',
			'event_label': 'click entrypoint',
			'&&events': 'event70',
			'entrypoint_code': '<entrypoint_code>',
			'entrypoint_section': 'especial para ti',
			'entrypoint_location': 'undefined',
			'entrypoint_position': 'undefined',
			'entrypoint_title': 'undefined',
			'entrypoint_type': '<reco|pega|target|normal>',
			'jouney_name': 'undefined',
		},
		impressionsDMPS4U: {
			'event_name': 'impresiones entrypoint',
			'event_category': 'visualizacion',
			'event_context': 'banner de personalizacion',
			'event_label': 'impresiones entrypoint',
			'&&events': 'event75',
			'entrypoint_list':
				'es=especial para ti|el=<entrypoint_location>|ep=<entrypoint_position>|jn=<journey_name>|en=<experience_name>|et=<entrypoint_title>|ety=<entrypoint_type>',
		},
		displayDMPS4U: {
			event_name: 'visualizacion del <experience_name>',
			event_category: 'carrusel',
			event_context: 'banner personalizacion especial para ti',
			event_label: 'visualizacion del <experience_name>',
		},
		clickDPMS4U: {
			'event_name': 'click en entrypoint',
			'event_category': 'carrusel',
			'event_context': 'banner de personalizacion',
			'event_label': 'click entrypoint',
			'&&events': 'event70',
			'entrypoint_code': 'undefined',
			'entrypoint_section': 'especial para ti',
			'entrypoint_location': '',
			'entrypoint_position': '1',
			'entrypoint_title': '',
			'entrypoint_type': '<reco|pega|target|normal>',
			'jouney_name': '',
			'experience_name': '',
		},
		clickOffersDMPS4U: {
			event_category: 'banner',
			event_context: 'especial para ti ofertas personalizacion',
			page_name: 'especial para ti:pantalla intermedia:<page_screen>',
			page_subcategory_level_1: 'pantalla intermedia',
		},
		middlePageOffers: {
			page_name: 'especial para ti:pantalla intermedia:<page_screen>',
			page_section: 'especial para ti',
			page_subcategory_level_1: 'pantalla intermedia',
			page_subcategory_level_2: '<moviles y dispositivos>',
			page_subcategory_level_3: '',
			page_screen: '<moviles y dispositivos>',
			page_typology: 'principal',
		},
		impressionsOffersDMP: {
			'event_name': 'impresiones entrypoint',
			'event_category': 'visualizacion',
			'event_context': 'especial para ti ofertas personalizacion',
			'event_label': 'impresiones entrypoint',
			'&&events': 'event75',
			'entrypoint_list':
				'es=<entrypoint_section>|el=<entrypoint_location>|ep=<entrypoint_position>|jn=<journey_name>|en=<experience_name>|et=<entrypoint_title>|ety=<entrypoint_type>',
		},
		impressionsOffersPEGA: {
			'event_name': 'impresiones entrypoint',
			'event_category': '{0}',
			'event_context': 'especial para ti ofertas pega',
			'event_label': 'impresiones entrypoint',
			'&&events': 'event75',
			'entrypoint_list':
				'es=<entrypoint_section>|el=<entrypoint_location>|ep=<entrypoint_position>|jn=<journey_name>|en=undefined|et=<entrypoint_title>|ety=<entrypoint_type>',
			'entrypoint_pega':
				'enm=<entrypoint_name>|elb=<entrypoint_label>|eis=<entrypoint_issue>|egn=<entrypoint_groupnba>|ec=<entrypoint_code>|jn=<journey_name>|el=<entrypoint_location>',
		},
		clicksOffersPEGA: {
			'event_name': 'click en entrypoint',
			'event_category': 'boton',
			'event_context': 'entrypoint especial para ti ofertas pega',
			'event_label': 'click entrypoint',
			'&&events': 'event70',
			'entrypoint_code': '<entrypoint_code>',
			'entrypoint_section': 'especial para ti',
			'entrypoint_location': 'undefined',
			'entrypoint_position': 'undefined',
			'entrypoint_title': 'undefined',
			'entrypoint_type': '<reco|pega|target|normal>',
			'jouney_name': 'undefined',
		},
		notInterestedOfferPEGA: {
			event_name: 'click no me interesa',
			event_category: 'boton',
			event_context: 'rechazo oferta pega',
			event_label: 'click no me interesa',
			entrypoint_code: '<entrypoint_code>',
			entrypoint_title: 'undefined',
			jouney_name: 'undefined',
		},
		backdropNotInterestedPage: {
			page_name: 'especial para ti:pantalla intermedia:<page_subcategory_level_2>:backdrop',
			page_section: 'especial para ti',
			page_subcategory_level_1: 'pantalla intermedia',
			page_subcategory_level_2: '<mejora tu tarifa|anadir nuevos servicios>',
			page_subcategory_level_3: '',
			page_screen: 'backdrop',
			page_typology: 'backdrop',
		},
		sendReasonBackdrop: {
			event_name: 'click motivo <texto de la elección>',
			event_category: 'boton',
			event_context: 'envio rechazo',
			event_label: 'click motivo <texto de la elección>',
			entrypoint_code: '<entrypoint_code>',
			entrypoint_title: 'undefined',
			jouney_name: 'undefined',
		},
		sendNoReasonBackdrop: {
			event_name: 'click sin motivo rechazo',
			event_category: 'boton',
			event_context: 'envio rechazo',
			event_label: 'click sin motivo rechazo',
		},
		clickCloseBackdrop: {
			event_name: 'click en cerrar',
			event_category: '<boton|swipe>',
			event_context: 'backdrop no me interesa',
			event_label: 'cerrar',
			entrypoint_code: '<entrypoint_code>',
			entrypoint_title: 'undefined',
			jouney_name: 'undefined',
		},
		modalNoOffers: {
			page_name: 'especial para ti:pantalla intermedia:<wcs>:<object>',
			journey_detail: '<object>',
			journey_type: 'transaccional',
			journey_name: '<name>',
		},
		modalNoMoreOffers: {
			page_name: 'especial para ti:pantalla intermedia:<wcs>:ofertas agotadas',
			journey_detail: 'ofertas agotadas',
		},
		clickCTANoOffers: {
			page_name: 'especial para ti:pantalla intermedia:<page_screen>:<ofertas>',
			page_section: 'especial para ti',
			page_subcategory_level_1: 'pantalla intermedia',
			page_typology: 'principal',
			event_category: 'boton',
			event_context: 'especial para ti {0}',
			event_label: '<copy>',
		},
		modalError: {
			page_name: 'especial para ti:pantalla intermedia:ko tecnico',
			page_screen: 'ko tecnico',
			journey_type: 'transaccional',
			journey_name: '<name>',
			event_category: 'error event',
			event_context: 'ec=<ecode>|eh=<code>',
			error_list: 'et=tecnico|el=frontend|ec=<ecode>|em=<transaction>|eh=<code>|ed=<description>',
			error_type: 'tecnico',
			error_category: 'entrypoints',
		},
		displayPEGAOffer: {
			event_name: 'visualizacion del <entrypoint_code>',
			event_category: 'carrusel',
			event_context: 'banner pega especial para ti',
			event_label: 'visualizacion del <entrypoint_code>',
		},
		clickCarrouselPEGAOffer: {
			'event_name': 'click en entrypoint',
			'event_category': 'carrusel',
			'event_context': 'banner de pega',
			'event_label': 'click entrypoint',
			'&&events': 'event70',
			'title': 'especial para ti:home:click en entrypoint',
		},
		clickFilters: {
			'event_category': 'interaccion filtros',
			'event_context': 'especial para ti ofertas pega',
			'event_label': 'impresiones entrypoint <code>',
			'entrypoint_list': '',
			'entrypoint_pega': '',
			'&&events': 'event75',
		},
	},
	staticVariables: {
		buySimJourneyProcessType: {
			newNumber: 'alta nueva',
			portability: 'portabilidad',
		},
		paymentMethod: 'tarjeta de credito',
		paymentSubCategory: 'pospago',
		paymentJourneyType: 'servicio',
		paymentFinancedDevicesEventNum: 'event65',
		editBankAccountErrorList: 'et=tecnico:otros|ec=<error_code>|em=editar datos de pago|ed=<error_desc>',
		error_list: 'et=tecnico:indisponibilidad|ec=<error code>|em=pagina|ed=<error message>',
		paymentDeptPageName: 'deuda',
		paymentInAdvancePageName: 'adelantado',
		paymentCashDeskPageName: 'actual',
		paymentDeptJourneyProcess: 'pagar deuda',
		paymentInAdvanceJourneyProcess: 'pagar factura por adelantado',
		paymentCashDeskJourneyProcess: 'pagar factura actual',
		systemNotAvailableErrorList: 'et=tecnico:otros|ec=<error_code>|em=pago con tarjeta|ed=<error_desc>',
		inValidCreditCardErrorList: 'et=tecnico:otros|ec=<error_code>|em=pago con tarjeta|ed=<error_desc>',
		timeoutErrorList: 'et=tecnico:tiempo agotado|ec=<error_code>|em=pago con tarjeta|ed=<error_desc>',
		unSupportedCreditCardErrorList: 'et=tecnico:otros|ec=<error_code>|em=pago con tarjeta|ed=<error_desc>',
		maintenanceErrorList:
			'et=tecnico:indisponibilidad|ec=indisponibilidad|em=pago con tarjeta|ed=estamos realizando labores de mantenimiento',
		inCorrectCreditCardErrorList: 'et=funcional|ec=funcional|em=pago con tarjeta|ed=<error_desc>',
		paymentGenericError: 'et=tecnico:otros|ec=<error_code>|em=pago con tarjeta|ed=<error_desc>',
		paymentFinancedDevicesGenericError: 'et=tecnico:otros|ec=<error_code>|em=financiacion activa|ed=<error_desc>',
		payPartial: 'pago parcial',
		payTotal: 'pago completo',
		paymentCreditCardPageName: 'checkout',
		paymentCreditCardJourneyProcess: 'pago inicial',
		buySimFieldsName: {
			name: 'nombre',
			surName: 'primer apellido',
			secondSurName: 'segundo apellido',
			contactNumber: 'telefono',
			id: 'dni',
			dateOfBirth: 'fechanacimiento',
			email: 'mail',
			legalTerms: 'politicaprotecciondatos',
			formattedAddress: 'direccion',
			streetNumber: 'numero',
			address: 'direccion',
			port: 'numero',
			postalCode: 'codigopostal',
			location: 'localidad',
		},
		savedCreditCard: 'guardada',
		newCreditCard: 'nueva',
		paymentAmount: 'event87={0}',
		pciPrepaidErrorCategories: {
			general: 'tecnico',
			invalidCreditCard: 'funcional',
			invalidCreditCardInfo: 'funcional',
			Notprepaid: 'funcional',
			NotvodafoneNumber: 'funcional',
			Notactive: 'funcional',
			TopupInProgress: 'funcional',
			CreditCardTypeNotSupported: 'funcional',
			SystemNotAvailable: 'tecnico',
			ReplicaMode: 'tecnico',
			refundSuccess: 'tecnico',
			PaymentApiFails: 'tecnico',
			TimeOut: 'funcional',
			mortiroloKO108: 'tecnico',
		},
		pciPrepaidErrorDescription: {
			PaymentApiFails: 'generic error',
			Notprepaid: 'invalid mobile',
			NotvodafoneNumber: 'invalid mobile',
			Notactive: 'invalid mobile',
			TopupInProgress: 'ongoing top-up',
			ReplicaMode: 'sistema no disponible',
			TimeOut: 'timeOut',
			refundSuccess: 'payment ok refund ok',
			mortiroloKO108: 'payment ok refund ok',
			general: 'generico',
			invalidCreditCard: 'tarjeta invalida',
			CreditCardTypeNotSupported: 'tarjeta no admitida',
			SystemNotAvailable: 'sistema no disponible',
			invalidCreditCardInfo: 'datos de tarjeta',
		},

		leavingBonitaSolved: {
			'&&events': 'event113',
		},
		leavingBonitaNotSolved: {
			'&&events': 'event114',
		},
		pageNameTemplate: 'averias:<temp-name>',
		journeySubCategory: 'averia de <ticket-type>',
		pciPrepaidLanguage: {
			english: 'english',
			spanish: 'espanol',
		},
		pciPrepaidJourniesSubCategory: {
			prepaid: 'propia',
			other: 'otro usuario',
			public: 'publico',
		},
		pciPrepaidJournyTypes: {
			withSavedCreditCard: 'compra-con tarjeta',
			withoutSavedCreditCard: 'compra-sin tarjeta',
		},
		faqAvailability: {
			available: 'disponible',
			unavailabe: 'no disponible',
		},
		pageOriginalRefering: 'url:{0}',
		pageOriginalReferingParam: 'link',
		removeHBORestriction: {
			pageName: 'productos y servicios:extras:entretenimiento:{packname}:overlay restriccion pago terceros',
			pageScreen: '{packname}',
		},
		removeHBORestrictionConfirmationOverlay: {
			pageName:
				'productos y servicios:extras:entretenimiento:{packname}:overlay restriccion pago terceros:confirmacion',
			pageScreen: '{packname}',
		},
		messageDetailsCtas: {
			image: 'imagen',
			video: 'video',
		},
		pendingCallId: {
			pageName: 'gestionar identificador llamada:pendiente {0}',
		},
		clientPrivileges: {
			network: 'ligero',
			light: 'ligero',
			complete: 'completo',
		},
		noBillsTutorial: {
			pageName: 'facturas:mis facturas:tutorial de factura:<titulo del paso>',
			pageScreen: '<titulo del paso>',
		},
		documentName: 'nombre desconocido',
		tvBundle: {
			nontvService: 'sin contratar',
			total: 'total',
			extra: 'extra',
			esent: 'esencial',
		},
		onlineTvBundle: {
			standalone: 'standalone ({0})',
			standard: 'standard ({0})',
			sin: 'no online tv',
		},
		consumptionTypes: {
			tv: 'tv',
			landline: 'fijo a fijo',
			international: 'fijo a roaming',
			mobile: 'fijo a móvil',
			data: 'datos',
			minutes: 'minutos',
			credit: 'saldo',
			sms: 'sms',
		},
		extraStatus: {
			active: 'activado',
			deactivate: 'desactivado',
			pending: 'pendiente',
		},
		specialDataBundlesStatus: {
			prepaidWithBenefitOrPostpaid: 'activado',
			prepaidWithoutBenefit: 'activado no disfrutando',
			basic: 'incluido en tarifa',
			promotion: 'promo',
		},
		benefitsStatus: {
			active: 'activado',
			inactive: 'desactivado',
			unknown: 'sin determinar',
		},
		tvOnlineStatus: {
			standard: 'tv online standard',
			standAlone: ' tv online standalone',
			notv: 'sin tv online',
		},
		tvStandaloneActivationForm: {
			pageName: 'productos y servicios:extras:entretenimiento:tv online standalone <bundle>:activacion',
			subCategoryLevel3: 'tv online standalone <bundle>',
		},
		extraChannelPreconfirmBuy: {
			pageName: 'productos y servicios:detalle de tarifa:canales extra:<tv channel>:activar canal',
			subcategoryLevel3: '<tv channel>',
		},
		extraChannelDetails: {
			pageName: 'productos y servicios:detalle de tarifa:canales extra:<tv channel>',
			pageScreen: '<tv channel>',
		},
		specialCall: 'Llamadas',
		specialSms: 'Mensajes',
		specialData: 'Datos',
		categories: {
			voice: 'extras de voz',
			data: 'extras de datos',
			sms: 'sms',
			fixed: 'fijo',
			international: 'internacional',
			adsl: 'adsl',
			fibre: 'fibra',
			tv: 'tv',
			roamingvoice: 'voz',
			roamingdata: 'datos',
			roamingsms: 'sms',
			entertainment: 'entretenimiento',
			credit: 'crédito',
			benefits: 'beneficios',
			datos: 'extras de datos',
			voz: 'extras de voz',
			entretenimiento: 'entretenimiento',
		},
		billStatus: {
			ready: 'factura disponible',
			pdf: 'factura disponible',
			inprogress: 'factura disponible en futuro',
			open: 'factura no disponible',
			error: 'error en carga de factura',
			generalerror: 'error en carga de factura',
			empty: 'factura no disponible',
			multiple: 'incidencias multiples',
		},
		standaloneOnlineTvChangeUsername: {
			pageName: 'productos y servicios:extras:entretenimiento:tv online standalone <bundle>:cambio de usuario',
			subcategoryLevel3: 'tv online standalone <bundle>',
		},
		standaloneOnlineTvDeactivation: {
			pageName: 'productos y servicios:extras:entretenimiento:tv online standalone <bundle>:desactivar',
			subcategoryLevel3: 'tv online standalone <bundle>',
		},
		standaloneOnlineTvPreconfirmation: {
			pageName: 'productos y servicios:extras:entretenimiento:tv online standalone <bundle>:preconfirmacion',
			subCategoryLevel3: 'tv online standalone <bundle>',
		},
		extraPreconfirmBuy: {
			pageName: 'productos y servicios:extras:<category>:<data bundle name>:activar bono',
			subCategoryLevel2: '<category>',
			subCategoryLevel3: '<data bundle name>',
		},
		extraPreConfirmDeactivate: {
			pageName: 'productos y servicios:extras:<category>:<data bundle name>:desactivar bono',
			subCategoryLevel2: '<category>',
			subCategoryLevel3: '<data bundle name>',
		},
		extrasListDetailsPageSection: {
			pageName: 'productos y servicios:extras:<category>:<data bundle name>',
			subCategoryLevel2: '<category>',
			pageScreen: '<data bundle name>',
		},
		entertainmentDetailsPageSection: {
			pageName: 'productos y servicios:extras:<category>:<data bundle name>',
			subCategoryLevel2: '<category>',
			pageScreen: '<data bundle name>',
		},
		extraListCategoryPageSection: {
			pageName: 'productos y servicios:extras:<category>',
			pageScreen: '<category>',
		},
		extraPageSection: {
			pay: {
				postpaid: 'postpaid',
				pospago: 'pospago',
				prepago: 'prepago',
			},
			data_list: {
				event_name: 'click en {extra}',
				page_name: 'productos y servicios:extras:resumen de extras',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'extras',
				page_subcategory_level_2: 'resumen de extras',
				page_subcategory_level_3: '',
				page_screen: 'resumen de extras',
			},
			data_category: {
				'&&events': '',
				'event_name': 'click en {bono}',
				'page_name': 'productos y servicios:extras:extras de datos',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'extras',
				'page_subcategory_level_2': 'extras de datos',
				'page_subcategory_level_3': '',
				'page_screen': 'extras de datos',
			},
			journey: {
				journey_name: 'gestion de bonos:inicio',
				journey_options: 'bono ilimitado',
				journey_type: 'compra',
			},
		},
		enjoyMorePreconfirmBuyPageSection: {
			pageName: 'productos y servicios:vodafone pass:<bundle name>:activar bono',
			pageLevel2: '<bundle name>',
		},
		onlineTvDetailsPageSection: {
			pageName: 'productos y servicios:extras:entretenimiento:tv online standalone <bundle>',
			pageScreen: 'tv online standalone <bundle>',
		},
		enjoyMoreDetailsPageSection: {
			pageName: 'productos y servicios:vodafone pass:<bundle name>',
			pageScreen: '<bundle name>',
		},
		promotionDetails: {
			pageName: 'productos y servicios:detalle de tarifa:promocion:<promotion name>',
			pageScreen: '<promotion name>',
		},
		preconfirmacionPromotion: {
			pageName: 'productos y servicios:detalle de tarifa:promocion:<promotion name>:preconfirmacion',
			subcategoryLevel3: '<promotion name>',
		},
		promotionMoreDetails: {
			pageName: 'productos y servicios:detalle de tarifa:promocion:<promotion name>:mas detalles',
			subcategoryLevel3: '<promotion name>',
		},
		enjoyMorePreconfirmDeactivateBundle: {
			pageName: 'productos y servicios:vodafone pass:<bundle name>:desactivar bono',
			subcategoryLevel2: '<bundle name>',
		},
		enjoyMorePreconfirmBuyBundle: {
			pageName: 'productos y servicios:vodafone pass:<bundle name>:activar bono',
			subcategoryLevel2: '<bundle name>',
		},
		billDetailsAlarmPage_name: {
			nuevoProducto: 'nuevo producto',
			nuevoProductoYPagoPorAdelantado: 'nuevo producto y pago por adelantado',
			cambioDeTarifa: 'cambio de tarifa',
			cambioDeTarifaYPagoPorAdelantado: 'cambio de tarifa y pago por adelantado',
			descuento: 'descuento',
			servicioDeTv: 'servicio de tv',
			megas: '+ megas',
			reconexionTrasCancelacion: 'reconexion tras cancelacion',
			servicioIdentificacionDeLlamadas: 'servicio identificacion de llamadas',
			consumoExtra: 'consumo extra',
			notasDeAbono: ' notas de abono',
		},
		billDetailsAlarmsreplacedVar: {
			pageName: 'facturas:mis facturas:detalle de factura:alarma:{0}',
			LevelAndPageScreen: '{0}',
		},
		billDetailsHelpOverlayReplaceVar: {
			page_name: 'facturas:mis facturas:detalle de factura:concepto:{1}',
			page_screen: '{1}',
		},
		journey_subcategory: {
			manual: 'manual',
			network: 'por red',
			seamlessLogin: 'autorizado',
			editDetails: 'datos de usuario',
			addAddress: 'añadir direccion',
			editAddress: 'editar direccion',
			bankAdress: 'direccion de domicializacion',
			bankData: 'datos bancarios',
			editUsername: 'modificar usuario',
			editPassword: 'modificar clave',
		},
		journey_options: {
			upgradeTvPackage: {
				gtotn: 'total',
				gextr: 'extra',
			},
		},
		bill: {
			billVariables: {
				billing_ebilling_active: 'ebilling activado',
				billing_ebilling_deactive: 'ebilling desactivado',
			},
			bill_Payment: {
				journey_subcategory: {
					actual: 'pagar factura actual ',
					porAdelantado: 'pagar factura por adelantado',
					adeudada: 'pagar factura adeudada',
				},
				journey_model: {
					completo: 'pago completo ',
					fraccionado: 'pago fraccionado',
				},
			},
			consumptionTabs: {
				voice: 'llamadas',
				data: 'internet',
				sms: 'mensajes',
			},
			consumptionSecondTabs: {
				extras: 'extras',
				incluido: 'incluido',
				todo: 'todo',
			},
		},
		pageNames: {
			bill: {
				billOverview: 'billOverview',
				billDetails: 'billDetails',
				DevicesInvoices: 'devicesInvoices',
				CreditNotes: 'creditNotes',
				BillReady: 'billReady',
				consumptionType: {
					BillingUsages: 'billingServiceUsages',
					CurrentConsumption: 'currentConsumption',
				},
			},
		},
		faultManagement: {
			openTicket: {
				pageName: 'averias:<temp-name>',
				pageScreen: '<temp-name>',
			},
		},
		afterTopUp: {
			withPromoCode: 'promo:opciones disponibles:',
			withOptions: 'opciones disponibles:',
		},
	},
	login: {
		page: {
			site_section: 'login',
			page_subcategory_level_1: 'login',
			page_detail: 'login',
			page_typology: 'principal',
			visitor_login_status: 'no logado',
		},
		start: {
			journey: {
				journey_name: 'login',
				journey_category: 'particulares contrato',
				journey_subcategory: 'postpago',
				journey_type: 'servicio',
				journey_detail: 'start',
				journey_step: 'login:start',
				journey_process: 'manual',
			},
			event_name: 'login:start',
			event_category: eventCategory.evento,
			event_context: 'login',
			event_label: 'start',
		},
		success: {
			journey: {
				journey_name: 'login',
				journey_category: 'particulares contrato',
				journey_subcategory: 'postpago',
				journey_type: 'servicio',
				journey_detail: 'ok',
				journey_step: 'login:ok',
				journey_process: 'manual',
			},
			event_name: 'login:ok',
			event_category: eventCategory.evento,
			event_context: 'login',
			event_label: 'ok',
			serviceAES256: '',
		},
		error: {
			journey: {
				journey_name: 'login',
				journey_category: 'particulares contrato',
				journey_subcategory: 'postpago',
				journey_type: 'servicio',
				journey_detail: 'ko',
				journey_step: 'login:ko',
				journey_process: 'manual',
			},
			event_name: 'login:ko',
			event_category: eventCategory.evento,
			event_context: 'login',
			event_label: 'ko',
		},
		registerButtonClicked: {
			eventName: 'login:clic en registrar',
			data: {
				event_name: 'clic en registrar',
			},
		},
		orderMangmentnavigateToEcareClicked: {
			eventName: 'login:clic en ver estado de pedidos',
			data: {
				event_name: 'clic en ver estado de pedidos',
			},
		},
		orderMangmentlogOutClicked: {
			eventName: 'login:clic en cerrar sesion',
			data: {
				event_name: 'clic en cerrar sesion',
			},
		},
		forgetPasswordButtonClicked: {
			eventName: 'login:clic en recuperar contraseña',
			data: {
				event_name: 'clic en recuperar contraseña',
			},
		},
		popupIconClicked: {
			eventName: 'login:clic en user tooltip',
			data: {
				event_name: 'clic en user tooltip',
			},
		},
		showHidePasswordIconClicked: {
			eventName: 'login:clic en password tooltip',
			data: {
				event_name: 'clic en password tooltip',
			},
		},
		helpButton: {
			eventName: 'login:clic en ayuda',
			data: {
				event_name: 'clic en ayuda',
			},
		},
		coverageMapButton: {
			eventName: 'login:clic en mapa de cobertura',
			data: {
				event_name: 'clic en mapa de cobertura',
			},
		},
		storeFinder: {
			eventName: 'login:clic en localiza una tienda',
			data: {
				event_name: 'clic en localiza una tienda',
			},
		},
		pager: {
			eventName: 'login:clic en pago',
			data: {
				event_name: 'clic en pago',
			},
		},
		topUp: {
			eventName: 'login:click en recarga',
			data: {
				event_name: 'click en recarga',
			},
		},
		preregister: {
			view: {
				'page_name': 'login:preregistro:ok',
				'page_section': 'login',
				'page_subcategory_level_1': 'preregistro',
				'page_subcategory_level_2': 'ok',
				'page_subcategory_level_3': '',
				'page_screen': 'ok',
				'asset_name': 'mivoweb',
				'navigation_level_1': 'login',
				'navigation_level_2': 'preregistro',
				'navigation_level_3': 'ok',
				'navigation_level_4': '',
				'navigation_level_5': '',
				'&&events': 'event28',
				'journey_name': 'confirmacion preregistro',
				'journey_category': '{userType}',
				'journey_subcategory': '{siteType}',
				'journey_type': 'servicio',
				'journey_detail': 'ok',
				'journey_environment': 'privada',
				'journey_step': 'confirmacion preregistro:ok',
			},
			clickGoToOrders: {
				eventName: 'mi vodafone:preregistro:fin proceso registro:click en acceder pedidos',
				data: {
					'event_name': 'acceder pedidos',
					'event_action': 'click',
					'event_category': 'boton',
					'event_context': 'preregistro',
					'event_label': 'ver pedidos',
					'&&events': '',
					'journey_name': '',
					'journey_category': '',
					'journey_subcategory': '',
					'journey_type': '',
					'journey_detail': '',
					'journey_environment': '',
					'journey_step': '',
				},
			},
		},
	},
	myAccount: {
		editPrefrences: {
			firstStep: {
				eventName: 'editar preferencias de usuario:start',
				data: {
					journey_name: 'editar preferencias de usuario:start',
					journey_category: 'editar preferencias de usuario',
					journey_type: 'eservice',
				},
			},
			success: {
				eventName: 'editar preferencias de usuario:ok',
				data: {
					journey_name: 'editar preferencias de usuario:ok',
					journey_category: 'editar preferencias de usuario',
					journey_type: 'eservice',
				},
			},
			error: {
				eventName: 'editar preferencias de usuario:ko',
				data: {
					journey_name: 'editar preferencias de usuario:ko',
					journey_category: 'editar preferencias de usuario',
					journey_type: 'eservice',
					journey_error_category: '',
					journey_error_description: '',
				},
			},
		},
		editPremissions: {
			firstStep: {
				eventName: 'editar permisos de usuario:start',
				data: {
					journey_name: 'editar permisos de usuario:start',
					journey_category: 'editar permisos de usuario',
					journey_type: 'eservice',
					journey_options: '',
				},
			},
			success: {
				eventName: 'editar permisos de usuario:ok',
				data: {
					journey_name: 'editar permisos de usuario:ok',
					journey_category: 'editar permisos de usuario',
					journey_type: 'eservice',
					journey_options: '',
				},
			},
			error: {
				eventName: 'editar permisos de usuario:ko',
				data: {
					journey_name: 'editar permisos de usuario:ko',
					journey_category: 'editar permisos de usuario',
					journey_type: 'eservice',
					journey_error_category: '',
					journey_error_description: '',
					journey_options: '',
				},
			},
		},
		editPremissionsDiagnosticGDPR: {
			firstStep: {
				eventName: 'editar permiso diagnostico de mi vodafone:start',
				data: {
					journey_name: 'editar permiso diagnostico de mi vodafone:start',
					journey_category: 'editar permiso diagnostico de mi vodafone',
					journey_subcategory: 'no permitir',
					journey_type: 'eservice',
				},
			},
			success: {
				eventName: 'editar permiso diagnostico de mi vodafone:ok',
				data: {
					journey_name: 'editar permiso diagnostico de mi vodafone:ok',
					journey_category: 'editar permiso diagnostico de mi vodafone',
					journey_subcategory: 'no permitir',
					journey_type: 'eservice',
				},
			},
			error: {
				eventName: 'editar permiso diagnostico de mi vodafone:ko',
				data: {
					journey_name: 'editar permiso diagnostico de mi vodafone:ko',
					journey_category: 'editar permiso diagnostico de mi vodafone',
					journey_subcategory: 'no permitir',
					journey_error_category: '',
					journey_error_description: 'error en el guardado de datos',
					journey_type: 'eservice',
					journey_error_code: '',
				},
			},
		},
		editUserDetails: {
			start: {
				eventName: 'editar datos de usuario:start',
				data: {
					journey_name: 'editar datos de usuario:start',
					journey_category: 'editar datos de usuario',
					journey_subcategory: '',
					journey_type: 'eservice',
				},
			},
			success: {
				eventName: 'leditar datos de usuario:ok',
				data: {
					journey_name: 'editar datos de usuario:ok',
					journey_category: 'editar datos de usuario',
					journey_subcategory: '', // will be (manual / por red / autorizado)
					journey_type: 'eservice',
				},
			},
			error: {
				eventName: 'editar datos de usuario:ko',
				data: {
					journey_name: 'editar datos de usuario:ko',
					journey_category: 'editar datos de usuario',
					journey_subcategory: '', //  (datos de usuario / editar direccion / añadir direccion)
					journey_error_category: 'error de servidor',
					journey_error_description: '',
					journey_type: 'eservice',
				},
			},
		},
		editPayMethods: {
			start: {
				eventName: 'editar datos de pago:start',
				data: {
					journey_name: 'editar datos de pago:start',
					journey_category: 'editar datos de pago',
					journey_subcategory: '', // (direccion de domicializacion / datos bancarios)
					journey_type: ' eservice',
				},
			},
			smsVerification: {
				eventName: 'editar datos de pago:verificacion de sms',
				data: {
					journey_name: 'editar datos de pago:verificacion de sms',
					journey_category: 'editar datos de pago',
					journey_subcategory: '', // (direccion de domicializacion / datos bancarios)
					journey_type: ' eservice',
				},
			},
			success: {
				eventName: 'editar datos de pago:ok',
				data: {
					journey_name: 'editar datos de pago:ok',
					journey_category: 'editar datos de pago',
					journey_subcategory: '', // (direccion de domicializacion / datos bancarios)
					journey_type: 'eservice',
				},
			},
			error: {
				eventName: 'editar datos de pago:ko',
				data: {
					journey_name: 'editar datos de pago:ko',
					journey_category: 'editar datos de pago',
					journey_subcategory: '', //  (direccion de domicializacion / datos bancarios)
					journey_error_category: 'error de servidor',
					journey_error_description: 'error en acceso a capa REST',
					journey_type: 'eservice',
				},
			},
		},
		serviceAccessDetails: {
			start: {
				eventName: 'editar datos de acceso:start',
				data: {
					journey_name: 'editar datos de acceso:start',
					journey_category: 'editar datos de acceso',
					journey_subcategory: '', // (modificar usuario / modificar clave)
					journey_type: ' eservice',
				},
			},
			success: {
				eventName: 'editar datos de acceso:ok',
				data: {
					journey_name: 'editar datos de acceso:ok',
					journey_category: 'editar datos de acceso',
					journey_subcategory: '', // (modificar usuario / modificar clave)
					journey_type: 'eservice',
				},
			},
			error: {
				eventName: 'editar datos de acceso:ko',
				data: {
					journey_name: 'editar datos de acceso:ko',
					journey_category: 'editar datos de acceso',
					journey_subcategory: '', // (modificar usuario / modificar clave)
					journey_error_category: 'error de servidor',
					journey_error_description: 'error en la integracion de datos',
					journey_type: 'eservice',
					journey_error_code: '1007',
				},
			},
		},
		resendSMS: {
			start: {
				eventName: '<page_name>:clic en enviar otro SMS',
				data: {
					event_name: 'clic en enviar otro SMS',
				},
			},
		},
		commitmentsAction: {
			openAccordion: {
				eventName: 'mi cuenta:mis permanencias:detalle de permanencias:{0}:clic en desplegar',
				data: {
					event_name: 'clic en desplegar',
				},
			},
			closeAccordion: {
				eventName: 'mi cuenta:mis permanencias:detalle de permanencias:{0}:clic en cerrar',
				data: {
					event_name: 'clic en cerrar',
				},
			},
			accordionCTA: {
				eventName: 'mi cuenta:mis permanencias:detalle de permanencias:commitment contract:clic en CTA',
				data: {
					event_name: 'clic en CTA',
				},
			},
		},
		addAuthorizedUser: {
			firstStep: {
				eventName: 'autorizar usuario:start',
				data: {
					journey_name: 'autorizar usuario:start',
					journey_category: 'autorizar usuario',
					journey_type: 'eservice',
				},
			},
			success: {
				eventName: 'autorizar usuario:ok',
				data: {
					journey_name: 'autorizar usuario:ok',
					journey_category: 'autorizar usuario',
					journey_type: 'eservice',
				},
			},
			error: {
				eventName: 'autorizar usuario:ko',
				data: {
					journey_name: 'autorizar usuario:ko',
					journey_category: 'autorizar usuario',
					journey_type: 'eservice',
					journey_error_category: '',
					journey_error_description: '',
				},
			},
		},
		clickOnReintentarEditBankInfoOTPPopUp: {
			eventName: 'mi cuenta:metodos de pago:editar datos bancarios:verificación de sms:clic en reintentar',
			data: {
				event_name: 'clic en reintentar',
			},
		},
		clickOnContinuarEditBankInfoOTPPopUp: {
			eventName: 'mi cuenta:metodos de pago:editar datos bancarios:verificación de sms:clic en continuar popup',
			data: {
				event_name: 'clic en continuar popup',
			},
		},
		clickOnContinuarEditBankInfoOTP: {
			eventName: 'mi cuenta:metodos de pago:editar datos bancarios:verificación de sms:clic en continuar',
			data: {
				event_name: 'clic en continuar',
			},
		},
	},
	upgrade: {
		start: {
			eventName: 'upgrade:start',
			data: {
				journey_name: 'upgrade:start',
				journey_category: 'upgrade',
				journey_type: 'otros',
			},
		},
		success: {
			eventName: 'upgrade:ok',
			data: {
				journey_name: 'upgrade:ok',
				journey_category: 'upgrade',
				journey_type: 'otros',
			},
		},
		error: {
			eventName: 'upgrade:ko',
			data: {
				journey_name: 'upgrade:ko',
				journey_category: 'upgrade',
				journey_type: 'otros',
			},
		},
	},
	productAndServices: {
		changeTariff: {
			eventName: 'productos y servicios:resumen de productos y servicios:cambiar de tarifa',
			data: {
				'event_name': 'cambiar de tarifa',
				'event_category': 'boton',
				'event_context': 'mi tarifa',
				'event_label': 'cambiar de tarifa',
				'entrypoint_section': 'pys',
				'entrypoint_location': 'card producto',
				'entrypoint_title': 'cambio tarifa',
				'journey_name': 'cambio tarifa prepago',
				'&&events': 'event70',
			},
		},
		simExpirationRecharge: {
			eventName: 'productos y servicios:resumen de productos y servicios:recargar',
			data: {
				'event_name': 'recargar',
				'event_category': 'boton',
				'event_context': 'mi tarifa',
				'event_label': 'recargar',
				'entrypoint_section': 'pys',
				'entrypoint_location': 'card producto',
				'entrypoint_title': 'recargar',
				'journey_name': 'recargar saldo',
				'&&events': 'event70',
			},
		},
		addBonus: {
			eventName: 'productos y servicios:resumen de productos y servicios:ver bonos',
			data: {
				'event_name': 'ver bonos',
				'event_category': 'boton',
				'event_context': 'mi tarifa',
				'event_label': 'ver bonos',
				'entrypoint_section': 'pys',
				'entrypoint_location': 'card producto',
				'entrypoint_title': 'ver bonos',
				'journey_name': 'gestion de bonos',
				'&&events': 'event70',
			},
		},
		careLanding: {
			page: {
				page_name: '<page>:seguro movil:<type>',
				page_subcategory_level_1: 'seguro movil',
				journey_name: 'seguro movil',
				journey_subcategory: 'pospago',
				journey_type: 'transaccional',
			},
			eventName: 'click en boton <cta>',
			productsTemplate:
				'seguro;<product name>;1;;;|eVar182=<product code>|eVar184=<product_promotion name>|eVar187=addons:fide|eVar188=primario|eVar181=<product msisdn>|eV',
		},
		careCard: {
			view: {
				page_name: 'productos y servicios:resumen de productos y servicios',
				event_name: 'impresiones entrypoint',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'resumen de productos y servicios',
				page_screen: 'resumen de productos y servicios',
				['&&events']: 'event75',
				entrypoint_list: 'es=pys|jn=seguro movil|let=<name>',
				['&&products']:
					'seguro;<name>;1;;;|eVar182=<code>|eVar184=<promotion>|eVar187=addons:fide|eVar188=primario|eVar181=<msisdn>|eV',
			},
			click: {
				page_name: 'productos y servicios:resumen de productos y servicios',
				event_name: 'click en entrypoint',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'resumen de productos y servicios',
				page_screen: 'resumen de productos y servicios',
				journey_name: 'seguro movil',
				journey_subcategory: 'pospago',
				journey_type: 'transaccional',
				['&&events']: 'event71',
				entrypoint_section: 'pys',
				entrypoint_location: 'banner vodafone care',
				['&&products']:
					'seguro;<name>;1;;;|eVar182=<code>|eVar184=<promotion>|eVar187=addons:fide|eVar188=primario|eVar181=<msisdn>|eV',
			},
		},
		careInfo: {
			page: {
				page_name: 'tienda:home:protege tu movil',
				page_section: 'tienda',
				page_subcategory_level_1: 'home',
				page_subcategory_level_2: 'protege tu movil',
				page_screen: 'protege tu movil',
				['&&products']: 'seguro;;1;;;eVar180=alta seguro|eVar187=addons:fide|eVar188=primario|eVar181=<msisdn>|eV',
				journey_name: 'seguro movil',
				journey_type: 'transaccional',
			},
			eventName: 'click en boton <cta>',
			overlay_exit: {
				page_name: 'tienda:home:protege tu movil:contratacion:exit',
				page_section: 'tienda',
				page_subcategory_level_1: 'home',
				page_subcategory_level_2: 'protege tu movil',
				page_subcategory_level_3: 'contratacion',
				page_subcategory_level_4: 'exit',
				navigation_level_1: 'tienda',
				navigation_level_2: 'home',
				navigation_level_3: 'protege tu movil',
				navigation_level_4: 'contratacion',
				navigation_level_5: 'exit',
				page_screen: 'exit',
				asset_name: 'mivoweb',
				page_platform: 'web',
				event_name: '',
				journey_name: 'seguro movil',
				journey_type: 'transaccional',
				journey_category: '',
				journey_subcategory: '',
				journey_process: 'activar',
				journey_detail: 'exit',
				journey_step: '<journey_name>:exit',
			},
			['&&products']:
				'seguro;;1;;;eVar180=alta seguro|eVar187=addons:fide|eVar188=primario|eVar181=<product msisdn>|eVar200=publica',
			overlay_exit_continue: {
				'event_name': 'continuar',
				'event_category': 'boton',
				'event_label': 'continuar',
				'&&events': 'event106',
			},
			overlay_exit_leave: {
				'event_name': 'abandonar',
				'event_category': 'boton',
				'event_label': 'abandonar',
				'&&events': 'event105',
			},
		},
		EnableDisableWiFiJourney: {
			firstStep: {
				eventName: 'gestionar wifi:start',
				data: {
					journey_name: 'gestionar wifi:start',
					journey_category: 'gestionar wifi',
					journey_subcategory: '',
					journey_type: 'eservice',
				},
			},
			secondStep: {
				eventName: 'gestionar wifi:preconfirmacion',
				data: {
					journey_name: 'gestionar wifi:preconfirmacion',
					journey_category: 'gestionar wifi',
					journey_subcategory: '',
					journey_type: 'eservice',
				},
			},
			success: {
				eventName: 'gestionar wifi:ok',
				data: {
					journey_name: 'gestionar wifi:ok',
					journey_category: 'gestionar wifi',
					journey_subcategory: '',
					journey_type: 'eservice',
				},
			},
			error: {
				eventName: 'gestionar wifi:ko',
				data: {
					journey_name: 'gestionar wifi:ko',
					journey_category: 'gestionar wifi',
					journey_subcategory: '',
					journey_type: 'eservice',
					journey_error_category: 'error de servidor',
					journey_error_description: 'error en el guardado de datos',
					journey_error_code: '1007',
				},
			},
		},
		ManageNetworkWiFiJourney: {
			firstStep: {
				eventName: 'gestionar wifi:start',
				data: {
					journey_name: 'gestionar wifi:start',
					journey_category: 'gestionar wifi',
					journey_subcategory: 'cambiar {0}',
					journey_type: 'eservice',
				},
			},
			secondStep: {
				eventName: 'gestionar wifi:preconfirmacion',
				data: {
					journey_name: 'gestionar wifi:preconfirmacion',
					journey_category: 'gestionar wifi',
					journey_subcategory: 'cambiar {0}',
					journey_type: 'eservice',
				},
			},
			success: {
				eventName: 'gestionar wifi:ok',
				data: {
					journey_name: 'gestionar wifi:ok',
					journey_category: 'gestionar wifi',
					journey_subcategory: 'cambiar {0}',
					journey_type: 'eservice',
				},
			},
			error: {
				eventName: 'gestionar wifi:ko',
				data: {
					journey_name: 'gestionar wifi:ko',
					journey_category: 'gestionar wifi',
					journey_subcategory: 'cambiar {0}',
					journey_type: 'eservice',
					journey_error_category: 'error de servidor',
					journey_error_description: 'error en el guardado de datos',
					journey_error_code: '1007',
				},
			},
		},
		clickOnPrepaidrouteToTopUp: {
			eventName: 'productos y servicios:resumen de productos y servicios:clic en recargar',
			data: {
				event_name: 'clic en recargar',
			},
		},
		// HBO Restriction
		removeHBORestriction: {
			start: {
				eventName: 'eliminar restriccion:start',
				data: {
					journey_name: 'eliminar restriccion:start',
					journey_category: 'eliminar restriccion',
					journey_subcategory: '',
					journey_type: 'eservice',
				},
			},
			success: {
				eventName: 'eliminar restriccion:ok',
				data: {
					journey_name: 'eliminar restriccion:ok',
					journey_category: 'eliminar restriccion',
					journey_subcategory: '',
					journey_type: 'eservice',
				},
			},
			error: {
				eventName: 'eliminar restriccion:ko',
				data: {
					journey_name: 'eliminar restriccion:ko',
					journey_category: 'eliminar restriccion',
					journey_subcategory: '',
					journey_type: 'eservice',
					journey_error_category: '',
					journey_error_description: '',
					journey_error_code: '',
				},
			},
			activate: {
				eventName:
					'productos y servicios:extras:entretenimiento:{packageName}:overlay restriccion pago terceros:clic en {ctaText}',
				data: {
					event_name: 'clic en {ctaText}',
				},
			},
			cancel: {
				eventName:
					'productos y servicios:extras:entretenimiento:{packageName}:overlay restriccion pago terceros:clic en {ctaText}',
				data: {
					event_name: 'clic en {ctaText}',
				},
			},
		},
		manageTvExtras: {
			firstStep: {
				eventName: 'gestionar extras tv:start',
				data: {
					journey_name: 'gestionar extras tv:start',
					journey_category: 'gestionar extras tv',
					journey_subcategory: '{action}',
					journey_options: '',
				},
			},
			secondStep: {
				eventName: 'gestionar extras tv:solicitud de datos',
				data: {
					journey_name: 'gestionar extras tv:solicitud de datos',
					journey_category: 'gestionar extras tv',
					journey_subcategory: '{action}',
					journey_options: '',
				},
			},
			preconfirmation: {
				eventName: 'gestionar extras tv:preconfirmacion',
				data: {
					journey_name: 'gestionar extras tv:preconfirmacion',
					journey_category: 'gestionar extras tv',
					journey_subcategory: '{action}',
					journey_options: '',
				},
			},
			success: {
				eventName: 'gestionar extras tv:ok',
				data: {
					journey_name: 'gestionar extras tv:ok',
					journey_category: 'gestionar extras tv',
					journey_subcategory: '{action}',
					journey_options: '',
				},
			},
			error: {
				eventName: 'gestionar extras tv:ko',
				data: {
					journey_name: 'gestionar extras tv:ko',
					journey_category: 'gestionar extras tv',
					journey_subcategory: '{action}',
					journey_options: '{name}',
					journey_error_category: 'error en provisionamiento',
					journey_error_description: 'error de servidor',
					journey_error_code: '1007',
				},
			},
		},
		clickOnPrepaidInfo: {
			eventName: 'productos y servicios:resumen de productos y servicios:clic en beneficio activo informacion',
			data: {
				event_name: 'clic en beneficio activo informacion',
			},
		},
		clickOnPromotions: {
			eventName: 'productos y servicios:detalle de tarifa:promocion:clic en <promotion name>',
			data: {
				event_name: 'clic en <promotion name>',
			},
		},
		activatePromotion: {
			eventName: 'productos y servicios:resumen de productos y servicios:clic en activar promo',
			data: {
				event_name: 'clic en activar promo',
			},
		},
		activatePromotionJourney: {
			firstStep: {
				eventName: 'activar promocion tarifa:start',
				data: {
					journey_name: 'activar promocion tarifa:start',
					journey_category: 'activar promocion tarifa',
					journey_options: '',
					journey_type: 'eservice',
				},
			},
			secondStep: {
				eventName: 'activar promocion tarifa:preconfirmacion',
				data: {
					journey_name: 'activar promocion tarifa:preconfirmacion',
					journey_category: 'activar promocion tarifa',
					journey_options: '',
					journey_type: 'eservice',
				},
			},
			success: {
				eventName: 'activar promocion tarifa:ok',
				data: {
					journey_name: 'activar promocion tarifa:ok',
					journey_category: 'activar promocion tarifa',
					journey_options: '',
					journey_type: 'eservice',
				},
			},
			error: {
				eventName: 'activar promocion tarifa:ko',
				data: {
					journey_name: 'activar promocion tarifa:ko',
					journey_options: '',
					journey_category: 'activar promocion tarifa',
					journey_type: 'eservice',
				},
			},
		},
		secureNet: {
			activateDeactivate: {
				firstStep: {
					eventName: 'gestionar securenet:start',
					data: {
						journey_name: 'gestionar securenet:start',
						journey_category: 'gestionar securenet',
						journey_subcategory: '',
						journey_type: 'eservice',
					},
				},
				preconfirmation: {
					eventName: 'gestionar securenet:preconfirmacion',
					data: {
						journey_name: 'gestionar securenet:preconfirmacion',
						journey_category: 'gestionar securenet',
						journey_subcategory: '',
						journey_type: 'eservice',
					},
				},
				success: {
					eventName: 'gestionar securenet:ok',
					data: {
						journey_name: 'gestionar securenet:ok',
						journey_category: 'gestionar securenet',
						journey_subcategory: '',
						journey_type: 'eservice',
					},
				},
				error: {
					eventName: 'gestionar securenet:ko',
					data: {
						journey_name: 'gestionar securenet:ko',
						journey_category: 'gestionar securenet',
						journey_subcategory: '',
						journey_type: 'eservice',
						journey_error_category: '',
						journey_error_description: '',
						journey_error_code: '',
					},
				},
			},
			secureNetFamilyCard: {
				object: {
					event_name: 'click en entrypoint',
					entrypoint_section: 'pys',
					entrypoint_location: 'card producto',
					entrypoint_position: 'undefined',
					entrypoint_title: 'securenet family gestion',
					journey_name: 'securenet family',
				},
				type: {
					activar: 'activar',
					gestion: 'gestion',
				},
			},
			secureNetFamilyCardView: {
				'page_name': 'productos y servicios:resumen de productos y servicios',
				'event_name': 'impresiones entrypoint',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'resumen de productos y servicios',
				'page_screen': 'resumen de productos y servicios',
				'asset_name': 'mivoweb',
				'navigation_level_1': 'productos y servicios',
				'navigation_level_2': 'resumen de productos y servicios',
				'event_category': 'visualizacion',
				'event_context': 'productos y servicios',
				'event_label': 'impresiones entrypoint',
				'&&events': 'event75',
				'entrypoint_list':
					'es=pys|el=card product|ep=undefined|jn=securenet family|en=undefined|et=securenet family gestion|ety=normal',
			},
			secureNetCard: {
				object: {
					'event_name': 'click en entrypoint',
					'&&events': 'event70',
					'entrypoint_section': 'pys',
					'entrypoint_location': 'card producto',
					'entrypoint_position': 'undefined',
					'entrypoint_title': 'securenet ',
					'journey_name': 'securenet',
				},
				type: {
					activar: 'activar',
					gestion: 'gestion',
				},
			},
			secureNetCardView: {
				'page_name': 'productos y servicios:resumen de productos y servicios',
				'event_name': 'impresiones entrypoint',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'resumen de productos y servicios',
				'page_screen': 'resumen de productos y servicios',
				'&&events': 'event75',
				'entrypoint_list': 'es=pys|el=card product|ep=undefined|jn=securenet|en=undefined|et=securenet ',
			},
			secureNetPage: {
				state: {
					'page_name': 'productos y servicios:securenet',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'securenet',
					'page_screen': 'securenet',
					'&&products': 'addons;securenet;1;;;eVar187=fide:principal',
				},
				bannerInteraction: {
					'page_name': 'productos y servicios:securenet',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'securenet',
					'page_screen': 'securenet',
					'event_name': 'click en {copyBtn}',
					'&&products': 'addons;securenet;1;;;eVar187=fide:principal',
				},
				toggleAction: {
					'event_name': 'click en toggle',
					'&&events': 'event10',
					'journey_name': 'securenet',
					'journey_category': '',
					'journey_subcategory': 'pospago',
					'journey_options': 'securenet',
					'journey_type': 'servicio',
					'journey_step': 'securenet:start',
					'journey_process': '',
				},
				activate_deactivate_modal: {
					'page_name': 'productos y servicios:securenet:preconfirmacion',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'securenet',
					'page_subcategory_level_2': 'preconfirmacion',
					'page_screen': 'preconfirmacion',
					'&&products': 'addons;securenet;1;;;eVar187=fide:principal',
					'&&events': 'event16',
					'journey_name': 'securenet',
					'journey_category': '',
					'journey_subcategory': 'pospago',
					'journey_options': 'securenet',
					'journey_type': 'servicio',
					'journey_step': 'securenet:preconfirmacion',
					'journey_process': '',
				},
				activate_deactivate_button: {
					'event_name': 'click en boton {copyBtn}',
					'journey_step': '',
					'&&events': '',
				},
				activate_modal_ok: {
					'page_name': 'productos y servicios:securenet:confirmacion',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'securenet',
					'page_subcategory_level_2': 'confirmacion',
					'page_screen': 'confirmacion',
					'&&products': 'addons;securenet;1;;;eVar187=fide:principal',
					'&&events': 'event11',
					'journey_name': 'securenet',
					'journey_category': '',
					'journey_subcategory': 'pospago',
					'journey_options': 'securenet',
					'journey_type': 'servicio',
					'journey_step': 'securenet:ok',
					'journey_process': 'activar',
				},
				deactivate_modal_ok: {
					'page_name': 'productos y servicios:securenet:confirmacion',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'securenet',
					'page_subcategory_level_2': 'confirmacion',
					'page_screen': 'confirmacion',
					'&&products': 'addons;securenet;1;;;eVar187=fide:principal',
					'&&events': 'event11',
					'journey_name': 'securenet',
					'journey_category': '',
					'journey_subcategory': 'pospago',
					'journey_options': 'securenet',
					'journey_type': 'servicio',
					'journey_step': 'securenet:ok',
					'journey_process': 'desactivar',
				},
				modal_ko: {
					'page_name': 'productos y servicios:securenet:error',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'securenet',
					'page_subcategory_level_2': 'error',
					'page_screen': 'error',
					'&&products': 'addons;securenet;1;;;eVar187=fide:principal',
					'&&events': 'event12,event100',
					'error_category': '',
					'error_descripcion': '',
					'error_type': '',
					'error_code': '',
					'journey_name': 'securenet',
					'journey_category': '',
					'journey_subcategory': 'pospago',
					'journey_options': 'securenet',
					'journey_type': 'servicio',
					'journey_step': 'securenet:ko',
					'journey_process': '',
				},
				activate_modal_ko: {
					journey_process: 'activar',
				},
				deactivate_modal_ko: {
					journey_process: 'desactivar',
				},
				open_exit_overlay: {
					'page_name': 'productos y servicios:<securenet|secureret familily>:exit',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': '<securenet|secureret familily>',
					'page_subcategory_level_2': 'exit',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': '<securenet|secureret familily>',
					'navigation_level_3': 'exit',
					'page_screen': 'exit',
					'asset_name': 'mivoweb',
					'page_platform': 'web',
					'event_name': '',
					'event_category': '',
					'event_label': '',
					'&&events': '',
					'journey_name': '<securenet|secureret familily>',
					'journey_category': '',
					'journey_subcategory': 'pospago',
					'journey_options': 'securenet',
					'journey_type': 'servicio',
					'journey_detail': 'exit',
					'journey_step': '<journeyname>:exit',
					'journey_process': 'activar',
				},
				close_exit_overlay: {
					'page_name': 'productos y servicios:<securenet|secureret familily>:exit',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': '<securenet|secureret familily>',
					'page_subcategory_level_2': 'exit',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': '<securenet|secureret familily>',
					'navigation_level_3': 'exit',
					'page_screen': 'exit',
					'asset_name': 'mivoweb',
					'page_platform': 'web',
					'event_name': 'continuar',
					'event_category': 'boton',
					'event_label': 'continuar',
					'&&events': 'event106',
					'journey_name': '<securenet|secureret familily>',
					'journey_category': '',
					'journey_subcategory': 'pospago',
					'journey_options': 'securenet',
					'journey_type': 'servicio',
					'journey_detail': 'exit',
					'journey_step': '<journeyname>:exit',
					'journey_process': 'activar',
				},
				exit_journey: {
					'page_name': 'productos y servicios:<securenet|secureret familily>:exit',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': '<securenet|secureret familily>',
					'page_subcategory_level_2': 'exit',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': '<securenet|secureret familily>',
					'navigation_level_3': 'exit',
					'page_screen': 'exit',
					'asset_name': 'mivoweb',
					'page_platform': 'web',
					'event_name': 'abandonar',
					'event_category': 'boton',
					'event_label': 'abandonar',
					'&&events': 'event105',
					'journey_name': '<securenet|secureret familily>',
					'journey_category': '',
					'journey_subcategory': 'pospago',
					'journey_options': 'securenet',
					'journey_type': 'servicio',
					'journey_detail': 'exit',
					'journey_step': '<journeyname>:exit',
					'journey_process': 'activar',
				},
			},
			secureNetFamilyPage: {
				state: {
					'page_name': 'productos y servicios:securenet family:informacion oferta:preconfirmacion',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'securenet family',
					'page_subcategory_level_2': 'informacion oferta',
					'page_subcategory_level_3': 'preconfirmacion',
					'page_screen': 'preconfirmacion',
					'&&products': 'addons;securenet family;1;;;eVar187=fide:principal',
					'&&events': 'event10',
					'journey_name': 'securenet family',
					'journey_category': '',
					'journey_subcategory': '',
					'journey_options': 'securenet family',
					'journey_type': 'servicio',
					'journey_step': 'securenet family:start',
					'journey_process': 'activar',
				},
				info_button: {
					'page_screen': 'informacion oferta',
					'event_name': 'click en boton {copyBtn}',
					'&&events': '',
					'journey_step': '',
				},
				activate_button: {
					'event_name': 'click en boton {copyBtn}',
					'&&events': 'event16',
					'journey_step': 'securenet family:preconfirmacion',
				},
				info_modal: {
					'page_name': 'productos y servicios:securenet family:saber mas',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'securenet family',
					'page_subcategory_level_2': 'saber mas',
					'page_screen': 'saber mas',
					'&&products': 'addons;securenet family;1;;;eVar187=fide:principal',
					'journey_name': 'securenet family',
					'journey_category': '',
					'journey_subcategory': '',
					'journey_options': 'securenet family',
					'journey_type': 'servicio',
					'journey_process': 'activar',
				},
			},
			secureNetFamilyLandingPage: {
				state: {
					'page_name': 'productos y servicios:securenet family',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'securenet family',
					'page_screen': 'securenet family',
					'&&products': 'addons;securenet family;1;;;eVar187=fide:principal',
					'&&events': 'event10',
					'journey_name': 'securenet family',
					'journey_category': '',
					'journey_subcategory': '',
					'journey_options': 'securenet family',
					'journey_type': 'servicio',
					'journey_step': 'securenet family:start',
					'journey_process': 'gestion',
				},
				interaction: {
					'event_name': 'click en {copyBtn}',
					'&&events': 'event16',
					'journey_step': 'securenet family:preconfirmacion',
				},
			},
			subCategory: {
				activate: 'activar',
				deactivate: 'desactivar',
			},
			vodafoneSecureNet: {
				view: {
					asset_name: 'mivoweb',
					page_screen: 'conecta un dispositivo',
					page_name: 'mi vodafone:que tengo contratado:servicio extra:securenet:configura o conecta un dispositivo',
					page_section: 'mi vodafone',
					page_subcategory_level_1: 'que tengo contratado',
					page_subcategory_level_2: 'servicio extra',
					page_subcategory_level_3: 'securenet',
					page_subcategory_level_4: 'configura o conecta un dispositivo',
					navigation_level_1: 'que tengo contratado',
					navigation_level_2: 'servicio extra',
					navigation_level_3: 'securenet',
					navigation_level_4: 'configura o conecta un dispositivo',
				},
			},
			securenetSingleSignOnOverlayGenericError: {
				view: {
					asset_name: 'mivoweb',
					page_screen: 'configura o conecta un dispositivo:error',
					page_name:
						'mi vodafone:que tengo contratado:servicio extra:securenet:configura o conecta un dispositivo:error',
					page_section: 'mi vodafone',
					page_subcategory_level_1: 'que tengo contratado',
					page_subcategory_level_2: 'servicio extra',
					page_subcategory_level_3: 'securenet',
					page_subcategory_level_4: 'configura o conecta un dispositivo:error',
					navigation_level_1: 'que tengo contratado',
					navigation_level_2: 'servicio extra',
					navigation_level_3: 'securenet',
					navigation_level_4: 'configura o conecta un dispositivo:error',
					error_type: 'tecnico',
					error_page:
						'mi vodafone:que tengo contratado:servicio extra:securenet:configura o conecta un dispositivo:error',
				},
				closebtn: {
					asset_name: 'mivoweb',
					page_screen: 'configura o conecta un dispositivo:error',
					page_name:
						'mi vodafone:que tengo contratado:servicio extra:securenet:configura o conecta un dispositivo:error',
					page_section: 'mi vodafone',
					page_subcategory_level_1: 'que tengo contratado',
					page_subcategory_level_2: 'servicio extra',
					page_subcategory_level_3: 'securenet',
					page_subcategory_level_4: 'configura o conecta un dispositivo:error',
					navigation_level_1: 'que tengo contratado',
					navigation_level_2: 'servicio extra',
					navigation_level_3: 'securenet',
					navigation_level_4: 'configura o conecta un dispositivo:error',
					event_name: 'click en cerrar',
					event_category: 'boton',
					event_context: 'configura o conecta un dispositivo error',
					event_label: 'cerrar',
				},
				primarybtn: {
					asset_name: 'mivoweb',
					page_screen: 'configura o conecta un dispositivo:error',
					page_name:
						'mi vodafone:que tengo contratado:servicio extra:securenet:configura o conecta un dispositivo:error',
					page_section: 'mi vodafone',
					page_subcategory_level_1: 'que tengo contratado',
					page_subcategory_level_2: 'servicio extra',
					page_subcategory_level_3: 'securenet',
					page_subcategory_level_4: 'configura o conecta un dispositivo:error',
					navigation_level_1: 'que tengo contratado',
					navigation_level_2: 'servicio extra',
					navigation_level_3: 'securenet',
					navigation_level_4: 'configura o conecta un dispositivo:error',
					event_name: 'click en entendido',
					event_category: 'boton',
					event_context: 'configura o conecta un dispositivo error',
					event_label: 'entendido',
				},
			},
		},
		digitalProduct: {
			activationPage: {
				state: {
					'page_name': 'productos y servicios:{product}:solicitar',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': '{product}',
					'page_subcategory_level_2': 'solicitar',
					'page_screen': 'solicitar',
					'journey_name': '{product}',
					'journey_category': '',
					'journey_subcategory': '',
					'journey_options': '{product}',
					'journey_type': 'servicio',
					'journey_process': 'activar',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': '{product}',
					'navigation_level_3': 'solicitar',
					'asset_name': 'mivoweb',
					'&&products': 'addons;{product};1;;;eVar187=fide:principal',
				},
				eventName: {
					event_name: 'click en boton {copy}',
				},
				events: {
					'&&events': 'event10',
				},
				journey_step: {
					journey_step: '{product}:start',
				},
				data: {
					event_category: 'boton',
					event_context: 'ficha {product}',
					event_label: '{copy}',
				},
			},
			formPage: {
				state: {
					'page_name': 'productos y servicios:{product}:formulario',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': '{product}',
					'page_subcategory_level_2': 'formulario',
					'page_screen': 'formulario',
					'journey_name': '{product}',
					'journey_category': '',
					'journey_subcategory': '',
					'journey_options': '{product}',
					'journey_type': 'servicio',
					'journey_process': 'activar',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': '{product}',
					'navigation_level_3': 'formulario',
					'asset_name': 'mivoweb',
					'&&products': 'addons;{product};1;;;eVar187=fide:principal',
				},
				eventName: {
					event_name: 'click en boton {copy}',
				},
				data: {
					event_category: 'boton',
					event_context: 'formulario {product}',
					event_label: '{copy}',
				},
			},
			modalKO: {
				state: {
					'page_name': 'productos y servicios:{product}:error',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': '{product}',
					'page_subcategory_level_2': 'error',
					'page_screen': 'error',
					'journey_name': '{product}',
					'journey_category': '',
					'journey_subcategory': '',
					'journey_options': '{product}',
					'journey_type': 'servicio',
					'journey_process': 'activar',
					'journey_step': '{product}:ko',
					'&&events': 'event12,event100',
					'error_category': '',
					'error_descripcion': '',
					'error_type': '',
					'error_code': '',
					'journey_error_category': '',
					'journey_error_description': '',
					'journey_error_code': '',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': '{product}',
					'navigation_level_3': 'error',
					'asset_name': 'mivoweb',
					'&&products': 'addons;{product};1;;;eVar187=fide:principal',
				},
			},
			modalOK: {
				state: {
					'page_name': 'productos y servicios:{product}:confirmacion',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': '{product}',
					'page_subcategory_level_2': 'confirmacion',
					'page_screen': 'confirmacion',
					'journey_name': '{product}',
					'journey_category': '',
					'journey_subcategory': '',
					'journey_options': '{product}',
					'journey_type': 'servicio',
					'journey_process': 'activar',
					'journey_step': '{product}:ok',
					'&&events': 'event11',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': '{product}',
					'navigation_level_3': 'confirmacion',
					'asset_name': 'mivoweb',
					'&&products': 'addons;{product};1;;;eVar187=fide:principal',
				},
			},
			exitModal: {
				state: {
					page_name: 'productos y servicios:{product}:formulario:salir',
					page_section: 'productos y servicios',
					page_subcategory_level_1: '{product}',
					page_subcategory_level_2: 'formulario',
					page_subcategory_level_3: 'salir',
					page_screen: 'formulario',
					journey_name: '{product}',
					journey_category: '',
					journey_subcategory: '',
					journey_options: '{product}',
					journey_type: 'servicio',
					journey_process: 'activar',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: '{product}',
					navigation_level_3: 'formulario',
					navigation_level_4: 'salir',
					asset_name: 'mivoweb',
				},
				action: {
					page_name: 'productos y servicios:{product}:formulario:salir',
					page_section: 'productos y servicios',
					page_subcategory_level_1: '{product}',
					page_subcategory_level_2: 'formulario',
					page_subcategory_level_3: 'salir',
					page_screen: 'salir',
					journey_name: '{product}',
					journey_category: '',
					journey_subcategory: '',
					journey_options: '{product}',
					journey_type: 'servicio',
					journey_process: 'activar',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: '{product}',
					navigation_level_3: 'formulario',
					navigation_level_4: 'salir',
					asset_name: 'mivoweb',
				},
				eventName: {
					event_name: 'click en boton {copy}',
				},
				data: {
					event_category: 'boton',
					event_context: 'modal {product}',
					event_label: '{copy}',
				},
			},
			conditionsBackdrop: {
				state: {
					page_name: 'productos y servicios:{product}:legal',
					page_section: 'productos y servicios',
					page_subcategory_level_1: '{product}',
					page_subcategory_level_2: 'legal',
					page_screen: 'legal',
					journey_name: '{product}',
					journey_category: '',
					journey_options: '{product}',
					journey_type: 'servicio',
					journey_process: 'activar',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: '{product}',
					navigation_level_3: 'legal',
					asset_name: 'mivoweb',
				},
			},
		},
		superWifi: {
			contractSuperwifi: {
				firstStep: {
					eventName: 'superwifi:start',
					data: {
						journey_name: 'superwifi:start',
						journey_category: 'superwifi',
						journey_subcategory: '',
						journey_type: 'compra',
					},
				},
				preconfirmation: {
					eventName: 'superwifi:preconfirmacion',
					data: {
						journey_name: 'superwifi:preconfirmacion',
						journey_category: 'superwifi',
						journey_subcategory: '',
						journey_type: 'compra',
					},
				},
				success: {
					eventName: 'superwifi:ok',
					data: {
						journey_name: 'superwifi:ok',
						journey_category: 'superwifi',
						journey_subcategory: '',
						journey_type: 'compra',
					},
				},
				error: {
					eventName: 'superwifi:ko',
					data: {
						journey_name: 'superwifi:ko',
						journey_category: 'superwifi',
						journey_subcategory: '',
						journey_type: 'compra',
						journey_error_category: 'error en el servidor',
						journey_error_description: 'error en el guardado de datos',
						journey_error_code: '1007',
					},
				},
			},
			subCategory: {
				activate: 'activar',
				deactivate: 'desactivar',
			},
		},
		superWifiPhase2: {
			mainScreen: {
				view: {
					'page_name': 'productos y servicios:resumen de productos y servicios',
					'event_name': 'impresiones entrypoint',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'resumen de productos y servicios',
					'page_screen': 'resumen de productos y servicios',
					'&&events': 'event75',
					'entrypoint_list': 'es={es}|el={el}|jn={jn}|et={et}|ep={ep}',
				},
				activateClick: {
					'page_name': 'productos y servicios:resumen de productos y servicios',
					'event_name': 'click en entrypoint',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'resumen de productos y servicios',
					'page_screen': 'resumen de productos y servicios',
					'&&events': 'event70',
					'entrypoint_section': 'pys',
					'entrypoint_location': 'card producto',
					'entrypoint_position': '4',
					'entrypoint_title': '{card_title}|{button_title}',
					'journey_name': 'superwifi',
					'journey_type': 'servicio',
					'journey_step': 'superwifi:resumen de productos y servicios',
					'journey_detail': 'resumen de productos y servicios',
				},
			},
			superWifiUpdate: {
				view: {
					'page_name': 'productos y servicios:superwifi:listado',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'superwifi',
					'page_subcategory_level_2': 'listado',
					'page_screen': 'listado',
					'&&events': 'event10',
					'journey_name': 'superwifi',
					'journey_process': 'activar',
					'journey_type': 'servicio',
					'journey_step': 'superwifi:listado',
					'journey_detail': 'listado',
				},
				deeplink: {
					entrypoint_section: 'deeplink',
					entrypoint_location: 'undefined',
					entrypoint_position: 'undefined',
					entrypoint_type: 'undefined',
					entrypoint_title: 'undefined',
				},
				continueClick: {
					'page_name': 'productos y servicios:superwifi:listado',
					'event_name': 'click en {button}',
					'event_context': 'add {product_name}',
					'event_category': 'boton',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'superwifi',
					'page_subcategory_level_2': 'listado',
					'&&products': 'superwifi;{product_name};1;{product_price}',
					'journey_name': 'superwifi',
					'journey_process': 'activar',
					'journey_type': 'servicio',
					'journey_step': 'superwifi:listado',
					'journey_detail': 'listado',
				},
				detailClick: {
					'page_name': 'productos y servicios:superwifi:listado',
					'event_name': 'click en detalles {product_name}',
					'event_context': 'detalles superwifi',
					'event_category': 'link',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'superwifi',
					'page_subcategory_level_2': 'listado',
					'&&products': 'superwifi;{product_name};1;{product_price}',
					'journey_name': 'superwifi',
					'journey_process': 'activar',
					'journey_type': 'servicio',
					'journey_step': 'superwifi:listado',
					'journey_detail': 'listado',
				},
				detailView: {
					'page_name': 'productos y servicios:{product_name}:detalles',
					'page_section': 'productos y servicios',
					'page_subcategory_level_2': 'detalles',
					'page_screen': 'detalles',
					'&&products': 'superwifi;{product_name};1;{product_price}',
					'journey_name': 'superwifi',
					'journey_process': 'activar',
					'journey_type': 'servicio',
					'journey_step': 'superwifi:detalles',
					'journey_detail': 'detalles',
				},
				overlay_exit: {
					page_name: 'productos y servicios:superwifi:exit',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'superwifi',
					page_subcategory_level_2: 'exit',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: 'superwifi',
					navigation_level_3: 'exit',
					page_screen: 'exit',
					asset_name: 'mivoweb',
					page_platform: 'web',
					event_name: '',
					journey_name: 'superwifi',
					journey_type: 'servicio',
					journey_process: 'activar',
					journey_detail: 'exit',
					journey_step: '<journey_name>:exit',
				},
				['&&products']: 'superwifi;{product_name};1;{product_price}',
				overlay_exit_continue: {
					'event_name': 'continuar',
					'event_category': 'boton',
					'event_label': 'continuar',
					'&&events': 'event106',
				},
				overlay_exit_leave: {
					'event_name': 'abandonar',
					'event_category': 'boton',
					'event_label': 'abandonar',
					'&&events': 'event105',
				},
			},
		},
		clickSuperWiFiLanding: {
			eventName: '{0} clic en {1}', // {0} page name {1} => <lo quiero superwifi|ver condiciones superwifi| gestionar superwifi>
			data: {
				event_name: 'clic en {1}', // {1} => <lo quiero superwifi|ver condiciones superwifi| gestionar superwifi>
			},
		},
		clickSuperWiFi: {
			eventName: '{0} clic en {1}', // {0} page name {1} => <llamar|link app store| link google play>
			data: {
				event_name: 'clic en {1}', // {1} =>  <llamar|link app store| link google play>
			},
		},
		activateSuperWifi: {
			start: {
				eventName: 'activate superwifi:start',
				data: {
					journey_name: 'activate superwifi:start',
					journey_category: 'activate superwifi',
				},
			},
			success: {
				eventName: 'activate superwifi:ok',
				data: {
					journey_name: 'activate superwifi:ok',
					journey_category: 'activate superwifi',
				},
			},
			error: {
				eventName: 'activate superwifi:ko',
				data: {
					journey_name: 'activate superwifi:ok',
					journey_category: 'activate superwifi',
					journey_error_category: '',
					journey_error_description: '',
				},
			},
		},
		/** Interactions */
		// launch on clicking button
		clickOnSuperWifiButton: {
			eventName: 'productos y servicios:resumen de productos y servicios:clic en lo quiero superwifi',
			data: {
				event_name: 'clic en lo quiero superwifi',
			},
		},
		clickOnSuperWifiGestionarButton: {
			eventName: 'productos y servicios:resumen de productos y servicios:clic en gestionar superwifi',
			data: {
				event_name: 'clic en gestionar superwifi',
			},
		},
		clickOnSuperWifiPending: {
			eventName: 'productos y servicios:resumen de productos y servicios:clic en ver condiciones superwifi',
			data: {
				event_name: 'clic en ver condiciones superwifi',
			},
		},
		clickOnActivateSuperWifi: {
			eventName: 'productos y servicios:resumen de productos y servicios:superwifi:clic en activar superwifi',
			data: {
				event_name: 'clic en activar superwifi',
			},
		},
		clickOnllamarSuperWifi: {
			data: {
				event_name: 'click en {button_text}',
				event_category: 'boton',
			},
		},
		clickOnMobileStoreSuperWifi: {
			data: {
				event_name: 'click en link {storeName}',
				event_label: 'descarga {storeName}',
				event_category: 'link',
			},
		},
		unfoldSuperWifiTermsAndConditions: {
			eventName: 'productos y servicios:resumen de productos y servicios:superwifi:clic en desplegar',
			data: {
				event_name: 'clic en desplegar',
			},
		},
		foldSuperWifiTermsAndConditions: {
			eventName: 'productos y servicios:resumen de productos y servicios:superwifi:clic en desplegar',
			data: {
				event_name: 'clic en cerrar',
			},
		},
		clickTabProductAndService: {
			eventName: 'productos y servicios:resumen de productos y servicios:click en {tab}',
			data: {
				event_name: 'acceder a {tab}',
				event_action: 'click',
				event_category: 'tab',
				event_context: 'productos y servicios',
				event_label: '{tab}',
			},
		},
		clickCarouselTabProductAndService: {
			eventName: 'productos y servicios:resumen de productos y servicios',
			data: {
				page_name: 'productos y servicios:resumen de productos y servicios',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'resumen de productos y servicios',
				page_subcategory_level_2: 'servicios para tu negocio',
				navigation_level_1: 'productos y servicios',
				page_detail: 'servicios para tu negocio',
				event_category: 'card',
				event_context: 'productos y servicios',
				page_typology: 'principal',
				event_label: '',
			},
		},
		clickBusinessService: {
			eventName: 'productos y servicios:resumen de productos y servicios',
			data: {
				page_name: 'productos y servicios:resumen de productos y servicios',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'resumen de productos y servicios',
				page_subcategory_level_2: 'servicios para tu negocio',
				navigation_level_1: 'productos y servicios',
				page_detail: 'servicios para tu negocio',
				event_category: 'boton',
				event_context: 'servicios para tu negocio',
				page_typology: 'principal',
				event_label: '',
			},
		},
		detailBusinessService: {
			eventName: 'productos y servicios:resumen de productos y servicios',
			data: {
				page_name: 'productos y servicios:resumen de productos y servicios:servicios para tu negocio:{litBoton}',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'resumen de productos y servicios',
				page_subcategory_level_2: 'servicios para tu negocio',
				page_subcategory_level_3: '',
				navigation_level_1: 'productos y servicios',
				page_detail: 'servicios para tu negocio:{litBoton}',
				page_typology: 'principal',
				event_label: '',
			},
		},
		deeplinkTabProductAndService: {
			event_name: 'deeplink a la tab de {tab} de productos y servicios',
			data: {
				'page_name': 'productos y servicios:resumen de productos y servicios:tab {tab}',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'resumen de productos y servicios',
				'page_subcategory_level_2': 'tab {tab}',
				'page_screen': 'tab {tab}',
				'event_name': 'deeplink a la tab de {tab} de productos y servicios',
				'asset_name': 'mivoweb',
				'navigation_level_1': 'productos y servicios',
				'navigation_level_2': 'resumen de productos y servicios',
				'navigation_level_3': 'tab {tab}',
				'event_category': 'link',
				'event_context': 'productos y servicios tab de {tab}',
				'event_label': 'click en deeplink',
				'&&events': 'event70',
				'entrypoint_section': 'deeplink',
				'entrypoint_location': 'undefined',
				'entrypoint_position': 'undefined',
				'entrypoint_title': 'undefined',
				'experience_name': 'undefined',
				'journey_name': 'undefined',
			},
		},
		clickOnComsuptionBonos: {
			eventName: 'productos y servicios:resumen de productos y servicios:consumo:click en consumo de mis bonos',
			data: {
				page_name: 'productos y servicios:resumen de productos y servicios:consumo',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'resumen de productos y servicios',
				page_subcategory_level_2: 'consumo',
				page_screen: 'consumo',
				event_name: 'click en consumo de mis bonos',
			},
		},
		overlayComsuptionBonos: {
			view: {
				page_name: 'productos y servicios:resumen de productos y servicios:consumo:consumo bonos',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'resumen de productos y servicios',
				page_subcategory_level_2: 'consumo',
				page_subcategory_level_3: 'consumo bonos',
				page_screen: 'consumo bonos',
			},
			clickOnViewDetails: {
				eventName:
					'productos y servicios:resumen de productos y servicios:consumo:consumo bonos:click en ver detalle de bonos {product}',
				data: {
					page_name: 'productos y servicios:resumen de productos y servicios:consumo:consumo bonos',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'resumen de productos y servicios',
					page_subcategory_level_2: 'consumo',
					page_subcategory_level_3: 'consumo bonos',
					page_screen: 'consumo bonos',
					event_name: 'click en ver detalle de bonos {product}',
				},
			},
			clickOnRetry: {
				eventName:
					'productos y servicios:resumen de productos y servicios:consumo:consumo bonos:click en reintentar general bonos',
				data: {
					page_name: 'productos y servicios:resumen de productos y servicios:consumo:consumo bonos',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'resumen de productos y servicios',
					page_subcategory_level_2: 'consumo',
					page_subcategory_level_3: 'consumo bonos',
					page_screen: 'consumo bonos',
					event_name: 'click en reintentar general bonos',
				},
			},
			clickOnClose: {
				eventName: 'productos y servicios:resumen de productos y servicios:consumo:consumo bonos:click en cerrar',
				data: {
					page_name: 'productos y servicios:resumen de productos y servicios:consumo:consumo bonos',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'resumen de productos y servicios',
					page_subcategory_level_2: 'consumo',
					page_subcategory_level_3: 'consumo bonos',
					page_screen: 'consumo bonos',
					event_name: 'click en cerrar',
				},
			},
		},
		activeStandAloneOnlineTv: {
			stepOne: {
				eventName: 'activar vodafone tv standalone:selecciona linea',
				data: {
					journey_name: 'activar vodafone tv standalone:selecciona linea',
					journey_category: 'activar vodafone tv standalone',
					journey_type: 'compra',
					journey_options: '',
				},
			},
			stepTwo: {
				eventName: 'activar vodafone tv standalone:formulario',
				data: {
					journey_name: 'activar vodafone tv standalone:formulario',
					journey_category: 'activar vodafone tv standalone',
					journey_type: 'compra',
					journey_options: '',
				},
			},
			stepThree: {
				eventName: 'activar vodafone tv standalone:preconfirmacion',
				data: {
					journey_name: 'activar vodafone tv standalone:preconfirmacion',
					journey_category: 'activar vodafone tv standalone',
					journey_type: 'compra',
					journey_options: '',
				},
			},
			success: {
				eventName: 'activar vodafone tv standalone:ok',
				data: {
					journey_name: 'activar vodafone tv standalone:ok',
					journey_category: 'activar vodafone tv standalone',
					journey_type: 'compra',
					journey_options: 'futbol',
				},
			},
			error: {
				eventName: 'activar vodafone tv standalone:ko',
				data: {
					journey_name: 'activar vodafone tv standalone:ko',
					journey_category: 'activar vodafone tv standalone',
					journey_type: 'compra',
					journey_options: 'futbol',
					journey_error_category: 'error de servidor',
					journey_error_description: '',
				},
			},
			errorDescription: 'error en provisionamiento',
		},
		activeStandrdOnlineTv: {
			start: {
				eventName: 'activar vodafone tv standard:formulario',
				data: {
					journey_name: 'activar vodafone tv standard:formulario',
					journey_category: 'activar vodafone tv standard',
					journey_type: 'compra',
				},
			},
			success: {
				eventName: 'activar vodafone tv standard:ok',
				data: {
					journey_name: 'activar vodafone tv standard:ok',
					journey_category: 'activar vodafone tv standard',
					journey_type: 'compra',
				},
			},
			error: {
				eventName: 'activar vodafone tv standard:ko',
				data: {
					journey_name: 'activar vodafone tv standard:ko',
					journey_category: 'activar vodafone tv standard',
					journey_type: 'compra',
					journey_error_category: 'error de servidor',
					journey_error_description: 'error en crm',
				},
			},
			errorDescription: 'error en crm',
		},
		deactivateStandAloneOnlineTv: {
			start: {
				eventName: 'desactivar vodafone tv standalone:preconfirmacion',
				data: {
					journey_name: 'desactivar vodafone tv standalone:preconfirmacion',
					journey_category: 'desactivar vodafone tv standalone',
					journey_type: 'otros',
					journey_options: 'futbol',
				},
			},
			success: {
				eventName: 'desactivar vodafone tv standalone:ok',
				data: {
					journey_name: 'desactivar vodafone tv standalone:ok',
					journey_category: 'desactivar vodafone tv standalone',
					journey_type: 'otros',
					journey_options: 'futbol',
				},
			},
			error: {
				eventName: 'desactivar vodafone tv standalone:ko',
				data: {
					journey_name: 'desactivar vodafone tv standalone:ko',
					journey_category: 'desactivar vodafone tv standalone',
					journey_type: 'otros',
					journey_options: 'futbol',
					journey_error_category: 'error en crm',
					journey_error_description: '',
				},
				errorDescription: 'error en carga de crm',
			},
		},
		modifyStandAloneOnlineTv: {
			start: {
				eventName: 'editar datos de usuario de vodafone tv standalone:start',
				data: {
					journey_name: 'editar datos de usuario de vodafone tv standalone:start',
					journey_category: 'editar datos de usuario de vodafone tv standalone',
					journey_subcategory: '',
					journey_type: 'otros',
				},
			},
			success: {
				eventName: 'editar datos de usuario de vodafone tv standalone:ok',
				data: {
					journey_name: 'editar datos de usuario de vodafone tv standalone:ok',
					journey_category: 'editar datos de usuario de vodafone tv standalone',
					journey_subcategory: 'modificar contraseña',
					journey_type: 'otros',
				},
			},
			error: {
				eventName: 'editar datos de usuario de vodafone tv standalone:ko',
				data: {
					journey_name: 'editar datos de usuario de vodafone tv standalone:ko',
					journey_category: 'editar datos de usuario de vodafone tv standalone',
					journey_subcategory: 'modificar contraseña',
					journey_type: 'otros',
					journey_error_category: 'error de servidor',
					journey_error_description: 'error en guardado en middleware',
				},
				errorDescription: 'error en guardado en middleware',
			},
			subCategories: {
				user: 'modificar usuario',
				password: 'modificar contraseña',
			},
		},
		modifyStandrdOnlineTv: {
			start: {
				eventName: 'editar datos de usuario de vodafone tv standard:start',
				data: {
					journey_name: 'editar datos de usuario de vodafone tv standard:start',
					journey_category: 'editar datos de usuario de vodafone tv standard',
					journey_subcategory: 'modificar contraseña',
					journey_type: 'otros',
				},
			},
			success: {
				eventName: 'editar datos de usuario de vodafone tv standard:ok',
				data: {
					journey_name: 'editar datos de usuario de vodafone tv standard:ok',
					journey_category: 'editar datos de usuario de vodafone tv standard',
					journey_subcategory: 'modificar contraseña',
					journey_type: 'otros',
				},
			},
			error: {
				eventName: 'editar datos de usuario de vodafone tv standard:ko',
				data: {
					journey_name: 'editar datos de usuario de vodafone tv standard:ko',
					journey_category: 'editar datos de usuario de vodafone tv standard',
					journey_subcategory: 'modificar contraseña',
					journey_type: 'otros',
					journey_error_category: 'error de crm',
					journey_error_description: '',
				},
				errorDescription: 'servicio ya activado',
			},
			subCategories: {
				user: 'modificar usuario',
				password: 'modificar contraseña',
			},
		},
		manageDataBundle: {
			stepOne: {
				eventName: 'gestion de bonos:ficha',
				data: {
					journey_name: 'gestion de bonos:ficha',
					journey_category: 'gestion de bono',
					journey_subcategory: '',
					journey_type: 'compra',
					journey_options: '',
					journey_model: '20 megas',
					journey_class: 'recurrente',
				},
			},
			stepTwo: {
				eventName: 'gestion de bono:preconfirmacion',
				data: {
					journey_name: 'gestion de bono:preconfirmacion',
					journey_category: 'gestion de bono',
					journey_subcategory: '',
					journey_type: 'compra',
					journey_options: '',
					journey_model: '20 megas',
					journey_class: 'recurrente',
				},
			},
			success: {
				eventName: 'gestion de bono:ok',
				data: {
					journey_name: 'gestion de bono:ok',
					journey_category: 'gestion de bono',
					journey_subcategory: '',
					journey_type: 'compra',
					journey_options: '',
					journey_model: '20 megas',
					journey_class: 'recurrente',
				},
			},
			error: {
				eventName: 'gestion de bono:ko',
				data: {
					journey_name: 'gestion de bono:ko',
					journey_category: 'gestion de bono',
					journey_subcategory: '',
					journey_type: 'compra',
					journey_options: '',
					journey_model: '20 megas',
					journey_class: 'recurrente',
					journey_error_category: 'error de servidor',
					journey_error_description: '',
				},
				errorDescription: 'error a la hora de guardar transaccion',
			},
			subCategory: {
				activate: 'activar',
				deactivate: 'desactivar',
				modifiy: 'modificar',
			},
			journeyClass: {
				recurrente: 'recurrente',
				puntual: 'puntual',
			},
			journeyOptions: {
				worryFree: 'mas megas',
				standard: 'bono',
				international: 'bono internacional',
				vodafonePass: 'vodafone pass',
			},
			journeyModel: {
				'1Gb': '1024 megas',
				'2Gb': '2048 megas',
				'4Gb': '4096 megas',
			},
			meags: ' megas',
		},
		upgradeChannel: {
			stepOne: {
				eventName: 'upgrade tv:ficha',
				data: {
					journey_name: 'upgrade tv:ficha',
					journey_category: 'upgrade tv',
					journey_type: 'compra',
					journey_options: '',
				},
			},
			stepTwo: {
				eventName: 'upgrade tv:preconfirmacion',
				data: {
					journey_name: 'upgrade tv:preconfirmacion',
					journey_category: 'upgrade tv',
					journey_type: 'compra',
					journey_options: 'fox',
				},
			},
			success: {
				eventName: 'upgrade tv:ok',
				data: {
					journey_name: 'upgrade tv:ok',
					journey_category: 'upgrade tv',
					journey_type: 'compra',
					journey_options: 'fox',
				},
			},
			error: {
				eventName: 'upgrade tv:ko',
				data: {
					journey_name: 'upgrade tv:ko',
					journey_category: 'upgrade tv',
					journey_type: 'compra',
					journey_options: 'fox',
					journey_error_category: 'error en provisionamiento',
					journey_error_description: 'error de servidor',
				},
			},
		},
		renewBenifits: {
			start: {
				eventName: 'renovar beneficios prepago:start',
				data: {
					journey_name: 'renovar beneficios prepago:start',
					journey_category: 'renovar beneficios prepago',
					journey_type: 'eservice',
				},
			},
			success: {
				eventName: 'renovar beneficios prepago:ok',
				data: {
					journey_name: 'renovar beneficios prepago:ok',
					journey_category: 'renovar beneficios prepago',
					journey_type: 'eservice',
				},
			},
			error: {
				eventName: 'renovar beneficios prepago:ko',
				data: {
					journey_name: 'renovar beneficios prepago:ko',
					journey_category: 'renovar beneficios prepago',
					journey_type: 'eservice',
					journey_error_category: 'error en el servidor',
					journey_error_description: 'error en el guardado de datos',
				},
				errorDescription: 'error en el guardado de datos',
			},
		},
		/** Interactions */
		// launch on clicking button
		roamingClicked: {
			eventName: 'productos y servicios:resumen:clic en ver roaming',
			data: {
				event_name: 'clic en cta roaming',
			},
		},
		// on swiping element
		swipeOnChangeService: {
			eventName: 'productos y servicios:resumen:swipe en cambio de producto',
			data: {
				event_name: 'swipe en cambio de producto',
			},
		},
		changePlanClicked: {
			eventName: 'productos y servicios:detalle de tarifa:clic en cambiar tarifa',
			data: {
				event_name: 'clic en cambiar tarifa',
			},
		},
		modifyIframeData: {
			eventName: '<page_name>:clic en modificar datos',
			data: {
				event_name: 'clic en modificar datos',
			},
		},
		KoIframeButton: {
			eventName: '<page_name>:clic en intentar otra vez',
			data: {
				event_name: 'clic en intentar otra vez',
			},
		},
		Ko3dsOverlay: {
			eventName: '<page_name>:clic en intentar de nuevo',
			data: {
				event_name: 'clic en intentar de nuevo',
			},
		},
		Ko3dsOverlayEntendido: {
			eventName: '<page_name>:clic en entendido',
			data: {
				event_name: 'clic en entendido',
			},
		},
		myCommitmentClicked: {
			eventName: 'productos y servicios:detalle de tarifa:clic en ver permanencias',
			data: {
				event_name: 'clic en ver permanencias',
			},
		},
		postSpeedClicked: {
			eventName: 'productos y servicios:detalle de tarifa:clic en mejorar velocidad',
			data: {
				event_name: 'clic en mejorar velocidad',
			},
		},
		myVodafoneTVAppClicked: {
			eventName: 'productos y servicios:detalle de tarifa:vodafone tv online:clic en descargar app',
			data: {
				event_name: 'clic en descargar app',
			},
		},
		activeBenifictsClicked: {
			eventName: '<page_name> y servicios:detalle de tarifa:clic en beneficios activados',
			data: {
				event_name: 'clic en beneficios activados',
			},
		},
		inactiveBenifictsClicked: {
			eventName: '<page_name> y servicios:detalle de tarifa:clic en beneficios desactivados',
			data: {
				event_name: 'clic en beneficios desactivados',
			},
		},
		extraDetailsClicked: {
			eventName: '{page_name}:{product_name}:click en cta',
			data: {
				event_name: 'click en cta',
			},
		},
		renewBenifitsClick: {
			eventName: '<page_name>:clic en renovar beneficios',
			data: {
				event_name: 'clic en renovar beneficios',
			},
		},
		upgradeToContractClick: {
			eventName: 'productos y servicios:detalle de tarifa:clic en pasate a contrato',
			data: {
				event_name: 'clic en pasate a contrato',
			},
		},
		downloadTvAppClick: {
			eventName: '<page_name>:clic en descargar app',
			data: {
				event_name: 'clic en descargar app',
			},
		},
		updateCpe: {
			interactions: {
				contratar: {
					eventName: 'productos y servicios:extras:actualizar equipo:click en boton <copy>',
					data: {
						event_name: 'click en boton <copy>',
						event_category: 'boton',
						event_context: 'actualizar equipo',
						journey_name: 'actualizar equipo',
						journey_type: 'servicio',
						journey_process: 'actualizacion',
					},
				},
				detalles: {
					eventName: '<page_name>:resumen de <page_name>:clic en ver detalles actualizacion',
					data: {
						event_name: 'clic en ver detalles actualizacion',
					},
				},
				clickEP: {
					'event_name': 'click en entrypoint',
					'event_category': 'banner',
					'event_context': 'banner pys <type>',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': 'resumen de productos y servicios',
					'&&events': 'event70',
					'entrypoint_section': 'productos y servicios',
					'entrypoint_location': 'productos y servicios',
					'entrypoint_position': '0',
					'page_typology': 'mivoweb',
					'journey_name': 'actualizar equipo',
				},
			},
			journey: {
				start: {
					eventName: 'actualizar equipo:start',
					data: {
						journey_name: 'actualizar equipo',
						journey_category: '',
						journey_type: 'servicio',
						journey_step: 'actualizar equipo:inicio',
						journey_process: 'actualizacion',
					},
				},
			},
			updatePage: {
				page: {
					'page_name': 'productos y servicios:extras:actualizar equipo',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'extras',
					'page_subcategory_level_2': 'actualizar equipo',
					'page_screen': 'actualizar equipo',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': 'extras',
					'navigation_level_3': 'actualizar equipo',
					'&&events': '',
					'page_typology': 'mivoweb',
				},
				preconfirmation: {
					page_name: 'productos y servicios:extras:actualizar equipo:preconfirmacion',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'extras',
					page_subcategory_level_2: 'actualizar equipo',
					page_subcategory_level_3: 'preconfirmacion',
					page_screen: 'preconfirmacion',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: 'extras',
					navigation_level_3: 'actualizar equipo',
					navigation_level_4: 'preconfirmacion',
					journey_name: 'actualizar equipo',
					journey_type: 'servicio',
					journey_step: 'actualizar equipo:preconfirmacion',
					journey_process: 'actualizacion',
					page_typology: 'mivoweb',
				},
				preconfirmationClick: {
					'page_name': 'productos y servicios:extras:actualizar equipo:preconfirmacion',
					'event_name': 'click en boton <copy>',
					'event_category': 'boton',
					'event_context': 'preconfirmacion actualizar equipo',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'extras',
					'page_subcategory_level_2': 'actualizar equipo',
					'page_subcategory_level_3': 'preconfirmacion',
					'page_screen': 'preconfirmacion',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': 'extras',
					'navigation_level_3': 'actualizar equipo',
					'navigation_level_4': 'preconfirmacion',
					'journey_name': 'actualizar equipo',
					'journey_type': 'servicio',
					'journey_process': 'actualizacion',
					'&&events': 'event12,event100',
					'page_typology': 'mivoweb',
				},
				error: {
					'page_name': 'productos y servicios:extras:actualizar equipo:error',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'extras',
					'page_subcategory_level_2': 'actualizar equipo',
					'page_subcategory_level_3': 'preconfirmacion',
					'page_screen': 'preconfirmacion',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': 'extras',
					'navigation_level_3': 'actualizar equipo',
					'navigation_level_4': 'error',
					'journey_name': 'actualizar equipo',
					'journey_type': 'servicio',
					'journey_step': 'actualizar equipo:ko',
					'journey_process': 'actualizacion',
					'&&events': 'event12,event100',
					'purchase_entrypoint': 'mivoweb',
				},
				success: {
					'page_name': 'productos y servicios:extras:actualizar equipo:confirmacion',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'extras',
					'page_subcategory_level_2': 'actualizar equipo',
					'page_subcategory_level_3': 'confirmacion',
					'page_screen': 'confirmacion',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': 'extras',
					'navigation_level_3': 'actualizar equipo',
					'navigation_level_4': 'confirmacion',
					'journey_name': 'actualizar equipo',
					'journey_type': 'servicio',
					'journey_step': 'actualizar equipo:ok',
					'journey_process': 'actualizacion',
					'&&events': 'event11',
					'purchase_entrypoint': 'mivoweb',
				},
				productTag: {
					body: 'addons;<name>;1;;;eVar187=<subcategory>|eVar188=<promotion>',
					promotion: '<type>:gratis <free>',
					name: '<router>:<decodificador>',
				},
				constants: {
					install: 'con instalador',
					undefined: 'undefined',
					autoInstall: 'autoinstalacion',
					router: 'router 5',
					router6: 'router 6',
					deco: 'decodificador 4K',
					gen3: 'decodificador 4K PRO',
					routerDeco: 'router 5 y decodificador 4K',
					routerGen3: 'router 5 y decodificador 4K PRO',
				},
				overlay_exit: {
					page_name: 'productos y servicios:extras:actualizar equipo:exit',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'extras',
					page_subcategory_level_2: 'actualizar equipo',
					page_subcategory_level_3: 'exit',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: 'extras',
					navigation_level_3: 'actualizar equipo',
					navigation_level_4: 'exit',
					page_screen: 'exit',
					asset_name: 'mivoweb',
					page_platform: 'web',
					event_name: '',
					journey_name: 'actualizar equipo',
					journey_type: 'servicio',
					journey_process: 'actualizacion',
					journey_detail: 'exit',
					journey_step: '<journey_name>:exit',
					['&&products']: 'addons;{product_name};1',
				},
				overlay_exit_continue: {
					'event_name': 'continuar',
					'event_category': 'boton',
					'event_label': 'continuar',
					'&&events': 'event106',
				},
				overlay_exit_leave: {
					'event_name': 'abandonar',
					'event_category': 'boton',
					'event_label': 'abandonar',
					'&&events': 'event105',
				},
			},
			combiPage: {
				view: {
					page_name: 'productos y servicios:extras:listado de equipos',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'extras',
					page_subcategory_level_2: 'listado de equipos',
					page_screen: 'listado de equipos',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: 'extras',
					navigation_level_3: 'listado de equipos',
					journey_name: 'actualizar equipo',
					journey_type: 'servicio',
					journey_process: 'actualizacion',
					page_typology: 'mivoweb',
				},
				click: {
					event_name: 'click en boton <copy>',
					event_category: 'boton',
				},
			},
			common: {
				router: 'router',
				deco: 'decodificador',
				combi: 'router y decodificador',
				undefined: 'undefined',
				deeplink: 'deeplink',
			},
		},
		activateExtraChannelAlert: {
			eventName: 'productos y servicios:resumen de productos y servicios:clic en activar ahora',
			data: {
				event_name: 'clic en activar ahora',
			},
		},
		upgradeTvPackage: {
			journey: {
				start: {
					eventName: 'upgrade tv package:start',
					data: {
						journey_name: 'upgrade tv package:start',
						journey_category: 'upgrade tv package',
						journey_options: '',
					},
				},
				success: {
					eventName: 'upgrade tv package:ok',
					data: {
						journey_name: 'upgrade tv package:ok',
						journey_category: 'upgrade tv package',
						journey_options: '',
					},
				},
				error: {
					eventName: 'upgrade tv package:ko',
					data: {
						journey_name: 'upgrade tv package:ko',
						journey_category: 'upgrade tv package',
						journey_options: '',
						journey_error_category: 'error de servidor',
						journey_error_description: 'error en provisionamiento',
					},
				},
			},
			interactions: {
				mejorar: {
					eventName: 'productos y servicios:detalle de tarifa:vodafone tv online:clic en mejorar tv',
					data: {
						event_name: 'clic en mejorar tv',
					},
				},
				selectPackage: {
					eventName: 'productos y servicios:detalle de tarifa:vodafone tv online:lista de paquetes:{0}',
					data: {
						event_name: 'cclic en {0}',
					},
				},
				upgradeButton: {
					eventName:
						'productos y servicios:detalle de tarifa:vodafone tv online:vista previa de actualizacion:clic en contratar',
					data: {
						event_name: 'clic en contratar',
					},
				},
			},
		},
		tvChannelList: {
			page: {
				page_name: 'productos y servicios:extras:packs vodafone tv',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'extras',
				page_subcategory_level_2: 'packs vodafone tv',
				page_subcategory_level_3: '',
				page_screen: 'packs vodafone tv',
				page_typology: 'screen',
			},
		},
		tvCheckout: {
			page: {
				page_name: 'productos y servicios:extras:contratacion tv:<option>',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'extras',
				page_subcategory_level_2: 'contratacion tv',
				page_subcategory_level_3: '<option>',
				page_subcategory_level_4: '',
				navigation_level_1: 'productos y servicios',
				navigation_level_2: 'extras',
				navigation_level_3: 'contratacion tv',
				navigation_level_4: '<option>',
				asset_name: 'mivoweb',
				page_screen: '<option>',
				page_typology: 'screen',
			},
			page_options: {
				summary: 'resumen de compra',
				error: 'error',
				confirmation: ':confirmacion',
				conf: 'confirmacion',
				email: 'confirmacion email',
				incomp: 'incompatibilidad',
				deco_no_deco: 'seleccionar con/sin deco',
				how_to_deco: 'como ver tu tv sin decodificador',
				how_to_deco4k: 'como ver tu tv 4k',
				how_to_decoGen3: 'como ver tu tv gen3',
				decos: 'decodificadores',
			},
			delightTvChecks: {
				event: {
					event_name: 'click en checkbox',
				},
				checks: {
					datosFacturacion: 'checkDatosFacturacion',
					comunicaciones: 'checkComunicacionesComerciales',
				},
			},
		},
		changeToDeco: {
			status: {
				howMuchToPay: ':cuanto vas a pagar',
				preconfirmation: ':preconfirmacion',
				buyResume: ':resumen de compra',
				errorMessage: ':error',
			},
			transactionShippingOptions: {
				office: 'recoger en correos',
				home: 'envio a casa',
			},
			page: {
				'page_name': 'productos y servicios:extras:decodificadores:cambiar a con deco',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'extras',
				'page_subcategory_level_2': 'decodificadores',
				'page_subcategory_level_3': 'cambiar a con deco',
				'page_subcategory_level_4': '',
				'page_screen': 'cambiar a con deco',
				'page_typology': 'screen',
				'purchase_entrypoint': 'productos y servicios',
				'&&events': 'event10',
			},
		},
		gen3: {
			journey_name: 'contratacion tv:preconfirmacion',
		},
		tvThirdPartyLanding: {
			page: {
				page_name: 'productos y servicios:extras:contenidos incluidos',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'extras',
				page_subcategory_level_2: 'contenidos incluidos',
				page_screen: 'contenidos incluidos',
				page_typology: 'screen',
			},
			modal: {
				view: {
					page_name: 'mivoweb:productos y servicios:extras:contenidos incluidos:revisar restricciones activas',
					asset_name: 'mivoweb',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: 'extras',
					navigation_level_3: 'contenidos incluidos',
					navigation_level_4: 'revisar restricciones activas',
					page_screen: 'revisar restricciones activas',
				},
				clickActivate: {
					eventName: 'mivoweb:productos y servicios:extras:contenidos incluidos:revisar restricciones activas:',
					data: {
						event_name: 'click en activar servicio',
						event_category: 'boton',
						event_label: 'acivar servicio',
					},
				},
				clickManage: {
					eventName: 'mivoweb:productos y servicios:extras:contenidos incluidos:revisar restricciones activas:',
					data: {
						event_name: 'click en gestionar restricciones',
						event_category: 'boton',
						event_label: 'gestionar restricciones',
					},
				},
			},
		},
		tvCancelPack: {
			last_pack: {
				page_name: 'productos y servicios:extras:baja paquete:<pack>:preconfirmacion',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'extras',
				page_subcategory_level_2: 'baja paquete',
				page_subcategory_level_3: '<pack>',
				page_subcategory_level_4: 'preconfirmacion',
				page_screen: 'preconfirmacion',
				page_typology: 'overlay',
				event_name: 'click en <text>',
				journey_name: 'baja paquetes:ok',
				journey_category: 'baja paquetes',
				journey_options: '<pack>',
				journey_subcategory: 'ultimo paquete',
				journey_type: 'baja servicio',
			},
			preconfirm: {
				page: {
					page_name: 'productos y servicios:extras:baja paquete:<pack>:preconfirmacion',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'extras',
					page_subcategory_level_2: 'baja paquete',
					page_subcategory_level_3: '<pack>',
					page_subcategory_level_4: 'preconfirmacion',
					page_screen: 'preconfirmacion',
					page_typology: 'overlay',
				},
				journey: {
					journey_name: 'baja paquetes:preconfirmacion',
					journey_category: 'baja paquetes',
					journey_options: '',
					journey_subcategory: '',
					journey_type: 'baja servicio',
				},
				event_name: 'click en <text>',
			},
			success: {
				page_name: 'productos y servicios:extras:baja paquete:<pack>:confirmacion',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'extras',
				page_subcategory_level_2: 'baja paquete',
				page_subcategory_level_3: '<pack>',
				page_subcategory_level_4: 'confirmacion',
				page_screen: 'confirmacion',
				page_typology: 'overlay',
				journey_name: 'baja paquetes:ok',
				journey_category: 'baja paquetes',
				journey_options: '<pack>',
				journey_subcategory: '',
				journey_type: 'baja servicio',
			},
			error: {
				page_name: 'productos y servicios:extras:baja paquete:<pack>:error',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'extras',
				page_subcategory_level_2: 'baja paquete',
				page_subcategory_level_3: '<pack>',
				page_subcategory_level_4: 'error',
				page_screen: 'error',
				page_typology: 'overlay',
				journey_name: 'baja paquetes:ko',
				journey_category: 'baja paquetes',
				journey_options: '<pack>',
				journey_subcategory: '',
				journey_type: 'baja servicio',
				journey_error_category: 'error de servidor',
				journey_error_description: 'error en provisionamiento',
			},
		},
		funded_device: {
			eventName: 'productos y servicios:resumen de productos y servicios:click en link <copy>',
			data: {
				page_name: 'productos y servicios:resumen de productos y servicios',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'resumen de productos y servicios',
				page_screen: 'resumen de productos y servicios',
				event_name: 'click en link <copy>',
				journey_name: 'financiacion activa',
				journey_category: '<client_typology>',
				journey_subcategory: 'pospago',
				journey_type: 'servicio',
			},
			modifyData: {
				page_name: 'productos y servicios:resumen de productos y servicios',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'resumen de productos y servicios',
				page_screen: 'resumen de productos y servicios',
				event_name: 'click en boton <copy>',
				journey_name: 'financiacion activa',
				journey_category: '<client_typology>',
				journey_subcategory: 'pospago',
				journey_type: 'servicio',
			},
			refinData: {
				'journey_options': '',
				'&&events': '',
			},
			overlayData: {
				'page_name': 'productos y servicios:financiacion activa:<case>',
				'journey_name': 'financiacion activa',
				'journey_category': '<client_typology>',
				'journey_subcategory': 'pospago',
				'journey_options': '',
				'journey_type': 'servicio',
				'journey_process': 'activar',
				'journey_step': '',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'financiacion activa',
				'page_subcategory_level_2': '<case>',
				'page_subcategory_level_3': '',
				'page_screen': '<case>',
				'page_typology': 'backdrop',
				'&&events': '',
			},
			overlayCases: {
				types: 'seleccion tipo pago',
				quotes: 'seleccion numero cuotas',
				preconfirmation: 'preconfirmacion',
				confirmation: 'confirmacion',
				error: 'error',
			},
			overlaySteps: {
				start: 'financiacion activa:start',
				preconfirmation: 'financiacion activa:preconfirmacion',
				ok: 'financiacion activa:ok',
				ko: 'financiacion activa:ko',
			},
			paymentMethod: {
				partial: 'tipo de pago:parcial',
				total: 'tipo de pago:total',
				refin: 'tipo de pago:refinanciacion',
			},
			errorData: {
				error_category: 'servicio dxl_{code}',
				error_description: '',
				error_type: 'tecnico',
				error_code: '',
			},
		},
		entrypoints: {
			view: {
				eventName: 'productos y servicios:resumen de productos y servicios:impresiones entrypoint',
				data: {
					'asset_name': 'mivoweb',
					'event_name': 'impresiones entrypoint',
					'page_name': 'productos y servicios:resumen de productos y servicios',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'resumen de productos y servicios',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': 'resumen de productos y servicios',
					'page_screen': 'resumen de productos y servicios',
					'event_category': 'visualizacion',
					'event_context': 'productos y servicios',
					'event_label': 'impresiones entrypoint',
					'&&events': 'event75',
					'entrypoint_list': 'es={es}|el={el}|ep={ep}|jn={jn}|en={en}|et={et}|ety={ety}',
					'entrypoint_pega':
						'enm=<entrypoint_name>|elb=<entrypoint_label>|eis=<entrypoint_issue>|egn=<entrypoint_groupnba>|jn=<v10.flows.customization.[EP CODE].analyticsName>|el=<entrypoint_location>|ec=<entrypoint_code>',
				},
			},
			business: {
				eventName: '',
				data: {
					'event_name': '',
					'page_name': 'productos y servicios:resumen de productos y servicios',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'resumen de productos y servicios',
					'page_subcategory_level_2': 'servicios para tu negocio',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': '',
					'page_typology': 'principal',
					'page_detail': 'servicios para tu negocio',
					'event_category': '',
					'event_context': '',
					'event_label': '',
					'&&events': '',
				},
			},
			tv: {
				'event_name': 'impresiones entrypoint',
				'page_name': 'productos y servicios:resumen de productos y servicios',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'resumen de productos y servicios',
				'page_screen': 'resumen de productos y servicios',
				'&&events': 'event75',
				'entrypoint_list': 'es=pys|el=banner boxless a deco|jn=|et=cambiar a con decodificador',
			},
			click: {
				eventName: 'productos y servicios:resumen de productos y servicios:click en entrypoint',
				data: {
					'event_name': 'click en entrypoint',
					'page_name': 'productos y servicios:resumen de productos y servicios',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'resumen de productos y servicios',
					'page_screen': 'resumen de productos y servicios',
					'&&events': 'event70',
					'entrypoint_section': '',
					'entrypoint_location': '',
					'entrypoint_position': '',
					'entrypoint_title': '',
					'entrypoint_type': '',
					'experience_name': '',
					'journey_name': '',
				},
			},
			promoTv10: {
				entrypoint_title: 'promocion television',
			},
		},
		tvChannel: {
			subcategory_pay: {
				postpaid: 'Postpaid',
				pospago: 'pospago',
				prepago: 'prepago',
			},
			impresion: {
				'page_name': 'productos y servicios:resumen de productos y servicios',
				'event_name': 'impresiones entrypoint',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'resumen de productos y servicios',
				'page_screen': 'resumen de productos y servicios',
				'&&events': 'event75',
				'entrypoint_list': 'es=pys|el=contenido a terceros|jn={jn}|et={et}|jo={channel}',
				'plan_type': 'television',
			},
			iteraction: {
				'page_name': 'productos y servicios:resumen de productos y servicios',
				'event_name': 'click en entrypoint',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'resumen de productos y servicios',
				'page_screen': 'resumen de productos y servicios',
				'&&events': 'event70',
				'entrypoint_section': 'pys',
				'entrypoint_location': 'contenido a terceros',
				'entrypoint_title': '',
				'journey_name': '',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_options': '',
				'journey_type': 'contratacion',
				'plan_type': 'television',
			},
			start_data: {
				et_details: 'canales movistar ver detalles',
				et_activate: 'canales movistar activacion',
				jn_details: 'contratacion mas packs',
				jn_activate: 'contractacion mas packs:inicio',
			},
			preconfirmation: {
				data: {
					page_name: 'productos y servicios:contratacion {channel}',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'contratacion {channel}',
					page_screen: 'contratacion {channel}',
					plan_type: 'television',
					journey_category: '',
					journey_subcategory: '',
					journey_options: '',
					journey_type: 'contratacion',
				},
				click: {
					event_name: 'click en {button}',
					journey_name: 'contratacion mas packs',
				},
				state: {
					'&&events': 'event16',
					'journey_name': 'contratacion mas packs:preconfirmacion',
				},
			},
			confirmation: {
				data: {
					page_name: 'productos y servicios:confirmacion',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'confirmacion',
					page_screen: 'confirmacion',
					journey_category: '',
					journey_subcategory: '',
					journey_options: '',
					journey_type: 'contratacion',
				},
				click: {
					event_name: 'click en {button}',
					journey_name: 'contratacion mas packs',
				},
				state: {
					'&&events': 'event11',
					'journey_name': 'contratacion mas packs:ok',
				},
			},
			details: {
				data: {
					page_name: 'productos y servicios:ver detalles {channel}',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'ver detalles {channel}',
					page_screen: 'ver detalles {channel}',
					plan_type: 'television',
					journey_category: '',
					journey_subcategory: '',
					journey_options: '',
					journey_type: 'contratacion',
					journey_name: 'contratacion mas packs',
				},
				click: {
					event_name: 'click en {button}',
				},
			},
		},
		secondResidences: {
			itemDetail: 'item1',
			codePath: 'SecondResidences/MainScreen',
			code: '2NDHOME',
			subcategory: {
				postpaid: 'Postpaid',
				pospago: 'pospago',
				prepago: 'prepago',
			},
			landing: {
				data: {
					'page_name': 'productos y servicios:segundas residencias',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'segundas residencias',
					'page_screen': 'segundas residencias',
					'&&products': '<category>;segundas residencias;1;eVar187=fide:principal;',
					'&&events': 'event10',
					'journey_name': 'segundas residencias',
					'journey_type': 'servicio',
					'journey_process': 'activar',
					'journey_step': 'segundas residencias:start',
				},
			},
			selectionPage: {
				data: {
					'page_name': 'productos y servicios:segundas residencias:configura tu dispositivo',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'segundas residencias',
					'page_subcategory_level_2': 'configura tu dispositivo',
					'page_screen': 'configura tu dispositivo ',
					'&&products': '<category>;segundas residencias;1;eVar187=fide:principal;',
					'journey_name': 'segundas residencias',
					'journey_type': 'servicio',
					'journey_process': 'activar',
				},
			},
			detailsPage: {
				selector: 'click en <button>',
				confirmation: 'click en boton <button>',
			},
			iterationDash: {
				data: {
					'page_name': 'dashboard:home',
					'event_name': 'click en entrypoint',
					'page_section': 'dashboard',
					'page_subcategory_level_1': 'home',
					'page_screen': 'home',
					'&&events': 'event70',
				},
				entrypoint_and_journey: {
					journey_name: 'segundas residencias',
					journey_type: 'servicio',
					journey_process: 'activar ',
					entrypoint_section: '<section>',
					entrypoint_location: '<location>',
					entrypoint_title: '<title>',
				},
			},
			entrypoint_list: 'es=<section>|el=<location>|jn=segundas residencias|et=<title>',
			overlay_exit: {
				page_name: 'productos y servicios:segundas residencias:exit',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'segundas residencias',
				page_subcategory_level_2: 'exit',
				navigation_level_1: 'productos y servicios',
				navigation_level_2: 'segundas residencias',
				navigation_level_3: 'exit',
				page_screen: 'exit',
				asset_name: 'mivoweb',
				page_platform: 'web',
				event_name: '',
				journey_name: 'segundas residencias',
				journey_type: 'servicio',
				journey_process: 'activar',
				journey_detail: 'exit',
				journey_step: '<journey_name>:exit',
			},
			['&&products']: 'segundas residencias;{product_name};1;{product_price}',
			overlay_exit_continue: {
				'event_name': 'continuar',
				'event_category': 'boton',
				'event_label': 'continuar',
				'&&events': 'event106',
			},
			overlay_exit_leave: {
				'event_name': 'abandonar',
				'event_category': 'boton',
				'event_label': 'abandonar',
				'&&events': 'event105',
			},
		},
		socialTariff: {
			event_name: 'click en entrypoint',
			events: {
				'&&events': 'event70',
				'event_impresion': 'event75',
			},
			data: {
				journey_name: 'renovacion tarifa social',
				page_name: 'productos y servicios: renovacion descuento',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'renovacion descuento',
				page_screen: 'renovacion descuento',
			},
			clickDashboard: {
				'journey_name': '{0}',
				'journey_category': '{0}',
				'journey_subcategory': '{0}',
				'page_name': 'dashboard:home',
				'page_section': 'dashboard',
				'page_subcategory_level_1': 'home',
				'page_screen': 'home',
				'&&events': 'event70',
				'entrypoint_section': 'Dashboard',
				'entrypoint_location': 'banner bienvenida',
				'entrypoint_position': 'undefined',
				'entrypoint_title': '{0}',
			},
			clickProductsAndServices: {
				'journey_name': 'renovacion tarifa social',
				'page_name': 'Productos y servicios: resumen de productos y servicios',
				'page_section': 'Productos y servicios',
				'page_subcategory_level_1': 'resumen de productos y servicios',
				'page_screen': 'resumen de productos y servicios',
				'&&events': 'event70',
				'entrypoint_section': '',
				'entrypoint_location': '',
				'entrypoint_position': '',
				'entrypoint_title': '',
				'entrypoint_list': 'es=pys|el=banner boxless a deco|jn=|et=cambiar a con decodificador',
				'event_name': 'impresiones entrypoint',
			},
			view: {
				'&&events': 'event16',
			},
			click: {
				event_name: 'click en {0}',
			},
			journey: {
				socialDiscount: 'renovacion tarifa social',
				trypay: 'try pay fibra',
				trybuy: 'try buy fibra',
				entrypointTitleSocialDiscount: 'Quieres seguir con la misma velocidad de internet',
			},
		},
		tryVoucher: {
			data: {
				page_name: 'productos y servicios:try fibra:inicio',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'try fibra',
				page_subcategory_level_2: 'inicio',
				page_screen: 'inicio',
			},
			journey: {
				journey_name: '{0}',
				journey_category: '{0}',
				journey_subcategory: '{0}',
				journey_step: '{0}:inicio',
				journey_detail: 'inicio',
			},
			journeyAux: {
				trypay: 'try pay fibra',
				trybuy: 'try buy fibra',
				pospago: 'pospago',
				prepago: 'prepago',
				particular: 'particular',
				empresa: 'empresa',
				acepto: 'acepto',
				rechazo: 'rechazo',
				boton: 'boton',
				aspa: 'aspa',
				x: 'x',
				cerrar: 'cerrar',
				aceptado: 'aceptado',
				rechazado: 'rechazado',
				recibida: 'recibida',
				rechazada: 'rechazada',
			},
			deeplink: {
				entrypoint_section: 'deeplink',
				entrypoint_location: 'undefined',
				entrypoint_position: 'undefined',
				entrypoint_title: 'undefined',
			},
			clickUpgradeKeep: {
				event_name: 'click en {0}',
				event_category: 'boton',
				event_label: '{0}',
				event_context: '{0} {1}',
			},
			error: {
				data: {
					'page_name': 'productos y servicios:try fibra:error',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'try fibra',
					'page_subcategory_level_2': 'error',
					'page_screen': 'error',
					'&&events': 'event28',
				},
				journey: {
					journey_name: '{0}',
					journey_category: '{0}',
					journey_subcategory: '{0}',
					journey_step: '{0}:error',
					journey_detail: 'error',
					error_category: '{0}',
					error_description: '{0}',
					error_type: 'tecnico',
					error_code: '{0}',
				},
			},
			success: {
				data: {
					page_name: 'productos y servicios:try fibra:solicitud {0}',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'try fibra',
					page_subcategory_level_2: 'solicitud {0}',
					page_screen: 'solicitud {0}',
				},
				journey: {
					journey_name: '{0}',
					journey_category: '{0}',
					journey_subcategory: '{0}',
					journey_step: '{0}:solicitud {1}',
					journey_detail: 'solicitud {0}',
				},
			},
			close: {
				data: {
					page_name: 'productos y servicios:try fibra:solicitud {0}',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'try fibra',
					page_subcategory_level_2: 'solicitud {0}',
					page_screen: 'solicitud {0}',
					journey_name: '{0}',
					journey_category: '{0}',
					journey_subcategory: '{0}',
				},
				event: {
					event_name: 'click en {0}',
					event_category: '{0}',
					event_label: '{0}',
					event_context: 'cerrar formulario',
				},
			},
		},
		tryAndPay: {
			subcategory: {
				postpaid: 'Postpaid',
				pospago: 'pospago',
				prepago: 'prepago',
			},
			keep_change_button: {
				keep: 'mantener tarifa actual',
				change: 'cambiar de tarifa',
			},
			stage: {
				preconfirmation: 'preconfirmacion',
				confirmation: 'confirmacion',
			},
			dashboard: {
				events_type: {
					iteration: 'click en entrypoint',
					impresion: 'impresiones entrypoint',
				},
				data: {
					page_name: 'productos y servicios:resumen de productos y servicios',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'resumen de productos y servicios',
					page_screen: 'resumen de productos y servicios ',
					entrypoint_section: 'pys',
					entrypoint_location: 'banner contador',
				},
				journey: {
					journey_name: 'trypayfibra',
					journey_type: 'servicio',
					journey_process: 'activar',
				},
				events: {
					event_70: 'event70',
					event_75: 'event75',
				},
				entrypoint_title: {
					active: 'Tu promo fibra por menos finaliza en',
					inactive: 'Tu promo fibra por menos ha finalizado',
				},
			},
			landing: {
				state: {
					'page_name': 'productos y servicios:trypayfibra:contador de fibra',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'trypayfibra',
					'page_subcategory_level_2': 'contador de fibra',
					'page_screen': 'contador de fibra',
					'&&products': 'movil + fibra;trypayfibra;1;eVar187=fide:principal',
					'journey_name': 'trypayfibra',
					'journey_type': 'servicio',
					'journey_process': 'activar',
				},
				journey: {
					journey_step: 'trypayfibra:start',
				},
				events: {
					'&&events': 'event10',
				},
				event_name: {
					click: 'click en boton <action>',
					click_info: 'click en icono de informacion',
				},
				journey_options: {
					active: 'descuento activo',
					disabled: 'descuento finalizado',
				},
				journey_options_confirmation: {
					active: 'descuento activo:<act>',
					disabled: 'descuento finalizado:<act>',
				},
				act: {
					change: 'bajar fibra',
					keep: 'mantener fibra',
				},
			},
			preconfirmation: {
				state: {
					page_name: 'productos y servicios:trypayfibra:<preconfirmation>',
					page_subcategory_level_2: '<preconfirmation>',
					page_screen: '<preconfirmation>',
				},
				journey: {
					journey_step: 'trypayfibra:<preconfirmation>',
				},
				event_16: {
					'&&events': '<event>',
				},
				event_name: {
					click: 'click en boton <action>',
				},
				events: {
					event_16: 'event16',
				},
			},
			confirmation: {
				state: {
					page_name: 'productos y servicios:trypayfibra:<confirmation>',
					page_subcategory_level_2: '<confirmation>',
					page_screen: '<confirmation>',
				},
				journey: {
					journey_step: 'trypayfibra:<confirmation>',
				},
				event_11: {
					'&&events': '<event>',
				},
				event_name: {
					click: 'click en boton <action>',
				},
				events: {
					event_11: 'event11',
				},
			},
		},
		try: {
			subcategory: {
				postpaid: 'Postpaid',
				pospago: 'pospago',
				prepago: 'prepago',
			},
			keep_change_button: {
				keep: 'mantener tarifa actual',
				change: 'cambiar de tarifa',
			},
			stage: {
				preconfirmation: 'preconfirmacion',
				confirmation: 'confirmacion',
			},
			dashboard: {
				events_type: {
					iteration: 'click en entrypoint',
					impresion: 'impresiones entrypoint',
				},
				data: {
					'page_name': 'Productos y servicios: resumen de productos y servicios',
					'page_section': 'Productos y servicios',
					'page_subcategory_level_1': 'resumen de productos y servicios',
					'page_screen': 'resumen de productos y servicios',
					'&&events': 'event70',
					'entrypoint_section': 'Pys',
					'entrypoint_location': 'banner contador',
					'entrypoint_position': 'undefined',
					'entrypoint_list':
						'es=<entrypoint_section>|el=<entrypoint_location>|jn=<journey_name>|et=<entrypoint_title>|ep=<entrypoint_position>',
					'entrypoint_title': 'Proximamente finaliza tu descuento',
				},
				journey: {
					trypay: 'try pay fibra',
					trybuy: 'try buy fibra',
					journey_type: 'servicio',
					journey_process: 'activar',
				},
				events: {
					event_70: 'event70',
					event_75: 'event75',
				},
				entrypoint_title: {
					active: 'Tu promo fibra por menos finaliza en',
					inactive: 'Tu promo fibra por menos ha finalizado',
				},
			},
			landing: {
				state: {
					'page_name': 'productos y servicios:trybuyfibra:contador de fibra',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'trybuyfibra',
					'page_subcategory_level_2': 'contador de fibra',
					'page_screen': 'contador de fibra',
					'&&products': 'movil + fibra;trybuyfibra;1;eVar187=fide:principal',
					'journey_name': 'trybuyfibra',
					'journey_type': 'servicio',
					'journey_process': 'activar',
				},
				journey: {
					journey_step: 'trybuyfibra:start',
				},
				events: {
					'&&events': 'event10',
				},
				event_name: {
					click: 'click en boton <action>',
					click_info: 'click en icono de informacion',
				},
				journey_options: {
					active: 'descuento activo',
					disabled: 'descuento finalizado',
				},
				journey_options_confirmation: {
					active: 'descuento activo:<act>',
					disabled: 'descuento finalizado:<act>',
				},
				act: {
					change: 'bajar fibra',
					keep: 'mantener fibra',
				},
			},
			preconfirmation: {
				state: {
					page_name: 'productos y servicios:trybuyfibra:<preconfirmation>',
					page_subcategory_level_2: '<preconfirmation>',
					page_screen: '<preconfirmation>',
				},
				journey: {
					journey_step: 'trybuyfibra:<preconfirmation>',
				},
				event_16: {
					'&&events': '<event>',
				},
				event_name: {
					click: 'click en boton <action>',
				},
				events: {
					event_16: 'event16',
				},
			},
			confirmation: {
				state: {
					page_name: 'productos y servicios:trybuyfibra:<confirmation>',
					page_subcategory_level_2: '<confirmation>',
					page_screen: '<confirmation>',
				},
				journey: {
					journey_step: 'trybuyfibra:<confirmation>',
				},
				event_11: {
					'&&events': '<event>',
				},
				event_name: {
					click: 'click en boton <action>',
				},
				events: {
					event_11: 'event11',
				},
			},
		},
		bondsSecondResidences: {
			pay: {
				postpaid: 'Postpaid',
				pospago: 'pospago',
				prepago: 'prepago',
			},
			iteraction: {
				data: {
					'page_name': 'productos y servicios:resumen de productos y servicios',
					'event_name': 'click en entrypoint',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'resumen de productos y servicios',
					'page_screen': 'resumen de productos y servicios',
					'&&events': 'event70',
				},
				entrypoint_and_journey: {
					entrypoint_section: 'pys',
					entrypoint_location: 'mi tarifa',
					entrypoint_title: '<title>',
					journey_name: 'bonos segundas residencias',
					journey_type: 'servicio',
					journey_process: 'contratacion',
				},
			},
			impresion: {
				'event_name': 'impresiones entrypoint',
				'&&events': 'event75',
				'entrypoint_list': 'es=pys|el=mi tarifa|jn=bonos segundas residencias|et=<title>',
			},
			generalBonds: {
				'data': {
					'page_name': 'productos y servicios:bonos segundas residencias',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'bonos segundas residencias',
					'page_screen': 'bonos segundas residencias',
					'&&products': 'mifi|router; segundas residencias; 1;eVar187=fide:principal;',
					'journey_name': 'bonos segundas residencias',
					'journey_type': 'servicio',
					'journey_process': 'contratacion',
				},
				'&&events': 'event10',
				'event_name': 'click en <bono>',
				'journey_step': 'bonos segundas residencias:start',
			},
			descriptionBonds: {
				data: {
					'page_name': 'productos y servicios:bonos segundas residencias:<bono>',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'bonos segundas residencias',
					'page_subcategory_level_2': '<bono>',
					'page_screen': '<bono>',
					'&&products': 'mifi|router; segundas residencias; 1;eVar187=fide:principal;',
					'journey_name': 'bonos segundas residencias',
					'journey_type': 'servicio',
					'journey_process': 'contratacion',
				},
				event_name: 'click en boton <button>',
				journey_step: 'bonos segundas residencias:ficha',
			},
			preconfirmationBonds: {
				'data': {
					'page_name': 'productos y servicios:bonos segundas residencias:preconfirmacion <bono>',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'bonos segundas residencias',
					'page_subcategory_level_2': 'preconfirmacion <bono>',
					'page_screen': 'preconfirmacion <bono>',
					'&&products': 'mifi|router; segundas residencias; 1;eVar187=fide:principal;',
					'journey_name': 'bonos segundas residencias',
					'journey_type': 'servicio',
					'journey_process': 'contratacion',
				},
				'&&events': 'event16',
				'event_name': 'click en boton <button>',
				'journey_step': 'bonos segundas residencias:preconfirmación',
				'journey_step_click': 'bonos segundas residencias:<type>',
			},
			overlay_exit: {
				page_name: 'productos y servicios:bonos segundas residencias:exit',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'bonos segundas residencias',
				page_subcategory_level_2: 'exit',
				navigation_level_1: 'productos y servicios',
				navigation_level_2: 'bonos segundas residencias',
				navigation_level_3: 'exit',
				page_screen: 'exit',
				asset_name: 'mivoweb',
				page_platform: 'web',
				event_name: '',
				journey_name: 'bonos segundas residencias',
				journey_type: 'servicio',
				journey_process: 'contratacion',
				journey_detail: 'exit',
				journey_step: '<journey_name>:exit',
			},
			['&&products']: 'segundas residencias;{product_name};1;eVar187=fide',
			overlay_exit_continue: {
				'event_name': 'continuar',
				'event_category': 'boton',
				'event_label': 'continuar',
				'&&events': 'event106',
			},
			overlay_exit_leave: {
				'event_name': 'abandonar',
				'event_category': 'boton',
				'event_label': 'abandonar',
				'&&events': 'event105',
			},
		},
		viewBonusDetailsButton: {
			event_Name: 'ver detalles',
			data: {
				event_name: 'ver detalles',
				event_category: 'boton',
				event_context: 'Bono Internacional LATAM 100min',
				event_label: 'ver detalles',
			},
		},
		addMoreBonusButton: {
			event_Name: 'anadir mas bonos',
			data: {
				'event_name': 'anadir mas bonos',
				'event_category': 'boton',
				'event_context': 'mi tarifa',
				'event_label': 'anadir mas bonos',
				'entrypoint_section': 'pys',
				'entrypoint_title': 'anadir bonos',
				'journey_name': 'gestion de bonos',
				'&&events': 'event70',
			},
		},
	},
	register: {
		companyButtonClicked: {
			eventName: 'registro:bienvenido:clic en soy empresa',
			data: {
				event_name: 'clic en soy empresa',
			},
		},
		employeeButtonClicked: {
			eventName: 'registro:bienvenido:clic en soy particular',
			data: {
				event_name: 'clic en soy particular',
			},
		},
		/** Interactions */
		// launch on clicking button
		roamingClicked: {
			eventName: 'productos y servicios:resumen:clic en ver roaming',
			data: {
				event_name: 'clic en cta roaming',
			},
		},
		// on swiping element
		swipeOnChangeService: {
			eventName: 'productos y servicios:resumen:swipe en cambio de producto',
			data: {
				event_name: 'swipe en cambio de producto',
			},
		},
		changePlanClicked: {
			eventName: 'productos y servicios:detalle de tarifa:clic en cambiar tarifa',
			data: {
				event_name: 'clic en cambiar tarifa',
			},
		},
		myCommitmentClicked: {
			eventName: 'productos y servicios:detalle de tarifa:clic en ver permanencias',
			data: {
				event_name: 'clic en ver permanencias',
			},
		},
		postSpeedClicked: {
			eventName: 'productos y servicios:detalle de tarifa:clic en mejorar velocidad',
			data: {
				event_name: 'clic en mejorar velocidad',
			},
		},
		myVodafoneTVAppClicked: {
			eventName: 'productos y servicios:detalle de tarifa:vodafone tv online:clic en descargar app',
			data: {
				event_name: 'clic en descargar app',
			},
		},
		activeBenifictsClicked: {
			eventName: '<page_name> y servicios:detalle de tarifa:clic en beneficios activados',
			data: {
				event_name: 'clic en beneficios activados',
			},
		},
		inactiveBenifictsClicked: {
			eventName: '<page_name> y servicios:detalle de tarifa:clic en beneficios desactivados',
			data: {
				event_name: 'clic en beneficios desactivados',
			},
		},
		extraDetailsClicked: {
			eventName: '{page_name}:{product_name}:clic en cta',
			data: {
				event_name: 'clic en cta',
			},
		},
		renewBenifitsClick: {
			eventName: '<page_name>:clic en renovar beneficios',
			data: {
				event_name: 'clic en renovar beneficios',
			},
		},
		upgradeToContractClick: {
			eventName: 'productos y servicios:detalle de tarifa:clic en pasate a contrato',
			data: {
				event_name: 'clic en pasate a contrato',
			},
		},
		downloadTvAppClick: {
			eventName: '<page_name>:clic en descargar app',
			data: {
				event_name: 'clic en descargar app',
			},
		},
	},
	serviceSelector: {
		start: {
			eventName: 'selector de servicios:start',
			data: {
				journey_name: 'selector de servicios:start',
				journey_category: 'selector de servicios',
				journey_type: 'otros',
			},
		},
		secondStep: {
			eventName: 'selector de servicios:elige tu servicio',
			data: {
				journey_name: 'selector de servicios:elige tu servicio',
				journey_category: 'selector de servicios',
				journey_subcategory: 'single id',
				journey_type: 'otros',
			},
		},
		success: {
			eventName: 'selector de servicios:ok',
			data: {
				journey_name: 'selector de servicios:ok',
				journey_category: 'selector de servicios',
				journey_subcategory: 'single id',
				journey_type: 'otros',
			},
		},
		error: {
			eventName: 'selector de servicios:ko',
			data: {
				journey_name: 'selector de servicios:ko',
				journey_category: 'selector de servicios',
				journey_subcategory: 'single id',
				journey_type: 'otros',
				journey_error_category: 'error de integracion',
				journey_error_description: 'No podemos cargar la informacion',
				journey_error_code: '',
			},
		},
		serviceButtonClicked: {
			eventName: 'consumo:mi consumo:detalle de consumo:click en {product}',
			data: {
				event_name: 'click en {product}',
				event_context: 'productos y servicios',
				event_category: 'card',
				event_label: '',
				page_detail: 'resumen de productos y servicios',
				page_subcategory_level_1: 'resumen de productos y servicios',
			},
		},
		serviceSwipe: {
			eventName: 'dashboard:selector de servicios:swipe de ids',
			data: {
				event_name: 'swipe de ids',
			},
		},
		singleId: 'single id',
		multipleIds: 'multiple ids',
		openOverlay: {
			page_name: 'productos y servicios:selecciona un producto',
			page_section: 'productos y servicios',
			page_subcategory_level_1: 'selecciona un producto',
			page_screen: 'selecciona un producto',
			page_typology: 'overlay',
		},
		closeOverlay: {
			eventName: 'click en cerrar',
			data: {
				event_name: 'click en cerrar',
				page_name: 'productos y servicios:selecciona un producto',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'selecciona un producto',
				page_screen: 'selecciona un producto',
			},
		},
		selectCif: {
			eventName: 'click en cif',
			data: {
				event_name: 'click en cif',
				page_name: 'productos y servicios:selecciona un producto',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'selecciona un producto',
				page_screen: 'selecciona un producto',
			},
		},
		selectorCardClicked: {
			eventName: 'click en product',
			data: {
				event_name: 'click en product',
				page_name: 'productos y servicios:selecciona un producto',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'selecciona un producto',
			},
		},
		selectorSiteClicked: {
			eventName: 'click en sede',
			data: {
				event_name: 'click en sede',
				page_name: 'productos y servicios:selecciona un producto',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'selecciona un producto',
				page_screen: 'selecciona un producto',
			},
		},
		retrySite: {
			eventName: 'click en sede reintentar',
			data: {
				event_name: 'click en sede reintentar',
				page_name: 'productos y servicios:selecciona un producto',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'selecciona un producto',
				page_screen: 'selecciona un producto',
			},
		},
		retryCif: {
			eventName: 'click en cif reintentar',
			data: {
				event_name: 'click en cif reintentar',
				page_name: 'productos y servicios:selecciona un producto',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'selecciona un producto',
				page_screen: 'selecciona un producto',
			},
		},
	},
	dashboard: {
		eventName: 'dashboard:home:',
		view: {
			page_name: 'dashboard:home',
			page_section: 'dashboard',
			page_screen: 'dashboard',
			page_subcategory_level_1: 'home',
			navigation_level_1: 'dashboard',
			asset_name: 'mivoweb',
		},
		data: {
			'&&events': 'event75',
			'entrypoint_list': 'es={es}|el={el}|ep={ep}|jn={jn}|en={en}|et={et}',
		},
		clickOnShowBillsLink: {
			eventName: 'dashboard:home:clic en ver mis facturas',
			data: {
				event_name: 'clic en ver mis facturas',
			},
		},
		clickOnTryAndBuy: {
			eventName: 'dashboard:home:click en banner one profesional',
			data: {
				event_name: 'click en banner one profesional',
			},
		},
		clickOnExtrasTile: {
			eventName: '<pagename>:click en extras',
			data: {
				event_name: 'click en extras',
			},
		},
		clickOnBenefitRenewalTile: {
			eventName: 'dashboard:home:click en recargas',
			data: {
				event_name: 'click en recargas',
				event_label: 'click en recargas',
				event_category: 'tile',
				event_context: 'dashboard home',
			},
		},
		clickOnVFPassToNavigate: {
			eventName: '<page_name>:click en vodafone pass',
			data: {
				event_name: 'click en vodafone pass',
			},
		},
		clickOnBundlesAndProductToNavigate: {
			eventName: '<page_name>:click en bonos de datos y minutos',
			data: {
				event_name: 'click en bonos de datos y minutos',
			},
		},
		vovClickOnAnyButton: {
			eventName: 'dashboard:vov:clic en {msg} - {cta}',
			data: {
				event_name: 'clic en {msg} - {cta}',
			},
		},
		gaugeClicked: {
			eventName: 'dashboard:gauge:clic en {0}',
			data: {
				event_name: 'clic en {0}',
			},
			buttons: {
				topUp: 'top up',
				smallCircle: 'burbuja pequeña',
				bigCircle: 'burbuja grande',
			},
		},
		outOfBundleClicked: {
			toShow: {
				eventName: 'dashboard:consumo fuera de tarifa:clic en desplegar',
				data: {
					event_name: 'clic en desplegar',
				},
			},
			toHide: {
				eventName: 'dashboard:consumo fuera de tarifa:clic en cerrar',
				data: {
					event_name: 'clic en cerrar',
				},
			},
			bundleClicked: {
				eventName: 'dashboard:consumo fuera de tarifa:clic en CTA',
				data: {
					event_name: 'clic en CTA',
				},
			},
		},
		outOfBundleBillClicked: {
			toShow: {
				eventName: 'dashboard:consumo fuera de factura:clic en desplegar',
				data: {
					event_name: 'clic en desplegar',
				},
			},
			toHide: {
				eventName: 'dashboard:consumo fuera de factura:clic en cerrar',
				data: {
					event_name: 'clic en cerrar',
				},
			},
			bundleClicked: {
				eventName: 'dashboard:consumo fuera de tarifa:clic en CTA',
				data: {
					event_name: 'clic en CTA',
				},
			},
		},
		downloadAppNudgeClic: {
			eventName: '{0}:overlay descarga app: clic en abrir',
			data: {
				event_name: 'clic en abrir',
			},
		},
		DownloadAppNudge: {
			eventName: '{0}:overlay descarga app',
			data: {
				event_name: 'overlay descarga app',
			},
		},
		topTile: {
			topTileClicked: {
				eventName: 'acceder a productos y servicios',
				data: {
					event_name: 'acceder a productos y servicios',
					page_name: 'dashboard:home',
					page_typology: 'principal',
					page_subcategory_level_1: 'home',
					page_screen: 'home',
					asset_name: 'mivoweb',
					navigation_level_1: 'dashboard',
					event_category: 'carrusel',
					event_context: 'dashboard  slider - {0}',
					event_label: 'acceder a productos y servicios',
				},
			},
			topTileRetry: {
				eventName: 'click en reintentar tile principal consumo de {0}',
				data: {
					event_name: 'click en reintentar tile principal consumo de {0}',
					page_name: 'dashboard:home',
					site_section: 'dashboard',
					page_subcategory_level_1: 'home',
					page_screen: 'dashboard',
					asset_name: 'mivoweb',
					navigation_level_1: 'dashboard',
					event_category: 'boton',
					event_context: 'tile principal consumo de {0}',
					event_label: 'click reintentar tile principal',
				},
			},
			topTileSlider: {
				eventName: 'cambiar producto principal',
				data: {
					page_name: 'dashboard:home',
					navigation_level_1: 'dashboard',
					navigation_level_2: 'home',
					page_typology: 'principal',
					page_screen: 'home',
					event_name: 'cambiar producto principal',
					event_context: 'dashboard  slider - {0}',
					event_category: 'carrusel',
					event_label: 'cambiar producto principal',
				},
			},
			types: {
				landline: 'fijo',
				pospaid: 'datos llamadas y sms',
				fibre: 'fibra',
				mbb: 'mbb',
				tv: 'television',
				prepago: 'prepago',
				prepago_puro: 'prepago_puro',
			},
		},
		cardMainTileclicked: {
			eventName: 'dashboard:maintile: clic en',
			data: {
				event_name: 'click en',
			},
		},
		cardMainTileclosed: {
			eventName: 'click en cerrar consumo de {0}',
			data: {
				event_name: 'click en cerrar consumo de {0}',
				page_name: 'consumo:mi consumo:detalle de consumo:de {0}',
				page_section: 'consumo',
				page_subcategory_level_1: 'mi consumo',
				page_subcategory_level_2: 'detalle de consumo',
				page_subcategory_level_3: 'de {0}',
				page_screen: 'de {0}',
			},
		},
		cardMainTilePrepYuClicked: {
			eventName: 'click en {0} entre yuser',
			data: {
				event_name: 'click en {0} entre yuser',
				page_name: 'dashboard:home:{0} entre yuser',
				page_section: 'dashboard',
				page_subcategory_level_1: 'home',
				page_subcategory_level_2: '{0} entre yuser',
			},
		},
		cardMainTilePrepYuClosed: {
			eventName: 'click en cerrar consumo de {0}',
			data: {
				event_name: 'click en cerrar consumo de {0}',
				page_name: 'consumo:mi consumo:detalle de consumo:de {0}:entre yuser',
				page_section: 'consumo',
				page_subcategory_level_1: 'mi consumo',
				page_subcategory_level_2: 'detalle de consumo',
				page_subcategory_level_3: 'de {0}',
				page_subcategory_level_4: 'entre yuser',
				page_screen: 'entre yuser',
			},
		},
		verifyAccountclicked: {
			eventName: 'click en entendido',
			viewErrorPage: {
				page_name: '',
				page_section: 'dashboard',
				page_subcategory_level_1: 'home',
				page_subcategory_level_2: '',
				page_subcategory_level_3: 'verifica tu cuenta',
				page_subcategory_level_4: 'error',
				asset_name: 'mivoweb',
				navigation_level_1: 'dashboard',
				navigation_level_2: '',
				navigation_level_3: 'verifica tu cuenta',
				navigation_level_4: 'error',
				page_screen: 'error',
				event_name: '',
			},
			eventBtnClick: {
				page_name: '',
				page_section: 'dashboard',
				page_subcategory_level_1: 'home',
				page_subcategory_level_2: '',
				page_subcategory_level_3: 'verifica tu cuenta',
				page_subcategory_level_4: '',
				asset_name: 'mivoweb',
				navigation_level_1: 'dashboard',
				navigation_level_2: '',
				navigation_level_3: 'verifica tu cuenta',
				navigation_level_4: '',
				page_screen: 'verifica tu cuenta',
				event_name: 'click en entendido',
			},
			eventHit: {
				page_name: '',
				page_section: 'dashboard',
				page_subcategory_level_1: 'home',
				page_subcategory_level_2: '',
				page_subcategory_level_3: 'verifica tu cuenta',
				page_subcategory_level_4: '',
				asset_name: 'mivoweb',
				navigation_level_1: 'dashboard',
				navigation_level_2: '',
				navigation_level_3: 'verifica tu cuenta',
				navigation_level_4: '',
				page_screen: 'verifica tu cuenta',
				event_name: '',
				journey_name: 'cambio perfil ligero a completo',
				journey_category: 'cambio perfil ligero a completo',
				journey_type: 'eservice',
				journey_error_category: '',
				journey_error_description: '',
				journey_error_code: '',
			},
		},
		verifyAccountcloseclicked: {
			eventName: 'click en cerrar',
			data: {
				page_name: '',
				page_section: 'dashboard',
				page_subcategory_level_1: 'home',
				page_subcategory_level_2: '',
				page_subcategory_level_3: 'verifica tu cuenta',
				page_subcategory_level_4: '',
				asset_name: 'mivoweb',
				navigation_level_1: 'dashboard',
				navigation_level_2: '',
				navigation_level_3: 'verifica tu cuenta',
				navigation_level_4: '',
				page_screen: '',
				event_name: 'click en cerrar',
			},
		},

		showDataSharingClicked: {
			eventName: 'click en gestion de datos compartidos',
			data: {
				event_name: 'click en gestion de datos compartidos',
				page_name: '',
				page_section: 'dashboard:',
				page_subcategory_level_1: 'home:',
				page_subcategory_level_2: 'datos moviles:',
				page_subcategory_level_3: 'consumo datos moviles',
				page_subcategory_level_4: '',
				page_screen: 'consumo datos moviles',
				page_typology: 'overlay',
			},
		},

		productManagement: {
			eventName: 'click en {0}',
			data: {
				event_name: 'click en {0}',
				page_name: 'dashboard:home:{0}',
			},
		},
		prepaidMainTileclicked: {
			eventName: 'dashboard: click en recargar saldo',
			data: {
				event_name: 'click en recargar saldo',
			},
		},
		cardOutOfBundleClick: {
			eventName: 'click en fuera de tarifa',
			data: {
				event_name: 'click en fuera de tarifa',
			},
		},
		retryCardOutOfBundleClick: {
			eventName: 'click en reintentar fuera de tarifa',
			data: {
				event_name: 'click en reintentar fuera de tarifa',
			},
		},
		cardConsumptionClick: {
			eventName: 'click en tu consumo',
			data: {
				event_name: 'click en tu consumo',
			},
		},
		cardConsumptionPurePrepaidClick: {
			eventName: 'click en tu consumo prepago puro',
			data: {
				event_name: 'click en tu consumo prepago puro',
			},
		},
		retryCardConsumptionClick: {
			eventName: 'click en reintentar tu consumo',
			data: {
				event_name: 'click en reintentar tu consumo',
			},
		},
		retryCardConsumptionPurePrepaidClick: {
			eventName: 'click en reintentar tu consumo prepago puro',
			data: {
				event_name: 'click en reintentar tu consumo prepago puro',
			},
		},
		retryBilling: {
			eventName: 'click en reintentar la facturación',
			data: {
				event_name: 'click en reintentar la facturación',
			},
		},
		retryBalance: {
			eventName: 'click en reintentar saldo beneficios',
			data: {
				event_name: 'click en reintentar saldo beneficios',
			},
		},
		retryOBB: {
			eventName: 'click en reintentar fuera de tarifa',
			data: {
				event_name: 'click en reintentar fuera de tarifa',
			},
		},
		retryMainTile: {
			eventName: 'click en reintentar consumo general',
			data: {
				event_name: 'click en reintentar consumo general',
			},
		},
		retryMainTilePrepYu: {
			eventName: 'click en reintentar consumo general',
			data: {
				event_name: 'click en reintentar consumo general',
				page_subcategory_level_2: 'entre yuser',
				page_name: 'dashboard:home:entre yuser',
			},
		},
		listCompareClickTrack: {
			eventName: 'click en guardar cambios',
			data: {
				event_name: 'click en guardar cambios',
			},
		},
		retryMainTileSimple: {
			eventName: 'click en reintentar television',
			data: {
				event_name: 'click en reintentar television',
			},
		},
		retryOverlayConsumption: {
			eventName: 'click en reintentar',
			data: {
				event_name: 'click en reintentar',
				page_name: 'consumo:mi consumo:detalle de consumo:de {0}',
			},
		},
		retryOverlayConsumptionPrepYu: {
			eventName: 'click en reintentar',
			data: {
				event_name: 'click en reintentar',
				page_name: 'consumo:mi consumo:detalle de consumo:de {0}:entre yuser',
			},
		},
		consumptionDetailsClick: {
			eventName: 'click en consumo fuera de tarifa: card',
			data: {
				event_name: 'click en consumo fuera de tarifa: card',
			},
		},
		consumptionSmallTileClick: {
			eventName: 'click en consumo prepago puro',
			data: {
				page_name: 'dashboard:home:tu consumo',
				page_section: 'dashboard',
				page_subcategory_level_1: 'home',
				page_subcategory_level_2: 'tu consumo',
				event_name: 'click en consumo prepago puro',
				asset_name: 'mivoweb',
				navigation_level_1: 'dashboard',
				navigation_level_2: 'tu consumo',
			},
		},
		consumptionSmallTileRetry: {
			eventName: 'click en reintentar prepago puro',
			data: {
				page_name: 'dashboard:home:tu consumo',
				page_section: 'dashboard',
				page_subcategory_level_1: 'home',
				page_subcategory_level_2: 'tu consumo',
				event_name: 'click en reintentar prepago puro',
				asset_name: 'mivoweb',
				navigation_level_1: 'dashboard',
				navigation_level_2: 'tu consumo',
			},
		},
		billingTileClick: {
			eventName: 'click en ver detalle en facturación',
			data: {
				event_name: 'click en ver detalle en facturación',
			},
		},
		billingTileDeniedClick: {
			eventName: 'click en ver detalle en facturación acceso denegado',
			data: {
				event_name: 'click en ver detalle en facturación acceso denegado',
			},
		},
		withoutBillBillingTileClick: {
			eventName: 'click en sin permiso para ver factura',
			data: {
				event_name: 'click en sin permiso para ver factura',
			},
		},
		overlayPrimaryBtnClick: {
			eventName: 'click en ver detalle de consumo card',
			data: {
				event_name: 'click en ver detalle de consumo card',
				page_name: 'consumo:mi consumo',
				page_section: 'consumo',
				page_subcategory_level_1: 'mi consumo',
				page_subcategory_level_2: '',
				page_subcategory_level_3: '',
			},
		},
		overlayClickSecondaryBtn: {
			eventName: 'click en ver todos los productos y servicios',
			data: {
				event_name: 'click en ver todos los productos y servicios',
				page_name: 'consumo:mi consumo',
				page_section: 'consumo',
				page_subcategory_level_1: 'mi consumo',
				page_subcategory_level_2: '',
				page_subcategory_level_3: '',
			},
		},
		overlayPrimaryBtnPrepYuClick: {
			eventName: 'click en ver detalles consumo {0}',
			data: {
				event_name: 'click en ver detalles consumo {0}',
				page_name: 'consumo:mi consumo:detalle de consumo:de {0}:entre yuser',
				page_section: 'consumo',
				page_subcategory_level_1: 'mi consumo',
				page_subcategory_level_2: 'detalle de consumo',
				page_subcategory_level_3: 'de {0}',
				page_subcategory_level_4: 'entre yuser',
			},
		},
		overlayClickSecondaryPrepYuBtn: {
			eventName: 'click en ver todos los productos y servicios',
			data: {
				event_name: 'click en ver todos los productos y servicios',
				page_name: 'consumo:mi consumo:detalle de consumo:de {0}:entre yuser',
			},
		},
		trayMyAccountClick: {
			eventName: 'click en tray mi cuenta',
			data: {
				event_name: 'click en tray mi cuenta',
			},
		},
		trayMyServicesClick: {
			eventName: 'click en tray mis productos',
			data: {
				event_name: 'click en tray mis productos',
				page_name: '',
				page_section: 'dashboard:',
				page_subcategory_level_1: 'home:',
				page_subcategory_level_2: 'mis productos',
				page_subcategory_level_3: '',
				link_text: '',
			},
		},
		leftMenuMyAccountClick: {
			eventName: 'click en tray mi cuenta',
			data: {
				event_name: 'click en tray mi cuenta',
				page_name: 'dashboard:home',
				page_section: 'dashboard',
				page_subcategory_level_1: 'home',
			},
		},
		leftMenuMyServicesClick: {
			eventName: 'click en tray mis productos',
			data: {
				event_name: 'click en tray mis productos',
				page_name: 'dashboard:home',
			},
		},
		selectorCardClicked: {
			eventName: 'click en product',
			data: {
				event_name: 'click en product',
				page_name: '',
				page_section: 'dashboard:',
				page_detail: 'selecciona un producto',
				page_subcategory_level_1: 'home:',
				page_subcategory_level_2: 'mis productos:',
				page_subcategory_level_3: 'selecciona un producto',
				link_text: '',
				event_context: 'dashboard',
				event_category: 'card',
				event_label: '',
			},
		},
		selectorCardBusinessClicked: {
			eventName: '',
			data: {
				event_name: '',
				page_name: 'dashboard:home:mis productos:selecciona un producto',
				page_section: 'dashboard',
				page_subcategory_level_1: 'home',
				page_subcategory_level_2: 'mis productos',
				page_subcategory_level_3: 'selecciona un producto',
				page_detail: 'selecciona un producto',
				navigation_level_1: 'dashboard',
				event_category: 'card',
				event_context: 'mis productos',
				page_typology: 'principal',
				event_label: '',
				link_text: '',
			},
		},
		closeTray: {
			eventName: 'click en cerrar',
			data: {
				event_name: 'click en cerrar',
				page_name: 'dashboard:home:mis productos:selecciona un producto',
				page_section: 'dashboard:',
				page_subcategory_level_1: 'home:',
				page_subcategory_level_2: 'mis productos:',
				page_subcategory_level_3: 'selecciona un producto',
				link_text: '',
			},
		},
		clickProgTv: {
			eventName: 'dashboard:home:click en entrypoint',
			data: {
				'page_name': 'dashboard:home',
				'page_section': 'dashboard',
				'page_subcategory_level_1': 'home',
				'page_subcategory_level_2': '',
				'page_subcategory_level_3': '',
				'asset_name': 'mivoweb',
				'navigation_level_1': 'dashboard',
				'page_screen': 'dashboard',
				'event_name': 'click en entrypoint',
				'event_category': 'boton',
				'event_context': 'entrypoint banner dashboard programacion tv',
				'event_label': 'click entrypoint',
				'&&events': 'event70',
				'entrypoint_section': 'dashboard',
				'entrypoint_location': 'banner gestiona',
				'entrypoint_position': 'undefined',
				'entrypoint_title': 'consulta programacion tv',
				'experience_name': 'undefined',
				'journey_name': 'undefined',
			},
		},
		clickAverias: {
			eventName: 'dashboard:home:click en entrypoint',
			data: {
				'page_name': 'dashboard:home',
				'page_section': 'dashboard',
				'page_subcategory_level_1': 'home',
				'page_subcategory_level_2': '',
				'page_subcategory_level_3': '',
				'asset_name': 'mivoweb',
				'navigation_level_1': 'dashboard',
				'page_screen': 'dashboard',
				'event_name': 'click en entrypoint',
				'event_category': 'boton',
				'event_context': 'entrypoint banner dashboard averias',
				'event_label': 'click entrypoint',
				'&&events': 'event70',
				'entrypoint_section': 'dashboard',
				'entrypoint_location': 'banner averias',
				'entrypoint_position': 'undefined',
				'entrypoint_title': 'averias',
				'experience_name': 'undefined',
				'journey_name': 'undefined',
			},
		},
		retrySite: {
			eventName: 'click en sede reintentar',
			data: {
				event_name: 'click en sede reintentar',
				page_name: 'dashboard:home:mis productos:selecciona un producto',
				page_section: 'dashboard:',
				page_subcategory_level_1: 'home:',
				page_subcategory_level_2: 'mis productos:',
				page_subcategory_level_3: 'selecciona un producto',
				link_text: '',
			},
		},
		retryCif: {
			eventName: 'click en cif  reintentar',
			data: {
				event_name: 'click en cif  reintentar',
				page_name: 'dashboard:home:mis productos:selecciona un producto',
				page_section: 'dashboard:',
				page_subcategory_level_1: 'home:',
				page_subcategory_level_2: 'mis productos:',
				page_subcategory_level_3: 'selecciona un producto',
				link_text: '',
			},
		},
		selectCif: {
			eventName: 'click en cif',
			data: {
				event_name: 'click en cif',
				page_name: 'dashboard:home:mis productos:selecciona un producto',
				page_section: 'dashboard:',
				page_subcategory_level_1: 'home:',
				page_subcategory_level_2: 'mis productos:',
				page_subcategory_level_3: 'selecciona un producto',
				link_text: '',
			},
		},
		trayMyServicesOverlayClick: {
			eventName: 'click en tray mis productos con más de una sede',
			data: {
				event_name: 'click en tray mis productos con más de una sede',
			},
		},
		trayOverlaySiteClick: {
			eventName: 'click en sede',
			data: {
				event_name: 'click en sede',
				page_name: 'dashboard:home:mis productos:selecciona un producto',
				page_section: 'dashboard:',
				page_subcategory_level_1: 'home:',
				page_subcategory_level_2: 'mis productos:',
				page_subcategory_level_3: 'selecciona un producto',
				link_text: '',
			},
		},
		trayOverlayButtonClick: {
			eventName: 'click en que tengo contratado',
			data: {
				event_name: 'click en que tengo contratado',
				page_name: '',
				page_section: 'dashboard:',
				page_subcategory_level_1: 'home:',
				page_subcategory_level_2: 'mis productos:',
				page_subcategory_level_3: 'selecciona un producto',
				link_text: '',
			},
		},
		trayOverlayButtonClickProducts: {
			eventName: 'click en productos y servicios',
			data: {
				event_name: 'click en productos y servicios',
				page_name: '',
				page_section: 'dashboard:',
				page_subcategory_level_1: 'home:',
				page_subcategory_level_2: 'mis productos:',
				page_subcategory_level_3: 'selecciona un producto',
				link_text: '',
			},
		},
		trayOverlayButtonClickMulti: {
			eventName: 'click en que tengo contratado multisede',
			data: {
				event_name: 'click en que tengo contratado multisede',
			},
		},
		tallTilePrepaidCredit: {
			eventName: 'click en tu saldo con beneficios',
			data: {
				event_name: 'click en tu saldo con beneficios',
			},
		},
		trayTobiClick: {
			eventName: 'click en tobi',
			data: {
				event_name: 'click en tobi',
			},
		},
		renoveBenefits: {
			eventName: 'click en renovar',
			data: {
				event_name: 'click en renovar',
			},
		},
		rechargePrepaid: {
			eventName: 'click en recargar',
			data: {
				event_name: 'click en recargar',
			},
		},
		storeCardEvent: {
			eventName: 'click en tienda',
			data: {
				event_name: 'click en tienda',
			},
		},
		tvClick: {
			eventName: 'click en tv',
			data: {
				event_name: 'click en tv',
			},
		},
		entryPointsClick: {
			eventName: 'click en entrypoint',
			data: {
				'event_name': 'click en entrypoint',
				'&&events': 'event70',
				'entrypoint_position': '{0}',
				'journey_options': '{type}',
			},
		},
		backdrop: {
			view: {
				entrypoint_location: 'backdrop',
			},
			click: {
				eventName: 'click en entrypoint',
				data: {
					'event_name': 'click en entrypoint',
					'&&events': 'event70',
					'entrypoint_position': 'undefined',
					'entrypoint_location': 'backdrop',
					'experience_name': 'undefined',
				},
			},
		},
		entryPointDmp: {
			eventName: 'dashboard:home:',
			eventCases: {
				view: 'impresiones entrypoint',
				track: 'click en entrypoint ',
			},
			page: {
				page_name: 'dashboard:home:',
				page_section: 'dashboard',
				page_subcategory_level_1: 'home',
				asset_name: 'mivoweb',
				navigation_level_1: 'dashboard',
				page_screen: 'dashboard',
				event_name: 'click en entrypoint',
				event_category: 'boton',
				event_context: 'entrypoint dashboard banner personalizacion',
				event_label: 'click entrypoint',
			},
			pageQTC: {
				page_name: 'que tengo contratado:resumen de que tengo contratado',
				page_section: 'que tengo contratado',
				page_subcategory_level_1: 'resumen de que tengo contratado',
				page_screen: 'resumen de que tengo contratado',
			},
			viewData: {
				'event_name': '',
				'&&events': 'event75',
				'entrypoint_list': '',
			},
			data: {
				'page_name': 'dashboard:home:',
				'page_section': 'dashboard',
				'page_subcategory_level_1': 'home',
				'asset_name': 'mivoweb',
				'navigation_level_1': 'dashboard',
				'page_screen': 'dashboard',
				'event_name': 'impresiones entrypoint',
				'event_category': 'visualizacion',
				'event_context': 'entrypoint dashboard banner personalizacion',
				'event_label': 'impresiones entrypoint',
				'&&events': 'event75',
				'entrypoint_list': '',
			},
			trackData: {
				'&&events': 'event70',
				'page_subcategory_level_2': '{experienceName}',
				'entrypoint_section': 'dashboard',
				'entrypoint_location': 'banner personalizacion 1',
				'entrypoint_position': 'undefined',
				'entrypoint_title': '',
				'entrypoint_type': 'undefined',
				'experience_name': '',
				'journey_name': '',
			},
		},
		ManageMyAccount: {
			misDatos: {
				view: {
					page_name: 'mi cuenta:mis datos',
					site_section: 'mi cuenta',
					asset_name: 'mivoweb',
					page_detail: 'mis datos',
					page_typology: 'principal',
				},
				viewUserChooser: {
					page_name: 'mivoweb:mi cuenta:mis datos',
					asset_name: 'mivoweb',
					page_section: 'mi cuenta',
					navigation_level_1: 'mi cuenta',
					page_subcategory_level_1: 'mis datos',
					page_detail: 'mis datos',
					page_typology: 'principal',
					page_channel: 'mi vodafone',
					journey_name: '',
					journey_category: '',
					journey_subcategory: '',
					journey_type: '',
					journey_detail: '',
				},
				clickMisDatosPersonalesContacto: {
					eventName: 'mi cuenta:mis datos:click en datos personales y de contacto',
					data: {
						event_name: 'click en datos personales y de contacto',
					},
				},
				clickMisDatosAcceso: {
					eventName: 'mi cuenta:mis datos:click en datos acceso a mi vodafone',
					data: {
						event_name: 'click en datos acceso a mi vodafone',
					},
				},
				metodosPago: {
					view: {
						page_name: 'mi cuenta:mis datos',
						page_section: 'mi cuenta',
						page_subcategory_level_1: 'mis datos',
						page_subcategory_level_2: 'datos personales y de contacto',
						page_screen: 'datos personales y de contacto',
						asset_name: 'mivoweb',
						navigation_level_1: 'mi cuenta',
						navigation_level_2: 'mis datos',
						navigation_level_3: 'datos personales y de contacto',
					},
					clickMetodosPago: {
						eventName: 'mi cuenta:mis datos:acceder a mis metodos de pago',
						data: {
							event_name: 'acceder a mis metodos de pago',
							event_category: 'boton',
							event_context: 'mis datos',
							event_action: 'click',
						},
					},
				},
				clickNuevoAutorizado: {
					eventName: 'mi cuenta:mis datos:click en crear nuevo autorizado',
					data: {
						event_name: 'click en crear nuevo autorizado',
					},
				},
				clickEliminar: {
					eventName: 'mi cuenta: mis datos:gestionar usuarios:eliminar usuario',
					data: {
						'event_name': 'eliminar usuario',
						'event_action': 'click',
						'event_category': 'boton',
						'event_context': 'gestionar usuarios',
						'&&events': 'event27',
					},
				},
				nuevoAutorizado: {
					view: {
						page_name: 'mi cuenta:mis datos:crear nuevo autorizado',
						page_section: 'mi cuenta',
						page_subcategory_level_1: 'mis datos',
						page_subcategory_level_2: 'crear nuevo autorizado',
						page_screen: 'crear nuevo autorizado',
					},
					crearAutorizado: {
						eventName: 'mi cuenta:mis datos:crear nuevo autorizado',
						data: {
							event_name: 'click en crear nuevo autorizado',
						},
					},
					crearAutorizadoSuccess: {
						view: {
							page_name: 'mi cuenta:mis datos:crear nuevo autorizado:ok',
							page_section: 'mi cuenta',
							page_subcategory_level_1: 'mis datos',
							page_subcategory_level_2: 'crear nuevo autorizado',
							page_subcategory_level_3: 'ok',
							page_screen: 'ok',
						},
					},
					crearAutorizadoFail: {
						view: {
							page_name: 'mi cuenta:mis datos:crear nuevo autorizado:ko',
							page_section: 'mi cuenta',
							page_subcategory_level_1: 'mis datos',
							page_subcategory_level_2: 'crear nuevo autorizado',
							page_subcategory_level_3: 'ko',
							page_screen: 'ko',
							error_descripcion: '{0}',
							error_category: '{0}',
							error_type: '{0}',
						},
					},
				},
				gestionarUsuarios: {
					view: {
						page_name: 'mi cuenta:mis datos:gestionar usuarios',
						asset_name: 'mivoweb',
						site_section: 'mi cuenta',
						page_detail: 'gestionar usuarios',
						page_typology: 'principal',
						journey_name: 'gestionar usuarios',
						journey_category: 'particulares',
						journey_subcategory: 'postpago',
						journey_type: 'servicio',
						journey_detail: 'home',
					},
					viewUserChooser: {
						page_name: 'mivoweb:mi cuenta:mis datos:gestionar usuarios',
						asset_name: 'mivoweb',
						page_section: 'mi cuenta',
						page_detail: 'gestionar usuarios',
						page_typology: 'principal',
						journey_name: 'gestionar usuarios',
						journey_category: 'particulares',
						journey_subcategory: 'postpago',
						journey_type: 'servicio',
						journey_detail: 'home',
					},
					viewUserChooserDetail: {
						page_name: 'mivoweb:mi cuenta:mis datos:gestionar usuarios:detalle usuario',
						asset_name: 'mivoweb',
						page_section: 'mi cuenta',
						page_detail: 'detalle usuario',
						page_typology: 'principal',
						journey_subcategory: 'postpago',
						journey_type: 'servicio',
						journey_name: 'gestionar usuarios',
						journey_detail: 'detalle usuario',
					},
					viewListUsers: {
						page_name: 'mi cuenta:mis datos:gestionar usuarios:detalle usuario',
						asset_name: 'mivoweb',
						site_section: 'mi cuenta',
						page_detail: 'gestionar usuarios',
						page_typology: 'principal',
						journey_name: 'gestionar usuarios',
						journey_category: 'particulares',
						journey_subcategory: 'postpago',
						journey_detail: 'detalle usuario',
						journey_type: 'servicio',
					},
					clickGestionarUsuarios: {
						eventName: 'mi cuenta:mis datos',
						data: {
							event_category: 'boton',
							event_context: 'gestionar usuarios',
							event_label: 'acceder gestionar usuarios',
						},
					},
					clickGestionarUsuariosUserChooser: {
						eventName: 'mi cuenta:mis datos',
						data: {
							event_category: 'boton',
							event_context: 'gestionar usuarios',
							event_label: 'acceder detalle usuario',
							journey_service_name: '',
						},
					},
					clickDetalleUsuario: {
						eventName: 'mi cuenta:mis datos',
						data: {
							event_category: 'boton',
							event_context: 'gestionar usuarios',
							event_label: 'acceder detalle usuario',
						},
					},
					clickDetalleUsuarioUserChooser: {
						eventName: 'mi cuenta:mis datos',
						data: {
							event_category: 'boton',
							event_context: 'gestionar usuarios',
							event_label: 'acceder detalle usuario',
							journey_service_name: '',
						},
					},
					clickChangePermissionUserChooser: {
						eventName: 'mi cuenta:mis datos',
						data: {
							page_name: 'mivoweb:mi cuenta:mis datos:gestionar usuarios:detalle usuario',
							event_category: 'toogle',
							event_context: 'gestionar usuarios',
							event_label: '{s} permiso',
							journey_process: '{s} permisos',
							journey_detail: 'start',
							journey_status: 'start',
							journey_subcategory: 'postpago',
							journey_service_name: '',
						},
					},
					clickDeleteUserChooser: {
						eventName: 'mi cuenta:mis datos',
						data: {
							page_name: 'mivoweb:mi cuenta:mis datos:gestionar usuarios:detalle usuario',
							event_category: 'boton',
							event_context: 'gestionar usuarios',
							event_label: 'eliminar usuario',
							journey_process: 'eliminar usuarios',
							journey_detail: 'start',
							journey_status: 'start',
							journey_subcategory: 'postpago',
							journey_service_name: '',
						},
					},
					viewChangePermissionUserChooserBackdrop: {
						eventName: 'mi cuenta:mis datos',
						data: {
							page_name: 'mivoweb:mi cuenta:mis datos:gestionar usuarios:detalle usuario:gestionar permisos',
							page_detail: 'gestionar permisos',
							page_typology: 'overlay',
							event_category: '',
							event_context: '',
							event_label: '',
							journey_status: '',
							journey_process: '{s} permisos',
							journey_detail: 'confirmacion',
							journey_subcategory: 'postpago',
							journey_service_name: '',
						},
					},
					viewDeleteUserChooserBackdrop: {
						eventName: 'mi cuenta:mis datos',
						data: {
							page_name: 'mivoweb:mi cuenta:mis datos:gestionar usuarios:detalle usuario:eliminar usuarios',
							page_detail: 'eliminar usuarios',
							page_typology: 'overlay',
							event_category: '',
							event_context: '',
							event_label: '',
							journey_status: '',
							journey_process: 'eliminar usuarios',
							journey_detail: 'confirmacion',
							journey_subcategory: 'postpago',
							journey_service_name: '',
						},
					},
					clickChangePermissionUserChooserBackdrop: {
						eventName: 'mi cuenta:mis datos',
						data: {
							page_name: 'mivoweb:mi cuenta:mis datos:gestionar usuarios:detalle usuario:gestionar permisos',
							page_detail: 'gestionar permisos',
							page_typology: 'overlay',
							event_category: 'boton',
							event_context: 'gestionar usuarios',
							event_label: '{s}',
							journey_status: '',
							journey_process: '{s} permisos',
							journey_detail: 'confirmacion',
							journey_subcategory: 'postpago',
							journey_service_name: '',
						},
					},
					clickDeletenUserChooserBackdrop: {
						eventName: 'mi cuenta:mis datos',
						data: {
							page_name: 'mivoweb:mi cuenta:mis datos:gestionar usuarios:detalle usuario:eliminar usuarios',
							page_detail: 'gestionar permisos',
							page_typology: 'overlay',
							event_category: 'boton',
							event_context: 'gestionar usuarios',
							event_label: 'confirmar eliminar usuario',
							journey_status: '',
							journey_process: 'eliminar usuarios',
							journey_detail: 'confirmacion',
							journey_subcategory: 'postpago',
							journey_service_name: '',
						},
					},
					viewChangePermissionUserChooserBackdropOk: {
						eventName: 'mi cuenta:mis datos',
						data: {
							page_name: 'mivoweb:mi cuenta:mis datos:gestionar usuarios:detalle usuario:gestionar permisos:ok',
							page_detail: 'gestionar permisos:ok',
							page_typology: 'overlay',
							event_category: '',
							event_context: '',
							event_label: '',
							journey_status: 'end',
							journey_process: '{s} permisos',
							journey_detail: 'ok',
							journey_subcategory: 'postpago',
							journey_service_name: '',
						},
					},
					viewDeleteUserChooserBackdropOk: {
						eventName: 'mi cuenta:mis datos',
						data: {
							page_name: 'mivoweb:mi cuenta:mis datos:gestionar usuarios:detalle usuario:eliminar usuario:ok',
							page_detail: 'eliminar usuarios:ok',
							page_typology: 'overlay',
							event_category: '',
							event_context: '',
							event_label: '',
							journey_status: 'end',
							journey_process: 'eliminar usuarios',
							journey_detail: 'ok',
							journey_subcategory: 'postpago',
							journey_service_name: '',
						},
					},
					viewChangePermissionUserChooserBackdropKo: {
						eventName: 'mi cuenta:mis datos',
						data: {
							page_name:
								'mivoweb:mi cuenta:mis datos:gestionar usuarios:detalle usuario:gestionar permisos:ko servicio',
							page_detail: 'gestionar permisos:ko servicio',
							page_typology: 'overlay',
							event_category: '',
							event_context: '',
							event_label: '',
							journey_status: '',
							journey_process: '{s} permisos',
							journey_detail: 'ko servicio',
							journey_subcategory: 'postpago',
							journey_service_name: '',
						},
					},
					viewDeleteUserChooserBackdropKo: {
						eventName: 'mi cuenta:mis datos',
						data: {
							page_name: 'mivoweb:mi cuenta:mis datos:gestionar usuarios:detalle usuario:eliminar usuario:ko servicio',
							page_detail: 'eliminar usuario:ko servicio',
							page_typology: 'overlay',
							event_category: '',
							event_context: '',
							event_label: '',
							journey_status: '',
							journey_process: 'eliminar usuarios',
							journey_detail: 'ko servicio',
							journey_subcategory: 'postpago',
							journey_service_name: '',
						},
					},
					viewDetalleUsuario: {
						page_name: 'mi cuenta:mis datos:gestionar usuarios:detalle usuario',
						asset_name: 'mivoweb',
						page_section: 'mi cuenta',
						page_detail: 'detalle usuario',
						page_typology: 'principal',
						journey_name: 'gestionar usuarios',
						journey_category: 'particulares',
						journey_subcategory: 'postpago',
						journey_type: 'servicio',
						journey_detail: 'detalle usuario',
					},
				},
				eliminarUsuario: {
					view: {
						page_name: 'mi cuenta:mis datos:gestionar usuarios:detalle usuario',
						asset_name: 'mivoweb',
						site_section: 'mi cuenta',
						page_detail: 'detalle usuario',
						page_typology: 'principal',
						journey_name: 'gestionar usuarios',
						journey_category: 'particulares',
						journey_subcategory: 'postpago',
						journey_process: 'eliminar usuarios',
						journey_type: 'servicio',
						journey_detail: 'start',
						journey_status: 'start',
					},
					viewEliminarUsuario: {
						page_name: 'mi cuenta:mis datos:gestionar usuarios:detalle usuario:eliminar usuario',
						asset_name: 'mivoweb',
						site_section: 'mi cuenta',
						page_detail: 'eliminar usuario',
						page_typology: 'principal',
						journey_name: 'gestionar usuarios',
						journey_category: 'particulares',
						journey_subcategory: 'postpago',
						journey_process: 'eliminar usuarios',
						journey_type: 'servicio',
						journey_detail: 'confirmacion',
					},
					clickEliminarUsuario: {
						eventName: 'mi cuenta:mis datos:gestionar usuarios:detalle usuario',
						data: {
							event_label: 'eliminar usuario',
							event_category: 'boton',
							event_context: 'gestionar usuarios',
						},
					},
				},
				confirmEliminar: {
					view: {
						page_name: 'mi cuenta:mis datos:gestionar usuarios:detalle usuario:eliminar usuarios',
						asset_name: 'mivoweb',
						site_section: 'mi cuenta',
						page_typology: 'principal',
						page_detail: 'eliminar usuarios',
						journey_name: 'gestionar usuarios',
						journey_category: 'particulares',
						journey_subcategory: 'postpago',
						journey_type: 'servicio',
						journey_detail: 'confirmacion',
						journey_process: 'eliminar usuario',
					},
					clickConfirmEliminar: {
						eventName: 'mi cuenta:mis datos:gestionar usuarios:detalle usuario:eliminar usuarios',
						data: {
							event_category: 'boton',
							event_context: 'gestionar usuarios',
							event_label: 'confirmar eliminar usuario',
						},
					},
					bannerUsuarioEliminado: {
						eventName: 'mi cuenta:mis datos:gestionar usuarios:eliminar usuarios:usuario eliminado',
						data: {
							'event_name': 'usuario eliminado',
							'event_action': 'visualizacion',
							'event_category': 'alert',
							'event_context': 'gestionar usuarios',
							'event_label': '{0}',
							'&&events': 'event28',
						},
					},
				},
				eliminarUsuarioFail: {
					view: {
						page_name: 'mi cuenta:mis datos:gestionar usuarios:detalle usuario:eliminar usuarios:ko parcial',
						asset_name: 'mivoweb',
						site_section: 'mi cuenta',
						page_detail: 'eliminar usuario:ko parcial',
						page_typology: 'principal',
						journey_name: 'gestionar usuarios',
						journey_category: 'particulares',
						journey_subcategory: 'postpago',
						journey_process: 'eliminar usuarios',
						journey_type: 'servicio',
						journey_detail: 'ko parcial',
					},
				},
				eliminarUsuarioOk: {
					view: {
						page_name: 'mi cuenta:mis datos:gestionar usuarios:detalle usuario:eliminar usuarios:ok',
						asset_name: 'mivoweb',
						site_section: 'mi cuenta',
						page_detail: 'eliminar usuario:ok',
						page_typology: 'principal',
						journey_name: 'gestionar usuarios',
						journey_category: 'particulares',
						journey_subcategory: 'postpago',
						journey_process: 'eliminar usuarios',
						journey_type: 'servicio',
						journey_detail: 'ok',
						journey_status: 'end',
					},
				},
				eliminarUsuarioFailTotal: {
					view: {
						page_name: 'mi cuenta:mis datos:gestionar usuarios:detalle usuario:eliminar usuarios:ko total',
						asset_name: 'mivoweb',
						site_section: 'mi cuenta',
						page_detail: 'eliminar usuario:ko total',
						page_typology: 'principal',
						journey_name: 'gestionar usuarios',
						journey_category: 'particulares',
						journey_subcategory: 'postpago',
						journey_process: 'eliminar usuarios',
						journey_type: 'servicio',
						journey_detail: 'ko total',
					},
				},
				editar: {
					view: {
						page_name: 'mi cuenta:mis datos',
						page_section: 'mi cuenta',
						page_subcategory_level_1: 'mis datos',
						page_subcategory_level_2: '',
						page_screen: 'mis datos',
					},
					genericError: {
						view: {
							page_name: 'mi cuenta:mis datos',
							page_section: 'mi cuenta',
							page_subcategory_level_1: 'mis datos',
							page_subcategory_level_2: '',
							page_screen: 'mis datos',
							error_descripcion: '{0}',
							error_category: '{0}',
						},
					},
					clickEdit: {
						eventName: 'mi cuenta:mis datos:click en modificar campo {0}',
						data: {
							event_name: 'click en modificar campo {0}',
						},
					},
					showOverlay: {
						view: {
							page_name: 'mi cuenta:mis datos:modificar campo {0}',
							page_section: 'mi cuenta',
							page_subcategory_level_1: 'mis datos',
							page_subcategory_level_2: 'modificar campo {0}',
							page_screen: 'modificar campo {0}',
						},
						clickLlamarCancelar: {
							eventName: 'mi cuenta:mis datos:modificar campo {0}:click en {1}',
							data: {
								event_name: 'click en {1}',
							},
						},
						clickGuardar: {
							eventName: 'mi cuenta:mis datos:modificar campo {0}:click en guardar',
							data: {
								event_name: 'click en guardar',
							},
						},
						clickLogout: {
							eventName: 'mi cuenta:mis datos:modificar campo {0}:cerrar sesion',
							data: {
								event_name: 'click en {1}',
							},
						},
					},
					showOverlayVerifyAddress: {
						view: {
							page_name: 'mi cuenta:mis datos:modificar campo direccion de facturacion:verificar direccion',
							page_section: 'mi cuenta',
							page_subcategory_level_1: 'mis datos',
							page_subcategory_level_2: 'modificar campo direccion de facturacion',
							page_subcategory_level_3: 'verificar direccion',
							page_screen: 'verificar direccion',
						},
						clickSave: {
							eventName:
								'mi cuenta:mis datos:modificar campo direccion de facturacion:verificar direccion:click en guardar',
							data: {
								event_name: 'click en guardar',
							},
						},
						clickCancel: {
							eventName:
								'mi cuenta:mis datos:modificar campo direccion de facturacion:verificar direccion:click en cancelar',
							data: {
								event_name: 'click en cancelar',
							},
						},
					},
					showOverlayOTP: {
						view: {
							page_name:
								'mi cuenta:mis datos:modificar campo direccion de facturacion:verificar direccion:confirmacion otp',
							page_section: 'mi cuenta',
							page_subcategory_level_1: 'mis datos',
							page_subcategory_level_2: 'modificar campo direccion de facturacion',
							page_subcategory_level_3: 'verificar direccion',
							page_screen: 'confirmacion otp',
						},
						clickReSend: {
							eventName:
								'mi cuenta:mis datos:modificar campo direccion de facturacion:verificar direccion:confirmacion otp:click en solicitar codigo',
							data: {
								event_name: 'click en solicitar codigo',
							},
						},
						clickConfirmar: {
							eventName:
								'mi cuenta:mis datos:modificar campo direccion de facturacion:verificar direccion:confirmacion otp:click en solicitar confirmar',
							data: {
								event_name: 'click en confirmar',
							},
						},
					},
					showOverlayConfirmationEmail: {
						view: {
							page_name: 'mi cuenta:mis datos:modificar campo {0}:email confirmacion enviado',
							page_section: 'mi cuenta',
							page_subcategory_level_1: 'mis datos',
							page_subcategory_level_2: 'modificar campo {0}',
							page_subcategory_level_3: 'email confirmacion enviado',
							page_screen: 'modificar campo {0}',
						},
					},
					showOverlaySuccess: {
						view: {
							page_name: 'mi cuenta:mis datos:modificar campo {0}:ok',
							page_section: 'mi cuenta',
							page_subcategory_level_1: 'mis datos',
							page_subcategory_level_2: 'modificar campo {0}',
							page_subcategory_level_3: 'ok',
							page_screen: 'ok',
						},
					},
					showOverlayError: {
						view: {
							page_name: 'mi cuenta:mis datos:modificar campo {0}:ko',
							page_section: 'mi cuenta',
							page_subcategory_level_1: 'mis datos',
							page_subcategory_level_2: 'modificar campo {0}',
							page_subcategory_level_3: 'ko',
							page_screen: 'ko',
						},
						clickRetry: {
							eventName: 'mi cuenta:mis datos:modificar campo {0}:ko:click en reintentar',
							data: {
								event_name: 'click en reintentar',
							},
						},
					},
				},
			},
			misPagos: {
				view: {
					page_name: 'mi cuenta:mis pagos',
					page_section: 'mi cuenta',
					page_subcategory_level_1: 'mis pagos',
					page_screen: 'mis pagos',
				},
				clickMetodosDePago: {
					eventName: 'mi cuenta:mis pagos:click en mis metodos de pago',
					data: {
						event_name: 'click en mis metodos de pago',
					},
				},
				clickPagoFacturas: {
					eventName: 'mi cuenta:mis pagos:click en pago de facturas',
					data: {
						event_name: 'click en pago de facturas',
					},
				},
				clickCopyIban: {
					eventName: 'copiar iban',
					data: {
						page_name: 'pagos:identificacion:sin acceso para clarify',
						navigation_level_1: 'pagos',
						navigation_level_2: 'identificacion',
						navigation_level_3: 'sin acceso para clarify',
						page_typology: 'principal',
						page_screen: 'sin acceso para clarify',
						event_name: 'copiar iban',
						event_context: 'error identificacion pago anonimo clarify',
						event_category: 'link',
						event_label: 'copiar iban',
					},
				},
			},
			contratosPermanencias: {
				view: {
					page_name: 'mi cuenta:contratos y permanencias',
					page_section: 'mi cuenta',
					page_subcategory_level_1: 'contratos y permanencias',
					page_screen: 'contratos y permanencias',
				},
				clickContratos: {
					eventName: 'mi cuenta:contratos y permanencias:click en mis contratos',
					data: {
						event_name: 'click en mis contratos',
					},
				},
				clickPermanencias: {
					eventName: 'mi cuenta:contratos y permanencias:click en mis permanencias',
					data: {
						event_name: 'click en mis permanencias',
					},
				},
				clickTileCargo: {
					eventName: 'mivoweb:mi cuenta:mis permanencias:click en cargo por instalacion',
					data: {
						event_name: 'click en cargo por instalacion',
						event_category: 'boton',
						event_context: 'card cargo por instalacion',
						event_label: 'cargo por instalacion',
					},
				},
				installationCharge: {
					view: {
						page_name: 'mivoweb:mi cuenta:mis permanencias:cargo por instalacion',
						asset_name: 'mivoweb',
						navigation_level_1: 'mi cuenta',
						navigation_level_2: 'mis permanencias',
						navigation_level_3: 'cargo por instalacion',
						navigation_level_4: '',
						page_screen: 'cargo por instalacion',
					},
				},
				detallePermanencias: {
					view: {
						page_name: 'mi cuenta:mis permanencias:detalle de permanencias',
						page_section: 'mi cuenta',
						page_subcategory_level_1: 'mis permanencias',
						page_subcategory_level_2: 'detalle de permanencias',
						page_subcategory_level_3: '',
						page_screen: 'detalle de permanencias',
						page_typology: 'principal',
						deeplink: true,
					},
				},
				myContractsAndDocuments: {
					view: {
						page_name: 'mi cuenta:contratos y permanencias:mis contratos y documentos',
						page_section: 'mi cuenta',
						page_subcategory_level_1: 'contratos y permanencias',
						page_subcategory_level_2: 'mis contratos y documentos',
						page_screen: 'mis contratos y documentos',
					},
					clickTab: {
						eventName: 'mi cuenta:contratos y permanencias:click en {0}',
						data: {
							event_name: 'click en {0}',
						},
					},
					clickViewMore: {
						eventName: 'mi cuenta:contratos y permanencias:click en ver mas {0}',
						data: {
							event_name: 'click en ver mas {0}',
						},
					},
					clickDownload: {
						eventName: 'mi cuenta:contratos y permanencias:click en descargar {0}',
						data: {
							event_name: 'click en descargar {0}',
						},
					},
				},
			},
			privacidad: {
				view: {
					page_name: 'mi cuenta:privacidad',
					page_section: 'mi cuenta',
					page_subcategory_level_1: 'privacidad',
					page_screen: 'privacidad',
				},
				clickPermisos: {
					eventName: 'mi cuenta:privacidad:click en permisos',
					data: {
						event_name: 'click en permisos',
					},
				},
				clickPreferencias: {
					eventName: 'mi cuenta:privacidad:click en preferencias',
					data: {
						event_name: 'click en preferencias',
					},
				},
				clickUsoRed: {
					eventName: 'mi cuenta:privacidad:click en uso de red',
					data: {
						event_name: 'click en uso de red',
					},
				},
				clickDiagnosticoVodafone: {
					eventName: 'mi cuenta:privacidad:click en diagnostico de mi vodafone',
					data: {
						event_name: 'click en diagnostico de mi vodafone',
					},
				},
			},
		},
		greeting: {
			fault: {
				show: {
					eventName: 'aviso averias',
					data: {
						page_name: 'dashboard:home:aviso averias',
						page_section: 'dashboard',
						page_subcategory_level_1: 'home',
						page_subcategory_level_2: 'aviso averias',
						page_screen: 'aviso averias',
						event_name: 'aviso averias',
						event_category: 'visualizacion',
						event_context: 'nudge averias',
						event_label: 'aviso averias',
					},
				},
				click: {
					eventName: 'click seguir estado de tu averia',
					data: {
						page_name: 'dashboard:home:aviso averias',
						page_section: 'dashboard',
						page_subcategory_level_1: 'home',
						page_subcategory_level_2: 'aviso averias',
						page_screen: 'aviso averias',
						event_name: 'click seguir estado de tu averia',
						event_category: 'link',
						event_context: 'nudge averias',
						event_label: 'seguir estado de tu averia',
					},
				},
			},
			debt: {
				show: {
					eventName: 'aviso deuda',
					data: {
						page_name: 'dashboard:home:aviso deuda',
						page_section: 'dashboard',
						page_subcategory_level_1: 'home',
						page_subcategory_level_2: 'aviso deuda',
						page_screen: 'aviso deuda',
						event_name: 'aviso deuda',
						event_category: 'visualizacion',
						event_context: 'nudge deuda',
						event_label: 'aviso deuda',
					},
				},
				click: {
					eventName: 'click solucionar',
					data: {
						page_name: 'dashboard:home:aviso deuda',
						page_section: 'dashboard',
						page_subcategory_level_1: 'home',
						page_subcategory_level_2: 'aviso deuda',
						page_screen: 'aviso deuda',
						event_name: 'click solucionar',
						event_category: 'link',
						event_context: 'nudge deuda',
						event_label: 'solucionar',
					},
				},
			},
		},
		activeSiteSignClick: {
			eventName: 'dashboard:home:acceder a firma de contrato',
			data: {
				event_name: 'acceder a firma de contrato',
				event_action: 'click',
				event_category: 'boton',
				event_context: 'dashboard con servicios activos',
				event_label: 'firmar',
			},
		},
		pendingInstallSignClick: {
			eventName: 'dashboard:home:acceder a firma de contrato',
			data: {
				event_name: 'acceder a firma de contrato',
				event_action: 'click',
				event_category: 'boton',
				event_context: 'dashboard sin servicios activos',
				event_label: 'firmar',
			},
		},
		pendingInstallMyOrdersClick: {
			eventName: 'dashboard:home:click en ver estado de pedidos',
			data: {
				event_name: 'click en ver estado de pedidos',
			},
		},
		pendingInstallMyOrdersTrayClick: {
			eventName: 'dashboard:home:mis productos:selecciona un producto:click en ver estado de pedidos',
			data: {
				event_name: 'click en ver estado de pedidos',
			},
		},
		subscriptionOnlineTrayClick: {
			eventName: 'mivoweb:dashboard:home:mis productos:selecciona un producto:click en suscripciones online',
			data: {
				page_name: 'mivoweb:dashboard:home:mis productos:selecciona un producto',
				asset_name: 'mivoweb',
				navigation_level_1: 'dashboard',
				navigation_level_2: 'home',
				navigation_level_3: 'mis productos',
				navigation_level_4: 'selecciona un producto',
				page_screen: 'selecciona un producto',
				event_name: 'click en suscripciones online',
				event_category: 'selector',
				event_label: 'suscripciones online',
			},
		},
		subscriptionOnlineDashboardClick: {
			eventName: 'mivoweb:dashboard:home:suscripciones online:click en suscripciones online',
			data: {
				page_name: 'mivoweb:dashboard:home:suscripciones online',
				asset_name: 'mivoweb',
				navigation_level_1: 'dashboard',
				navigation_level_2: 'home',
				navigation_level_3: 'suscripciones online',
				event_name: 'click en suscripciones online',
				event_category: 'link',
				event_context: 'seccion gestiona tus productos',
				event_label: 'suscripciones online',
			},
		},
		xrContents: {
			dashboardView: {
				'page_name': 'dashboard:home',
				'event_name': 'impresiones entrypoint',
				'page_section': 'dashboard',
				'page_subcategory_level_1': 'home',
				'page_screen': 'home',
				'&&events': 'event75',
				'entrypoint_list': 'es={es}|el={el}|jn={jn}|et={et}|ep={ep}',
			},
			dashboardClick: {
				'page_name': 'dashboard:home',
				'event_name': 'click en entrypoint',
				'page_section': 'dashboard',
				'page_subcategory_level_1': 'home',
				'page_screen': 'home',
				'&&events': 'event70',
				'entrypoint_section': 'dashboard',
				'entrypoint_location': 'por ser de vodafone',
				'entrypoint_position': '2',
				'entrypoint_title': 'descarga la app de vr vodafone 5g reality',
				'journey_name': 'descargar app',
			},
			detailView: {
				page_name: 'productos y servicios:vodafone 5g reality',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'vodafone 5g reality',
				page_screen: 'vodafone 5g reality',
				journey_name: 'descargar app',
			},
			detailClick: {
				page_name: 'productos y servicios:vodafone 5g reality',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'vodafone 5g reality',
				page_screen: 'vodafone 5g reality',
				journey_name: 'descargar app',
			},
			bannerView: {
				'page_name': 'productos y servicios:vodafone 5g reality',
				'event_name': 'impresiones entrypoint',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'vodafone 5g reality',
				'page_screen': 'vodafone 5g reality',
				'&&events': 'event75',
				'entrypoint_list': 'es={es}|el={el}|jn={jn}|et={et}|ep=undefined',
			},
			bannerClick: {
				'page_name': 'productos y servicios:vodafone 5g reality',
				'event_name': 'click en entrypoint',
				'event_category': 'boton',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'vodafone 5g reality',
				'page_screen': 'vodafone 5g reality',
				'&&events': 'event70',
				'entrypoint_section': 'pys',
				'entrypoint_location': 'banner pys',
				'entrypoint_title': 'vive la experiencia con las nreal',
				'journey_name': 'descargar app',
			},
		},
	},
	sidemenu: {
		itemClicked: {
			parentItem: {
				eventName: 'menu:clic en {0}', // {0} --> parent item name
				data: {
					event_name: 'menu:clic en {0}',
				},
			},
			childItem: {
				eventName: 'menu:clic en {0}:{1}', // {0} --> parent item name, {1} --> child item name
				data: {
					event_name: 'menu:clic en {0}:{1}',
				},
			},
		},
	},
	consumption: {
		clickOnDetails: {
			eventName: 'consumo:mi consumo:detalle de consumo: clic en ver todos los productos y servicios',
			data: {
				event_name: 'clic en ver todos los productos y servicios',
			},
		},
	},
	bill: {
		shared: {
			button123: {
				itemClicked: {
					eventName: '{0}:clic en llama al 123',
					data: {
						event_name: 'clic en llama al 123',
					},
				},
			},
			clickToDownloadPdf: {
				// used in billing details ,biling overview , device invoice
				downloadBilling: {
					eventName: '{0}:descarga:clic en descargar factura en pdf',
					data: {
						event_name: 'descarga:clic en descargar factura en pdf',
						document_name: '',
					},
				},
				downloadCreditNote: {
					// useed in download credit note
					eventName: '{0}:descarga:descargar abono',
					data: {
						event_name: 'descarga:descargar abono',
						document_name: '',
					},
				},
				downloadPaymentLetter: {
					// useed in download payment Letter
					eventName: '{0}:descarga:descargar carta de pago',
					data: {
						event_name: 'descarga:descargar carta de pago',
					},
				},
			},
			consumptionTypeTab: {
				eventName: '{0}:clic en {1}', // {0} pagename and {1} must be llamadas/internet/mensajes
				data: {
					event_name: 'clic en {1}', // {1} must be llamadas/internet/mensajes
				},
			},
			currentConsumptionTab: {
				eventName: '{0}:clic en {1}', // {0} pagename and {1} must be extra|incluido|todo
				data: {
					event_name: 'clic en {1}', // {1} must be extra|incluido|todo
				},
			},
		},
		billOverview: {
			swipeMonthBar: {
				eventName: 'billOverview:swipe en mes',
				data: {
					event_name: 'swipe en mes',
				},
			},
		},
		billDetails: {
			clickMonthBar: {
				eventName: 'billDetails:clic en histograma',
				data: {
					event_name: 'clic en histograma',
				},
			},
			clickCallId: {
				eventName: 'facturas:mis facturas:detalle de factura:clic en gestionar identificador llamada',
				data: {
					event_name: 'clic en gestionar identificador llamada ',
				},
			},
			clickGestionarServicio: {
				eventName: 'facturas:mis facturas:detalle de factura:clic en gestionar servicio',
				data: {
					event_name: 'clic en gestionar servicio securenet',
				},
			},
			clickCallIdEntendidoPendienteDesactivacion: {
				eventName: 'gestionar identificador llamada:pendiente desactivacion:clic en entendido',
				data: {
					event_name: 'clic en entendido',
				},
			},
			clickCallIdEntendidoPendienteActivacion: {
				eventName: 'gestionar identificador llamada:pendiente activacion:clic en entendido',
				data: {
					event_name: 'clic en entendido',
				},
			},
		},
		callOptions: {
			clickCallIdToggleActive: {
				eventName: 'ajustes de linea:opciones de llamada:clic en activar identificador llamada',
				data: {
					event_name: 'clic en activar identificador llamada',
				},
			},

			clickCallIdToggleDesActive: {
				eventName: 'ajustes de linea:opciones de llamada:clic en desactivar identificador llamada',
				data: {
					event_name: 'clic en desactivar identificador llamada',
				},
			},

			callIDJourney: {
				firstStep: {
					eventName: 'gestionar identificador llamada:start',
					data: {
						journey_name: 'gestionar identificador llamada:start',
						journey_category: 'gestionar identificador llamada',
						journey_subcategory: '',
						journey_type: 'otros',
					},
				},
				secondStep: {
					eventName: 'gestionar identificador llamada:preconfirmacion',
					data: {
						journey_name: 'gestionar identificador llamada:preconfirmacion',
						journey_category: 'gestionar identificador llamada',
						journey_subcategory: '',
						journey_type: 'otros',
					},
				},
				success: {
					eventName: 'gestionar identificador llamada:ok',
					data: {
						journey_name: 'gestionar identificador llamada:ok',
						journey_category: 'gestionar identificador llamada',
						journey_subcategory: '',
						journey_type: 'otros',
					},
				},
				error: {
					eventName: 'gestionar identificador llamada:ko',
					data: {
						journey_name: 'gestionar identificador llamada:ko',
						journey_category: 'gestionar identificador llamada',
						journey_subcategory: '',
						journey_type: 'otros',
						journey_error_category: '',
						journey_error_description: '',
						journey_error_code: '',
					},
				},
			},
			privateNumberJourney: {
				firstStep: {
					eventName: 'ocultar tu numero:start',
					data: {
						journey_name: 'ocultar tu numero:start',
						journey_category: 'ocultar tu numero',
						journey_subcategory: '',
						journey_type: 'otros',
					},
				},
				secondStep: {
					eventName: 'ocultar tu numero:preconfirmacion',
					data: {
						journey_name: 'ocultar tu numero:preconfirmacion',
						journey_category: 'ocultar tu numero',
						journey_subcategory: '',
						journey_type: 'otros',
					},
				},
				success: {
					eventName: 'ocultar tu numero:ok',
					data: {
						journey_name: 'ocultar tu numero:ok',
						journey_category: 'ocultar tu numero',
						journey_subcategory: '',
						journey_type: 'otros',
					},
				},
				error: {
					eventName: 'ocultar tu numero:ko',
					data: {
						journey_name: 'ocultar tu numero:ko',
						journey_category: 'ocultar tu numero',
						journey_subcategory: '',
						journey_type: 'otros',
						journey_error_category: '',
						journey_error_description: '',
						journey_error_code: '',
					},
				},
			},
			outgoingCallJourney: {
				firstStep: {
					eventName: 'restringir llamadas salientes:start',
					data: {
						journey_name: 'restringir llamadas salientes:start',
						journey_category: 'restringir llamadas salientes',
						journey_subcategory: '',
						journey_type: 'otros',
					},
				},
				secondStep: {
					eventName: 'restringir llamadas salientes:preconfirmacion',
					data: {
						journey_name: 'restringir llamadas salientes:preconfirmacion',
						journey_category: 'restringir llamadas salientes',
						journey_subcategory: '',
						journey_type: 'otros',
					},
				},
				success: {
					eventName: 'restringir llamadas salientes:ok',
					data: {
						journey_name: 'restringir llamadas salientes:ok',
						journey_category: 'restringir llamadas salientes',
						journey_subcategory: '',
						journey_type: 'otros',
					},
				},
				error: {
					eventName: 'restringir llamadas salientes:ko',
					data: {
						journey_name: 'restringir llamadas salientes:ko',
						journey_category: 'restringir llamadas salientes',
						journey_subcategory: '',
						journey_type: 'otros',
						journey_error_category: '',
						journey_error_description: '',
						journey_error_code: '',
					},
				},
			},
			whileAnsweringJourney: {
				firstStep: {
					eventName: 'desvio de llamadas:start',
					data: {
						journey_name: 'desvio de llamadas:start',
						journey_category: 'desvio de llamadas',
						journey_options: '{slider-text}:{activar|desactivar}',
						journey_type: 'eservice',
					},
				},
				secondStep: {
					eventName: 'desvio de llamadas:preconfirmacion',
					data: {
						journey_name: 'desvio de llamadas:preconfirmacion',
						journey_category: 'desvio de llamadas',
						journey_options: '{slider-text}:{activar|desactivar}',
						journey_type: 'eservice',
					},
				},
				success: {
					eventName: 'desvio de llamadas:ok',
					data: {
						journey_name: 'desvio de llamadas:ok',
						journey_category: 'desvio de llamadas',
						journey_options: '{slider-text}:{activar|desactivar}',
						journey_type: 'eservice',
					},
				},
				error: {
					eventName: 'desvio de llamadas:ko',
					data: {
						journey_name: 'desvio de llamadas:ko',
						journey_category: 'desvio de llamadas',
						journey_options: '{slider-text}:{activar|desactivar}',
						journey_type: 'eservice',
						journey_error_category: '',
						journey_error_description: '',
						journey_error_code: '',
					},
				},
			},
			whenNotAnsweredJourney: {
				firstStep: {
					eventName: 'desvio de llamadas:start',
					data: {
						journey_name: 'desvio de llamadas:start',
						journey_category: 'desvio de llamadas',
						journey_options: '{slider-text}:{activar|desactivar}',
						journey_type: 'eservice',
					},
				},
				secondStep: {
					eventName: 'desvio de llamadas:preconfirmacion',
					data: {
						journey_name: 'desvio de llamadas:preconfirmacion',
						journey_category: 'desvio de llamadas',
						journey_options: '{slider-text}:{activar|desactivar}',
						journey_type: 'eservice',
					},
				},
				success: {
					eventName: 'desvio de llamadas:ok',
					data: {
						journey_name: 'desvio de llamadas:ok',
						journey_category: 'desvio de llamadas',
						journey_options: '{slider-text}:{activar|desactivar}',
						journey_type: 'eservice',
					},
				},
				error: {
					eventName: 'desvio de llamadas:ko',
					data: {
						journey_name: 'desvio de llamadas:ko',
						journey_category: 'desvio de llamadas',
						journey_options: '{slider-text}:{activar|desactivar}',
						journey_type: 'eservice',
						journey_error_category: '',
						journey_error_description: '',
						journey_error_code: '',
					},
				},
			},
		},
		billPayment: {
			finalStep: {
				start: {
					eventName: 'pago de factura:start',
					data: {
						journey_name: 'pago de factura:start',
						journey_category: 'pago de factura',
						journey_subcategory: '', // will be pagar factura actual / pagar factura por adelantado / pagar factura adeudada
						journey_type: 'eservice',
						journey_model: '', // will be pago completo / pago fraccionado
					},
				},
				success: {
					eventName: 'pago de factura:ok',
					data: {
						'journey_name': 'pago de factura:ok',
						'journey_category': 'pago de factura',
						'journey_subcategory': '', // will be pagar factura actual / pagar factura por adelantado / pagar factura adeudada
						'journey_type': 'eservice',
						'journey_model': '', // will be pago completo / pago fraccionado
						'&&events': 'event87= {0}', // will be pago completo / pago fraccionado
					},
				},
				error: {
					eventName: 'pago de factura:ko',
					data: {
						journey_name: 'pago de factura:ko',
						journey_category: 'pago de factura',
						journey_subcategory: '', // will be pagar factura actual / pagar factura por adelantado / pagar factura adeudada
						journey_type: 'eservice',
						journey_model: '', // will be pago completo / pago fraccionado
						journey_error_category: '',
						journey_error_description: '',
						journey_error_code: '',
					},
				},
			},
			extraStep: {
				success: {
					eventName: 'pago de factura:justificante:ok',
					data: {
						journey_name: 'pago de factura:justificante:ok',
						journey_category: 'pago de factura',
						journey_subcategory: '', // will be pagar factura actual / pagar factura por adelantado / pagar factura adeudada
						journey_type: 'eservice',
						journey_model: '', // will be pago completo / pago fraccionado
					},
				},
				error: {
					eventName: 'pago de factura:justificante:ko',
					data: {
						journey_name: 'pago de factura:justificante:ko',
						journey_category: 'pago de factura',
						journey_subcategory: '', // will be pagar factura actual / pagar factura por adelantado / pagar factura adeudada
						journey_type: 'eservice',
						journey_model: '', // will be pago completo / pago fraccionado
						journey_error_category: '',
						journey_error_description: '',
					},
				},
			},
		},
		billConfig: {
			errorDescription: 'error en el guardado de datos',
		},

		callIdJourney: {
			firstStep: {
				eventName: 'gestionar identificador llamada:start',
				data: {
					journey_name: 'gestionar identificador llamada:start',
					journey_category: 'gestionar identificador llamada',
					journey_subcategory: '',
					journey_type: 'otros',
				},
			},
			secondStep: {
				eventName: 'gestionar identificador llamada:preconfirmacion',
				data: {
					journey_name: 'gestionar identificador llamada:preconfirmacion',
					journey_category: 'gestionar identificador llamada',
					journey_subcategory: '',
					journey_type: 'otros',
				},
			},
			success: {
				eventName: 'gestionar identificador llamada:ok',
				data: {
					journey_name: 'gestionar identificador llamada:ok',
					journey_category: 'gestionar identificador llamada',
					journey_subcategory: '',
					journey_type: 'otros',
				},
			},
			error: {
				eventName: 'gestionar identificador llamada:ko',
				data: {
					journey_name: 'gestionar identificador llamada:ko',
					journey_category: 'gestionar identificador llamada',
					journey_subcategory: '',
					journey_type: 'otros',
					journey_error_category: 'error en el servidor',
					journey_error_description: '',
					journey_error_code: '',
				},
			},
		},
		currenrConsumption: {
			openMonthSelector: {
				/** {0} should be the page name */
				eventName: '{0}:clic en ver periodos anteriores',
				data: {
					event_name: 'clic en ver periodos anteriores',
				},
			},
		},
	},
	topUp: {
		topUpHistory: {
			selectDate: {
				eventName: 'recargas:historico de recargas:clic en cambiar fecha',
				data: {
					event_name: 'clic en cambiar fecha',
				},
			},
		},
		topUpPrepaid: {
			start: {
				eventName: 'recargar saldo:start',
				data: {
					journey_name: 'recargar saldo:start',
					journey_category: 'recargar saldo',
					journey_options: '',
					journey_type: 'compra',
				},
			},
			success: {
				eventName: 'recargar saldo:ok',
				data: {
					journey_name: 'recargar saldo:ok',
					journey_category: 'recargar saldo',
					journey_options: '',
					journey_type: 'compra',
					journey_step: 'recargar saldo:ok',
				},
			},
			error: {
				eventName: 'recargar saldo:ko',
				data: {
					journey_name: 'recargar saldo:ko',
					journey_category: 'recargar saldo',
					journey_options: '',
					journey_error_category: '',
					journey_error_description: 'error en el guardado de datos',
					journey_type: 'compra',
					journey_error_code: '',
				},
				errorMessage: 'error en el guardado de datos',
			},
		},
	},
	inbox: {
		listing: {
			swipe: {
				eventName: 'mensajes:bandeja de entrada:swipe en mensaje',
				data: {
					event_name: 'swipe en mensaje',
				},
			},
			multiSelectToDelete: {
				deleteJourney: {
					start: {
						eventName: 'eliminar mensajes:start',
						data: {
							journey_name: 'eliminar mensajes:start',
							journey_category: 'eliminar mensajes',
							journey_subcategory: 'leido / no leido',
							journey_options: '',
							journey_type: 'otros',
						},
					},
					success: {
						eventName: 'eliminar mensajes:ok',
						data: {
							journey_name: 'eliminar mensajes:ok',
							journey_category: 'eliminar mensajes',
							journey_subcategory: 'leido / no leido',
							journey_options: '',
							journey_type: 'otros',
						},
					},
					error: {
						eventName: 'eliminar mensajes:ko',
						data: {
							journey_name: 'eliminar mensajes:ko',
							journey_category: 'eliminar mensajes',
							journey_subcategory: 'leido / no leido',
							journey_options: '',
							journey_type: 'otros',
						},
					},
				},
				cancel: {
					eventName: 'mensajes:bandeja de entrada:clic en cancelar',
					data: {
						event_name: 'clic en cancelar',
					},
				},
				confirm: {
					eventName: 'mensajes:bandeja de entrada:clic en eliminar mensajes',
					data: {
						event_name: 'clic en eliminar mensajes',
					},
				},
			},
		},
		details: {
			clickDetails: {
				eventName: 'mensajes:bandeja de entrada:detalle de mensaje:clic en {0}',
				data: {
					event_name: 'clic en {0}',
				},
			},
		},
	},
	crossEvents: {
		downloadDocument: {
			eventName: 'descarga:clic en descargar',
			data: {
				event_name: 'descarga:clic en descargar',
				document_name: '',
			},
		},
		biztalkNudge: {
			eventName: '{0}:biztalk error nudge shown',
			data: {
				event_name: 'biztalk error nudge shown',
			},
		},
	},
	dataSharing: {
		start: {
			eventName: 'compartir datos:start',
			data: {
				journey_name: 'compartir datos:start',
				journey_category: 'compartir datos',
				journey_type: 'eservice',
			},
		},
		success: {
			eventName: 'compartir datos:ok',
			data: {
				journey_name: 'compartir datos:ok',
				journey_category: 'compartir datos',
				journey_type: 'eservice',
			},
		},
		error: {
			eventName: 'compartir datos:ko',
			data: {
				journey_name: 'compartir datos:ko',
				journey_category: 'compartir datos',
				journey_type: 'eservice',
			},
		},
		page: {
			page_name: 'dashboard: home: datos moviles: consumo de datos moviles',
			page_section: '',
			page_subcategory_level_1: 'datos moviles',
			page_subcategory_level_2: 'cosumo de datos moviles',
			page_screen: '',
			page_typology: 'principal',
			page_platform: 'web',
		},
		data: {
			event_name: 'click en gestion de datos compartidos',
		},
	},
	serviceSettings: {
		interactions: {
			switchTabs: {
				eventName: 'ajustes de linea:pagos a vodafone y terceros:compras y suscripciones:click on {0}',
				data: {
					event_name: 'clic on {0}',
				},
			},
			filterByDate: {
				eventName: 'ajustes de linea:pagos a vodafone y terceros:compras y suscripciones:clic en cambiar fecha',
				data: {
					event_name: 'clic en cambiar fecha',
				},
			},
			cancelSubscription: {
				eventName: 'ajustes de linea:pagos a vodafone y terceros:compras y suscripciones:click cancel subscritpion',
				data: {
					event_name: 'click cancel subscritpion',
				},
			},
			tabSubscriptionClick: {
				view: {
					page_name: 'productos y servicios:resumen de productos y servicios',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'resumen de productos y servicios',
					page_subcategory_level_2: '',
					page_screen: 'resumen de productos y servicios',
					asset_name: 'mivoweb',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: 'resumen de productos y servicios',
					navigation_level_3: '',
				},
				clickEvent: {
					eventName: 'productos y servicios:resumen de productos y servicios',
					data: {
						event_name: 'acceder a mis {0}',
						event_action: 'click',
						event_category: 'tab',
						event_context: 'productos y servicios',
						event_label: 'acceder a {0}',
					},
				},
			},
			pillsSubscriptionClick: {
				view: {
					page_name: 'productos y servicios:resumen de productos y servicios:mis suscripciones',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'resumen de productos y servicios',
					page_subcategory_level_2: 'mis suscripciones',
					page_screen: 'mis suscripciones',
					asset_name: 'mivoweb',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: 'resumen de productos y servicios',
					navigation_level_3: 'mis suscripciones',
				},
				clickEvent: {
					eventName: 'productos y servicios:resumen de productos y servicios:mis suscripciones:acceder a {0}',
					data: {
						event_name: 'acceder a {0}',
						event_action: 'click',
						event_category: 'pill',
						event_context: 'productos y servicios',
						event_label: 'acceder a {0}',
					},
				},
			},
			showSubscriptionDetail: {
				view: {
					page_name: 'productos y servicios:resumen de productos y servicios:mis suscripciones:suscripciones',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'resumen de productos y servicios',
					page_subcategory_level_2: 'mis suscripciones',
					page_subcategory_level_3: 'suscripciones',
					page_screen: 'suscripciones',
					asset_name: 'mivoweb',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: 'resumen de productos y servicios',
					navigation_level_3: 'mis suscripciones',
					navigation_level_4: 'suscripciones',
				},
				clickEvent: {
					eventName: 'productos y servicios:resumen de productos y servicios:mis suscripciones:suscripciones',
					data: {
						event_name: 'ver detalle {0}',
						event_action: 'click',
						event_category: 'acordeon',
						event_context: 'productos y servicios',
						event_label: 'ver detalle {0}',
					},
				},
			},
			navigateToEntertainment: {
				eventName:
					'productos y servicios:resumen de productos y servicios:mis suscriciones:contratar mas entretenimiento',
				data: {
					event_name: 'contratar mas entretenimiento',
					event_action: 'click',
					event_category: 'boton',
					event_context: 'productos y servicios',
					event_label: '{0}',
				},
			},
			closeSubscriptionDetail: {
				view: {
					page_name:
						'productos y servicios:resumen de productos y servicios:mis suscripciones:suscripciones:detalle {0}',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'resumen de productos y servicios',
					page_subcategory_level_2: 'mis suscripciones',
					page_subcategory_level_3: 'suscripciones',
					page_subcategory_level_4: 'detalle {0}',
					page_screen: 'detalle {0}',
					asset_name: 'mivoweb',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: 'resumen de productos y servicios',
					navigation_level_3: 'mis suscripciones',
					navigation_level_4: 'suscripciones',
					navigation_level_5: 'detalle {0}',
				},
				clickEvent: {
					eventName:
						'productos y servicios:resumen de productos y servicios:mis suscripciones:suscripciones:detalle {0}',
					data: {
						event_name: 'cerrar detalle {0}',
						event_action: 'click',
						event_category: 'acordeon',
						event_context: 'productos y servicios',
						event_label: 'cerrar detalle {0}',
					},
				},
			},
			subscriptionCancel: {
				eventName: 'productos y servicios:resumen de productos y servicios:mis suscripciones:dar de baja {0}',
				data: {
					event_name: 'dar de baja {0}',
					event_action: 'click',
					event_category: 'boton',
					event_context: 'productos y servicios',
					event_label: 'dar de baja {0}',
				},
			},
			subscriptionOverlay: {
				eventName:
					'productos y servicios:resumen de productos y servicios:mis suscripciones:confirmar restringir suscripcion',
				data: {
					event_name: 'confirmar restringir suscripcion',
					event_action: 'click',
					event_category: 'boton',
					event_context: 'productos y servicios',
					event_label: 'confirmar restringir suscripcion',
				},
			},
			subscriptionOverlayER: {
				eventName: 'productos y servicios:resumen de productos y servicios:mis suscripciones:confirmar er',
				data: {
					event_name: 'confirmar er',
					event_action: 'click',
					event_category: 'boton',
					event_context: 'productos y servicios',
					event_label: '{0}',
				},
			},
			restrictionLoad: {
				view: {
					page_name:
						'productos y servicios:resumen de productos y servicios:mis suscripciones:gestion de restricciones',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'resumen de productos y servicios',
					page_subcategory_level_2: 'mis suscripciones',
					page_subcategory_level_3: 'gestion de restricciones',
					page_screen: 'gestion de restricciones',
					asset_name: 'mivoweb',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: 'resumen de productos y servicios',
					navigation_level_3: 'mis suscripciones',
					navigation_level_4: 'gestion de restricciones',
				},
			},
			restrictionPermitProduct: {
				view: {
					page_name:
						'productos y servicios:resumen de productos y servicios:mis suscripciones:gestion de restricciones',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'resumen de productos y servicios',
					page_subcategory_level_2: 'mis suscripciones',
					page_subcategory_level_3: 'gestion de restricciones',
					page_screen: 'gestion de restricciones',
					asset_name: 'mivoweb',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: 'resumen de productos y servicios',
					navigation_level_3: 'mis suscripciones',
					navigation_level_4: 'gestion de restricciones',
				},
				clickEvent: {
					eventName:
						'productos y servicios:resumen de productos y servicios:mis suscripciones:gestion de restricciones',
					data: {
						event_name: 'permitir servicio {0}',
						event_action: 'click',
						event_category: 'toggle',
						event_context: 'productos y servicios',
						event_label: '{0}',
					},
				},
			},
			restrictionNoPermitProduct: {
				view: {
					page_name:
						'productos y servicios:resumen de productos y servicios:mis suscripciones:gestion de restricciones',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'resumen de productos y servicios',
					page_subcategory_level_2: 'mis suscripciones',
					page_subcategory_level_3: 'gestion de restricciones',
					page_screen: 'gestion de restricciones',
					asset_name: 'mivoweb',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: 'resumen de productos y servicios',
					navigation_level_3: 'mis suscripciones',
					navigation_level_4: 'gestion de restricciones',
				},
				clickEvent: {
					eventName:
						'productos y servicios:resumen de productos y servicios:mis suscripciones:gestion de restricciones',
					data: {
						event_name: 'no permitir servicio {0}',
						event_action: 'click',
						event_category: 'toggle',
						event_context: 'productos y servicios',
						event_label: '{0}',
					},
				},
			},
			restrictionProductKOParcial: {
				view: {
					page_name:
						'productos y servicios:resumen de productos y servicios:mis suscripciones:gestion de restricciones:ko parcial',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'resumen de productos y servicios',
					page_subcategory_level_2: 'mis suscripciones',
					page_subcategory_level_3: 'gestion de restricciones',
					page_subcategory_level_4: 'ko parcial',
					page_screen: 'ko parcial',
					asset_name: 'mivoweb',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: 'resumen de productos y servicios',
					navigation_level_3: 'mis suscripciones',
					navigation_level_4: 'gestion de restricciones',
					navigation_level_5: 'ko parcial',
				},
				error: {
					data: {
						error_list:
							'et=tecnico|el=frontend|ec=<error_code>|em=<error_transactionId>|ed=<error_description>|eh=<error_http>',
					},
				},
			},
			restrictSubscription: {
				view: {
					page_name: 'productos y servicios:resumen de productos y servicios:mis suscripciones',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'resumen de productos y servicios',
					page_subcategory_level_2: 'mis suscripciones',
					page_screen: 'mis suscripciones',
					asset_name: 'mivoweb',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: 'resumen de productos y servicios',
					navigation_level_3: 'mis suscripciones',
				},
				clickEvent: {
					eventName:
						'productos y servicios:resumen de productos y servicios:mis suscripciones:restringir suscripciones',
					data: {
						event_name: 'restringir suscripciones',
						event_action: 'click',
						event_category: 'boton',
						event_context: 'productos y servicios',
						event_label: '{0}',
					},
				},
			},
		},
		journeys: {
			cnacleSubscription: {
				start: {
					eventName: 'cancel subscription:start',
					data: {
						journey_name: 'cancel subscription:start',
						journey_category: 'cancel subscription',
						journey_options: '',
					},
				},
				success: {
					eventName: 'cancel subscription:ok',
					data: {
						journey_name: 'cancel subscription:ok',
						journey_category: 'cancel subscription',
						journey_options: '<subName>',
					},
				},
				error: {
					eventName: 'cancel subscription:ko',
					data: {
						journey_name: 'cancel subscription:ko',
						journey_category: 'cancel subscription',
						journey_options: '',
						journey_error_category: '',
						journey_error_description: '',
					},
				},
			},
		},
	},
	errorTypes: {
		functional: 'funcional',
		tecnical: 'tecnico',
		security: 'seguridad',
	},
	errorDescription: {
		timeout: 'timeout',
	},
	errorCategories: {
		serverError: 'server error',
	},
	campaign: {
		view: {
			page_name: 'overlay lego:{0}bloqueante:<campaign id>',
			page_section: 'overlay lego',
			page_subcategory_level_1: '{0}bloqueante',
			page_subcategory_level_2: '<campaign id>',
			page_screen: '<campaign id>',
			page_typology: 'overlay',
		},
		save: {
			eventName: 'overlay lego:{0}bloqueante:<campaign id>:clic en guardar',
			data: {
				event_name: 'clic en guardar',
			},
		},
		continue: {
			eventName: 'overlay lego:{0}bloqueante:<campaign id>:clic en continuar sin guardar',
			data: {
				event_name: 'clic en continuar sin guardar',
			},
		},
		close: {
			eventName: 'overlay lego:{0}bloqueante:<campaign id>:clic en cerrar',
			data: {
				event_name: 'clic en cerrar',
			},
		},
	},
	gdpr: {
		overlay: {
			xButton: {
				eventName: 'permisos:gdpr:clic en cerrar popup',
				data: {
					event_name: 'clic en cerrar popup',
				},
			},
			confirmPermission: {
				eventName: 'permisos:gdpr:clic en confirmar permisos',
				data: {
					event_name: 'clic en confirmar permisos',
				},
			},
			goToPermissionsAndPreferences: {
				eventName: 'permisos:gdpr:clic en ir a permisos y preferencias',
				data: {
					event_name: 'clic en ir a permisos y preferencias',
				},
			},
			overlayGDPRImpressions: {
				eventName: 'permisos:gdpr:impresion overlay gdpr',
				data: {
					event_name: 'impresion overlay gdpr',
				},
			},
		},
		details: {
			confirmPermission: {
				eventName: 'permisos:gdpr:mas informacion:clic en confirmar permisos',
				data: {
					event_name: 'clic en confirmar permisos',
				},
			},
			goToPermissionsButton: {
				eventName: 'permisos:gdpr:mas informacion:clic en ir a permisos',
				data: {
					event_name: 'clic en ir a permisos',
				},
			},
		},
	},
	SharedData: {
		updateListCompare: {
			firstStep: {
				eventName: 'cambio limite de datos:start',
				data: {
					journey_name: 'cambio limite de datos:start',
					journey_category: 'cambio limite de datos',
					jouney_type: 'eservice',
					page_name: 'dashboard: home: datos moviles: consumo datos moviles: gestiona tus datos compartidos start',
				},
			},
			success: {
				eventName: 'cambio limite de datos:ok',
				data: {
					journey_name: 'cambio limite de datos:ok',
					journey_category: 'cambio limite de datos',
					jouney_type: 'eservice',
					page_name: 'dashboard: home: datos moviles: consumo datos moviles: gestiona tus datos compartidos ok',
				},
			},
			error: {
				eventName: 'cambio limite de datos:ko',
				data: {
					journey_name: 'cambio limite de datos:ko',
					journey_category: 'cambio limite de datos',
					jouney_type: 'eservice',
					journey_error_category: '{error}',
					journey_error_description: '{error description}',
					journey_error_code: '{error code}',
				},
			},
		},
	},
	CIFSelector: {
		loadCIF: {
			eventName: '',
			data: {
				page_name: 'dashboard:selector de cif',
				page_section: 'dashboard',
				page_subcategory_level_1: 'selector de cif',
			},
		},
		changeCIF: {
			eventName: 'click en cambio de cif',
			data: {
				page_name: 'dashboard:selector de cif',
				page_section: 'dashboard',
				page_subcategory_level_1: 'selector de cif',
				event_name: 'click en cambio de cif',
			},
		},
		closeCIF: {
			eventName: 'click en cierre',
			data: {
				page_name: 'dashboard:selector de cif',
				page_section: 'dashboard',
				page_subcategory_level_1: 'selector de cif',
				event_name: 'click en cierre',
			},
		},
		initCIF: {
			eventName: 'click en empezar',
			data: {
				page_name: 'dashboard:selector de cif',
				page_section: 'dashboard',
				page_subcategory_level_1: 'selector de cif',
				event_name: 'click en empezar',
			},
		},
		errorCIF: {
			view: {
				page_name: 'dashboard:selector cif:error de acceso',
				page_section: 'dashboard',
				page_subcategory_level_1: 'selector cif',
				page_subcategory_level_2: 'error de acceso',
				page_screen: 'error de acceso',
				error_category: '',
				error_descripcion: '',
			},
		},
	},
	lineServices: {
		editLineService: {
			firstStep: {
				eventName: 'edit {line service}:start',
				data: {
					journey_name: 'edit {line service}:start',
					journey_category: 'edit {line service}',
					jouney_options: '{toggle value}',
				},
			},
			success: {
				eventName: 'edit {line service}:ok',
				data: {
					journey_name: 'edit {line service}:ok',
					journey_category: 'edit {line service}',
					jouney_options: '{toggle value}',
				},
			},
			error: {
				eventName: 'edit {line service}:ko',
				data: {
					journey_name: 'edit {line service}:ko',
					journey_category: 'edit {line service}',
					jouney_options: '{toggle value}',
					journey_error_category: '<error>',
					journey_error_description: '<error description>',
				},
			},
		},
	},
	unavailabilityOverlay: {
		page_name: 'overlay no disponibilidad:{0}',
		page_typology: 'overlay',
	},
	clickOnContactUsNeedHelp: {
		eventName: 'webview:ayuda y chat:necesitas mas ayuda:clic en boton necesitas mas ayuda',
		data: {
			event_name: 'clic en boton necesitas mas ayuda',
		},
	},
	migration: {
		nba_show: {
			event_name: 'nba destiny',
		},
		landing: {
			event_name: 'landing',
		},
		entryPoint: {
			banner: {
				event_name: 'clic acceso destiny banner',
				event_name_canje: 'click acceso destiny:entry point:banner canje',
			},
			espacio_fijo: {
				event_name: 'clic acceso destiny espacio fijo',
			},
		},
		spinner: {
			event_name: 'clic cambio msisdn',
		},
		detail: {
			event_name: 'clic en detalle tarifa',
		},
		commitments: {
			commitment: {
				event_name: 'clic en desplegable permanencia',
			},
			detail: {
				event_name: 'clic en ver detalle penalizacion permanencia',
			},
			keeps: {
				event_name: 'clic en desplegable sigues manteniendo',
			},
		},
		keeps: {
			event_name: 'clic en desplegable sigues manteniendo',
		},
		ticket_summary: {
			event_name: 'clic en ver resumen',
		},
		activate: {
			event_name: 'continuar',
			start: 'start',
		},
		confirmActivate: {
			event_name: 'Activar Destiny',
			start: 'start',
		},
		email: {
			event_name: 'confirmar email',
			start: 'start',
			continue: 'continue',
		},
		confirm: {
			continue: {
				event_name: 'clic en seguir con mi pedido',
			},
			ok: {
				event_name: 'Activar Destiny: ok',
			},
		},
		page: {
			page_name: 'migracion destiny:paso{step}:{title}',
			page_section: 'migracion destiny',
			page_subcategory_level_1: 'paso {step}',
			page_subcategory_level_2: '',
			page_screen: 'tu tarifa destiny',
			page_typology: 'principal',
			page_platform: 'web',
		},
		data: {
			nba_title: 'Tarifa Destiny',
			result_fee: null,
			help_bubble_status: 'disponible',
			msisdn: null,
			event_name: null,
			plan_commitments: null,
			lifecycle_timestamp: null,
			aditional_line_availability: null,
			mobile_exchange_availability: null,
			promotion_code: null,
			client_origin: null,
			journey_type: '{0}',
		},
		event: {
			event_name: '{0}',
		},
		deepLink: {
			'entrypoint_section': 'deeplink',
			'entrypoint_location': '{cn}',
			'entrypoint_position': 'undefined',
			'entrypoint_title': 'undefined',
			'entrypoint_type': '',
			'experience_name': 'undefined',
			'entrypoint_issue': '',
			'entrypoint_label': '',
			'entrypoint_groupnba': '',
			'entrypoint_name': '',
			'journey_name': '{0}',
			'&&events': 'event70',
		},
		purchase: {
			'&&events': 'purchase',
			'purchaseID': '{purchaseID}',
		},
		purchase_classification: {
			purchase_product_classification: null,
		},
		journey: {
			journey_name: 'activar destiny:{result}',
			journey_category: 'activar destiny',
			journey_error_category: null,
			journey_error_description: null,
			journey_error_code: null,
			contractedrate: null,
			aditional_line: null,
			mobile_exchange: null,
		},
		ctc: {
			page_name: 'CTC sin tarifa',
			page_typology: 'principal',
			page_platform: 'web',
			nba_availability: 'no',
			nba_show: 'no',
			event_name: 'clic acceso destiny banner',
		},
		dataCTC: {
			nba_title: 'Tarifa Destiny LLamar',
			nba_availability: 'no',
			nba_show: 'no',
			result_fee: null,
			help_bubble_status: 'disponible',
			msisdn: null,
			plan_commitments: null,
			event_name: 'clic',
		},
		ctcBanner: {
			page_name: 'mi vodafone:migracion:overlay entry point:ctc',
			page_typology: 'principal',
			page_platform: 'web',
			nba_availability: 'no',
			nba_show: 'no',
			page_section: 'migracion destiny',
			page_subcategory_level_1: '',
			page_screen: 'tu tarifa destiny',
		},
		dataCTCBanner: {
			nba_title: 'Tarifa Destiny LLamar',
			nba_availability: 'no',
			nba_show: 'no',
			result_fee: null,
			help_bubble_status: 'disponible',
			msisdn: null,
			plan_commitments: null,
		},
		ctcButton: {
			page_name: '',
			page_typology: 'principal',
			page_platform: 'web',
			nba_availability: 'no',
			nba_show: 'no',
			page_section: 'mi vodafone',
			page_subcategory_level_1: 'migracion',
			page_subcategory_level_2: 'overlay entry point',
			page_subcategory_level_3: 'ctc',
			page_screen: 'ctc',
		},
		dataCTCButton: {
			nba_title: 'Tarifa Destiny LLamar',
			nba_availability: 'no',
			nba_show: 'no',
			result_fee: null,
			help_bubble_status: 'disponible',
			msisdn: null,
			plan_commitments: null,
			event_name: 'ctc sin tarifa:click en boton llamanos',
		},
		errorCTC: {
			'error_type': 'funcional',
			'error_category': 'dxl_{0}',
			'error_descripcion': '{0}',
			'error_code': '{0}',
			'&&events': '{0}',
		},
		Canje: {
			step: 'Overlay Información Oferta',
			delete: 'Quitar terminal',
			add: 'Añadir terminal',
		},
		type: {
			action: 'action',
			state: 'state',
			journey: 'journey',
			email: 'email',
		},
		steps: {
			_3: '3',
			_4: '4',
		},
		texts: {
			ok: 'ok',
			ko: 'ko',
			tariff: 'tu tarifa destiny',
			welcome: 'bienvenido a destiny',
			preconfirm: 'preconfirmacion',
			confirm_email: 'confirmar email',
			request_email: 'solicitud mail',
			funcional_error: 'error de entrypoint',
			funcional_error_event: 'event102',
			tecnical_error_event: 'event100',
		},
		typology: {
			principal: 'principal',
			overlay: 'overlay',
			detail: 'detalle',
		},
		dmpCtc: {
			view: {
				page_name: 'mi vodafone:migracion:overlay entry point:ctc',
				page_section: 'mi vodafone',
				page_subcategory_level_1: 'migracion',
				page_subcategory_level_2: 'overlay entry point',
				page_subcategory_level_3: 'ctc',
				page_screen: 'ctc',
			},
			click: {
				eventName: 'click en boton llamanos',
				data: {
					event_name: 'click en boton llamanos',
				},
			},
		},
		errorsServies: {
			page: {
				'page_name': '',
				'page_section': 'migracion destiny',
				'page_subcategory_level_1': 'paso {step}',
				'page_subcategory_level_2': '{tarifaDestiny}',
				'page_screen': '{error}',
				'&&events': '{events}',
			},
			category: {
				error_category: '{error_category}',
				error_descripcion: '{error_description}',
				error_type: 'tecnico',
				error_code: '{error_code}',
			},
		},
		backdropDL: {
			eventClickCTA: {
				event_name: 'click en descubrir ofertas',
				event_category: 'boton',
				event_context: 'mas ofertas deeplink pega',
				event_label: '',
			},
			eventView: {
				event_name: 'visualizacion backdrop deeplink',
				event_category: 'backdrop',
				event_context: 'deeplink pega',
				event_label: '',
			},
		},
	},
	aditionalLines: {
		banner: {
			event_name: 'Añade más líneas móviles',
		},
		select_line: {
			event_name: 'seleccionar linea adicional',
		},
		page_text: 'tu tarifa destiny:lineas adicionales',
		subcategory1: 'paso 3',
		subcategory3: 'tarifa móvil que deseas añadir',
		screen: 'linea adicional',
		overlay: {
			title: 'tu tarifa destiny:mas info linea datos',
			screen: 'mas info linea datos',
		},
		dashboard: {
			home: 'migracion destiny:paso3:tu tarifa destiny',
			event_name: 'click acceso destiny:entry point:banner:lineas adicionales',
			selector: {
				page_name: 'dashboard:home:mis productos',
			},
		},
		delete: {
			subcategory1: 'líneas adicionales',
			subcategory2: 'eliminar línea',
			page_name: 'migracion destiny:paso3:tu tarifa destiny:lineas adicionales:eliminar linea',
			overlay: 'overlay',
			event_name: 'confirmación eliminar línea',
		},
	},
	newTVPacks: {
		verpacks: {
			eventName: 'productos y servicios:click en <text>',
			data: {
				event_name: 'click en <text>',
			},
		},
		page: {
			page_subcategory_level_3: '',
			page_screen: '',
		},
		journey: {
			'journey_name': '',
			'journey_category': '',
			'journey_subcategory': '',
			'journey_options': '',
			'journey_type': '',
			'&&products': null,
			'journey_error_category': '',
			'journey_error_description': '',
			'journey_error_code': '',
			'purchase_entrypoint': 'productos y servicios',
			'&&events': 'event16',
			'transaction_shipping': '',
		},
		journey_cases: {
			contract_tv: 'contratacion tv',
			contract_pack: 'contratacion mas packs',
			third_party: 'contenido a terceros tv',
			change_deco: 'cambio boxless a deco',
			cancel_pack: 'baja paquetes',
			last_pack: 'ultimo paquete',
		},
		journey_steps: {
			start: ':inicio',
			preconfirm: ':preconfirmacion',
			ok: ':ok',
			ko: ':ko',
			confirm: ':confirmacion',
		},
		journey_types: {
			contract: 'contratacion',
			buy: 'compra',
			cancel: 'baja servicio',
		},
		varProducts: {
			category: 'addons',
			subcategory: 'fide:principal',
		},
		newProductsTag: {
			body: 'addons;<name>;1;<price>;eVar187=<subcategory>|eVar188=<promotion>',
			promotion: '<type>:gratis <free>',
			install: 'con instalador',
			autoInstall: 'autoinstalacion',
			name: '<decodificador>',
			undefined: 'undefined',
		},
		tvmode: {
			deco: 'con deco',
			nodeco: 'sin deco',
			deco4k: 'decodificador 4k',
			deco4kPro: 'decodificador 4k pro',
			migra: 'boxless',
		},
		installationMode: {
			auto: 'autoinstalacion',
			technician: 'con instalador',
		},
		error_messages: {
			functional: 'error funcional',
			incompatibilities: 'ups, los packs de series son incompatibles entre sí | <pack>',
			coverage: 'lo sentimos no podemos ofrecerle vodafone tv',
		},
		detail: {
			eventName: 'productos y servicios:extras:packs vodafone tv:click en ver detalles <pack>',
			data: {
				event_name: 'click en ver detalles <pack>',
			},
		},
		detailEvent: {
			eventName: 'productos y servicios:extras:packs vodafone tv:<pack>:click en <copy>',
			data: {
				event_name: 'click en <copy>',
			},
		},
		purchasePack: {
			transactional: {
				eventName: 'productos y servicios:extras:packs vodafone tv:click en contratar packs seleccionados',
				data: {
					event_name: 'click en contratar packs seleccionados',
				},
			},
			price: {
				eventName: 'productos y servicios:extras:packs vodafone tv:click en contratar pack por <price>',
				data: {
					event_name: 'click en contratar pack por <price>',
				},
			},
			deco_no_deco: {
				eventName: 'productos y servicios:extras:contratacion tv:seleccionar con/sin deco:click en boton <text>',
				data: {
					event_name: 'click en <text>',
					event_category: 'boton',
					event_context: 'contratar tv',
					event_label: '<text>',
					transaction_shipping: '',
				},
			},
			ctc: {
				eventName: 'productos y servicios:extras:packs vodafone tv:click en llamar para contratar',
				data: {
					event_name: 'click en llamar para contratar',
				},
			},
		},
		howToNoDeco: {
			eventName:
				'productos y servicios:extras:contratacion tv:seleccionar con/sin deco:click en como ver tu tv sin decodificador',
			data: {
				event_name: 'click en como ver tu tv sin decodificador',
			},
		},
		contractType: {
			eventName: 'productos y servicios:extras:packs vodafone tv:contratacion nuevos packs:click contratar online',
			data: {
				event_name: 'click contratar online',
			},
		},
		preselectedPack: {
			state: {
				page_name: 'productos y servicios:extras:contratacion tv:deseleccionar pack universal',
				journey_category: 'contratacion tv',
				journey_type: 'contratacion',
				journey_options: '',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'extras',
				page_subcategory_level_2: 'contratacion tv',
				page_subcategory_level_3: 'deseleccionar pack universal',
				page_screen: 'deseleccionar pack universal',
				page_typology: 'overlay',
			},
			event_name: 'click en <cta>',
		},
		packContratado: {
			eventName: 'click en llamar para contratar',
			data: {
				event_name: 'click en llamar para contratar',
			},
		},
		boxlessToDeco: {
			event_name: 'click entrypoint',
			data: {
				'event_name': 'click entrypoint',
				'journey_name': 'cambio boxless a deco',
				'journey_category': 'cambio boxless a deco',
				'&&events': 'event70',
				'entrypoint_section': 'pys',
				'entrypoint_location': 'banner boxless a deco',
				'entrypoint_title': 'cambiar a con decodificador',
				'event_label': 'puedes mejorar tus prestaciones contratando un decodificador',
			},
		},
		changeDecoToNoDeco: {
			eventName: 'click en finalizar pedido',
			data: {
				'event_name': 'click en finalizar pedido',
				'journey_name': 'cambio boxless a deco',
				'journey_category': 'cambio boxless a deco',
				'&&events': 'event70',
				'event_label': 'puedes mejorar tus prestaciones contratando un decodificador',
				'transaction_shipping': '<envio>',
			},
		},
		thirdParty: {
			eventName: 'click en <copy>:<name>',
			select: 'selecciona linea',
			data: {
				event_name: 'click en <copy>:<name>',
			},
		},
		bannerPacks: {
			eventName: 'click en banner tv:ver todos los packs',
			data: {
				event_name: 'click en banner tv:ver todos los packs',
			},
		},
		packsMicro: {
			eventName: 'click en para mi empresa',
			data: {
				event_name: 'click en para mi empresa',
			},
		},
		packsHogar: {
			eventName: 'click en para mi hogar',
			data: {
				event_name: 'click en para mi hogar',
			},
		},
		banner: {
			eventName: 'click en me interesa packs tv',
			data: {
				event_name: 'click en me interesa packs tv',
			},
		},
		activateThirdChannel: {
			eventName: 'click en activar ahora',
			data: {
				event_name: 'click en activar ahora',
			},
		},
	},
	faultManagement: {
		leavingBonitaProblemSolved: {
			eventName: '<pagename>:averia solucionada',
			data: {
				event_name: 'averia solucionada',
			},
		},
		leavingBonitaProblemNotSolved: {
			eventName: '<pagename>:averia no solucionada',
			data: {
				event_name: 'averia no solucionada',
			},
		},
		openNewTicketBtnClicked: {
			eventName: 'averias:clic en abrir averia',
			data: {
				event_name: 'clic en abrir averia',
			},
		},
		bonitaFlowErrorBtn: {
			eventName: 'averias:error bonita:clic en entendido',
			data: {
				event_name: 'clic en entendido',
			},
		},
		openChatBtn: {
			eventName: '<pagename>:clic en iniciar conversación',
			data: {
				event_name: 'clic en iniciar conversación',
			},
		},
		showHideTicketDetails: {
			eventName: 'averias:clic en {0}',
			data: {
				issue_type: '',
				issue_information: '',
				issue_type_description: '',
				event_name: 'clic en {0}',
			},
		},
		retryGetTicketdetails: {
			eventName: 'averias:clic en reintentar',
			data: {
				issue_type: '',
				issue_information: '',
				issue_type_description: '',
				event_name: 'clic en reintentar',
			},
		},
		failLoadingAveria: {
			eventName: 'averias:error carga:clic en entendido',
			data: {
				event_name: 'clic en entendido',
			},
		},
		generalBreakDownClose: {
			eventName: 'averias:averia masiva:clic en entendido',
			data: {
				event_name: 'clic en entendido',
			},
		},
		checkOngoingOrder: {
			eventName: 'averias:selector de averias:existe averia abierta:clic en ir a seguimiento de pedidos',
			data: {
				event_name: 'clic en ir a seguimiento de pedidos',
			},
		},
		checkOpenedTicket: {
			eventName: 'averias:selector de averias:existe pedido en curso:clic en ir a detalle de avería',
			data: {
				event_name: 'clic en ir a detalle de avería',
			},
		},
		selectServiceToOpenTicket: {
			eventName: 'averias:selector de averias:clic en {0}',
			data: {
				event_name: 'clic en {0}',
			},
		},
		createNewFault: {
			start: {
				eventName: 'crear nueva averia:start',
				data: {
					trackAction_first_value: 'crear nueva averia:start',
					journey_name: 'crear nueva averia:start',
					journey_category: 'crear nueva averia',
					journey_subcategory: '', // will be (manual / por red / autorizado)
					journey_options: '',
					journey_type: 'eservice',
				},
			},
			success: {
				eventName: 'crear nueva averia:ok',
				data: {
					trackAction_first_value: 'crear nueva averia:ok',
					journey_name: 'crear nueva averia:ok',
					journey_category: 'crear nueva averia',
					journey_subcategory: '', // will be (manual / por red / autorizado)
					journey_options: '',
					journey_type: 'eservice',
				},
			},
			error: {
				eventName: 'crear nueva averia:ko',
				data: {
					trackAction_first_value: 'crear nueva averia:ko',
					journey_name: 'crear nueva averia:ko',
					journey_category: 'crear nueva averia',
					journey_subcategory: '', // will be (manual / por red / autorizado)
					journey_options: '',
					journey_type: 'eservice',
					journey_error_category: '',
					journey_error_description: '',
					journey_error_code: '',
				},
			},
		},
		cancelFaultCreation: {
			start: {
				eventName: 'cancelar creacion nueva averia:start',
				data: {
					journey_name: 'cancelar creacion nueva averia:start',
					journey_category: 'cancelar creacion nueva averia',
					journey_subcategory: '', // will be (manual / por red / autorizado)
					journey_options: '',
					journey_type: 'eservice',
				},
			},
			preconfirmacion: {
				eventName: 'cancelar creacion nueva averia:preconfirmacion',
				data: {
					trackAction: 'cancelar creacion nueva averia:preconfirmacion',
					journey_name: 'cancelar creacion nueva averia:preconfirmacion',
					journey_category: 'cancelar creacion nueva averia',
					journey_subcategory: '', // will be (manual / por red / autorizado)
					journey_options: '',
					journey_type: 'eservice',
				},
			},
			success: {
				eventName: 'cancelar creacion nueva averia:ok',
				data: {
					journey_name: 'cancelar creacion nueva averia:ok',
					journey_category: 'cancelar creacion nueva averia',
					journey_subcategory: '', // will be (manual / por red / autorizado)
					journey_options: '',
					journey_type: 'eservice',
				},
			},
			error: {
				eventName: 'cancelar creacion nueva averia:ko',
				data: {
					journey_name: 'cancelar creacion nueva averia:ko',
					journey_category: 'cancelar creacion nueva averia',
					journey_subcategory: '', // will be (manual / por red / autorizado)
					journey_options: '',
					journey_type: 'eservice',
					journey_error_category: '',
					journey_error_description: '',
					journey_error_code: '',
				},
			},
		},
		closeOpenFault: {
			start: {
				eventName: 'marcar averia como solucionada:start',
				data: {
					journey_name: 'marcar averia como solucionada:start',
					journey_category: 'marcar averia como solucionada',
					journey_subcategory: '', // will be (intenert /  )
					journey_options: '',
					journey_type: 'eservice',
				},
			},
			preconfirmacion: {
				eventName: 'marcar averia como solucionada:preconfirmacion',
				data: {
					journey_name: 'marcar averia como solucionada:preconfirmacion',
					journey_category: 'marcar averia como solucionada',
					journey_subcategory: '', // will be (intenert /  )
					journey_options: '',
					journey_type: 'eservice',
				},
			},
			success: {
				eventName: 'marcar averia como solucionada:ok',
				data: {
					journey_name: 'marcar averia como solucionada:ok',
					journey_category: 'marcar averia como solucionada',
					journey_subcategory: '', // will be (intenert /  )
					journey_options: '',
					journey_type: 'eservice',
				},
			},
			error: {
				eventName: 'marcar averia como solucionada:ko',
				data: {
					journey_name: 'marcar averia como solucionada:ko',
					journey_category: 'marcar averia como solucionada',
					journey_subcategory: '', // will be (intenert /  )
					journey_options: '',
					journey_type: 'eservice',
					journey_error_category: '',
					journey_error_description: '',
					journey_error_code: '',
				},
			},
		},
		reOpenClosedFault: {
			start: {
				eventName: 'reabrir averia:start',
				data: {
					journey_name: 'reabrir averia:start',
					journey_category: 'reabrir averia',
					journey_subcategory: '', // will be (internet /  )
					journey_options: '',
					journey_type: 'eservice',
				},
			},
			success: {
				eventName: 'reabrir averia:ok',
				data: {
					journey_name: 'reabrir averia:ok',
					journey_category: 'reabrir averia',
					journey_subcategory: '', // will be (internet /  )
					journey_options: '',
					journey_type: 'eservice',
				},
			},
			error: {
				eventName: 'reabrir averia:ko',
				data: {
					journey_name: 'reabrir averia:ko',
					journey_category: 'reabrir averia',
					journey_subcategory: '', // will be (internett /  )
					journey_options: '',
					journey_type: 'eservice',
					journey_error_category: '',
					journey_error_description: '',
					journey_error_code: '',
				},
			},
		},
	},
	needHelpBubble: {
		interactions: {
			clickHelpBubble: {
				event_name: '<page_name>:clic en burbuja de ayuda',
				data: {
					event_name: 'clic en burbuja de ayuda',
				},
			},
			webviewLaunch: {
				event_name: 'webview:webview',
				data: {
					event_name: 'webview',
				},
			},
			webviewClose: {
				event_name: 'webview:clic en cerrar',
				data: {
					event_name: 'clic en cerrar',
				},
			},
			clickFaq: {
				event_name: 'webview:ayuda:clic en boton faqs',
				data: {
					event_name: 'clic en boton faqs',
				},
			},
			clickAsistente: {
				event_name: 'webview:ayuda:clic en boton asistente',
				data: {
					event_name: 'clic en boton asistente',
				},
			},
		},
	},
	PurchaseProducts: {
		page: {
			'page_name': 'que tengo contratado:resumen que tengo contratado',
			'entrypoint_list': '',
			'&&events': '',
		},
		impresion: {
			eventName: 'que tengo contratado:home:impresiones entrypoint',
			data: {
				'event_name': 'impresiones entrypoint',
				'page_name': 'que tengo contratado:resumen que tengo contratado',
				'page_section': 'que tengo contratado',
				'page_subcategory_level_1': 'resumen que tengo contratado ',
				'page_screen': 'home',
				'entrypoint_list': 'es=que tengo contratado|el={el}|jn={jn}|et={et}|jo=one plus',
				'&&events': 'event75',
				'event_category': 'visualizacion',
				'event_label': 'impresion entrypoint',
			},
		},
		product: {
			pay: {
				postpaid: 'Postpaid',
				pospago: 'pospago',
				prepago: 'prepago',
			},
			eventName: 'que tengo contratado:resumen que tengo contratado:clic en ver producto contratado',
			data: {
				event_name: 'clic en ver producto contratado',
				page_section: 'que tengo contratado',
				page_subcategory_level_1: 'resumen que tengo contratado ',
				page_screen: 'home',
				page_detail: 'resumen que tengo contratado',
				journey_name: '',
				journey_category: '',
				journey_subcategory: '',
				journey_type: 'servicio',
				journey_process: 'activar',
				journey_options: 'one plus',
				journey_detail: 'resumen que tengo contratado',
				event_context: '',
				event_category: 'banner',
				event_label: 'click en {product}',
			},
		},
		oob_details: {
			eventName: 'que tengo contratado:resumen que tengo contratado:clic en ver consumos fuera de tarifa',
			data: {
				event_name: 'clic en ver consumos fuera de tarifa',
			},
			page: {
				page_name: 'que tengo contratado:consumo fuera de tarifa',
			},
			oob_product: {
				eventName: 'que tengo contratado:consumo fuera de tarifa:clic en consumo fuera de tarifa',
				data: {
					event_name: 'clic en consumo fuera de tarifa',
				},
			},
		},
		bill: {
			eventName: 'que tengo contratado:resumen que tengo contratado:clic en detalle factura',
			data: {
				event_name: 'clic en detalle factura',
			},
		},
		coute_details: {
			more_info: {
				page_name: 'que tengo contratado:cuota de tus productos',
				event_context: 'cuota de tus productos',
				event_category: 'link',
				event_label: 'mas info',
				page_section: 'que tengo contratado',
				page_subcategory_level_1: 'cuota de tus productos',
				page_screen: 'cuota de tus productos',
				page_typology: 'principal',
			},
			eventName: 'que tengo contratado:resumen que tengo contratado:clic en ver cuotas y descuentos',
			data: {
				event_name: 'clic en ver cuotas y descuentos',
			},
			page: {
				page_name: 'que tengo contratado:cuota de tus productos',
			},
			iva: {
				eventName: 'que tengo contratado:cuota de tus productos:clic en {iva}',
				data: {
					event_name: 'clic en {iva}',
				},
			},
		},
		funded_device: {
			eventName: 'que tengo contratado:cuota de tus productos:click en entrypoint',
			data: {
				event_name: 'click en entrypoint',
				journey_name: 'financiacion activa',
				entrypoint_section: 'que tengo contratado',
				entrypoint_location: 'card financiaciones en curso',
				entrypoint_position: 'undefined',
				entrypoint_title: '<model>',
			},
		},
		clickInvoices: {
			eventName: 'que tengo contratado: resumen que tengo contratado: click {0}',
			data: {
				event_name: 'acceder a facturas',
				event_action: 'click',
				event_category: 'boton',
				event_context: 'productos y servicios',
				event_label: '{0}',
			},
		},
	},
	promosMicro: {
		view: {
			page_name: 'mivoweb:microempresas:tu espacio profesional',
			asset_name: 'mivoweb',
			navigation_level_1: 'microempresas',
			navigation_level_2: 'tu espacio profesional',
			navigation_level_3: '',
			navigation_level_4: '',
			page_screen: 'tu espacio profesional',
			page_section: 'microempresas',
			page_detail: 'tu espacio profesional',
			page_subcategory_level_1: 'tu espacio profesional',
			page_subcategory_level_2: '',
			page_subcategory_level_3: '',
			page_typology: 'principal',
			journey_type: '',
			journey_detail: '',
		},
		showEntryPoint: {
			eventName: 'impresiones entrypoint',
			data: {
				'event_category': 'visualización',
				'event_context': 'tu espacio profesional',
				'event_name': 'impresiones entrypoint',
				'&&events': 'event75',
				'event_label': 'impresiones entrypoint',
				'entrypoint_list': 'es=microempresas|el=tu espacio profesional|ep={ep}|jn={jn}|en=undefined|et={et}|ety=reco',
			},
		},
		viewError: {
			page_section: 'microempresas',
			page_subcategory_level_1: 'tu espacio profesional:error',
			page_subcategory_level_2: '',
			page_subcategory_level_3: '',
			page_name: 'microempresas:tu espacio profesional:error',
			asset_name: 'mivoweb',
			navigation_level_1: 'microempresas',
			navigation_level_2: 'tu espacio profesional:error',
			navigation_level_3: '',
			navigation_level_4: '',
			page_screen: 'error',
			error_category: '',
			error_description: '',
			error_type: 'funcional',
			error_page: 'microempresas tu espacio profesional error',
			journey_name: '',
			journey_type: 'sevicios',
			journey_detail: '',
			jouney_step: '',
		},
		extraData: {
			tryAndBuy: 'tryandbuy',
			opPres: 'opproductspresencia',
			digital: 'digital',
			impresiones: 'impresiones entrypoint',
		},
		showError: {
			data: {
				'&&events': 'event100',
				'error_list': 'et=tecnico:otros|ec={error_code}|em=pagina|ed={error_description}',
				'error_category': '',
				'error_description': '',
				'error_type': 'funcional',
				'error_page': '',
			},
			errorTxt: ':error',
		},
		clickEntryPoint: {
			eventName: 'mivoweb:microempresas:tu espacio profesional:click en entrypoint',
			data: {
				'page_section': 'microempresas',
				'event_name': 'click en entrypoint',
				'event_category': 'banner',
				'event_context': 'tu espacio profesional',
				'event_label': 'click en entrypoint',
				'&&events': 'event70',
				'entrypoint_section': 'microempresas',
				'entrypoint_location': 'tu espacio profesional',
				'entrypoint_position': '{undefined}',
				'entrypoint_title': '{et}',
				'entrypoint_code': '{code}',
				'journey_name': 'undefined',
				'journey_status': 'start',
				'experience_name': 'undefined',
				'entrypoint_type': 'reco',
			},
		},
	},
	promosList: {
		view: {
			page_name: 'mivoweb:one profesional:servicios one profesional',
			asset_name: 'mivoweb',
			navigation_level_1: 'one profesional',
			navigation_level_2: 'servicios one profesional',
			navigation_level_3: '',
			navigation_level_4: '',
			page_screen: 'servicios one profesional',
			page_section: 'one profesional',
			page_subcategory_level_1: 'servicios one profesional',
			page_subcategory_level_2: '',
			page_subcategory_level_3: '',
		},
		showEntryPoint: {
			eventName: 'click en entrypoint',
			data: {
				'event_name': 'click en entrypoint',
				'&&events': 'event70',
				'entrypoint_list':
					'es=microempresas|el=tu espacio profesional|ep={ep}|jn=undefined|en=undefined|et={et}|ety=servicio de ep',
			},
		},
		viewError: {
			page_name: 'mivoweb:one profesional:servicios one profesional',
			asset_name: 'mivoweb',
			navigation_level_1: 'one profesional',
			navigation_level_3: 'Error',
			navigation_level_4: '',
			page_screen: 'error',
			page_section: '',
			page_subcategory_level_1: '',
			page_subcategory_level_2: '',
			page_subcategory_level_3: '',
		},
		showError: {
			data: {
				'&&events': 'event103',
				'error_list': 'et=tecnico:otros|ec={error_code}|em=pagina|ed={error_description}',
			},
		},
		clickEntryPoint: {
			eventName: 'mivoweb:microempresas:tu espacio profesional:servicios one profesional:{tj}',
			data: {
				'event_name': 'click en {ts}',
				'event_category': 'link',
				'event_context': 'servicios one profesional',
				'event_label': '{ev}',
				'&&events': 'event70',
				'entrypoint_section': 'microempresas',
				'entrypoint_location': 'servicios one profesional',
				'entrypoint_position': '{undefined}',
				'entrypoint_title': '{et}',
				'journey_name': 'undefined',
				'experience_name': 'undefined',
				'entrypoint_type': 'servicio de ep',
			},
		},
	},
	beeDigital2: {
		view: {
			page_name: 'mivoweb:one profesional:analiza tu presencia en internet',
			asset_name: 'mivoweb',
			navigation_level_1: 'one profesional',
			navigation_level_2: 'analiza tu presencia en internet',
			navigation_level_3: 'bee digital',
			navigation_level_4: '',
			page_screen: 'analiza tu presencia en internet',
			page_section: 'one profesional',
			page_subcategory_level_1: 'analiza tu presencia en internet',
			page_subcategory_level_2: 'bee digital',
			page_subcategory_level_3: '',
		},
		showEntryPoint: {
			eventName: '',
			data: {
				'event_name': '',
				'&&events': '',
				'entrypoint_list': '',
			},
		},
		screenData: {
			screen: 'bee digital',
		},
		buttonDetails: {
			eventName: 'ver detalles',
		},
		buttonMoreDetails: {
			eventName: 'ver mas detalles',
		},
		buttonAnalyze: {
			eventName: 'analizar mi negocio',
		},
		viewError: {
			page_name: 'mivoweb:one profesional:analiza tu presencia en internet',
			page_name_iframe: 'mivoweb:one profesional:analiza tu presencia en internet:bee digital',
			asset_name: 'mivoweb',
			navigation_level_1: 'microempresas',
			navigation_level_2: 'analiza tu presencia en internet',
			navigation_level_3: 'Error',
			navigation_level_4: '',
			page_screen: 'error',
			page_section: '',
			page_subcategory_level_1: '',
			page_subcategory_level_2: '',
			page_subcategory_level_3: '',
		},
		showError: {
			data: {
				'&&events': 'event103',
				'error_list': 'et=tecnico:otros|ec={error_code}|em=pagina|ed={error_description}',
			},
		},
		clickEntryPoint: {
			eventName: 'mivoweb:one profesional:analiza tu presencia en internet:{0}',
			data: {
				event_name: 'click en {0}',
				event_category: 'boton',
				event_context: 'one profesional',
				event_label: '{ev}',
				page_name_iframe: 'mivoweb:one profesional:analiza tu presencia en internet:bee digital',
			},
		},
	},
	BillingVf10: {
		Landing: {
			view: {
				page_name: 'facturas:mis facturas:resumen facturas',
				page_section: 'facturas',
				page_subcategory_level_1: 'mis facturas',
				page_subcategory_level_2: 'resumen facturas',
				page_screen: 'resumen facturas',
				client_billing_id: '',
				client_billing_type: '',
				error_category: '',
				error_descripcion: '',
			},
			viewBillingPEGA: {
				page_name: 'facturas:mis facturas:resumen facturas:detalle de factura:{0}',
				page_section: 'facturas',
				page_subcategory_level_1: 'mis facturas',
				page_subcategory_level_2: 'resumen facturas',
				page_subcategory_level_3: 'detalle de factura',
				page_screen: '{0}',
				client_billing_id: '',
				client_billing_type: '',
				error_category: '',
				error_descripcion: '',
			},
			TutorialNoBills: {
				view: {
					page_name: 'facturas:mis facturas:resumen facturas:welcome tutorial paso{0}',
					page_section: 'facturas',
					page_subcategory_level_1: 'mis facturas',
					page_subcategory_level_2: 'resumen facturas',
					page_subcategory_level_3: 'welcome tutorial paso{0}',
					page_screen: 'welcome tutorial paso{0}',
					client_billing_id: '',
					client_billing_type: '',
				},
				clickClose: {
					eventName: 'facturas:mis facturas:resumen facturas:welcome tutorial paso{0}:click en cierre paso{0}',
					data: {
						event_name: 'click en cierre paso{0}',
					},
				},
			},
			TutorialFirstBill: {
				view: {
					page_name: 'facturas:mis facturas:resumen facturas:welcome tutorial primera factura {s} paso{0}',
					page_section: 'facturas',
					page_subcategory_level_1: 'mis facturas',
					page_subcategory_level_2: 'resumen facturas',
					page_subcategory_level_3: 'welcome tutorial primera factura{s} paso{0}',
					page_screen: 'welcome tutorial primera factura {s} paso{0}',
					client_billing_id: '',
					client_billing_type: '',
				},
				clickClose: {
					eventName:
						'facturas:mis facturas:resumen facturas:welcome tutorial primera factura {s} paso{0}:click en cierre paso{0}',
					data: {
						event_name: 'click en cierre paso{0}',
					},
				},
			},
			histogram: {
				eventName: 'facturas:mis facturas:resumen facturas:clic en histograma',
				data: {
					event_name: 'clic en histograma',
				},
			},
			cards: {
				eventName: 'facturas:mis facturas:resumen facturas:clic en ver factura',
				data: {
					event_name: 'clic en ver factura',
				},
			},
			pdf: {
				eventName: 'facturas:mis facturas:resumen facturas:clic en descargar factura pdf',
				data: {
					event_name: 'clic en descargar factura pdf',
				},
			},
			clickCommunicationsPrinc: {
				event_name: 'click en ver como queda tu factura principal',
			},
			clickCommunicationsEstimated: {
				event_name: 'click en ver como queda tu factura estimado',
			},
			detalleEstimado: {
				eventName: 'facturas:mis facturas:resumen facturas:clic en ver detalle proxima factura',
				data: {
					event_name: 'clic en ver detalle proxima factura',
				},
			},
			errorEstimado: {
				eventName: 'facturas:mis facturas:resumen facturas:error carga proxima factura',
				data: {
					event_name: 'error carga proxima factura',
				},
			},
			sedeEstimado: {
				eventName: 'facturas:mis facturas:resumen facturas:click en sede',
				data: {
					event_name: 'click en sede',
				},
			},
			multiSedeEstimado: {
				eventName: 'facturas:mis facturas:resumen facturas:click en consulta tu consumo estimado',
				data: {
					event_name: 'click en consulta tu consumo estimado',
				},
			},
			payNextBill: {
				eventName: 'facturas:mis facturas:resumen facturas:click en adelantar pago',
				data: {
					event_name: 'click en adelantar pago',
				},
			},
			payDebtBill: {
				eventName: 'facturas:mis facturas:resumen facturas:click en solucionar factura pendiente',
				data: {
					event_name: 'click en solucionar factura pendiente',
				},
			},
			clickOOBLink: {
				eventName: 'facturas:mis facturas:resumen facturas:click en consumo fuera de tarifa estimado',
				data: {
					event_name: 'click en consumo fuera de tarifa estimado',
				},
			},
			clickWhatWillHappenCard: {
				clickRenewDiscounts: {
					eventName:
						'facturas:mis facturas:resumen facturas:detalle de factura:detalle descuentos finalizados con renovacion:click en renovar descuento',
					data: {
						'event_name': 'click en entrypoint',
						'&&events': 'event70',
					},
				},
				clickViewDetails: {
					eventName: 'facturas:mis facturas:resumen facturas:click en ver detalle descuentos finalizados',
					data: {
						'event_name': 'click en ver detalle descuentos finalizados',
						'&&events': 'event70',
					},
				},
				clickRenewDiscountsPEGA: {
					entrypoint_section: 'mis facturas',
					entrypoint_location: 'banner que pasara',
					entrypoint_position: 'undefined',
					entrypoint_title: 'puedes estar tranquilo',
					entrypoint_type: '',
					journey_name: 'renovacion descuento',
					experience_name: 'undefined',
				},
			},
			billingWS10Discover: {
				data: {
					page_name: '',
					page_platform: 'web',
					page_section: 'facturas',
					page_subcategory_level_1: 'mis facturas',
					page_subcategory_level_2: 'resumen facturas',
					page_subcategory_level_3: 'detalle de factura',
					page_subcategory_level_4: '',
					page_screen: 'detalle descuentos finalizados con renovacion',
					page_typology: 'principal',
				},
				print: {
					'event_name': 'impresiones entrypoint',
					'&&events': 'event75',
					'entrypoint_list':
						'es=mis facturas|el=banner que pasara|ep=undefined|jn={journey}|en=undefined|et={title}|ety={type}',
					'entrypoint_pega': 'enm={name}|elb={label}|eis={issue}|egn={groupnba}|jn={journey_name}|el=banner que pasara',
				},
			},
		},
		details: {
			view: {
				page_name: 'facturas:mis facturas:resumen facturas:detalle de factura',
				page_section: 'facturas',
				page_subcategory_level_1: 'mis facturas',
				page_subcategory_level_2: 'resumen facturas',
				page_subcategory_level_3: 'detalle de factura',
				page_screen: 'detalle de factura',
				client_billing_id: '',
				client_billing_type: '',
				error_category: '',
				error_descripcion: '',
				billing_alert: '',
			},
			descargarPdf: {
				eventName: 'facturas:mis facturas:resumen facturas:detalle de factura:clic en descargar factura pdf',
				data: {
					event_name: 'clic en descargar factura pdf',
				},
			},
			payment: {
				eventName: 'facturas:mis facturas:resumen facturas:detalle de factura:click en pagar',
				data: {
					event_name: 'click en pagar',
				},
			},
			clickOob: {
				eventName: 'facturas:mis facturas:resumen facturas:detalle de factura:click en ver detalle de consumo',
				data: {
					event_name: 'click en ver detalle de consumo',
				},
			},
			clickDetailListProducts: {
				eventName: 'facturas:mis facturas:resumen facturas:detalle de factura:click en ver detalle',
				data: {
					event_name: 'click en ver detalle',
				},
			},
			clickProductOob: {
				eventName: 'facturas:mis facturas:resumen facturas:detalle de factura:click en consumo fuera de tarifa',
				data: {
					event_name: 'click en consumo fuera de tarifa',
				},
			},
			clickOtherConcept: {
				eventName: 'facturas:mis facturas:resumen facturas:detalle de factura:click en otros conceptos',
				data: {
					event_name: 'click en otros conceptos',
				},
			},
			clickWhatHappened: {
				eventName: 'facturas:mis facturas:resumen facturas:detalle de factura:click en ver que ha pasado',
				data: {
					event_name: 'click en ver que ha pasado',
				},
			},
			clickSecureNet: {
				eventName: 'facturas:mis facturas:resumen facturas:detalle de factura:click en vodafone secure net',
				data: {
					event_name: 'click en vodafone secure net',
				},
			},
			clickCallId: {
				eventName:
					'facturas:mis facturas:resumen facturas:detalle de factura:click en servicio de identificacion de llamadas',
				data: {
					event_name: 'click en servicio de identificacion de llamadas',
				},
			},
			clickNewTariffInfoIcon: {
				eventName: 'facturas:mis facturas:resumen facturas:detalle de factura:click en informacion cambio de tarifa',
				data: {
					event_name: 'click en informacion cambio de tarifa',
				},
			},
		},
		WhatHappened: {
			view: {
				page_name: 'facturas:mis facturas:resumen facturas:detalle de factura:alarma',
				page_section: 'facturas',
				page_subcategory_level_1: 'mis facturas',
				page_subcategory_level_2: 'resumen facturas',
				page_subcategory_level_3: 'detalle de factura',
				page_subcategory_level_4: 'alarma',
				page_screen: 'alarma',
				client_billing_id: '',
				client_billing_type: '',
				error_category: '',
				error_descripcion: '',
				billing_alert: '',
			},
			billingAlertValues: {
				new: 'novedades en tu tarifa',
				obb: 'consumo fuera de tu tarifa',
				discounts: 'descuentos finalizados',
				payment: 'abonos',
				financing: 'financiacion dispositivos',
			},
			clickChevron: {
				// Names must be the same as ChevronTypeWhatHappened enum
				obb: {
					eventName:
						'facturas:mis facturas:resumen facturas:detalle de factura:alarma:click en consumo fuera de tarifa',
					data: {
						event_name: 'click en consumo fuera de tarifa',
					},
				},
				new: {
					eventName:
						'facturas:mis facturas:resumen facturas:detalle de factura:alarma:click en novedades en tus tarifas',
					data: {
						event_name: 'click en novedades en tus tarifas',
					},
				},
				discounts: {
					eventName: 'facturas:mis facturas:resumen facturas:detalle de factura:alarma:click en descuentos finalizados',
					data: {
						event_name: 'click en descuentos finalizados',
					},
				},
				payment: {
					eventName: 'facturas:mis facturas:resumen facturas:detalle de factura:alarma:click en abonos',
					data: {
						event_name: 'click en abonos',
					},
				},
				financing: {
					eventName:
						'facturas:mis facturas:resumen facturas:detalle de factura:alarma:click en financiacion dispositivos',
					data: {
						event_name: 'click en financiacion dispositivos',
					},
				},
				third_parties: {
					eventName: 'facturas:mis facturas:resumen facturas:detalle de factura:alarma:click en pagos a terceros',
					data: {
						event_name: 'click en pagos a terceros',
					},
				},
				premium: {
					eventName: 'facturas:mis facturas:resumen facturas:detalle de factura:alarma:click en pagos premium',
					data: {
						event_name: 'click en pagos premium',
					},
				},
				new_price: {
					eventName: 'facturas:mis facturas:resumen facturas:detalle de factura:alarma:click en nuevo precio',
					data: {
						event_name: 'click en nuevo precio',
					},
					clickSecondPage: 'click en cuanto vas a pagar a partir de ahora',
				},
				auto_migration: {
					eventName:
						'facturas:mis facturas:resumen facturas:detalle de factura:alarma:click en descubre tu nueva tarifa',
					data: {
						event_name: 'click en descubre tu nueva tarifa',
					},
					clickSecondPage: 'click en como queda tu factura',
				},
			},
			clickDocumentsLink: {
				eventName: 'facturas:mis facturas:resumen facturas:detalle de factura:alarma:click en revisar descuentos',
				data: {
					event_name: 'click en revisar descuentos',
				},
			},
			clickRenewDiscount: {
				eventName:
					'facturas:mis facturas:resumen facturas:detalle de factura:alarma:descuentos finalizados:click en entrypoint',
				data: {
					'event_name': 'click en entrypoint',
					'&&events': 'event70',
					'entrypoint_section': 'mis facturas',
					'entrypoint_location': 'banner que ha pasado',
					'entrypoint_position': 'undefined',
					'entrypoint_title': 'puedes estar tranquilo',
					'entrypoint_type': '',
					'journey_name': 'renovacion descuento',
					'experience_name': 'undefined',
				},
			},
			DiscountsDetail: {
				view: {
					page_name: 'facturas:mis facturas:resumen facturas:detalle de factura:alarma:descuentos finalizados',
					page_section: 'facturas',
					page_subcategory_level_1: 'mis facturas',
					page_subcategory_level_2: 'resumen facturas',
					page_subcategory_level_3: 'detalle de factura',
					page_subcategory_level_4: 'alarma',
					page_screen: 'descuentos finalizados',
					client_billing_id: '',
					client_billing_type: '',
					error_category: '',
					error_descripcion: '',
					billing_alert: '',
				},
				hasEntryPoint: {
					eventName:
						'facturas:mis facturas:resumen facturas:detalle de factura:alarma:descuentos finalizados:impresiones entrypoint',
					data: {
						'event_name': 'impresiones entrypoint',
						'&&events': 'event75',
						'entrypoint_list':
							'es=mis facturas|el=banner que ha pasado|ep=undefined|jn=renovacion descuento|en=undefined|et={et}|ety={ety}',
						'entrypoint_pega':
							'enm={name}|elb={label}|eis={issue}|egn={groupnba}|jn=renovacion descuento|el=banner que ha pasado',
					},
				},
			},
			OutOfBundleDetail: {
				view: {
					page_name: 'facturas:mis facturas:resumen facturas:detalle de factura:alarma:consumo fuera de tarifa',
					page_section: 'facturas',
					page_subcategory_level_1: 'mis facturas',
					page_subcategory_level_2: 'resumen facturas',
					page_subcategory_level_3: 'detalle de factura',
					page_subcategory_level_4: 'alarma',
					page_screen: 'consumo fuera de tarifa',
					client_billing_id: '',
					client_billing_type: '',
					error_category: '',
					error_descripcion: '',
					billing_alert: '',
				},
				clickMobile: {
					eventName:
						'facturas:mis facturas:resumen facturas:detalle de factura:alarma:consumo fuera de tarifa:click en movil contrato',
					data: {
						event_name: 'click en movil contrato',
					},
				},
				clickLandline: {
					eventName:
						'facturas:mis facturas:resumen facturas:detalle de factura:alarma:consumo fuera de tarifa:click en fijo',
					data: {
						event_name: 'click en fijo',
					},
				},
				clickTV: {
					eventName:
						'facturas:mis facturas:resumen facturas:detalle de factura:alarma:consumo fuera de tarifa:click en television',
					data: {
						event_name: 'click en television',
					},
				},
			},
			PaymentsDetail: {
				view: {
					page_name: 'facturas:mis facturas:resumen facturas:detalle de factura:alarma:abonos',
					page_section: 'facturas',
					page_subcategory_level_1: 'mis facturas',
					page_subcategory_level_2: 'resumen facturas',
					page_subcategory_level_3: 'detalle de factura',
					page_subcategory_level_4: 'alarma',
					page_screen: 'abonos',
					client_billing_id: '',
					client_billing_type: '',
					error_category: '',
					error_descripcion: '',
					billing_alert: '',
				},
				clickPendingPayment: {
					eventName: 'facturas:mis facturas:resumen facturas:detalle de factura:alarma:abonos:click en abono pendiente',
					data: {
						event_name: 'click en abono pendiente',
					},
				},
				clickHelpPayment: {
					eventName: 'facturas:mis facturas:resumen facturas:detalle de factura:alarma:abonos:click en ayuda abonos',
					data: {
						event_name: 'click en ayuda abonos',
					},
				},
			},
			TariffChangesDetail: {
				view: {
					page_name: 'facturas:mis facturas:resumen facturas:detalle de factura:alarma:novedades en tu tarifa',
					page_section: 'facturas',
					page_subcategory_level_1: 'mis facturas',
					page_subcategory_level_2: 'resumen facturas',
					page_subcategory_level_3: 'detalle de factura',
					page_subcategory_level_4: 'alarma',
					page_screen: 'novedades en tu tarifa',
					client_billing_id: '',
					client_billing_type: '',
					error_category: '',
					error_descripcion: '',
					billing_alert: '',
				},
				clickExpandDetail: {
					eventName:
						'facturas:mis facturas:resumen facturas:detalle de factura:alarma:novedades en tu tarifa:click en en ver detalle de {0}',
					data: {
						event_name: 'click en en ver detalle de {0}',
					},
				},
				clickHelpChanges: {
					eventName:
						'facturas:mis facturas:resumen facturas:detalle de factura:alarma:novedades en tu tarifa:click en ayuda ciclo de facturacion',
					data: {
						event_name: 'click en ayuda ciclo de facturacion',
					},
				},
				auto_migration: 'descubre tu nueva tarifa',
				new_price: 'nuevo precio',
			},
		},
		WhatWillHappen: {
			view: {
				page_name: 'facturas:mis facturas:resumen facturas:detalle de factura:{0}',
				page_section: 'facturas',
				page_subcategory_level_1: 'mis facturas',
				page_subcategory_level_2: 'resumen facturas',
				page_subcategory_level_3: '{0}',
				page_subcategory_level_4: '',
				page_screen: '{0}',
				client_billing_id: '',
				client_billing_type: '',
				error_category: '',
				error_descripcion: '',
				billing_alert: '',
			},
			pageScreenValues: {
				withRenewal: 'detalle descuentos finalizados con renovacion',
				withoutRenewal: 'detalle descuentos finalizados sin renovacion',
			},
			showRenewDiscountsEntryPoint: {
				eventName:
					'facturas:mis facturas:resumen facturas:detalle descuentos finalizados con renovacion:impresiones entrypoint',
				data: {
					'event_name': 'impresiones entrypoint',
					'&&events': 'event75',
					'entrypoint_list':
						'es=mis facturas|el=banner que pasara|ep=undefined|jn=renovacion de descuentos|en=undefined|et={et}',
				},
			},
			clickRenewDiscountsEntryPoint: {
				eventName:
					'facturas:mis facturas:resumen facturas:detalle descuentos finalizados con renovacion:click en entrypoint',
				data: {
					'event_name': 'click en entrypoint',
					'&&events': 'event70',
					'entrypoint_section': 'mis facturas',
					'entrypoint_location': 'banner que pasara',
					'entrypoint_position': 'undefined',
					'entrypoint_title': '{wcs}',
					'journey_name': 'renovacion de descuentos',
					'experience_name': 'undefined',
				},
			},
		},
		/**
		 * @service movil pospago | fijo tradicional | television
		 * @card llamadas | mensajes | datos | television
		 * @type dicta sms | rellamada | sms premium | masmegas
		 */
		billedConsumption: {
			view: {
				page_name: 'mivoweb:mi consumo:detalle de consumo facturado:detalle de {service}',
				asset_name: 'mivoweb',
				navigation_level_1: 'mi consumo',
				navigation_level_2: 'detalle de consumo facturado',
				navigation_level_3: 'detalle de {service}',
				navigation_level_4: '',
				page_screen: 'detalle de {service}',
				page_section: '',
				page_subcategory_level_1: '',
				page_subcategory_level_2: '',
				page_subcategory_level_3: '',
			},
			clickInfoIcon: {
				eventName:
					'mivoweb:mi consumo:detalle de consumo facturado:detalle de {service}:click en informacion de {type}',
				data: {
					event_name: 'click en informacion de {type}',
					event_category: 'boton',
					event_context: 'card {card}',
					event_label: 'informacion de {type}',
				},
			},
			moreInfoTooltip: {
				view: {
					page_name: 'mivoweb:mi consumo:detalle de consumo facturado:detalle de {service}:tooltip {type}',
					asset_name: 'mivoweb',
					navigation_level_1: 'mi consumo',
					navigation_level_2: 'detalle de consumo facturado',
					navigation_level_3: 'detalle de {service}',
					navigation_level_4: 'tooltip {type}',
					page_screen: 'tooltip {type}',
					page_section: '',
					page_subcategory_level_1: '',
					page_subcategory_level_2: '',
					page_subcategory_level_3: '',
				},
				clickTooltipManage: {
					eventName:
						'mivoweb:mi consumo:detalle de consumo facturado:detalle de {service}:tooltip {type}:click en gestionar',
					data: {
						event_name: 'click en gestionar',
						event_category: 'boton',
						event_context: '',
						event_label: 'gestionar',
					},
				},
				clickTooltipMoreInfoLink: {
					eventName:
						'mivoweb:mi consumo:detalle de consumo facturado:detalle de {service}:tooltip {type}:click en mas informacion',
					data: {
						event_name: 'click en mas informacion',
						event_category: 'link',
						event_context: '',
						event_label: 'mas informacion',
					},
				},
			},
		},
		comsuption: {
			postpaid: {
				view: {
					page_name: 'consumo:mi consumo:detalle de consumo facturado:detalle de movil pospago',
					page_section: 'consumo',
					page_subcategory_level_1: 'mi consumo',
					page_subcategory_level_2: 'detalle de consumo facturado',
					page_subcategory_level_3: 'detalle de movil pospago',
					page_screen: 'detalle de movil pospago',
					client_billing_id: '',
					client_billing_type: '',
					error_category: '',
					error_descripcion: '',
				},
				clickCalls: {
					eventName:
						'consumo:mi consumo:detalle de consumo facturado:detalle de movil pospago:click en ver detalle de llamadas',
					data: {
						event_name: 'click en ver detalle de llamadas',
					},
				},
				clickInternet: {
					eventName:
						'consumo:mi consumo:detalle de consumo facturado:detalle de movil pospago:click en ver detalle de datos',
					data: {
						event_name: 'click en ver detalle de datos',
					},
				},
				clickMessages: {
					eventName:
						'consumo:mi consumo:detalle de consumo facturado:detalle de movil pospago:click en ver detalle de mensajes',
					data: {
						event_name: 'click en ver detalle de mensajes',
					},
				},
				clickIncluido: {
					eventName: 'consumo:mi consumo:detalle de consumo facturado:detalle de movil pospago:click en incluido',
					data: {
						event_name: 'click en incluido',
					},
				},
				clickFuera: {
					eventName:
						'consumo:mi consumo:detalle de consumo facturado:detalle de movil pospago:click en fuera de tarifa',
					data: {
						event_name: 'click en fuera de tarifa',
					},
				},
				clickTodo: {
					eventName: 'consumo:mi consumo:detalle de consumo facturado:detalle de movil pospago:click en todo',
					data: {
						event_name: 'click en todo',
					},
				},
				clickCloseConsumptionOverlay: {
					eventName:
						'consumo:mi consumo:detalle de consumo facturado:detalle de movil pospago:click en cerrar consumo de datos',
					data: {
						event_name: 'click en cerrar consumo de datos',
					},
				},
			},
			landline: {
				view: {
					page_name: 'consumo:mi consumo:detalle de consumo facturado:detalle de fijo tradicional',
					page_section: 'consumo',
					page_subcategory_level_1: 'mi consumo',
					page_subcategory_level_2: 'detalle de consumo facturado',
					page_subcategory_level_3: 'detalle de fijo tradicional',
					page_screen: 'detalle de fijo tradicional',
					client_billing_id: '',
					client_billing_type: '',
					error_category: '',
					error_descripcion: '',
				},
				clickCalls: {
					eventName:
						'consumo:mi consumo:detalle de consumo facturado:detalle de fijo tradicional:click en ver detalle de llamadas',
					data: {
						event_name: 'click en ver detalle de llamadas',
					},
				},
				clickInternet: {
					eventName:
						'consumo:mi consumo:detalle de consumo facturado:detalle de fijo tradicional:click en ver detalle de datos',
					data: {
						event_name: 'click en ver detalle de datos',
					},
				},
				clickMessages: {
					eventName:
						'consumo:mi consumo:detalle de consumo facturado:detalle de fijo tradicional:click en ver detalle de mensajes',
					data: {
						event_name: 'click en ver detalle de mensajes',
					},
				},
				clickIncluido: {
					eventName: 'consumo:mi consumo:detalle de consumo facturado:detalle de fijo tradicional:click en incluido',
					data: {
						event_name: 'click en incluido',
					},
				},
				clickFuera: {
					eventName:
						'consumo:mi consumo:detalle de consumo facturado:detalle de fijo tradicional:click en fuera de tarifa',
					data: {
						event_name: 'click en fuera de tarifa',
					},
				},
				clickTodo: {
					eventName: 'consumo:mi consumo:detalle de consumo facturado:detalle de fijo tradicional:click en todo',
					data: {
						event_name: 'click en todo',
					},
				},
				clickCloseConsumptionOverlay: {
					eventName:
						'consumo:mi consumo:detalle de consumo facturado:detalle de fijo tradicional:click en cerrar consumo de datos',
					data: {
						event_name: 'click en cerrar consumo de datos',
					},
				},
			},
			tv: {
				view: {
					page_name: 'consumo:mi consumo:detalle de consumo facturado:detalle de television',
					page_section: 'consumo',
					page_subcategory_level_1: 'mi consumo',
					page_subcategory_level_2: 'detalle de consumo facturado',
					page_subcategory_level_3: 'detalle de television',
					page_screen: 'detalle de television',
					client_billing_id: '',
					client_billing_type: '',
					error_category: '',
					error_descripcion: '',
				},
				clickTV: {
					eventName:
						'consumo:mi consumo:detalle de consumo facturado:detalle de television:click en ver detalle de television',
					data: {
						event_name: 'click en ver detalle de television',
					},
				},
				clickCloseConsumptionOverlay: {
					eventName:
						'consumo:mi consumo:detalle de consumo facturado:detalle de television:click en cerrar consumo de datos',
					data: {
						event_name: 'click en cerrar consumo de datos',
					},
				},
			},
		},
		labels: {
			server_error: 'server error',
			server_error_desc: 'ups parece que algo ha ido mal',
		},
	},
	Communications: {
		view: {
			page_name: 'comunicaciones:cambios en tu tarifa',
			page_section: 'comunicaciones',
			page_subcategory_level_1: 'cambios en tu tarifa',
			page_screen: 'cambios en tu tarifa',
			journey_subcategory: 'pospago',
		},
	},
	abonos: {
		view: {
			page_name: 'facturas:mis facturas:detalle de factura:notas de abono',
			page_section: 'facturas',
			page_subcategory_level_1: 'mis facturas',
			page_subcategory_level_2: 'detalles de factura',
			page_subcategory_level_3: 'notas de abono',
			page_screen: 'notas de abono',
			client_billing_id: '',
			client_billing_type: '',
			billing_alert: '',
		},
		click_abonos: {
			event_name: 'click en descargar abono',
		},
	},
	current_comsuption: {
		labels: {
			server_error: 'server error',
			server_error_desc: 'ups parece que algo ha ido mal',
		},
		postpaid: {
			view: {
				page_name: 'consumo:mi consumo:detalle de consumo sin facturar:detalle de movil pospago',
				page_section: 'consumo',
				page_subcategory_level_1: 'mi consumo',
				page_subcategory_level_2: 'detalle de consumo sin facturar',
				page_subcategory_level_3: 'detalle de movil pospago',
				page_screen: 'detalle de movil pospago',
				client_billing_id: '',
				client_billing_type: '',
				error_category: '',
				error_descripcion: '',
			},
			clickCalls: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de movil pospago:click en ver detalle de llamadas',
				data: {
					event_name: 'click en ver detalle de llamadas',
				},
			},
			clickInternet: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de movil pospago:click en ver detalle de datos',
				data: {
					event_name: 'click en ver detalle de datos',
				},
			},
			clickMessages: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de movil pospago:click en ver detalle de mensajes',
				data: {
					event_name: 'click en ver detalle de mensajes',
				},
			},
			clickIncluido: {
				eventName: 'consumo:mi consumo:detalle de consumo sin facturar:detalle de movil pospago:click en incluido',
				data: {
					event_name: 'click en incluido',
				},
			},
			clickFuera: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de movil pospago:click en fuera de tarifa',
				data: {
					event_name: 'click en fuera de tarifa',
				},
			},
			clickTodo: {
				eventName: 'consumo:mi consumo:detalle de consumo sin facturar:detalle de movil pospago:click en todo',
				data: {
					event_name: 'click en todo',
				},
			},
			clickRoamingPrices: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de movil pospago:click en ver precios roaming',
				data: {
					event_name: 'click en ver precios roaming',
				},
			},
			clickCloseConsumptionOverlay: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de movil pospago:click en cerrar consumo de datos',
				data: {
					event_name: 'click en cerrar consumo de datos',
				},
			},
		},
		landline: {
			view: {
				page_name: 'consumo:mi consumo:detalle de consumo sin facturar:detalle de fijo tradicional',
				page_section: 'consumo',
				page_subcategory_level_1: 'mi consumo',
				page_subcategory_level_2: 'detalle de consumo sin facturar',
				page_subcategory_level_3: 'detalle de fijo tradicional',
				page_screen: 'detalle de fijo tradicional',
				client_billing_id: '',
				client_billing_type: '',
				error_category: '',
				error_descripcion: '',
			},
			clickCalls: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de fijo tradicional:click en ver detalle de llamadas',
				data: {
					event_name: 'click en ver detalle de llamadas',
				},
			},
			clickIncluido: {
				eventName: 'consumo:mi consumo:detalle de consumo sin facturar:detalle de fijo tradicional:click en incluido',
				data: {
					event_name: 'click en incluido',
				},
			},
			clickFuera: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de fijo tradicional:click en fuera de tarifa',
				data: {
					event_name: 'click en fuera de tarifa',
				},
			},
			clickTodo: {
				eventName: 'consumo:mi consumo:detalle de consumo sin facturar:detalle de fijo tradicional:click en todo',
				data: {
					event_name: 'click en todo',
				},
			},
			clickCloseConsumptionOverlay: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de fijo tradicional:click en cerrar consumo de datos',
				data: {
					event_name: 'click en cerrar consumo de datos',
				},
			},
		},
		homezone: {
			view: {
				page_name: 'consumo:mi consumo:detalle de consumo sin facturar:detalle de fijo home zone',
				page_section: 'consumo',
				page_subcategory_level_1: 'mi consumo',
				page_subcategory_level_2: 'detalle de consumo sin facturar',
				page_subcategory_level_3: 'detalle de fijo home zone',
				page_screen: 'detalle de fijo home zone',
				client_billing_id: '',
				client_billing_type: '',
				error_category: '',
				error_descripcion: '',
			},
			clickCalls: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de fijo home zone:click en ver detalle de llamadas',
				data: {
					event_name: 'click en ver detalle de llamadas',
				},
			},
			clickInternet: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de fijo home zone:click en ver detalle de datos',
				data: {
					event_name: 'click en ver detalle de datos',
				},
			},
			clickMessages: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de fijo home zone:click en ver detalle de mensajes',
				data: {
					event_name: 'click en ver detalle de mensajes',
				},
			},
			clickIncluido: {
				eventName: 'consumo:mi consumo:detalle de consumo sin facturar:detalle de fijo home zone:click en incluido',
				data: {
					event_name: 'click en incluido',
				},
			},
			clickFuera: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de fijo home zone:click en fuera de tarifa',
				data: {
					event_name: 'click en fuera de tarifa',
				},
			},
			clickTodo: {
				eventName: 'consumo:mi consumo:detalle de consumo sin facturar:detalle de fijo home zone:click en todo',
				data: {
					event_name: 'click en todo',
				},
			},
			clickCloseConsumptionOverlay: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de fijo home zone:click en cerrar consumo de datos',
				data: {
					event_name: 'click en cerrar consumo de datos',
				},
			},
		},
		tv: {
			view: {
				page_name: 'consumo:mi consumo:detalle de consumo sin facturar:detalle de television',
				page_section: 'consumo',
				page_subcategory_level_1: 'mi consumo',
				page_subcategory_level_2: 'detalle de consumo sin facturar',
				page_subcategory_level_3: 'detalle de television',
				page_screen: 'detalle de television',
				client_billing_id: '',
				client_billing_type: '',
				error_category: '',
				error_descripcion: '',
			},
			clickTV: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de tv:click en ver detalle de television',
				data: {
					event_name: 'click en ver detalle de television',
				},
			},
			clickCloseConsumptionOverlay: {
				eventName: 'consumo:mi consumo:detalle de consumo sin facturar:detalle de tv:click en cerrar consumo de datos',
				data: {
					event_name: 'click en cerrar consumo de datos',
				},
			},
		},
		mbb: {
			view: {
				page_name: 'consumo:mi consumo:detalle de consumo sin facturar:detalle de linea de datos',
				page_section: 'consumo',
				page_subcategory_level_1: 'mi consumo',
				page_subcategory_level_2: 'detalle de consumo sin facturar',
				page_subcategory_level_3: 'detalle de linea de datos',
				page_screen: 'detalle de linea de datos',
				client_billing_id: '',
				client_billing_type: '',
				error_category: '',
				error_descripcion: '',
			},
			clickInternet: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de linea de datos:click en ver detalle de datos',
				data: {
					event_name: 'click en ver detalle de datos',
				},
			},
			clickIncluido: {
				eventName: 'consumo:mi consumo:detalle de consumo sin facturar:detalle de linea de datos:click en incluido',
				data: {
					event_name: 'click en incluido',
				},
			},
			clickFuera: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de linea de datos:click en fuera de tarifa',
				data: {
					event_name: 'click en fuera de tarifa',
				},
			},
			clickTodo: {
				eventName: 'consumo:mi consumo:detalle de consumo sin facturar:detalle de linea de datos:click en todo',
				data: {
					event_name: 'click en todo',
				},
			},
			clickCloseConsumptionOverlay: {
				eventName:
					'consumo:mi consumo:detalle de consumo sin facturar:detalle de linea de datos:click en cerrar consumo de datos',
				data: {
					event_name: 'click en cerrar consumo de datos',
				},
			},
		},
	},
	CommercialJourneys: {
		clickPDF: {
			pay: {
				postpaid: 'Postpaid',
				pospago: 'pospago',
				prepago: 'prepago',
			},
			click: {
				page_name: 'transaccional:microflujos:checkout:resumen',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'checkout',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'checkout',
				navigation_level_4: 'resumen',
				page_screen: 'resumen',
				event_name: 'click en resumen contrato',
				event_category: 'link',
				event_label: '',
				event_context: 'resumen contrato pdf',
				journey_name: 'checkout',
				journey_type: 'transaccional',
				journey_category: '',
				journey_subcategory: '',
				journey_detail: 'resumen',
				journey_step: 'checkout:resumen',
				state_flow: 'checkout',
			},
		},
		additionalLines: {
			page: {
				page_name: 'purchase:lineas adicionales:checkout:paso {0}',
				page_platform: 'web',
				page_section: 'purchase',
				page_subcategory_level_1: 'lineas adicionales',
				page_subcategory_level_2: '',
				page_subcategory_level_3: '',
				page_screen: 'paso {0}',
				page_typology: 'overlay',
				help_bubble_status: 'disponible',
			},
			data: {
				'purchase_migration_type': null,
				'purchase_product_classification': null,
				'&&products': null,
				'purchase_entrypoint': null,
				'transaction_shipping': null,
				'purchaseID': null,
				'company_portability': null,
				'promotion_name': null,
				'promotion_duration': null,
				'promotion_code': null,
				'&&events': null,
				'is_main_flow': null,
			},
			data_deeplink: {
				'entrypoint_section': 'deeplink',
				'entrypoint_location': 'undefined',
				'entrypoint_position': 'undefined',
				'entrypoint_title': 'undefined',
				'experience_name': 'undefined',
				'journey_name': '{journey}',
				'&&events': 'event70',
			},
			event: {
				event_name: 'click en {0}',
			},
			eventEmail: {
				event_name: 'copy de mail diferente',
			},
			eventoMail: {
				event: 'event168',
			},
			types: {
				loaded: 'loaded',
				continue: 'continue',
			},
			errors: {
				VFphone: 'VFphone',
			},
			pageRegistrationType: {
				page_name: 'transaccional:microflujos:lineas adicionales:tipo de alta',
				page_platform: 'web',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'lineas adicionales',
				page_subcategory_level_3: '',
				page_screen: 'tipo de alta',
				page_typology: 'overlay',
				help_bubble_status: 'disponible',
			},
			dataRegistrationType: {
				'navigation_level_1': 'transaccional',
				'navigation_level_2': 'microflujos',
				'navigation_level_3': 'lineas adicionales',
				'navigation_level_4': 'tipo de alta',
				'&&events': 'prodView',
				'journey_name': 'lineas adicionales',
				'journey_type': 'transaccional',
				'journey_category': '',
				'journey_subcategory': 'pospago',
				'journey_process': '',
				'journey_detail': 'tipo de alta',
				'journey_step': 'lineas adicionales:tipo de alta',
				'is_main_flow': '',
				'state_flow': 'microflujo',
				'&&products': null,
			},
		},
		canjeTerminales: {
			terminalList: {
				page: {
					page_name: 'transaccional:microflujos:canje terminales:listado',
					page_platform: 'web',
					page_section: 'transaccional',
					page_subcategory_level_1: 'microflujos',
					page_subcategory_level_2: 'canje terminales',
					page_screen: 'listado',
					page_typology: 'overlay',
					navigation_level_1: 'transaccional',
					navigation_level_2: 'canje terminales',
					navigation_level_3: 'tipo de alta',
					navigation_level_4: 'listado',
					asset_name: 'mioweb',
				},
				data: {
					'help_bubble_status': 'disponible',
					'purchase_entrypoint': '',
					'product_playlist_name': '',
					'product_filters': '',
					'&&events': 'event27',
					'journey_name': 'canje terminales',
					'journey_type': 'transaccional',
					'journey_category': '',
					'journey_subcategory': '',
					'journey_detail': 'listado',
					'journey_step': 'canje terminales:listado',
					'is_main_flow': '',
					'state_flow': 'microflujo',
					'event_context': '',
					'transaction_cart_id': '',
				},
				pagePrivateOffer: {
					page_name: 'transaccional:microflujos:canje terminales:listado',
					page_platform: 'web',
					page_section: 'transaccional',
					page_subcategory_level_1: 'microflujos',
					page_subcategory_level_2: 'canje terminales',
					page_screen: 'listado',
					navigation_level_1: 'transaccional',
					navigation_level_2: 'microflujos',
					navigation_level_3: 'canje terminales',
					navigation_level_4: 'listado',
					asset_name: 'mioweb',
				},
				eventPrivateOffer: {
					event_name: 'oferta pega',
					event_category: 'visualizacion',
					event_context: 'listado terminales',
					event_label: 'oferta pega',
				},
				event_click: 'click oferta pega',
				dataPrivateOffer: {
					'journey_name': 'canje terminales',
					'journey_type': 'transaccional',
					'journey_category': '',
					'journey_subcategory': '',
					'journey_detail': 'listado',
					'journey_step': 'canje terminales:listado',
					'is_main_flow': '',
					'state_flow': 'microflujo',
					'&&products': null,
				},
				pageError: {
					page_name: 'transaccional:microflujos:canje terminales:error',
					page_platform: 'web',
					page_section: 'transaccional',
					page_subcategory_level_1: 'microflujos',
					page_subcategory_level_2: 'canje terminales',
					page_screen: 'error',
					page_typology: 'overlay',
					navigation_level_1: 'transaccional',
					navigation_level_2: 'lineas adicionales',
					navigation_level_3: 'tipo de alta',
					navigation_level_4: 'error',
					asset_name: 'mioweb',
				},
				pageDenegacionError: {
					page_name: 'purchase:canje terminales:error de entryPoint',
					page_platform: 'web',
					page_section: 'purchase',
					page_subcategory_level_1: 'canje terminales',
					page_screen: 'error',
					page_typology: 'overlay',
				},
				dataError: {
					help_bubble_status: 'disponible',
					purchase_entrypoint: '',
					product_playlist_name: '',
					product_filters: '',
					journey_name: '',
					journey_detail: 'error funcional',
					journey_step: '<microflujo>:error funcional',
					error_path: '',
				},
				errorTerminalList: {
					'help_bubble_status': 'disponible',
					'purchase_entrypoint': '',
					'error_code': '',
					'error_category': '',
					'error_type': '',
					'&&events': 'event102',
					'journey_name': 'canje terminales',
					'journey_detail': 'error',
					'journey_step': 'canje terminales:error',
					'journey_type': 'transaccional',
					'journey_category': '',
					'journey_subcategory': '',
					'state_flow': 'microflujo',
					'error_path': '',
				},
				pageButtonBdpChange: {
					page_name: 'transaccional:microflujos:canje terminales:listado',
					page_platform: 'web',
					page_section: 'transaccional',
					page_subcategory_level_1: 'microflujos',
					page_subcategory_level_2: 'canje terminales',
					page_screen: 'listado',
					navigation_level_1: 'transaccional',
					navigation_level_2: 'microflujos',
					navigation_level_3: 'canje terminales',
					navigation_level_4: 'listado',
					asset_name: 'mivoweb',
				},
				eventButtonBdpChange: {
					event_name: 'click cambiar',
					event_category: 'boton',
					event_context: 'listado terminales',
					event_label: 'click cambiar',
				},
				dataButtonBdpChange: {
					journey_name: 'canje terminales',
					journey_type: 'transaccional',
					journey_category: '',
					journey_subcategory: '',
					journey_detail: 'listado',
					journey_step: 'canje terminales:listado',
					is_main_flow: '',
					state_flow: 'microflujo',
				},
				pageButtonChangeLine: {
					page_name: 'transaccional:microflujos:canje terminales:listado:backdrop',
					page_platform: 'web',
					page_section: 'transaccional',
					page_subcategory_level_1: 'microflujos',
					page_subcategory_level_2: 'canje terminales',
					page_screen: 'listado',
					navigation_level_1: 'transaccional',
					navigation_level_2: 'microflujos',
					navigation_level_3: 'canje terminales',
					navigation_level_4: 'listado',
					navigation_level_5: 'backdrop',
					asset_name: 'mivoweb',
				},
				eventButtonChangeLine: {
					event_name: 'click cambiar linea',
					event_category: 'boton',
					event_context: 'listado terminales backdrop',
					event_label: 'click cambiar linea',
				},
				pagePlaylist: {
					page_name: 'purchase:canje terminales:home',
					page_platform: 'web',
					page_section: 'purchase',
					page_subcategory_level_1: 'canje terminales',
					page_screen: 'home',
					page_typology: 'overlay',
				},
				dataPlaylist: {
					'help_bubble_status': 'disponible',
					'purchase_entrypoint': '',
					'&&events': 'event133',
					'product_playlist_name': '',
					'product_filters': '',
				},
				eventPlaylist: {
					event_name: 'seleccion de playlist: {0}',
				},
				pageFilter: {
					page_name: 'purchase:canje terminales:home',
					page_platform: 'web',
					page_section: 'purchase',
					page_subcategory_level_1: 'canje terminales',
					page_screen: 'home',
					page_typology: 'overlay',
				},
				dataFilter: {
					'help_bubble_status': 'disponible',
					'purchase_entrypoint': '',
					'&&events': 'event17',
					'product_playlist_name': '',
					'product_filters': '',
				},
				eventFilter: {
					event_name: 'filtros aplicados',
				},
				pageEmptyResponse: {
					page_name: 'purchase:canje terminales:home',
					page_platform: 'web',
					page_section: 'purchase',
					page_subcategory_level_1: 'canje terminales',
					page_screen: 'home',
					page_typology: 'overlay',
				},
				dataEmptyResponse: {
					'help_bubble_status': 'disponible',
					'purchase_entrypoint': '',
					'&&events': 'event95',
					'product_playlist_name': '',
					'product_filters': '',
				},
				eventEmptyResponse: {
					event_name: 'listado sin resultados',
				},
				eventInfo: {
					event_name: 'click en mas info',
				},
				dataEventFinancAct: {
					'help_bubble_status': 'disponible',
					'purchase_entrypoint': '',
					'&&events': 'event97',
					'product_playlist_name': '',
					'product_filters': '',
				},
				eventFinancAct: {
					event_name: 'Financiación activa',
				},
				delivery: {
					page_name: 'checkout:',
					paso: 'paso 2',
					page_typology: 'overlay',
					transaction_shipping: '',
					transaction_payment_type: '',
					event_name: 'seleccionado {0}',
					home: 'envio a casa',
					shop: 'recoger en tienda',
					office: 'recoger en correos',
				},
			},
			buscadorCanjes: {
				page: {
					page_name: 'transaccional:microflujos:canje terminales:listado:buscador:<metodo>:<keyword>:<resultados>',
				},
				data: {
					page_name: 'transaccional:microflujos:canje terminales:listado',
					page_platform: 'web',
					page_section: 'transaccional',
					page_subcategory_level_1: 'microflujos',
					page_subcategory_level_2: 'canje terminales',
					page_screen: 'listado',
					navigation_level_1: 'transaccional',
					navigation_level_2: 'microflujos',
					navigation_level_3: 'canje terminales',
					navigation_level_4: 'listado',
					asset_name: 'mioweb',
					event_name: 'buscador:<metodo>:<keyword>:<resultados>',
					event_category: 'buscador:<metodo>',
					event_context: '<resultados>',
					event_label: '<keyword>',
					journey_name: 'canje terminales',
					journey_type: 'transaccional',
					journey_category: '',
					journey_subcategory: '',
					journey_detail: 'listado',
					journey_step: 'canje terminales:listado',
					is_main_flow: '',
					state_flow: 'microflujo',
				},
				const: {
					resultadoKO: 'no hay resultados para esta linea',
					resultadoOK: 'si hay resultados para esta linea',
					lupa: 'lupa',
					predictivo: 'predictivo',
				},
			},
			summary: {
				page: {
					page_name: 'purchase:canje terminales:checkout:paso 3',
					page_platform: 'web',
					page_section: 'purchase',
					page_subcategory_level_1: 'canje terminales',
					page_screen: 'paso 3',
					page_typology: 'overlay',
				},
				data: {
					'help_bubble_status': 'disponible',
					'purchase_entrypoint': '',
					'transaction_shipping': '',
					'transaction_payment_type': '',
					'&&products': null,
					'product_playlist_name': '',
					'product_filters': '',
					'journey_options': 'ultimo pago:{0}',
				},
			},
			page: {
				page_name: 'transaccional:microflujos:canje terminales:ficha',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'canje terminales',
				page_subcategory_level_3: '',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'canje terminales',
				navigation_level_4: 'ficha',
				page_typology: 'overlay',
				page_screen: 'ficha',
				event_name: null,
				asset_name: 'mivoweb',
				page_platform: 'web',
			},
			data: {
				'purchase_entrypoint': '',
				'transaction_shipping': '',
				'transaction_payment_type': '',
				'&&products': null,
				'purchaseID': '',
				'product_playlist_name': '',
				'product_filters': '',
				'journey_options': 'ultimo pago:{0}',
				'&&events': '',
				'journey_name': 'canje terminales',
				'journey_type': 'transaccional',
				'journey_category': '',
				'journey_subcategory': 'pospago',
				'journey_detail': 'ficha',
				'journey_step': 'canje terminales:ficha',
				'journey_payment_type': '',
				'is_main_flow': null,
				'transaction_cart_id': '',
				'state_flow': 'microflujo',
				'event_category': '',
				'event_context': '',
				'event_label': '',
				'product_catalog_offer': '',
				'product_type_offer': '',
				'journey_transaction_type': '',
			},
			detail: {
				event_init: 'prodView',
				event_add: 'scAdd',
			},
			promotions: {
				event_category: 'boton',
				event_context: 'promociones terminales',
			},
			sapDeeplinkData: {
				'&&events': 'event70',
				'entrypoint_section': 'deeplink',
				'entrypoint_location': 'undefined',
				'entrypoint_position': 'undefined',
				'entrypoint_title': 'undefined',
				'experience_name': 'undefined',
				'journey_name': '',
			},
			sapDeeplinkError: {
				page_name: 'purchase:canje terminales:error',
				event_name: 'sap no encontrado',
				error_category: 'sap no encontrado',
				error_descripcion: '',
				error_type: 'funcional',
				error_code: '',
				error_page: 'purchase:canje terminales:error',
			},
			event: {
				event_name: '',
				event_category: '',
				event_context: '',
				event_label: '',
			},
			checkout: {
				page_name: 'purchase:canje terminales:checkout:paso {0}',
				page_section: 'purchase',
				page_subcategory_level_1: 'canje terminales',
				page_subcategory_level_2: 'checkout',
				page_typology: 'overlay',
				page_screen: 'paso {0}',
				transaction_payment_type: '',
			},
			accordion: {
				event_name: 'click en el acordeon {0}',
				purchase_entrypoint: '',
				transaction_payment_type: '',
				journey_options: 'ultimo pago:{0}',
				event_category: 'acordeon',
			},
			addToCart: {
				event_name: 'anadir terminal al carrito',
				event_category: 'boton',
				event_context: 'ficha terminales',
			},
			canon: {
				page: {
					page_name: 'transaccional:microflujos:canje terminales:ficha',
					page_section: 'trasnsaccional',
					page_subcategory_level_1: 'microflujos',
					page_subcategory_level_2: 'canje terminales',
					navigation_level_1: 'transaccional',
					navigation_level_2: 'microflujos',
					navigation_level_3: 'canje terminales',
					navigation_level_4: 'ficha',
					page_screen: 'ficha',
					asset_name: 'mivoweb',
					page_platform: 'web',
				},
				event: {
					event_name: 'click que es',
					event_category: 'link',
					event_context: 'canon digital',
				},
			},
			buyNow: {
				page: {
					page_name: 'transaccional:microflujos:canje terminales:ficha',
					page_section: 'trasnsaccional',
					page_subcategory_level_1: 'microflujos',
					page_subcategory_level_2: 'canje terminales',
					navigation_level_1: 'transaccional',
					navigation_level_2: 'microflujos',
					navigation_level_3: 'canje terminales',
					navigation_level_4: 'ficha',
					page_screen: 'ficha',
					asset_name: 'mivoweb',
					page_platform: 'web',
				},
				event: {
					'event_name': 'anadir terminal en un click',
					'event_category': 'boton',
					'event_context': 'ficha terminales',
					'event_label': '',
					'&&events': 'sccAdd',
				},
			},
			types: {
				inicio: 'init',
				error: 'error',
				characteristic: 'characteristic',
				size: 'size',
				financing: 'financing',
				success: 'success',
				prescoring: 'prescoring',
				viewMorePromo: 'viewmore',
				llbbPromo: 'llbb',
				final_refinancing: 'final_refinancing',
				financing_detail: 'financing_detail',
				addToCart: 'add_to_cart',
				notify_unavailable: 'notify_unavailable',
				send_mail: 'send_mail',
				canon: 'canon',
				promos: 'promos',
				buyNow: 'buyNow',
			},
			error: {
				page_name: 'transaccional:microflujos:canje terminales:error',
				page_section: 'transaccional',
				page_subcategory_level_1: 'canje terminales',
				page_screen: 'error',
			},
			dataError: {
				error_list: 'et=funcional|el=back|ec=<ec>|em=pagina|eh=200|ed=<ed>',
			},
			notifyUnavailable: {
				page: {
					page_name: ':email disponibilidad',
					page_subcategory_level_3: 'ficha',
					page_screen: 'email disponibilidad',
				},
				event: {
					event_name: 'confirmacion',
					event_category: 'boton',
					event_context: 'disponibilidad terminales',
				},
			},
			confirmation: {
				page_name: 'purchase:canje terminales:checkout:thank you',
				page_screen: 'thank you',
				differentCopy: 'Copy de mail diferente',
				event: 'event169',
			},
			promos: {
				viewMore: {
					event_name: 'ver mas',
				},
				llbb: {
					event_name: 'click en bases legales',
				},
			},
			selectCharacteristic: {
				'event_name': 'seleccion de caracteristica',
				'event_category': 'selector',
				'event_context': '',
				'event_label': '',
				'&&events': 'prodView',
			},
			promoCode: {
				commonError: {
					'page_platform': 'web',
					'page_section': 'transaccional',
					'page_subcategory_level_1': 'microflujos',
					'page_subcategory_level_2': 'canje terminales',
					'navigation_level_1': 'transaccional',
					'navigation_level_2': 'microflujos',
					'navigation_level_3': 'canje terminales',
					'navigation_level_4': 'ficha',
					'asset_name': 'mivoweb',
					'journey_name': 'canje terminales',
					'journey_type': 'transaccional',
					'journey_category': '',
					'journey_subcategory': '',
					'state_flow': 'microflujo',
					'&&products': '',
				},
				funtionalError: {
					page_name: 'transaccional:microflujos:canje terminales:ficha',
					page_screen: 'ficha',
					event_category: 'evento',
					event_context: 'ec:404|eh:200',
					event_label: '',
					journey_options: 'ultimo pago:{0}',
					journey_detail: 'ficha',
					journey_step: 'canje terminales:ficha',
					journey_payment_type: '',
					is_main_flow: '',
					error_list: 'et=funcional|el=frontend|ec=404|em=trastienda|ed=<ed>|eh=200',
					error_type: 'funcional',
					product_catalog_offer: '',
					product_type_offer: '',
				},
				unauthorizedError: {
					page_name: 'transaccional:microflujos:canje terminales:ficha:error',
					page_subcategory_level_3: 'ficha',
					page_subcategory_level_4: 'error',
					navigation_level_5: 'error',
					page_typology: 'overlay',
					page_screen: 'error',
					event_category: 'error tecnico overlay',
					event_context: 'ec:undefined|eh:401',
					event_label: '',
					journey_detail: 'error',
					journey_step: 'canje terminales:error',
					error_list: 'et=tecnico|el=frontend|ec=undefined|em=trastienda|ed=<ed>|eh=401',
					error_type: 'tecnico',
				},
				otherError: {
					page_name: 'transaccional:microflujos:canje terminales:ficha:error',
					page_subcategory_level_3: 'ficha',
					page_subcategory_level_4: 'error',
					navigation_level_5: 'error',
					page_typology: 'backdrop',
					page_screen: 'error',
					event_category: 'error tecnico backdrop',
					event_context: 'ec:<ec>|eh:<eh>',
					event_label: '',
					journey_detail: 'error',
					journey_step: 'canje terminales:error',
					error_list: 'et=tecnico|el=frontend|ec=<ec>|em=trastienda|ed=<ed>|eh=<eh>',
					error_type: 'tecnico',
				},
			},
			shareTerminalDetail: {
				'page_name': 'transaccional:microflujos:canje terminales:ficha',
				'page_section': 'transaccional',
				'page_subcategory_level_1': 'microflujos',
				'page_subcategory_level_2': 'canje terminales',
				'navigation_level_1': 'transaccional',
				'navigation_level_2': 'microflujos',
				'navigation_level_3': 'canje terminales',
				'navigation_level_4': 'ficha',
				'page_screen': 'ficha',
				'asset_name': 'mivoweb',
				'page_platform': 'web',
				'event_category': 'boton',
				'event_context': 'undefined',
				'event_label': 'compartir',
				'journey_name': 'canje terminales',
				'journey_type': 'transaccional',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_options': 'ultimo pago:{0}',
				'journey_detail': 'ficha',
				'journey_step': 'canje terminales:ficha',
				'journey_payment_type': '',
				'is_main_flow': '',
				'state_flow': 'microflujo',
				'&&products': '',
			},
		},
		unallowedLineRenewal: {
			page: {
				page_name: 'purchase:<journey_name>:error de linea no renovable',
				page_section: 'purchase',
				page_subcategory_level_1: '<journey_name>',
				page_screen: 'error de linea no renovable',
			},
			data: {
				journey_name: '',
				purchase_entrypoint: '',
			},
		},
		error: {
			page: {
				page_name: '',
				page_section: '',
				page_subcategory_level_1: '',
				page_screen: 'error',
			},
			data: {
				'error_type': 'tecnico',
				'&&events': 'event100',
				'error_category': '',
				'error_description': '',
				'previous_pagename': '',
				'error_code': '',
				'journey_name': '',
				'journey_type': '',
				'purchase_entrypoint': '',
			},
			strings: {
				page_name: 'purchase:lineas adicionales:error',
				page_section: 'purchase',
				page_subcategory_level_1: 'lineas adicionales',
				financing: 'financing',
				page_screen: 'error de entrypoint',
			},
			stringsMtp: {
				page_name: 'purchase:movil to pack:error',
				page_name_denegacion: 'purchase:movil to pack:error de entryPoint',
				page_section: 'purchase',
				page_subcategory_level_1: 'movil to pack',
				page_screen: 'error de entryPoint',
				financing: 'financing',
				purchase_product_category: '{0}',
			},
		},
		convergence: {
			page: {
				page_name: '',
				page_platform: 'web',
				page_section: 'purchase',
				page_subcategory_level_1: 'movil to pack',
				page_subcategory_level_2: '',
				page_subcategory_level_3: '',
				page_subcategory_level_4: '',
				page_screen: 'home',
			},
			data: {
				'purchase_migration_type': null,
				'purchase_product_classification': null,
				'&&products': null,
				'purchase_entrypoint': null,
				'transaction_shipping': null,
				'purchaseID': null,
				'company_portability': null,
				'promotion_name': null,
				'promotion_duration': null,
				'promotion_code': null,
				'promotion_category': null,
				'purchase_product_category': '{0}',
				'&&events': '{0}',
			},
			event: {
				event_name: '{0}',
			},
			error: {
				'&&events': 'event101',
				'error_description': null,
				'error_code': null,
				'error_type': 'funcional',
			},
		},
		movilToPack: {
			page: {
				page_name: 'purchase:movil to pack:home',
				page_platform: 'web',
				page_section: 'purchase',
				page_subcategory_level_1: 'movil to pack',
				page_subcategory_level_2: 'home',
				page_screen: 'home',
				page_typology: 'overlay',
				help_bubble_status: 'disponible',
			},
			data: {
				'purchase_migration_type': null,
				'purchase_product_classification': null,
				'&&products': null,
				'purchase_entrypoint': 'commercialadditionalline',
				'transaction_shipping': null,
				'purchaseID': null,
				'company_portability': null,
				'promotion_name': null,
				'promotion_duration': null,
				'promotion_code': null,
				'&&events': null,
				'purchase_product_category': '{0}',
				'is_main_flow': null,
			},
			event: {
				event_name: 'click en {0}',
				default: 'producto default visto',
				picked: 'producto visto',
			},
		},
		movilToPackHigh: {
			page: {
				page_name: 'purchase:movil to pack:checkout:step 1',
				page_section: 'purchase',
				page_subcategory_level_1: 'movil to pack',
				page_subcategory_level_2: 'checkout',
				page_subcategory_level_3: '',
				page_screen: 'step 1',
				purchase: '',
				checkout: '',
			},
			data: {
				'purchase_migration_type': null,
				'purchase_product_category': null,
				'&&products': null,
				'purchase_entrypoint': null,
			},
			event: {
				event_name: 'seleccionado {0}',
			},
		},
		movilToPackInstallation: {
			page: {
				page_name: 'purchase:movil to pack:checkout:step 2',
				page_section: 'purchase',
				page_subcategory_level_1: 'movil to pack',
				page_subcategory_level_2: 'checkout',
				page_screen: 'step2',
				installation_type: '',
			},
			data: {
				'purchase_migration_type': null,
				'purchase_product_category': null,
				'&&products': null,
				'&&events': null,
				'purchase_entrypoint': null,
				'installation_type': null,
			},
			event: {
				event_name: 'click en más info',
				customLink: '',
			},
		},
		movilToPackSummary: {
			page: {
				page_name: 'purchase:movil to pack:checkout:step 3',
				page_section: 'purchase',
				page_subcategory_level_1: 'movil to pack',
				page_subcategory_level_2: 'checkout',
				page_screen: 'step3',
			},
			data: {
				'purchaseID': null,
				'purchase_migration_type': null,
				'purchase_product_category': null,
				'&&products': null,
				'&&events': null,
				'purchase_entrypoint': null,
				'installation_type': null,
				'is_main_flow': null,
			},
			pageError: {
				page_name: 'purchase:movil to pack:error',
				page_platform: 'web',
				page_section: 'purchase',
				page_subcategory_level_1: 'movil to pack',
				page_screen: 'error',
				page_typology: 'overlay',
			},
			event: {
				event_name: 'click en <copy>',
			},
			transaction: {
				transaction_shipping: '{type}',
			},
		},
		movilToPackSummaryThanks: {
			page: {
				page_name: 'purchase:movil to pack:checkout:thank you',
				page_section: 'purchase',
				page_subcategory_level_1: 'movil to pack',
				page_subcategory_level_2: 'checkout',
				page_screen: 'thank you',
			},
			data: {
				'purchase_migration_type': null,
				'purchase_product_category': null,
				'purchaseID': null,
				'&&products': null,
				'&&events': 'purchase',
				'purchase_entrypoint': null,
				'installation_type': null,
				'is_main_flow': null,
			},
		},

		shop: {
			view: {
				page_name: 'tienda:home',
				page_section: 'tienda',
				page_screen: 'home',
				page_typology: 'overlay',
				page_subcategory_level_1: 'home',
			},
			error: {
				'state': 'tienda:error',
				'page_name': 'tienda:error',
				'page_section': 'tienda',
				'page_screen': 'error',
				'previous_pagename': 'dashboard',
				'&&events': 'event100',
				'error_type': 'tecnico',
				'error_description': '',
				'error_code': '',
				'error_category': '',
			},
			data: {
				'purchase_migration_type': null,
				'purchase_product_classification': null,
				'&&products': null,
				'purchase_entrypoint': 'tienda',
				'transaction_shipping': null,
				'purchaseID': null,
				'company_portability': null,
				'promotion_name': null,
				'promotion_duration': null,
				'promotion_code': null,
				'&&events': null,
			},
			close: {
				eventName: 'click en cerrar',
				data: {
					event_name: 'click en cerrar',
					page_name: 'tienda:home',
					page_section: 'tienda',
				},
			},
			entrypoints: {
				view: {
					eventName: 'tienda:home:impresiones entrypoint',
					data: {
						'event_name': 'impresiones entrypoint',
						'page_name': 'tienda:home',
						'page_section': 'tienda',
						'page_subcategory_level_1': 'home',
						'page_screen': 'home',
						'&&events': 'event75',
						'entrypoint_list': 'es={es}|el={el}|ep={ep}|jn={jn}|en={en}|et={et}|ety=undefined',
					},
				},
				click: {
					eventName: 'click en entrypoint',
					data: {
						'event_name': 'click en entrypoint',
						'asset_name': 'mivoweb',
						'page_name': 'tienda:home',
						'page_section': 'tienda',
						'page_subcategory_level_1': 'home',
						'page_screen': 'home',
						'&&events': 'event70',
						'navigation_level_1': 'tienda',
						'navigation_level_2': 'home',
						'entrypoint_section': '',
						'entrypoint_location': '',
						'entrypoint_position': '',
						'entrypoint_title': '',
						'entrypoint_type': 'reco',
						'entrypoint_name': '',
						'entrypoint_issue': '',
						'entrypoint_label': '',
						'entrypoint_groupnba': '',
						'event_category': 'boton',
						'event_context': 'tienda banner wcs productos vodafone',
						'event_label': 'click entrypoint',
						'experience_name': 'undefined',
						'journey_name': '',
					},
				},
			},
			entryPointTabs: {
				tabClick: {
					eventName: 'tienda:home:click en {0}',
					data: {
						page_name: 'tienda:home',
						page_section: 'tienda',
						page_subcategory_level_1: 'home',
						asset_name: 'mivoweb',
						navigation_level_1: 'tienda',
						navigation_level_2: 'home',
						page_screen: 'home',
						event_name: 'click en {0}',
						event_category: 'boton',
						event_context: 'tienda',
						event_label: 'click en {0}',
					},
				},
			},
			bannerDmp: {
				loadBanner: {
					eventName: 'tienda:home:impresiones entrypoint',
					data: {
						'page_name': 'tienda:home',
						'page_section': 'tienda',
						'page_subcategory_level_1': 'home',
						'asset_name': 'mivoweb',
						'navigation_level_1': 'tienda',
						'page_screen': 'home',
						'event_name': 'impresiones entrypoint',
						'event_category': 'visualizacion',
						'event_context': 'tienda banners personalizados',
						'event_label': 'impresiones entrypoint',
						'&&events': 'event75',
						'entrypoint_list': 'es={es}|el={el}|ep={ep}|jn={jn}|en={en}|et={et}',
					},
				},
				changeBanner: {
					eventName: 'tienda:home:change en entrypoint',
					data: {
						'page_name': 'tienda:home',
						'page_section': 'tienda',
						'page_subcategory_level_1': 'home',
						'asset_name': 'mivoweb',
						'navigation_level_1': 'tienda',
						'page_screen': 'tienda',
						'&&events': 'event70',
						'event_name': 'visualizacion del {0}',
						'event_category': 'carrusel',
						'event_context': 'banner personalizacion tienda',
						'event_label': 'visualizacion del {0}',
					},
				},
				clickBanner: {
					eventName: 'tienda:home:click en entrypoint',
					data: {
						'page_name': 'tienda:home',
						'page_section': 'tienda',
						'page_subcategory_level_1': 'home',
						'asset_name': 'mivoweb',
						'navigation_level_1': 'tienda',
						'page_screen': 'home',
						'event_name': 'click en entrypoint',
						'event_category': 'boton',
						'event_context': 'tienda banner personalizado carrusel',
						'event_label': 'click entrypoint',
						'&&events': 'event70',
						'entrypoint_section': 'tienda',
						'entrypoint_location': '{0}',
						'entrypoint_position': 'undefined',
						'entrypoint_title': '{0}',
						'experience_name': '{0}',
						'journey_name': '{0}',
					},
				},
			},
			deepLinkTabs: {
				page: {
					page_name: 'tienda:home',
					page_section: 'tienda',
					page_subcategory_level_1: 'home',
					page_subcategory_level_2: '',
					page_subcategory_level_3: '',
					page_subcategory_level_4: '',
					page_screen: 'home',
				},
				data: {
					'asset_name': 'mivoweb',
					'navigation_level_1': 'tienda',
					'navigation_level_2': '',
					'navigation_level_3': '',
					'navigation_level_4': '',
					'navigation_level_5': '',
					'event_name': 'deeplink',
					'event_category': 'visualizacion',
					'event_context': 'tienda seccion ',
					'event_label': 'deeplink',
					'&&events': 'event70',
					'entrypoint_section': 'deeplink',
					'entrypoint_location': 'undefined',
					'entrypoint_position': 'undefined',
					'entrypoint_title': 'undefined',
					'entrypoint_name': 'undefined',
				},
			},
			specialForYou: {
				eventName: 'click en ofertas especiales para ti',
				data: {
					page_name: 'tienda:home',
					page_section: 'tienda',
					page_subcategory_level_1: 'home',
					navigation_level_1: 'tienda',
					navigation_level_2: 'home',
					asset_name: 'mioweb',
					page_screen: 'homa',
					page_platform: 'web',
					event_category: 'link',
					event_context: '{0}',
					event_label: '{0}',
				},
			},
		},
		errorDebt: {
			view: {
				page_name: 'tienda:home:pago pendiente',
				page_section: 'tienda',
				page_subcategory_level_1: 'home',
				page_subcategory_level_2: 'pago pendiente',
				page_screen: 'pago pendiente',
			},
			clickCerrar: {
				eventName: 'tienda:home:pago pendiente:click en cerrar',
				data: {
					event_name: 'click en cerrar',
				},
			},
			clickSolucionar: {
				eventName: 'tienda:home:pago pendiente:click en solucionar',
				data: {
					event_name: 'click en solucionar',
				},
			},
		},
		errorOrder: {
			view: {
				page_name: 'tienda:home:pedido en curso',
				page_section: 'tienda',
				page_subcategory_level_1: 'home',
				page_subcategory_level_2: 'pedido en curso',
				page_screen: 'pedido en curso',
			},
			clickCerrar: {
				eventName: 'tienda:home:pedido en curso:click en cerrar',
				data: {
					event_name: 'click en cerrar',
				},
			},
			clickOrders: {
				eventName: 'tienda:home:pedido en curso:click en ir a mis pedidos',
				data: {
					event_name: 'click en ir a mis pedidos',
				},
			},
		},
		closeConfirmation: {
			page: {
				page_name: 'purchase:<journey_name>:exit',
				page_section: 'purchase',
				page_subcategory_level_1: '',
				page_screen: 'exit',
				page_typology: 'overlay',
			},
			data_canje: {
				journey_name: '',
				purchase_entrypoint: '',
			},
			data_lines: {
				journey_name: '',
				purchase_entrypoint: '',
			},
			event: {
				'event_name': '',
				'&&events': '',
			},
		},
		closeConfirmationTransaccional: {
			page: {
				page_name: 'transaccional:microflujos:<journey_name>:error',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: '<journey_name>',
				page_screen: 'exit',
				page_typology: 'overlay',
			},
			data_lines: {
				'&&products': '',
				'state_flow': 'microflujo',
				'is_main_flow': '',
				'journey_name': '',
				'journey_type': 'transaccional',
				'journey_category': '',
				'journey_subcategory': '',
				'client_typology': '',
				'journey_process': '',
				'journey_detail': 'oferta',
				'journey_step': '<journey_name>:exit',
				'event_category': '',
				'event_context': '',
				'event_label': '',
				'navigation_level_1': 'transaccional',
				'navigation_level_2': 'microflujos',
				'navigation_level_3': '<journey_name>',
				'navigation_level_4': 'exit',
				'asset_name': 'mivoweb',
				'experience_name_test': '',
				'purchase_entrypoint': '',
			},
			event: {
				'event_name': '',
				'&&products': '',
				'&&events': '',
			},
		},
		bonoExtra: {
			view: {
				page_name: 'tienda:home:bonos y extras',
				page_section: 'tienda',
				page_subcategory_level_1: 'home',
				page_subcategory_level_2: 'bonos y extras',
				page_screen: 'bonos y extras',
			},
			clickCambioLinea: {
				eventName: 'tienda:home:bonos y extras:click en cambio de linea',
				data: {
					event_name: 'click en cambio de linea',
					page_name: 'tienda:home:bonos y extras',
					page_section: 'tienda',
					page_subcategory_level_1: 'home',
					page_subcategory_level_2: 'bonos y extras',
					page_screen: 'bonos y extras',
				},
			},
			clickVodafonePass: {
				eventName: 'tienda:home:bonos y extras:click en vodafone pass',
				data: {
					event_name: 'click en vodafone pass',
					page_name: 'tienda:home:bonos y extras',
					page_section: 'tienda',
					page_subcategory_level_1: 'home',
					page_subcategory_level_2: 'bonos y extras',
					page_screen: 'bonos y extras',
				},
			},
			clickExtraData: {
				eventName: 'tienda:home:bonos y extras:click en extras de datos',
				data: {
					event_name: 'click en extras de datos',
					page_name: 'tienda:home:bonos y extras',
					page_section: 'tienda',
					page_subcategory_level_1: 'home',
					page_subcategory_level_2: 'bonos y extras',
					page_screen: 'bonos y extras',
				},
			},
			clickEntertainment: {
				eventName: 'tienda:home:bonos y extras:click en entretenimiento',
				data: {
					event_name: 'click en entretenimiento',
					page_name: 'tienda:home:bonos y extras',
					page_section: 'tienda',
					page_subcategory_level_1: 'home',
					page_subcategory_level_2: 'bonos y extras',
					page_screen: 'bonos y extras',
				},
			},
			clickInternational: {
				eventName: 'tienda:home:bonos y extras:click en voz al extranjero',
				data: {
					event_name: 'click en voz al extranjero',
					page_name: 'tienda:home:bonos y extras',
					page_section: 'tienda',
					page_subcategory_level_1: 'home',
					page_subcategory_level_2: 'bonos y extras',
					page_screen: 'bonos y extras',
				},
			},
			clickSecureNet: {
				eventName: 'tienda:home:bonos y extras:click en secure net',
				data: {
					event_name: 'click en secure net',
					page_name: 'tienda:home:bonos y extras',
					page_section: 'tienda',
					page_subcategory_level_1: 'home',
					page_subcategory_level_2: 'bonos y extras',
					page_screen: 'bonos y extras',
				},
			},
			clickRetry: {
				eventName: 'tienda:home:bonos y extras:click en reintentar',
				data: {
					event_name: 'click en reintentar',
					page_name: 'tienda:home:bonos y extras',
					page_section: 'tienda',
					page_subcategory_level_1: 'home',
					page_subcategory_level_2: 'bonos y extras',
					page_screen: 'bonos y extras',
				},
			},
			clickCerrar: {
				eventName: 'tienda:home:bonos y extras:click en cerrar',
				data: {
					event_name: 'click en cerrar',
					page_name: 'tienda:home:bonos y extras',
					page_section: 'tienda',
					page_subcategory_level_1: 'home',
					page_subcategory_level_2: 'bonos y extras',
					page_screen: 'bonos y extras',
				},
			},
		},
		paths: {
			additionalLines: 'additionalLines',
			movilToPack: 'movilToPack',
		},
		care: {
			page: {
				'page_name': 'transaccional:microflujos:seguro movil:<step>',
				'page_section': 'transaccional',
				'page_subcategory_level_1': 'microflujos',
				'page_subcategory_level_2': 'seguro movil',
				'page_subcategory_level_3': '<step>',
				'navigation_level_1': 'transaccional',
				'navigation_level_2': 'seguro movil',
				'navigation_level_3': '<step>',
				'navigation_level_4': '',
				'navigation_level_5': '',
				'page_screen': '<step>',
				'asset_name': 'mivoweb',
				'&&products': '',
				'&&events': '',
				'error_category': '',
				'error_description': '',
				'error_type': '',
				'error_code': '',
			},
			events: {
				event_name: 'click en boton <copy>',
				event_category: 'visualizacion',
				event_context: '<context>',
				event_label: '<copy>',
			},
			journey: {
				journey_name: 'seguro movil',
				journey_type: 'transaccional',
				journey_category: '',
				journey_subcategory: '',
				journey_proccess: '',
				journey_options: '',
				journey_detail: '<journey>',
				journey_step: 'seguro movil:<journey>',
				journey_payment_type: '',
				is_main_flow: 'no',
				state_flow: 'microflujo',
				shipping_type: '',
				transaction_shipping: '',
			},
			stepCases: {
				terminalList: 'listado terminales',
				insuranceList: 'listado',
				error: 'error',
				exit: 'exit',
				ok: 'confirmacion',
			},
			contextCases: {
				onDevice: 'listado terminales movil',
				onInsurance: 'listado seguro movil',
				onCheckout: 'add seguro al nexo',
				onExit: 'exit seguro al nexo',
			},
			journeyCases: {
				screen: 'listado',
				backdrop: 'ficha',
				error: 'error',
			},
			error: {
				cases: {
					trastienda: 'trastienda',
					dxl: 'dxl',
					otros: 'otros',
				},
				type: 'funcional',
				description: {
					trastienda: 'error cargar listado seguros',
					dxl: 'error al contratar un seguro',
					otros: 'error no hay linea disponible',
				},
			},
			productsTemplate:
				'seguro;<product_name>;1;;;eVar180=alta seguro|eVar184=<promo_name>|eVar187=addons:fide|eVar188=secundario|eVar207=<promo_code>|eVar181=<msisdn>|eVar214=<model>|eVar138=<brand>',
		},
	},

	SVAs: {
		start: {
			eventName: 'sugerir una nueva app:start',
			data: {
				journey_name: 'sugerir una nueva app:start',
				journey_category: 'sugerir una nueva app',
				journey_type: 'otros',
			},
		},
		success: {
			eventName: 'sugerir una nueva app:ok',
			data: {
				journey_name: 'sugerir una nueva app:ok',
				journey_category: 'sugerir una nueva app',
				journey_type: 'otros',
			},
		},
		error: {
			eventName: 'sugerir una nueva app:ko',
			data: {
				journey_name: 'sugerir una nueva app:ko',
				journey_category: 'sugerir una nueva app',
				journey_type: 'otros',
			},
		},
		clickDetailsSection: {
			eventName: 'que tengo contratado:resumen que tengo contratado:clic en card <name>',
			data: {
				event_name: 'clic en card <name>',
				event_category: 'card',
			},
		},
		clickSVAContactButton: {
			eventName: 'que tengo contratado:servicios extra:{serviceName}:clic en contactar',
			data: {
				event_name: 'clic en contactar',
			},
		},
		clickCallButton: {
			eventName:
				'que tengo contratado:servicios extra:{serviceName}:contacta con tu tecnico especializado:clic en llamar',
			data: {
				event_name: 'clic en llamar',
			},
		},
		navigateToPS: {
			eventName: 'que tengo contratado:servicios extra:{serviceName}:clic en ir a servicio',
			data: {
				event_name: 'clic en ir a servicio',
			},
		},
		clickMoreDetailsButton: {
			eventName: 'que tengo contratado:servicios extra:{serviceName}:clic en saber mas',
			data: {
				event_name: 'clic en saber mas',
			},
		},
		clickMoreAppsButton: {
			eventName: 'que tengo contratado:servicios extra:{serviceName}:clic en sugerir una nueva app',
			data: {
				event_name: 'clic en sugerir una nueva app',
			},
		},
	},
	FirstTimeExperience: {
		page: {
			page_name: 'dashboard:first time experience',
			page_section: 'dashboard',
			page_subcategory_level_1: 'first time experience',
			page_screen: 'first time experience',
			fte_campaign_id: '',
			fte_page_id: '',
		},
		next: {
			event_name: 'clic en siguiente',
		},
		section: {
			event_name: 'clic en ir a seccion',
		},
		close: {
			event_name: 'clic en cerrar',
		},
	},
	pciPrepaid: {
		newTopupJourney: {
			'page_name': 'prepago:recargas:inicio de recarga',
			'page_section': 'prepago',
			'page_subcategory_level_1': 'recargas',
			'page_subcategory_level_2': 'inicio de recarga',
			'page_subcategory_level_3': '',
			'page_screen': 'inicio de recarga',
			'journey_name': 'recargar saldo',
			'journey_category': 'particulares',
			'journey_subcategory': 'prepago',
			'journey_type': 'servicio',
			'journey_detail': 'start',
			'journey_step': 'recargar saldo:start',
			'journey_card_selected': '',
			'&&events': 'event27',
			'&&products': 'recarga;recarga prepago;1;;;eVar184=|eVar208=|eVar187=|eVar182=|eVar207=',
		},
		journys: {
			firstStep: {
				eventName: 'recargar saldo:start',
				data: {
					journey_name: 'recargar saldo:start',
					journey_category: 'recargar saldo',
					journey_subcategory: 'propia',
					journey_options: '15',
					journey_type: '',
				},
			},
			secondStep: {
				eventName: 'recargar saldo:datos tarjeta',
				data: {
					journey_name: 'recargar saldo:datos tarjeta',
					journey_category: 'recargar saldo',
					journey_subcategory: 'propia',
					journey_options: '',
					journey_type: '',
					mobile_recharge: '',
				},
			},
			success: {
				eventName: 'recargar saldo:ok',
				data: {
					journey_name: 'recargar saldo:ok',
					journey_category: 'recargar saldo',
					journey_subcategory: 'propia',
					journey_options: '',
					journey_type: '',
					mobile_recharge: '',
					journey_step: 'recargar saldo:ok',
				},
			},
			error: {
				eventName: 'recargar saldo:ko',
				data: {
					journey_name: 'recargar saldo:ko',
					journey_category: 'recargar saldo',
					journey_subcategory: 'propia',
					journey_options: '',
					journey_type: '',
					mobile_recharge: '',
					journey_error_category: '',
					journey_error_description: '',
					journey_error_code: '',
				},
			},
		},
		interactions: {
			clickLineNumber: {
				eventName: 'recargas:realizar recarga:inicio de recarga:clic en número para recargar',
				data: {
					event_name: 'clic en número para recargar',
				},
			},
			clickRetryLoadIframe: {
				eventName: 'recargas:realizar recarga:datos tarjeta:clic en intentar de nuevo',
				data: {
					event_name: 'clic en intentar de nuevo',
				},
			},
			clickContinueErrorOverlay: {
				eventName: 'recargas:realizar recarga:ko:clic en volver a datos tarjeta',
				data: {
					event_name: 'clic en volver a datos tarjeta',
				},
			},
			clickEmpezaErrorOverlay: {
				eventName: 'recargas:realizar recarga:ko:clic en empezar otra recarga',
				data: {
					event_name: 'clic en empezar otra recarga',
				},
			},
			clickDownLoadIOSApp: {
				eventName: '<page_name>:click en descargar app mi vodafone',
				data: {
					event_name: 'click en descargar app mi vodafone',
				},
			},
			clickDownLoadApp: {
				eventName: 'recargas:realizar recarga:ok:clic en descargar mi vodafone',
				data: {
					event_name: 'clic en descargar mi vodafone',
				},
			},
			clickAddNewLineNumber: {
				eventName: 'recargas:realizar recarga:clic en anadir movil',
				data: {
					event_name: 'clic en anadir movil',
				},
			},
			clickSavePhoto: {
				eventName: 'recargas:realizar recarga:ticket de pago:clic en guardar en galeria',
				data: {
					event_name: 'clic en guardar en galeria',
				},
			},
			clickSpanishLanguage: {
				eventName: 'recargas:realizar recarga:inicio de recarga:clic en espanol',
				data: {
					event_name: 'clic en espanol',
				},
			},
			clickEnglishLanguage: {
				eventName: 'recargas:realizar recarga:inicio de recarga:clic en english',
				data: {
					event_name: 'clic en english',
				},
			},
			clickOnIrAMiVodafone: {
				eventName: 'recargas:realizar recarga:inicio de recarga:clic en ir a mi vodafone',
				data: {
					event_name: 'clic en ir a mi vodafone',
				},
			},
			clickOnGoToMiVodafone: {
				eventName: 'recargas:realizar recarga:inicio de recarga:clic en go to mi vodafone',
				data: {
					event_name: 'clic en go to mi vodafone',
				},
			},
			clickSaveCreditCard: {
				eventName: 'recargas:realizar recarga:ok:guardar tarjeta',
				data: {
					event_name: 'guardar tarjeta',
				},
			},
			clickDontSaveCreditCard: {
				eventName: 'recargas:realizar recarga:ok:no guardar tarjeta',
				data: {
					event_name: 'no guardar tarjeta',
				},
			},
			clickViewSavedCreditCard: {
				eventName: 'recargas:realizar recargainicio de recarga:clic en ver tarjetas guardadas',
				data: {
					event_name: 'clic en ver tarjetas guardadas',
				},
			},
			clickOncloseCrossTopupJourney: {
				eventName: '<page_name>:click en cerrar',
				data: {
					event_name: 'click en cerrar',
				},
			},
		},
	},
	buySIMJourney: {
		personelInfojourney: {
			'page_name': 'prepago:tienda:datos personales',
			'page_section': 'prepago',
			'page_subcategory_level_1': 'tienda',
			'page_subcategory_level_2': 'datos personales',
			'page_screen': 'datos personales',
			'journey_name': 'tienda prepago',
			'journey_category': 'particulares',
			'journey_subcategory': 'prepago',
			'journey_type': 'transaccional',
			'journey_process': 'comprar tarjeta sim prepago',
			'journey_options': '',
			'journey_detail': 'start',
			'journey_step': 'tienda prepago:start',
			'journey_prepaid_tariff': '',
			'product_process_type': '',
			'&&events': 'event27',
			'&&products': 'movil;<product name>;1;;;eVar184=|eVar208=|eVar187=|eVar182=|eVar207=',
		},
		personelInfoLoadedjourney: {
			'page_name': 'prepago:tienda:datos personales',
			'page_section': 'prepago',
			'page_subcategory_level_1': 'tienda',
			'page_subcategory_level_2': 'datos personales',
			'page_screen': 'datos personales',
			'journey_name': 'tienda prepago',
			'journey_category': 'particulares',
			'journey_subcategory': 'prepago',
			'journey_type': 'transaccional',
			'journey_process': 'comprar tarjeta sim prepago',
			'journey_options': '',
			'journey_detail': 'start carga completa',
			'journey_step': 'tienda prepago:start carga completa',
			'journey_prepaid_tariff': '',
			'product_process_type': '',
			'&&events': '',
			'&&products': 'movil;<product name>;1;;;eVar184=|eVar208=|eVar187=|eVar182=|eVar207=',
		},
		okjourney: {
			'page_name': 'prepago:tienda:confirmacion pedido',
			'page_section': 'prepago',
			'page_subcategory_level_1': 'tienda',
			'page_subcategory_level_2': 'confirmacion pedido',
			'page_screen': 'confirmacion pedido',
			'journey_name': 'tienda prepago',
			'journey_category': 'particulares',
			'journey_subcategory': 'prepago',
			'journey_type': 'transaccional',
			'journey_process': 'comprar tarjeta sim prepago',
			'product_process_type': '',
			'journey_options': '',
			'journey_detail': 'ok',
			'journey_step': 'tienda prepago:ok',
			'journey_prepaid_tariff': '',
			'&&events': 'event28,purchase',
			'&&products': 'movil;<product name>;1;;;eVar184=|eVar208=|eVar187=|eVar182=|eVar207=',
			'purchaseID': '',
		},
		kojourney: {
			'page_name': 'prepago:tienda:ko',
			'page_section': 'prepago',
			'page_subcategory_level_1': 'tienda',
			'page_subcategory_level_2': 'ko',
			'page_screen': 'ko',
			'journey_name': 'tienda prepago',
			'journey_category': 'particulares',
			'journey_subcategory': 'prepago',
			'journey_type': 'transaccional',
			'journey_process': 'comprar tarjeta sim prepago',
			'product_process_type': '',
			'journey_options': '',
			'journey_detail': 'direccion de envio',
			'journey_step': 'tienda prepago:ko',
			'journey_prepaid_tariff': '',
			'&&events': '',
			'&&products': 'movil;<product name>;1;;;eVar184=|eVar208=|eVar187=|eVar182=|eVar207=',
			'error_list': '',
		},
		personalInfoTermsAndCondition: {
			page_name: 'prepago:tienda:datos personales:proteccion de datos',
			page_section: 'prepago',
			page_subcategory_level_1: 'tienda',
			page_subcategory_level_2: 'datos personales',
			page_subcategory_level_3: 'proteccion de datos',
			page_screen: 'proteccion de datos',
		},
		paymentTermsAndCondition: {
			page_name: 'prepago:tienda:pago:privacidad de tus datos',
			page_section: 'prepago',
			page_subcategory_level_1: 'tienda',
			page_subcategory_level_2: 'pago',
			page_subcategory_level_3: 'privacidad de tus datos',
			page_screen: 'privacidad de tus datos',
		},
		enterAddress: {
			'page_name': 'prepago:tienda:introducir direccion',
			'page_section': 'prepago',
			'page_subcategory_level_1': 'tienda',
			'page_subcategory_level_2': 'introducir direccion',
			'page_screen': 'introducir direccion',
			'journey_name': 'tienda prepago',
			'journey_category': 'particulares',
			'journey_subcategory': 'prepago',
			'journey_type': 'transaccional',
			'journey_process': 'comprar tarjeta sim prepago',
			'journey_options': '',
			'journey_detail': 'envio',
			'journey_step': 'tienda prepago:introducir direccion',
			'journey_prepaid_tariff': '',
			'product_process_type': '',
			'&&events': '',
			'&&products': 'movil;<product name>;1;;;eVar184=|eVar208=|eVar187=|eVar182=|eVar207=',
		},
		payment: {
			'page_name': 'prepago:tienda:pago',
			'page_section': 'prepago',
			'page_subcategory_level_1': 'tienda',
			'page_subcategory_level_2': 'pago',
			'page_screen': 'pago',
			'journey_name': 'tienda prepago',
			'journey_category': 'particulares',
			'journey_subcategory': 'prepago',
			'journey_type': 'transaccional',
			'journey_process': 'comprar tarjeta sim prepago',
			'product_process_type': '',
			'journey_options': '',
			'journey_detail': 'direccion de envio',
			'journey_step': 'tienda prepago:pago',
			'journey_prepaid_tariff': '',
			'&&events': '',
			'&&products': 'movil;<product name>;1;;;eVar184=|eVar208=|eVar187=|eVar182=|eVar207=',
		},
		deliveryAddressStep1: {
			'page_name': 'prepago:tienda:envio paso1',
			'page_section': 'prepago',
			'page_subcategory_level_1': 'tienda',
			'page_subcategory_level_2': 'envio paso1',
			'page_screen': 'envio paso1',
			'journey_name': 'tienda prepago',
			'journey_category': 'particulares',
			'journey_subcategory': 'prepago',
			'journey_type': 'transaccional',
			'journey_process': 'comprar tarjeta sim prepago',
			'journey_options': '',
			'journey_detail': 'envio paso1',
			'journey_step': 'tienda prepago:envio paso1',
			'journey_prepaid_tariff': '',
			'product_process_type': '',
			'&&events': '',
			'&&products': 'movil;<product name>;1;;;eVar184=|eVar208=|eVar187=|eVar182=|eVar207=',
		},
		deliveryAddressStep2: {
			'page_name': 'prepago:tienda:envio paso2',
			'page_section': 'prepago',
			'page_subcategory_level_1': 'tienda',
			'page_subcategory_level_2': 'envio paso2',
			'page_screen': 'envio paso2',
			'journey_name': 'tienda prepago',
			'journey_category': 'particulares',
			'journey_subcategory': 'prepago',
			'journey_type': 'transaccional',
			'journey_process': 'comprar tarjeta sim prepago',
			'journey_options': '',
			'journey_detail': 'envio paso2',
			'journey_step': 'tienda prepago:envio paso2',
			'journey_prepaid_tariff': '',
			'product_process_type': '',
			'&&events': '',
			'&&products': 'movil;<product name>;1;;;eVar184=|eVar208=|eVar187=|eVar182=|eVar207=',
		},
		portability: {
			'page_name': 'prepago:tienda:tu numero',
			'page_section': 'prepago',
			'page_subcategory_level_1': 'tienda',
			'page_subcategory_level_2': 'tu numero',
			'page_screen': 'tu numero',
			'journey_name': 'tienda prepago',
			'journey_category': 'particulares',
			'journey_subcategory': 'prepago',
			'journey_type': 'transaccional',
			'journey_process': 'comprar tarjeta sim prepago',
			'product_process_type': '',
			'journey_detail': 'tu numero',
			'journey_step': 'tienda prepago:tu numero',
			'&&events': '',
			'&&products': 'movil;<product name>;1;;;eVar184=|eVar208=|eVar187=|eVar182=|eVar207=',
		},
		portabilityEditOwner: {
			'page_name': 'prepago:tienda:tu numero:datos del titular',
			'page_section': 'prepago',
			'page_subcategory_level_1': 'tienda',
			'page_subcategory_level_2': 'tu numero',
			'page_subcategory_level_3': 'datos del titular',
			'page_screen': 'datos del titular',
			'journey_name': 'tienda prepago',
			'journey_category': 'particulares',
			'journey_subcategory': 'prepago',
			'journey_type': 'transaccional',
			'journey_process': 'comprar tarjeta sim prepago',
			'product_process_type': '',
			'journey_detail': 'tu numero datos del titular',
			'journey_step': 'tienda prepago:tu numero datos del titular',
			'&&events': '',
			'&&products': 'movil;<product name>;1;;;eVar184=|eVar208=|eVar187=|eVar182=|eVar207=',
		},
	},
	buySIMInteractions: {
		clickOnContinuar: {
			eventName: '<page_name>:click en continuar',
			data: {
				event_name: 'click en continuar',
			},
		},
		clickOnAcceptDataPolicy: {
			eventName: 'prepago:tienda:datos personales:click en aceptar politica de datos',
			data: {
				event_name: 'click en aceptar politica de datos',
			},
		},
		clickOnAcceptCommunication: {
			eventName: 'prepago:tienda:datos personales:click en aceptar comunicaciones',
			data: {
				event_name: 'click en aceptar comunicaciones',
			},
		},
		errorInField: {
			eventName: '<page_name>:error en campo <field name>',
			data: {
				event_name: 'error en campo <field name>',
			},
		},
		clickOnEditAddress: {
			eventName: 'prepago:tienda:envio paso2:click en editar',
			data: {
				event_name: 'click en editar',
			},
		},
		clickOnSaveAddress: {
			eventName: 'prepago:tienda:introducir direccion:click en guardar direccion',
			data: {
				event_name: 'click en guardar direccion',
			},
		},
		clickOnAcceptGeneralCondition: {
			eventName: 'prepago:tienda:pago:click en aceptar condiciones generales',
			data: {
				event_name: 'click en aceptar condiciones generales',
			},
		},
		clickOnSaveCondition: {
			eventName: 'prepago:tienda:pago:condiciones generales:click en guardar',
			data: {
				event_name: 'click en guardar',
			},
		},
		clickOnBackToStart: {
			eventName: 'prepago:tienda:confirmacion pedido:click en volver al inicio',
			data: {
				event_name: 'click en volver al inicio',
			},
		},
		clickOnFinishOrder: {
			eventName: 'prepago:tienda:pago:click en finalizar pedido',
			data: {
				event_name: 'click en finalizar pedido',
			},
		},
		clickOnFindAddress: {
			eventName: 'prepago:tienda:introducir direccion:click en introducir direccion',
			data: {
				event_name: 'click en introducir direccion',
			},
		},
		clickOnSaveUserData: {
			eventName: 'prepago:tienda:tu numero:datos del titular:click en guardar titular',
			data: {
				event_name: 'click en guardar titular',
				event_context: 'overlay titular a portar',
				event_category: 'boton',
				event_label: 'guardar titular',
				journey_name: 'tienda prepago',
				journey_category: 'particulares',
				journey_subcategory: 'prepago',
				journey_type: 'transaccional',
				journey_process: 'comprar tarjeta sim prepago',
				product_process_type: 'portabilidad',
				journey_detail: 'tu numero datos del titular',
				journey_step: 'tienda prepago:tu numero datos del titular',
			},
		},
	},
	paymentCards: {
		interactions: {
			clickOnUseAsMainCard: {
				eventName: 'recargas:realizar recarga:tarjetas guardadas:clic en usar como tarjeta principal',
				data: {
					event_name: 'clic en usar como tarjeta principal',
				},
			},
			clickOnDeleteCard: {
				eventName: 'recargas:realizar recarga:tarjetas guardadas:clic en eliminar tarjeta',
				data: {
					event_name: 'clic en eliminar tarjeta',
				},
			},
			clickOnConfirmDeleteCard: {
				eventName: 'recargas:realizar recarga:tarjetas guardadas:eliminar tarjeta:clic en confirmar eliminar tarjeta',
				data: {
					event_name: 'clic en confirmar eliminar tarjeta',
				},
			},
			clickOnCancelDeleteCard: {
				eventName: 'recargas:realizar recarga:tarjetas guardadas:eliminar tarjeta:clic en cancelar eliminar tarjeta',
				data: {
					event_name: 'clic en cancelar eliminar tarjeta',
				},
			},
		},
	},

	vfPassLanding: {
		interactions: {
			clickOnActiveUserCTA: {
				eventName: 'recargas:vodafone pass:beneficios activos:click en descubre vodafone pass',
				data: {
					event_name: 'click en descubre vodafone pass',
				},
			},
			clickOnInctiveUserCTA: {
				eventName: 'recargas:vodafone pass:beneficios no activos:click en descubre vodafone pass',
				data: {
					event_name: 'click en descubre vodafone pass',
				},
			},
		},
	},

	campaignPromotions: {
		page_platform: 'web',
		page: {
			page_name: '',
		},
		data: {
			promo_segment: '',
			promo_plan: '',
		},
		segments: {
			limited: 'no ilimitable',
			unlimited: 'ilimitable',
		},
		plans: {
			on15: 'on15:{segment}',
			on19: 'on19:{segment}',
		},
		pageCases: {
			pageMulticard: 'pageMulticard',
			pageStart: 'pageStart',
			pagePreconfirm: 'pagePreconfirm',
			pageOK: 'pageOK',
			pageKO: 'pageKO',
			pageCompleted: 'pageCompleted',
		},
		pageMulticard: {
			page_name: 'promocion:delight:<code>',
			page_section: 'promocion',
			page_subcategory_level_1: 'delight',
			page_subcategory_level_2: 'seleccion novedad',
			page_screen: 'seleccion novedad',
		},
		pageStart: {
			page_name: 'promocion:delight:<code>',
			page_section: 'promocion',
			page_subcategory_level_1: 'delight',
			page_subcategory_level_2: '<code>',
			page_screen: '<code>',
		},
		pagePreconfirm: {
			page_name: 'promocion:delight:<code>:preconfirmacion',
			page_section: 'promocion',
			page_subcategory_level_1: 'delight',
			page_subcategory_level_2: '<code>',
			page_subcategory_level_3: 'preconfirmacion',
			page_screen: 'preconfirmacion',
		},
		pageOK: {
			page_name: 'promocion:delight:<code>:confirmacion',
			page_section: 'promocion',
			page_subcategory_level_1: 'delight',
			page_subcategory_level_2: '<code>',
			page_subcategory_level_3: 'confirmacion',
			page_screen: 'confirmacion',
		},
		pageKO: {
			page_name: 'promocion:delight:<code>:error',
			page_section: 'promocion',
			page_subcategory_level_1: 'delight',
			page_subcategory_level_2: '<code>',
			page_subcategory_level_3: 'error',
			page_screen: 'error',
			error_category: '',
			error_description: '',
			error_type: '',
		},
		pageExchange: {
			page_name: 'promocion:delight:<code>:canjear codigo',
			page_section: 'promocion',
			page_subcategory_level_1: 'delight',
			page_subcategory_level_2: '<code>',
			page_subcategory_level_3: 'canjear codigo',
			page_screen: 'canjear codigo',
		},
		pageCompleted: {
			page_name: 'promocion:delight:<code>:<completeCase>',
			page_section: 'promocion',
			page_subcategory_level_1: 'delight',
			page_subcategory_level_2: '<code>',
			page_subcategory_level_3: '<completeCase>',
			page_screen: '<completeCase>',
		},
		journeyCases: {
			bf: 'promo bf',
			journeyStart: 'journeyStart',
			journeyPreconfirm: 'journeyPreconfirm',
			journeyOK: 'journeyOK',
			journeyKO: 'journeyKO',
		},
		journeyStart: {
			journey_name: '<action>:inicio',
			journey_category: '<action>',
			journey_subcategory: 'delight',
			journey_options: '<code>',
			journey_type: '<type>',
		},
		journeyPreconfirm: {
			journey_name: '<action>:preconfirmacion',
			journey_category: '<action>',
			journey_subcategory: 'delight',
			journey_options: '<code>',
			journey_type: '<type>',
		},
		journeyConfirm: {
			journey_step: '<action>:confirmacion',
		},
		journeyOK: {
			journey_name: '<action>:ok',
			journey_category: '<action>',
			journey_subcategory: 'delight',
			journey_options: '<code>',
			journey_type: '<type>',
		},
		journeyKO: {
			journey_name: '<action>:ko',
			journey_category: '<action>',
			journey_subcategory: 'delight',
			journey_options: '<code>',
			journey_type: '<type>',
			journey_error_category: '',
			journey_error_description: '',
			journey_error_type: '',
		},
		journeyActionCases: {
			activate: 'activar <code>',
			third_party: 'contenido a terceros',
			pass: 'vodafone pass',
		},
		journeyTypeCases: {
			promotion: 'promocion',
			contract: 'contratacion',
			buy: 'compra',
		},
		entrypointdata: {
			entrypoint_section: 'dashboard',
			entrypoint_location: '<location>',
			entrypoint_position: 'undefined',
			entrypoint_title: '<copy>:<code>',
			journey_name: '<journey_name>',
			experience_name: '<experience_name>',
		},
		entrypointdataList: {
			'entrypoint_list': '<list>',
			'&&events': '{0}',
		},
		entrypointdataMultiCard: {
			'entrypoint_section': 'promocion',
			'entrypoint_location': 'boton promocion',
			'entrypoint_position': '{0}',
			'&&events': 'event70',
		},
		entryPointLocationCases: {
			welcome: 'banner bienvenida',
			overlay: 'overlay',
			custom: 'banner personalizacion 1',
			counter: 'banner contador',
			section: 'dashboard',
			journey_name: 'gestion de bonos',
		},
		completeCases: {
			counter: 'contador',
			voucher_pending: 'ya tienes tu codigo',
			voucher_active: 'consulta promoción',
			end_of_promo: 'fin de promocion',
			end_of_stock: 'fin de stock',
			activation: 'activacion',
			warning: 'aviso estas seguro',
			discount: 'descuento sin activar',
			congrats: 'enhorabuena',
			any_bookable_pack: 'ya tienes todos los packs',
		},
		events: {
			event_name: '',
		},
		promotionChecks: {
			event: {
				event_name: 'click en checkbox',
			},
			checks: {
				datosFacturacion: 'checkDatosFacturacion',
				comunicaciones: 'checkComunicacionesComerciales',
			},
		},
		cta_names: {
			generic: 'click en {text}',
			promo: 'promo {code}',
			counter: 'delight contador pasarme a ',
			close: 'aspa',
			closeButton: 'cerrar',
			share: 'compartir',
			conditions: 'condiciones',
			unavailabe: 'No disponible',
			copy: 'copiar codigo',
			button: 'saber mas',
			select: 'seleccion novedad',
		},
		flipCard: {
			iteraction: {
				title: 'iteraction',
				click: 'entrypoint',
			},
			event_impresion: 'dashboard:home:impresiones entrypoint',
			data: {
				page_name: 'dashboard:home',
				page_section: 'dashboard',
				page_subcategory_level_1: 'home',
				page_screen: 'home',
			},
			impresion: {
				'event_name': 'impresiones entrypoint',
				'&&events': 'event75',
				'entrypoint_list': 'es=dashboard|el=banner bienvenida|jn=gestion de bonos|et={et}',
			},
			inactive_text: 'añade datos ilimitados los días que quieras desde 5€',
		},
	},
	simplePromo: {
		page: {
			page_name: 'promocion:delight:{content}',
			event_name: '',
			page_section: 'promocion',
			page_subcategory_level_1: 'delight',
			page_subcategory_level_2: '',
			page_screen: '',
		},
		event: {
			event_name: 'click en promo {content} {copy}',
		},
		link: {
			event_name: 'click en link {link}',
			page_name: 'promocion:delight:content',
			page_section: 'promocion',
			page_subcategory_level_1: 'delight',
			page_subcategory_level_2: 'content',
			page_screen: 'content',
		},
		button: {
			event_name: 'click en boton {boton}',
			page_name: 'promocion:delight:content',
			page_section: 'promocion',
			page_subcategory_level_1: 'delight',
			page_subcategory_level_2: 'content',
			page_screen: 'content',
		},
	},
	// TODO
	DiscountRenewalJourneys: {
		home: {
			page: {
				page_name: '',
				page_section: 'transaccional',
				page_subcategory_level_1: 'renovacion descuento',
				page_screen: 'oferta',
				page_typology: 'principal',
			},
			data: {
				promotion_code: '',
				client_origin: '',
			},
		},
		event: {
			'event_name': '',
			'event_category': '',
			'event_context': '',
			'event_label': '',
			'&&events': '',
		},
		typeEvents: {
			offer: 'oferta',
			click: {
				event_name: 'click en el acordeon permanencias',
				event_category: 'acordeon',
				event_context: 'permanencias renovacion descuento',
			},
			confirm: {
				event_name: 'confirmar renovacion',
				event_category: 'boton',
				event_context: 'confirmar renovacion descuento',
			},
			reject: {
				event_name: 'rechazar renovacion',
				event_category: 'enlace',
				event_context: 'rechazar renovacion descuento',
			},
			exit: {
				event_name: 'click en cerrar',
				event_category: 'boton',
				event_context: 'renovacion descuento',
				event_label: 'cerrar',
			},
			clickConfirmCTA: {
				event_name: 'click en ir a mis pedidos',
				event_category: 'boton',
				event_context: 'renovacion descuento',
				event_label: '',
			},
			clickLinkEmail: {
				event_name: 'click en modificar email',
				event_category: 'enlace',
				event_context: 'renovacion descuento',
				event_label: '',
			},
			clickEmailCTA: {
				event_name: 'click en confirmar email',
				event_category: 'boton',
				event_context: 'renovacion descuento',
				event_label: '',
			},
		},
		journey: {
			journey_name: 'renovacion descuento',
			journey_type: 'transaccional',
			journey_category: '',
			journey_subcategory: '',
			journey_process: '',
			journey_detail: '',
		},
		transactional: {
			'&&products':
				'renovacion descuento;<product_name>;1;;;eVar181=<product_msisdn>|eVar182=<product_code>|eVar184=<product_promotion_name>|eVar207=<product_promotion_code>',
			'purchaseID': '',
			'new_discount_code': '',
			'discount_expiration': '',
			'discount_value_change': '',
		},
		deepLink: {
			page: {
				page_name: '',
				page_section: 'transaccional',
				page_subcategory_level_1: 'renovacion descuento',
				page_screen: 'oferta',
				page_typology: 'principal',
			},
			data: {
				'entrypoint_section': 'deepLink',
				'entrypoint_location': 'undefined',
				'entrypoint_position': 'undefined',
				'entrypoint_title': 'undefined',
				'experience_name': 'undefined',
				'entrypoint_name': '',
				'entrypoint_issue': '',
				'entrypoint_label': '',
				'entrypoint_groupnba': '',
				'journey_name': '{journey}',
				'&&events': 'event70',
				'promotion_code': '',
				'client_origin': '',
				'entrypoint_type': '',
			},
		},
		entryPointsError: {
			page: {
				page_name: '',
				page_section: 'purchase',
				page_subcategory_level_1: '{journey}',
				page_screen: 'error de entrypoint',
			},
			data: {
				journey_name: '{journey}',
				promotion_code: '',
			},
			deepLinkData: {
				entrypoint_section: 'deepLink',
				entrypoint_location: 'undefined',
				entrypoint_position: 'undefined',
				entrypoint_title: 'undefined',
				experience_name: 'undefined',
				journey_name: '{journey}',
				promotion_code: '',
			},
		},
		clickConfirmRenew: {
			page: {
				page_name: '',
				page_section: 'transaccional',
				page_subcategory_level_1: 'renovacion descuento',
				page_screen: 'confirmacion',
				page_typology: 'principal',
			},
			events: {
				'&&events': 'purchase,event28',
			},
		},
		clickRejectRenew: {
			page: {
				page_name: 'purchase:cpp:home',
				page_section: 'purchase',
				page_subcategory_level_1: 'cpp',
				page_screen: 'home',
			},
			data: {
				event_name: 'rechazar renovacion',
				promotion_code: '',
				client_origin: '',
			},
		},
		acceptedRenew: {
			page: {
				page_name: 'purchase:cpp:confirmacion',
				page_section: 'purchase',
				page_subcategory_level_1: 'cpp',
				page_screen: 'confirmacion',
			},
			data: {
				'&&events': 'purchase',
				'purchaseID': null,
				'promotion_code': '',
				'client_origin': '',
			},
		},
		rejectedRenew: {
			page: {
				page_name: 'purchase:cpp:confirmacion',
				page_section: 'purchase',
				page_subcategory_level_1: 'cpp',
				page_screen: 'confirmacion',
			},
			data: {
				event_name: 'ko',
				promotion_code: '',
				client_origin: '',
			},
		},
		mailRequestRenew: {
			page: {
				page_name: '',
				page_section: 'transaccional',
				page_subcategory_level_1: 'renovacion descuento',
				page_screen: 'solicitud email',
				page_typology: 'principal',
			},
		},
		mailConfirmationRenew: {
			page: {
				page_name: 'purchase:cpp:solicitud email',
				page_section: 'purchase',
				page_subcategory_level_1: 'cpp',
				page_screen: 'solicitud email',
			},
			data: {
				event_name: 'confirmar email',
				promotion_code: '',
				client_origin: '',
			},
		},
		error: {
			tecnicalError: {
				page: {
					page_name: 'transaccional:renovacion descuento:error',
					page_section: 'transaccional',
					page_subcategory_level_1: 'renovacion descuento',
					page_screen: 'error',
					page_typology: 'principal',
				},
			},
			funtionError: {
				page: {
					page_name: 'transaccional:renovacion descuento:error funcional',
					page_section: 'transaccional',
					page_subcategory_level_1: 'renovacion descuento',
					page_screen: 'error',
					page_typology: 'principal',
				},
			},
			data: {
				error_category: '',
				error_description: '',
				error_type: '',
				error_code: '',
			},
		},
		backdropDL: {
			eventClickCTA: {
				event_name: 'click en descubrir ofertas',
				event_category: 'boton',
				event_context: 'mas ofertas deeplink pega',
				event_label: '',
			},
			eventView: {
				event_name: 'visualizacion backdrop deeplink',
				event_category: 'backdrop',
				event_context: 'deeplink pega',
				event_label: '',
			},
		},
	},
	// TODO
	onePlus: {
		vProductPurchase: {
			landingRequest: {
				page_name: 'productos y servicios:{type}:solicitar',
				page_section: 'productos y servicios',
				page_subcategory_level_1: '{type}',
				page_subcategory_level_2: 'solicitar',
				page_screen: 'solicitar',
				journey_name: 'solicitar iot:inicio',
				journey_category: 'solicitar iot',
				journey_subcategory: '{package}',
				journey_options: '{type}',
				jouney_type: 'compra',
			},
			landingShipping: {
				page_name: 'productos y servicios:{type}:envio',
				page_section: 'productos y servicios',
				page_subcategory_level_1: '{type}',
				page_subcategory_level_2: 'envio',
				page_screen: 'envio',
				journey_category: 'solicitar iot',
				journey_subcategory: '{package}',
				journey_options: '{type}',
				jouney_type: 'compra',
			},
			landingResume: {
				page_name: 'productos y servicios:{type}:resumen:{state}',
				page_section: 'productos y servicios',
				page_subcategory_level_1: '{type}',
				page_subcategory_level_2: 'resumen',
				page_subcategory_level_3: '{state}',
				page_screen: '{state}',
				journey_name: 'solicitar iot:preconfirmacion',
				journey_category: 'solicitar iot',
				journey_subcategory: '{package}',
				journey_options: '{type}',
				jouney_type: 'compra',
			},
			eventLanding: {
				action: 'productos y servicios:{type}:solicitar:click en {copy}',
				object: {
					event_name: 'click en {copy}',
					page_name: 'productos y servicios:{type}:solicitar',
					page_section: 'productos y servicios',
					page_subcategory_level_1: '{type}',
					page_subcategory_level_2: 'solicitar',
					page_screen: 'solicitar',
					journey_category: 'solicitar iot',
					journey_subcategory: '{package}',
					journey_options: '{type}',
					jouney_type: 'compra',
				},
			},
			eventShipping: {
				action: 'productos y servicios:{type}:envio:click en {copyTab}:{copyButton}',
				object: {
					event_name: 'click en {copyTab}:{copyButton}',
					page_name: 'productos y servicios:{type}:envio',
					page_section: 'productos y servicios',
					page_subcategory_level_1: '{type}',
					page_subcategory_level_2: 'envio',
					page_screen: 'envio',
					journey_category: 'solicitar iot',
					journey_subcategory: '{package}',
					journey_options: '{type}',
					jouney_type: 'compra',
				},
			},
			eventResume: {
				action: 'productos y servicios:{type}:resumen:preconfirmacion:click en {copyTab}:{copyButton}',
				object: {
					event_name: 'click en {copyTab}:{copyButton}',
					page_name: 'productos y servicios:{type}:resumen:preconfirmacion',
					page_section: 'productos y servicios',
					page_subcategory_level_1: '{type}',
					page_subcategory_level_2: 'resumen',
					page_subcategory_level_3: 'preconfirmacion',
					page_screen: 'preconfirmacion',
					journey_category: 'solicitar iot',
					journey_subcategory: '{package}',
					journey_options: '{type}',
					jouney_type: 'compra',
				},
			},
			alertExitJourney: {
				page_name: 'productos y servicios:{type}:resumen:alerta salida jorney',
				page_section: 'productos y servicios',
				page_subcategory_level_1: '{type}',
				page_subcategory_level_2: 'resumen',
				page_subcategory_level_3: 'alerta salida jorney',
				page_screen: 'alerta salida jorney',
				journey_category: 'solicitar iot',
				journey_subcategory: '{package}',
				journey_options: '{type}',
				jouney_type: 'compra',
			},
			orderProccesing: {
				page_name: 'productos y servicios:{type}:resumen:procesar',
				page_section: 'productos y servicios',
				page_subcategory_level_1: '{type}',
				page_subcategory_level_2: 'resumen',
				page_subcategory_level_3: 'procesar',
				page_screen: 'procesar',
				journey_category: 'solicitar iot',
				journey_subcategory: '{package}',
				journey_options: '{type}',
				jouney_type: 'compra',
			},
			states: {
				preconfirmacion: 'preconfirmacion',
				confirmacion: 'confirmacion',
				error: 'error',
				errorObject: {
					error_category: '',
					error_description: '',
					error_type: '',
					error_code: '',
				},
			},
		},
		oneNumberSingleSignOnOverlayGenericError: {
			view: {
				asset_name: 'mivoweb',
				page_screen: 'configura o conecta un dispositivo:error',
				page_name: 'mi vodafone:que tengo contratado:servicio extra:onenumber:configura o conecta un dispositivo:error',
				page_section: 'mi vodafone',
				page_subcategory_level_1: 'que tengo contratado',
				page_subcategory_level_2: 'servicio extra',
				page_subcategory_level_3: 'onenumber',
				page_subcategory_level_4: 'configura o conecta un dispositivo:error',
				navigation_level_1: 'que tengo contratado',
				navigation_level_2: 'servicio extra',
				navigation_level_3: 'onenumber',
				navigation_level_4: 'configura o conecta un dispositivo:error',
				error_type: 'tecnico',
				error_page:
					'mi vodafone:que tengo contratado:servicio extra:onenumber:configura o conecta un dispositivo:error',
			},
			closebtn: {
				asset_name: 'mivoweb',
				page_screen: 'configura o conecta un dispositivo:error',
				page_name: 'mi vodafone:que tengo contratado:servicio extra:onenumber:configura o conecta un dispositivo:error',
				page_section: 'mi vodafone',
				page_subcategory_level_1: 'que tengo contratado',
				page_subcategory_level_2: 'servicio extra',
				page_subcategory_level_3: 'onenumber',
				page_subcategory_level_4: 'configura o conecta un dispositivo:error',
				navigation_level_1: 'que tengo contratado',
				navigation_level_2: 'servicio extra',
				navigation_level_3: 'onenumber',
				navigation_level_4: 'configura o conecta un dispositivo:error',
				event_name: 'click en cerrar',
				event_category: 'boton',
				event_context: 'configura o conecta un dispositivo error',
				event_label: 'cerrar',
			},
			primarybtn: {
				asset_name: 'mivoweb',
				page_screen: 'configura o conecta un dispositivo:error',
				page_name: 'mi vodafone:que tengo contratado:servicio extra:onenumber:configura o conecta un dispositivo:error',
				page_section: 'mi vodafone',
				page_subcategory_level_1: 'que tengo contratado',
				page_subcategory_level_2: 'servicio extra',
				page_subcategory_level_3: 'onenumber',
				page_subcategory_level_4: 'configura o conecta un dispositivo:error',
				navigation_level_1: 'que tengo contratado',
				navigation_level_2: 'servicio extra',
				navigation_level_3: 'onenumber',
				navigation_level_4: 'configura o conecta un dispositivo:error',
				event_name: 'click en entendido',
				event_category: 'boton',
				event_context: 'configura o conecta un dispositivo error',
				event_label: 'entendido',
			},
		},
		vodafoneOneNumber: {
			view: {
				asset_name: 'mivoweb',
				page_screen: 'conecta un dispositivo',
				page_name: 'mi vodafone:que tengo contratado:servicio extra:onenumber:configura o conecta un dispositivo',
				page_section: 'mi vodafone',
				page_subcategory_level_1: 'que tengo contratado',
				page_subcategory_level_2: 'servicio extra',
				page_subcategory_level_3: 'onenumber',
				page_subcategory_level_4: 'configura o conecta un dispositivo',
				navigation_level_1: 'que tengo contratado',
				navigation_level_2: 'servicio extra',
				navigation_level_3: 'onenumber',
				navigation_level_4: 'configura o conecta un dispositivo',
			},
		},
		oneNumberActivation: {
			state: {
				'page_name': 'productos y servicios:onenumber:solicitar',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'onenumber',
				'page_subcategory_level_2': 'solicitar',
				'navigation_level_1': 'productos y servicios',
				'navigation_level_2': 'onenumber',
				'navigation_level_3': 'solicitar',
				'page_screen': 'solicitar',
				'journey_name': 'iot',
				'journey_category': 'particular contrato',
				'journey_subcategory': '{package}:pospago',
				'journey_options': 'onenumber',
				'journey_type': 'servicio',
				'journey_process': 'activar',
				'journey_step': 'iot:start',
				'&&events': 'event10',
			},
			faqEvent: {
				action: 'productos y servicios:onenumber:solicitar:click en preguntas frecuentes',
				object: {
					page_name: 'productos y servicios:onenumber:solicitar',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'onenumber',
					page_subcategory_level_2: 'solicitar',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: 'onenumber',
					navigation_level_3: 'solicitar',
					page_screen: 'solicitar',
					asset_name: 'mivoweb',
					page_platform: 'web',
					event_name: 'click en card {copy}',
					event_category: 'card',
					event_context: 'saber mas',
					event_label: '{copy}',
					journey_name: 'iot',
					journey_category: 'particular contrato',
					journey_subcategory: '{package}:pospago',
					journey_options: 'onenumber',
					journey_process: 'activar',
					journey_type: 'servicio',
				},
			},
			activateEvent: {
				action: 'productos y servicios:onenumber:solicitar:click en activar',
				object: {
					'page_name': 'productos y servicios:onenumber:solicitar',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'onenumber',
					'page_subcategory_level_2': 'solicitar',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': 'onenumber',
					'navigation_level_3': 'solicitar',
					'page_screen': 'solicitar',
					'asset_name': 'mivoweb',
					'page_platform': 'web',
					'event_name': 'click en {copy}',
					'event_category': 'boton',
					'event_context': 'activar',
					'event_label': '{copy}',
					'journey_name': 'iot',
					'journey_category': 'particular contrato',
					'journey_subcategory': '{package}:pospago',
					'journey_options': 'onenumber',
					'journey_process': 'activar',
					'journey_step': 'iot:preconfirmacion',
					'journey_type': 'servicio',
					'&&events': 'event16',
				},
			},
			card: {
				object: {
					'page_name': 'productos y servicios:resumen de productos y servicios',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'resumen de productos y servicios',
					'page_screen': 'resumen de productos y servicios',
					'event_name': 'click en entrypoint',
					'&&events': 'event70',
					'asset_name': 'mivoweb',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': 'resumen de productos y servicios',
					'event_category': 'boton',
					'event_context': 'productos y servicios',
					'event_label': 'click entrypoint',
					'entrypoint_section': 'pys',
					'entrypoint_location': 'card producto',
					'entrypoint_position': 'undefined',
					'entrypoint_title': 'onenumber {type}',
					'journey_name': 'iot',
					'entrypoint_type': 'normal',
					'experience_name': 'undefined',
				},
				type: {
					activar: 'activar',
					gestion: 'gestion',
				},
			},
		},
		oneNumberLanding: {
			state: {
				'page_name': 'productos y servicios:onenumber:configurar',
				'page_section': 'productos y servicios',
				'page_subcategory_level_1': 'onenumber',
				'page_subcategory_level_2': 'configurar',
				'page_screen': 'configurar',
				'journey_name': 'iot',
				'journey_category': '{client_typology}',
				'journey_subcategory': '{package}',
				'journey_options': 'onenumber',
				'journey_type': 'servicio',
				'journey_process': 'gestion',
				'journey_detail': 'start',
				'journey_step': 'iot:start',
				'asset_name': 'mivoweb',
				'navigation_level_1': 'productos y servicios',
				'navigation_level_2': 'onenumber',
				'navigation_level_3': 'configurar',
				'&&events': 'event10',
			},
			faqEvent: {
				action: 'productos y servicios:onenumber:preconfirmacion:click en preconfirmacion pregunta {copy}',
				object: {
					page_name: 'productos y servicios:onenumber:preconfirmacion',
					event_name: 'click en preconfirmacion pregunta {copy}',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'onenumber',
					page_subcategory_level_2: 'preconfirmacion',
					page_screen: 'preconfirmacion',
					journey_category: 'solicitar iot',
					journey_subcategory: '{package}',
					journey_options: 'onenumber',
					journey_type: 'compra',
				},
			},
			clickEventConnectNewDevice: {
				action: 'productos y servicios:onenumber:preconfirmacion:click en configurar {copy}',
				object: {
					page_name: 'productos y servicios:onenumber:configurar',
					event_name: 'click en {copy}',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'onenumber',
					page_subcategory_level_2: 'configurar',
					asset_name: 'mivoweb',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: 'onenumber',
					navigation_level_3: 'configurar',
					page_screen: 'configurar',
					journey_name: 'iot',
					journey_category: '{client_typology}',
					journey_subcategory: '{package}',
					journey_options: 'onenumber',
					journey_type: 'servicio',
					journey_process: 'gestion',
					event_category: 'boton',
					event_context: 'activar',
					event_label: '{copy}',
				},
			},
			clickEventConfigureOneNumber: {
				action: 'productos y servicios:onenumber:preconfirmacion:click en configurar {copy}',
				object: {
					'page_name': 'productos y servicios:onenumber:configurar',
					'event_name': 'click en {copy}',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': 'onenumber',
					'page_subcategory_level_2': 'configurar',
					'asset_name': 'mivoweb',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': 'onenumber',
					'navigation_level_3': 'configurar',
					'page_screen': 'configurar',
					'journey_name': 'iot',
					'journey_category': '{client_typology}',
					'journey_subcategory': '{package}',
					'journey_options': 'onenumber',
					'journey_type': 'servicio',
					'journey_process': 'gestion',
					'journey_detail': 'preconfirmacion',
					'journey_step': 'iot:preconfirmacion',
					'event_category': 'link',
					'event_context': 'configurar',
					'event_label': '{copy}',
					'&&events': 'event16',
				},
			},
		},
		oneNumberExitOverlay: {
			overlay_exit: {
				page_name: 'productos y servicios:onenumber:exit',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'onenumber',
				page_subcategory_level_2: 'exit',
				navigation_level_1: 'productos y servicios',
				navigation_level_2: 'onenumber',
				navigation_level_3: 'exit',
				page_screen: 'exit',
				asset_name: 'mivoweb',
				page_platform: 'web',
				event_name: '',
				journey_name: 'iot',
				journey_type: 'servicio',
				journey_category: '',
				journey_subcategory: '',
				journey_process: 'activar',
				journey_detail: 'exit',
				journey_step: '<journey_name>:exit',
				journey_product: 'onenumber',
			},
			overlay_exit_continue: {
				'event_name': 'continuar',
				'event_category': 'boton',
				'event_label': 'continuar',
				'&&events': 'event106',
			},
			overlay_exit_leave: {
				'event_name': 'abandonar',
				'event_category': 'boton',
				'event_label': 'abandonar',
				'&&events': 'event105',
			},
		},
		comparator: {
			subcategory_comparator: 'comparador',
			type: 'hogar ilimitable',
			sectionComparator: {
				action: 'productos y servicios:{type}:comparador:click en {copy}',
				object: {
					event_name: 'click en {copy}',
					page_name: 'productos y servicios:{type}:comparador',
					page_section: 'productos y servicios',
					page_subcategory_level_1: '{type}',
					page_subcategory_level_2: 'comparador',
					page_screen: 'comparador',
				},
				state: {
					page_name: 'productos y servicios:{type}:saber mas',
					page_section: 'productos y servicios',
					page_subcategory_level_1: '{type}',
					page_subcategory_level_2: 'saber mas',
					page_screen: 'saber mas',
				},
			},
			modalComparator: {
				action: 'productos y servicios:{type}:activar:salir:click en {copy}',
				object: {
					event_name: 'click en {copy}',
					page_name: 'productos y servicios:{type}:activar:salir',
					page_section: 'productos y servicios',
					page_subcategory_level_1: '{type}',
					page_subcategory_level_2: 'activar',
					page_subcategory_level_3: 'salir',
					page_screen: 'salir',
				},
				state: {
					page_name: 'productos y servicios:{type}:activar:salir',
					page_section: 'productos y servicios',
					page_subcategory_level_1: '{type}',
					page_subcategory_level_2: 'activar',
					page_subcategory_level_3: 'salir',
					page_screen: 'salir',
				},
			},
			comparatorActionBtn: {
				action: 'productos y servicios:{type}:comparador:click en {copy}',
				object: {
					page_name: 'productos y servicios:{type}:comparador',
					page_section: 'productos y servicios',
					page_subcategory_level_1: '{type}',
					page_subcategory_level_2: 'comparador',
					asset_name: 'mivoweb',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: '{type}',
					navigation_level_3: 'comparador',
					page_screen: 'comparador',
					event_name: 'click en {copy}',
					event_category: 'boton',
					event_context: 'migracion',
					event_label: '{copy}',
					journey_name: '{type}',
					journey_type: 'transaccional',
					journey_category: '{value}',
					journey_subcategory: '{type} {package}:{siteType}',
					journey_process: 'activar',
					journey_detail: 'migracion',
					journey_step: '',
					journey_packs: '{promoCopy}',
					journey_options: '',
					journey_type_plan: '{type} {package}',
					journey_product: '',
					page_typology: 'principal',
				},
			},
			summaryActionBtn: {
				object: {
					page_name: 'productos y servicios:{type}:comparador:resumen del paquete',
					page_section: 'productos y servicios',
					page_subcategory_level_1: '{type}',
					page_subcategory_level_2: 'comparador',
					page_subcategory_level_3: 'resumen del paquete',
					asset_name: 'mivoweb',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: '{type}',
					navigation_level_3: 'comparador',
					navigation_level_4: 'resumen del paquete',
					page_screen: 'resumen del paquete',
					event_name: 'click en {copy}',
					event_category: 'boton',
					event_context: 'migracion',
					event_label: '{copy}',
					journey_name: '{type}',
					journey_type: 'transaccional',
					journey_category: '{value}',
					journey_subcategory: '{type} {package}:{siteType}',
					journey_process: 'activar',
					journey_detail: 'migracion',
					journey_step: '',
					journey_packs: '{promoCopy}',
					journey_options: '',
					journey_type_plan: '{type} {package}',
					journey_product: '',
					page_typology: 'overlay',
				},
			},
			summaryViewModal: {
				action: 'productos y servicios:{type}:comparador:click en {copy}',
				object: {
					'page_name': 'productos y servicios:{type}:comparador:resumen del paquete',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': '{type}',
					'page_subcategory_level_2': 'comparador',
					'page_subcategory_level_3': 'resumen del paquete',
					'asset_name': 'mivoweb',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': '{type}',
					'navigation_level_3': 'comparador',
					'navigation_level_4': 'resumen del paquete',
					'page_screen': 'resumen del paquete',
					'&&events': 'event10',
					'journey_name': '{type}',
					'journey_type': 'transaccional',
					'journey_category': '{value}',
					'journey_subcategory': '{type} {package}:{siteType}',
					'journey_process': 'activar',
					'journey_detail': 'migracion',
					'journey_step': '',
					'journey_packs': '{promoCopy}',
					'journey_options': '',
					'journey_type_plan': '{type} {package}',
					'journey_product': '',
					'page_typology': 'overlay',
				},
			},
			activationView: {
				object: {
					'page_name': 'productos y servicios:{type}:resumen',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': '{type}',
					'page_subcategory_level_2': 'resumen',
					'page_screen': 'resumen',
					'asset_name': 'mivoweb',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': '{type}',
					'navigation_level_3': 'resumen',
					'&&events': 'event10',
					'journey_name': '{type}',
					'journey_category': '{value}',
					'journey_subcategory': '{type} {package}:{siteType}',
					'journey_options': '',
					'journey_type': 'transaccional',
					'journey_process': 'activar',
					'journey_detail': 'migracion',
					'journey_step': '',
					'journey_packs': '{promoCopy}',
					'journey_type_plan': '{type} {package}',
					'journey_product': '',
					'page_typology': 'principal',
				},
			},
			activationButtonSummary: {
				object: {
					'event_name': 'click en {copy}',
					'event_category': 'boton',
					'event_context': 'migracion',
					'event_label': '{copy}',
					'page_name': 'productos y servicios:{type}:resumen',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': '{type}',
					'page_subcategory_level_2': 'resumen',
					'page_screen': 'resumen',
					'asset_name': 'mivoweb',
					'journey_name': '{type}',
					'journey_category': '{value}',
					'journey_options': '',
					'journey_type': 'transaccional',
					'journey_process': 'activar',
					'journey_packs': '{promoCopy}',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': '{type}',
					'navigation_level_3': 'resumen',
					'&&events': 'event16',
					'journey_subcategory': '{type} {package}:{siteType}',
					'journey_detail': 'migracion',
					'journey_step': '',
					'journey_type_plan': '{type} {package}',
					'journey_product': '',
					'page_typology': 'principal',
				},
			},
			closeModalMigration: {
				action: 'productos y servicios:{type}:consulta:salir:click en {copy}',
				object: {
					event_name: 'click en {copy}',
					page_name: 'productos y servicios:{type}:consulta:salir',
					page_section: 'productos y servicios',
					page_subcategory_level_1: '{type}',
					page_subcategory_level_2: 'consulta',
					page_subcategory_level_3: 'salir',
					page_screen: 'salir',
				},
				state: {
					page_name: 'productos y servicios:{type}:consulta:salir',
					page_section: 'productos y servicios',
					page_subcategory_level_1: '{type}',
					page_subcategory_level_2: 'consulta',
					page_subcategory_level_3: 'salir',
					page_screen: 'salir',
					page_platform: 'web',
				},
			},
			migrationWithRecomender: {
				state: {
					'page_name': 'productos y servicios:{type}:comparador',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': '{type}',
					'page_subcategory_level_2': 'comparador',
					'page_screen': 'comparador',
					'journey_name': '{type}',
					'journey_category': '{value}',
					'journey_subcategory': 'pospago',
					'journey_options': 'ilimitada plus {package}',
					'journey_type': 'transaccional',
					'journey_step': '{type}:start',
					'journey_process': 'activar',
					'&&events': 'event10',
				},
			},
			startMigration: {
				pagePreconfirmation: {
					'page_name': 'productos y servicios:{type}:preconfirmacion',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': '{type}',
					'page_subcategory_level_2': 'preconfirmacion',
					'page_screen': 'preconfirmacion',
					'journey_name': '{type}',
					'journey_category': '{value}',
					'journey_subcategory': 'pospago',
					'journey_options': 'hogar ilimitable {package}',
					'journey_type': 'transaccional',
					'journey_step': '{type}:preconfirmacion',
					'journey_process': 'activar',
					'&&events': 'event16',
				},
				pageOK: {
					'page_name': 'productos y servicios:{type}:confirmacion',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': '{type}',
					'page_subcategory_level_2': 'confirmacion',
					'page_screen': 'confirmacion',
					'journey_name': '{type}',
					'journey_category': '{value}',
					'journey_subcategory': 'pospago',
					'journey_options': 'hogar ilimitable {package}',
					'journey_type': 'transaccional',
					'journey_step': '{type}:ok',
					'journey_process': 'activar',
					'&&events': 'event11',
				},
				pageKO: {
					'asset_name': 'mivoweb',
					'navigation_level_1': 'productos y servicios',
					'navigation_level_2': '{type}',
					'navigation_level_3': 'error',
					'event_name': 'error {type}',
					'page_name': 'productos y servicios:{type}:error',
					'page_section': 'productos y servicios',
					'page_subcategory_level_1': '{type}',
					'page_subcategory_level_2': 'error',
					'page_screen': 'error',
					'journey_name': '{type}',
					'journey_category': '{value}',
					'journey_subcategory': 'pospago',
					'journey_options': 'hogar ilimitable {package}',
					'journey_type': 'transaccional',
					'journey_step': '{type}:ko',
					'journey_process': 'activar',
					'&&events': 'event12,event100',
				},
			},
			typeClient: {
				'x2': 'x2',
				'20': '20',
				'30': '30',
				'40': '40',
			},
			typeClientPx2: {
				0: '',
				1: '',
				2: 'x2',
				3: 'x3',
				4: 'x4',
			},
			typeClientHiu: {
				0: '',
				1: '',
				2: '20',
				3: '30',
				4: '40',
			},
		},
		landingPurchase: {
			state: {
				'page_name': 'que tengo contratado:resumen que tengo contratado',
				'page_section': 'que tengo contratado',
				'page_subcategory_level_1': 'resumen que tengo contratado',
				'page_screen': 'resumen que tengo contratado',
				'event_name': 'click en entrypoint',
				'&&events': 'event70',
				'journey_name': 'iot',
			},
			pendingActivationBanner: {
				page_name: 'que tengo contratado:resumen que tengo contratado:activaciones pendientes',
				page_subcategory_level_2: 'activaciones pendientes',
				page_screen: 'activaciones pendientes',
				navigation_level_1: 'que tengo contratado',
				navigation_level_2: 'resumen que tengo contratado',
				navigation_level_3: 'activaciones pendientes',
				asset_name: 'mivoweb',
			},
			tabClick: {
				event_name: 'click en tab:{tab}',
			},
			additionalLinesView: {
				event_name: 'impresiones entrypoint',
			},
			productClick: {
				'event_name': 'click en entrypoint',
				'entrypoint_section': 'que tengo contratado',
				'entrypoint_location': 'boton {0}',
				'entrypoint_position': '{0}',
				'entrypoint_title': '',
				'&&events': 'event70',
				'journey_name': '',
				'navigation_level_1': 'que tengo contratado',
				'navigation_level_2': 'resumen que tengo contratado',
				'asset_name': 'mivoweb',
			},
			oneNumberLandingClick: {
				'page_name': 'que tengo contratado:resumen que tengo contratado',
				'page_section': 'que tengo contratado',
				'page_subcategory_level_1': 'resumen que tengo contratado',
				'asset_name': 'mivoweb',
				'navigation_level_1': 'resumen de productos y servicios',
				'page_screen': 'resumen de productos y servicios',
				'event_name': 'click en entrypoint',
				'event_category': 'boton',
				'event_context': 'que tengo contratado',
				'event_label': 'click entrypoint',
				'&&events': 'event70',
				'entrypoint_section': 'que tengo contratado',
				'entrypoint_location': '{{typeOnePlus}}',
				'entrypoint_position': 'undefined',
				'entrypoint_title': '{{name}}',
				'experience_name': 'undefined',
				'entrypoint_type': 'normal',
				'journey_name': 'iot',
			},
			click: {
				pay: {
					postpaid: 'Postpaid',
					pospago: 'pospago',
					prepago: 'prepago',
				},
				eventName: 'que tengo contratado:home:click en entrypoint',
				data: {
					'event_name': 'click en entrypoint',
					'page_name': 'que tengo contratado:resumen que tengo contratado',
					'page_section': 'que tengo contratado',
					'page_subcategory_level_1': 'resumen que tengo contratado ',
					'page_screen': 'home',
					'&&events': 'event70',
					'entrypoint_section': 'que tengo contratado',
					'entrypoint_location': '',
					'entrypoint_title': '',
					'journey_name': '',
					'journey_category': '',
					'journey_subcategory': '',
					'journey_type': 'servicio',
					'journey_process': 'activar',
					'journey_options': 'one plus',
				},
			},
			impresion: {
				eventName: 'que tengo contratado:home:impresiones entrypoint',
				data: {
					'event_name': 'impresiones entrypoint',
					'page_name': 'que tengo contratado:resumen que tengo contratado',
					'page_section': 'que tengo contratado',
					'page_subcategory_level_1': 'resumen que tengo contratado ',
					'page_screen': 'home',
					'entrypoint_list': 'es=que tengo contratado|el={el}|jn={jn}|et={et}|jo=one plus',
					'&&events': 'event75',
				},
			},
			entryPointsImpresion: {
				data: {
					'event_name': 'impresiones entrypoint',
					'page_name': 'que tengo contratado:resumen que tengo contratado',
					'page_section': 'que tengo contratado',
					'page_subcategory_level_1': 'resumen que tengo contratado',
					'asset_name': 'mivoweb',
					'navigation_level_1': 'que tengo contratado',
					'navigation_level_2': 'resumen que tengo contratado',
					'page_screen': 'resumen que tengo contratado',
					'event_category': 'visualizacion',
					'event_context': 'que tengo contratado',
					'event_label': 'impresiones entrypoint',
					'&&events': 'event75',
					'entrypoint_list':
						'es=que tengo contratado|el={{entrypoint_location}}|ep=undefined|jn=iot|en=undefined|et={{entrypoint_title}}|ety=normal|ec={{entrypoint_code}}',
				},
			},
			shopBanner: {
				event_name: 'click en {copyBanner}:{copyBtn}',
			},
			journey_subcategory: {
				journey_subcategory: '',
			},
			journeys: {
				snf: {
					journey_name: 'securenet family',
				},
				sn: {
					journey_name: 'securenet',
				},
				iot: {
					journey_name: 'iot',
				},
			},
			sections: {
				connectivity: 'conectividad',
				entertainment: 'entretenimiento',
				smart_home: 'hogar inteligente',
				pending: 'activaciones pendientes',
				professionalServices: 'servicios profesionales',
			},
			entryPointTitle: {
				activar: '{product} activar',
				gestionar: '{product} gestion',
			},
			modalInProgress: {
				page_name: 'productos y servicios:{product}:espera',
				page_section: 'productos y servicios',
				page_subcategory_level_1: '{product}',
				page_subcategory_level_2: 'espera',
				page_screen: 'espera',
				navigation_level_1: 'productos y servicios',
				navigation_level_2: '{product}',
				navigation_level_3: 'espera',
				asset_name: 'mivoweb',
				journey_name: '{product}',
				journey_category: '',
				journey_options: '{product}',
				journey_type: 'servicio',
				journey_proccess: 'activar',
			},
		},
		onePlusTv: {
			state: {
				page_name: 'productos y servicios:tv hogar ilimitable',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'tv hogar ilimitable',
				page_screen: 'tv hogar ilimitable',
			},
			click: {
				event_name: 'click en boton {0}',
			},
		},
		productType: {
			carConnect: 'carconnect',
			vHome: 'v-home mini',
			oneNumber: 'onenumber',
			secureNetFamily: 'securenet family',
			secureNet: 'securenet',
			digitalSecurity: 'seguridad digital',
			digitalBusiness: 'negocio digital',
			iot: 'iot',
		},
	},
	promoBf: {
		journey: {
			journey_name: '{promo}:{type}',
			journey_category: '{promo}',
			journey_type: 'eservice',
			promo_segment: '',
			promo_plan: '',
		},
		journey_names: {
			start: 'start',
			preconfirmacion: 'preconfirmacion',
			ok: 'ok',
			ko: 'ko',
		},
		event: {
			event_name: 'clic en {cta}',
			promo_segment: '',
			promo_plan: '',
		},
		button: {
			close: 'boton cerrar',
			confirm: 'Lo quiero',
		},
	},
	nexusCommercial: {
		page: {
			page_name: 'transaccional:microflujos:nexo',
			page_section: 'transaccional',
			page_subcategory_level_1: 'microflujos',
			page_subcategory_level_2: 'nexo',
			page_subcategory_level_3: '',
			page_screen: '',
			navigation_level_1: 'transaccional',
			navigation_level_2: 'microflujos',
			navigation_level_3: 'nexo',
			navigation_level_4: '',
			asset_name: 'mivoweb',
			page_platform: 'web',
		},
		data: {
			journey_principal: '',
			journey_name: 'checkout',
			journey_type: 'transaccional',
			journey_category: '',
			journey_subcategory: '',
			journey_detail: 'nexo',
			journey_step: 'nexo',
			state_flow: 'nexo',
			product_catalog_offer: '',
			product_type_offer: '',
			transaction_cart_id: '',
		},
		event: {
			'event_name': 'anadir producto',
			'event_category': 'boton',
			'event_context': 'card producto',
			'event_label': '{0}',
			'&&events': '',
			'&&products': '',
		},
		eventCart: {
			event_name: 'click carrito',
			event_category: 'icono',
			event_context: 'carrito',
			event_label: 'ver detalle del pedido',
		},
		loadBannerDmp: {
			eventName: 'impresiones dmp',
			data: {
				page_name: 'transaccional:microflujos:nexo',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'nexo',
				page_subcategory_level_3: '',
				page_screen: '',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'nexo',
				navigation_level_4: '',
				asset_name: 'mivoweb',
				page_platform: 'web',
				event_category: '{0}',
				event_context: 'card producto',
				event_label: '{0}',
				journey_principal: '',
				journey_name: 'checkout',
				journey_type: 'transaccional',
				journey_category: '',
				journey_subcategory: '',
				journey_detail: 'nexo',
				journey_step: 'checkout:nexo',
				state_flow: 'nexo',
			},
		},
		overlay: {
			data: {
				'page_section': 'transaccional',
				'page_subcategory_level_1': 'microflujos',
				'page_subcategory_level_2': '{product}',
				'page_detail': '{product}',
				'page_typology': 'overlay',
				'page_name': '{page_name}',
				'journey_name': '{product}',
				'journey_type': 'transaccional',
				'journey_category': '',
				'journey_detail': '{product}',
				'&&products': '',
			},
			confirm: {
				event_category: 'boton',
				event_context: '{product}',
				event_label: '{action}',
			},
			add: 'agregar',
			close: 'cerrar',
			taggingOpTemplate: 'servicio;solucion profesional;1;;;',
			varPos: 'eVar244=',
		},
		error: {
			page: {
				page_name: 'transaccional:microflujos:error',
				journey_type: 'transaccional',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'nexo',
				page_screen: 'error',
			},
			data: {
				'journey_type': 'transaccional',
				'&&events': 'event100',
				'error_category': '',
				'error_description': '',
				'error_type': 'tecnico',
				'error_code': '',
			},
		},
		delete: {
			page: {
				page_name: 'transaccional:microflujos:nexo',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'nexo',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'nexo',
				asset_name: 'mioweb',
				page_platform: 'web',
			},
			data: {
				'journey_name': 'checkout',
				'journey_type': 'transaccional',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_detail': 'nexo',
				'journey_step': 'checkout:nexo',
				'state_flow': 'nexo',
				'&&products': null,
			},
			event: {
				'event_name': 'eliminar producto',
				'event_category': 'boton',
				'event_context': 'card producto',
				'event_label': '',
				'&&events': 'scRemove',
			},
		},
		confirmClose: {
			page: {
				page_name: 'transaccional:microflujos:nexo',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'nexo',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'nexo',
				asset_name: 'mioweb',
				page_platform: 'web',
			},
			data: {
				'journey_name': 'checkout',
				'journey_type': 'transaccional',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_detail': 'nexo',
				'journey_step': 'checkout:nexo',
				'state_flow': 'nexo',
				'&&products': null,
			},
			event: {
				'event_name': 'tramitar pedido',
				'event_category': 'boton',
				'event_label': '',
				'&&events': 'scCheckout',
			},
		},
		dataBox: {
			'journey_name': 'checkout',
			'journey_type': 'transaccional',
			'journey_category': '',
			'journey_subcategory': '',
			'journey_detail': 'nexo',
			'journey_step': 'checkout:nexo',
			'state_flow': 'nexo',
			'&&products': null,
		},
		eventBox: {
			event_name: 'ver tarjeta:',
			event_category: 'boton',
			event_label: '',
		},
		dataGoTo: {
			'journey_type': 'transaccional',
			'journey_detail': 'nexo',
			'journey_step': 'nexo',
			'event_name': 'tramitar pedido',
			'event_category': 'boton',
			'state_flow': 'nexo',
			'event_label': '',
			'&&events': 'scCheckout',
			'transaction_cart_id': '',
		},
		nexusDMP: {
			recomendationType: {
				notPersonalized: {
					name: 'notPersonalized',
					impresionValues: {
						ety: '|ety=normal',
						jn_en: '|jn=<journey_name>',
					},
					clickValues: {
						entrypoint_type: 'normal',
					},
				},
				adobeTarget: {
					name: 'adobeTarget',
					impresionValues: {
						ety: '|ety=target',
						jn_en: '|jn=<journey_name>|en=<experience_name>',
					},
					clickValues: {
						entrypoint_type: 'target',
						experience_name: '',
					},
				},
			},
			commonSymbols: {
				separtor_entrypoints: ',',
				separator_values: '|',
			},
			impresionEntrypoint: {
				page: {
					page_name: 'transaccional:microflujos:nexo',
				},
				data: {
					'event_name': 'impresiones entrypoint',
					'&&events': 'event75',
					'entrypoint_list': '',
				},
			},
			pageClickDMP: {
				page_name: 'transaccional:microflujos:nexo',
				page_platform: 'web',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'nexo',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'nexo',
				asset_name: 'mivoweb',
			},
			dataClickDMP: {
				journey_name: 'checkout',
				journey_type: 'transaccional',
				journey_category: '',
				journey_subcategory: '',
				journey_detail: 'nexo',
				journey_step: 'checkout:nexo',
				state_flow: 'nexo',
			},
			eventClickDMP: {
				event_name: '',
				event_category: 'boton',
				event_context: 'card producto',
				event_label: '',
			},
			clickEntrypoint: {
				page: {
					page_name: 'transaccional:microflujos:nexo',
				},
				data: {
					'event_name': 'click en entrypoint',
					'&&events': 'event70',
					'entrypoint_section': 'nexo',
					'entrypoint_location': 'recomendador',
					'entrypoint_title': '',
					'entrypoint_position': '',
					'entrypoint_type': '',
					'journey_name': '',
					'experience_name': '',
				},
				overlay: {
					'page_section': 'transaccional',
					'page_subcategory_level_1': 'microflujos',
					'page_subcategory_level_2': 'nexo',
					'page_typology': 'principal',
					'page_name': '{page_name}',
					'navigation_level_1': 'transaccional',
					'event_category': 'boton',
					'event_context': 'nexo',
					'event_label': 'agregar',
					'journey_type': 'transaccional',
					'journey_detail': 'nexo',
					'&&products': '',
				},
			},
		},
	},
	genericCheckout: {
		page: {
			page_name: 'transaccional:microflujos:checkout:tipo de envio',
			page_section: 'transaccional',
			page_subcategory_level_1: 'microflujos',
			page_subcategory_level_2: 'checkout',
			page_screen: 'tipo de envio',
		},
		data: {
			journey_type: 'transaccional',
			journey_detail: 'tipo de envio',
			journey_step: 'checkout:tipo de envio',
			state_flow: 'checkout',
			transaction_cart_id: '',
			shipping_type: '',
			transaction_shipping: '',
			product_catalog_offer: '',
			product_type_offer: '',
		},
		taggingShipping: {
			page_name: 'transaccional:microflujos:checkout:tipo de envio',
			page_section: 'transaccional',
			page_subcategory_level_1: 'microflujos',
			page_subcategory_level_2: 'checkout',
			avigation_level_1: 'transaccional',
			navigation_level_2: 'microflujos',
			navigation_level_3: 'checkout',
			navigation_level_4: 'tipo de envio',
			page_screen: 'tipo de envio',
			event_name: 'click en continuar',
			event_category: 'boton',
			event_context: '',
			event_label: '',
			journey_name: 'checkout',
			journey_type: 'transaccional',
			journey_category: '',
			journey_subcategory: '',
			journey_detail: 'tipo de envio',
			journey_step: 'checkout:tipo de envio',
			state_flow: 'checkout',
			shipping_type: '',
			transaction_shipping: '',
		},
		changeAddress: {
			event_name: 'click en cambiar direccion',
			event_context: 'cambiar direccion tipo de envio',
		},
		confirmAddres: {
			event_name: 'click en guardar direccion',
			event_context: 'guardar direccion tipo de envio',
			event_context_auto: 'guardar direccion auto tipo de envio',
		},
		closeChangeAddress: {
			event_name: 'click en cerrar',
			event_context: 'cancelar direccion tipo de envio',
		},
		error: {
			page: {
				page_name: 'transaccional:microflujos:error',
				journey_type: 'transaccional',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: '',
				page_screen: 'error',
			},
			data: {
				'journey_type': 'transaccional',
				'&&events': 'event100',
				'error_category': '',
				'error_description': '',
				'error_type': 'tecnico',
				'error_code': '',
			},
		},
		confirmClose: {
			page: {
				page_name: 'transaccional:microflujos:exit',
				page_section: 'transaccional',
				page_subcategory_level_1: '',
				page_screen: 'exit',
			},
			data: {
				'journey_type': 'transaccional',
				'journey_detail': 'exit',
				'journey_step': 'exit',
				'&&events': '',
				'event_name': '',
				'event_category': '',
				'event_label': '',
				'journey_transaction_type': '',
			},
		},
		xrContents: {
			state: {
				page_name: 'transaccional:microflujos:vodafone 5g reality:aviso',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'vodafone 5g reality',
				page_subcategory_level_3: 'aviso',
				page_screen: 'aviso',
				page_typology: 'backdrop',
				journey_type: 'transaccional',
				journey_step: 'transaccional:aviso',
				journey_detail: 'aviso',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'vodafone 5g reality',
				navigation_level_4: 'aviso',
			},
			click: {
				event_category: 'boton',
				event_name: 'click en <cta>',
			},
		},
		genericSummary: {
			page: {
				page_name: 'transaccional:microflujos:checkout:resumen',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'checkout',
				page_screen: 'resumen',
			},
			data: {
				journey_type: 'transaccional',
				journey_detail: 'resumen',
				journey_step: 'resumen',
				state_flow: 'checkout',
				product_catalog_offer: '',
				product_type_offer: '',
				transaction_cart_id: '',
			},
			openProductP: {
				page_name: 'transaccional:microflujos:checkout:resumen',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'checkout',
				page_screen: 'resumen',
			},
			openProductD: {
				journey_type: 'transaccional',
				journey_detail: 'resumen',
				journey_step: 'resumen',
				event_name: 'ver mis productos',
				event_category: 'boton',
				event_label: 'ver mis productos',
				state_flow: 'checkout',
			},
			pageBox: {
				page_name: 'transaccional:microflujos:checkout:resumen',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'checkout',
				page_screen: 'resumen',
			},
			dataBox: {
				journey_type: 'transaccional',
				journey_detail: 'resumen',
				journey_step: 'resumen',
				event_name: 'ver tarjeta:{0}',
				event_category: 'boton',
				event_label: '',
				state_flow: 'checkout',
			},
			pageThankYou: {
				page_name: 'transaccional:microflujos:checkout:thank you',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'checkout',
				page_screen: 'thank you',
			},
			dataThankYou: {
				'journey_name': 'checkout',
				'journey_type': 'transaccional',
				'journey_detail': 'thank you',
				'journey_step': 'checkout:thank you',
				'journey_category': '',
				'journey_subcategory': '',
				'&&events': 'purchase,event28',
				'state_flow': 'checkout',
				'purchaseID': '',
				'product_catalog_offer': '',
				'product_type_offer': '',
				'asset_name': 'mivoweb',
				'transaction_cart_id': '',
			},
			pagePaymentProcess: {
				page_name: 'transaccional:microflujos:checkout:pasarela',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'checkout',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'checkout',
				navigation_level_4: 'pasarela',
				page_screen: 'pasarela',
			},
			dataPaymentProcess: {
				'journey_type': 'transaccional',
				'journey_detail': 'pasarela',
				'journey_step': 'checkout:pasarela',
				'state_flow': 'checkout',
				'&&products': '',
				'journey_name': 'checkout',
				'journey_category': '',
				'journey_subcategory': '',
			},
			editClick: {
				page_name: 'transaccional:microflujos:checkout:resumen',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'checkout',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'checkout',
				navigation_level_4: 'resumen',
				page_screen: 'resumen',
				asset_name: 'mivoweb',
				event_name: 'click en editar infomacion',
				event_category: 'link',
				event_label: 'editar',
				event_context: 'informacion envio',
				journey_principal: '',
				journey_name: 'checkout',
				journey_type: 'transaccional',
				journey_category: '',
				journey_subcategory: '',
				journey_detail: 'resumen',
				journey_step: 'checkout:resumen',
				state_flow: 'checkout',
			},
		},
		paymentMethod: {
			page: {
				page_name: 'transaccional:microflujos:checkout:metodo pago',
				journey_type: 'transaccional',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'checkout',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'checkout',
				navigation_level_4: 'metodo pago',
				page_screen: 'metodo pago',
			},
			data: {
				'journey_type': 'transaccional',
				'journey_detail': 'metodo pago',
				'journey_step': 'transaccional:metodo pago',
				'state_flow': 'checkout',
				'&&products': '',
				'event_label': '',
				'transaction_cart_id': '',
			},
			dataBackdrop: {
				event_name: 'click en como protege vodafone',
				event_category: 'link',
				event_context: 'tarjeta metodo pago',
			},
			dataContinue: {
				event_name: 'click en continuar',
				event_category: 'button',
				journey_payment_type: '',
			},
			error: {
				page: {
					page_name: 'transaccional:microflujos:error',
					journey_type: 'transaccional',
					page_section: 'transaccional',
					page_subcategory_level_1: 'microflujos',
					page_subcategory_level_2: 'checkout',
					navigation_level_1: 'transaccional',
					navigation_level_2: 'microflujos',
					navigation_level_3: 'error',
					page_screen: 'error',
				},
				data: {
					'journey_type': 'transaccional',
					'&&events': 'event100',
					'error_category': 'dxl',
					'error_description': '',
					'error_type': 'tecnico',
					'error_code': '',
				},
			},
			pageInitPayment: {
				page_name: 'transaccional:microflujos:checkout:pasarela',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'checkout',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'checkout',
				navigation_level_4: 'pasarela',
				page_screen: 'pasarela',
			},
			dataInitPayment: {
				'journey_name': 'checkout',
				'journey_type': 'transaccional',
				'journey_detail': 'pasarela',
				'journey_step': 'checkout:pasarela',
				'state_flow': 'checkout',
				'&&products': '',
			},
			eventInitPayment: {
				event_name: '',
				event_category: '',
				event_context: '',
			},
			pageClosePayment: {
				page_name: 'transaccional:microflujos:checkout:pasarela:pago tarjeta:click en pagar',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'checkout',
				page_subcategory_level_3: 'pasarela',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'checkout',
				navigation_level_4: 'pasarela',
				page_screen: 'pasarela',
			},
			dataClosePayment: {
				'journey_name': 'checkout',
				'journey_type': 'transaccional',
				'journey_detail': 'pasarela',
				'journey_step': 'checkout:pasarela',
				'state_flow': 'checkout',
				'&&products': '',
			},
			eventClosePayment: {
				event_name: 'click en cerrar',
				event_category: 'boton',
				event_context: 'pasarela',
				event_label: 'cerrar',
			},
		},
		Reestrena: {
			page: {
				page_name: 'transaccional:microflujos:checkout:thank you',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'checkout',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'checkout',
				navigation_level_4: 'thank you',
				page_screen: 'thank you',
			},
			data: {
				journey_type: 'transaccional',
				journey_detail: 'pasarela',
				journey__step: 'transaccional:pasarela',
				state_flow: 'checkout',
			},
			event: {
				event_name: 'click en reestrena',
				event_category: 'boton',
				event_context: 'recicla tu movil',
				event_label: '{0}',
			},
		},

		ChangeNumberOverlay: {
			openChangeNumberOverlay: {
				'page_name': 'transaccional:microflujos:checkout:tipo de envio',
				'page_section': 'transaccional',
				'page_subcategory_level_1': 'microflujos',
				'page_subcategory_level_2': 'checkout',
				'navigation_level_1': 'transaccional',
				'navigation_level_2': 'microflujos',
				'navigation_level_3': 'checkout',
				'navigation_level_4': 'tipo de envio',
				'page_screen': 'tipo de envio',
				'asset_name': 'mivoweb',
				'page_platform': 'web',
				'event_name': 'click en cambiar numero',
				'event_category': 'link',
				'event_context': '<cambiar|guardar>',
				'event_label': '<copyText>',
				'journey_name': 'checkout',
				'journey_type': 'transaccional',
				'journey_category': 'particular',
				'journey_subcategory': '<prepago|postpago>',
				'journey_detail': 'tipo de envio',
				'journey_step': 'checkout:tipo de envio',
				'state_flow': 'checkout',
				'shipping_type': '<recoger en tienda|envio a casa|recoger en correos>',
				'transaction_shipping': '<recoger en tienda|envio a casa|recoger en correos>',
				'&&products': '',
			},
			saveChangeNumberOverlay: {
				'page_name': 'transaccional:microflujos:checkout:tipo de envio',
				'page_section': 'transaccional',
				'page_subcategory_level_1': 'microflujos',
				'page_subcategory_level_2': 'checkout',
				'navigation_level_1': 'transaccional',
				'navigation_level_2': 'microflujos',
				'navigation_level_3': 'checkout',
				'navigation_level_4': 'tipo de envio',
				'page_screen': 'tipo de envio',
				'asset_name': 'mivoweb',
				'page_platform': 'web',
				'event_name': 'click en guardar numero',
				'event_category': 'boton',
				'event_context': 'guardar numero movil',
				'event_label': '<copyText>',
				'journey_name': 'checkout',
				'journey_type': 'transaccional',
				'journey_category': '<particular|empresa|autonomos>',
				'journey_subcategory': '<prepago|postpago>',
				'journey_detail': 'tipo de envio',
				'journey_step': 'checkout:tipo de envio',
				'state_flow': 'checkout',
				'shipping_type': '<recoger en tienda|envio a casa|recoger en correos>',
				'transaction_shipping': '<recoger en tienda|envio a casa|recoger en correos>',
				'&&products': '',
			},
			closeChangeNumberOverlay: {
				'page_name': 'transaccional:microflujos:checkout:tipo de envio',
				'page_section': 'transaccional',
				'page_subcategory_level_1': 'microflujos',
				'page_subcategory_level_2': 'checkout',
				'navigation_level_1': 'transaccional',
				'navigation_level_2': 'microflujos',
				'navigation_level_3': 'checkout',
				'navigation_level_4': 'tipo de envio',
				'page_screen': 'tipo de envio',
				'asset_name': 'mivoweb',
				'page_platform': 'web',
				'event_name': 'click en cerrar',
				'event_category': 'boton',
				'event_context': 'cancelar cambio de numero movil',
				'event_label': 'cerrar',
				'journey_name': 'checkout',
				'journey_type': 'transaccional',
				'journey_category': '<particular|empresa|autonomos>',
				'journey_subcategory': '<prepago|postpago>',
				'journey_detail': 'tipo de envio',
				'journey_step': 'checkout:tipo de envio',
				'state_flow': 'checkout',
				'shipping_type': '<recoger en tienda|envio a casa|recoger en correos>',
				'transaction_shipping': '<recoger en tienda|envio a casa|recoger en correos>',
				'&&products': '',
			},
		},
	},
	buttonRoamingConsumptionLimits: {
		eventName: '',
		data: {
			event_name: 'acordeon:que ocurre si me quedo sin datos',
			event_category: 'link',
			event_context: 'cual es mi tarifa en el extranjero',
			event_label: 'que ocurre si me quedo sin datos',
		},
	},
	airAndSearRoaming: {
		eventName: 'roaming:gestiona el roaming de tus lineas:acordeon:roaming aereo y maritimo',
		data: {
			event_name: 'acordeon:roaming aereo y maritimo',
			event_category: 'acordeon',
			event_context: 'cual es mi tarifa en el extranjero',
			event_label: 'roaming aereo y maritimo',
		},
	},
	reintentarRoaming: {
		eventName: 'roaming:gestiona el roaming de tus lineas',
		data: {
			event_name: 'buscar pais destino:reintentar',
			event_category: 'link',
			event_context: 'cual es mi tarifa en el extranjero',
			event_label: 'buscar pais destino:reintentar',
		},
	},
	IcomingRoamingActivationTray: {
		page_name: 'roaming:permisos de roaming:llamadas entrantes:activacion',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas entrantes',
		page_subcategory_level_3: 'activacion',
		page_subcategory_level_4: '',
		page_screen: 'activacion',
		page_typology: 'overlay',
	},
	RoamingActivationTray: {
		page_name: 'roaming:permisos de roaming:llamadas y datos:activacion',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas y datos',
		page_subcategory_level_3: 'activacion',
		page_subcategory_level_4: '',
		page_screen: 'activacion',
		page_typology: 'overlay',
	},
	outgoingActivationTray: {
		page_name: 'roaming:permisos de roaming:llamadas salientes:activacion',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas salientes',
		page_subcategory_level_3: 'activacion',
		page_subcategory_level_4: '',
		page_screen: 'activacion',
		page_typology: 'overlay',
	},
	dataActivationTray: {
		page_name: 'roaming:permisos de roaming:datos:activacion',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'datos',
		page_subcategory_level_3: 'activacion',
		page_subcategory_level_4: '',
		page_screen: 'activacion',
		page_typology: 'overlay',
	},
	IcomingRoamingDeActivationTray: {
		page_name: 'roaming:permisos de roaming:llamadas entrantes:desactivacion',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas entrantes',
		page_subcategory_level_3: 'desactivacion',
		page_subcategory_level_4: '',
		page_screen: 'desactivacion',
		page_typology: 'overlay',
	},
	RoamingDeActivationTray: {
		page_name: 'roaming:permisos de roaming:llamadas y datos:desactivacion',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas y datos',
		page_subcategory_level_3: 'desactivacion',
		page_subcategory_level_4: '',
		page_screen: 'desactivacion',
		page_typology: 'overlay',
	},
	outgoingDeActivationTray: {
		page_name: 'roaming:permisos de roaming:llamadas salientes:desactivacion',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas salientes',
		page_subcategory_level_3: 'desactivacion',
		page_subcategory_level_4: '',
		page_screen: 'desactivacion',
		page_typology: 'overlay',
	},
	dataDeActivationTray: {
		page_name: 'roaming:permisos de roaming:datos:desactivacion',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'datos',
		page_subcategory_level_3: 'desactivacion',
		page_subcategory_level_4: '',
		page_screen: 'desactivacion',
		page_typology: 'overlay',
	},
	incomingRoamingSucessActive: {
		page_name: 'roaming:permisos de roaming:llamadas entrantes:activacion ok',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas entrantes',
		page_subcategory_level_3: 'activacion ok',
		page_subcategory_level_4: '',
		page_screen: 'activacion ok',
		page_typology: 'overlay',
	},
	RoamingSucessActive: {
		page_name: 'roaming:permisos de roaming:llamadas y datos:activacion ok',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas y datos',
		page_subcategory_level_3: 'activacion ok',
		page_subcategory_level_4: '',
		page_screen: 'activacion ok',
		page_typology: 'overlay',
	},
	outgoingSucessActive: {
		page_name: 'roaming:permisos de roaming:llamadas salientes:activacion ok',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas salientes',
		page_subcategory_level_3: 'activacion ok',
		page_subcategory_level_4: '',
		page_screen: 'activacion ok',
		page_typology: 'overlay',
	},
	dataSucessActive: {
		page_name: 'roaming:permisos de roaming:datos:activacion ok',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'datos',
		page_subcategory_level_3: 'activacion ok',
		page_subcategory_level_4: '',
		page_screen: 'activacion ok',
		page_typology: 'overlay',
	},
	incomingRoamingFailActive: {
		page_name: 'roaming:permisos de roaming:llamadas entrantes:activacion ko',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas entrantes',
		page_subcategory_level_3: 'activacion ko',
		page_subcategory_level_4: '',
		page_screen: 'activacion ko',
		page_typology: 'overlay',
	},
	RoamingFailActive: {
		page_name: 'roaming:permisos de roaming:llamadas y datos:activacion ko',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas y datos',
		page_subcategory_level_3: 'activacion ko',
		page_subcategory_level_4: '',
		page_screen: 'activacion ko',
		page_typology: 'overlay',
	},
	outgoingFailActive: {
		page_name: 'roaming:permisos de roaming:llamadas salientes:activacion ko',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas salientes',
		page_subcategory_level_3: 'activacion ko',
		page_subcategory_level_4: '',
		page_screen: 'activacion ko',
		page_typology: 'overlay',
	},
	dataFailActive: {
		page_name: 'roaming:permisos de roaming:datos:activacion ko',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'datos',
		page_subcategory_level_3: 'activacion ko',
		page_subcategory_level_4: '',
		page_screen: 'activacion ko',
		page_typology: 'overlay',
	},
	incomingRoamingSucessDeActive: {
		page_name: 'roaming:permisos de roaming:llamadas entrantes:desactivacion ok',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas entrantes',
		page_subcategory_level_3: 'desactivacion ok',
		page_subcategory_level_4: '',
		page_screen: 'desactivacion ok',
		page_typology: 'overlay',
	},
	RoamingSucessDeActive: {
		page_name: 'roaming:permisos de roaming:llamadas y datos:desactivacion ok',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas y datos',
		page_subcategory_level_3: 'desactivacion ok',
		page_subcategory_level_4: '',
		page_screen: 'desactivacion ok',
		page_typology: 'overlay',
	},
	outgoingSucessDeActive: {
		page_name: 'roaming:permisos de roaming:llamadas salientes:desactivacion ok',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas salientes',
		page_subcategory_level_3: 'desactivacion ok',
		page_subcategory_level_4: '',
		page_screen: 'desactivacion ok',
		page_typology: 'overlay',
	},
	dataSucessDeActive: {
		page_name: 'roaming:permisos de roaming:datos:desactivacion ok',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'datos',
		page_subcategory_level_3: 'desactivacion ok',
		page_subcategory_level_4: '',
		page_screen: 'desactivacion ok',
		page_typology: 'overlay',
	},
	incomingRoamingFailDeActive: {
		page_name: 'roaming:permisos de roaming:llamadas entrantes:desactivacion ko',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas entrantes',
		page_subcategory_level_3: 'desactivacion ko',
		page_subcategory_level_4: '',
		page_screen: 'desactivacion ko',
		page_typology: 'overlay',
	},
	RoamingFailDeActive: {
		page_name: 'roaming:permisos de roaming:llamadas y datos:desactivacion ko',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas y datos',
		page_subcategory_level_3: 'desactivacion ko',
		page_subcategory_level_4: '',
		page_screen: 'desactivacion ko',
		page_typology: 'overlay',
	},
	outgoingFailDeActive: {
		page_name: 'roaming:permisos de roaming:llamadas salientes:desactivacion ko',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'llamadas salientes',
		page_subcategory_level_3: 'desactivacion ko',
		page_subcategory_level_4: '',
		page_screen: 'desactivacion ko',
		page_typology: 'overlay',
	},
	dataFailDeActive: {
		page_name: 'roaming:permisos de roaming:datos:desactivacion ko',
		page_section: 'roaming',
		page_subcategory_level_1: 'permisos de roaming',
		page_subcategory_level_2: 'datos',
		page_subcategory_level_3: 'desactivacion ko',
		page_subcategory_level_4: '',
		page_screen: 'desactivacion ko',
		page_typology: 'overlay',
	},
	RoamingErrorNoRoaming: {
		page_name: 'roaming:detalle tarifa error destino',
		page_section: 'roaming',
		page_subcategory_level_1: 'detalle tarifa error destino',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: 'detalle tarifa error destino',
		page_typology: '',
	},
	ZoneOneBothCountrySearchBox: {
		page_name: 'roaming:detalle tarifa desde zona 1 a zona 1',
		page_section: 'roaming',
		page_subcategory_level_1: 'detalle tarifa desde zona 1 a zona 1',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: 'detalle tarifa desde zona 1 a zona 1',
		page_typology: '',
	},
	ZoneOneOriginCountrySearchBox: {
		page_name: 'roaming:detalle tarifa desde zona 1 a otras',
		page_section: 'roaming',
		page_subcategory_level_1: 'detalle tarifa desde zona 1 a otras',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: 'detalle tarifa desde zona 1 a otras',
		page_typology: '',
	},
	ZoneTwoOriginCountrySearchBox: {
		page_name: 'roaming:detalle tarifa desde zona 2',
		page_section: 'roaming',
		page_subcategory_level_1: 'detalle tarifa desde zona 2',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: 'detalle tarifa desde zona 2',
		page_typology: '',
	},
	ZoneThreeOriginCountrySearchBox: {
		page_name: 'roaming:detalle tarifa desde zona 3',
		page_section: 'roaming',
		page_subcategory_level_1: 'detalle tarifa desde zona 3',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: 'detalle tarifa desde zona 3',
		page_typology: '',
	},
	ZoneFourOriginCountrySearchBox: {
		page_name: 'roaming:detalle tarifa desde zona 4',
		page_section: 'roaming',
		page_subcategory_level_1: 'detalle tarifa desde zona 4',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: 'detalle tarifa desde zona 4',
		page_typology: '',
	},
	ZoneSixOriginCountrySearchBox: {
		page_name: 'roaming:detalle tarifa desde zona 6',
		page_section: 'roaming',
		page_subcategory_level_1: 'detalle tarifa desde zona 6',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: 'detalle tarifa desde zona 6',
		page_typology: '',
	},
	ZoneErrorOriginCountrySearchBox: {
		page_name: 'roaming:detalle tarifa error destino',
		page_section: 'roaming',
		page_subcategory_level_1: 'detalle tarifa error destino',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_subcategory_level_4: '',
		page_screen: 'detalle tarifa error destino',
		page_typology: '',
	},
	activacionRoamingLlamadasYdatos: {
		page_name: 'activacion roaming llamadas y datos',
	},
	activacionOutgoing: {
		page_name: 'activacion roaming llamadas salientes',
	},
	desactivacionOutgoing: {
		page_name: 'desactivacion roaming llamadas salientes',
	},
	activacionData: {
		page_name: 'activacion roaming datos',
	},
	desactivacionData: {
		page_name: 'desactivacion roaming datos',
	},
	activacionRoamingLlamadasEntrantes: {
		page_name: 'activacion roaming llamadas entrantes',
	},

	desactivacionRoamingLlamadasEntrantes: {
		page_name: 'desactivacion roaming llamadas entrantes',
	},

	desactivacionRoamingLlamadasYdatos: {
		page_name: 'desactivacion roaming llamadas y datos',
	},

	RoamingActions: {
		originCountrySearchBox: {
			eventName: 'roaming:gestiona el roaming de tus lineas:buscar pais origen',
			data: {
				event_name: 'buscar pais origen',
				event_category: 'link',
				event_context: 'cual es mi tarifa en el extranjero',
				event_label: 'buscar pais origen',
			},
		},
		destinationCountrySearchBox: {
			eventName: 'roaming:gestiona el roaming de tus lineas: buscar pais destino',
			data: {
				event_name: ' buscar pais destino',
				event_category: 'link',
				event_context: 'cual es mi tarifa en el extranjero',
				event_label: 'buscar pais destino',
			},
		},
		roaming: {
			activeBtnCard: {
				eventName: 'roaming:gestiona el roaming de tus lineas:activacion roaming llamadas y datos',
				data: {
					event_name: 'activar',
					event_category: 'boton',
					event_context: 'permisos de roaming',
					event_label: 'activacion roaming llamadas y datos',
				},
			},
			desActiveBtnCard: {
				eventName: 'roaming:gestiona el roaming de tus lineas:desactivacion llamadas y datos',
				data: {
					event_name: 'desactivar',
					event_category: 'boton',
					event_context: 'permisos de roaming',
					event_label: 'desactivacion llamadas y datos',
				},
			},
			activateBtnTray: {
				eventName: 'roaming:permisos de roaming:llamadas y datos:activacion:activar',
				data: {
					event_name: 'activar',
					event_category: 'boton',
					event_context: 'activacion roaming llamadas y datos',
					event_label: 'activar',
				},
			},
			CancelBtnTray: {
				eventName: 'roaming:permisos de roaming:llamadas y datos:activacion:cancelar',
				data: {
					event_name: 'cancelar',
					event_category: 'boton',
					event_context: 'activacion roaming llamadas y datos',
					event_label: 'cancelar',
				},
			},
			desactivationBtnTray: {
				eventName: 'roaming:permisos de roaming:llamadas y datos:desactivacion:desactivar',
				data: {
					event_name: 'desactivar',
					event_category: 'boton',
					event_context: 'desactivacion roaming llamadas entrantes',
					event_label: 'desactivar',
				},
			},
			desactivationCancelBtnTray: {
				eventName: 'roaming:permisos de roaming:llamadas y datos:desactivacion:cancelar',
				data: {
					event_name: 'cancelar',
					event_category: 'boton',
					event_context: 'desactivacion roaming llamadas y datos',
					event_label: 'cancelar',
				},
			},
		},
		incomingRomaing: {
			activeBtnCard: {
				eventName: 'roaming:gestiona el roaming de tus lineas:activacion llamadas entrantes',
				data: {
					event_name: 'activar',
					event_category: 'boton',
					event_context: 'permisos de roaming',
					event_label: 'activación llamadas entrantes',
				},
			},
			desActiveBtnCard: {
				eventName: 'roaming:gestiona el roaming de tus lineas:desactivacion llamadas entrantes',
				data: {
					event_name: 'desactivar',
					event_category: 'boton',
					event_context: 'permisos de roaming',
					event_label: 'desactivacion llamadas entrantes',
				},
			},
			activateBtnTray: {
				eventName: 'roaming:permisos de roaming:llamadas entrantes:activacion:activar',
				data: {
					event_name: 'activar',
					event_category: 'boton',
					event_context: 'activacion roaming llamadas entrantes',
					event_label: 'activar',
				},
			},
			CancelBtnTray: {
				eventName: 'roaming:permisos de roaming:llamadas entrantes:activacion:cancelar',
				data: {
					event_name: 'cancelar',
					event_category: 'boton',
					event_context: 'activacion roaming llamadas entrantes',
					event_label: 'cancelar',
				},
			},
			desactivationBtnTray: {
				eventName: 'roaming:permisos de roaming:llamadas entrantes:desactivacion:desactivar',
				data: {
					event_name: 'desactivar',
					event_category: 'boton',
					event_context: 'desactivacion roaming llamadas entrantes',
					event_label: 'desactivar',
				},
			},
			desactivationCancelBtnTray: {
				eventName: 'roaming:permisos de roaming:llamadas entrantes:desactivacion:cancelar',
				data: {
					event_name: 'cancelar',
					event_category: 'boton',
					event_context: 'desactivacion roaming llamadas entrantes',
					event_label: 'cancelar',
				},
			},
		},
		outgoing: {
			activeBtnCard: {
				eventName: 'roaming:gestiona el roaming de tus lineas:activacion llamadas salientes',
				data: {
					event_name: 'activar',
					event_category: 'boton',
					event_context: 'permisos de roaming',
					event_label: 'activación llamadas salientes',
				},
			},
			desActiveBtnCard: {
				eventName: 'roaming:gestiona el roaming de tus lineas:desactivacion llamadas salientes',
				data: {
					event_name: 'desactivar',
					event_category: 'boton',
					event_context: 'permisos de roaming',
					event_label: 'desactivacion llamadas salientes',
				},
			},
			activateBtnTray: {
				eventName: 'roaming:permisos de roaming:llamadas salientes:activacion:activar',
				data: {
					event_name: 'activar',
					event_category: 'boton',
					event_context: 'activacion roaming llamadas salientes',
					event_label: 'activar',
				},
			},
			CancelBtnTray: {
				eventName: 'roaming:permisos de roaming:llamadas salientes:activacion:cancelar',
				data: {
					event_name: 'cancelar',
					event_category: 'boton',
					event_context: 'activacion roaming llamadas salientes',
					event_label: 'cancelar',
				},
			},
			desactivationBtnTray: {
				eventName: 'roaming:permisos de roaming:llamadas salientes:desactivacion:desactivar',
				data: {
					event_name: 'desactivar',
					event_category: 'boton',
					event_context: 'desactivacion roaming llamadas salientes',
					event_label: 'desactivar',
				},
			},
			desactivationCancelBtnTray: {
				eventName: 'roaming:permisos de roaming:llamadas salientes:desactivacion:cancelar',
				data: {
					event_name: 'cancelar',
					event_category: 'boton',
					event_context: 'desactivacion roaming llamadas salientes',
					event_label: 'cancelar',
				},
			},
		},
		data: {
			activeBtnCard: {
				eventName: 'roaming:gestiona el roaming de tus lineas:activacion datos',
				data: {
					event_name: 'activar',
					event_category: 'boton',
					event_context: 'permisos de roaming',
					event_label: 'activación datos',
				},
			},
			desActiveBtnCard: {
				eventName: 'roaming:gestiona el roaming de tus lineas:desactivacion datos',
				data: {
					event_name: 'desactivar',
					event_category: 'boton',
					event_context: 'permisos de roaming',
					event_label: 'desactivacion datos',
				},
			},
			activateBtnTray: {
				eventName: 'roaming:permisos de roaming:datos:activacion:activar',
				data: {
					event_name: 'activar',
					event_category: 'boton',
					event_context: 'activacion roaming datos',
					event_label: 'activar',
				},
			},
			CancelBtnTray: {
				eventName: 'roaming:permisos de roaming:datos:activacion:cancelar',
				data: {
					event_name: 'cancelar',
					event_category: 'boton',
					event_context: 'activacion roaming datos',
					event_label: 'cancelar',
				},
			},
			desactivationBtnTray: {
				eventName: 'roaming:permisos de roaming:datos:desactivacion:desactivar',
				data: {
					event_name: 'desactivar',
					event_category: 'boton',
					event_context: 'desactivacion roaming datos',
					event_label: 'desactivar',
				},
			},
			desactivationCancelBtnTray: {
				eventName: 'roaming:permisos de roaming:datos:desactivacion:cancelar',
				data: {
					event_name: 'cancelar',
					event_category: 'boton',
					event_context: 'desactivacion roaming datos',
					event_label: 'cancelar',
				},
			},
		},
	},

	icomingRoamingActivationJourney: {
		firstStep: {
			eventName: 'activacion roaming llamadas entrantes:start',
			data: {
				journey_name: 'activacion roaming llamadas entrantes',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_options: '',
				journey_proccess: 'activar',
				journey_type: 'servicio',
				journey_detail: 'start',
				journey_step: 'activacion roaming llamadas entrantes:start',
				event_name: 'start',
			},
		},
		sucess: {
			eventName: 'activacion roaming llamadas entrantes:ok',
			data: {
				journey_name: 'activacion roaming llamadas entrantes',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_options: 'activacion roaming llamadas entrantes:activado',
				journey_proccess: 'activar',
				journey_type: 'servicio',
				journey_detail: 'ok',
				journey_step: 'activacion roaming llamadas entrantes:ok',
				event_name: 'ok',
			},
		},
		error: {
			eventName: 'activacion roaming llamadas entrantes:ko',
			data: {
				event_name: 'ko',
				journey_name: 'activacion roaming llamadas entrantes',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_proccess: 'activar',
				journey_type: 'servicio',
				journey_detail: 'ko',
				journey_step: 'activacion roaming llamadas entrantes:ko',
				journey_error_category: '',
				journey_error_code: '',
				journey_error_description: '',
			},
		},
	},
	outgoingActivationJourney: {
		firstStep: {
			eventName: 'activacion roaming llamadas salientes:start',
			data: {
				journey_name: 'activacion roaming llamadas salientes',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_options: '',
				journey_proccess: 'activar',
				journey_type: 'servicio',
				journey_detail: 'start',
				journey_step: 'activacion roaming llamadas salientes:start',
				event_name: 'start',
			},
		},
		sucess: {
			eventName: 'activacion roaming llamadas salientes:ok',
			data: {
				journey_name: 'activacion roaming llamadas salientes',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_options: 'activacion roaming llamadas salientes:activado',
				journey_proccess: 'activar',
				journey_type: 'servicio',
				journey_detail: 'ok',
				journey_step: 'activacion roaming llamadas salientes:ok',
				event_name: 'ok',
			},
		},
		error: {
			eventName: 'activacion roaming llamadas salientes:ko',
			data: {
				event_name: 'ko',
				journey_name: 'activacion roaming llamadas salientes',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_proccess: 'activar',
				journey_type: 'servicio',
				journey_detail: 'ko',
				journey_step: 'activacion roaming llamadas salientes:ko',
				journey_error_category: '',
				journey_error_code: '',
				journey_error_description: '',
			},
		},
	},
	dataActivationJourney: {
		firstStep: {
			eventName: 'activacion roaming datos:start',
			data: {
				journey_name: 'activacion roaming datos',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_options: '',
				journey_proccess: 'activar',
				journey_type: 'servicio',
				journey_detail: 'start',
				journey_step: 'activacion roaming datos:start',
				event_name: 'start',
			},
		},
		sucess: {
			eventName: 'activacion roaming datos:ok',
			data: {
				journey_name: 'activacion roaming datos',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_options: 'activacion roaming datos:activado',
				journey_proccess: 'activar',
				journey_type: 'servicio',
				journey_detail: 'ok',
				journey_step: 'activacion roaming datos:ok',
				event_name: 'ok',
			},
		},
		error: {
			eventName: 'activacion roaming datos:ko',
			data: {
				event_name: 'ko',
				journey_name: 'activacion roaming datos',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_proccess: 'activar',
				journey_type: 'servicio',
				journey_detail: 'ko',
				journey_step: 'activacion roaming datos:ko',
				journey_error_category: '',
				journey_error_code: '',
				journey_error_description: '',
			},
		},
	},
	SuperwifiJourney: {
		oferta: {
			page: {
				page_name: '',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'superwifi',
				page_subcategory_level_2: 'solicitar',
				page_subcategory_level_3: '',
				page_subcategory_level_4: '',
				page_screen: 'solicitar',
			},
			data: {
				'&&events': '{type_event}',
				'journey_type': 'servicio',
				'journey_name': 'superwifi',
				'journey_category': '{client_typology}',
				'journey_subcategory': '{client_typology}',
				'journey_proccess': 'activar',
				'journey_option': 'superwifi',
				'journey_detail': 'solicitar',
				'journey_step': 'superwifi:solicitar',
				'entrypoint_section': '',
			},
			event: {
				event_name: 'click en {copy_button}',
				event_category: '{event_type}',
				event_context: 'solicitar superwifi',
				event_label: '{copy_button}',
				event_10: 'event10',
				event_deeplink: 'event10,event70',
				deeplink: 'deeplink',
			},
			state: {
				init_state: 'init',
				button_state: 'button',
				acordeon_state: 'acordeon',
				cond_state: 'link_condiciones',
				frec_state: 'link_pFrecuentes',
				error_funcional: 'error_funcional',
				error_tecnico: 'error_tecnico',
			},
			error: {
				page: {
					page_name: '',
					page_section: 'productos y servicios',
					page_subcategory_level_1: 'superwifi',
					page_subcategory_level_2: 'error',
					page_subcategory_level_3: '',
					page_subcategory_level_4: '',
					page_screen: 'error',
				},
				data: {
					'&&products': 'superwifi;superwifi {type};1;{product_price}',
					'&&events': 'event28',
					'journey_type': 'servicio',
					'journey_name': 'superwifi',
					'journey_category': '{client_typology}',
					'journey_subcategory': '{client_typology}',
					'journey_proccess': 'activar',
					'journey_detail': 'error',
					'journey_step': 'superwifi:error',
					'error_category': '{category}_{http_status}',
					'error_description': '{error_description}',
					'error_page': '',
					'error_type': 'funcional',
					'error_code': '400',
				},
				data_error: {
					category_tienda: 'trastienda',
					category_dxl: 'dxl',
					category_otros: 'otros',
					category_eFuncional: 'sw',
				},
				error_tecnico: {
					error_event: 'event100',
					error_subcategory_level_2: 'error',
					error_page_screen: 'error',
					error_type: 'tecnico',
				},
				error_funcional: {
					error_event: 'event102',
					error_subcategory_level_2: 'error funcional',
					error_page_screen: 'error funcional',
					error_type: 'funcional',
					error_code: '400',
				},
			},
		},
		installation: {
			page: {
				page_name: '',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'superwifi',
				page_subcategory_level_2: 'instalacion',
				page_subcategory_level_3: '',
				page_subcategory_level_4: '',
				page_screen: 'instalacion',
			},
			data: {
				'&&products': 'superwifi;{product_name};1;{product_price}',
				'journey_type': 'servicio',
				'journey_name': 'superwifi',
				'journey_category': '{client_typology}',
				'journey_subcategory': '{client_typology}',
				'journey_process': 'activar',
				'journey_options': 'ins:{option}',
				'journey_detail': 'instalacion',
				'journey_step': 'superwifi:instalacion',
			},
			options: {
				con_cita: 'con cita',
				sin_cita: 'sin cita',
			},
			states: {
				state_ins_con_cita: 'conCita',
				state_ins_sin_cita: 'sinCita',
				button_state: 'boton',
			},
			continueButton_event: {
				event_name: 'click en {copy_button}',
				event_label: '{copy_button}',
				event_category: 'boton',
			},
		},
		preconfirmacion: {
			page: {
				page_name: '',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'superwifi',
				page_subcategory_level_2: 'preconfirmacion',
				page_subcategory_level_3: '{subcategory}',
				page_subcategory_level_4: '',
				page_screen: '{page_screen}',
			},
			data: {
				'&&products': 'superwifi;{product_name};1;{product_price}',
				'journey_type': 'servicio',
				'journey_name': 'superwifi',
				'journey_category': '{client_typology}',
				'journey_subcategory': '{client_typology}',
				'journey_process': 'activar',
				'journey_detail': '{journey_detail}',
				'journey_step': '',
			},
			states: {
				state_preconfirm: 'preconfirmacion',
				button_cta: 'boton_cta',
				state_email: 'email',
				button_state: 'boton',
			},
			emailButton_event: {
				event_name: 'click en {copy_button}',
				event_category: 'boton',
				event_context: 'solicitud email',
				event_label: '{copy_button}',
			},
			preconfirm_button_event: {
				event_name: 'click en {copy_button}',
				event_category: 'boton',
				event_label: '{copy_button}',
			},
			preconfirm_parameters: {
				page_screen: 'preconfirmacion',
				journey_detail: 'preconfirmacion',
			},
			email_parameters: {
				page_screen: 'solicitud de email',
				journey_detail: 'solicitud de email',
			},
		},
		confirmacion: {
			page: {
				page_name: '',
				page_section: 'productos y servicios',
				page_subcategory_level_1: 'superwifi',
				page_subcategory_level_2: 'confirmacion',
				page_subcategory_level_3: '',
				page_subcategory_level_4: '',
				page_screen: 'confirmacion',
			},
			data: {
				'&&products': 'superwifi;{product_name};1;{product_price}',
				'journey_type': 'servicio',
				'journey_name': 'superwifi',
				'journey_category': '{client_typology}',
				'journey_subcategory': '{client_typology}',
				'journey_process': 'activar',
				'journey_detail': 'confirmacion',
				'journey_step': '',
			},
		},
	},
	icomingRoamingDeActivationJourney: {
		firstStep: {
			eventName: 'desactivacion roaming llamadas entrantes:start',
			data: {
				journey_name: 'desactivacion roaming llamadas entrantes',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_proccess: 'desactivar',
				journey_type: 'servicio',
				journey_detail: 'start',
				journey_step: 'desactivacion roaming llamadas entrantes:start',
				event_name: 'start',
			},
		},
		sucess: {
			eventName: 'desactivacion roaming llamadas entrantes:ok',
			data: {
				journey_name: 'desactivacion roaming llamadas entrantes',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_options: 'desactivacion roaming llamadas entrantes:activado',
				journey_proccess: 'desactivar',
				journey_type: 'servicio',
				journey_detail: 'ok',
				journey_step: 'desactivacion roaming llamadas entrantes:ok',
				event_name: 'ok',
			},
		},
		error: {
			eventName: 'desactivacion roaming llamadas entrantes:ko',
			data: {
				journey_name: 'desactivacion roaming llamadas entrantes',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_proccess: 'desactivar',
				journey_type: 'servicio',
				journey_detail: 'ko',
				journey_step: 'desactivacion roaming llamadas entrantes:ko',
				journey_error_category: '',
				journey_error_code: '',
				journey_error_description: '',
				event_name: 'ko',
			},
		},
	},
	RoamingActivationJourney: {
		firstStep: {
			eventName: 'activacion roaming llamadas y datos:start',
			data: {
				event_name: 'start',
				journey_name: 'activacion roaming llamadas y datos',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_proccess: 'activar',
				journey_type: 'servicio',
				journey_step: 'activacion roaming llamadas y datos:start',
				journey_detail: 'start',
			},
		},
		sucess: {
			eventName: 'activacion roaming llamadas y datos:ok',
			data: {
				event_name: 'ok',
				journey_name: 'activacion roaming llamadas y datos',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_options: 'activacion roaming llamadas y datos:activado',
				journey_proccess: 'activar',
				journey_type: 'servicio',
				journey_detail: 'ok',
				journey_step: 'activacion roaming llamadas y datos:ok',
			},
		},
		error: {
			eventName: 'activacion roaming llamadas y datos:ko',
			data: {
				event_name: 'ko',
				journey_name: 'activacion roaming llamadas y datos',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_proccess: 'activar',
				journey_type: 'servicio',
				journey_detail: 'ko',
				journey_step: 'activacion roaming llamadas y datos:ko',
				journey_error_category: '',
				journey_error_code: '',
				journey_error_description: '',
			},
		},
	},
	RoamingDeActivationJourney: {
		firstStep: {
			eventName: 'desactivacion roaming llamadas y datos:start',
			data: {
				journey_name: 'desactivacion roaming llamadas y datos',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_proccess: 'desactivar',
				journey_type: 'servicio',
				journey_step: 'desactivacion roaming llamadas y datos:start',
				journey_detail: 'start',
				event_name: 'start',
			},
		},
		sucess: {
			eventName: 'desactivacion roaming llamadas y datos:ok',
			data: {
				journey_name: 'desactivacion roaming llamadas y datos',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_options: 'desactivacion roaming llamadas y datos:activado',
				journey_proccess: 'desactivar',
				journey_type: 'servicio',
				journey_detail: 'ok',
				journey_step: 'desactivacion roaming llamadas y datos:ok',
				event_name: 'ok',
			},
		},
		error: {
			eventName: 'desactivacion roaming llamadas y datos:ko',
			data: {
				journey_name: 'desactivacion roaming llamadas y datos',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_proccess: 'desactivar',
				journey_type: 'servicio',
				journey_detail: 'ko',
				journey_step: 'desactivacion roaming llamadas y datos:ko',
				journey_error_category: '',
				journey_error_code: '',
				journey_error_description: '',
				event_name: 'ko',
			},
		},
	},
	outgoingDeActivationJourney: {
		firstStep: {
			eventName: 'desactivacion roaming llamadas salientes:start',
			data: {
				journey_name: 'desactivacion roaming llamadas salientes',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_proccess: 'desactivar',
				journey_type: 'servicio',
				journey_detail: 'start',
				journey_step: 'desactivacion roaming llamadas salientes:start',
				event_name: 'start',
			},
		},
		sucess: {
			eventName: 'desactivacion roaming llamadas salientes:ok',
			data: {
				journey_name: 'desactivacion roaming llamadas salientes',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_options: 'desactivacion roaming llamadas salientes:activado',
				journey_proccess: 'desactivar',
				journey_type: 'servicio',
				journey_detail: 'ok',
				journey_step: 'desactivacion roaming llamadas salientes:ok',
				event_name: 'ok',
			},
		},
		error: {
			eventName: 'desactivacion roaming llamadas salientes:ko',
			data: {
				journey_name: 'desactivacion roaming llamadas salientes',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_proccess: 'desactivar',
				journey_type: 'servicio',
				journey_detail: 'ko',
				journey_step: 'desactivacion roaming llamadas salientes:ko',
				journey_error_category: '',
				journey_error_code: '',
				journey_error_description: '',
				event_name: 'ko',
			},
		},
	},
	dataDeActivationJourney: {
		firstStep: {
			eventName: 'desactivacion roaming datos:start',
			data: {
				journey_name: 'desactivacion roaming datos',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_proccess: 'desactivar',
				journey_type: 'servicio',
				journey_detail: 'start',
				journey_step: 'desactivacion roaming datos:start',
				event_name: 'start',
			},
		},
		sucess: {
			eventName: 'desactivacion roaming datos:ok',
			data: {
				journey_name: 'desactivacion roaming datos',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_options: 'desactivacion roaming datos:activado',
				journey_proccess: 'desactivar',
				journey_type: 'servicio',
				journey_detail: 'ok',
				journey_step: 'desactivacion roaming datos:ok',
				event_name: 'ok',
			},
		},
		error: {
			eventName: 'desactivacion roaming datos:ko',
			data: {
				journey_name: 'desactivacion roaming datos',
				journey_category: '',
				journey_subcategory: 'pospago',
				journey_proccess: 'desactivar',
				journey_type: 'servicio',
				journey_detail: 'ko',
				journey_step: 'desactivacion roaming datos:ko',
				journey_error_category: '',
				journey_error_code: '',
				journey_error_description: '',
				event_name: 'ko',
			},
		},
	},
	RoamingFaqPage: {
		page_name: 'roaming:dudas cuando viajes al extranjero',
		page_section: 'roaming',
		page_subcategory_level_1: 'dudas cuando viajes al extranjero',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'dudas cuando viajes al extranjero',
		page_typology: 'principal',
	},
	RoamingFAQ: {
		extranjeroButton: {
			eventName: 'roaming:gestiona el roaming de tus lineas:acordeon:dudas cuando viajes al extranjero',
			data: {
				event_name: 'acordeon:dudas cuando viajes al extranjero',
				event_category: 'acordeon',
				event_context: 'cual es mi tarifa en el extranjero',
				event_label: 'dudas cuando viajes al extranjero',
			},
		},

		viajarButton: {
			eventName: 'roaming:dudas cuando viajes al extranjero:acordeon:necesito activar el roaming antes de viajar',
			data: {
				event_name: 'acordeon:necesito activar el roaming antes de viajar',
				event_category: 'acordeon',
				event_context: 'dudas cuando viajes al extranjero',
				event_label: 'necesito activar el roaming antes de viajar',
			},
		},

		tengodatosButton: {
			eventName:
				'roaming:dudas cuando viajes al extranjero:acordeon:estoy conectado a la red del pais pero no tengo datos',
			data: {
				event_name: 'acordeon:estoy conectado a la red del pais pero no tengo datos',
				event_category: 'acordeon',
				event_context: 'dudas cuando viajes al extranjero',
				event_label: 'estoy conectado a la red del pais pero no tengo datos',
			},
		},
		nacionalButton: {
			eventName:
				'roaming:dudas cuando viajes al extranjero:acordeon:esta incluido el pais al que viajas en tu tarifa nacional',
			data: {
				event_name: 'acordeon:esta incluido el pais al que viajas en tu tarifa nacional',
				event_category: 'acordeon',
				event_context: 'dudas cuando viajes al extranjero',
				event_label: 'esta incluido el pais al que viajas en tu tarifa nacional',
			},
		},

		lineaButton: {
			eventName: 'roaming:dudas cuando viajes al extranjero:acordeon:tengo alguna restriccion de roaming en mi linea',
			data: {
				event_name: 'acordeon:tengo alguna restriccion de roaming en mi linea',
				event_category: 'acordeon',
				event_context: 'dudas cuando viajes al extranjero',
				event_label: 'tengo alguna restriccion de roaming en mi linea',
			},
		},

		datosExtranjeroButton: {
			eventName: 'roaming:dudas cuando viajes al extranjero:acordeon:como puedo ahorrar datos en el extranjero',
			data: {
				event_name: 'acordeon:como puedo ahorrar datos en el extranjero',
				event_category: 'acordeon',
				event_context: 'dudas cuando viajes al extranjero',
				event_label: 'como puedo ahorrar datos en el extranjero',
			},
		},

		linkToVideo: {
			eventName: 'roaming:dudas cuando viajes al extranjero:como hacerlo',
			data: {
				event_name: 'como hacerlo',
				event_category: 'link',
				event_context: 'dudas cuando viajes al extranjero',
				event_label: 'como hacerlo',
			},
		},
	},
	roamingBundlesActionsZone2: {
		DeActiveMoudoBtnTray: {
			eventName: 'roaming:detalle tarifa desde zona 2:desactivar tarifa viaje mundo:clic en desactivar',
			data: {
				event_name: 'clic en desactivar',
				event_category: 'boton',
				event_context: 'desactivar tarifa viaje mundo',
				event_label: 'desactivar',
			},
		},
		DeActiveMoudoBtn: {
			eventName: 'roaming:gestiona el roaming de tus lineas:clic en desactivar',
			data: {
				event_name: 'clic en desactivar',
				event_category: 'boton',
				event_context: 'tarifa viaje mundo',
				event_label: 'desactivar',
			},
		},
		DeActiveMoudoBtnTrayCancel: {
			eventName: 'roaming:detalle tarifa desde zona 2:desactivar tarifa viaje mundo:clic en cancelar',
			data: {
				event_name: 'clic en cancelar',
				event_category: 'boton',
				event_context: 'desactivar tarifa viaje mundo',
				event_label: 'cancelar',
			},
		},
		ActiveMoudoBtn: {
			eventName: 'roaming:gestiona el roaming de tus lineas:clic en activar',
			data: {
				event_name: 'clic en activar',
				event_category: 'boton',
				event_context: 'tarifa viaje mundo',
				event_label: 'activar',
			},
		},
		ActiveMoudoBtnTray: {
			eventName: 'roaming:detalle tarifa desde zona 2:activar tarifa viaje mundo:clic en activar',
			data: {
				event_name: 'clic en activar',
				event_category: 'boton',
				event_context: 'activar tarifa viaje mundo',
				event_label: 'activar',
			},
		},
		ActiveMoudoBtnTrayCancel: {
			eventName: 'roaming:detalle tarifa desde zona 2:activar tarifa viaje mundo:clic en cancelar',
			data: {
				event_name: 'clic en cancelar',
				event_category: 'boton',
				event_context: 'activar tarifa viaje mundo',
				event_label: 'cancelar',
			},
		},
	},
	roamingBundlesJourneysZone2: {
		RoamingDeActiveBundleJourney: {
			firstStep: {
				eventName: 'desactivar tarifa viaje mundo:start',
				data: {
					journey_name: 'desactivar tarifa viaje mundo',
					journey_category: 'particulares',
					journey_subcategory: 'pospago',
					journey_options: '',
					journey_proccess: 'desactivar',
					journey_type: 'servicio',
					journey_detail: 'start',
					journey_step: 'desactivar tarifa viaje mundo:start',
				},
			},
			sucess: {
				eventName: 'desactivar tarifa viaje mundo:ok',
				data: {
					journey_name: 'desactivar tarifa viaje mundo',
					journey_category: 'particulares',
					journey_subcategory: 'pospago',
					journey_options: '',
					journey_proccess: 'desactivar',
					journey_type: 'servicio',
					journey_detail: 'ok',
					journey_step: 'desactivar tarifa viaje mundo:ok',
				},
			},
			error: {
				eventName: 'desactivar tarifa viaje mundo:ko',
				data: {
					journey_name: 'desactivar tarifa viaje mundo',
					journey_category: 'particulares',
					journey_subcategory: 'pospago',
					journey_proccess: 'desactivar',
					journey_type: 'servicio',
					journey_detail: 'ko',
					journey_step: 'desactivar tarifa viaje mundo:ko',
					journey_error_category: '',
					journey_error_description: '',
					journey_error_code: '',
				},
			},
		},
		RoamingActiveBundleJourney: {
			firstStep: {
				eventName: 'activar tarifa viaje mundo:start',
				data: {
					journey_name: 'activar tarifa viaje mundo',
					journey_category: 'particulares',
					journey_subcategory: 'pospago',
					journey_options: '',
					journey_proccess: 'activar',
					journey_type: 'servicio',
					journey_detail: 'start',
					journey_step: 'activar tarifa viaje mundo:start',
				},
			},
			sucess: {
				eventName: 'activar tarifa viaje mundo:ok',
				data: {
					journey_name: 'activar tarifa viaje mundo',
					journey_category: 'particulares',
					journey_subcategory: 'pospago',
					journey_options: '',
					journey_proccess: 'activar',
					journey_type: 'servicio',
					journey_detail: 'ok',
					journey_step: 'activar tarifa viaje mundo:ok',
				},
			},
			error: {
				eventName: 'roaming:detalle tarifa desde zona 2:activar tarifa viaje mundo:ko',
				data: {
					journey_name: 'activar tarifa viaje mundo',
					journey_category: 'particulares',
					journey_subcategory: 'pospago',
					journey_proccess: 'activar',
					journey_type: 'servicio',
					journey_detail: 'ko',
					journey_step: 'activar tarifa viaje mundo:ko',
					journey_error_category: '',
					journey_error_description: '',
					journey_error_code: '',
				},
			},
		},
	},
	roamingBundlesViewsZone2: {
		moundoDeActivtionTray: {
			page_name: 'roaming:detalle tarifa desde zona 2:desactivar tarifa viaje mundo',
			page_section: 'roaming',
			page_subcategory_level_1: 'detalle tarifa desde zona 2',
			page_subcategory_level_2: 'desactivar tarifa viaje mundo',
			page_subcategory_level_3: '',
			page_subcategory_level_4: '',
			page_screen: 'desactivar tarifa viaje mundo',
			page_typology: 'overlay',
		},
		moundoDeActivtionOkTray: {
			page_name: 'roaming:detalle tarifa desde zona 2:desactivar tarifa viaje mundo:ok',
			page_section: 'roaming',
			page_subcategory_level_1: 'detalle tarifa desde zona 2',
			page_subcategory_level_2: 'desactivar tarifa viaje mundo',
			page_subcategory_level_3: 'ok',
			page_subcategory_level_4: '',
			page_screen: 'ok',
			page_typology: 'overlay',
		},
		moundoDeActivtionKoTray: {
			page_name: 'roaming:detalle tarifa desde zona 2:desactivar tarifa viaje mundo:ko',
			page_section: 'roaming',
			page_subcategory_level_1: 'detalle tarifa desde zona 2',
			page_subcategory_level_2: 'desactivar tarifa viaje mundo',
			page_subcategory_level_3: 'ko',
			page_subcategory_level_4: '',
			page_screen: 'ko',
			page_typology: 'overlay',
		},
		moundoActivtionTray: {
			page_name: 'roaming:detalle tarifa desde zona 2:activar tarifa viaje mundo',
			page_section: 'roaming',
			page_subcategory_level_1: 'detalle tarifa desde zona 2',
			page_subcategory_level_2: 'activar tarifa viaje mundo',
			page_subcategory_level_3: '',
			page_subcategory_level_4: '',
			page_screen: '',
			page_typology: 'overlay',
		},
		moundoActivtionOkTray: {
			page_name: 'roaming:detalle tarifa desde zona 2:activar tarifa viaje mundo:ok',
			page_section: 'roaming',
			page_subcategory_level_1: 'detalle tarifa desde zona 2',
			page_subcategory_level_2: 'activar tarifa viaje mundo',
			page_subcategory_level_3: 'ok',
			page_subcategory_level_4: '',
			page_screen: 'ok',
			page_typology: 'overlay',
		},
		moundoActivtionKoTray: {
			page_name: 'roaming:detalle tarifa desde zona 2:activar tarifa viaje mundo:ko',
			page_section: 'roaming',
			page_subcategory_level_1: 'detalle tarifa desde zona 2',
			page_subcategory_level_2: 'activar tarifa viaje mundo',
			page_subcategory_level_3: 'ko',
			page_subcategory_level_4: '',
			page_screen: 'ko',
			page_typology: 'overlay',
		},
	},
	RoamingPage: {
		page_name: 'roaming:gestiona el roaming de tus lineas',
		page_section: 'roaming',
		page_subcategory_level_1: 'gestiona el roaming de tus lineas',
		page_screen: 'gestiona el roaming de tus lineas',
		page_typology: 'principal',
	},
	newLinesPurchase: {
		page: {
			page_name: '',
			page_section: 'transaccional',
			page_subcategory_level_1: 'microflujos',
			page_subcategory_level_2: 'lineas adicionales',
			page_subcategory_level_3: '',
			page_screen: 'oferta',
		},
		data: {
			'&&events': '',
			'&&products': '',
			'state_flow': 'microflujo',
			'is_main_flow': '',
			'journey_name': 'lineas adicionales',
			'journey_type': 'transaccional',
			'journey_category': '',
			'journey_subcategory': '',
			'client_typology': '',
			'journey_process': '',
			'journey_detail': 'oferta',
			'journey_step': 'lineas adicionales:{step}',
			'event_name': '',
			'event_category': '',
			'event_context': '',
			'event_label': '',
			'navigation_level_1': 'transaccional',
			'navigation_level_2': 'microflujos',
			'navigation_level_3': 'lineas adicionales',
			'navigation_level_4': 'oferta',
			'asset_name': 'mivoweb',
			'experience_name_test': '',
		},
		strings: {
			offer: 'oferta',
			event27: 'event27',
			event100: 'event100',
			autonomo: 'autonomos',
			parti: 'particular',
			selector: 'selector',
			type: 'tipo de alta',
			line_button_event: 'click en lo quiero',
			continue_button_event: 'click en continuar',
			category_button_event: 'boton',
			portability: 'portabilidad',
			new_line: 'alta nueva',
			new: 'nuevo',
			no_surprises_event: 'click en mas info sin sorpresas',
			no_surprises_info: 'informacion sin sorpresas',
			error: 'error',
			error_dxl: 'dxl',
			error_trastienda: 'trastienda',
			error_tecnico: 'tecnico',
			error_code: '1300',
			error_description: 'Invalidate pegaToken',
			events_prod_view: 'prodView',
			events_csAdd: 'csAdd',
			pega_offer: 'pega',
			public_offer: 'publica',
			open_overlay_permanence: 'overlayPermanence',
			month_permanence: 'click en permanencia 12 meses',
			init_view_offer: 'initViewOffer',
			click_overlay_permanence: 'click en compromisos y permanencias',
			category_overlay_permanence: 'link',
			context_overlay_permanence: 'compromisos lineas adicionales',
			context_legal_conditions: 'condiciones legales lineas adicionales',
			tariff: 'tarifa',
		},
		error: {
			error_category: '{error_category}',
			error_description: '{error_description}',
			error_type: '{error_type}',
			error_code: '{error_code}',
		},
		errorVFphone: {
			error_type: 'tipo de alta',
			error_line: 'error linea vodafone',
			error_page: 'error',
			error_code: ERRORCODES.VFPORTABILITY.ERROR137,
			error_desription: 'Operador donante Vodafone',
		},
		publicOffer: {
			page_name: 'transaccional:microflujos:lineas adicionales:oferta',
			page_section: 'transaccional',
			page_subcategory_level_1: 'microflujos',
			page_subcategory_level_2: 'lineas adicionales',
			page_screen: 'oferta',
			asset_name: 'mivoweb',
			page_platform: 'web',
			event_category: 'boton',
			event_context: 'oferta',
			event_label: 'click en mas info',
			journey_name: 'lineas adicionales',
			journey_process: '<type>',
			journey_step: 'lineas adicionales:oferta',
			is_main_flow: 'no',
			state_flow: 'microflujo',
		},
		bannerIPC: {
			page: {
				page_name: '',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'lineas adicionales',
				page_subcategory_level_3: '',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'lineas adicionales',
				navigation_level_4: 'oferta',
				page_screen: 'oferta',
				asset_name: 'mivoweb',
				page_platform: 'web',
			},
			event: {
				'event_category': 'banner',
				'event_context': 'ipc lineas adicionales',
				'event_label': '{0}',
				'&&events': '',
			},
			journey: {
				journey_name: 'lineas adicionales',
				journey_type: 'trasaccional',
				journey_category: '',
				journey_subcategory: '',
				journey_process: '',
				journey_detail: 'oferta',
				journey_step: 'lineas adicionales:oferta',
				is_main_flow: '',
				state_flow: 'microflujo',
			},
		},
		backdropDL: {
			page: {
				page_name: '',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'lineas adicionales',
				page_subcategory_level_3: '',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'lineas adicionales',
				navigation_level_4: 'oferta',
				page_screen: 'oferta',
				asset_name: 'mivoweb',
				page_platform: 'web',
			},
			eventImp: {
				'event_category': 'backdrop',
				'event_context': 'deeplink pega',
				'event_label': '{0}',
				'&&events': '',
			},
			eventClick: {
				'event_category': 'boton',
				'event_context': 'mas ofertas deeplink pega',
				'event_label': '{0}',
				'&&events': '',
			},
			journey: {
				journey_name: 'lineas adicionales',
				journey_type: 'trasaccional',
				journey_category: '',
				journey_subcategory: '',
				journey_process: '',
				journey_detail: 'oferta',
				journey_step: 'lineas adicionales:oferta',
				is_main_flow: '',
				state_flow: 'microflujo',
			},
		},
		continueToCheckout: {
			'page_name': 'transaccional:microflujos:lineas adicionales:detalle',
			'page_section': 'transaccional',
			'page_subcategory_level_1': 'microflujos',
			'page_subcategory_level_2': 'lineas adicionales',
			'navigation_level_1': 'transaccional',
			'navigation_level_2': 'microflujos',
			'navigation_level_3': 'lineas adicionales',
			'navigation_level_4': 'detalle',
			'page_screen': 'detalle',
			'asset_name': 'mivoweb',
			'page_platform': 'web',
			'event_category': 'boton',
			'event_context': 'detalle',
			'event_label': 'click en lo quiero',
			'&&events': 'scAdd',
			'journey_name': 'lineas adicionales',
			'journey_type': 'transaccional',
			'journey_detail': 'detalle',
			'journey_step': 'lineas adicionales:detalle',
			'state_flow': 'microflujo',
		},
		viewPDPClickDropdown: {
			page: {
				page_name: '',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'lineas adicionales',
				page_subcategory_level_3: '',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'lineas adicionales',
				navigation_level_4: 'detalle',
				page_screen: 'detalle',
				asset_name: 'mivoweb',
				page_platform: 'web',
			},
			eventClick: {
				'event_category': 'desplegable',
				'event_context': 'detalle',
				'event_label': 'click en sin sorpresas',
				'&&events': '',
			},
			journey: {
				journey_name: 'lineas adicionales',
				journey_type: 'transaccional',
				journey_category: '',
				journey_subcategory: '',
				journey_process: '',
				journey_detail: 'detalle',
				journey_step: 'lineas adicionales:detalle',
				is_main_flow: '',
				state_flow: 'microflujo',
			},
		},
		pdpGeneric: {
			page_name: 'transaccional:microflujos:lineas adicionales:detalle',
			page_section: 'transaccional',
			page_subcategory_level_1: 'microflujos',
			page_subcategory_level_2: 'lineas adicionales',
			navigation_level_1: 'transaccional',
			navigation_level_2: 'microflujos',
			navigation_level_3: 'lineas adicionales',
			navigation_level_4: 'detalle',
			page_screen: 'detalle',
			asset_name: 'mivoweb',
			page_platform: 'web',
			event_category: '{0}',
			event_context: 'detalle',
			event_label: '{0}',
			journey_name: 'lineas adicionales',
			journey_type: 'transaccional',
			journey_detail: 'detalle',
			journey_step: 'lineas adicionales:detalle',
			state_flow: 'microflujo',
		},
	},
	smartPayClickOnChangeButtonTags: {
		productsAndServices: {
			page: {
				page_name: 'mivoapp:productos y servicios:resumen de productos y servicios',
				asset_name: 'mivoweb',
				navigation_level_1: 'productos y servicios',
				navigation_level_2: 'resumen de productos y servicios',
				page_screen: 'resumen de productos y servicios',
			},
			data: {
				event_name: 'cambiar pago facil',
				event_category: 'boton',
				event_context: 'card pago facil {0}',
				event_label: 'cambiar',
			},
		},
	},
	bundleupsell: {
		offer: {
			data: {
				page_name: 'transaccional:contingencia:oferta',
				page_section: 'transaccional',
				page_subcategory_level_1: 'contingencia',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'contingencia',
				navigation_level_3: 'oferta',
				page_screen: 'oferta',
				asset_name: 'mivoweb',
			},
			journey: {
				'&&events': 'event27',
				'journey_name': 'contingencia',
				'journey_type': 'eservice',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_detail': 'oferta',
				'journey_step': 'contingencia:oferta',
				'journey_bundle': '',
			},
			entrypoint: {
				entrypoint_section: 'deeplink',
				entrypoint_location: '',
				entrypoint_position: '',
				entrypoint_title: '',
				entrypoint_type: 'pega',
				entrypoint_name: '',
				entrypoint_issue: '',
				entrypoint_label: '',
				entrypoint_groupnba: '',
			},
		},
		moreInfo: {
			event: {
				event_name: 'click en info {0}',
				event_category: 'boton',
				event_context: 'mas info contingencia',
				event_label: '{0}',
			},
			copy: {
				more_info_contingence: 'Mas info contingencia',
			},
		},
		commitmentsAndPermanence: {
			data: {
				page_name: '',
				page_section: 'transaccional',
				page_subcategory_level_1: 'contingencia',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'contingencia',
				navigation_level_3: 'oferta',
				asset_name: '',
			},
			event: {
				event_name: 'click en compromisos y permanencias',
				event_category: 'link',
				event_context: 'compromisos contingencia',
				event_label: '{0}',
			},
			journey: {
				journey_name: 'contingencia',
				journey_type: 'eservice',
				journey_category: '{0}',
				journey_subcategory: '{0}',
				journey_detail: 'oferta',
				journey_step: 'contingencia:oferta',
			},
			copy: {
				commitments: 'Compromisos',
				permanence: 'Permanencias',
			},
		},
		preconfirmation: {
			data: {
				page_name: 'transaccional:contingencia:preconfirmacion',
				page_section: 'transaccional',
				page_subcategory_level_1: 'contingencia',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'contingencia',
				navigation_level_3: 'preconfirmacion',
				page_screen: 'preconfirmacion',
				asset_name: 'mivoweb',
			},
			journey: {
				journey_name: 'contingencia',
				journey_type: 'eservice',
				journey_category: '',
				journey_subcategory: '',
				journey_detail: 'preconfirmacion',
				journey_step: 'contingencia:preconfirmacion',
			},
			terms: {
				event_name: 'click en terminos y condiciones',
				event_category: 'link',
				event_context: 'terminos y condiciones contingencia',
				event_label: '',
			},
		},
		confirmation: {
			data: {
				page_name: 'transaccional:contingencia:confirmacion',
				page_section: 'transaccional',
				page_subcategory_level_1: 'contingencia',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'contingencia',
				navigation_level_3: 'confirmacion',
				page_screen: 'confirmacion',
				asset_name: 'mivoweb',
			},
			journey: {
				'journey_name': 'contingencia',
				'journey_type': 'eservice',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_detail': 'confirmacion',
				'journey_step': 'contingencia:confirmacion',
				'&&events': 'event28',
			},
		},
		exit: {
			data: {
				page_name: '',
				page_section: 'transaccional',
				page_subcategory_level_1: 'contingencia',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'contingencia',
				navigation_level_3: 'exit',
				page_screen: 'exit',
				asset_name: 'mivoweb',
			},
			journey: {
				journey_name: 'contingencia',
				journey_type: 'eservice',
				journey_category: '{0}',
				journey_subcategory: '{0}',
				journey_detail: 'exit',
				journey_step: 'contingencia:exit',
			},
			continueProccess: {
				'event_name': 'continuar',
				'event_category': 'boton',
				'event_label': 'continuar',
				'&&events': 'event106',
			},
			exitProcess: {
				'event_name': 'abandonar',
				'event_category': 'boton',
				'event_label': 'abandonar',
				'&&events': 'event105',
			},
		},
		errors: {
			tecnicalError: {
				'page_name': '',
				'page_section': 'transaccional',
				'page_subcategory_level_1': 'contingencia',
				'navigation_level_1': 'transaccional',
				'navigation_level_2': 'contingencia',
				'navigation_level_3': 'error',
				'page_screen': 'error',
				'asset_name': 'mivoweb',
				'&&events': 'event100',
			},
			funtionError: {
				'page_name': '',
				'page_section': 'transaccional',
				'page_subcategory_level_1': 'contingencia',
				'navigation_level_1': 'transaccional',
				'navigation_level_2': 'contingencia',
				'navigation_level_3': 'error de entrypoint',
				'page_screen': 'error de entrypoint',
				'asset_name': 'mivoweb',
				'&&events': 'event102',
			},
			journey: {
				journey_name: 'contingencia',
				journey_type: 'eservice',
				journey_category: '{0}',
				journey_subcategory: '{0}',
			},
			error: {
				error_category: '{0}',
				error_descripcion: '{0}',
				error_type: '{0}' /* funcional o tecnico*/,
				error_code: '{0}',
			},
		},
		deepLink: {
			entrypoint_section: '{0}',
			entrypoint_location: '{0}',
			entrypoint_position: '{0}',
			entrypoint_title: '{0}',
			entrypoint_type: '{0}',
			entrypoint_name: '{0}',
			entrypoint_issue: '{0}',
			entrypoint_label: '{0}',
			entrypoint_groupnba: '{0}',
			experience_name: '{0}',
		},
		transsational: {
			discount_code: '{0}',
			new_discount_code: '{0}',
			discount_expiration: '{0}',
			discount_value_change: '{0}',
		},
		initDMP: {
			data: {
				page_name: '',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'bundle upsell',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'bundle upsell',
				navigation_level_4: 'acceso',
				page_screen: 'acceso',
				asset_name: 'mivoweb',
			},
			journey: {
				journey_name: 'bundle upsell',
				journey_type: 'transaccional',
				journey_category: '',
				journey_subcategory: '',
				journey_detail: 'acceso',
				journey_step: 'bundle upsell:acceso',
				state_flow: 'microflujo',
			},
		},
		backdropDL: {
			eventClickCTA: {
				event_name: 'click en descubrir ofertas',
				event_category: 'boton',
				event_context: 'mas ofertas deeplink pega',
				event_label: '',
			},
			eventView: {
				event_name: 'visualizacion backdrop deeplink',
				event_category: 'backdrop',
				event_context: 'deeplink pega',
				event_label: '',
			},
		},
	},
	PegaCrossSellLineas: {
		page: {
			page_name: '',
			asset_name: 'mivoweb',
			navigation_level_1: 'productos y servicios',
			navigation_level_2: 'hogar ilimitable',
			navigation_level_3: 'configurar lineas moviles',
			navigation_level_4: '{navigation_level_4}',
			navigation_level_5: '{navigation_level_5}',
			page_screen: '{page_screen}',
		},
		data: {
			journey_type: 'transaccional',
			journey_name: 'hogar ilimitable',
			journey_category: '{client_typology}',
			journey_subcategory: 'pospago',
			journey_process: 'activar',
			journey_options: 'hogar ilimitable {op}',
		},
		event: {
			event_name: '',
			event_category: '{event_category}',
			event_context: '{event_context}',
			event_label: '',
		},
		common: {
			linea: 'linea{num}',
			context: 'configurar lineas moviles',
			cat_boton: 'boton',
			cat_radio_button: 'radio button',
			personal_data: 'datos personales',
			close: 'cerrar',
			contrato: 'contrato',
			prepago: 'prepago',
			click: 'click ',
			click_en: 'click en ',
		},
		names: {
			new_num_name: 'necesito un numero nuevo ',
			old_num_name: 'mantener numero ',
			cta_save_name: 'guardar datos personales',
			cta_close_name: 'cerrar datos personales',
		},
		states: {
			state_page: 'page',
			state_saveData: 'newNum',
			state_cta_save: 'save',
			state_cta_close: 'close',
		},
	},
	MobileToPack: {
		offer: {
			data: {
				experience_name: '',
				page_name: 'transaccional:microflujos:bundle upsell:oferta',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'bundle upsell',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'bundle upsell',
				navigation_level_4: 'oferta',
				page_screen: 'oferta',
				asset_name: 'mivoweb',
				page_platform: 'web',
			},
			journey: {
				'&&events': 'event27,prodView',
				'journey_name': 'bundle upsell',
				'journey_type': '{transaccional}',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_detail': 'oferta',
				'journey_step': 'bundle upsell:oferta',
				'state_flow': 'microflujo',
				'journey_numoffers': 0,
				'journey_position': 0,
				'journey_bundle': '',
			},
			entrypoint: {
				entrypoint_section: 'deeplink',
				entrypoint_location: '',
				entrypoint_position: '',
				entrypoint_title: '',
				entrypoint_type: 'pega',
				entrypoint_name: '',
				entrypoint_issue: '',
				entrypoint_label: '',
				entrypoint_groupnba: '',
			},
			event: {
				'event_name': 'click en lo quiero',
				'event_category': 'boton',
				'event_label': '',
				'event_context': 'bundle upsell',
				'&&events': 'scAdd',
			},
		},
		configuration: {
			data: {
				page_name: 'transaccional:microflujos:bundle upsell:tipo de alta',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'bundle upsell',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'bundle upsell',
				navigation_level_4: 'tipo de alta',
				page_screen: 'tipo de alta',
				asset_name: 'mivoweb',
				page_platform: 'web',
			},
			journey: {
				journey_name: 'bundle upsell',
				journey_type: '{transaccional}',
				journey_category: '',
				journey_subcategory: '',
				journey_detail: 'tipo de alta',
				journey_step: 'bundle upsell:tipo de alta',
				journey_process: '',
				state_flow: 'microflujo',
			},
			clickType: {
				event_name: 'click en {0}',
				event_category: 'selector',
				event_context: '{0}',
				event_label: '',
			},
			clickAccept: {
				'event_name': 'click en continuar',
				'event_category': 'boton',
				'event_context': 'tipo de alta bundle upsell',
				'event_label': '',
				'&&events': 'scAdd',
			},
			clickBackdropQuestions: {
				event_name: 'click en {0}',
				event_category: 'link',
				event_label: '{0}',
				event_context: '{0}',
			},
			clickOpacityDeco: {
				event_name: 'seleccion deco',
				event_category: 'visualizacion',
				event_context: 'deco bundle upsell',
				event_label: 'seleccion deco',
			},
		},
		errors: {
			tecnicalError: {
				'page_name': 'transaccional:microflujos:bundle upsell:error',
				'page_section': 'transaccional',
				'page_subcategory_level_1': 'microflujos',
				'page_subcategory_level_2': 'bundle upsell',
				'navigation_level_1': 'transaccional',
				'navigation_level_2': 'microflujos',
				'navigation_level_3': 'bundle upsell',
				'navigation_level_4': 'error',
				'page_screen': 'error',
				'asset_name': 'mivoweb',
				'page_platform': 'web',
				'&&events': 'event100',
			},
			funtionError: {
				'page_name': 'transaccional:microflujos:bundle upsell:error funcional',
				'page_section': 'transaccional',
				'page_subcategory_level_1': 'microflujos',
				'page_subcategory_level_2': 'bundle upsell',
				'navigation_level_1': 'transaccional',
				'navigation_level_2': 'microflujos',
				'navigation_level_3': 'bundle upsell',
				'navigation_level_4': 'error',
				'page_screen': 'error',
				'asset_name': 'mivoweb',
				'page_platform': 'web',
				'&&events': 'event102',
			},
			journey: {
				journey_name: 'bundle upsell',
				journey_type: 'transaccional',
				journey_category: '{0}',
				journey_subcategory: '{0}',
			},
			error: {
				error_category: '{0}',
				error_descripcion: '{0}',
				error_type: '{0}',
				error_code: '{0}',
			},
			eventCTC: {
				event_name: 'click en ctc',
				event_category: 'boton',
				event_context: 'ctc oc bundle upsell',
				event_label: '{0}',
			},
			eventCTCshop: {
				event_name: 'click en ir a tienda',
				event_category: 'boton',
				event_context: 'ir a tienda bundle upsell',
				event_label: '{0}',
			},
		},
		deepLink: {
			entrypoint_section: '{0}',
			entrypoint_location: '{0}',
			entrypoint_position: '{0}',
			entrypoint_title: '{0}',
			entrypoint_type: '{0}',
			entrypoint_name: '{0}',
			entrypoint_issue: '{0}',
			entrypoint_label: '{0}',
			entrypoint_groupnba: '{0}',
			experience_name: '{0}',
		},
		transactional: {
			'&&products': '',
			'product_connection_type': '',
			'visitor_donor_mobile': '',
			'new_discount_code': '{0}',
			'discount_expiration': '{0}',
			'discount_value_change': '{0}',
			'transaction_cart_id': '',
		},
		commitmentsAndPermanence: {
			data: {
				page_name: '',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'bundle upsell',
				navigation_level_4: 'oferta',
				page_screen: 'oferta',
				asset_name: 'mivoweb',
				page_platform: 'web',
			},
			event: {
				event_name: 'click en compromisos y permanencias',
				event_category: 'link',
				event_context: 'compromisos bundle upsell',
				event_label: '{0}',
			},
			journey: {
				journey_name: 'bundle upsell',
				journey_type: 'transaccional',
				journey_category: '{0}',
				journey_subcategory: '{0}',
				journey_detail: 'oferta',
				journey_step: 'bundle upsell:oferta',
				state_flow: 'microflujo',
			},
			copy: {
				commitments: 'Compromisos',
				permanence: 'Permanencias',
			},
		},
		moreInfo: {
			event: {
				event_name: 'click en resumen',
				event_category: 'link',
				event_context: 'resumen bundle upsell',
				event_label: '{0}',
			},
			journey: {
				journey_name: 'bundle upsell',
				journey_type: 'transaccional',
				journey_category: '{0}',
				journey_subcategory: '{0}',
				journey_detail: 'oferta',
				journey_step: 'bundle upsell:oferta',
				state_flow: 'microflujo',
			},
			copy: {
				commitments: 'Compromisos',
				permanence: 'Permanencias',
			},
		},
		legalTerms: {
			event: {
				event_category: 'link',
				event_context: 'condiciones legales bundle upsell',
				event_label: '{0}',
			},
			journey: {
				journey_name: 'bundle upsell',
				journey_type: 'transaccional',
				journey_category: '{0}',
				journey_subcategory: '{0}',
				journey_detail: 'oferta',
				journey_step: 'bundle upsell:oferta',
				state_flow: 'microflujo',
			},
		},
		installation: {
			data: {
				page_name: 'transaccional:microflujos:checkout:instalacion',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'bundle upsell',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'checkout',
				navigation_level_4: 'instalacion',
				page_screen: 'instalacion',
				asset_name: 'mivoweb',
				page_platform: 'web',
			},
			journey: {
				journey_name: '',
				journey_type: 'transaccional',
				journey_category: '{0}',
				journey_subcategory: '{0}',
				journey_options: 'ins:{0}',
				journey_detail: 'instalacion',
				journey_step: 'transacciona:instalacion',
				state_flow: 'checkout',
				installation_type: '{0}',
			},
		},
		exit: {
			data: {
				page_name: 'transaccional:microflujos:bundle upsell:exit',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'bundle upsell',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'bundle upsell',
				navigation_level_4: 'exit',
				page_screen: 'exit',
				asset_name: 'mivoweb',
				page_platform: 'web',
			},
			journey: {
				journey_name: 'bundle upsell',
				journey_type: 'transaccional',
				journey_category: '{0}',
				journey_subcategory: '{0}',
				journey_detail: 'exit',
				journey_step: 'bundle upsell:exit',
				state_flow: 'microflujo',
			},
			continueProccess: {
				'event_name': 'click en continuar',
				'event_category': 'boton',
				'event_label': 'continuar',
				'&&events': 'event106',
			},
			exitProcess: {
				'event_name': 'click en abandonar',
				'event_category': 'boton',
				'event_label': 'abandonar',
				'&&events': 'event105',
			},
		},
		preConfirmation: {
			data: {
				page_name: '',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'bundle upsell',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'bundle upsell',
				navigation_level_4: 'preconfirmacion',
				page_screen: 'preconfirmacion',
				asset_name: 'mivoweb',
				page_platform: 'web',
			},
			journey: {
				journey_name: 'bundle upsell',
				journey_type: 'eservice',
				journey_category: '',
				journey_subcategory: '',
				journey_detail: 'preconfirmacion',
				journey_step: 'bundle upsell:preconfirmacion',
				state_flow: 'microflujo',
			},
			event: {
				event_name: 'click en terminos y condiciones',
				event_category: 'link',
				event_context: 'terminos y condiciones bundle upsell',
				event_label: '',
			},
		},
		confirmation: {
			data: {
				page_name: 'transaccional:microflujos:bundle upsell:confirmacion',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'bundle upsell',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'bundle upsell',
				navigation_level_4: 'confirmacion',
				page_screen: 'confirmacion',
				asset_name: 'mivoweb',
				page_platform: 'web',
				purchaseID: '{0}',
			},
			journey: {
				'journey_name': 'bundle upsell',
				'journey_type': 'eservice',
				'journey_category': '',
				'journey_subcategory': '',
				'journey_detail': 'confirmacion',
				'journey_step': 'bundle upsell:confirmacion',
				'state_flow': 'microflujo',
				'&&events': 'event28,purchase',
			},
		},
		backdropLA: {
			eventView: {
				event_name: 'añadir promocion',
				event_category: 'backdrop',
				event_context: 'promocion bundle upsell',
				event_label: 'visualizacion',
			},
			eventContinueProcess: {
				event_name: '',
				event_category: 'boton',
				event_context: 'promocion bundle upsell',
				event_label: '',
			},
			eventExitProcess: {
				event_name: 'click en cerrar',
				event_category: 'backdrop',
				event_context: 'promocion bundle upsell',
				event_label: 'cerrar',
			},
		},
		offersPlusX2: {
			eventClickViewMoreOffers: {
				event_name: 'click en ver mas ofertas',
				event_category: 'boton',
				event_context: 'mas ofertas bundle upsell',
				event_label: '',
			},
			eventViewMoreOffers: {
				'event_name': 'mas ofertas one',
				'event_category': 'visualizacion',
				'event_context': 'mas ofertas bundle upsell',
				'event_label': '',
				'&&events': 'prodView',
			},
			journeyViewMoreOffers: {
				journey_name: 'bundle upsell',
				journey_type: 'transaccional',
				journey_category: '',
				journey_subcategory: '',
				journey_detail: 'oferta',
				journey_step: 'bundle upsell:oferta',
				state_flow: 'microflujo',
				journey_numoffers: 0,
			},
		},
		pdp: {
			data: {
				page_name: '',
				page_section: 'transaccional',
				page_subcategory_level_1: 'microflujos',
				page_subcategory_level_2: 'bundle upsell',
				navigation_level_1: 'transaccional',
				navigation_level_2: 'microflujos',
				navigation_level_3: 'bundle upsell',
				navigation_level_4: 'detalle oferta',
				page_screen: 'detalle oferta',
				asset_name: 'mivoweb',
				page_platform: 'web',
			},
			event: {
				'&&events': 'prodView',
			},
			eventClick: {
				event_name: 'click en lo quiero',
				event_category: 'boton',
				event_context: 'lo quiero detalle bundle upsell',
				event_label: '{0}',
			},
			eventClickPermanence: {
				event_name: 'click en permanencia',
				event_category: 'acordeon',
				event_context: 'permanencia bundle upsell',
				event_label: '{0}',
			},
			eventClickLegalTerms: {
				event_name: 'click en condiciones legales',
				event_category: 'acordeon',
				event_context: 'condiciones legales bundle upsell',
				event_label: '{0}',
			},
			journey: {
				journey_name: 'bundle upsell',
				journey_type: '{transaccional}',
				journey_category: '',
				journey_subcategory: '',
				journey_detail: 'detalle oferta',
				journey_step: 'bundle upsell:detalle oferta',
				state_flow: 'microflujo',
				journey_position: 0,
				journey_numoffers: 0,
				journey_bundle: '',
				journey_offer_diff: '',
			},
			transactional: {
				'&&products':
					'movil+fibra;{0};1;;;eVar180=undefined|eVar181={1}|eVar182={2}|eVar200={3}|eVar187=producto principal:fide|eVar188=primario|eVar184={4}|eVar207={5}|eVar214={6}',
				'new_discount_code': '{0}',
				'discount_expiration': '{0}',
				'discount_value_change': '{0}',
				'transaction_cart_id': '{0}',
			},
		},
		backdropDL: {
			eventClickCTA: {
				event_name: 'click en descubrir ofertas',
				event_category: 'boton',
				event_context: 'mas ofertas deeplink pega',
				event_label: '',
			},
			eventView: {
				event_name: 'visualizacion backdrop deeplink',
				event_category: 'backdrop',
				event_context: 'deeplink pega',
				event_label: '',
			},
		},
		tags: {
			eventClickTag: {
				'event_name': 'click en {0}',
				'event_category': 'selector',
				'event_context': 'playlist bundle upsell',
				'event_label': '',
				'&&events': 'event133',
				'product_playlist_name': '',
			},
			eventPreselectedTag: {
				event_name: 'preseleccion {0}',
				event_category: 'preseleccion',
				event_context: 'playlist bundle upsell',
				event_label: '',
				product_playlist_name: '',
			},
		},
	},
	dispacherCapta: {
		data: {
			page_name: '',
			page_section: 'transaccional',
			page_subcategory_level_1: 'microflujos',
			page_subcategory_level_2: 'bundle upsell',
			navigation_level_1: 'transaccional',
			navigation_level_2: 'microflujos',
			navigation_level_3: 'bundle upsell',
			navigation_level_4: 'error trastienda',
			page_screen: 'error trastienda',
			asset_name: 'mivoweb',
			page_platform: 'web',
		},
		event: {
			'event_name': 'redirección a pública',
			'event_category': 'link',
			'event_label': '',
			'event_context': 'redirección bundle upsell',
			'url_origin': '{0}',
			'url_destination': '{0}',
			'&&events': 'event140',
		},
	},
	smartpayPSLoadingTags: {
		productsAndServices: {
			page: {
				page_name: 'mivoapp:productos y servicios:pago facil:servicio activo',
				asset_name: 'mivoweb',
				navigation_level_1: 'productos y servicios',
				navigation_level_2: 'pago facil',
				navigation_level_3: 'servicio activo',
				page_screen: 'servicio activo',
			},
		},
	},
	smartpayDeactivateClickTags: {
		productsAndServices: {
			page: {
				page_name: 'mivoapp:productos y servicios:pago facil:servicio activo',
				asset_name: 'mivoweb',
				navigation_level_1: 'prepago',
				navigation_level_2: 'pago facil',
				navigation_level_3: 'servicio activo',
				page_screen: 'servicio activo',
			},
			data: {
				event_name: 'desactivar pago facil',
				event_category: 'boton',
				event_context: 'card pago facil',
				event_label: 'desactivar',
			},
		},
	},
	smartpayActivateScreenTags: {
		page: {
			page_name: 'mivoapp:productos y servicios:pago facil:ok',
			asset_name: 'mivoweb',
			navigation_level_1: 'productos y servicios',
			navigation_level_2: 'pago facil',
			navigation_level_3: 'ok',
			page_screen: 'ok',
		},
		journey: {
			journey_name: 'pago facil',
			journey_category: 'particulares',
			journey_subcategory: 'prepago',
			journey_type: 'servicio',
			journey_detail: 'ok',
			journey_step: 'pago facil:ok',
		},
		overlayData: {
			data: {
				'&&events': 'event28',
			},
		},
		clickOnCloseTray: {
			eventName: 'mivoapp:productos y servicios:pago facil:ok:cerrar',
			data: {
				event_name: 'cerrar',
				event_category: 'boton',
				event_label: 'cerrar',
			},
		},
		clickOnCTA: {
			eventName: 'mivoapp:productos y servicios:pago facil:ok:entendido',
			data: {
				event_name: 'entendido',
				event_category: 'boton',
				event_label: 'entendido',
			},
		},
	},
	smartPayNoBenefitsTags: {
		noBenefits: {
			page: {
				page_name: 'mivoapp:prepago:pago facil tarifa sin beneficios info',
				asset_name: 'mivoweb',
				navigation_level_1: 'prepago',
				navigation_level_2: 'pago facil tarifa sin beneficios info',
				page_screen: 'pago facil tarifa sin beneficios info',
			},
			data: {
				event_name: 'cambiar de tarifa',
				event_category: 'boton',
				event_label: 'cambiar de tarifa',
			},
		},
	},
	smartPayInactiveBenefitsTags: {
		inactiveBenefits: {
			page: {
				page_name: 'mivoapp:prepago:pago facil beneficios no activos info',
				asset_name: 'mivoweb',
				navigation_level_1: 'prepago',
				navigation_level_2: 'pago facil beneficios no activos info',
				page_screen: 'pago facil beneficios no activos info',
			},
			data: {
				event_name: 'ir a recargas',
				event_category: 'boton',
				event_label: 'ir a recargas',
			},
		},
	},
	smartPayComingSoonTags: {
		comingSoon: {
			page: {
				page_name: 'mivoapp:prepago:pago facil info',
				asset_name: 'mivoweb',
				navigation_level_1: 'prepago',
				navigation_level_2: 'pago facil info',
				page_screen: 'pago facil info',
			},
		},
		smartPayUnavailableTags: {
			unAvailable: {
				page: {
					page_name: 'mivoapp:productos y servicios:pago facil:ko',
					asset_name: 'mivoweb',
					navigation_level_1: 'productos y servicios',
					navigation_level_2: 'pago facil',
					navigation_level_3: 'ko',
					page_screen: 'ko',
					error_list:
						'et=tecnico:indisponibilidad|ec=<netplus error code/dxl error code>|em=pagina|ed=<netplus url key "mensaje">',
				},
				data: {
					'&&events': 'event101',
					'journey_name': 'pago facil',
					'journey_category': 'particulares',
					'journey_subcategory': 'prepago',
					'journey_type': 'servicio',
					'journey_detail': 'ko',
					'journey_step': 'pago facil:ko',
				},
			},
		},
	},
	exceedResendlimit: 'maximo numero de clics en reenviar codigo otp excedido',
	exceedvalidationLimit: 'maximo numero intentos excedido',
	smartPayUnavailableTags: {
		unAvailable: {
			page: {
				page_name: 'mivoapp:productos y servicios:pago facil:ko',
				asset_name: 'mivoweb',
				navigation_level_1: 'productos y servicios',
				navigation_level_2: 'pago facil',
				navigation_level_3: 'ko',
				page_screen: 'ko',
				error_list:
					'et=tecnico:indisponibilidad|ec=<netplus error code/dxl error code>|em=pagina|ed=<netplus url key "mensaje">',
			},
			data: {
				'&&events': 'event101',
				'journey_name': 'pago facil',
				'journey_category': 'particulares',
				'journey_subcategory': 'prepago',
				'journey_type': 'servicio',
				'journey_detail': 'ko',
				'journey_step': 'pago facil:ko',
			},
		},
	},
	smartPayNoPaymentRechargeClickTags: {
		noCreditCard: {
			page: {
				page_name: 'mivoapp:productos y servicios:pago facil:servicio no activo',
				asset_name: 'mivoweb',
				navigation_level_1: 'productos y servicios',
				navigation_level_2: 'pago facil',
				navigation_level_3: 'servicio no activo',
				page_screen: 'servicio no activo',
			},
			data: {
				event_name: 'hacer una recarga',
				event_category: 'link',
				event_context: 'card {0}',
				event_label: 'hacer una recarga',
			},
		},
	},
	smartPayChangePaymentMethodClickTags: {
		changePaymentMethod: {
			page: {
				page_name: 'mivoapp:productos y servicios:pago facil:servicio activo',
				asset_name: 'mivoweb',
				navigation_level_1: 'productos y servicios',
				navigation_level_2: 'pago facil',
				navigation_level_3: 'servicio activo',
				page_screen: 'servicio activo',
			},
			data: {
				event_name: 'cambiar',
				event_category: 'link',
				event_context: 'card tu nuevo metodo de pago',
				event_label: 'cambiar',
			},
		},
	},
	myOrders: {
		overlayCloseSignProcess: {
			view: {
				page_name: 'mivoweb:mis pedidos:contratos pendientes de firma:cierre de proceso firma digital',
				asset_name: 'mivoweb',
				navigation_level_1: 'mis pedidos',
				navigation_level_2: 'contratos pendientes de firma',
				navigation_level_3: 'cierre de proceso firma digital',
				page_screen: 'cierre de proceso firma digital',
			},
			clickClose: {
				eventName: 'mivoweb:mis pedidos:contratos pendientes de firma:cierre de proceso firma digital:cerrar',
				data: {
					event_name: 'cerrar',
					event_action: 'click',
					event_category: 'boton',
					event_context: 'firma digital',
					event_label: 'X',
				},
			},
			clickStay: {
				eventName:
					'mivoweb:mis pedidos:contratos pendientes de firma:cierre de proceso firma digital:continuar firma digital',
				data: {
					event_name: 'continuar firma digital',
					event_action: 'click',
					event_category: 'boton',
					event_context: 'firma digital',
					event_label: '',
				},
			},
			clickLeave: {
				eventName:
					'mivoweb:mis pedidos:contratos pendientes de firma:cierre de proceso firma digital:salir proceso firma digital',
				data: {
					event_name: 'salir proceso firma digital',
					event_action: 'click',
					event_category: 'boton',
					event_context: 'firma digital',
					event_label: '',
				},
			},
		},
		overlayMaxOTP: {
			view: {
				page_name: 'mivoweb:mis pedidos:informacion digitel:numero intentos otp superado',
				page_section: 'mis pedidos',
				page_subcategory_level_1: 'informacion digitel',
				page_subcategory_level_2: 'numero intentos otp superado',
				asset_name: 'mivoweb',
				navigation_level_1: 'mis pedidos',
				navigation_level_2: 'informacion digitel',
				navigation_level_3: 'numero intentos otp superado',
				page_screen: 'numero intentos otp superado',
			},
			clickClose: {
				eventName: 'mivoweb:mis pedidos:informacion digitel:numero intentos otp superado:click en cerrar',
				data: {
					event_name: 'click en cerrar',
					event_action: 'click',
					event_category: 'boton',
					event_context: 'iframe firma digital',
					event_label: 'click en cerrar',
				},
			},
			clickUnderstood: {
				eventName: 'mivoweb:mis pedidos:informacion digitel:numero intentos otp superado:click en entendido',
				data: {
					event_name: 'click en entendido',
					event_action: 'click',
					event_category: 'boton',
					event_context: 'iframe firma digital',
					event_label: 'click en entendido',
				},
			},
		},
		redirectToSignAfterSiteCheck: {
			eventName: 'mivoweb:firma digital:redireccion sites:acceder firma digital 2',
			data: {
				page_name: 'mivoweb:firma digital:redireccion sites',
				asset_name: 'mivoweb',
				navigation_level_1: 'firma digital',
				navigation_level_2: 'redireccion sites',
				page_screen: 'redireccion sites',
				event_name: 'acceder firma digital 2',
				event_label: 'acceder firma digital 2',
				event_action: 'redireccion',
				event_category: 'visualizacion',
				event_context: 'sites to firma digital',
			},
		},
		signIframeEvent: {
			eventName: 'mivoweb:mis pedidos:informacion digitel:{event_id}',
			data: {
				page_name: 'mivoweb:mis pedidos:informacion digitel',
				asset_name: 'mivoweb',
				navigation_level_1: 'mis pedidos',
				navigation_level_2: 'informacion digitel',
				page_screen: 'informacion digitel',
				event_name: '{event_id}',
				event_action: 'eventos digitel',
				event_category: '{contract_type}',
				event_context: 'iframe firma digital',
				event_label: '{contract_id}',
			},
		},
	},
	smartPayManagementOnViewTags: {
		smartPayManagement: {
			page: {
				page_name: 'mivoapp:mi cuenta:pago facil',
				asset_name: 'mivoweb',
				navigation_level_1: 'mi cuenta',
				navigation_level_2: 'pago facil',
			},
		},
	},
	smartPayManagementOnClickTags: {
		smartPayManagementLineClick: {
			page: {
				page_name: 'mivoapp:mi cuenta:pago facil',
				asset_name: 'mivoweb',
				navigation_level_1: 'mi cuenta',
				navigation_level_2: 'pago facil',
				page_screen: 'pago facil',
			},
			data: {
				event_name: 'linea prepago',
				event_category: 'boton',
				event_label: 'linea prepago',
			},
		},
		smartPayManagementHistoryClick: {
			page: {
				page_name: 'mivoapp:mi cuenta:pago facil',
				asset_name: 'mivoweb',
				navigation_level_1: 'mi cuenta',
				navigation_level_2: 'pago facil',
				page_screen: 'pago facil',
			},
			data: {
				event_name: 'historico de pago facil',
				event_category: 'boton',
				event_label: 'historico de pago facil',
			},
		},
	},
	smartPayTopUpHistoryOnViewTags: {
		smartPayTopUpHistory: {
			page: {
				page_name: 'mivoapp:mi cuenta:pago facil',
				asset_name: 'mivoweb',
				navigation_level_1: 'mi cuenta',
				navigation_level_2: 'pago facil',
				navigation_level_3: 'historico',
				page_screen: 'historico',
			},
		},
	},
	zoansDeatilsRoaming: {
		page_name: 'roaming:zonas roaming',
		page_section: 'roaming',
		page_subcategory_level_1: 'zonas roaming',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'zonas roaming',
		page_typology: 'principal',
	},
	zonaroaming: {
		page_name: 'roaming:zonas roaming',
		page_section: 'roaming',
		page_subcategory_level_1: 'zonas roaming',
		page_subcategory_level_2: '',
		page_subcategory_level_3: '',
		page_screen: 'zonas roaming',
		page_typology: 'principal',
	},
	clcWhatRoamingZonesExist: {
		eventName: 'roaming:gestiona el roaming de tus lineas:Es el roaming igual en todo el mundo',
		data: {
			event_name: 'acordeon:Es el roaming igual en todo el mundo',
			event_category: 'acordeon',
			event_context: 'cual es mi tarifa en el extranjero',
			event_label: 'dudas cuando viajes al extranjero',
		},
	},
	clcZonaRoaming: {
		eventName: 'roaming:zonas roaming:clic en zona {0}',
		data: {
			event_name: 'clic en zona {0}',
			event_category: 'boton',
			event_context: 'zonas roaming',
			event_label: 'zona {0}',
		},
	},
	clcCountryZona: {
		eventName: 'roaming:zonas roaming:clic en {0}',
		data: {
			event_name: 'clic en {0}',
			event_category: 'boton',
			event_context: 'zonas roaming',
			event_label: '{0}',
		},
	},
	nbaCardTile: {
		eventName: 'dashboard:home:click en saber mas',
		data: {
			event_name: 'click en saber mas',
		},
	},
	MasInfoNBA: {
		eventName: 'dashboard:home:amplia tus limites:click en mas info',
		data: {
			event_name: 'click en mas info',
		},
	},
	dashboardStoriesOnClickTags: {
		dashboardStoryClick: {
			page_name: 'dashboard:home',
			page_section: 'dashboard',
			page_screen: 'home',
			page_typology: 'principal',
			event_name: 'dashboardStoryClick',
			event_category: 'stories',
			event_label: '<id>:<title>',
			event_context: '<story type> click',
			site_section: 'dashboard',
			page_detail: 'home',
		},
		dashboardButtonClick: {
			page_name: 'dashboard:home',
			page_section: 'dashboard',
			page_screen: 'home',
			page_typology: 'principal',
			event_name: 'dashboardButtonClick',
			event_category: 'stories',
			event_label: '<id>:<title>',
			event_context: '<story type> interaction',
			site_section: 'dashboard',
			page_detail: 'home',
		},
		storyType: {
			featured: 'featured',
			countdown: 'countdown',
			regular: 'regular',
		},
	},
	debtOtError: {
		page: {
			page_name: '',
		},
		data: {
			entrypoint_code: '',
			entrypoint_section: '',
			entrypoint_location: 'undefined',
			entrypoint_position: 'undefined',
			entrypoint_title: '',
			entrypoint_type: 'undefined',
			journey_name: '',
			experience_name: 'undefined',
		},
	},
	deptPaymentsSuspendedReconnectBanner: {
		eventName: 'pagos:mis pagos:pago en proceso',
		data: {
			event_name: 'pago en proceso',
			page_name: 'pagos:mis pagos',
			event_context: 'pagos:mis pagos',
			event_category: 'visualizacion',
			event_label: 'pago en proceso',
		},
	},
	microflowsAccess: {
		page: {
			page_name: 'transaccional:microflujos:<microflujo>:acceso',
			page_section: 'transaccional',
			page_subcategory_level_1: 'microflujos',
			page_subcategory_level_2: '',
			page_subcategory_level_3: 'acceso',
			navigation_level_1: 'transaccional',
			navigation_level_2: 'microflujos',
			navigation_level_4: 'acceso',
			journey_detail: 'acceso',
			journey_type: 'transaccional',
			journey_step: '<microflujo>:acceso',
			journey_category: '',
		},
		data: {
			'event_name': 'click en entrypoint',
			'event_category': 'deeplink',
			'event_context': 'entrypoint <state>',
			'event_label': 'click en deeplink',
			'&&events': 'event70',
			'entrypoint_type': '',
			'entrypoint_code': '',
			'entrypoint_section': 'deeplink',
			'entrypoint_location': undefined,
			'entrypoint_title': undefined,
			'entrypoint_position': undefined,
		},
	},
	afterTopUp: {
		countDownPage: {
			state: {
				'page_name': 'recargas:recarga realizada:actualizando saldo',
				'journey_name': 'recargar saldo',
				'journey_category': 'particulares',
				'journey_subcategory': 'prepago',
				'journey_type': 'servicio',
				'journey_detail': 'ok',
				'journey_step': 'recargar saldo:ok',
				'journey_card_selected': 'guardada',
				'journey_environment': 'privada',
				'&&products': 'recarga;recarga prepago;1;;eVar207=<promotion code>',
				'payment_id': '01234567890123456789',
			},
		},

		pageWithOptions: {
			state: {
				'page_name': 'recargas:recarga realizada:opciones saldo',
				'journey_name': 'recargar saldo',
				'journey_category': 'particulares',
				'journey_subcategory': 'prepago',
				'journey_type': 'servicio',
				'journey_detail': 'ok',
				'journey_step': 'recargar saldo:completado:opciones',
				'journey_card_selected': 'guardada',
				'journey_environment': 'privada',
				'&&products': 'recarga;recarga prepago;1;;eVar207=<promotion code>',
				'payment_id': '01234567890123456789',
			},
			interactions: {
				updateMyTarrifa: {
					eventName: 'recargas:recarga realizada:opciones saldo',
					data: {
						event_name: 'click en renovar mi tarifa ahora',
						journey_name: 'renovar beneficios prepago',
						event_label: 'click en renovar mi tarifa ahora',
					},
				},
				buyBonus: {
					eventName: 'recargas:recarga realizada:opciones saldo',
					data: {
						event_name: 'click en comprar un bono',
						journey_name: 'gestion de bonos',
						event_label: 'click en comprar un bono',
					},
				},
			},
		},
		successPageWithNewOptions: {
			state: {
				'page_name': 'prepago:recargas:opciones disponibles:pago tarjeta:ok',
				'journey_name': 'recargar saldo',
				'journey_category': 'particulares',
				'journey_subcategory': 'prepago',
				'journey_type': 'servicio',
				'journey_detail': 'ok',
				'journey_step': 'recargar saldo:ok',
				'journey_card_selected': 'guardada',
				'journey_environment': 'privada',
				'&&products': 'recarga;recarga prepago;1;;eVar207=<promotion code>',
				'payment_id': '01234567890123456789',
			},
			interactions: {
				renovarTarifaOComprarBono: {
					eventName: 'prepago:recargas:pago tarjeta:opciones disponibles:ok',
					data: {
						event_name: 'click en renovar tarifa o comprar bono',
						journey_name: 'recargar saldo:ok',
						event_label: 'click en renovar tarifa o comprar bono',
					},
				},
				VerTicketDePago: {
					eventName: 'prepago:recargas:opciones disponibles:pago tarjeta:ok',
					data: {
						event_name: 'ver ticket de pago',
						event_context: 'recargar saldo',
						event_category: 'boton',
						event_label: 'ver ticket de pago',
					},
				},
			},
		},
		successPageWithPromoCode: {
			state: {
				'journey_name': 'recargar saldo',
				'journey_category': 'particulares',
				'journey_subcategory': 'prepago',
				'journey_type': 'servicio',
				'journey_detail': 'ok',
				'journey_step': 'recargar saldo:ok',
				'journey_card_selected': 'guardada',
				'journey_environment': 'privada',
				'&&products': 'recarga;recarga prepago;1;;eVar207=<promotion code>',
				'payment_id': '01234567890123456789',
			},
		},
		FailureScreenAfterTopUp: {
			state: {
				'page_name': 'recargas:recarga realizada:opciones saldo:no actualizado',
				'journey_name': 'recargar saldo',
				'journey_category': 'particulares',
				'journey_subcategory': 'prepago',
				'journey_type': 'servicio',
				'journey_detail': 'ok',
				'journey_step': 'recargar saldo:ok',
				'journey_card_selected': 'guardada',
				'journey_environment': 'privada',
				'&&products': 'recarga;recarga prepago;1;;eVar207=<promotion code>',
				'payment_id': '01234567890123456789',
			},
		},
	},
	smartPayAfterTopUp: {
		state: {
			page_name: 'recargas:recarga realizada:retencion pago facil',
			site_section: 'recargas',
			page_subcategory_level_1: 'recarga realizada',
			page_subcategory_level_2: 'retencion pago facil',
			page_typology: 'overlay',
			page_detail: 'retencion pago facil',
			asset_name: 'mivoweb',
		},
		interaction: {
			ClickOnCerrar: {
				eventName: 'recargas:recarga realizada:retencion pago facil',
				data: {
					event_name: 'clic en cerrar',
					event_context: 'retencion pago facil',
					event_category: 'aspa',
					event_label: 'clic en cerrar',
				},
			},
		},
	},

	englishOverlayJourney: {
		state: {
			page_name: 'prepago:ayuda tobi idioma:backdrop',
			page_section: 'prepago',
			page_subcategory_level_1: 'ayuda tobi idioma',
			page_screen: 'backdrop',
			page_detail: 'backdrop',
		},
		interactions: {
			openWhatsApp: {
				eventName: 'prepago:ayuda tobi idioma:backdrop',
				data: {
					event_name: 'click en abrir whatsapp ingles',
					event_context: 'backdrop idioma',
					event_category: 'boton',
					event_label: 'click en abrir whatsapp ingles',
				},
			},
			openTopUp: {
				eventName: 'prepago:ayuda tobi idioma:backdrop',
				data: {
					event_name: 'click en recargar ahora',
					event_context: 'backdrop idioma',
					event_category: 'boton',
					event_label: 'click en recargar ahora',
				},
			},
			continueExperienceInSpanish: {
				eventName: 'prepago:ayuda tobi idioma:backdrop',
				data: {
					event_name: 'click en continuar en espanol',
					event_context: 'backdrop idioma',
					event_category: 'link',
					event_label: 'click en continuar en espanol',
				},
			},
			closeButton: {
				eventName: 'prepago:ayuda tobi idioma:backdrop',
				data: {
					event_name: 'clic en cerrar',
					event_context: 'backdrop idioma',
					event_category: 'link',
					event_label: 'clic en cerrar',
				},
			},
		},
	},
	topUpMovementsHistoryJourney: {
		state: {
			page_name: 'prepago:historico de movimientos',
			page_section: 'prepago',
			page_subcategory_level_1: 'historico de movimientos',
		},
		interactions: {
			clickOnAnyOption: {
				eventName: 'prepago:historico de movimientos',
				data: {
					event_name: 'click en opcion temporal',
					event_context: 'tiempo',
					event_category: 'filtro',
					event_label: 'click en <element text>',
				},
			},
			clickOnLoadMore: {
				eventName: 'prepago:historico de movimientos:click en cargar mas',
				data: {
					event_label: 'click en cargar mas',
					event_context: 'historico de movimientos',
					event_category: 'boton',
					event_name: 'click en cargar mas',
				},
			},
		},
	},

	benefitRenewelJourney: {
		state: {
			'page_name': 'productos y servicios:resumen de productos y servicios:renovar beneficios',
			'asset_name': 'mivoweb',
			'site_section': 'productos y servicios',
			'page_subcategory_level_1': 'resumen de productos y servicios',
			'page_subcategory_level_2': 'renovar beneficios',
			'page_detail': 'renovar beneficios',
			'page_typology': 'principal',
			'journey_name': 'renovacion beneficios prepago',
			'journey_category': 'particulares',
			'journey_subcategory': 'prepago',
			'journey_type': 'servicio',
			'journey_process': 'renovacion disponible',
			'journey_options': '',
			'journey_prepaid_tariff': 'tarifa s',
			'journey_type_plan': 'smartpay no',
			'journey_topup_amount': 'saldo suficiente renovar tarifa si-saldo suficiente renovar extras no',
			'journey_bundle': '',
			'journey_detail': 'start',
			'journey_step': 'renovacion beneficios prepago:start',
			'&&events': 'event27',
		},
		failureOverlay: {
			'page_name': 'productos y servicios:resumen de productos y servicios:renovar beneficios:ko',
			'asset_name': 'mivoweb',
			'site_section': 'productos y servicios',
			'page_subcategory_level_1': 'resumen de productos y servicios',
			'page_subcategory_level_2': 'renovar beneficios',
			'page_subcategory_level_3': 'ko',
			'page_detail': 'ko',
			'page_typology': 'principal',
			'journey_name': 'renovacion beneficios prepago',
			'journey_category': 'particulares',
			'journey_subcategory': 'prepago',
			'journey_type': 'servicio',
			'journey_process': 'renovacion disponible',
			'journey_options': 'tarifa s-chat pass-bono regalo 5gb',
			'journey_prepaid_tariff': 'tarifa s',
			'journey_type_plan': 'smartpay no',
			'journey_topup_amount': 'saldo suficiente renovar tarifa si-saldo suficiente renovar extras no',
			'journey_detail': 'ko',
			'journey_step': 'renovacion beneficios prepago:ko',
			'error_list': 'et=tecnico|el=backend|ec=2022|em=b0b91cca-e444-b52f-35fe-c2568e1cfc46|eh=400|ed=no response',
			'&&events': 'event115',
		},
		successOverlay: {
			'page_name': 'productos y servicios:resumen de productos y servicios:renovar beneficios:ok',
			'asset_name': 'mivoweb',
			'site_section': 'productos y servicios',
			'page_subcategory_level_1': 'resumen de productos y servicios',
			'page_subcategory_level_2': 'renovar beneficios',
			'page_detail': 'ok',
			'page_typology': 'principal',
			'journey_name': 'renovacion beneficios prepago',
			'journey_category': 'particulares',
			'journey_subcategory': 'prepago',
			'journey_type': 'servicio',
			'journey_process': 'renovacion disponible',
			'journey_options': '',
			'journey_prepaid_tariff': 'tarifa s',
			'journey_type_plan': 'smartpay no',
			'journey_topup_amount': 'saldo suficiente renovar tarifa si-saldo suficiente renovar extras no',
			'journey_bundle': '',
			'journey_detail': 'ok',
			'journey_step': 'renovacion beneficios prepago:ok',
			'&&events': 'event28',
		},
		interactions: {
			ClickOnCambiar: {
				eventName: 'productos y servicios:resumen de productos y servicios:renovar beneficios',
				data: {
					'page_name': 'productos y servicios:resumen de productos y servicios:renovar beneficios',
					'asset_name': 'mivoweb',
					'site_section': 'productos y servicios',
					'page_subcategory_level_1': 'resumen de productos y servicios',
					'page_subcategory_level_2': 'renovar beneficios',
					'page_detail': 'renovar beneficios',
					'page_typology': 'principal',
					'page_original_referring': 'directo',
					'event_category': 'boton',
					'event_context': 'renovar beneficios',
					'event_label': 'clic en cambiar',
					'visitor_login_status': 'logado',
					'&&events': '',
				},
			},
			impresionesEntrypoint: {
				eventName: 'productos y servicios:resumen de productos y servicios:renovar beneficio',
				data: {
					'page_name': 'productos y servicios:resumen de productos y servicios:renovar beneficios',
					'asset_name': 'mivoweb',
					'site_section': 'productos y servicios',
					'page_subcategory_level_1': 'resumen de productos y servicios',
					'page_subcategory_level_2': 'renovar beneficios',
					'page_detail': 'renovar beneficios',
					'page_typology': 'principal',
					'page_original_referring': 'directo',
					'event_category': 'visualizacion',
					'event_context': 'recargar aviso',
					'event_label': 'impresiones entrypoint',
					'visitor_login_status': 'logado',
					'entrypoint_list':
						'es=pys|el=link renovacion beneficios|ep=undefined|jn=recargar saldo|en=undefined|et=recargar',
					'&&events': 'event75',
				},
			},
			clickOnTopUp: {
				eventName: 'productos y servicios:resumen de productos y servicios:renovar beneficios',
				data: {
					'page_name': 'productos y servicios:resumen de productos y servicios:renovar beneficios',
					'asset_name': 'mivoweb',
					'site_section': 'productos y servicios',
					'page_subcategory_level_1': 'resumen de productos y servicios',
					'page_subcategory_level_2': 'renovar beneficios',
					'page_detail': 'renovar beneficios',
					'page_typology': 'principal',
					'entrypoint_section': 'pys',
					'entrypoint_location': 'link renovacion beneficios',
					'entrypoint_position': 'undefined',
					'entrypoint_title': 'recargar',
					'journey_name': 'recargar saldo',
					'experience_name': 'undefined',
					'event_category': 'boton',
					'event_context': 'recargar',
					'event_label': 'click en entrypoint',
					'visitor_login_status': 'logado',
					'&&events': 'event70',
				},
			},
			ClickOnRenovarAhora: {
				eventName: 'productos y servicios:resumen de productos y servicios:renovar beneficios',
				data: {
					'event_Name': 'productos y servicios:resumen de productos y servicios:renovar beneficios',
					'page_name': 'productos y servicios:resumen de productos y servicios:renovar beneficios',
					'asset_name': 'mivoweb',
					'site_section': 'productos y servicios',
					'page_subcategory_level_1': 'resumen de productos y servicios',
					'page_subcategory_level_2': 'renovar beneficios',
					'page_detail': 'renovar beneficios',
					'page_typology': 'principal',
					'page_original_referring': 'directo',
					'event_category': 'boton',
					'event_context': 'renovar beneficios',
					'event_label': 'clic en renovar ahora',
					'visitor_login_status': 'logado',
					'&&events': '',
				},
			},
			ClickOnEntendido: {
				eventName: 'productos y servicios:resumen de productos y servicios:renovar beneficios:ok',
				data: {
					'page_name': 'productos y servicios:resumen de productos y servicios:renovar beneficios:ok',
					'asset_name': 'mivoweb',
					'site_section': 'productos y servicios',
					'page_subcategory_level_1': 'resumen de productos y servicios',
					'page_subcategory_level_2': 'renovar beneficios',
					'page_subcategory_level_3': 'ok',
					'page_detail': 'ok',
					'page_typology': 'principal',
					'page_original_referring': 'directo',
					'event_category': 'boton',
					'event_context': 'renovar beneficios ok',
					'event_label': 'clic en entendido',
					'visitor_login_status': 'logado',
					'&&events': '',
				},
			},
			ClickOnCloseCross: {
				eventName: 'productos y servicios:resumen de productos y servicios:renovar beneficios:ko',
				data: {
					'page_name': 'productos y servicios:resumen de productos y servicios:renovar beneficios:ko',
					'asset_name': 'mivoweb',
					'site_section': 'productos y servicios',
					'page_subcategory_level_1': 'resumen de productos y servicios',
					'page_subcategory_level_2': 'renovar beneficios',
					'page_subcategory_level_3': 'ko',
					'page_detail': 'ko',
					'page_typology': 'principal',
					'page_original_referring': 'directo',
					'event_category': 'boton',
					'event_context': 'renovar beneficios ko',
					'event_label': 'clic en cerrar',
					'visitor_login_status': 'logado',
					'&&events': '',
				},
			},
		},
	},
	pegaPlatinumJourney: {
		almostIN: {
			state: {
				page_name: 'recargas:nuevo cliente platino',
				asset_name: 'mivoweb',
				site_section: 'recargas',
				page_subcategory_level_1: 'nuevo cliente platino',
				page_detail: 'nuevo cliente platino',
				page_typology: 'principal',
			},
			interaction: {
				ClickOnRecargar: {
					eventName: 'recargas:nuevo cliente platino:clic en recargar',
					data: {
						event_name: 'clic en recargar',
						event_context: 'oferta platino',
						event_category: 'boton',
						event_label: 'clic en recargar',
					},
				},
				ClickOnCerrar: {
					eventName: 'recargas:nuevo cliente platino:clic en cerrar',
					data: {
						event_name: 'clic en cerrar',
						event_context: 'oferta platino',
						event_category: 'aspa',
						event_label: 'clic en cerrar',
					},
				},
				infoMessageTopUp: {
					eventName: 'recargas:nuevo cliente platino:impresion:recarga para ser cliente platino',
					data: {
						event_name: 'impresion:recarga para ser cliente platino',
						event_context: 'oferta platino',
						event_category: 'mensaje info',
						event_label: 'impresion:recarga para ser cliente platino',
					},
				},
				clickOnToolTip: {
					eventName: 'recargas:nuevo cliente platino:recarga para ser cliente platino',
					data: {
						event_name: 'recarga para ser cliente platino',
						event_context: 'oferta platino',
						event_category: 'boton info',
						event_label: 'recarga para ser cliente platino',
					},
				},
			},
		},
		almostOut: {
			state: {
				page_name: 'recargas:cliente platino',
				asset_name: 'mivoweb',
				site_section: 'recargas',
				page_subcategory_level_1: 'cliente platino',
				page_detail: 'cliente platino',
				page_typology: 'principal',
			},
			interaction: {
				ClickOnRecargar: {
					eventName: 'recargas:cliente platino:clic en recargar',
					data: {
						event_name: 'clic en recargar',
						event_context: 'oferta platino',
						event_category: 'boton',
						event_label: 'clic en recargar',
					},
				},
				ClickOnCerrar: {
					eventName: 'recargas:cliente platino:clic en cerrar',
					data: {
						event_name: 'clic en cerrar',
						event_context: 'oferta platino',
						event_category: 'aspa',
						event_label: 'clic en cerrar',
					},
				},
				infoPlatinumOfferTopUp: {
					eventName: 'recargas:cliente platino:impresion:recarga para seguir siendo cliente platino',
					data: {
						event_name: 'impresion:recarga para seguir siendo cliente platino',
						event_context: 'oferta platino',
						event_category: 'mensaje info',
						event_label: 'impresion:recarga para seguir siendo cliente platino',
					},
				},
				clickOnToolMoreInfoIcon: {
					eventName: 'recargas:cliente platino:recarga para seguir siendo cliente platino',
					data: {
						event_name: 'recarga para seguir siendo cliente platino',
						event_context: 'oferta platino',
						event_category: 'boton info',
						event_label: 'recarga para seguir siendo cliente platino',
					},
				},
			},
		},
	},
	tariffTile: {
		clickOnCountriesIncluded: {
			state: {
				page_name: 'productos y servicios:resumen de productos y servicios',
				asset_name: 'mivoweb',
				site_section: 'productos y servicios',
				page_subcategory_level_1: 'resumen de productos y servicios',
				page_detail: 'resumen de productos y servicios',
				page_typology: 'principal',
			},
			interaction: {
				eventName: 'productos y servicios:resumen de productos y servicios:clic en consulta paises incluidos',
				data: {
					event_name: 'clic en consulta paises incluidos',
					event_category: 'boton info',
					event_context: 'resumen de productos y servicios:mi tarifa',
					event_label: 'clic en consulta paises incluidos',
				},
			},
		},
	},
	servicesError: {
		page: {
			page_name: '',
			page_section: 'transaccional',
			page_subcategory_level_1: 'microflujos',
			page_subcategory_level_2: '{0}',
			page_subcategory_level_3: '{0}',
			navigation_level_1: 'transaccional',
			navigation_level_2: 'microflujos',
			navigation_level_3: '{0}',
			navigation_level_4: '{0}',
			page_screen: '{0}',
			asset_name: 'mivoweb',
			page_platform: 'web',
		},
		journey: {
			journey_name: '{0}',
			journey_type: 'trasaccional',
			journey_category: '',
			journey_subcategory: '',
			journey_detail: '{0}',
			journey_step: '{0}',
			is_main_flow: '',
			state_flow: 'microflujo',
		},
		errorData: {
			error_list: '',
		},
		event: {
			event_name: 'error no bloqueante',
			event_category: 'error',
			event_context: 'no bloqueante',
			event_label: '',
		},
		eventBlocked: {
			event_name: 'error bloqueante',
			event_category: 'error',
			event_context: 'bloqueante',
			event_label: '',
		},
	},
};
