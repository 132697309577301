import { CustomerType } from '../../shared/enums/customerType.enum';
import { StorageService } from '../../core/services/storage.service';
import { ProfileType } from '../../shared/enums/profileType.enum';
import { config } from './../../../config/pages-config';
import { UpgradeProfile } from './../../upgrade-profile/UpgradeProfile.enum';
import { AuthenticateService } from '../services/authenticate.service';
import { UpgradeProfileService } from './../../upgrade-profile/upgrade-profile.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../../app.service';

@Injectable({
	providedIn: 'root',
})
export class UpgradeProfileGuard implements CanActivate {
	constructor(
		private upgradeService: UpgradeProfileService,
		private authenticationService: AuthenticateService,
		private storageService: StorageService,
		private appService: AppService,
		private router: Router
	) {}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (state.url === config.billing.currentConsumption.route) {
			return true;
		}
		if (
			this.authenticationService.hasRole(CustomerType.Employee.toLowerCase()) ||
			this.authenticationService.hasRole(CustomerType.SME.toLowerCase())
		) {
			if (state.url === config.myAccount.details.route) {
				return true;
			}
			this.upgradeProfileLogic(state);
			this.upgradeService.step = UpgradeProfile.stepOne;
			return false;
		} else if (
			this.storageService.userProfile.profileType.toLowerCase() === ProfileType.LIGHT.toLowerCase() ||
			this.storageService.userProfile.profileType.toLowerCase() === ProfileType.NETWORK.toLowerCase()
		) {
			this.upgradeProfileLogic(state);
			return false;
		} else {
			return true;
		}
	}
	upgradeProfileLogic(state) {
		this.upgradeService.targetRoute = state.url;
		this.upgradeService.startScenario = true;
		this.appService.showFullAppLoader = false;
		this.router.navigate([config.dashboard.route]);
	}
}
