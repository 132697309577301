<div class="mva10-responsive mva10-o-wrapper">
	<p id="MiddlePageHeaderParagraph" class="header-title" *ngIf="data.pageDesc">
		{{ data.pageDesc }}
	</p>

	<div *ngFor="let item of data.itemList">
		<div class="card-details">
			<button id="MiddlePageBtnNavigate" class="col-xs-12" (click)="actionClick(item)">
				<img class="img-icon" *ngIf="item.img" [src]="appService.getImgFullPath(item.img)" />
				<span class="title-contanier">
					<p class="title" id="MiddlePageItemTitle" *ngIf="item.title">{{ item.title }}</p>
					<p *ngIf="item.description" id="MiddlePageItemDescripcion" class="descripcion">{{ item.description }}</p>
				</span>
				<span class="arrow-icon">
					<i class="icon icon-chevron-right"> </i>
				</span>
			</button>
		</div>
	</div>
</div>
