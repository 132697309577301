export enum PaymentErrorCategoryInWCS {
	// general
	'Genérico' = 'GenericError',
	'Tarjeta inválida' = 'invalidCreditCard',
	'Datos de Tarjeta' = 'incorrectCreditCard',
	'Tarjeta no admitida' = 'unsupportedCreditCard',
	'sistema no disponible' = 'systemUnvailable',
	'replica mode' = 'MaintenanceErrorPayment',
	'tecnico' = 'GenericError',
	'timeout' = 'timeout',
	// prepaid
	'Notprepaid' = 'invalidMobileNumber',
	'NotvodafoneNumber' = 'invalidMobileNumber',
	'not active' = 'invalidMobileNumber',
	'TopupInProgress' = 'rechargeInProgress',
	'refundSuccess' = 'sucessRefundMsg',
	'operationNotExist' = 'operationNotExistMsg',
	'refundFailed' = 'failureRefundMsg',
	'mortiroloKO108' = 'failureMortiroloMsg',
	// anonymous
	'billDetailsFailure' = 'billDetailsFailure',
	'anonymousIdentifierFailure' = 'anonymousIdentifierFailure',
	'noDebtFailure' = 'noDebtFailure',
	'codeRetrialFailure' = 'codeRetrialFailure',
	'codeRenewFailure' = 'codeRenewFailure',
	'codeTechnicalError' = 'codeTechnicalError',
	// wallet related
	'emptyWallet' = 'emptyWallet',
	'loadingWalletFailed' = 'loadingWalletFailed',
	// promoCode
	'invalidPromoCode' = 'invalidPromoCode',
	'validPromoButInvalidRechargeAmount' = 'validPromoButInvalidRechargeAmount',
	'validPromoCodeButPromotionNotApplied' = 'validPromoCodeButPromotionNotApplied',
}
