import { Pipe, PipeTransform } from '@angular/core';
import { DiaDisponible, ObjetoFranja } from '../models/citasDisponiblesOT.model';
import { Paso } from '../models/detalleSeguimientoPedido.model';

@Pipe({
	name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
	transform(
		array: Paso[] | ObjetoFranja[] | DiaDisponible[],
		field: string
	): Paso[] | ObjetoFranja[] | DiaDisponible[] {
		if (!Array.isArray(array)) {
			return;
		}
		array.sort((a, b) => {
			if (a[field] < b[field]) {
				return -1;
			} else if (a[field] > b[field]) {
				return 1;
			} else {
				return 0;
			}
		});
		return array;
	}
}
