import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { StorageService } from '../../core/services/storage.service';
import { API_URLS } from '../constants/routes-config';
import { Inbox_Message } from '../constants/defines';
import { NotificationBadgeService } from './notification-badge.service';
import { NotificationType } from '../enums/notification-type.enum';

@Injectable()
export class NotificationInboxService {
	public notificationInboxCount = 0;
	public timerCount = 0;
	private runEach = 100;
	public sideMenuId = Inbox_Message.sideMenuId;
	public totalTime;
	private intervalId;

	constructor(
		private http: HttpClient,
		private notificationBadgeService: NotificationBadgeService,
		private StorageService: StorageService
	) {}

	getNotificatoinCount() {
		if (this.StorageService && this.StorageService.userProfile && this.StorageService.userProfile.id) {
			const url = API_URLS.Inbox.getNotificatoinCount.replace('{userId}', this.StorageService.userProfile.id);
			// this code gets Notification Bubble Count then update its bubble vlaue
			return this.http
				.get(url)
				.pipe(
					map((res) => {
						// If everything went fine, return the response
						return res;
					}),
					catchError((er, caught) => {
						return throwError(er);
					})
				)
				.subscribe(
					(data: any) => {
						const resultCount = data?.count?.delivered;
						this.notificationInboxCount =
							resultCount || resultCount === 0 ? parseInt(resultCount, 10) : this.notificationInboxCount;
						this.notificationBadgeService.setNotificationCounter({
							type: NotificationType.INBOX,
							value: this.notificationInboxCount,
						});
					},
					// If request fails for any reason, return current notification counter
					(err) => {
						this.notificationInboxCount = this.notificationBadgeService.getSpecificCounter(NotificationType.INBOX);
					}
				);
		}
	}

	cachedVersion() {
		return this.notificationInboxCount;
	}

	startCount() {
		// INIT
		this.totalTime = this.totalTime || 0;
		// count each time till 1 min
		if (!this.intervalId) {
			this.intervalId = setInterval(() => {
				this.totalTime += this.runEach;
				if (this.totalTime > Inbox_Message.REFRESH_TIME) {
					// reset
					this.totalTime = 0;
					// get notification counter
					this.getNotificatoinCount();
				}
			}, this.runEach);
		}
	}

	endCount() {
		if (this.intervalId) {
			clearInterval(this.intervalId);
			this.intervalId = null;
		}
	}
}
