import { IconClassNames } from './../../enums/iconClassNames.enum';
import { config } from './../../../../config/pages-config';
import { NotificationInboxService } from './../../services/notification-inbox.service';
import { SubMenuItemSubItem } from './../../../models/submenu-items.model';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SubMenuItem } from '../../../models/submenu-items.model';

@Component({
	selector: 'sp-submenu-menu',
	templateUrl: './submenu-menu.component.html',
	styleUrls: ['./submenu-menu.component.scss'],
})
export class SubmenuMenuComponent {
	subMenuDisplayData: SubMenuItem[] = [];
	selectedItemIndex: number;
	public inboxRoute: string = config.Inbox.route.toLowerCase();

	@Output() itemClick: EventEmitter<SubMenuItem | SubMenuItemSubItem> = new EventEmitter();

	@Input() set data(data: SubMenuItem[]) {
		if (data) {
			this.subMenuDisplayData = data.map((item: SubMenuItem) => {
				const iconName = item.iconClassName.split('icon ');
				if (iconName.length > 1) {
					switch (iconName[1]) {
						case IconClassNames.icon_info_icon:
							item.iconClassName = IconClassNames.icon_device_guide;
							break;
						case IconClassNames.icon_my_vodafone_profile:
							item.iconClassName = IconClassNames.icon_admin;
							break;
						default:
							item.iconClassName = iconName[1];
							break;
					}
				}
				return item;
			});
		}
	}

	constructor(public NotificationInboxService: NotificationInboxService) {}

	itemClicked(item, itemIndex?) {
		if (!item.subItems) {
			this.selectedItemIndex = -1;
			this.itemClick.emit(item);
		} else {
			this.selectedItemIndex = this.selectedItemIndex === itemIndex ? -1 : itemIndex;
		}
	}
}
