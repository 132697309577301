import { SharedServicesPegaReplacerTokensMap } from '../../entity/shared-services-pega-replacer.entity';

/**
 *
 * @param tokens Tokens map generated by `sharedServicesPegaReplacerCreateToken(value: string): SharedServicesPegaReplacerTokensMap`.
 * @param templateVar Variable to replace the template. Default to all characters.
 * @returns {RegExp} Regular expression pattern created by options.
 * @example
 * ```ts
 * const templateVar = 'SuperField';
 * const tokens: SharedServicesPegaReplacerTokensMap = sharedServicesPegaReplacerCreateToken('{+}');
 * const pattern = sharedServicesPegaReplacerCreatePatternFromToken(tokens, templateVar);
 *
 * const replaced = 'Hello, {SuperField}, how are you?'.replace(pattern, 'Alexa');
 * ```
 */
export function sharedServicesPegaReplacerCreatePatternFromToken(
	tokens: SharedServicesPegaReplacerTokensMap,
	templateVar?: string
): RegExp {
	const tokensPrefix: string = tokens.get('prefix');
	const tokensSuffix: string = tokens.get('suffix');

	return new RegExp(`\\${tokensPrefix}${templateVar || '(.*?)'}\\${tokensSuffix}`, 'g');
}
