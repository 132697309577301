import {
	SharedServicesPegaParserHashMap,
	SharedServicesPegaParserZippedHashMap,
	SharedServicesPegaParserError,
} from '../../entity/shared-services-parser.entity';

export function sharedServicesPegaParserZipHashMaps(
	z1: SharedServicesPegaParserHashMap,
	z2: SharedServicesPegaParserHashMap
): SharedServicesPegaParserZippedHashMap {
	const hashMapSizeEquality: boolean = z1.size === z2.size;

	if (!hashMapSizeEquality) {
		throw new Error(SharedServicesPegaParserError.InvalidZipSizes);
	}

	const z1Separator: RegExp = z1.get('separator') as RegExp;
	const z2Separator: RegExp = z2.get('separator') as RegExp;

	const hashMapSeparatorEquality: boolean = z1Separator.source === z2Separator.source;

	if (!hashMapSeparatorEquality) {
		throw new Error(SharedServicesPegaParserError.InvalidZipSeparators);
	}

	const zipped: SharedServicesPegaParserZippedHashMap = new Map();

	for (let i = 0; i < z1.size - 1; i++) {
		const v1: string = z1.get(i) as string;
		const v2: string = z2.get(i) as string;

		zipped.set(v1, v2);
	}

	return zipped;
}
