import { Component, OnInit, OnDestroy } from '@angular/core';
import { SpMva10OverlayService } from '../../shared/services/sp-mva10-overlay.service';
import { TopUpService } from '../top-up.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../app.service';
import { TopupPrepaidStages, VisaFirstDigit, MasterCardFirstDigit } from '../../shared/constants/defines';
import { PaymentCardsService } from '../../shared/services/payment-cards.service';
import { StorageService } from '../../core/services/storage.service';
import { PaymentCardModel, PaymentCardPatchModel } from '../../models/payment-card.model';
import { TopupPrepaidErrorOVerlayDataService } from '../top-up-prepaid-error-overlay-data.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { tagging } from '../../../config/tagging-config';
import { DynamicTrayService } from '../../shared/services/dynamic-tray.service';
import { BillingService } from '../../billing/billing.service';
import { Breakpoints } from '@mva10/mva10-angular';

@Component({
	selector: 'sp-top-up-card-wallet-screen',
	templateUrl: './top-up-card-wallet-screen.component.html',
	styleUrls: [
		'./top-up-card-wallet-screen.component.scss',
		'../mva10-topup-landing-with-pci/mva10-topup-landing-with-pci.component.scss',
	],
})
export class TopUpCardWalletScreenComponent implements OnInit, OnDestroy {
	menuIconPath: string;
	actions: Array<String>;
	setPrincipalWalletButton: string;
	deleteWalletButton: string;
	principleAction: string;
	deleteAction: string;
	creditCardsList: Array<PaymentCardModel>;
	principalTitle: String;
	screenTitle: string;
	anotherCreditCardWallet: string;
	showFullLoader: boolean;
	visaImage: string;
	otherImage: string;
	masterCardImage: string;
	selectedCardImage: string;
	hideContent: boolean = false;
	selectedCardPrincipal: boolean = false;
	showLeavingOverlay: boolean = false;
	constructor(
		public trayService: SpMva10OverlayService,
		private topupErrorService: TopupPrepaidErrorOVerlayDataService,
		public topupService: TopUpService,
		private translateService: TranslateService,
		private appService: AppService,
		public paymentCardsService: PaymentCardsService,
		private storageService: StorageService,
		private billingService: BillingService,
		private tagging: TaggingHelperService,
		private dynamicTrayService: DynamicTrayService
	) {}

	ngOnInit() {
		this.setPageUi();
		this.setMenuActions();
		this.getSavedCards();
		this.sendPageTagging();
	}

	setPageUi() {
		this.translateService.get('payment.itemsList.wallet').subscribe((data) => {
			(this.principalTitle = data.title), (this.screenTitle = data.body);
		});
		this.translateService.get('payment.paymentMethods.images').subscribe((data) => {
			this.visaImage = this.appService.getImgFullPath(data.walletVisa.url);
			this.masterCardImage = this.appService.getImgFullPath(data.walletMasterCard.url);
			this.otherImage = this.appService.getImgFullPath(data.walletOther.url);
		});
		if (this.dynamicTrayService.trayData) {
			/** check if the device is mobile */
			const isMobileView: boolean = window.innerWidth < Breakpoints.TABLET;
			/** Make title is bold in responsive view */
			this.dynamicTrayService.trayData.isBoldTitle = !isMobileView;
			/** Reset tray to has white backgroud */
			this.dynamicTrayService.trayData.isGradientBackground = false;
		}
	}

	sendPageTagging() {
		/** Send page object only for prepaid */
		const pageObj: any = tagging.paymentCardsPages.manageCreditCards;
		this.tagging.view(pageObj);
	}

	/**
	 * setDynamicTrayConfig function responsible for
	 * set the tray title
	 * show tray back button
	 * bind back button action
	 * bind close button action
	 */

	setDynamicTrayConfig() {
		this.topupService.dynamicTrayData.basicTrayData.title = this.screenTitle;
		this.topupService.dynamicTrayData.basicTrayData.showBackButton = true;
		this.topupService.dynamicTrayData.backButtonAction = this.returnToScreenFunction();
		this.topupService.dynamicTrayData.closeButtonAction = this.closeTray.bind(this);
		if (this.dynamicTrayService.trayData) {
			/** Set close function to the original dynamic tray */
			this.dynamicTrayService.trayData.closeButtonAction = this.closeTray.bind(this);
		}
	}

	getSavedCards(): void {
		this.showFullLoader = true;
		this.paymentCardsService.getPaymentCardsByDocumentId(this.storageService.userProfile.document.id).subscribe(
			() => {
				this.setDynamicTrayConfig();
				if (this.paymentCardsService.cardsList && this.paymentCardsService.cardsList.length > 0) {
					this.setImagesForEachCard(this.paymentCardsService.cardsList);
					if (this.topupService.selectedCardDeleted || this.selectedCardPrincipal) {
						this.topupService.selectedCreditCard = this.paymentCardsService.cardsList.find((card) => card.principle);
						this.topupService.selectedCardDeleted = false;
						this.selectedCardPrincipal = false;
					}
				} else {
					this.topupService.showCardSelectionSection = false;
				}
				this.showFullLoader = false;
			},
			(error) => {
				this.showFullLoader = false;
				this.paymentCardsService.cardsList = new Array<PaymentCardModel>();
				this.topupService.topupNavigationSubject.next({ page: TopupPrepaidStages.secondStep });
			}
		);
	}

	displayPopupMsg(card: PaymentCardModel) {
		this.showFullLoader = true;
		this.topupService.dynamicTrayData.basicTrayData.showBackButton = false;
		const lastFour = card.cardMaskData.substr(card.cardMaskData.length - 4);
		this.paymentCardsService.setDeleteTopupUiData(lastFour);
		this.topupService.topupNavigationSubject.next({ page: TopupPrepaidStages.topupGeneralError });
		this.topupErrorService.confirmationButtonFn = () => {
			this.deleteCard(card.uuid);
			this.sendConfirmDeleteInteractionTagging();
		};
		this.topupErrorService.cancelationButtonFn = () => {
			this.topupService.topupNavigationSubject.next({ page: TopupPrepaidStages.TopUpCardWalletScreen });
			this.sendCancelDeleteInteractionTagging();
		};
		if (this.dynamicTrayService.trayData) {
			this.dynamicTrayService.trayData.closeButtonAction = this.setClickXButtonInDeleteConfirmationAction.bind(this);
		}
		this.setClickXButtonInDeleteConfirmationAction();

		this.sendDeleteCardTagging();
	}

	/**
	 * setClickXButtonInDeleteConfirmationAction function responsible for
	 * override X click on tray to navigate back to wallet credit card listing screen.
	 */
	setClickXButtonInDeleteConfirmationAction() {
		this.topupErrorService.activateXbuttonSubject = true;
		this.topupErrorService.xButtonSubject.subscribe(() => {
			this.topupService.topupNavigationSubject.next({ page: TopupPrepaidStages.TopUpCardWalletScreen });
		});
	}

	sendConfirmDeleteInteractionTagging() {
		this.tagging.track(
			tagging.paymentCards.interactions.clickOnConfirmDeleteCard.eventName,
			tagging.paymentCards.interactions.clickOnConfirmDeleteCard.data,
			tagging.paymentCardsPages.confirmDeleteCard
		);
	}

	sendCancelDeleteInteractionTagging() {
		this.tagging.track(
			tagging.paymentCards.interactions.clickOnCancelDeleteCard.eventName,
			tagging.paymentCards.interactions.clickOnCancelDeleteCard.data,
			tagging.paymentCardsPages.confirmDeleteCard
		);
	}

	sendDeleteCardTagging() {
		this.tagging.track(
			tagging.paymentCards.interactions.clickOnDeleteCard.eventName,
			tagging.paymentCards.interactions.clickOnDeleteCard.data,
			tagging.paymentCardsPages.manageCreditCards
		);
		this.tagging.view(tagging.paymentCardsPages.confirmDeleteCard);
	}

	deleteCard(uuid: string): void {
		this.showFullLoader = true;
		this.paymentCardsService.deletePaymentCard(this.storageService.userProfile.document.id, uuid).subscribe(
			() => {
				this.getSavedCards();
				if (uuid === this.topupService.selectedCreditCard.uuid) {
					this.topupService.selectedCardDeleted = true;
				}
			},
			(error) => {
				this.topupService.topupNavigationSubject.next({ page: TopupPrepaidStages.TopUpCardWalletScreen });
			}
		);
	}

	updateCardAsPrinciple(card: PaymentCardModel) {
		this.showFullLoader = true;
		this.selectedCardPrincipal = true;
		const cardObj = new PaymentCardPatchModel();
		cardObj.cardMask = card.cardMaskData;
		cardObj.documentID = card.documentID;
		cardObj.documentType = card.documentType;
		cardObj.expiryDate = card.expiryDate;
		cardObj.journey = card.journey;
		cardObj.token = card.token;
		cardObj.principle = true;
		this.paymentCardsService.savePaymentCard(this.storageService.userProfile.document.id, cardObj).subscribe(
			() => {
				this.getSavedCards();
			},
			(error) => {
				this.topupService.topupNavigationSubject.next({ page: TopupPrepaidStages.TopUpCardWalletScreen });
			}
		);
		this.sendUpdateAsPrincipleTagging();
	}

	sendUpdateAsPrincipleTagging() {
		this.tagging.track(
			tagging.paymentCards.interactions.clickOnUseAsMainCard.eventName,
			tagging.paymentCards.interactions.clickOnUseAsMainCard.data,
			tagging.paymentCardsPages.manageCreditCards
		);
	}

	setMenuActions() {
		this.translateService.get('payment.buttonList').subscribe((data) => {
			(this.principleAction = data.setPrincipalWalletButton.text),
				(this.deleteAction = data.deleteWalletButton.text),
				(this.anotherCreditCardWallet = data.anotherCreditCardWalletButton.text);
		});
		this.actions = [this.principleAction, this.deleteAction];
	}

	returnToTopupScreens(newCard = false, card?: PaymentCardModel) {
		this.trayService.showBackwardTransition = true;
		this.topupService.returnFromWalletScreen = true;
		this.hideContent = true;
		if (newCard) {
			this.topupService.selectedCreditCard = null;
		} else if (card) {
			this.topupService.selectedCreditCard = card;
		}
		this.topupService.topupNavigationSubject.next({ page: TopupPrepaidStages.firstStep });
	}

	//#region Postpaid Payment Users
	returnToScreenFunction(): any {
		return this.returnToTopupScreens.bind(this);
	}
	//#endregion

	updateOrDelete(action: string, card: PaymentCardModel) {
		if (action === this.deleteAction) {
			this.displayPopupMsg(card);
		} else {
			this.updateCardAsPrinciple(card);
		}
	}

	setImagesForEachCard(cardsList: Array<PaymentCardModel>) {
		for (const card of cardsList) {
			card.cardImage = this.getImageForSelectedCard(card.cardMaskData);
		}
		this.creditCardsList = cardsList;
	}
	getImageForSelectedCard(cardMask: string) {
		if (cardMask && cardMask[0] === VisaFirstDigit) {
			this.selectedCardImage = this.visaImage;
		} else if (cardMask && cardMask[0] === MasterCardFirstDigit) {
			this.selectedCardImage = this.masterCardImage;
		} else {
			this.selectedCardImage = this.otherImage;
		}
		return this.selectedCardImage;
	}
	/**
	 * Show leaving overlay when click on x in cclist screen with animation in postpaid journey only.
	 * Send tagging for leaving overlay in postpaid journey only.
	 * Bind x in leaving overlay to clickXButtonInCancelationOverlay function in postpaid journey only.
	 */
	closeTray(): void {
		this.topupService.selectedItemIndex = 0;
		this.topupService.showPlus = false;
		this.topupService.msisdn = '';
		this.sendTaggingClickOnXInteraction();
		/** Do not create a new card model if postpaid user */
		this.topupService.selectedCreditCard = new PaymentCardModel();
		this.trayService.close(false);
		this.dynamicTrayService.closeTraySubject.next();
	}
	/**
	 * Executed when click on x in leaving overlay.
	 * Return to cclist screen again without animation.
	 * Make tagging of cclist screen again.
	 */
	clickXButtonInCancelationOverlay(): void {
		this.billingService.sendPostpaidPageTagging(
			tagging.paymentCardsPages.managePostpaidCreditCardsPageName,
			tagging.paymentCardsPages.managePostpaidCreditCards
		);
		this.translateService.get('payment.itemsList.savedCreditTitle.body').subscribe((title) => {
			this.dynamicTrayService.trayData.basicTrayData.title = title;
		});
		this.showLeavingOverlay = false;
		this.billingService.dynamicTrayData.isGradientBackground = false;
		this.billingService.dynamicTrayData.preventTrayInnerScrolling = true;
		this.billingService.dynamicTrayData.closeButtonAction = this.closeTray.bind(this);
		this.billingService.forwardTransition = false;
		this.billingService.dynamicTrayData.basicTrayData.showBackButton = true;
	}
	/**
	 * Executed when click on continuar button in leaving overlay.
	 * Return to cclist screen again with animation.
	 * Make tagging of cclist screen again.
	 */
	continuePayment(): void {
		this.billingService.sendPostpaidPageTagging(
			tagging.paymentCardsPages.managePostpaidCreditCardsPageName,
			tagging.paymentCardsPages.managePostpaidCreditCards
		);
		this.billingService.fadeOutTransition = true;
		setTimeout(() => {
			this.translateService.get('payment.itemsList.savedCreditTitle.body').subscribe((title) => {
				this.dynamicTrayService.trayData.basicTrayData.title = title;
			});
			this.billingService.fadeOutTransition = false;
			this.billingService.forwardTransition = false;
			this.trayService.showBackwardTransition = true;
			this.showLeavingOverlay = false;
			this.billingService.dynamicTrayData.isGradientBackground = false;
			this.billingService.dynamicTrayData.basicTrayData.showBackButton = true;
			this.billingService.dynamicTrayData.preventTrayInnerScrolling = true;
			this.billingService.dynamicTrayData.closeButtonAction = this.closeTray.bind(this);
			this.dynamicTrayService.closeTraySubject.subscribe(() => {
				this.closeTray.bind(this);
			});
		}, 1000);
	}
	/**
	 * Executed when click on cancel button in leaving overlay.
	 * Close tray and return to mis pagos page.
	 * Make tagging for cancel button.
	 */
	cancelPayment(): void {
		this.billingService.sendPostpaidPageTagging(
			tagging.cancelPaymentBtnClickedInCClistPageName,
			tagging.cancelPaymentBtnClickedJourney
		);
		this.topupService.returnFromWalletScreen = false;
		this.dynamicTrayService.closeTraySubject.next();
		setTimeout(() => {
			this.billingService.forwardTransition = false;
			this.showLeavingOverlay = false;
			this.billingService.dynamicTrayData.isGradientBackground = false;
		}, 1000);
	}
	ngOnDestroy(): void {
		this.topupService.dynamicTrayData.basicTrayData.showBackButton = false;
		this.topupService.dynamicTrayData.closeButtonAction = null;
		this.topupService.dynamicTrayData.backButtonAction = null;
	}

	sendTaggingClickOnXInteraction(): void {
		const pageTaggingName: string = tagging.paymentCardsPages.manageCreditCards.page_name;
		const eventName: string = tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.eventName.replace(
			'<page_name>',
			pageTaggingName
		);
		this.tagging.track(
			eventName,
			tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.data,
			tagging.paymentCardsPages.manageCreditCards
		);
	}
}
