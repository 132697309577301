import { CharacteristicsPerTerminalElement, CommercialTerminalList } from './commercial-terminal-list.model';

export class CommercialTerminal {
	public id: number;
	public type: string;
	public nombre: string;
	public marca?: string;
	public orden: number;
	public ordenCuotaMensual: number;
	public ordenPagoInicial: number;
	public listTerminals?: CommercialTerminalList[];
	public commercialTerminalList: CommercialTerminalList[];
	public characteristicsPerTerminalElement: CharacteristicsPerTerminalElement;
	public isPrioritary?: boolean;
	public orderCriteria?: Object;
}

export class CommercialTerminalKeywords {
	groupId: number;
	name: string;
	listTerminals: number[];
}

export class CommercialTerminalKeywordsItems {
	items: CommercialTerminalKeywords[];
}

export class CommercialKeywordsAll {
	items: CommercialKeywords[];
	ecode?: string;
}

export class CommercialKeywords {
	groupId: number;
	listKeywords: string[];
}
