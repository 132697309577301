import { TranslateService } from '@ngx-translate/core';
import { PaymentFullTrayModel } from '../../../models/payment';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentWalletService } from '../../../shared/services/payment-wallet.service';
import { DelegateCreationService } from '../delegate-creation.service';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentTrayConfigInterface } from './payment-tray-config-interface';
import { PaymentTrayConfigPrePaid } from './payment-tray-config-prePaid';
import { config } from '../../../../config/pages-config';
import { Router } from '@angular/router';

export class PaymentTrayConfigPrePaidAnonymous extends PaymentTrayConfigPrePaid implements PaymentTrayConfigInterface {
	// this component is treated like an ovrelay we remove tem from history after navigating away from them
	// not to show them to the user accidentaly when he navigate back
	compnentToBeRemovedFromHistoryAfternavigation: PaymentPages[] = [
		PaymentPages.cancel,
		PaymentPages.loading,
		PaymentPages.deleteConfiramtion,
	];
	/** variable to be used for getting a tray config method based on component name */
	trayConfigurations: any = {
		[PaymentPages.thirdPartyContainer]: this.getIframeCompTrayConfigAnonymous.bind(this),
		[PaymentPages.ok]: this.getOkCompTrayConfigAnonymous.bind(this),
		[PaymentPages.ko]: this.getKoCompTrayConfigِAnonymous.bind(this),
		[PaymentPages.cancel]: this.getCancelCompTrayConfig.bind(this),
		[PaymentPages.loading]: this.getLoadingCompTrayConfig.bind(this),
		[PaymentPages.ticket]: this.getTicketCompTrayConfigAnonymous.bind(this),
		[PaymentPages.topupScreen]: this.getTopUpLandingCompTrayConfigPrePaidAnonymous.bind(this),
	};
	constructor(
		public paymentNavService: PaymentNavigationService,
		public delegateService: DelegateCreationService,
		public translate: TranslateService,
		public paymentWalletService: PaymentWalletService,
		public router: Router
	) {
		super(paymentNavService, delegateService, translate, paymentWalletService);
	}
	/**
	 * @param compName the page we need it's tray configuration
	 * function should return PaymentFullTrayModel containing
	 * the proper tray configuration
	 */
	getCurrentTrayConfig(compName: PaymentPages): PaymentFullTrayModel {
		let config: PaymentFullTrayModel;
		/** if compName is exist in trayConfigurations then invoke its method to get tray configuration */
		if (this.trayConfigurations[compName]) {
			config = this.trayConfigurations[compName]();
		} else {
			config = this.getIframeCompTrayConfig();
		}
		return config;
	}
	/**
	 * function return PaymentFullTrayModel
	 * the configration of the iframe screen configration
	 */
	getIframeCompTrayConfigAnonymous(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getIframeCompTrayConfig();
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			if (this.paymentNavService.thirdPartyURLParams.transactionId) {
				this.paymentNavService.showCancelPayment();
			} else {
				this.paymentNavService.closePayment();
				if (this.router.url.toLowerCase().includes(config.publicPaymentTopup.route.toLowerCase())) {
					setTimeout(() => {
						this.router.navigate([config.login.route]);
					}, 500);
				}
			}
		};
		return paymentFullTrayModel;
	}

	/**
	 * function return PaymentFullTrayModel
	 * the configration of the ticket screen configration
	 */
	getTicketCompTrayConfigAnonymous(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getTicketCompTrayConfig();
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.delegateService.paymentTaggingConfig.ticketXbtnClickInteraction();
			this.paymentNavService.closePayment();
			if (this.router.url.toLowerCase().includes(config.publicPaymentTopup.route.toLowerCase())) {
				setTimeout(() => {
					this.router.navigate([config.login.route]);
				}, 500);
			}
		};
		return paymentFullTrayModel;
	}
	/**
	 * function return PaymentFullTrayModel
	 * the configration of the ko screen configration
	 */
	getKoCompTrayConfigِAnonymous(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getKoCompTrayConfigPrePaid();
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.paymentNavService.closePayment();
			if (this.router.url.toLowerCase().includes(config.publicPaymentTopup.route.toLowerCase())) {
				setTimeout(() => {
					this.router.navigate([config.login.route]);
				}, 500);
			}
		};
		return paymentFullTrayModel;
	}
	/**
	 * function return PaymentFullTrayModel
	 * the configration of the ok screen configration
	 */
	getOkCompTrayConfigAnonymous(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getOkCompTrayConfig();
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.paymentNavService.closePayment();
			if (this.router.url.toLowerCase().includes(config.publicPaymentTopup.route.toLowerCase())) {
				setTimeout(() => {
					this.router.navigate([config.login.route]);
				}, 500);
			}
		};
		return paymentFullTrayModel;
	}
	/**
	 * function return PaymentFullTrayModel
	 * the configuration of the inadvance payment start screen configuration
	 */
	getTopUpLandingCompTrayConfigPrePaidAnonymous(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getTopUpLandingCompTrayConfig();
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.delegateService.paymentTaggingConfig.topupLandingXbtnClickInteraction();
			this.paymentNavService.closePayment();
			if (this.router.url.toLowerCase().includes(config.publicPaymentTopup.route.toLowerCase())) {
				setTimeout(() => {
					this.router.navigate([config.login.route]);
				}, 500);
			}
		};
		return paymentFullTrayModel;
	}
}
