import { Pipe, PipeTransform } from '@angular/core';
import { coin } from '../../constants/defines';

@Pipe({
	name: 'priceToShow',
})
export class PriceToShowPipe implements PipeTransform {
	private pointInThousand(value: string): string {
		if (value.length > 3) {
			const rest = value.slice(0, -3);
			const integer = value.slice(-3);
			return this.pointInThousand(rest) + '.' + integer;
		} else {
			return value;
		}
	}

	transform(value: number): string {
		let res;
		value = Number(value);
		if (value % 1 === 0) {
			res = this.pointInThousand(value.toString());
		} else {
			const integer = value - (value % 1);
			const zeroLeft = value * 100 - integer * 100 < 10;
			const nine = value * 100 - integer * 100 > 9 && zeroLeft;
			const float = ((value - integer) * 100).toFixed(0);
			if (!zeroLeft || nine) {
				res = this.pointInThousand(integer.toString()) + ',' + float.toString();
			} else {
				res = this.pointInThousand(integer.toString()) + ',0' + float.toString();
			}
		}
		return res + coin;
	}
}
