<div class="has-error">
	<div class="iconText">
		<span *ngIf="!ShowErrorImg" class="icon icon-warning position-style"> </span>
		<img
			id="sharedErrorAlertDefaultImg"
			*ngIf="ShowErrorImg && !errorIconSrc && !iconId"
			src="https://assets-es-dev.dxldev.local.vodafone.es/assets/images/warning-hi-dark@3x.png"
			style="width: 86px; margin-bottom: 14px"
		/>
		<img
			id="sharedErrorAlertCustomImg"
			*ngIf="ShowErrorImg && errorIconSrc"
			[src]="errorIconSrc"
			style="margin-bottom: 14px"
		/>
		<mva10-c-icon
			id="sharedErrorAlertCustomIcon"
			*ngIf="ShowErrorImg && iconId"
			[size]="60"
			[iconId]="iconId"
			style="margin-bottom: 14px"
		>
		</mva10-c-icon>
		<div class="textWarning">
			<p id="sharedErrorAlertMessage" class="textLine1">{{ message }}</p>
		</div>
		<button *ngIf="showButton" id="sharedErrorAlertBtn" class="button red" (click)="action()" class="button red">
			{{ retryBtn }}
		</button>
	</div>
</div>
