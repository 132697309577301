import { TimePeriodDtoModel } from './time-period.dto.model';

export class TopUpCurrentSpendingDTOModel {
	type: string;
	description?: any;
	amount: number;
	quantity: number;
	destination: string;
	unit?: string;
	timePeriod: TimePeriodDtoModel;

	public constructor(init?: Partial<TopUpCurrentSpendingDTOModel>) {
		Object.assign(this, init);
	}
}
