import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http';
import { CacheService } from './cache.service';
import { CachedResponse } from '../models/cached-response';
import { CACHE_CONFIG } from '../constants/cache-config';
import { of, Observable } from 'rxjs';
import { LoggerService } from './LoggerService';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
	constructor(private logger: LoggerService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// intercept request

		// get the caching configuration for this api
		const cacheConfig = CACHE_CONFIG.find((config) => {
			const apiRegex = CacheService.buildCacheRegex(config.api.replace('?', '\\?'));
			return apiRegex.test(req.url);
		});
		if (CacheService.enableCaching && cacheConfig) {
			this.logger.info(`intercepted request for ${cacheConfig.key} with url ${req.url}`);
			const cachedResponse = CacheService.serveCached(req.url, cacheConfig);

			if (cachedResponse) {
				this.logger.info(`serving a cached response for ${cacheConfig.key} with url ${req.url}`);
				// SUBJECT TO REFACTOR
				// Added a delay of 100 ms as serving cached data results
				// in the observable being resolved too fast for change detection to
				// work properly inside P&S landing page until a better solution is found
				return of(cachedResponse.response);
			}
		}
		return next.handle(req).pipe(
			tap((event) => {
				if (event instanceof HttpResponse) {
					// Cache the request only if the api has caching configuration, request is a get request
					// and was served successfully
					if (CacheService.enableCaching && cacheConfig && req.method.toLowerCase() === 'get' && event.ok) {
						this.logger.info(`caching response for ${cacheConfig.key} with url ${req.url}`);
						const cacheResponse = new CachedResponse();
						cacheResponse.identifier = req.url;
						cacheResponse.expiryDate = cacheConfig.timeout
							? new Date(new Date().getTime() + 60000 * cacheConfig.timeout)
							: undefined;
						cacheResponse.response = event;
						cacheResponse.api = cacheConfig.key;
						CacheService.cacheTable.push(cacheResponse);
					}
				}
			})
		);
	}
}
