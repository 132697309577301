<vfac-overlay
	#nebaInstallationGuideTvOverlay
	[overlayData]="nebaInstallationGuideTvOverlayData"
	[show]="showNebaInstallationGuideTvOverlay"
	[page]="overlayPage"
	[loaded]="overlayLoaded"
	[loaderType]="'vodafone'"
	(closed)="closeGuideOverlay(true, 'btnCerrarGuia')"
	(backPage)="overlayGoBackwards()"
>
	<div header *ngIf="overlayPage > 1">
		<div class="guide-progress">
			<vfac-g-progress-bar
				[total]="progressBarTitles?.length"
				[value]="progressBarCurrentStep"
				[disableAnimation]="false"
				[title]="progressBarTitles[progressBarCurrentStep - 1] | translate"
				[showProgress]="true"
				[styleClass]="'mva10-u--body'"
			>
			</vfac-g-progress-bar>
		</div>
	</div>

	<!-- Intro -->
	<div body id="page1">
		<div class="intro">
			<div class="intro-header">
				<!-- CSS image goes here -->
			</div>
			<div class="intro-body">
				<span class="intro-body__title mva10-u-heading--3">
					{{ 'v10.myOrder.nebaInstallationTv.guideNebaTvIntroTitle' | translate }}
				</span>
				<ul class="intro-body__points">
					<li
						class="intro-body__points-container"
						[ngStyle]="{
							'background-image':
								'url(' + appService.getImgFullPath('v10.myOrder.nebaInstallationTv.img.networksignal' | translate) + ')'
						}"
					>
						<span
							class="intro-body__points-container__description mva10-u--body"
							[innerHTML]="'v10.myOrder.nebaInstallationTv.guideNebaTvIntroPoint1' | translate"
						>
						</span>
					</li>
				</ul>
				<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
			</div>
		</div>
	</div>

	<!-- Guide Page 1 -->
	<div body id="page2">
		<div class="guide-step step1">
			<span class="mva10-u-heading--4 vfes-align--center">
				<strong> {{ 'v10.myOrder.nebaInstallationTv.guideNebaTvStep1EntryTitle' | translate }} </strong>
			</span>
			<div class="components-grid">
				<div class="grid-image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallationTv.img.powersupply' | translate) }}"
						alt="Fuente de alimentación"
					/>
					<span class="mva10-u-heading--5">
						<strong> {{ 'v10.myOrder.nebaInstallationTv.guideNebaTvStep1Component1' | translate }} </strong>
					</span>
				</div>
				<div class="grid-image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallationTv.img.cableethernet' | translate) }}"
						alt="Cable Ethernet"
					/>
					<span class="mva10-u-heading--5">
						<strong> {{ 'v10.myOrder.nebaInstallationTv.guideNebaTvStep1Component2' | translate }} </strong>
					</span>
				</div>
				<div class="grid-image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallationTv.img.decoder' | translate) }}"
						alt="Decodificador"
					/>
					<span class="mva10-u-heading--5">
						<strong> {{ 'v10.myOrder.nebaInstallationTv.guideNebaTvStep1Component3' | translate }} </strong>
					</span>
				</div>
				<div class="grid-image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallationTv.img.mando' | translate) }}"
						alt="Mando"
					/>
					<span class="mva10-u-heading--5">
						<strong> {{ 'v10.myOrder.nebaInstallationTv.guideNebaTvStep1Component4' | translate }} </strong>
					</span>
				</div>
				<div class="grid-image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallationTv.img.cablehdmi' | translate) }}"
						alt="Cable HDMI"
					/>
					<span class="mva10-u-heading--5">
						<strong> {{ 'v10.myOrder.nebaInstallationTv.guideNebaTvStep1Component5' | translate }} </strong>
					</span>
				</div>
				<div class="grid-image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallationTv.img.cabletdt' | translate) }}"
						alt="Cable TDT"
					/>
					<span class="mva10-u-heading--5">
						<strong> {{ 'v10.myOrder.nebaInstallationTv.guideNebaTvStep1Component6' | translate }} </strong>
					</span>
				</div>
			</div>
			<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
		</div>
	</div>

	<!-- Guide Page 2 -->
	<div body id="page3">
		<div class="guide-step step2">
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					title: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep2EntryTitle',
					description: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep2EntryDescription',
					customClass: 'vfes-mt-'
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep2Component1SubTitle',
					description: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep2Component1Description',
					imageAlt: 'Conectar el decodificador.',
					image: 'v10.myOrder.nebaInstallationTv.img.connectdecoder' | translate | imgFullPath
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep2Component2SubTitle',
					description: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep2Component2Description',
					imageAlt: 'Conectar TV al decodificador.',
					image: 'v10.myOrder.nebaInstallationTv.img.connecttvtodecoder' | translate | imgFullPath
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep2Component3SubTitle',
					description: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep2Component3Description',
					imageAlt: 'Conectar cable alimentación al decodificador.',
					image: 'v10.myOrder.nebaInstallationTv.img.connectpowersupplycabletodecoder' | translate | imgFullPath
				}"
			>
			</ng-container>
		</div>
		<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
	</div>

	<!-- Guide Page 3 -->
	<div body id="page4">
		<div class="guide-step step3">
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					title: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep3EntryTitle',
					customClass: 'smaller vfes-mt-'
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep3Component1SubTitle',
					description: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep3Component1Description',
					imageAlt: 'Seleccionar fuente HDMI',
					image: 'v10.myOrder.nebaInstallationTv.img.selecthdmisource' | translate | imgFullPath
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep3Component2SubTitle',
					description: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep3Component2Description',
					imageAlt: 'Comprobar inicio guia de descodificador',
					image: 'v10.myOrder.nebaInstallationTv.img.checkstartguidedecoder' | translate | imgFullPath
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep3Component3SubTitle',
					description: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep3Component3Description',
					imageAlt: 'Conectar tv al decodificador.',
					image: 'v10.myOrder.nebaInstallationTv.img.connectdataandhdmi' | translate | imgFullPath
				}"
			>
			</ng-container>
		</div>
		<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
	</div>

	<!-- Guide Page 4 -->
	<div body id="page5">
		<div class="guide-step step4">
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					title: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep4EntryTitle',
					customClass: 'smaller vfes-mt-'
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep4Component1SubTitle',
					description: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep4Component1Description1',
					imageAlt: 'Un momento por favor',
					image: 'v10.myOrder.nebaInstallationTv.img.installationamoment' | translate | imgFullPath,
					customClass: 'smaller vfes-mt--'
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					imageAlt: 'Instalando Vodafone TV',
					image: 'v10.myOrder.nebaInstallationTv.img.vodafonetv' | translate | imgFullPath,
					customClass: 'smaller vfes-mt- vfes-mb'
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					description: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep4Component1Description2'
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep4Component2SubTitle',
					description: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep4Component2Description',
					imageAlt: 'Condiciones de privacidad.',
					image: 'v10.myOrder.nebaInstallationTv.img.privacyconditionstv' | translate | imgFullPath
				}"
			>
			</ng-container>
		</div>
		<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
	</div>

	<!-- Guide Page 5 -->
	<div body id="page6">
		<div class="guide-step step5">
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					title: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep5EntryTitle',
					customClass: 'vfes-mt-'
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep5Component1SubTitle',
					description: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep5Component1Description',
					imageAlt: 'Empezar con Alexa (Amazon).',
					image: 'v10.myOrder.nebaInstallationTv.img.amazonalexastart' | translate | imgFullPath,
					customClass: 'smaller vfes-mb-'
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					imageAlt: 'Crear cuenta de Alexa (Amazon).',
					image: 'v10.myOrder.nebaInstallationTv.img.amazonalexacreateaccount' | translate | imgFullPath,
					customClass: 'smaller  vfes-mb-'
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					imageAlt: 'Cuenta de Alexa (Amazon) lista.',
					image: 'v10.myOrder.nebaInstallationTv.img.amazonalexaaccountready' | translate | imgFullPath,
					customClass: 'smaller vfes-mb'
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep5Component2SubTitle',
					description: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep5Component2Description',
					imageAlt: 'Ahorrar energia.',
					image: 'v10.myOrder.nebaInstallationTv.img.energysaving' | translate | imgFullPath
				}"
			>
			</ng-container>
		</div>
		<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
	</div>

	<!-- Guide Page 6 -->
	<div body id="page7">
		<div class="guide-step step6">
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					title: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep6EntryTitle',
					customClass: 'vfes-mt-'
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep6Component1SubTitle',
					description: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep6Component1Description',
					imageAlt: 'Emparejar mando.',
					image: 'v10.myOrder.nebaInstallationTv.img.mandoconnection' | translate | imgFullPath
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep6Component2SubTitle',
					description: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep6Component2Description1',
					imageAlt: 'Busqueda de canales',
					image: 'v10.myOrder.nebaInstallationTv.img.channelssearch' | translate | imgFullPath,
					customClass: 'smaller vfes-mb'
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					description: 'v10.myOrder.nebaInstallationTv.guideNebaTvStep6Component2Description2'
				}"
			>
			</ng-container>
		</div>
		<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
	</div>
</vfac-overlay>

<ng-template #navigationButtonsTemplate>
	<div class="navigation-buttons">
		<mva10-c-button
			(mva10OnClick)="
				overlayPage === pagesNames.length
					? translate.instant('v10.myOrder.checkInstallationOverlay.checkNebaAutoInstallationActive') === stringOfTrue
						? closeGuideOverlayAndOpenInstallationCheck()
						: closeGuideOverlay(true, 'btnComprobarInstalacion')
					: overlayGoForwards()
			"
		>
			{{
				overlayPage !== pagesNames.length
					? 'v10.myOrder.nebaInstallationTv.guideNebaTvNextStep'
					: ('v10.myOrder.checkInstallationOverlay.checkNebaAutoInstallationActive' | translate) === stringOfTrue
					? ('v10.myOrder.nebaInstallationTv.autoiCheckInstallationButton' | translate)
					: ('v10.myOrder.nebaInstallationTv.guideNebaTvOverlayPageFinish' | translate)
			}}
		</mva10-c-button>
	</div>
</ng-template>

<ng-template
	#greyCardTemplate
	let-title="title"
	let-subTitle="subTitle"
	let-description="description"
	let-image="image"
	let-imageAlt="imageAlt"
	let-customClass="customClass"
>
	<div class="grey-card">
		<div class="activate-router-title mva10-u-heading--4 vfes-mb vfes-mt" *ngIf="title">
			<strong> {{ title | translate }} </strong>
		</div>
		<div class="activate-router-title mva10-u-heading--4 vfes-mb vfes-mt- vfes-title--left" *ngIf="subTitle">
			<strong> {{ subTitle | translate }} </strong>
		</div>
		<p
			*ngIf="description"
			class="activate-router-description vfes-mb mva10-u-body vfes-title--left"
			[innerHTML]="description | translate"
		></p>
		<div class="activate-router-image" *ngIf="image" [ngClass]="customClass">
			<img [src]="image" [alt]="imageAlt" />
		</div>
	</div>
</ng-template>
