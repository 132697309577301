import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Error } from '../../models/error.model';
import { NotificationService } from '../../shared/services/notification.service';
import { ErrorHandlingService } from '../../core/services/errorHandling.service';
import { SpModalComponent } from '../../shared/components/sp-modal/sp-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorHadlingUsingNotificationService {
	customError: Error;
	constructor(
		private errorHandling: ErrorHandlingService,
		private notificationService: NotificationService,
		private translateService: TranslateService
	) {}
	errorHandlingUsingNotification(
		error: HttpResponse<any>,
		requestMethod: string,
		pageName: string,
		isPageLoaded?: boolean,
		hideFunction?: Function
	): SpModalComponent {
		this.customError = new Error();
		this.customError = this.errorHandling.lookUpError(pageName, error);
		return this.notificationService.createErrorNotification(
			error,
			this.customError,
			requestMethod,
			isPageLoaded,
			hideFunction
		);
	}
	noConnectionHandlingNotification(
		error: HttpResponse<any>,
		requestMethod: string,
		isPageLoaded?: boolean
	): SpModalComponent {
		this.customError = new Error();
		this.translateService.get('common.messagesList.noConnectionError').subscribe((data) => {
			this.customError.title = data.title;
			this.customError.description = data.description;
			this.customError.retryButton = true;
			this.notificationService.createErrorNotification(error, this.customError, requestMethod, isPageLoaded);
		});
		return this.notificationService.notificationModal;
	}

	showSimpleErrorModal(customError: Error): void {
		const notificationModal: SpModalComponent = this.notificationService.createErrorNotification(
			null,
			customError,
			null
		);
		notificationModal.show();
	}
}
