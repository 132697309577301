import { Component, Input } from '@angular/core';

/**
 * Based on Card Label component: http://dev.vodafone.es/docs/mva10/componentes/card-label/
 */
@Component({
	selector: 'sp-label-nba',
	templateUrl: './label-nba.component.html',
	styleUrls: ['./label-nba.component.scss'],
})
export class LabelNbaComponent {
	/**
	 * Title inside the header of the NBA
	 */
	@Input() title: string;
	/**
	 * Whether to expand the title to content's full width (true) or fit to title width (false)
	 */
	@Input() largeTitle: string;
	/**
	 * Determines color for label and border.
	 * For more colors see http://dev.vodafone.es/docs/mva10/dev-tools/colors-gradients/
	 */
	@Input() colorBg: string = 'primary';
	/**
	 * Icon to show on the left side of the description. The icon has to be in icon's spritesheet
	 * For example: #icon-price_promise
	 */
	@Input() iconLeft: string;
	/**
	 * Description between the left icon and the right chevron. Can be any valid HTML string
	 */
	@Input() description: string;
	/**
	 * Whether to show a chevron on the right side of the description or not
	 */
	@Input() showChevron: boolean = false;
}
