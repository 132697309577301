<div class="vfac-submenu-account vfes-2/3">
	<p class="vfac-submenu-account__title mva10-u--body" id="submenusShopSelectorTitle">
		{{ 'v10.common.tray.overlay_shop.title' | translate }}
	</p>
	<p class="vfac-submenu-account__subtitle mva10-u-heading--4-lt" id="submenusShopSelectorSubmenu">
		{{ 'v10.common.tray.overlay_shop.subTitle' | translate }}
	</p>
	<div class="vfac-submenu-account__cards-container">
		<mva10-c-card
			[ngClass]="'vfac-submenu-account__card'"
			[colorBg]="'aluminium'"
			[modifier]="'no-shadow'"
			(click)="clickOnShopSelectorCard(false)"
			id="submenusShopSelectorShopCard"
		>
			<img
				class="vfac-submenu-account__card__image"
				src="{{ 'v10.common.tray.overlay_shop.card_shop.img_card_shop' | imgFullPath }}"
				alt="shop"
				id="submenusShopSelectorShopCardImage"
			/>
			<div class="vfac-submenu-account__card__container">
				<p
					class="vfes-title--bold vfac-c-main-tile-simple__title vfac-submenu-account__card__title"
					id="submenusShopSelectorShopCardTitle"
				>
					{{ 'v10.common.tray.overlay_shop.card_shop.title' | translate }}
				</p>
				<p
					class="vfes-title--regular vfac-submenu-account__card__description"
					id="submenusShopSelectorShopCardDescription"
				>
					{{ 'v10.common.tray.overlay_shop.card_shop.subtitle' | translate }}
				</p>
			</div>
		</mva10-c-card>
		<mva10-c-card
			[ngClass]="'vfac-submenu-account__card'"
			[colorBg]="'aluminium'"
			[modifier]="'no-shadow'"
			(click)="clickOnShopSelectorCard(true)"
		>
			<img
				class="vfac-submenu-account__card__image"
				src="{{ 'v10.common.tray.overlay_shop.card_mktp.img_card_mktp' | imgFullPath }}"
				alt="marketplace"
				id="submenusShopSelectorMktpCardImage"
			/>
			<div class="vfac-submenu-account__card__container">
				<p
					class="vfes-title--bold vfac-c-main-tile-simple__title vfac-submenu-account__card__title"
					id="submenusShopSelectorMktpCardTitle"
				>
					{{ 'v10.common.tray.overlay_shop.card_mktp.title' | translate }}
				</p>
				<p
					class="vfes-title--regular vfac-submenu-account__card__description"
					id="submenusShopSelectorMktpCardDescription"
				>
					{{ 'v10.common.tray.overlay_shop.card_mktp.subtitle' | translate }}
				</p>
			</div>
		</mva10-c-card>
	</div>
</div>
