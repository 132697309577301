import { Pipe, PipeTransform } from '@angular/core';
import { DatePipeFormat, datePipeErrors } from '../constants/defines';
import { UtilitiesService } from '../utils/utilities.service';
import moment from 'moment';

/**
 * Pipe used to transform dates based on UtilitiesService functions. In case of not maching any of the defined functions,
 * the pipe will try to parse the input date based on `outputFormat` and `inputFormat` arguments.
 *
 * Usage: myDate | formatDate: 'outputFormat'[:'inputFormat']
 *
 * @param outputFormat First argument of the pipe and desired output format for input date.
 * Any ISO 8601 string is valid. Example: 'D MMM YYYY'
 * @param inputFormat [Optional] Second argument of the pipe used to correctly detect input date format.
 * It should be idicated in case of 'INVALID INPUT FORMAT' message appears. Defaults to: 'DD/MM/YYYY'
 */
@Pipe({
	name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
	constructor(public utils: UtilitiesService) {
		moment.locale('es');
	}

	transform(date: string, outputFormat: string, inputFormat: string = DatePipeFormat.defaultInputFormat): string {
		if (!date) {
			return datePipeErrors.missingDate;
		}
		if (!outputFormat) {
			return datePipeErrors.missingOutputFormat;
		}
		const momentDate: moment.Moment = moment(date, inputFormat);
		if (!momentDate.isValid()) {
			return datePipeErrors.invalidInputFormat;
		}

		const utilsFormats: string[] = [
			DatePipeFormat.dayLongMonthAndYear,
			DatePipeFormat.dayShortMonthAndYear,
			DatePipeFormat.dayLongMonth,
			DatePipeFormat.dayShortMonth,
			DatePipeFormat.shortMonthAndYear,
			DatePipeFormat.legibleFullDate,
			DatePipeFormat.timeAndFullDate,
			DatePipeFormat.dayName,
		];
		if (utilsFormats.includes(outputFormat)) {
			return this.parseWithUtils(momentDate, outputFormat);
		}

		if (outputFormat === DatePipeFormat.fullDayAndMonth) {
			// Martes, 31 de Diciembre
			return (
				this.utils.capitalizeText(momentDate.format(DatePipeFormat.dayName)) +
				', ' +
				momentDate.format(DatePipeFormat.dayNumber) +
				' de ' +
				this.utils.capitalizeText(momentDate.format(DatePipeFormat.monthName))
			);
		}

		// Custom outputFormat (not in UtilitiesService)
		const destinationDate: string = momentDate.format(outputFormat);
		return moment(destinationDate, outputFormat).isValid() ? destinationDate : datePipeErrors.invalidOutputFormat;
	}

	private parseWithUtils(momentDate: moment.Moment, outputFormat: string): string {
		if (outputFormat === DatePipeFormat.dayLongMonthAndYear) {
			// 1 junio 2017
			return this.utils.getDateWithMonth(momentDate.format());
		}
		if (outputFormat === DatePipeFormat.dayShortMonthAndYear) {
			// 1 jun 2017
			return this.utils.getDateWithMonth(momentDate.format(), true);
		}
		if (outputFormat === DatePipeFormat.dayLongMonth) {
			// 1 junio
			return this.utils.getDateWithMonth(momentDate.format(), false, false);
		}
		if (outputFormat === DatePipeFormat.dayShortMonth) {
			// 1 jun
			return this.utils.getDateWithMonth(momentDate.format(), true, false);
		}
		if (outputFormat === DatePipeFormat.shortMonthAndYear) {
			// jun 2017
			return this.utils.getDateWithMonth(momentDate.format(), true, true, false);
		}
		if (outputFormat === DatePipeFormat.legibleFullDate) {
			// Martes, 31/12/2018  11:00h
			return this.utils.dateWithDayName(momentDate.format());
		}
		if (outputFormat === DatePipeFormat.timeAndFullDate) {
			// 22:00, 31/12/2018
			return this.utils.dateFormateWithHours(momentDate.format());
		}
		if (outputFormat === DatePipeFormat.dayName) {
			// Lunes
			return this.utils.getDayName(momentDate.day());
		}
	}
}
