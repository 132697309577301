import { Component, Input, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
	selector: 'sp-loader-logo',
	templateUrl: './sp-loader-logo.component.html',
	styleUrls: ['./sp-loader-logo.component.scss'],
})
export class SpLoaderLogoComponent implements OnInit {
	@Input() width: number;
	@Input() height: number;
	@Input() lottieImageUrl: string;
	@Input() loop: boolean = true;
	@Input() loadingText: string;
	@Input() textWidth: number;

	lottieConfig: AnimationOptions;
	anim: AnimationItem;

	ngOnInit(): void {
		this.lottieConfig = {
			path: this.lottieImageUrl,
			autoplay: true,
			loop: this.loop,
		};
	}
	handleAnimation(anim: AnimationItem): void {
		this.anim = anim;
	}
}
