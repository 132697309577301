export const FOLDER_TRAY_FOR_YOU = 'tray-configuration/tray-for-you';
export const FOLDER_TRAY_TOP_UP = 'tray-configuration/tray-top-up';
export const FOLDER_TRAY_KIT_DIGITAL = 'tray-configuration/tray-kit-digital';
export const FOLDER_TRAY_ONE_PROFESSIONAL = 'tray-configuration/tray-one-professional';
export const FOLDER_TRAY_SHOP_SELECTOR = 'tray-configuration/tray-shop-selector-overlay';
export const FOLDER_TRAY_HISTORY = 'tray-configuration/tray-history';
export const TRAY_CONFIG_TRACKING = {
	EVENTS: {
		TRAY_INTERACTION: 'event_tray-interaction',
		SHOW_SHOP_SELECTOR: 'click en tienda',
		CLICK_FOR_YOU: 'click en tray para ti',
		CLICK_TOP_UP: 'click en tray recargas',
		CLICK_KIT_DIGITAL: 'click en tray kit digital',
		CLICK_HISTORY: 'click en historico',
		CLICK_ONE_PROFESSIONAL: 'click en tray negocio',
		GO_TO_SHOP: 'click en tienda vodafone',
		GO_TO_MKTP: 'click en otros vendedores',
	},
};
