<article
	class="vfes-card vfes-card-border-radius vfes-colours-bg-vodafone-white vfes-card-no-border mvf-detail vfes-mt"
>
	<!-- HEADER 1.0 -->

	<section class="mvf-detail__header-group">
		<header class="mvf-collapse__header">
			<!-- ICON -->
			<div>
				<svg
					class="vfes-alert-icon icon"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					width="24px"
					height="24px"
				>
					<title>{{ 'v10.myOrder.detailNtol.error' | translate }}</title>
					<use class="icon-v3" xlink:href="" [attr.xlink:href]="'#' + icon"></use>
				</svg>
			</div>
			<!-- TITULO Y DIRECCION -->
			<div class="mvf-collapse__title-container">
				<h3 class="mvf-collapse__title">
					<span class="mvf-collapse__title-text mva10-u-heading--5-bd">
						{{ orderData.nombreComercial || orderData.descripcion }}
					</span>
				</h3>
				<span class="mvf-collapse__subtitle mva10-u--body">
					{{ orderData.nombreTienda }}
				</span>
			</div>
		</header>

		<!-- FECHA DE SOLICITUD -->
		<section class="mvf-detail__header-content">
			<div>
				<span class="mvf-detail__item-title mva10-u--body">
					{{ titleFecha }}
				</span>

				<span class="mvf-detail__item-desc mva10-u--body-bd">
					{{
						getDateByOrderState(orderData.statePedido)?.includes('/')
							? getDateByOrderState(orderData.statePedido)
							: (getDateByOrderState(orderData.statePedido) | date : 'dd/MM/yyyy')
					}}
				</span>
			</div>
		</section>
		<!-- Botón detail web -->
		<sp-button (click)="closeDetail.emit()" [parentClass]="'mvf-button--closeable mvf-button--closeable--web'">
			<span class="mvf-button__icon arrow-down arrow-rotate"> </span>
		</sp-button>
	</section>

	<!-- DETAIL CONTENT -->
	<section class="mvf-detail__content">
		<div>
			<div class="mvf-order-detail-summary__image">
				<figure class="center">
					<img src="{{ appService.getImgFullPath('v10.myOrder.detailNtol.img.clock' | translate) }}" alt="Waiting" />
				</figure>
				<div class="mvf-order-detail-summary__icono-responsive">
					<svg
						class="vfes-alert-icon icon"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						width="24px"
						height="24px"
					>
						<title>{{ 'v10.myOrder.detailNtol.error' | translate }}</title>
						<use class="mvf-detail__icon" [attr.xlink:href]="'#icon-clock-or-timeout-mid'"></use>
					</svg>
				</div>
				<div class="vfes-align--center">
					<span>
						{{ 'v10.myOrder.detailNtol.workingInYourOrder' | translate }}
					</span>
				</div>
			</div>
		</div>

		<div *ngIf="incluidoPedido.length > 0">
			<h3 class="mvf-order-detail-summary__title">{{ 'v10.myOrder.detailNtol.orderInclude' | translate }}</h3>

			<ul class="mvf-order-detail-summary__list">
				<li class="mvf-order-detail-summary__item" *ngFor="let item of incluidoPedido">
					{{ item.nombre }}
					<ul class="mvf-order-detail-summary__list2" *ngIf="item.elementos">
						<li class="mvf-order-detail-summary__item2" *ngFor="let item2 of item.elementos">
							{{ item2 }}
						</li>
					</ul>
				</li>
			</ul>
			<span class="mvf-order-detail-summary__link" (click)="redirectToContracts()">
				{{ 'v10.myOrder.detailNtol.linkSummaryPurchase' | translate }}
			</span>
		</div>

		<sp-button (click)="closeDetail.emit()" [parentClass]="'mvf-button--closeable mvf-button--closeable--mobile'">
			<span class="mvf-button__text mva10-u--body"> {{ 'cerrar' | translate }} </span>
		</sp-button>
	</section>
	<div class="mvf-detail-error" *ngIf="showSpinner">
		<sp-loader [showSpinner]="showSpinner"> </sp-loader>
	</div>
</article>
