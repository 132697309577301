import { Contract } from './contract.model';
import { ServicePackageModel } from './../models/service-package.model';
import { ServiceType } from '../shared/enums/serviceType.enum';

export class ServiceDTO {
	id?: string;
	name?: string;
	description?: string;
	type?: ServiceType;
	iconName?: string;
	package?: ServicePackageModel;
	msisdn?: string;
	serviceContracts?: Contract[];
}
