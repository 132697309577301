<div class="queIncluye moduleContent" *ngIf="include" [ngClass]="{ 'background-White': include.ctaText }">
	<h3 [ngClass]="{ sectionTitle: !modeBuy, sectionTitleCTA: modeBuy }" id="tryAndBuyWhatIncludeTitle">
		{{ include.title }}
	</h3>
	<ul>
		<li *ngFor="let item of include.elements; let indice = index">
			<span class="products__item--left-icon" id="tryAndBuyWhatIncludeImage{{ indice }}">
				<img class="listImage" [src]="item.image | imgFullPath" />
			</span>
			<span innerHTML="{{ item.description }}" id="tryAndBuyWhatIncludeDescription{{ indice }}"> </span>
		</li>
	</ul>
	<div *ngIf="modeBuy && include.ctaText" class="linkCTA" (click)="openOverlayCta()" id="tryAndBuyWhatIncludeLinkCta">
		<p class="Headline" id="tryAndBuyWhatIncludeCtaText">{{ include.ctaText }}</p>
		<svg class="icon" id="tryAndBuyWhatIncludeCtaIcon">
			<use xlink:href="#icon-chevron-right" />
		</svg>
	</div>
</div>

<mva10-c-backdrop
	*ngIf="modeBuy && include.ctaText"
	[show]="showOverlayCta"
	(close)="closeOverlayCta()"
	(back)="closeOverlayCta()"
	class="ctaContainer"
	id="tryAndBuyWhatIncludeShowOverlayCta"
>
	<div bd-subtitle id="tryAndBuyWhatIncludeCtaSubtitle">
		<p class="mva10-u-heading--5 txt-bold" id="tryAndBuyWhatIncludeShowOverlayTitle">
			{{ include.includeCTA_overlay.title }}
		</p>
		<p class="mva10-u-heading--3-lt fontLight" id="tryAndBuyWhatIncludeShowOverlayDescription">
			{{ productName }}
		</p>
	</div>
	<div
		bd-content
		[innerHTML]="include.includeCTA_overlay.description"
		id="tryAndBuyWhatIncludeShowOverlayInnerDesc"
	></div>
</mva10-c-backdrop>
