import { Injectable } from '@angular/core';
import { responseType } from '../../../enums/response-type.enum';
import { DataPortabilidadModel } from '../../../models/dataPortabilidad.model';
import { MyOrdersService } from './my-orders.service';
import { OrdersService } from './orders.service';

@Injectable({
	providedIn: 'root',
})
export class PortabilityService {
	dataPort: DataPortabilidadModel;
	constructor(public ordersService: OrdersService, public myOrdersService: MyOrdersService) {}

	public sendInfo(dataPort: DataPortabilidadModel): void {
		this.dataPort = dataPort;
		dataPort.idCliente = this.myOrdersService.MYORDERS_DATA.idCliente;
		dataPort.numeroPedido = this.ordersService.detailOrderData.numeroPedido;
		this.ordersService.postRelanzarPortabilidadPack(dataPort).subscribe({
			next: () => {
				this.myOrdersService.showSpinner = false;
				this.myOrdersService.loadingResponseBox = false;
				this.myOrdersService.typeResponseBox = responseType.success;
				this.myOrdersService.isPortResponseBox = true;
				this.myOrdersService.showResponseBox = true;
			},
			error: (error) => {
				this.myOrdersService.showSpinner = false;
				this.myOrdersService.loadingResponseBox = false;
				this.myOrdersService.typeResponseBox = responseType.error;
				this.myOrdersService.isPortResponseBox = true;
				if (error.status >= 500 || error.errorCode >= 500) {
					this.myOrdersService.genericErrorResponseBox = true;
				}
				this.myOrdersService.showResponseBox = true;
			},
		});
	}
}
