import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverLayContent } from '../../models/overlayContent.model';
import { smartPayLandingImage, AppThemes } from '../../shared/constants/defines';
import { SpMva10OverlayService } from '../../shared/services/sp-mva10-overlay.service';
import { Router } from '@angular/router';
import { config } from './../../../config/pages-config';
import { ResizeService } from '../../mva10/shared/services/resize.service';
import { Breakpoints } from '@mva10/mva10-angular';
import { AppService } from '../../app.service';

@Component({
	selector: 'sp-new-smart-pay-landing',
	templateUrl: './new-smart-pay-landing.component.html',
	styleUrls: ['./new-smart-pay-landing.component.scss'],
})
export class NewSmartPayLandingComponent implements OnInit, OnDestroy {
	overlayContent: OverLayContent;
	isMobile: boolean;
	previousTheme: string;
	constructor(
		public translate: TranslateService,
		private trayService: SpMva10OverlayService,
		private router: Router,
		private resizeService: ResizeService,
		private appService: AppService
	) {}

	ngOnInit(): void {
		this.appService.hiddenHeader = true;
		this.previousTheme = this.appService.theme;
		this.isMobile = this.resizeService.breakpoint < Breakpoints.TABLET;
		this.appService.theme = this.isMobile ? AppThemes.ThemeBkgWhite + ' ' + AppThemes.Theme2 : AppThemes.ThemeGrayBG;
		this.setUIContent();
	}

	setUIContent(): void {
		this.overlayContent = {
			pageTitle: this.translate.instant('v10.productsServices.smartPay.common.easypay'),
			pageSubtitle: this.translate.instant('v10.productsServices.smartPay.PyS.benefits_spInact.title'),
			btnConfigs: {
				btnName: this.translate.instant('v10.productsServices.smartPay.discoverScreen.button'),
			},
			imgUrl: this.isMobile ? smartPayLandingImage.mobileCoverImage : smartPayLandingImage.desktopCoverImage,
		};
		this.translate.get('v10.productsServices.smartPay.PyS.benefits').subscribe((data) => {
			const keys: any = Object.keys(data);
			this.overlayContent.subtitleContent = [];
			keys.forEach((value) => {
				if (value.includes('text')) {
					this.overlayContent.subtitleContent.push({ title: data[value] });
				}
			});
			smartPayLandingImage.contentImages.forEach((imgUrl, index) => {
				this.overlayContent.subtitleContent[index].imageUrl = imgUrl;
			});
		});
	}

	ngOnDestroy(): void {
		this.appService.theme = this.previousTheme;
		this.appService.hiddenHeader = false;
	}

	closeTray(): void {
		this.trayService.close();
		this.router.navigate([config.dashboard.route]);
	}

	btnAction(): void {
		this.trayService.close();
		this.router.navigate([config.ProductsAndServices.Smartpay.route]);
	}
}
