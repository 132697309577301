import { Injectable } from '@angular/core';
import * as JsonQuery from 'jsonpath/jsonpath';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PaymentCompoentInputData_Keys } from 'src/app/shared/enums/payment-compoent-input-data-keys.enum';
import { PaymentPages } from 'src/app/shared/enums/payment-pages.enum';
import { PaymentTrayAction } from 'src/app/shared/enums/payment-tray-action.enum';
import { PaymentJourny } from 'src/app/shared/enums/payment-journy.enum';
import { PaymentErrorCategory } from 'src/app/shared/enums/payment-error-category.enum';
import { AnonymousPaymentCustomer, AnonymousPaymentCustomerDept } from '../../models/payment';
import { AuthenticateService } from '../../core/services/authenticate.service';
import { ERRORCODES, JSON_PATHS } from '../../shared/constants/defines';
import { PaymentDataService } from '../../shared/services/payment-data.service';
import { PaymentErrorService } from './payment-error.service';
import { PaymentNavigationService } from './payment-navigation.service';
import { tagging } from '../../../config/tagging-config';
import { HttpErrorResponse } from '@angular/common/http';
import {
	FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION,
	PAYMENTS_TRACKING,
} from 'src/app/shared/constants/archetype/paymentsTracking';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';
import { NewTaggingHelperService } from 'src/app/core/services/new-tagging.helper.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { TaggingHelperService } from 'src/app/core/services/tagging.helper.service';
@Injectable({
	providedIn: 'root',
})
export class AnonymousPostPaidPaymentService {
	anonymousPaymentCustomer: AnonymousPaymentCustomer = new AnonymousPaymentCustomer();
	subscriptionToClosePaymentModule: any;
	constructor(
		private authenticateService: AuthenticateService,
		private paymentNavService: PaymentNavigationService,
		private paymentErrorService: PaymentErrorService,
		private storageService: StorageService,
		private taggingHelperService: TaggingHelperService,
		private paymentDataService: PaymentDataService,
		private newTagging: NewTaggingHelperService
	) {}

	// post validate Anonymous Payment Customer and request to send him otp
	validateAnonymousPaymentCustomer(loginHint: string): Observable<any> {
		return this.paymentDataService.validateCustomer(loginHint);
	}
	// post validate Anonymous Payment OTP
	validateAnonymousPaymentOTP(OTP: string): Observable<any> {
		return this.paymentDataService.validateOTP(OTP).pipe(
			map((response) => {
				this.authenticateService.getUserProfileWithOTP(response);
			}),
			tap(() => {
				this.clearlocalStorageOnCLose();
			})
		);
	}

	getUserDept(customer: AnonymousPaymentCustomer): void {
		this.paymentNavService.showVodafoneLoader();
		this.paymentDataService
			.getUserDept(customer)
			.pipe(
				map((resp) => {
					return this.mapAnonymousPaymentCustomerDept(resp);
				})
			)
			.subscribe(
				(data: AnonymousPaymentCustomerDept) => {
					this.filterDataWithSelectedSiteID(data);
				},
				(err) => {
					this.paymentErrorService.handleEnteryPonitsErrorsCategory(PaymentErrorCategory.billDetailsFailure, [
						{
							key: PaymentCompoentInputData_Keys.paymentDeleteCardConfiramtionComponent_firstBtnAction,
							value: () => {
								this.getUserDept(customer);
							},
						},
						{
							key: PaymentCompoentInputData_Keys.paymentErrorComponent_firstBtnDimmed,
							value: true,
						},
					]);
					this.handleTaggingForError(PaymentErrorCategory.billDetailsFailure, err);
				}
			);
	}

	getUserDeptNoOTP(customer: AnonymousPaymentCustomer): void {
		this.paymentNavService.showVodafoneLoader();
		this.paymentDataService
			.getUserDeptNoOTP(customer)
			.pipe(
				map((resp) => {
					return this.mapAnonymousPaymentCustomerDept(resp);
				})
			)
			.subscribe(
				(data: AnonymousPaymentCustomerDept) => {
					this.filterDataWithSelectedSiteIDNoOtp(data);
				},
				(err) => {
					if (err.status === 401 || err.status === 400) {
						this.paymentErrorService.handleEnteryPonitsErrorsCategory(PaymentErrorCategory.noDebtFailure, [
							{
								key: PaymentCompoentInputData_Keys.paymentDeleteCardConfiramtionComponent_firstBtnAction,
								value: () => {
									this.paymentNavService.back(2);
								},
							},
						]);
						this.handleTaggingForError(PaymentErrorCategory.anonymousIdentifierFailure, err);
					} else if (err?.status === 403 && err.error?.code?.toString() === ERRORCODES.CLARIFYUSER) {
						this.paymentNavService.openPaymentComponent(PaymentPages.PaymentClarifyUser);
						this.sendPaymentClarifyTagging('403');
					} else {
						this.paymentErrorService.handleEnteryPonitsErrorsCategory(PaymentErrorCategory.billDetailsFailure, [
							{
								key: PaymentCompoentInputData_Keys.paymentDeleteCardConfiramtionComponent_firstBtnAction,
								value: () => {
									this.getUserDeptNoOTP(customer);
								},
							},
							{
								key: PaymentCompoentInputData_Keys.paymentErrorComponent_firstBtnDimmed,
								value: true,
							},
						]);
						this.handleTaggingForError(PaymentErrorCategory.billDetailsFailureNoOTP, err);
					}
				}
			);
	}
	mapAnonymousPaymentCustomerDept(data: any): AnonymousPaymentCustomerDept {
		const anonymousPaymentCustomerDept: AnonymousPaymentCustomerDept = new AnonymousPaymentCustomerDept();
		anonymousPaymentCustomerDept.state = JsonQuery.value(data, JSON_PATHS.PaymentCustomerDept.state) || null;
		anonymousPaymentCustomerDept.accountBalanceAmount =
			JsonQuery.value(data, JSON_PATHS.PaymentCustomerDept.accountBalanceAmount) || null;
		anonymousPaymentCustomerDept.accountBalanceAmountUnit =
			JsonQuery.value(data, JSON_PATHS.PaymentCustomerDept.accountBalanceAmountUnit) || null;
		return anonymousPaymentCustomerDept;
	}
	filterDataWithSelectedSiteID(data: AnonymousPaymentCustomerDept): void {
		let showDept = false;
		if (data && data.accountBalanceAmount != null) {
			this.anonymousPaymentCustomer = { ...this.anonymousPaymentCustomer, dept: data };
			showDept = true;
		}
		this.anonymousPaymentCustomer.selectedsiteId = this.storageService.userProfile?.sites[0]?.id;
		this.paymentNavService.openPaymentComponent(PaymentPages.paymentAnonymousBilling, PaymentJourny.paymentAnonymus, [
			{ key: PaymentCompoentInputData_Keys.showDebt, value: showDept },
		]);
		this.storageService.empty();
	}
	filterDataWithSelectedSiteIDNoOtp(data: AnonymousPaymentCustomerDept): void {
		if (data) {
			this.anonymousPaymentCustomer = { ...this.anonymousPaymentCustomer, dept: data };
			this.paymentNavService.openPaymentComponent(PaymentPages.paymentAnonymousBilling, PaymentJourny.paymentAnonymus, [
				{ key: PaymentCompoentInputData_Keys.showDebt, value: true },
			]);
		} else {
			this.paymentErrorService.handleEnteryPonitsErrorsCategory(PaymentErrorCategory.noDebtFailure, [
				{
					key: PaymentCompoentInputData_Keys.paymentDeleteCardConfiramtionComponent_firstBtnAction,
					value: () => {
						this.paymentNavService.back(2);
					},
				},
			]);
		}
		this.anonymousPaymentCustomer.selectedsiteId = this.paymentNavService.accountNumberValue
			? this.paymentNavService.accountNumberValue
			: this.storageService.userProfile?.sites[0]?.id;
		this.storageService.empty();
	}
	clearlocalStorageOnCLose(): void {
		if (this.subscriptionToClosePaymentModule) {
			this.subscriptionToClosePaymentModule.unsubscribe();
		}
		this.subscriptionToClosePaymentModule = this.paymentNavService.changePaymentCompSubject.subscribe(
			({ actionNeeded }) => {
				if (actionNeeded === PaymentTrayAction.forceClose) {
					this.storageService.empty();
					this.subscriptionToClosePaymentModule.unsubscribe();
				}
			}
		);
	}

	handleTaggingForError(errorType: PaymentErrorCategory, err: HttpErrorResponse): void {
		let anonyKOVar: { '&&events': string; 'error_list': string } = { '&&events': '', 'error_list': '' };
		let error_list: string = '';
		switch (errorType) {
			case PaymentErrorCategory.billDetailsFailure:
				anonyKOVar = { ...tagging.anonymousKOVar };
				error_list = anonyKOVar.error_list
					.replace('<error_code>', err.error.code)
					.replace('<error_message>', 'pago anonimo')
					.replace('<error_desc>', err.error.message);
				this.newTagging
					.getJsonTagging(FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION)
					.subscribe((data: NewTaggingJsonModel) => {
						this.taggingHelperService.error_list = error_list;
						const constantName: string = PAYMENTS_TRACKING.ANONYMOUS_IDENTIFICATION.STATES.SECURITY_CODE_KO;
						this.newTagging.state(data.page.stateList[constantName]);
					});
				break;
			case PaymentErrorCategory.billDetailsFailureNoOTP:
				anonyKOVar = { ...tagging.anonymousKOVar };
				error_list = anonyKOVar.error_list
					.replace('<error_code>', err.error.code)
					.replace('<error_message>', 'pago anonimo')
					.replace('<error_desc>', err.error.message);
				this.newTagging
					.getJsonTagging(FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION)
					.subscribe((data: NewTaggingJsonModel) => {
						this.taggingHelperService.error_list = error_list;
						const constantName: string = PAYMENTS_TRACKING.ANONYMOUS_IDENTIFICATION.STATES.DEBT_CONSULTATION_KO;
						this.newTagging.state(data.page.stateList[constantName]);
					});
				break;
			case PaymentErrorCategory.anonymousIdentifierFailure:
				anonyKOVar = { ...tagging.anonymousNoExisteDeudaVar };
				error_list = anonyKOVar.error_list
					.replace('<error_code>', err.error.code)
					.replace('<error_message>', 'pago anonimo')
					.replace('<error_desc>', err.error.message);
				this.newTagging
					.getJsonTagging(FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION)
					.subscribe((data: NewTaggingJsonModel) => {
						this.taggingHelperService.error_list = error_list;
						const constantName: string =
							PAYMENTS_TRACKING.ANONYMOUS_IDENTIFICATION.STATES.DEBT_CONSULTATION_NOT_EXIST_DEBT;
						this.newTagging.state(data.page.stateList[constantName]);
					});
				break;
			default:
				break;
		}
	}
	sendPaymentClarifyTagging(code?: string): void {
		const anonyKOVar: { '&&events': string; 'error_list': string } = { ...tagging.clarifyuser };
		const error_list: string = anonyKOVar.error_list.replace('<error_code>', code ? code : '');
		this.newTagging.getJsonTagging(FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION).subscribe((data: NewTaggingJsonModel) => {
			this.taggingHelperService.error_list = error_list;
			const constantName: string = PAYMENTS_TRACKING.ANONYMOUS_IDENTIFICATION.STATES.CLARIFY_USERS;
			this.newTagging.state(data.page.stateList[constantName]);
		});
	}
}
