export class CreditNote {
	public id: string;
	public docHref: string;
	public creationDate: string;
	public amount: any;
	public status: string;
	public resolutionDate: string;
	public resolutionDateNoFormat: string;
	public paymentMethod?: string;
	public paymentDate?: string;
	public fertilizerMethod?: string;
}
