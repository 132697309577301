import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OverlayModel } from '@mva10/mva10-angular';
import { MyOrdersService } from '../services/my-orders.service';
import { StatePedido } from '../../../enums/order-type.enum';
import { OrdersService } from '../services/orders.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { MyOrdersTaggingService } from '../../../services/my-orders-tagging.service';

@Component({
	selector: 'sp-activate-router',
	templateUrl: './sp-activate-router.component.html',
	styleUrls: ['./sp-activate-router.component.scss'],
})
export class SpActivateRouterComponent implements OnInit {
	showErrorView: boolean;
	pageName: string = 'activa tu nuevo router';
	stepsData: string[];
	selectedStep: number;
	showSpinner: boolean;
	StatePedido: typeof StatePedido = StatePedido;
	@Output() onClose: EventEmitter<string> = new EventEmitter();
	routerActivationGuideOverlayData: OverlayModel;
	showRouterActivationGuideOverlay: boolean;
	overlayLoaded: boolean;

	constructor(
		public ordersService: OrdersService,
		private myOrderService: MyOrdersService,
		private translate: TranslateService,
		public appService: AppService,
		private tagging: MyOrdersTaggingService
	) {}

	ngOnInit(): void {
		this.overlayLoaded = false;
		this.showRouterActivationGuideOverlay = true;
		this.selectedStep = 0;
		this.routerActivationGuideOverlayData = {
			isFullScreen: true,
			isCenterTitle: true,
			title: this.translate.instant('v10.myOrder.activateRouter.activateRouterGuideInstallation'),
		};
		this.stepsData = [
			this.translate.instant('v10.myOrder.activateRouter.activateRouterStep1Name'),
			this.translate.instant('v10.myOrder.activateRouter.activateRouterStep2Name'),
		];
		if (this.ordersService.detailOrderData.statePedido === StatePedido.OPEN) {
			this.stepsData.push(this.translate.instant('v10.myOrder.activateRouter.activateRouterStep3Name'));
		}
		this.doSendView();
		setTimeout(() => {
			this.overlayLoaded = true;
		}, 500);
	}

	resetTagging(): void {
		this.tagging.extendUtagPageName(
			[
				'detalle pedido',
				this.pageName,
				'desconecta el router',
				'conecta el router',
				'activa el router',
				'error',
				'success',
			],
			null,
			true
		);
		this.tagging.extendUtagPageName(['detalle pedido', this.pageName]);
	}

	doSendView(): void {
		this.resetTagging();
		this.tagging.sendPage(['desconecta el router']);
	}

	goBackwards(): void {
		this.selectedStep--;
		this.resetTagging();
		this.scrollToTop();
		if (this.selectedStep < 0) {
			this.closeGuideOverlay();
		} else {
			if (this.selectedStep === 1) {
				this.tagging.sendPage(['conecta el router']);
			}
			if (this.selectedStep === 0) {
				this.tagging.sendPage(['desconecta el router']);
			}
		}
	}

	scrollToTop(): void {
		document.querySelector('#activate_router').scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

	goForwards(): void {
		this.resetTagging();
		if (this.selectedStep === 0) {
			this.selectedStep = 1;
			this.tagging.sendPage(['conecta el router']);
		} else if (this.ordersService.detailOrderData.statePedido === StatePedido.OPEN) {
			this.selectedStep = 2;
			this.tagging.sendPage(['activa el router']);
		} else {
			this.closeGuideOverlay();
		}
		this.scrollToTop();
	}

	activateRouter(): void {
		this.tagging.sendInteraction({ link_type: 'activar equipo' });
		this.overlayLoaded = false;
		this.ordersService
			.activarRouter(this.myOrderService.MYORDERS_DATA.idCliente, this.ordersService.detailOrderData.numeroPedido)
			.subscribe({
				next: () => {
					this.selectedStep = 3;
					this.overlayLoaded = true;
					this.resetTagging();
					this.tagging.sendPage(['success']);
				},
				error: () => {
					this.selectedStep = 3;
					this.showErrorView = true;
					this.overlayLoaded = true;
					this.resetTagging();
					this.tagging.sendPage(['error']);
				},
			});
	}

	closeGuideOverlay(closeType?: string): void {
		this.showRouterActivationGuideOverlay = false;
		this.resetTagging();
		setTimeout(() => {
			this.selectedStep = 0;
			this.onClose.emit(closeType);
		}, 500);
	}
}
