export enum DocumentumType {
	scoring = 'vf_credit_scoring',
}

export enum DocumentumTypeMicro {
	scoring = 'vf_asesor_micro',
}

export enum DocumentumFilterName {
	agent = 'agente',
}

export enum DocumentumFilterValue {
	ecare = 'ecare',
	other = 'otro',
}

export enum DocumentumFilterValueType {
	string = 'string',
}

export enum DocumentumCharacteristicName {
	r_object_id = 'r_object_id',
	r_full_content_size = 'r_full_content_size',
	a_content_type = 'a_content_type',
	full_size_1 = 'full_size_1',
	dm_attr_0006 = 'dm_attr_0006',
	subtipo = 'subtipo',
	f_documento = 'f_documento',
	fiscalnum = 'fiscalnum',
	agente = 'agente',
	custacct = 'custacct',
	id_orden = 'id_orden',
	GetDocument = 'GetDocument',
	TransferMode = 'TransferMode',
	Format = 'Format',
}
