import { Component, Input } from '@angular/core';
import { SuperwifiService } from '../../../shared/services/superwifi.service';

@Component({
	selector: 'sp-shimmer-loader',
	templateUrl: './shimmer-loader.component.html',
	styleUrls: ['./shimmer-loader.component.scss'],
})
export class ShimmerLoaderComponent {
	@Input() show: boolean = false;
	constructor(public superWifiservice: SuperwifiService) {}
}
