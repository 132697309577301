<div class="ticket-content" *ngIf="loadedLines" [ngClass]="{ noTitle: !showTitle }">
	<div *ngFor="let line of lines">
		<div class="{{ line.style }}">
			{{ line.text }} <span *ngIf="line?.span"> {{ line.span }} </span>
		</div>
	</div>
	<div class="switch" *ngIf="showSwitch">
		<mat-slide-toggle class="switch" [checked]="switchState" (change)="changeSwitch($event)">
			<div class="switch_text">
				{{ switchText }}
			</div>
		</mat-slide-toggle>
	</div>
</div>

<div class="ticket_overlay" *ngIf="overlay">
	<div class="ticket_overlay_content slideUp" (scroll)="onScroll($event)">
		<div class="ticket_overlay_content-header" id="unScrollableContent" [ngClass]="{ scroll: isScrolled }">
			<div>
				<div class="overlay_header">
					<div *ngFor="let line of lines">
						<div class="{{ line.style }}">
							{{ line.text }} <span *ngIf="line?.span"> {{ line.span }} </span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="ticket_overlay_content-lines" id="scrollableContent">
			<ng-content> </ng-content>
		</div>
	</div>
</div>
