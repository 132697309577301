import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
@Component({
	selector: 'sp-wallet-card',
	templateUrl: './sp-wallet-card.component.html',
	styleUrls: ['./sp-wallet-card.component.scss'],
})
export class SpWalletCardComponent implements OnInit {
	@Input() isDotsShow: boolean;
	@Input() menuItems: Array<string>;
	@Input() isPrinciple: boolean;
	@Input() label: '';
	@Input() cardImg;
	@Input() cardNumber;
	@Output() onMenuActionClick = new EventEmitter();
	@Output() onCardClick = new EventEmitter();

	constructor() {}

	ngOnInit() {}

	onMenuActionClicked(item) {
		this.onMenuActionClick.emit(item);
	}

	onCardClicked() {
		this.onCardClick.emit();
	}
}
