<ng-template #ttButton>
	<span (click)="showAsOverlay ? (isTooltipOverlayOpen = true) : null">
		<ng-content select="[tooltipButton]"> </ng-content>
	</span>
</ng-template>

<ng-template #ttContent>
	<ng-content select="[tooltipContent]"> </ng-content>
</ng-template>

<!-- Tooltip version -->
<mva10-c-tooltip *ngIf="!showAsOverlay">
	<ng-container triggerTooltip *ngTemplateOutlet="ttButton"> </ng-container>
	<ng-container contentTooltip *ngTemplateOutlet="ttContent"> </ng-container>
</mva10-c-tooltip>

<!-- Modal version -->
<ng-container *ngIf="showAsOverlay">
	<ng-container *ngTemplateOutlet="ttButton"> </ng-container>

	<vfac-overlay [show]="isTooltipOverlayOpen" [loaded]="true" (closed)="isTooltipOverlayOpen = false">
		<div body>
			<ng-container *ngTemplateOutlet="ttContent"> </ng-container>
		</div>
	</vfac-overlay>
</ng-container>
