import { Tariff } from './../models/tariff.model';
import { Product } from './../models/product.model';
import { Injectable } from '@angular/core';
import { JumbotronAccordion } from '../models/jumbotron-accordion.model';
import { TermsAndConditionsApps } from '../models/terms-and-condition.model';
import { ExtraList } from '../models/extra-list.model';
import { Error } from '../models/error.model';
import { HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHadlingUsingNotificationService } from '../core/services/error-hadling-using-notification.service';
import { BundleType } from '../shared/enums/bundleType.enum';
import { EnjoyMoreStatus } from '../shared/enums/enjoy-more-status.enum';
import { BenefitStatus } from '../shared/enums/benefit-status.enum';
import { BundleStatus } from '../shared/enums/bundleStatus.enum';
import { ProductCategory } from '../shared/enums/productCategory.enum';
import { deliveryParams, renewalMargin, superOffers } from '../shared/constants/defines';
import { statusSbaPending } from '../shared/constants/defines';
import { AlertCase } from '../shared/enums/alert-case.enum';
import { AlertMessage } from '../shared/enums/alert-message.enum';
import { SimDataModel } from '../models/sim-data.model';
import { ProductType } from '../shared/enums/productType.enum';
import { ProductService } from '../shared/services/product.service';
import { tagging } from '../../config/tagging-config';
import { map } from 'rxjs/operators';
import { SubscriptionService } from '../core/services/subscription.service';
import { ServiceType } from '../shared/enums/serviceType.enum';
import { TVDiscounts } from '../models/tv-discounts.model';
import { PostalAddress } from '../models/postal-address.model';
import { Subject, Observable } from 'rxjs';
import { CustomerAccountService } from '../shared/services/customer-account.service';
import { StorageService } from '../core/services/storage.service';
import { ServiceModel } from '../models/service.model';
import { CustomerType } from '../shared/enums/customerType.enum';
import { SiteStatus } from '../shared/enums/siteStatus.enum';
import { UtilitiesService } from '../shared/utils/utilities.service';
import { DeliveryOffice } from '../models/delivery-office.model';
import { OverlayModel, CardImageSelectorModel } from '@mva10/mva10-angular';
import { TaggingViewModel } from '../models/tagging.model';
import { Address } from '../models/Address.model';
import { CrossFunctionalityService } from '../shared/services/cross-functionality.service';
import { DeliveryStore } from '../models/delivery-store.model';
import { ProfileType } from '../shared/enums/profileType.enum';
import { CacheEntrypointsService } from '../shared/services/cache-entrypoints.service';
import { AppService } from '../app.service';
import { ProductManagementEnum } from '../shared/enums/product-management.enum';
@Injectable()
export class ProductsAndServicesService {
	public initialAmount: number;
	public title: string;
	public code: string;
	public address: string;
	public txHomeAddress: PostalAddress;
	public txEurPrecioTaxes: number = 0;
	public cost: string;
	public onlineTvCost: string;
	public titleHtml: string;
	public isLanding = true;
	public isLandingFlag = false;
	public isDetails = false;
	public isMiWifi: boolean = false;
	public isOneProfesional: boolean = false;
	public image: string;
	public isEnjoyMore = false;
	public extraStyleTitle = false;
	public spinner = false;
	public showError = false;
	public showDescription = false;
	public showInactiveError = false;
	public allProducts = true;
	public isExtraDetailsStyle = false;
	public footerText;
	public extraProduct: Product;
	public extraProductCode: string;
	public isEnjoyMoreActiveStyle = false;
	public product: JumbotronAccordion;
	public extraListCategory: ExtraList;
	public termsAndConditions: TermsAndConditionsApps[];
	public isExtraListFromDB = false;
	public extraType: string;
	public bold = false;
	public tvProducts: Product[];
	public selectedTvExtra: Product;
	public onlineTvExtra = false;
	public hasOnlineTvService: boolean;
	public isNonTvService: boolean;
	public isEntertainmentDetails: boolean;
	public showEditDataSharing: boolean;
	public onlineTvDetail = false;
	public customError: Error;
	public errorPopup = false;
	public changeWorryFreeStatus = false;
	public promtionMiniXs: Product;
	public isStatic = false;
	public isStaticFooter: string;
	public isStaticTitle: string;
	public isStaticSubTitle: string;
	public costBefore: string;
	public isStaticIcon: string;
	public unitCodeMB: string;
	public unitCodeGB: string;
	public channelPage = false;
	public changeEnjoyMoreStatus = false;
	public onlineTVWCS: string;
	public onlineTVWCSDesc: string;
	public additionalInfoTitle: string;
	public isClient: boolean;
	public priceType: string;
	public additionalInfoDesc: string;
	public additionalInfo: string;
	public serviceCreditBalance: number;
	public enjoyMoreProducts: Product[];
	public isYu: boolean;
	public isPrePaid: boolean;
	public isAccumulate: boolean;
	public pnsSpinner: boolean = false;
	public showTouristLastDayFlag: boolean;
	public isSuperWifi: boolean;
	public hasNBAs: boolean;
	public availableNBAs: boolean;
	public isON19: boolean;
	public statusSBA: string;
	public pendingSBA: boolean;
	public pendingSBAMsg: string;
	public productTagging: string;
	public shippingMode: string;
	public streetDisplay: string;
	public townDisplay: string;
	public TxTv: boolean;
	public isOpProducts: boolean = false;
	public isOpActive: boolean = true;
	oneProfesionalCard: CardImageSelectorModel;
	showDetailsTabNavigator;
	extraData: boolean;
	mins;
	isPrePaidYu: boolean;
	simProfileData: SimDataModel;
	private secureNetProduct: Product;
	goToStaticPage: boolean = false;
	public selectedBookableChannelList: Product[];
	redirectionTabComsuption: boolean = false;
	selectedTab: number;
	showComsuptionFlag: boolean;
	/**this flag to hide header and tabs section of product and service component */
	hideServicesHeaderAndTabs: boolean = false;
	showFullLoader: boolean = false;
	// for benefit renewal
	totalAmountNeedToRecharge: number;
	extrasAmountNeedToRecharge: number;
	currentBalance: string;
	prepaidExtrasList: Array<Product>;
	ExtrasListIsEmpty: boolean = false;
	totalFeeUnit: string;
	/** Remove padding from parent */
	public noPadding: boolean;
	/** Insurance to load on CommercialCareLanding */
	public selectedCare: Product;
	showTabConsumption: boolean;
	/** boolen to check if service is prepaid or not */
	isPrePaidService: boolean = false;
	isSmartPay: boolean;
	/** Service level discounts on tv checkout */
	public serviceLevelDiscounts: TVDiscounts[];
	/** Hide product fee in header */
	public hideHeaderFee: boolean;

	tabSubject: Subject<number> = new Subject<number>();
	public tab$: Observable<number> = this.tabSubject.asObservable();
	public selectedOffice: DeliveryOffice;
	public selectedStore: DeliveryStore;
	public showMode: string;
	public deliveryFree: string;
	public deliveryPrice: number;
	public isDeliveryFree: boolean;
	public officeDireccion: string;
	public officeProvince: string;
	public officeLocation: string;
	public officeCP: string;
	public isOffice: boolean;
	public officePrice: string;
	public leftMenuHideService: boolean;
	public tariffMainContainerPaddingTop: string;
	public showNewEntertainmentScreenTitle: boolean;

	constructor(
		private errorHadlingUsingNotificationService: ErrorHadlingUsingNotificationService,
		private translate: TranslateService,
		private productService: ProductService,
		public subscriptionData: SubscriptionService,
		public customerAccountService: CustomerAccountService,
		public storageService: StorageService,
		private utility: UtilitiesService,
		public cross: CrossFunctionalityService,
		public cacheEntrypointsService: CacheEntrypointsService,
		public appTheme: AppService
	) {}

	round(amount: number) {
		if (amount.toString().indexOf('.') > -1) {
			return amount.toString().substring(0, amount.toString().indexOf('.') + 3);
		} else {
			return amount;
		}
	}

	convertConsumption(extraProduct: Product, showWithoutSplit?) {
		const totalConsumption = extraProduct.TotalConsumption || 0;
		const unitConsumption = extraProduct.TotalConsumptionUnit;
		const extraType = extraProduct.Type;
		this.translate.get('dashboard.contentList.guage').subscribe((data) => {
			this.unitCodeGB = data.gigabytes;
			this.unitCodeMB = data.megabytes;
		});
		const unit: string = showWithoutSplit ? unitConsumption : unitConsumption?.split('/')[0];
		if (extraType?.toLowerCase() === BundleType.Voice.toLowerCase()) {
			if (extraProduct.TotalConsumption) {
				if (
					extraProduct.currentStatus &&
					(extraProduct.currentStatus.toLowerCase() === BundleStatus.Inactive.toLowerCase() ||
						extraProduct.currentStatus.toLowerCase() === BundleStatus.PendingActivation.toLowerCase())
				) {
					return totalConsumption + ' ' + unitConsumption.toLowerCase();
				}
				this.translate.get('dashboard.contentList.guage.Mins').subscribe((data) => {
					this.mins = data;
				});

				return Math.trunc(totalConsumption / 60) + ' ' + this.mins;
			}
		} else {
			if (
				totalConsumption &&
				totalConsumption >= 1024 &&
				unit &&
				unit.toLowerCase() === this.unitCodeMB.toLowerCase()
			) {
				const calcConsumption = this.round(totalConsumption / 1024);
				return calcConsumption + ' ' + this.unitCodeGB;
			} else {
				return totalConsumption + ' ' + unit;
			}
		}
	}

	public checkTabSubscriptions(customer: string): { [type: string]: string[] } {
		let customerwcs: { [type: string]: string[] } = this.translate.instant('v10.productsServices.tabs.config.customer');

		if (customer === ProfileType.AUTHORIZED) {
			customerwcs = this.translate.instant('v10.productsServices.tabs.config.authorized');
		} else if (customer === ProfileType.EMPLOYEE) {
			customerwcs = this.translate.instant('v10.productsServices.tabs.config.employee');
		} else if (customer === ProfileType.SME) {
			customerwcs = this.translate.instant('v10.productsServices.tabs.config.employee_sme');
		}
		return customerwcs;
	}

	errorHandling(error?: HttpResponse<any>, requestMethod?: string, isPageLoaded?: boolean) {
		this.spinner = false;
		this.isLanding = true;
		this.showError = true;
		if (error?.status === 0 && !navigator.onLine) {
			this.errorPopup = false;
			const notificationModal = this.errorHadlingUsingNotificationService.noConnectionHandlingNotification(
				error,
				requestMethod,
				isPageLoaded
			);
			notificationModal.show();
		} else {
			this.errorPopup = true;
		}
	}

	checkFlagComsuption(): void {
		this.translate.get('v10.productsServices.tabs_tariff.flagActiveButton').subscribe((data) => {
			this.showComsuptionFlag = data ? (data.toLowerCase() === 'true' ? true : false) : false;
		});
	}

	remainFormate(bundle: Product) {
		if (bundle.Type.toLowerCase() === BundleType.Data.toLowerCase()) {
			if (bundle.RemainConsumptionData) {
				return bundle.RemainConsumptionData.toString().replace('.', ',');
			}
		} else if (bundle.Type.toLowerCase() === BundleType.Voice.toLowerCase()) {
			return bundle.RemainConsumptionExtraVoice;
		}
	}
	unitFormate(bundle: Product) {
		if (bundle.Type.toLowerCase() === BundleType.Data.toLowerCase()) {
			return bundle.RemainConsumptionUnit;
		} else if (bundle.Type.toLowerCase() === BundleType.Voice.toLowerCase()) {
			this.translate.get('dashboard.contentList.guage.Mins').subscribe((data) => {
				this.mins = data;
			});

			return this.mins;
		}
	}
	isVoiceInternationalExtra(bundle: Product) {
		return (
			bundle.Type &&
			bundle.Type.toLowerCase() === BundleType.Voice.toLowerCase() &&
			bundle.CallType &&
			bundle.CallType.toLowerCase() === BundleType.International.toLowerCase()
		);
	}
	isVoiceNationalExtra(bundle: Product) {
		return (
			bundle.Type &&
			bundle.Type.toLowerCase() === BundleType.Voice.toLowerCase() &&
			bundle.CallType &&
			bundle.CallType.toLowerCase() === BundleType.national.toLowerCase()
		);
	}
	hasEnoughBalance(product, tariff) {
		let productStatus;

		if (Array.isArray(product)) {
			// we list all enjoymore STATUS as per needed to search for at least one condition of them
			productStatus = product.map((Product) => {
				return this.getEnjoyMoreType(Product).toLocaleLowerCase();
			});
			// this function will sum all servicebalance.amount.amount
			// then will find if the amount is enough to buy products (just - Active not enjoyed)
			// this periurity as per BA Rehab
			if (productStatus.includes(EnjoyMoreStatus.activeNotEnjoyed)) {
				const ActiveNotEnjoyedTotalFee =
					this.enjoyMoreProducts
						.filter((enjoyProduct) => {
							return (
								this.getEnjoyMoreType(enjoyProduct).toLocaleLowerCase() ===
								EnjoyMoreStatus.activeNotEnjoyed.toLocaleLowerCase()
							);
						})
						// loop over all active not enjoyed array and return its fees
						.map((activeNotEnjoyed) => activeNotEnjoyed.fee)
						// accumulate the fee values
						.reduce((previousItem, currentItem) => previousItem + currentItem) || 0;
				// true if we have enough balance
				return this.serviceCreditBalance > +ActiveNotEnjoyedTotalFee;
			} else if (productStatus.includes(EnjoyMoreStatus.inactive)) {
				// in that case we check it with the tariff fees
				return this.serviceCreditBalance > parseFloat((+tariff.Fee).toString().replace('.', ','));
			}
		} else {
			// if one product return its compered result with creditBalance and JUST the product fee
			return this.serviceCreditBalance > product.fee;
		}
	}

	isMoreThanRenewalMargin(tariff: Tariff) {
		// renewal date from tariff as per BA need to end the enjoy more with each start cycle from the tariff renewal date
		const numberOfDaysAfterRenewal: number = this.getNumberOfDaysAfterRenewal(tariff.benefitRenewalDate) || 0;
		// renewal margin is the limit after the renewal date passed after this margin the user has to buy the product again
		return numberOfDaysAfterRenewal > renewalMargin;
		// NOTE: if the date if in the future and bigger than current date (NOW) it will return UNDEFINED
		// So in case of less than Renewal Margin or returned undefined it will return false
	}
	isLessThanOrEqualRenewalMargin(tariff: Tariff) {
		const numberOfDaysAfterRenewal: number = this.getNumberOfDaysAfterRenewal(tariff.benefitRenewalDate) || 0;
		return numberOfDaysAfterRenewal <= renewalMargin;
	}

	getNumberOfDaysAfterRenewal(date: string) {
		// this function helps us to calculate the remenaning days after renwal date ( start cycle )
		const dateInDateType: Date = new Date(date);

		const currentDate = new Date();
		return Math.round((+currentDate - +dateInDateType) / (1000 * 60 * 60 * 24));
	}

	getEnjoyMoreType(product: Product) {
		// this function as per design document section 3.12.1.3.1.1 Enjoy More
		// this function is to classify the enjoy-more product to its coresponding status or type
		if (product.ProductCategory && product.ProductCategory.toLowerCase() === ProductCategory.Basic.toLowerCase()) {
			// as per design document if product category is basic then we conseder this enjoy more is inclusive
			return EnjoyMoreStatus.inclusive;
		} else {
			if (product.currentStatus && product.currentStatus.toLowerCase() === BundleStatus.Inactive.toLowerCase()) {
				return EnjoyMoreStatus.inactive;
			} else {
				// the rest options is active
				// so we check if it is enjoyed or not enjoyed
				if (product.benefitsFlag) {
					return EnjoyMoreStatus.activeEnjoyed;
				} else {
					return EnjoyMoreStatus.activeNotEnjoyed;
				}
			}
		}
	}

	getEnjoyMoreAlertCase(product: Product | Product[], tariff: Tariff) {
		// one or more
		// ARGS::
		// -    product is Array or only one product - one or more
		const TariffBenefitStatus = tariff.benefitStatus || BenefitStatus.unknown;
		let productStatus;

		if (Array.isArray(product)) {
			// we list all enjoymore STATUS as per needed to search for at least one condition of them
			productStatus = product.map((Product) => {
				return this.getEnjoyMoreType(Product).toLocaleLowerCase();
			});
		} else {
			// if one product get its status
			productStatus = this.getEnjoyMoreType(product).toLocaleLowerCase();
		}

		// next code as per story MVES-1033 table
		// this function will return number coressponding to case number
		if (TariffBenefitStatus.toLocaleLowerCase() === BenefitStatus.active.toLocaleLowerCase()) {
			// Enjoy More Status
			// Note: we consider that we have to search in the enjoy-more status array to find AT LEAST on of its status
			// AT LEAST of Status also we assume that we will never find any conflect
			if (productStatus.includes(EnjoyMoreStatus.inclusive.toLowerCase()) && productStatus.length === 1) {
				// active - inclusive
				return AlertCase.one;
			} // end of enjoy more status ==> Inclusive
			else if (productStatus.includes(EnjoyMoreStatus.activeNotEnjoyed.toLowerCase())) {
				if (this.isMoreThanRenewalMargin(tariff)) {
					if (this.hasEnoughBalance(product, tariff)) {
						// active - active not enjoyed - >16 - has enough balance
						return AlertCase.nine;
					} else {
						// active - active not enjoyed - >16 - not has enough balance
						return AlertCase.eight;
					}
				} else {
					if (this.hasEnoughBalance(product, tariff)) {
						// active - activ me not enjoyed - =<16
						return AlertCase.one;
					} else {
						// active - activ me not enjoyed - =<16
						return AlertCase.seven;
					}
				}
			} // end of enjoy more status ==> Active Not Enjoyed
			else if (productStatus.includes(EnjoyMoreStatus.activeEnjoyed.toLowerCase())) {
				// active - Active Enjoyed
				return AlertCase.six;
			} // end of enjoy more status ==> Active Enjoyed
			else if (productStatus.includes(EnjoyMoreStatus.inactive.toLowerCase())) {
				if (this.hasEnoughBalance(product, tariff)) {
					// active - not active - has enough balance
					return AlertCase.four;
				} else {
					if (this.isMoreThanRenewalMargin(tariff)) {
						// active - not active - > 16
						return AlertCase.five;
					} else {
						// active - not active - =< 16
						return AlertCase.fivePointOne;
					}
				}
			} // end of enjoy more status ==> not active
		} // we added the next condition and not used else because TariffBenefitStatus has anther status not lised here
		else if (TariffBenefitStatus.toLocaleLowerCase() === BenefitStatus.inactive.toLocaleLowerCase()) {
			// Benefit status - inactive
			// Enjoy More Status
			if (productStatus.includes(EnjoyMoreStatus.inactive.toLowerCase())) {
				// inactive - not active
				return AlertCase.ten;
			} else if (productStatus.includes(EnjoyMoreStatus.activeNotEnjoyed.toLowerCase())) {
				// inactive - active not enjoyed
				return AlertCase.twelve;
			}
		}
	}

	/** function return an alert message from WCS, it takes one parameter
	 * @param messageKey : from M1 to M9
	 */
	getAlertMessage = (messageKey: AlertMessage) => {
		let result = '';
		this.translate
			.get(`productsServices.contentList.alerts.messagesList.${messageKey}.${messageKey}_description`)
			.subscribe((data) => {
				result = data;
			});
		return result;
	};

	isCreationDateLessThan(orderCreationDate: string, numberOfHours: number) {
		const todayDate = new Date();
		const todayDateMilliSconds = todayDate.getTime();
		const creationDate = new Date(orderCreationDate);
		const creationDateMilliSconds = creationDate.getTime();
		const diffBtnTwoDatesMilliSconds = todayDateMilliSconds - creationDateMilliSconds;
		return diffBtnTwoDatesMilliSconds <= 3600000 * numberOfHours;
	}

	get SecureNetProduct(): Product {
		this.secureNetProduct = this.productService.products.find((el: Product) => {
			return el.ProductType === ProductType.secureNet;
		});
		return this.secureNetProduct;
	}
	set SecureNetProduct(secureNetProduct: Product) {
		this.secureNetProduct = secureNetProduct;
	}

	public setSelectedBookableChannelList(list: Product[]): void {
		this.selectedBookableChannelList = list;
	}

	/** @param applyLogic filter service level discounts */
	public getSelectedBookableChannelList(applyLogic?: boolean): Product[] {
		if (this.selectedBookableChannelList) {
			return applyLogic ? this.tvCheckoutChannelLogic() : this.selectedBookableChannelList;
		} else {
			return [];
		}
	}

	handleSbaStatus() {
		if (this.statusSBA === statusSbaPending) {
			this.pendingSBA = true;
			this.translate
				.get('productsServices.messagesList.psStatusSBAMsg.psStatusSBAMsg_description')
				.subscribe((data) => {
					this.pendingSBAMsg = data;
				});
		} else {
			this.pendingSBA = false;
		}
	}

	/**
	 * Returns <product category>; <product name>; <product quantity>; <product price>; eVar187=<product subcategory>; <promotion code>
	 */
	public getProductsTag(): string {
		const category = tagging.newTVPacks.varProducts.category;
		const quantity = 1;
		const subcategory = tagging.newTVPacks.varProducts.subcategory;
		let productName: string = '',
			productFee: number = 0,
			productPrice: string = '',
			promocode: string = '';
		if (this.selectedTvExtra) {
			productName = this.selectedTvExtra.Name;
			productPrice = this.selectedTvExtra.discountedFee?.toString().replace('.', ',') + this.selectedTvExtra.feeUnit;
			promocode = this.selectedTvExtra.Code.split('_')[0];
		} else if (this.selectedBookableChannelList && this.selectedBookableChannelList.length) {
			const names: string[] = [],
				codes: string[] = [];
			for (const { Name: name, Code: code, discountedFee: fee, feeUnit: unit } of this.selectedBookableChannelList) {
				names.push(name);
				codes.push(code.split('_')[0]);
				productFee += fee;
				productPrice = productFee + unit;
			}
			productName = names.join(',');
			promocode = codes.join(',');
		}

		return `${category}; ${productName}; ${quantity}; ${productPrice}; eVar187=${subcategory}; ${promocode}`;
	}

	/**
	 * @param section Id of the section you want to scroll to
	 * @param timeout Number of miliseconds you need the timeout
	 * @param block ScrollLogicalPosition -> 'start', 'center', 'end', 'nearest'
	 */
	public scrollToSection(section: string, timeout: number, block: ScrollLogicalPosition): void {
		const elemToScroll: Element = document.querySelector('#' + section);
		if (elemToScroll) {
			setTimeout(() => {
				elemToScroll.scrollIntoView({ behavior: 'smooth', block: block });
			}, timeout);
		}
	}

	public setHasON19(selectedService: ServiceModel): void {
		const yuUser: boolean = this.subscriptionData.yuUser;
		this.translate.get('v10.commercial').subscribe((data) => {
			if (
				this.storageService.userProfile.customerType.toLowerCase() === CustomerType.Consumer.toLowerCase() &&
				this.utility.getSpanishCurrentSiteStatus() === SiteStatus.Activo.toLowerCase() &&
				selectedService.type.toLowerCase() === ServiceType.Postpaid.toLowerCase() &&
				this.subscriptionData.serviceListSite.filter(
					(srv) => srv.type.toLowerCase() === ServiceType.Postpaid.toLowerCase()
				).length +
					this.subscriptionData.serviceListSite.filter(
						(srv) => srv.type.toLowerCase() === ServiceType.Prepaid.toLowerCase()
					).length <=
					parseInt(data.maxLineasPartis, 10) &&
				!this.utility.hasOverdue() &&
				superOffers.includes(this.subscriptionData.superOffer) &&
				data.switches.web.disable_additionalLines.toLowerCase() === 'false' &&
				this.utility.releaseHigherThan(data.additionalLines.version.min)
			) {
				this.isON19 = !yuUser;
			} else {
				this.isON19 = false;
			}
		});
	}

	public checkUserProfile(): boolean {
		if (
			(this.customerAccountService.customerAccounts && this.customerAccountService.customerAccounts.length > 1) ||
			(this.storageService.userProfile &&
				((this.storageService.userProfile.companies && this.storageService.userProfile.companies.length > 1) ||
					(this.storageService.userProfile.sites &&
						this.storageService.userProfile.sites.length > 1 &&
						this.customerAccountService.isP1Sites(this.storageService.userProfile))))
		) {
			return true;
		} else {
			return false;
		}
	}

	public checkTabConsumption(): void {
		this.showTabConsumption =
			this.subscriptionData.customerData.currentService.type.toLocaleLowerCase() !==
				ServiceType.Fibre.toLocaleLowerCase() &&
			this.subscriptionData.customerData.currentService.type.toLocaleLowerCase() !==
				ServiceType.ADSL.toLocaleLowerCase();
		this.isPrePaidService =
			this.subscriptionData.customerData.currentService.type.toLocaleLowerCase() ===
			ServiceType.Prepaid.toLocaleLowerCase();
		this.isSmartPay = this.subscriptionData.customerData.currentService.smartPay;
	}

	addOneProfesionalCard(): CardImageSelectorModel {
		this.translate.get('v10').subscribe((text) => {
			this.oneProfesionalCard = {
				id: text.productsServices.businessProducts.id,
				title: text.productsServices.businessProducts.titleText,
				image: this.appTheme.getImgFullPath(
					this.translate.instant('v10.dashboard.images.product_selector.businessProducts')
				),
				description: text.productsServices.businessProducts.subtitleText,
				checked: false,
			};
		});
		return this.oneProfesionalCard;
	}

	/** Deletes service level discounts from selectedBookableChannelList and push them on serviceLevelDiscounts */
	private tvCheckoutChannelLogic(): Product[] {
		const serviceLevelDiscounts: string[] = this.translate
			.instant('v10.productsServices.tv.purchase.serviceLevelDiscounts')
			.split(',');
		this.serviceLevelDiscounts = [];
		const provisionalList: Product[] = this.selectedBookableChannelList.map((ch) => {
			if (serviceLevelDiscounts.includes(ch.tvDiscounts?.code)) {
				if (!this.serviceLevelDiscounts.some((disc) => disc.code === ch.tvDiscounts?.code)) {
					const disc: TVDiscounts = Object.assign({}, ch.tvDiscounts);
					disc.discountAmount = (-Number(disc.discountAmount.replace(',', '.'))).toString();
					this.serviceLevelDiscounts.push(disc);
				}
				const newCh: Product = Object.assign({}, ch);
				newCh.tvDiscounts = undefined;
				newCh.discountedFee = parseFloat(ch.fee.replace(',', '.'));
				return newCh;
			} else {
				return ch;
			}
		});
		return provisionalList;
	}

	public findVodafoneCareProduct(msisdn: string): Observable<Product> {
		const insuranceCodes: string[] = this.translate.instant('v10.commercial.care.insuranceCodes');
		return this.productService.GetAllProducts(msisdn).pipe(
			map(() => {
				const care: Product = this.productService.products.find((product) => {
					return insuranceCodes.some((code) => product.Code.includes(code));
				});
				if (
					care.Name.toLowerCase() === this.translate.instant('v10.productsServices.care.accidentalId').toLowerCase()
				) {
					care.wcsTitle = this.translate.instant('v10.productsServices.care.accidentalTitle');
				} else if (
					care.Name.toLowerCase() === this.translate.instant('v10.productsServices.care.totalId').toLowerCase()
				) {
					care.wcsTitle = this.translate.instant('v10.productsServices.care.totalTitle');
				}
				return care;
			})
		);
	}

	changeDataOverlayBonos(value: boolean): OverlayModel {
		const overlayBonosData: OverlayModel = new OverlayModel();
		if (value) {
			overlayBonosData.title = this.translate.instant('v10.productsServices.tabs_consumption.overlay.details');
			overlayBonosData.isCenterTitle = true;
			overlayBonosData.isScrollableTray = true;
		} else {
			overlayBonosData.title = ' ';
			overlayBonosData.isCenterTitle = true;
		}
		return overlayBonosData;
	}

	public setAutoInstallment(): void {
		if (this.showMode === deliveryParams.officeCd) {
			this.officeDireccion = this.selectedOffice?.direccion;
			this.officeProvince = this.selectedOffice?.provincia;
			this.officeLocation = this.selectedOffice?.localidad;
			this.officeCP = this.selectedOffice?.codigoPostal;
			this.isOffice = true;
		} else if (this.showMode === deliveryParams.homeCd) {
			this.isOffice = false;
			this.selectedOffice = null;
		}
	}

	public getTaggingJourneyTvCheckoutDetail(
		journey_step?: string,
		isChangeAction?: boolean,
		contractType?: string,
		selectedChannels?: Product[],
		tariff?: number
	): TaggingViewModel {
		const journey: TaggingViewModel = Object.assign({}, tagging.newTVPacks.journey);
		journey['&&products'] = this.productTagging;
		if (isChangeAction) {
			journey.journey_category = tagging.newTVPacks.journey_cases.change_deco;
			journey.journey_options = tagging.newTVPacks.tvmode.migra;
			journey.journey_type = tagging.newTVPacks.journey_types.buy;
		} else if (contractType) {
			journey.journey_category = tagging.newTVPacks.journey_cases.contract_tv;
			selectedChannels?.forEach((ch) => (journey.journey_options += `${ch.Name},`));
			journey.journey_options = journey.journey_options.slice(0, journey.journey_options.length - 1);
			journey.journey_type = tagging.newTVPacks.journey_types.contract;
			journey['&&events'] = null;
		} else {
			journey.journey_category = tagging.newTVPacks.journey_cases.contract_pack;
			journey.journey_subcategory = tariff ? tagging.newTVPacks.tvmode.deco : tagging.newTVPacks.tvmode.nodeco;
			selectedChannels?.forEach((ch) => (journey.journey_options += `${ch.Name},`));
			journey.journey_options = journey.journey_options.slice(0, journey.journey_options.length - 1);
			journey.journey_type = tagging.newTVPacks.journey_types.contract;
		}
		journey.journey_name = journey_step ? `${journey.journey_category}${journey_step}` : '';
		return journey;
	}
	public getAddressTvCheckout(address: Address): void {
		this.streetDisplay = address.streetType ? address.streetType : '';
		this.streetDisplay = this.streetDisplay + (address.street ? ' ' + address.street : '');
		this.streetDisplay = this.streetDisplay + (address.buildingNo ? ', ' + address.buildingNo : '');
		this.streetDisplay = this.streetDisplay + (address.level ? ', ' + address.level : '');
		this.streetDisplay = this.streetDisplay + (address.door ? '-' + address.door : '');
		this.townDisplay = address.town ? address.town : '';
		this.townDisplay = this.townDisplay + (address.postcode ? ', ' + address.postcode : '');
		this.townDisplay = this.townDisplay + (address.country ? ', ' + address.country : '');
	}
	handleUIForGuards(): void {
		this.noPadding = false;
		this.hideServicesHeaderAndTabs = false;
	}
	getNudgeData(): void {
		this.translate.get('common.messagesList.contentForbidden').subscribe((data) => {
			this.cross.nudgeTitle = data.title;
			this.cross.nudgeDescription = data.description;
			this.cross.showOneButton = true;
			this.cross.showRetryButton = false;
			this.cross.buttonText = data.confirmButton.text;
			this.cross.showNudge = true;
		});
	}
	isCurrentServicePrepaid(): boolean {
		return (
			this.subscriptionData?.customerData?.currentService?.type?.toLowerCase() === ServiceType.Prepaid.toLowerCase()
		);
	}
	public setBusiness(id?: string): void {
		if (id === ProductManagementEnum.business) {
			this.isOpProducts = true;
			this.spinner = false;
		}
	}
}
