export class Error {
	httpStatus?: string;
	errorCode?: string;
	ecode?: string;
	title?: string;
	description?: string;
	confirmButtonText?: string;
	dismissButtonText?: string;
	retryButton?: boolean;
	hideX?: boolean;
	descriptionRichText?: string;
	status?: string;
	vodafoneMsisdn?: string;
	error?: {
		ecode?: string;
		error?: string;
		message?: string;
		transactionId?: string;
		additionalInfo?: {
			token?: string;
		};
	};
	url?: string;
	message?: string;
}

export class FuntionalError {
	ecode?: string;
	message?: string;
	errorType?: string;
	systemOrigin?: string;
}
