<div class="selector-container row">
	<div class="selector" [ngClass]="{ 'selector-scroll': selections?.length >= 4 }">
		<div class="selector-item" *ngFor="let selection of selections; let i = index">
			<div *ngIf="selection.hasPendingPciTransactions" class="pending-service">
				{{ 'common.itemsList.pendingTopup.body' | translate }}
			</div>
			<div
				class="content-container"
				[ngClass]="{
					'active-selection': i === selectedIndex && !selection.hasPendingPciTransactions,
					'pending-content': selection.hasPendingPciTransactions,
					'dashed-border': selection?.showPlus
				}"
				(click)="onSelectionClick(i)"
			>
				<div class="selector-content">
					<div class="add" *ngIf="selection?.showPlus"><span class="icon icon-add"> </span></div>
					<p class="selector-msisdn" *ngIf="selection?.id">{{ selection?.id }}</p>
					<span class="icon icon-mobile" *ngIf="selection?.icon"> </span>
					<p class="service-name" [ngClass]="{ 'add-text': selection?.showPlus }">{{ selection?.name }}</p>
				</div>
			</div>
		</div>
		<div class="selector-item" *ngIf="showPlus">
			<div
				class="content-container dashed-border"
				[ngClass]="{ 'active-selection': plusSelected }"
				(click)="clickPlus()"
			>
				<div class="selector-content">
					<div class="add"><span class="icon icon-add"> </span></div>
					<span class="icon icon-mobile"> </span>
					<p class="service-name add-text">{{ 'common.itemsList.addMobile.body' | translate }}</p>
				</div>
			</div>
		</div>
	</div>
</div>
