import { billReviewTicketCreation } from '../shared/constants/defines';

export class BillReviewTicketRequestBody {
	'description': string = billReviewTicketCreation.Description;
	'ticketType': string = billReviewTicketCreation.TicketType;
	'requestedResolutionDate': Date = new Date();
	'channel': { id: string } = { id: billReviewTicketCreation.ChannelID };
	'note': { id: string; text: string }[] = [{ id: billReviewTicketCreation.NoteID, text: '' }];
	'relatedParty': Array<{ id: string }>;
	'@type': string = billReviewTicketCreation.Type;
}
