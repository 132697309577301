<!-- SPINNER -->
<div *ngIf="showSpinner" class="mvf-loader__spinner" [ngClass]="{ 'full-height': fullHeight }">
	<div class="mvf-loader__spinner-content">
		<h2>{{ 'v10.myOrder.loader.pleasewait' | translate }}</h2>
	</div>
	<span class="mvf-loader__spinner-animacion"> </span>
</div>

<!-- ERROR GLOBAL -->
<div *ngIf="showError">
	<div class="mvf-loader__error" [ngClass]="{ 'full-height': fullHeight }">
		<svg class="vfes-alert-icon icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
			<title>{{ 'v10.myOrder.loader.error' | translate }}</title>
			<use class="icon-v3" xlink:href="#icon-warning-mid"></use>
		</svg>
		<div class="mvf-loader__error-content">
			<h2>{{ content.errorTitle }}</h2>
			<p>{{ content.errorContent }}</p>
		</div>
	</div>
</div>
