import { Component, EventEmitter, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { OverlayModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { NebaGuideType } from '../../../enums/guide-types.enum';
import { constants } from '../../../models/constants';
import { SlidConfig } from '../../../models/slidResponse.model';
import { OrdersService } from '../services/orders.service';
import { SlidService } from '../services/slid.service';
import { EventMessage } from '../../../models/eventMessage.model';
import { UtilsService } from '../services/utils.service';
import { InteractionType } from '../../../enums/interaction-type.enum';
import { MyOrdersService } from '../services/my-orders.service';
import { MyOrdersTaggingService } from '../../../services/my-orders-tagging.service';
import { StepFlow, MotiveSupport } from '../../../enums/step-flow.enum';

@Component({
	selector: 'sp-neba-installation',
	templateUrl: './sp-neba-installation.component.html',
	styleUrls: ['./sp-neba-installation.component.scss'],
})
export class SpNebaInstallationComponent implements OnInit {
	nebaInternet5Step: string;
	progressBarTitles: string[] = [];
	progressBarCurrentStep: number;
	overlayPage: number;
	pageName: string = 'guia instalacion desconocida';
	pagesNames: string[] = [];
	koNames: string[] = [];
	overlayLoaded: boolean;
	showNebaInstallationGuideOverlay: boolean;
	nebaInstallationGuideOverlayData: OverlayModel;
	slidResponse: string;
	isSettingSlid: boolean;
	nebaInstallationGuideExitData: OverlayModel;
	showNebaExitGuideOverlay: boolean;
	numErrorsWhileGettingSlid: number;
	numErrorsWhileSendingSlid: number;
	@Output() onClose: EventEmitter<string> = new EventEmitter();
	deferredAnalytics: boolean;
	deferredInteractions: Array<{ page: string[]; link_type: string }> = [];
	deferredPages: Array<string[]> = [];
	showCallButton: boolean;
	showErrorGetSlidChat: boolean;
	isErrorGetSlid: boolean;

	constructor(
		private ref: ChangeDetectorRef,
		public ordersService: OrdersService,
		readonly utilsService: UtilsService,
		readonly slidService: SlidService,
		public appService: AppService,
		public translate: TranslateService,
		private myOrdersService: MyOrdersService,
		private tagging: MyOrdersTaggingService
	) {
		this.nebaInstallationGuideOverlayData = {
			isFullScreen: true,
			isCenterTitle: true,
			title: this.translate.instant('v10.myOrder.nebaInstallation.guideNebaTitle'),
		};
		this.nebaInstallationGuideExitData = {
			primaryButton: 'Continuar con la guía',
			secondaryButton: 'Salir',
		};
	}

	ngOnInit(): void {
		this.nebaInternet5Step = this.translate
			.instant('v10.myOrder.nebaInstallation.nebaInternetGuideStep5LedEnd')
			.replace('{phone}', this.translate.instant('v10.myOrder.nebaInstallation.nebaInternetGuideContactPhone'));
		this.overlayLoaded = false;
		this.showNebaInstallationGuideOverlay = true;
		this.overlayPage = 1;
		this.slidService.slidConfig = null;
		this.numErrorsWhileGettingSlid = 0;
		this.numErrorsWhileSendingSlid = 0;
		this.slidResponse = null;
		this.updateTitleAndProgressBar();
		if (this.ordersService.detailOrderData.guiaAutoiNeba === NebaGuideType.NEBA_INTERNET) {
			this.pageName = 'guia neba internet';
			this.progressBarTitles = [
				this.translate.instant('v10.myOrder.nebaInstallation.guideNebaStep1Title'),
				this.translate.instant('v10.myOrder.nebaInstallation.guideNebaStep2Title'),
				this.translate.instant('v10.myOrder.nebaInstallation.guideNebaStep3Title'),
				this.translate.instant('v10.myOrder.nebaInstallation.guideNebaStep4Title'),
				this.translate.instant('v10.myOrder.nebaInstallation.guideNebaStep5Title'),
			];
			this.pagesNames = [
				'overlay inicio guia',
				'video y material',
				'conexion cable alimentacion',
				'datos slid',
				'aviso perdida conexion',
				'configuracion router',
			];
		}
		this.koNames = ['ko slid vacio', 'ko slid no recuperado', 'ko llamada', 'ko chat'];
		this.showCallButton = !constants.trueLiteral.includes(
			this.translate.instant('v10.myOrder.nebaInstallation.hideClickToCallButtonsNEBA')
		);
		this.showErrorGetSlidChat = constants.trueLiteral.includes(
			this.translate.instant('v10.myOrder.nebaInstallation.guiaNebaInternetErrorSlidShowChat')
		);

		this.sendTaggingFirstView();

		setTimeout(() => {
			this.overlayLoaded = true;
		}, 600);
	}

	updateTitleAndProgressBar(): void {
		if (this.overlayPage < 2) {
			this.progressBarCurrentStep = 1;
			this.nebaInstallationGuideOverlayData.title = this.translate.instant(
				'v10.myOrder.nebaInstallation.guideNebaTitle'
			);
		} else {
			this.progressBarCurrentStep = this.overlayPage - 1;
			this.nebaInstallationGuideOverlayData.title = ' ';
		}
	}

	sendInteractionAnalytics(link_type: string): void {
		if (this.deferredAnalytics) {
			this.deferredInteractions.push({
				page: [this.pagesNames[this.overlayPage - 1]],
				link_type,
			});
		} else {
			this.tagging.sendInteraction({ link_type });
		}
	}
	sendPageAnalytics(page: string[]): void {
		if (this.deferredAnalytics) {
			this.deferredPages.push(page);
		} else {
			this.tagging.sendPage(page);
		}
	}

	sendDeferredAnalytics(): void {
		if (this.deferredAnalytics) {
			this.deferredAnalytics = false;

			this.deferredInteractions.forEach((interaction) => {
				this.resetTagging();
				this.tagging.extendUtagPageName(interaction.page);
				this.tagging.sendInteraction({ link_type: interaction.link_type });
			});
			this.deferredInteractions = [];

			this.deferredPages.forEach((page) => {
				this.resetTagging();
				this.tagging.sendPage(page);
			});
			this.deferredPages = [];
		}
	}

	sendTaggingFirstView(): void {
		this.resetTagging(true);
		this.sendPageAnalytics([this.pageName, this.pagesNames[0]]);
	}

	sendPageOverlayTagging(): void {
		this.resetTagging();
		if (this.overlayPage === 4 && this.numErrorsWhileGettingSlid > 0) {
			this.sendGetSlidErrorTagging();
		} else {
			this.sendPageAnalytics([this.pagesNames[this.overlayPage - 1]]);
		}
	}

	sendGetSlidErrorTagging(): void {
		this.resetTagging();
		if (this.isErrorGetSlid) {
			this.sendPageAnalytics(
				this.numErrorsWhileGettingSlid <
					+this.translate.instant('v10.myOrder.nebaInstallation.nebaInternetGuideMaxRetriesGettingSlid')
					? [this.pagesNames[this.overlayPage - 1], 'ko slid no recuperado']
					: [
							this.pagesNames[this.overlayPage - 1],
							'ko slid no recuperado',
							this.showErrorGetSlidChat ? 'ko chat' : 'ko llamada',
					  ]
			);
		} else {
			this.sendPageAnalytics([
				this.pagesNames[this.overlayPage - 1],
				'ko slid vacio',
				this.showErrorGetSlidChat ? 'ko chat' : 'ko llamada',
			]);
		}
	}

	overlayGoForwards(): void {
		if (this.overlayPage !== this.pagesNames.length) {
			// Not last page, go to next one
			this.overlayPage++;
			this.updateTitleAndProgressBar();
			this.sendPageOverlayTagging();
		} else {
			// Last guide's page, close
			this.sendInteractionAnalytics('click en finalizar');
			this.closeGuideOverlay(true);
		}
	}

	overlayGoBackwards(): void {
		if (this.overlayPage === 1) {
			// First guide's page, close
			this.closeGuideOverlay();
		} else {
			// Not in first page, go to previous one
			this.overlayPage--;
			this.updateTitleAndProgressBar();
			this.sendPageOverlayTagging();
		}
	}

	getSlid(): void {
		if (this.slidService.slidConfig) {
			this.overlayGoForwards();
		} else {
			this.overlayLoaded = false;
			const idCliente: string = this.myOrdersService.MYORDERS_DATA.idCliente;
			this.sendInteractionAnalytics(this.numErrorsWhileGettingSlid > 0 ? 'click en reintentar' : 'click en configurar');
			this.tagging.extendUtagPageName(['ko slid vacio', 'ko slid no recuperado', 'ko llamada'], null, true);
			this.slidService.getRouterSLID().subscribe({
				next: (res: SlidConfig) => {
					this.isErrorGetSlid = false;
					if (res?.userConfig && res?.passUserConfig && res?.passGpon) {
						this.slidService.postInsertInteractionSLID(idCliente, InteractionType.NEBA).subscribe();
						this.numErrorsWhileGettingSlid = 0;
						this.overlayGoForwards();
					} else {
						// Service ok but missing some SLID info, take to call screen
						this.slidService.postInsertInteractionSLID(idCliente, InteractionType.KO_NEBA).subscribe();
						this.utilsService.stepFlow = StepFlow.KO_SLID;
						this.utilsService.motiveSupport = MotiveSupport.KO_SLID_SMART;
						this.numErrorsWhileGettingSlid = +this.translate.instant(
							'v10.myOrder.nebaInstallation.nebaInternetGuideMaxRetriesGettingSlid'
						);
						this.slidService.slidConfig = null;
						this.sendGetSlidErrorTagging();
					}
					this.overlayLoaded = true;
				},
				error: () => {
					this.slidService.postInsertInteractionSLID(idCliente, InteractionType.KO_NEBA).subscribe();
					this.utilsService.stepFlow = StepFlow.KO_SLID;
					this.utilsService.motiveSupport = MotiveSupport.FAILURE_SLID;
					this.isErrorGetSlid = true;
					this.numErrorsWhileGettingSlid++;
					this.overlayLoaded = true;
					this.sendGetSlidErrorTagging();
				},
			});
		}
	}

	goToChat(): void {
		this.sendInteractionAnalytics('click en abrir chat');
		this.utilsService.openAutoiChat(
			this.ordersService.detailOrderData.numeroPedido,
			this.utilsService.flowNebaTV,
			this.utilsService.stepFlow,
			this.utilsService.motiveSupport
		);
	}

	sendCallAnalytics(): void {
		this.sendInteractionAnalytics('click en llamar asistencia');
	}

	sendSLIDToONT(): void {
		this.isSettingSlid = true;
		this.deferredAnalytics = true;
		this.sendInteractionAnalytics('click en activar router');

		this.slidService.sendSlidToRouter().subscribe({
			next: (result: EventMessage) => {
				if (result.action === 'router-events') {
					if (result.message === 'set-slid') {
						this.sendInteractionAnalytics('activar router ok');
						this.slidResponse = 'ok';
						this.numErrorsWhileSendingSlid = 0;
						this.isSettingSlid = false;
					} else {
						this.handleSlidError(result);
					}
					this.detectChangesAndUnsuscribeSlid();
				}
			},
			error: (result: EventMessage) => {
				this.handleSlidError(result);
				this.detectChangesAndUnsuscribeSlid();
			},
		});
	}

	detectChangesAndUnsuscribeSlid(): void {
		this.ref.detach();
		this.ref.detectChanges();
		this.ref.reattach();
		this.slidService.$routerSlidSubject.unsubscribe();
	}

	handleSlidError(result: EventMessage): void {
		this.slidResponse = 'error';
		this.numErrorsWhileSendingSlid++;
		this.isSettingSlid = false;

		if (result.message === 'login-router') {
			// KO router login
			this.sendInteractionAnalytics('activar router ko login');
		}
		if (result.message === 'reset-slid') {
			// KO reset SLID
			this.sendInteractionAnalytics('activar router ko reset slid');
		}
		if (result.message === 'set-slid') {
			// KO sending SLID
			this.sendInteractionAnalytics('activar router ko establecer slid');
		}

		if (
			this.numErrorsWhileSendingSlid <
			+this.translate.instant('v10.myOrder.nebaInstallation.nebaInternetGuideMaxRetriesGettingSlid')
		) {
			this.sendInteractionAnalytics('activar router ko reintentar');
		} else {
			this.sendInteractionAnalytics('activar router ko telefono asistencia');
			this.slidResponse = 'max-retries';
		}
	}

	closeGuideOverlay(forceClose?: boolean): void {
		if (!forceClose && this.slidService.slidConfig) {
			this.showNebaExitGuideOverlay = true;
			this.sendPageAnalytics(['overlay abandonar proceso']);
		} else {
			this.showNebaInstallationGuideOverlay = false;
			setTimeout(() => {
				this.onClose.emit();
			}, 500);
		}
	}

	closeExitOverlay(closeType: string): void {
		let interactionName: string = 'click en cerrar';
		if (closeType === 'continue') {
			interactionName = 'click en continuar con la guia';
		}
		if (closeType === 'exit') {
			interactionName = 'click en salir';
		}
		this.sendInteractionAnalytics(interactionName);
		this.showNebaExitGuideOverlay = false;
		this.tagging.extendUtagPageName(['overlay abandonar proceso'], null, true);
	}

	buttonExit(): void {
		this.closeExitOverlay('exit');
		this.closeGuideOverlay(true);
	}

	resetTagging(removePageName?: boolean): void {
		this.tagging.extendUtagPageName([...this.pagesNames, 'detalle pedido'], null, true);
		if (removePageName) {
			this.tagging.extendUtagPageName([this.pageName], null, true);
		}
	}

	ngOnDestroy(): void {
		this.sendDeferredAnalytics();
		this.resetTagging(true);
		this.tagging.extendUtagPageName(['detalle pedido']);
	}
}
