import { Injectable } from '@angular/core';
import { NewTaggingHelperService } from '../../../../core/services/new-tagging.helper.service';
import { StorageService } from '../../../../core/services/storage.service';
import { TaggingHelperService } from '../../../../core/services/tagging.helper.service';
import { SubscriptionService } from '../../../../core/services/subscription.service';
import { ClientTypology } from '../../../enums/clientTopology.enum';
import { BubbleStatus, estimatedDateFormat, pay, setOB, myOrdersTag, returnDefines } from '../../../constants/defines';
import { UtilsService } from './utils.service';
import { NewTaggingJsonModel } from '../../../../models/new-tagging-json-model';
import { OrdersService } from './orders.service';
import { MonitoringPurchase } from '../../../models/getOrder.model';
import { constants } from '../../../models/constants';
import { Paso } from '../../../models/detalleSeguimientoPedido.model';
import { TranslateService } from '@ngx-translate/core';
import { HistoryOlService } from './history-ol.service';
import { HistoryStatusDetail } from '../../../models/historyStatusMap.model';
import { PlatformType } from '../../../enums/platform-type.enum';
import { ReturnsDeviceService } from './returns-device.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class MyOrdersTagService {
	public category: string = this.tagging
		.getUserType(this.storageService?.userProfile?.customerType, ClientTypology)
		.toLowerCase();
	public subcategory: string =
		this.subscriptionService.customerData.SiteType === pay.postpaid_check ? pay.postpaid : pay.prepaid;
	public monitoring_purchase: MonitoringPurchase = new MonitoringPurchase();
	public olDetail: HistoryStatusDetail = this.olService.getShipmentHistoryStatusDetail(
		this.olService.orderLastOLStatus
	);
	public wvPlatform: string = this.storageService.getLocalStorage('wvPlatform')?.toLowerCase() || null;
	public platform: string[] = [PlatformType.Android.toString(), PlatformType.IOS.toString()];

	constructor(
		public newTagging: NewTaggingHelperService,
		public storageService: StorageService,
		private tagging: TaggingHelperService,
		public subscriptionService: SubscriptionService,
		public utilsService: UtilsService,
		public ordersService: OrdersService,
		public translate: TranslateService,
		public olService: HistoryOlService,
		public returnDevice: ReturnsDeviceService
	) {}

	public tagClickNoDoa(button?: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/returnsNoDoa').subscribe((dataEventNoDoa: NewTaggingJsonModel) => {
			dataEventNoDoa = this.setDataTagIteration(dataEventNoDoa, returnDefines.clickReturns);
			dataEventNoDoa.eventList['click_returns'].event.event_label = this.translate.instant(
				'v10.myOrder.subOrders.details.btnReturn'
			);
			this.newTagging.interaction(dataEventNoDoa.eventList['click_returns'], dataEventNoDoa.page);
		});
	}

	public tagClickDoa(button?: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/returnsDoa').subscribe((dataEventDoa: NewTaggingJsonModel) => {
			dataEventDoa = this.setDataTagIteration(dataEventDoa, returnDefines.clickContinue);
			dataEventDoa.eventList['click_continue'].event.event_label = this.translate.instant(
				'v10.myOrder.subOrders.details.btnReturnFix'
			);
			this.newTagging.interaction(dataEventDoa.eventList['click_continue'], dataEventDoa.page);
		});
	}

	public tagStateDoa(button?: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/repairDoa').subscribe((dataStateRepairDoa: NewTaggingJsonModel) => {
			dataStateRepairDoa = this.setDataTagStatePrincipal(dataStateRepairDoa);
			this.newTagging.state(dataStateRepairDoa.page);
		});
	}

	public tagClickContinueDoa(button?: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/repairDoa').subscribe((dataEventContinueDoa: NewTaggingJsonModel) => {
			dataEventContinueDoa = this.setDataTagIteration(dataEventContinueDoa, returnDefines.clickContinue);
			dataEventContinueDoa.eventList['click_continue'].event.event_label = this.translate.instant(
				'v10.myOrder.orders.repair.btn'
			);
			this.newTagging.interaction(dataEventContinueDoa.eventList['click_continue'], dataEventContinueDoa.page);
		});
	}

	public tagClickHelpDoa(button?: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/repairDoa').subscribe((dataEventHelpCases: NewTaggingJsonModel) => {
			dataEventHelpCases = this.setDataTagIteration(dataEventHelpCases, returnDefines.clickHelp);
			dataEventHelpCases.eventList['click_help'].event.event_label = this.translate.instant(
				'v10.myOrder.orders.returns.optionsReturn.helpLink'
			);
			this.newTagging.interaction(dataEventHelpCases.eventList['click_help'], dataEventHelpCases.page);
		});
	}

	public tagModalStateCases(button?: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/returnsCases').subscribe((dataStateCases: NewTaggingJsonModel) => {
			dataStateCases = this.setDataTagStatePrincipal(dataStateCases);
			this.newTagging.state(dataStateCases.page);
		});
	}

	public tagClickContinueCases(button?: string): void {
		this.setMonitoring(button);
		this.newTagging
			.getJsonTagging('my-orders/returnsCases')
			.subscribe((dataEventContinueCases: NewTaggingJsonModel) => {
				dataEventContinueCases = this.setDataTagIteration(dataEventContinueCases, returnDefines.clickContinue);
				dataEventContinueCases.eventList['click_continue'].event.event_context =
					this.returnDevice.selectedType?.toLowerCase() === returnDefines.doa.toLowerCase()
						? this.translate.instant('v10.myOrder.orders.returns.optionsReturn.labelDOA')
						: this.translate.instant('v10.myOrder.orders.returns.optionsReturn.labelNoDOA');
				dataEventContinueCases.eventList['click_continue'].event.event_label = this.translate.instant(
					'v10.myOrder.orders.returns.optionsReturn.continueButton'
				);
				this.newTagging.interaction(dataEventContinueCases.eventList['click_continue'], dataEventContinueCases.page);
			});
	}

	public tagClickCancelCases(button?: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/returnsCases').subscribe((dataEventCancelCases: NewTaggingJsonModel) => {
			dataEventCancelCases = this.setDataTagIteration(dataEventCancelCases, returnDefines.clickCancel);
			dataEventCancelCases.eventList['click_cancel'].event.event_context =
				this.returnDevice.selectedType?.toLowerCase() === returnDefines.doa.toLowerCase()
					? this.translate.instant('v10.myOrder.orders.returns.optionsReturn.labelDOA')
					: this.translate.instant('v10.myOrder.orders.returns.optionsReturn.labelNoDOA');
			dataEventCancelCases.eventList['click_cancel'].event.event_label = this.translate.instant(
				'v10.myOrder.orders.returns.optionsReturn.cancelButton'
			);
			this.newTagging.interaction(dataEventCancelCases.eventList['click_cancel'], dataEventCancelCases.page);
		});
	}

	public tagClickHelpCases(button?: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/returnsCases').subscribe((dataEventHelpCases: NewTaggingJsonModel) => {
			dataEventHelpCases = this.setDataTagIteration(dataEventHelpCases, returnDefines.clickHelp);
			dataEventHelpCases.eventList['click_help'].event.event_label = this.translate.instant(
				'v10.myOrder.orders.returns.optionsReturn.helpLink'
			);
			this.newTagging.interaction(dataEventHelpCases.eventList['click_help'], dataEventHelpCases.page);
		});
	}

	public tagModalShipmentCases(button?: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/returnsShipment').subscribe((ShipmentCases: NewTaggingJsonModel) => {
			ShipmentCases = this.setDataTagStatePrincipal(ShipmentCases);
			this.newTagging.state(ShipmentCases.page);
		});
	}

	public tagClickContinueShipmentCases(button?: string): void {
		this.setMonitoring(button);
		this.newTagging
			.getJsonTagging('my-orders/returnsShipment')
			.subscribe((dataEventContinueShipmentCases: NewTaggingJsonModel) => {
				dataEventContinueShipmentCases = this.setDataTagIteration(
					dataEventContinueShipmentCases,
					returnDefines.clickContinue
				);
				dataEventContinueShipmentCases.eventList['click_continue'].event.event_context = this.returnDevice
					.isFirstOptionSelected
					? this.translate.instant('v10.myOrder.orders.returns.optionsShipment.titleCorreos')
					: this.translate.instant('v10.myOrder.orders.returns.optionsShipment.titleHome');
				dataEventContinueShipmentCases.eventList['click_continue'].event.event_label = this.translate.instant(
					'v10.myOrder.orders.returns.optionsShipment.continueButton'
				);
				this.newTagging.interaction(
					dataEventContinueShipmentCases.eventList['click_continue'],
					dataEventContinueShipmentCases.page
				);
			});
	}

	public tagClickEditShipmentCases(button?: string): void {
		this.setMonitoring(button);
		this.newTagging
			.getJsonTagging('my-orders/returnsShipment')
			.subscribe((dataEventEditShipmentCases: NewTaggingJsonModel) => {
				dataEventEditShipmentCases = this.setDataTagIteration(dataEventEditShipmentCases, returnDefines.clickEdit);
				dataEventEditShipmentCases.eventList['click_edit'].event.event_context = this.returnDevice.isAdrdess
					? returnDefines.address
					: returnDefines.phone;
				dataEventEditShipmentCases.eventList['click_edit'].event.event_label = returnDefines.edit;
				this.newTagging.interaction(
					dataEventEditShipmentCases.eventList['click_edit'],
					dataEventEditShipmentCases.page
				);
			});
	}

	public tagClickHelpShipmentCases(button?: string): void {
		this.setMonitoring(button);
		this.newTagging
			.getJsonTagging('my-orders/returnsShipment')
			.subscribe((dataEventHelpShipmentCases: NewTaggingJsonModel) => {
				dataEventHelpShipmentCases = this.setDataTagIteration(dataEventHelpShipmentCases, returnDefines.clickHelp);
				dataEventHelpShipmentCases.eventList['click_help'].event.event_label = this.translate.instant(
					'v10.myOrder.orders.returns.optionsShipment.helpLink'
				);
				this.newTagging.interaction(
					dataEventHelpShipmentCases.eventList['click_help'],
					dataEventHelpShipmentCases.page
				);
			});
	}

	public tagClickAspaShipmentCases(button?: string): void {
		this.setMonitoring(button);
		this.newTagging
			.getJsonTagging('my-orders/returnsShipment')
			.subscribe((dataEventAspaShipmentCases: NewTaggingJsonModel) => {
				dataEventAspaShipmentCases = this.setDataTagIteration(dataEventAspaShipmentCases, returnDefines.clickClose);
				this.newTagging.interaction(
					dataEventAspaShipmentCases.eventList['click_close'],
					dataEventAspaShipmentCases.page
				);
			});
	}

	public tagClickBackShipmentCases(button?: string): void {
		this.setMonitoring(button);
		this.newTagging
			.getJsonTagging('my-orders/returnsShipment')
			.subscribe((dataEventBackShipmentCases: NewTaggingJsonModel) => {
				dataEventBackShipmentCases = this.setDataTagIteration(dataEventBackShipmentCases, returnDefines.clickBack);
				this.newTagging.interaction(
					dataEventBackShipmentCases.eventList['click_back'],
					dataEventBackShipmentCases.page
				);
			});
	}

	public tagModalSummaryCases(button?: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/returnsSummary').subscribe((SummaryCases: NewTaggingJsonModel) => {
			SummaryCases = this.setDataTagStatePrincipal(SummaryCases);
			this.newTagging.state(SummaryCases.page);
		});
	}

	public tagClickContinueSummaryCases(button?: string): void {
		this.setMonitoring(button);
		this.newTagging
			.getJsonTagging('my-orders/returnsSummary')
			.subscribe((dataEventContinueSummaryCases: NewTaggingJsonModel) => {
				dataEventContinueSummaryCases = this.setDataTagIteration(
					dataEventContinueSummaryCases,
					returnDefines.clickContinue
				);
				dataEventContinueSummaryCases.eventList['click_continue'].event.event_context = this.returnDevice
					.isFirstOptionSelected
					? this.translate.instant('v10.myOrder.orders.returns.optionsShipment.titleCorreos')
					: this.translate.instant('v10.myOrder.orders.returns.optionsShipment.titleHome');
				dataEventContinueSummaryCases.eventList['click_continue'].event.event_label = this.translate.instant(
					'v10.myOrder.orders.returns.sumary.continueButton'
				);
				this.newTagging.interaction(
					dataEventContinueSummaryCases.eventList['click_continue'],
					dataEventContinueSummaryCases.page
				);
			});
	}

	public tagClickCancelSummaryCases(button?: string): void {
		this.setMonitoring(button);
		this.newTagging
			.getJsonTagging('my-orders/returnsSummary')
			.subscribe((dataEventCancelSummaryCases: NewTaggingJsonModel) => {
				dataEventCancelSummaryCases = this.setDataTagIteration(dataEventCancelSummaryCases, returnDefines.clickCancel);
				dataEventCancelSummaryCases.eventList['click_cancel'].event.event_context = this.returnDevice
					.isFirstOptionSelected
					? this.translate.instant('v10.myOrder.orders.returns.optionsShipment.titleCorreos')
					: this.translate.instant('v10.myOrder.orders.returns.optionsShipment.titleHome');
				dataEventCancelSummaryCases.eventList['click_cancel'].event.event_label = this.translate.instant(
					'v10.myOrder.orders.returns.sumary.cancelButton'
				);
				this.newTagging.interaction(
					dataEventCancelSummaryCases.eventList['click_cancel'],
					dataEventCancelSummaryCases.page
				);
			});
	}

	public tagClickAspaSummaryCases(button?: string): void {
		this.setMonitoring(button);
		this.newTagging
			.getJsonTagging('my-orders/returnsSummary')
			.subscribe((dataEventAspaSummaryCases: NewTaggingJsonModel) => {
				dataEventAspaSummaryCases = this.setDataTagIteration(dataEventAspaSummaryCases, returnDefines.clickClose);
				this.newTagging.interaction(dataEventAspaSummaryCases.eventList['click_close'], dataEventAspaSummaryCases.page);
			});
	}

	public tagClickBackSummaryCases(button?: string): void {
		this.setMonitoring(button);
		this.newTagging
			.getJsonTagging('my-orders/returnsSummary')
			.subscribe((dataEventBackSummaryCases: NewTaggingJsonModel) => {
				dataEventBackSummaryCases = this.setDataTagIteration(dataEventBackSummaryCases, returnDefines.clickBack);
				this.newTagging.interaction(dataEventBackSummaryCases.eventList['click_back'], dataEventBackSummaryCases.page);
			});
	}

	public tagModalOk(button?: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/returnsOKKO').subscribe((ModalOk: NewTaggingJsonModel) => {
			ModalOk = this.setDataTagStatePrincipal(ModalOk);
			this.newTagging.state(ModalOk.page);
		});
	}

	public tagClickContinueOk(btn: string, button?: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/returnsOKKO').subscribe((dataEventContinueOk: NewTaggingJsonModel) => {
			dataEventContinueOk = this.setDataTagIteration(dataEventContinueOk, returnDefines.clickContinue);
			dataEventContinueOk.eventList['click_continue'].event.event_label = btn;
			this.newTagging.interaction(dataEventContinueOk.eventList['click_continue'], dataEventContinueOk.page);
		});
	}

	public tagModalKO(error: HttpErrorResponse, button?: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/returnsKO').subscribe((ModalKO: NewTaggingJsonModel) => {
			ModalKO = this.setDataTagStatePrincipal(ModalKO);
			ModalKO.page.custom_map['error_category'] = returnDefines.errorCategory;
			ModalKO.page.custom_map['error_description'] = error.message;
			ModalKO.page.custom_map['error_type'] = returnDefines.errorType;
			ModalKO.page.custom_map['error_code'] = error.status;
			this.newTagging.state(ModalKO.page);
		});
	}

	public tagClickContinueKO(error: HttpErrorResponse, btn: string, button?: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/returnsKO').subscribe((dataEventContinueKO: NewTaggingJsonModel) => {
			dataEventContinueKO.eventList['click_continue'].custom_map['error_category'] = returnDefines.errorCategory;
			dataEventContinueKO.eventList['click_continue'].custom_map['error_description'] = error.message;
			dataEventContinueKO.eventList['click_continue'].custom_map['error_type'] = returnDefines.errorType;
			dataEventContinueKO.eventList['click_continue'].custom_map['error_code'] = error.status;
			dataEventContinueKO = this.setDataTagIteration(dataEventContinueKO, returnDefines.clickContinue);
			dataEventContinueKO.eventList['click_continue'].event.event_label = btn;
			this.newTagging.interaction(dataEventContinueKO.eventList['click_continue'], dataEventContinueKO.page);
		});
	}

	public tagClickAspaOk(button?: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/returnsOKKO').subscribe((dataEventAspaOk: NewTaggingJsonModel) => {
			dataEventAspaOk = this.setDataTagIteration(dataEventAspaOk, returnDefines.clickClose);
			this.newTagging.interaction(dataEventAspaOk.eventList['click_close'], dataEventAspaOk.page);
		});
	}

	public tagClickAspaKO(error: HttpErrorResponse, button?: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/returnsKO').subscribe((dataEventAspaKO: NewTaggingJsonModel) => {
			dataEventAspaKO.eventList['click_close'].custom_map['error_category'] = returnDefines.errorCategory;
			dataEventAspaKO.eventList['click_close'].custom_map['error_description'] = error.message;
			dataEventAspaKO.eventList['click_close'].custom_map['error_type'] = returnDefines.errorType;
			dataEventAspaKO.eventList['click_close'].custom_map['error_code'] = error.status;
			dataEventAspaKO = this.setDataTagIteration(dataEventAspaKO, returnDefines.clickClose);
			this.newTagging.interaction(dataEventAspaKO.eventList['click_close'], dataEventAspaKO.page);
		});
	}

	public tagClickBreakdowns(button?: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/breakdowns').subscribe((dataEventBreakdowns: NewTaggingJsonModel) => {
			dataEventBreakdowns = this.setDataTagIteration(dataEventBreakdowns, myOrdersTag.clickBreakdowns);
			dataEventBreakdowns.eventList[myOrdersTag.clickBreakdowns].event.event_label = dataEventBreakdowns.eventList[
				myOrdersTag.clickBreakdowns
			].event.event_label.replace('{0}', this.translate.instant('v10.myOrder.collapse.Breakdowns.button'));
			this.newTagging.interaction(dataEventBreakdowns.eventList[myOrdersTag.clickBreakdowns], dataEventBreakdowns.page);
		});
	}

	public tagModalStateCancellations(button?: string): void {
		this.setMonitoring(button);
		this.newTagging
			.getJsonTagging('my-orders/cancellations')
			.subscribe((dataStateCancellation: NewTaggingJsonModel) => {
				dataStateCancellation = this.setDataTagStatePrincipal(dataStateCancellation);
				this.newTagging.state(dataStateCancellation.page);
			});
	}

	public tagClickContinue(button: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/open-banking').subscribe((dataEventContinue: NewTaggingJsonModel) => {
			dataEventContinue = this.setDataTagIteration(dataEventContinue, 'click_continue');
			dataEventContinue.eventList['click_continue'].event.event_label = this.translate.instant(
				'v10.myOrder.detail.ScoringBtnOB'
			);
			this.newTagging.interaction(dataEventContinue.eventList['click_continue'], dataEventContinue.page);
		});
	}

	public tagModalState(button: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/open-banking').subscribe((dataStateModal: NewTaggingJsonModel) => {
			dataStateModal = this.setDataTagState(dataStateModal, 'modal_openBanking');
			this.newTagging.state(dataStateModal.page.stateList['modal_openBanking']);
		});
	}

	public tagClickStart(button: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/open-banking').subscribe((dataEventStart: NewTaggingJsonModel) => {
			dataEventStart = this.setDataTagIteration(dataEventStart, 'click_start');
			this.newTagging.interaction(
				dataEventStart.eventList['click_start'],
				dataEventStart.page.stateList['modal_openBanking']
			);
		});
	}

	public tagCloseBackDrop(button: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/open-banking').subscribe((dataEventClose: NewTaggingJsonModel) => {
			dataEventClose = this.setDataTagIteration(dataEventClose, 'close_modal');
			this.newTagging.interaction(
				dataEventClose.eventList['close_modal'],
				dataEventClose.page.stateList['modal_openBanking']
			);
		});
	}

	public tagIframeState(button: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/open-banking').subscribe((dataIframeState: NewTaggingJsonModel) => {
			dataIframeState = this.setDataTagState(dataIframeState, 'iframe_openBanking');
			this.newTagging.state(dataIframeState.page.stateList['iframe_openBanking']);
		});
	}

	public tagCloseIframe(button: string): void {
		this.setMonitoring(button);
		this.newTagging.getJsonTagging('my-orders/open-banking').subscribe((data: NewTaggingJsonModel) => {
			data = this.setDataTagIteration(data, 'close_iframe');
			this.newTagging.interaction(data.eventList['close_iframe'], data.page.stateList['iframe_openBanking']);
		});
	}

	public setDataTagIteration(dataEvent: NewTaggingJsonModel, event: string): NewTaggingJsonModel {
		dataEvent.eventList[event].custom_map['monitoring_purchase_id'] = this.monitoring_purchase.monitoring_purchase_id;
		dataEvent.eventList[event].custom_map['monitoring_purchase_status'] =
			this.monitoring_purchase.monitoring_purchase_status;
		dataEvent.eventList[event].custom_map['monitoring_purchase_type'] =
			this.monitoring_purchase.monitoring_purchase_type;
		dataEvent.eventList[event].custom_map['monitoring_purchase_flux'] =
			this.monitoring_purchase.monitoring_purchase_flux;
		dataEvent.eventList[event].custom_map['monitoring_purchase_step'] =
			this.monitoring_purchase.monitoring_purchase_step;
		dataEvent.eventList[event].custom_map['monitoring_purchase_substatus'] =
			this.monitoring_purchase.monitoring_purchase_substatus;
		dataEvent.eventList[event].journey.journey_category = this.category;
		dataEvent.eventList[event].journey.journey_subcategory = this.subcategory;
		dataEvent.eventList[event].custom_map['asset_name'] = this.setAssetName();
		dataEvent.eventList[event].custom_map['page_platform'] = this.setPagePlatform();
		return dataEvent;
	}

	public setDataTagState(dataState: NewTaggingJsonModel, state: string): NewTaggingJsonModel {
		dataState.page.stateList[state].custom_map['monitoring_purchase_id'] =
			this.monitoring_purchase.monitoring_purchase_id;
		dataState.page.stateList[state].custom_map['monitoring_purchase_status'] =
			this.monitoring_purchase.monitoring_purchase_status;
		dataState.page.stateList[state].custom_map['monitoring_purchase_type'] =
			this.monitoring_purchase.monitoring_purchase_type;
		dataState.page.stateList[state].custom_map['monitoring_purchase_flux'] =
			this.monitoring_purchase.monitoring_purchase_flux;
		dataState.page.stateList[state].custom_map['monitoring_purchase_step'] =
			this.monitoring_purchase.monitoring_purchase_step;
		dataState.page.stateList[state].custom_map['monitoring_purchase_substatus'] =
			this.monitoring_purchase.monitoring_purchase_substatus;
		dataState.page.stateList[state].journey.journey_category = this.category;
		dataState.page.stateList[state].journey.journey_subcategory = this.subcategory;
		dataState.page.stateList[state].custom_map['asset_name'] = this.setAssetName();
		dataState.page.stateList[state].custom_map['page_platform'] = this.setPagePlatform();
		return dataState;
	}

	public setDataTagStatePrincipal(setDataTagStatePrincipal: NewTaggingJsonModel): NewTaggingJsonModel {
		setDataTagStatePrincipal.page.custom_map['monitoring_purchase_id'] =
			this.monitoring_purchase.monitoring_purchase_id;
		setDataTagStatePrincipal.page.custom_map['monitoring_purchase_status'] =
			this.monitoring_purchase.monitoring_purchase_status;
		setDataTagStatePrincipal.page.custom_map['monitoring_purchase_type'] =
			this.monitoring_purchase.monitoring_purchase_type;
		setDataTagStatePrincipal.page.custom_map['monitoring_purchase_flux'] =
			this.monitoring_purchase.monitoring_purchase_flux;
		setDataTagStatePrincipal.page.custom_map['monitoring_purchase_step'] =
			this.monitoring_purchase.monitoring_purchase_step;
		setDataTagStatePrincipal.page.custom_map['monitoring_purchase_substatus'] =
			this.monitoring_purchase.monitoring_purchase_substatus;
		setDataTagStatePrincipal.page.journey.journey_category = this.category;
		setDataTagStatePrincipal.page.journey.journey_subcategory = this.subcategory;
		setDataTagStatePrincipal.page.custom_map['asset_name'] = this.setAssetName();
		setDataTagStatePrincipal.page.custom_map['page_platform'] = this.setPagePlatform();
		return setDataTagStatePrincipal;
	}

	public setAssetName(): string {
		return this.platform.includes(this.wvPlatform) ? myOrdersTag.mivoapp : myOrdersTag.mivoweb;
	}

	public setPagePlatform(): string {
		return this.platform.includes(this.wvPlatform) ? this.wvPlatform : myOrdersTag.web;
	}

	public setMonitoring(button: string): void {
		this.monitoring_purchase.monitoring_purchase_id = this.ordersService.detailOrderData.numeroPedido;
		this.monitoring_purchase.monitoring_purchase_status = this.setPurchaseStatus(this.ordersService.mensajeEEScoring);
		this.monitoring_purchase.monitoring_purchase_type = this.hasShopOB(this.ordersService.selectedOrderData.pedidoTol);
		this.monitoring_purchase.monitoring_purchase_step = button
			? `${this.utilsService.normalize(
					this.ordersService.detailOrderData.pasos[
						this.ordersService.detailOrderData.pasos.findIndex(this.isStepIndex, this)
					]?.pasoNombre.toLowerCase()
			  )}${estimatedDateFormat.mensajeOLNotInfo}${button.toLowerCase()} ${BubbleStatus.available}`
			: `${this.utilsService.normalize(
					this.ordersService.detailOrderData.pasos[
						this.ordersService.detailOrderData.pasos.findIndex(this.isStepIndex, this)
					]?.pasoNombre.toLowerCase()
			  )}`;
		this.monitoring_purchase.monitoring_purchase_flux = this.ordersService.selectedOrderData.nombreComercial
			? this.utilsService
					.normalize(this.utilsService.getStringOrdersFlux([this.ordersService.selectedOrderData]))
					.toLowerCase()
			: this.utilsService
					.normalize(this.utilsService.getStringOrdersFlux(this.ordersService.detailOrderData.descripcion))
					.toLowerCase();
		this.monitoring_purchase.monitoring_purchase_substatus = this.olDetail?.statusCode
			? this.olDetail?.statusCode
			: undefined;
	}

	isStepIndex(elem: Paso): boolean {
		return parseInt(elem.pasoOrden, 10) === parseInt(this.ordersService.detailOrderData.paso, 10);
	}

	public hasShopOB(pedido: number): string {
		switch (pedido) {
			case 0:
				return constants.shopTypes.tiendaFisica;
			case 1:
			case 2:
				return constants.shopTypes.tiendaOnline;
			default:
				return constants.shopTypes.telefonica;
		}
	}

	setPurchaseStatus(mensajeEEScoring: string): string {
		let msg: string;
		if (this.ordersService.detailOrderData.portabilidadRechazada?.tipoRechazoPortabilidad) {
			msg =
				constants.statePedidoAnalytics[parseInt(this.ordersService.detailOrderData.statePedido, 10) - 1] +
				setOB.monitoringPurchaseStatusOne;
		} else {
			msg = this.ordersService.detailOrderData.mensajeEE
				? constants.statePedidoAnalytics[parseInt(this.ordersService.detailOrderData.statePedido, 10) - 1] +
				  setOB.monitoringPurchaseStatusTwo +
				  mensajeEEScoring
				: constants.statePedidoAnalytics[parseInt(this.ordersService.detailOrderData.statePedido, 10) - 1];
		}
		return msg;
	}
}
