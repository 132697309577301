export enum BackdropChooser {
	spinnerColor = 'red',
	spinnerType = 'intro',
	deleteType = 'deleteUser',
	errorType = 'error',
	succesType = 'succes',
	grtType = 'grtpermisson',
	dnyType = 'dnypermisson',
	typeErrScreen = 'errorScreen',
	typeSuccScreen = 'SuccessScreen',
}

export enum ActivateDesactivate {
	activar = 'activar',
	desactivar = 'desactivar',
}

export enum Permission {
	activar = 'permitir',
	desactivar = 'no permitir',
}
