import { InteractionType } from './interaction-type.enum';

export enum SchamanState {
	// Finished
	FINISHED = 'finished',
	// In progress
	EXECUTING_RESOLUTION = 'executing_resolution',
	EXECUTING_DIAGNOSE = 'executing_diagnose',
}

export enum SchamanResolutions {
	// OK's
	SOLVED = 'solved',
	TRANSFER_OK = 'transfer_ok',
	SOLVED_REBOOT_CPE = 'solved_reboot_cpe',
	SOLVED_REBOOT_STB = 'solved_reboot_stb',
	// NC's
	ERROR = 'error',
	// KO's
	TRANSFER_KO = 'transfer_ko',
	ENTRADA_HDMI_TV_KO = 'entrada_hdmi_tv_ko',
	ENTRADA_HDMI_KO = 'entrada_hdmi_ko',
	HDMI_KO = 'hdmi_ko',
	STB_ENCENDIDO_KO = 'stb_encendido_ko',
	TX_ERROR = 'tx_error',
}

export enum SchamanResolutionsInteractionMapping {
	'solved' = InteractionType.SOLVED,
	'transfer_ok' = InteractionType.TRANSFER_OK,
	'solved_reboot_cpe' = InteractionType.SOLVED_REBOOT_CPE,
	'solved_reboot_stb' = InteractionType.SOLVED_REBOOT_STB,
	'error' = InteractionType.ERROR,
	'transfer_ko' = InteractionType.TRANSFER_KO,
	'entrada_hdmi_ko' = InteractionType.ENTRADA_HDMI_KO,
	'entrada_hdmi_tv_ko' = InteractionType.ENTRADA_HDMI_KO,
	'hdmi_ko' = InteractionType.HDMI_KO,
	'stb_encendido_ko' = InteractionType.STB_ENCENDIDO_KO,
}
