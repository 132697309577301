export class CommercialOperator {
	public id: number;
	public name: string;
	public objId: string;
	public visible: boolean;
	public xDesCortaOper: string;
	public xNomIdentificacion: string;
	public xNomOperadora: string;
	public xNumIdentificacion: string;
	public value?: string;
}
