import { CommercialCapacity } from './commercial-capacity.model';
import { CommercialFinancingOption } from './commercial-terminal-financing.model';

export class CommercialTerminalList {
	public idGrupo: number;
	public ordenGrupo: number;
	public sap: string;
	public color: string;
	public codigoColor: string;
	public capacidadValorUnidad: CommercialCapacity;
	public imagenListado: string;
	public textoImagenListado: string;
	public modelo: string;
	public so: string;
	public altImagenSo: string;
	public analyticsCategoryType: string;
	public marca: string;
	public idMarca: number;
	public idTerminal: number;
	public idTipoTerminal: number;
	public limitado: boolean;
	public catalogOffer: number;
	public nombreTarifa: string;
	public exclusivoVodafone: number;
	public listaPromos: IdPromos[];
	public textoNoCobroCanon: string;
	public semaphore: Semaphore;
	public financingOptions: CommercialFinancingOption[];
	public mainCharacteristics: MainCharacteristics[];
	public isPrioritary?: boolean;
	public techDeviceType?: string;
	public presale?: boolean;
	public accelerator?: boolean;
	public unavailable?: boolean;
}

export class IdPromos {
	id: string;
}

export class Semaphore {
	color: string;
	text: string;
}

export class MainCharacteristics {
	code: string;
	desc: string;
	order: number;
	sortMethod: string;
	sortType: string;
	icon: string;
	value: string | number;
	standardValue: string;
	extendedValue: string | null;
	unit: string;
}

export class CharacteristicsPerTerminalElement {
	idTerminal: number;
	technicalCharacteristics: MainCharacteristics[];
}

export class ParamsBuyNow {
	idPrecio?: number;
	paymentType?: boolean | number;
	flagPaymentOptionFp?: boolean;
	shopType?: number;
	itemid?: number;
	relatedService?: number;
	modalidadSeguro?: string;
	app?: number;
	version?: string;
	siteId?: string;
	clientType?: number;
	sceneType?: number;
}
