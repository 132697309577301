import { ServiceBalanceSubscription } from './service-balance-subscription.model';
import { IconClassNames } from '../shared/enums/iconClassNames.enum';
import { ServiceType } from '../shared/enums/serviceType.enum';

export class OutOfBundleModel {
	icon: IconClassNames;
	id: string;
	type: ServiceType;
	expirationDate: Date;
	name: string;
	amount: number;
	showDots: boolean;
	amountPostPaid: number;
	currency: string;
	public services: ServiceBalanceSubscription;
}
