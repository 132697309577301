export function sharedServicesPegaParserHashMapToObject(value: Map<string, string>): Record<string, string> {
	const transformed: Record<string, string> = {};

	for (const entry of [...value.entries()]) {
		const [a, b]: [string, string] = entry;

		transformed[a] = b;
	}

	return transformed;
}
