<div class="payment-card" id="paymentCreditCard">
	<div class="payment-card__content">
		<div class="payment-card__initial-pay">
			<div class="payment-card__desc-container">
				<p class="payment-card__desc" id="paymentCardDescription">{{ descriptionText }}</p>
				<img class="payment-card__wink-image" [src]="winkImage" alt="wink" id="paymentCardImageWink" />
			</div>
			<div class="payment-card__price" id="paymentCardPrice">
				{{ initialPay }}
			</div>
			<sp-payment-wallet-chevron [marginTop]="56"> </sp-payment-wallet-chevron>
		</div>
		<div class="btn-container">
			<button (click)="openPaymentProcess()" id="paymentCardButtonPayment">{{ confirmButtonText }}</button>
		</div>
	</div>
	<div class="payment-card__footer">
		<div class="payment-card__footer-images">
			<div class="visa-image">
				<img [src]="visaImage" alt="visa" id="paymentCardImageVisa" />
			</div>
			<div class="mastercard-image">
				<img [src]="masterCardImage" alt="mastercard" id="paymentCardImageMastercard" />
			</div>
		</div>
		<div class="payment-card__footer-securedssl">
			<p id="paymentCardSslText">{{ securedSslText }}</p>
		</div>
	</div>
</div>
