import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'eliminateZeros',
})
export class EliminateZerosPipe implements PipeTransform {
	transform(value: string | number): string {
		let pipeValue: string = value?.toString();
		if (pipeValue?.includes(',')) {
			pipeValue = Number(pipeValue.split(',')[1]) > 0 ? pipeValue : pipeValue.split(',')[0];
		} else if (pipeValue?.includes('.')) {
			pipeValue = Number(pipeValue.split('.')[1]) > 0 ? pipeValue : pipeValue.split('.')[0];
		}
		return pipeValue;
	}
}
