export enum LanguageEnum {
	Castellano = 'Castellano',
	Catalan = 'Catalan',
	Euskera = 'Euskera',
	Gallego = 'Gallego',
	Ingles = 'Ingles',
	english = 'en',
	spanish = 'es',
}

export enum LanguageEnumForFacturas {
	Castellano = 'Castellano',
	Catalan = 'Catalán',
	Euskera = 'Euskera',
	Gallego = 'Gallego',
	Ingles = 'Inglés',
}
