<div class="spinner spinner-out-container-sva">
	<div class="spinner-container row">
		<div class="spinnerContainer col-xs-12 mva10-u--mb4x">
			<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</div>
		<span class="loading-text col-xs-8 col-xs-push-2">
			{{ spinnerText || 'usage.messagesList.loadingData.loadingData_description' | translate }}
		</span>
	</div>
</div>
