import { Injectable } from '@angular/core';
import * as JsonQuery from 'jsonpath/jsonpath';
import { Equipment } from '../../models/equipment.model';
import { TariffPromotion, Voucher } from '../../models/tariff.model';
import { JSON_PATHS, surveyAnswer } from '../constants/defines';
import { BundleStatus } from '../enums/bundleStatus.enum';
import { ProductConfigurationID } from '../enums/product-configuration-id.enum';
import { ProductConfigurationService } from './product-configuration.service';
import { TariffPromotionInterface } from '../../models/tariff-list-response.model';

@Injectable({
	providedIn: 'root',
})
export class TariffHelperService {
	constructor(private productConfigurationService: ProductConfigurationService) {}

	public showDelightTvSurvey(vouchers: Voucher[]): boolean {
		if (vouchers?.length) {
			const surveyVoucher: Voucher = vouchers.find(
				(voucher) =>
					(voucher.type === surveyAnswer.negative || voucher.type === surveyAnswer.affirmative) &&
					voucher.status.toLowerCase() === BundleStatus.Active.toLowerCase()
			);
			if (
				!surveyVoucher &&
				this.productConfigurationService.shouldShowFeature(ProductConfigurationID.delightTvSurvey)
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	public mapPromotions(promotions: TariffPromotionInterface[]): TariffPromotion[] {
		let promos: TariffPromotion[] = [];
		if (promotions.length) {
			promos = promotions.map((promo) => {
				const newPromo: TariffPromotion = new TariffPromotion();
				newPromo.activationDate = promo.activationDate ? new Date(promo.activationDate) : null;
				newPromo.deactivationDate = promo.deactivationDate ? new Date(promo.deactivationDate) : null;
				newPromo.status = promo.status ? promo.status.toUpperCase() : null;
				newPromo.code = promo.code || null;
				newPromo.fee = promo.fee ? parseFloat(promo.fee) : null;
				newPromo.name = promo.name || null;
				return newPromo;
			});
		}
		return promos;
	}

	public mapEquipment(paramEquip: Object, item: Object): Equipment {
		const equipment: Equipment = new Equipment();
		this.mapEquipmentPrices(equipment, paramEquip);
		this.mapEquipmentChangeIDs(equipment, paramEquip);
		equipment.id = JsonQuery.value(item, JSON_PATHS.Equipment.id) || null;
		const type: string = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.type) || null;
		equipment.type = type ? type.toUpperCase() : null;
		equipment.hasAppointment = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.hasAppointment) || null;
		return equipment;
	}

	public mapVouchers(paramVouchers: Object[]): Voucher[] {
		const vouchers: Voucher[] = paramVouchers.map((vchr) => {
			const voucher: Voucher = new Voucher();
			voucher.type = JsonQuery.value(vchr, JSON_PATHS.Voucher.type).toUpperCase();
			voucher.status = JsonQuery.value(vchr, JSON_PATHS.Voucher.status);
			voucher.serviceId = JsonQuery.value(vchr, JSON_PATHS.Voucher.serviceId) || null;
			voucher.voucher = JsonQuery.value(vchr, JSON_PATHS.Voucher.voucher) || null;
			voucher.endDate = JsonQuery.value(vchr, JSON_PATHS.Voucher.endDate) || null;
			return voucher;
		});
		return vouchers;
	}

	private mapEquipmentPrices(equipment: Equipment, paramEquip: Object): void {
		equipment.routerPrice = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.routerPrice) || null;
		equipment.router6Price = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.router6Price) || null;
		equipment.routerDecoPrice = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.routerDecoPrice) || null;
		equipment.routerDeco2Price = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.routerDeco2Price) || null;
		equipment.decoPrice = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.decoPrice) || null;
		equipment.decoAutoPrice = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.decoAutoPrice) || null;
		equipment.deco2Price = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.deco2Price) || null;
		equipment.deco2AutoPrice = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.deco2AutoPrice) || null;
		equipment.decoQuoteAmount = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.decoQuoteAmount) || null;
	}

	private mapEquipmentChangeIDs(equipment: Equipment, paramEquip: Object): void {
		equipment.changeRouterID = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.changeRouterID) || null;
		equipment.changeRouter6ID = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.changeRouter6ID) || null;
		equipment.changeRouterDecoID = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.changeRouterDecoID) || null;
		equipment.changeDecoID = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.changeDecoID) || null;
		equipment.changeDecoAutoID = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.changeDecoAutoID) || null;
		equipment.changeRouterDeco2ID = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.changeRouterDeco2ID) || null;
		equipment.changeDeco2ID = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.changeDeco2ID) || null;
		equipment.changeDeco2AutoID = JsonQuery.value(paramEquip, JSON_PATHS.Equipment.changeDeco2AutoID) || null;
	}
}
