import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { config } from '../../../../config/pages-config';
import { ERRORCODES, VERIFYEMAIL, warningImg } from '../../../shared/constants/defines';
import { PaymentErrorCategory } from '../../../shared/enums/payment-error-category.enum';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentErrorDefault } from './payment-error-default';
import { PaymentErrorInterface } from './payment-error-interface';
import { PaymentErrorCategoryInWCS } from './../../../shared/enums/payment-error-category-inWCS.enum';
import { PaymentPages } from './../../../shared/enums/payment-pages.enum';
import { PaymentError } from './../../../models/payment';

export class PaymentErrorPrePaidLogin extends PaymentErrorDefault implements PaymentErrorInterface {
	constructor(
		public paymentNavigationService: PaymentNavigationService,
		public translate: TranslateService,
		public router: Router
	) {
		super(paymentNavigationService, translate, router);
	}

	/**
	 * function to fill error overlay with it's proper
	 * wcs values and btn actions
	 */
	fillErrorOverlay(errorCategory: string): PaymentError {
		this.displayedErrorCategory = Object.keys(PaymentErrorCategory)
			.map((key) => PaymentErrorCategory[key])
			.find((value) => value === errorCategory);
		if (this.displayedErrorCategory) {
			const currentError: PaymentError = new PaymentError();
			const url: string = `${this.paymentNavigationService.getWcsPath(PaymentPages.ko)}.${
				PaymentErrorCategoryInWCS[errorCategory]
			}`;

			this.translate.get(url).subscribe((data) => {
				this.currentErrorTitle = data.title;
				currentError.title = data.subtitle;
				currentError.desc = data.description;
				currentError.imgUrl = data.icon;
				currentError.lottieUrl = warningImg;
				currentError.firstBtnText = data.button1;
				currentError.secondBtnText = data.button2;
				currentError.supportPhoneNumber = data.supportPhoneNumber;
			});
			this.paymentNavigationService.supportedPhoneNumber = currentError.supportPhoneNumber ?? '';
			currentError.firstBtnAction = this.getFirstBtnAction(errorCategory);
			currentError.secondBtnAction = this.getSecondBtnAction();
			return currentError;
		} else {
			return this.fillErrorOverlay(this.defaultErrorCategory);
		}
	}
	/**
	 * @param ecode error code string
	 * functon to map error code to error category
	 */
	mapErrorCode(ecode: string): PaymentErrorCategory {
		let errorCategory: PaymentErrorCategory;
		switch (ecode) {
			case ERRORCODES.NOT_A_PREPAID_SERVICE:
			case ERRORCODES.PENDING_MSISDN:
			case ERRORCODES.PAYMENT_INACTIVE_MSISDN:
				errorCategory = PaymentErrorCategory.Notactive;
				break;
			case ERRORCODES.PREPAID_SERVICE_HAS_PENDING_ORDERS:
				errorCategory = PaymentErrorCategory.TopupInProgress;
				break;
			case ERRORCODES.REPLICA_MODE_ERROR:
				errorCategory = PaymentErrorCategory.ReplicaMode;
				break;
			case ERRORCODES.INVALID_PROMO_CODE:
				errorCategory = PaymentErrorCategory.invalidPromoCode;
				break;
			case ERRORCODES.INVALID_RECHARGE_AMOUNT:
				errorCategory = PaymentErrorCategory.validPromoButInvalidRechargeAmount;
				break;
			case ERRORCODES.PROMOTION_NOT_APPLIED:
				errorCategory = PaymentErrorCategory.validPromoCodeButPromotionNotApplied;
				break;
			case VERIFYEMAIL.errorAppUnavilability:
				throw new Error(ecode);
			default:
				errorCategory = this.defaultErrorCategory;
				break;
		}
		return errorCategory;
	}

	getFirstBtnActonSwitchCases(category: string): Function {
		let btnAction: Function;
		switch (category) {
			case PaymentErrorCategory.invalidCreditCard:
			case PaymentErrorCategory.invalidCreditCardInfo:
			case PaymentErrorCategory.CreditCardTypeNotSupported:
				btnAction = this.restartFromSecondStepJourney();
				break;
			case PaymentErrorCategory.validPromoCodeButPromotionNotApplied:
				btnAction = this.callSupportedNumber();
				break;
			default:
				btnAction = this.close();
		}
		return btnAction;
	}

	getFirstBtnAction(category: string): Function {
		let btnAction: Function;
		switch (category) {
			case PaymentErrorCategory.PaymentApiFails:
			case PaymentErrorCategory.general:
			case PaymentErrorCategory.Notactive:
			case PaymentErrorCategory.Notprepaid:
			case PaymentErrorCategory.NotvodafoneNumber:
			case PaymentErrorCategory.TopupInProgress:
			case PaymentErrorCategory.TimeOut:
			case PaymentErrorCategory.mortiroloKO108:
			case PaymentErrorCategory.invalidPromoCode:
			case PaymentErrorCategory.validPromoButInvalidRechargeAmount:
				btnAction = this.restartJourney();
				break;
			default:
				btnAction = this.getFirstBtnActonSwitchCases(category);
				break;
		}
		return btnAction;
	}
	getSecondBtnAction(): () => void {
		return this.paymentNavigationService.closePayment.bind(this.paymentNavigationService);
	}
	restartJourney(): () => void {
		return this.paymentNavigationService.restartPaymentJourney.bind(this.paymentNavigationService);
	}
	restartFromSecondStepJourney(): () => void {
		return this.paymentNavigationService.restartFromIframePaymentJourney.bind(this.paymentNavigationService);
	}
	callSupportedNumber(): () => void {
		return this.paymentNavigationService.callSupportedNumber.bind(this.paymentNavigationService);
	}
	/**
	 * the default implmentaion of the second btn in error payment screen
	 */
	close(): () => {} {
		return this.paymentNavigationService.closePayment.bind(this.paymentNavigationService);
	}
	handleExceptionalEcode(ecode: string): boolean {
		const handled: boolean = ecode === VERIFYEMAIL.errorAppUnavilability;
		if (handled) {
			this.paymentNavigationService.closePayment();
			this.router.navigate([config.dashboard.route]);
		}
		return handled;
	}

	/**
	 * action for x btn click on overlay
	 */
	closeBtnAction(): void {
		this.paymentNavigationService.closePayment();
	}
}
