<div class="save-credit-card-entry-point">
	<div class="save-credit-card-entry-point__message">
		<div class="save-credit-card-entry-point__img">
			<img
				[src]="'v10.payment.messagesList.saveCreditCard.es.entryScreen.icon' | imgFullPath"
				alt=""
				id="PaymentSaveCreditCardIcon"
			/>
		</div>
		<p class="save-credit-card-entry-point__subtitle" id="PaymentSaveCreditCardSubTitle">
			{{ 'v10.payment.messagesList.saveCreditCard.es.entryScreen.subtitle' | translate }}
		</p>
		<p
			class="save-credit-card-entry-point__description"
			id="PaymentSaveCreditCardDescription"
			[innerHTML]="'v10.payment.messagesList.saveCreditCard.es.entryScreen.description' | translate"
		></p>
	</div>
	<div class="save-credit-card-entry-point__btns">
		<mva10-c-button
			(click)="navigateToIframe()"
			[body]="'v10.payment.messagesList.saveCreditCard.es.entryScreen.button1' | translate"
			[styleClass]="'mva10-c-button--primary'"
			[name]="''"
			id="PaymentSaveCreditCardButton"
		>
		</mva10-c-button>
	</div>
</div>
