import { TranslateService } from '@ngx-translate/core';
import { CustomStyle, PaymentFullTrayModel } from '../../../models/payment';
import {
	increaseHeightCustomStyle,
	overlayHeadercustomStyle,
	overlayHeaderSelector,
	overlayMainsectionGrayBackGroundOnMobile,
	overlayMainsectionSelector,
	overlayTitlecustomStyle,
	overlayTitleSelector,
	removePaddingCustomStyle,
	removePaddingSelector,
	removeTopPaddingCustomStyle,
	VFExternalBuisnessWeb,
} from '../../../shared/constants/defines';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentWalletService } from '../../../shared/services/payment-wallet.service';
import { DelegateCreationService } from '../delegate-creation.service';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentTrayConfigInterface } from './payment-tray-config-interface';
import { PaymentTrayConfigPostPaid } from './payment-tray-config-postPaid';

export class PaymentTrayConfigAnonymousPostPaid
	extends PaymentTrayConfigPostPaid
	implements PaymentTrayConfigInterface
{
	// this component is treated like an ovrelay we remove tem from history after navigating away from them
	// not to show them to the user accidentaly when he navigate back
	compnentToBeRemovedFromHistoryAfternavigation: PaymentPages[] = [
		PaymentPages.cancel,
		PaymentPages.loading,
		PaymentPages.deleteConfiramtion,
	];
	/** variable to be used for getting a tray config method based on component name */
	trayConfigurations: any = {
		[PaymentPages.thirdPartyContainer]: this.getIframeCompTrayConfig.bind(this),
		[PaymentPages.ok]: this.getOkCompTrayConfig.bind(this),
		[PaymentPages.ko]: this.getKoCompTrayConfig.bind(this),
		[PaymentPages.cancel]: this.getCancelCompTrayConfig.bind(this),
		[PaymentPages.wallet]: this.getWalletCompTrayConfig.bind(this),
		[PaymentPages.loading]: this.getLoadingCompTrayConfig.bind(this),
		[PaymentPages.ticket]: this.getTicketCompTrayConfig.bind(this),
		[PaymentPages.deleteConfiramtion]: this.getDeleteCardCompTrayConfig.bind(this),
		[PaymentPages.dept]: this.getDeptCompTrayConfig.bind(this),
		[PaymentPages.partialDept]: this.getDeptPartialCompTrayConfig.bind(this),
		[PaymentPages.partialCashDesk]: this.getCashDeskPartialCompTrayConfig.bind(this),
		[PaymentPages.inadvance]: this.getInadvanceCompTrayConfig.bind(this),
		[PaymentPages.cashDesk]: this.getCashDeskCompTrayConfig.bind(this),
		[PaymentPages.otherPaymentMethods]: this.getOtherPaymentMethodsCompTrayConfig.bind(this),
		[PaymentPages.paymentAuthntication]: this.getAnonymousCompTrayConfig.bind(this),
		[PaymentPages.paymentAnonymousBilling]: this.getAnonymousBillingCompTrayConfig.bind(this),
		[PaymentPages.paymentOtp]: this.getPaymentOtpCompTrayConfig.bind(this),
		[PaymentPages.paymentDisclaimer]: this.getPaymentDisclaimerCompTrayConfig.bind(this),
		[PaymentPages.PaymentClarifyUser]: this.getPaymentClarifyUserCompTrayConfig.bind(this),
	};
	constructor(
		public paymentNavService: PaymentNavigationService,
		public delegateService: DelegateCreationService,
		public translate: TranslateService,
		public paymentWalletService: PaymentWalletService
	) {
		super(paymentNavService, delegateService, translate, paymentWalletService);
	}
	/**
	 * @param compName the page we need it's tray configuration
	 * function should return PaymentFullTrayModel containing
	 * the proper tray configuration
	 */
	getCurrentTrayConfig(compName: PaymentPages): PaymentFullTrayModel {
		let config: PaymentFullTrayModel;
		/** if compName is exist in trayConfigurations then invoke its method to get tray configuration */
		if (this.trayConfigurations[compName]) {
			config = this.trayConfigurations[compName]();
		} else {
			config = this.getIframeCompTrayConfig();
		}
		return config;
	}
	/**
	 * function return PaymentFullTrayModel
	 * the configuration of the anonymous payment screen configuration
	 */
	getAnonymousCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate.get('v10.payment.itemsList.anonymousPayment.es.anonymousAuthntication.title').subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data;
		});
		paymentFullTrayModel.MVA10Config.showVodafoneLogo = true;
		paymentFullTrayModel.paymentCustomConfig.customStyles.push(
			new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle),
			new CustomStyle(removePaddingSelector, removePaddingCustomStyle),
			new CustomStyle(removePaddingSelector, increaseHeightCustomStyle)
		);
		paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = false;
		return paymentFullTrayModel;
	}

	/* function return PaymentFullTrayModel
	 * the configuration of the dept payment start screen configuration
	 */
	getDeptCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate.get('payment.messagesList.pendingPayment.title').subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data;
		});
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.paymentNavService.showCancelPayment();
		};
		paymentFullTrayModel.MVA10Config.showVodafoneLogo = true;
		paymentFullTrayModel.MVA10Config.showBackButton = false;
		paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = false;
		paymentFullTrayModel.paymentCustomConfig.customStyles.push(
			new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle)
		);
		return paymentFullTrayModel;
	}
	/**
	 * function return PaymentFullTrayModel
	 * the configuration of the inadvance payment start screen configuration
	 */
	getInadvanceCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate.get('payment.messagesList.paymentInAdvance.title').subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data;
		});
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.paymentNavService.showCancelPayment();
		};
		paymentFullTrayModel.MVA10Config.showVodafoneLogo = true;
		paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = false;
		paymentFullTrayModel.paymentCustomConfig.customStyles.push(
			new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle)
		);
		return paymentFullTrayModel;
	}

	/**
	 * function return PaymentFullTrayModel
	 * the configuration of the anonymous billing payment screen configuration
	 */
	getAnonymousBillingCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate.get('v10.payment.itemsList.anonymousPayment.es.anonymousAuthntication.title').subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data;
		});
		paymentFullTrayModel.MVA10Config.showVodafoneLogo = true;
		paymentFullTrayModel.paymentCustomConfig.customStyles.push(
			new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle),
			new CustomStyle(removePaddingSelector, removePaddingCustomStyle),
			new CustomStyle(removePaddingSelector, removeTopPaddingCustomStyle),
			new CustomStyle(removePaddingSelector, increaseHeightCustomStyle),
			new CustomStyle(overlayHeaderSelector, overlayHeadercustomStyle),
			new CustomStyle(overlayMainsectionSelector, overlayMainsectionGrayBackGroundOnMobile)
		);
		return paymentFullTrayModel;
	}
	/**
	 * function return PaymentFullTrayModel
	 * the configuration of the anonymous payment screen configuration
	 */
	getPaymentOtpCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate.get('v10.payment.itemsList.anonymousPayment.es.otp.title').subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data;
		});
		paymentFullTrayModel.MVA10Config.showVodafoneLogo = true;
		paymentFullTrayModel.paymentCustomConfig.customStyles.push(
			new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle),
			new CustomStyle(removePaddingSelector, removePaddingCustomStyle),
			new CustomStyle(removePaddingSelector, increaseHeightCustomStyle)
		);
		return paymentFullTrayModel;
	}

	/**
	 * function return PaymentFullTrayModel
	 * the configuration of the anonymous payment screen configuration
	 */
	getPaymentDisclaimerCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate
			.get('v10.payment.messagesList.anonymousPayment.es.accountNumberDisclaimer.title')
			.subscribe((data) => {
				paymentFullTrayModel.MVA10Config.title = data;
			});
		paymentFullTrayModel.MVA10Config.showVodafoneLogo = true;
		paymentFullTrayModel.paymentCustomConfig.customStyles.push(
			new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle),
			new CustomStyle(removePaddingSelector, removePaddingCustomStyle),
			new CustomStyle(removePaddingSelector, increaseHeightCustomStyle)
		);
		return paymentFullTrayModel;
	}

	/**
	 * function return PaymentFullTrayModel
	 * the configuration of the anonymous payment screen configuration
	 */
	getPaymentClarifyUserCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		paymentFullTrayModel.MVA10Config.showVodafoneLogo = true;
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.paymentNavService.openExternalVFWebLink(VFExternalBuisnessWeb);
		};
		return paymentFullTrayModel;
	}
}
