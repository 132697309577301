import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Breakpoints } from '@mva10/mva10-angular';

@Injectable()
export class ResizeService {
	breakpoint: Breakpoints;

	private resizeSubject: Subject<Breakpoints>;

	get onResize$(): Observable<Breakpoints> {
		return this.resizeSubject.asObservable().pipe(distinctUntilChanged());
	}

	isResponsive(): boolean {
		return this.breakpoint !== Breakpoints.MOBILE;
	}

	constructor() {
		this.resizeSubject = new Subject();
	}

	onResize(size: Breakpoints) {
		this.breakpoint = size;
		this.resizeSubject.next(size);
	}
}
