import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PurchaseProductsData } from './purchase-products-data';
import * as constants from '../shared/constants/defines';
import * as JsonQuery from 'jsonpath/jsonpath';
import { Coute, Amount, AdditionalCharges, AggregatedAmount, GetCoutesResponse } from '../models/coute.model';
import { BillType, BillChargesType } from '../shared/enums/bill-type.enum';
import { SVAs, SVAAmount, Discount, SBA, PackagesDesc } from '../models/SVAS.model';
import { SubscriptionService } from '../core/services/subscription.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';
import { ProductType } from '../shared/enums/productType.enum';
import { BundleStatus } from '../shared/enums/bundleStatus.enum';
import moment from 'moment';
import { Observable } from 'rxjs';

@Injectable()
export class PurchaseProductsService {
	public coutes: Array<Coute>;
	public aggregatedAmount = new AggregatedAmount();
	public svas: Array<SVAs>;
	/* Cambio PO */
	public svas2: Array<SVAs>;
	/* Fin Cambio PO */
	public selectedService: SVAs;
	public svaProductType: SVAs;
	navigate: boolean = false;
	showLoader: boolean = false;
	constructor(
		private purchaseProductsData: PurchaseProductsData,
		private subscriptionService: SubscriptionService,
		private translate: TranslateService,
		private appTheme: AppService
	) {}

	GetCoutes(customerAccountId: string, isFinancing?: boolean): Observable<Array<Coute>> {
		this.coutes = new Array();
		return this.purchaseProductsData.GetCoutes(customerAccountId, isFinancing).pipe(
			map((res: GetCoutesResponse) => {
				const items: Coute[] = JsonQuery.value(res, constants.JSON_PATHS.PurchaseProductsCoutes.items);
				items.map((item) => {
					this.coutes.push(this.mapCoutesData(item));
				});
				this.coutes = this.generateJsonCoutes(this.coutes);
				const aggregatedAmount: AggregatedAmount = JsonQuery.value(
					res,
					constants.JSON_PATHS.PurchaseProductsCoutes.aggregatedAmount
				);
				this.aggregatedAmount = this.mapAggregatedAmount(aggregatedAmount);
				return this.coutes;
			})
		);
	}

	/**map billing accounts data */
	mapCoutesData(item: Coute): Coute {
		const coute = new Coute();
		coute.type = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsCoutes.type) || null;
		coute.name = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsCoutes.name) || null;
		coute.desc = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsCoutes.desc) || null;
		coute.isBundle = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsCoutes.isBundle) || null;
		const amount = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsCoutes.amount) || null;
		if (amount) {
			coute.amount = new Amount();
			coute.amount.currency = JsonQuery.value(amount, constants.JSON_PATHS.PurchaseProductsCoutes.currency) || null;
			coute.amount.taxesCode = JsonQuery.value(amount, constants.JSON_PATHS.PurchaseProductsCoutes.taxesCode) || null;
			coute.amount.netAmountDue =
				JsonQuery.value(amount, constants.JSON_PATHS.PurchaseProductsCoutes.netAmountDue) || null;
			coute.amount.grossAmountDue =
				JsonQuery.value(amount, constants.JSON_PATHS.PurchaseProductsCoutes.grossAmountDue) || null;
		}
		coute.additionalCharges = new Array<AdditionalCharges>();
		const additionalCharges: AdditionalCharges[] =
			JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsCoutes.additionalCharges) || null;
		if (additionalCharges) {
			additionalCharges.map((charge) => {
				coute.additionalCharges.push(this.mapAdditionalCharges(charge));
			});
		}
		return coute;
	}

	mapAdditionalCharges(item: AdditionalCharges): AdditionalCharges {
		const additionalCharges = new AdditionalCharges();

		additionalCharges.type = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsCoutes.type) || null;
		additionalCharges.desc = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsCoutes.desc) || null;
		additionalCharges.terminationDate =
			JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsCoutes.terminationDate) || null;
		const amountAdditionalCharges = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsCoutes.amount) || null;
		if (amountAdditionalCharges) {
			additionalCharges.amount = new Amount();
			additionalCharges.amount.currency =
				JsonQuery.value(amountAdditionalCharges, constants.JSON_PATHS.PurchaseProductsCoutes.currency) || null;
			additionalCharges.amount.taxesCode =
				JsonQuery.value(amountAdditionalCharges, constants.JSON_PATHS.PurchaseProductsCoutes.taxesCode) || null;
			additionalCharges.amount.netAmountDue =
				JsonQuery.value(amountAdditionalCharges, constants.JSON_PATHS.PurchaseProductsCoutes.netAmountDue) || null;
			additionalCharges.amount.grossAmountDue =
				JsonQuery.value(amountAdditionalCharges, constants.JSON_PATHS.PurchaseProductsCoutes.grossAmountDue) || null;
		}

		return additionalCharges;
	}

	mapAggregatedAmount(item: AggregatedAmount): AggregatedAmount {
		const aggregatedAmount = new AggregatedAmount();

		aggregatedAmount.type = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsCoutes.type) || null;
		aggregatedAmount.taxesCode = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsCoutes.taxesCode) || null;
		aggregatedAmount.currency = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsCoutes.currency) || null;
		aggregatedAmount.grossAmountDue =
			JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsCoutes.grossAmountDue) || null;
		aggregatedAmount.netAmountDue =
			JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsCoutes.netAmountDue) || null;
		return aggregatedAmount;
	}

	generateJsonCoutes(coutes: Coute[]): Coute[] {
		const coutesList: Array<Coute> = [];
		const otherDiscount: Array<Coute> = [];
		coutes.forEach((item) => {
			const auxCoute: Coute = {};
			auxCoute['name'] = item.name;
			auxCoute['type'] = item.type;
			auxCoute['amounts'] = [];
			if ((!item.isBundle || item.type === BillType.bundle) && item.amount) {
				auxCoute['amounts'].push({
					desc: item.desc || '',
					netAmountDue: item.amount.netAmountDue,
					grossAmountDue: item.amount.grossAmountDue,
					currency: item.amount.currency,
				});
			}

			item.additionalCharges.forEach((charges) => {
				auxCoute['amounts'].push({
					desc: charges.desc || '',
					netAmountDue: charges.amount.netAmountDue,
					grossAmountDue: charges.amount.grossAmountDue,
					currency: charges.amount.currency,
					endDate:
						charges.type === BillChargesType.discount ? this.parseTerminationDate(charges.terminationDate) : null,
				});
			});

			if (auxCoute['amounts'].length > 0) {
				if (item.type === BillType.other) {
					otherDiscount.push(auxCoute);
				} else {
					coutesList.push(auxCoute);
				}
			}
		});

		if (otherDiscount.length > 0) {
			const auxCoute: Coute = {};
			auxCoute['name'] = otherDiscount[0].name;
			auxCoute['type'] = otherDiscount[0].type;
			auxCoute['amounts'] = [];
			otherDiscount.forEach((discount) => {
				discount.amounts.forEach((amount) => {
					auxCoute['amounts'].push(amount);
				});
			});
			coutesList.push(auxCoute);
		}

		return coutesList;
	}

	parseTerminationDate(terminationDate: AdditionalCharges['terminationDate']): string {
		if (!terminationDate) {
			return null;
		}
		const endDate: moment.Moment = moment(terminationDate * 1000);
		if (
			endDate.get('year') >= constants.maxYearToConsiderPermanentDiscount ||
			endDate.get('year') <= constants.minYearToConsiderPermanentDiscount
		) {
			return null;
		}
		return endDate.format('DD/MM/YYYY');
	}

	getSVAs() {
		this.svas = new Array<SVAs>();
		return this.purchaseProductsData
			.GetSVAs(this.subscriptionService.customerData ? this.subscriptionService.customerData.customerAccountsId : null)
			.pipe(
				map((res) => {
					const items = JsonQuery.value(res, constants.JSON_PATHS.PurchaseProductsSVAs.items);
					items.map((item) => {
						this.svas.push(this.mapSVAsData(item));
					});
				})
			);
	}
	getSVAsByProductType(productType: ProductType) {
		this.svaProductType = new SVAs();
		return this.purchaseProductsData
			.GetSVAsByType(
				this.subscriptionService.customerData ? this.subscriptionService.customerData.customerAccountsId : null,
				productType
			)
			.pipe(
				map((res) => {
					const item = JsonQuery.value(res, constants.JSON_PATHS.PurchaseProductsSVAs.items)[0];
					this.svaProductType = this.mapSVAsData(item);
					this.svaProductType.sba.forEach((sba: SBA) => {
						if (!sba.subscription.status) {
							sba.subscription.status = BundleStatus.Active;
						}
					});
				})
			);
	}
	/* Cambio PO */
	getSolucionProfesional() {
		this.svas2 = new Array<SVAs>();
		return this.purchaseProductsData
			.GetSVAs(this.subscriptionService.customerData ? this.subscriptionService.customerData.customerAccountsId : null)
			.pipe(
				map((res) => {
					const solucionProfesional = JsonQuery.value(
						res,
						constants.JSON_PATHS.PurchaseProductsSVAs.solucionProfesional
					);
					solucionProfesional.map((item) => {
						this.svas2.push(this.mapSVAsData(item));
					});
				})
			);
	}
	/* Fin Cambio PO */
	mapSVAsData(item) {
		const sva = new SVAs();
		sva.code = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsSVAs.code) || null;
		sva.name = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsSVAs.name) || null;
		sva.category = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsSVAs.category) || null;
		sva.resumeIcon = this.getIcon(JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsSVAs.resumeIcon)) || null;
		sva.detailIcon = this.getIcon(JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsSVAs.detailIcon)) || null;
		sva.info = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsSVAs.info) || null;
		sva.longDesc = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsSVAs.longDesc) || null;
		sva.shortDesc = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsSVAs.shortDesc) || null;
		sva.additionalInfo = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsSVAs.additionalInfo) || null;
		sva.deviceName = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsSVAs.deviceName) || null;
		sva.type = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsSVAs.type) || null;
		const amount = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsSVAs.amount) || null;
		const packagesDesc = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsSVAs.packagesDesc) || null;

		if (packagesDesc) {
			sva.packagesDesc = new Array<PackagesDesc>();
			sva.packagesDesc = packagesDesc;
		}
		sva.amount = new SVAAmount();
		sva.amount = this.fillAmount(amount);
		const discount = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsSVAs.discount) || null;
		if (discount) {
			sva.discount = new Discount();
			sva.discount.amount = new SVAAmount();
			const discountAmount = JsonQuery.value(discount, constants.JSON_PATHS.PurchaseProductsSVAs.amount) || null;
			sva.discount.amount = this.fillAmount(discountAmount);
			sva.discount.terminationDate =
				JsonQuery.value(discount, constants.JSON_PATHS.PurchaseProductsSVAs.terminationDate) || null;
		}
		sva.sba = new Array<SBA>();
		sva.sba = (JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsSVAs.sba) as Array<SBA>) || null;

		sva.status = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsSVAs.status) || null;
		sva.typeOnePlus = JsonQuery.value(item, constants.JSON_PATHS.PurchaseProductsSVAs.typeOnePlus) || null;
		return sva;
	}
	fillAmount(data) {
		const amount = new SVAAmount();
		if (data) {
			amount.currency = JsonQuery.value(data, constants.JSON_PATHS.PurchaseProductsSVAs.currency) || null;
			amount.taxesCode = JsonQuery.value(data, constants.JSON_PATHS.PurchaseProductsSVAs.taxesCode) || null;
			amount.netAmountDue = JsonQuery.value(data, constants.JSON_PATHS.PurchaseProductsSVAs.netAmountDue) || 0;
			amount.grossAmountDue = JsonQuery.value(data, constants.JSON_PATHS.PurchaseProductsSVAs.grossAmountDue) || 0;
			amount.taxesDue = JsonQuery.value(data, constants.JSON_PATHS.PurchaseProductsSVAs.taxesDue) || null;
			amount.amountCredited = JsonQuery.value(data, constants.JSON_PATHS.PurchaseProductsSVAs.amountCredited) || null;
			amount.chargedFor = JsonQuery.value(data, constants.JSON_PATHS.PurchaseProductsSVAs.chargedFor) || null;
			amount.type = new Array<string>();
			amount.type = JsonQuery.value(data, constants.JSON_PATHS.PurchaseProductsSVAs.type) || null;
		}
		return amount;
	}

	getIcon(path) {
		let image;
		this.translate.get(path).subscribe((data) => {
			if (path === data) {
				image = 'https://assets-es-dev.dxldev.local.vodafone.es/assets/images/icon-generic-sva.png';
			} else {
				image = this.appTheme.getImgFullPath(data);
			}
		});
		return image;
	}
}
