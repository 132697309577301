export class DeliveryType {
	idType?: string;
	cdType?: string;
	price?: number | string;
	priceTax?: number;
	itMarcadoDefault?: boolean;
	nmOrden?: number;
	title?: string;
	description?: string;
	selected?: boolean;
	xOpcEntrega?: string;
	icon?: string;
	cp?: string;
}
