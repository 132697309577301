import { Component } from '@angular/core';
import { LeftMenuWs10Service } from '../../../mva10/shared/services/left-menu-ws10.service';
import { TrayConfigurationService } from '../../../mva10/dashboard/services/tray-configuration.service';

@Component({
	selector: 'sp-submenu-shop-selector',
	templateUrl: './submenu-shop-selector.component.html',
	styleUrls: ['./submenu-shop-selector.component.scss'],
})
export class SubmenuShopSelectorComponent {
	constructor(
		private leftMenuService: LeftMenuWs10Service,
		public trayConfigurationService: TrayConfigurationService
	) {}

	clickOnShopSelectorCard(toMarketplace: boolean): void {
		this.leftMenuService.closeSubmenu();
		if (toMarketplace) {
			this.trayConfigurationService.navigateToMktp();
		} else {
			this.trayConfigurationService.navigateToShop();
		}
	}
}
