import { AfterViewInit, HostListener, Component, OnInit } from '@angular/core';
import { Breakpoints } from '@mva10/mva10-angular';
import { ResizeService } from '../../services/resize.service';

@Component({
	selector: 'sp-resize-listener',
	templateUrl: 'resize-listener.component.html',
})
export class ResizeListenerComponent implements AfterViewInit, OnInit {
	currentSize: Breakpoints;

	constructor(private resizeSvc: ResizeService) {}

	@HostListener('window:resize', ['$event'])
	private onResize(event) {
		this.detectScreenSize();
	}

	ngAfterViewInit() {
		this.detectScreenSize();
	}

	ngOnInit() {
		this.detectScreenSize();
	}

	private detectScreenSize() {
		let breakpoint = Breakpoints.MOBILE;
		if (window.innerWidth >= Breakpoints.DESKTOP) {
			breakpoint = Breakpoints.DESKTOP;
		} else if (window.innerWidth >= Breakpoints.TABLET) {
			breakpoint = Breakpoints.TABLET;
		}

		this.currentSize = breakpoint;
		this.resizeSvc.onResize(breakpoint);
	}
}
