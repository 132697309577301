import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServiceBalanceWs10Service } from 'src/app/shared/services/service-balance-ws10.service';
import { ProductsAndServicesService } from '../../products-and-services.service';
import { ResizeService } from 'src/app/mva10/shared/services/resize.service';
import { Breakpoints } from '@mva10/mva10-angular';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { MsisdnToShowPipe } from 'src/app/shared/pipes/msisdn-to-show.pipe';

@Component({
	selector: 'sp-tariff-change-header',
	templateUrl: './tariff-change-header.component.html',
	styleUrls: ['./tariff-change-header.component.scss'],
	providers: [MsisdnToShowPipe],
})
export class TariffChangeHeaderComponent implements OnInit {
	constructor(
		public translate: TranslateService,
		public serviceBalanceService: ServiceBalanceWs10Service,
		private productsAndServicesService: ProductsAndServicesService,
		private resizeService: ResizeService,
		public msisdnToShowPipe: MsisdnToShowPipe,
		public subscriptionService: SubscriptionService
	) {}

	ngOnInit(): void {
		this.productsAndServicesService.noPadding = this.resizeService.breakpoint < Breakpoints.TABLET ? false : true;
	}
}
