<div class="form-group">
	<label class="switch">
		<input
			type="checkbox"
			[disabled]="disable"
			[id]="id"
			type="checkbox"
			[checked]="check"
			[name]="name"
			(click)="toggle()"
		/>
		<span class="slider round" [ngClass]="{ 'animate-after-load': stopToggleAnimation }"> {{ label }} </span>
	</label>
</div>
