export enum Engagement {
	prepaid = 'prepaid',
	postpaidRS = 'postpaidRS',
	postpaidMICRO = 'postpaidRSMICRO',
	version = '6520',
	screenCode = 'LANDINGAE',
	noDebt = 'EP_LANDINGAE',
	progressBarIit = 30,
	progressBarFirsStep = 80,
	progressBarLastStep = 100,
	progressBarLow = 10,
	progressBarFast = 100,
	visitorInstance = '24A034B852785AB90A490D44',
	true = 'true',
}
