<div class="ppayment_full_dynamic_Tray_section">
	<vfac-overlay
		[show]="TrayControl.showTray"
		[loaded]="true"
		(closed)="closeOverlay()"
		[overlayData]="TrayControl.currentTrayConfig.MVA10Config"
		#overlay
	>
		<div
			*ngIf="TrayControl.showBackBtn || TrayControl?.nextTrayConfig?.MVA10Config?.showVodafoneLogo"
			class="backdrop-header"
			header
		>
			<mva10-c-icon [size]="leftIconSize" [title]="'iconTitle'" [iconId]="leftIcon" (click)="back()"> </mva10-c-icon>
		</div>
		<div
			body
			[ngClass]="{
				'forward-transition-tray': showForwardTransition,
				'backward-transition-tray': showBackwardTransition,
				'fade-out-tray': fadeOut
			}"
		>
			<div
				class="unset-height-responsive"
				[ngStyle]="{ display: currentUsedRef === 2 ? 'none' : 'inherit', height: pageHeight ? pageHeight : 'inherit' }"
			>
				<div #dynamicCompRef_1></div>
			</div>
			<div
				class="unset-height-responsive"
				[ngStyle]="{ display: currentUsedRef === 1 ? 'none' : 'inherit', height: pageHeight ? pageHeight : 'inherit' }"
			>
				<div #dynamicCompRef_2></div>
			</div>
		</div>
	</vfac-overlay>
</div>
