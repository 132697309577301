/**
 * Main state of an OL order history
 */
export enum OLStep {
	INIT = 'init',
	PREPARING = 'preparing',
	READY = 'ready',
	SENT = 'sent',
	DELIVERED = 'delivered',
	UNKNOWN = 'unknown',
}

/**
 * Sub-state (below OLStep) of an OL order history
 */
export enum OLStatus {
	INIT = 'init',
	PREPARING = 'preparing',
	READY = 'ready',
	SENT = 'sent',
	PICK_UP = 'pick_up',
	NOT_DELIVERED = 'not_delivered',
	LAST_TRY = 'last_try',
	DELIVERED = 'delivered',
	CANCELLED = 'cancelled',
	INCIDENCE = 'incidence',
	EXPIRED = 'expired',
	UNKNOWN = 'unknown',
}
