<div class="delete__container">
	<div class="delete__cont">
		<div class="delete__icon">
			<img [src]="appService.getImgFullPath(paymentCancelService.deleteConfirmation.icon)" alt="delete-icon" />
		</div>
		<p
			class="delete__title"
			[innerHTML]="paymentCancelService.deleteConfirmation.desc?.replace('{0}', lastFourDigits)"
		></p>
		<p
			class="delete__second-title"
			[innerHTML]="paymentCancelService.deleteConfirmation.secondDesc?.replace('{0}', lastFourDigits)"
		></p>
	</div>
	<div class="delete__btns-cont">
		<div class="delete__first-btn">
			<mva10-c-button
				[body]="paymentCancelService.deleteConfirmation.firstBtnText"
				*ngIf="paymentCancelService.deleteConfirmation.firstBtnText"
				[styleClass]="'mva10-c-button--primary'"
				(click)="onFirstBtnAction()"
			>
			</mva10-c-button>
		</div>
		<div class="delete__second-btn">
			<mva10-c-button
				[body]="paymentCancelService.deleteConfirmation.secondBtnText"
				*ngIf="paymentCancelService.deleteConfirmation.secondBtnText"
				[styleClass]="'mva10-c-button--tertiary'"
				(click)="onSecondBtnAction()"
			>
			</mva10-c-button>
		</div>
	</div>
</div>
