<div class="pay-debt-other-methods">
	<div class="pay-debt-other-methods__content">
		<div class="mva10-o-layout">
			<div class="mva10-o-layout__item margin-bottom-16">
				<div>
					<div class="vfes-1/1" data-card="title" class="title">
						<p class="vfes-title--bold vfac-c-main-tile-simple__title">
							{{ 'billing.readyPayBill.fieldsList.payThroughBank.title' | translate }}
						</p>
					</div>
					<div>
						<p class="vfac-c-main-tile-description" [innerHtml]="payThroughBankBody"></p>
					</div>
				</div>
			</div>
		</div>

		<div class="mva10-o-layout card-container">
			<div class="mva10-o-layout__item">
				<div>
					<div class="vfes-1/1" data-card="title" class="title">
						<p class="vfes-title--bold vfac-c-main-tile-simple__title">
							{{ 'billing.readyPayBill.fieldsList.payThroughPost.title' | translate }}
						</p>
					</div>
					<div>
						<p
							class="vfac-c-main-tile-description"
							[innerHtml]="'billing.readyPayBill.fieldsList.payThroughPost.body' | translate"
						></p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="btn-container">
		<button (click)="closeTray()">
			{{ firstBtnDescription }}
		</button>
	</div>
</div>
