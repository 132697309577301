export enum MyOrdersEvents {
	loadOrders = 'load-pedidos',
	redirectTienda = 'tienda-online',
	webIkkiType = 'IkkiWeb',
	closeSign = 'close-firma',
	redirectContratos = 'contratos-documentacion',
	none = '',

	finishFirmaDigital = 'finish-firma-digital',
	signedFirmaDigital = 'signed-firma-digital',
	cancelFirmaDigital = 'cancel-firma-digital',
	retriesFirmaDigital = 'retries-firma-digital',
	/**
	 * Last FD event
	 */
	acceptFinishFirma = 'accept-finish-firma',
	/**
	 * Last VPT event
	 */
	finishFirmaVPT = 'finish-vpt',
	downloadPDF = 'download-pdf',

	openChat = 'open-chat',
}

export enum MyOrdersEventSignContractType {
	firma_digital = 'FD',
	portabilidad = 'VPT',
}

export enum MyOrdersEventAction {
	error = 'error',
}

export enum MyOrderEventChatId {
	default = 'default',
	autoi = 'autoi',
}
