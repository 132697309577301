<mva10-c-bottom-tray [show]="showBackdrop" [size]="4" [inverse]="false" [showCloseIcon]="false">
	<div class="backdropDL-content">
		<img
			id="backdropOfferNoAvailableImg"
			class="backdropDL-content__img"
			[src]="data.icon | imgFullPath"
			[alt]="'img'"
		/>
		<p id="backdropOfferNoAvailableTitle" class="backdropDL-content__title">{{ data.title }}</p>
		<p *ngIf="!!data.subtitle" id="backdropOfferNoAvailableSubtitle" class="backdropDL-content__subtitle">
			{{ data.subtitle }}
		</p>
		<mva10-c-bottom-tray-footer [appearance]="'button'" [inverse]="false" (mainButtonClick)="close(true)">
			<span id="backdropOfferNoAvailableTextButton" btf-main-button> {{ data.textButton }} </span>
		</mva10-c-bottom-tray-footer>
	</div>
</mva10-c-bottom-tray>
