<!-- Edit bank account Overlay -->
<vfac-overlay
	[overlayData]="overlayData"
	[show]="showEditOverlay"
	(closed)="closeOverlay()"
	[loaded]="true"
	[greyMode]="false"
	[page]="pageOverlay"
	(backPage)="back()"
>
	<!-- Bank Account Data Page -->
	<div body id="page1">
		<div class="edit-content">
			<div class="my-account-details-overlay-subtitle">
				{{ addEditBankAccountTitle }}
			</div>
			<div class="my-account-details-overlay-description mva10-u--footnote">
				{{ addEditBankAccountSubTitle }}
			</div>

			<form [formGroup]="overlayForm" class="my-account-details-overlay-form" name="editDataForm">
				<ng-container *ngFor="let subField of fieldData.inputsArray; let i = index">
					<ng-container>
						<p *ngIf="subField.inputId === fieldsIDs.IBAN" class="my-account-details-overlay-form__iban-description">
							{{ addEditBankAccountEditBankTitle }}
						</p>
						<mva10-c-text-field
							[idInput]="subField.inputId"
							ngDefaultControl
							[formControlName]="subField.inputId"
							[invalid]="true"
							[(ngModelInput)]="subField.inputValue"
							(input)="subField.inputId === fieldsIDs.IBAN ? validateIBAN(subField) : validateField(subField)"
							(ngModelInputChange)="subField.inputId === fieldsIDs.IBAN ? formatIBAN(subField) : null"
							[error]="!subField.isValid && subField.showError"
							[helperText]="!subField.isValid && subField.showError ? subField.errorText : ''"
							[textLabel]="subField.inputLabel"
							(mva10blur)="subField.inputId === fieldsIDs.IBAN ? validateIBAN(subField, true) : validateField(subField)"
						>
						</mva10-c-text-field>
					</ng-container>
				</ng-container>
			</form>
		</div>
		<div class="btn-container">
			<!-- using checkFormValidity method here to avoid infinite recursion in ts code -->
			<button
				type="button"
				[disabled]="!fieldData.allFieldsAreValid"
				class="button red overlay-button"
				[ngClass]="{ silver: !fieldData.allFieldsAreValid }"
				(click)="changePage(pageOverlay + 1)"
			>
				{{ addEditBankAccountCTA }}
			</button>
		</div>
	</div>

	<!-- Confirmation Page -->
	<div body id="page2">
		<div *ngIf="loadOverlayPages" class="confirmation-content">
			<div class="my-account-details-modal-img">
				<img
					class="my-account-details-modal-img-file"
					[src]="appService.getImgFullPath('v10.common.images.icon.tick' | translate)"
				/>
			</div>
			<p class="my-account-details-modal-title">
				{{ confirmationTitle }}
			</p>
			<p class="edit-subtible-first">
				<!-- name + firstName + (secondName if exists) -->
				{{
					fieldData.inputsArray[0]?.inputValue +
						' ' +
						fieldData.inputsArray[1]?.inputValue +
						(fieldData.inputsArray[2]?.inputValue ? ' ' + fieldData.inputsArray[2]?.inputValue : '')
				}}
			</p>
			<p class="address-title">
				{{ confirmationSubTitle }}
			</p>
			<p class="edit-subtible-second">
				<!-- IBAN -->
				{{ fieldData.inputsArray[3]?.inputValue }}
			</p>
		</div>
		<div *ngIf="loadOverlayPages" class="btns-container">
			<div class="my-account-details-modal-button-accept">
				<button type="button" class="button red overlay-button" (click)="updateBankAccount()">
					{{ confirmationSaveCTA }}
				</button>
			</div>
			<div class="my-account-details-modal-button-back">
				<button type="button" class="button overlay-button" (click)="changePage(pageOverlay - 1)">
					{{ confirmationEditAgainCTA }}
				</button>
			</div>
		</div>
	</div>

	<!-- OTP Page -->
	<div body id="page3">
		<div *ngIf="loadOverlayPages" class="modalOTPWrapper">
			<div class="my-account-details-modal-img">
				<img
					class="my-account-details-modal-img-file"
					[src]="appService.getImgFullPath('v10.common.images.icon.mobileOTP' | translate)"
				/>
			</div>
			<p class="my-account-details-modal-title">
				{{ otpTitle }}
			</p>
			<p class="modalOTPWrapper-subtitle">
				{{ otpSubTitle + ' ' + userMsisdn }}
			</p>
			<mva10-c-text-field
				[(ngModelInput)]="OTPKey"
				[textLabel]="otpTextFieldPlaceHolder"
				[ngClass]="{ hideXButton: !showXButton }"
				[error]="showOtpError && OTPKey && !validOtpKey(OTPKey)"
				[helperText]="showOtpError && OTPKey && !validOtpKey(OTPKey) ? otpInvalid : ''"
				[invalid]="true"
				(mva10focus)="showOtpError = false; showXButton = true"
				(mva10blur)="showOtpError = true; showXButton = false"
				[limit]="6"
			>
			</mva10-c-text-field>
		</div>
		<div *ngIf="loadOverlayPages" class="otp-btns-container">
			<div class="modalOTPWrapper-button">
				<button
					type="button"
					[disabled]="!OTPKey || !validOtpKey(OTPKey)"
					class="button red overlay-button"
					[ngClass]="{ silver: !OTPKey || !validOtpKey(OTPKey) }"
					(click)="patchNewBankAccountData()"
				>
					{{ otpCTA }}
				</button>
			</div>
			<div class="modalOTPWrapper-link">
				<a class="button-otp-again" (click)="reSendCode()">
					{{ otpResendCTA }}
				</a>
			</div>
		</div>
	</div>
</vfac-overlay>

<!-- OK Overlay -->
<vfac-overlay
	[overlayData]="{ title: ' ' }"
	[show]="showOkOverlay"
	(closed)="okayButtonClick()"
	[loaded]="true"
	[greyMode]="false"
>
	<div body>
		<div class="edit-ok-screen">
			<div class="edit-ok-content">
				<div class="my-account-details-modal-img">
					<img
						class="my-account-details-modal-img-file"
						[src]="appService.getImgFullPath('v10.common.images.icon.tick' | translate)"
					/>
				</div>
				<p class="my-account-details-modal-title">
					<!-- if operation is add the set cash desk text, otherwise in advance text -->
					{{ operation === 'add' ? okTitleCashDesk : okTitle }}
				</p>
				<p class="my-account-details-modal-subtitle">
					<!-- if operation is add the set cash desk text, otherwise in advance text -->
					{{ operation === 'add' ? okSubTitleCashDesk : okSubTitle }}
				</p>
			</div>
			<div class="edit-ok-button">
				<button type="button" class="button red overlay-button" (click)="okayButtonClick()">
					{{ okCTA }}
				</button>
			</div>
		</div>
	</div>
</vfac-overlay>

<!-- KO Parcial Overlay -->
<vfac-overlay
	[overlayData]="{ title: ' ' }"
	[show]="showKoOverlay"
	(closed)="closeOverlay()"
	[loaded]="true"
	[greyMode]="false"
>
	<div body>
		<div class="edit-ko-screen">
			<div class="edit-ko-content">
				<div class="my-account-details-modal-img">
					<img
						class="my-account-details-modal-img-file"
						[src]="appService.getImgFullPath('v10.common.images.icon.warning_grey' | translate)"
					/>
				</div>
				<p class="my-account-details-modal-title">
					{{ koTitle }}
				</p>
				<p class="edit-ko-subtitle-first">
					{{ koSubTitle1 }}
				</p>
				<p class="edit-ko-subtitle-second">
					{{ koSubTitle2 }}
				</p>
			</div>
			<div class="edit-ko-button">
				<button type="button" class="button red overlay-button" (click)="closeOverlay()">
					{{ koCTA }}
				</button>
			</div>
		</div>
	</div>
</vfac-overlay>
