import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { DecoGuideType, NebaGuideType, NebaTvGuideType, RouterGuideType } from '../../../../enums/guide-types.enum';
import { orderTypes, StatePedido } from '../../../../enums/order-type.enum';
import { constants } from '../../../../models/constants';
import { DataPortabilidadModel } from '../../../../models/dataPortabilidad.model';
import { DetallePedidoExtended, PortabilidadRechazada } from '../../../../models/detalleSeguimientoPedido.model';
import { HistoricoOL } from '../../../../models/pedidoHistoricoOL.model';
import { HistoryOlService } from '../../services/history-ol.service';
import { MyOrdersService } from '../../services/my-orders.service';
import { OrdersService } from '../../services/orders.service';
import { PortabilityService } from '../../services/portability.service';
import { ResponseBoxUtilsService } from '../../services/response-box-utils.service';
import { TicketsService } from '../../services/tickets.service';
import { UtilsService } from '../../services/utils.service';
import { SchamanServiceType } from '../../../../enums/schaman-service-type.enum';
import { SlidService } from '../../services/slid.service';
import { config } from '../../../../../../config/pages-config';
import { MyOrdersTaggingService } from '../../../../services/my-orders-tagging.service';
import { autoiMigration, setOB, stringOfTrue } from '../../../../constants/defines';
import { OverlayFullScreenModel, OverlayModel } from '@mva10/mva10-angular';
import { GetOrder, TaskFlow } from '../../../../models/getOrder.model';
import { MyOrdersTagService } from '../../services/myOrderTag.service';
import { SpModalFullComponent } from '../../../sp-modal-full/sp-modal-full.component';
import { OrderFlow } from '../../../../enums/order-flow.enum';

@Component({
	selector: 'sp-steps',
	templateUrl: './sp-steps.component.html',
	styleUrls: ['./sp-steps.component.scss'],
})
export class SpStepsComponent implements OnInit {
	@Input() isActivateRouter: boolean;
	@Input() orderData: DetallePedidoExtended;
	@Input() orderLastOLStatus: HistoricoOL;
	@Output() openOverlayCheckInstallation: EventEmitter<SchamanServiceType> = new EventEmitter();
	@Output() openAutoiOverlay: EventEmitter<void> = new EventEmitter<void>();
	@Output() openAutoiTicketDetail: EventEmitter<void> = new EventEmitter<void>();
	@Output() openRouterActivationGuide: EventEmitter<void> = new EventEmitter<void>();
	@Output() openRouterInstallationGuide: EventEmitter<void> = new EventEmitter<void>();
	@Output() openDecoInstallationGuide: EventEmitter<void> = new EventEmitter<void>();
	@Output() openDecoGen3InstallationGuide: EventEmitter<void> = new EventEmitter<void>();
	@Output() openHistoryOLModal: EventEmitter<void> = new EventEmitter<void>();
	@Output() openNebaInstallationGuide: EventEmitter<void> = new EventEmitter<void>();
	@Output() openNebaInstallationTvGuide: EventEmitter<void> = new EventEmitter<void>();
	@Output() openNebaInstallationTvMulticastGuide: EventEmitter<void> = new EventEmitter<void>();
	@Output() openNebaInstallationTvUnicastGuide: EventEmitter<void> = new EventEmitter<void>();
	@ViewChild(autoiMigration.modalKo) modalKo: SpModalFullComponent;
	showOverlayCheckInstallation: boolean = false;
	stringOfTrue: string = stringOfTrue;
	stepsClass: string = 'mvf-steps__list';
	progressBarClass: string = 'mvf-steps__item-progress-bar';
	progressIconClass: string = 'mvf-steps__item-progress-icon';
	contentClass: string = 'mvf-steps__item-group-content';
	groupClass: string = 'mvf-steps__item-group';
	itemClass: string = 'mvf-steps__item';
	infoDocAnalytics: typeof constants.infoDocAnalytics = constants.infoDocAnalytics;
	docOptions: string[];
	botonCita: string;
	docType: string;
	numDoc: string;
	numPortar: string;
	nombre: string;
	apellido: string;
	apellido2: string;
	iccid: string;
	codigoRechazo: string;
	isFinished: boolean;
	currentInfoPage: string;
	enableButton: boolean;
	numValid: boolean;
	dniValid: boolean;
	isSafari: boolean;
	isIphone: boolean;
	isIkki: boolean;
	showAutoiCard: boolean;
	DecoGuideType: typeof DecoGuideType = DecoGuideType;
	RouterGuideType: typeof RouterGuideType = RouterGuideType;
	NebaGuideType: typeof NebaGuideType = NebaGuideType;
	NebaTvGuideType: typeof NebaTvGuideType = NebaTvGuideType;
	StatePedido: typeof StatePedido = StatePedido;
	showStepOverlayFullScreenModal: boolean;
	overlayFullScreenData: OverlayFullScreenModel = {
		closeBtn: true,
		background: '#f4f4f4',
	};
	public showBackDropOBSteps: boolean;
	public urlOBSteps: string;
	public xrContentsOverlayDataSteps: OverlayModel;
	public currentOrdersSteps: GetOrder;
	public orderFlow: string = OrderFlow.FLUJO_7;
	public overlayData: OverlayModel = new OverlayModel();
	public openBackDrop: boolean = false;
	public four_one: string = autoiMigration.four_one;
	public four_two: string = autoiMigration.four_two;

	constructor(
		private router: Router,
		public myOrdersService: MyOrdersService,
		private utilsService: UtilsService,
		public ordersService: OrdersService,
		private responseBoxUtils: ResponseBoxUtilsService,
		readonly ticketsService: TicketsService,
		readonly olService: HistoryOlService,
		private portabilityService: PortabilityService,
		public translate: TranslateService,
		public appService: AppService,
		public slidService: SlidService,
		private tagging: MyOrdersTaggingService,
		public route: ActivatedRoute,
		public obService: MyOrdersTagService
	) {}

	ngOnInit(): void {
		if (!this.orderData) {
			this.orderData = this.ordersService.detailOrderData;
		}
		this.ordersService.flow = this.orderData.orderFlow;
		this.setTokenOBSteps();
		this.isIphone = this.utilsService.isIphone();
		this.isSafari = this.utilsService.isSafari();
		this.isIkki = this.utilsService.isIkki();
		if (this.isActivateRouter) {
			this.stepsClass = 'mvf-steps__list-activate-router';
			this.progressBarClass = 'mvf-steps__item-progress-bar-activate-router';
			this.progressIconClass = 'mvf-steps__item-progress-icon-activate-router';
			this.contentClass = 'mvf-steps__item-group-content-activate-router';
			this.groupClass = 'mvf-steps__item-group-activate-router';
		}
		this.docOptions = [
			this.translate.instant('v10.myOrder.steps.relaunchPortabilityCIF'),
			this.translate.instant('v10.myOrder.steps.relaunchPortabilityNIF'),
			this.translate.instant('v10.myOrder.steps.relaunchPortabilityNIE'),
			this.translate.instant('v10.myOrder.steps.relaunchPortabilityPassport'),
		];

		if (this.orderData.cambioCita) {
			if (this.orderData.cambioCita < 4) {
				this.botonCita = 'Cambiar cita';
			} else {
				this.botonCita = 'Planificar cita';
			}
		}

		setTimeout(() => {
			const portabilidadRechazada: PortabilidadRechazada = this.ordersService.detailOrderData.portabilidadRechazada;
			if (portabilidadRechazada) {
				this.docType = portabilidadRechazada.tipoDoc;
				this.numDoc = portabilidadRechazada.numDoc;
				this.numPortar = portabilidadRechazada.numPortar;
				this.nombre = portabilidadRechazada.nombre;
				this.apellido = portabilidadRechazada.apellido1;
				this.apellido2 = portabilidadRechazada.apellido2;
				this.iccid = portabilidadRechazada.iccid;
			}
		}, 1500);

		this.showAutoiCard =
			this.orderData.hasAutoInstallationCard &&
			(this.ordersService.selectedOrderType === orderTypes.vigentes ||
				this.ordersService.selectedOrderType === orderTypes.completados) &&
			+this.orderData.paso > 2;
	}

	public setTokenOBSteps(): void {
		this.findOrdersSteps();
		this.currentOrdersSteps?.processFlow?.taskFlow?.forEach((task) => {
			this.setCharacteristicSteps(task);
		});
	}

	public findOrdersSteps(): void {
		this.currentOrdersSteps = this.ordersService?.orders?.find(
			(el) => el.id === this.ordersService?.detailOrderData?.numeroPedido
		);
	}

	public setCharacteristicSteps(task: TaskFlow): void {
		task.characteristic?.forEach((item) => {
			if (
				item.name.toLowerCase() === setOB.ordenName.toLowerCase() &&
				item.value.toLowerCase() === setOB.ordenValue.toLowerCase() &&
				task.state.toLowerCase() === setOB.activeOB.toLowerCase()
			) {
				this.ordersService.isOB = true;
				this.ordersService.setTokenOB(this.currentOrdersSteps.processFlow.characteristic);
			}
		});
	}

	public clickOBSteps(): void {
		this.obService.tagClickContinue(this.botonCita);
		this.showBackDropOBSteps = true;
		this.xrContentsOverlayDataSteps = new OverlayModel();
		this.xrContentsOverlayDataSteps.title = setOB.spacer;
		this.xrContentsOverlayDataSteps.hideXButton = false;
		this.xrContentsOverlayDataSteps.primaryButton = this.translate.instant('v10.myOrder.detail.backdropOB.button');
		this.obService.tagModalState(this.botonCita);
	}

	public closeOBSteps(): void {
		this.obService.tagCloseBackDrop(this.botonCita);
		this.showBackDropOBSteps = false;
	}

	public clickButtonBackdropSteps(): void {
		this.obService.tagClickStart(this.botonCita);
		this.ordersService.setUrlOB();
		window.open(this.ordersService.urlOB, '_blank');
		this.showBackDropOBSteps = false;
		this.router.navigate([config.dashboard.route]);
	}

	getStepState(numPaso: string): string {
		this.isFinished = false;

		if (this.isActivateRouter) {
			if (Number(this.orderData.paso) > 4) {
				this.stepsClass = 'mvf-steps__list-activate-router mvf-steps__many-items-list';
			} else {
				this.stepsClass = 'mvf-steps__list-activate-router';
			}
			if (Number(this.orderData.paso) === Number(numPaso)) {
				return 'mvf-steps__item-group-activate-router--active';
			} else if (Number(this.orderData.paso) > Number(numPaso)) {
				return 'mvf-steps__item-group-activate-router--ok';
			} else {
				return 'mvf-steps__item-group-activate-router--pending';
			}
		} else {
			if (Number(this.orderData.paso) === Number(numPaso) && !this.isFinished) {
				return 'mvf-steps__item-group--active';
			} else if (Number(this.orderData.paso) > Number(numPaso) || this.isFinished) {
				return 'mvf-steps__item-group--ok';
			}
			return 'mvf-steps__item-group--pending';
		}
	}

	getClassByEstado(estado: string): string {
		switch (estado) {
			case 'Completado':
				return 'mvf-detail__subtitleCompletado';
			case 'Pendiente':
				return 'mvf-detail__subtitlePendiente';
			case 'Verificando':
				return 'mvf-detail__subtitleVerificando';
		}
	}

	calcularDiasRestantes(fechaLimite: number): string {
		const today: number = Date.now();
		const dif: number = fechaLimite - today;
		const one_day: number = 1000 * 60 * 60 * 24;
		const dias: number = Math.round(dif / one_day);
		return dias + ' días';
	}

	isLast(last: boolean): string {
		if (last) {
			return 'mvf-detail__hide';
		} else {
			return '';
		}
	}

	goToInfo(): void {
		this.router.navigate([config.myorders2.mvfInfoDevolucion.route], { skipLocationChange: true });
	}

	openAppointmentModal(): void {
		this.tagging.sendInteraction({
			link_type: 'acceder ' + (this.botonCita ? this.botonCita.toLowerCase() : 'cambiar cita'),
		});
		this.router.navigate([config.myorders2.appointment.route], { skipLocationChange: true });
	}

	openRouterModal(): void {
		if (this.orderData.activarRouter === RouterGuideType.ACTIVATE_ROUTER) {
			this.openRouterActivationGuide.emit();
		} else {
			this.openRouterInstallationGuide.emit();
		}
	}

	openOneConectaModal(): void {
		this.tagging.sendInteraction({
			link_type:
				this.orderData.guiaOneConecta === 1
					? 'instalar equipo one conecta con deco'
					: 'instalar equipo one conecta sin deco',
		});
		if (this.orderData.guiaOneConecta === 1) {
			this.router.navigate([config.myorders2.oneConectaConDecoGuide.route], { skipLocationChange: true });
		} else {
			this.router.navigate([config.myorders2.oneConectaSinDecoGuide.route], { skipLocationChange: true });
		}
	}

	openDecoModal(): void {
		if (this.orderData.instalarEquipos === DecoGuideType.UPGRADE_TV) {
			this.openDecoInstallationGuide.emit();
		} else if (
			this.orderData.instalarEquipos === DecoGuideType.UPGRADE_DECO_GEN3 ||
			this.orderData.activarRouter === RouterGuideType.UPGRADE_INTERNET_AND_DECO_GEN3
		) {
			this.openDecoGen3InstallationGuide.emit();
		}
	}

	openNebaModal(): void {
		if (this.orderData.guiaAutoiNeba === NebaGuideType.NEBA_INTERNET) {
			this.openNebaInstallationGuide.emit();
		}
	}

	openNebaTvGuideModal(): void {
		if (this.orderData.guiaAutoiNebaTv === NebaTvGuideType.NEBA_DECO_GEN3) {
			this.openNebaInstallationTvGuide.emit();
		} else if (this.orderData.guiaAutoiNebaTv === NebaTvGuideType.NEBA_DECO_MULTICAST) {
			this.openNebaInstallationTvMulticastGuide.emit();
		} else if (this.orderData.guiaAutoiNebaTv === NebaTvGuideType.NEBA_DECO_UNICAST) {
			this.openNebaInstallationTvUnicastGuide.emit();
		}
	}

	openCheckInstallationOverlay(): void {
		this.openOverlayCheckInstallation.emit();
	}

	openDecoChangeModal(): void {
		this.tagging.sendInteraction({
			link_type: 'abrir modal',
		});
		this.router.navigate([config.myorders2.decoChange.route], { skipLocationChange: true });
	}

	openScoringModal(): void {
		this.tagging.sendInteraction({
			link_type: 'adjuntar documentacion',
		});
		this.router.navigate([config.myorders2.documentationScoring.route], { skipLocationChange: true });
	}

	openOrderHistoryModal(): void {
		this.tagging.sendInteraction({
			link_type: 'click en ver detalle del envio',
		});
		this.openHistoryOLModal.emit();
	}

	watchScoringDocs(): void {
		this.tagging.sendInteraction({
			link_type: 'ver documentacion',
		});
		this.utilsService.postMessageToParent(
			JSON.stringify({
				action: 'forward',
				message: 'contratos-documentacion',
			})
		);
	}

	showDocumentInfo(document: string): void {
		this.myOrdersService.openDocumentInfo(document, this.ordersService.detailOrderData);
		this.showStepOverlayFullScreenModal = this.myOrdersService.showResponseBox;
		const docInfoType: { texto: string; codigo: string } = constants.infoDocAnalytics.find(
			(elem) => elem.codigo === document
		);
		this.tagging.sendInteraction({
			link_type: 'ver informacion ' + docInfoType.texto,
		});
		this.currentInfoPage = constants.infoPageName + docInfoType.texto;
		this.doSendView();
		this.tagging.extendUtagPageName([this.currentInfoPage], false, true);
	}

	doSendView(): void {
		this.tagging.sendPage([this.currentInfoPage]);
	}

	sendInfo(): void {
		const idCliente: string = this.myOrdersService.MYORDERS_DATA.idCliente;
		this.tagging.sendInteraction({
			link_type: 'enviar',
		});
		const dataPort: DataPortabilidadModel = {
			idCliente: idCliente,
			numeroPedido: this.orderData.numeroPedido,
			docType: this.docType,
			numDoc: this.numDoc,
			numPortar: this.numPortar,
			codigoRechazo: this.orderData.portabilidadRechazada ? this.orderData.portabilidadRechazada.codigoRechazo : '',
			tipoLinea:
				this.orderData.portabilidadRechazada.tipoLinea.toLowerCase() === 'movil'
					? this.orderData.portabilidadRechazada.tipoLinea.toLowerCase()
					: 'fija',
		};
		this.portabilityService.sendInfo(dataPort);
	}

	change(): void {
		this.numValid = this.responseBoxUtils.validatePhone(
			this.numPortar,
			this.numValid,
			this.ordersService.detailOrderData.mensajePM
		);
		this.dniValid = this.responseBoxUtils.validateDNI(this.numDoc, this.docType, this.dniValid);
		this.enableButton = this.responseBoxUtils.change(
			this.docType,
			this.numDoc,
			this.numPortar,
			this.enableButton,
			this.orderData.portabilidadRechazada,
			this.numValid,
			this.dniValid
		);
	}
	startVptSignProcess(currentOrderDetail: DetallePedidoExtended): void {
		if (currentOrderDetail?.urlVPT) {
			this.ordersService.firmaDigitalRes = {
				errorCode: 100,
				error: '',
				pendienteFirma: true,
				url: [currentOrderDetail.urlVPT],
			};
		}
		this.tagging.sendInteraction({
			link_type: 'click en autoriza la portabilidad',
		});
		this.myOrdersService.vptSignProcess = true;
		this.router.navigate([config.myorders2.firmaDigital.route], { skipLocationChange: true });
	}
	openDropdown(): void {
		document.getElementById('inputDropdownSteps').setAttribute('aria-expanded', 'true');
	}

	closeDropdown(): void {
		document.getElementById('inputDropdownSteps').setAttribute('aria-expanded', 'false');
	}

	selectOption(option: string): void {
		this.docType = option;
		this.change();
	}

	editData(): void {
		this.tagging.sendInteraction({
			link_type: this.orderData.portabilidadRechazada.codigoRechazo
				? 'click en modificar ' + this.orderData.portabilidadRechazada.codigoRechazo.replace('RECH_', '').toLowerCase()
				: 'editar datos personales',
		});
		this.router.navigate([config.myorders2.formBox.route], { skipLocationChange: true });
	}

	showICCIDInfo(): void {
		this.tagging.sendInteraction({
			link_type: 'click en info iccid',
		});
		this.router.navigate([config.myorders2.iccidInfo.route], { skipLocationChange: true });
	}

	updateNumDoc(numDoc: string): void {
		this.numDoc = numDoc;
		this.change();
	}

	updateNumPortar(value: string): void {
		this.numPortar = value;
		this.change();
	}

	openAutoiHelp(): void {
		this.openAutoiOverlay.emit();
	}

	goToTicketDetail(): void {
		this.openAutoiTicketDetail.emit();
	}

	onDestroy(): void {
		this.tagging.extendUtagPageName(['error iccid', 'error ident', 'informacion iccid'], null, true);
	}

	closeModal() {
		this.showStepOverlayFullScreenModal = false;
	}

	public hasStep41OrStep42(step: string): boolean {
		return this.findActiveTask() === step;
	}

	public findActiveTask(): string {
		return this.ordersService.orders
			?.find((el) => el.id === this.orderData?.numeroPedido)
			?.processFlow?.taskFlow?.find((el) => el.state === autoiMigration.active)
			.characteristic.find((el) => el.name === autoiMigration.orden)?.value;
	}

	public continueTaskAutoi(): void {
		this.ticketsService
			.advanceTicketStatus(
				this.ordersService.detailOrderData.numeroPedido,
				this.myOrdersService.MYORDERS_DATA.idCliente
			)
			.subscribe(
				() => {
					this.goToGuide();
				},
				(error) => {
					this.modalError();
				}
			);
	}

	public goToGuide(): void {
		if (this.orderData.instalarEquipos === DecoGuideType.UPGRADE_TV) {
			this.openDecoInstallationGuide.emit();
		} else if (this.orderData.activarRouter === RouterGuideType.INTERNET_AND_TV) {
			this.openRouterInstallationGuide.emit();
		}
	}

	public modalError(): void {
		this.modalKo.primaryButton = true;
		this.modalKo.primaryButtonText = this.translate.instant('v10.myOrder.orders.returns.sumary.modalKo.btn');
		this.modalKo.primaryButtonClick = () => {
			this.router.navigate([config.myorders.route]);
			this.modalKo?.hide();
		};
		this.modalKo.close = () => {
			this.router.navigate([config.myorders.route]);
			this.modalKo?.hide();
		};
		this.modalKo.show();
	}

	public backDropAutoi(): void {
		this.openBackDrop = true;
	}

	public closeOverlay(): void {
		this.openBackDrop = false;
	}

	public clickOk(): void {
		this.openBackDrop = false;
		this.showOverlayCheckInstallation = true;
	}

	public clickNoOk(): void {
		this.goToGuide();
		this.openBackDrop = false;
	}

	public closeCheckInstallationOverlay(closeType: string): void {
		this.showOverlayCheckInstallation = false;
	}
}
