export class RemainingStep {
	name: string;
	totalLimit: string;
	totalallowance: string;
	availableAllowance: string;
	availableAllowanceUnitCode: string;
	usedAllowance: string;
	usedAllowanceUnitCode: string;
	unlimited: boolean;
	costOneTime: string;
	costOneTimeCurrency: string;
}
