import { Component, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AppService } from '../../app.service';
import { BillingService } from '../billing.service';

@Component({
	selector: 'sp-cancel-payment',
	templateUrl: './cancel-payment.component.html',
	styleUrls: ['./cancel-payment.component.scss'],
})
export class CancelPaymentComponent implements OnInit {
	title: string = '';
	firstBtnDesc: string = '';
	secondBtnDesc: string = '';
	imgUrl: string = '';
	@Output() continueBtnClickedSubject: Subject<any> = new Subject<any>();
	@Output() cancelBtnClickedSubject: Subject<any> = new Subject<any>();
	constructor(
		public billingService: BillingService,
		private translate: TranslateService,
		private appService: AppService
	) {}

	ngOnInit() {
		this.billingService.dynamicTrayData.removeTrayPadding = false;
		this.translate.get('payment.messagesList').subscribe((data) => {
			this.imgUrl = this.appService.getImgFullPath(data.cancelTopupMsg.icon.url);
			this.title = data.paymentCancelation.description;
			this.firstBtnDesc = data.paymentCancelation.button1.text;
			this.secondBtnDesc = data.paymentCancelation.button2.text;
		});
	}
	/** Fire subject when click on continue button to subscribe on it in parent components to handle diffrent funs executions*/
	continuePayment(): void {
		this.continueBtnClickedSubject.next();
	}
	/** Fire subject when click on cancel button to subscribe on it in parent components to handle diffrent funs executions*/
	cancelPayment(): void {
		this.cancelBtnClickedSubject.next();
	}
}
