import { Injectable } from '@angular/core';
import { TaggingHelperService } from '../core/services/tagging.helper.service';
import { tagging } from '../../config/tagging-config';
import { TaggingViewModel } from '../models/tagging.model';
import { CrossVariables } from '../models/tagging.model';
import { OneProfesionalService } from './one-profesional.service';
@Injectable({
	providedIn: 'root',
})
export class OpSetTaggingService {
	public journeyDetail: string;
	public FormStep: string = '';
	public statusProduct: string = '';
	public isTry: boolean = false;
	constructor(private tagging: TaggingHelperService, public oneProfesionalService: OneProfesionalService) {}

	setState(isKO?: boolean): void {
		const trackData: TaggingViewModel = Object.assign({}, tagging.oneProfesional.newView);
		const newExtraData: TaggingViewModel = Object.assign({}, tagging.oneProfesional.listService);
		const sendData: TaggingViewModel = Object.assign(trackData, newExtraData);
		sendData.journey_category = CrossVariables.client_typology;
		this.journeyDetail = sendData.journey_detail;
		if (isKO) {
			sendData.page_detail = tagging.oneProfesional.listServiceKo.page_detail;
			sendData.journey_detail = tagging.oneProfesional.listServiceKo.journey_detail;
			sendData.page_name = tagging.oneProfesional.listServiceKo.page_name;
		}
		this.tagging.view(null, sendData);
	}

	setButton(literalTxt: string, literalButton: string): void {
		const trackData: TaggingViewModel = Object.assign({}, tagging.oneProfesional.newView);
		const newExtraData: TaggingViewModel = Object.assign({}, tagging.oneProfesional.listService);
		const clickData: TaggingViewModel = Object.assign(newExtraData, tagging.oneProfesional.listServiceClick);
		const sendData: TaggingViewModel = Object.assign(trackData, clickData);
		sendData.event_label = sendData.event_label.replace('{service}', literalTxt);

		sendData.journey_process = literalButton;
		sendData.journey_category = CrossVariables.client_typology;
		sendData['&&products'] = tagging.oneProfesional.listServiceClick['&&products'].replace('<productType>', literalTxt);

		this.tagging.track(sendData.event_name, sendData);
	}

	setFormState(serviceName: string, literalForm: string, tabName?: string, formKO?: boolean): void {
		let sendState: TaggingViewModel;
		if (formKO) {
			literalForm = tagging.oneProfesional.newExtraData.formKo;
		}
		tabName
			? (sendState = this.setForm(serviceName, literalForm, tabName))
			: (sendState = this.setForm(serviceName, literalForm));
		this.journeyDetail = sendState.journey_detail;
		if (formKO) {
			sendState.journey_detail = tagging.oneProfesional.newExtraData.formKo;
		}
		this.tagging.view(null, sendState);
	}

	setCloseProcessState(serviceName: string, processType?: string, literalBoton?: string, actTab?: string): void {
		let sendState: TaggingViewModel;
		const exitTxt: string = tagging.oneProfesional.newExtraData.cierreProceso;
		sendState = this.closeOverlayType(serviceName, actTab);
		sendState.page_subcategory_level_3 = tagging.oneProfesional.newExtraData.exit;
		sendState.page_screen = tagging.oneProfesional.newExtraData.exit;
		sendState.page_name += `:${exitTxt}`;
		sendState.journey_type_plan = this.statusProduct;
		sendState.journey_detail = tagging.oneProfesional.newExtraData.cierreProceso;
		sendState.page_typology = tagging.oneProfesional.newExtraData.overlay;

		if (this.oneProfesionalService.newDataPega.isPega) {
			sendState.page_name += tagging.oneProfesional.newExtraData.pega;
			sendState.page_detail = `${exitTxt} ${tagging.oneProfesional.newExtraData.pega}`;
		}

		if (processType) {
			const setClick: TaggingViewModel = Object.assign(sendState, tagging.oneProfesional.formClick);
			let event: string = '';
			event =
				processType === tagging.oneProfesional.newExtraData.continuar
					? tagging.oneProfesional.newExtraData.events106
					: tagging.oneProfesional.newExtraData.events105;
			setClick.page_subcategory_level_3 += `:${processType}`;

			setClick.event_context = actTab ? `${serviceName}:${actTab}` : `${serviceName}`;
			setClick.journey_process = actTab ? `${serviceName}:${actTab}` : `${serviceName}`;

			setClick.journey_detail = tagging.oneProfesional.newExtraData.cierreProceso;
			setClick.journey_subcategory = '';
			setClick.event_label = this.oneProfesionalService.newDataPega.isPega
				? literalBoton
				: literalBoton + ` ${tagging.oneProfesional.newExtraData.flujo}`;
			setClick['&&events'] = processType !== tagging.oneProfesional.newExtraData.notEvent ? event : '';
			setClick['&&products'] = tagging.oneProfesional.listServiceClick['&&products'].replace(
				'<productType>',
				serviceName
			);
			this.tagging.track(setClick.event_name, setClick);
		} else {
			sendState['&&events'] = '';
			sendState.journey_process = actTab ? `${serviceName}:${actTab}` : `${serviceName}`;
			this.tagging.view(null, sendState);
		}
	}

	closeOverlayType(serviceName: string, actTab?: string): TaggingViewModel {
		return this.FormStep
			? this.getStateTabTagging(serviceName, this.statusProduct, actTab)
			: this.setForm(serviceName, this.FormStep, actTab);
	}

	setFormClick(
		serviceName: string,
		literalForm: string,
		literalBoton: string,
		infoTxt?: string,
		tabName?: string,
		socialItem?: string
	): void {
		let sendState: TaggingViewModel;

		tabName
			? (sendState = this.setForm(serviceName, literalForm, tabName))
			: (sendState = this.setForm(serviceName, literalForm));

		const setClick: TaggingViewModel = Object.assign(sendState, tagging.oneProfesional.formClick);

		setClick.event_label = literalBoton;

		socialItem ? (setClick.journey_social_media = socialItem) : (setClick.journey_social_media = null);
		setClick['&&products'] = tagging.oneProfesional.formClick['&&products']
			.replace('<productType>', serviceName)
			.replace('<tab>', tabName)
			.toLowerCase();

		this.tagging.track(setClick.event_name, setClick);
	}

	setFormDomainClick(
		serviceName: string,
		literalForm: string,
		literalBoton: string,
		infoTxt?: string,
		tabName?: string,
		eventLabeltxt?: string
	): void {
		let sendState: TaggingViewModel;

		tabName
			? (sendState = this.setForm(serviceName, literalForm, tabName))
			: (sendState = this.setForm(serviceName, literalForm));

		const setClick: TaggingViewModel = Object.assign(sendState, tagging.oneProfesional.formClickDomain);

		setClick.event_label = literalBoton;

		setClick['&&products'] = tagging.oneProfesional.formClick['&&products']
			.replace('<productType>', serviceName)
			.replace('<tab>', tabName)
			.toLowerCase();

		setClick.event_label = eventLabeltxt;

		this.tagging.track(setClick.event_name, setClick);
	}

	setConfirmationState(serviceName: string, tabName?: string, socialItem?: string, isKo?: boolean): void {
		let sendState: TaggingViewModel;

		const solicitud: string = isKo
			? tagging.oneProfesional.newExtraData.solicitudKo
			: tagging.oneProfesional.newExtraData.solicitudRecibida;
		sendState = tabName ? this.setForm(serviceName, solicitud, tabName) : this.setForm(serviceName, solicitud);

		let setConfirmationState: TaggingViewModel;

		setConfirmationState = isKo
			? { ...sendState, ...tagging.oneProfesional.formConfirmationKo }
			: { ...sendState, ...tagging.oneProfesional.formConfirmation };

		socialItem
			? (setConfirmationState.journey_social_media = socialItem)
			: (setConfirmationState.journey_social_media = '');
		setConfirmationState['&&products'] = tagging.oneProfesional.formConfirmation['&&products']
			.replace('<productType>', serviceName)
			.replace('<tab>', tabName)
			.toLowerCase();

		if (this.isTry) {
			setConfirmationState.journey_type_plan = tagging.oneProfesional.newExtraData.tryBuy;
		}

		this.tagging.view(null, setConfirmationState);
	}

	setLicencesClick(
		serviceName: string,
		literalForm: string,
		literalBoton: string,
		numLicencias: string,
		tabName?: string
	): void {
		let sendState: TaggingViewModel;

		tabName
			? (sendState = this.setForm(serviceName, literalForm, tabName))
			: (sendState = this.setForm(serviceName, literalForm));

		const setClick: TaggingViewModel = Object.assign(sendState, tagging.oneProfesional.formClick);
		setClick.event_label = literalBoton;
		setClick.journey_detail = `${numLicencias}`;

		this.tagging.track(setClick.event_name, setClick);
	}

	setForm(serviceName: string, literalForm: string, tabName?: string): TaggingViewModel {
		const trackData: TaggingViewModel = Object.assign({}, tagging.oneProfesional.newView);
		const sendData: TaggingViewModel = Object.assign(trackData, tagging.oneProfesional.formSteep);
		sendData.page_subcategory_level_2 = serviceName;

		tabName
			? ((sendData.page_subcategory_level_3 = tabName), (sendData.page_subcategory_level_4 = literalForm))
			: (sendData.page_subcategory_level_3 = literalForm);
		sendData.page_name += `${serviceName}`;
		sendData.journey_process = `${serviceName}`;
		if (tabName) {
			sendData.page_name += `:${tabName}`;
			sendData.journey_process += `:${tabName}`;
		}
		if (literalForm) {
			sendData.page_name += `:${literalForm}`;
			sendData.page_detail = `${literalForm}`;
		}
		sendData.navigation_level_3 = serviceName;
		sendData['&&products'] = tagging.oneProfesional.formSteep['&&products'].replace('<productType>', serviceName);
		sendData.journey_category = CrossVariables.client_typology;

		return sendData;
	}

	getStateTabTagging(Servicename: string, status: string, tabName?: string, orgTabName?: string): TaggingViewModel {
		const trackData: TaggingViewModel = Object.assign({}, tagging.oneProfesional.newView);
		const newExtraData: TaggingViewModel = Object.assign({}, tagging.oneProfesional.tabDetail);

		newExtraData.page_subcategory_level_2 = newExtraData.page_subcategory_level_2.replace('{product}', Servicename);
		newExtraData.navigation_level_3 = newExtraData.navigation_level_3.replace('{product}', Servicename);
		newExtraData.page_detail = newExtraData.page_detail.replace('{product}', Servicename);

		if (tabName) {
			newExtraData.page_name = `${tagging.oneProfesional.newExtraData.page_name}${tagging.oneProfesional.newExtraData.tabs}`;
			newExtraData['&&products'] = tagging.oneProfesional.newExtraData.products_tab;
			newExtraData['&&products'] = newExtraData['&&products']
				.replace('<productType>', Servicename)
				.replace('<tab>', tabName)
				.toLowerCase();
			newExtraData.journey_process = `${tagging.oneProfesional.newExtraData.product}${tagging.oneProfesional.newExtraData.tabs}`;
			newExtraData.journey_process = newExtraData.journey_process
				.replace('{product}', Servicename)
				.replace('{tab}', tabName)
				.toLowerCase();
			newExtraData.page_detail = `${tagging.oneProfesional.newExtraData.product}${tagging.oneProfesional.newExtraData.tabs}`;
			newExtraData.page_detail = newExtraData.page_detail.replace('{product}', Servicename);
			if (orgTabName) {
				newExtraData.page_subcategory_level_3 = orgTabName;
				newExtraData.page_name = newExtraData.page_name.replace('{product}', Servicename).replace('{tab}', orgTabName);
				newExtraData.page_detail = newExtraData.page_detail.replace('{tab}', orgTabName).toLowerCase();
				newExtraData.page_detail = newExtraData.page_detail.replace('{tab}', orgTabName).toLowerCase();
			} else {
				newExtraData.page_subcategory_level_3 = tabName;
				newExtraData.page_name = newExtraData.page_name.replace('{product}', Servicename).replace('{tab}', tabName);
				newExtraData.page_detail = newExtraData.page_detail.replace('{tab}', tabName).toLowerCase();
				newExtraData.page_detail = newExtraData.page_detail.replace('{tab}', orgTabName).toLowerCase();
			}
		} else {
			newExtraData.page_name = tagging.oneProfesional.newExtraData.page_name;
			newExtraData.page_name = newExtraData.page_name.replace('{product}', Servicename);
			newExtraData['&&products'] = tagging.oneProfesional.newExtraData.products;
			newExtraData['&&products'] = newExtraData['&&products'].replace('<productType>', Servicename);
			newExtraData.journey_process = '{product}';
			newExtraData.journey_process = newExtraData.journey_process.replace('{product}', Servicename);
			newExtraData.page_detail = '{product}';
			newExtraData.page_detail = newExtraData.page_detail.replace('{product}', Servicename);
		}
		newExtraData.journey_type_plan = newExtraData.journey_type_plan.replace('{status}', status);
		trackData.journey_category = CrossVariables.client_typology;

		const sendData: TaggingViewModel = Object.assign(trackData, newExtraData);

		return sendData;
	}

	setStateTab(Servicename: string, status: string, tabName?: string, isKo?: boolean): void {
		let setStateTab: TaggingViewModel;
		setStateTab = tabName
			? this.getStateTabTagging(Servicename, status, tabName)
			: this.getStateTabTagging(Servicename, status);

		if (isKo) {
			setStateTab.page_name += `:${tagging.oneProfesional.newExtraData.ko_pdp}`;
			setStateTab.page_detail = tagging.oneProfesional.newExtraData.ko_pdp;
			setStateTab.journey_detail = tagging.oneProfesional.newExtraData.ko_pdp;
		}
		this.tagging.view(null, setStateTab);
	}

	setButtonTab(
		literalTxt: string,
		literalButton: string,
		Servicename: string,
		status: string,
		tabName?: string,
		orgTabName?: string
	): void {
		const trackData: TaggingViewModel = Object.assign(
			{},
			tabName
				? orgTabName
					? this.getStateTabTagging(Servicename, status, tabName, orgTabName)
					: this.getStateTabTagging(Servicename, status, tabName)
				: this.getStateTabTagging(Servicename, status)
		);

		const sendData: TaggingViewModel = Object.assign(
			trackData,
			literalButton !== tagging.oneProfesional.newExtraData.comprar
				? tagging.oneProfesional.tabDetailClickChange
				: tagging.oneProfesional.tabDetailClickBuy
		);
		if (orgTabName) {
			sendData.event_context = `${tagging.oneProfesional.newExtraData.product}${tagging.oneProfesional.newExtraData.tabs}`;
			sendData.event_context = sendData.page_detail.replace('{product}', Servicename).replace('{tab}', orgTabName);
			sendData.journey_process = `${tagging.oneProfesional.newExtraData.product}${tagging.oneProfesional.newExtraData.tabs}`;
			sendData.journey_process = sendData.journey_process
				.replace('{product}', Servicename)
				.replace('{tab}', orgTabName);
		}

		if (literalButton.toLowerCase() === tagging.oneProfesional.newExtraData.comprar.toLowerCase()) {
			sendData.event_context = `${tagging.oneProfesional.newExtraData.product}${tagging.oneProfesional.newExtraData.tabs}`;
			sendData.event_context = sendData.page_detail;
			sendData.journey_status = tagging.oneProfesional.newExtraData.start.toLowerCase();
			sendData.event_category += ` ${literalTxt}`;
			sendData.journey_detail = tagging.oneProfesional.tabDetail.journey_detail;
		}

		sendData.event_name = sendData.event_name.replace('{literalButton}', literalButton);
		sendData.event_label = sendData.event_label.replace('{literal}', literalButton);
		sendData.journey_type_plan = sendData.journey_type_plan.replace('{status}', status);
		this.tagging.track(sendData.event_name, sendData);
	}
}
