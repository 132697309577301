import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'dot',
})
export class DotPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
	}
}
