export class PrivateOfferListCartModel {
	id?: string;
	clientId?: string;
	serviceId?: string;
	state?: number;
	bundleParent?: boolean;
	codigoNBA?: string;
	containerName?: string;
	cuotaDescAdhoc?: number;
	duracionDescAdhoc?: number;
	groupNBA?: string;
	idPega?: string;
	interactionId?: string;
	issue?: string;
	label?: string;
	name?: string;
	netFeeIncrease?: number;
	offerVfDescriptor?: string;
	originNetfee?: number;
	paqueteDescAdhoc?: string;
	precioDescAdhoc?: number;
	rank?: 3;
	discountCompatibleOrigin?: string;
	flow?: string;
	emissionDate?: string;
	sapArticle?: string;
	nbaSegmentCatalog?: string;
	segmentCatalogList?: string;
	arpc?: number;
	direction?: string;
	terminalFeeNumber?: number;
	nodeItemId?: string;
	techDeviceType?: string;
}

export class NodeItemIdServiceModel {
	id?: string;
}
