import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { orderTypes, StatePedido } from '../../../enums/order-type.enum';
import { TicketType } from '../../../enums/ticket-type.enum';
import { Ticket } from '../../../models/autoiTicketResponse.model';
import { constants } from '../../../models/constants';
import { OrdenObtenerPedidos, OrderTypes } from '../../../models/obtenerPedidos.model';
import { AuthService } from '../services/auth.service';
import { MyOrdersService } from '../services/my-orders.service';
import { OrdersService } from '../services/orders.service';
import { TicketsService } from '../services/tickets.service';
import { UtilsService } from '../services/utils.service';
import { config } from '../../../../../config/pages-config';
import { DigitalSign } from '../../../models/digitalSign.model';
import { AppThemes, sapBlackList, subOrders, tabsMyOrders } from '../../../constants/defines';
import { MyOrdersTaggingService } from '../../../services/my-orders-tagging.service';
import { TranslateService } from '@ngx-translate/core';
import { LeftMenuWs10Service } from '../../../../mva10/shared/services/left-menu-ws10.service';

@Component({
	selector: 'sp-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
	showError: boolean;
	allowScroll: boolean;
	orderTypes: OrderTypes[];
	direccion: string;
	ordersToShow: OrdenObtenerPedidos[] = [];
	autoiOpenOrders: OrdenObtenerPedidos[] = [];
	orderTypesEnum: typeof orderTypes = orderTypes;
	nextFn: Function;
	showDetailNtol: boolean = false;
	idPedidosList: string[];
	showChatOption: boolean;
	messageFromIkki: (receivedData: string) => void;
	orderReturnsTitle: string = subOrders.returns;
	setReturns: string = orderTypes.returns;
	public letterB: string = tabsMyOrders.letterB;
	public sapBlackList: boolean;

	constructor(
		private router: Router,
		public utilsService: UtilsService,
		public ticketsService: TicketsService,
		public ordersService: OrdersService,
		private translate: TranslateService,
		private authService: AuthService,
		public myOrdersService: MyOrdersService,
		public appService: AppService,
		private taggingService: MyOrdersTaggingService,
		public leftMenuService: LeftMenuWs10Service
	) {
		this.initMessageReceivingFunction();
	}

	ngOnInit(): void {
		this.appService.theme = AppThemes.Theme3;
		this.myOrdersService.showSpinner = true;
		this.resetTagging();
		this.authService.requestTicket().subscribe({
			next: () => {
				this.ordersService.getFirmaDigital().subscribe({
					next: (res?: DigitalSign) => {
						const showFirmaDigital: boolean = res?.url?.length && constants.trueLiteral.includes(res.pendienteFirma);
						if (!showFirmaDigital) {
							this.ordersService.obtenerPedidosCliente(this.myOrdersService.MYORDERS_DATA.idCliente).subscribe({
								next: () => {
									this.orderTypes = [
										{
											ordertype: tabsMyOrders.vigentes,
											status: true,
										},
										{
											ordertype: tabsMyOrders.returns,
											status: false,
										},
										{
											ordertype: tabsMyOrders.cancels,
											status: false,
										},
									];
									this.ordersService.selectedOrderType = orderTypes.vigentes;
									this.sendTaggingTabSelected(true);
									this.direccion = this.leftMenuService.currentAddress
										? this.leftMenuService.currentAddress
										: this.ordersService.direccion;
									this.idPedidosList = this.utilsService.getUniqueValues(this.ordersService.orderData, 'numeroPedido');
									this.initOrdersToShow();
									this.checkAutoiChatOption();
									this.nextFn = this.clickCollapse.bind(this);
								},
								error: () => {
									this.myOrdersService.showSpinner = false;
									this.myOrdersService.showError = true;
									// TO DO: add analitycs get orders
								},
							});
						} else {
							this.myOrdersService.showSpinner = false;
							this.router.navigate([config.firmaDigital.route], { skipLocationChange: true });
						}
						this.notifyParent();
					},
					error: () => {
						this.myOrdersService.showSpinner = false;
						this.myOrdersService.showError = true;
						// TO DO: add analitycs signature
					},
				});
			},
			error: () => {
				this.myOrdersService.showSpinner = false;
				this.myOrdersService.showError = true;
				// TO DO: add analitycs get orders
			},
		});
		this.findBlackList();
	}

	initMessageReceivingFunction(): void {
		window.globalThis.messageFromIkki = (receivedData: string) =>
			this.utilsService.handleReceivedMessageFromIkki(receivedData);
	}

	resetTagging(): void {
		this.taggingService.utagData = {
			page_name: 'seguimiento de pedidos:pedidos en curso',
			monitoring_purchase_id: '',
			monitoring_purchase_number: '',
			monitoring_purchase_status: '',
			monitoring_purchase_type: '',
			monitoring_purchase_code: '',
			monitoring_purchase_technology: '',
			monitoring_purchase_flux: '',
			monitoring_purchase_step: '',
		};
	}

	checkAutoiChatOption(): void {
		this.ticketsService.getAutoiOpenTickets(TicketType.AVERIAS).subscribe({
			next: (tickets: Ticket[]) => {
				if (tickets?.length) {
					this.autoiOpenOrders = this.filterPedidosByType(orderTypes.vigentes)?.filter((openOrder) =>
						this.ordersService.autoiFlows.includes(openOrder.nombreFlujo?.toUpperCase())
					);
					this.showChatOption = this.autoiOpenOrders?.length > 0;
					if (this.showChatOption) {
						setTimeout(() => {
							this.taggingService.sendInteraction({
								link_type: 'impresión acceso chat en curso',
							});
						}, 2000);
					}
				} else {
					this.showChatOption = false;
				}
			},
			error: () => {
				this.showChatOption = false;
			},
		});
	}

	openChat(): void {
		this.utilsService.openAutoiChat(this.autoiOpenOrders?.[0]?.numeroPedido);
		this.taggingService.sendInteraction({
			link_type: 'acceder chat en curso',
		});
	}

	clickTab(tab: OrderTypes): void {
		switch (tab.ordertype.toLocaleLowerCase()) {
			case tabsMyOrders.vigentes.toLocaleLowerCase():
				this.ordersService.selectedOrderType = orderTypes.vigentes;
				break;
			case tabsMyOrders.returns.toLocaleLowerCase():
				this.ordersService.selectedOrderType = orderTypes.returns;
				break;
			case tabsMyOrders.cancels.toLocaleLowerCase():
				this.ordersService.selectedOrderType = orderTypes.cancelados;
				break;
		}
		this.orderTypes.forEach((item) => {
			if (item.ordertype !== tab.ordertype) {
				item.status = false;
			} else {
				item.status = true;
			}
		});
		this.sendTaggingTabSelected(false);
		this.ordersService.selectedOrderData = null;
		this.initOrdersToShow();
	}

	initOrdersToShow(): void {
		this.ordersService.selectedOrderData = null;
		if (this.ordersService.selectedOrderData) {
			this.clickCollapse(this.ordersService.selectedOrderData);
		}
		this.showDetailNtol = false;
		setTimeout(() => {
			this.ordersToShow = this.filterPedidosByType(this.ordersService.selectedOrderType);
			this.idPedidosList = this.utilsService.getUniqueValues(this.ordersToShow, tabsMyOrders.numberOrder);
			this.myOrdersService.showSpinner = false;
		});
		setTimeout(() => {
			if (this.ordersService.selectedOrderData) {
				document
					.getElementById(this.ordersService.selectedOrderData.numeroPedido)
					.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}, 500);
	}

	goToInfoDevolucion(): void {
		this.router.navigate([config.myorders2.mvfInfoDevolucion.route], { skipLocationChange: true });
	}

	goToGuide(): void {
		this.router.navigate([config.myorders2.formBox.route], { skipLocationChange: true });
	}

	clickCollapse(orderData: OrdenObtenerPedidos): void {
		this.showDetailNtol = false;
		this.ordersService.selectedOrderData = orderData;
		if (orderData.pedidoTol && orderData.pedidoTol !== 2) {
			this.showDetailNtol = true;
		}
	}

	closeDetail(): void {
		this.ordersService.selectedOrderData = null;
		this.showDetailNtol = false;
	}

	showTiendaCta(): boolean {
		const arg1: boolean =
			this.utilsService.isAndroidApp() || this.utilsService.isIOSApp() || this.utilsService.isIkkiWeb;
		const arg2: boolean =
			this.ordersService.selectedOrderType === orderTypes.completados ||
			this.ordersService.selectedOrderType === orderTypes.cancelados;
		const arg3: boolean =
			this.ordersService.orderData?.filter((order) => order.statePedido === StatePedido.OPEN).length === 0;
		return arg1 && arg2 && arg3;
	}

	openCtaTienda(): void {
		const message: string = JSON.stringify({
			action: 'forward',
			message: 'tienda-online',
		});
		this.taggingService.sendInteraction({
			link_type: 'visita tienda online',
		});
		if (!this.utilsService.postMessageToParent(message)) {
			console.error('No se ha recibido una respuesta correcta de la ventana padre');
		}
	}

	pedidoHasOrders(idPedidoSelected: string): boolean {
		const ordersFilteredByIdpedido: OrdenObtenerPedidos[] = this.ordersService.orderData.filter(
			(order) => order.numeroPedido === idPedidoSelected
		);
		if (this.ordersService.selectedOrderType === orderTypes.vigentes) {
			return !!ordersFilteredByIdpedido.filter(
				(order) => order.statePedido === StatePedido.OPEN || order.statePedido === StatePedido.COMPLETED
			)[0];
		} else if (this.ordersService.selectedOrderType === orderTypes.returns) {
			return !!ordersFilteredByIdpedido.filter((order) => order.statePedido === StatePedido.RETURNS)[0];
		} else if (this.ordersService.selectedOrderType === orderTypes.cancelados) {
			return !!ordersFilteredByIdpedido.filter((order) => order.statePedido === StatePedido.CANCELLED)[0];
		} else {
			return false;
		}
	}

	getFechaSolicitud(idPedido: string): string {
		return this.ordersToShow.find((order) => order.numeroPedido === idPedido)?.fechaSolicitud;
	}

	filterPedidos(idPedido: string): OrdenObtenerPedidos[] {
		return this.ordersToShow.filter((order) => order.numeroPedido === idPedido);
	}

	filterPedidosByType(orderType: string): OrdenObtenerPedidos[] {
		if (orderType === orderTypes.vigentes) {
			return this.ordersService.orderData.filter(
				(order) => order.statePedido === StatePedido.OPEN || order.statePedido === StatePedido.COMPLETED
			);
		} else if (orderType === orderTypes.returns) {
			return this.ordersService.orderData.filter((order) => order.statePedido === StatePedido.RETURNS);
		} else if (orderType === orderTypes.cancelados) {
			return this.ordersService.orderData.filter((order) => order.statePedido === StatePedido.CANCELLED);
		}
	}

	sendTaggingTabSelected(isFirstLoad: boolean): void {
		this.taggingService.resetDetailVariables();
		this.taggingService._extend({
			monitoring_purchase_flux: this.utilsService.normalize(
				this.utilsService.getStringOrdersFlux(this.filterPedidosByType(this.ordersService.selectedOrderType))
			),
		});
		if (
			(this.ordersService.selectedOrderType === orderTypes.vigentes ||
				this.ordersService.selectedOrderType === orderTypes.completados) &&
			this.ordersService.orderData.some((ord) => ord.mnsjExcepcion)
		) {
			this.taggingService._extend({
				monitoring_purchase_status: `${constants.statePedidoAnalytics[2]}-contiene ee`,
			});
		}
		if (this.ordersService.selectedOrderData) {
			this.taggingService.extendUtagPageName(['detalle pedido', 'detalle pedido ntol'], false, true);
		}
		let arrayIndex: number;
		if (this.ordersService.selectedOrderType === orderTypes.vigentes) {
			arrayIndex = 2;
			this.taggingService.extendUtagPageName([tabsMyOrders.returnOrders, tabsMyOrders.cancelOrders], false, true);
		} else if (this.ordersService.selectedOrderType === orderTypes.returns) {
			arrayIndex = 1;
			this.taggingService.extendUtagPageName([tabsMyOrders.inProgressOrders, tabsMyOrders.cancelOrders], false, true);
		} else {
			arrayIndex = 0;
			this.taggingService.extendUtagPageName([tabsMyOrders.inProgressOrders, tabsMyOrders.returnOrders], false, true);
		}
		this.taggingService.sendPage([constants.statePedidoAnalytics[arrayIndex]]);
	}

	notifyParent(): void {
		const urlParent: string = window.parent.location.href;
		window.addEventListener(
			'message',
			(event) => {
				if (event.origin !== window.parent.location.origin) {
					return;
				}
				if (event.data === 'IkkiWeb') {
					this.utilsService.isIkkiWeb = true;
				}
			},
			false
		);
		this.utilsService.postMessageToParent(
			JSON.stringify({
				action: 'loaded',
				message: 'load-pedidos',
			}),
			urlParent
		);
	}

	public findBlackList(): void {
		this.sapBlackList = this.translate.instant('v10.myOrder.subOrders.sapsNotAllowed').includes(sapBlackList);
	}
}
