import { Component, OnInit } from '@angular/core';
import { PaymentCancelService } from '../services/payment-cancel.service';

@Component({
	selector: 'sp-payment-cancel',
	templateUrl: './payment-cancel.component.html',
	styleUrls: ['./payment-cancel.component.scss'],
})
export class PaymentCancelComponent implements OnInit {
	constructor(public payCancelSer: PaymentCancelService) {}

	ngOnInit() {
		this.payCancelSer.fillCurrentCancelOverlay();
	}
}
