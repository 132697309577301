import { CONFIG } from './../constants/defines';
import { API_URLS } from './../constants/routes-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class VerifyEmailService {
	public isExpired: boolean;
	constructor(private http: HttpClient) {}
	getStatus(token) {
		if (token) {
			let headers = new HttpHeaders();
			headers = headers.append('accept', 'application/json');
			const options = {
				headers: headers,
			};
			const url = API_URLS.verifyEmail.verifyEmail
				.replace('{tokenId}', token)
				.replace('{ak}', btoa(CONFIG.CLIENT_ID))
				.replace('{countryCode}', CONFIG.COUNTRY_CODE);
			return this.http.get(url, options);
		}
	}
}
