import { Pipe, PipeTransform } from '@angular/core';
import { monthlyFeeEnum } from '../enums/monthlyFee.enum';

@Pipe({
	name: 'monthlyFee',
})
export class MonthlyFeePipe implements PipeTransform {
	transform(months: number, args?: any[]): string {
		return `(${months} ${months === 1 ? monthlyFeeEnum.singular : monthlyFeeEnum.plural})`;
	}
}
