export enum PaymentTrayAction {
	changeComp = 'changeComp',
	close = 'close',
	pop = 'pop',
	popWithoutAnimation = 'popWithoutAnimation',
	restart = 'restart',
	forceClose = 'forceClose',
	restartFromIframe = 'restartfromIframe',
	changeLanguage = 'changeLanguage',
	startPayment = 'startPayment',
	// commented for future use
	// fullAppRedirection = 'fullAppRedirection',
}
