export enum ConfigurationScreenTabsNumber {
	Red = 0,
	Extenders = 1,
}
export enum ConnectionState {
	Connected = 'connected',
	DisConnected = 'disconnected',
	UnKnown = 'unknown',
}

export enum QualityOfConnection {
	Excellent = 'excellent',
	Good = 'good',
	Poor = 'poor',
	Fair = 'fair',
}

export enum deviceIcons {
	iphone = 'iphone',
	ipod = 'ipod',
	phone = 'phone',
	android = 'android',
	cam = 'cam',
	appletv = 'appletv',
	chromecast = 'chromecast',
	tivo = 'tivo',
	tv = 'tv',
	vizo = 'vizo',
	np = 'np-',
	xbox = 'xbox',
	ps3 = 'ps3',
	ps4 = 'ps4',
	ipad = 'ipad',
	kindle = 'kindle',
	imac = 'imac',
	macintosh = 'macintosh',
	desktop = 'desktop',
	linksys = 'linksys',
	netgear = 'netgear',
	asus = 'asus',
	belkin = 'belkin',
	airport = 'airport',
	thinkpad = 'thinkpad',
	macbook = 'macbook',
	pc = 'pc',
	air = 'air',
	mbp = 'mbp',
	epson = 'epson',
	printer = 'printer',
	voip = 'voip',
	lifx = 'lifx',
	sonos = 'sonos',
}
