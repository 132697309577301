import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AppService } from '../../app.service';
import { AuthenticateService } from '../../core/services/authenticate.service';
import { ChatBotTokenRequest } from '../../models/chat-bot-token-request.model';
import { config } from 'src/config/pages-config';
import { CustomerAccountService } from '../../shared/services/customer-account.service';
import { EntryPointModel } from '../../models/entry-points.model';
import { EntryPointsService } from '../../shared/services/entry-points.service';
import { environment } from '../../../environments/environment';
import {
	IframeMessageTypes,
	IframeMessageOrders,
	IframeMessageStatus,
	ShopTabs,
	MarketplaceUrlParams,
	MarketplaceTagging,
} from '../Interfaces/marketplace-enums';
import { MarketplaceIframeMessage, ReducedToken } from '../Interfaces/marketplace-interfaces';
import {
	marketplaceParams,
	marketplaceGrifoEntryPoint,
	marketplaceMyOrdersScreenCode,
} from '../Interfaces/marketplace-defines';
import { marketplaceTagging } from '../tagging/marketplaceTagging';
import { Mva10FullModslServiceService } from '../../shared/services/mva10-full-modsl-service.service';
import { OnBehalOfTypes, stringOfTrue, baseURL, tab } from '../../shared/constants/defines';
import { PaymentNavigationService } from 'src/app/payment/services/payment-navigation.service';
import { ServiceType } from 'src/app/shared/enums/serviceType.enum';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { TaggingClickDataModel, TaggingViewModel } from '../../models/tagging.model';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { TariffService } from 'src/app/shared/services/tariff.service';
import { Engagement } from '../../shared/enums/engagement.enum';
import { UtilitiesService } from '../../shared/utils/utilities.service';

@Injectable({
	providedIn: 'root',
})
export class MarketplaceService {
	useAppNavbar: boolean = false;
	showNavbar: boolean = true;
	showTabs: boolean = true;
	tabShop: boolean = true;
	tabMarketplace: boolean = false;

	url: string = '';
	marketplaceMyOrdersUrl: string = '';
	marketplaceBillingAccountUrl: string = '';
	marketplaceURL: string = '';

	tariffCanSeeShop: boolean = false;
	tariffCanSeeShopChecked: boolean = false;
	hasMarketPlaceEP: boolean = false;
	hasMarketPlaceEPChecked: boolean = false;
	hasMarketPlaceMyOrdersEP: boolean = false;
	hasMarketPlaceFlag: boolean = false;
	protectedShoppingCart: boolean = false;
	isPaymentOverlay: boolean = false;

	constructor(
		private subscriptionService: SubscriptionService,
		public appService: AppService,
		public auth: AuthenticateService,
		public customerAccountService: CustomerAccountService,
		public entrypointService: EntryPointsService,
		public fullModalServiceService: Mva10FullModslServiceService,
		public paymentNavigationService: PaymentNavigationService,
		public route: ActivatedRoute,
		public router: Router,
		public tagging: TaggingHelperService,
		public tariffService: TariffService,
		private utilitiesService: UtilitiesService,
		public translate: TranslateService
	) {}

	async init(getMktpEpFromDxl: boolean = false, isPaymentOverlay: boolean = false): Promise<void> {
		this.getRefreshTokenUrl();
		this.useAppNavbar = !this.appService.hiddenHeader;
		this.setMessageListener();
		this.setTabWithParams();
		this.checkTariffCanSeeShop();

		if (getMktpEpFromDxl && !window.location.href.includes(config.TiendaV10.route)) {
			this.getHasMarketplaceEP();
		}
		this.isPaymentOverlay = isPaymentOverlay;
		this.hasMarketPlaceFlag = this.getHasMarketplaceActivatedFlag();
	}

	async getRefreshTokenUrl(): Promise<void> {
		this.url = '';
		this.marketplaceMyOrdersUrl = '';
		this.marketplaceBillingAccountUrl = '';
		const reducedToken: string = await this.getReducedToken();
		this.url = this.getMarketplaceURL(reducedToken);
	}

	destroy(): void {
		this.removeMessageListener();
		this.setDefaultValues();
	}

	setDefaultValues(): void {
		this.tabShop = true;
		this.tabMarketplace = false;
		this.showNavbar = true;
		this.showTabs = true;
		this.protectedShoppingCart = false;
		this.tariffCanSeeShop = false;
		if (this.useAppNavbar) {
			this.appService.hiddenHeader = false;
			this.useAppNavbar = false;
		}
		this.url = '';
		this.tariffCanSeeShopChecked = false;
		this.hasMarketPlaceEPChecked = false;
		this.isPaymentOverlay = false;
		this.hasMarketPlaceEP = false;
		this.hasMarketPlaceMyOrdersEP = false;
	}

	checkTariffCanSeeShop(): void {
		const tariffSub: Subscription = this.tariffService.getTariff().subscribe((result) => {
			const isPrepaid: boolean = this.tariffService.Tariff.serviceType === ServiceType.Prepaid;
			this.tariffCanSeeShop = !isPrepaid;
			this.tariffCanSeeShopChecked = true;
			tariffSub.unsubscribe();
		});
	}

	setTabWithParams(): void {
		const queryParamsSubscriber: Subscription = this.route.queryParams.subscribe((params) => {
			if (params[MarketplaceUrlParams.tab]?.toLocaleLowerCase() === ShopTabs.Mktplace.toLocaleLowerCase()) {
				this.tabMarketplace = true;
				this.tabShop = false;
			}
		});
		queryParamsSubscriber.unsubscribe();
	}

	getUrlParams(): string {
		return this.route.snapshot.queryParams.uri ? `/${this.route.snapshot.queryParams.uri}` : '';
	}

	setMessageListener(): void {
		window.addEventListener('message', (e: MessageEvent<string>) => this.onReceivedMessage(e), false);
	}

	removeMessageListener(): void {
		window.removeEventListener('message', (e: MessageEvent<string>) => this.onReceivedMessage(e), false);
	}

	onReceivedMessage(event: MessageEvent<string>): void {
		const mktpMessage: MarketplaceIframeMessage = JSON.parse(event.data);
		if (!mktpMessage.action) {
			return;
		}
		if (mktpMessage.action === IframeMessageTypes.order) {
			this.doIframeOrders(
				mktpMessage.message,
				mktpMessage?.destination?.includes(baseURL)
					? mktpMessage?.destination?.split(baseURL)[1]
					: mktpMessage?.destination
			);
		}
		if (mktpMessage.action === IframeMessageTypes.status) {
			this.doIframeStatus(mktpMessage.message);
		}
	}

	doIframeOrders(order: string, destination?: string): void {
		if (order === IframeMessageOrders.hideNavbar) {
			this.showNavbar = false;
			if (this.useAppNavbar) {
				this.appService.hiddenHeader = true;
			}
		}
		if (order === IframeMessageOrders.showNavbar) {
			this.showNavbar = true;
			if (this.useAppNavbar) {
				this.appService.hiddenHeader = false;
			}
		}
		if (order === IframeMessageOrders.hideTabs) {
			this.showTabs = false;
		}
		if (order === IframeMessageOrders.showTabs) {
			this.showTabs = true;
		}
		if (order === IframeMessageOrders.close) {
			this.protectedShoppingCart
				? this.instantiateMarketplaceAlertModal(null, destination)
				: this.goToPreviousScreen(destination);
		}
		if (order === IframeMessageOrders.fullClose) {
			this.goToPreviousScreen(destination);
		}
	}

	doIframeStatus(status: string): void {
		if (status === IframeMessageStatus.buying) {
			this.protectedShoppingCart = true;
		}
		if (status === IframeMessageStatus.notBuying) {
			this.protectedShoppingCart = false;
		}
	}
	goToPreviousScreen(destination?: string): void {
		if (!this.isPaymentOverlay) {
			!destination?.includes(tab)
				? this.router.navigate([destination ?? config.dashboard.route])
				: this.router.navigate([destination.split('?')[0]], {
						queryParams: this.utilitiesService.getRouteParams(destination),
				  });
		} else {
			this.paymentNavigationService.closePayment();
		}
	}

	instantiateMarketplaceAlertModal(goTo?: ShopTabs, destination?: string): void {
		this.translate.get('v10.productsServices.marketplace.closeOverlay').subscribe((closeOverlay) => {
			this.fullModalServiceService.img = closeOverlay.image;
			this.fullModalServiceService.errorTitle = closeOverlay.title;
			this.fullModalServiceService.errorSubtitle = closeOverlay.subtitle;
			this.fullModalServiceService.errorButton1 = closeOverlay.button1;
			this.fullModalServiceService.errorButton2 = closeOverlay.button2;
		});
		this.fullModalServiceService.imageHeight = 96;
		this.fullModalServiceService.imageWidth = 96;
		this.fullModalServiceService.errorButton1Action = () => {
			this.appService.showErrorFullModal = false;
			this.taggingMarketplaceAlertModalClickEvent(this.fullModalServiceService.errorButton1);
		};
		this.fullModalServiceService.closeAction = () => {
			this.appService.showErrorFullModal = false;
			this.taggingMarketplaceAlertModalClickEvent(MarketplaceTagging.close);
		};
		if (goTo === ShopTabs.Shop) {
			this.fullModalServiceService.errorButton2Action = () => {
				this.tabMarketplace = false;
				this.tabShop = true;
				this.appService.showErrorFullModal = false;
				this.taggingMarketplaceAlertModalClickEvent(this.fullModalServiceService.errorButton2);
			};
		} else {
			this.fullModalServiceService.errorButton2Action = () => {
				this.appService.showErrorFullModal = false;
				this.goToPreviousScreen(destination);
				this.taggingMarketplaceAlertModalClickEvent(this.fullModalServiceService.errorButton2);
			};
		}
		this.appService.showErrorFullModal = true;
		this.taggingMarketplaceAlertModalPageView();
	}

	taggingMarketplaceAlertModalClickEvent(label: string): void {
		const data: TaggingClickDataModel = Object.assign(
			{},
			marketplaceTagging.marketplaceAlertModal.page,
			marketplaceTagging.marketplaceAlertModal.event
		);
		data.event_label = data.event_label.replace('{0}', label);
		this.tagging.track(data.page_name + data.event_label, data);
	}

	taggingMarketplaceAlertModalPageView(): void {
		const data: TaggingViewModel = Object.assign({}, marketplaceTagging.marketplaceAlertModal.page);
		this.tagging.view(null, data);
	}

	async getReducedToken(): Promise<string> {
		await this.auth.refreshTokens(true).toPromise();
		const WCSData: ChatBotTokenRequest = {
			clientId: environment.CLIENT_ID,
			grantType: OnBehalOfTypes.grantType,
			subjectTokenType: OnBehalOfTypes.subjectTokenType,
			actorToken: environment.ADARA_CLIENT_ID,
			actorTokenType: OnBehalOfTypes.actorTokenType,
			chatbotScope: marketplaceParams.REDUCEDTOKENSCOPE,
		};

		const { access_token }: ReducedToken = await this.appService.getChatBotToken(WCSData).toPromise();
		return access_token;
	}

	getMarketplaceURL(reducedToken: string): string {
		const {
			wcsUrlBase,
			wcsUriHome,
			wcsUriMyOrders,
			wcsUriBillingAccount,
			authorizeClientIdValue,
			redirectPrefix,
			redirectUri,
			redirectScope,
		}: { [key: string]: string } = marketplaceParams;
		const urlBase: string = this.translate.instant(wcsUrlBase);
		const uriHome: string = this.translate.instant(wcsUriHome);
		const uriMyOrders: string = this.translate.instant(wcsUriMyOrders);
		const uriBillingAccount: string = wcsUriBillingAccount;

		if (urlBase === wcsUrlBase) {
			this.marketplaceMyOrdersUrl = '';
			this.marketplaceBillingAccountUrl = '';
			return '';
		}

		this.marketplaceURL = `${urlBase}${redirectPrefix}${authorizeClientIdValue}${redirectUri}${uriHome}${this.getUrlParams()}${redirectScope}${reducedToken}`;
		this.marketplaceMyOrdersUrl = `${urlBase}${redirectPrefix}${authorizeClientIdValue}${redirectUri}${uriMyOrders}${this.getUrlParams()}${redirectScope}${reducedToken}`;
		this.marketplaceBillingAccountUrl = `${urlBase}${redirectPrefix}${authorizeClientIdValue}${redirectUri}${uriBillingAccount}${redirectScope}${reducedToken}`;

		if ((window as any).Visitor) {
			const visitor: any = (window as any).Visitor.getInstance(Engagement.visitorInstance);
			this.marketplaceMyOrdersUrl = visitor.appendVisitorIDsTo(this.marketplaceMyOrdersUrl);
			this.marketplaceBillingAccountUrl = visitor.appendVisitorIDsTo(this.marketplaceBillingAccountUrl);
			return visitor.appendVisitorIDsTo(this.marketplaceURL);
		} else {
			return this.marketplaceURL;
		}
	}

	getHasMarketplaceActivatedFlag(): boolean {
		return this.translate.instant(marketplaceParams.wcsActivatedFlag) === stringOfTrue;
	}

	getHasMarketplaceEP(): void {
		if (this.hasMarketPlaceMyOrdersEP) {
			return;
		}
		this.getMarketplaceEntryPointFromDXL().subscribe(
			(entrypoints) => {
				if (entrypoints.some((ep) => ep.code?.toLowerCase() === marketplaceGrifoEntryPoint?.toLowerCase())) {
					this.hasMarketPlaceMyOrdersEP = true;
				} else {
					this.hasMarketPlaceMyOrdersEP = false;
					this.tabMarketplace = false;
					this.tabShop = true;
				}
			},
			(err) => {
				this.hasMarketPlaceMyOrdersEP = false;
			}
		);
	}

	getMarketplaceEntryPointFromDXL(
		screenCode: string = marketplaceMyOrdersScreenCode,
		maxNumber: number = 99,
		version: string = this.translate.instant('v10.commercial.versionPortalEP')
	): Observable<EntryPointModel[]> {
		const optionCustomerAccountId: string = this.subscriptionService.customerData.customerAccountsId;
		const optionServiceId: string = this.subscriptionService.customerData.currentService.id;

		return this.entrypointService
			.getEntryPoints(screenCode, optionCustomerAccountId, maxNumber, false, optionServiceId, version)
			.pipe(map((response) => response.entryPoints));
	}
}
