<div
	bsModal
	#modal="bs-modal"
	class="modal fade"
	tabindex="-1"
	role="dialog"
	aria-labelledby="mySmallModalLabel"
	aria-hidden="true"
	[config]="{ keyboard: false, backdrop: 'static' }"
>
	<div class="small modal-dialog">
		<div class="modal-header">
			<button *ngIf="!hideX" id="btn_hide" type="button" class="close pull-right" aria-label="Close" (click)="hide()">
				<span class="icon icon-close modal-close"> </span>
			</button>
			<button
				*ngIf="xFunctionSameAsPrimaryButton && hideX"
				id="btn_hide"
				type="button"
				class="close pull-right"
				aria-label="Close"
				(click)="primaryButtonClick()"
			>
				<span class="icon icon-close modal-close"> </span>
			</button>
		</div>
		<div class="modal-body">
			<div>
				<span class="{{ bodyIcon }}"> </span>
				<div class="title" *ngIf="bodyTitle">
					{{ bodyTitle }}
				</div>
				<div class="description" [innerHtml]="bodyContent">
					<p></p>
				</div>
				<div>
					<div class="modal-buttons">
						<div class="form-group" *ngIf="showRetryButton">
							<button id="btn_retryClick" (click)="retry()" class="button red">
								{{ 'dashboard.messagesList.limitChangeFailureNudge.limitChangeFailureNudge_button1.text' | translate }}
							</button>
						</div>
						<div class="form-group" *ngIf="primaryButtonText">
							<button
								id="btn_primaryClick"
								(click)="primaryButtonClick()"
								class="button"
								[ngClass]="{ silver: showRetryButton, red: !showRetryButton }"
							>
								{{ primaryButtonText }}
							</button>
						</div>
						<div class="form-group" *ngIf="secondaryButtonText">
							<button
								id="btn_secondaryClick"
								(click)="secondaryButtonClick()"
								class="button"
								[ngClass]="{
									silver: showRetryButton || primaryButtonText,
									red: !showRetryButton || !primaryButtonText
								}"
							>
								{{ secondaryButtonText }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
