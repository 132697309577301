import { Component, ViewChild, Input, Output, EventEmitter, Optional } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { AppService } from '../../../app.service';
import { SafeHtml } from '@angular/platform-browser';
import { NewTaggingHelperService } from '../../../core/services/new-tagging.helper.service';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';
export class Item {
	public title?: string;
	public lineOne?: string;
	public lineTwo?: string;
	public rightText?: any;
}
@Component({
	selector: 'sp-modal-full',
	templateUrl: './sp-modal-full.component.html',
	styleUrls: ['./sp-modal-full.component.scss'],
})
export class SpModalFullComponent {
	@Input() hideX: boolean;
	@ViewChild('modal', { static: true }) public modal: ModalDirective;
	/**
	 *  modal body Title (defult empty string)
	 */
	@Input()
	public bodyTitle: string = '';
	/**
	 *  notification style
	 */

	/**
	 *  modal Header Title (defult empty string)
	 */
	@Input()
	public headerTitle: string = '';
	/**
	 *  notification style
	 */
	@Input()
	public notificationStyle: boolean;
	/**
	 *  modal body Content  (defult empty string)
	 */
	@Input()
	public bodyContent: string = '';
	/**
	 *  modal body Html Content centered  (defult empty string)
	 */
	@Input()
	public htmlContent: string = '';
	/**
	 *  modal image centered  (defult empty string)
	 */
	@Input()
	public imgSrc: string = '';

	/**
	 * Indicate whether modal has primaryButton or not (defult not)
	 */
	@Input()
	public primaryButton = false;
	/**
	 * Indicate whether modal has secondaryButton or not (defult not)
	 */
	@Input()
	public secondaryButton = false;
	/**
	 * Indicate whether modal has tertiaryButton or not (defult not)
	 */
	@Input()
	public tertiaryButton = false;
	/**
	 *  modal primaryButton text (defult empty string)
	 */
	@Input()
	public primaryButtonText: String = '';
	/**
	 *  modal secondaryButton text (defult empty string)
	 */
	@Input()
	public secondaryButtonText: String = '';
	/**
	 *  modal tertiaryButton text (defult empty string)
	 */
	@Input()
	public tertiaryButtonText: String = '';
	/**
	 *  modal primaryButton style (defult empty string)
	 */
	@Input()
	public primaryButtonStyle: String = '';
	/**
	 *  modal secondaryButton style (defult empty string)
	 */
	@Input()
	public secondaryButtonStyle: String = '';
	/**
	 *  modal body Icon url (defult empty string)
	 */
	@Input()
	public bodyIcon: String = '';
	/**
	 *  modal primaryButtonClick event will equal to function and invoked on button click
	 */
	@Input()
	public primaryButtonClick: Function;
	/**
	 *  modal secondaryButtonClick event will equal to function and invoked on button click
	 */
	@Input()
	public secondaryButtonClick: Function;
	/**
	 *  modal tertiaryButtonClick event will equal to function and invoked on button click
	 */
	@Input()
	public tertiaryButtonClick: Function;
	@Input()
	public close: Function;
	/**
	 *  modal iitems array
	 */
	@Input()
	public bodyRouter: string;
	@Input()
	public textRouter: string;
	@Input()
	public textAddress: string;
	@Input()
	public textAuto: string;
	@Input()
	public items: Array<Item>;
	@Input()
	public totalTitle: string;
	@Input()
	public totalPrice: any;
	@Input() spinner: boolean;
	@Input() newSpinner: boolean;
	@Input() alarmClass: string;
	/**
	 * modal title will be in the center
	 */
	@Input()
	public centerTheTitle: boolean;
	@Output() xButtonClicked = new EventEmitter();
	@Input() adjustStyleForSingleCharge: boolean;
	@Input() differentIconWidth: boolean;
	@Input() differentFontSize: boolean;
	/**
	 *  modal body Content from WCS Safe Html (defult empty string)
	 */
	@Input()
	public bodyContentSafeHtml: SafeHtml = '';
	/**
	 * Whether to equally size buttons or not
	 */
	@Input() sameSizeButtons: boolean = false;

	constructor(
		private tagging: TaggingHelperService,
		public appService: AppService,
		@Optional() private newTagging?: NewTaggingHelperService
	) {}
	/**
	 * Pointer to Button openModal (ElementRef)
	 */
	public show(): void {
		this.modal.show();
		this.appService.openBubbleStopScroll = true;
	}

	public hide(sendTagging = true, JSON_FOLDER: string = null): void {
		this.appService.openBubbleStopScroll = false;
		this.xButtonClicked.emit();
		this.modal.hide();
		if (sendTagging && !JSON_FOLDER) {
			this.tagging.sendViewState();
			this.tagging.hideBillingOverlay.next();
		} else if (sendTagging && JSON_FOLDER) {
			this.newTagging.getJsonTagging(JSON_FOLDER).subscribe((data: NewTaggingJsonModel) => {
				this.newTagging.state(data.page);
			});
		}
	}
}
