/**
 * Type of Schaman checks for AUTOI process
 */
export enum SchamanTags {
	NONE = '',
	/**
	 * Validates network and voice services
	 */
	NET = 'trb_auto_net',
	/**
	 * Validates TV services
	 */
	TV = 'trb_autotv',
	/**
	 * Validates TV, network and voice services
	 */
	ALL = 'trb_autoall',
}
