import { Injectable } from '@angular/core';
import { responseType } from '../../../enums/response-type.enum';
import { MyOrdersDataModel } from '../../../models/myOrdersData.model';
import { constants } from '../../../models/constants';
import { DetallePedidoExtended } from '../../../models/detalleSeguimientoPedido.model';
import { ResponseBoxData } from '../../../models/responseBoxData.model';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
	providedIn: 'root',
})
export class MyOrdersService {
	public MYORDERS_DATA: MyOrdersDataModel;
	showError: boolean;
	showSpinner: boolean;
	showResponseBox: boolean;
	typeResponseBox: string;
	isPortResponseBox: boolean;
	genericErrorResponseBox: boolean;
	isChangeResponseBox: boolean;
	loadingResponseBox: boolean;
	codigoRechazoResponseBox: string;
	retryFunctionResponseBox: Function;
	dismissFunctionResponseBox: Function;
	callbackFunctionResponseBox: Function;
	dataResponseBox: ResponseBoxData;
	imageBaseUrl = environment.imageBaseUrl;
	vptSignProcess: boolean;

	constructor(public translate: TranslateService) {}

	resetResponseBox(): void {
		this.showResponseBox = false;
		this.typeResponseBox = null;
		this.isPortResponseBox = false;
		this.genericErrorResponseBox = false;
		this.isChangeResponseBox = false;
		this.loadingResponseBox = false;
		this.codigoRechazoResponseBox = null;
		this.dataResponseBox = null;
	}

	openDocumentInfo(docType: string, detailedOrder: DetallePedidoExtended): void {
		this.resetResponseBox();
		this.dataResponseBox = new ResponseBoxData();
		this.dataResponseBox.imagen = this.translate.instant(
			`v10.myOrder.myOrderService.scoringInfoDocumentoImagen.${docType}`
		);
		this.dataResponseBox.titulo = this.translate.instant(
			`v10.myOrder.myOrderService.scoringInfoDocumentoTitulo.${docType}`
		);
		if (docType === constants.infoDocAnalytics[4].codigo) {
			this.dataResponseBox.notaPequena = this.translate.instant(
				`v10.myOrder.myOrderService.scoringInfoDocumentoNota.${docType}`
			);
			this.dataResponseBox.subtitulo = this.translate
				.instant(`v10.myOrder.myOrderService.scoringInfoDocumentoDescripcion.${docType}`)
				.replace('{0}', detailedOrder.scoring.importeFianza);
		} else {
			this.dataResponseBox.subtitulo = this.translate.instant(
				`v10.myOrder.myOrderService.scoringInfoDocumentoDescripcion.${docType}`
			);
		}
		this.typeResponseBox = responseType.success;
		this.dataResponseBox.buttonClick = () => {
			this.showResponseBox = false;
		};
		this.showResponseBox = true;
	}
}
