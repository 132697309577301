import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../app.service';
import { EverythingOkService } from '../../../core/services/everything-ok.service';
import { config } from '../../../../config/pages-config';
import { BillingService } from '../../../billing/billing.service';
import { FOLDER_PENDING_PAYMENTS, PAYMENTS_TRACKING } from '../../constants/archetype/paymentsTracking';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';
import { NewTaggingHelperService } from '../../../core/services/new-tagging.helper.service';

@Component({
	selector: 'sp-total-debt-payment-modal',
	templateUrl: './total-debt-payment-modal.component.html',
	styleUrls: ['./total-debt-payment-modal.component.scss'],
})
export class TotalDebtPaymentModalComponent implements OnInit {
	totalAmountOverlayDescription: string;
	totalAmountOverlayImagePath: string;

	constructor(
		private everythingOkService: EverythingOkService,
		private router: Router,
		private translate: TranslateService,
		private billingService: BillingService,
		private appService: AppService,
		private newTagging: NewTaggingHelperService
	) {}

	ngOnInit(): void {
		this.appService.showFullAppLoader = false;
		this.checkAndShowDebtTotalAmountOverlay();
	}

	/**
	 * Method for showing total debt payment overlay and send tagging
	 */
	checkAndShowDebtTotalAmountOverlay(): void {
		if (this.everythingOkService?.everythingOkStatus?.debtAmount) {
			this.translate.get('v10.payment.messagesList.showDebtTotalOverlay').subscribe((data) => {
				this.totalAmountOverlayImagePath = data.icon;
				this.totalAmountOverlayDescription = data.description.replace(
					'{0}',
					this.everythingOkService.everythingOkStatus.debtAmount.toString().replace('.', ',')
				);
				this.newTagging.getJsonTagging(FOLDER_PENDING_PAYMENTS).subscribe((data: NewTaggingJsonModel) => {
					this.newTagging.state(data.page);
				});
			});
		}
	}

	/**
	 * Method for hiding total debt payment overlay and send track action
	 */
	hideTotalAmountOverlay(): void {
		this.newTagging.getJsonTagging(FOLDER_PENDING_PAYMENTS).subscribe((data: NewTaggingJsonModel) => {
			this.newTagging.interaction(
				data.eventList[PAYMENTS_TRACKING.PENDING_PAYMENTS.INTERACTIONS.CLICK_ON_CLOSE],
				data.page
			);
		});

		this.appService.showVodaFullLoader = false;
		this.appService.showDebtTotalAmountOverlay = false;
	}

	/**
	 * Method for hiding total amount overlay and navigate to bill payment screen
	 */
	openPaymentPage(): void {
		this.newTagging.getJsonTagging(FOLDER_PENDING_PAYMENTS).subscribe((data: NewTaggingJsonModel) => {
			this.newTagging.interaction(
				data.eventList[PAYMENTS_TRACKING.PENDING_PAYMENTS.INTERACTIONS.CLICK_ON_CHECK_PAYMENTS_BY_SITE],
				data.page
			);
		});
		this.appService.showVodaFullLoader = true;
		this.appService.showDebtTotalAmountOverlay = false;
		/** reset the flag fo the next time */
		this.billingService.cameFromDebtTotalAmountOverlay = true;
		this.router.navigate([config.billing.billPaymentDetails.route]);
	}
}
