<article
	class="vfes-card vfes-card-border-radius vfes-colours-bg-vodafone-white vfes-card-no-border mvf-card-link-card vfes-mt"
>
	<section>
		<p class="mvf-card-link">
			<span>
				<span class="mvf-card-link--title" [innerHtml]="title"> </span>
				<span class="mvf-card-link--text" [innerHtml]="text"> </span>
			</span>
			<span>
				<svg
					(click)="openLink()"
					class="icon"
					xmlns="http://www.w3.org/2000/svg "
					xmlns:xlink="http://www.w3.org/1999/xlink "
					width="40px"
					height="40px"
				>
					<title>{{ 'v10.myOrder.cardLink.goToStoreTitle' | translate }}</title>
					<use class="icon-v3" xlink:href="#icon-chevron-right"></use>
				</svg>
			</span>
		</p>
	</section>
</article>
