import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OverlayModel } from '@mva10/mva10-angular';
import { AppService } from 'src/app/app.service';
import { RouterGuideType } from '../../../enums/guide-types.enum';
import { constants } from '../../../models/constants';
import { OrdersService } from '../services/orders.service';
import { TicketsService } from '../services/tickets.service';
import { MyOrdersTaggingService } from '../../../services/my-orders-tagging.service';
import { OrderFlow } from '../../../enums/order-flow.enum';

@Component({
	selector: 'sp-router-installation',
	templateUrl: './sp-router-installation.component.html',
	styleUrls: ['./sp-router-installation.component.scss'],
})
export class SpRouterInstallationComponent implements OnInit {
	stepsData: string[];
	selectedStep: number;
	pageName: string;
	pagesNames: string[] = ['lo tienes todo', 'conecta los equipos', 'puesta en marcha'];
	showOverlayCheckInstallation: boolean = false;
	disableSchamanCheck: boolean = true;

	RouterGuideType: typeof RouterGuideType = RouterGuideType;

	routerInstallationGuideOverlayData: OverlayModel;
	@Input() withActivation: boolean;
	@Output() onClose: EventEmitter<string> = new EventEmitter();
	showRouterInstallationGuideOverlay: boolean;
	overlayLoaded: boolean;

	constructor(
		public ordersService: OrdersService,
		private ticketsService: TicketsService,
		public appService: AppService,
		private tagging: MyOrdersTaggingService,
		private translate: TranslateService
	) {
		const refact: string =
			ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV
				? translate.instant('v10.myOrder.routerInstallation.installRouterTitle3P')
				: ' ';
		this.routerInstallationGuideOverlayData = {
			isFullScreen: true,
			title:
				ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET
					? translate.instant('v10.myOrder.routerInstallation.installRouterTitle')
					: refact,
		};
	}

	ngOnInit(): void {
		this.overlayLoaded = false;
		this.showRouterInstallationGuideOverlay = true;
		this.selectedStep = 0;
		this.stepsData = [
			this.translate.instant('v10.myOrder.routerInstallation.installRouterStep1name'),
			this.translate.instant('v10.myOrder.routerInstallation.installRouterStep2name'),
			this.translate.instant('v10.myOrder.routerInstallation.installRouterStep3name'),
		];

		if (this.ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET) {
			this.pageName = 'guia instalacion router';
		} else if (this.ordersService.detailOrderData.activarRouter === RouterGuideType.INTERNET_AND_TV) {
			this.pageName = 'guia instalacion router y deco';
		} else {
			this.pageName = 'guia instalacion desconocida';
		}

		this.disableSchamanCheck =
			!constants.trueLiteral.includes(this.withActivation) || !this.ticketsService.autoiHelp.showNotWorkingOption;

		if (!this.disableSchamanCheck) {
			this.stepsData.push(this.translate.instant('v10.myOrder.routerInstallation.autoiCheckInstallationStep'));
			this.pagesNames.push('comprobar instalacion');
		}

		this.doSendView();

		setTimeout(() => {
			this.overlayLoaded = true;
		}, 500);
	}

	doSendView(): void {
		this.resetTagging(true);
		this.tagging.extendUtagPageName([this.pageName]);
		this.tagging.sendPage([this.pagesNames[0]]);
	}

	goForwards(): void {
		// Si no es la ultima pagina de la guía
		if (this.selectedStep !== this.pagesNames.length - 1) {
			this.avanzaPaso();
		} else {
			// Es la ultima pagina de la guia, botón Entendido
			this.tagging.sendInteraction({ link_type: 'click en entendido' });
			if (this.ordersService.flow === OrderFlow.FLUJO_7) {
				this.showOverlayCheckInstallation = true;
			} else {
				this.closeGuideOverlay();
			}
		}
	}

	avanzaPaso(): void {
		this.selectedStep++;
		this.resetTagging();
		this.tagging.sendPage([this.pagesNames[this.selectedStep]]);
	}

	goBackwards(): void {
		this.selectedStep--;
		// The timeout waits for the div on the ngif to load
		if (this.selectedStep < 0) {
			this.closeGuideOverlay();
		} else {
			this.resetTagging();
			this.tagging.sendPage([this.pagesNames[this.selectedStep]]);
		}
	}

	onNextButtonClick(): void {
		if (!this.disableSchamanCheck && this.selectedStep === this.pagesNames.length - 2) {
			this.tagging.sendInteraction({ link_type: 'click en comprobar instalacion' });
			this.openCheckInstallationOverlay();
		} else {
			this.goForwards();
		}
	}

	closeGuideOverlay(closeType?: string): void {
		this.showRouterInstallationGuideOverlay = false;
		this.resetTagging(true);
		setTimeout(() => {
			this.selectedStep = 0;
			this.onClose.emit(closeType);
		}, 500);
	}

	openCheckInstallationOverlay(): void {
		this.resetTagging(true);
		this.showOverlayCheckInstallation = true;
	}

	closeCheckInstallationOverlay(closeType: string): void {
		this.showOverlayCheckInstallation = false;
		this.resetTagging(true);
		this.tagging.extendUtagPageName([this.pageName]);
		this.tagging.extendUtagPageName([this.pagesNames[this.selectedStep]]);
		if (['chat', 'check-ok'].includes(closeType)) {
			this.closeGuideOverlay(closeType);
		}
	}

	resetTagging(removePageName?: boolean): void {
		this.tagging.extendUtagPageName([...this.pagesNames, 'detalle pedido'], null, true);
		if (removePageName) {
			this.tagging.extendUtagPageName([this.pageName], null, true);
		}
	}
}
