<article class="vfes-card vfes-card-border-radius vfes-colours-bg-vodafone-white vfes-card-no-border vfes-mt">
	<div class="mvf-collapse" id="myOrderSpSubOrdersContent">
		<header id="myOrderSpSubOrdersHeader">
			<!-- ICON -->
			<div class="mvf-collapse__bottom-border" id="myOrderSpSubOrdersStatusBox">
				<div class="boxStatusOrders" id="myOrderSpSubOrdersTitleStatus">
					<strong
						id="myOrderSpSubOrdersStatusCard"
						class="titleBoxStatusOrders mvf-collapse__title-text mva10-u-heading--5-bd"
					>
						{{ 'v10.myOrder.steps.status' | translate }}
					</strong>
					<strong
						id="myOrderSpSubOrdersTitleDelivered"
						class="descrpBoxStatusOrders mvf-collapse__title-text mva10-u-heading--5-bd"
					>
						{{ delivered }}
					</strong>
				</div>
			</div>
		</header>
		<section id="myOrderSpSubOrdersTerminalDetailsSection">
			<div class="terminalDetails" id="myOrderSpSubOrders">
				<span id="myOrderSpSubOrdersTerminalDetails" class="terminalDetailImg">
					<img alt="image" [src]="setImageTerminal()" id="myOrderSpSubOrdersTerminalDetailImg" />
				</span>
				<span id="myOrderSpSubOrdersDetailsDevice">
					{{ setNameTerminal() }}
				</span>
			</div>
			<div class="boxDateDelivery" id="myOrderSpSubOrdersDateDelivery">
				<span id="myOrderSpSubOrdersDetailsDelivery" class="mva10-u--body">
					{{ 'v10.myOrder.subOrders.details.delivery' | translate }}
				</span>
				<strong id="myOrderSpSubOrdersTitleDelivery" class="mva10-u--body-bd">
					{{
						(orderData?.date | formatDate : formatDay : formatX) +
							ofString +
							(orderData?.date | formatDate : formatMonth : formatX)
					}}
				</strong>
			</div>
			<div *ngIf="showDetails" id="myOrderSpSubOrdersContentDetailsSummary" class="contentDetailsSummary mva10-u--mb4">
				<div id="myOrderSpSubOrdersBoxHelpReturn" class="boxHelpReturn">
					<strong *ngIf="isReturn || isReturnFix" class="mva10-u--mb5 helpTitle" id="myOrderSpSubOrdersSummaryHelpText">
						{{ 'v10.myOrder.subOrders.details.helpText' | translate }}
					</strong>
					<div *ngIf="isReturn" class="mva10-u--mt4">
						<div id="myOrderSpSubOrdersReturnContentButton" class="mva10-u--mb2">
							<mva10-c-button
								id="myOrderSpSubOrdersReturnButton"
								(mva10OnClick)="clickReturnDevice()"
								styleClass="mva10-c-button--alt1"
								[width]="'full'"
							>
								{{ 'v10.myOrder.subOrders.details.btnReturn' | translate }}
							</mva10-c-button>
						</div>
						<div id="myOrderSpSubOrdersReturnContent" class="stileText">
							<span id="myOrderSpSubOrdersReturnText">
								{{
									('v10.myOrder.subOrders.details.dateReturn' | translate).replace(
										'{0}',
										(informationDate | formatDate : formatDay : formatX) +
											ofString +
											(informationDate | formatDate : formatMonth : formatX)
									)
								}}
							</span>
						</div>
					</div>
					<div *ngIf="isReturnFix" class="mva10-u--mt4">
						<div id="myOrderSpSubOrdersReturnContentFixButton" class="mva10-u--mb2">
							<mva10-c-button
								id="myOrderSpSubOrdersReturnFixButton"
								(mva10OnClick)="clickDoa()"
								styleClass="mva10-c-button--alt1"
								[width]="'full'"
							>
								{{ 'v10.myOrder.subOrders.details.btnReturnFix' | translate }}
							</mva10-c-button>
						</div>
						<div id="myOrderSpSubOrdersReturnContent" class="stileText">
							<span id="myOrderSpSubOrdersReturnFixText">
								{{
									('v10.myOrder.subOrders.details.dateFix' | translate).replace(
										'{0}',
										(informationDate | formatDate : formatDay : formatX) +
											ofString +
											(informationDate | formatDate : formatMonth : formatX)
									)
								}}
							</span>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- DETAIL BUTTON -->
		<span *ngIf="!showDetails" class="mvf-collapse__nav-icon" id="myOrderSpSubOrdersBtnDetail">
			<sp-button id="myOrderSpSubOrdersBtnShowDetails" [parentClass]="'mvf-button--closeable'" (click)="showDetail()">
				<span class="mvf-button__text mva10-u--body" id="myOrderSpSubOrdersDetailsSummary">
					{{ 'v10.myOrder.subOrders.details.BtnViewDetail' | translate }}
				</span>
			</sp-button>
		</span>
		<!-- FIN DETAIL BUTTON -->
		<span *ngIf="showDetails" class="mvf-collapse__nav-icon" id="myOrderSpSubOrdersBtnDetailClose">
			<sp-button
				id="myOrderSpSubOrdersBtnShowDetailsClose"
				[parentClass]="'mvf-button--closeable'"
				(click)="showDetail()"
			>
				<span class="mvf-button__text mva10-u--body" id="myOrderSpSubOrdersBtnDetailCloseText">
					{{ 'v10.myOrder.subOrders.details.BtnClose' | translate }}
				</span>
			</sp-button>
		</span>
	</div>
</article>

<sp-modal-full #modalReturnDevice [hideX]="true">
	<div id="myOrdersmodalReturnDeviceHeader" class="modalReturnHeaderContainer">
		<p id="myOrdersmodalReturnDeviceSubTitle" class="modalReturnSubTitle">
			{{ 'v10.myOrder.orders.returns.optionsReturn.subTitle' | translate }}
		</p>
		<p id="myOrdersmodalReturnDeviceTitle" class="modalReturnTitle">
			{{ 'v10.myOrder.orders.returns.optionsReturn.headerTitle' | translate }}
		</p>
	</div>
	<div id="myOrdersmodalReturnDeviceOption" class="modalReturnOption">
		<mva10-c-inputs
			[type]="0"
			[inputId]="'inputofDoa'"
			[title]="'v10.myOrder.orders.returns.optionsReturn.labelDOA' | translate"
			[disabled]="false"
			[value]="'v10.myOrder.orders.returns.optionsReturn.valueDOA' | translate"
			[checked]="checkedDoa"
			[textLabel]="'v10.myOrder.orders.returns.optionsReturn.labelDOA' | translate"
			[idSpan]="'inputDoa'"
			(mva10Change)="setOption(translate.instant('v10.myOrder.orders.returns.optionsReturn.valueDOA'))"
		>
		</mva10-c-inputs>
		<p
			id="myOrdersmodalReturnDeviceDescDoa"
			class="modalReturnSubDesc"
			[innerHTML]="'v10.myOrder.orders.returns.optionsReturn.descDOA' | translate"
		></p>

		<mva10-c-inputs
			[type]="0"
			[inputId]="'inputofNoDoa'"
			[title]="'v10.myOrder.orders.returns.optionsReturn.labelNoDOA' | translate"
			[disabled]="false"
			[value]="'v10.myOrder.orders.returns.optionsReturn.valueNoDOA' | translate"
			[checked]="checkedNoDoa"
			[textLabel]="'v10.myOrder.orders.returns.optionsReturn.labelNoDOA' | translate"
			[idSpan]="'inputNoDoa'"
			(mva10Change)="setOption(translate.instant('v10.myOrder.orders.returns.optionsReturn.valueNoDOA'))"
		>
		</mva10-c-inputs>
		<p
			id="myOrdersmodalReturnDeviceDescNoDoa"
			class="modalReturnSubDesc"
			[innerHTML]="'v10.myOrder.orders.returns.optionsReturn.descNoDOA' | translate"
		></p>
	</div>
	<div id="myOrdersmodalReturnDeviceHelp" class="modalReturnHelpContainer">
		<p id="myOrdersmodalReturnDeviceHelpTitle" class="modalReturnHelpTitle">
			{{ 'v10.myOrder.orders.returns.optionsReturn.helpTitle' | translate }}
		</p>
		<p id="myOrdersmodalReturnDeviceHelpDesc" class="modalReturnHelpDesc">
			{{ 'v10.myOrder.orders.returns.optionsReturn.helpDesc' | translate }}
		</p>
		<span id="myOrdersmodalReturnDeviceHelpLink" class="modalReturnHelpLink">
			<a (click)="onClickLink()" rel="noopener">
				<u> {{ 'v10.myOrder.orders.returns.optionsReturn.helpLink' | translate }} </u>
			</a>
		</span>
	</div>
	<div id="myOrdersmodalReturnBtnContainer" class="modalReturnBtnContainer">
		<div id="myOrdersmodalReturnBtnContainerContinue" class="modalReturnBtnContainerContinue">
			<mva10-c-button (mva10OnClick)="clickContinue()" [styleClass]="'mva10-c-button--primary'" [disabled]="disableBtn">
				<p class="extra-padding" id="myOrdersmodalReturnDevicecontinueBtn">
					{{ 'v10.myOrder.orders.returns.optionsReturn.continueButton' | translate }}
				</p>
			</mva10-c-button>
		</div>
		<div id="myOrdersmodalReturnBtnContainerCancel" class="modalReturnBtnContainerCancel">
			<mva10-c-button (mva10OnClick)="clickCancel()" [styleClass]="'mva10-c-button--tertiary'">
				<p class="extra-padding" id="myOrdersmodalReturnDeviceCancelBtn">
					{{ 'v10.myOrder.orders.returns.optionsReturn.cancelButton' | translate }}
				</p>
			</mva10-c-button>
		</div>
	</div>
</sp-modal-full>
