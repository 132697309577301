<mva10-c-card-flip>
	<mva10-c-card card-1-front class="mva10-u--p0">
		<ng-content select="[consumptionTile]"> </ng-content>
	</mva10-c-card>
	<mva10-c-card card-1-back>
		<ng-container *ngTemplateOutlet="loaded && consumptionData ? backComsumption : backSkeleton"> </ng-container>
	</mva10-c-card>
	<mva10-c-card card-2-front class="mva10-u--p0">
		<ng-container *ngTemplateOutlet="flipCardData.hasCounter ? cardWithCounter : cardWithoutCounter"> </ng-container>
	</mva10-c-card>
	<mva10-c-card card-2-back class="mva10-u--p0">
		<ng-container *ngTemplateOutlet="backDelight"> </ng-container>
	</mva10-c-card>
</mva10-c-card-flip>

<!-- Consumption Back -->
<ng-template #backComsumption>
	<p class="vfes-title--bold title display-inline mva10-u--m0">
		<mva10-c-icon class="mva10-u--mr1x" iconId="icon-data" size="24" title="Data icon"> </mva10-c-icon>
		{{ 'v10.dashboard.common.mobileData' | translate }}
	</p>
	<vfac-g-progress-bar [total]="consumptionData.items[0].total" [value]="consumptionData.items[0].value">
	</vfac-g-progress-bar>
</ng-template>
<ng-template #backSkeleton>
	<div class="skeleton-wrapper-section">
		<div class="skeleton"></div>
		<div class="skeleton"></div>
	</div>
</ng-template>

<!-- Front Card -->
<ng-template #cardWithoutCounter>
	<div
		[ngStyle]="{
			'background-image':
				breakpoint === BREAKPOINTS.MOBILE
					? 'url(' + flipCardData.frontCardImg + ')'
					: 'url(' + flipCardData.imgResponsive + ')'
		}"
		class="delight-front background-card"
	>
		<div class="mva10-u--m0 row h-100">
			<div class="col-xs-6 mva10-u--p0 delight-front__left h-100">
				<span>
					<p class="mva10-u--m0 light-font text-font-size">{{ flipCardData.frontCardText1 }}</p>
					<p class="mva10-u--m0 bold-font text-font-size">{{ flipCardData.frontCardText2 }}</p>
				</span>
				<button (click)="clickDetailButton()" class="light-font delight-front__button">
					{{ flipCardData.frontCardButton }}
					<mva10-c-icon class="mva10-u--ml1x" iconId="icon-chevron-right" size="16" title="Chevron to detail page">
					</mva10-c-icon>
				</button>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #cardWithCounter>
	<div class="delight-front counter-front">
		<div
			[ngStyle]="{
				'background-image':
					breakpoint === BREAKPOINTS.MOBILE
						? 'url(' + flipCardData.frontCardImg + ')'
						: 'url(' + flipCardData.imgResponsive + ')'
			}"
			class="counter-header"
		></div>
		<div class="mva10-u--m0 row counter-body">
			<div class="col-xs-6 mva10-u--p0 delight-front__left h-100">
				<span>
					<p class="mva10-u--m0 light-font text-font-size">{{ flipCardData.frontCardText1 }}</p>
					<p class="mva10-u--m0 bold-font text-font-size">{{ flipCardData.frontCardText2 }}</p>
				</span>
				<button (click)="clickDetailButton()" class="light-font delight-front__button">
					{{ flipCardData.frontCardButton }}
					<mva10-c-icon class="mva10-u--ml1x" iconId="icon-chevron-right" size="16" title="Chevron to detail page">
					</mva10-c-icon>
				</button>
			</div>
			<div class="col-xs-6 mva10-u--p0 delight-front__right h-100">
				<vfac-g-countdown-timer
					[header]="flipCardData.counterHeader"
					[toDate]="flipCardData.counterEndDate"
					[units]="flipCardData.counterUnits"
				>
				</vfac-g-countdown-timer>
			</div>
		</div>
	</div>
</ng-template>

<!-- Back Card -->
<ng-template #backDelight>
	<div
		[ngStyle]="{
			'background-image':
				breakpoint === BREAKPOINTS.MOBILE
					? 'url(' + flipCardData.backCardImg + ')'
					: 'url(' + flipCardData.imgResponsive + ')'
		}"
		class="background-card"
	>
		<div class="mva10-u--p4x delight-back">
			<span class="flex-tablet">
				<p class="mva10-u--m0 light-font text-font-size padding-tablet">{{ flipCardData.backCardText1 }}</p>
				<p *ngIf="flipCardData.backCardText2" class="mva10-u--m0 bold-font text-font-size">
					{{ flipCardData.backCardText2 }}
				</p>
			</span>
		</div>
	</div>
</ng-template>
