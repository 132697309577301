export class PaymentCardModel {
	public uuid: string;
	public documentID: string;
	public documentType: string;
	public journey: string;
	public cardMaskData: string;
	private _cardMask?: string;
	public token: string;
	public expiryDate: string;
	public savedDate: string;
	public lastUsedDate: string;
	public principle: boolean;
	public cardImage: string;
	public pagoRecurrente?: string;

	set cardMask(value: string) {
		this._cardMask = '******' + value.slice(6);
		this._cardMask = this._cardMask.match(/.{1,4}/g).join(' ');
		this.cardMaskData = value;
	}
	get cardMask() {
		return this._cardMask;
	}
}

export class PaymentCardPatchModel {
	public uuid: string;
	public documentID: string;
	public documentType: string;
	public journey: string;
	public cardMask: string;
	public token: string;
	public expiryDate: string;
	public principle: boolean;
}
