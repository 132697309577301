export class CarrouselDslModel {
	mode?: string;
	build: Build;
	responsive: Responsive;
}

export class Build {
	arrows: boolean;
	bullets: boolean;
	clickable_bullets: boolean;
}

export class Responsive {
	0?: ConfigResponsive;
	1?: ConfigResponsive;
	2?: ConfigResponsive;
}

export class ConfigResponsive {
	arrows?: boolean;
	bullets?: boolean;
	elements: number;
	breakpoint?: number;
}
