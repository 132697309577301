import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OverlayModel } from '@mva10/mva10-angular';
import { AppService } from 'src/app/app.service';
import { DecoGuideType, RouterGuideType } from '../../../enums/guide-types.enum';
import { constants } from '../../../models/constants';
import { OrdersService } from '../services/orders.service';
import { TicketsService } from '../services/tickets.service';
import { MyOrdersTaggingService } from '../../../services/my-orders-tagging.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'sp-gen3-installation',
	templateUrl: './sp-gen3-installation.component.html',
	styleUrls: ['./sp-gen3-installation.component.scss'],
})
export class SpGen3InstallationComponent implements OnInit {
	stepsData: string[];
	selectedStep: number;
	pageName: string = 'guia instalacion desconocida';
	pagesNames: string[];

	DecoGuideType: typeof DecoGuideType = DecoGuideType;
	RouterGuideType: typeof RouterGuideType = RouterGuideType;
	disableSchamanCheck: boolean;
	showOverlayCheckInstallation: boolean;
	overlayLoaded: boolean;
	showDecoGen3InstallationGuideOverlay: boolean;
	decoGen3InstallationGuideOverlayData: OverlayModel;
	@Input() withActivation: boolean;
	@Output() onClose: EventEmitter<string> = new EventEmitter();

	constructor(
		public ordersService: OrdersService,
		private ticketsService: TicketsService,
		public appService: AppService,
		private tagging: MyOrdersTaggingService,
		public translate: TranslateService
	) {
		this.decoGen3InstallationGuideOverlayData = {
			isFullScreen: true,
			isCenterTitle: true,
			title:
				ordersService.detailOrderData.activarRouter === RouterGuideType.UPGRADE_INTERNET_AND_DECO_GEN3
					? translate.instant('v10.myOrder.gen3Installation.installRouterTitle3P')
					: translate.instant('v10.myOrder.gen3Installation.installGen3TitleB'),
		};
	}

	ngOnInit(): void {
		this.overlayLoaded = false;
		this.showDecoGen3InstallationGuideOverlay = true;
		this.selectedStep = 0;
		this.pagesNames = ['lo tienes todo', 'instala el decodificador', 'puesta en marcha', 'listo'];
		if (this.ordersService.detailOrderData.instalarEquipos === DecoGuideType.UPGRADE_DECO_GEN3) {
			this.pageName = 'guia instalacion deco gen 3';
			this.stepsData = [
				this.translate.instant('v10.myOrder.decoInstallation.guideInstalacionDecoPaso1Nombre'),
				this.translate.instant('v10.myOrder.gen3Installation.installGen3Step2Name'),
				this.translate.instant('v10.myOrder.decoInstallation.guideInstalacionDecoPaso3Nombre'),
				this.translate.instant('v10.myOrder.decoInstallation.guideInstalacionDecoPaso4Nombre'),
			];
		} else if (this.ordersService.detailOrderData.activarRouter === RouterGuideType.UPGRADE_INTERNET_AND_DECO_GEN3) {
			this.pageName = 'guia instalacion router y deco gen 3';
			this.pagesNames = ['lo tienes todo', 'conecta los equipos', 'puesta en marcha', 'listo'];
			this.stepsData = [
				this.translate.instant('v10.myOrder.decoInstallation.guideInstalacionDecoPaso1Nombre'),
				this.translate.instant('v10.myOrder.gen3Installation.installRouterStep2Name'),
				this.translate.instant('v10.myOrder.decoInstallation.guideInstalacionDecoPaso3Nombre'),
				this.translate.instant('v10.myOrder.decoInstallation.guideInstalacionDecoPaso4Nombre'),
			];
		}

		this.disableSchamanCheck =
			!constants.trueLiteral.includes(this.withActivation) || !this.ticketsService.autoiHelp.showNotWorkingOption;
		if (!this.disableSchamanCheck) {
			this.stepsData[this.stepsData.length - 1] = this.translate.instant(
				'v10.myOrder.decoInstallation.autoiCheckInstallationStep'
			);
			this.pagesNames[this.pagesNames.length - 1] = 'comprobar instalacion';
		}

		this.doSendView();

		setTimeout(() => {
			this.overlayLoaded = true;
		}, 500);
	}

	doSendView(): void {
		this.resetTagging(true);
		this.tagging.extendUtagPageName([this.pageName]);
		this.tagging.sendPage([this.pagesNames[0]]);
	}

	goForwards(): void {
		// Si es la penultima pagina y hay que comprobar instalación
		if (!this.disableSchamanCheck && this.selectedStep === this.pagesNames.length - 2) {
			this.tagging.sendInteraction({ link_type: 'click en comprobar instalacion' });
			this.openCheckInstallationOverlay();
		}
		// Si no es la ultima pagina de la guía
		else if (this.selectedStep !== this.pagesNames.length - 1) {
			this.avanzaPaso();
		} else {
			// Es la ultima pagina de la guia, botón Entendido
			this.tagging.sendInteraction({ link_type: 'click en entendido' });
			this.closeGuideOverlay();
		}
	}

	openCheckInstallationOverlay(): void {
		this.resetTagging(true);
		this.showOverlayCheckInstallation = true;
	}

	closeCheckInstallationOverlay(closeType: string): void {
		this.showOverlayCheckInstallation = false;
		this.resetTagging(true);
		this.tagging.extendUtagPageName([this.pageName]);
		this.tagging.extendUtagPageName([this.pagesNames[this.selectedStep]]);
		if (['chat', 'check-ok'].includes(closeType)) {
			this.closeGuideOverlay(closeType);
		}
	}

	avanzaPaso(): void {
		this.selectedStep++;
		this.resetTagging();
		this.tagging.extendUtagPageName([this.pagesNames[this.selectedStep]]);
	}

	goBackwards(): void {
		this.selectedStep--;
		if (this.selectedStep < 0) {
			this.closeGuideOverlay();
		} else {
			this.resetTagging();
			this.tagging.sendPage([this.pagesNames[this.selectedStep]]);
		}
	}

	closeGuideOverlay(closeType?: string): void {
		this.showDecoGen3InstallationGuideOverlay = false;
		this.resetTagging(true);
		setTimeout(() => {
			this.selectedStep = 0;
			this.onClose.emit(closeType);
		}, 500);
	}

	resetTagging(removePageName?: boolean): void {
		this.tagging.extendUtagPageName([...this.pagesNames, 'detalle pedido'], null, true);
		if (removePageName) {
			this.tagging.extendUtagPageName([this.pageName], null, true);
		}
	}
}
