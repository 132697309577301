import { AppService } from './../../../app.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { UserProfile } from './../../../models/user-profile.model';
import { Router } from '@angular/router';
import { config } from './../../../../config/pages-config';
import { UpgradeProfileService } from './../../../upgrade-profile/upgrade-profile.service';
import { AuthenticateService } from '../../../core/services/authenticate.service';
import { StorageService } from './../../../core/services/storage.service';
import { Company } from './../../../models/company.model';
import { CustomerAccount } from './../../../models/customer-account.model';
import { CompanyService } from './../../services/company.service';
import { SubscriptionService } from '../../../core/services/subscription.service';
import { DataSource } from './../../../models/radio-button-data-source.model';
import { CustomerAccountService } from './../../services/customer-account.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {
	Component,
	OnInit,
	ViewChild,
	Input,
	OnChanges,
	SimpleChanges,
	AfterContentChecked,
	Output,
	EventEmitter,
} from '@angular/core';
import { ProfileType } from '../../enums/profileType.enum';
import { TranslateService } from '@ngx-translate/core';
import { tagging } from './../../../../config/tagging-config';
import { NotificationService } from '../../services/notification.service';
import { Notification } from '../../../models/notification.model';
import { LoginService } from '../../../login/login.service';

@Component({
	selector: 'sp-service-selector-modal',
	templateUrl: './sp-service-selector-modal.component.html',
	styleUrls: ['./sp-service-selector-modal.component.scss'],
})
export class SpServiceSelectorModalComponent implements OnInit, OnChanges, AfterContentChecked {
	inactiveErrorMessage: any;
	companyId: string;
	warningMessage: string;
	light: boolean;
	warningButtonMessage: string;
	statusInactiveError = false;
	@ViewChild('modal', { static: true }) public modal: ModalDirective;
	/**
	 *  modal body Title (defult empty string)
	 */
	@Input() customerAccounts: CustomerAccount[];
	@Input() userProfile: UserProfile;
	@Input()
	public bodyTitle: string;
	/**
	 *  modal body Content  (defult empty string)
	 */
	@Input()
	public bodyContent: string;

	/**
	 * Indicate whether modal has primaryButton or not (defult not)
	 */
	@Input()
	public primaryButton = false;
	/**
	 *  modal primaryButton text (defult empty string)
	 */
	@Input()
	public primaryButtonText: string;
	/**
	 *  modal primaryButton style (defult empty string)
	 */
	@Input()
	public primaryButtonStyle: string;
	/**
	 *  modal body Icon url (defult empty string)
	 */
	@Input()
	public bodyIcon: string;
	@Input()
	nonDashoard: boolean;

	companies: any[];
	customerType: boolean;

	@Output()
	modalClosed = new EventEmitter<any>();
	// Initially set to -1 to mark the inital load of the service selector
	// as we need the code inside ngAfterViewChecked to run only in the initial
	// view check not with every change detection cycle
	selectedCif: number = -1;
	selectedCompanyId: string;
	constructor(
		private customerAccountService: CustomerAccountService,
		private subscriptionService: SubscriptionService,
		private companyService: CompanyService,
		public storage: StorageService,
		private authenticationService: AuthenticateService,
		private upgradeService: UpgradeProfileService,
		private router: Router,
		private translate: TranslateService,
		private tagging: TaggingHelperService,
		private notificationService: NotificationService,
		private loginService: LoginService,
		private appService: AppService
	) {
		this.companyId = '';
		this.companies = [];
	}

	ngAfterViewChecked() {
		if (this.companies.length > 1 && this.selectedCif === -1) {
			let selectedIndex: number;
			this.companies.find((company: Company, index) => {
				if (company.id === this.companyService.selectedCompanyId) {
					selectedIndex = index;
				}
				return company.id === this.companyService.selectedCompanyId;
			});
			this.setCentredCif(selectedIndex);
		}
	}
	ngAfterContentChecked(): void {
		if (this.userProfile) {
			if (
				this.authenticationService.hasRole('employee') ||
				this.userProfile.profileType.toLowerCase() === ProfileType.NETWORK.toLowerCase() ||
				this.userProfile.profileType.toLowerCase() === ProfileType.LIGHT.toLowerCase()
			) {
				this.customerType = true;
				if (this.authenticationService.hasRole('employee')) {
					this.light = false;
					this.translate.get('dashboard').subscribe((data) => {
						this.warningMessage =
							data.contentList.serviceSelector.messagesList.ssDisconnectErr.ssDisconnectErr_description;
						this.warningButtonMessage =
							data.contentList.serviceSelector.messagesList.ssDisconnectErr.ssDisconnectErr_button1.text;
					});
				} else if (this.userProfile.profileType.toLowerCase() === ProfileType.LIGHT.toLowerCase()) {
					this.light = true;
					this.translate.get('dashboard').subscribe((data) => {
						this.warningMessage =
							data.contentList.serviceSelector.messagesList.ssAuthorizeErr.ssAuthorizeErr_description;
						this.warningButtonMessage =
							data.contentList.serviceSelector.messagesList.ssAuthorizeErr.ssAuthorizeErr_button1.text;
					});
				} else if (this.userProfile.profileType.toLowerCase() === ProfileType.NETWORK.toLowerCase()) {
					this.light = false;
					this.translate.get('dashboard').subscribe((data) => {
						this.warningMessage =
							data.contentList.serviceSelector.messagesList.ssManualLoginErr.ssManualLoginErr_description;
						this.warningButtonMessage =
							data.contentList.serviceSelector.messagesList.ssManualLoginErr.ssManualLoginErr_button1.text;
					});
				}
			} else {
				this.customerType = false;
			}
		}
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['customerAccounts']) {
			this.customerAccounts = changes['customerAccounts'].currentValue;
		}
		if (changes['userProfile']) {
			this.userProfile = changes['userProfile'].currentValue;
			if (this.userProfile) {
				if (
					this.authenticationService.hasRole('employee') ||
					this.userProfile.profileType.toLowerCase() === ProfileType.NETWORK.toLowerCase() ||
					this.userProfile.profileType.toLowerCase() === ProfileType.LIGHT.toLowerCase()
				) {
					this.customerType = true;
					if (this.authenticationService.hasRole('employee')) {
						this.light = false;
						this.translate.get('dashboard').subscribe((data) => {
							this.warningMessage =
								data.contentList.serviceSelector.messagesList.ssDisconnectErr.ssDisconnectErr_description;
							this.warningButtonMessage =
								data.contentList.serviceSelector.messagesList.ssDisconnectErr.ssDisconnectErr_button1.text;
						});
					} else if (this.userProfile.profileType.toLowerCase() === ProfileType.LIGHT.toLowerCase()) {
						this.light = true;
						this.translate.get('dashboard').subscribe((data) => {
							this.warningMessage =
								data.contentList.serviceSelector.messagesList.ssAuthorizeErr.ssAuthorizeErr_description;
							this.warningButtonMessage =
								data.contentList.serviceSelector.messagesList.ssAuthorizeErr.ssAuthorizeErr_button1.text;
						});
					} else if (this.userProfile.profileType.toLowerCase() === ProfileType.NETWORK.toLowerCase()) {
						this.light = false;
						this.translate.get('dashboard').subscribe((data) => {
							this.warningMessage =
								data.contentList.serviceSelector.messagesList.ssManualLoginErr.ssManualLoginErr_description;
							this.warningButtonMessage =
								data.contentList.serviceSelector.messagesList.ssManualLoginErr.ssManualLoginErr_button1.text;
						});
					}
				} else {
					this.customerType = false;
				}
			}
		}
	}
	ngOnInit(): void {
		this.translate.get('dashboard.contentList.serviceSelector.title').subscribe((data) => {
			this.bodyTitle = data;
		});
		this.companyId = this.companyService.selectedCompanyId;
		this.companies = this.companyService.CompanyList;
		if (this.authenticationService.hasRole('employee')) {
			this.light = false;
			this.translate.get('dashboard').subscribe((data) => {
				this.warningMessage = data.contentList.serviceSelector.messagesList.ssDisconnectErr.ssDisconnectErr_description;
				this.warningButtonMessage =
					data.contentList.serviceSelector.messagesList.ssDisconnectErr.ssDisconnectErr_button1.text;
			});
		} else if (this.userProfile.profileType.toLowerCase() === ProfileType.LIGHT.toLowerCase()) {
			this.light = true;
			this.translate.get('dashboard').subscribe((data) => {
				this.warningMessage = data.contentList.serviceSelector.messagesList.ssAuthorizeErr.ssAuthorizeErr_description;
				this.warningButtonMessage =
					data.contentList.serviceSelector.messagesList.ssAuthorizeErr.ssAuthorizeErr_button1.text;
			});
		} else if (this.userProfile.profileType.toLowerCase() === ProfileType.NETWORK.toLowerCase()) {
			this.light = false;
			this.translate.get('dashboard').subscribe((data) => {
				this.warningMessage =
					data.contentList.serviceSelector.messagesList.ssManualLoginErr.ssManualLoginErr_description;
				this.warningButtonMessage =
					data.contentList.serviceSelector.messagesList.ssManualLoginErr.ssManualLoginErr_button1.text;
			});
		}
	}

	showStatusInactiveError(error: any[]) {
		this.statusInactiveError = error[0];
		if (error[1]) {
			this.inactiveErrorMessage = error[1].error;
			tagging.serviceSelector.error.data.journey_error_description = this.inactiveErrorMessage;
			tagging.serviceSelector.error.data.journey_subcategory =
				this.customerAccounts.length > 1 ? tagging.serviceSelector.multipleIds : tagging.serviceSelector.singleId;
			this.tagging.track(tagging.serviceSelector.error.eventName, tagging.serviceSelector.error.data);
		}
	}
	public show(): void {
		this.selectedCompanyId = this.companyService.selectedCompanyId;
		if (
			this.storage.userProfile.profileType &&
			this.storage.userProfile.profileType.toLowerCase() === ProfileType.LIGHT.toLowerCase()
		) {
			this.tagging.view(tagging.serviceSelectorPageLightUser, this.storage.dashboardVariables);
		} else {
			this.tagging.view(tagging.serviceSelectorPage, this.storage.dashboardVariables);
		}
		this.appService.openBubbleStopScroll = true;
		this.modal.show();
	}

	public hide(): void {
		this.appService.openBubbleStopScroll = false;
		this.modal.hide();
		this.showStatusInactiveError([false]);
		this.modalClosed.next(true);
		// reset the service selector status and let it re draw
	}
	siebleRedirect() {
		this.loginService.siebleRedirection(
			this.subscriptionService.customerData.customerAccountsId,
			this.subscriptionService.customerData.currentService.id
		);
	}

	radioEvent(data: DataSource) {
		event.stopPropagation();
		if (
			!this.subscriptionService.loadedCompaneis.find((company) => company === this.companyService.selectedCompanyId)
		) {
			this.subscriptionService.loadedCompaneis.push(this.companyService.selectedCompanyId);
			this.gdprOverlayLogic();
		}
		this.hide();
	}
	onClick(company: Company) {
		this.storage.userProfile.showGDPROverlay = false;
		this.tagging.track(tagging.serviceSelector.serviceSwipe.eventName, tagging.serviceSelector.serviceSwipe.data);
		const companyIndex = this.companies.indexOf(company);
		this.setCentredCif(companyIndex);
		this.companyId = company.id;
		this.companyService.selectedCompanyId = company.id;
	}
	openLogOutModal = () => {
		const notification = new Notification();
		this.translate.get('common.messagesList.logoutMessage').subscribe((data) => {
			notification.bodyTitle = data.title;
			notification.bodyContent = data.description;
			notification.primaryButtonText = data.confirmButton.text;
			notification.primaryButtonStyle = 'btn registration';
			notification.primaryButtonClick = () => {
				this.authenticationService.logout();
				this.router.navigate([config.login.route]);
				this.notificationService.notificationModal.hide();
			};
			notification.secondaryButtonText = data.cancelButton.text;
			notification.secondaryButtonStyle = 'btn login';
			notification.secondaryButtonClick = () => {
				this.notificationService.notificationModal.hide();
			};
			this.notificationService.createNotification(notification);
		});
	};
	primaryButtonClick() {
		if (this.authenticationService.hasRole('employee')) {
			this.hide();
			this.openLogOutModal();
		} else if (this.userProfile.profileType.toLowerCase() === ProfileType.LIGHT.toLowerCase()) {
			this.hide();
			this.upgradeService.targetRoute = config.dashboard.name;
			this.upgradeService.startScenario = true;
			this.upgradeService.showModal = () => {
				if (!this.customerAccountService.customerAccounts.length) {
					this.customerAccountService.GetCustomerAccounts().subscribe();
				}
				this.show();
			};
		} else if (this.userProfile.profileType.toLowerCase() === ProfileType.NETWORK.toLowerCase()) {
			this.hide();
			this.upgradeService.showModal = () => {
				if (!this.customerAccountService.customerAccounts.length) {
					this.customerAccountService.GetCustomerAccounts().subscribe();
				}
				this.show();
			};
			this.upgradeService.targetRoute = config.dashboard.name;
			this.upgradeService.startScenario = true;
		}
	}
	/**
	 * should be called if company tab changed and subscription API called
	 */
	private gdprOverlayLogic() {
		if (
			this.selectedCompanyId !== this.companyService.selectedCompanyId &&
			this.subscriptionService.subscriptionAPICalled
		) {
			this.selectedCompanyId = this.companyService.selectedCompanyId;
			this.appService.showGDPROverlay = false;
			this.appService.gdprOverlayLoaded = false;
			this.appService.showAndHideGDPROverlay();
		}
	}

	setCentredCif(index) {
		this.selectedCif = index;
		const navigationList = document.querySelector('ul.nav-pills');
		const tabWidth = navigationList.firstElementChild.clientWidth;
		const scrollOffset = 35 + (index - 1) * tabWidth;
		navigationList.scrollLeft = scrollOffset;
	}
}
