import { LegalTermsItemModel } from './buy-sim-legal-terms-item.model';

export class BuySimPersonalInfoHistoryData {
	formData: any;
	documentType: string;
	legalTermes: LegalTermsItemModel[];
	constructor() {
		this.formData = null;
	}
}
