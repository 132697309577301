import { Component, Input, OnInit } from '@angular/core';
import { ConsumptionSpNavigator, SpMVA10Variable, promoBanner } from '../../constants/defines';

@Component({
	selector: 'sp-promo-banner-layout',
	templateUrl: './promo-banner-layout.component.html',
	styleUrls: ['./promo-banner-layout.component.scss'],
})
export class PromoBannerLayoutComponent implements OnInit {
	@Input() text: string;
	@Input() img: string;
	@Input() backGroundColor1?: string = SpMVA10Variable.vodafoneRed;
	@Input() backGroundColor2?: string = SpMVA10Variable.vodafoneRed;
	@Input() textColor?: string = ConsumptionSpNavigator.backColor;
	public gradient: string;

	ngOnInit(): void {
		this.gradient = promoBanner.style;
		this.gradient = this.gradient.replace(promoBanner.bg1, this.backGroundColor1);
		this.gradient = this.gradient.replace(promoBanner.bg2, this.backGroundColor2);
	}
}
