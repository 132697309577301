export enum LineRestrictionTypes {
	all = 'all',
	incoming = 'incoming',
	international = 'international',
	roaming = 'roaming',
	incomingRoaming = 'incomingRoaming',
	lowLevel = 'lowLevel',
	highLevel = 'highLevel',
	data = 'data',
}

export const LineRestrictionTypesArray = [
	'all',
	'incoming',
	'international',
	'roaming',
	'incomingRoaming',
	'lowLevel',
	'highLevel',
	'data',
];
