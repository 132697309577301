import { Injectable } from '@angular/core';
import { BodyResponse, ProductOrderItem, ReturnResponse } from '../../../models/getOrder.model';
import { API_URLS } from '../../../constants/routes-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { returnDefines } from '../../../constants/defines';
import { Address } from '../../../../models/Address.model';
import { SubscriptionService } from '../../../../core/services/subscription.service';

@Injectable({
	providedIn: 'root',
})
export class ReturnsDeviceService {
	public address: string;
	public msisdn: string;
	public isFirstOptionSelected: boolean = true;
	public subOrden: ProductOrderItem;
	public sapSubOrder: string;
	public sapImage: string;
	public priceCorreos: string;
	public priceHome: string;
	public isFreeCorreos: boolean;
	public isFreeHome: boolean;
	public priceDevice: number;
	public adressNotFormatted: Address;
	public imei: string;
	public selectedType: string;
	public isAdrdess: boolean;
	public mail: string;
	public sapCode: string;
	public hasImei: boolean;

	constructor(public http: HttpClient, public subscription: SubscriptionService) {}

	public setReturns(): Observable<ReturnResponse> {
		const url: string = API_URLS.ProductOrderingManagement.ProductOrder;
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('X-VF-API-Process', 'createReturnOrder');
		const options: Object = {
			headers: headers,
		};
		const body: BodyResponse = this.isFirstOptionSelected ? this.setBodyCorreos() : this.setBodyHome();
		return this.http.post(url, body, options).pipe(
			map((res: ReturnResponse) => {
				return res;
			})
		);
	}

	public setBodyHome(): BodyResponse {
		return {
			'deliveryInstruction': {
				type: returnDefines.deliveryInstructionHome,
				geographicSite: {
					place: {
						'streetType': this.adressNotFormatted.streetType
							? this.adressNotFormatted.streetType
							: returnDefines.whiteSring,
						'streetName': this.adressNotFormatted.street,
						'streetNr': this.adressNotFormatted.buildingNo,
						'portalDoor': this.adressNotFormatted.door ? this.adressNotFormatted.door : this.adressNotFormatted.level,
						'locality': this.adressNotFormatted.country,
						'stateOrProvince': this.adressNotFormatted.town,
						'postCode': this.adressNotFormatted.postcode,
						'geographicSubAddress': {
							'subUnitNumber': this.adressNotFormatted.line1,
							'levelNumber': this.adressNotFormatted.level,
							'block': this.adressNotFormatted.line2,
							'@type': returnDefines.typeSubCollection,
						},
						'role': returnDefines.roleCollection,
						'@referredType': returnDefines.referredTypeCollection,
						'@type': returnDefines.typeCollection,
					},
				},
			},
			'productOrderItem': [
				{
					'id': returnDefines.id,
					'action': returnDefines.action,
					'product': {
						productSerialNumber: this.hasImei ? this.imei : this.sapCode,
					},
					'@type': returnDefines.productOrderItemExtended,
				},
			],
			'relatedParty': [
				{
					'id': returnDefines.id,
					'contactMedium': {
						characteristic: {
							phoneNumber: this.msisdn,
							contactType: returnDefines.contactType,
						},
					},
					'role': returnDefines.roleContact,
					'@referredType': returnDefines.referredTypeAgent,
					'@type': returnDefines.typeInfoIndividual,
				},
				{
					'id': returnDefines.idAgent,
					'role': returnDefines.roleAgent,
					'@referredType': returnDefines.referredTypeAgent,
				},
				{
					'id': this.subscription.customerData.currentService.siteId,
					'role': returnDefines.roleCustomer,
					'@referredType': returnDefines.referredTypeCustomer,
				},
			],
			'@type': returnDefines.productOrderExtendedCreate,
		};
	}

	public setBodyCorreos(): BodyResponse {
		return {
			'deliveryInstruction': {
				type: returnDefines.deliveryInstructionCorreos,
			},
			'productOrderItem': [
				{
					'id': returnDefines.id,
					'action': returnDefines.action,
					'product': {
						productSerialNumber: this.hasImei ? this.imei : this.sapCode,
					},
					'@type': returnDefines.productOrderItemExtended,
				},
			],
			'relatedParty': [
				{
					'id': returnDefines.id,
					'contactMedium': {
						characteristic: {
							phoneNumber: this.msisdn,
							contactType: returnDefines.contactType,
						},
					},
					'role': returnDefines.roleContact,
					'@referredType': returnDefines.referredTypeAgent,
					'@type': returnDefines.typeInfoIndividual,
				},
				{
					'id': returnDefines.idAgent,
					'role': returnDefines.roleAgent,
					'@referredType': returnDefines.referredTypeAgent,
				},
				{
					'id': this.subscription.customerData.currentService.siteId,
					'role': returnDefines.roleCustomer,
					'@referredType': returnDefines.referredTypeCustomer,
				},
			],
			'@type': returnDefines.productOrderExtendedCreate,
		};
	}
}
