<div class="content light" [ngClass]="{ black: !background, light: background }">
	<div class="spinner" id="shareLoadingViewSpeechmarkSpinner">
		<mva10-c-speechmark-spinner class="spinnerDimension" [color]="spinner" [type]="'living_always'">
		</mva10-c-speechmark-spinner>
	</div>
	<div class="gallery" id="shareLoadingViewAutoSwiperSlideSpinner" *ngIf="showSwiper">
		<swiper #swiper [config]="config" fxFlex (indexChange)="controlChangePips($event)" id="autoSwiperSlide">
			<div *ngFor="let text of arraySwiper; let i = index" id="{{ 'autoSwiperSlideContent' + i }}">
				<p
					class="text blackText"
					id="{{ 'shareLoadingViewAutoSwiperSlideContentTextSpinner' + i }}"
					[ngClass]="{ blackText: background, lightText: !background }"
				>
					{{ text.text }}
				</p>
			</div>
		</swiper>
	</div>
</div>
