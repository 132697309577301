import { Component, Input } from '@angular/core';
import { LandingComponent } from '../../../products-and-services/landing/landing.component';
import { Product } from '../../../models/product.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'sp-landing-data-bundle',
	templateUrl: './landing-data-bundle.component.html',
	styleUrls: ['./landing-data-bundle.component.scss'],
})
export class LandingDataBundleComponent {
	@Input() Bundle: Product;
	public parent: LandingComponent;
	public isUnlimited: boolean = false;
	public showYuWeekendText: boolean;

	constructor(private translate: TranslateService) {}

	ngOnInit() {
		const tariffYuCodes: string[] = this.translate.instant('v10.productsServices.misc.yuNewLineUp').split(',');
		this.showYuWeekendText = tariffYuCodes.includes(this.parent.subscription.customerData.currentService.tarrifCode);
	}
}
