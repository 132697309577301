<div class="orders-main-container">
	<div class="tabs">
		<div class="tabs_box" id="myOrdersMainFilterContent">
			<mva10-c-filter
				id="myOrdersMainFilterTabs"
				*ngFor="let tab of orderTypes"
				[idInput]="tab.ordertype"
				[selected]="tab.status"
				[size]="3"
				[noWrap]="true"
				(mva10Change)="clickTab(tab)"
				>{{ tab.ordertype }}</mva10-c-filter
			>
		</div>
	</div>
	<div class="mvf-section" [ngClass]="{ 'mvf-tabs__content--white': showError }">
		<div class="content">
			<div class="shipment-address">
				<div id="myOrdersMainOrderAddress" *ngIf="!showError && direccion" class="vfes-colours--mid-grey mva10-u--body">
					{{ 'v10.myOrder.orderAddress' | translate }}
				</div>
				<div class="mva10-u--body-bd">
					<strong id="myOrdersMainCurrentAddress" *ngIf="!showError && direccion">
						{{ direccion }}
					</strong>
				</div>
			</div>
			<!-- chat ticket BE -->
			<div
				id="myOrdersMainTicketBE"
				class="chat-ticketBE"
				*ngIf="
					showChatOption &&
					(ordersService.selectedOrderType === orderTypesEnum.vigentes ||
						ordersService.selectedOrderType === orderTypesEnum.completados)
				"
			>
				<mva10-c-card paddingDesktop="large" paddingTablet="large">
					<div class="concept-row">
						<div class="chat-image">
							<img src="{{ appService.getImgFullPath('v10.myOrder.img.chatSocial' | translate) }}" alt="Chat social" />
						</div>
						<div class="mva10-u--body chat-text">
							<p>{{ 'v10.myOrder.accessChatInstallation' | translate }}</p>
						</div>
						<div>
							<mva10-c-button [appearance]="'secondary'" [small]="true" (mva10OnClick)="openChat()">
								{{ 'v10.myOrder.accessChatInstallationButton' | translate }}
							</mva10-c-button>
						</div>
					</div>
				</mva10-c-card>
			</div>
			<sp-loader
				[showError]="myOrdersService.showError"
				[showSpinner]="myOrdersService.showSpinner"
				[fullHeight]="true"
			>
			</sp-loader>
			<sp-orders-empty
				class="vfes-pt-"
				*ngIf="!ordersToShow.length && !showError"
				[orderType]="ordersService.selectedOrderType"
			>
			</sp-orders-empty>
			<div *ngFor="let idPedido of idPedidosList; let i = index" id="MyOrdersMainOrdersContent">
				<div *ngIf="pedidoHasOrders(idPedido)" id="MyOrdersMainOrdersCard-{{ i }}">
					<span
						id="MyOrdersMainOrdersTitleOrderNotReturns-{{ i }}"
						class="vfes-align--left mva10-u-heading--4 bold-font"
						*ngIf="ordersService.selectedOrderType !== setReturns"
					>
						{{ 'v10.myOrder.numberOrder' | translate }} {{ idPedido }}
					</span>
					<span
						id="MyOrdersMainOrdersTitleOrderReturns-{{ i }}"
						class="vfes-align--left mva10-u-heading--4 bold-font"
						*ngIf="ordersService.selectedOrderType === setReturns"
					>
						{{ orderReturnsTitle }} {{ idPedido }}
					</span>
				</div>
				<div *ngFor="let order of filterPedidos(idPedido)" id="{{ idPedido }}">
					<sp-collapse
						*ngIf="ordersService.selectedOrderData !== order"
						[orderData]="order"
						[orderType]="ordersService.selectedOrderType"
						[color]="utilsService.getDetailsByComercialName(order.tipo).color"
						[icon]="utilsService.getDetailsByComercialName(order.tipo).icon"
						[nextIcon]="'icon-mvf-pointer-right'"
						[btnDetalle]="ordersService.selectedOrderType !== orderTypesEnum.cancelados"
						(clickButton)="clickCollapse($event)"
					>
					</sp-collapse>
					<sp-detail
						*ngIf="ordersService.selectedOrderData === order && !showDetailNtol"
						(closeDetail)="closeDetail()"
						[orderData]="ordersService.selectedOrderData"
						[icon]="utilsService.getDetailsByComercialName(order.tipo).icon"
						[titleFecha]="utilsService.getTitleFecha(ordersService.selectedOrderType)"
					>
					</sp-detail>
					<sp-detail-ntol
						*ngIf="ordersService.selectedOrderData === order && showDetailNtol"
						(closeDetail)="closeDetail()"
						[orderData]="order"
						[icon]="utilsService.getDetailsByComercialName(order.tipo).icon"
						[titleFecha]="utilsService.getTitleFecha(ordersService.selectedOrderType)"
					>
					</sp-detail-ntol>
				</div>
				<div *ngIf="ordersService.subOrder[idPedido]?.length > 0 && !sapBlackList" id="MyOrdersMainSubOrdersCards">
					<div
						id="MyOrdersMainSubOrdersTitleSubOrderNotReturns"
						*ngFor="let order of ordersService.subOrder[idPedido]; let i = index"
					>
						<div
							*ngIf="order?.date && ordersService.isDelivered(order?.logisticState)"
							id="MyOrdersMainSubOrdersCardsContent"
						>
							<span
								id="MyOrdersMainSubOrdersTitleSubOrderNotReturns-{{ i }}"
								class="vfes-align--left mva10-u-heading--4 bold-font"
								*ngIf="ordersService.selectedOrderType !== setReturns"
							>
								{{ 'v10.myOrder.numberOrder' | translate }} {{ idPedido }}{{ letterB }}
							</span>
							<sp-sub-orders [orderData]="order"> </sp-sub-orders>
						</div>
					</div>
				</div>
			</div>
			<sp-card-link
				*ngIf="showTiendaCta()"
				[title]="'v10.myOrder.nowVodafone' | translate"
				[text]="'v10.myOrder.visitOnlineStore' | translate"
				(clickCard)="openCtaTienda()"
			>
			</sp-card-link>
		</div>
	</div>
</div>
