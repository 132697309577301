/**
 * Possible values for Field 'activarRouter' from order detail
 */
export enum RouterGuideType {
	ACTIVATE_ROUTER = 1,
	INTERNET = -1,
	INTERNET_AND_TV = -2,
	UPGRADE_INTERNET_AND_DECO_GEN3 = -3,
	NO_GUIDE = 0,
	NO_GUIDE_2 = 2,
}

/**
 * Possible values for Field 'instalarEquipos' from order detail
 */
export enum DecoGuideType {
	UPGRADE_TV = 1,
	UPGRADE_DECO_GEN3 = 2,
	NO_GUIDE = 0,
}

/**
 * Possible values for Field 'guiaNeba' from order detail
 */
export enum NebaGuideType {
	NEBA_INTERNET = 1,
	NEBA_INTERNET_AND_DECO = 2,
	NEBA_INTERNET_AND_DECO_GEN3 = 3,
	NO_GUIDE = 0,
}

/**
 * Possible values for Field 'guiaAutoiNebaTv' from order detail
 */
export enum NebaTvGuideType {
	NEBA_DECO_UNICAST = 1,
	NEBA_DECO_MULTICAST = 2,
	NEBA_DECO_GEN3 = 3,
	NO_GUIDE = 0,
}
