<div
	[ngClass]="{
		'prevent-scrolling': dynamicTrayService?.trayData?.preventTrayInnerScrolling,
		'bold-title': dynamicTrayService?.trayData?.isBoldTitle,
		'tray-inner-content-scrolling': dynamicTrayService?.trayData?.trayInnerContentScrolling,
		'tray-body-min-height': dynamicTrayService?.trayData?.isContentBodyFullHeight,
		'title-18-px': dynamicTrayService?.trayData?.is18PxTitle,
		'gradient-background': dynamicTrayService?.trayData?.isGradientBackground,
		'centered-Title-With-Padding': dynamicTrayService?.trayData?.centeredTitlWithPadding,
		'remove-padding': dynamicTrayService?.trayData?.removeTrayPadding,
		'padding-9px-below-header': dynamicTrayService?.trayData?.padding9pxBelowHeader,
		'centered-title-when-scroll-to-full-screen': dynamicTrayService?.trayData?.centeredTitleWhenScrollToFullScreen,
		'show-back-arrow-when-scroll-to-full-screen': dynamicTrayService?.trayData?.showBackArrowWhenScrollToFullScreen
	}"
>
	<vfac-overlay
		[id]="dynamicTrayService?.trayData?.overlayID"
		[ngClass]="{ 'hide-dark-place': dynamicTrayService?.trayData?.hideDarkPlace }"
		[overlayData]="dynamicTrayService?.trayData?.basicTrayData || {}"
		[show]="showTray"
		[loaded]="!dynamicTrayService?.trayData?.showSkeletonTray"
		[page]="
			dynamicTrayService?.trayData?.basicTrayData?.showBackButton ||
			dynamicTrayService?.trayData?.showBackArrowWhenScrollToFullScreen
				? 2
				: 1
		"
		[showButtons]="
			dynamicTrayService?.trayData?.basicTrayData?.primaryButton ||
			dynamicTrayService?.trayData?.basicTrayData?.secondaryButton
		"
		[greyMode]="dynamicTrayService?.trayData?.isGreyContentBackground"
		(closed)="closeOverlay()"
		(backPage)="backOverlay()"
		(primaryBtnClicked)="primaryButtonClicked()"
		(secondaryBtnClicked)="secondaryButtonClicked()"
	>
		<div header *ngIf="dynamicTrayService?.trayData?.showCustomTrayHeader">
			<div class="container">
				<div class="skeleton title-skeleton"></div>
				<div class="skeleton close-skeleton"></div>

				<div></div>
			</div>
		</div>
		<div body>
			<div #dynamicComponentRef></div>
		</div>
	</vfac-overlay>
</div>
