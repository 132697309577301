import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentTicket } from '../../../models/payment';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentSuccessDefault } from './payment-success-default';
import { PaymentSuccessInterface } from './payment-success-interface';
import { TariffService } from '../../../shared/services/tariff.service';
import { BenefitStatus } from '../../../shared/enums/benefit-status.enum';
import { SubscriptionService } from '../../../core/services/subscription.service';

export class PaymentSuccessPrePaid extends PaymentSuccessDefault implements PaymentSuccessInterface {
	constructor(
		public payNavSer: PaymentNavigationService,
		public translate: TranslateService,
		public router: Router,
		public tariffService: TariffService,
		public subscriptionService:SubscriptionService,
	) {
		super(payNavSer, translate, router);
	}
	/**
	 * the default implmentaion of the first btn in success payment screen
	 */
	firstBtnAction(): void {
		this.payNavSer.openPaymentComponent(PaymentPages.ticket);
	}

	/**
	 * action for x btn click on overlay
	 */
	closeBtnAction(): void {
		if (
			this.tariffService?.Tariff?.benefitStatus &&
			this.tariffService?.Tariff?.benefitStatus?.toLowerCase() === BenefitStatus.active.toLowerCase() && !this.subscriptionService.smartPay
		) {
			this.payNavSer.openPaymentComponent(PaymentPages.SmartPayPromoAfterTopUp);
		} else {
			this.payNavSer.closePayment();
		}
	}
	/**
	 * default omplmentaion here is for post Paid
	 */
	fillTicketOverlay(): PaymentTicket {
		const paymentTicket: PaymentTicket = new PaymentTicket();
		this.translate.get(this.payNavSer.getWcsPath(PaymentPages.ticket)).subscribe((data) => {
			paymentTicket.subtitle = data.subtitle;
			paymentTicket.firstBtnText = data.button1;
			paymentTicket.secondBtnText = data.button2;
			paymentTicket.toast = data.toast;
			paymentTicket.ticketNote = '';
			paymentTicket.ticketDetails.push({
				key: data.ecommerceCompanyText,
				value: data.ecommerceCompanyDes,
				spaceFromLeft: 90,
			});
			paymentTicket.ticketDetails.push({
				key: data.PaymentTypeTitle,
				value: data.PaymentTypeDes,
				spaceFromLeft: 90,
			});
			paymentTicket.ticketDetails.push({
				key: data.paymentIdTitle,
				value: this.payNavSer.thirdPartyURLParams.transactionId,
				spaceFromLeft: 92,
			});
			paymentTicket.ticketDetails.push({
				key: data.dateAndTimeTitle,
				value: this.payNavSer.thirdPartyURLParams.transactionDate,
				spaceFromLeft: 107,
			});
			paymentTicket.ticketDetails.push({
				key: data.cardNumberTitle,
				value: this.payNavSer.thirdPartyURLParams.cardMask.slice(6),
				spaceFromLeft: 145,
			});
			paymentTicket.ticketDetails.push({
				key: data.rechargeAmountTitle,
				value: this.payNavSer.roundAmountToString(this.payNavSer.paymentJourneyParams.amount) + '€',
				spaceFromLeft: 75,
			});
			paymentTicket.ticketDetails.push({
				key: data.rechargeNumber,
				value: this.payNavSer.paymentJourneyParams.buyer.serviceId,
				spaceFromLeft: 148,
			});
		});
		if (
			this.tariffService?.Tariff?.benefitStatus &&
			this.tariffService?.Tariff?.benefitStatus?.toLowerCase() === BenefitStatus.active.toLowerCase() && !this.subscriptionService.smartPay
		) {
			paymentTicket.secondBtnAction = () => this.payNavSer.openPaymentComponent(PaymentPages.SmartPayPromoAfterTopUp);
		} else {
			paymentTicket.secondBtnAction = this.secondBtnAction.bind(this);
		}
		return paymentTicket;
	}
}
