import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DynamicTrayService } from '../../shared/services/dynamic-tray.service';
import { AppService } from '../../app.service';

@Component({
	selector: 'sp-politica-zone-one',
	templateUrl: './politica-zone-one.component.html',
	styleUrls: ['./politica-zone-one.component.scss'],
})
export class PoliticaZoneOneComponent implements OnInit {
	description: SafeHtml;
	description1: SafeHtml;
	description2: SafeHtml;
	itemsList: any;
	buttonText: any;

	constructor(
		public translate: TranslateService,
		public sanitizer: DomSanitizer,
		public appService: AppService,
		public dynamicTrayService: DynamicTrayService
	) {}

	ngOnInit(): void {
		this.translate.get('v10.roaming.tray.fairUsePolicyZone1').subscribe((data) => {
			this.description = this.sanitizer.bypassSecurityTrustHtml(data.description);
			this.description1 = this.sanitizer.bypassSecurityTrustHtml(data.description1);
			this.description2 = this.sanitizer.bypassSecurityTrustHtml(data.description2);
			this.buttonText = data.button;
			this.itemsList = data.itemsList;
		});
	}

	closeTray(): void {
		this.dynamicTrayService.close();
	}
}
