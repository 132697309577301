export class CommercialPriceFeeIncrease {
	public fee?: number;
	public feePromo?: number;
	public feePromoTaxes?: number;
	public feeTaxes?: number;
}
export class CommercialPrice {
	public currentFee?: number;
	public currentFeeTaxes?: number;
	public installment?: number;
	public installmentTaxes?: number;
	public installmentPromo?: number;
	public installmentPromoTaxes?: number;
	public upfront?: number;
	public upfrontTaxes?: number;
	public upfrontPromo?: number;
	public upfrontTaxesPromo?: number;
	public devicesUpfrontPromo?: number;
	public devicesUpfrontTaxesPromo?: number;
	public devicesUpfront?: number;
	public devicesUpfrontTaxes?: number;
	public singlePayment?: number;
	public singlePaymentTaxes?: number;
	public singlePaymentPromo?: number;
	public singlePaymentTaxesPromo?: number;
	public deviceFinalPayment?: number;
	public deviceFinalPaymentTaxes?: number;
	public finalPaymentMonthlyFee?: number;
	public finalPaymentMonthlyFeeTaxes?: number;
	public feeIncrease?: CommercialPriceFeeIncrease;
}
