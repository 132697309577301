import { Component } from '@angular/core';
import { MyAccountService } from '../../../my-account/my-account.service';
import { LeftMenuWs10Service } from '../../../mva10/shared/services/left-menu-ws10.service';
import { CardImageSelectorModel } from '@mva10/mva10-angular';
import { tagging } from '../../../../config/tagging-config';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { TaggingActionModel, TaggingViewModel } from '../../../models/tagging.model';
import { SidemenuConfigService } from '../../services/sidemenu-config.service';
import { DashboardService } from '../../services/dashboard.service';
import { MyAccountSections } from '../../enums/my-account-sections.enum';
import { NewTaggingHelperService } from '../../../core/services/new-tagging.helper.service';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';

@Component({
	selector: 'sp-submenu-account',
	templateUrl: './submenu-account.component.html',
	styleUrls: ['./submenu-account.component.scss'],
})
export class SubmenuAccountComponent {
	constructor(
		public myAccountService: MyAccountService,
		private leftMenuService: LeftMenuWs10Service,
		public menuService: SidemenuConfigService,
		private taggingHelper: TaggingHelperService,
		public dashboardService: DashboardService,
		private newTaggingHelperService: NewTaggingHelperService
	) {}

	myAccountSelectorCardClicked(clickedCard: CardImageSelectorModel): void {
		this.leftMenuService.closeSubmenu();
		setTimeout(() => {
			// On menu close
			this.menuService.onCarouselCardClicked(clickedCard.id);
		}, 500);
	}
}
