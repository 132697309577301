<vfac-overlay
	#checkInstallationOverlay
	[overlayData]="checkInstallationData"
	[show]="showOverlay"
	[loaded]="isLoaded"
	[loaderType]="'vodafone'"
	[page]="page"
	(closed)="page !== 2 ? onClose(page === 3 ? 'check-ok' : 'header') : null"
>
	<div body id="page1">
		<div class="installation-check">
			<div class="content">
				<div class="image">
					<img alt="" src="{{ 'v10.myOrder.checkInstallationOverlay.img.fonendo' | imgFullPath }}" />
				</div>
				<ng-container *ngIf="!ordersService.detailOrderData.esOrdenNeba">
					<div class="title mva10-u-heading--3-lt" id="checkInstallationOverlayIntroTitle">
						{{ 'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationIntroTitle' | translate }}
					</div>
				</ng-container>
				<ng-container *ngIf="ordersService.detailOrderData.esOrdenNeba">
					<div
						class="title mva10-u-heading--3-lt"
						id="checkInstallationOverlayIntroTitle"
						*ngIf="
							serviceType === schamanServiceType.NEBA_INTERNET || serviceType === schamanServiceType.NEBA_INTERNET_TV
						"
					>
						{{ 'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationRouterIntroTitle' | translate }}
					</div>
					<div
						class="title mva10-u-heading--3-lt"
						*ngIf="serviceType === schamanServiceType.NEBA_TV"
						id="checkInstallationOverlayIntroTitle"
					>
						{{ 'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationTvIntroTitle' | translate }}
					</div>
				</ng-container>
				<div class="description mva10-u--body" id="checkInstallationOverlayIntroDescription">
					{{ 'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationIntroDescription' | translate }}
				</div>
			</div>
			<div class="button-container vfes-mt">
				<mva10-c-button (mva10OnClick)="callCheckService()">
					{{ 'v10.myOrder.checkInstallationOverlay.continue' | translate }}
				</mva10-c-button>
			</div>
		</div>
	</div>

	<div body id="page2">
		<div class="checking-service">
			<div class="content">
				<div class="progress">
					<vfac-g-progress-bar
						[total]="checkStatus?.length"
						[value]="currentCheckStatusStep"
						[disableAnimation]="false"
						[title]="checkStatus[currentCheckStatusStep - 1]?.title | translate"
						[showProgress]="true"
						[styleClass]="'mva10-u--body'"
					>
					</vfac-g-progress-bar>
				</div>
				<div class="loading">
					<sp-loader-logo [lottieImageUrl]="'https://assets-es-dev.dxldev.local.vodafone.es/assets/loader-med.json'"> </sp-loader-logo>
				</div>
				<div class="description">
					<span class="description-text mva10-u-heading--5">
						{{ checkStatus[currentCheckStatusStep - 1]?.description | translate }}
					</span>
					<span class="description-wait mva10-u-heading--5">
						{{ 'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationStepWait' | translate }}
					</span>
				</div>
			</div>
		</div>
	</div>

	<div body id="page3">
		<div class="service-result">
			<ng-container *ngIf="!ordersService.detailOrderData.esOrdenNeba">
				<ng-container *ngIf="resultType === 'ok'" id="checkInstallationOverlayHfcOk">
					<sp-schaman-client-confirmation-layout
						[openFrom]="openFrom"
						[imageRoute]="'v10.myOrder.checkInstallationOverlay.img.connectivity' | imgFullPath"
						[title]="'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationConfirmationTitle' | translate"
						[description]="
							'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationConfirmationDescription' | translate
						"
						[okButtonText]="
							'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationConfirmationOkButton' | translate
						"
						(okButtonClick)="goToFinalStep()"
						(koButtonClick)="goToChatNet()"
					></sp-schaman-client-confirmation-layout>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="this.ordersService.detailOrderData.esOrdenNeba">
				<ng-container
					*ngIf="
						resultType === 'ok' &&
						(serviceType === schamanServiceType.NEBA_INTERNET || serviceType === schamanServiceType.NEBA_INTERNET_TV)
					"
					id="checkInstallationOverlayNebaNetOk"
				>
					<sp-schaman-client-confirmation-layout
						[imageRoute]="'v10.myOrder.checkInstallationOverlay.img.network' | imgFullPath"
						[title]="'v10.myOrder.checkInstallationOverlay.internetAndWifiConnectionConfirmation' | translate"
						[description]="'v10.myOrder.checkInstallationOverlay.wifiConnectionConfirmationDescription' | translate"
						[okButtonText]="'v10.myOrder.checkInstallationOverlay.nebaAutoiCheckConfirmationButtonOk' | translate"
						(okButtonClick)="goNext()"
						(koButtonClick)="goToChatNet()"
					></sp-schaman-client-confirmation-layout>
				</ng-container>
				<ng-container
					*ngIf="resultType === 'ok' && serviceType === schamanServiceType.NEBA_TV"
					id="checkInstallationOverlayNebaTvOk"
				>
					<sp-schaman-client-confirmation-layout
						[imageRoute]="'v10.myOrder.checkInstallationOverlay.img.tv_dark_theme' | imgFullPath"
						[title]="'v10.myOrder.checkInstallationOverlay.nebaAutoiCheckTvInstallationTitle' | translate"
						[description]="'v10.myOrder.checkInstallationOverlay.nebaAutoiCheckTvInstallationDescription' | translate"
						(okButtonClick)="closeTvGuideAndGoToFinalStep()"
						[okButtonText]="'v10.myOrder.checkInstallationOverlay.nebaAutoiCheckConfirmationButtonOk' | translate"
						(koButtonClick)="closeTvGuideAndGoToChat()"
					></sp-schaman-client-confirmation-layout>
				</ng-container>

				<ng-container
					*ngIf="
						resultType === 'ok' &&
						serviceType !== schamanServiceType.NEBA_TV &&
						serviceType !== schamanServiceType.NEBA_INTERNET &&
						serviceType !== schamanServiceType.NEBA_INTERNET_TV
					"
					id="checkInstallationOverlayNebaGenericOk"
				>
					<div class="content result-ok">
						<div class="image">
							<img
								[attr.src]="'v10.myOrder.checkInstallationOverlay.img.check_ok' | imgFullPath"
								alt="Check OK"
								id="imageCheckOk"
							/>
						</div>
						<div class="title vfes-title--bold mva10-u-heading--3" id="checkInstallationOkTitle">
							{{ 'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationOkTitle' | translate }}
						</div>
						<div class="description mva10-u--body" id="checkInstallationOverlayOkDescription">
							{{ 'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationOkDescription' | translate }}
						</div>
					</div>
					<div class="button-container vfes-mt">
						<mva10-c-button (mva10OnClick)="onClose('check-ok')" id="checkInstallationOverlayOkButton">
							{{ 'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationOkButton' | translate }}
						</mva10-c-button>
					</div>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="resultType === errorText" id="checkInstallationOverlayError">
				<div class="content result-error">
					<div class="image">
						<img
							[attr.src]="'v10.myOrder.checkInstallationOverlay.img.headphones' | imgFullPath"
							alt="Headphones"
							id="checkInstallationOverlayErrorImg"
						/>
					</div>
					<div class="title vfes-title--bold mva10-u-heading--3" id="checkInstallationOverlayErrorTitle">
						{{
							(showChat
								? 'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationErrorChatTitle'
								: 'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationErrorTitle'
							) | translate
						}}
					</div>
					<div class="description mva10-u--body" id="checkInstallationOverlayErrorDescription">
						{{
							(showChat
								? 'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationErrorChatDescription'
								: 'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationErrorDescription'
							) | translate
						}}
					</div>
				</div>
				<div class="button-container vfes-mt" id="checkInstallationOverlayErrorButton">
					<mva10-c-button (mva10OnClick)="goToChat(true)">
						{{
							(showChat
								? 'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationErrorChatButton'
								: 'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationErrorButton'
							) | translate
						}}
					</mva10-c-button>
				</div>
			</ng-container>
		</div>
	</div>
	<div body id="page4">
		<div class="service-result">
			<ng-container>
				<div class="content result-ok">
					<div class="image">
						<img
							[attr.src]="'v10.myOrder.checkInstallationOverlay.img.lighttheme' | imgFullPath"
							alt="Check OK"
							id="imageLightTheme"
						/>
					</div>
					<div class="title vfes-title--bold mva10-u-heading--3">
						{{ 'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationCongratsTitle' | translate }}
					</div>
					<div class="description mva10-u--body">
						{{ 'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationCongratsDescription' | translate }}
						<div *ngIf="serviceType === schamanServiceType.NEBA_TV">
							<br />
							{{ 'v10.myOrder.checkInstallationOverlay.nebaAutioCheckInstallationCongratsTvDescription' | translate }}
							<br />
							<br />
							<div class="route">
								{{
									'v10.myOrder.checkInstallationOverlay.nebaAutioCheckInstallationCongratsTvActivationRoute' | translate
								}}
							</div>
						</div>
					</div>
				</div>
				<div class="button-container vfes-mt">
					<mva10-c-button (mva10OnClick)="onClose('check-ok')">
						{{ 'v10.myOrder.checkInstallationOverlay.autoiCheckInstallationOkButton' | translate }}
					</mva10-c-button>
				</div>
			</ng-container>
		</div>
	</div>
</vfac-overlay>
