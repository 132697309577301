import { NgModule } from '@angular/core';

import { SharedServicesPegaParserService } from '../feature/parser';
import { SharedServicesPegaReplacerService } from '../feature/replacer';

import { SharedServicesPegaService } from './shared-services-pega.service';

@NgModule({
	providers: [SharedServicesPegaService, SharedServicesPegaParserService, SharedServicesPegaReplacerService],
})
export class SharedServicesPegaModule {}
