<div class="generic-accordion">
	<div
		id="genericAccordionTitleMain"
		*ngIf="accordion?.mainTitle"
		class="title"
		[ngClass]="{ 'generic-accordion-details__main-title': accordion?.accordionCardDesc }"
	>
		{{ accordion.mainTitle }}
	</div>
	<accordion>
		<accordion-group #group>
			<div accordion-heading>
				<div *ngIf="!accordion?.url" class="item" (click)="checkisOpenAccordion(group?.isOpen)">
					<div class="accordion-header">
						<div
							[ngClass]="{
								'accordion-title-main': accordion?.isMainTitle,
								'accordion-title': !accordion?.isMainTitle && accordion?.isTariffDetails,
								'generic-accordion-details__heading': accordion?.accordionCardDesc
							}"
						>
							<mva10-c-icon *ngIf="accordion?.icon" iconId="{{ accordion.icon }}" size="25" [ngClass]="'mva10-u--mr2x'">
							</mva10-c-icon>
							<span
								id="genericAccordionTitle"
								[innerHTML]="accordion?.accordionTitle"
								*ngIf="!accordion?.url"
								class="item-text"
								[ngClass]="{
									'item-text--f-70': accordion?.icon,
									'generic-accordion-details__title': accordion?.accordionCardDesc
								}"
							>
							</span>
						</div>
						<span
							id="genericAccordionTag"
							*ngIf="accordion?.accordionTag"
							class="item-tag"
							[ngClass]="{ 'item-tag-colored': accordion?.accordionCardDesc }"
							[innerHTML]="accordion?.accordionTag"
						>
						</span>
					</div>
					<div class="chevron-text">
						<span class="chevron-text__title">
							{{ !group?.isOpen ? accordion?.chevronTextClosed : accordion?.chevronTextOpened }}
						</span>
						<em
							id="genericAccordionOpenIcon"
							*ngIf="!accordion?.url"
							class="icon"
							[ngClass]="{ 'icon-chevron-up': group?.isOpen, 'icon-chevron-down': !group?.isOpen }"
						>
						</em>
					</div>
				</div>
				<p id="genericAccordionDesc" class="generic-accordion-details__desc">{{ accordion?.accordionCardDesc }}</p>
				<div *ngIf="!lastItem" class="separator">
					<hr *ngIf="!group?.isOpen && !accordion?.isTariffDetails" />
				</div>
			</div>
			<div
				id="genericAccordionDesc"
				*ngIf="accordion?.htmlContent"
				class="description"
				[ngClass]="{ 'generic-accordion-details__inner-desc': accordion?.accordionCardDesc }"
				[innerHtml]="accordion?.htmlContent | safe : 'html'"
			></div>
			<div
				id="genericAccordionSecondParagraph"
				*ngIf="accordion?.secondParagraph"
				class="second-desc"
				[innerHtml]="accordion?.secondParagraph | safe : 'html'"
			></div>
			<div
				(click)="accordion?.secBtnAction()"
				id="genericAccordionLinkedText"
				*ngIf="accordion?.linkText"
				class="linked-text"
				[innerHtml]="accordion.linkText | safe : 'html'"
			></div>
			<div
				(click)="accordion?.firstBtnAction()"
				id="genericAccordionSeconfLinkedText"
				*ngIf="accordion?.secondLinkText"
				class="linked-text"
				[innerHtml]="accordion.secondLinkText | safe : 'html'"
			></div>
			<hr class="group-open-hr" *ngIf="group?.isOpen && !accordion?.isTariffDetails" />
		</accordion-group>
	</accordion>
	<div *ngIf="accordion?.url" class="item" (click)="openLink(accordion.url)">
		<span class="item-text"> {{ accordion.accordionTitle }} </span>
		<i class="icon icon-chevron-right"> </i>
	</div>
</div>
