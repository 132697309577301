import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { PaymentPages } from '../../shared/enums/payment-pages.enum';
import { PaymentWalletService } from '../../shared/services/payment-wallet.service';
import { PaymentNavigationService } from '../services/payment-navigation.service';

@Component({
	selector: 'sp-payment-wallet-chevron',
	templateUrl: './payment-wallet-chevron.component.html',
	styleUrls: ['./payment-wallet-chevron.component.scss'],
})
export class PaymentWalletChevronComponent implements OnInit, OnDestroy {
	/** This input for adding margin top because we can't add margin top to custom this component's directive in its parent */
	@Input() marginTop: number;
	/** This output to be emitted when chevron clicked to open wallet saved cards screen */
	@Output() chevronClicked: EventEmitter<void> = new EventEmitter<void>();
	/** This output to be emitted when chevron data loaded to open wallet saved cards screen */
	@Output() chevronDataLoaded: EventEmitter<void> = new EventEmitter<void>();
	/** selected card from wallet saved cards */
	selectedCardNumber: string;
	/** selected type of card from wallet saved cards (master, visa, ...) */
	selectedCardImage: string;
	/** Subscriber to be unsubscribed on destroy */
	$subscriber: Subscription;

	constructor(
		private paymentWalletService: PaymentWalletService,
		private paymentNavigationService: PaymentNavigationService
	) {}

	ngOnInit() {
		/** set chevron data if saved cards list is loaded, or load cards list (for the first time only) then set chevron data */
		this.$subscriber = this.paymentWalletService.selectedCardTextAndIcon.subscribe(
			() => this.setChevronData(),
			(error: any) => this.chevronDataLoaded.emit()
		);
	}

	/** Set text and icon of chevron */
	setChevronData(): void {
		this.selectedCardNumber = this.paymentWalletService.selectedCardText;
		this.selectedCardImage = this.paymentWalletService.selectedCardIcon;

		this.chevronDataLoaded.emit();
	}

	/** Open saved credit cards list and send track tagging for prepaid */
	navigateToWalletScreen(): void {
		this.paymentNavigationService.openPaymentComponent(PaymentPages.wallet);
		this.chevronClicked.emit();
	}

	ngOnDestroy(): void {
		if (this.$subscriber) {
			this.$subscriber.unsubscribe();
		}
	}
}
