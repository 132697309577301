import { Component, OnInit } from '@angular/core';
import { GenereicAccordionModel } from '../../shared/models/generic-accordion.model';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../app.service';
import { UtilitiesService } from '../../shared/utils/utilities.service';

@Component({
	selector: 'sp-privacy-policy',
	templateUrl: './privacy-policy.component.html',
	styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
	privayPolicies: GenereicAccordionModel[] = [];
	showTopDiv: boolean;
	constructor(
		private translate: TranslateService,
		public utilitiesService: UtilitiesService,
		public appService: AppService
	) {}

	ngOnInit() {
		this.showTopDiv = false;
		this.translate.get('v10.legal.privacyPolicy.web.itemList').subscribe((items: any[]) => {
			items.forEach((list: any) => {
				let title = list.title;
				list.headerArr.forEach((el) => {
					this.privayPolicies.push({
						mainTitle: title,
						accordionTitle: el.title,
						htmlContent: el.description,
						url: el.url,
					});
					title = '';
				});
			});
		});
	}
}
