import { DataSharingType } from './../shared/enums/data-sharing-type.enum';
import { BundleType } from '../shared/enums/bundleType.enum';
import { ProductType } from '../shared/enums/productType.enum';
import { ProductCategory } from '../shared/enums/productCategory.enum';
import { BundleStatus } from '../shared/enums/bundleStatus.enum';
import { CallType } from '../shared/enums/callType.enum';
import { WorryFree } from './worryFree.model';
import { OnlineTv } from './online-tv.model';
import { Mobility } from './mobility.model';
import { ProductSubtype } from '../shared/enums/product-subtype.enum';
import { ProductRestriction } from './products-restriction.model';
import { ProductAlert } from './product-alert.model';
import { TVDiscounts } from './tv-discounts.model';
import { BillinDiscounts } from './billin-discounts.model';
import { BillinCustomerAcount } from './billin-customer-acount.model';
import { ShippingModesTv } from '../shared/enums/shipping-modes-tv.enum';
import { Channels } from './channels.model';
import { OpAddon } from './opAddon.model';
import { TVPromotions } from './tv-promotions.model';
export class Product {
	public Name: string;
	public serviceType?: string;
	public additionalInfo?: string;
	public defaultSVACode?: string;
	public billinDiscount?: BillinDiscounts;
	public billinCustomerAcount?: BillinCustomerAcount;
	public isOneProfesional?: boolean;
	public Icon?: string;
	public MediumIcon?: string;
	public ColorHex?: string;
	public wcsTitle?: string;
	public wcsDescription?: string;
	public Type: BundleType;
	public Code: string;
	public TypeName: string;
	public Interval?: string;
	public CallType: CallType;
	public ProductType: ProductType;
	public ProductCategory: ProductCategory;
	public lastStatus: BundleStatus;
	public currentStatus: BundleStatus;
	public ChannelDeactivationLocked?: boolean;
	public ChannelDeactivationLockedCode?: string;
	public transactionStatus: BundleStatus;
	public Special: boolean;
	public TotalConsumption: number;
	public RemainConsumptionMinutes?: number;
	public RemainConsumptionData?: number;
	public RemainConsumptionUnit?: string;
	public RemainingConsumptionDataShareing?: number;
	public ConsumedConsumption: number;
	public RemainingConsumptionPercentage: number;
	public ConsumedConsumptionUnit: string;
	public TotalConsumptionUnit: string;
	public ConsumptionExpiryDate: string;
	public ShortDescription: string;
	public LongDescription: string;
	public PayPerUse: boolean;
	public CallSetup: string;
	public CallSetupCurrerncy: string;
	public CallSetupUnit: string;
	public IsUnLimited?: boolean;
	public fee: string;
	public feeUnit: string;
	public discountedFee?: number;
	public EnjoyMoreRenewalDate: string;
	public IsLimited: boolean;
	public EnjoyMoreIncludedApplicationsNames: string[];
	public worryFree?: WorryFree;
	public serviceID: string;
	public onlineTv?: OnlineTv;
	public benefitsFlag?: boolean;
	public withBenefit?: boolean;
	public activationDate?: string;
	public isShared?: boolean;
	public sharingDataType?: DataSharingType;
	public monthlyCurrency?: string;
	public productExpirationDate?: Date;
	public RemainConsumptionExtraVoice?: number;
	public title?: string;
	public titleLimit?: string;
	public titleRate?: string;
	public mobility?: Mobility;
	public subType?: ProductSubtype;
	public enjoyMoreActiveIcon?: string;
	public enjoyMoreInactiveIcon?: string;
	public enjoyMoreBlackIcon?: string;
	public Email?: string;
	public ableToBook?: boolean;
	public oneMonthPromotionCode?: string;
	public oneMonthPromotionStatus?: string;
	public canBeDeactivated?: boolean;
	public noConsumption?: boolean;

	public tvDiscounts?: TVDiscounts;
	public tvPromotions?: TVPromotions;
	public deviceName?: string;
	public wcsSubTitle?: string;
	public wcsTitleDetail?: string;
	public wcsTitleIncludedChannels?: string;
	public wcsImagesThirds?: Array<string>;
	public wcsImagesDetail?: Array<string>;

	public Restrictions?: ProductRestriction;
	public Order?: string;
	public OrderCreationDate?: string;
	public productAlert?: ProductAlert;
	public incompatibilities?: string[];
	public selected?: boolean;
	public voucherCode?: string;
	public availableShipping?: ShippingModesTv;
	public offeringCode?: string;
	public channelsTV?: Channels[];
	public addons?: OpAddon[];
	public isInPlan?: boolean;
}
