import { Component, Input, OnInit } from '@angular/core';
@Component({
	selector: 'sp-skeleton',
	templateUrl: './sp-skeleton.component.html',
	styleUrls: ['./sp-skeleton.component.scss'],
})
export class SpSkeletonComponent implements OnInit {
	@Input() imageSize: number;
	@Input() numberOfBars: number;
	skeletonArray: number[];

	ngOnInit(): void {
		this.imageSize = this.imageSize ?? 32;
		this.skeletonArray = Array.from({ length: this.numberOfBars || 2 });
	}
}
