import { CrossFunctionalityService } from './../../services/cross-functionality.service';
import { CustomerAccountService } from './../../services/customer-account.service';
import { CustomerType } from './../../enums/customerType.enum';
import { StorageService } from './../../../core/services/storage.service';
import { AppService } from './../../../app.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SpModalFullComponent } from '../../components/sp-modal-full/sp-modal-full.component';
import { Router } from '@angular/router';
import { config } from '../../../../config/pages-config';
import { TranslateService } from '@ngx-translate/core';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { tagging } from '../../../../config/tagging-config';
import { DynamicTrayService } from '../../services/dynamic-tray.service';
import { IGenericSuccessErrorTray } from '../../../models/generic-success-error-tray.model';
import { ResizeService } from '../../../mva10/shared/services/resize.service';
import { Breakpoints } from '@mva10/mva10-angular';
import { UtilitiesService } from '../../utils/utilities.service';
import { Safari } from '../../constants/defines';

@Component({
	selector: 'sp-gdpr-modal',
	templateUrl: './gdpr-modal.component.html',
	styleUrls: ['./gdpr-modal.component.scss'],
})
export class GdprModalComponent implements OnInit, AfterViewInit {
	@ViewChild('fullModal') fullModal: SpModalFullComponent;
	imgSrc: string;
	wcsText: string;
	showSpinner: boolean;
	/** tray data is a variable for passing data to tray */
	trayData: IGenericSuccessErrorTray;
	/** isMobile is to determine if the device is mobile or not */
	isMobile: boolean;
	sizeBreakDown: Breakpoints;
	isTabletORDesktop: boolean;
	browserName: string;
	isSafari: boolean;

	constructor(
		public appService: AppService,
		private router: Router,
		private storageService: StorageService,
		private cutomerAccountService: CustomerAccountService,
		private cross: CrossFunctionalityService,
		private translate: TranslateService,
		private tealium: TaggingHelperService,
		private dynamicTrayService: DynamicTrayService,
		private resizeService: ResizeService,
		private utilities: UtilitiesService
	) {}

	ngOnInit() {
		this.resizeService.onResize$.subscribe((size: Breakpoints) => {
			this.sizeBreakDown = size;
			this.trayData.imgMarginTop = this.isMobile ? 0 : -55;
			this.trayData.imgMarginBottom = this.isMobile ? 0 : -15;
			this.isTabletORDesktop = this.sizeBreakDown >= Breakpoints.TABLET;
		});
		this.isMobile = this.resizeService.breakpoint < Breakpoints.TABLET;
		this.browserName = this.utilities.browserName();
		this.isSafari = this.browserName.toLowerCase() === Safari.toLowerCase();
		this.drawTray();
	}
	navigatetoDashboard() {
		if (this.router.url !== config.Dashboard.route) {
			this.appService.showFullAppLoader = true;
			this.router.navigate([config.Dashboard.route]);
		}
	}
	ngAfterViewInit() {
		this.dynamicTrayService.openSuccessErrorTray(this.trayData);
	}

	xButtonClick() {
		this.tealium.track(
			tagging.gdpr.overlay.xButton.eventName,
			tagging.gdpr.overlay.xButton.data,
			tagging.gdprOverlayPage
		);
		this.appService.gdprOverlayLoaded = true;
		this.dynamicTrayService.close();
	}

	close() {
		if (!this.showSpinner) {
			this.fullModal.hide();
			this.appService.gdprOverlayLoaded = true;
		}
	}
	goToGDPRMoreInformation() {
		this.tealium.track(
			tagging.gdpr.overlay.goToPermissionsAndPreferences.eventName,
			tagging.gdpr.overlay.goToPermissionsAndPreferences.data,
			tagging.gdprOverlayPage
		);
		this.appService.showFullAppLoader = true;
		this.router.navigate([config.myAccount.permissions.route]);
		this.appService.gdprOverlayLoaded = true;
		this.dynamicTrayService.close();
	}
	enableGDPRPermission() {
		this.tealium.track(
			tagging.gdpr.overlay.confirmPermission.eventName,
			tagging.gdpr.overlay.confirmPermission.data,
			tagging.gdprOverlayPage
		);
		this.cross.productID = config.dashboard.name.toLowerCase();
		this.cutomerAccountService.enableGDPRPermission().subscribe(
			() => {
				this.appService.gdprOverlayLoaded = true;
				this.cross.productID = config.dashboard.name.toLowerCase();
				this.translate.get('v10.gdpr.tray.permitConfirmationSuccess').subscribe((data) => {
					const sucessTray: IGenericSuccessErrorTray = {
						title: data.title,
						boldCenterTitle: data.subtitle,
						isSuccess: true,
						imgMarginTop: this.isMobile ? 0 : -55,
						imgMarginBottom: this.isMobile ? 0 : -15,
						firstDescription: data.description,
						trayMarginbottom: this.isSafari ? '32px' : '0px',
						minimumVhHeight: 50,
						primaryButton: {
							primaryButtonText: data.button1,
							primaryButtonAction: () => {
								this.xButtonClick();
							},
						},
						closeButtonAction: () => {
							this.xButtonClick();
							this.navigatetoDashboard();
						},
					};
					this.resizeService.onResize$.subscribe((size: Breakpoints) => {
						this.isMobile = size < Breakpoints.TABLET; // mobile view
						sucessTray.imgMarginTop = this.isMobile ? 0 : -55;
						sucessTray.imgMarginBottom = this.isMobile ? 0 : -15;
					});
					this.dynamicTrayService.openSuccessErrorTray(sucessTray);
				});
			},
			(error) => {
				this.appService.gdprOverlayLoaded = true;
				this.cross.productID = config.dashboard.name.toLowerCase();
				this.translate.get('v10.gdpr.tray.PermitConfirmationFailure').subscribe((data) => {
					const ErrorTray: IGenericSuccessErrorTray = {
						title: data.title,
						boldCenterTitle: data.subtitle,
						imgMarginTop: this.isMobile ? 0 : -55,
						imgMarginBottom: this.isMobile ? 0 : -15,
						isSuccess: false,
						trayMarginbottom: this.isSafari ? '32px' : '0px',
						minimumVhHeight: 50,
						firstDescription: data.description,
						primaryButton: {
							primaryButtonText: data.button1,
							primaryButtonAction: () => {
								this.xButtonClick();
							},
						},
						closeButtonAction: () => {
							this.xButtonClick();
							this.navigatetoDashboard();
						},
					};
					this.resizeService.onResize$.subscribe((size: Breakpoints) => {
						this.isMobile = size < Breakpoints.TABLET; // mobile view
						ErrorTray.imgMarginTop = this.isMobile ? 0 : -55;
						ErrorTray.imgMarginBottom = this.isMobile ? 0 : -15;
					});
					this.dynamicTrayService.openSuccessErrorTray(ErrorTray);
				});
			}
		);
	}

	drawTray(): void {
		this.tealium.track(
			tagging.gdpr.overlay.overlayGDPRImpressions.eventName,
			tagging.gdpr.overlay.overlayGDPRImpressions.data,
			tagging.gdprOverlayPage
		);
		const hasMultipleSites =
			this.storageService.userProfile &&
			this.storageService.userProfile.sites &&
			this.storageService.userProfile.sites.length > 1;
		this.translate.get('GDPR.GDPR.messagesList').subscribe((data) => {
			this.trayData = {
				staticImagePath: data.authorizedOneSiteOverlay.icon.url,
				staticImageNoPadding: true,
				imgMarginTop: this.isMobile ? 0 : -55,
				imgMarginBottom: this.isMobile ? 0 : -15,
				trayMarginbottom: this.isSafari ? '32px' : '0px',
				minimumVhHeight: 50,
				primaryButton: {
					primaryButtonText: data.authorizedOneSiteOverlay.button1.text,
					primaryButtonAction: () => {
						this.enableGDPRPermission();
					},
					width: this.isTabletORDesktop ? '100%' : '251px !important',
					height: 44,
				},
				secondaryButton: {
					secondaryButtonText: data.authorizedOneSiteOverlay.button2.text,
					secondaryButtonAction: () => {
						this.goToGDPRMoreInformation();
					},
					width: this.isTabletORDesktop ? '100%' : '251px !important',
					height: 44,
				},
				closeButtonAction: () => {
					this.xButtonClick();
					this.navigatetoDashboard();
				},
			};
			if (this.storageService.userProfile.customerType) {
				const userType = this.storageService.userProfile.customerType.toLowerCase();
				if (userType === CustomerType.Consumer.toLowerCase()) {
					if (hasMultipleSites) {
						this.trayData.title = data.consumerMultipleSiteOverlay.title;
						this.trayData.secondDescription = data.consumerMultipleSiteOverlay.description;
						this.trayData.boldCenterTitle = data.consumerMultipleSiteOverlay.subtitle;
					} else {
						this.trayData.title = data.consumerOneSiteOverlay.title;
						this.trayData.secondDescription = data.consumerOneSiteOverlay.description;
						this.trayData.boldCenterTitle = data.consumerOneSiteOverlay.subtitle;
					}
				} else if (userType === CustomerType.Authorized.toLowerCase()) {
					if (hasMultipleSites) {
						this.trayData.title = data.authorizedMultipleSiteOverlay.title;
						this.trayData.secondDescription = data.authorizedMultipleSiteOverlay.description;
						this.trayData.boldCenterTitle = data.authorizedMultipleSiteOverlay.subtitle;
					} else {
						this.trayData.title = data.authorizedOneSiteOverlay.title;
						this.trayData.secondDescription = data.authorizedOneSiteOverlay.description;
						this.trayData.boldCenterTitle = data.authorizedOneSiteOverlay.subtitle;
					}
				}
			}
		});
	}
}
