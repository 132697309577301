<div id="overlay" #overlay>
	<div id="stepOne">
		<div class="close-icon">
			<span class="modal-full-close close" type="button" aria-label="Close" (click)="dismiss()">
				<img src="https://assets-es-dev.dxldev.local.vodafone.es/assets/images/close-32.png" />
			</span>
		</div>
		<div class="clearfix"></div>
		<div id="stepOneTitle">
			<p>{{ 'login.upgradeProfile.title' | translate }}</p>
		</div>
		<div id="stepOneDesc">
			<p>{{ 'login.upgradeProfile.fieldsList.upgradeDesc.body' | translate }}</p>
		</div>

		<div class="make-label-white" style="overflow: auto">
			<form
				style="overflow: auto"
				class="dropdown-form login-form-upgrade"
				[formGroup]="form"
				novalidate="true"
				(ngSubmit)="submit()"
			>
				<sp-dropdown
					id="StepTwoComp_dropdown_DNI"
					[defaultOptionText]="defultOption"
					(change)="onChange($event.target)"
					formControlName="dniType"
					[name]="'ddl1'"
					label="{{ 'login.upgradeProfile.fieldsList.docType.label' | translate }}"
					[items]="documentTypes"
					[isDisabled]="false"
				>
				</sp-dropdown>
				<sp-text
					class="text-in-step-two"
					id="StepTwoComp_txt_dni"
					[max]="maxLength"
					id="txt_dni"
					patternErrorMessage="{{ 'login.messagesList.upgradeDocTypeErrMessage.description' | translate }}"
					isUpperCase="true"
					[ngModel]="value"
					(ngModelChange)="value = $event"
					[enforceValidation]="showValidation"
					label="{{ 'login.upgradeProfile.fieldsList.pinLabel.body' | translate }}"
					*ngIf="showInput"
					[pattern]="pattern"
					formControlName="dni"
					[isRequired]="isRequired"
					[showInlineHint]="showBackEndValidation"
					[hintText]="backEndValidationText"
					[showBackEndError]="showBackEndValidation"
					[hideRedBorder]="hideRedBorder"
					(contentChange)="resetFormValidation()"
				>
				</sp-text>
				<div class="form-group col-xs-12">
					<button id="StepTwoComp_btn_register" class="registration-btn" [disabled]="!value">
						{{ 'login.upgradeProfile.buttonsList.continueButton.text' | translate }}
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
<sp-modal
	#modal
	*ngIf="message"
	bodyIcon="icon icon-tick-outline"
	bodyTitle="{{ 'login.messagesList.upgradeSuccess.title' | translate }}"
	bodyContent="{{ 'login.messagesList.upgradeSuccess.description' | translate }}"
	primaryButton="true"
	primaryButtonText="{{ 'login.messagesList.upgradeSuccess.confirmButton.text' | translate }}"
	[primaryButtonClick]="accept"
	primaryButtonStyle="btn registration"
	[closeButtonClick]="destroy"
>
</sp-modal>
