<div class="success__container">
	<div class="success__cont">
		<div class="success__icon">
			<sp-lottie-loader [lottieImageUrl]="paySuccessSer.currnetSuccess.iconUrl" [loop]="false"> </sp-lottie-loader>
		</div>
		<div class="success__desc-cont">
			<p class="success__title" [innerHTML]="paySuccessSer.currnetSuccess.title"></p>
			<p
				class="success__desc hide-break-point-tablet-desktop"
				[innerHTML]="paySuccessSer.currnetSuccess.desc"
				*ngIf="paySuccessSer.currnetSuccess.desc"
			></p>
		</div>
	</div>
	<div class="success__toggle-and-btns-cont" [ngClass]="{ 'sm-spaces': paySuccessSer.currnetSuccess.desc }">
		<div class="success__save-card-container" *ngIf="showSaveCardToggle">
			<p class="success__save-card-text" [innerHTML]="paySuccessSer.currnetSuccess.walletToggleTitle"></p>
			<sp-toggle-button
				[disable]="false"
				(option)="onToggleBtnChange($event)"
				[check]="paySuccessSer.currnetSuccess.isSuccessToggleON"
				class="toggel"
			>
			</sp-toggle-button>
		</div>
		<div class="success__btns-cont" [ngClass]="{ 'margin-sm': paySuccessSer.currnetSuccess.desc }">
			<div
				class="success__first-btn"
				[ngClass]="{ 'add-btn-width-success': paySuccessSer.currnetSuccess.secondBtnText }"
			>
				<mva10-c-button
					[body]="paySuccessSer.currnetSuccess.firstBtnText"
					[styleClass]="'mva10-c-button--primary'"
					(click)="onFirstBtnClick(); sendCaptureResponse()"
				>
				</mva10-c-button>
			</div>
			<div
				class="success__second-btn"
				*ngIf="paySuccessSer.currnetSuccess.secondBtnText && paySuccessSer.currnetSuccess.secondBtnText !== ' '"
				[ngClass]="{ 'add-btn-width-success': paySuccessSer.currnetSuccess.secondBtnText }"
			>
				<mva10-c-button
					[body]="paySuccessSer.currnetSuccess.secondBtnText"
					[styleClass]="'mva10-c-button--tertiary'"
					(click)="onSecondBtnclick()"
				>
				</mva10-c-button>
			</div>
		</div>
	</div>
</div>
