<section id="topUpSpendNewBalanceContainer" class="spendnewbalance-container">
	<div>
		<img
			id="topUpSpendNewBalanceImg"
			class="spendnewbalance-container__img"
			[src]="'v10.payment.counter.counterResponse.icon' | imgFullPath"
		/>
	</div>
	<p id="topUpSpendNewBalanceTitle" class="spendnewbalance-container__title">
		{{ 'v10.payment.counter.counterResponse.title' | translate }}
	</p>
	<p
		id="topUpSpendNewBalanceChangedDesc"
		class="spendnewbalance-container__desc"
		[innerHTML]="isBalanceChanged ? descriptionNewBalance : ('v10.payment.counter.counterResponse.equal' | translate)"
	></p>
	<div *ngIf="isBalanceChanged" class="spendnewbalance-container__btns">
		<div class="spendnewbalance-container__first-btn">
			<mva10-c-button
				id="topUpSpendNewBalanceRenewBenefitBtn"
				(click)="navigateToBenefitRenewal()"
				[styleClass]="'mva10-c-button--primary'"
				[body]="'v10.payment.counter.counterResponse.renewButton' | translate"
				[title]="'v10.payment.counter.counterResponse.renewButton' | translate"
			>
			</mva10-c-button>
		</div>
		<mva10-c-button
			id="topUpSpendNewBalanceExtrasBtn"
			(click)="navigateToExtrasList()"
			[styleClass]="'mva10-c-button--tertiary'"
			[body]="'v10.payment.counter.counterResponse.extraButton' | translate"
			[title]="'v10.payment.counter.counterResponse.extraButton' | translate"
		>
		</mva10-c-button>
	</div>
	<div *ngIf="!isBalanceChanged" class="spendnewbalance-container__btns dismiss">
		<mva10-c-button
			id="topUpSpendNewBalanceDismissBtn"
			[styleClass]="'mva10-c-button--primary'"
			(click)="closeScreen()"
			[body]="'v10.payment.counter.counterResponse.cancelButton' | translate"
			[title]="'v10.payment.counter.counterResponse.cancelButton' | translate"
		>
		</mva10-c-button>
	</div>
</section>
