export enum InteractionType {
	NEBA = 'NEBA',
	KO_WS_SCHAMAN = 'SegtoPedidoApp01',
	TIMEOUT_DIAGNOSTICO = 'SegtoPedidoApp02',
	SOLVED = 'SegtoPedidoApp03',
	SOLVED_REBOOT_CPE = 'SegtoPedidoApp04',
	SOLVED_REBOOT_STB = 'SegtoPedidoApp05',
	TRANSFER_KO = 'SegtoPedidoApp06',
	TRANSFER_OK = 'SegtoPedidoApp07',
	ERROR = 'SegtoPedidoApp08',
	ENTRADA_HDMI_KO = 'SegtoPedidoApp09',
	HDMI_KO = 'SegtoPedidoApp10',
	STB_ENCENDIDO_KO = 'SegtoPedidoApp11',
	CLIENTE_OK_NET = 'SegtoPedidoApp12',
	CLIENTE_OK_TV = 'SegtoPedidoApp13',
	CLIENTE_KO_NET = 'SegtoPedidoApp14',
	CLIENTE_KO_TV = 'SegtoPedidoApp15',
	KO_NEBA = 'SegtoPedidoApp16',
	CLIENTE_OK_ALL = 'SegtoPedidoApp17',
	CLIENTE_KO_ALL = 'SegtoPedidoApp18',
	SCORING = 'Scoring',
}
