import { Component } from '@angular/core';
import { DynamicTrayService } from '../../services/dynamic-tray.service';

@Component({
	selector: 'sp-more-info-tray',
	templateUrl: './sp-more-info-tray.component.html',
	styleUrls: ['./sp-more-info-tray.component.scss'],
})
export class MoreInfoTrayComponent {
	/**
	 * this class is a helper component for let us easily deal with more information trays
	 * @param dynamicTrayService that has all data and functionality of tray
	 */
	constructor(public dynamicTrayService: DynamicTrayService) {}
}
