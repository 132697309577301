<vfac-overlay
	[show]="showOverlay"
	[loaded]="!showLoaded"
	[page]="page"
	[overlayData]="overlayData"
	(backPage)="onOverlayBackPage()"
	(closed)="closeOverlay()"
>
	<div body id="page1">
		<img
			class="landline-portability__img vfes-pr"
			src="{{
				appService.getImgFullPath('v10.myOrder.newLandlinePortabilityGuide.carousel.img.landline1' | translate)
			}}"
			alt="instalación de los equipos"
		/>
		<span class="installation__title"> {{ 'v10.myOrder.newLandlinePortabilityGuide.select' | translate }} </span>
		<mva10-c-card class="installation__card" (click)="technicianWillComeHouse()">
			<span> {{ 'v10.myOrder.newLandlinePortabilityGuide.technician' | translate }} </span>
			<svg class="installation__item--icon">
				<use xlink:href="#icon-chevron-right" />
			</svg>
		</mva10-c-card>

		<mva10-c-card class="installation__card" (click)="selfInstallation()">
			<span> {{ 'v10.myOrder.newLandlinePortabilityGuide.selfinstall' | translate }} </span>
			<svg class="installation__item--icon">
				<use xlink:href="#icon-chevron-right" />
			</svg>
		</mva10-c-card>
	</div>
	<div body id="page2">
		<vfac-g-progress-bar
			[total]="titleProgressBarTechnician.length"
			[value]="positionIndex + 1"
			[disableAnimation]="true"
			[showProgress]="true"
			[title]="titleProgressBarTechnician[positionIndex]"
		>
		</vfac-g-progress-bar>

		<div class="guide-portability">
			<mva10-c-carousel
				*ngIf="guideArrayTechnician?.length"
				[config]="configCarousel"
				[hasMask]="true"
				[appMobile]="false"
				[secondary]="true"
				[positionIndex]="positionIndex"
				(mva10OnChange)="showValTechnician($event.position)"
			>
				<mva10-c-carousel-item *ngFor="let item of guideArrayTechnician; let i = index">
					<div class="guide-portability__img-guide">
						<img src="{{ item.img }}" alt="{{ item.title }}" />
					</div>
					<div class="guide-portability__text">
						<p class="guide-portability__text__title">{{ item.title }}</p>
						<p [innerHTML]="item.description"></p>
						<p [innerHTML]="item.subdescription"></p>
					</div>
				</mva10-c-carousel-item>
			</mva10-c-carousel>
		</div>
	</div>
	<div body id="page3">
		<vfac-g-progress-bar
			[total]="titleProgressBarSelfInstallation.length"
			[value]="positionIndex + 1"
			[disableAnimation]="true"
			[showProgress]="true"
			[title]="titleProgressBarSelfInstallation[positionIndex]"
		>
		</vfac-g-progress-bar>

		<div class="guide-portability">
			<mva10-c-carousel
				*ngIf="guideArrayInstallation?.length"
				[config]="configCarousel"
				[hasMask]="true"
				[appMobile]="false"
				[secondary]="true"
				[positionIndex]="positionIndex"
				(mva10OnChange)="showVal($event.position)"
			>
				<mva10-c-carousel-item *ngFor="let item of guideArrayInstallation">
					<div class="guide-portability__img-guide">
						<img src="{{ item.img }}" alt="{{ item.title }}" />
					</div>
					<div class="guide-portability__text">
						<p class="guide-portability__text__title">{{ item.title }}</p>
						<p [innerHTML]="item.description"></p>
						<p [innerHTML]="item.subdescription"></p>
						<div *ngIf="item.moreInformation" class="guide-portability__information" (click)="clickMoreInformation()">
							<div class="guide-portability__information__div-img">
								<img
									class="guide-portability__img-inf"
									src="{{
										appService.getImgFullPath('v10.myOrder.newLandlinePortabilityGuide.carousel.img.circle' | translate)
									}}"
									alt=""
								/>
							</div>
							<p class="guide-portability__text_information" [innerHTML]="item.moreInformation"></p>
						</div>
					</div>
				</mva10-c-carousel-item>
			</mva10-c-carousel>
		</div>
	</div>
</vfac-overlay>

<vfac-overlay [show]="showMoreInf" [loaded]="!showLoaded" [overlayData]="overlayDataInf" (closed)="closeOverlayInfo()">
	<div body>
		<p class="guide-portability__information__title">
			{{ 'v10.myOrder.newLandlinePortabilityGuide.autoinstalltitle' | translate }}
		</p>
		<p class="guide-portability__information__description">
			{{ 'v10.myOrder.newLandlinePortabilityGuide.autoinstalldesc' | translate }}
		</p>
		<img
			class="guide-portability__information__img"
			src="{{ appService.getImgFullPath('v10.myOrder.newLandlinePortabilityGuide.img.landline' | translate) }}"
			alt=""
		/>
		<div class="guide-portability__information__button">
			<mva10-c-button [appearance]="'alt1'" (mva10OnClick)="closeOverlayInfo()">
				{{ 'v10.myOrder.newLandlinePortabilityGuide.autoinstallbutton' | translate }}
			</mva10-c-button>
		</div>
	</div>
</vfac-overlay>
