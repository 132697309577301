<div class="label-nba-wrapper">
	<mva10-c-card-label [title]="title" [largeTitle]="largeTitle" [colorBg]="colorBg">
		<div class="content-body">
			<div class="content-icon" *ngIf="iconLeft">
				<svg class="icon icon__leftIcon">
					<use [attr.xlink:href]="iconLeft" />
				</svg>
			</div>
			<div class="content-text mva10-u-heading--5" *ngIf="description" [innerHtml]="description"></div>
			<div class="content-chevron" *ngIf="showChevron">
				<svg class="icon icon__chevron-right">
					<title>Chrevon</title>
					<use xlink:href="#icon-chevron-right" />
				</svg>
			</div>
		</div>
	</mva10-c-card-label>
</div>
