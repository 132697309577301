import { Injectable } from '@angular/core';
import { SpMVA10OverlayConfigModel } from '../models/spmva10OverLayConfigModel';
import { Subject } from 'rxjs';

@Injectable()
export class SpMva10OverlayService {
	public _config: SpMVA10OverlayConfigModel;
	public _isOpened: boolean = false;
	public closeOverLaySubject: Subject<any> = new Subject<any>();
	public openOverLaySubject: Subject<any> = new Subject<any>();
	public maximizeOverLaySubject: Subject<any> = new Subject<any>();
	public trayIndex: number = 2;
	/** Incase we set a value for this variable, will remove min-height from overlay */
	public trayMaxHeight: string;
	public isTrayScrolable: boolean;
	public keepState: boolean;
	closeAnimation: Boolean;
	stopTrayAnimation = false;
	showErrorOverLay = false;
	onFirstBtnClick: () => void = null;
	onSecondBtnClick: () => void = null;
	fullScreenTray = false;
	forceCloseTray = false;
	forceOpenTray = false;
	showForwardTransition: boolean = false;
	showBackwardTransition: boolean = false;
	enableRepeatComponent: boolean;
	constructor() {}
	/**
	 * This method for openning the overlay
	 * @param trayIndex Should be set in z-index style
	 * @param trayMaxHeight Incase we set a value for this variable, will remove min-height from overlay
	 * @param isTrayScrolable To make the overlay scrollable
	 */
	open(trayIndex: number = 2, isFirstTimeOpen = false, trayMaxHeight = null, isTrayScrolable = false) {
		this.closeAnimation = false;
		this._isOpened = this.stopTrayAnimation ? false : true;
		this.trayIndex = trayIndex;
		this.forceCloseTray = false;
		this.forceOpenTray = isFirstTimeOpen ? false : true;
		this.trayMaxHeight = trayMaxHeight;
		this.isTrayScrolable = isTrayScrolable;
	}

	close(keepState = true) {
		this.closeAnimation = true;
		this.closeOverLaySubject.next(keepState);
		this._isOpened = false;
		this.forceCloseTray = true;
		this.forceOpenTray = false;

		// this timeout is only for prevent clear the overlay content before it collapse
		setTimeout(() => {
			this._config = undefined;
			this.showErrorOverLay = false;
			this.fullScreenTray = false;
		}, 1000);
	}

	configureAndOpenOverlay(
		_title: string,
		_desc: string,
		_firstBtnWCS: string,
		_secondBtnWCS: string,
		firstBtnFunction: () => void,
		secondBtnFunction: () => void
	) {
		this._config = {
			...new SpMVA10OverlayConfigModel(),
			over_tittle: _title,
			over_desc: _desc,
			over_firstBtnWCS: _firstBtnWCS,
			over_secondBtnWCS: _secondBtnWCS ? _secondBtnWCS : '',
		};
		this.open();
		if (firstBtnFunction) {
			this.onFirstBtnClick = firstBtnFunction;
		} else {
			this.onFirstBtnClick = this.close;
		}
		if (secondBtnFunction) {
			this.onSecondBtnClick = secondBtnFunction;
		} else {
			this.onSecondBtnClick = this.close;
		}
	}
	configureAndOpenOverlayIframe(url: string, headerText: string): void {
		this.openOverLaySubject.next({ url: url, headerText: headerText });
		this.open();
	}

	configureDynamicAndOpenOverlay(config: {}, firstBtnFunction: () => void, secondBtnFunction: () => void): void {
		this._config = {
			...new SpMVA10OverlayConfigModel(),
			...config,
		};

		this.showErrorOverLay = true;
		this.onFirstBtnClick = firstBtnFunction;
		this.onSecondBtnClick = secondBtnFunction;
		this.open();
	}
}
