import { CacheService } from '../utils/cache.service';
export function evict(...apis): MethodDecorator {
	return function (target: Function, key: string, descriptor: any) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: any[]) {
			// Evict all cached responses for this api
			apis.forEach((api) => {
				CacheService.evict(api);
			});
			const result = originalMethod.apply(this, args);
			return result;
		};
		return descriptor;
	};
}
