import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'sp-link-and-chevron',
	templateUrl: './link-and-chevron.component.html',
	styleUrls: ['./link-and-chevron.component.scss'],
})
export class LinkAndChevronComponent {
	/** flag to show the div with divder class */
	@Input() showDivider: boolean;
	/** Input paramater for displayed text inside link */
	@Input() linkText: string;
	@Input() imgUrl: string = '';
	@Input() id: string = '';
	@Output() linkClick: EventEmitter<any> = new EventEmitter<any>();

	linkAction(): void {
		this.linkClick.emit();
	}
}
