import { EnvironmentMock } from './environmentMock.model';

export const environmentMock: EnvironmentMock = {
	production: false,
	baseUrl: '',
	endpoints: {
		getTicket: {
			method: 'GET',
			url: 'assets/mocks/casTicket.json',
			responseType: 'json',
		},
		obtenerPedidosCliente: {
			method: 'GET',
			url: 'assets/mocks/recuperarPedidosCliente.json',
			responseType: 'json',
		},
		getDetalleSeguimientoPedido: {
			method: 'GET',
			url: 'assets/mocks/getDetalleSeguimientoPedido_guiaNebaTV.json',
			responseType: 'json',
		},
		getCitasDisponiblesOT: {
			method: 'GET',
			url: 'assets/mocks/getCitasDisponiblesOT.json',
			responseType: 'json',
		},
		cambioCitaOT: {
			method: 'GET',
			url: 'assets/mocks/respuestaGenerica.json',
			responseType: 'json',
		},
		activarRouter: {
			method: 'GET',
			url: 'assets/mocks/respuestaGenerica.json',
			responseType: 'json',
		},
		getContent: {
			method: 'GET',
			url: 'assets/mocks/wcs_redirect.json',
			responseType: 'json',
		},
		getSeguimientoPedidosContent: {
			method: 'GET',
			url: 'assets/mocks/content.json',
			responseType: 'json',
		},
		postDocumentosScoring: {
			method: 'GET',
			url: 'assets/mocks/postDocumentosScoring.json',
			responseType: 'json',
		},
		postRelanzarPortabilidad: {
			method: 'GET',
			url: 'assets/mocks/postRelanzarPortabilidad.json',
			responseType: 'json',
		},
		getCitasDisponiblesNebaOT: {
			method: 'GET',
			url: 'assets/mocks/getCitasDisponiblesNebaOT.json',
			responseType: 'json',
		},
		getPedidoHistoricoOL: {
			method: 'GET',
			url: 'assets/mocks/getPedidoHistoricoOL.json',
			responseType: 'json',
		},
		getAutoiTickets: {
			method: 'GET',
			url: 'assets/mocks/getAutoiTickets.json',
			responseType: 'json',
		},
		createAutoiTicket: {
			method: 'GET',
			url: 'assets/mocks/postCreateAutoiTicket.json',
			responseType: 'json',
		},
		getAutoiServiceTestStatus: {
			method: 'GET',
			url: 'assets/mocks/getAutoiSchamanTest.json',
			responseType: 'json',
		},
		createAutoiServiceTest: {
			method: 'GET',
			url: 'assets/mocks/postAutoiSchamanTest.json',
			responseType: 'json',
		},
		cambiarEE: {
			method: 'GET',
			url: 'assets/mocks/respuestaGenerica.json',
			responseType: 'json',
		},
		getRouterSLID: {
			method: 'GET',
			url: 'assets/mocks/getRouterSLID.json',
			responseType: 'json',
		},
		loginIntoRouter: {
			method: 'GET',
			url: 'assets/mocks/getLoginRouter.json',
			responseType: 'json',
		},
		sendSlidToRouter: {
			method: 'GET',
			url: 'assets/mocks/respuestaVacia.json',
			responseType: 'json',
		},
		postInsertInteractionSLID: {
			method: 'POST',
			url: 'assets/mocks/respuestaGenerica.json',
			responseType: 'json',
		},
		getInteractionSLID: {
			method: 'GET',
			url: 'assets/mocks/getInteractionSLID.json',
			responseType: 'json',
		},
		advanceTicketStatus: {
			method: 'POST',
			url: '/c/srv/mivodafone-eservices/webapi/eservices/avanzarOrden',
			responseType: 'json',
		},
	},
};
