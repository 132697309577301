export enum NeedBubbleHelpTabs {
	faqs = 'faq',
	chat = 'chat',
	contactus = 'contactUs',
	survey = 'survey',
	asessor = 'asessor',
	documentum = 'documentum',
	autoi = 'autoi',
	fault = 'fault',
}
