<ul class="mvf-steps" [ngClass]="stepsClass">
	<li [ngClass]="itemClass" *ngFor="let paso of orderData.pasos | orderBy : 'pasoOrden'; let last = last">
		<section [ngClass]="getStepState(paso.pasoOrden)">
			<div [ngClass]="groupClass">
				<div [ngClass]="contentClass">
					<span [ngClass]="progressBarClass" *ngIf="!last"> </span>
					<span [ngClass]="progressIconClass"> </span>
				</div>
				<span class="mvf-steps__item-text mva10-u--body"> {{ paso.tituloPaso || paso.pasoNombre }} </span>
			</div>
		</section>
		<section
			class="mvf-steps__messages mvf-steps__item-group mvf-steps__item-group--active"
			*ngIf="
				getStepState(paso.pasoOrden) == 'mvf-steps__item-group--active' &&
				!(orderData.tipo == 6 && orderData.paso == '2')
			"
		>
			<div class="mvf-steps__item-group-content">
				<span
					class="mvf-steps__item-progress-bar mvf-steps__item-progress-bar--messages"
					*ngIf="!last && !isSafari && !isIphone"
				>
				</span>
				<span
					class="mvf-steps__item-progress-bar mvf-steps__item-progress-bar--messages-ios"
					*ngIf="(!last && isSafari) || isIphone"
				>
				</span>
			</div>
			<span class="mvf-steps__item-text mvf-steps__item-text--messages">
				<div *ngIf="!orderData.mensajeEE && orderData.mostrarMensajes && !orderData.portabilidadRechazada">
					<table>
						<caption></caption>
						<tr class="mvf-steps__warnbox" *ngIf="orderData.mensaje">
							<th
								id=""
								*ngIf="
									orderData.activarRouter === RouterGuideType.NO_GUIDE ||
									orderData.activarRouter === RouterGuideType.NO_GUIDE_2
								"
							>
								<svg
									*ngIf="orderData.activarRouter === RouterGuideType.NO_GUIDE"
									class="vfes-alert-icon icon mvf-detail-tick-icon"
									xmlns="http://www.w3.org/2000/svg "
									xmlns:xlink="http://www.w3.org/1999/xlink "
									width="24px "
									height="24px "
								>
									<title>{{ 'v10.myOrder.steps.error' | translate }}</title>
									<use class="icon-v3" xlink:href="#icon-tick-or-solved "></use>
								</svg>
								<svg
									*ngIf="orderData.activarRouter === RouterGuideType.NO_GUIDE_2"
									class="vfes-alert-icon icon mvf-detail-clock-icon"
									xmlns="http://www.w3.org/2000/svg "
									xmlns:xlink="http://www.w3.org/1999/xlink "
									width="24px "
									height="24px "
								>
									<title>{{ 'v10.myOrder.steps.error' | translate }}</title>
									<use class="icon-v3" xlink:href="#icon-clock-or-timed"></use>
								</svg>
							</th>
							<td>
								<span [innerHtml]="orderData.mensaje | trustHtml"> </span>
							</td>
						</tr>
						<tr class="mvf-steps__warnbox" *ngIf="orderData.urlVPT">
							<td>
								<!-- VPT signing -->
								<div class="vpt-sign">
									<span class="vpt-sign-description mva10-u--body">
										{{ 'v10.myOrder.steps.signDigitalIVPTDescription' | translate }}
									</span>
									<div class="vpt-sign-cta" (click)="startVptSignProcess(orderData)">
										<span class="vpt-sign-cta-text mva10-u--body">
											{{ 'v10.myOrder.steps.signDigitalIVPTLink' | translate }}
										</span>
										<span class="vpt-sign-cta-chevron">
											<svg class="vpt-sign-cta-chevron__icon" width="30px" height="30px">
												<title>{{ 'v10.myOrder.steps.chevron' | translate }}</title>
												<use class="icon-v3" xlink:href="#icon-chevron-right"></use>
											</svg>
										</span>
									</div>
								</div>
							</td>
						</tr>
					</table>
				</div>

				<!-- Mensajes de Estado de Excepción (EE) -->
				<div class="mvf-detail__messages__warn" *ngIf="orderData.mensajeEE && !orderData.warningMessage">
					<sp-warn-box *ngIf="orderData.mensajeEE" [messages]="[orderData.mensajeEE]"> </sp-warn-box>
				</div>
				<div class="mvf-detail__messages__warn" *ngIf="orderData.warningMessage && !orderData.mensajeEE">
					<sp-warn-box *ngIf="orderData.warningMessage" [messages]="[orderData.warningMessage]"> </sp-warn-box>
				</div>

				<!-- Pedido historico OL -->
				<div
					class="mvf-steps__warnbox"
					*ngIf="
						(orderData.estadool || orderData.mensajeOL) &&
						('v10.myOrder.detail.showButtonHistoricoOL' | translate) == stringOfTrue &&
						(!orderData.mensajeEE || orderData.hasAutoInstallationCard)
					"
				>
					<div *ngIf="orderData.mensajeOL">
						<span class="mva10-u--body" [innerHtml]="orderData.mensajeOL | trustHtml"> </span>
					</div>
					<div>
						<div class="ol-history-container" *ngIf="!olService.checkingOL || !orderLastOLStatus">
							<sp-order-status-cta
								[loading]="olService.checkingOL"
								[orderStatus]="orderLastOLStatus"
								(click)="openOrderHistoryModal()"
							>
							</sp-order-status-cta>
						</div>
					</div>
				</div>

				<!-- Mensaje Porta Fija y Movil -->
				<div class="mvf-steps__warnbox" *ngIf="orderData.mensajePF">
					<span class="mva10-u--body" [innerHtml]="orderData.mensajePF | trustHtml"> </span>
				</div>
				<div class="mvf-steps__warnbox" *ngIf="orderData.mensajePM">
					<span class="mva10-u--body" [innerHtml]="orderData.mensajePM | trustHtml"> </span>
				</div>

				<div
					class="mvf-steps__warnbox"
					*ngIf="orderData.citaPlanificada && !orderData.mensajeEE && !(orderData.tipo == 6 && orderData.paso == '2')"
				>
					<sp-warn-box [messages]="[orderData.mensaje]" [info]="true"> </sp-warn-box>
				</div>
				<div *ngIf="orderData.cambioCita > 0" class="vfes-mt vfes-colours-bg-light-white vfes-align--center">
					<div class="vfes-layout__item">
						<button
							(click)="openAppointmentModal()"
							type="button"
							name="Cambiar cita"
							title="Cambiar cita"
							class="vfes-button vfes-button--tertiary"
						>
							{{ botonCita }}
						</button>
					</div>
					<br />
				</div>
				<!--Añadido para mostrar las ordenes de devolucion de equipos-->
				<div class="mvf-steps__divDevolucion" *ngIf="orderData.devolucionesEquipos?.length && orderData.paso == '2'">
					<p class="mvf-detail__titleDev">{{ 'v10.myOrder.steps.devicesRecived' | translate }}</p>
					<div class="mvf-steps__bgEquipo" *ngFor="let equipo of orderData.devolucionesEquipos">
						<div class="mvf-detail__detailP">
							<div class="mvf-detail__detail">
								<p class="mvf-detail__subtitleDetailN">{{ equipo.numEquipo }}</p>
								<p class="mvf-detail__subtitleDetail">{{ 'v10.myOrder.steps.numberDevices' | translate }}</p>
							</div>
							<div class="mvf-detail__detail">
								<p class="mvf-detail__subtitleDetailN">
									{{ equipo.codDevolucion }}
								</p>
								<p class="mvf-detail__subtitleDetail">{{ 'v10.myOrder.steps.returnCode' | translate }}</p>
							</div>
							<div class="mvf-detail__detail">
								<p class="mvf-detail__subtitleDetailN">
									{{ equipo.fechaLimite | date : 'dd/MM/yyyy' }}
								</p>
								<p class="mvf-detail__subtitleDetail">{{ 'v10.myOrder.steps.dateDeadline' | translate }}</p>
							</div>
							<div class="mvf-detail__detail">
								<p class="{{ getClassByEstado(equipo.estado) }}">
									{{ equipo.estado }}
								</p>
								<p class="mvf-detail__subtitleDetail">{{ 'v10.myOrder.steps.status' | translate }}</p>
							</div>
							<div *ngIf="equipo.estado === 'Pendiente'">
								<br />
								<p class="mvf-detail__subtitleDetail">
									{{ 'v10.myOrder.steps.daysReturnDevice' | translate }}
									<span class="mvf-detail__subtitleDetailN"> {{ calcularDiasRestantes(equipo.fechaLimite) }} </span>
								</p>
								<p class="mvf-detail__subtitleWarn" (click)="goToInfo()">
									{{ 'v10.myOrder.steps.howReturnDevices' | translate }}
									<a>
										<svg
											class="vfes-alert-icon icon mvf-detail__info"
											xmlns="http://www.w3.org/2000/svg"
											xmlns:xlink="http://www.w3.org/1999/xlink"
										>
											<title>{{ 'v10.myOrder.steps.information' | translate }}</title>
											<use class="icon-v3" xlink:href="#icon-info-circle"></use>
										</svg>
									</a>
								</p>
							</div>
							<div *ngIf="equipo.textoDescripcion">
								<p class="mvf-detail__subtitleDetailRev">
									{{ equipo.textoDescripcion }}
								</p>
							</div>
							<hr [ngClass]="isLast(last)" />
						</div>
					</div>
				</div>

				<div
					*ngIf="
						(orderData.activarRouter === RouterGuideType.ACTIVATE_ROUTER ||
							orderData.activarRouter === RouterGuideType.INTERNET ||
							orderData.activarRouter === RouterGuideType.INTERNET_AND_TV) &&
						orderData.orderFlow !== orderFlow
					"
					class="vfes-mt vfes-colours-bg-light-white vfes-align--center"
				>
					<div class="vfes-layout__item">
						<mva10-c-button
							(click)="openRouterModal()"
							name="activar router"
							title="activar router"
							[appearance]="'alt1'"
						>
							{{
								orderData.activarRouter === RouterGuideType.ACTIVATE_ROUTER &&
								orderData.statePedido === StatePedido.COMPLETED
									? ('v10.myOrder.steps.guideInstallRouter' | translate)
									: orderData.activarRouter === RouterGuideType.ACTIVATE_ROUTER &&
									  orderData.statePedido !== StatePedido.COMPLETED
									? ('v10.myOrder.steps.activateRouter' | translate)
									: ('v10.myOrder.steps.installYourNewRouter' | translate)
							}}
						</mva10-c-button>
					</div>
				</div>

				<div
					*ngIf="
						(orderData.instalarEquipos === DecoGuideType.UPGRADE_TV ||
							orderData.instalarEquipos === DecoGuideType.UPGRADE_DECO_GEN3 ||
							orderData.activarRouter === RouterGuideType.UPGRADE_INTERNET_AND_DECO_GEN3) &&
						orderData.orderFlow !== orderFlow
					"
					class="vfes-mt vfes-colours-bg-light-white vfes-align--center"
				>
					<div class="vfes-layout__item">
						<button
							type="button"
							(click)="openDecoModal()"
							name="instalar equipos"
							title="instalar equipos"
							class="vfes-button vfes-button--tertiary"
						>
							{{ 'v10.myOrder.steps.installDevice' | translate }}
						</button>
					</div>
				</div>

				<div
					id="MyOrdersSpStepsWarnAutoiContainerStep41"
					*ngIf="orderData.orderFlow === orderFlow && hasStep41OrStep42(four_one)"
					class="vfes-mt vfes-colours-bg-light-white"
				>
					<div class="vfes-layout__item" id="MyOrdersSpStepsWarnAutoiCard">
						<mva10-c-alert [layout]="'horizontal'" [state]="'info'">
							<div id="MyOrdersSpStepsWarnAutoiMessageStep41" class="mva10-u--mb4">
								{{ 'v10.myOrder.orders.autoi.infoMessage' | translate }}
							</div>
							<mva10-c-button
								id="MyOrdersSpStepsWarnAutoiButton"
								(mva10OnClick)="continueTaskAutoi()"
								[appearance]="'alt1'"
							>
								{{ 'v10.myOrder.orders.autoi.infoBtn' | translate }}
							</mva10-c-button>
						</mva10-c-alert>
					</div>
				</div>

				<div
					id="MyOrdersSpStepsCheckAutoiContainerStep42"
					*ngIf="orderData.orderFlow === orderFlow && hasStep41OrStep42(four_two)"
					class="vfes-mt vfes-colours-bg-light-white vfes-align--center"
				>
					<div class="vfes-layout__item" id="MyOrdersSpStepsWarnAutoiContainer">
						<mva10-c-card [modifier]="'shadow'">
							<div id="MyOrdersSpStepsWarnAutoiMessageStep42" class="messageCardCheck">
								<img
									class="mva10-u--mb2"
									id="MyOrdersSpStepCardIcon"
									src="{{ 'v10.myOrder.orders.autoi.img.boosting' | imgFullPath }}"
									alt="icon"
								/>
								{{ 'v10.myOrder.orders.autoi.checkCard' | translate }}
							</div>
							<mva10-c-button
								id="MyOrdersSpStepsWarnAutoiButton"
								(mva10OnClick)="backDropAutoi()"
								[appearance]="'primary'"
							>
								{{ 'v10.myOrder.orders.autoi.checkCardBtn' | translate }}
							</mva10-c-button>
						</mva10-c-card>
					</div>
				</div>

				<div
					*ngIf="orderData.statePedido === StatePedido.OPEN && orderData.guiaAutoiNeba === NebaGuideType.NEBA_INTERNET"
					class="vfes-mt vfes-colours-bg-light-white vfes-align--center"
				>
					<mva10-c-button (mva10OnClick)="openNebaModal()" [appearance]="'alt1'">
						{{ 'v10.myOrder.steps.guideNebaInternetButtonOpen' | translate }}
					</mva10-c-button>
				</div>

				<div
					*ngIf="
						('v10.myOrder.checkInstallationOverlay.checkNebaAutoInstallationActive' | translate) === stringOfTrue &&
						slidService.showCheckInstallation &&
						orderData.statePedido === StatePedido.OPEN &&
						ordersService.detailOrderData.guiaAutoiNeba === NebaGuideType.NEBA_INTERNET
					"
					class="card-slid"
				>
					<mva10-c-card class="card-recupSlid">
						<p class="card-recupSlid__text">{{ 'v10.myOrder.detail.recuperarSmartSlidText' | translate }}</p>
						<mva10-c-button (mva10OnClick)="openCheckInstallationOverlay()">
							{{ 'v10.myOrder.detail.recuperarSmartSlidButton' | translate }}
						</mva10-c-button>
					</mva10-c-card>
				</div>

				<div
					*ngIf="
						orderData.statePedido === StatePedido.OPEN &&
						(orderData.guiaAutoiNebaTv === NebaTvGuideType.NEBA_DECO_GEN3 ||
							orderData.guiaAutoiNebaTv === NebaTvGuideType.NEBA_DECO_MULTICAST ||
							orderData.guiaAutoiNebaTv === NebaTvGuideType.NEBA_DECO_UNICAST)
					"
					class="vfes-mt vfes-colours-bg-light-white vfes-align--center"
				>
					<mva10-c-button [appearance]="'alt1'" (mva10OnClick)="openNebaTvGuideModal()">
						{{ 'v10.myOrder.steps.guideNebaTvButtonOpen' | translate }}
					</mva10-c-button>
				</div>

				<div
					*ngIf="orderData.guiaOneConecta === 1 || orderData.guiaOneConecta === 2"
					class="vfes-mt vfes-colours-bg-light-white vfes-align--center"
				>
					<div class="vfes-layout__item">
						<button
							type="button"
							(click)="openOneConectaModal()"
							name="guia one conecta"
							title="guia one conecta"
							class="vfes-button vfes-button--tertiary"
						>
							{{ 'v10.myOrder.steps.installDevice' | translate }}
						</button>
					</div>
				</div>

				<div
					*ngIf="orderData.cambiarEquipos === 1 && orderData.cambioCita === 0"
					class="vfes-mt vfes-colours-bg-light-white vfes-align--center"
				>
					<div class="vfes-layout__item">
						<button
							type="button"
							(click)="openDecoChangeModal()"
							name="cambiar equipos"
							title="cambiar equipos"
							class="vfes-button vfes-button--tertiary"
						>
							{{ 'v10.myOrder.steps.changeDeviceStart' | translate }}
						</button>
					</div>
				</div>

				<!-- Autoinstalación -->
				<div class="vfes-installation__help vfes-mt" *ngIf="showAutoiCard">
					<sp-card-help
						[loading]="ticketsService.checkingAutoiTickets || olService.checkingOL"
						withBorder="true"
						image="{{ appService.getImgFullPath('v10.myOrder.steps.img.icRouterMid' | translate) }}"
						[title]="'v10.myOrder.steps.autoiHelpCardTitle' | translate"
						[subtitle]="'v10.myOrder.steps.autoiHelpCardDescription' | translate"
						[buttonText]="'v10.myOrder.steps.autoiHelpCardButton' | translate"
						(clickHelp)="openAutoiHelp()"
						(clickDetail)="goToTicketDetail()"
					>
					</sp-card-help>
				</div>

				<!-- Documentacion scoring -->
				<div class="vfes-layout vfes-pt" *ngIf="orderData.scoring?.necesitaDocumentosScoring">
					<div *ngFor="let document of orderData.scoring.documentos">
						<div class="vfes-layout__item vfes-mb-">
							<svg
								class="vfes-alert-icon icon mvf-detail-clock-icon"
								xmlns="http://www.w3.org/2000/svg "
								xmlns:xlink="http://www.w3.org/1999/xlink "
								width="24px "
								height="24px "
							>
								<title>Document</title>
								<use class="icon-v3" xlink:href="#icon-document"></use>
							</svg>
							<span> {{ 'v10.myOrder.documentationScoring.scoringNameDocument.' + document | translate }} </span>
							<svg
								(click)="showDocumentInfo(document)"
								class="vfes-alert-icon icon mvf-document-info-icon"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="24px"
								height="24px"
							>
								<title>{{ 'v10.myOrder.steps.info' | translate }}</title>
								<use class="icon-v3" xlink:href="#icon-info-circle"></use>
							</svg>
						</div>
					</div>
					<div
						class="vfes-layout__item vfes-mt"
						*ngIf="
							(!ordersService.isOB &&
								('v10.myOrder.steps.scoringFlagBtnShowDocs' | translate) === stringOfTrue &&
								isIkki) ||
							orderData.scoring.necesitaDocumentosScoring !== 2
						"
					>
						<mva10-c-button
							(mva10OnClick)="
								orderData.scoring.necesitaDocumentosScoring === 2 ? watchScoringDocs() : openScoringModal()
							"
							name="documentation scoring"
							title="documentation scoring"
							[appearance]="'alt1'"
						>
							{{
								orderData.scoring.necesitaDocumentosScoring === 2
									? ('v10.myOrder.steps.scoringBtnShow' | translate)
									: ('v10.myOrder.steps.scoringBtnAttach' | translate)
							}}
						</mva10-c-button>
					</div>
				</div>

				<!-- OpenBanking -->
				<div id="MyOrdersSpStepsOBButton" class="vfes-layout__item vfes-mt" *ngIf="ordersService.isOB">
					<mva10-c-button
						(click)="clickOBSteps()"
						name="documentation scoring"
						title="documentation scoring"
						[appearance]="'alt1'"
					>
						{{ 'v10.myOrder.detail.ScoringBtnOB' | translate }}
					</mva10-c-button>
				</div>

				<!-- Rechazo portabilidad -->
				<div
					*ngIf="orderData.portabilidadRechazada?.tipoRechazoPortabilidad === 0"
					[ngClass]="{ 'margin-negative': !this.orderData.mensajePF && !this.orderData.mensajePM }"
				>
					<sp-warn-box
						*ngIf="orderData.mensajePM || orderData.mensajePF"
						[isError]="true"
						[messages]="[orderData.mensajePM || orderData.mensajePF]"
					>
					</sp-warn-box>
					<br />

					<div class="mvf-steps__input">
						<div class="vfes-form__box">
							<mva10-c-text-field
								data-vfes-js="_autocomplete"
								class="vfes-form__input vfes-form__input--dropdown js-form-autocomplete vfes-form__input--selector"
								readonly
								aria-labelledby="label"
								type="text"
								id="inputDropdownSteps"
								aria-autocomplete="both"
								aria-expanded="false"
								aria-owns="vfes-autocomplete"
								name="texto2"
								placeholder="Tipo de documento"
								autocomplete="off"
								(focus)="openDropdown()"
								(blur)="closeDropdown()"
								(change)="change()"
								[(ngModelInput)]="docType"
							>
							</mva10-c-text-field>
							<span class="vfes-form__label" id="label">
								{{ 'v10.myOrder.steps.relaunchPortabilityDocType' | translate }}
							</span>
							<svg
								class="icon vfes-form__icon vfes-form__icon-down mvf-steps__input-icon"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="32px"
								height="32px"
							>
								<title>{{ 'v10.myOrder.steps.locate' | translate }}</title>
								<use class="icon-v3" xlink:href="#icon-chevron-down"></use>
							</svg>
							<svg
								class="icon vfes-form__icon vfes-form__icon-up mvf-steps__input-icon"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="32px"
								height="32px"
							>
								<title>{{ 'v10.myOrder.steps.locate' | translate }}</title>
								<use class="icon-v3" xlink:href="#icon-chevron-up"></use>
							</svg>
							<ul
								id="vfes-autocomplete"
								name="vfes-autocomplete"
								role="listbox"
								class="vfes-form__autocomplete-list js-form-autocomplete-list mvf-steps__ul"
							>
								<li
									*ngFor="let item of docOptions"
									role="option"
									class="js-form-autocomplete-list-option vfes-form__autocomplete-list-option"
									(mousedown)="selectOption(item)"
								>
									{{ item }}
								</li>
							</ul>
						</div>
					</div>

					<div class="mvf-steps__input">
						<div class="vfes-form__box">
							<mva10-c-text-field
								class="vfes-form__input vfes-form__input"
								[ngClass]="{ 'vfes-form__input--error': dniValid !== undefined && !dniValid }"
								type="text"
								aria-labelledby="label"
								id="texto2"
								name="texto2"
								placeholder="Documento"
								[(ngModelInput)]="numDoc"
								(ngModelChange)="updateNumDoc($event)"
							>
							</mva10-c-text-field>
							<span class="vfes-form__label" id="label">
								{{ 'v10.myOrder.steps.relaunchPortabilityDNI' | translate }}
							</span>
							<p class="vfes-form__error-text mvf-steps__input-validation">
								{{ 'v10.myOrder.steps.relaunchPortabilityDNIValidation' | translate }}
							</p>
							<svg
								class="icon vfes-form__icon vfes-form__icon-warning mvf-steps__input-warning"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="32px"
								height="32px"
							>
								<title>{{ 'v10.myOrder.steps.warning' | translate }}</title>
								<use class="icon-v3" xlink:href="#icon-warning-esp"></use>
							</svg>
							<svg
								*ngIf="dniValid === undefined || dniValid"
								class="icon mvf-steps__input-icon"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="32px"
								height="32px"
							>
								<title>{{ 'v10.myOrder.steps.profile' | translate }}</title>
								<use class="icon-v3" xlink:href="#icon-profile"></use>
							</svg>
						</div>
					</div>

					<div class="mvf-steps__input">
						<div class="vfes-form__box">
							<mva10-c-text-field
								class="vfes-form__input vfes-form__input"
								[ngClass]="{ 'vfes-form__input--error': numValid !== undefined && !numValid }"
								type="text"
								aria-labelledby="label"
								id="texto2"
								name="texto2"
								placeholder="Teléfono móvil"
								(ngModelChange)="updateNumPortar($event)"
								[(ngModelInput)]="numPortar"
							>
							</mva10-c-text-field>
							<span *ngIf="orderData.mensajePM" class="vfes-form__label" id="label">
								{{ 'v10.myOrder.steps.relaunchPortabilityMobilePhone' | translate }}
							</span>
							<span *ngIf="orderData.mensajePF" class="vfes-form__label" id="label">
								{{ 'v10.myOrder.steps.relaunchPortabilityLandline' | translate }}
							</span>
							<p class="vfes-form__error-text mvf-steps__input-validation">
								{{ 'v10.myOrder.steps.relaunchPortabilityPhoneValidation' | translate }}
							</p>
							<svg
								class="icon vfes-form__icon vfes-form__icon-warning mvf-steps__input-warning"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="32px"
								height="32px"
							>
								<title>{{ 'v10.myOrder.steps.warning' | translate }}</title>
								<use class="icon-v3" xlink:href="#icon-warning-esp"></use>
							</svg>
							<svg
								*ngIf="
									(numValid === undefined || numValid) &&
									orderData.portabilidadRechazada.tipoLinea.toLowerCase() === 'movil'
								"
								class="icon mvf-steps__input-icon"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="32px"
								height="32px"
							>
								<title>{{ 'v10.myOrder.steps.mobile' | translate }}</title>
								<use class="icon-v3" xlink:href="#icon-mobile"></use>
							</svg>
							<svg
								*ngIf="
									(numValid === undefined || numValid) &&
									orderData.portabilidadRechazada.tipoLinea.toLowerCase() !== 'movil'
								"
								class="icon mvf-steps__input-icon"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="32px"
								height="32px"
							>
								<title>{{ 'v10.myOrder.steps.landline' | translate }}</title>
								<use class="icon-v3" xlink:href="#icon-business-phone"></use>
							</svg>
						</div>
					</div>

					<mva10-c-button
						[disabled]="!enableButton"
						data-analytics-element=""
						data-analytics-category="cta"
						(mva10OnClick)="sendInfo()"
					>
						{{ 'v10.myOrder.steps.relaunchPortabilitySend' | translate }}
					</mva10-c-button>
				</div>

				<!-- Rechazo portabilidad (lapiz) -->
				<div
					*ngIf="orderData.portabilidadRechazada?.tipoRechazoPortabilidad > 0"
					[ngClass]="{ 'margin-negative': !this.orderData.mensajePF && !this.orderData.mensajePM }"
				>
					<sp-warn-box
						*ngIf="orderData.mensajePM || orderData.mensajePF"
						[isError]="true"
						[messages]="[orderData.mensajePM || orderData.mensajePF]"
					>
					</sp-warn-box>
					<div *ngIf="orderData.mensajePF">
						<p class="mvf-steps__text">{{ 'v10.myOrder.steps.relaunchPortabilityInfoText' | translate }}</p>

						<div class="mvf-steps__separator"></div>
						<div class="mvf-steps__title-container">
							<p class="mvf-steps__txt-title">{{ 'v10.myOrder.steps.relaunchPortabilityPersonalInfo' | translate }}</p>
							<svg
								class="icon mvf-steps__icon-edit"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="24px"
								height="24px"
								(click)="editData()"
							>
								<title>{{ 'v10.myOrder.steps.edit' | translate }}</title>
								<use class="icon-v3" xlink:href="#icon-edit"></use>
							</svg>
						</div>
						<p>{{ 'v10.myOrder.steps.relaunchPortabilityDocType' | translate }}: {{ docType }}</p>
						<p>{{ 'v10.myOrder.steps.relaunchPortabilityDNI' | translate }}: {{ numDoc }}</p>
						<p>{{ 'v10.myOrder.steps.relaunchPortabilityPhone' | translate }}: {{ numPortar }}</p>
						<div class="mvf-steps__separator"></div>
					</div>

					<div *ngIf="ordersService.detailOrderData.mensajePM">
						<div class="mvf-steps__separator margin-top-24"></div>
						<div class="mvf-steps__title-container">
							<p
								*ngIf="ordersService.detailOrderData.portabilidadRechazada.tipoRechazoPortabilidad !== 3"
								class="mvf-steps__txt-title"
							>
								{{ 'v10.myOrder.steps.relaunchPortabilityPersonalInfo' | translate }}
							</p>
							<p class="mvf-steps__txt-title" *ngIf="orderData.portabilidadRechazada.codigoRechazo === 'RECH_ICCID'">
								{{ 'v10.myOrder.steps.relaunchPortabilityDAtaICCID' | translate }}
							</p>
							<svg
								*ngIf="ordersService.detailOrderData.portabilidadRechazada.tipoRechazoPortabilidad !== 3"
								class="icon mvf-steps__icon-edit"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="24px"
								height="24px"
								(click)="editData()"
							>
								<title>{{ 'v10.myOrder.steps.edit' | translate }}</title>
								<use class="icon-v3" xlink:href="#icon-edit"></use>
							</svg>
						</div>
						<!-- RECH_IDENT  -->
						<div *ngIf="ordersService.detailOrderData.portabilidadRechazada.tipoRechazoPortabilidad !== 3">
							<p *ngIf="docType === ('v10.myOrder.steps.relaunchPortabilityCIF' | translate)">
								{{ 'v10.myOrder.steps.relaunchPortabilityCompanyNAme' | translate }}: {{ nombre }}
							</p>
							<p
								id="myordersstepsrelaunchPortabilityName"
								*ngIf="docType !== ('v10.myOrder.steps.relaunchPortabilityCIF' | translate)"
							>
								{{ 'v10.myOrder.steps.relaunchPortabilityName' | translate }}: {{ nombre }}
							</p>
							<p *ngIf="docType !== ('v10.myOrder.steps.relaunchPortabilityCIF' | translate)">
								{{ 'v10.myOrder.steps.relaunchPortabilitySurname' | translate }}: {{ apellido }}
							</p>
							<p *ngIf="docType !== ('v10.myOrder.steps.relaunchPortabilityCIF' | translate)">
								{{ 'v10.myOrder.steps.relaunchPortabilitySurname2' | translate }}: {{ apellido2 }}
							</p>
							<p class="margin-bottom-16">{{ docType }}: {{ numDoc }}</p>
							<div class="mvf-steps__separator"></div>
						</div>
						<!-- RECH_ICCID   -->
						<div *ngIf="orderData.portabilidadRechazada.codigoRechazo === 'RECH_ICCID'">
							<p class="margin-bottom-16">
								{{ 'v10.myOrder.steps.relaunchPortabilityICCID' | translate }}: {{ iccid }}
							</p>
							<div class="mvf-steps__separator"></div>
							<p class="mvf-steps__iccid-text">{{ 'v10.myOrder.steps.relaunchPortabilityICCIDInfo' | translate }}</p>
							<svg
								(click)="showICCIDInfo()"
								class="vfes-alert-icon icon"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="24px"
								height="24px"
							>
								<title>{{ 'v10.myOrder.steps.info' | translate }}</title>
								<use class="icon-v3" xlink:href="#icon-info-circle"></use>
							</svg>
						</div>
					</div>
				</div>
			</span>
		</section>
	</li>
</ul>
<vfac-overlay-full-screen
	*ngIf="showStepOverlayFullScreenModal"
	[show]="myOrdersService.showResponseBox"
	[overlayFullScreenData]="overlayFullScreenData"
	(closeBtnClicked)="myOrdersService.dataResponseBox.buttonClick(); closeModal()"
>
	<sp-response-box
		[loading]="myOrdersService.loadingResponseBox"
		[isPort]="myOrdersService.isPortResponseBox"
		[type]="myOrdersService.typeResponseBox"
		[genericError]="myOrdersService.genericErrorResponseBox"
		[isChange]="myOrdersService.isChangeResponseBox"
		[codigoRechazo]="myOrdersService.codigoRechazoResponseBox"
		[overlayData]="myOrdersService.dataResponseBox"
	>
	</sp-response-box>
</vfac-overlay-full-screen>

<vfac-overlay
	[show]="showBackDropOBSteps"
	[overlayData]="xrContentsOverlayDataSteps"
	[loaded]="true"
	[zIndex]="24"
	id="MyOrdersSpStepsBackdropOB"
	(closed)="closeOBSteps()"
	(primaryBtnClicked)="clickButtonBackdropSteps()"
	[showButtons]="true"
>
	<div body>
		<div class="imgContent">
			<div class="headerImage">
				<img
					[src]="'v10.myOrder.detail.backdropOB.imgTitle' | imgFullPath"
					width="80"
					height="80"
					id="MyOrdersSpStepsBackdropOBImgHeader"
					alt="imgTitle"
				/>
			</div>
		</div>
		<div class="titleContent">
			<div class="headerTitle">
				<h3 class="mva10-u--mb4x bold-font" id="MyOrdersSpStepsBackdropOBTitle">
					{{ 'v10.myOrder.detail.backdropOB.title' | translate }}
				</h3>
			</div>
		</div>
		<div class="bodyOBContent">
			<p id="MyOrdersSpStepsBackdropOBTitleContent">
				{{ 'v10.myOrder.detail.backdropOB.subtitle' | translate }}
			</p>
			<p id="MyOrdersSpStepsBackdropOBSubtitleContent">
				{{ 'v10.myOrder.detail.backdropOB.titleContent' | translate }}
			</p>
			<span
				id="MyOrdersSpStepsBackdropOBContent"
				class="OBContentList list-disc"
				[innerHtml]="'v10.myOrder.detail.backdropOB.content' | translate"
			>
			</span>
			<div class="containerCardOB">
				<mva10-c-card [colorBg]="'light-grey'" [selected]="false" [paddingMobile]="'mini'">
					<div class="cardOB">
						<div class="cardOBImg">
							<img
								[src]="'v10.myOrder.detail.backdropOB.imgAlert' | imgFullPath"
								width="40"
								height="40"
								id="MyOrdersSpStepsBackdropOBCardOBImg"
								alt="imgAlert"
							/>
						</div>
						<div class="cardOBText">
							<span
								[innerHtml]="'v10.myOrder.detail.backdropOB.alert' | translate"
								id="MyOrdersSpStepsBackdropOBDescriptionBox"
							>
							</span>
						</div>
					</div>
				</mva10-c-card>
			</div>
		</div>
	</div>
</vfac-overlay>
<sp-modal-full #modalKo [hideX]="false">
	<div id="MyOrdersSpStepsmodalKoIconContainer" class="modalKoIconContainer">
		<img
			id="MyOrdersSpStepsmodalKoIcon"
			class="modalKoIcon"
			src="{{ 'v10.myOrder.orders.returns.sumary.modalKo.image' | imgFullPath }}"
			alt="control gen1"
		/>
	</div>
	<div id="MyOrdersSpStepsmodalKoBody" class="modalKoBodyContainer vfes-align--center">
		<p id="myOrdersmodalKoHeader" class="modalKoTitle">
			{{ 'v10.myOrder.orders.returns.sumary.modalKo.title' | translate }}
		</p>
		<p id="MyOrdersSpStepsmodalKoBodyTitle" class="modalKoBodyTitle">
			{{ 'v10.myOrder.orders.autoi.modalKoMessage' | translate }}
		</p>
	</div>
</sp-modal-full>

<vfac-overlay
	id="MyOrdersSpStepsBackDropAutoi"
	[overlayData]="'overlayData'"
	[show]="openBackDrop"
	[loaded]="true"
	(closed)="closeOverlay()"
>
	<div body class="backDropContainer">
		<div id="MyOrdersSpStepsBackDropIconContainer" class="backDropIconContainer">
			<img
				id="MyOrdersSpStepsBackDropIcon"
				class="backDropIcon"
				src="{{ 'v10.myOrder.orders.autoi.img.backDropIcon' | imgFullPath }}"
				alt="icon backdrop"
			/>
		</div>
		<div id="MyOrdersSpStepsBackDropDescContainer" class="descContainer">
			<p id="MyOrdersSpStepsBackDropTitle" class="backDropBodyTitle">
				{{ 'v10.myOrder.orders.autoi.backDropTitle' | translate }}
			</p>
			<p id="MyOrdersSpStepsBackDropSubTitle" class="backDropBodySubTitle">
				{{ 'v10.myOrder.orders.autoi.backDropSubTitle' | translate }}
			</p>
		</div>
		<div id="MyOrdersSpStepsBackDropButtonContainer" class="buttonContainer">
			<div id="MyOrdersSpStepsBackDropButtonContainerOk" class="mva10-u--mb4">
				<mva10-c-button (mva10OnClick)="clickOk()" [appearance]="'primary'">
					<p id="MyOrdersSpStepsBackDropOkBtn">
						{{ 'v10.myOrder.orders.autoi.backDropBtnOk' | translate }}
					</p>
				</mva10-c-button>
			</div>
			<div id="MyOrdersSpStepsBackDropButtonContainerNoOk">
				<mva10-c-button (mva10OnClick)="clickNoOk()" [appearance]="'alt1'">
					<p id="MyOrdersSpStepsBackDropNoOkBtn">
						{{ 'v10.myOrder.orders.autoi.backDropBtnGuide' | translate }}
					</p>
				</mva10-c-button>
			</div>
		</div>
	</div>
</vfac-overlay>

<sp-check-installation-overlay *ngIf="showOverlayCheckInstallation" (close)="closeCheckInstallationOverlay($event)">
</sp-check-installation-overlay>
