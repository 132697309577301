<vfac-overlay
	#nebaInstallationGuideOverlay
	[overlayData]="nebaInstallationGuideOverlayData"
	[show]="showNebaInstallationGuideOverlay"
	[page]="overlayPage"
	[loaded]="overlayLoaded"
	[loaderType]="'vodafone'"
	(closed)="closeGuideOverlay()"
	(backPage)="overlayGoBackwards()"
>
	<div header *ngIf="overlayPage > 1">
		<div class="guide-progress">
			<vfac-g-progress-bar
				[total]="progressBarTitles?.length"
				[value]="progressBarCurrentStep"
				[disableAnimation]="false"
				[title]="progressBarTitles[progressBarCurrentStep - 1] | translate"
				[showProgress]="true"
				[styleClass]="'mva10-u--body'"
			>
			</vfac-g-progress-bar>
		</div>
	</div>

	<!-- Intro -->
	<div body id="page1">
		<div class="intro">
			<div class="intro-header">
				<!-- CSS image goes here -->
			</div>
			<div class="intro-body">
				<span class="intro-body__title mva10-u-heading--3">
					{{ 'v10.myOrder.nebaInstallation.nebaInternetGuideIntroTitle' | translate }}
				</span>
				<ul class="intro-body__points">
					<li
						class="intro-body__points-container"
						[ngStyle]="{
							'background-image': 'url(' + ('v10.myOrder.nebaInstallation.img.clockortimed' | imgFullPath) + ')'
						}"
					>
						<span
							class="intro-body__points-container__description mva10-u--body"
							[innerHTML]="'v10.myOrder.nebaInstallation.nebaInternetGuideIntroPoint1' | translate"
						>
						</span>
					</li>
					<li
						class="intro-body__points-container"
						[ngStyle]="{
							'background-image': 'url(' + ('v10.myOrder.nebaInstallation.img.networksignal' | imgFullPath) + ')'
						}"
					>
						<span
							class="intro-body__points-container__description mva10-u--body"
							[innerHTML]="'v10.myOrder.nebaInstallation.nebaInternetGuideIntroPoint2' | translate"
						>
						</span>
					</li>
					<li
						class="intro-body__points-container"
						[ngStyle]="{
							'background-image': 'url(' + ('v10.myOrder.nebaInstallation.img.deviceguide' | imgFullPath) + ')'
						}"
					>
						<span
							id="MyOrdersNebaInstallationInternetGuideIntroPoint13"
							class="intro-body__points-container__description mva10-u--body"
							[innerHTML]="'v10.myOrder.nebaInstallation.nebaInternetGuideIntroPoint13' | translate"
						>
						</span>
					</li>
				</ul>
				<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
			</div>
		</div>
	</div>

	<!-- Guide Page 1 -->
	<div body id="page2">
		<div class="guide-step step1">
			<span class="mva10-u-heading--4 vfes-align--center">
				<strong> {{ 'v10.myOrder.nebaInstallation.nebaInternetGuideStep1intro' | translate }} </strong>
			</span>
			<div class="components-grid">
				<div class="grid-image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallation.img.routerwifi' | translate) }}"
						alt="Router WiFi"
					/>
					<span class="mva10-u-heading--5">
						<strong> {{ 'v10.myOrder.nebaInstallation.nebaInternetGuideStep1Component1' | translate }} </strong>
					</span>
				</div>
				<div class="grid-image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallation.img.cablefibra' | translate) }}"
						alt="Cable de fibra"
					/>
					<span class="mva10-u-heading--5">
						<strong> {{ 'v10.myOrder.nebaInstallation.nebaInternetGuideStep1Component2' | translate }} </strong>
					</span>
				</div>
				<div class="grid-image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallation.img.fuentealimentacion' | translate) }}"
						alt="Fuente de alimentación"
					/>
					<span class="mva10-u-heading--5">
						<strong> {{ 'v10.myOrder.nebaInstallation.nebaInternetGuideStep1Component3' | translate }} </strong>
					</span>
				</div>
				<div class="grid-image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallation.img.cableethernet' | translate) }}"
						alt="Cable Ethernet"
					/>
					<span class="mva10-u-heading--5">
						<strong> {{ 'v10.myOrder.nebaInstallation.nebaInternetGuideStep1Component4' | translate }} </strong>
					</span>
				</div>
			</div>
			<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
		</div>
	</div>

	<!-- Guide Page 2 -->
	<div body id="page3">
		<div class="guide-step step2">
			<div class="connect-router-title mva10-u-heading--4">
				<strong> {{ 'v10.myOrder.nebaInstallation.nebaInternetGuideStep2ConnectCabletoCurrent' | translate }} </strong>
			</div>
			<p
				class="connect-router-description mva10-u-body"
				[innerHTML]="'v10.myOrder.nebaInstallation.nebaInternetGuideStep2PressPowerButtonRouter' | translate"
			></p>
			<div class="alerts-section">
				<ng-container
					[ngTemplateOutlet]="alertTemplate"
					[ngTemplateOutletContext]="{
						type: 'neutral',
						description: 'v10.myOrder.nebaInstallation.nebaInternetGuideStep2OnlyPowerCable'
					}"
				>
				</ng-container>
			</div>
			<p
				class="connect-router-description mva10-u-body"
				id="MyOrdersNebaInstallationInternetGuideTextRouter"
				[innerHTML]="'v10.myOrder.nebaInstallation.nebaInternetPaso2OnOff' | translate"
			></p>
			<div class="connect-router-image vfes-mb" id="MyOrdersNebaInstallationInternetGuideContentImageRouterONOFF">
				<img
					src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallation.img.routerOnOff' | translate) }}"
					alt="Router On Off"
					id="MyOrdersNebaInstallationInternetGuideImageRouterONOFF"
				/>
			</div>
			<p
				class="connect-router-description mva10-u-body"
				id="MyOrdersNebaInstallationInternetGuideSubTextRouter"
				[innerHTML]="'v10.myOrder.nebaInstallation.nebaInternetPaso2Wifi2G5G' | translate"
			></p>
			<div class="connect-router-image" id="MyOrdersNebaInstallationInternetGuideContentImageRouterLedWifi">
				<img
					src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallation.img.routerLedWifi' | translate) }}"
					alt="Led WiFi encendido"
					id="MyOrdersNebaInstallationInternetGuideImageRouterLedWifi"
				/>
			</div>
			<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
		</div>
	</div>

	<!-- Guide Page 3 -->
	<div body id="page4">
		<div class="guide-step step3 no-padding-bottom">
			<ng-container *ngIf="numErrorsWhileGettingSlid === 0; else errorSlidTemplate">
				<div class="step-content">
					<div class="start-configuration-image vfes-mt+ vfes-mb">
						<img
							src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallation.img.settingshi' | translate) }}"
							alt="Configuración"
						/>
					</div>
					<div class="start-configuration-title mva10-u-heading--4">
						<strong> {{ 'v10.myOrder.nebaInstallation.nebaInternetGuideStep3ConfigurationStart' | translate }} </strong>
					</div>
				</div>
				<ng-container [ngTemplateOutlet]="navigationButtonsTemplate"> </ng-container>
			</ng-container>

			<!-- Error while getting slid -->
			<ng-template #errorSlidTemplate>
				<ng-container
					*ngIf="
						numErrorsWhileGettingSlid <
							+('v10.myOrder.nebaInstallation.nebaInternetGuideMaxRetriesGettingSlid' | translate);
						else errorGetSlidMaxAttempts
					"
				>
					<div class="step-content">
						<div class="error-configuration-image vfes-mt+ vfes-mb">
							<img
								src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallation.img.alert' | translate) }}"
								alt="Alerta"
							/>
						</div>
						<div class="error-configuration-title vfes-title--bold mva10-u-heading--3 vfes-mb">
							<strong>
								{{ 'v10.myOrder.nebaInstallation.nebaInternetGuideStep3ConfigurationError' | translate }}
							</strong>
						</div>
						<div class="error-configuration-description mva10-u-body">
							{{ 'v10.myOrder.nebaInstallation.nebaInternetGuideStep3Retries' | translate }}
						</div>
					</div>
					<ng-container [ngTemplateOutlet]="navigationButtonsTemplate"> </ng-container>
				</ng-container>

				<ng-template #errorGetSlidMaxAttempts>
					<ng-container *ngIf="!showErrorGetSlidChat; else errorGetSlidChat">
						<div class="step-content">
							<div class="error-configuration-image vfes-mt+ vfes-mb">
								<img
									src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallation.img.alert' | translate) }}"
									alt="Alerta"
								/>
							</div>
							<div class="error-configuration-title vfes-title--bold mva10-u-heading--3 vfes-mb">
								<strong>
									{{ 'v10.myOrder.nebaInstallation.nebaInternetGuideStep3ConfigurationError' | translate }}
								</strong>
							</div>
							<div class="error-configuration-description mva10-u-body">
								{{
									'v10.myOrder.nebaInstallation.nebaInternetGuideStep3MultipleCallAttempts'
										| translate
										| replace : '{phone}' : ('v10.myOrder.nebaInstallation.nebaInternetGuideContactPhone' | translate)
								}}
							</div>
						</div>
						<div class="call-button" (click)="sendCallAnalytics()" *ngIf="showCallButton">
							<a
								class="mva10-c-button"
								[attr.href]="'tel:' + ('v10.myOrder.nebaInstallation.nebaInternetGuideContactPhone' | translate)"
							>
								{{
									'v10.myOrder.nebaInstallation.nebaInternetGuideStep3NoticeCall'
										| translate
										| replace : '{phone}' : ('v10.myOrder.nebaInstallation.nebaInternetGuideContactPhone' | translate)
								}}
							</a>
						</div>
						<div class="call-button" *ngIf="!showCallButton">
							<mva10-c-button (mva10OnClick)="closeGuideOverlay(true)">
								{{ 'v10.myOrder.nebaInstallation.close' | translate }}
							</mva10-c-button>
						</div>
					</ng-container>

					<ng-template #errorGetSlidChat>
						<div class="content result-error">
							<div class="error-configuration-image vfes-mt+ vfes-mb">
								<img
									[attr.src]="appService.getImgFullPath('v10.myOrder.nebaInstallation.img.cascos' | translate)"
									alt="Headphones"
								/>
							</div>
							<div class="error-configuration-title vfes-title--bold mva10-u-heading--3 vfes-mb">
								{{ 'v10.myOrder.nebaInstallation.autoiCheckInstallationErrorChatTitle' | translate }}
							</div>
							<div class="error-configuration-description mva10-u--body">
								{{ 'v10.myOrder.nebaInstallation.autoiCheckInstallationErrorChatDescription' | translate }}
							</div>
						</div>
						<div class="button-container vfes-mt">
							<mva10-c-button (mva10OnClick)="goToChat()">
								{{ 'v10.myOrder.nebaInstallation.autoiCheckInstallationErrorChatButton' | translate }}
							</mva10-c-button>
						</div>
					</ng-template>
				</ng-template>
			</ng-template>
		</div>
	</div>

	<!-- Guide Page 4 -->
	<div body id="page5">
		<div class="guide-step step4 no-padding-bottom">
			<div class="step-content">
				<div class="image vfes-mt+ vfes-mb">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallation.img.touchgridhi' | translate) }}"
						alt="Tocar pantalla del móvil"
					/>
				</div>
				<div class="title mva10-u-heading--4">
					<strong> {{ 'v10.myOrder.nebaInstallation.nebaInternetGuideStep4DoNotCloseScreen' | translate }} </strong>
				</div>
				<p
					class="description mva10-u-body"
					[innerHTML]="'v10.myOrder.nebaInstallation.nebaInternetGuideStep4LoseInternetConnection' | translate"
				></p>
			</div>
			<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
		</div>
	</div>

	<!-- Guide Page 5 -->
	<div body id="page6">
		<div class="guide-step step5">
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					title: 'v10.myOrder.nebaInstallation.nebaInternetPaso5AntesEmpezar',
					description: 'v10.myOrder.nebaInstallation.nebaInternetPaso5ActivarWifi',
					customClass: 'vfes-mt-',
					imageAlt: 'Desconectar datos y activar WiFi',
					image: 'v10.myOrder.nebaInstallation.img.enableWifi' | translate | imgFullPath
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					title: 'v10.myOrder.nebaInstallation.nebaInternetGuideStep5ConnectWifi',
					description: 'v10.myOrder.nebaInstallation.nebaInternetGuideStep5TeamSticker',
					customClass: 'smaller vfes-mt-',
					imageAlt: 'Etiqueta del router',
					image: 'v10.myOrder.nebaInstallation.img.routerlabel' | translate | imgFullPath
				}"
			>
			</ng-container>

			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					title: 'v10.myOrder.nebaInstallation.nebaInternetGuideStep5MenuSettings',
					description: 'v10.myOrder.nebaInstallation.nebaInternetGuideStep5RedVodafone',
					imageAlt: 'Conectar al WiFi',
					image: 'v10.myOrder.nebaInstallation.img.wificonnect' | translate | imgFullPath
				}"
			>
			</ng-container>
			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					title: 'v10.myOrder.nebaInstallation.nebaInternetGuideStep5Activation',
					description: 'v10.myOrder.nebaInstallation.nebaInternetGuideStep5ActiveRouter'
				}"
			>
			</ng-container>

			<div class="activate-router-section">
				<div class="activate-router-section__button vfes-mb">
					<mva10-c-button
						[disabled]="slidResponse === 'ok' || slidResponse === 'max-retries' || isSettingSlid"
						(mva10OnClick)="sendSLIDToONT()"
					>
						{{ 'v10.myOrder.nebaInstallation.activateRouter' | translate }}
					</mva10-c-button>
				</div>

				<div *ngIf="isSettingSlid" class="skeleton-container vfes-mb">
					<div class="skeleton skeleton-image"></div>
					<div class="skeleton skeleton-text"></div>
				</div>
				<ng-container
					*ngIf="slidResponse && !isSettingSlid"
					[ngTemplateOutlet]="alertTemplate"
					[ngTemplateOutletContext]="{
						type: slidResponse === 'ok' ? 'positive' : 'critical',
						description:
							numErrorsWhileSendingSlid === 0
								? 'v10.myOrder.nebaInstallation.nebaInternetGuideStep5ActiveOk'
								: numErrorsWhileSendingSlid <
								  +('v10.myOrder.nebaInstallation.nebaInternetGuideMaxRetriesSendingSlid' | translate)
								? 'v10.myOrder.nebaInstallation.nebaInternetGuideStep5ActiveError'
								: ('v10.myOrder.nebaInstallation.nebaInternetGuideStep5ActiveErrorCall'
								  | translate
								  | replace : '{phone}' : ('v10.myOrder.nebaInstallation.nebaInternetGuideContactPhone' | translate))
					}"
				>
				</ng-container>
			</div>

			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					title: 'v10.myOrder.nebaInstallation.nebaInternetGuideStep5RouterActived',
					description: 'v10.myOrder.nebaInstallation.nebaInternetGuideStep5DesactiveWifi',
					imageAlt: 'Desactivar WiFi',
					image: 'v10.myOrder.nebaInstallation.img.disablewifi' | translate | imgFullPath
				}"
			>
			</ng-container>

			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					title: 'v10.myOrder.nebaInstallation.nebaInternetGuideStep5TitleConnectFibre',
					description: 'v10.myOrder.nebaInstallation.nebaInternetGuideStep5ConnecteFibra',
					customClass: 'vfes-mt',
					imageAlt: 'Conectar fibra al router',
					image: 'v10.myOrder.nebaInstallation.img.cabletorouter' | translate | imgFullPath
				}"
			>
			</ng-container>

			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					title: 'v10.myOrder.nebaInstallation.nebaInternetGuideStep5TitleLedInternet',
					description: 'v10.myOrder.nebaInstallation.nebaInternetGuideStep5LedInternet',
					customClass: 'smaller vfes-mt',
					imageAlt: 'Led internet del router',
					image: 'v10.myOrder.nebaInstallation.img.routerled' | translate | imgFullPath
				}"
			>
			</ng-container>

			<div class="alerts-section">
				<ng-container
					[ngTemplateOutlet]="alertTemplate"
					[ngTemplateOutletContext]="{
						type: 'info',
						description: 'v10.myOrder.nebaInstallation.nebaInternetGuideStep5ResetRouter'
					}"
				>
				</ng-container>
				<ng-container
					[ngTemplateOutlet]="alertTemplate"
					[ngTemplateOutletContext]="{
						type: 'info',
						description:
							('v10.myOrder.checkInstallationOverlay.checkNebaAutoInstallationActive' | translate) === 'true'
								? ('v10.myOrder.nebaInstallation.guideNebaInternetStep5LedFinishShamanActive' | translate)
								: ('v10.myOrder.nebaInstallation.nebaInternetGuideStep5LedEnd'
								  | translate
								  | replace : '{phone}' : ('v10.myOrder.nebaInstallation.nebaInternetGuideContactPhone' | translate))
					}"
				>
				</ng-container>
			</div>

			<ng-container
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					title: 'v10.myOrder.nebaInstallation.nebaInternetGuideStep5ConnectLandlinePhone',
					description: 'v10.myOrder.nebaInstallation.nebaInternetGuideStep5Port',
					customClass: 'smaller vfes-mt',
					imageAlt: 'Conectar teléfono fijo al router',
					image: 'v10.myOrder.nebaInstallation.img.teltorouter' | translate | imgFullPath
				}"
			>
			</ng-container>

			<div class="alerts-section">
				<ng-container
					[ngTemplateOutlet]="alertTemplate"
					[ngTemplateOutletContext]="{
						type: 'info',
						description:
							('v10.myOrder.checkInstallationOverlay.checkNebaAutoInstallationActive' | translate) === 'true'
								? ('v10.myOrder.nebaInstallation.guideNebaInternetStep5FinishSchamanActive' | translate)
								: ('v10.myOrder.nebaInstallation.nebaInternetGuideStep5Finalize' | translate)
					}"
				>
				</ng-container>
			</div>

			<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
		</div>
	</div>
</vfac-overlay>

<ng-template #navigationButtonsTemplate>
	<div class="navigation-buttons">
		<mva10-c-button (mva10OnClick)="overlayPage === 4 ? getSlid() : overlayGoForwards()">
			{{
				(overlayPage === pagesNames.length
					? 'v10.myOrder.nebaInstallation.nebaInternetGuideOverlayPageFinalize'
					: overlayPage === 4
					? numErrorsWhileGettingSlid > 0
						? 'v10.myOrder.nebaInstallation.nebaInternetGuideOverlayPageRetry'
						: 'v10.myOrder.nebaInstallation.nebaInternetGuideOverlayPageConfigure'
					: overlayPage === 1
					? 'v10.myOrder.nebaInstallation.nebaInternetGuideStart'
					: 'v10.myOrder.nebaInstallation.nebaInternetGuideNextStep'
				) | translate
			}}
		</mva10-c-button>
	</div>
</ng-template>

<ng-template
	#greyCardTemplate
	let-title="title"
	let-description="description"
	let-image="image"
	let-imageAlt="imageAlt"
	let-customClass="customClass"
>
	<div class="grey-card">
		<div class="activate-router-title mva10-u-heading--4 vfes-mb" *ngIf="title">
			<strong> {{ title | translate }} </strong>
		</div>
		<p *ngIf="description" class="activate-router-description mva10-u-body" [innerHTML]="description | translate"></p>
		<div class="activate-router-image" *ngIf="image" [ngClass]="customClass">
			<img [src]="image" [alt]="imageAlt" />
		</div>
	</div>
</ng-template>

<ng-template #alertTemplate let-type="type" let-description="description">
	<div class="vfes-mb" *ngIf="type">
		<mva10-c-alert [state]="type" layout="horizontal">
			<span class="mva10-u--body" [innerHTML]="description | translate"> </span>
		</mva10-c-alert>
	</div>
</ng-template>

<vfac-overlay
	[overlayData]="nebaInstallationGuideExitData"
	[show]="showNebaExitGuideOverlay"
	[loaded]="true"
	[loaderType]="'vodafone'"
	(closed)="closeExitOverlay('header')"
>
	<div body>
		<div class="guide-step exit-overlay no-padding-bottom">
			<div class="content">
				<div class="image-exit">
					<img src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallation.img.confused' | translate) }}" alt="" />
				</div>
				<div class="title-exit mva10-u-heading--4">
					<strong>
						{{ 'v10.myOrder.nebaInstallation.nebaInternetGuideExitAbandonInstallationProcess' | translate }}
					</strong>
				</div>
				<p
					class="description-exit mva10-u-body"
					[innerHTML]="'v10.myOrder.nebaInstallation.nebaInternetGuideExitReturnAccessMyOrders' | translate"
				></p>
			</div>
			<div class="button-exit navigation-buttons">
				<div class="button-continue">
					<mva10-c-button (mva10OnClick)="closeExitOverlay('continue')">
						{{ 'v10.myOrder.nebaInstallation.nebaInstallationGuideExitData.primaryButton' | translate }}
					</mva10-c-button>
				</div>
				<div>
					<mva10-c-button class="vfes-colours-bg-light-white" [appearance]="'alt1'" (mva10OnClick)="buttonExit()">
						{{ 'v10.myOrder.nebaInstallation.nebaInstallationGuideExitData.secondaryButton' | translate }}
					</mva10-c-button>
				</div>
			</div>
		</div>
	</div>
</vfac-overlay>
