import { Input, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { AnonymousPostPaidPaymentService } from '../../../../payment/services/anonymous-post-paid-payment.service';
import { AppService } from '../../../../app.service';
import { PaymentCompoentInputData_Keys } from '../../../../shared/enums/payment-compoent-input-data-keys.enum';
import { PaymentPages } from '../../../../shared/enums/payment-pages.enum';
import { PaymentJourny } from '../../../../shared/enums/payment-journy.enum';
import { PaymentNavigationService } from '../../../../payment/services/payment-navigation.service';
import { BillingService } from '../../../../billing/billing.service';
import { PaymentAnonymousState } from 'src/app/shared/enums/payment-anonymous-state';
import { FOLDER_ANONYMOUS_PAYMENT_MY_PAYMENTS } from 'src/app/shared/constants/archetype/paymentsTracking';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';
import { NewTaggingHelperService } from 'src/app/core/services/new-tagging.helper.service';
@Component({
	selector: 'sp-payment-billing',
	templateUrl: './payment-billing.component.html',
	styleUrls: ['./payment-billing.component.scss'],
})
export class PaymentBillingComponent implements OnInit {
	isAdvance: boolean = false;
	isDebt: boolean = true;
	@Input() showDebt: boolean = false;

	constructor(
		public appService: AppService,
		private payNavService: PaymentNavigationService,
		public billingService: BillingService,
		public anonPayService: AnonymousPostPaidPaymentService,
		private newTagging: NewTaggingHelperService
	) {}

	ngOnInit(): void {
		this.newTagging.getJsonTagging(FOLDER_ANONYMOUS_PAYMENT_MY_PAYMENTS).subscribe((data: NewTaggingJsonModel) => {
			this.newTagging.state(data.page);
		});
	}

	/**To be developed in the next story */
	payInAdvance(): void {
		this.payNavService.openPaymentComponent(PaymentPages.inadvance, PaymentJourny.anonymousPayInAdvance, [
			{
				key: PaymentCompoentInputData_Keys.isAnonymous,
				value: true,
			},
		]);
	}
	/**To be developed in the next story */
	pay(): void {
		this.billingService.deptAmount = parseFloat(
			this.anonPayService.anonymousPaymentCustomer?.dept?.accountBalanceAmount
		);
		this.payNavService.openPaymentComponent(PaymentPages.dept, PaymentJourny.anonymousDebtTotal, [
			{
				key: PaymentCompoentInputData_Keys.isAnonymous,
				value: true,
			},
		]);
	}

	public get paymentReflectionStatus(): typeof PaymentAnonymousState {
		return PaymentAnonymousState;
	}
}
