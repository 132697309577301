<div
	*ngIf="savings.text"
	class="component_new_savings mva10-u--pt4x mva10-u--pb4x"
	[ngClass]="{
		component_new_savings__morado: savings.backgroundColor === 'morado',
		component_new_savings__azul: savings.backgroundColor === 'azul',
		component_new_savings__rojo: savings.backgroundColor === 'rojo'
	}"
>
	<p
		id="SharedSavingsComponentDataSaving"
		class="mva10-u-heading--6"
		[ngClass]="{ colorWhite: savings.colorText === 'blanco' }"
		[innerHTML]="savings.text"
	></p>
</div>
