<footer
	class="container"
	[ngClass]="{
		'default-background': isMobileScrollable,
		'shadow-footer': shadowFooter && !isMobileScrollable,
		'container-with-label-above': btnText,
		'container-without-label-above': !btnText
	}"
	#firstFooter
>
	<p *ngIf="btnText" class="text" id="sharedComponentFixedButtonTextAbove" [innerHTML]="btnText"></p>
	<button
		[ngClass]="{ dimmed: disabled }"
		class="button"
		[disabled]="disabled"
		type="button"
		(click)="btnClick()"
		id="sharedComponentFixedButtonBtn"
	>
		{{ btnName }}
	</button>
</footer>

<footer
	*ngIf="grayBackgroundBelowFooter"
	class="grey-Container"
	id="sharedComponentFixedButtonGreyFooter"
	#remaninigFooter
></footer>
