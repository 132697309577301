<div class="otp__container">
	<form #form class="otp__form" [formGroup]="infoForm" #otpForm (submit)="submitOtp(!infoForm.invalid)">
		<div class="otp__upperSection">
			<div id="paymentOtpImg" class="otp__header-image">
				<img
					class="otp__image"
					src="{{ appService.getImgFullPath('v10.payment.itemsList.anonymousPayment.es.otp.icon' | translate) }}"
				/>
			</div>
			<div class="otp__desc">
				<p
					class="otp__subtitle"
					id="paymentOtpDesc"
					[innerHTML]="'v10.payment.itemsList.anonymousPayment.es.otp.subtitle' | translate"
				></p>
			</div>

			<div class="otp__input">
				<mva10-c-text-field
					[idInput]="'paymentOtpid'"
					ngDefaultControl
					isRequired="true"
					name="otp"
					id="paymentOtpid"
					formControlName="otp"
					[invalid]="showOtpError"
					[error]="showOtpError"
					[limit]="6"
					(mva10blur)="checkOtpValidity(true)"
					(mva10focus)="checkOtpValidity(false)"
					(input)="checkOtpValidity(false)"
					[disabled]="counter === 0"
					[textLabel]="'payment.enterCode.editPageTitle' | translate"
					[ngModelInput]="otpText"
					[helperText]="wrongOtp ? inValidPinCodeErrorText : incorrectPinCodeError"
				>
				</mva10-c-text-field>

				<div class="otp__timer" [ngClass]="{ disabled: counter === 0 }">
					<mva10-c-icon iconId="icon-clock-or-timed" size="24" ngClass="mva10-u-color--dark-grey"> </mva10-c-icon>
					<span class="otp__timer-text">
						{{ 'v10.login.otp.timerFinishesIn' | translate }}
					</span>
					<span class="otp__time"> {{ counter | countDownFormat }} min.</span>
				</div>
				<p class="otp__resendBtn" (click)="reSendOtp()" id="paymentOtpRetryBtn">
					{{ 'v10.payment.itemsList.anonymousPayment.es.otp.button2' | translate }}
				</p>
			</div>
		</div>
		<div class="otp__lowerSection">
			<div class="otp__btn-container" id="paymentOtpSubmitBtn" [ngClass]="{ 'decrease-margin': showOtpError }">
				<mva10-c-button
					[body]="'v10.payment.itemsList.anonymousPayment.es.otp.button1' | translate"
					(click)="submitOtp(!infoForm.invalid && counter !== 0)"
					[disabled]="infoForm.invalid || counter === 0"
					[styleClass]="infoForm.invalid ? 'mva10-c-button--secondary' : 'mva10-c-button--primary'"
					[name]="'v10.payment.itemsList.anonymousPayment.es.otp.button1' | translate"
					[title]="'v10.payment.itemsList.anonymousPayment.es.otp.button1' | translate"
				>
				</mva10-c-button>
			</div>
		</div>
	</form>
	<div class="otp__footer">
		<div class="otp__footer-images">
			<div class="visa-image">
				<img
					src="{{ appService.getImgFullPath('payment.paymentMethods.images.pciVisa.url' | translate) }}"
					alt="visa"
				/>
			</div>
			<div class="mastercard-image">
				<img
					src="{{ appService.getImgFullPath('payment.paymentMethods.images.pciMasterCard.url' | translate) }}"
					alt="mastercard"
				/>
			</div>
		</div>
		<div class="otp__footer-securedssl">
			<p>{{ 'payment.itemsList.securedSsl.body' | translate }}</p>
		</div>
	</div>
</div>
