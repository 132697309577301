import { OLStatus } from '../enums/ol-status.enum';

export interface HistoryStatusMap {
	[statusCode: string]: HistoryStatusDetail;
}

export class HistoryStatusDetail {
	step?: string;
	status?: OLStatus;
	statusDescription: string;
	callButton?: number;
	showAlert?: boolean;
	alertMsg?: string;
	statusCode?: string;
	completedStep?: boolean;
	date?: number;
	detailedDescription?: string;
	currentStep?: boolean;
	subSteps?: HistoryStatusDetail[];
	outdatedSLA?: boolean;
}
