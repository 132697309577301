import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URLS } from '../constants/routes-config';
import * as JsonQuery from 'jsonpath/jsonpath';
import {
	JSON_PATHS,
	multifinancingKeyNames,
	coin,
	perMonth,
	amortizeTypes,
	AccountManagementHeader,
} from '../constants/defines';
import { FundedDevice, PaymentDetail } from '../../models/funded-device.model';
import { Observable } from 'rxjs';
import { StorageService } from '../../core/services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { FinancialPlanType } from '../enums/financialPlanType.enum';
import { FinancialBalanceType } from '../enums/financialBalanceType.enum';
import { PaymentStatus } from '../enums/paymentStatus.enum';
import { map } from 'rxjs/operators';
import { BillingService } from '../../billing/billing.service';
import { siteTypes } from '../enums/siteStatus.enum';

@Injectable({
	providedIn: 'root',
})
export class FundedDevicesService {
	private payedQuotes: number;
	private notIssuedQuotes: number;
	private pendingQuotes: number;
	private notPayedQuotes: number;
	private nextPlan: PlanDTO;

	constructor(
		private http: HttpClient,
		private storageService: StorageService,
		private translate: TranslateService,
		private billingService: BillingService
	) {}

	/**
	 * Returns all site funded devices
	 * @param siteId string
	 */
	public GetMultifinancingBySiteId(siteId: string, mapDetails?: boolean): Observable<FundedDevice[]> {
		const url: string = API_URLS.AccountManagement.GetMultifinancingBySiteId.replace(
			'{NIF}',
			this.storageService.userProfile.document.id
		).replace('{siteId}', siteId);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append(AccountManagementHeader.name, AccountManagementHeader.value);
		return this.http.get(url, { headers, withCredentials: true }).pipe(
			map((res: Array<any>) => {
				const fundedDevices: FundedDevice[] = [];
				if (res?.length) {
					res.map((item) => fundedDevices.push(this.deviceLogic(item, mapDetails)));
				}
				return fundedDevices;
			})
		);
	}
	/**
	 * Returns all site funded devices
	 * @param siteId string
	 */
	public GetfinancedDevicesBySiteId(siteId: string, mapDetails?: boolean): Observable<FundedDevice[]> {
		const siteType: string = this.billingService.getCurrentSite().type;
		const url: string = (
			siteType.toLowerCase() === siteTypes.maestra.toLowerCase()
				? API_URLS.AccountManagement.GetFinancedDevicesBySiteIdWithMaestraType
				: API_URLS.AccountManagement.GetFinancedDevicesBySiteId
		)
			.replace('{NIF}', this.storageService.userProfile.document.id)
			.replace('{siteId}', siteId);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append(AccountManagementHeader.name, AccountManagementHeader.value);
		return this.http.get(url, { headers, withCredentials: true }).pipe(
			map((res: Array<any>) => {
				const fundedDevices: FundedDevice[] = [];
				if (res?.length) {
					res.map((item) => fundedDevices.push(this.deviceLogic(item, mapDetails)));
				}
				return fundedDevices;
			})
		);
	}

	/**
	 * Returns all  funded devices without side ID
	 */
	public GetfinancedDevicesPage(mapDetails?: boolean): Observable<FundedDevice[]> {
		const url: string = API_URLS.AccountManagement.GetFinancedDevicesBySiteIdPage.replace(
			'{NIF}',
			this.storageService.userProfile.document.id
		);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append(AccountManagementHeader.name, AccountManagementHeader.value);
		return this.http.get(url, { headers, withCredentials: true }).pipe(
			map((res: Array<any>) => {
				const fundedDevices: FundedDevice[] = [];
				if (res?.length) {
					res.forEach((item) => fundedDevices.push(this.deviceLogic(item, mapDetails)));
				}
				return fundedDevices;
			})
		);
	}
	/**
	 * Returns funced devices linked to a msisdn
	 * @param siteId string
	 * @param msisdn string
	 */
	public GetMultifinancingByMsisdn(siteId: string, msisdn: string): Observable<FundedDevice[]> {
		const url: string = API_URLS.AccountManagement.GetMultifinancingByMsisdn.replace(
			'{NIF}',
			this.storageService.userProfile.document.id
		)
			.replace('{siteId}', siteId)
			.replace('{msisdn}', msisdn);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append(AccountManagementHeader.name, AccountManagementHeader.value);
		return this.http.get(url, { headers, withCredentials: true }).pipe(
			map((res: Array<any>) => {
				const fundedDevices: FundedDevice[] = [];
				if (res?.length) {
					res.map((item) => fundedDevices.push(this.deviceLogic(item, true)));
				}
				return fundedDevices;
			})
		);
	}

	/**
	 * @param siteId current site id
	 * @param msisdn financing associated msisdn
	 * @param imei device imei
	 */
	public acceptRefinancing(siteId: string, msisdn: string, imei: string): Observable<any> {
		const url: string = API_URLS.ProductOrderingManagement.ProductOrder;
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('X-VF-API-Process', 'AceptacionRefinanciacion');
		const options: { [key: string]: HttpHeaders } = {
			headers: headers,
		};
		const body: { [key: string]: any } = {
			billingAccount: {
				id: siteId,
			},
			productOrderItem: [
				{
					id: msisdn,
					action: amortizeTypes.refin,
					product: {
						id: imei,
					},
				},
			],
		};
		return this.http.post(url, body, options).pipe(
			map((res) => {
				return res;
			})
		);
	}

	public amortizeRefinancing(
		siteId: string,
		msisdn: string,
		imei: string,
		typeAction: string,
		cancelRates: number,
		cancelQuotes: number
	): Observable<any> {
		const url: string = API_URLS.ProductOrderingManagement.ProductOrder;
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('X-VF-API-Process', 'PagoFinanciacion');
		const options: any = {
			headers: headers,
		};
		const body: any = {
			billingAccount: {
				id: siteId,
			},
			productOrderItem: [
				{
					id: imei,
					action: typeAction,
					itemPrice: [
						{
							price: {
								taxIncludedAmount: {
									value: cancelRates,
								},
							},
						},
					],
					itemTerm: [
						{
							name: 'Financing',
							duration: {
								amount: cancelQuotes,
							},
						},
					],
					payment: [
						{
							id: imei,
							name: 'Domiciliacion',
						},
					],
					product: {
						id: msisdn,
					},
				},
			],
		};
		return this.http.post(url, body, options).pipe(
			map((res) => {
				return res;
			})
		);
	}
	private deviceLogic(item: any, mapDetails: boolean): FundedDevice {
		const fundedDevice: FundedDevice = new FundedDevice();
		this.initRelatedParty(item, fundedDevice);
		this.initCharacteristic(item, fundedDevice);
		if (mapDetails) {
			const balanceTypes: BalanceTypes = this.initAccountBalance(item);
			this.mapRegularBalanceTypes(fundedDevice, balanceTypes);
			this.mapDeferredBalanceTypes(fundedDevice, balanceTypes);
			const planTypes: PlanTypes = this.initPaymentPlans(item, fundedDevice);
			this.mapRegularQuotesPlanTypes(fundedDevice, planTypes);
			this.mapDeferredQuotesPlanTypes(fundedDevice, planTypes);
			this.mapDatesPlanTypes(fundedDevice, planTypes);
			this.fundedDeviceLogic(fundedDevice);
		}
		return fundedDevice;
	}

	private initRelatedParty(item: any, fundedDevice: FundedDevice): void {
		fundedDevice.id = JsonQuery.value(item, JSON_PATHS.fundedDevices.id);
		const sap: string = JsonQuery.value(item, JSON_PATHS.fundedDevices.sap) || 'default';
		fundedDevice.imagePath = this.translate.instant(`v10.productsServices.fundedDevices.images.${sap}`);
		if (fundedDevice.imagePath.includes('v10.productsServices.fundedDevices')) {
			fundedDevice.imagePath = this.translate.instant(`v10.productsServices.fundedDevices.images.default`);
		}
		const relatedParty: Characteristic[] = JsonQuery.value(item, JSON_PATHS.fundedDevices.relatedParty) || [];
		const msisdn: Characteristic = relatedParty.find(
			(el) => el.name.toLowerCase() === multifinancingKeyNames.subscriber
		);
		fundedDevice.msisdn = msisdn ? msisdn.id : '';
		fundedDevice.paymentStatus = item?.paymentStatus;
		fundedDevice.state = item?.state;
	}

	private initCharacteristic(item: any, fundedDevice: FundedDevice): void {
		const productCharacteristic: Characteristic[] =
			JsonQuery.value(item, JSON_PATHS.fundedDevices.productCharacteristic) || [];
		const brand: Characteristic = productCharacteristic.find(
			(el) => el.name.toLowerCase() === multifinancingKeyNames.marcaterminal
		);
		fundedDevice.brand = brand ? brand.value : '';
		const model: Characteristic = productCharacteristic.find(
			(el) => el.name.toLowerCase() === multifinancingKeyNames.modeloterminal
		);
		fundedDevice.model =
			model && model.value && model.value.length
				? model.value
				: this.translate.instant('v10.productsServices.fundedDevices.model.default');
	}

	private initAccountBalance(item: any): BalanceTypes {
		const balanceTypes: BalanceTypes = new BalanceTypes();
		const accountBalance: BalanceDTO[] = JsonQuery.value(item, JSON_PATHS.fundedDevices.accountBalance) || [];
		accountBalance.forEach((balance: BalanceDTO) => (balanceTypes[balance.balanceType] = balance));
		return balanceTypes;
	}

	private initPaymentPlans(item: any, fundedDevice: FundedDevice): PlanTypes {
		const planTypes: PlanTypes = new PlanTypes();
		this.payedQuotes = 0;
		this.pendingQuotes = 0;
		this.notIssuedQuotes = 0;
		this.notPayedQuotes = 0;
		let previousQuote: number = 0;
		fundedDevice.regularPayment.totalQuotes = 0;
		const paymentPlans: PlanDTO[] = JsonQuery.value(item, JSON_PATHS.fundedDevices.paymentPlan) || [];
		paymentPlans.forEach((plan: PlanDTO) => {
			planTypes[plan.planType] = plan;
			if (plan.planType === FinancialPlanType.Fee && plan.numberOfPayments !== previousQuote) {
				fundedDevice.regularPayment.totalQuotes++;
				this.calculateQuotes(plan.status);
				previousQuote = plan.numberOfPayments;
				if (plan.numberOfPayments === this.payedQuotes + 1) {
					this.nextPlan = plan;
				}
			}
		});
		return planTypes;
	}

	private calculateQuotes(planStatus: string = ''): void {
		switch (planStatus.toLowerCase()) {
			case PaymentStatus.Pagada.toLowerCase():
				this.payedQuotes++;
				break;
			case PaymentStatus.SinEmitir.toLowerCase():
				this.notIssuedQuotes++;
				break;
			case PaymentStatus.Pendiente.toLowerCase():
				this.pendingQuotes++;
				break;
			case PaymentStatus.Impagadas.toLowerCase():
				this.notPayedQuotes++;
				break;
		}
	}

	private mapRegularBalanceTypes(fundedDevice: FundedDevice, balanceTypes: BalanceTypes): void {
		const {
			InitialPayment,
			Pendingam,
			Payedam,
			Pending,
			Payed,
			CuotaPenCan,
			TotalPending,
			TotalPendingAm,
		}: BalanceTypes = balanceTypes;

		fundedDevice.initialPayment = InitialPayment && InitialPayment.amount ? InitialPayment.amount.value : 0;
		fundedDevice.totalPendingQuotes = TotalPending?.amount ? TotalPending.amount.value : 0;
		fundedDevice.totalPendingAm = TotalPendingAm?.amount ? TotalPendingAm.amount.value : 0;
		const regularPaymentDetail: PaymentDetail = new PaymentDetail();
		regularPaymentDetail.pendingAmount = Pendingam && Pendingam.amount ? Pendingam.amount.value : 0;
		regularPaymentDetail.pendingCancelledQuotes = CuotaPenCan?.amount ? CuotaPenCan.amount.value : 0;
		regularPaymentDetail.payedAmount = Payedam && Payedam.amount ? Payedam.amount.value : 0;
		regularPaymentDetail.pendingQuotes = Pending && Pending.amount ? Pending.amount.value : 0;
		regularPaymentDetail.payedQuotes = Payed && Payed.amount ? Payed.amount.value : 0;
		fundedDevice.regularPayment = regularPaymentDetail;
	}

	private mapDeferredBalanceTypes(fundedDevice: FundedDevice, balanceTypes: BalanceTypes): void {
		const { CuotaFp, PendingamPf, FpPendingDue, PendingPf }: BalanceTypes = balanceTypes;
		const deferredPaymentDetail: PaymentDetail = new PaymentDetail();
		deferredPaymentDetail.pendingAmount = PendingamPf && PendingamPf.amount ? PendingamPf.amount.value : 0;
		deferredPaymentDetail.totalQuotes = FpPendingDue && FpPendingDue.amount ? FpPendingDue.amount.value : 0;
		deferredPaymentDetail.pendingQuotes = PendingPf && PendingPf.amount ? PendingPf.amount.value : 0;
		const deferredMonthlyQuote: number = CuotaFp?.amount ? CuotaFp.amount.value : 0;
		deferredPaymentDetail.monthlyQuote = this.formatQuote(deferredMonthlyQuote);
		fundedDevice.deferredPayment = deferredPaymentDetail;
	}

	private mapRegularQuotesPlanTypes(fundedDevice: FundedDevice, planTypes: PlanTypes): void {
		const { TotalFinanciado, Cuota }: PlanTypes = planTypes;
		fundedDevice.totalAmount = TotalFinanciado && TotalFinanciado.totalAmount ? TotalFinanciado.totalAmount.value : 0;
		fundedDevice.regularPayment.totalAmount = fundedDevice.totalAmount;
		const regularMonthlyQuote: number = Cuota && Cuota.totalAmount ? Cuota.totalAmount.value : 0;
		fundedDevice.regularPayment.monthlyQuote = this.formatQuote(regularMonthlyQuote);
		fundedDevice.regularPayment.monthlyQuoteNotFormated = regularMonthlyQuote;
	}

	private mapDeferredQuotesPlanTypes(fundedDevice: FundedDevice, planTypes: PlanTypes): void {
		const { FinalpaymentAm, Estadorefin }: PlanTypes = planTypes;
		fundedDevice.deferredPayment.totalAmount =
			FinalpaymentAm && FinalpaymentAm.totalAmount ? FinalpaymentAm.totalAmount.value : 0;
		fundedDevice.pendingFinalPaymentAproval = Estadorefin ? Estadorefin.status === '1' : false;
		fundedDevice.deferToBeConfirmed = Estadorefin ? Estadorefin.status === '2' : false;
		fundedDevice.deferAccepted = parseFloat(Estadorefin?.status) > 2;
	}

	private mapDatesPlanTypes(fundedDevice: FundedDevice, planTypes: PlanTypes): void {
		const { Duedate, Inidate, AceptanceDuedate, AceptanceInidate, FpDateEnd }: PlanTypes = planTypes;
		fundedDevice.nextQuoteDate =
			this.nextPlan && this.nextPlan.validFor ? this.getValidDate(this.nextPlan.validFor.endDateTime) : null;
		fundedDevice.finalQuoteDate = Duedate && Duedate.validFor ? this.getValidDate(Duedate.validFor.endDateTime) : null;
		fundedDevice.finalPaymentDueDate = FpDateEnd?.validFor ? this.getValidDate(FpDateEnd.validFor.endDateTime) : null;
		fundedDevice.purchaseDate = Inidate && Inidate.validFor ? this.getValidDate(Inidate.validFor.endDateTime) : null;
		fundedDevice.aceptanceIniDate =
			AceptanceInidate && AceptanceInidate.validFor ? this.getValidDate(AceptanceInidate.validFor.endDateTime) : null;
		fundedDevice.aceptanceDueDate =
			AceptanceDuedate && AceptanceDuedate.validFor ? this.getValidDate(AceptanceDuedate.validFor.endDateTime) : null;
	}

	private fundedDeviceLogic(fundedDevice: FundedDevice): void {
		fundedDevice.isCompletedRegularFunded = fundedDevice.regularPayment.pendingQuotes === 0;
		fundedDevice.hasFinalPayment = fundedDevice.deferredPayment.totalQuotes !== 0;
		fundedDevice.totalPendingAmount =
			fundedDevice.regularPayment.pendingAmount + fundedDevice.deferredPayment.pendingAmount;
		fundedDevice.isDeferrable = fundedDevice.deferredPayment.totalQuotes > 1;
		fundedDevice.isCompletedFunded =
			fundedDevice.isCompletedRegularFunded && fundedDevice.deferredPayment.pendingQuotes === 0;
		fundedDevice.isLastPayment =
			this.payedQuotes + this.notIssuedQuotes + this.pendingQuotes + this.notPayedQuotes === 1;
		fundedDevice.showFinalPaymentData =
			fundedDevice.deferAccepted && fundedDevice.isCompletedRegularFunded && fundedDevice.hasFinalPayment;
		if (fundedDevice.aceptanceIniDate && fundedDevice.aceptanceDueDate) {
			const today: Date = new Date();
			fundedDevice.isAceptancePeriod =
				fundedDevice.aceptanceIniDate &&
				today > fundedDevice.aceptanceIniDate &&
				fundedDevice.aceptanceDueDate &&
				today < fundedDevice.aceptanceDueDate;
		} else {
			fundedDevice.isAceptancePeriod = true;
		}
	}

	private formatQuote(quote: number): string {
		return `${quote.toFixed(2).toString().replace('.', ',')}${coin}${perMonth}`;
	}

	private getValidDate(stringDate: string): Date {
		const date: Date = new Date(stringDate);
		return isNaN(date.getTime()) ? null : date;
	}
}

interface Characteristic {
	id?: string;
	name?: string;
	value?: string;
}

interface BalanceDTO {
	balanceType: FinancialBalanceType;
	amount: {
		unit: string;
		value: number;
	};
	validFor: {
		startDateTime: string;
		endDateTime: string;
	};
}
class BalanceTypes {
	InitialPayment: BalanceDTO;
	CuotaPenCan: BalanceDTO;
	Pendingam: BalanceDTO;
	Payedam: BalanceDTO;
	Pending: BalanceDTO;
	Payed: BalanceDTO;
	PendingamPf: BalanceDTO;
	FpPendingDue: BalanceDTO;
	PendingPf: BalanceDTO;
	CuotaFp: BalanceDTO;
	TotalPendingAm: BalanceDTO;
	TotalPending: BalanceDTO;
}

interface PlanDTO {
	numberOfPayments: number;
	planType: FinancialPlanType;
	priority: number;
	status: string;
	totalAmount: {
		unit: string;
		value: number;
	};
	validFor: {
		startDateTime: string;
		endDateTime: string;
	};
}
class PlanTypes {
	TotalFinanciado: PlanDTO;
	Cuota: PlanDTO;
	FinalpaymentAm: PlanDTO;
	Estadorefin: PlanDTO;
	Duedate: PlanDTO;
	Inidate: PlanDTO;
	AceptanceDuedate: PlanDTO;
	AceptanceInidate: PlanDTO;
	FpDateEnd: PlanDTO;
}
