import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URLS } from '../constants/routes-config';
import { SubscriptionService } from '../../core/services/subscription.service';

@Injectable()
export class ContactUsService {
	constructor(private http: HttpClient, private subscription: SubscriptionService) {}
	sendContactUsRequest(section, description, email) {
		const url = API_URLS.contactUs.patchContactUs.replace(
			'{siteId}',
			this.subscription.customerData.customerAccountsId
		);
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');
		const options = {
			headers: headers,
		};
		const body = {
			email: email,
			description: description,
			section: section,
		};
		return this.http.patch(url, body, options);
	}
}
