<div class="pay-debt-other-methods" [ngClass]="{ 'forward-transition-tray': billingService.forwardTransition }">
	<div>
		<div class="mva10-o-layout">
			<div class="mva10-o-layout__item margin-bottom-16">
				<div>
					<div class="vfes-1/1" data-card="title" class="title">
						<p class="vfes-title--bold vfac-c-main-tile-simple__title">
							{{ 'billing.readyPayBill.fieldsList.payThroughBank.title' | translate }}
						</p>
					</div>
					<div>
						<p
							class="vfac-c-main-tile-description"
							[ngClass]="{ 'margin-top': billingService.showOtherPaymentMethodsSecondBtn }"
							[innerHtml]="payThroughBankBody"
						></p>
					</div>
				</div>
			</div>
		</div>

		<div class="mva10-o-layout card-container" *ngIf="!billingService.showOtherPaymentMethodsSecondBtn">
			<div class="mva10-o-layout__item">
				<div>
					<div class="vfes-1/1" data-card="title" class="title">
						<p class="vfes-title--bold vfac-c-main-tile-simple__title">
							{{ 'billing.readyPayBill.fieldsList.payThroughPost.title' | translate }}
						</p>
					</div>
					<div>
						<p
							class="vfac-c-main-tile-description"
							[innerHtml]="'billing.readyPayBill.fieldsList.payThroughPost.body' | translate"
						></p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div [ngClass]="{ 'btns-container': billingService.showOtherPaymentMethodsSecondBtn }">
		<div
			class="btn-container"
			[ngClass]="{ 'cash-desk-download-btn-container': billingService.showOtherPaymentMethodsSecondBtn }"
		>
			<button (click)="billingService.showOtherPaymentMethodsSecondBtn ? downloadPdf() : dynamicTray.close()">
				{{ firstBtnDescription }}
			</button>
		</div>
		<div class="cash-desk-close-btn-container" *ngIf="billingService.showOtherPaymentMethodsSecondBtn">
			<mva10-c-button
				[body]="'payment.buttonList.otherMethodsConfirmButton.text' | translate"
				(click)="closeTray()"
				[styleClass]="'mva10-c-button--tertiary'"
				[name]="'payment.buttonList.otherMethodsConfirmButton.text' | translate"
				[title]="'payment.buttonList.otherMethodsConfirmButton.text' | translate"
			>
			</mva10-c-button>
		</div>
	</div>
</div>
