import { Component, Input } from '@angular/core';

@Component({
	selector: 'sp-warn-box',
	templateUrl: './sp-warn-box.component.html',
	styleUrls: ['./sp-warn-box.component.scss'],
})
export class SpWarnBoxComponent {
	@Input() text: string;
	@Input() messages: string[];
	@Input() info: boolean;
	@Input() fijo: boolean;
	@Input() isError: boolean;
}
