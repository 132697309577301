export enum opProducts {
	opProfesionalSolution = 1,
	opGsuite = 4,
	negocioDigital = 8,
	gestionLaboral = 9,
	wifiNegocio = 3,
	ipFija = 5,
	office365 = 10,
}
export const opProductsN: OpProductMod[] = [
	{ name: 'opProfesionalSolution', value: 1, wcs: '', selectorCategory: false },
	{
		name: 'office365',
		value: 10,
		wcs: 'v10.commercial.microCartera.oneProLanding.product.office365.',
		selectorCategory: true,
	},
	{
		name: 'opGsuite',
		value: 4,
		wcs: 'v10.commercial.microCartera.oneProLanding.product.gsuite.',
		selectorCategory: true,
	},
	{
		name: 'negocioDigital',
		value: 8,
		wcs: 'v10.commercial.microCartera.oneProLanding.product.negocioDigital.',
		selectorCategory: true,
	},
	{
		name: 'gestionLaboral',
		value: 9,
		wcs: 'v10.commercial.microCartera.oneProLanding.product.gestionLaboral.',
		selectorCategory: false,
	},
];

export class OpProductMod {
	name?: string;
	value?: number;
	wcs?: string;
	selectorCategory?: boolean;
}

export enum opParams {
	productid = 'productid',
	productname = 'productname',
	modalityid = 'modalityid',
	pegaDiscount = 'pegaDiscount',
}

export class OpProductData {
	title?: string;
	description?: string;
	actProduct?: OneProfesionalProduct[] | Shop;
}

export class CurrentSiteServices {
	title?: string;
	display?: boolean;
	dataProductsList?: DataProductsList[];
}

export class DataProductsList {
	section?: string;
	description?: string;
	productData?: OpProductData;
	tvProduct?: TvProduct;
	position?: number;
}

export class CategoryData {
	nameCategory?: string;
	posCategory?: number;
}

export class DataProduct {
	title?: string;
	description?: string;
	name?: string;
	id?: number;
	idCCategoria?: number;
	itTryAndBuy?: boolean;
	imgUrlIkki?: string;
	isEntryPoint?: boolean;
	link?: string;
	link_web?: string;
	position?: number;
	idGroup?: number;
}

export class OneProfesionalProduct {
	id?: number;
	idTipoTienda?: number;
	idTipoEstado?: number;
	idTipoAsociado?: number;
	idCPuntoProcesoCompra?: number;
	idCCategoria?: number;
	idTipoAgrupacion?: number;
	itCatalogofferFamilia?: boolean;
	name?: string;
	dsDescripcionInterna?: string;
	position?: number;
	imgUrlIkki?: string;
	clHtmlDescrLargaIkki?: string;
	clHtmlDescrNoDispIkki?: string;
	itTryAndBuy?: boolean;
	tvProduct?: TvProduct;
	productData?: OpProductData;
}

export interface TvProduct {
	SHOP: Shop;
	link: string;
	link_web: string;
	name: string;
	analyticsName: string;
}

export interface Shop {
	distrib: TvData;
	name?: string;
}

interface TvData {
	image: string;
	image_rsp: string;
	desc: string;
	title: string;
	category: string;
	section: string;
	longDesc: string;
}

export class OrderData {
	description: string;
	name: string;
}

export class Categories {
	description: string;
	section: string;
}
