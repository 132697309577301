export class AllowedServices {
	public serviceId: string;
	public value: string;
	public code: string;
	public productType: string;
	public serviceType: string;
	public serviceName: string;
	public packageName: string;
	public allowed: boolean;
	public origin?: string;
	public registerType?: number;
	public itemId?: number;
	public selected?: boolean = false;
	public premium?: boolean;
}
