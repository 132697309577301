<div class="search-autocomplete mva10-o-layout">
	<div class="mva10-o-layout__item search-box-contanier">
		<form role="form" [formGroup]="autoCompleteForm">
			<p class="mva10-u-body" *ngIf="label">{{ label }}</p>
			<mva10-c-autocomplete
				search="true"
				(searching)="searchClicked()"
				formControlName="autoComplete"
				[data]="data"
				(changeSelect)="changeSelectEvent($event)"
				[placeholder]="placeholder || ''"
				[nameInput]="searchBoxInput"
				(mva10OnClick)="onClick($event)"
				[ngModelInput]="InputValueText"
				[optionsBeginWith]="true"
				[idInput]="(id || '') + 'AutoComplete'"
				[idUl]="(id || '') + 'AutoCompleteUl'"
			>
			</mva10-c-autocomplete>
		</form>
	</div>
</div>
