export class DeliveryHours {
	idModalidadEntrega: string;
	idTipoEntrega: string;
	cdTipoDeliveryType: string;
	xOpcEntrega: string;
	nombreModalidadEntrega: string;
	dsHoraEntrega: string;
	dsOpcEntrega: string;
	xIdentificacion: string;
	xPrioridadEntrega: string;
	eurPrecio: number;
	eurPrecioPromocion: number;
	eurPrecioTaxes: number;
	eurPrecioPromocionTaxes: number;
	cdCatalogOfferCharge: string;
	dsCatalogOfferChargeName: string;
	euCatalogOfferCharge: number;
}
