import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../app.service';
import { UtilitiesService } from '../../shared/utils/utilities.service';

@Component({
	selector: 'sp-open-source-libraries',
	templateUrl: './open-source-libraries.component.html',
	styleUrls: ['./open-source-libraries.component.scss'],
})
export class OpenSourceLibrariesComponent implements OnInit {
	libraries: any[] = [];

	showTopDiv: boolean;
	constructor(
		private translate: TranslateService,
		public utilitiesService: UtilitiesService,
		public appService: AppService
	) {}

	ngOnInit() {
		this.showTopDiv = false;
		this.translate.get('v10.legal.libraries.web.itemList').subscribe((items: any[]) => {
			items.forEach(({ name, url }) => {
				this.libraries.push({ name, url });
			});
		});
	}
	openLink(url: string) {
		window.open(url, '_blank');
	}
}
