<div class="vfac-submenu-products vfes-5/6">
	<p class="vfac-submenu-products__title mva10-u--pb3x">
		{{ 'v10.common.tray.overlay_myproducts.myproducts' | translate }}
	</p>
	<p class="vfac-submenu-products__subtitle">{{ 'v10.common.tray.overlay_myproducts.chooseproducts' | translate }}</p>

	<sp-adara-selector
		(telcoFilterSelected)="telcoFilter = $event"
		[locationClass]="'submenu-products'"
		*ngIf="customerAccountService.showAdaraSelector"
	>
	</sp-adara-selector>

	<mva10-c-tabs-simple
		*ngIf="customerAccountService.selectorCIFData && customerAccountService.selectorCIFData.length > 1 && telcoFilter"
		[dataSelectTab]="leftMenuService.cifTabIdx"
		[styleInline]="{ 'margin-bottom': '24px' }"
		(mva10OnClick)="leftMenuService.setCifSelectorClicked($event)"
	>
		<mva10-c-tab *ngFor="let tab of customerAccountService.selectorCIFData; let i = index" [title]="tab">
			<ng-container *ngIf="leftMenuService.addressSelectorLoaded; then content; else skeleton"> </ng-container>
		</mva10-c-tab>
	</mva10-c-tabs-simple>

	<ng-container
		*ngIf="!customerAccountService.selectorCIFData || customerAccountService.selectorCIFData.length <= 1"
		[ngTemplateOutlet]="content"
	>
	</ng-container>
</div>

<ng-template #content>
	<div
		class="vfac-submenu-products__products scrollbar-hidden mva10-u--pt2x vfes-5/6"
		*ngIf="!leftMenuService.errorCif"
	>
		<mva10-c-select-field
			*ngIf="
				leftMenuService.addressSelectorLoaded &&
				leftMenuService.addressSelector &&
				leftMenuService.addressSelector.length > 1 &&
				telcoFilter
			"
			[label]="leftMenuService.selectorDataDescription"
			[listItems]="leftMenuService.addressSelector"
			[preselectedValue]="leftMenuService.addressSelectorDefault"
			(clickEvent)="selectClick($event)"
		>
		</mva10-c-select-field>

		<div *ngIf="telcoFilter">
			<div *ngIf="leftMenuService.productSelectorLoad; else skeleton">
				<ng-container *ngIf="!leftMenuService.isSitePendingInstallation; else pendingInstall">
					<div *ngIf="leftMenuService.clientsPQ" class="mva10-u--pb4x">
						<button
							class="vfac-submenu-products__button vfes-title--bold"
							(click)="leftMenuService.productsButtonClicked()"
						>
							{{ leftMenuService.selectorDataButtonText }}
						</button>
					</div>
					<div *ngIf="!productAndService.leftMenuHideService">
						<div
							*ngFor="let item of customerAccountService.selectorDataWithSubscriptions?.cardImageSelectorList"
							(click)="leftMenuService.productSelected(item.id)"
						>
							<vfac-card-product
								[image]="item.image"
								[title]="item.title"
								[description]="item.description"
								[checked]="item.checked"
								[colorBg]="item.backgroundColor"
								[borderColor]="item.borderColor"
								extraClass="mva10-u--mb2x"
								[modifier]="this.appService.checkYuUser() ? 'border-gradient-yu' : 'line'"
							>
							</vfac-card-product>
						</div>
					</div>
				</ng-container>
				<ng-template #pendingInstall>
					<sp-pending-install-warning (linkClick)="goToMyOrders()"> </sp-pending-install-warning>
				</ng-template>
			</div>
		</div>

		<div *ngIf="!telcoFilter">
			<div *ngIf="customerAccountService.hasAdaraSite">
				<div
					*ngFor="let item of customerAccountService.adaraSelectorData?.cardImageSelectorList"
					(click)="clickOnAdaraCTATags(); leftMenuService.closeSubmenu()"
				>
					<vfac-card-product
						[svg]="'#icon-idea-or-innovation-mid'"
						[title]="item.title"
						[description]="item.description"
						[checked]="item.checked"
						[colorBg]="item.backgroundColor"
						[borderColor]="item.borderColor"
						extraClass="mva10-u--mb2x"
						[modifier]="this.appService.checkYuUser() ? 'border-gradient-yu' : 'line'"
					>
					</vfac-card-product>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #skeleton>
	<div>
		<div class="skeleton vfac-submenu-products__skeleton__button mva10-u--1/2"></div>

		<div>
			<div class="skeleton vfac-submenu-products__skeleton__item mva10-u--5/6">
				<div class="skeleton vfac-submenu-products__skeleton__title mva10-u--1/6"></div>
				<div class="skeleton vfac-submenu-products__skeleton__subtitle mva10-u--1/3"></div>
			</div>

			<div class="skeleton vfac-submenu-products__skeleton__item mva10-u--5/6">
				<div class="skeleton vfac-submenu-products__skeleton__title mva10-u--1/6"></div>
				<div class="skeleton vfac-submenu-products__skeleton__subtitle mva10-u--1/3"></div>
			</div>

			<div class="skeleton vfac-submenu-products__skeleton__item mva10-u--5/6">
				<div class="skeleton vfac-submenu-products__skeleton__title mva10-u--1/6"></div>
				<div class="skeleton vfac-submenu-products__skeleton__subtitle mva10-u--1/3"></div>
			</div>
		</div>
	</div>
</ng-template>
