import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from '../../../app.service';

@Component({
	selector: 'sp-overlay',
	templateUrl: './sp-overlay.component.html',
	styleUrls: ['./sp-overlay.component.scss'],
})
export class SpOverlayComponent {
	/**
	 * Header content
	 */
	@Input() breadcrumb: string;
	@Input() title: string;
	@Input() subtitle: string;

	/**
	 * Footer content
	 * main btn
	 */
	@Input() redBtn: string;
	@Input() redBtnClick: Function;

	/**
	 * secondary grey btn
	 */
	@Input() greyBtn: string;
	@Input() greyBtnClick: Function;

	/**
	 * secondary white btn
	 */
	@Input() whiteBtn: string;
	@Input() whiteBtnClick: Function;

	/**
	 * Condition to delete padding
	 */
	@Input() handSetRenewal: boolean;
	@Input() info: boolean;
	@Input() filters: boolean;

	@Output() closed = new EventEmitter();
	@Output() loadOverlay = new EventEmitter();

	@Input() actionImage: string;
	@Input() actionText: string;
	@Output() actionClick = new EventEmitter();

	constructor(public appService: AppService) {}

	ngAfterViewChecked() {
		this.loadOverlay.emit();
	}

	closeOverlay() {
		this.closed.emit();
	}

	onClick(event) {
		this.actionClick.emit(event);
	}
}
