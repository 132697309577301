import { RemainingStep } from './remainingStep.model';

export class WorryFree {
	isCurrentlyUsed: boolean;
	currentStep: number;
	maximumSteps: number;
	remainingStep: RemainingStep;
	totalPricePaid: string;
	limit: string;
}
