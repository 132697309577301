<vfac-overlay
	#nebaInstallationGuideTvOverlay
	[overlayData]="nebaInstallationGuideTvOverlayData"
	[show]="showNebaInstallationGuideTvUnicastOverlay"
	[page]="overlayPage"
	[loaded]="overlayLoaded"
	[loaderType]="'vodafone'"
	(closed)="closeGuideOverlay(true, 'btnCerrarGuia')"
	(backPage)="overlayGoBackwards()"
	[greyMode]="true"
>
	<div header *ngIf="overlayPage > 1">
		<div class="guide-progress">
			<vfac-g-progress-bar
				[total]="progressBarTitles?.length"
				[value]="progressBarCurrentStep"
				[disableAnimation]="false"
				[title]="progressBarTitles[progressBarCurrentStep - 1] | translate"
				[showProgress]="true"
				[styleClass]="'mva10-u--body'"
			>
			</vfac-g-progress-bar>
		</div>
	</div>

	<!-- Intro -->
	<div body id="page1">
		<div class="intro">
			<div class="intro-header">
				<!-- CSS image goes here -->
			</div>
			<div class="intro-body">
				<span class="intro-body__title mva10-u-heading--3">
					{{ 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastIntroTitle' | translate }}
				</span>
				<ul class="intro-body__points">
					<li
						class="intro-body__points-container"
						[ngStyle]="{
							'background-image':
								'url(' +
								appService.getImgFullPath('v10.myOrder.nebaInstallationTvUnicast.img.networksignal' | translate) +
								')'
						}"
					>
						<span
							class="intro-body__points-container__description mva10-u--body"
							[innerHTML]="'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastIntroPoint1' | translate"
						>
						</span>
					</li>
				</ul>
				<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
			</div>
		</div>
	</div>

	<!-- Guide Page 1 -->
	<div body id="page2">
		<div class="guide-step step1" id="myOrderSpNebaInstallationTvUnicastStep1Content">
			<span class="mva10-u-heading--4 vfes-align--center" id="myOrderSpNebaInstallationTvUnicastStep1Title">
				<strong> {{ 'v10.myOrder.routerInstallation.installRouterStep1name' | translate }} </strong>
			</span>
			<p
				id="myOrderSpNebaInstallationTvUnicastStep1Description"
				class="activate-router-description vfes-mb mva10-u-body vfes-title--left mva10-u--mt3"
				[innerHTML]="'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep1EntryTitle' | translate"
			></p>
			<div class="components-grid">
				<div class="grid-image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallationTvUnicast.img.powersupply' | translate) }}"
						alt="Fuente de alimentación"
					/>
					<span class="mva10-u-heading--5">
						<strong>
							{{ 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep1Component1' | translate }}
						</strong>
					</span>
				</div>
				<div class="grid-image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallationTvUnicast.img.cableethernet' | translate) }}"
						alt="Cable Ethernet"
					/>
					<span class="mva10-u-heading--5">
						<strong>
							{{ 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep1Component2' | translate }}
						</strong>
					</span>
				</div>
				<div class="grid-image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallationTvUnicast.img.decoder' | translate) }}"
						alt="Decodificador"
					/>
					<span class="mva10-u-heading--5">
						<strong>
							{{ 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep1Component3' | translate }}
						</strong>
					</span>
				</div>
				<div class="grid-image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallationTvUnicast.img.mando' | translate) }}"
						alt="Mando"
					/>
					<span class="mva10-u-heading--5">
						<strong>
							{{ 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep1Component4' | translate }}
						</strong>
					</span>
				</div>
				<div class="grid-image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallationTvUnicast.img.cablehdmi' | translate) }}"
						alt="Cable HDMI"
					/>
					<span class="mva10-u-heading--5">
						<strong>
							{{ 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep1Component5' | translate }}
						</strong>
					</span>
				</div>
				<div class="grid-image">
					<img
						src="{{ appService.getImgFullPath('v10.myOrder.nebaInstallationTvUnicast.img.cabletdt' | translate) }}"
						alt="Cable TDT"
					/>
					<span class="mva10-u-heading--5">
						<strong>
							{{ 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep1Component6' | translate }}
						</strong>
					</span>
				</div>
			</div>
		</div>
		<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
	</div>

	<!-- Guide Page 2 -->
	<div body id="page3">
		<div class="guide-step step2">
			<div class="grey-card" id="myOrderSpNebaInstallationTvUnicastStep2Substate1">
				<div
					class="activate-router-title mva10-u-heading--4 vfes-mb vfes-mt- vfes-title--left"
					id="myOrderSpNebaInstallationTvUnicastStep2Substate1Title"
				>
					<strong>
						{{ 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component1SubTitle' | translate }}
					</strong>
				</div>
				<p
					id="myOrderSpNebaInstallationTvUnicastStep2Substate1Description"
					class="activate-router-description vfes-mb mva10-u-body vfes-title--left"
					[innerHTML]="'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component1Description' | translate"
				></p>
				<div class="activate-router-image" id="myOrderSpNebaInstallationTvUnicastStep2Substate1ImgA">
					<img [src]="'v10.myOrder.routerInstallation.img.step2_02_controlGEN1' | imgFullPath" [alt]="'controlGen1'" />
				</div>
				<p
					id="myOrderSpNebaInstallationTvUnicastStep2Substate1DescriptionImg"
					class="activate-router-description vfes-mb mva10-u-body vfes-title--left mva10-u--mt3"
					[innerHTML]="
						'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component1DescriptionControl' | translate
					"
				></p>
				<div class="activate-router-image" id="myOrderSpNebaInstallationTvUnicastStep2Substate1ImgB">
					<img [src]="'v10.myOrder.routerInstallation.img.step2_02_controlGEN3' | imgFullPath" [alt]="'controlGen3'" />
				</div>
				<p
					id="myOrderSpNebaInstallationTvUnicastStep2Substate1DescriptionImgB"
					class="activate-router-description vfes-mb mva10-u-body vfes-title--left mva10-u--mt3"
					[innerHTML]="
						'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component1DescriptionControlB' | translate
					"
				></p>
			</div>
			<ng-container
				id="myOrderSpNebaInstallationTvUnicastStep2Substate2Content"
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component2SubTitle',
					description: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component2Description',
					imageAlt: 'Conectar HDMI al decodificador.',
					image: 'v10.myOrder.routerInstallation.img.step2_05' | imgFullPath
				}"
			>
			</ng-container>
			<ng-container
				id="myOrderSpNebaInstallationTvUnicastStep2Substate3Content"
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component3SubTitle',
					description: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component3Description',
					imageAlt: 'Conectar cable alimentación al decodificador.',
					image: 'v10.myOrder.nebaInstallationTvUnicast.img.tdt_hdmi' | imgFullPath
				}"
			>
			</ng-container>
			<ng-container
				id="myOrderSpNebaInstallationTvUnicastStep2Substate4Content"
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component4SubTitle',
					description: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component4Description',
					imageAlt: 'Conectar cable alimentación al decodificador.',
					image: 'v10.myOrder.nebaInstallationTvUnicast.img.deco_fuente' | imgFullPath
				}"
			>
			</ng-container>
			<ng-container
				id="myOrderSpNebaInstallationTvUnicastStep2Substate5Content"
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component5SubTitle',
					description: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component5Description',
					imageAlt: 'Conectar cable alimentación al decodificador.',
					image: 'v10.myOrder.nebaInstallationTvUnicast.img.mando_hdmi' | imgFullPath
				}"
			>
			</ng-container>
			<mva10-c-button
				(mva10OnClick)="overlayGoForwards()"
				class="navigation-buttons"
				id="myOrderSpNebaInstallationTvUnicastStep2Substate5Btn"
			>
				{{ 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component5Btn' | translate }}
			</mva10-c-button>
			<ng-container
				id="myOrderSpNebaInstallationTvUnicastStep2Substate10Content"
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component10SubTitle',
					description: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component10Description',
					imageAlt: 'Conectar cable alimentación al decodificador.',
					image: 'v10.myOrder.routerInstallation.img.step2_14GEN3' | imgFullPath
				}"
			>
			</ng-container>
			<ng-container
				id="myOrderSpNebaInstallationTvUnicastStep2Substate11Content"
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component11SubTitle',
					description: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component11Description',
					imageAlt: 'Conectar cable alimentación al decodificador.',
					image: 'v10.myOrder.nebaInstallationTvUnicast.img.tdt_hdmi_gen3' | imgFullPath
				}"
			>
			</ng-container>
			<ng-container
				id="myOrderSpNebaInstallationTvUnicastStep2Substate12Content"
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component12SubTitle',
					description: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component12Description',
					imageAlt: 'Conectar cable alimentación al decodificador.',
					image: 'v10.myOrder.nebaInstallationTvUnicast.img.deco_fuente_gen3' | imgFullPath
				}"
			>
			</ng-container>
			<ng-container
				id="myOrderSpNebaInstallationTvUnicastStep2Substate2Content"
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component13SubTitle',
					description: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component13Description',
					imageAlt: 'Conectar cable alimentación al decodificador.',
					image: 'v10.myOrder.nebaInstallationTvUnicast.img.mando_hdmi' | imgFullPath
				}"
			>
			</ng-container>
		</div>
		<div class="navigation-buttons" id="myOrderSpNebaInstallationTvUnicastStep2Substate13ContentBtn">
			<mva10-c-button (mva10OnClick)="overlayGoForwards()" id="myOrderSpNebaInstallationTvUnicastStep2Substate13Btn">
				{{ 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep2Component13Btn' | translate }}
			</mva10-c-button>
		</div>
	</div>

	<!-- Guide Page 3 -->
	<div body id="page4">
		<div class="guide-step step3">
			<ng-container
				id="myOrderSpNebaInstallationTvUnicastStep3Substate4Content"
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3Component6SubTitle',
					description: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3Component6Description',
					imageAlt: 'Seleccionar fuente HDMI',
					image: 'v10.myOrder.nebaInstallationTvUnicast.img.checkstartguidedecoder' | imgFullPath
				}"
			>
			</ng-container>
			<ng-container
				id="myOrderSpNebaInstallationTvUnicastStep3Substate7Content"
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3Component7SubTitle',
					description: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3Component7Description',
					imageAlt: 'Comprobar inicio guia de descodificador',
					image: 'v10.myOrder.decoInstallation.img.softSyncChannels' | imgFullPath
				}"
			>
			</ng-container>
			<ng-container
				id="myOrderSpNebaInstallationTvUnicastStep3Substate8Content"
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3Component8SubTitle',
					description: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3DescriptionControlBluetooth',
					imageAlt: 'Comprobar inicio guia de descodificador',
					image: 'v10.myOrder.routerInstallation.img.step3_remoteControl' | imgFullPath,
					customClass: 'withControl'
				}"
			>
			</ng-container>
			<ng-container
				id="myOrderSpNebaInstallationTvUnicastStep3Substate9Content"
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3Component9SubTitle',
					description: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3Component9Description',
					imageAlt: 'Conectar cable alimentación al decodificador.',
					image: 'v10.myOrder.routerInstallation.img.step3_11' | imgFullPath
				}"
			>
			</ng-container>
			<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
			<ng-container
				id="myOrderSpNebaInstallationTvUnicastStep3Substate14Content"
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3Component14SubTitle',
					description: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3Component14Description',
					imageAlt: 'Sintonizar canales',
					image: 'v10.myOrder.nebaInstallationTvUnicast.img.checkstartguidedecoder' | imgFullPath,
					customClass: 'smaller vfes-mb'
				}"
			>
			</ng-container>
			<div class="grey-card" id="myOrderSpNebaInstallationTvUnicastStep3Substate15TContent">
				<div
					id="myOrderSpNebaInstallationTvUnicastStep3Substate15Title"
					class="activate-router-title mva10-u-heading--4 vfes-mb vfes-mt- vfes-title--left"
				>
					<strong id="myOrderSpNebaInstallationTvUnicastStep3Substate15TitleItem">
						{{ 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3Component15SubTitle' | translate }}
					</strong>
				</div>
				<p
					id="myOrderSpNebaInstallationTvUnicastStep3Substate15DescriptionA"
					class="activate-router-description vfes-mb mva10-u-body vfes-title--left"
				>
					{{ 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3Component15Description' | translate }}
				</p>
				<div class="activate-router-image vfes-mb" id="myOrderSpNebaInstallationTvUnicastStep3Substate15ImgA">
					<img
						id="myOrderSpNebaInstallationTvUnicastStep3Substate15ImgAItem"
						[src]="'v10.myOrder.decoInstallation.img.step3_12ImgA' | imgFullPath"
						[alt]="'tv1'"
					/>
				</div>
				<p
					id="myOrderSpNebaInstallationTvUnicastStep3Substate15DescriptionB"
					class="activate-router-description vfes-mb mva10-u-body vfes-title--left mva10-u--mt3"
					[innerHTML]="
						'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3Component15DescriptionB' | translate
					"
				></p>
				<div class="activate-router-image" id="myOrderSpNebaInstallationTvUnicastStep3Substate15ImgB">
					<img
						id="myOrderSpNebaInstallationTvUnicastStep3Substate15ImgBItem"
						[src]="'v10.myOrder.decoInstallation.img.step3_12ImgB' | imgFullPath"
						[alt]="'tv2'"
					/>
				</div>
			</div>
			<ng-container
				id="myOrderSpNebaInstallationTvUnicastStep3Substate16Content"
				[ngTemplateOutlet]="greyCardTemplate"
				[ngTemplateOutletContext]="{
					subTitle: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3Component16SubTitle',
					description: 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3DescriptionControlBluetooth',
					imageAlt: 'Sintonizar canales',
					image: 'v10.myOrder.routerInstallation.img.step3_remoteControl' | imgFullPath,
					customClass: 'withControl'
				}"
			>
			</ng-container>
			<div class="grey-card" id="myOrderSpNebaInstallationTvUnicastStep3Substate17Content">
				<div
					class="activate-router-title mva10-u-heading--4 vfes-mb vfes-mt- vfes-title--left"
					id="myOrderSpNebaInstallationTvUnicastStep3Substate17Title"
				>
					<strong id="myOrderSpNebaInstallationTvUnicastStep3Substate17TitleItem">
						{{ 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3Component17SubTitle' | translate }}
					</strong>
				</div>
				<p
					id="myOrderSpNebaInstallationTvUnicastStep3Substate17DescriptionA"
					class="activate-router-description vfes-mb mva10-u-body vfes-title--left"
					[innerHTML]="
						'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3Component17DescriptionA' | translate
					"
				></p>
				<p
					id="myOrderSpNebaInstallationTvUnicastStep3Substate17DescriptionB"
					class="activate-router-description vfes-mb mva10-u-body vfes-title--left"
					[innerHTML]="
						'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3Component17DescriptionB' | translate
					"
				></p>
				<p
					id="myOrderSpNebaInstallationTvUnicastStep3Substate17DescriptionC"
					class="activate-router-description vfes-mb mva10-u-body vfes-title--left"
				>
					{{ 'v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastStep3Component17DescriptionC' | translate }}
				</p>
				<div class="activate-router-image smaller vfes-mb'" id="myOrderSpNebaInstallationTvUnicastStep3Substate17Img">
					<img
						id="myOrderSpNebaInstallationTvUnicastStep3Substate17ImgItem"
						[src]="'v10.myOrder.routerInstallation.img.step3_11GEN3' | imgFullPath"
						[alt]="'tvControl'"
					/>
				</div>
			</div>
		</div>
		<ng-container *ngTemplateOutlet="navigationButtonsTemplate"> </ng-container>
	</div>
</vfac-overlay>

<ng-template #navigationButtonsTemplate>
	<div class="navigation-buttons">
		<mva10-c-button
			(mva10OnClick)="
				overlayPage === pagesNames.length
					? translate.instant('v10.myOrder.checkInstallationOverlay.checkNebaAutoInstallationActive') === stringOfTrue
						? closeGuideOverlayAndOpenInstallationCheck()
						: closeGuideOverlay(true, 'btnComprobarInstalacion')
					: overlayGoForwards()
			"
		>
			{{
				overlayPage !== pagesNames.length
					? ('v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastNextStep' | translate)
					: ('v10.myOrder.checkInstallationOverlay.checkNebaAutoInstallationActive' | translate) === stringOfTrue
					? ('v10.myOrder.nebaInstallationTvUnicast.autoiCheckInstallationButton' | translate)
					: ('v10.myOrder.nebaInstallationTvUnicast.guideNebaTvUnicastOverlayPageFinish' | translate)
			}}
		</mva10-c-button>
	</div>
</ng-template>

<ng-template
	#greyCardTemplate
	let-title="title"
	let-subTitle="subTitle"
	let-description="description"
	let-image="image"
	let-imageAlt="imageAlt"
	let-customClass="customClass"
>
	<div class="grey-card">
		<div class="activate-router-title mva10-u-heading--4 vfes-mb vfes-mt" *ngIf="title">
			<strong> {{ title | translate }} </strong>
		</div>
		<div class="activate-router-title mva10-u-heading--4 vfes-mb vfes-mt- vfes-title--left" *ngIf="subTitle">
			<strong> {{ subTitle | translate }} </strong>
		</div>
		<p
			*ngIf="description"
			class="activate-router-description vfes-mb mva10-u-body vfes-title--left"
			[innerHTML]="description | translate"
		></p>
		<div class="activate-router-image" *ngIf="image" [ngClass]="customClass">
			<img [src]="image" [alt]="imageAlt" />
		</div>
	</div>
</ng-template>
