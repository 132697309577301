<div class="media-container">
	<ng-container *ngIf="!customIcon; then default; else custom"> </ng-container>

	<ng-template #default>
		<div *ngFor="let media of rrss" class="single-media">
			<a *ngIf="media.link" href="{{ media.link }}" target="_blank">
				<img src="{{ media.img }}" alt="{{ media.name }}" />
			</a>
			<a *ngIf="media.action">
				<img src="{{ media.img }}" alt="{{ media.name }}" (click)="media.action()" />
			</a>
		</div>
	</ng-template>

	<ng-template #custom>
		<div (click)="rrss[0].action()">
			<ng-content> </ng-content>
		</div>
	</ng-template>
</div>
<div #tooltip></div>
