<div *ngIf="displayBanner" class="app-from-web">
	<div class="logo-container">
		<img
			id="vodafoneLogo"
			class="voda-logo"
			alt="vodafone-logo"
			[src]="authenticate ? (imagePathAuth | imgFullPath) : (imagePathNoAuth | imgFullPath)"
		/>
	</div>

	<div class="close-container">
		<span id="closeButton" (click)="xButtonClicked()" class="icon-close"> </span>
	</div>
	<div class="text-buttons-container">
		<div class="texts">
			<div
				id="nativeBannerTitle"
				class="text-content"
				*ngIf="(authenticate && titleAuth.trim() != '') || (!authenticate && titleNoAuth.trim() != '')"
				[innerHtml]="authenticate ? titleAuth : titleNoAuth"
			></div>
			<div
				id="nativeBannerDescription"
				class="description"
				*ngIf="(authenticate && descAuth.trim() != '') || (!authenticate && descNoAuth.trim() != '')"
				[innerHtml]="authenticate ? descAuth : descNoAuth"
			></div>
		</div>

		<div class="flex btns-container">
			<button id="nativeBannerOpenApp" (click)="openAppOrStore()" class="btn btn-mvl open">
				{{ 'common.messagesList.nativeBanner.confirmButton.text' | translate }}
			</button>
			<button id="nativeBannerRegister" (click)="register()" class="btn btn-mvl create" *ngIf="!authenticate">
				{{ 'login.loginForm.buttonsList.registerMVA10.extLinkText' | translate }}
			</button>
		</div>
	</div>
</div>
