import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { OrdenObtenerPedidos, OrderIncluded } from '../../../models/obtenerPedidos.model';
import { UtilsService } from '../services/utils.service';
import { MyOrdersTaggingService } from '../../../services/my-orders-tagging.service';

@Component({
	selector: 'sp-detail-ntol',
	templateUrl: './sp-detail-ntol.component.html',
	styleUrls: ['./sp-detail-ntol.component.scss'],
})
export class SpDetailNtolComponent implements OnInit {
	@Input() showDetailNtol: boolean;
	@Input() icon: string;
	@Input() titleFecha: string;
	@Input() orderData: OrdenObtenerPedidos;
	@Output() closeDetail: EventEmitter<void> = new EventEmitter<void>();
	public showError: boolean;
	public showSpinner: boolean;
	public incluidoPedido: OrderIncluded[] = [];

	constructor(
		private utilsService: UtilsService,
		public appService: AppService,
		private tagging: MyOrdersTaggingService
	) {}

	ngOnInit(): void {
		this.showSpinner = true;
		let nombrePaquete: string[];
		if (this.orderData.elementosCesta) {
			nombrePaquete = this.orderData.elementosCesta
				.filter((elem) => elem.tipo === 'Paquete')
				.map((elem) => {
					return elem.nombre;
				});
			this.orderData.elementosCesta
				.filter((elem) => !elem.paquete && elem.tipo !== 'Paquete')
				.forEach((elem) => {
					const aux: OrderIncluded = { nombre: elem.nombre, elementos: [] };
					this.incluidoPedido.push(aux);
				});
		}
		if (nombrePaquete?.length > 0 && this.orderData.elementosCesta) {
			const elementosPaquete: string[] = this.orderData.elementosCesta
				.filter((elem) => elem.paquete)
				.map((elem) => {
					return elem.nombre;
				});

			const paquetePedido: OrderIncluded = {
				nombre: nombrePaquete[0],
				elementos: elementosPaquete,
			};
			this.incluidoPedido.push(paquetePedido);
		}
		this.showSpinner = false;
		this.doSendView();
	}
	doSendView(): void {
		this.tagging.sendPage(['detalle pedido ntol']);
	}
	getDateByOrderState(stateNum: string): string {
		switch (Number(stateNum)) {
			case 1:
				return this.orderData.fechaCancelacion;
			case 2:
				return this.orderData.fechaFin;
			case 3:
				return this.orderData.fechaSolicitud;
		}
	}
	close(): void {
		this.closeDetail.emit();
	}

	redirectToContracts(): void {
		this.tagging.sendInteraction({
			link_type: 'click en acceder resumen de compra',
		});
		this.utilsService.postMessageToParent(
			JSON.stringify({
				action: 'forward',
				message: 'contratos-documentacion',
			})
		);
	}
}
