<sp-modal
	#modal
	bodyTitle="{{ 'common.messagesList.fullAccessMessageNetLogged.title' | translate }}"
	bodyContent="{{ 'common.messagesList.fullAccessMessageNetLogged.description' | translate }}"
	primaryButton="true"
	primaryButtonText="{{ 'common.messagesList.fullAccessMessageNetLogged.confirmButton.text' | translate }}"
	[primaryButtonClick]="accept"
	primaryButtonStyle="btn registration"
	secondaryButton="true"
	secondaryButtonText="{{ 'common.messagesList.fullAccessMessageNetLogged.cancelButton.text' | translate }}"
	secondaryButtonStyle="btn login"
	[secondaryButtonClick]="destroy"
	[closeButtonClick]="destroy"
>
</sp-modal>
