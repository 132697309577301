import { InfoPromoPega } from './info-promo-pega.model';
import { InformationTV } from './informationTV.model';
import { PromotionsIngenio } from './promotions-ingenio.model';
import { Savings } from './savings.model';
import { BackdropPromotionModel } from './backdrop-promotion.model';
import { BackdropPermanenceModel } from './backdrop-permanence.model';
import { ModalBodyServices } from '../mobile-to-pack/modal-body-services';
import { ModelChannelPacks, ModelTVPackage } from '../mobile-to-pack/model-tv-package.model';
import { RateServices } from '../mobile-to-pack/rate-services.model';

export class CommercialOffer {
	public offerId?: number;
	public packageId?: string;
	public code?: string;
	public rate?: any;
	public name?: string;
	public nameList?: string;
	public nameFicha?: string;
	public permanenceDuration?: string;
	public installment?: string;
	public installmentPromo?: string;
	public installmentTaxes?: string;
	public installmentPromoTaxes?: string;
	public installmentNumber?: number;
	public installmentPromoNumber?: number;
	public installmentTaxesNumber?: number;
	public installmentPromoTaxesNumber?: number;
	public deviceUpfrontTaxesPromo?: number;
	public callsIncludedDetailList?: string;
	public callsIncludedList?: string;
	public callsIncludedSecondList?: string;
	public callsList?: string;
	public dataDetailList?: string;
	public dataIncludedDetailList?: string;
	public dataIncludedList?: string;
	public dataIncludedSecondList?: string;
	public dataList?: string;
	public dataSpeedList?: string;
	public otherDetailList?: string;
	public otherList?: string;
	public roamingList?: string;
	public roamingDetailList?: string;
	public image?: string;
	public promotions?: string[];
	public recommendedUses?: string[];
	public showDetailList?: boolean;
	public showDetailFicha?: boolean;
	public showOpenList?: boolean;
	public registerType?: number;
	public additionalLine?: boolean;
	public close?: boolean;
	public internetIncludedFicha?: string;
	public internetIncludedSecondFicha?: string;
	public internetIncludedList?: string;
	public internetIncludedSecondList?: string;
	public dataAddLineDefNewRegi?: string;
	public dataAddLineDefNewRegiOne?: string;
	public keep?: boolean;
	public infoPromoPega?: InfoPromoPega;
	public disabled?: boolean;
	public transaccional?: boolean;
	public unsubscribeDiscounts?: string;
	public tvPackageInfo?: ModelTVPackage;
	public configurable?: boolean;
	public device?: string;
}

export class CommercialOfferPega {
	public flagPromoPega: boolean;
	public cardTitle: string;
	public cardSubtitle: string;
	public cardVelocity: string;
	public thunderAltImageIcon: string;
	public thunderImageIcon: string;
	public installmentTaxes: number;
	public netFeeIncrease: string;
	public precioDescAdHoc: string;
	public duracionDescAdHoc: string;
	public originNetFee: string;
	public secondLinesDiscountPrice: string;
	public recoDtoExpire: string;
	public exclusiveOffer: number;
	public priceOfferEnds: number;
	public bindingLife?: string;
	public bindingPenalty?: string;
}

export class CommercialTagg {
	optionSelected?: string;
	isLimitedTarif?: boolean;
	number?: string;
	company?: string;
	firstName?: string;
	middleName?: string;
	familyName?: string;
	dni?: string;
	email?: string;
	change?: boolean;
}
export interface TarifCharacteristic {
	datos: string;
	llamadasSMS: string;
	roaming: string;
	red?: string;
	textoVelocidad?: string;
	imgVelocidad?: string;
	// BUNDLEUPSELL
	fibreTitle?: string;
	fibreDescription?: string;
	tvTitle?: string;
	tvDescription?: string;
}
export interface IOfferViewModel {
	commercialOffer: CommercialOffer;
	headbandOpenText: string;
	discountAmmountTaxes?: string[];
	detailTextList: string[];
	detailTextListLongText: string[];
	detailTextFicha: string[];
	longTextList: string[];
	longImgList: string[];
	hasTooltip: boolean;
	roundedInstallmentPromoTaxes: string;
	roundedInstallmentPromo: string;
	roundedInstallmentTaxes: string;
	roundedInstallment: string;
	detailTarif?: TarifCharacteristic;
	promotionsIngenio?: PromotionsIngenio;
	stayPromotionText?: string;
	informationTV?: InformationTV;
	informationPackageTV?: ModelChannelPacks;
	savings?: Savings;
	backdropPromotion?: BackdropPromotionModel;
	backdropPermanence?: BackdropPermanenceModel;
	cardTitle?: string;
	bodyServices?: ModalBodyServices;
	bodyServicesPdP?: RateServices[];
	hasMobile?: boolean;
	visible?: boolean;
	visibleNoPromotedQuota?: boolean;
	isOneIlimited?: boolean;
	clientInfoMsisdnPackage?: string;
	unsubscribeServices?: Array<string>;
}

export interface OfferDiscount {
	code: string;
	instance: string;
	type: string;
	dtoStart: string;
	dtoExpire: string;
	dtoDuration: string;
	amount: string;
	netAmount: string;
}
export interface PegaModel {
	offers: CommercialOffer[];
	infoPromoPega: {
		bundleParent?: string;
		codigoDescAdHoc?: string;
		codigoNBA?: string;
		colorNBA?: string;
		containerName?: string;
		cuotaDescAdHoc?: string;
		duracionDescAdHoc?: string;
		groupNBA?: string;
		id?: string;
		interactionID?: string;
		issue?: string;
		label?: string;
		name?: string;
		netFeeIncrease?: string;
		offerVFDescriptor?: string;
		originNetFee?: string;
		paqueteDescAdHoc?: string;
		precioDescAdHoc?: string;
		rank?: string;
		space?: string;
		secondLinesDiscountPrice?: string;
		umbralesNBA?: string;
		bindingLife?: string;
		bindingPenalty?: string;
		unsubcribeDiscountsExpirationDate?: string;
	};
}
