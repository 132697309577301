<div class="order-status-cta">
	<div class="order-status-cta-loading" *ngIf="loading">
		<sp-skeleton [imageSize]="32" [numberOfBars]="2"> </sp-skeleton>
	</div>
	<ng-container *ngIf="!loading && statusMap">
		<div class="order-status-cta-alert" *ngIf="statusMap.showAlert">
			<span class="order-status-cta-alert__badge mva10-u--footnote-bd">
				{{ statusMap.alertMsg | translate }}
			</span>
		</div>
		<div class="order-status-cta-content" (click)="onCTAClick()">
			<div class="order-status-cta-content__wrapper">
				<img
					src="{{ appService.getImgFullPath('v10.myOrder.orderStatusCta.img.' + statusMap.status | translate) }}"
					alt="{{ statusMap.status }}"
				/>
				<div class="order-status-cta-content__status">
					<p class="order-status-cta-content__status__title">
						{{ statusMap.statusDescription | translate }}
					</p>
					<p class="order-status-cta-content__status__subtitle">
						{{ 'v10.myOrder.orderStatusCta.historicoOLSubtitleCTA' | translate }}
					</p>
				</div>
			</div>
			<div class="order-status-cta-chevron">
				<svg class="order-status-cta-chevron__icon" width="30px" height="30px">
					<title>{{ 'v10.myOrder.orderStatusCta.titleChevron' | translate }}</title>
					<use class="icon-v3" xlink:href="#icon-chevron-right"></use>
				</svg>
			</div>
		</div>
	</ng-container>
</div>
