<div [ngClass]="{ 'card-prepaid': cardResp?.isPrepaid }">
	<div
		class="cintillo pega"
		[ngClass]="{ public: !showCintillo, pega: showCintillo }"
		[ngStyle]="showCintillo && { background: cintilloColor }"
	>
		<p class="cintillo-text" [ngStyle]="{ color: textColor }">{{ cardResp.headBandText }}</p>
	</div>
	<div
		[ngClass]="{ 'cintilloPega': showCintillo, 'card': !showCintillo, 'card-prep': cardResp?.isPrepaid }"
		[ngStyle]="showCintillo && { border: cintilloBorderColor }"
	>
		<div
			class="card-superior"
			[ngClass]="{
				'gradientVf': !cardResp.isLimitedTariff,
				'gradientYu': cardResp.isLimitedTariff,
				'card-superior-public': !showCintillo,
				'card-sup-prep': cardResp?.isPrepaid
			}"
		>
			<p id="PLPRevampCardSuperiorHeader" class="card-superior-header fontText">{{ cardResp.titleText }}</p>
			<p
				id="PLPRevampCardSuperiorDescription"
				*ngIf="existsDescription1"
				class="card-superior-description fontText"
				[innerHtml]="cardResp.description1"
			></p>
			<p
				id="PLPRevampCardSuperiorDescriptionSec"
				*ngIf="cardResp?.desc2"
				class="desc fontText"
				[innerHtml]="cardResp.desc2"
			></p>
			<p
				id="PLPRevampCardSuperiorDescriptionThird"
				*ngIf="cardResp?.desc3"
				class="desc fontText"
				[innerHtml]="cardResp.desc3"
			></p>
			<p id="PLPRevampCardSuperiorDescriptionSpace" *ngIf="!existsDescription1" class="card-superior-description"></p>
			<h5 id="PLPRevampCardSuperiorDescription2" *ngIf="existsDescription2" class="card-superior-description2 fontText">
				{{ cardResp.description2 }}
			</h5>
			<p id="PLPRevampCardSuperiorDescriptionSpace2" *ngIf="!existsDescription2" class="card-superior-description2"></p>
			<div class="card-superior-velocityInfo" *ngIf="existsTopIconText">
				<img
					alt="cardResp.topIconText"
					id="PLPRevampCardSuperiorVelocityInfoIcon"
					class="card-superior-velocityInfo-icon"
					src="{{ cardResp.topIcon }}"
					*ngIf="existsTopIcon"
				/>
				<p
					id="PLPRevampCardSuperiorVelocityInfoText"
					class="card-superior-velocityInfo-text fontText"
					[innerHtml]="cardResp.topIconText"
				></p>
			</div>
		</div>
		<div class="card-inferior">
			<div class="card-inferior-price">
				<mva10-c-price
					id="PLPRevampCardInferiorPriceDiscount"
					*ngIf="showIva"
					[amount]="cardResp?.isPrepaid ? cardResp?.textPriceWithDiscount : priceModel.priceDiscountTaxes"
					[recurrence]="cardResp?.isPrepaid ? days : month"
					[ngClass]="'ws10-c-price--x-small@mobile ws10-c-price--medium@tablet'"
				>
				</mva10-c-price>
				<mva10-c-price
					id="PLPRevampCardInferiorPriceRoundedInstallmentPromo"
					*ngIf="!showIva"
					[amount]="priceModel.priceDiscount"
					[recurrence]="month"
					[ngClass]="'ws10-c-price--x-small@mobile ws10-c-price--medium@tablet'"
				>
				</mva10-c-price>
				<mva10-c-price
					id="PLPRevampCardInferiorPriceNormal"
					class="strike"
					*ngIf="showIva && !controlPrice && !cardResp?.isPrepaid"
					[amount]="priceModel.priceTaxes"
					[strike]="true"
					[ngClass]="'ws10-c-price--x-small@mobile ws10-c-price--medium@tablet'"
				>
				</mva10-c-price>
				<mva10-c-price
					id="PLPRevampCardInferiorPriceRoundedInstallment"
					class="strike"
					*ngIf="!showIva && !controlPrice"
					[amount]="priceModel.price"
					[strike]="true"
					[ngClass]="'ws10-c-price--x-small@mobile ws10-c-price--medium@tablet'"
				>
				</mva10-c-price>
			</div>
			<div class="card-inferior-info">
				<div class="mva10-u--mb1">
					<p id="PLPRevampCardInferiorBlueText" class="card-inferior-info-textBelow">
						{{ textBlue[0] }} <strong> {{ textBlue[1] }} </strong> {{ textBlue[2] }}
					</p>
				</div>
			</div>

			<div class="card-inferior-next">
				<div *ngIf="!isMandatory; else templateIsMandatory">
					<div class="mva10-u--mb1">
						<button id="PLPRevampCardInferiorButton" class="button" (click)="clickContinue()">
							{{ cardResp.textButton }}
						</button>
					</div>
					<a id="PLPRevampCardInferiorNextText" class="expandArea" (click)="clickInfo()">
						{{ cardResp.textBelowButton }}
					</a>
				</div>
			</div>
			<ng-template #templateIsMandatory>
				<div class="mrgnTopAuto mva10-u--mb1">
					<button id="PLPRevampCardInferiorButtonMandatory" class="button" (click)="clickInfo()">
						{{ cardResp.textBelowButton }}
					</button>
				</div>
			</ng-template>
		</div>
	</div>
</div>
